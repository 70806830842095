
import { STORAGE_NOTICES } from '../constants/storage';

import * as storage from '../persistance/storage';

export class WebsiteNotice {

	constructor(props) {

		// As props, should accept another WebsiteNotice object or a server resp
		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.position = props.position || props.key || '';
		this.copy = props.copy || '';
		this.href = props.href || '';
		this.textColor = props.textColor || props.text_color || '';
		this.backgroundColor = props.backgroundColor || props.background_color || '';
		this.isEnabled = props.isEnabled || props.is_enabled || false;
	}

	get key() {
		return this.position;
	}

	get linkTo() {
		return this.href ? `/${this.href}` : null;
	}

	get fullHrefUrl() {
		return this.href ? `${window.location.origin}/${this.href}` : null;
	}

	static getLocal() {
		
		const notices = [];
		const localNotices = storage.get(STORAGE_NOTICES);

		if(!localNotices) {
			return notices;
		}

		for(const nt of localNotices) {
			notices.push(new WebsiteNotice(nt));
		}
		return notices;
	}

	getApiData() {
		const apiData = {
			key: this.position,
			copy: this.copy || '',
			href: this.href || null,
			text_color: this.textColor || null,
			background_color: this.backgroundColor || null,
			is_enabled: this.isEnabled || false,
		};
		return apiData;
	}

	toJson() {
		return {
			publicUuid: this.publicUuid,
			position: this.position,
			copy: this.copy,
			href: this.href,
			textColor: this.textColor,
			backgroundColor: this.backgroundColor,
			isEnabled: this.isEnabled,
		};
	}
}