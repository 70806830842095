import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { utcToZonedTime } from 'date-fns-tz';

import { CAL_DAY_NAMES } from '../../../constants/calendar';
import { 
  ERROR_EVENT_FORMAT_MISSING,
  ERROR_EVENT_REPEAT_FREQUENCY, 
  ERROR_PRODUCT_LINE_PLEASE_SELECT,
} from '../../../constants/errors';
import {
  EVENT_FREQUENCY_DAILY, 
  EVENT_FREQUENCY_WEEKLY, 
  EVENT_FREQUENCY_MONTHLY_WEEK, 
  EVENT_MONTHLY_WEEK_OPTIONS, 
} from '../../../constants/events';
import { ICON_CALENDAR } from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { Month } from '../../../models/calendar';
import { 
  StoreEvent, 
  StoreEventSchedule, 
} from '../../../models/events';
import { 
  ProductLine, 
} from '../../../models/products';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../utils/currency';
import { 
  getCostError,
  getDescriptionError, 
  getEventRegistrationLimitError,
  getFutureDateError,
  getNameError, 
  getNotEmptyError,
  getQuantityError, 
  getTimeError,
  isFormValid, 
} from '../../../utils/form-validation';
import { 
  formatServerError,
  normalizePrice,
} from '../../../utils/formatting';
import { 
  dateDelta,
  getStoreTimeZone,
  is24HourTime, 
  twoDigitInt, 
} from '../../../utils/general';
import { 
  getLanguageObjectFromCode,
  getStoreLanguage,
} from '../../../utils/language';
import { getOrderedProductLines } from '../../../utils/product';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Dropdown from '../../Input/Dropdown';
import { Icon } from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';

import * as eventActionCreators from '../../../actions/event';
import * as productLineActionCreators from '../../../actions/product-line';
let allActionCreators = Object.assign({}, eventActionCreators, productLineActionCreators);

export class EventScheduleDetailsEdit extends Component {

  constructor(props) {
    super(props);

    const is24hr = is24HourTime(this.getLanguage());
    const zonedTime = utcToZonedTime(this.props.eventObj.startTime, getStoreTimeZone());

    let hourValue = zonedTime.getHours();
    if(is24hr === false) {
      if(hourValue === 0) {
        hourValue = 12;
      } else if(hourValue > 12) {
        hourValue = hourValue - 12;
      }
    }

    this.state = {

      isDirty: false,

      inputName: this.props.eventObj.name,
      inputEndDate: this.props.eventObj.schedule.endDate || '',
      inputTimeHour: hourValue,
      inputTimeMinutes: twoDigitInt(zonedTime.getMinutes()),
      inputTimePost: zonedTime.getHours() >= 12 && zonedTime.getHours() !== 24 ? 'pm' : 'am',
      inputRepeatFrequency: this.props.eventObj.schedule.scheduleType,
      inputDescription: this.props.eventObj.description || '', 
      inputProductLine: this.props.eventObj.productLine || new ProductLine(), 
      inputFormat: this.props.eventObj.format ? this.props.eventObj.format.key : '', 
      inputCost: this.props.eventObj.cost ? normalizePrice(this.props.eventObj.cost) : '', 
      inputLimit: this.props.eventObj.capacity || '',

      errorName: '',
      errorEndDate: '',
      errorTime: '',
      errorDescription: '', 
      errorProductLine: '', 
      errorFormat: '', 
      errorCost: '',
      errorLimit: '',

      is24HourTime: is24hr,

      requestPending: false,
      requestError: null,

      savePromptOpen: false, 
    };

    this.descriptionLimit = 8192;

    this.hourInput = React.createRef();
  }

  componentDidMount() {

    // Fetch product line data
    if(this.props.productLine && this.props.productLine.productLines === null) {
      
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {

    // Check for save signal
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }

    // Toggle 12 / 24 hour
    const check24HourTime = is24HourTime(this.getLanguage());
    if(this.state.is24HourTime !== check24HourTime) {

      const timeChangeState = {
        is24HourTime: check24HourTime,
      }

      if(check24HourTime) {
        timeChangeState['inputTimeHour'] = this.getDateHour();
      } else {
        if(parseInt(this.state.inputTimeHour) === 0) {
          timeChangeState['inputTimeHour'] = 12;
          timeChangeState['inputTimePost'] = 'am';
        } else if(parseInt(this.state.inputTimeHour) > 12) {
          timeChangeState['inputTimeHour'] = parseInt(this.state.inputTimeHour) - 12;
          timeChangeState['inputTimePost'] = 'pm';
        } else if(this.state.inputTimeHour && parseInt(this.state.inputTimeHour) === 12) {
          timeChangeState['inputTimePost'] = 'pm';
        } else if(this.state.inputTimeHour && parseInt(this.state.inputTimeHour) < 12) {
          timeChangeState['inputTimePost'] = 'am';
        }
      }
      this.setState(timeChangeState);
    }
  }

  checkTimeClick(evt) {
      
    let targetElement = evt.target;
    do {
      if(targetElement.tagName === 'INPUT' || targetElement.tagName === 'SELECT') {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    if(this.hourInput && this.hourInput.current) {
      this.hourInput.current.focus()
    }
  }

  getDateHour() {
    if(this.state.inputTimePost === 'pm' && parseInt(this.state.inputTimeHour) < 12) {
      return parseInt(this.state.inputTimeHour) + 12;
    } else if(this.state.inputTimePost === 'am' && parseInt(this.state.inputTimeHour) === 12) {
      return 0;
    } else {
      return parseInt(this.state.inputTimeHour);
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getLocale() {
    const lang = getLanguageObjectFromCode(this.getLanguage());

    if(!lang) { return ''; }
    return lang.locale || '';
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorName) {
        this.validateName();
      }
    });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  changeTimeHour(evt) {
    this.setState({
      inputTimeHour: evt.target.value,
    }, () => {
      this.makeDirty();
    });
  }

  validateTimeHour() {
    
    const hrValue = parseInt(this.state.inputTimeHour);

    const minValue = this.state.is24HourTime ? 0 : 1;
    const maxValue = this.state.is24HourTime ? 23 : 12;
    
    if(!hrValue && hrValue !== 0) {
      this.setState({ inputTimeHour: '' });
    } else if(hrValue < minValue) {
      this.setState({ inputTimeHour: minValue });
    } else if(hrValue > maxValue) {
      this.setState({ inputTimeHour: maxValue });
    }
  }

  changeTimeMinutes(evt) {
    this.setState({
      inputTimeMinutes: evt.target.value,
    }, () => {
      this.makeDirty();
    });
  }

  validateTimeMinutes() {

    const minutes = parseInt(this.state.inputTimeMinutes);

    const minValue = 0;
    const maxValue = 59;
    
    if(!minutes && minutes !== 0) {
      this.setState({ inputTimeMinutes: '' });
    } else if(minutes < minValue) {
      this.setState({ inputTimeMinutes: minValue });
    } else if(minutes > maxValue) {
      this.setState({ inputTimeMinutes: maxValue });
    }
  }

  changeTimePost(evt) {
    this.setState({
      inputTimePost: evt.target.value,
    }, () => {
      this.makeDirty();
    });
  }

  getTimePostOptions() {
    return [
      {
        display: 'am',
        value: 'am',
      },
      {
        display: 'pm',
        value: 'pm',
      },
    ];
  }

  changeEndDate(selectedDate) {
    this.setState({
      inputEndDate: selectedDate,
    }, () => {
      this.makeDirty();
      if(this.state.errorEndDate) {
        this.validateEndDate();
      }
    });
  }

  validateEndDate() {
    this.setState({ errorEndDate: getFutureDateError(this.state.inputEndDate, true) });
  }

  changeRepeatFrequency(evt) {
    this.setState({
      inputRepeatFrequency: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorRepeatFrequency) {
        this.setState({
          errorRepeatFrequency: getNotEmptyError(this.state.inputRepeatFrequency, ERROR_EVENT_REPEAT_FREQUENCY),
        })
      }
    });
  }

  changeDescription(evt) {
    this.setState({
      inputDescription: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorDescription) {
        this.validateDescription();
      }
    });
  }

  validateDescription() {
    this.setState({ errorDescription: getDescriptionError(this.state.inputDescription, true) });
  }

  getDescritpionLength() {
    return this.state.inputDescription ? this.state.inputDescription.length : 0;
  }

  changeProductLine(evt) {

    if(!this.props.productLine.productLines) {
      return;
    }

    if(evt.target.value === '') {
      this.setState({ inputProductLine: evt.target.value });
      return;
    }

    for(const pl of this.props.productLine.productLines) {
      if(pl.id === parseInt(evt.target.value)) {
        this.setState({ inputProductLine: pl });
        this.makeDirty();
        break;
      }
    }
  }

  getProductLineOptions() {

    const options = [];

    if(getOrderedProductLines(this.props.productLine.productLines).length === 0) {
      options.push({
        display: this.props.t(tx.TX_INV_ADD_NO_PL),
        value: '',
      });
    } else {
      options.push({
        display: this.props.t(tx.TX_PLACEHOLDER_PL_DROPDOWN),
        value: '',
      });
    }

    for(const pl of getOrderedProductLines(this.props.productLine.productLines)) {
      options.push({
        display: pl.name,
        value: pl.id,
      });
    }

    return options;
  }

  changeFormat(evt) {
    this.setState({
      inputFormat: evt.target.value,
    }, () => {
      this.makeDirty();
    });
  }

  getFormatOptions() {

    const options = [
      {
        display: tx.TX_PLACEHOLDER_FORMAT,
        value: '',
      }
    ];

    if(this.state.inputProductLine) {
      for(const format of this.state.inputProductLine.eventFormats) {
        options.push({
          display: format.name,
          value: format.key,
        });
      }
    }

    return options;
  }

  hasFormat() {
    if(this.state.inputProductLine && this.state.inputProductLine.eventFormats && this.state.inputProductLine.eventFormats.length) {
      return true;
    }
    return false;
  }

  changeCost(evt) {
    this.setState({
      inputCost: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorCost) {
        this.validateCost();
      }
    });
  }

  validateCost() {
    this.setState({ 
      inputCost: normalizePrice(this.state.inputCost), 
      errorCost: getCostError(this.state.inputCost, true), 
    });
  }

  changeLimit(evt) {
    this.setState({
      inputLimit: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorLimit) {
        this.validateLimit();
      }
    });
  }

  validateLimit() {
    this.setState({ errorLimit: getQuantityError(this.state.inputLimit, true) });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      // Create new schedule
      const timeHour = this.state.is24HourTime ? this.state.inputTimeHour : this.getDateHour();
      const selectedTime = new Date(this.props.eventObj.startTime.getFullYear(), this.props.eventObj.startTime.getMonth(), this.props.eventObj.startTime.getDate(), timeHour, parseInt(this.state.inputTimeMinutes));

      const newSchedule = new StoreEventSchedule({
        selectedTime: selectedTime,
        name: this.state.inputName,
        description: this.state.inputDescription,
        productLine: this.state.inputProductLine,
        capacity: this.state.inputLimit,
        cost: this.state.inputCost,
        format: this.state.inputFormat,
        endDate: this.state.inputEndDate,
        scheduleType: this.state.inputRepeatFrequency,
      });

      const newEvent = new StoreEvent({
        selectedTime: selectedTime,
        name: this.state.inputName,
        description: this.state.inputDescription,
        productLine: this.state.inputProductLine,
        capacity: this.state.inputLimit,
        cost: this.state.inputCost,
        format: this.state.inputFormat,
        schedule: newSchedule,        
      });

      const createResp = await this.props.eventCreate(newEvent.getApiData())
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!createResp) {
        return null;
      }

      // End existing schedule by updating end date to day before today
      const endDate = dateDelta(this.props.date, -1)
      const updateData = {
        end_date: `${endDate.getFullYear()}-${twoDigitInt(endDate.getMonth() + 1)}-${twoDigitInt(endDate.getDate())}`,
      };
      const updateResp = await this.props.eventUpdateEventSchedule(this.props.eventObj.schedule.publicUuid, updateData)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!updateResp) {
        return null;
      }

      this.setState({
        isDirty: false,
      }, () => {
        this.props.makeClean(createResp.schedule_uuid);
        this.tryToToggle();
      });
    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('details', true);
    }
  }

  validateAll() {
    const errorObj = {
      errorName: getNameError(this.state.inputName),
      errorEndDate: getFutureDateError(this.state.inputEndDate, true),
      errorTime: getTimeError(this.state.is24HourTime ? this.state.inputTimeHour : this.getDateHour(), this.state.inputTimeMinutes),
      errorRepeatFrequency: getNotEmptyError(this.state.inputRepeatFrequency, ERROR_EVENT_REPEAT_FREQUENCY),
      errorDescription: getDescriptionError(this.state.inputDescription, true), 
      errorProductLine: getNotEmptyError(this.state.inputProductLine, ERROR_PRODUCT_LINE_PLEASE_SELECT), 
      errorFormat: this.hasFormat() ? getNotEmptyError(this.state.inputFormat, ERROR_EVENT_FORMAT_MISSING) : '', 
      errorCost: getCostError(this.state.inputCost, true),
      errorLimit: getEventRegistrationLimitError(this.state.inputLimit, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  getRepeatOptions() {
    
    const t =  this.props.t;

    const repeatOptions = [
      {
        display: t(tx.TX_PLACEHOLDER_EVENT_REPEAT_FREQUENCY),
        value: '',
      },
      {
        display: t(tx.TX_CAL_FREQUENCY_DAILY),
        value: EVENT_FREQUENCY_DAILY,
      },
      {
        display: t(tx.TX_CAL_FREQUENCY_WEEKLY),
        value: EVENT_FREQUENCY_WEEKLY,
      },
    ];

    const zonedStartTime = utcToZonedTime(this.props.eventObj.startTime, getStoreTimeZone());

    if(zonedStartTime) {
      const occurance = Month.nthDay(zonedStartTime);
      if(occurance) {
        repeatOptions.push({
          display: t(EVENT_MONTHLY_WEEK_OPTIONS[occurance], { day: t(CAL_DAY_NAMES[zonedStartTime.getDay()]) }),
          value: EVENT_FREQUENCY_MONTHLY_WEEK,
        })
      }
    }
    return repeatOptions;
  }

  render() {

    const {t} = this.props;

    return <div className={'EventScheduleDetailsEdit'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'eventDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_EVENTS_EVENT_DETAILS)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            

            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='given-name'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.state.inputName}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EVENT_NAME)}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>

            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_EVENTS_REPEAT_FREQUENCY)}</div>
              <div className='adminInputWrapper'>
                <div className='adminDropdownWrapper'>
                  <Dropdown 
                    className={'adminDropdownSelect'}
                    options={this.getRepeatOptions()}
                    name={t(tx.TX_EVENTS_REPEAT_FREQUENCY)}
                    error={this.state.errorRepeatFrequency}
                    value={this.state.inputRepeatFrequency}
                    onChange={this.changeRepeatFrequency.bind(this)}
                    required={true}
                    noTranslate={true} />
                </div>
                {this.state.errorRepeatFrequency ?
                  <div className={'adminError FieldError'}>{t(this.state.errorRepeatFrequency)}</div> :
                  null
                }
              </div>
            </div>

            <div className='adminFieldWrapper'>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_TIME)}</div>
                <div className={this.state.errorTime ? 'adminInputTime fieldError' : 'adminInputTime'} onClick={this.checkTimeClick.bind(this)}>
                  <div className='aitLiner'>
                    <div className={'aitHourWrapper aitElement'}>
                      <input
                        type='number'
                        ref={this.hourInput}
                        className={this.state.errorTimeHour ? 'InputError' : ''}
                        value={this.state.inputTimeHour}
                        onChange={this.changeTimeHour.bind(this)}
                        onBlur={this.validateTimeHour.bind(this)}
                        placeholder={t(this.state.is24HourTime ? tx.TX_PLACEHOLDER_TIME_HOUR_24 : tx.TX_PLACEHOLDER_TIME_HOUR)}
                        min={this.state.is24HourTime ? 0 : 1}
                        max={this.state.is24HourTime ? 23 : 12} />
                    </div>
                    <div className={'aitDelimiterWrapper aitElement'}>
                      <div className={'delimWrapper FlexCenter'}>:</div>
                    </div>
                    <div className={'aitMinutesWrapper aitElement'}>
                      <input
                        type='number'
                        className={this.state.errorTimeMinutes ? 'InputError' : ''}
                        value={this.state.inputTimeMinutes}
                        onChange={this.changeTimeMinutes.bind(this)}
                        onBlur={this.validateTimeMinutes.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_TIME_MINUTES)}
                        min={0}
                        max={59} />
                    </div>
                    {!this.state.is24HourTime ?
                      <div className={'aitPostScriptWrapper aitElement'}>
                        <Dropdown 
                          className={'aitPostSelect'}
                          options={this.getTimePostOptions()}
                          name={t(tx.TX_TIME)}
                          value={this.state.inputTimePost}
                          noTranslate={true}
                          onChange={this.changeTimePost.bind(this)} />
                      </div> :
                      null
                    }
                  </div>
                </div>
                {this.state.errorTime ?
                  <div className={'adminError FieldError'}>{t(this.state.errorTime)}</div> :
                  null
                }
              </div>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_EVENTS_END_DATE)}</div>
                <div className='adminInputDate'>
                  <div className={this.state.errorEndDate ? 'datePickerWrapper error' : 'datePickerWrapper'}>
                    <DatePicker 
                      selected={this.state.inputEndDate} 
                      onChange={(date) => this.changeEndDate(date)}
                      onBlur={this.validateEndDate.bind(this)}
                      locale={this.getLocale()}
                      closeOnScroll={true}
                      dateFormat='P'
                      placeholderText={t(tx.TX_PLACEHOLDER_DATE_SELECT)}
                      excludeDateIntervals={[{
                        start: new Date(0), 
                        end: dateDelta(new Date(), -1), 
                      }]} />
                  </div>
                  <div className='calendarOverlay'>
                    <Icon value={ICON_CALENDAR} />
                  </div>
                  {this.state.errorEndDate ?
                    <div className={'adminError FieldError'}>{t(this.state.errorEndDate)}</div> :
                    null
                  }
                </div>
              </div>
            </div>

            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_DESCRIPTION)}</div>
              <div className='adminInputWrapper'>
                <textarea
                  className={this.state.errorDescription ? 'InputError' : ''}
                  value={this.state.inputDescription}
                  onChange={this.changeDescription.bind(this)}
                  onBlur={this.validateDescription.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EVENT_DESCRIPTION)}
                  maxLength={this.descriptionLimit} />
                {this.state.errorDescription ?
                  <div className={'FieldError'}>{t(this.state.errorDescription)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  <div className='productCharacterCount'>
                    <span className='countNum'>{(this.descriptionLimit - this.getDescritpionLength()).toLocaleString(this.getLanguage())}</span>
                    <span className='countSlash'>/</span>
                    <span className='countNum'>{this.descriptionLimit.toLocaleString(this.getLanguage())}</span>
                    <span className='countCopy'>{t(tx.TX_CHARACTERS_REMAINING)}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='adminFieldWrapper'>
              <div className='adminInputWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRODUCT_LINE)}</div>
                <div className='adminInputProductLineWrapper'>
                  {this.props.productLine.productLinesPending ?
                    <div className='aiProductLineLoading'>
                      <div className='aiProductLineLoadingIcon'>
                        <LoadingIcon />
                      </div>
                      <div className='aiProductLoadingLabel'>{t(tx.TX_LOADING)}</div>
                    </div> :
                    <div> 
                      <div className='adminDropdownWrapper'>
                        <Dropdown 
                          className={'adminDropdownSelect'}
                          options={this.getProductLineOptions()}
                          name={t(tx.TX_PRODUCT_LINE)}
                          value={this.state.inputProductLine ? this.state.inputProductLine.id : ''}
                          onChange={this.changeProductLine.bind(this)}
                          required={true}
                          noTranslate={true}
                          disabled={getOrderedProductLines(this.props.productLine.productLines).length === 0} />
                      </div>
                      {this.state.errorProductLine ?
                        <div className={'adminError FieldError'}>{t(this.state.errorProductLine)}</div> :
                        null
                      }
                    </div>
                  }
                </div>
              </div>
            </div>

            {this.hasFormat() ?
              <div className='adminFieldWrapper'>
                <div className={'adminInputWrapper'}>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_EVENTS_FORMAT)}</div>
                  <div className='adminDropdownWrapper'>
                    <Dropdown 
                      className={'adminDropdownSelect'}
                      options={this.getFormatOptions()}
                      name={t(tx.TX_EVENTS_FORMAT)}
                      value={this.state.inputFormat}
                      onChange={this.changeFormat.bind(this)}
                      required={true} />
                  </div>
                  {this.state.errorFormat ?
                    <div className={'adminError FieldError'}>{t(this.state.errorFormat)}</div> :
                    null
                  }
                </div>
              </div> :
              null
            }

            <div className='adminFieldWrapper'> 
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_EVENTS_REGISTRATION_FEE)}</div>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    step={getCurrencyIncrement()}
                    className={this.state.errorCost ? 'InputError currencyInput' : 'currencyInput'}
                    value={this.state.inputCost}
                    onChange={this.changeCost.bind(this)}
                    onBlur={this.validateCost.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorCost ?
                  <div className={'adminError FieldError'}>{t(this.state.errorCost)}</div> :
                  null
                }
              </div>
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_EVENTS_REGISTRATION_LIMIT)}</div>
                <input
                  type='text'
                  className={this.state.errorLimit ? 'registrationInput InputError' : 'registrationInput'}
                  value={this.state.inputLimit}
                  onChange={this.changeLimit.bind(this)}
                  onBlur={this.validateLimit.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_QUANTITY)}
                  maxLength={100} />
                {this.state.errorLimit ?
                  <div className={'adminError FieldError'}>{t(this.state.errorLimit)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventScheduleDetailsEdit));