

import * as tx from './strings';

export const PRT_KEY_UNLIMITED = 'unlimited';
export const PRT_KEY_1ST_EDITION = '1st';


export const PRINTING_UNLIMITED = {
	key: PRT_KEY_UNLIMITED,
	name: tx.TX_PRINTING_UNLIMITED,
	description: '',
	exportName: 'Unlimited',
}

export const PRINTING_1ST_EDITION = {
	key: PRT_KEY_1ST_EDITION,
	name: tx.TX_PRINTING_1ST_EDITION,
	description: '',
	exportName: '1st Edition',
}


export const PRINTINGS_ALL = [ 
	PRINTING_UNLIMITED, 
	PRINTING_1ST_EDITION, 
];

export const PRINTINGS_POKEMON = [ 
	PRINTING_UNLIMITED,
	PRINTING_1ST_EDITION, 
];