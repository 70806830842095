import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { getMyId } from '../../utils/auth';
import { formatServerError } from '../../utils/formatting';
import { hasPermission } from '../../utils/permissions';

import { P_MANAGE_PERMISSIONS, P_MANAGE_SALES } from '../../constants/permissions';
import * as tx from '../../constants/strings';
import {
  URL_ADMIN_CUSTOMERS, 
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import Confirm from '../Popups/Confirm';
import CustomerDetailsEdit from './blocks/CustomerDetailsEdit';
import CustomerDetailsView from './blocks/CustomerDetailsView';
import CustomerStoreCreditAdd from './blocks/CustomerStoreCreditAdd';
import CustomerStoreCreditView from './blocks/CustomerStoreCreditView';
import CustomerOrdersView from './blocks/CustomerOrdersView';
import CustomerPermissionsEdit from './blocks/CustomerPermissionsEdit';
import CustomerPermissionsView from './blocks/CustomerPermissionsView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_viewcustomer.scss';

import * as commonActionCreators from '../../actions/common';
import * as customerActionCreators from '../../actions/customer';
const allActionCreators = Object.assign({}, commonActionCreators, customerActionCreators);

export class ViewCustomer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirtyDetails: false,
      isDirtyStoreCredit: false,
      isDirtyPermissions: false,

      savePromptOpen: false,
      lastBlock: '',

      editDetails: false,
      editPermissions: false,
      editStoreCredit: false,

      permissionsExpanded: false,
      storeCreditExpanded: false,
      ordersExpanded: false,

      requestPending: true,
      requestError: null,
      responseObject: null,

      savingAll: false,
      remoteSaveDetails: false,
      remoteSaveStoreCredit: false,
      remoteSavePermissions: false,

      lockConfirmOpen: false,
    };

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyDetails === false && this.state.isDirtyPermissions === false && this.state.isDirtyStoreCredit === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchCustomer();
  }

  componentWillUnmount() {
    this.unblock();

    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyDetails === false && this.state.isDirtyPermissions === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchCustomer();
      }
    }
  }

  fetchCustomer(service = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      responseObject: null,
    });

    // Get customer details
    if(this.props.match.params.customerUUID) {
      this.props.customerFetchSingle(this.props.match.params.customerUUID, controller.signal)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
          responseObject: resp,
        });
        if(service === 'details') {
          this.setState({ isDirtyDetails: false });
        } else if(service === 'permissions') {
          this.setState({ isDirtyPermissions: false });
        } else if(service === 'store-credit') {
          this.setState({ isDirtyStoreCredit: false });
        }
      })
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responseObject: null,
        });
      });
    }
  }

  makeDetailsDirty() {
    if(this.state.isDirtyDetails === false) {
      this.setState({ isDirtyDetails: true });
    }
  }

  makePermissionsDirty() {
    if(this.state.isDirtyPermissions === false) {
      this.setState({ isDirtyPermissions: true });
    }
  }

  makeStoreCreditDirty() {
    if(this.state.isDirtyStoreCredit === false) {
      this.setState({ isDirtyStoreCredit: true });
    }
  }

  toggleDetails() {
    this.setState({ editDetails: !this.state.editDetails }, () => {
      if(this.state.editDetails === false) {
        this.setState({ isDirtyDetails: false, });
      }
    });
  }

  togglePermissions() {
    this.setState({ editPermissions: !this.state.editPermissions }, () => {
      if(this.state.editPermissions === false) {
        this.setState({ isDirtyPermissions: false, });
      }
    });
  }

  toggleStoreCredit() {
    this.setState({ editStoreCredit: !this.state.editStoreCredit }, () => {
      if(this.state.editStoreCredit === false) {
        this.setState({ isDirtyStoreCredit: false, });
      }
    });
  }

  togglePermissionsExpanded() {
    this.setState({ permissionsExpanded: !this.state.permissionsExpanded });
  }

  toggleOrdersExpanded() {
    this.setState({ ordersExpanded: !this.state.ordersExpanded });
  }

  toggleStoreCreditExpanded() {
    this.setState({ storeCreditExpanded: !this.state.storeCreditExpanded });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      savingAll: true, 
      remoteSaveDetails: this.state.isDirtyDetails,
      remoteSavePermissions: this.state.isDirtyPermissions,
    });
  }

  finishRemote(service, didFail = false) {

    if(service === 'details') {
      this.setState({ 
        isDirtyDetails: didFail !== false, 
        remoteSaveDetails: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true || this.state.remoteSavePermissions === true || this.state.remoteSaveStoreCredit === true });
      });
    }

    if(service === 'permissions') {
      this.setState({ 
        isDirtyPermissions: didFail !== false, 
        remoteSavePermissions: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true || this.state.remoteSavePermissions === true || this.state.remoteSaveStoreCredit === true });
      });
    }

    if(service === 'store-credit') {
      this.setState({ 
        isDirtyStoreCredit: didFail !== false, 
        remoteSaveStoreCredit: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true || this.state.remoteSavePermissions === true || this.state.remoteSaveStoreCredit === true });
      });
    }
  }

  isThisMe() {
    return getMyId() && this.state.responseObject && this.state.responseObject.id && getMyId() === this.state.responseObject.id;
  }

  toggleLockConfirm() {
    this.setState({ lockConfirmOpen: !this.state.lockConfirmOpen });
  }

  lockButtonAction(evt) {
    if(evt) { evt.preventDefault(); }

    if(!this.state.responseObject) {
      return null;
    }

    if(this.isThisMe()) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_NOT_ALLOWED, 
        alertCopy: tx.TX_CUSTOMER_CANNOT_LOCK_SELF, 
      });
      return null;
    }

    console.log(this.state.responseObject);

    if(this.state.responseObject.isActive) {
      this.toggleLockConfirm();
    } else {
      this.unlockAccount();
    }
  }

  lockAccount() {
    this.props.customerAdminLockAccount(false, this.props.match.params.customerUUID)
    .then((resp) => {
      this.fetchCustomer();
    })
    .catch((errResp) => {
      console.error(errResp);
    });
  }

  unlockAccount() {
    this.props.customerAdminLockAccount(true, this.props.match.params.customerUUID)
    .then((resp) => {
      this.props.commonAlert({ 
        alertTitle: tx.TX_CUSTOMER_UNLOCK_TITLE, 
        alertCopy: tx.TX_CUSTOMER_UNLOCK_COPY, 
      });
      this.fetchCustomer();
    })
    .catch((errResp) => {
      console.error(errResp);
    });
  }

  lockButtonCopy() {
    if(this.state.responseObject && this.state.responseObject.isActive === false) {
      return tx.TX_CUSTOMER_UNLOCK_ACCOUNT;
    }
    return tx.TX_CUSTOMER_LOCK_ACCOUNT;
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewCustomer AdminPage'}>
      <AdminTitle
        title={tx.TX_CUSTOMER_VIEW_CUSTOMER}
        breadcrumbs={[
          {
            url: URL_ADMIN_CUSTOMERS,
            title: tx.TX_CUSTOMERS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>
          {this.state.editDetails ? 
            <CustomerDetailsEdit
              customer={this.state.responseObject}
              customerPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)}
              makeDirty={this.makeDetailsDirty.bind(this)}
              makeClean={this.fetchCustomer.bind(this)} 
              remoteSave={this.state.remoteSaveDetails}
              finishRemote={this.finishRemote.bind(this)} /> :
            <CustomerDetailsView
              customer={this.state.responseObject}
              customerPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)} />
          }
          {hasPermission(P_MANAGE_SALES) ?
            <div>
              {this.state.editStoreCredit ? 
                <CustomerStoreCreditAdd
                  customer={this.state.responseObject}
                  customerPending={this.state.requestPending}
                  toggleEdit={this.toggleStoreCredit.bind(this)}
                  expanded={this.state.storeCreditExpanded}
                  toggleExpanded={this.toggleStoreCreditExpanded.bind(this)}
                  makeDirty={this.makeStoreCreditDirty.bind(this)}
                  makeClean={this.fetchCustomer.bind(this)} 
                  remoteSave={this.state.remoteSaveStoreCredit}
                  finishRemote={this.finishRemote.bind(this)} /> :
                <CustomerStoreCreditView
                  customer={this.state.responseObject}
                  customerPending={this.state.requestPending}
                  expanded={this.state.storeCreditExpanded}
                  toggleEdit={this.toggleStoreCredit.bind(this)}
                  toggleExpanded={this.toggleStoreCreditExpanded.bind(this)} />
              }
            </div> :
            null
          }
          {hasPermission(P_MANAGE_SALES) ? 
            <div>
              <CustomerOrdersView
                customer={this.state.responseObject}
                customerPending={this.state.requestPending}
                expanded={this.state.ordersExpanded}
                toggleExpanded={this.toggleOrdersExpanded.bind(this)} />
            </div> :
            null
          }
          {hasPermission(P_MANAGE_PERMISSIONS) ?
            <div>
              {this.state.editPermissions ?
                <CustomerPermissionsEdit
                  customer={this.state.responseObject}
                  customerPending={this.state.requestPending}
                  makeDirty={this.makePermissionsDirty.bind(this)}
                  makeClean={this.fetchCustomer.bind(this)} 
                  remoteSave={this.state.remoteSavePermissions}
                  finishRemote={this.finishRemote.bind(this)} 
                  toggleEdit={this.togglePermissions.bind(this)}
                  expanded={this.state.permissionsExpanded}
                  toggleExpanded={this.togglePermissionsExpanded.bind(this)} /> : 
                <CustomerPermissionsView
                  customer={this.state.responseObject}
                  customerPending={this.state.requestPending}
                  toggleEdit={this.togglePermissions.bind(this)}
                  expanded={this.state.permissionsExpanded}
                  toggleExpanded={this.togglePermissionsExpanded.bind(this)} />
              }
            </div> :
            null
          }
        </div>
      </div>
      <div className='adminActions'>
        <button className='blockButton' onClick={this.lockButtonAction.bind(this)}>{t(this.lockButtonCopy())}</button>
        <Link to={URL_ADMIN_CUSTOMERS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <Confirm
        title={tx.TX_CUSTOMER_LOCK_ACCOUNT_QUESTION}
        copy={tx.TX_CUSTOMER_LOCK_ACCOUNT_WARNING}
        open={this.state.lockConfirmOpen}
        confirmValue={tx.TX_CUSTOMER_LOCK_ACCOUNT}
        closeMethod={this.toggleLockConfirm.bind(this)}
        onConfirm={this.lockAccount.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewCustomer));