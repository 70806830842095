import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { GV_HEADER_SORT_DIR_ASC, GV_HEADER_SORT_DIR_DESC } from '../../../constants/gallery';
import * as tx from '../../../constants/strings';

import { 
  getGallerySortDisplay, 
  getGallerySortOptions, 
  getGallerySortValue, 
} from '../../../utils/gallery';
import { getStoreLanguage } from '../../../utils/language';


let allActionCreators = Object.assign({});

export class GalleryHeader extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  changeSortValue(evt) {
    this.props.changeSort(evt.target.value, this.props.sortDir);
  }

  changeSortDirection() {
    if(!this.props.sortKey) {
      return false;
    }
    this.props.changeSort(this.props.sortKey, this.props.sortDir === GV_HEADER_SORT_DIR_ASC ? GV_HEADER_SORT_DIR_DESC : GV_HEADER_SORT_DIR_ASC);
  }

  render() {

    const {t} = this.props;

    return <div className={'GalleryHeader'}>
      <div className='ghSortWrapper'>
        <div className='ghSort'>
          <div className='sortLabel'>{t(tx.TX_GALLERY_SORTED_BY)}</div>
          <div className='sortDropdownWrapper'>
            <select 
              value={this.props.sortKey} 
              onChange={this.changeSortValue.bind(this)}>
              {getGallerySortOptions(this.props.config).map((sortObj, i) => {
                return <option key={i} value={getGallerySortValue(sortObj)}>{t(getGallerySortDisplay(sortObj))}</option>;
              })}
            </select>
          </div>
          <div className='sortVr'></div>
          <div className='sortArrowWrapper' onClick={this.changeSortDirection.bind(this)}>
            <div className={this.props.sortKey ? 'sortArrow' : 'sortArrow disabled'}>
              <div className={this.props.sortDir === GV_HEADER_SORT_DIR_ASC && this.props.sortKey !== '' ? 'arrowWrapper upArrow' : 'arrowWrapper'}></div>
            </div>
          </div>
        </div>
      </div>
      <div className='ghCountWrapper'>
        {this.props.dataLoading === true ?
          <div className='ghCount'>
            <div className='ghCountText'>&nbsp;</div>
          </div> :
          <div className='ghCount'>
            {this.props.dataCount === 0 ?
              <div className='ghCountText'>{t(tx.TX_GALLERY_NO_RESULTS)}</div> :
              <div className='ghCountText'>
                <div className='ghCountNumber'>{this.props.dataCount ? this.props.dataCount.toLocaleString(this.getLanguage()) : ''}</div>
                <div className='ghCountLabel'>&nbsp;{t(this.props.dataCount === 1 ? tx.TX_RESULT : tx.TX_RESULTS).toLowerCase()}</div>
              </div>
            }
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(GalleryHeader));