


// Cart endpoints

export const ENDPOINT_CART_ADD = `/api/carts/{{uuid}}`;
export const ENDPOINT_CART_ADMIN_FETCH = `/api/carts/{{publicUuid}}/manage`;
export const ENDPOINT_CART_ADMIN_FETCH_BY_USER = `/api/carts/user/{{publicUuid}}`;
export const ENDPOINT_CART_ADMIN_FETCH_LIST = `/api/carts/list`;
export const ENDPOINT_CART_ADMIN_UPDATE = `/api/carts/{{publicUuid}}/manage`;
export const ENDPOINT_CART_BUYLIST_ADMIN_FETCH = `/api/carts/buylist/{{publicUuid}}/manage`;
export const ENDPOINT_CART_BUYLIST_ADMIN_FETCH_BY_USER = `/api/carts/user/{{publicUuid}}/buylist`;
export const ENDPOINT_CART_BUYLIST_ADMIN_UPDATE = `/api/carts/buylist/{{publicUuid}}/manage`;
export const ENDPOINT_CART_BUYLIST_ADD = `/api/carts/buylist/{{uuid}}`;
export const ENDPOINT_CART_BUYLIST_CLEAR = `/api/carts/buylist/{{uuid}}/clear`;
export const ENDPOINT_CART_BUYLIST_CREATE = `/api/carts/buylist`;
export const ENDPOINT_CART_BUYLIST_ITEM_DELETE = `/api/carts/buylist/{{uuid}}/item/{{itemId}}`;
export const ENDPOINT_CART_BUYLIST_ITEM_UPDATE = `/api/carts/buylist/{{uuid}}/item/{{itemId}}`;
export const ENDPOINT_CART_CLEAR = `/api/carts/{{uuid}}/clear`;
export const ENDPOINT_CART_CREATE = `/api/carts/`;
export const ENDPOINT_CART_COUPON_APPLY = `/api/carts/{{uuid}}/coupon/{{code}}`;
export const ENDPOINT_CART_COUPON_REMOVE = `/api/carts/{{uuid}}/coupon/{{code}}`;
export const ENDPOINT_CART_FETCH = `/api/carts/`;
export const ENDPOINT_CART_ITEM_DELETE = `/api/carts/{{uuid}}/item/{{itemId}}`;
export const ENDPOINT_CART_ITEM_UPDATE = `/api/carts/{{uuid}}/item/{{itemId}}`;


// Checkout endpoints

export const ENDPOINT_CHECKOUT_ADD_PAYMENT_METHOD = `/api/checkout/manage/payment-methods`;
export const ENDPOINT_CHECKOUT_ADD_SHIPPING_METHOD = `/api/checkout/manage/shipping-methods`;
export const ENDPOINT_CHECKOUT_DELETE_PAYMENT_METHOD = `/api/checkout/manage/payment-methods/{{uuid}}`;
export const ENDPOINT_CHECKOUT_DELETE_SHIPPING_METHOD = `/api/checkout/manage/shipping-methods/{{uuid}}`;
export const ENDPOINT_CHECKOUT_FETCH_ADMIN_PAYMENT_METHODS = `/api/checkout/manage/payment-methods`;
export const ENDPOINT_CHECKOUT_FETCH_BUYLIST_PAYMENT_METHODS = `/api/checkout/buylist/payment-methods`;
export const ENDPOINT_CHECKOUT_FETCH_PAYMENT_METHODS = `/api/checkout/payment-methods`;
export const ENDPOINT_CHECKOUT_FETCH_SINGLE_PAYMENT_METHOD = `/api/checkout/manage/payment-methods/{{uuid}}`;
export const ENDPOINT_CHECKOUT_FETCH_SINGLE_SHIPPING_METHOD = `/api/checkout/manage/shipping-methods/{{uuid}}`;
export const ENDPOINT_CHECKOUT_GET_ADMIN_SHIPPING_METHODS = `/api/checkout/manage/shipping-methods`;
export const ENDPOINT_CHECKOUT_GET_SHIPPING_METHODS = `/api/checkout/shipping-methods`;
export const ENDPOINT_CHECKOUT_UPDATE_PAYMENT_METHOD = `/api/checkout/manage/payment-methods/{{uuid}}`;
export const ENDPOINT_CHECKOUT_UPDATE_SHIPPING_METHOD = `/api/checkout/manage/shipping-methods/{{uuid}}`;


// Event endpoints

export const ENDPOINT_EVENT_ADMIN_CREATE = `/api/events/manage`;
export const ENDPOINT_EVENT_ADMIN_DELETE = `/api/events/manage/events/{{uuid}}`;
export const ENDPOINT_EVENT_ADMIN_FETCH = `/api/events/manage/events/{{uuid}}`;
export const ENDPOINT_EVENT_ADMIN_FETCH_MONTH = `/api/events/manage`;
export const ENDPOINT_EVENT_ADMIN_UPDATE = `/api/events/manage/events/{{uuid}}`;
export const ENDPOINT_EVENT_SCHEDULE_ADMIN_DELETE = `/api/events/manage/schedule/{{uuid}}`;
export const ENDPOINT_EVENT_SCHEDULE_ADMIN_FETCH = `/api/events/manage/schedule/{{uuid}}`;
export const ENDPOINT_EVENT_SCHEDULE_ADMIN_UPDATE = `/api/events/manage/schedule/{{uuid}}`;
export const ENDPOINT_EVENT_SCHEDULE_CREATE_EXCEPTION = `/api/events/manage/schedule/{{uuid}}/exception`;
export const ENDPOINT_EVENT_UPCOMING = `/api/events/upcoming`;


// Lorcana endpoints

export const ENDPOINT_LORCANA_NAME_AUTOCOMPLETE = `/api/lorcana/autocomplete/name`;
export const ENDPOINT_LORCANA_NAME_SEARCH = `/api/lorcana/search/name`;
export const ENDPOINT_LORCANA_SETS = `/api/lorcana/sets`;


// Magic endpoints

export const ENDPOINT_MAGIC_NAME_AUTOCOMPLETE = `/api/magic-card/autocomplete/name`;
export const ENDPOINT_MAGIC_NAME_SEARCH = `/api/magic-card/search/name`;
export const ENDPOINT_MAGIC_SETS = `/api/magic-card/sets`;


// Payment endpoints

export const ENDPOINT_PAYMENT_ADD_BUYLIST = `/api/payments/{{paymentMethodUuid}}/buylist/add`;
export const ENDPOINT_PAYMENT_ADD_EXTERNAL = `/api/payments/{{uuid}}/external/add`;
export const ENDPOINT_PAYMENT_REFUND_PAYMENT = `/api/payments/manage/refund/{{publicUuid}}`;
export const ENDPOINT_PAYMENT_SQUARE_AUTHORIZE = `/api/payments/{{uuid}}/square/authorize`;
export const ENDPOINT_PAYMENT_SQUARE_MAKE_PAYMENT = `/api/payments/make-payment`;
export const ENDPOINT_PAYMENT_SQUARE_REFRESH = `/api/payments/{{uuid}}/square/refresh`;
export const ENDPOINT_PAYMENT_SQUARE_REVOKE = `/api/payments/{{uuid}}/square/revoke`;
export const ENDPOINT_PAYMENT_TRANSBANK_INITIATE_PAYMENT = `/api/payments/make-payment`;
export const ENDPOINT_PAYMENT_TRANSBANK_CONFIRM_PAYMENT = `/api/payments/{{uuid}}/transbank/confirm-payment`;


// Pokemon endpoints

export const ENDPOINT_POKEMON_NAME_AUTOCOMPLETE = `/api/pokemon-card/autocomplete/name`;
export const ENDPOINT_POKEMON_NAME_SEARCH = `/api/pokemon-card/search/name`;
export const ENDPOINT_POKEMON_SETS = `/api/pokemon-card/sets`;


// Product line endpoints

export const ENDPOINT_PL_ADD = `/api/product-lines/`;
export const ENDPOINT_PL_DELETE = `/api/product-lines/{{id}}`;
export const ENDPOINT_PL_EDIT = `/api/product-lines/{{id}}`;
export const ENDPOINT_PL_ENABLE_PRICING = `/api/product-lines/{{permalink}}/pricing/enable`;
export const ENDPOINT_PL_GET_ALL = `/api/product-lines/`;
export const ENDPOINT_PL_GET_ENABLED = `/api/product-lines/enabled`;
export const ENDPOINT_PL_GET_MANAGED = `/api/product-lines/managed`;
export const ENDPOINT_PL_GET_SINGLE = `/api/product-lines/permalink/{{permalink}}`;
export const ENDPOINT_PL_GET_MENUS = `/api/product-lines/menu`;
export const ENDPOINT_PL_PRICING_MATRIX_ADD = `/api/product-lines/{{permalink}}/pricing`;
export const ENDPOINT_PL_PRICING_MATRIX_RULE_ADD = `/api/product-lines/{{permalink}}/pricing/{{publicUuid}}/rule`;
export const ENDPOINT_PL_PRICING_MATRIX_RULE_DELETE = `/api/product-lines/{{permalink}}/pricing/rule/{{publicUuid}}`;
export const ENDPOINT_PL_PRICING_MATRIX_RULE_UPDATE = `/api/product-lines/{{permalink}}/pricing/rule/{{publicUuid}}`;
export const ENDPOINT_PL_SET_PRICING = `/api/product-lines/{{permalink}}/pricing`;
export const ENDPOINT_PL_UPDATE_DEFAULTS = `/api/product-lines/{{id}}/defaults`;


// Product (admin) endpoints

export const ENDPOINT_PRODUCT_ADMIN_ADD = `/api/products/manage`;
export const ENDPOINT_PRODUCT_ADMIN_ATTRIBUTE_CREATE = `/api/products/{{productLinePermalink}}/{{productPermalink}}/attributes`;
export const ENDPOINT_PRODUCT_ADMIN_ATTRIBUTE_DELETE = `/api/products/{{productLinePermalink}}/{{productPermalink}}/attributes/{{attributeUuid}}`;
export const ENDPOINT_PRODUCT_ADMIN_ATTRIBUTE_UPDATE = `/api/products/{{productLinePermalink}}/{{productPermalink}}/attributes/{{attributeUuid}}`;
export const ENDPOINT_PRODUCT_ADMIN_EDIT = `/api/products/{{pl_permalink}}/{{permalink}}/manage`;
export const ENDPOINT_PRODUCT_ADMIN_DELETE = `/api/products/{{pl_permalink}}/{{permalink}}/manage`;
export const ENDPOINT_PRODUCT_ADMIN_INIT_ASYNC_BULK_UPDATE = `/api/products/manage/inventory/bulk-upload`;
export const ENDPOINT_PRODUCT_ADMIN_INVENTORY_ADD = `/api/products/manage/inventory`;
export const ENDPOINT_PRODUCT_ADMIN_INVENTORY_VARIANT_ADD = `/api/products/{{pl_permalink}}/{{permalink}}/manage/variants`;
export const ENDPOINT_PRODUCT_ADMIN_FETCH_BULK_UPLOAD = `/api/products/manage/inventory/bulk-upload/{{uuid}}`;
export const ENDPOINT_PRODUCT_ADMIN_GET_BULK_UPLOAD_DOWNLOAD_ERRORS = `/api/products/manage/inventory/bulk-upload/{{uuid}}/errors`;
export const ENDPOINT_PRODUCT_ADMIN_GET_BULK_UPLOAD_PAGE = `/api/products/manage/inventory/bulk-upload`;
export const ENDPOINT_PRODUCT_ADMIN_GET_FOREIGN_VARIANTS = `/api/products/{{pl_permalink}}/{{permalink}}/manage/variants`;
export const ENDPOINT_PRODUCT_ADMIN_GET_PAGE = `/api/products/manage`;
export const ENDPOINT_PRODUCT_ADMIN_GET_PAGE_DEEP = `/api/products/manage/export`;
export const ENDPOINT_PRODUCT_ADMIN_GET_SINGLE = `/api/products/{{pl_permalink}}/{{permalink}}/manage`;
export const ENDPOINT_PRODUCT_ADMIN_GET_SINGLE_BY_SKU = `/api/products/manage/{{sku}}`;
export const ENDPOINT_PRODUCT_ADMIN_INVENTORY_FETCH = `/api/products/{{pl_permalink}}/{{permalink}}/inventory/{{id}}`;
export const ENDPOINT_PRODUCT_ADMIN_INVENTORY_EDIT = `/api/products/{{pl_permalink}}/{{permalink}}/inventory/{{id}}`;
export const ENDPOINT_PRODUCT_ADMIN_MEDIA_GENERATE = `/api/products/{{pl_permalink}}/{{permalink}}/media/generate/{{name}}`;
export const ENDPOINT_PRODUCT_ADMIN_MEDIA_UPSERT = `/api/products/{{pl_permalink}}/{{permalink}}/media`;
export const ENDPOINT_PRODUCT_ADMIN_SET_CREATE = `/api/products/sets/{{productLinePermalink}}`;
export const ENDPOINT_PRODUCT_ADMIN_SET_UPDATE = `/api/products/sets/{{productLinePermalink}}/{{setUuid}}`;
export const ENDPOINT_PRODUCT_ADMIN_SET_DELETE = `/api/products/sets/{{productLinePermalink}}/{{setUuid}}`;
export const ENDPOINT_PRODUCT_ADMIN_TAG_CREATE = `/api/products/{{productLinePermalink}}/{{productPermalink}}/tags`;
export const ENDPOINT_PRODUCT_ADMIN_TAG_DELETE = `/api/products/{{productLinePermalink}}/{{productPermalink}}/tags/{{tagUuid}}`;
export const ENDPOINT_PRODUCT_ADMIN_TAG_UPDATE = `/api/products/{{productLinePermalink}}/{{productPermalink}}/tags/{{tagUuid}}`;


// Product (sell) endpoints
export const ENDPOINT_PRODUCT_SELL_FEATURED_BUYLIST = `/api/products/sell/featured-buylist`;


// Product (shop) endpoints

export const ENDPOINT_PRODUCT_SHOP_FETCH_BY_SKUS = `/api/products/shop/skus`;
export const ENDPOINT_PRODUCT_SHOP_FETCH_SETS = `/api/products/sets/{{productLinePermalink}}`;
export const ENDPOINT_PRODUCT_SHOP_GET_SINGLE = `/api/products/shop/{{pl_permalink}}/{{permalink}}`;
export const ENDPOINT_PRODUCT_SHOP_SEARCH_INVENTORY = `/api/products/search/inventory`;


// Product endpoints

export const ENDPOINT_PRODUCT_AUTOCOMPLETE = `/api/products/search/autocomplete`;


// Promotion endpoints

export const ENDPOINT_PROMOTIONS_ADMIN_ADD_COUPON = `/api/promotions/manage/coupons`;
export const ENDPOINT_PROMOTIONS_ADMIN_COUPON_AVAILABLE = `/api/promotions/manage/coupons/{{code}}/available`;
export const ENDPOINT_PROMOTIONS_ADMIN_DELETE_COUPON = `/api/promotions/manage/coupons/{{code}}`;
export const ENDPOINT_PROMOTIONS_ADMIN_EDIT_COUPON = `/api/promotions/manage/coupons/{{code}}`;
export const ENDPOINT_PROMOTIONS_ADMIN_FETCH_COUPON_LIST = `/api/promotions/manage/coupons`;
export const ENDPOINT_PROMOTIONS_ADMIN_FETCH_COUPON_SINGLE = `/api/promotions/manage/coupons/{{code}}`;


// Order endpoints

export const ENDPOINT_ORDERS_ADMIN_ADD_PICKUP = `/api/orders/manage/{{publicUuid}}/pickups`;
export const ENDPOINT_ORDERS_ADMIN_ADD_SHIPMENT = `/api/orders/manage/{{publicUuid}}/shipments`;
export const ENDPOINT_ORDERS_ADMIN_CANCEL_SHIPMENT = `/api/orders/manage/{{publicUuid}}/shipments/{{shipmentUuid}}/cancel`;
export const ENDPOINT_ORDERS_ADMIN_CANCEL_ORDER = `/api/orders/manage/{{orderUuid}}/cancel`;
export const ENDPOINT_ORDERS_ADMIN_CLOSE_PICKUP = `/api/orders/manage/{{publicUuid}}/pickups/{{pickupUuid}}/close`;
export const ENDPOINT_ORDERS_ADMIN_COMPLETE_GRADING = `/api/orders/manage/{{orderUuid}}/grading/{{gradingUuid}}/complete`
export const ENDPOINT_ORDERS_ADMIN_CREATE_GRADING = `/api/orders/manage/{{orderUuid}}/grading`;
export const ENDPOINT_ORDERS_ADMIN_CREATE_ITEM_GRADE = `/api/orders/manage/{{orderUuid}}/grading/{{gradingUuid}}/item`;
export const ENDPOINT_ORDERS_ADMIN_FETCH_GRADING = `/api/orders/manage/{{orderUuid}}/grading/{{gradingUuid}}`;
export const ENDPOINT_ORDERS_ADMIN_FETCH_LIST = `/api/orders/manage`;
export const ENDPOINT_ORDERS_ADMIN_FETCH_ORDER = `/api/orders/manage/{{publicUuid}}`;
export const ENDPOINT_ORDERS_ADMIN_PLACE_BUYLIST_ORDER = `/api/orders/manage/place-order/buylist`;
export const ENDPOINT_ORDERS_ADMIN_RECORD_GOODS_RECEIPT = `/api/orders/manage/{{publicUuid}}/goods-received`;
export const ENDPOINT_ORDERS_ADMIN_SEND_MESSAGE = `/api/orders/manage/{{publicUuid}}/message`;
export const ENDPOINT_ORDERS_ADMIN_UPDATE_ITEM_GRADE = `/api/orders/manage/{{orderUuid}}/grading/{{gradingUuid}}/item/{{itemGradeUuid}}`;
export const ENDPOINT_ORDERS_ADMIN_UPDATE_ORDER = `/api/orders/manage/{{publicUuid}}`;
export const ENDPOINT_ORDERS_ANALYTICS_ORDERS_TOTAL = `/api/orders/analytics/count`;
export const ENDPOINT_ORDERS_ANALYTICS_ORDERS_BY_STATUS = `/api/orders/analytics/totals/status`;
export const ENDPOINT_ORDERS_ANALYTICS_SALES_BY_DATE = `/api/orders/analytics/sales`;
export const ENDPOINT_ORDERS_ANALYTICS_TOP_LIST = `/api/orders/analytics/top/{{aggregationType}}`;
export const ENDPOINT_ORDERS_FETCH_LIST = `/api/orders`;
export const ENDPOINT_ORDERS_FETCH_ORDER = `/api/orders/{{publicUuid}}`;
export const ENDPOINT_ORDERS_PLACE_ORDER = `/api/orders/place-order`;
export const ENDPOINT_ORDERS_PLACE_BUYLIST_ORDER = `/api/orders/place-order/buylist`;


// Search endpoints

export const ENDPOINT_SEARCH_PRODUCT_INVENTORY = `/api/products/search`;


// Star Wars endpoints

export const ENDPOINT_STARWARS_NAME_AUTOCOMPLETE = `/api/star-wars/autocomplete/name`;
export const ENDPOINT_STARWARS_NAME_SEARCH = `/api/star-wars/search/name`;
export const ENDPOINT_STARWARS_SETS = `/api/star-wars/sets`;


// Store endpoints

export const ENDPOINT_STORE_FETCH = `/api/stores/me`;
export const ENDPOINT_STORE_ADMIN_ADD_INTEGRATION = `/api/stores/integrations`;
export const ENDPOINT_STORE_ADMIN_FETCH_INTEGRATION_PROVIDER_SINGLE = `/api/stores/integrations/providers/{{key}}`;
export const ENDPOINT_STORE_ADMIN_FETCH_INTEGRATION_PROVIDERS = `/api/stores/integrations/providers`;
export const ENDPOINT_STORE_ADMIN_INTEGRATION_OAUTH_AUTHORIZE = `/api/stores/integrations/oauth/{{publicUuid}}/authorize`;
export const ENDPOINT_STORE_ADMIN_INTEGRATION_OAUTH_REVOKE = `/api/stores/integrations/oauth/{{publicUuid}}/revoke`;
export const ENDPOINT_STORE_ADMIN_UPDATE_INTEGRATION = `/api/stores/integrations/{{publicUuid}}`;


// Integration (stamps) endpoints

export const ENDPOINT_STAMPS_CREATE_LABEL = `/api/stores/stamps/{{publicUuid}}/label`;
export const ENDPOINT_STAMPS_FETCH_ADDRESS_VARIANTS = `/api/stores/stamps/address/{{shippingMethodUuid}}/validate/{{addressUuid}}`;
export const ENDPOINT_STAMPS_FETCH_LABEL = `/api/stores/stamps/{{publicUuid}}/label/{{labelUuid}}`;
export const ENDPOINT_STAMPS_FETCH_PARCELGUARD_MAKE_CLAIM = `/api/stores/stamps/parcelguard/{{orderUuid}}/claim/{{labelUuid}}`;
export const ENDPOINT_STAMPS_FETCH_PARCELGUARD_STATUS = `/api/stores/stamps/parcelguard/{{integrationUuid}}/status`;
export const ENDPOINT_STAMPS_FETCH_PARCELGUARD_VIEW_CLAIM = `/api/stores/stamps/parcelguard/{{orderUuid}}/claim/{{labelUuid}}`;
export const ENDPOINT_STAMPS_FETCH_TEST_LABEL = `/api/stores/stamps/test/{{integrationUuid}}/label`;
export const ENDPOINT_STAMPS_FETCH_RATES = `/api/stores/stamps/{{publicUuid}}/rates`;


// User endpoints

export const ENDPOINT_USER_ADDRESS_TAX_RATES = `/api/users/address/tax-rate/{{publicUuid}}`;
export const ENDPOINT_USER_CHANGE = `/api/users/password/change`;
export const ENDPOINT_USER_CREATE_ADDRESS = `/api/users/address`;
export const ENDPOINT_USER_EMAIL_AVAILABLE = `/api/users/email/available`;
export const ENDPOINT_USER_FETCH_ADDRESSES = `/api/users/address`;
export const ENDPOINT_USER_FORGOT = `/api/users/password/forgot`;
export const ENDPOINT_USER_GUEST_CREATE = `/api/users/guest`;
export const ENDPOINT_USER_LOGIN = `/api/users/login`; 
export const ENDPOINT_USER_LOGOUT = `/api/users/logout`;
export const ENDPOINT_USER_ME = `/api/users/me`;
export const ENDPOINT_USER_REAUTH = `/api/users/reauth`; 
export const ENDPOINT_USER_RESET = `/api/users/password/reset`;
export const ENDPOINT_USER_REGISTER = `/api/users/register`;
export const ENDPOINT_USER_SEARCH = `/api/users/manage/search`;
export const ENDPOINT_USER_STORE_CREDIT_HISTORY = `/api/users/store-credit`;
export const ENDPOINT_USER_TOKEN_REFRESH = `/api/users/token/refresh`;
export const ENDPOINT_USER_UPDATE_ADDRESS = `/api/users/address/{{public_uuid}}`;
export const ENDPOINT_USER_UPDATE_ME = `/api/users/me`;
export const ENDPOINT_USER_VALIDATE = `/api/users/validate/{{token}}`;  // Not used yet


// User admin endpoints

export const ENDPOINT_USER_ADMIN_GET_BULK_UPLOAD = `/api/users/bulk-upload/{{publicUuid}}`;
export const ENDPOINT_USER_ADMIN_GET_BULK_UPLOAD_PAGE = `/api/users/bulk-upload`;
export const ENDPOINT_USER_ADMIN_BULK_UPLOAD_PROCESS = `/api/users/bulk-upload`;
export const ENDPOINT_USER_ADMIN_GET_ADMIN = `/api/users/manage/admins`;
export const ENDPOINT_USER_ADMIN_GET_PAGE = `/api/users/manage`;
export const ENDPOINT_USER_ADMIN_GET_SINGLE = `/api/users/manage/{{uuid}}`;
export const ENDPOINT_USER_ADMIN_GET_SINGLE_ADMIN = `/api/users/{{uuid}}/permissions`;
export const ENDPOINT_USER_ADMIN_CREATE = `/api/users/manage`;
export const ENDPOINT_USER_ADMIN_EDIT = `/api/users/manage/{{uuid}}`;
export const ENDPOINT_USER_ADMIN_DELETE = `/api/users/manage/{{uuid}}`;
export const ENDPOINT_USER_ADMIN_EDIT_PERMISSIONS = `/api/users/{{uuid}}/permissions`;
export const ENDPOINT_USER_ADMIN_LOCK_ACCOUNT = `/api/users/manage/{{uuid}}/status`;
export const ENDPOINT_USER_ADMIN_PASSWORD_RESET = `/api/users/manage/{{uuid}}/password/reset`;
export const ENDPOINT_USER_ADMIN_STORE_CREDIT_ADD = `/api/users/manage/{{publicUuid}}/store-credit`;
export const ENDPOINT_USER_ADMIN_STORE_CREDIT_HISTORY = `/api/users/manage/{{publicUuid}}/store-credit`;


// Website endpoints

export const ENDPOINT_WEBSITE_ADMIN_CREATE_NOTICE = `/api/website/manage/notices`;
export const ENDPOINT_WEBSITE_ADMIN_GET_NOTICES = `/api/website/manage/notices`;
export const ENDPOINT_WEBSITE_ADMIN_UPDATE_NOTICE = `/api/website/manage/notices/{{publicUuid}}`;
export const ENDPOINT_WEBSITE_FETCH_LAYOUT = `/api/website/layouts/{{layoutKey}}`;
export const ENDPOINT_WEBSITE_GET_NOTICES = `/api/website/notices`;


// External endpoints

export const EXTERNAL_ENDPOINT_STAMPS_AUTHORIZE = `/authorize`;













