import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  ICON_EMAIL, 
  ICON_HOURS, 
  ICON_LOCATION, 
  ICON_PHONE, 
} from '../../constants/icons';
import { LANG_PRODUCT_LOCALIZATIONS } from '../../constants/languages';
import { LINK_TALARIA_MARKETING } from '../../constants/links';
import { 
  STORE_CODE,
  STORE_LANGUAGE_OPTIONS, 
} from '../../constants/store';
import * as tx from '../../constants/strings';
import {
  URL_BUY_GENERAL, 
  URL_BUY_PRODUCT_LINE, 
  URL_CONTACT_US, 
  URL_PLAY_GENERAL, 
  URL_PRIVACY_POLICY,
  URL_RETURN_POLICY,
  URL_TERMS,
} from '../../constants/urls';

import { 
  formatPhone,
  stringFormat, 
} from '../../utils/formatting';
import { 
  legalHasPrivacy,
  legalHasReturnPolicy,
  legalHasTerms, 
} from '../../utils/legal';
import { getOrderedMenu } from '../../utils/product';

import Icon from '../Icons/Icon';
import StoreHours from '../Store/StoreHours';
import TalariaLogo from '../Image/TalariaLogo';

import './style/_footer.scss';

import * as productLineActionCreators from '../../actions/product-line';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, productLineActionCreators, userActionCreators);

export class Footer extends Component {

  constructor(props) {
    super(props);

    this.BURRITO_TIME = 3000;

    this.state = {
      showBurritos: false,
    };

    this.burritoCountdown = null;
  }

  componentDidMount() {
    
    // Intentionally doesn't check if data already present; should try to pick up any changes
    this.props.productLineFetchMenus()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    // Get store data
    if(!this.props.user.store || !this.props.user.store.publicUuid) {
      // Get store data
      this.props.userFetchStore()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });
    }
  }

  getDateYear() {
    let d = new Date();
    return d.getFullYear();
  }

  hoverStart() {
    this.burritoCountdown = setTimeout(() => {
      this.setState({ showBurritos: true });
    }, this.BURRITO_TIME);
  }

  hoverEnd() {
    if(this.burritoCountdown) {
      clearTimeout(this.burritoCountdown);
    }
  }

  getLanguageList() {
    return STORE_LANGUAGE_OPTIONS[STORE_CODE] ? STORE_LANGUAGE_OPTIONS[STORE_CODE] : LANG_PRODUCT_LOCALIZATIONS;
  }

  render() {

    const { i18n } = this.props;
    const {t} = this.props;

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    const isSelected = (lng) => {
      return i18n.language.includes(lng);
    };

    return <div className='Footer'>
      <div className='footerLiner'>
        <div className='blockWrapper'>

          <div className='footerBlock'>
            <div className='fbLiner'>
              <div className='fbTitle'>
                <div className='fbTitleCopy'>{t(tx.TX_STORE)}</div>
              </div>
              <div className='fbBody'>
                <div className='fbBodySection'>
                  <div className={'iconElementWrapper addressElement'}>
                    <a href={this.props.user.store.mapsLink()} className='iconElementLink' target='_blank' rel='noreferrer'>
                      <div className='iconContainer'>
                        <Icon value={ICON_LOCATION} iconClass='iconComponent' />
                      </div>
                      <div className='iconElementText'>
                        {this.props.user.store.address.format().map((line, j) => {
                          return <div key={j} className='addressLine'>{line}</div>;
                        })}
                      </div>
                    </a>
                  </div>
                  {StoreHours.WrappedComponent.hasHours && StoreHours.WrappedComponent.hasHours() ?
                    <div className={'iconElementWrapper hoursElement'}>
                      <div className='iconContainer'>
                        <Icon value={ICON_HOURS} iconClass='iconComponent' />
                      </div>
                      <div className='iconElementText'>
                        <div className='iconElementTitle'>{t(tx.TX_HOURS)}</div>
                        <StoreHours />
                      </div>
                    </div> :
                    null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className='footerBlock'>
            <div className='fbLiner'>
              <div className='fbTitle'>
                <div className='fbTitleCopy'>{t(tx.TX_ABOUT_US)}</div>
              </div>
              <div className='fbBody'>
                <div className='fbBodySection'>
                  <div className='fbLinkWrapper'>
                    <Link className='fbLink' to={URL_CONTACT_US}>{t(tx.TX_CONTACT_US)}</Link>
                  </div>
                  <div className='fbLinkWrapper'>
                    <Link className='fbLink' to={URL_PLAY_GENERAL}>{t(tx.TX_EVENTS)}</Link>
                  </div>
                  {legalHasReturnPolicy(process.env.REACT_APP_STORE_CODE) ?
                    <div className='fbLinkWrapper'>
                      <Link className='fbLink' to={URL_RETURN_POLICY}>{t(tx.TX_LEGAL_RETURN_POLICY)}</Link>
                    </div> :
                    null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className='footerBlock'>
            <div className='fbLiner'>
              <div className='fbTitle'>
                <div className='fbTitleCopy'>{t(tx.TX_SHOP)}</div>
              </div>
              <div className='fbBody'>
                <div className='fbBodySection'>
                  <div className='fbLinkWrapper'>
                    <Link className='fbLink' to={URL_BUY_GENERAL}>{t(tx.TX_SHOP_ALL)}</Link>
                  </div>
                  {getOrderedMenu(this.props.productLine.menus).map((menuItem, i) => {
                    return <div key={i} className='fbLinkWrapper'>
                      <Link className='fbLink' to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: menuItem.permalink })}>{menuItem.name}</Link>
                    </div>;
                  })}
                  <div className='fbLinkWrapper'>
                    <Link className='fbLink' to={''}></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='footerBlock'>
            <div className='fbLiner'>
              <div className='fbTitle'>
                <div className='fbTitleCopy'>{t(tx.TX_CONNECT)}</div>
              </div>
              <div className='fbBody'>
                <div className='fbBodySection'>
                  {this.props.user.store.email ?
                    <div className='iconElementWrapper'>
                      <a href={`mailto:${this.props.user.store.email}`} className='iconElementLink'>
                        <div className='iconContainer'>
                          <Icon value={ICON_EMAIL} iconClass='iconComponent' />
                        </div>
                        <div className='iconElementText'>{this.props.user.store.email}</div>
                      </a>
                    </div> :
                    null
                  }
                  {this.props.user.store.phone ?
                    <div className='iconElementWrapper'>
                      <a href={`tel:${this.props.user.store.phone}`} className='iconElementLink'>
                        <div className='iconContainer'>
                          <Icon value={ICON_PHONE} iconClass='iconComponent' />
                        </div>
                        <div className='iconElementText'>{formatPhone(this.props.user.store.phone, this.props.user.store.countryCode)}</div>
                      </a>
                    </div> :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='languageWrapper'>
          {this.getLanguageList().map((lang, j) => {
            return <div 
              key={j}
              onClick={() => changeLanguage(lang.code)}
              className={isSelected(lang.code) ? 'languageElement selected' : 'languageElement'}>
              {lang.name}
            </div>;
          })}
        </div>

        <div className='bottomFooter'>
          <div className='copyWrapper'>&copy; {`${this.getDateYear()} ${this.props.user.store.name || process.env.REACT_APP_WEBSITE_NAME}`}</div>
          <div className='footerLegalRow'>
            {legalHasTerms() ?
              <Link className='legalLink' to={URL_TERMS}>{t(tx.TX_LEGAL_TERMS_OF_SERVICE)}</Link> :
              null
            }
            {legalHasPrivacy() ?
              <Link className='legalLink' to={URL_PRIVACY_POLICY}>{t(tx.TX_LEGAL_PRIVACY_POLICY)}</Link> :
              null
            }
          </div>
        </div>
        <div className='poweredByWrapper'>
          <a 
            className='poweredBy'
            href={LINK_TALARIA_MARKETING} 
            target='_blank' 
            rel='noreferrer' 
            onMouseEnter={this.hoverStart.bind(this)}
            onMouseLeave={this.hoverEnd.bind(this)}>
            <div className='poweredByCopy'>{t(tx.TX_FOOTER_POWERED_BY)}</div>
            <div className='poweredByImg'>
              <TalariaLogo />
            </div>
          </a>
          <div className={`andBurritos ${this.state.showBurritos ? 'burritoTime' : 'noBurritos'}`}>{t(tx.TX_FOOTER_AND_BURRITOS)}</div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  allActionCreators
)(withTranslation()(Footer));