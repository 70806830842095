import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style/HiddenDropdown.module.scss';

const HiddenDropdown = (props) => {

  // Props
  const {
    options, 
    value, 
    onChange, 
    children,
    noTranslate = false,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Select id
  const selectId = useMemo(() => `hidden-select-${Math.random().toString(36).substr(2, 9)}`, []);

  // Methods
  const handleClick = () => {
    console.log('click');
    console.log(document.getElementById(selectId));
    document.getElementById(selectId).click();
  };

  return (
    <div className={styles.HiddenDropdown}>
      <div onClick={handleClick} className={styles.overlay}>
        {children}
      </div>

      <select
        id={selectId}
        value={value}
        onChange={onChange}
        className={styles.selectElement}>
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {noTranslate ? option.display : t(option.display)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HiddenDropdown;
