import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_SEARCH } from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';

import { 
  simulateTab,
} from '../../../../utils/general';

import Checkbox from '../../../Input/Checkbox';
import Icon from '../../../Icons/Icon';

const allActionCreators = Object.assign({});

export class FilterMultiselect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputSearch: '',
    }
  }

  static isListElement() {
    return true;
  }

  changeSearch(evt) {
    this.setState({ inputSearch: evt.target.value });
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      simulateTab();
    }
  }

  clearInput() {
    this.setState({ inputSearch: '' });
  }

  getOptions() {

    if(!this.state.inputSearch) {
      return this.props.filterElement.elements;
    }

    const filteredOptions = [];
    const needle = this.state.inputSearch.toLowerCase();

    for(const elem of this.props.filterElement.elements) {

      const displayString = (this.props.filterElement.optionsTranslate ? this.props.t(elem.value) : elem.value).toLowerCase();
      if(displayString.includes(needle)) {
        filteredOptions.push(elem);
      }
    }
    return filteredOptions;
  }

  getCurrentValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.key) === false) {
        return [];
      } else {
        return this.props.filters[this.props.filterElement.key].split(',');
      }
    } catch(err) {
      console.error(err);
    }
    return [];
  }

  toggleOption(evt, key) {

    if(evt) { evt.stopPropagation(); }

    const currentValue = this.getCurrentValue();
    if(currentValue.includes(key)) {
      if(currentValue.length === 1) {
        this.props.clearFilter();  
      } else {

        currentValue.splice(currentValue.indexOf(key), 1);

        this.props.setFilters({
          [this.props.filterElement.key]: currentValue.join(','),
        });
      }
    } else {

      currentValue.push(key);

      this.props.setFilters({
        [this.props.filterElement.key]: currentValue.join(','),
      });
    }
  }

  selectAll() {

    let needsUpdate = false;
    const currentValue = this.getCurrentValue();
    
    for(const op of this.getOptions()) {
      if(!currentValue.includes(op.key)) {
        currentValue.push(op.key);
        needsUpdate = true;
      }
    }

    if(needsUpdate) {
      this.props.setFilters({
        [this.props.filterElement.key]: currentValue.join(','),
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterMultiselect AdminTableFilter'}>
      <div className='filterElementTitle'>
        <div className='filterElementTitleValue'>{t(this.props.filterElement.name)}</div>
        <div className='filterElementClearLink' onClick={this.props.clearFilter.bind(this)}>{t(tx.TX_CLEAR)}</div>
      </div>
      <div className='filterElementBody'>
        <div className='multiWrapper'>
          <div className='multiInputWrapper'>
            <div className='multiInput'>
              <div className='multiInputLiner'>
                {this.props.filterElement.quickLookup ?
                  <div className='multiQuickSearchInput'>
                    <input
                      type='text'
                      name={t(tx.TX_SEARCH)}
                      value={this.state.inputSearch}
                      placeholder={t(tx.TX_SEARCH)}
                      onChange={this.changeSearch.bind(this)}
                      onKeyPress={this.handleKeyPress.bind(this)} />
                    <div className='searchOverlay'>
                      <Icon value={ICON_SEARCH} iconClass='searchIcon' />
                    </div>
                    {this.state.inputSearch ?
                      <div className='clearOverlay' onClick={this.clearInput.bind(this)}>&times;</div> :
                      null 
                    }
                  </div> :
                  null
                }
                <div className={`multiElements ${this.props.filterElement.quickLookup !== true ? 'noSearch' : ''}`}>
                  {this.getOptions().length === 0 ?
                    <div className='noneFound'>{t(tx.TX_NO_RESULTS)}</div> :
                    <>
                      {this.getOptions().map((elem, i) => {
                        return <div className={`elementWrapper ${this.getCurrentValue().includes(elem.key) ? 'selected' : ''}`} key={elem.key} onClick={(evt) => this.toggleOption(evt, elem.key)}>
                          <div className='elementSelectLine'>
                            <div className='elementCheckboxWrapper'>
                              <div className='elementCheckboxLiner'>
                                <Checkbox 
                                  adminTheme={true}
                                  name={this.props.filterElement.optionsTranslate ? t(elem.value) : elem.value}
                                  id={`cb-elem-${elem.key}`}
                                  value={elem.key}
                                  checked={this.getCurrentValue().includes(elem.key)}
                                  onChange={() => {}} />
                              </div>
                            </div>
                            <div className='elementDisplayWrapper'>
                              <div className='elementDisplayLiner'>{this.props.filterElement.optionsTranslate ? t(elem.value) : elem.value}</div>
                            </div>
                          </div>
                        </div>;
                      })}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='multiResultsWrapper'>
            <div className='multiResults'>
              <div className='multiTitle'>
                <div className='multiTitleValue'>{t(tx.TX_SELECTED)}</div>
                <div className='multiTitleAction' onClick={this.selectAll.bind(this)}>{t(tx.TX_SELECT_ALL)}</div>
              </div>
              <div className='multiResultsBody'>
                {this.getCurrentValue().length === 0 ?
                  <div className='noneFound'>{t(tx.TX_NONE)}</div> :
                  <>
                    {this.getOptions().map((opt, j) => {
                      
                      if(!this.getCurrentValue().includes(opt.key)) {
                        return null;
                      }

                      return <div className='resultRow' key={j}>
                        <div className='removeActionWrapper' onClick={(evt) => this.toggleOption(evt, opt.key)}>
                          <div className='removeX'>&times;</div>
                        </div>
                        <div className='resultNameWrapper'>
                          <div className='resultNameValue'>{this.props.filterElement.optionsTranslate ? t(opt.value) : opt.value}</div>
                        </div>
                      </div>;
                    })}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterMultiselect));