import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ANALYTICS_CONFIG_METRIC,
  ANALYTICS_CONFIG_MODE,
  ANALYTICS_CONFIG_SHOW_TODAY,
} from '../../../../../constants/analytics';
import { ICON_SETTINGS } from '../../../../../constants/icons';
import { 
  TX_ANALYTICS_CONFIG_GENERAL_LABEL, 
  TX_ANALYTICS_CONFIG_METRIC_LABEL,
  TX_ANALYTICS_CONFIG_MODE_LABEL,
  TX_ANALYTICS_CONFIG_SHOW_TODAY, 
  TX_DURATION, 
  TX_LOADING, 
} from '../../../../../constants/strings';

import useClickOutside from '../../../../../hooks/useClickOutside';

import Icon from '../../../../Icons/Icon';
import Checkbox from '../../../../Input/Checkbox';
import Dropdown from '../../../../Input/Dropdown';

import styles from '../../../style/AnalyticsGraphConfigMenu.module.scss';

export const AnalyticsGraphConfigMenu = (props) => {
  
  // Props
  const {
    dataLoading, 
    dataModel,
    duration,
    setConfigValue,
    setDuration,
  } = props;

  // State
  const [open, setOpen] = useState(false);

  // Refs
  // None yet

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const toggleMenuOpen = (evt) => {
    if(!dataLoading) {
      setOpen(!open);
    }
  };

  const handleOutsideClick = (evt) => {
    if(evt) { evt.stopPropagation(); }
    if(open) {
      setOpen(false);
    }
  };

  const getValue = (key) => {
    if(!key || !dataModel) { return ''; }
    return dataModel.getConfigValue(key);
  };

  const setValue = (evt, key) => {
    setConfigValue(key, evt.target.value);
  };

  const toggleConfigValue = (key) => {
    if(!key || !dataModel) { return null; }
    const currentValue = getValue(key);
    setConfigValue(key, !currentValue);
  };

  const getModeOptions = () => {
    if(dataLoading || !dataModel) {
      return [
        {
          display: TX_LOADING,
          value: '',
        }
      ];
    }
    return dataModel.getModeOptions();
  };

  const getMetricOptions = () => {
    if(dataLoading || !dataModel) {
      return [
        {
          display: TX_LOADING,
          value: '',
        }
      ];
    }
    return dataModel.getMetricOptions();
  };

  const getDurationOptions = () => {
    if(dataLoading || !dataModel) {
      return [
        {
          display: TX_LOADING,
          value: '',
        }
      ];
    }
    return dataModel.getDurationOptions();
  };

  // Hooks
  const menuRef = useClickOutside(handleOutsideClick);

  // Effects
  // None yet


  return (
    <div className={styles.AnalyticsGraphConfigMenu}>
      <div className={styles.configMenuWrapper}>
        <div className={`${styles.menuToggleWrapper} ${dataLoading || !dataModel ? styles.disabled : ''}`} onClick={toggleMenuOpen}>
          <div className={styles.menuIconWrapper}>
            <Icon value={ICON_SETTINGS} />
          </div>
        </div>
        <div className={`${styles.menuBodyWrapper} ${open ? styles.open : ''}`} ref={menuRef}>
          <div className={styles.menuBodyLiner}>
            <div className={`${styles.configModeWrapper} ${styles.configSection}`}>
              <div className={styles.configSectionTitle}>
                <div className={styles.configSectionTitleValue}>{t(TX_ANALYTICS_CONFIG_MODE_LABEL)}</div>
              </div>
              <div className={styles.configSectionBody}>
                <div className={styles.dropdownLine}>
                  <Dropdown
                    className={styles.dropdownLineElement}
                    disabled={dataLoading || !dataModel}
                    options={getModeOptions()}
                    name={t(TX_ANALYTICS_CONFIG_MODE_LABEL)}
                    value={getValue(ANALYTICS_CONFIG_MODE)}
                    noTranslate={false}
                    onChange={(evt) => setValue(evt, ANALYTICS_CONFIG_MODE)} />
                </div>
              </div>
            </div>
            <div className={`${styles.configMetricWrapper} ${styles.configSection}`}>
              <div className={styles.configSectionTitle}>
                <div className={styles.configSectionTitleValue}>{t(TX_ANALYTICS_CONFIG_METRIC_LABEL)}</div>
              </div>
              <div className={styles.configSectionBody}>
                <div className={styles.dropdownLine}>
                  <Dropdown
                    className={styles.dropdownLineElement}
                    disabled={dataLoading || !dataModel}
                    options={getMetricOptions()}
                    name={t(TX_ANALYTICS_CONFIG_METRIC_LABEL)}
                    value={getValue(ANALYTICS_CONFIG_METRIC)}
                    noTranslate={false}
                    onChange={(evt) => setValue(evt, ANALYTICS_CONFIG_METRIC)} />
                </div>
              </div>
            </div>
            <div className={`${styles.configMetricWrapper} ${styles.configSection} ${styles.mobileSection}`}>
              <div className={styles.configSectionTitle}>
                <div className={styles.configSectionTitleValue}>{t(TX_DURATION)}</div>
              </div>
              <div className={styles.configSectionBody}>
                <div className={styles.dropdownLine}>
                  <Dropdown
                    className={styles.dropdownLineElement}
                    disabled={dataLoading || !dataModel}
                    options={getDurationOptions()}
                    name={t(TX_DURATION)}
                    value={duration}
                    noTranslate={false}
                    onChange={(evt) => setDuration(evt.target.value)} />
                </div>
              </div>
            </div>
            <div className={`${styles.configGeneralWrapper} ${styles.configSection}`}>
              <div className={styles.configSectionTitle}>
                <div className={styles.configSectionTitleValue}>{t(TX_ANALYTICS_CONFIG_GENERAL_LABEL)}</div>
              </div>
              <div className={styles.configSectionBody}>
                <div className={styles.toggleLine}>
                  <div className={styles.inputWrapper}>
                    <div className={styles.inputElement}>
                      <Checkbox 
                        id={`dgcm-checkbox-today`}
                        adminTheme={true}
                        disabled={dataLoading || !dataModel}
                        name={t(TX_ANALYTICS_CONFIG_SHOW_TODAY)}
                        value={t(TX_ANALYTICS_CONFIG_SHOW_TODAY)}
                        checked={getValue(ANALYTICS_CONFIG_SHOW_TODAY)}
                        ambiguousCheck={false}
                        onChange={() => toggleConfigValue(ANALYTICS_CONFIG_SHOW_TODAY)} />
                    </div>
                  </div>
                  <div className={styles.labelWrapper}>
                    <label className={styles.labelValue} htmlFor={'dgcm-checkbox-today'}>{t(TX_ANALYTICS_CONFIG_SHOW_TODAY)}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsGraphConfigMenu;

