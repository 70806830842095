import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  LANG_DEFAULT_CODE,
  LANG_PRODUCT_GENERIC, 
} from '../../../../constants/languages';
import * as tx from '../../../../constants/strings';

import { ProductLinePricingMatrix } from '../../../../models/products';

import { isFormValid } from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';

import LoadingIcon from '../../../Icons/LoadingIcon';
import ProductLinePricingMatrixEdit from './ProductLinePricingMatrixEdit';
import SavePrompt from '../../../Popups/SavePrompt';
import Toggle from '../../../Input/Toggle';

import * as commonActionCreators from '../../../../actions/common';
import * as productLineActionCreators from '../../../../actions/product-line';
let allActionCreators = Object.assign({}, commonActionCreators, productLineActionCreators);

export class ProductLinePricingEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      inputEnabled: this.props.productLineObj && this.props.productLineObj.automatedPricing ? this.props.productLineObj.automatedPricing : false,

      defaultRequestPending: false,
      defaultRequestError: null,

      requestPending: false,
      requestError: null,

      savePromptOpen: false, 
    };
  }

  componentDidMount() {
    if(this.props.productLineObj && this.props.productLineObj.priceMatrices && this.props.productLineObj.priceMatrices.length === 0) {
      this.addDefaultMatrix();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    if(!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
    this.props.makeDirty();
  }

  async addDefaultMatrix() {

    this.setState({
      defaultRequestPending: true,
      defaultRequestError: null,
    });

    const defaultResp = await this.props.productLineAddPricingMatrix(ProductLinePricingMatrix.defaultApiData(), this.props.productLinePermalink)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          defaultRequestPending: false,
          defaultRequestError: formatServerError(errResp),
        });
      });

    if(!defaultResp) { return null; }

    this.setState({
      defaultRequestPending: false,
      defaultRequestError: null,
    }, () => {
      this.props.makeClean('', false, false);
    });
  }

  changeEnabled(evt) {
    this.setState({
      inputEnabled: !this.state.inputEnabled, 
    });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const enableData = {
        is_enabled: this.state.inputEnabled,
      }

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const enableResp = await this.props.productLineEnablePricing(enableData, this.props.productLinePermalink)
        .catch((errResp) => {
          // Fail response
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });

          if(this.props.remoteSave) {
            this.props.finishRemote('pricing', true);
          }
        });

      if(!enableResp) { return null; }

      // Success response
      this.props.commonAlert({ 
        alertTitle: tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_UPDATING_TITLE, 
        alertCopy: tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_UPDATING_COPY, 
      });

      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        if(this.props.remoteSave) {
          this.props.finishRemote('pricing');
        } else {
          this.props.makeClean('pricing');
        }
        this.props.toggleEdit(null);
      });

    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('pricing', true);
    }
  }

  validateAll() {
    const errorObj = {
      
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  getDefaultLanguage() {
    for(const lang of LANG_PRODUCT_GENERIC) {
      if(lang.code === LANG_DEFAULT_CODE) {
        return lang;
      }
    }
    return '';
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  isEnabledDisabled() {
    return false;
  }

  buylistAllowed() {
    return this.props.productObj && this.props.productObj.productLine ? this.props.productObj.productLine.hasBuylist : false;
  }

  sealedAllowed() {
    if(this.props.productObj && this.props.productObj.foreignModel) {
      return this.props.productObj.foreignModel.allowSealed;
    }
    return true;
  }

  languageAllowed() {
    return this.props.productObj && !this.props.productObj.foreignModel;
  }

  changeBuylist(evt) {
    this.setState({
      inputBuylist: !this.state.inputBuylist, 
    }, () => {
      if(!this.state.inputBuylist) {
        this.setState({ 
          inputBuyPrice: '', 
          errorBuyPrice: '', 
        });
      }
    });
    this.makeDirty();
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductLinePricingEdit adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>

          <div className='adminForm'>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputEnabled}
                    disabled={this.isEnabledDisabled()}
                    onToggle={this.changeEnabled.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>

            <div className='adminFieldWrapper pricingWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_RULES)}</div>
              {this.props.productLineObj && this.props.productLineObj.priceMatrices && this.props.productLineObj.priceMatrices.length > 0 ?
                <>
                  {this.props.productLineObj.priceMatrices.map((matrix, i) => {

                    // First, show default
                    if(!matrix.isDefault) { return null; }

                    return <div key={i} className='matrixOuterWrapper'>
                      <ProductLinePricingMatrixEdit
                        productLineObj={this.props.productLineObj}
                        pricingMatrix={matrix}
                        makeClean={this.props.makeClean} />
                    </div>;
                  })}
                  {this.props.productLineObj.priceMatrices.map((matrix, j) => {

                    // Next, show all non-default
                    if(matrix.isDefault) { return null; }

                    return <div key={j} className='matrixOuterWrapper'>
                      <ProductLinePricingMatrixEdit
                        productLineObj={this.props.productLineObj}
                        pricingMatrix={matrix}
                        makeClean={this.props.makeClean} />
                    </div>;
                  })}
                </> :
                <div className='loadingDefaults'>
                  <div className='loadingDefaultsLiner'>
                    <div className='loadingIcon'>
                      <LoadingIcon />
                    </div>
                    <div className='loadingCopy'>{t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_LOADING)}</div>
                  </div>
                </div>
              }
            </div>

            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='button'
                onClick={this.saveAction.bind(this)}
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLinePricingEdit));