


export const APP_ENV_PROD = 'PROD';
export const APP_ENV_STAGING = 'STAGING';
export const APP_ENV_LOCAL = 'LOCAL';

export const APP_ENV = process.env.REACT_APP_BUILD_PLATFORM;

export const APP_IS_PROD = APP_ENV === APP_ENV_PROD;

export const APP_CRYPT_KEY = APP_ENV === APP_ENV_PROD ? process.env.REACT_APP_AES_CRYPT_KEY : process.env.REACT_APP_AES_CRYPT_KEY_STAGING;