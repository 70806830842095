import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_EMPTY } from '../../constants/icons';
import * as tx from '../../constants/strings';

import './style/_minicart.scss';

import Icon from '../Icons/Icon';
import LoadingIcon from '../Icons/LoadingIcon';
import MiniCartItem from './blocks/MiniCartItem';

import * as cartActionCreators from '../../actions/cart';
let allActionCreators = Object.assign({}, cartActionCreators);

export class MiniCart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cart: this.props.isBuylist ? this.props.cart.currentBuylistCart : this.props.cart.currentCart,
    }
  }

  componentDidMount() {
    // Get cart, if one exists
    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  hasItems() {
    return this.getCart() && this.getCart().items && this.getCart().items.length > 0 ? true : false;
  }

  setLoading(val) {
    if(this.props.setParentLoading) {
      this.props.setParentLoading(val);
    }
  }

  async editItem(cartItemObj, newQty) {
    
    if(!this.getCart() || !this.getCart().publicUuid || !cartItemObj) { return null; }

    const itemUpdateObj = {
      inventory_id: cartItemObj.inventory.id,
      quantity: newQty,
    }
    this.setLoading(true);

    if(this.props.isBuylist) {
      await this.props.cartBuylistItemUpdate(this.getCart().publicUuid, cartItemObj.id, itemUpdateObj)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }
          this.setLoading(false);
        });
    } else {
      await this.props.cartItemUpdate(this.getCart().publicUuid, cartItemObj.id, itemUpdateObj)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }
          this.setLoading(false);
        });
    }

    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    })
    .finally(() => {
      this.setLoading(false);
    });
  }

  async removeItem(cartItemObj) {
    
    if(!this.getCart() || !this.getCart().publicUuid || !cartItemObj) { return null; }

    this.setLoading(true);

    if(this.props.isBuylist) {
      await this.props.cartBuylistItemDelete(this.getCart().publicUuid, cartItemObj.id)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }
          this.setLoading(false);
        });
    } else {
      await this.props.cartItemDelete(this.getCart().publicUuid, cartItemObj.id)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }
          this.setLoading(false);
        });
    }

    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    })
    .finally(() => {
      this.setLoading(false);
    });
  }

  getCartItems() {
    return !this.hasItems() ? [] : this.getCart().items;
  }

  getCart() {
    return this.props.cartOverride ? this.props.cartOverride : (this.props.isBuylist ? this.props.cart.currentBuylistCart : this.props.cart.currentCart);
  }

  getCartPending() {
    return this.props.cartOverride ? false : this.props.cart.pendingGetCart;
  }

  render() {

    const {t} = this.props;

    return <div className={'MiniCart'}>
      <div className='mcBody'>
        <div className='mcBodyWrapper'>
          {!this.getCart() && this.getCartPending() ?
            <div className='cartLoading'>
              <div className='loadingIcon'>
                <LoadingIcon />
              </div>
              <div className='loadingLabel'>{t(tx.TX_LOADING)}</div>
            </div> :
            <>
              {this.hasItems() === false ?
                <div className='cartEmpty'>
                  <div className='emptyIcon'>
                    <Icon
                      value={ICON_EMPTY} />
                  </div>
                  <div className='emptyLabel'>{t(tx.TX_CART_EMPTY)}</div>
                </div> :
                <div className='cartBody'>
                  <div className='cartBodyLiner'>
                    {this.props.isBuylist && !this.props.cartOverride && !this.props.hideDisclaimer ?
                      <div className='cartBodyNoticeWrapper'>
                        <div className='cartBodyNoticeBlock'>{t(tx.TX_PRODUCT_PAGE_BUYLIST_NOTICE)}</div>
                        <div className='cartBodyNoticeBlock'>{t(tx.TX_PRODUCT_PAGE_BUYLIST_NOTICE_2)}</div>
                      </div> :
                      null
                    }
                    {this.getCartItems().map((item, j) => {

                      if(this.props.truncateLines && this.props.truncateLines <= j) {
                        return null;
                      }

                      return <div className='cartBodyItemWrapper' key={j}>
                        <MiniCartItem
                          isBuylist={this.props.isBuylist}
                          cart={this.getCart()}
                          order={this.props.order}
                          item={item}
                          removeItem={this.removeItem.bind(this)}
                          editItem={this.editItem.bind(this)}
                          readOnly={this.props.readOnly} />
                      </div>;
                    })}
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(MiniCart));