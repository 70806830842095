import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  CAL_DISPLAY_TYPE_MOBILE,
  CAL_DISPLAY_TYPE_WEEKLY,
} from '../../../constants/calendar';
import * as tx from '../../../constants/strings';
import { URL_ADMIN_EVENTS_CREATE } from '../../../constants/urls';

import {
  Month,
  Week,
} from '../../../models/calendar';

import { getStoreLanguage } from '../../../utils/language';

let allActionCreators = Object.assign({});

export class EventCalendarNavigation extends Component {

  isMobileView() {
    return this.props.displayMode === CAL_DISPLAY_TYPE_MOBILE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  goToToday() {
    
    if(this.props.displayMode === CAL_DISPLAY_TYPE_WEEKLY) {
      this.props.setWeek(new Week());
    } else {
      this.props.setMonth(new Month());
    }
  }

  prevNav() {
    if(this.props.displayMode === CAL_DISPLAY_TYPE_WEEKLY) {
      this.props.setWeek(this.props.displayWeek.lastWeek());
    } else {
      this.props.setMonth(this.props.displayMonth.lastMonth());
    }
  }

  nextNav() {
    if(this.props.displayMode === CAL_DISPLAY_TYPE_WEEKLY) {
      this.props.setWeek(this.props.displayWeek.nextWeek());
    } else {
      this.props.setMonth(this.props.displayMonth.nextMonth());
    }
  }

  getRootClass() {
    const baseClass = 'EventCalendarNavigation';

    const adminClass = this.props.admin ? ' adminTheme' : '';
    const mobileClass = this.isMobileView() ? ' mobileView' : '';

    return `${baseClass}${adminClass}${mobileClass}`;
  }

  render() {

    const {t} = this.props;

    return <div className={this.getRootClass()}>
      <div className='ecnLiner'>
        <div className='ecnTitleWrapper'>
          <div className={'navArrow leftArrow'} onClick={this.prevNav.bind(this)}>
            <div className='arrowElement'></div>
          </div>
          {this.props.displayMode === CAL_DISPLAY_TYPE_WEEKLY ?
            <div className='ecnWrapper'>
              <div className='ecnMonthWrapper'>{this.props.displayWeek.formatMonth(this.getLanguage())}</div>
              <div className='ecnYearWrapper'>{this.props.displayWeek.formatYear(this.getLanguage())}</div>
            </div> :
            <div className='ecnWrapper'>
              <div className='ecnMonthWrapper'>{this.props.displayMonth.formatName(this.getLanguage())}</div>
              <div className='ecnYearWrapper'>{this.props.displayMonth.formatYear(this.getLanguage())}</div>
            </div>
          }
          <div className={'navArrow rightArrow'} onClick={this.nextNav.bind(this)}>
            <div className='arrowElement'></div>
          </div>
        </div>
        <div className='ecnButtonLeftWrapper'>
          <button 
            type='button'
            className={'ecnNavButton ecnTodayButton'}
            onClick={this.goToToday.bind(this)}>
            {t(tx.TX_TODAY)}
          </button>
        </div>
        <div className='ecnButtonWrapper'>
          {this.props.admin ?
            <Link to={URL_ADMIN_EVENTS_CREATE} tabIndex='-1'>
              <button className={'ecnNavButton ecnAddButton'}>{t(tx.TX_EVENTS_CREATE_EVENT)}</button>
            </Link> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventCalendarNavigation));