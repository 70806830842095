import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';
import { history } from '../../../store';

import {
  FV_KEY_FINISH,
  FV_KEY_LANGUAGE,
  FV_KEY_PRINTING, 
} from '../../../constants/filters';
import { LANG_DEFAULT_CODE } from '../../../constants/languages';
import { PROD_BUYLIST_QTY_LIMIT } from '../../../constants/product';
import * as tx from '../../../constants/strings';

import { addToCartEvent } from '../../../utils/analytics';
import { isBuylistCheckoutEnabled } from '../../../utils/checkout';
import { getConditionsOrderedDeduped } from '../../../utils/condition';
import {
  getFinishObjectFromKey, 
  getFinishesOrderedDeduped,
} from '../../../utils/finish';
import { 
  formatServerError,
  serverDateFull, 
} from '../../../utils/formatting';
import { safeParseInt } from '../../../utils/general';
import {
  getLanguageObjectFromCode, 
  getLanguagesOrderedDeduped, 
} from '../../../utils/language';
import {
  getPrintingsOrderedDeduped, 
  getPrintingObjectFromKey, 
} from '../../../utils/printings';

import LoadingIcon from '../../Icons/LoadingIcon';
import ProductPageInventoryConfigCondition from './config/ProductPageInventoryConfigCondition';
import ProductPageInventoryConfigFinish from './config/ProductPageInventoryConfigFinish';
import ProductPageInventoryConfigLanguage from './config/ProductPageInventoryConfigLanguage';
import ProductPageInventoryConfigPrinting from './config/ProductPageInventoryConfigPrinting';
import ProductPageInventoryPrice from './ProductPageInventoryPrice';
import ProductPageInventoryQuantity from './ProductPageInventoryQuantity';

import * as cartActionCreators from '../../../actions/cart';
let allActionCreators = Object.assign({}, cartActionCreators);

export class ProductPageInventory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      condition: this.getMaxCondition(),
      finish: this.getDefaultFinish(), 
      language: this.getDefaultLanguage(),
      printing: this.getDefaultPrinting(),

      errorCondition: '', 
      errorFinish: '', 
      errorLanguage: '', 

      qty: 1, 

      requestPending: false,
      requestError: null, 
    };
  }

  componentDidMount() {
    this.filterInventoryOptions();
  }

  componentDidUpdate(prevProps, prevState) {

    // Set initial state if product (id) changes
    if(prevProps.productObj && prevProps.productObj.id && this.props.productObj && this.props.productObj.id && prevProps.productObj.id !== this.props.productObj.id) {
      this.setState({
        condition: this.getMaxCondition(),
        finish: this.getDefaultFinish(), 
        language: this.getDefaultLanguage(),
        printing: this.getDefaultPrinting(),
        qty: 1, 
      }, () => {
        this.filterInventoryOptions();
      });
    }

    // If object "selected" (only one option left), assign unassigned config filters
    if(!_.isEqual(this.props.selectedInvObj, prevProps.selectedInvObj)) {
      
      if(this.props.selectedInvObj) {
        if(this.props.selectedInvObj.language && !this.state.language) {
          this.setState({
            language: this.props.selectedInvObj.language, 
          });
        }

        if(this.props.selectedInvObj.finish && !this.state.finish) {
          this.setState({
            finish: this.props.selectedInvObj.finish, 
          });
        }

        if(this.props.selectedInvObj.condition && !this.state.condition) {
          this.setState({
            condition: this.props.selectedInvObj.condition, 
          });
        }
      }
    }
  }

  getDefaultFinish() {

    if(!this.props.productObj || !this.props.productObj.inventory || !this.shouldDisplayFinish()) { return null; }

    const allFinishes = [];
    const filteredFinishes = [];

    const filterFinishKeys = this.props.searchFilters && this.props.searchFilters[FV_KEY_FINISH] ? this.props.searchFilters[FV_KEY_FINISH] : [];

    // Check for finishes in default language
    for(const inv of this.props.inventoryOptions) {
      
      if(!inv.finish) { continue; }
      
      const finishObj = getFinishObjectFromKey(inv.finish.key);
      if(!finishObj) {
        continue;
      }

      if(filterFinishKeys.includes(inv.finish.key)) {
        filteredFinishes.push(finishObj);
      }

      allFinishes.push(finishObj);
    }

    if(filteredFinishes.length) {
      const respArray = getFinishesOrderedDeduped(filteredFinishes);
      return respArray.length > 0 ? respArray[0] : null;
    } else if(allFinishes.length) {
      const respArray = getFinishesOrderedDeduped(allFinishes);
      return respArray.length > 0 ? respArray[0] : null;
    }

    return null;
  }

  getDefaultPrinting() {

    if(!this.props.productObj || !this.props.productObj.inventory || !this.shouldDisplayPrinting()) { return null; }

    const allPrintings = [];
    const filteredPrintings = [];

    const filterPrintingKeys = this.props.searchFilters && this.props.searchFilters[FV_KEY_PRINTING] ? this.props.searchFilters[FV_KEY_PRINTING] : [];

    // Check for printings in default language
    for(const inv of this.props.inventoryOptions) {
      
      if(!inv.printing) { continue; }
      
      const printingObj = getPrintingObjectFromKey(inv.printing.key);
      if(!printingObj) {
        continue;
      }

      if(filterPrintingKeys.includes(inv.printing.key)) {
        filteredPrintings.push(printingObj);
      }

      allPrintings.push(printingObj);
    }

    if(filteredPrintings.length) {
      const respArray = getPrintingsOrderedDeduped(filteredPrintings);
      return respArray.length > 0 ? respArray[0] : null;
    } else if(allPrintings.length) {
      const respArray = getPrintingsOrderedDeduped(allPrintings);
      return respArray.length > 0 ? respArray[0] : null;
    }

    return null;
  }

  getDefaultLanguage() {

    if(!this.props.productObj || !this.props.productObj.inventory) { return null; }

    const filterLanguagueCodes = this.props.searchFilters && this.props.searchFilters[FV_KEY_LANGUAGE] ? this.props.searchFilters[FV_KEY_LANGUAGE] : [];

    let defaultLanguage = null;
    for(const inv of this.props.productObj.inventory) {
      
      if(!inv.language) { continue; }

      if(filterLanguagueCodes.length && !filterLanguagueCodes.includes(LANG_DEFAULT_CODE)) {
        if(filterLanguagueCodes.includes(inv.language.code)) {
          return inv.language;
        }
      } else if(inv.language.code === LANG_DEFAULT_CODE) {
        return inv.language;
      }


      if(inv.language.code === LANG_DEFAULT_CODE) {
        defaultLanguage = inv.language;
      }
    }
    return defaultLanguage || null;
  }

  getMaxCondition() {
    
    if(!this.props.inventoryOptions) { return null; }

    const conditionArray = [];
    for(const inv of this.props.inventoryOptions) {
      if(inv.condition) {
        conditionArray.push(inv.condition);
      }
    }
    const orderedConditions = getConditionsOrderedDeduped(conditionArray);
    
    if(orderedConditions.length > 0) {
      return orderedConditions[0];
    }
    return null;
  }

  setCondition(conditionObj) {
    this.setState({
      condition: conditionObj,
    }, () => {
      this.filterInventoryOptions();
    });
  }
  
  setFinish(attrObj) {

    const finishObj = attrObj && attrObj.target && attrObj.target.value ? getFinishObjectFromKey(attrObj.target.value) : (attrObj && attrObj.key ? attrObj : null);
    this.setState({
      finish: finishObj,
    }, () => {
      this.filterInventoryOptions();
    });
  }

  setPrinting(attrObj) {

    const printingObj = attrObj && attrObj.target && attrObj.target.value ? getPrintingObjectFromKey(attrObj.target.value) : (attrObj && attrObj.key ? attrObj : null);
    this.setState({
      printing: printingObj,
    }, () => {
      this.filterInventoryOptions();
    });
  }

  setLanguage(evt) {
    this.setState({
      language: getLanguageObjectFromCode(evt.target.value, null),
    }, () => {
      this.filterInventoryOptions();
    });
  }

  filterInventoryOptions() {

    if(!this.props.productObj || !this.props.productObj.inventory) { return null; }

    const filteredInventory = [];
    const selectedInventory = [];
    for(const inv of this.props.productObj.inventory) {

      if(this.state.language && this.state.language.code && inv.language) {

        if(!inv.language.code.includes(this.state.language.code)) {
          if(!inv.language.parent || !inv.language.parent.code.includes(this.state.language.code)) {
            continue;
          }
        }
      }

      if(this.state.printing && this.state.printing.key && inv.printing && this.state.printing.key !== inv.printing.key) {
        continue;
      }

      if(this.state.finish && this.state.finish.key && inv.finish && this.state.finish.key !== inv.finish.key) {
        continue;
      }

      // For filtered inventory, always exclude condition
      filteredInventory.push(inv);

      if(this.state.condition && this.state.condition.key && inv.condition && this.state.condition.key !== inv.condition.key) {
        continue;
      }
      selectedInventory.push(inv);
    }

    this.props.setInventoryOptions(filteredInventory);

    if(selectedInventory.length === 1) {
      this.props.selectInvObj(selectedInventory[0]);
      if(selectedInventory[0].totalQuantity < this.state.qty && !this.props.isBuylist) {
        this.setState({
          qty: selectedInventory[0].totalQuantity,
        });
      }
    } else if(filteredInventory.length === 1) {
      this.props.selectInvObj(filteredInventory[0]);
      if(filteredInventory[0].totalQuantity < this.state.qty && !this.props.isBuylist) {
        this.setState({
          qty: filteredInventory[0].totalQuantity,
        });
      }
    } else {
      this.props.selectInvObj(null);
    }
  }

  getSelectedCondition() {
    if(this.props.selectedInvObj && this.props.selectedInvObj.condition) {
      return this.props.selectedInvObj.condition;
    } else {
      return this.state.condition && this.state.condition.key ? this.state.condition : null;
    }
  }

  resetConfigs() {
    this.setState({
      condition: null,
      finish: null, 
      language: null,
      printing: null,
      qty: 1, 
    }, () => {
      this.filterInventoryOptions();
    });
  }

  setQuantity(qtyVal) {

    if(!this.props.selectedInvObj) {
      return null;
    }

    // Allow empty string so user can use backspace
    if(qtyVal === '') {
      this.setState({ 
        qty: qtyVal, 
      });
      return null;
    }

    try {
      let qty = safeParseInt(qtyVal);

      const maxQty = this.props.isBuylist ? PROD_BUYLIST_QTY_LIMIT : this.props.selectedInvObj.totalQuantity;

      if(qty < 1) {
        qty = 1;
      } else if(qty > maxQty) {
        qty = maxQty;
      }
      if(qty !== this.state.qty) {
        this.setState({
          qty: qty, 
        });
      }
    } catch(errResp) {
      console.error(errResp);
      this.setState({
        qty: 1, 
      });
    }
  }

  shouldRequireLanguage() {
    if(!this.props.productObj || !this.props.productObj.inventory) {
      return false;
    }

    const languageArray = [];
    for(const inv of this.props.productObj.inventory) {
      if(inv.language) {
        languageArray.push(inv.language);
      }
    }
    return getLanguagesOrderedDeduped(languageArray).length > 1;
  }

  getInventoryLanguages() {
    
    const languageArray = [];
    for(const inv of this.props.productObj.inventory) {
      if(inv.language) {
        languageArray.push(inv.language);
      }
    }
    return getLanguagesOrderedDeduped(languageArray);
  }

  shouldDisplayLanguage() {
    if(!this.props.productObj || !this.props.productObj.inventory) { return false; }
    if(!this.props.isBuylist && !this.props.productObj.inStock) { return false; }
    for(const inv of this.props.productObj.inventory) {
      if(inv.language && inv.language.code !== LANG_DEFAULT_CODE) {
        return true;
      }
    }
    return false;
  }

  shouldDisplayFinish() {
    if(!this.props.productObj || !this.props.productObj.inventory) { return false; }
    if(!this.props.isBuylist && !this.props.productObj.inStock) { return false; }
    return this.props.productObj.hasFinish();
  }

  shouldDisplayPrinting() {
    if(!this.props.productObj || !this.props.productObj.inventory) { return false; }
    if(!this.props.isBuylist && !this.props.productObj.inStock) { return false; }
    return this.props.productObj.hasPrinting() && this.props.productObj.allPrintings().length > 1;
  }

  shouldDisplayCondition() {

    if(!this.props.productObj || !this.props.productObj.inventory) {
      return false;
    }

    for(const inv of this.props.productObj.inventory) {
      if(inv.isSealed !== true) {
        return true;
      }
    }
    return false;
  }

  async addToCart(evt) {

    if(!this.props.productObj || !this.props.selectedInvObj) {
      return null;
    }

    this.setState({
      requestPending: true,
      requestError: null, 
    });

    const addData = {
      inventory_id: this.props.selectedInvObj.id, 
      quantity: this.state.qty, 
      product_id: this.props.productObj.id, 
    };

    let addResp = null;
    if(this.props.isBuylist) {
      addResp = await this.props.cartAddBuylistProduct(addData)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }

          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp), 
          });
        });
    } else {
      addResp = await this.props.cartAddProduct(addData)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }

          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp), 
          });
        });
    }

    if(!addResp) {
      return null;
    }

    if(this.props.isBuylist) {
      if(!this.props.cart.buylistMode) {
        this.props.setBuylistMode(true);
      }
    } else {

      // Fire add_to_cart analytics event
      addToCartEvent(this.props.selectedInvObj, this.props.productObj, {
        quantity: this.state.qty,
      });

      if(this.props.cart.buylistMode) {
        this.props.setBuylistMode(false);
      }
    }

    this.props.cartFetchCart()
    .then((resp) => {
      this.props.cartToggleMinicartSlider();
      this.setState({
        requestPending: false,
        requestError: null, 
      });
    })
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }

      this.setState({
        requestPending: false,
        requestError: formatServerError(errResp), 
      });
    });
  }

  isAddDisabled() {
    // Add pending
    if(this.state.requestPending) {
      return true;
    }

    // Check to make sure a single inv is selected
    if(this.props.selectedInvObj === null) {
      return true;
    }

    const cart = this.props.isBuylist ? this.props.cart.currentBuylistCart : this.props.cart.currentCart;
    const cartQty = cart ? cart.getInventoryQuantity(this.props.selectedInvObj) : 0;

    if(this.props.isBuylist) {
      if(cartQty >= PROD_BUYLIST_QTY_LIMIT) {
        return true;
      }
    } else {
      if(cartQty >= this.props.selectedInvObj.totalQuantity) {
        return true;
      }
    }

    // Check language
    if(this.state.language === null && this.shouldRequireLanguage()) {
      return true;
    }
    return false;
  }

  goBack() {
    history.goBack();
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductPageInventory'}>
      <div className={'productPageInventoryWrapper'}>
        <div className='ppiConfigWrapper'>

          {this.props.productObj && this.props.productObj.inventory && this.props.productObj.inventory.length > 1 ?
            <div className='ppConfigResetLink' onClick={this.resetConfigs.bind(this)}>
              {t(tx.TX_RESET)}
            </div> :
            null
          }
          
          {this.shouldDisplayLanguage() && (!this.props.isBuylist || isBuylistCheckoutEnabled()) ?
            <ProductPageInventoryConfigLanguage
              productObj={this.props.productObj}
              value={this.state.language && this.state.language.code ? this.state.language : null}
              setAttr={this.setLanguage.bind(this)}
              inventoryOptions={this.props.inventoryOptions}
              searchFilters={this.props.searchFilters} /> :
            null
          }

          {this.shouldDisplayFinish() && (!this.props.isBuylist || isBuylistCheckoutEnabled()) ?
            <ProductPageInventoryConfigFinish
              productObj={this.props.productObj}
              value={this.state.finish && this.state.finish.key ? this.state.finish : null}
              setAttr={this.setFinish.bind(this)}
              exclusionFilters={{
                language: this.state.language,
              }}
              searchFilters={this.props.searchFilters} /> :
            null
          }

          {this.shouldDisplayPrinting() && (!this.props.isBuylist || isBuylistCheckoutEnabled()) ?
            <ProductPageInventoryConfigPrinting
              productObj={this.props.productObj}
              value={this.state.printing && this.state.printing.key ? this.state.printing : null}
              setAttr={this.setPrinting.bind(this)}
              exclusionFilters={{
                finish: this.state.finish,
                language: this.state.language,
              }}
              searchFilters={this.props.searchFilters} /> :
            null
          }

          {this.shouldDisplayCondition() && (!this.props.isBuylist || isBuylistCheckoutEnabled()) ?
            <ProductPageInventoryConfigCondition
              isBuylist={this.props.isBuylist}
              productObj={this.props.productObj}
              value={this.getSelectedCondition()}
              setAttr={this.setCondition.bind(this)}
              inventoryOptions={this.props.inventoryOptions}
              searchFilters={this.props.searchFilters} /> :
            null
          }

        </div>
        <div className='ppiPriceWrapper'>
          <ProductPageInventoryPrice
            isBuylist={this.props.isBuylist}
            productObj={this.props.productObj}
            invArray={this.props.inventoryOptions}
            selectedInvObj={this.props.selectedInvObj} />
        </div>

        {!this.props.isBuylist || isBuylistCheckoutEnabled() ?
          <div className='ppiQuantityWrapper'>
            <ProductPageInventoryQuantity
              isBuylist={this.props.isBuylist}
              productObj={this.props.productObj}
              qty={this.state.qty}
              setQty={this.setQuantity.bind(this)}
              selectedInvObj={this.props.selectedInvObj} />
          </div> :
          null
        }
          

        {this.props.isBuylist ?
          <div className='ppiNoticeWrapper'>
            <div className='ppiNotice'>{t(tx.TX_PRODUCT_PAGE_BUYLIST_NOTICE)}</div>
            {isBuylistCheckoutEnabled() ?
              <div className='ppiNotice'>{t(tx.TX_PRODUCT_PAGE_BUYLIST_NOTICE_2)}</div> :
              <div className='ppiNotice'>{t(tx.TX_PRODUCT_PAGE_BUYLIST_NOTICE_IN_STORE)}</div>
            }
          </div> :
          <>
            {this.props.productObj.isReleased === false ?
              <div className='ppiNoticeWrapper'>
                <div className='ppiNotice chiefNotice'>{t(tx.TX_PRODUCT_PAGE_PREORDER_NOTICE)}</div>
                <div className='ppiEmHr'></div>
                <div className='ppiNotice'>
                  <div className='noticeLabel'>{t(tx.TX_RELEASE_DATE)}</div>
                  <div className='noticeValue'>{serverDateFull(this.props.productObj.releaseDate)}</div>
                </div>
                <div className='ppiNotice'>{t(tx.TX_PRODUCT_PAGE_PREORDER_NOTICE_2)}</div>
              </div> :
              null
            }
          </>
        }

        {!this.props.isBuylist || isBuylistCheckoutEnabled() ?
          <div className='ppiActionWrapper'>
            {this.state.requestPending ?
              <div className='ppiActionWorkingWrapper'>
                <div className='ppiActionWorkingWrapperIconWrapper'>
                  <LoadingIcon />
                </div>
              </div> :
              <button 
                type='button' 
                className='ppiAddToCart'
                disabled={this.isAddDisabled()}
                onClick={this.addToCart.bind(this)}>
                {t(this.props.isBuylist ? tx.TX_ADD_TO_BUYLIST : tx.TX_ADD_TO_CART)}
              </button>
            }
          </div> :
          null
        }


      </div>
      {this.props.allowBack ?
        <div className='ppBackWrapper'>
          <button type='button' className='ppBack' onClick={this.goBack.bind(this)}>{t(tx.TX_BACK)}</button>
        </div> :
        null
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageInventory));