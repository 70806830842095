import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const allActionCreators = Object.assign({});

export class PrivacyOasis extends Component {

  constructor(props) {
    super(props);

    this.storeName = this.props.user.store.name || process.env.REACT_APP_WEBSITE_NAME;
  }

  render() {
    return <div className={'PrivacyOasis legalBody'}>
      <div className='legalSection'>
        <div className='sectionTitle'>Your Privacy</div>
        <div className='sectionP'>{this.storeName}  is committed to protecting your privacy. We only use the information we collect about you to process orders and to provide support and upgrades for our products. Please read on for more details about our privacy policy.</div>
        <div className={'sectionP pHeader'}>What information do we collect? How do we use it?</div>
        <div className='sectionP'>When you order, we need to know your name, e-mail address, mailing address, credit card number, and expiration date. This allows us to process and fulfill your order and to notify you of your order status. When you enter a contest or other promotional feature, we may ask for your name, address, and e-mail address so we can administer the contest and notify winners. You agree to be added to our mailing list for future promotions and deals. When you order through our website, you are opting in to receive newsletters about promotions, coupons, and important company announcements.</div>
        <div className={'sectionP pHeader'}>Will Oasis Games disclose the information it collects to outside parties?</div>
        <div className='sectionP'>Oasis Games does not sell, trade, or rent your personal information to companies not affiliated with Oasis Games. Oasis Games may provide aggregate statistics about our customers, sales, traffic patterns, and related site information to reputable third-party vendors, but these statistics will not include personally identifying information.</div>
        <div className='sectionP'>Oasis Games may release account information when we believe, in good faith, that such release is reasonably necessary to (i) comply with law, (ii) enforce or apply the terms of any of our user agreements or (iii) protect the rights, property or safety of Oasis Games, our users, or others.</div>
        <div className={'sectionP pHeader'}>In Summary</div>
        <div className='sectionP'>Oasis Games is committed to protecting your privacy. We use the information we collect on the site to make shopping at oasisgamesslc.com as simple as possible and to enhance your overall shopping experience. We do not sell, trade, or rent your personal information to others.</div>
        <div className={'sectionP pHeader'}>Your Consent</div>
        <div className='sectionP'>By using our Web site, you consent to the collection and use of this information by Oasis Games LLC. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.</div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PrivacyOasis));