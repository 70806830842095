import React from 'react';
// import { useTranslation } from 'react-i18next';

export const MaintenanceDefault = () => {

  // Internationalization
  // const { t } = useTranslation();

  return (
    <div className='MaintenanceDefault'>
      MaintenanceDefault
    </div>
  );
};

export default MaintenanceDefault;




