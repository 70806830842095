import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../../constants/strings';

import { 
  getCurrencyPrecision, 
  getCurrencySymbol, 
} from '../../../../utils/currency';
import { 
  formatPrice,
} from '../../../../utils/formatting';
import { 
  safeParseFloat,
  simulateTab,
} from '../../../../utils/general';
import { getStoreLanguage } from '../../../../utils/language';

const allActionCreators = Object.assign({});

export class FilterPriceRange extends Component {

  constructor(props) {
    super(props);

    this.inputTimeoutDuration = 10*1000; // 10 seconds

    this.state = {
      inputMin: this.getCurrentMinValue(),
      inputMax: this.getCurrentMaxValue(),
    }

    // Counters
    this.minTimeout = null;
    this.maxTimeout = null;

    this.submitMinValue = this.submitMinValue.bind(this);
    this.submitMaxValue = this.submitMaxValue.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevProps.filters, this.props.filters)) {
      
      if(prevProps.filters[prevProps.filterElement.minKey] !== this.props.filters[this.props.filterElement.maxKey]) {

        const wasPresent = Object.keys(prevProps.filters).includes(prevProps.filterElement.minKey);
        const isPresent = Object.keys(this.props.filters).includes(this.props.filterElement.minKey);

        if(wasPresent && !isPresent) {
          this.setState({ inputMin: '' });
        }
      }

      if(prevProps.filters[prevProps.filterElement.maxKey] !== this.props.filters[this.props.filterElement.maxKey]) {

        const wasPresent = Object.keys(prevProps.filters).includes(prevProps.filterElement.maxKey);
        const isPresent = Object.keys(this.props.filters).includes(this.props.filterElement.maxKey);

        if(wasPresent && !isPresent) {
          this.setState({ inputMax: '' });
        }
      }
    }
  }

  componentWillUnmount() {
    if(this.minTimeout) {
      clearTimeout(this.minTimeout);
    }
    if(this.maxTimeout) {
      clearTimeout(this.maxTimeout);
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  static formatTagValue(val) {
    return formatPrice(val);
  }

  changeMinValue(evt) {
    this.setState({
      inputMin: evt.target.value,
    }, () => {
      if(this.minTimeout) {
        clearTimeout(this.minTimeout);
      }
      if(this.validateMinValue()) {
        this.minTimeout = setTimeout(this.submitMinValue, this.inputTimeoutDuration);
      }
    });
  }

  validateMinValue() {
    try {
      if(this.state.inputMin === '') {
        return true;
      }
      
      const minInt = safeParseFloat(this.state.inputMin);
      if(minInt < 0) {
        return false;
      }

    } catch(err) {
      return false;
    }
    return true;
  }

  keyPressMin(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.submitMinValue();
      simulateTab();
    }
  }

  submitMinValue() {
    if(this.validateMinValue()) {

      try {

        const filterValue = this.getCurrentMinValue();

        // Intentionally use the crappy one to avoid any string/number confusion
        // eslint-disable-next-line
        if(this.state.inputMin != filterValue) {

          const minInt = parseFloat(this.state.inputMin);
          const maxInt = parseFloat(this.state.inputMax);

          const newMax = maxInt && minInt > maxInt ? minInt : null;

          if(this.state.inputMin === '' && Object.keys(this.props.filters).includes(this.props.filterElement.minKey)) {
            this.props.clearFilter(this.props.filterElement.minKey);
            this.setState({ inputMin: '' });
          } else if(this.state.inputMin !== '') {
            
            const newFilters = { [this.props.filterElement.minKey]: safeParseFloat(this.state.inputMin) };
            const newState = { inputMin: safeParseFloat(this.state.inputMin).toFixed(getCurrencyPrecision()) };

            if(newMax) {
              newFilters[this.props.filterElement.maxKey] = newMax;
              newState['inputMax'] = newMax.toFixed(getCurrencyPrecision());
            }
            this.props.setFilters(newFilters);
            this.setState(newState);
          }

          if(this.minTimeout) {
            clearTimeout(this.minTimeout);
          }
        }
      } catch(err) {
        console.error(err);
      }
    }
  }

  getCurrentMinValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.minKey) === false) {
        return '';
      } else {
        return this.props.filters[this.props.filterElement.minKey];
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  changeMaxValue(evt) {
    this.setState({
      inputMax: evt.target.value,
    }, () => {
      if(this.maxTimeout) {
        clearTimeout(this.maxTimeout);
      }
      if(this.validateMaxValue()) {
        this.maxTimeout = setTimeout(this.submitMaxValue, this.inputTimeoutDuration);
      }
    });
  }

  validateMaxValue() {
    try {
      if(this.state.inputMax === '') {
        return true;
      }
      
      const maxInt = safeParseFloat(this.state.inputMax);
      if(maxInt < 0) {
        return false;
      }

    } catch(err) {
      return false;
    }
    return true;
  }

  keyPressMax(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.submitMaxValue();
      simulateTab();
    }
  }

  submitMaxValue() {
    if(this.validateMaxValue()) {

      try {

        const filterValue = this.getCurrentMaxValue();

        // Intentionally use the crappy one to avoid any string/number confusion
        // eslint-disable-next-line
        if(this.state.inputMax != filterValue) {

          const minInt = parseFloat(this.state.inputMin);
          const maxInt = parseFloat(this.state.inputMax);

          const newMin = minInt && minInt > maxInt ? maxInt : null;

          if(this.state.inputMax === '' && Object.keys(this.props.filters).includes(this.props.filterElement.maxKey)) {
            this.props.clearFilter(this.props.filterElement.maxKey);
            this.setState({ inputMax: '' });
          } else if(this.state.inputMax !== '') {
            
            const newFilters = { [this.props.filterElement.maxKey]: safeParseFloat(this.state.inputMax) };
            const newState = { inputMax: safeParseFloat(this.state.inputMax).toFixed(getCurrencyPrecision()) };

            if(newMin) {
              newFilters[this.props.filterElement.minKey] = newMin;
              newState['inputMin'] = newMin.toFixed(getCurrencyPrecision());
            }
            this.props.setFilters(newFilters);
            this.setState(newState);
          }

          if(this.maxTimeout) {
            clearTimeout(this.maxTimeout);
          }
        }
      } catch(err) {
        console.error(err);
      }
    }
  }

  getCurrentMaxValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.maxKey) === false) {
        return '';
      } else {
        return this.props.filters[this.props.filterElement.maxKey];
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterPriceRange AdminTableFilter'}>
      <div className='filterElementTitle'>
        <div className='filterElementTitleValue'>{t(this.props.filterElement.name)}</div>
        <div className='filterElementClearLink' onClick={this.props.clearFilter.bind(this)}>{t(tx.TX_CLEAR)}</div>
      </div>
      <div className='filterElementBody'>
        <div className='rangeInput'>
          <div className={'minMaxWrapper minWrapper'}>
            <div className='minMaxInput'>
              <input
                type='number'
                name={`${t(this.props.filterElement.name)} ${t(tx.TX_MIN)}`}
                value={this.state.inputMin}
                step={1}
                min={0}
                placeholder={t(tx.TX_MIN)}
                onChange={this.changeMinValue.bind(this)}
                onBlur={this.submitMinValue.bind(this)}
                onKeyPress={this.keyPressMin.bind(this)} />
              <div className='currencyOverlay'>{getCurrencySymbol()}</div>
            </div>
          </div>
          <div className='separatorWrapper'>&mdash;</div>
          <div className={'minMaxWrapper maxWrapper'}>
            <div className='minMaxInput'>
              <input
                type='number'
                name={`${t(this.props.filterElement.name)} ${t(tx.TX_MAX)}`}
                value={this.state.inputMax}
                step={1}
                min={0}
                placeholder={t(tx.TX_MAX)}
                onChange={this.changeMaxValue.bind(this)}
                onBlur={this.submitMaxValue.bind(this)}
                onKeyPress={this.keyPressMax.bind(this)} />
              <div className='currencyOverlay'>{getCurrencySymbol()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterPriceRange));