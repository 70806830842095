import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Dropzone from 'react-dropzone';

import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../../constants/urls';

import { ProductMedia } from '../../../models/products';

import { getErrorProductImage } from '../../../utils/files';

import { ProductMediaSortableGrid } from './ProductMediaSortableGrid';

let allActionCreators = Object.assign({});

export class AddProductMediaGeneric extends Component {

  constructor(props) {
    super(props);

    this.state = {

      mediaArray: this.props.mediaArray ? this.props.mediaArray : [],
      mediaId: this.props.mediaArray ? this.props.mediaArray.length + 1 : 1, 

      errorMedia: '',

      dragCount: 0,
    };

    this.fileDropRef = React.createRef();
  }

  syncMedia() {
    this.props.setMedia(this.state.mediaArray);
  }

  dragEnterInput(evt) {
    for(const tp of evt.dataTransfer.types) {
      if(tp === 'Files') {
        this.setState({ dragCount: this.state.dragCount + 1 });
        break;
      }
    }
  }

  dragLeaveInput(evt) {
    this.setState({ dragCount: Math.max(this.state.dragCount - 1, 0) });
  }

  dragDropInput(files) {

    let newFiles = [];
    let nextId = this.state.mediaId;

    for(const fl of files) {
      let fileError = getErrorProductImage(fl);
      if(fileError !== '') {
        this.setState({
          errorMedia: fileError,
          dragCount: 0,
        });
        return null;
      } else {
        newFiles.push(new ProductMedia({
          id: nextId,
          file: fl,
        }));
        nextId++;
      }
    }

    let oldFiles = [...this.props.mediaArray];
    this.setState({
      mediaArray: oldFiles.concat(newFiles),
      errorMedia: '',
      mediaId: nextId,
      dragCount: 0,
    }, () => {
      this.syncMedia();
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'AddProductMediaGeneric'}>
      <div className='imagesPreviewWrapper'>
        <ProductMediaSortableGrid
          fileArray={this.props.mediaArray}
          setMedia={this.props.setMedia} />
      </div>
      <div className='fileDropzoneWrapper'>
        <Dropzone onDrop={acceptedFiles => this.dragDropInput(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div 
                  ref={this.fileDropRef}
                  className={this.state.dragCount ? 'dropWrapper hoverFile' : 'dropWrapper'}
                  onDragEnter={this.dragEnterInput.bind(this)}
                  onDragLeave={this.dragLeaveInput.bind(this)}>
                  <div className='dropPrompt'>{t(tx.TX_INV_ADD_PRODUCT_DRAG_HERE)}</div>
                  {this.state.errorMedia ?
                    <div className='dropError'>{t(this.state.errorMedia)}</div> :
                    null
                  }
                  <button 
                    className='dropButton'
                    type='button'>
                    {t(tx.TX_INV_ADD_PRODUCT_UPLOAD_IMAGE)}
                  </button>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <div className='adminActionRow'>
        <Link 
          className={'adminAction adminActionCancel'} 
          to={URL_ADMIN_INVENTORY}>
          {t(tx.TX_CANCEL)}
        </Link>
        <button 
          className={'adminAction adminActionSave'} 
          type='button'
          onClick={this.props.saveProduct.bind(this)}>
          {t(tx.TX_SAVE)}
        </button>
        <button 
          className={'adminAction adminActionBack adminLeft'} 
          type='button'
          onClick={this.props.movePrev.bind(this)}>
          {t(tx.TX_BACK)}
        </button>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductMediaGeneric));