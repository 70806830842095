import { useEffect, useRef, useCallback } from 'react';
import * as _ from 'underscore';

const ResizeListener = ({ onResize, throttle = 50 }) => {
  
  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    onResize(width, height);
  }, [onResize]);

  const throttledHandleResize = useRef(_.throttle(handleResize, throttle)).current;

  useEffect(() => {
    window.addEventListener('resize', throttledHandleResize);
    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, [throttledHandleResize]);

  return null;
};

export default ResizeListener;
