import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import { URL_HOME } from '../../constants/urls';

import { 
  legalGetReturnPolicyComponent, 
  legalHasReturnPolicy,
} from '../../utils/legal';

import LoadingIcon from '../Icons/LoadingIcon';

import './style/_legal.scss';

const allActionCreators = Object.assign({});

export class ReturnPolicy extends Component {

  constructor(props) {
    super(props);

    if(!legalHasReturnPolicy(process.env.REACT_APP_STORE_CODE)) {
      history.replace(URL_HOME);
    }
  }

  getReturnComponent() {
    const ReturnComponent = legalGetReturnPolicyComponent(process.env.REACT_APP_STORE_CODE);
    return <ReturnComponent />;
  }

  goBack() {
    history.goBack();
  }

  render() {

    const {t} = this.props;

    return <div className={'ReturnPolicy LegalPage'}>
      <div className='legalLiner'>
        <div className={'legalHeader returns'}>
          <div className='titleWrapper'>
            <div className='pageTitle'>
              <div className='pageTitleCopy'>{t(tx.TX_LEGAL_RETURN_POLICY)}</div>
            </div>
          </div>
        </div>
        {!this.props.user.store || !this.props.user.store.publicUuid ?
          <div className='legalLoading'>
            <div className='loadingWrapper'>
              <div className='loadingIconWrapper'>
                <LoadingIcon />
              </div>
              <div className='loadingLabel'>{t(tx.TX_LOADING)}</div>
            </div>
          </div> :
          <>
            <div className='legalBodyWrapper'>{this.getReturnComponent()}</div>
            <div className='legalActionWrapper'>
              <button type='button' className='legalPrimaryAction' onClick={this.goBack.bind(this)}>{t(tx.TX_BACK)}</button>
            </div>
          </>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ReturnPolicy));