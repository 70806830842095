

import {
	GEO_COUNTRY_CL,
	GEO_COUNTRY_US,
} from './geographies';
import {
	TX_SHIPMENT_CARRIER_CHILEXPRESS, 
	TX_SHIPMENT_CARRIER_FEDEX,
	TX_SHIPMENT_CARRIER_UPS,
	TX_SHIPMENT_CARRIER_USPS,
	TX_SHIPMENT_STATUS_SHIPPED, 
	TX_OTHER,
} from './strings';

///////////////////////////////////
///          STATUSES           ///
/////////////////////////////////// 


export const SHIPMENT_STATUS_KEY_SHIPPED = 'shipped';

export const SHIPMENT_STATUS_SHIPPED = {
	key: SHIPMENT_STATUS_KEY_SHIPPED,
	name: TX_SHIPMENT_STATUS_SHIPPED,
}

export const SHIPMENT_STATUSES_ALL = [
	SHIPMENT_STATUS_SHIPPED, 
];


///////////////////////////////////
///          CARRIERS           ///
/////////////////////////////////// 


export const SHIPMENT_CARRIER_KEY_CHILEXPRESS = 'chilexpress';
export const SHIPMENT_CARRIER_KEY_FEDEX = 'fedex';
export const SHIPMENT_CARRIER_KEY_OTHER = 'other';
export const SHIPMENT_CARRIER_KEY_UPS = 'ups';
export const SHIPMENT_CARRIER_KEY_USPS = 'usps';


export const SHIPMENT_CARRIER_USPS = {
	key: SHIPMENT_CARRIER_KEY_USPS,
	name: TX_SHIPMENT_CARRIER_USPS,
	hasTracking: true,
	trackingUrl: `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{trackingNumber}}`,
	countries: [ GEO_COUNTRY_US ],
};

export const SHIPMENT_CARRIER_UPS = {
	key: SHIPMENT_CARRIER_KEY_UPS,
	name: TX_SHIPMENT_CARRIER_UPS,
	hasTracking: true,
	trackingUrl: `https://www.ups.com/mobile/track?trackingNumber={{trackingNumber}}`,
	countries: [ GEO_COUNTRY_US ],
};

export const SHIPMENT_CARRIER_FEDEX = {
	key: SHIPMENT_CARRIER_KEY_FEDEX,
	name: TX_SHIPMENT_CARRIER_FEDEX,
	hasTracking: true,
	trackingUrl: `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber={{trackingNumber}}`,
	countries: [ GEO_COUNTRY_US ],
};

export const SHIPMENT_CARRIER_CHILEXPRESS = {
	key: SHIPMENT_CARRIER_KEY_CHILEXPRESS,
	name: TX_SHIPMENT_CARRIER_CHILEXPRESS,
	hasTracking: true,
	trackingUrl: `https://centrodeayuda.chilexpress.cl/seguimiento/{{trackingNumber}}`,
	countries: [ GEO_COUNTRY_CL ],
};

export const SHIPMENT_CARRIER_OTHER = {
	key: SHIPMENT_CARRIER_KEY_OTHER,
	name: TX_OTHER,
	hasTracking: false,
	trackingUrl: null,
};





export const SHIPMENT_CARRIERS_ALL = [
	SHIPMENT_CARRIER_USPS, 
	SHIPMENT_CARRIER_UPS, 
	SHIPMENT_CARRIER_FEDEX, 
	SHIPMENT_CARRIER_CHILEXPRESS, 
	SHIPMENT_CARRIER_OTHER,
];
