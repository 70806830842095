import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class PaymentMethodDetailsView extends Component {

  render() {

    const {t} = this.props;

    return <div className={'PaymentMethodDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_CHECKOUT_PAYMENT_METHOD)}</div>
      </div>
      {this.props.paymentMethod.isEnabled === false ?
        <div className='detailsMethodDisabled'>{t(tx.TX_CURRENTLY_DISABLED)}</div> : 
        null
      }
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_NAME)}</div>
                  <div className='adminViewValue'>{this.props.paymentMethod.getDisplayName(t)}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_ENABLED_QUESTION)}</div>
                  <div className='adminViewValue'>{this.props.paymentMethod.isEnabled ? t(tx.TX_ENABLED) : t(tx.TX_DISABLED)}</div>
                </div>
              </div>
              <div className='adminViewButtonWrapper'>
                <div className={'paymentMethodDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.paymentMethodPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PaymentMethodDetailsView));