import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';
import { 
	URL_ADMIN_CUSTOMERS_ADD, 
	URL_ADMIN_ORDERS, 
	URL_ADMIN_ORDERS_BUYLIST_CARTS_VIEW,
	URL_ADMIN_ORDERS_CARTS_VIEW,
} from '../../../../constants/urls';

import { stringFormat } from '../../../../utils/formatting';

const allActionCreators = Object.assign({});

export class CartModalChangeCustomerNullResults extends Component {

  getLink() {
  	const linkObj = {
  		pathname: URL_ADMIN_CUSTOMERS_ADD,
  	}
  	if(this.props.cart) {
  		const uuidObj = { publicUuid: this.props.cart.publicUuid };
  		linkObj['state'] = { from: this.props.cart.isBuylist ? stringFormat(URL_ADMIN_ORDERS_BUYLIST_CARTS_VIEW, uuidObj) : stringFormat(URL_ADMIN_ORDERS_CARTS_VIEW, uuidObj) };
  	} else {
  		linkObj['state'] = { from: URL_ADMIN_ORDERS };
  	}
  	return linkObj;
  }

  render() {

    const {t} = this.props;

    return <div className={'CartModalChangeCustomerNullResults'}>
      <div className='cartNullLiner'>
        {this.props.lastQuery ?
          <>
          	<div className='nullCopyLine'>
          		<div className='noResultsCopy'>{t(tx.TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING)}</div>
	            <div className='noResultsNeedle'>{this.props.lastQuery}</div>
          	</div>
	          <div className='nullActionWrapper'>
	          	<Link to={this.getLink()} className='nullAction'>{t(tx.TX_ADD_NOUN, { noun: t(tx.TX_CUSTOMER) })}</Link>
	          </div>
          </> :
          <div className='noResultsCopy'>{t(tx.TX_NO_RESULTS)}</div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CartModalChangeCustomerNullResults));