import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

let allActionCreators = Object.assign({});

export class ProductPageMediaForeignModel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedMedia: 0, 
      visibleLoaded: false, 
    }
  }

  visibleMediaLoaded(isVisible) {
    
    if(!isVisible) { return null; }
    
    if(isVisible && !this.state.visibleLoaded) {
      this.setState({
        visibleLoaded: true, 
      });
    }
  }

  setSelectedMedia(idx) {
    this.setState({
      selectedMedia: idx, 
    });
  }

  getForeignModel() {
    return this.props.modelOverride || this.props.productObj.foreignModel;
  }

  render() { 

    const foreignModel = this.getForeignModel();

    return <div className={'ProductPageMediaForeignModel'}>
      <div className='ppMediaInnerWrapper'>
        <div className='ppMediaPrimary'>
          <div className='ppMediaPrimaryWrapper'>
            {foreignModel ?
              foreignModel.getPrimaryImage() :
              null
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageMediaForeignModel));