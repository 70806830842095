import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_CHECK, 
} from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  getNotEmptyError,
  isFormValid, 
} from '../../../utils/form-validation';

import Icon from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class AccountInputField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      editing: false,

      inputValue: this.props.value,

      errorValue: '',

      requestOriginated: false,
    }

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.requestOriginated === true && prevProps.pending === true && this.props.pending === false) {
      this.setState({
        requestOriginated: false,
      });
      this.toggleEdit();
    }
  }

  getDisplayValue() {
    if(this.props.password) {
      return this.props.t(tx.TX_PLACEHOLDER_PASSWORD);
    } else {
      return this.props.value;
    }
  }

  toggleEdit() {

    if(this.props.password) {
      if(this.props.openPasswordModal) {
        this.props.openPasswordModal();
      }
      return null;
    }

    this.setState({
      editing: !this.state.editing,
      inputValue: this.props.value, 
    }, () => {
      if(this.state.editing && this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      } else if(this.state.editing === false) {
        this.setState({
          errorValue: '',
        });
      }
    });
  }

  changeInput(evt) {
    this.setState({
      inputValue: evt.target.value,
    }, () => {
      if(this.state.errorValue) {
        this.validateInput();
      }
    });
  }

  validateInput() {
    this.setState({ errorValue: getNotEmptyError(this.state.inputValue) });
  }

  validateAll() {
    const errorObj = {
      errorValue: this.props.validationMethod ? this.props.validationMethod(this.state.inputValue) : getNotEmptyError(this.state.inputValue),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  submitConfig(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.props.pending) {
        return null;
      }

      this.setState({
        requestOriginated: true,
      });

      this.props.setUserAttribute(this.props.apiKey, this.state.inputValue);
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'AccountInputField'}>
      <div className={'accountInputLabel'}>{t(this.props.label ? this.props.label : tx.TX_null)}</div>
      {this.state.editing ?
        <form className='aiEdit' onSubmit={this.submitConfig.bind(this)}>
          <div className='aiEditInputWrapper'>
            <input 
              type='text'
              ref={this.inputRef}
              className='aiEditInput'
              value={this.state.inputValue}
              onChange={this.changeInput.bind(this)} />
            {this.state.errorValue ?
              <div className={'aiEditError FieldError'}>{t(this.state.errorValue)}</div> :
              null
            }
          </div>
          <div className='aiEditSaveAction' onClick={this.submitConfig.bind(this)}>
            <div className='aiEditSaveActionIconWrapper'>
              {this.state.requestOriginated ?
                <LoadingIcon
                  iconClass={'aiEditSaveActionIcon'} /> :
                <Icon 
                  value={ICON_CHECK}  
                  iconClass={'aiEditSaveActionIcon'} />
              }
            </div>
          </div>
          <div className='aiEditCancelAction'>
            <div className={'aiEditCancelActionIconWrapper FlexCenter'} onClick={this.toggleEdit.bind(this)}>&times;</div>
          </div>
        </form> :
        <div className={'adminViewValue configValue'}>
          <span className={'adminViewValueData EllipsisElement'}>{this.getDisplayValue()}</span>
          <span 
            className={this.props.value || this.props.password ? 'adminViewValueToggle' : 'adminViewValueToggle noMainValue'}
            onClick={this.toggleEdit.bind(this)}>
            {t(this.props.value ? tx.TX_EDIT : (this.props.password ? tx.TX_CHANGE : tx.TX_ADD))}
          </span>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountInputField));