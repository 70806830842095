
import React from 'react';


const Message = ({ component: Component, render, ...rest }) => {

  return <svg 
    aria-hidden='true' 
    focusable='false' 
    data-prefix='fas' 
    data-icon='message' 
    className='iconSVG' 
    role='img' 
    xmlns='http://www.w3.org/2000/svg' 
    viewBox='0 0 512 512'>
    <path 
      d='M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z'>
    </path>
  </svg>;
};

export default Message;