import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Papa from 'papaparse';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_CUSTOMERS,
  URL_ADMIN_CUSTOMERS_BULK_UPLOAD,
  URL_ADMIN_CUSTOMERS_BULK_UPLOAD_VIEW,
} from '../../constants/urls';

import { stringFormat } from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import CustomerBulkUploadInput from './blocks/CustomerBulkUploadInput';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';

import './style/_bulkupload.scss';

import * as customerActionCreators from '../../actions/customer';
let allActionCreators = Object.assign({}, customerActionCreators);

export class CustomerBulkUploadCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,
      lastBlock: '',
      savePromptOpen: false,

      requestError: null,
      requestPending: false,
      
      csvRecords: null,
      csvProcessing: false,
      csvUploading: false,
    };
  }

  componentDidMount() {
    
    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  makeDirty() {
    if(this.state.isDirty === false) {
      this.setState({ isDirty: true });
    }
  }

  initiateUpload(csvFile, setTotalBalance, createNewUsers) {
    if(!csvFile)  { return null; }

    this.setState({
      requestError: null,
      requestPending: true,
      
      csvRecords: null,
      csvProcessing: true,
      csvUploading: false,
    });

    Papa.parse(csvFile, {
      worker: true,
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        this.setState({
          csvRecords: results.data.length,
          csvProcessing: false,
          csvUploading: true,
        }, () => {
          this.makeUploadRequest(results, setTotalBalance, createNewUsers, csvFile.path);
        });
      },
    });
  }

  async makeUploadRequest(csvData, setTotalBalance, createNewUsers, filename) {

    const uploadData = {
      records: this.state.csvRecords,
      set_total_balance: setTotalBalance,
      create_new_users: createNewUsers,
      upload_filename: filename,
    }

    // const resp = await this.props.productInitiateAsyncBulkImport(splitData)
    //   .catch((errResp) => {
    //     console.error(errResp);
    //   });

    // if(!resp || !resp.product_upload || !resp.presigned_post) {
    //   return null;
    // }

    const splitString = Papa.unparse(csvData, {});
    const splitBlob = new Blob([splitString], { type: 'text/plain' });
    const splitFile = new File([splitBlob], 'customer-upload.csv', {type: 'text/csv'});

    const resp = await this.props.customerProcessBulkUploadCSV(uploadData, splitFile)
      .catch((errResp) => {
        console.error(errResp);
      });

    if(!resp) { return null; }

    this.unblock();
    history.replace(stringFormat(URL_ADMIN_CUSTOMERS_BULK_UPLOAD_VIEW, { publicUuid: resp.customer_upload.publicUuid }));
  }

  render() {

    const {t} = this.props;

    return <div className={'CustomerBulkUploadCreate AdminPage'}>
      <AdminTitle
        title={tx.TX_CREATE}
        breadcrumbs={[
          {
            url: URL_ADMIN_CUSTOMERS,
            title: tx.TX_CUSTOMERS,
          },
          {
            url: URL_ADMIN_CUSTOMERS_BULK_UPLOAD,
            title: tx.TX_INV_BULK_UPLOAD_BULK_UPLOADS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminSection'>

          <div className='adminSectionTitle'>
            <div className='asTitleWrapper'>{t(tx.TX_CUSTOMER_BULK_UPLOAD)}</div>
          </div>

          <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
          
          <div className='buCreateWrapper'>
            <div className='buComponentWrapper'>
              <CustomerBulkUploadInput
                initiateUpload={this.initiateUpload.bind(this)}
                makeDirty={this.makeDirty.bind(this)} />
            </div>
          </div>
          
          {this.state.requestPending ?
            <div className='uploadLoading'>
              <div className='uploadLoadingWrapper'>
                <div className='iconWrapper'>
                  <LoadingIcon />
                </div>
                <div className='labelWrapper'>
                  {this.state.csvProcessing ? t(tx.TX_INV_BULK_UPLOAD_PROCESSING_CSV) : (this.state.csvUploading ? t(tx.TX_INV_BULK_UPLOAD_UPLOADING_CSV) : '')}
                </div>
              </div>
            </div> :
            null
          }
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={null}
        onCancel={this.leaveWithoutSave.bind(this)}
        confirmLabel={tx.TX_BACK} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerBulkUploadCreate));