import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import JSZip from 'jszip';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../../../constants/api';
import { TC_HEADER_SORT_DIR_ASC } from '../../../../constants/tables';

import { getStoreLanguage } from '../../../../utils/language';
import { 
  getPageLimit, 
  getPageOffset,
} from '../../../../utils/request';
import * as tx from '../../../../constants/strings';

import LoadingIcon from '../../../Icons/LoadingIcon';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class BulkActionOrderInvoices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      exportPending: false,
      exportData: [],
      exportType: null,
    }

    this.EXPORT_DOWNLOAD = 'download';
    this.EXPORT_PRINT = 'print';

    this.API_PAGE_SIZE = 50;

    this.controller = null;
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getSortApiKey() {
    if(this.props.sortDir === TC_HEADER_SORT_DIR_ASC) {
      return this.props.sortKey;
    } else {
      return `-${this.props.sortKey}`;
    }
  }

  getResultsCopy(resultsCount) {
    const t = this.props.t;
    try {
      if(resultsCount === 0) {
        return t(tx.TX_GALLERY_NO_RESULTS);
      } else if(resultsCount === 1) {
        return t(tx.TX_RESULTS_ONE);
      } else if(resultsCount > 0) {
        return t(tx.TX_RESULTS_NUM, { num: resultsCount.toLocaleString(this.getLanguage()) });
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  async fetchDownloadSlug(exportType, pageNum) {

    const exportData = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, this.API_PAGE_SIZE),
      [API_KEY_OFFSET]: getPageOffset(pageNum, this.API_PAGE_SIZE),
      [API_KEY_SORT]: this.getSortApiKey(),
      full_export: 'True',
    };

    const orderUuids = [];
    for(const odr of this.props.selectedRows) {
      orderUuids.push(odr.publicUuid);
    }
    exportData['uuids'] = orderUuids.join(',');

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    const downloadResp = await this.props.ordersAdminFetchPage(exportData, controller.signal)
      .catch((errResp) => {

        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({
          exportPending: false,
        });
      });

    if(!downloadResp) { return null; }

    return downloadResp || null;
  }

  async initiateExport(exportType) {

    if(!exportType) { return null; }

    this.setState({
      exportData: [],
      exportPending: true,
      exportType: exportType,
    });

    const lines = [];
    const initData = await this.fetchDownloadSlug(exportType, 1);

    if(!initData) { return null; }

    for(const ln of initData.data) {
      lines.push(ln);
    }

    if(initData.count > initData.data.length) {

      for(let i = 2; i <= Math.ceil(initData.count / initData.data.length); i++) {
        const newData = await this.fetchDownloadSlug(exportType, i);

        if(!newData) { break; }
        for(const ln of newData.data) {
          lines.push(ln);
        }
      }
    }

    this.setState({
      exportData: lines,
      exportPending: false,
    });
  }

  async exportAction() {
    if(this.state.exportPending || !this.state.exportData || this.state.exportData.length <= 0) { return null; }

    if(this.state.exportType === this.EXPORT_DOWNLOAD) {

      if(this.state.exportData.length === 1) {
        const inv = await this.state.exportData[0].generateInvoice(this.props.t, null);
        inv.save(`invoice-${this.state.exportData[0].displayNumber}.pdf`);
      } else {

        const zip = new JSZip();

        for(const order of this.state.exportData) {
          const inv = await order.generateInvoice(this.props.t, null);
          zip.file(`invoice-${order.displayNumber}.pdf`, inv.output('blob'));
        }

        zip.generateAsync({ type: 'blob' }).then((content) => {
          const url = URL.createObjectURL(content);
          const link = document.createElement('a');
          link.href = url;
          link.download = `invoices-${Date.now()}.zip`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }

    } else {

      // Print mode
      let mergedPdf = null;

      for(const order of this.state.exportData) {
        mergedPdf = await order.generateInvoice(this.props.t, mergedPdf);
      }

      // Prompt the user to print the PDF
      mergedPdf.autoPrint();

      // Open the print dialog
      window.open(mergedPdf.output('bloburl'), '_blank');
    }

    this.props.closeMethod();
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkActionOrderInvoices BulkActionModal'}>
      <div className='baWrapper'>
        
        {!this.props.selectedRows || this.props.selectedRows.length === 0 ?
          <div className='errorSelection'>
            <div className='errorBody'>
              <div className='errorLabel'>{t(tx.TX_ORDER_NO_ORDERS_SELECTED)}</div>
              <button className={'errorAction'} type='button' onClick={this.props.closeMethod.bind(this)}>{t(tx.TX_CLOSE)}</button>
            </div>
          </div> :
          <>
            {this.state.exportType === null ?
              <div className='selectionSection'>
                <div className='exportBlock'>
                  <div className='exportBlockLiner'>
                    <button 
                      type='button'
                      disabled={this.props.selectedRows && this.props.selectedRows.length === 0}
                      className='exportButton'
                      onClick={() => this.initiateExport(this.EXPORT_DOWNLOAD)}>
                      {t(tx.TX_DOWNLOAD)}
                    </button>
                    <div className='exportResultsWrapper'>{this.getResultsCopy(this.props.selectedRows.length)}</div>
                  </div>
                </div>
                <div className='exportBlock'>
                  <div className='exportBlockLiner'>
                    <button 
                      type='button'
                      disabled={this.props.selectedRows && this.props.selectedRows.length === 0}
                      className='exportButton'
                      onClick={() => this.initiateExport(this.EXPORT_PRINT)}>
                      {t(tx.TX_PRINT)}
                    </button>
                    <div className='exportResultsWrapper'>{this.getResultsCopy(this.props.selectedRows.length)}</div>
                  </div>
                </div>
              </div> :
              <>
                {this.state.exportPending ?
                  <div className='pendingSection'>
                    <div className='loadingBody'>
                      <div className='loadingIconLabel'>{t(tx.TX_ORDER_PREPARING_EXPORT)}</div>
                      <div className='loadingIconWrapper'>
                        <LoadingIcon />
                      </div>
                    </div>
                  </div> :
                  <div className='availableSection'>
                    <div className='completeBody'>
                      <div className='completeLabel'>{t(this.state.exportType === this.EXPORT_DOWNLOAD ? tx.TX_INV_BULK_UPLOAD_DOWNLOAD_READY : tx.TX_ORDER_EXPORT_READY)}</div>
                      <button className={'completeAction'} type='button' onClick={this.exportAction.bind(this)}>{t(this.state.exportType === this.EXPORT_DOWNLOAD ? tx.TX_DOWNLOAD : tx.TX_PRINT)}</button>
                    </div>
                  </div>
                }
              </>
            }
          </>
        }

      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkActionOrderInvoices));