import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { URL_CONTACT_US } from '../../../../constants/urls';

const allActionCreators = Object.assign({});

export class TermsOasisChile extends Component {

  constructor(props) {
    super(props);

    this.storeName = this.props.user.store.name || process.env.REACT_APP_WEBSITE_NAME;
  }

  render() {
    return <div className={'TermsOasisChile legalBody'}>
      <div className='legalSection'>
        <div className='sectionTitle'>Términos de uso</div>
        <div className='sectionP'>{this.storeName} se reserva la facultad y el derecho de: actualizar, modificar o eliminar contenido o información de este sitio web que estime conveniente ya sea parcial o totalmente, pudiendo hacer uso de tal facultad en cualquier momento y sin previo aviso.</div>
        <div className='sectionP'>Este derecho abarca los contenidos referidos a:</div>
        <div className='sectionP'>Disponibilidad de inventario de singles, Precios, Disponibilidad de productos o servicios, Formas de despacho, Formas de Pago o en cuanto a las funcionalidades del sitio o de la información de actividades, eventos, promociones, ofertas o materiales promocionales.</div>
        <div className='sectionP'>{this.storeName} se reserva la facultad de modificar unilateralmente las condiciones y términos de uso de este sitio web. La entrada en vigor de las modificaciones de las condiciones de uso se producirá desde el momento de su publicación en este sitio web.</div>
        <div className='sectionP'>Los precios y textos publicados en nuestra página están sujetos a variaciones y confirmaciones sin previo aviso.</div>
        <div className={'sectionP listHeader'}>Los pagos de tus órdenes son realizados en nuestra tienda física, vía webpay o a través de depósito bancario o transferencia, cuyos datos son mostrados en la página de pago cuando se está procesando el pedido.</div>
        <ul className='sectionUL'>
          <li>Si el depósito bancario o transferencia que el usuario realiza no aparece en la cuenta que {this.storeName} facilita, el pedido no será procesado el pedido y {this.storeName} no se hará responsable del destino del dinero depositado o transferido.</li>
        </ul>
        <div className='sectionP'>Los precios en esta página no reflejan necesariamente los precios de los productos en nuestras tienda fisica.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Retiro en Tienda</div>
        <div className='sectionP'>{this.storeName} ofrece la opción de retiro en tienda para todas las ventas.</div>
        <div className='sectionP'>Recuerda, debes esperar dos e-mails. El primero, confirma tu compra, y el segundo te avisa que ya puedes acercarte a la tienda a retirar tu producto. Desde el momento que recibes el segundo e-mail, tienes <span className='legalEm'>30 días</span> para retirar tu compra. Posterior a este plazo, tu orden será considerada abandonada.</div>
        <div className='sectionP'>Ten en cuenta que al retirar tus productos deberás presentar tu cédula de identidad y orden de compra. Si la compra no la retirarás tú mismo, debes autorizar a un tercero previamente vía correo electrónico y dicha persona deberá presentar su carnet de identidad, y el número de la orden de compra.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Contacto</div>
        <div className='sectionP'>Los únicos canales oficiales de comunicación de {this.storeName} son: Nuestra página web, correos electrónicos y nuestras redes sociales. Puedes encontrar links a ellas en nuestra <Link className='inlineLink' to={URL_CONTACT_US}>página web</Link>.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Créditos de Tienda</div>
        <div className='sectionP'>Se podrán utilizar para hacer pago de cualquier pedido válido en nuestra página web, o en nuestra tienda física, ya sea para Eventos, Ordenes de Singles, Productos Sellados, accesorios, etc.</div>
        <div className='sectionP'>Los créditos de Tienda <span className='legalEm'>NO</span> pueden ser utilizados junto con códigos de descuento promocionales, o en promociones que incluyan descuentos. Esto aplica para compras en nuestrá pagina web, y en nuestra tienda fisica.</div>
        <div className='sectionP'>Los créditos de Tienda no son transferibles, ni serán cambiados por efectivo bajo ningun motivo.</div>
        <div className='sectionP'>Nos reservamos el derecho de eliminar cualquier orden, cuenta, o créditos de tienda, si consideramos que se están utilizando de manera maliciosa o en contra de los Terminos y Condiciones de uso.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Garantía {this.storeName}</div>
        <div className='sectionP'>Si banean una carta que compraste en {this.storeName} en los últimos 30 días (de cualquier formato sancionado por Wizards of the Coast), puedes traer o enviar la carta a la tienda y te reembolsaremos el 100% del valor pagado en crédito de tienda. (siempre y cuando la carta se encuentre en una condición similar a la de venta)</div>
        <div className='sectionP'>Para hacer efectivo esta garantía tienes 30 días desde el anuncio del ban y la compra debe estar registrada en tu cuenta personal de {this.storeName}.</div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(TermsOasisChile));