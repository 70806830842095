import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import {
  URL_ACCOUNT_ORDERS, 
  URL_ACCOUNT_ORDERS_BUYLIST_SINGLE, 
  URL_HOME, 
} from '../../constants/urls';

import { Order } from '../../models/orders';

import { purchaseEvent } from '../../utils/analytics';
import { isLoggedIn } from '../../utils/auth';
import { 
  formatPrice,
  stringFormat,
} from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';

import CartSummary from '../Cart/CartSummary';
import LoadingIcon from '../Icons/LoadingIcon';
import MiniCart from '../Cart/MiniCart';

import './style/_ordercomplete.scss';

import * as cartActionCreators from '../../actions/cart';
import * as orderActionCreators from '../../actions/order';
import * as productActionCreators from '../../actions/product';
let allActionCreators = Object.assign({}, cartActionCreators, orderActionCreators, productActionCreators);

export class OrderComplete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      order: new Order(),
      orderReqPending: true,
    };

    this.isBuylist = this.props && this.props.location && this.props.location.state ? this.props.location.state.isBuylist : false;
    this.orderUuid = this.props && this.props.location && this.props.location.state ? this.props.location.state.orderUuid : null;
  }

  componentDidMount() {

    if(!this.orderUuid) {
      history.replace(URL_HOME);
      return null;
    }

    // This should always fall in catch as cart will have just been archived
    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    this.props.ordersFetchOrder(this.orderUuid)
    .then((resp) => {
      this.setState({
        order: resp,
        orderReqPending: false,
      }, () => {

        if(!this.isBuylist) {
          // Fire purchase analytics event when user completes purchase
          purchaseEvent(this.state.order, {});
        }

        // Clear product filters
        this.props.productClearProductFilters();

        // Clear history
        const historyState = { ...history.location.state };
        delete historyState.orderUuid;
        history.replace({ 
          ...history.location, 
          state: historyState, 
        });
      });
    })
    .catch((errResp) => {
      history.push(URL_HOME);
    });
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderComplete'}>
      <div className='ocLiner'>
        <div className='ocHeaderWrapper'>
          <div className='ochTitle'>{t(tx.TX_CHECKOUT_COMPLETE_TITLE)}</div>
          <div className='ochCopy'>{t(tx.TX_CHECKOUT_COMPLETE_COPY, { storeName: process.env.REACT_APP_WEBSITE_NAME })}</div>
        </div>
        <div className='ocDetailsWrapper'>
          {this.state.orderReqPending ?
            <div className='orderLoading'>
              <div className='loadingIconWrapper'>
                <LoadingIcon />
              </div>
            </div> :
            <div className='ocDetailsData'>
              {!this.isBuylist ?
                <div className='ocdMethodsWrapper'>
                  <div className='ocdMethodsVr' />
                  <div className='ocdShippingMethodWrapper'>
                    <div className='methodBlockWrapper'>
                      <div className='ocdMethodsTitle'>{t(tx.TX_SHIPPING_METHOD)}</div>
                      {this.state.order.shippingMethod ?
                        <>
                          <div className='methodName'>{this.state.order.shippingMethod.name}</div>
                          <div className='methodDescription'>{this.state.order.shippingMethod.description}</div>
                        </> :
                        null
                      }
                    </div>
                    <div className='addressBlockWrapper'>
                      <div className='ocdMethodsTitle'>{t(tx.TX_SHIPPING_ADDRESS)}</div>
                      {this.state.order ?
                        <div 
                          className='methodName'
                          dangerouslySetInnerHTML={{ __html: this.state.order.shippingAddress.format({ addTags: true }) }} /> :
                        null
                      }
                    </div>
                  </div>
                  <div className='ocdPaymentMethodWrapper'>
                    

                    {this.state.order.shippingMethod.isPickup && this.props.user && this.props.user.store ?
                      <>
                        <div className='methodBlockWrapper'>
                          <div className='ocdMethodsTitle'>{t(tx.TX_ORDER_CUSTOMER_PICKUP_LOCATION)}</div>
                            <div 
                              className='methodName'
                              dangerouslySetInnerHTML={{ __html: this.props.user.store.address.format({ addTags: true }) }} />
                        </div>
                      </> :
                      null
                    }

                    {this.state.order.hasPaymentRecord() ?
                      <div className='methodBlockWrapper'>
                        <div className='ocdMethodsTitle'>{t(tx.TX_PAYMENT_METHOD)}</div>
                        <div 
                          className='methodName'
                          dangerouslySetInnerHTML={{ __html: this.state.order.paymentRecord.format(t, { addTags: true, language: this.getLanguage() }) }} />
                      </div> :
                      <>
                        {this.state.order && this.state.order.paymentMethod ?
                          <div className='methodBlockWrapper'>
                            <div className='ocdMethodsTitle'>{t(tx.TX_PAYMENT_METHOD)}</div>
                            <div className='methodName'>{this.state.order.paymentMethod.getDisplayName(t)}</div>
                          </div> :
                          null
                        }
                      </>
                    }

                    {this.state.order.totalStoreCredit > 0 ?
                      <>
                        <div className='methodBlockWrapper'>
                          <div className='ocdMethodsTitle'>{t(tx.TX_ORDER_STORE_CREDIT)}</div>
                            <div 
                              className='methodName'
                              dangerouslySetInnerHTML={{ __html: 
                                t(tx.TX_ORDER_AMOUNT_APPLIED, { 
                                  amount: `<span class='amountEm'>${formatPrice(this.state.order.totalStoreCredit, { addTags: true, language: this.getLanguage() })}</span>`, 
                                }),
                              }} />
                        </div>
                      </> :
                      null
                    }

                    {this.state.order.billingAddress ?
                      <div className='addressBlockWrapper'>
                        <div className='ocdMethodsTitle'>{t(tx.TX_BILLING_ADDRESS)}</div>
                        {this.state.order ?
                          <>
                            {this.state.order.billingSameAsShipping() ?
                              <div className='addressNote'>{t(tx.TX_CHECKOUT_BILLING_ADDRESS_SAME_SHORT)}</div> :
                              <div 
                                className='methodName'
                                dangerouslySetInnerHTML={{ __html: this.state.order.billingAddress.format({ addTags: true }) }} />
                            }
                          </> :
                          null
                        }
                      </div> :
                      null
                    }
                  </div>
                </div> :
                null
              }
              <div className='ocdOrderDetailsWrapper'>
                <div className='ocdDetailsTitle'>
                  <div className='ocdDetailsTitleValue'>{t(tx.TX_ORDER_ORDER_ITEMS)}</div>
                </div>
                <div className='ocdDetailsCart'>
                  <MiniCart
                    isBuylist={this.isBuylist}
                    cartOverride={this.state.order ? (this.isBuylist ? this.state.order.buylistCart : this.state.order.cart) : null}
                    readOnly={true} />
                </div>
              </div>
              <div className='ocdOrderSummaryWrapper'>
                <CartSummary
                  isBuylist={this.isBuylist}
                  order={this.state.order}
                  cartOverride={this.state.order ? this.state.order.orderCart : null}
                  readOnly={true} />
              </div>
            </div>
          }
        </div>
        <div className='ocOptionsWrapper'>
          <Link to={URL_HOME}>
            <button type='button' className='optionButton'>{t(tx.TX_CHECKOUT_COMPLETE_BACK_HOME)}</button>
          </Link>
          {isLoggedIn() && !this.isBuylist ?
            <Link to={URL_ACCOUNT_ORDERS}>
              <button type='button' className='optionButton'>{t(tx.TX_CHECKOUT_COMPLETE_VIEW_ORDERS)}</button>
            </Link> :
            null
          }
          {this.isBuylist ?
            <Link to={stringFormat(URL_ACCOUNT_ORDERS_BUYLIST_SINGLE, { publicUuid: this.orderUuid })}>
              <button type='button' className='optionButton'>{t(tx.TX_CHECKOUT_COMPLETE_VIEW_STATUS)}</button>
            </Link> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderComplete));