import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { FV_KEY_SORT } from '../../constants/filters';
import { 
  GV_DISPLAY_TYPE_THUMBNAIL, 
  GV_HEADER_SORT_DIR_ASC, 
} from '../../constants/gallery';
import * as tx from '../../constants/strings';

import { 
  getGalleryComponentThemeClass, 
  getGalleryDefaultPageSize, 
  getGalleryDefaultSortDir, 
  getGalleryDefaultSortKey, 
  getGalleryDisplayType, 
  getGalleryStoreThemeClass, 
  getGalleryWidthClass, 
} from '../../utils/gallery';

import GalleryHeader from './blocks/GalleryHeader';
import GalleryPagination from './blocks/GalleryPagination';
import LoadingIcon from '../Icons/LoadingIcon';
import ThumbnailGallery from './ThumbnailGallery';

import './style/_gallery.scss';

let allActionCreators = Object.assign({});

export class Gallery extends Component {

  constructor(props) {
    super(props);

    this.state = {

      sortKey: getGalleryDefaultSortKey(this.props.config, this.props.filters),
      sortDir: getGalleryDefaultSortDir(this.props.config, this.props.filters),

      pageNum: 1,
      pageSize: getGalleryDefaultPageSize(this.props.config),

      galleryWidth: null,
      galleryWidthClass: '',
    };

    // Create refs
    this.gallerySection = React.createRef();

    // Bind event listeners
    this.updateWidthStateThrottled = _.throttle(this.updateWidthState.bind(this), 250);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWidthStateThrottled, false);
    if(this.gallerySection && this.gallerySection.current) {
      this.updateWidthState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.signal !== this.props.signal) {
      this.updateWidthState();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidthStateThrottled, false);
  }

  updateWidthState() {

    if(!this.gallerySection.current) {
      return null;
    }

    this.setState({ 
      galleryWidth: this.gallerySection.current.clientWidth, 
      galleryWidthClass: getGalleryWidthClass(this.gallerySection.current.clientWidth), 
    });
  }

  getGalleryElement() {

    if(!this.props.config) {
      return null;
    }

    const displayType = getGalleryDisplayType(this.props.config);
    if(displayType === null) {
      return null;
    }

    switch(displayType) {
      case GV_DISPLAY_TYPE_THUMBNAIL:
        return <ThumbnailGallery
                  config={this.props.config}
                  data={this.getData()}
                  galleryWidth={this.state.galleryWidth}
                  isBuylist={this.props.isBuylist}
                  selectAction={this.props.selectAction} />;
      default:
        return null;
    }
  }

  changeSort(key, dir) {
    this.setState({ 
      sortKey: key, 
      sortDir: dir,  
      pageNum: 1,
    }, () => {
      this.refreshApiData(false);
    });
  }

  changePage(pageNum, pageSize) {
    if(!pageNum || !pageSize) {
      return;
    }

    this.setState({
      pageNum: pageNum,
      pageSize: pageSize,
    }, () => {
      this.refreshApiData(false);
      this.scrollGallery();
    });
  }

  addInfinitePage() {

    if(this.props.infiniteLoading) {
      return null;
    }

    if(this.allDataLoaded()) {
      return null;
    }

    this.setState({
      pageNum: this.state.pageNum + 1,
    }, () => {
      this.refreshApiData(true);
    });
  }

  allDataLoaded() {
    if(!this.props.dataCount) {
      return false;
    }
    if(this.getData().length === this.props.dataCount) {
      return true;
    }
    return false;
  }

  scrollGallery() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  getData() {
    if(!this.props.data) {
      return [];
    }
    return this.props.data;
  }

  getSortApiKey() {
    if(this.state.sortDir === GV_HEADER_SORT_DIR_ASC) {
      return this.state.sortKey;
    } else {
      return this.state.sortKey ? `-${this.state.sortKey}` : '';
    }
  }

  refreshApiData(infiniteRequest = false) {
    // Call API
    // Function should all confirm to: callApi(filters, pageNum, pageSize)
    // sort is a filter parameter

    // Currently, sort and pagination are the only things that call the API (no filter changes)
    // This could be extended in the future

    const filters = {
      [FV_KEY_SORT]: this.getSortApiKey(), 
    }
    this.props.callApi(filters, this.state.pageNum, this.state.pageSize, infiniteRequest);
  }

  getGalleryParentClass() {

    const storeTheme = getGalleryStoreThemeClass(this.props.config);
    const componentTheme = getGalleryComponentThemeClass(this.props.config);

    return `Gallery${this.state.galleryWidthClass}${storeTheme}${componentTheme}`;
  }

  hasNoResults() {
    return !this.props.dataLoading && this.props.dataCount === 0;
  }

  render() {

    const {t} = this.props;

    return <div className={this.getGalleryParentClass()} ref={this.gallerySection}>
      <GalleryHeader
        config={this.props.config}
        dataCount={this.props.dataCount}
        sortDir={this.state.sortDir}
        sortKey={this.state.sortKey}
        changeSort={this.changeSort.bind(this)} />
      <div className='galleryLoadingArea'>
        {this.hasNoResults() ?
          <div className='galleryBodyNoResults'>{t(tx.TX_GALLERY_NO_RESULTS)}</div> :
          this.getGalleryElement()
        }
        <div className={this.props.dataLoading ? 'galleryLoading loading' : 'galleryLoading'}>
          <div className='galleryLoadingScreen'></div>
          <div className='galleryLoadingWrapper'>
            <LoadingIcon />
          </div>
        </div>
      </div>
      {this.hasNoResults() === false ?
        <GalleryPagination
          config={this.props.config}
          changePage={this.changePage.bind(this)}
          pageNum={this.state.pageNum}
          pageSize={this.state.pageSize}
          dataCount={this.props.dataCount}
          dataLoading={this.props.dataLoading}
          addInfinitePage={this.addInfinitePage.bind(this)}
          infiniteLoading={this.props.infiniteLoading} /> :
        null
     }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Gallery));