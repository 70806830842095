import 'whatwg-fetch';

import * as x from '../constants/actions';
import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authSetPermissions, authTokenRefresh } from './auth';

import { Address } from '../models/geographies';
import { Store } from '../models/stores';
import { 
	StoreCreditEntry,
	User, 
} from '../models/users';

import { isLoggedIn } from '../utils/auth';
import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/

export function userSetUser(payload) {
  return {
    type: x.ACTION_USER_SET,
    payload
  };
}

export function usersFetchMePending(payload) {
	return {
    type: x.ACTION_USER_SET_PENDING,
    payload
  };
}

// Payload must be model to save local
export function usersSetStore(payload) {
	return {
		type: x.ACTION_USER_SET_STORE,
		payload
	}
}

export function usersStoreFetchPending(payload) {
	return {
    type: x.ACTION_USER_STORE_FETCH_PENDING,
    payload
  };
}



/******************************
*******  Asynchronous  ********
******************************/


export function userFetchStore(signal = null) {
	return async (dispatch, getState) => {
		dispatch(usersStoreFetchPending(true));
		const url = a.API_TITAN_API + n.ENDPOINT_STORE_FETCH;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(usersStoreFetchPending(false));
	    if(isOk(resp.status)) {
	    	dispatch(usersSetStore(new Store(resp.data)));
	    	return Promise.resolve(true);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function userEmailExists(email, signal) {
	return dispatch => {

		const getParams = {
			email: email,
		};

		const url = a.API_TITAN_API + n.ENDPOINT_USER_EMAIL_AVAILABLE + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function usersRegister(registerData) {
	return dispatch => {

		const url = a.API_TITAN_API + n.ENDPOINT_USER_REGISTER;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(registerData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function usersLogin(loginData) {
	return dispatch => {

		const url = a.API_TITAN_API + n.ENDPOINT_USER_LOGIN;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(loginData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function usersReAuth(reAuthData, signal = null) {
	return dispatch => {

		const url = a.API_TITAN_API + n.ENDPOINT_USER_REAUTH;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(reAuthData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function usersLogout() {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_LOGOUT;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify({}),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function usersFetchMe(signal = null) {
	return async (dispatch, getState) => {

		if(!isLoggedIn() || getState().user.userPending) {
			return Promise.reject(null);
		}
		dispatch(usersFetchMePending(true));

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_ME;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(usersFetchMePending(false));
	    if(isOk(resp.status)) {
	    	dispatch(authSetPermissions(resp.data.user.permissions ? resp.data.user.permissions : {}));
	    	dispatch(userSetUser(new User(resp.data.user)));
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function usersUpdateMe(userData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_UPDATE_ME;
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(userData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function usersForgotPassword(forgotData) {
	return dispatch => {

		const url = a.API_TITAN_API + n.ENDPOINT_USER_FORGOT;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(forgotData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function usersResetPassword(resetData) {
	return dispatch => {

		const url = a.API_TITAN_API + n.ENDPOINT_USER_RESET;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(resetData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function usersSetPassword(setData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_CHANGE;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(setData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function usersCreateGuest(guestData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_GUEST_CREATE;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(guestData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function usersFetchSavedAddresses() {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_FETCH_ADDRESSES;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function usersCreateAddress(addressData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_CREATE_ADDRESS;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addressData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Address(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function usersUpdateAddress(addressData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_UPDATE_ADDRESS, { public_uuid: addressData.public_uuid });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addressData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Address(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function usersAddressTaxRate(publicUuid, signal) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADDRESS_TAX_RATES, { publicUuid: publicUuid });
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function userStoreCreditHistory(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_STORE_CREDIT_HISTORY + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const entryArray = [];
	    	for(const e of resp.data.data) {
	    		entryArray.push(new StoreCreditEntry(e));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: entryArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

























