import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getFullName } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import { 
  getDefaultStyle,
  getDefaultTranslate,
  getDefaultValue,
} from '../../../../utils/table';

let allActionCreators = Object.assign({});

export class CombinedIdentityElement extends Component {

  getLanguage() {
  	const { i18n } = this.props;
  	return getStoreLanguage(i18n);
  }

  render() {  

    const {t} = this.props;

    return <div className={'CombinedIdentityElement'}>
      {getFullName(this.props.firstName || '', this.props.lastName || '', this.getLanguage()).trim() ?
        <div className='combinedIdentityElementWrapper'>
        	<div className='cieName'>{getFullName(this.props.firstName || '', this.props.lastName || '', this.getLanguage())}</div>
        	<div className='cieEmail'>{this.props.email ? this.props.email : ''}</div>
        </div> :
        <div className='combinedIdentityElementWrapper'>
          <div className='cieName' style={this.props.dataValue ? {} : getDefaultStyle(this.props.schema)}>{getDefaultTranslate(this.props.schema) ? t(getDefaultValue(this.props.schema)) : getDefaultValue(this.props.schema)}</div>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CombinedIdentityElement));


