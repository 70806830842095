import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isLoggedIn } from '../../../utils/auth';

import HeaderAccountLoggedIn from './HeaderAccountLoggedIn';
import HeaderAccountLoggedOut from './HeaderAccountLoggedOut';

import '../style/_headeraccount.scss';

let allActionCreators = Object.assign({});

export class HeaderAccount extends Component {

  render() {
    return <div className='HeaderAccount'>
      {isLoggedIn() ?
        <HeaderAccountLoggedIn /> :
        <HeaderAccountLoggedOut />
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderAccount));


