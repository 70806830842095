import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';
import { toDate } from 'date-fns-tz';

import { ICON_CALENDAR } from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';

import { 
  serverDateFromJs, 
  serverDateShort, 
} from '../../../../utils/formatting';
import { simulateTab } from '../../../../utils/general';
import { 
  getLanguageObjectFromCode,
  getStoreLanguage, 
} from '../../../../utils/language';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Icon from '../../../Icons/Icon';

const allActionCreators = Object.assign({});

export class FilterDateRange extends Component {

  constructor(props) {
    super(props);

    this.inputTimeoutDuration = 10*1000; // 10 seconds

    this.state = {
      inputMin: this.getCurrentMinValue(),
      inputMax: this.getCurrentMaxValue(),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevProps.filters, this.props.filters)) {
      
      if(prevProps.filters[prevProps.filterElement.minKey] !== this.props.filters[this.props.filterElement.maxKey]) {

        const wasPresent = Object.keys(prevProps.filters).includes(prevProps.filterElement.minKey);
        const isPresent = Object.keys(this.props.filters).includes(this.props.filterElement.minKey);

        if(wasPresent && !isPresent) {
          this.setState({ inputMin: '' });
        }
      }

      if(prevProps.filters[prevProps.filterElement.maxKey] !== this.props.filters[this.props.filterElement.maxKey]) {

        const wasPresent = Object.keys(prevProps.filters).includes(prevProps.filterElement.maxKey);
        const isPresent = Object.keys(this.props.filters).includes(this.props.filterElement.maxKey);

        if(wasPresent && !isPresent) {
          this.setState({ inputMax: '' });
        }
      }
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getLocale() {
    const lang = getLanguageObjectFromCode(this.getLanguage());

    if(!lang) { return ''; }
    return lang.locale || '';
  }

  static formatTagValue(val) {
    return serverDateShort(toDate(val));
  }

  changeMinValue(minDate) {
    this.setState({
      inputMin: minDate || '',
    }, () => {
      if(this.validateMinValue()) {
        this.submitMinValue();
      }
    });
  }

  validateMinValue() {
    try {
      if(this.state.inputMin === '') {
        return true;
      }

      if(this.state.inputMin instanceof Date === false) {
        return false;
      }

    } catch(err) {
      return false;
    }
    return true;
  }

  keyPressMin(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.submitMinValue();
      simulateTab();
    }
  }

  submitMinValue() {
    if(this.validateMinValue()) {
      try {

        const filterValue = this.getCurrentMinValue();

        // Intentionally use the crappy one to avoid any string/number confusion
        // eslint-disable-next-line
        if(serverDateFromJs(this.state.inputMin) != filterValue) {

          const newMax = this.state.inputMax && this.state.inputMin > this.state.inputMax ? this.state.inputMin : null;

          if(this.state.inputMin === '' && Object.keys(this.props.filters).includes(this.props.filterElement.minKey)) {
            this.props.clearFilter(this.props.filterElement.minKey);
            this.setState({ inputMin: '' });
          } else if(this.state.inputMin !== '') {
            
            const newFilters = { [this.props.filterElement.minKey]: serverDateFromJs(this.state.inputMin) };
            const newState = { inputMin: this.state.inputMin };

            if(newMax) {
              newFilters[this.props.filterElement.maxKey] = serverDateFromJs(newMax);
              newState['inputMax'] = newMax;
            }
            this.props.setFilters(newFilters);
            this.setState(newState);
          }
        }
      } catch(err) {
        console.error(err);
      }
    }
  }

  getCurrentMinValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.minKey) === false) {
        return '';
      } else {
        return this.props.filters[this.props.filterElement.minKey];
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  changeMaxValue(minDate) {
    this.setState({
      inputMax: minDate || '',
    }, () => {
      if(this.validateMaxValue()) {
        this.submitMaxValue();
      }
    });
  }

  validateMaxValue() {
    try {
      if(this.state.inputMax === '') {
        return true;
      }
      
      if(this.state.inputMax instanceof Date === false) {
        return false;
      }

    } catch(err) {
      return false;
    }
    return true;
  }

  keyPressMax(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.submitMaxValue();
      simulateTab();
    }
  }

  submitMaxValue() {
    if(this.validateMaxValue()) {

      try {

        const filterValue = this.getCurrentMaxValue();

        // Intentionally use the crappy one to avoid any string/number confusion
        // eslint-disable-next-line
        if(this.state.inputMax != filterValue) {

          const newMin = this.state.inputMin && this.state.inputMin > this.state.inputMax ? this.state.inputMax : null;

          if(this.state.inputMax === '' && Object.keys(this.props.filters).includes(this.props.filterElement.maxKey)) {
            this.props.clearFilter(this.props.filterElement.maxKey);
            this.setState({ inputMax: '' });
          } else if(this.state.inputMax !== '') {
            
            const newFilters = { [this.props.filterElement.maxKey]: serverDateFromJs(this.state.inputMax) };
            const newState = { inputMax: this.state.inputMax };

            if(newMin) {
              newFilters[this.props.filterElement.minKey] = serverDateFromJs(newMin);
              newState['inputMin'] = newMin;
            }
            this.props.setFilters(newFilters);
            this.setState(newState);
          }
        }
      } catch(err) {
        console.error(err);
      }
    }
  }

  getCurrentMaxValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.maxKey) === false) {
        return '';
      } else {
        return this.props.filters[this.props.filterElement.maxKey];
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterDateRange AdminTableFilter'}>
      <div className='filterElementTitle'>
        <div className='filterElementTitleValue'>{t(this.props.filterElement.name)}</div>
        <div className='filterElementClearLink' onClick={this.props.clearFilter.bind(this)}>{t(tx.TX_CLEAR)}</div>
      </div>
      <div className='filterElementBody'>
        <div className='rangeInput'>
          <div className={'minMaxWrapper minWrapper'}>
            <div className='minMaxInput'>

              <DatePicker 
                selected={this.state.inputMin} 
                onChange={(date) => this.changeMinValue(date)}
                locale={this.getLocale()}
                closeOnScroll={true}
                dateFormat='P'
                placeholderText={t(tx.TX_MIN)} />

              <div className='calendarOverlay'>
                <Icon value={ICON_CALENDAR} />
              </div>
            </div>
          </div>
          <div className='separatorWrapper'>&mdash;</div>
          <div className={'minMaxWrapper maxWrapper'}>
            <div className='minMaxInput'>

              <DatePicker 
                selected={this.state.inputMax} 
                onChange={(date) => this.changeMaxValue(date)}
                locale={this.getLocale()}
                closeOnScroll={true}
                dateFormat='P'
                placeholderText={t(tx.TX_MAX)} />

              <div className='calendarOverlay'>
                <Icon value={ICON_CALENDAR} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterDateRange));