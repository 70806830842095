import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { StoreDaySchedule } from '../../../models/stores';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../utils/currency';
import { 
  getCostError,
  getNameError, 
  getPurchaseAmountError, 
  getShortDescriptionError, 
  isFormValid, 
} from '../../../utils/form-validation';
import { 
  formatServerError,
  normalizePrice,
} from '../../../utils/formatting';

import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';
import StoreSettingsHourLine from './StoreSettingsHourLine';
import Toggle from '../../Input/Toggle';

import * as checkoutActionCreators from '../../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class StoreSettingsHoursEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      requestPending: false,
      requestError: null,

      resetConfirmOpen: false,
      savePromptOpen: false, 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  saveAction(evt) {
    // if(evt) { evt.preventDefault(); }
    // if(this.validateAll()) {

    //   const updatedShippingMethod = new ShippingMethod({
    //     is_enabled: this.state.inputStatus,
    //     name: this.state.inputName,
    //     description: this.state.inputDescription,
    //     cost: this.state.inputCost,
    //     min_purchase: this.state.inputMinPurchase,
    //   });

    //   this.setState({
    //     requestPending: true,
    //     requestError: null,
    //   });

    //   this.props.checkoutUpdateShippingMethod(updatedShippingMethod.getApiData(), this.props.shippingMethod.publicUuid)
    //   .then((resp) => {
    //     this.setState({
    //       requestPending: false,
    //       requestError: null,
    //     }, () => {
    //       if(this.props.remoteSave) {
    //         this.props.finishRemote('details');
    //       } else {
    //         this.props.makeClean('details');
    //       }
    //       this.props.toggleEdit();
    //     });
    //   })
    //   .catch((errResp) => {
        
    //     console.error(errResp);
    //     this.setState({ 
    //       requestPending: false,
    //       requestError: formatServerError(errResp),
    //     });

    //     if(this.props.remoteSave) {
    //       this.props.finishRemote('details', true);
    //     }
    //   });
    // } else {
    //   // Validation failed, pass back to parent
    //   this.props.finishRemote('details', true);
    // }
  }

  validateAll() {
    // const errorObj = {
    //   errorName: getNameError(this.state.inputName),
    //   errorDescription: getShortDescriptionError(this.state.inputDescription, true),
    //   errorCost: getCostError(this.state.inputCost),
    //   errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase),
    // };
    // this.setState(errorObj);
    // return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  render() {

    const {t} = this.props;

    if(!this.props.user.store || !this.props.user.store.schedule) { return null; }

    return <div className={'StoreSettingsHoursEdit adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_SETTINGS_STORE_SETTINGS_HOURS)}</div>
      </div>
      <div className={'adminSectionBody expandableBody expanded'}>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'storeHoursForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

            {StoreDaySchedule.WEEK.map((dayCode, i) => {
              return <div key={i} className='storeHourRowWrapper'>
                <StoreSettingsHourLine
                  dayCode={dayCode}
                  makeDirty={this.makeDirty.bind(this)} />
              </div>;
            })}

            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StoreSettingsHoursEdit));