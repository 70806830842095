
import * as x from '../constants/actions';
import { STORAGE_NOTICES } from '../constants/storage';

import { WebsiteNotice } from '../models/notices';

import * as storage from '../persistance/storage';

const localNotices = WebsiteNotice.getLocal();

const initialState = {
  notices: localNotices || [],
  noticesPending: false,
  noticesDismissed: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_STORE_SET_NOTICES:
      return storeSetNotices(state, action);
    case x.ACTION_STORE_NOTICES_PENDING:
      return storeNoticesPending(state, action);
    case x.ACTION_STORE_NOTICE_DISMISS:
      return storeNoticeDismiss(state, action);
    case x.ACTION_STORE_NOTICE_RESET_DISMISSALS:
      return storeNoticeResetDismissals(state, action);
    default:
      return state;
  }
}


function storeSetNotices(state, action) {

	const noticesJson = [];
	const noticesModels = action.payload;

	for(const nm of noticesModels) {
		noticesJson.push(nm.toJson());
	}
	storage.put(STORAGE_NOTICES, noticesJson);

  return Object.assign({}, state, {
    notices: noticesModels,
  });
}

function storeNoticesPending(state, action) {
  return Object.assign({}, state, {
    noticesPending: action.payload,
  });
}

function storeNoticeDismiss(state, action) {

	const currentDismissals = [ ...state.noticesDismissed ];
	if(!currentDismissals.includes(action.payload)) {
		currentDismissals.push(action.payload);
	}

	return Object.assign({}, state, {
    noticesDismissed: currentDismissals,
  });
}

function storeNoticeResetDismissals(state, action) {
  return Object.assign({}, state, {
    noticesDismissed: [],
  });
}