import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../../constants/strings';

import { 
  formatPrice,
  formatServerError, 
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import '../../style/_vieworder.scss';

import LoadingIcon from '../../../Icons/LoadingIcon';
import OrderLineItemGradable from '../line-items/OrderLineItemGradable';
import Toggle from '../../../Input/Toggle';

import * as orderActionCreators from '../../../../actions/order';
import * as paymentsActionCreators from '../../../../actions/payments';
const allActionCreators = Object.assign({}, orderActionCreators, paymentsActionCreators);

export class OrderModalGradeItems extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestPending: true,
      requestError: null,
      requestObject: null,

      gradeStep: 0,

      inputReturnToInventory: true,
    };

    this.controller = null;
    this.controllerCreate = null;
    this.controllerFetch = null;
  }

  componentDidMount() {

    this.props.allowConfirm(false);
    this.props.setSuccessLabel(tx.TX_NEXT);

    if(this.props.order && this.props.order.gradings && this.props.order.gradings.length > 0) {
      this.setState({ requestObject: this.props.order.gradings[0] }, () => {
        this.fetchOrderGrading();
      });
    } else {
      this.createOrderGrading();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
    if(this.props.supplementalSignal && prevProps.supplementalSignal !== this.props.supplementalSignal) {
      this.lastStep();
    }
    if(!_.isEqual(prevState, this.state)) {
      this.props.didUpdate();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
    if(this.controllerCreate) {
      this.controllerCreate.abort();
    }
    if(this.controllerFetch) {
      this.controllerFetch.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  static confirmLabel() {
    return tx.TX_COMPLETE;
  }

  async createOrderGrading() {

    if(this.controllerCreate) {
      this.controllerCreate.abort();
    }
    const controllerCreate = new AbortController();
    this.controllerCreate = controllerCreate;

    this.setState({
      requestPending: true,
      requestError: null,
    });

    // Create order grading
    const createResp = await this.props.ordersAdminCreateGrading({}, this.props.order.publicUuid, controllerCreate.signal)
      .catch((errResp) => {
        
        if(controllerCreate.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          requestObject: null,
        });
      });

    if(!createResp || !createResp.public_uuid) {
      return null;
    }

    this.fetchOrderGrading(createResp.public_uuid)
  }

  async fetchOrderGrading(passedUuid = null) {

    const gradingUuid = this.state.requestObject ? this.state.requestObject.publicUuid : passedUuid;
    if(!gradingUuid) {
      return null;
    }

    if(this.controllerFetch) {
      this.controllerFetch.abort();
    }
    const controllerFetch = new AbortController();
    this.controllerFetch = controllerFetch;

    // Intentionally don't set request to loading so that it happens silently

    // Fetch order grading
    const gradingResp = await this.props.ordersAdminFetchGrading(this.props.order.publicUuid, gradingUuid, controllerFetch.signal)
      .catch((errResp) => {
        
        if(controllerFetch.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          requestObject: null,
        });
      });

    this.setState({
      requestPending: false,
      requestError: null,
      requestObject: gradingResp || null,
    }, () => {
      this.props.allowConfirm(true);
    });
  }

  validateAll() {
    return true;
  }

  nextStep() {
    this.setState({ 
      gradeStep: this.state.gradeStep + 1, 
    }, () => {
      this.props.setSuccessLabel(null);
      this.props.setCustomTitle(tx.TX_ORDER_COMPLETE_GRADING);
      this.props.setSupplementalActionLabel(tx.TX_BACK);
      this.props.completeAction();
    });
  }

  lastStep() {
    this.setState({ 
      gradeStep: 0, 
    }, () => {
      this.props.setSuccessLabel(tx.TX_NEXT);
      this.props.setCustomTitle(null);
      this.props.setSupplementalActionLabel(null);
      this.props.completeAction();
    });
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }

    if(this.state.gradeStep === 0) {
      this.nextStep();
    } else if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const completeData = {
        add_to_inventory: this.state.inputReturnToInventory,
      };

      const completeResp = await this.props.ordersAdminCompleteGrading(completeData, this.props.order.publicUuid, this.state.requestObject.publicUuid, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!completeResp) { return null; }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  getLineItems() {
    if(!this.props.order || !this.props.order.buylistCart) { return []; }

    const lineItemArray = [];
    for(const item of this.props.order.buylistCart.items) {
      for(let i = 0; i < item.quantity; i++) {
        lineItemArray.push({
          cartItem: item,
          itemGrade: item.grades[i] || null,
        });
      }
    }
    return lineItemArray;
  }

  changeReturnToInventory() {
    this.setState({ inputReturnToInventory: !this.state.inputReturnToInventory });
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalGradeItems OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'orderGradingForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          {this.state.gradeStep === 0 ?
            <div className='gradeStep0'>
              <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

              <div className='gradingScrollableWrapper'>
                <div className='gradingScrollableLiner'>

                  {this.state.requestObject === null ?
                    <div className='nullSpacer'></div> :
                    <>
                      {this.getLineItems().length === 0 ?
                        <div className='noItems'>
                          <div className='noItemsNotice'>{t(tx.TX_ORDER_GRADE_ITEMS_NO_ITEMS)}</div>
                        </div> :
                        <div className='itemsListWrapper'>
                          <div className='itemsList'>
                            {this.getLineItems().map((cartSingleLine, i) => {
                              return <div key={i} className={`lineItemWrapper`}>
                                <OrderLineItemGradable
                                  order={this.props.order}
                                  orderPending={this.props.orderPending}
                                  orderPendingSilently={this.props.orderPendingSilently}
                                  cartItem={cartSingleLine.cartItem}
                                  itemGrade={cartSingleLine.itemGrade}
                                  orderGrading={this.state.requestObject}
                                  loading={this.state.requestPending}
                                  makeClean={this.props.makeClean} />
                              </div>;
                            })}
                          </div>
                        </div>
                      }
                    </>
                  }
                </div>
              </div> 

              <div className='gradingTotalWrapper'>
                <div className='gradingTotalLiner'>
                  <div className='gradingTotalLabel'>{t(tx.TX_TOTAL)}</div>
                  {this.props.orderPendingSilently ?
                    <div className='gradingTotalValueLoading'>
                      <LoadingIcon />
                    </div> :
                    <div 
                      className='gradingTotalValue'
                      dangerouslySetInnerHTML={{ 
                        __html: this.props.order && this.props.order.buylistCart ? formatPrice(this.props.order.buylistCart.totalGraded, { addTags: true, language: this.getLanguage() }) : '', 
                      }} />
                  }
                </div>
              </div>
              
            </div> :
            <div className='gradeStep1'>
              <div className='step1Liner'>
                <div className='step1Copy'>{t(tx.TX_ORDER_GRADE_CONFIRM_COPY)}</div>
                <div className='step1ToggleWrapper'>
                  <Toggle
                    checked={this.state.inputReturnToInventory}
                    disabled={false}
                    onToggle={this.changeReturnToInventory.bind(this)}
                    trueValue={tx.TX_ORDER_GRADE_ADD_TRUE}
                    falseValue={tx.TX_ORDER_GRADE_ADD_FALSE} />
                </div>
              </div>
            </div>
          }
          <div className={`loadingOverlay ${this.state.requestPending ? 'active' : ''}`}>
            <div className='loadingIconWrapper'>
              <LoadingIcon />
            </div>
          </div>
        </form>    
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalGradeItems));