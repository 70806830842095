import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { STORE_INTEGRATION_TYPE_SHIPPING_KEY } from '../../constants/store';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_SETTINGS, 
  URL_ADMIN_SETTINGS_SHIPPING_METHODS, 
} from '../../constants/urls';

import { ShippingMethod } from '../../models/shipping-methods';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../utils/currency';
import { 
  isFormValid, 
  getNameError, 
  getCostError,
  getPurchaseAmountError,
  getShortDescriptionError,
  getWeightError,
} from '../../utils/form-validation';
import { 
  formatServerError,
  normalizeWeight, 
} from '../../utils/formatting';
import { getStoreDefaultWeightUnit } from '../../utils/general';
import { 
  convertWeightBetweenUnits,
  getWeightUnitFromKey, 
  getWeightUnitOptions, 
} from '../../utils/measurements';

import AdminTitle from '../Admin/AdminTitle';
import Dropdown from '../Input/Dropdown';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';
import Toggle from '../Input/Toggle';

import './style/_shippingmethods.scss';

import * as checkoutActionCreators from '../../actions/checkout';
import * as storeActionCreators from '../../actions/store';
let allActionCreators = Object.assign({}, checkoutActionCreators, storeActionCreators);

export class AddShippingMethod extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      savePromptOpen: false,

      inputName: '',
      inputDescription: '', 
      inputIsPickup: false,
      inputAllowInStorePayment: false, 
      inputCost: '', 
      inputMinPurchase: '', 
      inputMinWeight: '',
      inputMinWeightUnit: getStoreDefaultWeightUnit(),
      inputMaxWeight: '',
      inputMaxWeightUnit: getStoreDefaultWeightUnit(),
      inputIntegration: '', 

      errorName: '',
      errorDescription: '', 
      errorCost: '',
      errorMinPurchase: '', 
      errorMinWeight: '', 
      errorMaxWeight: '', 
      errorIntegrationConfig: '', 

      lastBlock: '',
      saveRedirect: false,

      requestPending: false,
      requestError: null,

      integrations: null,
      integrationsPending: false,

      integrationConfig: {},
      integrationValidationSignal: null,
    };

    this.controller = null;
  }

  componentDidMount() {
    
    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });

    this.fetchIntegrations();
  }

  componentWillUnmount() {
    this.unblock();
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  async fetchIntegrations() {

    this.setState({
      integrationsPending: true, 
    });

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    const integrationResp = await this.props.storeFetchIntegrationProvidersPage({}, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }
        this.setState({
          integrationsPending: false, 
        });
      });

    if(!integrationResp) { return null; }

    this.setState({
      integrations: this.getApplicableIntegrations(integrationResp.data),
      integrationsPending: false, 
    });
  }

  getApplicableIntegrations(providers) {

    const applicableIntegrations = [];
    try {
      for(const pro of providers) {
        for(const ing of pro.integrations) {
          if(ing.type && ing.type.key === STORE_INTEGRATION_TYPE_SHIPPING_KEY && ing.isEnabled) {
            applicableIntegrations.push(ing);
          }
        }
      }
    } catch(err) {
      console.error(err);
    }
    return applicableIntegrations;
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveAction();
    });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const newShippingMethod = new ShippingMethod({
        isEnabled: true,
        name: this.state.inputName,
        description: this.state.inputDescription,
        cost: this.state.inputCost,
        minPurchase: this.state.inputMinPurchase,
        minWeight: this.state.inputMinWeight || null,
        minWeightUnit: this.state.inputMinWeightUnit || null,
        maxWeight: this.state.inputMaxWeight || null,
        maxWeightUnit: this.state.inputMaxWeightUnit || null,
        isPickup: this.state.inputIsPickup,
        allowPaymentAtPickup: this.state.inputAllowInStorePayment,
        
        integration: this.state.inputIntegration,
        integrationConfig: this.state.integrationConfig,
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const addResp = await this.props.checkoutAddShippingMethod(newShippingMethod.getApiData())
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!addResp) {
        return null;
      }

      this.setState({ isDirty: false }, () => {
        if(!this.state.saveRedirect) {
          history.push(URL_ADMIN_SETTINGS_SHIPPING_METHODS);
        } else {
          history.push(this.state.lastBlock);
        }
      });
    }
  }

  validateAll() {
    const errorObj = {
      errorName: getNameError(this.state.inputName),
      errorDescription: getShortDescriptionError(this.state.inputDescription, true),
      errorCost: getCostError(this.state.inputCost, this.shouldExcludeCost()),
      errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase, !!this.state.inputIntegration),
      errorMinWeight: getWeightError(this.state.inputMinWeight, true),
      errorMaxWeight: getWeightError(this.state.inputMaxWeight, true),
      errorIntegrationConfig: this.validateIntegrationConfig(),
    };
    this.setState(Object.assign({}, errorObj, { integrationValidationSignal: Date.now() }));
    return isFormValid(errorObj);
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorName) {
        this.validateName();
      }
    });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  changeDescription(evt) {
    this.setState({
      inputDescription: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorDescription) {
        this.validateDescription();
      }
    });
  }

  validateDescription() {
    this.setState({ errorDescription: getShortDescriptionError(this.state.inputDescription, true) });
  }

  changePickup(evt) {
    this.setState({
      inputIsPickup: !this.state.inputIsPickup,
      isDirty: true,
    }, () => {
      this.setState({
        inputAllowInStorePayment: this.state.inputIsPickup,
      })
    });
  }

  changeAllowInStorePayment(evt) {
    this.setState({
      inputAllowInStorePayment: !this.state.inputAllowInStorePayment,
      isDirty: true,
    });
  }

  changeCost(evt) {
    this.setState({
      inputCost: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorCost) {
        this.validateCost();
      }
    });
  }

  validateCost() {
    this.setState({ errorCost: getCostError(this.state.inputCost, this.shouldExcludeCost()) });
  }

  changeMinPurchase(evt) {
    this.setState({
      inputMinPurchase: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorMinPurchase) {
        this.validateMinPurchase();
      }
    });
  }

  validateMinPurchase() {
    this.setState({ errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase) });
  }

  changeMinWeight(evt) {
    this.setState({
      inputMinWeight: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorMinWeight) {
        this.validateMinWeight(false);
      }
    });
  }

  validateMinWeight(shouldNormalize = true) {
    this.setState({ 
      inputMinWeight: shouldNormalize ? normalizeWeight(this.state.inputMinWeight, this.state.inputMinWeightUnit) : this.state.inputMinWeight, 
      errorMinWeight: getWeightError(this.state.inputMinWeight, true),
    });
  }

  changeMinWeightUnit(evt) {
    const previousUnit = this.state.inputMinWeightUnit;
    this.setState({
      inputMinWeightUnit: getWeightUnitFromKey(evt.target.value),
    }, () => {
      this.setState({
        inputMinWeight: normalizeWeight(convertWeightBetweenUnits(this.state.inputMinWeight, previousUnit, this.state.inputMinWeightUnit), this.state.inputMinWeightUnit),
      });
    });
  }

  changeMaxWeight(evt) {
    this.setState({
      inputMaxWeight: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorMaxWeight) {
        this.validateMaxWeight(false);
      }
    });
  }

  validateMaxWeight(shouldNormalize = true) {
    this.setState({ 
      inputMaxWeight: shouldNormalize ? normalizeWeight(this.state.inputMaxWeight, this.state.inputMaxWeightUnit) : this.state.inputMaxWeight, 
      errorMaxWeight: getWeightError(this.state.inputMaxWeight, true),
    });
  }

  changeMaxWeightUnit(evt) {
    const previousUnit = this.state.inputMaxWeightUnit;
    this.setState({
      inputMaxWeightUnit: getWeightUnitFromKey(evt.target.value),
    }, () => {
      this.setState({
        inputMaxWeight: normalizeWeight(convertWeightBetweenUnits(this.state.inputMaxWeight, previousUnit, this.state.inputMaxWeightUnit), this.state.inputMaxWeightUnit),
      });
    });
  }

  getIntegrationOptions() {
    if(!this.state.integrations || this.state.integrationsPending) { return []; }

    const dropdownOptions = [{
      display: this.props.t(tx.TX_NONE),
      value: '',
    }];
    for(const ing of this.state.integrations) {
      dropdownOptions.push({
        display: ing.provider.name,
        value: ing.publicUuid,
      });
    }
    return dropdownOptions;
  }

  changeIntegration(evt) {

    let selectedIntegration = '';
    const ingUuid = evt.target.value;
    
    for(const ing of this.state.integrations) {
      if(ing.publicUuid === ingUuid) {
        selectedIntegration = ing;
        break;
      }
    }

    this.setState({
      inputIntegration: selectedIntegration,
    }, () => {
      if(this.state.inputIntegration) {
        this.setState({
          inputIsPickup: false,
          inputAllowInStorePayment: false, 
          inputCost: '', 
          inputMinPurchase: '', 
          errorCost: '',
          errorMinPurchase: '', 
        });
      }
    });
  }

  shouldExcludePickup() {
    if(this.state.inputIntegration && this.state.inputIntegration.hidePickup) {
      return true;
    }
    return false;
  }

  shouldExcludeCost() {
    if(this.state.inputIntegration && this.state.inputIntegration.hideCosts) {
      return true;
    }
    return false;
  }

  changeIntegrationConfig(confData) {
    this.setState({ integrationConfig: confData });
  }

  validateIntegrationConfig() {
    if(!this.state.inputIntegration || !this.state.inputIntegration.localConfig) { return ''; }

    for(const confKey in this.state.inputIntegration.localConfig) {
      const configValue = this.state.integrationConfig[confKey] || '';
      const validationMethod = this.state.inputIntegration.localConfig[confKey].validationMethod;
      const isRequired = this.state.inputIntegration.localConfig[confKey].isRequired;

      if(validationMethod) {
        const err = validationMethod(configValue, !isRequired);
        if(err) {
          return err;
        }
      }
    }
    return '';
  }

  getIntegrationLocalComponent() {
    if(!this.state.inputIntegration || !this.state.inputIntegration.saveShippingMethodComponent) { return null; }

    const LocalComponent = this.state.inputIntegration.saveShippingMethodComponent;
    return <LocalComponent
              integration={this.state.inputIntegration}
              integrationParent={this.props.shippingMethod}
              setInegrationConfig={this.changeIntegrationConfig.bind(this)}
              validationSignal={this.state.integrationValidationSignal} />;
  }

  render() {

    const {t} = this.props;

    return <div className={'AddShippingMethod AdminPage'}>

      <AdminTitle
        title={tx.TX_ADD}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
          {
            url: URL_ADMIN_SETTINGS_SHIPPING_METHODS,
            title: tx.TX_SHIPPING_METHODS,
          },
        ]} />

      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className='addShippingMethodForm'
            onSubmit={this.saveAction.bind(this)}
            noValidate={true}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_SETTINGS_ADD_SHIPPING_METHOD)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.state.inputName}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_NAME)}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_DESCRIPTION)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorDescription ? 'InputError' : ''}
                  value={this.state.inputDescription}
                  onChange={this.changeDescription.bind(this)}
                  onBlur={this.validateDescription.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_DESCRIPTION)}
                  maxLength={1024} />
                {this.state.errorDescription ?
                  <div className={'adminError FieldError'}>{t(this.state.errorDescription)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              {this.state.integrationsPending ?
                <div className='integratonsLoading'>
                  <div className='loadingWrapping'>
                    <div className='iconWrapper'>
                      <div className='iconLiner'>
                        <LoadingIcon />
                      </div>
                    </div>
                    <div className='copyWrapper'>
                      <div className='copyLiner'>
                        <div className={'finallyCopy FlexCenterLeft'}>{t(tx.TX_SETTINGS_INTEGRATIONS_CHECKING)}</div>
                      </div>
                    </div>
                  </div>
                </div> :
                <>
                  {this.state.integrations && this.state.integrations.length > 0 ?
                    <div className='adminInputWrapper'>
                      <div className={'adminFieldLabel'}>{t(tx.TX_SETTINGS_ADD_SHIPPING_METHOD_CONNECT_INTEGRATION)}</div>
                      <div className='adminDropdownWrapper'>
                        <Dropdown 
                          className={'adminDropdownSelect'}
                          options={this.getIntegrationOptions()}
                          name={t(tx.TX_SETTINGS_INTEGRATIONS)}
                          value={this.state.inputIntegration ? this.state.inputIntegration.publicUuid : ''}
                          onChange={this.changeIntegration.bind(this)}
                          required={true}
                          noTranslate={true} />
                      </div>
                      {this.state.errorProductLine ?
                        <div className={'adminError FieldError'}>{t(this.state.errorProductLine)}</div> :
                        null
                      }
                    </div> :
                    null
                  }
                </>
              }
            </div>
            {this.shouldExcludePickup() === false ?
              <div className='adminFieldWrapper'> 
                <div className='adminInputWrapper halfWidth'>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ORDER_CUSTOMER_PICKUP_QUESTION)}</div>
                  <div className='adminInputToggleWrapper'>
                    <Toggle
                      checked={this.state.inputIsPickup}
                      onToggle={this.changePickup.bind(this)}
                      trueValue={tx.TX_YES}
                      falseValue={tx.TX_NO} />
                  </div>
                </div>
                {this.state.inputIsPickup ?
                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_PAYMENT_METHODS_ALLOW_AT_PICKUP_QUESTION)}</div>
                    <div className='adminInputToggleWrapper'>
                      <Toggle
                        checked={this.state.inputAllowInStorePayment}
                        onToggle={this.changeAllowInStorePayment.bind(this)}
                        trueValue={tx.TX_YES}
                        falseValue={tx.TX_NO} />
                    </div>
                  </div> :
                  null
                }
              </div> :
              null
            }
            {this.state.inputIsPickup ?
              <div className='adminFieldWrapper'> 
                <div className='adminInputWrapper halfWidth'>
                  <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_ORDER_CUSTOMER_PICKUP_LOCATION)}</div>
                  <div className='adminInputReplacement'>{t(tx.TX_IN_STORE)}</div>
                </div>
              </div> :
              null
            }
            {this.shouldExcludeCost() === false ?
              <>
                <div className='adminFieldWrapper'> 
                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_COST)}</div>
                    <div className='currencyWrapper'>
                      <input
                        type='number'
                        min={0}
                        max={100000000}
                        step={getCurrencyIncrement()}
                        className={this.state.errorCost ? 'InputError currencyInput' : 'currencyInput'}
                        value={this.state.inputCost}
                        onChange={this.changeCost.bind(this)}
                        onBlur={this.validateCost.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_COST)} />
                      <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                    </div>
                    {this.state.errorCost ?
                      <div className={'adminError FieldError'}>{t(this.state.errorCost)}</div> :
                      null
                    }
                  </div>
                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_MIN_PURCHASE)}</div>
                    <div className='currencyWrapper'>
                      <input
                        type='number'
                        min={0}
                        max={100000000}
                        step={getCurrencyIncrement()}
                        className={this.state.errorMinPurchase ? 'InputError currencyInput' : 'currencyInput'}
                        value={this.state.inputMinPurchase}
                        onChange={this.changeMinPurchase.bind(this)}
                        onBlur={this.validateMinPurchase.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_MIN_PURCHASE)} />
                      <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                    </div>
                    {this.state.errorMinPurchase ?
                      <div className={'adminError FieldError'}>{t(this.state.errorMinPurchase)}</div> :
                      null
                    }
                  </div>
                </div>

                <div className='adminFieldWrapper'>

                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_SETTINGS_MIN_WEIGHT)}</div>
                    <div className={this.state.errorMinWeight ? 'adminInputWeight fieldError' : 'adminInputWeight'}>
                      <div className='aiwLiner'>
                        <div className={'aiwInputElement aiwElement'}>
                          <input
                            type='text'
                            className={this.state.errorMinWeight ? 'InputError' : ''}
                            value={this.state.inputMinWeight}
                            onChange={this.changeMinWeight.bind(this)}
                            onBlur={this.validateMinWeight.bind(this)}
                            placeholder={t(tx.TX_PLACEHOLDER_WEIGHT)}
                            maxLength={100} />
                        </div>
                        <div className={'aiwPostScriptWrapper aiwElement'}>
                          <Dropdown 
                            className={'aiwPostSelect'}
                            options={getWeightUnitOptions()}
                            name={t(tx.TX_SETTINGS_MIN_WEIGHT)}
                            value={this.state.inputMinWeightUnit.key}
                            noTranslate={true}
                            onChange={this.changeMinWeightUnit.bind(this)} />
                        </div>
                      </div>
                    </div>
                    {this.state.errorMinWeight ?
                      <div className={'FieldError'}>{t(this.state.errorMinWeight)}</div> :
                      null
                    }
                  </div>                  

                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_SETTINGS_MAX_WEIGHT)}</div>
                    <div className={this.state.errorMaxWeight ? 'adminInputWeight fieldError' : 'adminInputWeight'}>
                      <div className='aiwLiner'>
                        <div className={'aiwInputElement aiwElement'}>
                          <input
                            type='text'
                            className={this.state.errorMaxWeight ? 'InputError' : ''}
                            value={this.state.inputMaxWeight}
                            onChange={this.changeMaxWeight.bind(this)}
                            onBlur={this.validateMaxWeight.bind(this)}
                            placeholder={t(tx.TX_PLACEHOLDER_WEIGHT)}
                            maxLength={100} />
                        </div>
                        <div className={'aiwPostScriptWrapper aiwElement'}>
                          <Dropdown 
                            className={'aiwPostSelect'}
                            options={getWeightUnitOptions()}
                            name={t(tx.TX_SETTINGS_MAX_WEIGHT)}
                            value={this.state.inputMaxWeightUnit.key}
                            noTranslate={true}
                            onChange={this.changeMaxWeightUnit.bind(this)} />
                        </div>
                      </div>
                    </div>
                    {this.state.errorMaxWeight ?
                      <div className={'FieldError'}>{t(this.state.errorMaxWeight)}</div> :
                      null
                    }
                  </div>

                </div>
              </> :
              null
            }
            {this.state.inputIntegration && this.state.inputIntegration.saveShippingMethodComponent ?
              <>{this.getIntegrationLocalComponent()}</> :
              null
            }
            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_SETTINGS_SHIPPING_METHODS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddShippingMethod));