import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../../constants/strings';

import {
  getPrintingsOrderedDeduped, 
} from '../../../../utils/printings';

import Dropdown from '../../../Input/Dropdown';

let allActionCreators = Object.assign({});

export class ProductPageInventoryConfigPrinting extends Component {

  constructor(props) {
    super(props);

    this.state = {
      printings: this.getInventoryPrintings(),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(this.props.productObj, prevProps.productObj) || !_.isEqual(this.props.exclusionFilters, prevProps.exclusionFilters) || !_.isEqual(this.props.value, prevProps.value)) {
      this.setState({
        printings: this.getInventoryPrintings(),
      });
    }
  }

  getInventoryPrintings() {
    
    if(!this.props.productObj || !this.props.productObj.inventory) { return []; }

    if(this.props.productObj.hasPrinting()) {

      const printingArray = [];
      for(const inv of this.props.productObj.inventory) {

        if(!inv.printing) {
          continue;
        }

        // Loop through exclusion filters, and exclude any inventory that doesn't match
        let shouldExclude = false;
        for(const exKey in this.props.exclusionFilters) {
          if(this.props.exclusionFilters[exKey]) {

            // If inventory doesn't have a valid attribute to filter, exclude it
            if(!inv[exKey]) {
              shouldExclude = true;
              break;
            }

            if(this.props.exclusionFilters[exKey].key && inv[exKey].key !== this.props.exclusionFilters[exKey].key) {
              shouldExclude = true;
            } else if(this.props.exclusionFilters[exKey].code && inv[exKey].code !== this.props.exclusionFilters[exKey].code) {
              shouldExclude = true;
            }
          }
        }

        if(!shouldExclude) {
          printingArray.push(inv.printing);
        }
      }

      const orderedPrintings = getPrintingsOrderedDeduped(printingArray);
  
      // If printing isn't viable based on exclusion filters, either unset or set as the only option
      if(this.props.value && this.props.value.key) {
        let validFilter = false;
        for(const pr of orderedPrintings) {
          if(this.props.value.key === pr.key) {
            validFilter = true;
            break;
          }
        }

        if(!validFilter) {
          if(orderedPrintings.length === 1) {
            // Set printing to only remaining option
            this.props.setAttr(orderedPrintings[0]);
          } else {
            // Unset printing
            this.props.setAttr(null);
          }
        }
      }

      // If printing is unset and only one printing avilable, set it
      if(this.props.value === null && orderedPrintings.length === 1) {
        this.props.setAttr(orderedPrintings[0]);
      }

      return orderedPrintings;
    }
    return [];
  }

  shouldDisplayDropdown() {

    if(!this.props.productObj || !this.props.productObj.inventory) { return false; }

    if(this.props.productObj.hasPrinting()) {

      const printingsArray = [];
      for(const inv of this.props.productObj.inventory) {
        if(!inv.printing) {
          continue;
        }
        printingsArray.push(inv.printing);
      }

      const allPrintings = getPrintingsOrderedDeduped(printingsArray);
      return allPrintings.length > 1;
    }
    return false;
  }

  getStaticOption() {
    if(!this.state.printings || this.state.printings.length < 1) {
      return tx.TX_NONE;
    }
    return this.state.printings[0].name;
  }

  formatOptions() {
    if(!this.state.printings) {
      return [];
    }
    
    const valueOptions = [];
    for(const pr of this.state.printings) {
      valueOptions.push({
        display: pr.name, 
        value: pr.key,
      });
    }

    valueOptions.unshift({
      display: tx.TX_PLACEHOLDER_SELECT_PRINTING,
      value: '',
    });

    return valueOptions;
  }

  getSelectedPrintingName() {
    return this.props.value ? this.props.value.name : tx.TX_null;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductPageInventoryConfig ProductPageInventoryConfigPrinting'}>
      <div className='ppiConfigElementWrapper'>
        {this.shouldDisplayDropdown() ?
          <div className='ppiConfigSelectable'>
            <div className='ppiConfigInlineLabel'>
              <div className='ppiConfigLabel'>{t(tx.TX_PRINTING)}</div>
              <div className='ppiSelectedValue'>{t(this.getSelectedPrintingName())}</div>
            </div>
            <div className='ppiConfigSelectWrapper'>
              <Dropdown 
                className='ppiConfigSelect'
                options={this.formatOptions()}
                name={t(tx.TX_PRINTING)}
                value={this.props.value ? this.props.value.key : ''}
                required={true}
                onChange={this.props.setAttr.bind(this)} />
            </div>
          </div> :
          <div className='ppiConfigStatic'>
            <div className='ppiConfigLabel'>{t(tx.TX_PRINTING)}</div>
            <div className='ppiStaticValue'>{t(this.getStaticOption())}</div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageInventoryConfigPrinting));