import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_PAYMENT_METHODS, 
  TABLE_SCHEMA_PAYMENT_METHODS, 
} from '../../constants/tables';
import { URL_ADMIN_SETTINGS } from '../../constants/urls';

import { PaymentMethod } from '../../models/payment-methods';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import './style/_paymentmethods.scss';

import * as checkoutActionCreators from '../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class PaymentMethods extends Component {

  constructor(props) {
    super(props);

    this.state = {
      paymentMethods: null,
      paymentMethodsPending: false,
    };
  }

  componentDidMount() {

    this.setState({
      paymentMethodsPending: true, 
    });

    this.props.checkoutFetchAdminPaymentMethods()
    .then((resp) => {

      const paymentMethods = [];
      for(const pm of resp) {
        paymentMethods.push(new PaymentMethod(pm));
      }

      this.setState({
        paymentMethods: paymentMethods,
        paymentMethodsPending: false, 
      });
    })
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
      this.setState({
        paymentMethodsPending: false, 
      });
    });
  }

  render() {
    return <div className={'PaymentMethods AdminPage'}>
      <AdminTitle
        title={tx.TX_SETTINGS_PAYMENT_METHODS}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_PAYMENT_METHODS}
          schema={TABLE_SCHEMA_PAYMENT_METHODS}
          data={this.state.paymentMethods} 
          dataLoading={this.state.paymentMethodsPending} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PaymentMethods));