import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../../constants/strings';

import { 
  getCheckoutStepData,
} from '../../../../../utils/checkout';

let allActionCreators = Object.assign({});

export class CheckoutStepBuylistAddressComplete extends Component {

  editShippingAddress() {
    const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);
    const currentStepData = stepData && stepData.data ? stepData.data : {};
    this.props.setStepData(this.props.config, Object.assign({}, currentStepData, {
          editing: true,
        }));
  }

  getShippingAddress() {
    const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);
    if(!stepData || !stepData.data || !stepData.data.shippingAddress || !stepData.data.shippingAddress.format) {
      return null;
    }
    return stepData.data.shippingAddress.format();
  }

  render() {

    const {t} = this.props;
    const addressLines = this.getShippingAddress();

    return <div className={'CheckoutStepBuylistAddressComplete CheckoutStepComponent'}>
      <div className='cssaLiner'>
        <div className='checkoutFormSubheader'>{t(tx.TX_CHECKOUT_BUYLIST_RETURN_ADDRESS)}</div>
        {addressLines ?
          <div className='cssAddressWrapper'>
            {addressLines.map((line, i) => {
              return <div key={i} className='cssAddressLine'>{line}</div>;
            })}
            <div className='cssAddressToggleWrapper'>
              <button type='button' className='cssAddressToggleButton' onClick={this.editShippingAddress.bind(this)}>{t(tx.TX_EDIT)}</button>
            </div>
          </div> :
          null
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepBuylistAddressComplete));