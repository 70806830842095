import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_INTEGRATIONS, 
  TABLE_SCHEMA_INTEGRATIONS, 
} from '../../constants/tables';
import {
  URL_ADMIN_SETTINGS, 
} from '../../constants/urls';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import './style/_integrations.scss';

import * as storeActionCreators from '../../actions/store';
const allActionCreators = Object.assign({}, storeActionCreators);

export class Integrations extends Component {

  constructor(props) {
    super(props);

    this.state = {
      integrations: null,
      integrationsPending: false,
    };

    this.controller = null;
  }

  async componentDidMount() {

    this.setState({
      integrationsPending: true, 
    });

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    const integrationResp = await this.props.storeFetchIntegrationProvidersPage({}, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }
        this.setState({
          integrationsPending: false, 
        });
      });

    if(!integrationResp) { return null; }

    this.setState({
      integrations: integrationResp.data,
      integrationsPending: false, 
    });
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  render() {

    return <div className={'Integrations AdminPage'}>
      <AdminTitle
        title={tx.TX_SETTINGS_INTEGRATIONS}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_INTEGRATIONS}
          schema={TABLE_SCHEMA_INTEGRATIONS}
          data={this.state.integrations} 
          dataLoading={this.state.integrationsPending} />
      </div>
      
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Integrations));