import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { formatServerError } from '../../../../utils/formatting';

import * as storeActionCreators from '../../../../actions/store';
const allActionCreators = Object.assign({}, storeActionCreators);

export class IntegrationDisplayStampsTestPrint extends Component {

	constructor(props) {
    super(props);

    this.state = {
    	pendingPrint: false,
    	reqError: '',
    };

    this.controller = null;
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  async fetchTestLabel() {

  	this.setState({ 
  		pendingPrint: true, 
  		reqError: '',
  	});

  	if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

  	const testResp = await this.props.storeIntegrationStampsTestLabel(this.props.integration.publicUuid, controller.signal)
      .catch((errResp) => {

        if(controller.signal.aborted) { return null; }
        console.error(errResp);

        this.setState({ 
          pendingPrint: false,
          reqError: formatServerError(errResp),
        });
      });

    if(!testResp) {
      return null;
    }

    testResp.print();

    this.setState({ pendingPrint: false });
  }
  
  render() {

    const {t} = this.props;

    return <div className={'IntegrationDisplayStampsTestPrint'}>
      <button 
      	type='button' 
      	className='testPrintButton' 
      	disabled={this.state.pendingPrint}
      	onClick={this.fetchTestLabel.bind(this)}>
      	{t(tx.TX_INTEGRATION_CONFIG_LABEL_TEST_PRINT)}
      </button>
      {this.state.reqError ?
      	<div className={'testPrintError'}>{t(this.state.reqError)}</div> :
      	null
    	}
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationDisplayStampsTestPrint));