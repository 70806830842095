

export const STORAGE_BROWSER_SESSION = 'tal-tMu2tsnhu8ztCxajFZpXLpfTwjgp8Tdz';
export const STORAGE_ENABLED_PRODUCT_LINES = 'tal-x8ZtkX7nN4J5yA4wraAYN9LBBAhQMArJ';
export const STORAGE_MENU = 'tal-CWwRfBWH8B9ZYfWPyDqhgYdMLx6jcgES';
export const STORAGE_NOTICES = 'tal-j06coe89TxPiOh77h92n0o8U8yKQVeMe';
export const STORAGE_REAUTH_SESSION = 'tal-N5Wyz9SwaMzpRdfrBWdQ4qXkW8GhU2AT';
export const STORAGE_SQUARE_STATE = 'tal-bGuul7pyUFyN8bLq9FTPlrWNZMxbtlNE';
export const STORAGE_STORE = 'tal-5AIPKJyCdEZVZ5RzQhUMSlNjNrfpo7DX';
export const STORAGE_TRANSBANK_DATA = 'tal-jOUqtR7OBivI5eBRL7N6vjqA0x6bCRRJ';
export const STORAGE_USER_BUYLIST_CART = 'tal-yF9FU3e8H8JjYUMNFQ7p1h97kQVqevQe';
export const STORAGE_USER_CART = 'tal-s3DvLAQedp2sr4rfKfcKXJBn7wxvs4XU';
export const STORAGE_USER_SESSION = 'tal-USRTqRQz2dzt7qBHxX9pYP8jjf9u7ZvF';
export const STORAGE_USER_PERMISSIONS = 'tal-XzXBCX6tkhsHbbFLdNzx9CWFssgzP6TM';