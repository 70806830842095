import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_BUY, 
  ICON_HOME, 
  ICON_USER, 
} from '../../constants/icons';
import * as tx from '../../constants/strings';
import { 
  URL_ACCOUNT, 
  URL_BUY_GENERAL, 
  URL_HOME, 
} from '../../constants/urls';

import { isLoggedIn } from '../../utils/auth';

import { Icon } from '../Icons/Icon';

import './style/_error.scss';

import * as userActionCreators from '../../actions/user';
const allActionCreators = Object.assign({}, userActionCreators);

export class Error404 extends Component {

  componentDidMount() {

    // Get store data
    if(!this.props.user.store || !this.props.user.store.publicUuid) {
      this.props.userFetchStore()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className='Error404'>
      <div className='errorHeader'>
        <div className='titleWrapper'>
          <div className='pageTitle'>
            <div className='pageErrorCode'>404</div>
            <div className='pageTitleCopy'>{t(tx.TX_ERR_404_TITLE)}</div>
          </div>
        </div>
      </div>
      <div className='errorBody'>
        <div className='bodyCopy'>{t(tx.TX_ERR_404_COPY)}</div>
        <div className='bodyActionHeader'>{t(tx.TX_ERR_404_ACTIONS_HEADER, { name: this.props.user.store.name || '' })}</div>
        <div className='bodyActionsWrapper'>
          <div className='bodyAction'>
            <div className='actionIcon'>
              <Icon value={ICON_HOME} iconClass={'icon'} />
            </div>
            <Link to={URL_HOME} className='actionLink'>
              <button type='button' className='actionButton'>{t(tx.TX_HOME)}</button>
            </Link>
          </div>
          <div className='bodyAction'>
            <div className='actionIcon'>
              <Icon value={ICON_BUY} iconClass={'icon'} />
            </div>
            <Link to={URL_BUY_GENERAL} className='actionLink'>
              <button type='button' className='actionButton'>{t(tx.TX_SHOP)}</button>
            </Link>
          </div>
          {isLoggedIn() ?
            <div className='bodyAction'>
              <div className='actionIcon'>
                <Icon value={ICON_USER} iconClass={'icon'} />
              </div>
              <Link to={URL_ACCOUNT} className='actionLink'>
                <button type='button' className='actionButton'>{t(tx.TX_MENU_SECTION_ACCOUNT)}</button>
              </Link>
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Error404));