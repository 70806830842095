
import React, { Component } from 'react';

import './style/_radio.scss';

const radioFilterProps = [
	'adminTheme', 
	'className',
	'error', 
];

const omit = (obj, omitKeys) =>
	Object.keys(obj).reduce((result, key) => {
		if(!omitKeys.includes(key)) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export default class Radio extends Component {

	getRootClass() {
		const themeClass = this.props.adminTheme ? ' titanTheme' : '';
		const errorClass = this.props.error ? ' error' : '';

		return `Radio${themeClass}${errorClass}`;
	}

	render() {
		
		const filteredProps = omit(this.props, radioFilterProps);
		
		return (<div className={this.getRootClass()}>
			<label htmlFor={this.props.id}>
				<div 
					className='radio__input'>
			    <input 
			    	type='radio' 
			    	className={this.props.className ? this.props.className : ''}
			    	{...filteredProps} 
			    />
			    <div className='radio__control'></div>
			  </div>
			</label>
		</div>);
	}
}