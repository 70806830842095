import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { ShippingMethod } from '../../../models/shipping-methods';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../utils/currency';
import { 
  getCostError,
  getNameError, 
  getPurchaseAmountError, 
  getShortDescriptionError, 
  isFormValid, 
} from '../../../utils/form-validation';
import { 
  formatServerError,
  normalizePrice,
} from '../../../utils/formatting';

import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';
import Toggle from '../../Input/Toggle';

import * as checkoutActionCreators from '../../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class StoreSettingsDetailsEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      inputStatus: this.props.shippingMethod.isEnabled,
      inputName: this.props.shippingMethod.name,
      inputDescription: this.props.shippingMethod.description, 
      inputCost: normalizePrice(this.props.shippingMethod.cost), 
      inputMinPurchase: normalizePrice(this.props.shippingMethod.minPurchase), 

      errorName: '',
      errorDescription: '', 
      errorCost: '',
      errorMinPurchase: '', 

      requestPending: false,
      requestError: null,

      resetConfirmOpen: false,
      savePromptOpen: false, 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  changeStatus(evt) {
    this.setState({
      inputStatus: !this.state.inputStatus, 
    });
    this.makeDirty();
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorName) {
        this.validateName();
      }
    });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  changeDescription(evt) {
    this.setState({
      inputDescription: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorDescription) {
        this.validateDescription();
      }
    });
  }

  validateDescription() {
    this.setState({ errorDescription: getShortDescriptionError(this.state.inputDescription, true) });
  }

  changeCost(evt) {
    this.setState({
      inputCost: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorCost) {
        this.validateCost();
      }
    });
  }

  validateCost() {
    this.setState({ errorCost: getCostError(this.state.inputCost) });
  }

  changeMinPurchase(evt) {
    this.setState({
      inputMinPurchase: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorMinPurchase) {
        this.validateMinPurchase();
      }
    });
  }

  validateMinPurchase() {
    this.setState({ errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase) });
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const updatedShippingMethod = new ShippingMethod({
        is_enabled: this.state.inputStatus,
        name: this.state.inputName,
        description: this.state.inputDescription,
        cost: this.state.inputCost,
        min_purchase: this.state.inputMinPurchase,
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      this.props.checkoutUpdateShippingMethod(updatedShippingMethod.getApiData(), this.props.shippingMethod.publicUuid)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
        }, () => {
          if(this.props.remoteSave) {
            this.props.finishRemote('details');
          } else {
            this.props.makeClean('details');
          }
          this.props.toggleEdit();
        });
      })
      .catch((errResp) => {
        
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });

        if(this.props.remoteSave) {
          this.props.finishRemote('details', true);
        }
      });
    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('details', true);
    }
  }

  validateAll() {
    const errorObj = {
      errorName: getNameError(this.state.inputName),
      errorDescription: getShortDescriptionError(this.state.inputDescription, true),
      errorCost: getCostError(this.state.inputCost),
      errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'StoreSettingsDetailsEdit'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'shippingMethodDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_SETTINGS_STORE_SETTINGS_DETAILS)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputStatus}
                    onToggle={this.changeStatus.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='given-name'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.state.inputName}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_FIRST_NAME)}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_DESCRIPTION)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorDescription ? 'InputError' : ''}
                  value={this.state.inputDescription}
                  onChange={this.changeDescription.bind(this)}
                  onBlur={this.validateDescription.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_DESCRIPTION)}
                  maxLength={1024} />
                {this.state.errorDescription ?
                  <div className={'adminError FieldError'}>{t(this.state.errorDescription)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'> 
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_COST)}</div>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    max={100000000}
                    step={getCurrencyIncrement()}
                    className={this.state.errorCost ? 'InputError currencyInput' : 'currencyInput'}
                    value={this.state.inputCost}
                    onChange={this.changeCost.bind(this)}
                    onBlur={this.validateCost.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_COST)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorCost ?
                  <div className={'adminError FieldError'}>{t(this.state.errorCost)}</div> :
                  null
                }
              </div>
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_MIN_PURCHASE)}</div>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    max={100000000}
                    step={getCurrencyIncrement()}
                    className={this.state.errorMinPurchase ? 'InputError currencyInput' : 'currencyInput'}
                    value={this.state.inputMinPurchase}
                    onChange={this.changeMinPurchase.bind(this)}
                    onBlur={this.validateMinPurchase.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_MIN_PURCHASE)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorMinPurchase ?
                  <div className={'adminError FieldError'}>{t(this.state.errorMinPurchase)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StoreSettingsDetailsEdit));