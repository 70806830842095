import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { isFormValid, getNameError, getEmailError } from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import { ERROR_EMAIL_EXISTS } from '../../constants/errors';
import * as tx from '../../constants/strings';
import {
  URL_ADMIN_CUSTOMERS, 
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';

import * as customerActionCreators from '../../actions/customer';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, customerActionCreators, userActionCreators);

export class AddCustomer extends Component {

  constructor(props) {
    super(props);

    this.destination = props.location && props.location.state && props.location.state.from ? props.location.state.from : null;

    this.state = {

      isDirty: false,
      savePromptOpen: false,
      lastBlock: '',
      saveRedirect: false,

      inputFirstName: '',
      inputLastName: '',
      inputEmail: '',
      errorFirstName: '',
      errorLastName: '',
      errorEmail: '',

      requestPending: false,
      requestError: null,
    };
  }

  componentDidMount() {

    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });

    const historyState = { ...history.location.state };
    delete historyState.from;
    history.replace({ 
      ...history.location, 
      state: historyState, 
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  changeFirstName(evt) {
    this.setState({
      inputFirstName: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorFirstName) {
        this.validateFirstName();
      }
    });
  }

  changeLastName(evt) {
    this.setState({
      inputLastName: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorLastName) {
        this.validateLastName();
      }
    });
  }

  changeEmail(evt) {
    this.setState({ 
      inputEmail: evt.target.value, 
      isDirty: true,
    }, () => {
      if(this.state.emailError) {
        this.validateEmail();
      }
    });
  }

  validateFirstName() {
    this.setState({ errorFirstName: getNameError(this.state.inputFirstName) });
  }

  validateLastName() {
    this.setState({ errorLastName: getNameError(this.state.inputLastName) });
  }

  validateEmail() {
    this.setState({ errorEmail: getEmailError(this.state.inputEmail) }, () => {
      if(!this.state.emailError) {
        
        this.props.userEmailExists(this.state.inputEmail)
        .then((resp) => {
          if(resp.exists) {
            this.setState({ errorEmail: ERROR_EMAIL_EXISTS });
          }
        })
        .catch((errResp) => {
          // Do nothing, email available
        });
      }
    });
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveAction();
    });
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const { i18n } = this.props;

      const addData = {
        first_name: this.state.inputFirstName,
        last_name: this.state.inputLastName,
        email_address: this.state.inputEmail,
        language: i18n.language,
      };

      this.setState({
        requestPending: true,
        requestError: null,
      });

      this.props.customerAdminAdd(addData)
      .then((resp) => {

        this.setState({ isDirty: false }, () => {
          if(!this.state.saveRedirect) {
            history.push(this.destination || URL_ADMIN_CUSTOMERS);
          } else {
            history.push(this.state.lastBlock);
          }
        });
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });
      });
    }
  }

  validateAll() {
    const errorObj = {
      errorFirstName: getNameError(this.state.inputFirstName),
      errorLastName: getNameError(this.state.inputLastName),
      errorEmail: this.state.errorEmail === '' ? getEmailError(this.state.inputEmail) : this.state.errorEmail,
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  render() {

    const {t} = this.props;

    return <div className={'AddCustomer AdminPage'}>
      <AdminTitle
        title={tx.TX_CUSTOMER_ADD_CUSTOMER}
        breadcrumbs={[
          {
            url: URL_ADMIN_CUSTOMERS,
            title: tx.TX_CUSTOMERS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className='addCustomerForm'
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_CUSTOMER_ADD_CUSTOMER)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>


            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_FIRST_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='given-name'
                  className={this.state.errorFirstName ? 'InputError' : ''}
                  value={this.state.inputFirstName}
                  onChange={this.changeFirstName.bind(this)}
                  onBlur={this.validateFirstName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_FIRST_NAME)}
                  maxLength={100} />
                {this.state.errorFirstName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorFirstName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_LAST_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='family-name'
                  className={this.state.errorLastName ? 'InputError' : ''}
                  value={this.state.inputLastName}
                  onChange={this.changeLastName.bind(this)}
                  onBlur={this.validateLastName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_LAST_NAME)}
                  maxLength={100} />
                {this.state.errorLastName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorLastName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_EMAIL_ADDRESS)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='email'
                  autoComplete='email'
                  className={this.state.errorEmail ? 'InputError' : ''}
                  value={this.state.inputEmail}
                  onChange={this.changeEmail.bind(this)}
                  onBlur={this.validateEmail.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EMAIL)}
                  maxLength={100} />
                {this.state.errorEmail ?
                  <div className={'adminError FieldError'}>{t(this.state.errorEmail)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_PASSWORD)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputReplacement'>{t(tx.TX_CUSTOMER_ADD_NO_PASSWORD)}</div>
              </div>
            </div>
            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_CUSTOMERS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddCustomer));