import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { LAYOUT_KEY_DASHBOARD } from '../../constants/pages';
import { P_MANAGE_SALES } from '../../constants/permissions';
import * as tx from '../../constants/strings';

import { hasPermission } from '../../utils/permissions';

import AdminTitle from '../Admin/AdminTitle';
import LayoutComponent from '../Layout/LayoutComponent';

import './style/_dashboard.scss';

import * as storeActionCreators from '../../actions/store';
let allActionCreators = Object.assign({}, storeActionCreators);

export class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      layoutPending: false,
      layoutResp: null,
    }

    this.controller = null;
  }

  componentDidMount() {
    this.fetchLayout();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  async fetchLayout() {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      layoutPending: true,
      layoutResp: null,
    });

    // Get layout

    const layoutResp = await this.props.storeFetchLayout(LAYOUT_KEY_DASHBOARD, controller.signal)
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          layoutPending: false,
          layoutResp: null,
        });
      });

    this.setState({
      layoutPending: false,
      layoutResp: layoutResp,
    });
  }

  render() {

    const {t} = this.props;
    
    return <div className={'Dashboard AdminPage'}>
      <AdminTitle
        title={tx.TX_DASHBOARD}
        breadcrumbs={[]} />
      <div className='adminBody'>
        <div className={'adminGenericBlock bottomPadding'}>
          <div className='dashboardWrapper'>
            {hasPermission(P_MANAGE_SALES) ?
              <LayoutComponent
                adminTheme={true}
                layout={this.state.layoutResp}
                layoutLoading={this.state.layoutPending} /> :
              <div className='dashboardNoPermissiosn'>{t(tx.TX_NO_RESULTS)}</div>
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Dashboard));