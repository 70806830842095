import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { isBuylistCheckoutEnabled } from '../../../utils/checkout';
import { getCurrencySymbol } from '../../../utils/currency';
import { getStoreLanguage } from '../../../utils/language';
import { getPriceRangeFromInventory } from '../../../utils/product';

let allActionCreators = Object.assign({});

export class ProductPageInventoryPrice extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getPricingInventoryOptions() {
    return this.props.selectedInvObj ? [ this.props.selectedInvObj ] : (this.props.invArray ? this.props.invArray : []);
  }

  getMaxInventoryBuylist() {
    let maxInv = null;
    for(const inv of this.props.invArray) {
      if(inv.isBuylist) {
        if(maxInv === null) {
          maxInv = inv;
        } else if(inv.buyPrice > maxInv.buyPrice) {
          maxInv = inv;
        }
      }
    }
    return maxInv ? [ maxInv ] : [];
  }

  hasBuylistOptions() {
    
    const buylistArray = [];
    const optionsArray = this.props.selectedInvObj ? [ this.props.selectedInvObj ] : (this.props.invArray ? this.props.invArray : []);

    for(const inv of optionsArray) {
      if(inv.isBuylist) {
        buylistArray.push(inv);
      }
    }
    return buylistArray.length > 0;
  }

  render() {

    if(!this.props.productObj) { return null; }

    const {t} = this.props;
    const priceConfig = {
      available: !this.props.isBuylist,
      buylist: this.props.isBuylist,
      omitSymbol: true, 
      language: this.getLanguage(),
    }

    return <div className={`ProductPageInventoryPrice ${isBuylistCheckoutEnabled() ? '' : 'readOnly'}`}>
      {this.props.isBuylist ?
        <>
          {this.hasBuylistOptions() ?
            <>
              <div className='ppiTagWrapper'>
                <div className='ppiTag'>
                  <div className='ppiTagLiner'>{t(tx.TX_BUYLIST)}</div>
                </div>
              </div>
              <div className='ppiPriceBlockWrapper'>
                <div className='ppiPriceSymbol'>{getCurrencySymbol()}</div>
                <div className='ppiPriceValue'>{getPriceRangeFromInventory(isBuylistCheckoutEnabled() ? this.getPricingInventoryOptions() : this.getMaxInventoryBuylist(), priceConfig)}</div>
              </div>
            </> :
            <div className='ppiPriceBlockWrapper'>
              <div className='ppiOutOfStock'>{t(tx.TX_PRODUCT_PAGE_NOT_BUYLIST)}</div>
            </div>
          }
        </> :
        <>
          {this.props.productObj.inStock ?
            <>
              {this.props.productObj.isReleased === false ?
                <div className='ppiTagWrapper'>
                  <div className='ppiTag'>
                    <div className='ppiTagLiner'>{t(tx.TX_PREORDER)}</div>
                  </div>
                </div> :
                null
              }
              <div className='ppiPriceBlockWrapper'>
                <div className='ppiPriceSymbol'>{getCurrencySymbol()}</div>
                <div className='ppiPriceValue'>{getPriceRangeFromInventory(this.getPricingInventoryOptions(), priceConfig)}</div>
              </div>
            </> :
            <div className='ppiPriceBlockWrapper'>
              <div className='ppiOutOfStock'>{t(tx.TX_OUT_OF_STOCK)}</div>
            </div>
          }
        </>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageInventoryPrice));