import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { STORE_INTEGRATION_TYPE_SHIPPING_KEY } from '../../../constants/store';
import * as tx from '../../../constants/strings';

import { 
  formatServerError, 
} from '../../../utils/formatting';

import LoadingIcon from '../../Icons/LoadingIcon';
import IntegrationConfigField from './IntegrationConfigField';

import * as storeActionCreators from '../../../actions/store';
let allActionCreators = Object.assign({}, storeActionCreators);

export class IntegrationShippingConfigView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestPending: false,
      requestError: null,
    };
  }

  async setConfigAttribute(config, value) {

    const configData = config.getApiData(value);

    for(const conf of this.props.integration.getCalcConfigs()) {

      const calcData = conf.getApiData(conf.value);
      for(const key of Object.keys(calcData)) {
        if(configData[key]) {
          configData[key] = Object.assign({}, configData[key], { [conf.key]: conf.value });
        } else {
          configData[key] = { [conf.key]: conf.value };
        }
      }
    }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    const configResp = await this.props.storeUpdateIntegration(configData, this.props.integration.publicUuid)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });
      });

    if(!configResp) { return null; }

    this.setState({
      requestPending: false,
      requestError: null,
    }, () => {
      this.props.makeClean(STORE_INTEGRATION_TYPE_SHIPPING_KEY);
    }); 
  }

  getCustomComponent(conf) {

    if(!conf || !conf.component) { return null; }

    const CustomComponent = conf.component;

    return <CustomComponent
              config={conf}
              integration={this.props.integration}
              integrationPending={this.props.integrationPending}
              setConfigAttribute={this.setConfigAttribute.bind(this)} />;
  }

  render() {

    const {t} = this.props;

    const calculatedConfigs = this.props.integration.getCalcConfigs();
    const customConfigs = this.props.integration.getCustomConfigs();
    const variableConfigs = this.props.integration.getVariableConfigs();

    return <div className={'IntegrationShippingConfigView IntegrationConfigView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{this.props.integration && this.props.integration.type ? t(this.props.integration.type.name) : t(tx.TX_CONFIGURATION)}</div>
      </div>
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className={'detailBlock adminViewEditable'}>
              {(calculatedConfigs && calculatedConfigs.length) || (variableConfigs && variableConfigs.length) || (customConfigs && customConfigs.length) ?
                <div className='detailBlockWrapper'>
                  <div className={this.state.requestError ? 'configError present' : 'configError'}>{t(this.state.requestError)}</div>
                  {customConfigs && customConfigs.length > 0 ?
                    <div className='fullWidthConfigs'>
                      {customConfigs.map((conf, i) => {
                        return <div className='adminViewItem' key={i}>
                          <div className='adminViewLabel'>{t(conf.label)}</div>
                          <div className={'adminViewComponent'}>{this.getCustomComponent(conf)}</div>
                        </div>;
                      })}
                    </div> :
                    null
                  }
                  {calculatedConfigs && calculatedConfigs.length && customConfigs && customConfigs.length ?
                    <div className='configHr' /> :
                    null
                  }
                  {calculatedConfigs && calculatedConfigs.length > 0 ?
                    <div className='fullWidthConfigs'>
                      {calculatedConfigs.map((conf, i) => {
                        return <div className='adminViewItem' key={i}>
                          <div className='adminViewLabel'>{t(conf.label)}</div>
                          <div className={'adminViewValue urlValue'}>{conf.value}</div>
                        </div>;
                      })}
                    </div> :
                    null
                  }
                  {(calculatedConfigs && calculatedConfigs.length && variableConfigs && variableConfigs.length) || (calculatedConfigs && calculatedConfigs.length === 0 && customConfigs && customConfigs.length && variableConfigs && variableConfigs.length) ?
                    <div className='configHr' /> :
                    null
                  }
                  {variableConfigs && variableConfigs.length ?
                    <div className='halfWidthConfigs'>
                      {variableConfigs.map((conf, j) => {
                        return <IntegrationConfigField 
                                  key={j}
                                  config={conf}
                                  integration={this.props.integration}
                                  integrationPending={this.props.integrationPending}
                                  setConfigAttribute={this.setConfigAttribute.bind(this)} />;
                      })}
                    </div> :
                    null
                  }
                </div> :
                <div className='detailBlockWrapper'>
                  <div className='noConfigMethod'>{t(tx.TX_SETTINGS_INTEGRATIONS_NO_CONFIG)}</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.integrationPending || this.state.requestPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationShippingConfigView));