import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import { 
  ICON_ADD, 
  ICON_PRODUCT_LINE, 
  ICON_UPLOAD, 
} from '../../constants/icons';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_PRODUCT, 
  TABLE_SCHEMA_PRODUCT, 
  TC_PAGINATION_CONFIGS, 
  TC_DEFAULT_PAGE_SIZE, 
} from '../../constants/tables';
import {
  URL_ADMIN_INVENTORY_ADD, 
  URL_ADMIN_INVENTORY_BULK_UPLOAD, 
  URL_ADMIN_INVENTORY_PRODUCT_LINES, 
} from '../../constants/urls';

import { 
  getPageLimit, 
  getPageOffset, 
} from '../../utils/request';
import {
  getDefaultPageSize,
  getTableFilterStorageKey,
} from '../../utils/table';

import AdminTable from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';
import { Icon } from '../Icons/Icon';

import * as productActionCreators from '../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class Inventory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      productData: [],
      productPending: true,
      productCount: 0,

      productPage: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getProductPage(this.state.productPage, getDefaultPageSize(TABLE_CONFIG_PRODUCT), null, this.getInitialFilters());
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getInitialFilters() {
    const filterKey = getTableFilterStorageKey(TABLE_CONFIG_PRODUCT);
    return filterKey && this.props.product.filters[filterKey] ? this.props.product.filters[filterKey] : {};
  }

  async getProductPage(pageNum = 1, pageSize = TABLE_CONFIG_PRODUCT[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}, silentLoading = false) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    if(!silentLoading) {
      this.setState({
        productData: [],
        productPending: true,
        productCount: 0,
      });
    }

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    
    const resp = await this.props.productFetchPage(getParams, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) {
          // Request aborted; do nothing
        } else if(errResp) {
          console.error(errResp);
          this.setState({
            productData: [],
            productPending: false,
            productCount: 0,
          });
        }
      });

    if(resp) {
      this.setState({
        productData: resp.data,
        productPending: false,
        productCount: resp.count,
      });
    }
  }

  hotSwapProduct(product, lookupKey, lookupValue) {

    try {

      if(!product || !product[lookupKey] || !lookupKey || !lookupValue) {
        return null;
      }

      const newProducts = [];
      for(const prod of this.state.productData) {
        if(prod[lookupKey] === product[lookupKey]) {
          newProducts.push(product);
        } else {
          newProducts.push(prod);
        }
      }

      this.setState({
        productData: newProducts,
      });

    } catch(err) {
      console.error(err);
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'Inventory AdminPage'}>
      <AdminTitle
        title={tx.TX_CATALOG}
        breadcrumbs={[]} />
      <div className={'inventoryIconRow adminIconRow'}>
        <Link to={URL_ADMIN_INVENTORY_ADD} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_ADD}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_INV_ADD_PRODUCT)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_INVENTORY_BULK_UPLOAD} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_UPLOAD}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_INV_BULK_UPLOAD_BULK_UPLOADS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_INVENTORY_PRODUCT_LINES} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_PRODUCT_LINE}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_PRODUCT_LINES)} 
            ellipsisLabel={false} />
        </Link>
      </div>
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_PRODUCT}
          schema={TABLE_SCHEMA_PRODUCT}
          data={this.state.productData}
          dataCount={this.state.productCount} 
          dataLoading={this.state.productPending}
          callApi={this.getProductPage.bind(this)}
          hotSwap={this.hotSwapProduct.bind(this)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    product: state.product,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Inventory));