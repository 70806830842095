import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { history } from '../../store';

import { API_KEY_SORT } from '../../constants/api';
import {
  CAL_DISPLAY_TYPE_MOBILE,
  CAL_DISPLAY_TYPE_MONTHLY,
  CAL_DISPLAY_TYPE_WEEKLY,
} from '../../constants/calendar';
import { 
  CSS_CALENDAR_BREAK, 
  CSS_SLIDE_DURATION,
  CSS_SLIDE_FAST_DURATION, 
} from '../../constants/css';
import { EVENT_FILTER_KEY_PRODUCT_LINE } from '../../constants/events';
import * as tx from '../../constants/strings';
import { 
  URL_PARAM_DAY,
  URL_PARAM_MONTH,
  URL_PARAM_YEAR,
} from '../../constants/urls';

import {
  Month,
  Week,
} from '../../models/calendar';

import { 
  eventMonthFetched,
  getDaysEvents, 
} from '../../utils/events';
import { 
  isDateToday,
  urlQueryParams,
} from '../../utils/general';
import { getUrlParams } from '../../utils/request';

import EventCalendarControls from './blocks/EventCalendarControls';
import EventCalendarDay from './blocks/EventCalendarDay';
import EventCalendarEventSummary from './blocks/EventCalendarEventSummary';
import EventCalendarNavigation from './blocks/EventCalendarNavigation';
import { LoadingIcon } from '../Icons/LoadingIcon';

import './style/_eventcalendar.scss';

import * as eventActionCreators from '../../actions/event';
const allActionCreators = Object.assign({}, eventActionCreators);

export class EventCalendar extends Component {

  constructor(props) {
    super(props);

    const now = new Date();
    const estimatedWidth = 0.9 * window.innerWidth;

    const getParams = urlQueryParams();

    const startDay = getParams[URL_PARAM_DAY] || null;
    const startMonth = getParams[URL_PARAM_MONTH] || null;
    const startYear = getParams[URL_PARAM_YEAR] || null;
    
    const startDate = startDay && startMonth && startYear ? new Date(startYear, startMonth, startDay) : null;

    this.state = {
      
      now: now,

      viewType: estimatedWidth > CSS_CALENDAR_BREAK ? CAL_DISPLAY_TYPE_MONTHLY : CAL_DISPLAY_TYPE_MOBILE,
      lastViewType: CAL_DISPLAY_TYPE_MONTHLY,

      selectedMonth: new Month({ month: startMonth, year: startYear }),
      selectedWeek: new Week({ date: startDate }),

      requestPending: false,

      eventModalDate: null,
      eventModalOpen: false,
      eventModalEvent: null,
      eventModalLastOpen: 0,
      eventModalClickHeight: 0.0,
      eventWeekIndex: 0,

      expanding: false,
      expandedDate: null,
      topLayerDate: null,

      calendarWidth: estimatedWidth,

      filters: {},
    }

    this.expandingTimeout = null;
    this.openTimeout = null;
    this.closeTimeout = null;

    this.controllers = {};

    this.calendarBodyRef = React.createRef();
    this.eventModalRef = React.createRef();
    this.checkClick = this.checkClick.bind(this);
    this.checkSizeThrottled = _.throttle(this.checkSize.bind(this), 50);
  }

  async componentDidMount() {
    
    document.addEventListener('click', this.checkClick, false);
    window.addEventListener('resize', this.checkSizeThrottled, false);

    if(this.props.plPermalink) {
      this.setFilter(EVENT_FILTER_KEY_PRODUCT_LINE, this.props.plPermalink);
    }

    this.refreshData(true);
    this.checkSize();
  }

  componentDidUpdate(prevProps, prevState) {

    // Listen for route changes
    if(prevProps.plPermalink !== this.props.plPermalink) {
      this.setFilter(EVENT_FILTER_KEY_PRODUCT_LINE, this.props.plPermalink);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.expandingTimeout);
    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
    document.removeEventListener('click', this.checkClick, false);
    window.removeEventListener('resize', this.checkSizeThrottled, false);
  }

  isMobileView() {
    // All dimension checking and view setting done in checkSize
    return this.state.viewType === CAL_DISPLAY_TYPE_MOBILE;
  }

  checkClick(evt) {

    const OPEN_DELAY = 100;

    if(this.state.eventModalOpen && this.state.eventModalLastOpen < Date.now() - OPEN_DELAY) {
      let targetElement = evt.target;
      do {
        if(this.eventModalRef && targetElement === this.eventModalRef.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeEventModal();
    }

    if(this.state.expandedDate !== null && this.state.expanding === false && this.state.viewType === CAL_DISPLAY_TYPE_MONTHLY) {
      let targetElement = evt.target;
      do {
        try {
          if(targetElement.classList.contains('ecCalDay') && targetElement.classList.contains('expanded')) {
            return null;
          }
        } catch(err) {
          // Do nothing
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.setExpandedDate(null);
    }
  }

  checkSize() {

    const WIDTH_HYSTERESIS = 15;

    if(!this.calendarBodyRef || !this.calendarBodyRef.current) {
      return null;
    }

    const calWidth = this.calendarBodyRef.current.getBoundingClientRect().width;
    if(!calWidth) {
      return null;
    }

    this.setState({ 
      calendarWidth: calWidth, 
    }, () => {
      if(calWidth < (CSS_CALENDAR_BREAK - WIDTH_HYSTERESIS) && this.isMobileView() === false) {
        this.setDisplayMode(CAL_DISPLAY_TYPE_MOBILE);
        this.closeEventModal();
      } else if(calWidth > (CSS_CALENDAR_BREAK + WIDTH_HYSTERESIS) && this.isMobileView() === true) {
        this.setDisplayMode(this.state.lastViewType);
      }
    });
  }

  async refreshData(forceReq = false) {

    if(!eventMonthFetched(this.state.selectedMonth, this.props.event.calendarEvents)) {
      this.setState({ requestPending: true });
    }

    await this.getEventForMonth(this.state.selectedMonth, null, {}, forceReq);
    await this.getEventForMonth(this.state.selectedMonth.nextMonth(), null, {}, forceReq);
    await this.getEventForMonth(this.state.selectedMonth.lastMonth(), null, {}, forceReq);

    if(this.state.requestPending) {
      this.setState({ requestPending: false });
    }

    await this.getEventForMonth(this.state.selectedMonth.nextMonth().nextMonth(), null, {}, forceReq);
    await this.getEventForMonth(this.state.selectedMonth.lastMonth().lastMonth(), null, {}, forceReq);
  }

  async getEventForMonth(month = this.state.selectedMonth, sort = null, filters = {}, forceReq = false) {

    if(forceReq === false && eventMonthFetched(month, this.props.event.calendarEvents)) {
      return null;
    }

    if(this.controllers[month.year] && this.controllers[month.year][month.monthIndex]) {
      this.controllers[month.year][month.monthIndex].abort();
    }

    if(!this.controllers[month.year]) {
      this.controllers[month.year] = {};
    }

    const controller = new AbortController();
    this.controllers[month.year][month.monthIndex] = controller;

    // Add the +1 for backend since python month index starts at 1, not 0
    const controlParams = {
      month: month.monthIndex + 1,
      year: month.year,
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    const fetchResp = await this.props.eventAdminFetchMonth(getParams, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) {
          // Request aborted; do nothing
        } else if(errResp) {
          console.error(errResp);
        }
      });

    if(!fetchResp) {
      return null;
    }
  
    this.props.eventSetMonthEvents({
      month: month.monthIndex,
      year: month.year,
      events: fetchResp.events,
      schedules: fetchResp.schedules,
    });
  }

  getWeekWrapperClass(week, weekIndex) {

    const baseClass = 'ecCalWeek';

    if(this.isMobileView()) {
      return `${baseClass} mobileWeek`;
    }
    
    const isWeekViewClass = this.state.viewType === CAL_DISPLAY_TYPE_WEEKLY ? ' finalWeek weekView' : '';
    const isFirstWeekClass = this.state.viewType === CAL_DISPLAY_TYPE_MONTHLY && weekIndex === 0 ? ' firstWeek' : '';
    const isLastWeekClass = this.state.viewType === CAL_DISPLAY_TYPE_MONTHLY && this.state.selectedMonth.weeksCount === weekIndex + 1 ? ' finalWeek' : '';

    return `${baseClass}${isWeekViewClass}${isFirstWeekClass}${isLastWeekClass}`;
  }

  isDayExpanded(day) {
    if(this.state.expandedDate === null) { return false; }

    try {
      if(day.getFullYear() === this.state.expandedDate.getFullYear() && day.getMonth() === this.state.expandedDate.getMonth() && day.getDate() === this.state.expandedDate.getDate()) {
        return true;
      }
    } catch(err) {
      return false;
    }
    return false;
  }

  isDayTopLayer(day) {
    if(this.state.topLayerDate === null) { return false; }

    try {
      if(day.getFullYear() === this.state.topLayerDate.getFullYear() && day.getMonth() === this.state.topLayerDate.getMonth() && day.getDate() === this.state.topLayerDate.getDate()) {
        return true;
      }
    } catch(err) {
      return false;
    }
    return false;
  }

  getDayWrapperClass(day, dayIndex) {

    const baseClass = 'ecCalDay';

    const firstOfWeekClass = dayIndex === 0 ? ' firstOfWeek' : '';
    const isTodayClass = isDateToday(day) ? ' ecCalToday' : '';
    const lastOfWeekClass = dayIndex === 6 && !this.isMobileView() ? ' lastOfWeek' : '';
    const mobileClass = this.isMobileView() ? ' mobileDay' : '';
    const isExpandedClass = this.isDayExpanded(day) ? ' expanded' : '';
    const isTopLayerClass = this.isDayTopLayer(day) ? ' topped' : '';

    return `${baseClass}${firstOfWeekClass}${isTodayClass}${lastOfWeekClass}${mobileClass}${isExpandedClass}${isTopLayerClass}`;
  }

  setDisplayMonth(mon) {
    this.setState({ 
      selectedMonth: mon, 
      selectedWeek: new Week({ date: mon.firstDate }),
    }, () => {
      this.refreshData();
    });
  }

  setDisplayWeek(week) {
    this.setState({ 
      selectedMonth: new Month({ monthIndex: week.monthIndex, year: week.year }),
      selectedWeek: week, 
    }, () => {
      this.refreshData();
    });
  }

  setDisplayMode(mode) {
    this.setState({ 
      viewType: mode,
      lastViewType: this.state.viewType !== CAL_DISPLAY_TYPE_MOBILE ? this.state.viewType : this.state.lastViewType,
    });
  }

  getWeekArray() {
    if(this.state.viewType === CAL_DISPLAY_TYPE_MONTHLY) {
      return this.state.selectedMonth.calendarWeeks();
    } else if(this.state.viewType === CAL_DISPLAY_TYPE_WEEKLY) {
      return [ this.state.selectedWeek ];
    } else if(this.state.viewType === CAL_DISPLAY_TYPE_MOBILE) {
      return this.state.selectedMonth.calendarWeeks();
    }
    return [];
  }

  openEventModal(evt, eventObj, idx, evtDate) {

    if(!this.calendarBodyRef || !this.calendarBodyRef.current || this.isMobileView()) {
      return null;
    }

    const percent = (evt.clientY - this.calendarBodyRef.current.getBoundingClientRect().top) / this.calendarBodyRef.current.getBoundingClientRect().height;

    let lastOpen = this.state.eventModalOpen ? 0 : Date.now();
    let delayOpen = 0;

    if(this.state.eventModalOpen && this.state.eventModalEvent.publicUuid === eventObj.public_uuid) {
      lastOpen = Date.now();
    } else if(this.state.eventModalOpen) {
      delayOpen = 25;
      this.closeEventModal();
    }
    
    this.openTimeout = setTimeout(() => {
      this.setState({
        eventModalDate: evtDate,
        eventModalOpen: true,
        eventModalEvent: eventObj,
        eventModalLastOpen: lastOpen,
        eventModalClickHeight: percent,
        eventWeekIndex: idx,
      }, () => {
        history.replace(window.location.pathname + getUrlParams(eventObj.getUrlParams()));
      });
    }, delayOpen);
  }

  closeEventModal() {
    this.setState({
      eventModalDate: null,
      eventModalOpen: false,
      eventModalEvent: null,
      eventModalClickHeight: 0.0,
      eventWeekIndex: 0,
    }, () => {
      history.replace(window.location.pathname);
    });
  }

  getModalWrapperClass() {

    const baseClass = 'ecEventModalWrapper';
    const openValue = this.state.eventModalClickHeight && this.state.eventModalClickHeight > 0.5 ? 'openBottom' : 'open';

    const openClass = this.state.eventModalOpen ? ` ${openValue}` : ``;
    const indexClass = this.state.eventWeekIndex === 0 || this.state.eventWeekIndex > 0 ? ` idx${this.state.eventWeekIndex}` : ``;

    return `${baseClass}${openClass}${indexClass}`;
  }

  setExpandedDate(day) {
    if(this.state.expandedDate === null) {
      this.setState({ 
        expanding: true,
        expandedDate: day, 
        topLayerDate: day,
      }, () => {
        this.expandingTimeout = setTimeout(() => {
          this.setState({ expanding: false });
        }, CSS_SLIDE_DURATION);
      });  
    } else {
      this.setState({ 
        expandedDate: null, 
      }, () => {
        this.closeTimeout = setTimeout(() => {
          this.setState({ topLayerDate: null });
        }, CSS_SLIDE_FAST_DURATION);
      }); 
    }
  }

  setFilter(key, val, cb) {

    if(key === EVENT_FILTER_KEY_PRODUCT_LINE) {
      this.props.setPlPermalink(val);
    }

    this.setState({
      filters: Object.assign({}, this.state.filters, { [key]: val }),
    }, () => {
      if(cb) { cb(); }
    });
  }

  render() {

    let daysRendered = 0;
    const {t} = this.props;

    return <div className={this.props.admin ? 'EventCalendar adminTheme' : 'EventCalendar'} ref={this.calendarBodyRef}>
      <div className='ecLiner'>
        <div className='ecNavWrapper'>
          <EventCalendarNavigation
            admin={this.props.admin}
            displayMode={this.state.viewType}
            displayMonth={this.state.selectedMonth}
            displayWeek={this.state.selectedWeek}
            setMonth={this.setDisplayMonth.bind(this)}
            setWeek={this.setDisplayWeek.bind(this)} />
        </div>
        <div className='ecControlsWrapper'>
          <EventCalendarControls
            admin={this.props.admin}
            displayMode={this.state.viewType}
            filters={this.state.filters}
            setDisplayMode={this.setDisplayMode.bind(this)}
            setFilter={this.setFilter.bind(this)}
            width={this.state.calendarWidth} />
        </div>
        <div className='ecCalWrapper'>
          <div className='ecCalLiner'>
            {this.isMobileView() === false ?
              <>
                {this.getWeekArray().map((week, i) => {
                  return <div className={this.getWeekWrapperClass(week, i)} key={`wk${i}mon${this.state.selectedMonth.monthIndex}`}>
                    {week.days.map((day, j) => {
                      return <div className={this.getDayWrapperClass(day, j)} key={`day${j}wk${i}mon${this.state.selectedMonth.monthIndex}`}>
                        <EventCalendarDay
                          admin={this.props.admin}
                          date={day}
                          displayMode={this.state.viewType}
                          displayMonth={this.state.selectedMonth}
                          expanded={this.isDayExpanded(day)}
                          filters={this.state.filters}
                          firstWeek={i === 0}
                          loading={this.state.requestPending}
                          openModal={this.openEventModal.bind(this)}
                          setExpanded={this.setExpandedDate.bind(this)}
                          weekIndex={j} />
                      </div>
                    })}
                  </div>
                })}
              </> :
              <>
                <div className={this.getWeekWrapperClass()}>
                  {this.state.selectedMonth.days.map((day, k) => {
                    
                    if(getDaysEvents(day, this.props.event.calendarEvents, this.props.event.calendarSchedules, this.state.filters).length === 0 && !isDateToday(day)) {
                      return null;
                    }

                    daysRendered++;

                    return <div className={this.getDayWrapperClass(day, k)} key={`day${k}wkMmon${this.state.selectedMonth.monthIndex}`}>
                      <EventCalendarDay
                        admin={this.props.admin}
                        date={day}
                        displayMode={this.state.viewType}
                        displayMonth={this.state.selectedMonth}
                        filters={this.state.filters}
                        firstWeek={true}
                        loading={this.state.requestPending}
                        openModal={this.openEventModal.bind(this)}
                        weekIndex={0} />
                    </div>
                  })}
                  {this.isMobileView() && daysRendered === 0 ?
                    <div className='noDaysRendered'>
                      {t(tx.TX_EVENTS_NO_EVENTS_SCHEDULED)}
                    </div> :
                    null
                  }
                </div>
              </>
            }
          </div>
          {!this.isMobileView() ?
            <div className={this.getModalWrapperClass()} ref={this.eventModalRef}>
              <EventCalendarEventSummary
                admin={this.props.admin}
                closeAction={this.closeEventModal.bind(this)}
                date={this.state.eventModalDate}
                displayMode={this.state.viewType}
                eventObj={this.state.eventModalEvent} />
            </div> :
            null
          }
          {this.state.requestPending ?
            <div className='ecLoadingOverlay'>
              <div className='ecLoadingIconWrapper'>
                <LoadingIcon />
              </div>
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventCalendar));