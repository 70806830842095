import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';
import { 
  URL_HOME, 
  URL_FORGOT_PASSWORD, 
  URL_REGISTER, 
} from '../../constants/urls';

import { setReAuth } from '../../utils/auth';
import { isFormValid, getEmailError, getPasswordInputError } from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import './style/_auth.scss';

import * as authActionCreators from '../../actions/auth';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, userActionCreators);

export class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordError: '',

      loginReqError: null,
      loginReqPending: false,
    };
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const login_data = {
        email_address: this.state.email,
        password: this.state.password,
      };

      this.setState({ loginReqPending: true, loginReqError: null });

      this.props.usersLogin(login_data)
      .then((resp) => {
        
        // Record login in store, primarily to trigger re-renders
        this.props.authSet(resp);

        // Init reAuth timer
        setReAuth();

        // Navigate to homepage, later will need logic to check if there is another page to revert to
        this.props.history.push(URL_HOME);
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          loginReqPending: false,
          loginReqError: formatServerError(errResp),
        });
      });
    }
  }

  validateAll() {
    let errorObj = {
      emailError: getEmailError(this.state.email),
      passwordError: getPasswordInputError(this.state.password),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeEmail(evt) {
    this.setState({ email: evt.target.value }, () => {
      if(this.state.emailError) {
        this.validateEmail(this.state.email);
      }
    });
  }

  validateEmail() {
    this.setState({ emailError: getEmailError(this.state.email) });
  }

  changePassword(evt) {
    this.setState({ password: evt.target.value }, () => {
      if(this.state.passwordError) {
        this.validatePassword(this.state.password);
      }
    });
  }

  validatePassword() {
    this.setState({ passwordError: getPasswordInputError(this.state.password) });
  }

  render() {

    const {t} = this.props;

    return <div className={'Login authPage'}>
      <div className='authPageTitle'>{t(tx.TX_LOGIN)}</div>
      <div className='authPageBody'>
        <div className='loginWrapper'>
          <form className='loginForm' onSubmit={this.handleSubmit.bind(this)}>
            <div className={this.state.loginReqError ? 'loginFormError present' : 'loginFormError'}>{t(this.state.loginReqError)}</div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
              <input
                type='email'
                autoComplete='email'
                className={this.state.emailError ? 'InputError' : ''}
                value={this.state.email}
                onChange={this.changeEmail.bind(this)}
                onBlur={this.validateEmail.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_EMAIL)} />
              {this.state.emailError ?
                <div className={'loginError FieldError'}>{t(this.state.emailError)}</div> :
                null
              }
            </div>
            <div className='loginFieldWrapper'>
              <div className='loginFieldLabel'>{t(tx.TX_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='current-password'
                className={this.state.passwordError ? 'InputError' : ''}
                value={this.state.password}
                onChange={this.changePassword.bind(this)}
                onBlur={this.validatePassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)} />
              {this.state.passwordError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordError)}</div> :
                null
              }
            </div>
            <div className='loginFieldWrapper'>
              <div className='forgotPasswordWrapper'>
                <Link to={{
                  pathname: URL_FORGOT_PASSWORD,
                  state: {
                    emailVal: this.state.email
                  },
                }}>{t(tx.TX_FORGOT_PASSWORD_QUESTION)}</Link>
              </div>
            </div>
            <div className='loginFieldWrapper'>
              <button 
                className='loginSubmitButton'
                disabled={this.state.loginReqPending}>
                {t(tx.TX_LOGIN_SHORT)}
              </button>
            </div>
            
          </form>
          <div className='registerToggle'>
            <div className='toggleHeader'>{t(tx.TX_AUTH_DONT_HAVE_ACCOUNT)}</div>
            <div className='toggleCopy'>{t(tx.TX_AUTH_JOIN_COMMUNITY, {storeName: process.env.REACT_APP_WEBSITE_NAME})}</div>
            <Link to={URL_REGISTER}>
              <button className='toggleButton'>{t(tx.TX_CREATE_ACCOUNT)}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Login));