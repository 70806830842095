
import { 
	getAuthToken, 
	getSessionId, 
	needAuthReset, 
} from './auth';



export function parseJSON(response) {
	const status = response.status;
	return response.json()
		.then((data) => {
			return {
				status: status,
				data: data
			};
		});
}

export function parseStatus(response) {
	const status = response.status;
	return {
		status: status,
	}
}

export function isOk(status) {
  if(status >= 200 && status < 300) {
    return true;
  } else {
    return false;
  }
}

export function isRedirect(status) {
	if(status >= 300 && status < 400) {
    return true;
  } else {
    return false;
  }
}

export function authReq(headers) {

	const authHeaders = {};
	const authToken = needAuthReset() === false ? getAuthToken() : null;
	const sessionId = getSessionId();
	const storeId = process.env.REACT_APP_STORE_UUID;

	// Auth Token
	if(authToken) {
		authHeaders['Authorization'] = `Token ${authToken}`;
	}

	// Session Id
	authHeaders['x-sessionid'] = sessionId;

	// Store Id
	authHeaders['x-storeid'] = storeId;

	return Object.assign({}, headers, authHeaders);
}

export function refreshReq(headers) {

	const authHeaders = {};
	const storeId = process.env.REACT_APP_STORE_UUID;

	// Store Id
	authHeaders['x-storeid'] = storeId;

	return Object.assign({}, headers, authHeaders);
}

export function getUrlParams(paramObj) {

	if(!paramObj || Object.keys(paramObj).length === 0) {
		return '';
	}

	let formattedList = [];
	for(const [key, value] of Object.entries(paramObj)) {
		const formattedValue = encodeURIComponent(value);
	  	formattedList.push(`${key}=${formattedValue}`);
	}
	return '?' + formattedList.join('&');
}

export function getPageLimit(pageNum, pageSize) {
	return pageSize;
}

export function getPageOffset(pageNum, pageSize) {
	return parseInt(pageNum - 1) * parseInt(pageSize);
}
