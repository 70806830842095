import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import Dropdown from '../../../Input/Dropdown';
import LoadingIcon from '../../../Icons/LoadingIcon';

import * as productLineActionCreators from '../../../../actions/product-line';
const allActionCreators = Object.assign({}, productLineActionCreators);

export class FilterProductLine extends Component {

  componentDidMount() {
    if(this.props.productLine && this.props.productLine.productLines === null) {     
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
        this.props.setCustomFilterOptions({
          [this.props.filterElement.key]: resp,
        });
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    } else {
      this.props.setCustomFilterOptions({
        [this.props.filterElement.key]: this.props.productLine.productLines,
      });
    }
  }

  getProductLineOptions() {

    if(!this.props.productLine.productLines) {
      return [{
        display: this.props.t(tx.TX_LOADING),
        value: '',
      }];
    }

    const options = [
      {
        display: this.props.t(tx.TX_PLACEHOLDER_PL_DROPDOWN),
        value: '',
      },
    ];
    for(const pl of this.props.productLine.productLines) {
      options.push({
        display: pl.name,
        value: pl.permalink,
      });
    }
    return options;
  }

  changeProductLine(evt) {
    const plPermalink = evt.target.value;
    if(!plPermalink) {
      this.props.clearFilter();
    } else {
      this.props.setFilters({
        [this.props.filterElement.key]: plPermalink,
      });
    }
  }

  getCurrentValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.key) === false) {
        return '';
      } else {
        return this.props.filters[this.props.filterElement.key];
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterProductLine AdminTableFilter'}>
      <div className='filterElementTitle'>
        <div className='filterElementTitleValue'>{t(this.props.filterElement.name)}</div>
        <div className='filterElementClearLink' onClick={this.props.clearFilter.bind(this)}>{t(tx.TX_CLEAR)}</div>
      </div>
      <div className='filterElementBody'>
        {this.props.productLine.productLinesPending ?
          <div className='elementLoading'>
            <div className='loadingIconWrapper'>
              <LoadingIcon iconClass='loadingIcon' />
            </div>
            <div className='loadingValue'>{t(tx.TX_LOADING)}</div>
          </div> :
          <div className='singleInput'>
            <Dropdown 
              className='singleInputDropdown'
              options={this.getProductLineOptions()}
              name={t(this.props.filterElement.name)}
              noTranslate={true}
              value={this.getCurrentValue()}
              required={true}
              onChange={this.changeProductLine.bind(this)} />
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterProductLine));