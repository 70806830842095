import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED,
  STORE_INTEGRATION_TYPE_SHIPPING_KEY, 
} from '../../../../constants/store';
import * as tx from '../../../../constants/strings';

import Modal from '../../../Popups/Modal';

import * as storeActionCreators from '../../../../actions/store';
const allActionCreators = Object.assign({}, storeActionCreators);

export class IntegrationDisplayStampsParcelGuardModal extends Component {

	constructor(props) {
    super(props);

    this.state = {
    	open: false,
      termsUrl: '',
    };

    this.controller = null;
  }

  componentDidMount() {
    this.fetchParcelguardTerms();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  closeMethod(confirm = false) {
    this.setState({ 
      open: false, 
    }, () => {
      const config = this.props.integration.config[STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED];
      if(config.value && !confirm) {
        this.setConfigAttribute(false);
      }
    });
  }

  async confirmMethod() {
    this.setState({ 
      open: false,
    }, () => {
      this.setConfigAttribute(true);
    });
  }

  async fetchParcelguardTerms() {

  	if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

  	const statusResp = await this.props.storeIntegrationStampsParcelguardStatus(this.props.integration.publicUuid, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        console.error(errResp);
      });

    if(!statusResp) {
      return null;
    }

    // this.setState({
    //   open: true,
    //   termsUrl: 'https://talaria.shop',
    // });

    if(statusResp.available === false) {
      // TODO: block config
    } else if(statusResp.enabled === false) {

      const config = this.props.integration.config[STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED];

      if(statusResp.url && (this.props.initialLoad || config.value)) {
        this.setState({
          open: true,
          termsUrl: statusResp.url,
        });
      } else if(config.value) {
        this.setConfigAttribute(false);
      }
    }
  }

  async setConfigAttribute(value) {

    const config = this.props.integration.config[STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED];
    const configData = config.getApiData(value);

    const configResp = await this.props.storeUpdateIntegration(configData, this.props.integration.publicUuid)
      .catch((errResp) => {
        console.error(errResp);
      });

    if(!configResp) { return null; }

    this.props.makeClean(STORE_INTEGRATION_TYPE_SHIPPING_KEY);
  }
  
  render() {

    const {t} = this.props;

    return <div className={'IntegrationDisplayStampsParcelGuardModal'}>
      <Modal
        title={tx.TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD}
        open={this.state.open}
        confirmValue={tx.TX_FINISH_VERB}
        closeMethod={this.closeMethod.bind(this)}
        onConfirm={this.confirmMethod.bind(this)}>

        <div className='pgTermsWrapper'>
          {this.state.termsUrl ?
            <>
              <iframe src={this.state.termsUrl} className='pgTerms' title={t(tx.TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD)}></iframe>
              <div className='pgHr'></div>
              <div className='pgTermsCopy'>
                {t(tx.TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_TERMS_NOTICE)}
                <a href={this.state.termsUrl} className='pgTermsLink' target='_blank' rel='noreferrer'>{t(tx.TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_TERMS_OPEN)}</a>
              </div>
            </> :
            null
          }
        </div>

      </Modal>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationDisplayStampsParcelGuardModal));