
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isLoggedIn } from '../utils/auth';

import { STORE_MAINTENANCE_MODE } from '../constants/store';
import {
  URL_LOGIN, 
  URL_MAINTENANCE, 
} from '../constants/urls';

const SetPasswordRoute = ({ component: Component, render, ...rest }) => {

  const renderContent = props => {
    
    if(STORE_MAINTENANCE_MODE) {
      // Use window.location to force hard refresh
      window.location.href = URL_MAINTENANCE;
    }

    if (!isLoggedIn()) {
      return (
        <Redirect
          to={{
            pathname: URL_LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }

    return typeof render === 'function' ? (
      render(props)
    ) : (
      <Component {...props} />
    );
  };
  return <Route {...rest} render={renderContent} />;
};

export default SetPasswordRoute;