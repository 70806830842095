import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  ERR_5500,
  ERROR_ORDER_CARRIER_MISSING,
  ERROR_ORDER_CARRIER_SELECT,
  ERROR_ORDER_TRACKING_NUMBER_MISSING, 
} from '../../../../constants/errors';
import { ORDER_STATUS_SHIPPED } from '../../../../constants/orders';
import { SHIPMENT_CARRIER_KEY_OTHER } from '../../../../constants/shipments';

import { 
  Shipment,
  ShipmentCarrier, 
} from '../../../../models/shipments';

import { 
  getNotEmptyError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import * as tx from '../../../../constants/strings';

import Dropdown from '../../../Input/Dropdown';
import LoadingIcon from '../../../Icons/LoadingIcon';
import Toggle from '../../../Input/Toggle';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class BulkActionShipOrders extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputCarrier: '',
      inputCarrierAltName: '', 
      inputHasTracking: false,
      inputTracking: '',
      
      errorCarrier: '',
      errorCarrierAltName: '',
      errorTracking: '',

      requestPending: false,
      requestError: null,
    }

    this.DISPLAY_LIMIT = 20;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  validateAll() {

    const errorObj = {
      errorCarrier: getNotEmptyError(this.state.inputCarrier, this.getCarrierError()),
    };

    if(this.state.inputCarrier && this.state.inputCarrier.key === SHIPMENT_CARRIER_KEY_OTHER) {
      errorObj['errorCarrierAltName'] = getNotEmptyError(this.state.inputCarrierAltName, ERROR_ORDER_CARRIER_MISSING);
    } else {
      errorObj['errorCarrierAltName'] = '';
    }

    if(this.state.inputCarrier && this.state.inputCarrier.hasTracking && this.state.inputHasTracking) {
      errorObj['errorTracking'] = getNotEmptyError(this.state.inputTracking, ERROR_ORDER_TRACKING_NUMBER_MISSING);
    } else {
      errorObj['errorTracking'] = '';
    }

    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async saveAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      this.props.blockClose(true);
      this.setState({
        requestPending: true,
        requestError: null,
      });

      // Create shipments
      const shipmentArray = [];
      for(const odr of this.props.selectedRows) {
        const newShipment = new Shipment({ 
          trackingNumber: this.state.inputTracking,
          carrier: this.state.inputCarrier,
          carrierAlt: this.state.inputCarrierAltName,
        });
        shipmentArray.push(this.props.ordersAddShipment(newShipment.getApiData(), odr.publicUuid));
      }
      const shipmentResp = await Promise.all(shipmentArray)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: errResp && errResp.error ? errResp.error : ERR_5500,
          });
          this.props.blockClose(false);
        });

      if(!shipmentResp) {
        this.props.blockClose(false);
        return null;
      }

      const statusData = {
        status: ORDER_STATUS_SHIPPED.key,
        reason: this.props.t(tx.TX_ORDER_HISTORY_SHIPMENT),
      };

      const statusArray = [];
      for(const odr of this.props.selectedRows) {
        statusArray.push(this.props.ordersUpdateStatus(statusData, odr.publicUuid));
      }
      const statusResp = await Promise.all(statusArray)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.blockClose(false);
        });

      if(!statusResp) {
        this.props.blockClose(false);
        return null;
      }

      this.props.blockClose(false);
      this.props.refreshData(false, false, true);

      setTimeout(() => {
        this.props.closeMethod();
      }, 50);
    }
  }

  changeCarrier(evt) {
    this.setState({
      inputCarrier: new ShipmentCarrier(evt.target.value),
    }, () => {
      if(this.state.errorCarrier) {
        this.validateCarrier();
      }
      if(evt.target.value === '' && this.state.inputHasTracking) {
        this.setState({
          inputHasTracking: false,
        });
      }
    });
  }

  validateCarrier() {
    this.setState({ errorCarrier: getNotEmptyError(this.state.inputCarrier, this.getCarrierError()) });
  }

  getCarrierOptions() {
    const respArray = [];
    try {
      respArray.push({
        display: tx.TX_PLACEHOLDER_SHIPMENT_CARRIER,
        value: '',
      });
      for(const carrierObj of ShipmentCarrier.getByCounty(this.props.user && this.props.user.store ? this.props.user.store.countryCode : null)) {
        
        const shippingCarrier = new ShipmentCarrier(carrierObj);
        if(shippingCarrier.key === SHIPMENT_CARRIER_KEY_OTHER) {
          continue;
        }

        respArray.push({
          display: shippingCarrier.name,
          value: shippingCarrier.key,
        });
      }
      respArray.push({
        display: tx.TX_OTHER,
        value: SHIPMENT_CARRIER_KEY_OTHER,
      });
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  getCarrierError() {
    return this.state.inputCarrier && this.state.inputCarrier.key === SHIPMENT_CARRIER_KEY_OTHER ? ERROR_ORDER_CARRIER_MISSING : ERROR_ORDER_CARRIER_SELECT;
  }

  changeCarrierAltName(evt) {
    this.setState({
      inputCarrierAltName: evt.target.value,
    }, () => {
      if(this.state.errorCarrierAltName) {
        this.validateCarrierAltName();
      }
    });
  }

  validateCarrierAltName() {
    this.setState({ errorCarrierAltName: getNotEmptyError(this.state.inputCarrierAltName, ERROR_ORDER_CARRIER_MISSING) });
  }

  changeHasTracking(evt) {
    this.setState({
      inputHasTracking: !this.state.inputHasTracking,
    });
  }

  changeTracking(evt) {
    this.setState({
      inputTracking: evt.target.value,
    }, () => {
      if(this.state.errorTracking) {
        this.validateTracking();
      }
    });
  }

  validateTracking() {
    this.setState({ errorTracking: getNotEmptyError(this.state.inputTracking, ERROR_ORDER_TRACKING_NUMBER_MISSING) });
  }

  shipmentsPresent() {

    const ordersWithShipment = [];
    
    for(const odr of this.props.selectedRows) {

      if(odr.requiresShipping && odr.shipmentCount > 0) {
        ordersWithShipment.push(odr.displayNumber);
      } else if(!odr.readyForShipment) {
        ordersWithShipment.push(odr.displayNumber);
      }
    }

    return ordersWithShipment;
  }

  trackingDisabled() {
    if(this.props.selectedRows.length > 1) {
      return true;
    }
    return this.state.inputCarrier && this.state.inputCarrier.hasTracking ? false : true;
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkActionShipOrders BulkActionModal'}>
      <div className='baWrapper'>
        
        {!this.props.selectedRows || this.props.selectedRows.length === 0 ?
          <div className='errorSelection'>
            <div className='errorBody'>
              <div className='errorLabel'>{t(tx.TX_ORDER_NO_ORDERS_SELECTED)}</div>
              <button className={'errorAction'} type='button' onClick={this.props.closeMethod.bind(this)}>{t(tx.TX_CLOSE)}</button>
            </div>
          </div> :
          <>
            {this.shipmentsPresent().length > 0 ?
              <div className='errorSelection'>
                <div className='errorBody'>
                  <div className='errorLabel'>{t(tx.TX_ORDER_SHIPMENTS_NOT_REQUIRED)}</div>
                  <div className='errorData'>
                    {this.shipmentsPresent().map((orderNum, i) => {
                      if(i > this.DISPLAY_LIMIT) {
                        return null;
                      }
                      if(i === this.DISPLAY_LIMIT) {
                        return <div key={i} className='errorDatum'>...</div>;
                      }
                      return <div key={i} className='errorDatum'>{orderNum}</div>;
                    })}
                  </div>
                  <button className={'errorAction'} type='button' onClick={this.props.closeMethod.bind(this)}>{t(tx.TX_CLOSE)}</button>
                </div>
              </div> :
              <>
                {this.state.requestPending ?
                  <div className='requestPending'>
                    <div className='loadingBody'>
                      <div className='loadingIconLabel'>{t(tx.TX_ORDER_CREATING_SHIPMENTS)}</div>
                      <div className='loadingIconWrapper'>
                        <LoadingIcon />
                      </div>
                    </div>
                  </div> :
                  <form 
                    className={'bulkActionAdminForm adminForm'}
                    onSubmit={this.saveAction.bind(this)}>

                    <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

                    <div className='adminFieldWrapper'>
                      <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ORDER_SHIPMENT_CARRIER)}</div>
                      <div className='adminInputWrapper'>
                        <div className='adminDropdownWrapper'>
                          <Dropdown 
                            className={'adminDropdownSelect'}
                            options={this.getCarrierOptions()}
                            name={t(tx.TX_ORDER_SHIPMENT_CARRIER)}
                            value={this.state.inputCarrier && this.state.inputCarrier.key ? this.state.inputCarrier.key : ''}
                            required={true}
                            onChange={this.changeCarrier.bind(this)} />
                        </div>
                        {this.state.errorCarrier ?
                          <div className={'adminError FieldError'}>{t(this.state.errorCarrier)}</div> :
                          null
                        }
                      </div>
                    </div>

                    {this.state.inputCarrier && this.state.inputCarrier.key === SHIPMENT_CARRIER_KEY_OTHER ?
                      <div className='adminFieldWrapper'>
                        <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ORDER_SHIPMENT_CARRIER_NAME)}</div>
                        <div className='adminInputWrapper'>
                          <input
                            type='text'
                            className={this.state.errorCarrierAltName ? 'InputError' : ''}
                            value={this.state.inputCarrierAltName}
                            onChange={this.changeCarrierAltName.bind(this)}
                            onBlur={this.validateCarrierAltName.bind(this)}
                            placeholder={t(tx.TX_PLACEHOLDER_CARRIER_NAME)}
                            maxLength={100} />
                        </div>
                        {this.state.errorCarrierAltName ?
                          <div className={'FieldError'}>{t(this.state.errorCarrierAltName)}</div> :
                          null
                        }
                      </div> :
                      null
                    }

                    {this.state.inputCarrier && this.state.inputCarrier.hasTracking ?
                      <div className='adminFieldWrapper'>
                        <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ORDER_HAS_TRACKING_QUESTION)}</div>
                        <div className='adminInputWrapper'>
                          <div className='adminInputToggleWrapper'>
                            <Toggle
                              checked={this.state.inputHasTracking}
                              disabled={this.trackingDisabled()}
                              onToggle={this.changeHasTracking.bind(this)}
                              trueValue={tx.TX_YES}
                              falseValue={tx.TX_NO} />
                          </div>
                        </div>
                        {this.props.selectedRows.length > 1 ?
                          <div className={'FieldNotice'}>{t(tx.TX_ORDER_TRACKING_ONLY_FOR_SINGLE_ORDER)}</div> :
                          null
                        }
                      </div> :
                      null
                    }

                    {this.state.inputCarrier && this.state.inputCarrier.hasTracking && this.state.inputHasTracking ?
                      <div className='adminFieldWrapper'>
                        <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ORDER_SHIPMENT_TRACKING_NUMBER)}</div>
                        <div className='adminInputWrapper'>
                          <input
                            type='text'
                            className={this.state.errorTracking ? 'InputError' : ''}
                            value={this.state.inputTracking}
                            onChange={this.changeTracking.bind(this)}
                            onBlur={this.validateTracking.bind(this)}
                            placeholder={t(tx.TX_PLACEHOLDER_TRACKING_NUMBER)}
                            maxLength={100} />
                        </div>
                        {this.state.errorTracking ?
                          <div className={'FieldError'}>{t(this.state.errorTracking)}</div> :
                          null
                        }
                      </div> :
                      null
                    }

                    <div className='adminActionRow'>
                      <button 
                        className={'adminAction adminActionSave'} 
                        type='submit'
                        disabled={this.state.requestPending}>
                        {t(tx.TX_SAVE)}
                      </button>
                    </div>

                  </form>
                }
              </>
            }
          </>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkActionShipOrders));