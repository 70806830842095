import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERR_5500 } from '../../../../constants/errors';

import { Pickup } from '../../../../models/shipments';

import { 
  getDescriptionError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { getStoreLanguage } from '../../../../utils/language';
import * as tx from '../../../../constants/strings';

import LoadingIcon from '../../../Icons/LoadingIcon';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class BulkActionReadyForPickup extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputNote: '',

      errorNote: '',

      requestPending: false,
      requestError: null,
    }

    this.DISPLAY_LIMIT = 20;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  validateAll() {
    const errorObj = {
      errorNote: getDescriptionError(this.state.inputNote, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async saveAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      this.props.blockClose(true);
      this.setState({
        requestPending: true,
        requestError: null,
      });

      // Create pickups
      const pickupArray = [];
      for(const odr of this.props.selectedRows) {

        const newPickup = new Pickup({
          isDropoff: false,
          isCompleteOrder: true,
          initNote: this.state.inputNote,
        });
      
        pickupArray.push(this.props.ordersAddPickup(newPickup.getApiDataCreate(), odr.publicUuid));
      }
      const pickupResp = await Promise.all(pickupArray)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: errResp && errResp.error ? errResp.error : ERR_5500,
          });
          this.props.blockClose(false);
        });

      if(!pickupResp) {
        this.props.blockClose(false);
        return null;
      }

      this.props.blockClose(false);
      this.props.refreshData(false, false, true);

      setTimeout(() => {
        this.props.closeMethod();
      }, 50);
    }
  }

  changeNote(evt) {
    this.setState({
      inputNote: evt.target.value,
    }, () => {
      if(this.state.errorNote) {
        this.validateNote();
      }
    });
  }

  validateNote() {
    this.setState({ errorNote: getDescriptionError(this.state.inputNote, true) });
  }

  pickupNotReady() {

    const ordersNotReadyForPickup = [];

    for(const odr of this.props.selectedRows) {
      if(!odr.pickupReadyToBeCreated) {
        ordersNotReadyForPickup.push(odr.displayNumber);
      }
    }
    return ordersNotReadyForPickup;
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkActionReadyForPickup BulkActionModal'}>
      <div className='baWrapper'>
        
        {!this.props.selectedRows || this.props.selectedRows.length === 0 ?
          <div className='errorSelection'>
            <div className='errorBody'>
              <div className='errorLabel'>{t(tx.TX_ORDER_NO_ORDERS_SELECTED)}</div>
              <button className={'errorAction'} type='button' onClick={this.props.closeMethod.bind(this)}>{t(tx.TX_CLOSE)}</button>
            </div>
          </div> :
          <>
            {this.pickupNotReady().length > 0 ?
              <div className='errorSelection'>
                <div className='errorBody'>
                  <div className='errorLabel'>{t(tx.TX_ORDER_NOT_READY_FOR_PICKUP)}</div>
                  <div className='errorData'>
                    {this.pickupNotReady().map((orderNum, i) => {
                      if(i > this.DISPLAY_LIMIT) {
                        return null;
                      }
                      if(i === this.DISPLAY_LIMIT) {
                        return <div key={i} className='errorDatum'>...</div>;
                      }
                      return <div key={i} className='errorDatum'>{orderNum}</div>;
                    })}
                  </div>
                  <button className={'errorAction'} type='button' onClick={this.props.closeMethod.bind(this)}>{t(tx.TX_CLOSE)}</button>
                </div>
              </div> :
              <>
                {this.state.requestPending ?
                  <div className='requestPending'>
                    <div className='loadingBody'>
                      <div className='loadingIconLabel'>{t(tx.TX_ORDER_CREATING_PICKUPS)}</div>
                      <div className='loadingIconWrapper'>
                        <LoadingIcon />
                      </div>
                    </div>
                  </div> :
                  <form 
                    className={'bulkActionAdminForm adminForm'}
                    onSubmit={this.saveAction.bind(this)}>

                    <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

                    <div className='adminFieldWrapper'>
                      <div className={'adminFieldLabel omOptional'}>{t(tx.TX_ORDER_PICKUPS_ADD_PICKUP_NOTE)}</div>
                      <div className='adminInputWrapper'>
                        <textarea
                          type='text'
                          className={this.state.errorNote ? 'InputError' : ''}
                          value={this.state.inputNote}
                          onChange={this.changeNote.bind(this)}
                          onBlur={this.validateNote.bind(this)}
                          placeholder={t(tx.TX_OPTIONAL)}
                          maxLength={190}></textarea>
                      </div>
                      {this.state.errorNote ?
                        <div className={'FieldError'}>{t(this.state.errorNote)}</div> :
                        null
                      }
                    </div>

                    <div className='adminActionRow'>
                      <button 
                        className={'adminAction adminActionSave'} 
                        type='submit'
                        disabled={this.state.requestPending}>
                        {t(tx.TX_SAVE)}
                      </button>
                    </div>

                  </form>
                }
              </>
            }
          </>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkActionReadyForPickup));