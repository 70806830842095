


import { TX_MARKETING_NOTICES_HEADER } from './strings';


export const MKT_NOTICE_KEY_HEADER = 'header';


export const MKT_NOTICES = [
	{
		key: MKT_NOTICE_KEY_HEADER,
		label: TX_MARKETING_NOTICES_HEADER,
	},
];



