import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  STORE_INTEGRATION_TYPE_COMMON_KEY,
  STORE_INTEGRATION_TYPE_SHIPPING_KEY, 
} from '../../../constants/store';
import * as tx from '../../../constants/strings';

import { 
  isFormValid, 
} from '../../../utils/form-validation';
import {
  formatServerError
} from '../../../utils/formatting';

import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';
import Toggle from '../../Input/Toggle';

import * as storeActionCreators from '../../../actions/store';
let allActionCreators = Object.assign({}, storeActionCreators);

export class IntegrationCommonEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      inputStatus: this.getIntegration() ? this.getIntegration().isEnabled : false,

      errorConfig: '', 

      oauthToggling: false,

      requestPending: false,
      requestError: null,

      resetConfirmOpen: false,
      savePromptOpen: false, 

      saveSignal: Date.now(),
    };

    this.controllerRevoke = null;

    this.oauthWrapper = React.createRef();

    this.endToggle = this.endToggle.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
      this.setState({ saveSignal: Date.now() });
    }
  }

  componentWillUnmount() {
    // Check for transition listeners
    if(this.oauthWrapper && this.oauthWrapper.current) {
      this.oauthWrapper.current.removeEventListener('transitionend', this.endToggle, false);
    }
    if(this.controllerRevoke) {
      this.controllerRevoke.abort();
    }
  }

  getIntegration() {
    // 
    // this.props.integration is the integration provider
    // 
    // This is hard-coded to the shipping integration because that's all there is
    // When that's not the case, is_enabled should be moved to the individual blocks
    // When that happens, you'll have to seperate props into common -> integration type
    // 

    if(this.props.integration) {
      return this.props.integration.getIntegration(STORE_INTEGRATION_TYPE_SHIPPING_KEY);
    }
    return null;
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  endToggle() {
    this.setState({
      oauthToggling: false,
    }, () => {
      if(this.oauthWrapper && this.oauthWrapper.current) {
        this.oauthWrapper.current.removeEventListener('transitionend', this.endToggle, false);
      }
    });
  }

  changeStatus(evt) {

    if(this.oauthWrapper && this.oauthWrapper.current) {
      this.oauthWrapper.current.addEventListener('transitionend', this.endToggle, false);
    }

    this.setState({
      inputStatus: !this.state.inputStatus, 
      oauthToggling: true,
    }, () => {

      if(this.state.inputStatus && this.state.inputStatus !== this.props.integration.isEnabled) {

      }

      if(this.state.inputStatus === true) {
        const configError = this.getIntegration().getConfigError();
        if(configError) {
          this.setState({
            inputStatus: false,
            errorConfig: configError,
          });
        } else {
          this.makeDirty();
        }
      } else {
        this.makeDirty();
      }
    });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.blockGeneralSave()) { return false; }
    if(this.validateAll(true)) {

      const integration = this.getIntegration();

      // If oauth backed, send revoke request first if disabling
      if(integration.oauth) {

        if(this.controllerRevoke) {
          this.controllerRevoke.abort();
        }
        const controllerRevoke = new AbortController();
        this.controllerRevoke = controllerRevoke;

        // Revoke data; none currently, but allow for the possibility
        const revokeData = {};
        const revokeResp = await this.props.storeIntegrationOauthRevoke(revokeData, integration.publicUuid, controllerRevoke.signal)
          .catch((errResp) => {
            if(controllerRevoke.signal.aborted) { return null; }
            console.error(errResp);
          });

        if(!revokeResp) {
          return null;
        }
      }

      const configData = {
        is_enabled: this.state.inputStatus,
      }

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const configResp = await this.props.storeUpdateIntegration(configData, integration.publicUuid)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });

          if(this.props.remoteSave) {
            this.props.finishRemote(STORE_INTEGRATION_TYPE_COMMON_KEY, true);
          }
        });

      if(!configResp) { return null; }

      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        this.props.makeClean(STORE_INTEGRATION_TYPE_COMMON_KEY);
        this.props.toggleEdit();
      });

    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote(STORE_INTEGRATION_TYPE_COMMON_KEY, true);
    }
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorConfig: this.getIntegration().getConfigError(),
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  getGeneralError() {
    if(this.state.requestError) {
      return this.state.requestError;
    }
    if(this.state.errorConfig) {
      return this.state.errorConfig;
    }
    return '';
  }

  getOauthAuthorizeComponent() {

    const primaryInt = this.getPrimaryIntegration();

    if(!primaryInt || !primaryInt.oauth || !primaryInt.oauth.componentAuthorize) { return null; }

    const CustomComponent = primaryInt.oauth.componentAuthorize;

    return <CustomComponent
              integration={primaryInt}
              integrationPending={this.props.integrationPending}
              saveSignal={this.state.saveSignal} />;
  }

  hasAuthorizeComponent() {
    const primaryInt = this.getPrimaryIntegration();

    if(primaryInt && primaryInt.oauth && primaryInt.oauth.componentAuthorize) {
      return true;
    }
    return false;
  }

  getPrimaryIntegration() {
    if(!this.props.integration || !this.props.integration.integrations || !this.props.integration.integrations.length) { return null; }
    return this.props.integration.integrations[0] || null;
  }

  blockGeneralSave() {
    if(this.hasAuthorizeComponent() && this.shouldShowAuthorize()) {
      return true;
    }
    return false;
  }
  
  shouldShowAuthorize() {
    if(!this.getPrimaryIntegration()) {
      return false;
    }
    return this.state.inputStatus && this.state.inputStatus !== this.getPrimaryIntegration().isEnabled;
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationCommonEdit'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'integrationDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_INTEGRATION)}</div>
            </div>
            <div className={this.getGeneralError() ? 'adminFormError present' : 'adminFormError'}>{this.getGeneralError() ? t(this.getGeneralError()) : ''}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputStatus}
                    onToggle={this.changeStatus.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>

            {this.hasAuthorizeComponent() ?
              <div 
                ref={this.oauthWrapper}
                className={`adminComponentSupplementWrapper${this.shouldShowAuthorize() ? ' open' : ''}${this.state.oauthToggling ? ' opening' : ''}`}>
                <div className='adminComponentSupplementBody'>{this.getOauthAuthorizeComponent()}</div>
              </div> :
              null
            }
            
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.validateAll() === false || this.state.requestPending || this.blockGeneralSave()}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationCommonEdit));