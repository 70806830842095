



export const WEIGHT_UNIT_G = {
	key: 'g',
	display: 'g',
	grams: 1,
	decimalPlaces: 0, 
	serverDefault: true,
};

const WEIGHT_UNIT_KG = {
	key: 'kg',
	display: 'kg',
	grams: 1000,
	decimalPlaces: 3, 
};

const WEIGHT_UNIT_OZ = {
	key: 'oz',
	display: 'oz',
	grams: 28.3495231,
	decimalPlaces: 1, 
};

const WEIGHT_UNIT_LB = {
	key: 'lb',
	display: 'lb',
	grams: 453.59237,
	decimalPlaces: 2, 
};

const WEIGHT_UNIT_STONE = {
	key: 'stone',
	display: 'stone',
	grams: 6350.29318,
	decimalPlaces: 3, 
};


export const WEIGHT_UNITS_ALL = [
	WEIGHT_UNIT_G,
	WEIGHT_UNIT_KG,
	WEIGHT_UNIT_OZ,
	WEIGHT_UNIT_LB,
	WEIGHT_UNIT_STONE,
];


export const DISTANCE_UNIT_MM = {
	key: 'mm',
	display: 'mm',
	mms: 1,
	decimalPlaces: 0, 
	serverDefault: true,
};

export const DISTANCE_UNIT_UM = {
	key: 'um',
	display: 'μm',
	mms: 0.001,
	decimalPlaces: 0, 
};

export const DISTANCE_UNIT_CM = {
	key: 'cm',
	display: 'cm',
	mms: 10,
	decimalPlaces: 1, 
};

export const DISTANCE_UNIT_M = {
	key: 'm',
	display: 'm',
	mms: 1000,
	decimalPlaces: 3, 
};

export const DISTANCE_UNIT_IN = {
	key: 'in',
	display: 'in',
	mms: 25.4,
	decimalPlaces: 1, 
};

export const DISTANCE_UNITS_ALL = [
	DISTANCE_UNIT_MM,
	DISTANCE_UNIT_UM,
	DISTANCE_UNIT_CM,
	DISTANCE_UNIT_M,
	DISTANCE_UNIT_IN,
];




