import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Papa from 'papaparse';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
} from '../../../constants/api';
import { 
  ICON_MENU_DOTS,
  ICON_PLAY, 
} from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  getPageLimit, 
  getPageOffset,
} from '../../../utils/request';

import { Icon } from '../../Icons/Icon';
import { LoadingIcon } from '../../Icons/LoadingIcon';

import BulkUploadDownloadResultsModal from '../../Popups/BulkUploadDownloadResultsModal';

import * as productActionCreators from '../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class BulkUploadProcessing extends Component {

  constructor(props) {
    super(props);

    this.STATUS_UPLOADING = 'uploading';
    this.STATUS_COMPLETE = 'complete';
    this.STATUS_INCOMPLETE = 'incomplete';

    this.DOWNLOAD_ERRORS = 'error';
    this.DOWNLOAD_SKIPPED = 'skipped';
    this.DOWNLOAD_LIMIT = 10000;

    this.state = {

      uploadStatus: '', 

      statusMenuOpen: false,

      downloadData: [],
      downloadModalOpen: false,
      downloadPending: false,
      downloadType: null, 
    };

    this.downloadController = null;

    this.barWrapper = React.createRef();
    this.iconWrapper = React.createRef();
    this.statusMenuIcon = React.createRef();
    this.statusMenuBody = React.createRef();

    this.checkClick = this.checkClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.props.requestUpload) {

      if(this.state.uploadStatus !== this.STATUS_UPLOADING && !this.props.requestUpload.isComplete() && this.props.requestUpload.isFresh()) {
        this.setState({
          uploadStatus: this.STATUS_UPLOADING,
        });
      }

      if(this.state.uploadStatus !== this.STATUS_COMPLETE && this.props.requestUpload.isComplete()) {
        this.setState({
          uploadStatus: this.STATUS_COMPLETE,
        });
      }

      if(this.state.uploadStatus !== this.STATUS_INCOMPLETE && !this.props.requestUpload.isComplete() && !this.props.requestUpload.isFresh()) {
        this.setState({
          uploadStatus: this.STATUS_INCOMPLETE,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
  }

  checkClick(evt) {

    // const OPEN_DELAY = 100;

    if(this.state.statusMenuOpen) {
      let targetElement = evt.target;
      do {
        if(this.statusMenuIcon && targetElement === this.statusMenuIcon.current) {
          return null;
        } else if(this.statusMenuBody && targetElement === this.statusMenuBody.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.toggleStatusMenu();
    }
  }

  getLabel() {

    if(this.props.requestPending) {
      return tx.TX_LOADING;
    }

    if(this.STATUS_UPLOADING === this.state.uploadStatus) {
      return tx.TX_INV_BULK_UPLOAD_STATUS_UPLOADING;
    } else if(this.STATUS_COMPLETE === this.state.uploadStatus) {
      return tx.TX_INV_BULK_UPLOAD_STATUS_COMPLETE;
    } else if(this.STATUS_INCOMPLETE === this.state.uploadStatus) {
      return tx.TX_INV_BULK_UPLOAD_STATUS_INCOMPLETE;
    }
    return tx.TX_null;
  }

  getStatusPercent() {
    if(!this.props.requestUpload) { return 0; }
    return this.props.requestUpload.percentComplete;
  }

  getIconWidth() {

    const widthDefault = 30;
    try {
      return this.iconWrapper.current.getBoundingClientRect().width;
    } catch(err) {
      return widthDefault;
    }
  }

  getBarWidth() {

    const widthDefault = 780;
    try {
      return this.barWrapper.current.getBoundingClientRect().width;
    } catch(err) {
      return widthDefault;
    }
  }

  getFillBarStyle() {
    if(this.STATUS_COMPLETE === this.state.uploadStatus) {
      return { width: '100%' };
    } else if(this.STATUS_UPLOADING === this.state.uploadStatus || this.STATUS_INCOMPLETE === this.state.uploadStatus) {
      return { width: `${Math.round(this.getStatusPercent())}%` };
    }
    return {};
  }

  getIconStyle() {
    if(this.STATUS_COMPLETE === this.state.uploadStatus) {
      return { left: `calc(100% - ${this.getIconWidth()}px)` };
    } else if(this.STATUS_UPLOADING === this.state.uploadStatus || this.STATUS_INCOMPLETE === this.state.uploadStatus) {

      const barWidth = this.getBarWidth();
      const iconWidth = this.getIconWidth();
      const statusPercent = this.getStatusPercent();

      if( (barWidth * statusPercent/100) <= iconWidth/2) {
        return { left: '0' };
      }
      if( ( barWidth * (1 - statusPercent/100)) <= iconWidth/2) {
        return { left: `calc(100% - ${iconWidth}px)` };
      }
      return { left: `calc(${statusPercent}% - ${iconWidth/2}px)` };
    }
    return {};
  }

  toggleStatusMenu() {
    this.setState({
      statusMenuOpen: !this.state.statusMenuOpen,
    });
  }

  async fetchDownloadSlug(downloadType, pageNum) {

    const downloadData = {
      download_type: downloadType,
      [API_KEY_LIMIT]: getPageLimit(pageNum, this.DOWNLOAD_LIMIT),
      [API_KEY_OFFSET]: getPageOffset(pageNum, this.DOWNLOAD_LIMIT),
    };

    const downloadResp = await this.props.productBulkUploadDownloadErrors(downloadData, this.props.requestUpload.publicUuid)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({
          downloadPending: false,
        });
      });

    if(!downloadResp) { return null; }

    return downloadResp || null;
  }

  async initiateDownload(downloadType) {

    if(!downloadType) { return null; }

    this.toggleDownloadModal();

    this.setState({
      downloadData: [],
      downloadPending: true,
      downloadType: downloadType,
      statusMenuOpen: false,
    });

    const lines = [];
    const initData = await this.fetchDownloadSlug(downloadType, 1);

    if(!initData) { return null; }

    for(const ln of initData.data) {
      lines.push(ln.lineData);
    }

    if(initData.count > initData.data.length) {

      for(let i = 2; i <= Math.ceil(initData.count / initData.data.length); i++) {
        const newData = await this.fetchDownloadSlug(downloadType, i);

        if(!newData) { break; }
        for(const ln of newData.data) {
          lines.push(ln.lineData);
        }
      }
    }

    this.setState({
      downloadData: lines,
      downloadPending: false,
    });
  }

  toggleDownloadModal() {
    this.setState({ 
      downloadModalOpen: !this.state.downloadModalOpen,
    });
  }

  downloadFile() {
    if(this.state.downloadPending || !this.state.downloadData) { return null; }

    const csvLines = [];
    for(const dataLine of this.state.downloadData) {
      csvLines.push(dataLine);
    }

    const now = Date.now();
    const template = Papa.unparse(csvLines);
    const templateData = new Blob([ template ], { type: 'text/csv;charset=utf-8;' });
    const templateURL = navigator.msSaveBlob ? navigator.msSaveBlob(templateData, `data-${this.state.downloadType}-${now}.csv`) : window.URL.createObjectURL(templateData);

    let tempLink = document.createElement('a');
    tempLink.href = templateURL;
    tempLink.setAttribute('download', `data-${this.state.downloadType}-${now}.csv`);
    tempLink.click();
  }

  toggleDebug() {
    this.toggleStatusMenu();
    this.props.toggleDebugMode();
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkUploadProcessing'}>
      <div className='bupLiner'>
        <div className={`statusLabel ${this.state.uploadStatus}`}>
          <div className='statusValue'>{t(this.getLabel())}</div>
          {this.STATUS_UPLOADING === this.state.uploadStatus ?
            <div className='statusPercent'>{Math.round(this.getStatusPercent())}%</div> :
            null
          }
          <div className='statusMenuIconWrapper' onClick={this.toggleStatusMenu.bind(this)} ref={this.statusMenuIcon}>
            <Icon value={ICON_MENU_DOTS} iconClass={'statusMenuIcon'} />
          </div>
          <div className={`statusMenuWrapper ${this.state.statusMenuOpen ? 'open' : ''}`} ref={this.statusMenuBody}>
            <div className='statusMenu'>
              <div className='statusMenuOption' onClick={() => this.initiateDownload(this.DOWNLOAD_ERRORS)}>{t(tx.TX_INV_BULK_UPLOAD_DOWNLOAD_ERRORS)}</div>
              <div className='statusMenuOption' onClick={() => this.initiateDownload(this.DOWNLOAD_SKIPPED)}>{t(tx.TX_INV_BULK_UPLOAD_DOWNLOAD_SKIPPED)}</div>
              <div className='statusMenuOption' onClick={this.toggleDebug.bind(this)}>
                {this.props.debugMode ? t(tx.TX_INV_BULK_UPLOAD_SUPPLEMENTAL_HIDE) : t(tx.TX_INV_BULK_UPLOAD_SUPPLEMENTAL_SHOW)}
              </div>
            </div>
          </div>
        </div>
        <div className='statusBarWrapper' ref={this.barWrapper}>
          <div className='sbBody'>
            <div className='sbBodyFill' style={this.getFillBarStyle()}></div>
          </div>
          <div className={`sbIconWrapper ${this.state.uploadStatus}`} ref={this.iconWrapper} style={this.getIconStyle()}>
            {this.STATUS_UPLOADING === this.state.uploadStatus ?
              <LoadingIcon /> :
              <Icon value={ICON_PLAY} />
            }
          </div>
        </div>
      </div>
      <BulkUploadDownloadResultsModal
        open={this.state.downloadModalOpen}
        closeMethod={this.toggleDownloadModal.bind(this)}
        downloadPending={this.state.downloadPending}
        onConfirm={this.downloadFile.bind(this)}
        onCancel={this.toggleDownloadModal.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkUploadProcessing));