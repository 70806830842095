import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  PM_VALUE_KEY_EXTERNAL_GENERAL,
  PM_VALUE_KEY_PAY_AT_PICKUP,
} from '../../../constants/payment-methods';
import * as tx from '../../../constants/strings';

import { formatPrice } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';
import { dynamicSort } from '../../../utils/sort';

let allActionCreators = Object.assign({});

export class OrderHistory extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getShipmentEvents() {
    if(!this.props.order || !this.props.order.shipments) { return []; }

    const eventLabels = [];
    for(const sh of this.props.order.shipments) {

      eventLabels.push({
        date: sh.date,
        display: tx.TX_ORDER_HISTORY_SHIPMENT,
      });

      if(sh.cancelDate) {
        eventLabels.push({
          date: sh.cancelDate,
          display: tx.TX_ORDER_HISTORY_SHIPMENT_CANCELLED,
        });
      }
    }
    return eventLabels;
  }

  getPickupEvents() {
    if(!this.props.order || !this.props.order.pickups) { return []; }

    const eventLabels = [];
    for(const pu of this.props.order.pickups) {
      
      eventLabels.push({
        date: pu.initDate,
        display: tx.TX_ORDER_HISTORY_PICKUP_CREATED,
      });

      if(pu.completeDate) {
        eventLabels.push({
          date: pu.completeDate,
          display: tx.TX_ORDER_HISTORY_PICKUP_CLOSED,
        });
      }
    }
    return eventLabels;
  }

  getPaymentEvents() {
    if(!this.props.order || !this.props.order.paymentMethod) { return []; }

    const asyncPaymentMethodKeys = [
      PM_VALUE_KEY_EXTERNAL_GENERAL,
      PM_VALUE_KEY_PAY_AT_PICKUP,
    ];

    const eventLabels = [];
    if(asyncPaymentMethodKeys.includes(this.props.order.paymentMethod.key)) {

      if(this.props.order.paymentRecord) {
        eventLabels.push({
          date: this.props.order.paymentRecord.date,
          display: tx.TX_ORDER_HISTORY_PAYMENT_RECEIVED,
        });
      }        
    }

    return eventLabels;
  }

  getRefundEvents() {
    if(!this.props.order || !this.props.order.refunds) { return []; }

    const eventLabels = [];
    for(const rf of this.props.order.refunds) {
      eventLabels.push({
        date: rf.date,
        display: tx.TX_ORDER_HISTORY_REFUND_ISSUED_AMOUNT,
        displayData: {
          amount: formatPrice(rf.total, { language: this.getLanguage() }),
        }
      });
    }
    return eventLabels;
  }

  getReceiptOfGoodsEvents() {
    if(!this.props.order || !this.props.order.goodsReceipts) { return []; }

    const eventLabels = [];
    for(const gr of this.props.order.goodsReceipts) {
      eventLabels.push({
        date: gr.date,
        display: tx.TX_ORDER_ITEMS_RECEIVED,
      });
    }
    return eventLabels;
  }

  getGradingEvents() {
    if(!this.props.order || !this.props.order.goodsReceipts) { return []; }

    const eventLabels = [];
    for(const gr of this.props.order.gradings) {
      if(gr.completeDate) {
        eventLabels.push({
          date: gr.completeDate,
          display: tx.TX_ORDER_HISTORY_GRADED,
        });
      }
    }
    return eventLabels;
  }

  getSortedEvents() {

    const shipments = this.getShipmentEvents();
    const pickups = this.getPickupEvents();
    const payments = this.getPaymentEvents();
    const refunds = this.getRefundEvents();
    const goodsReceipts = this.getReceiptOfGoodsEvents();
    const gradings = this.getGradingEvents();

    return [ ...shipments, ...pickups, ...payments, ...refunds, ...goodsReceipts, ...gradings ].sort(dynamicSort('date'));
  }

  openPaymentModal() {
    if(this.props.openCreatePaymentModal) {
      this.props.openCreatePaymentModal();
    }
  }

  openShipmentModal() {
    if(this.props.openCreateShipmentModal) {
      this.props.openCreateShipmentModal();
    }
  }

  render() {

    const {t} = this.props;

    if(!this.props.order) { return null; }

    return <div className={'OrderHistory'}>
      <div className='odvHistoryTable'>
        <div className='odvHistoryRow'>
          <div className='odvHistoryRowKey'>{t(tx.TX_ORDER_HISTORY_CREATED)}</div>
          <div className='odvHistoryRowValue'>
            <div className='odvDateWrapper'>
              {this.props.order.date.toLocaleString(this.getLanguage(), {
                dateStyle: 'short',
              })}
            </div>
            <div className='odvTimeWrapper'>
              {this.props.order.date.toLocaleString(this.getLanguage(), {
                hour: 'numeric', 
                minute: '2-digit',
              })}
            </div>
          </div>
        </div>
          
        {this.getSortedEvents().map((evtLabel, i) => {
          return <div className='odvHistoryRow' key={i}>
            <div className='odvHistoryRowKey'>{evtLabel.displayData ? t(evtLabel.display, evtLabel.displayData) : t(evtLabel.display)}</div>
            <div className='odvHistoryRowValue'>
              <div className='odvDateWrapper'>
                {evtLabel.date.toLocaleString(this.getLanguage(), {
                  dateStyle: 'short',
                })}
              </div>
              <div className='odvTimeWrapper'>
                {evtLabel.date.toLocaleString(this.getLanguage(), {
                  hour: 'numeric', 
                  minute: '2-digit',
                })}
              </div>
            </div>
          </div>
        })}

        {this.props.order.requiresPayment ?
          <div className='odvHistoryLinkWrapper'>
            <div className='odvHistoryLink' onClick={this.openPaymentModal.bind(this)}>{`+ ${t(tx.TX_PAYMENT)}`}</div>
          </div> :
          null
        }

        {this.props.order.readyForShipment ?
          <div className='odvHistoryLinkWrapper'>
            <div className='odvHistoryLink' onClick={this.openShipmentModal.bind(this)}>{`+ ${t(tx.TX_SHIPMENT)}`}</div>
          </div> :
          null
        }
          
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderHistory));