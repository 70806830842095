import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import HeaderHelloUser from './HeaderHelloUser';

import * as headerActionCreators from '../../../actions/header';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, headerActionCreators, userActionCreators);

export class HeaderAccountLoggedIn extends Component {

  render() {
    return <div className='HeaderAccountLoggedIn' onClick={this.props.headerToggleUserMenu.bind(this)}>
      <div className='humWrapper'>
        <div className='humBody'>
          <HeaderHelloUser />
        </div>
        <div className='humArrow'>
          <div className='arrowWrapper'></div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderAccountLoggedIn));


