import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { Product } from '../../../../models/products';

import { getProductInventoryLabel } from '../../../../utils/product';

import LoadingIcon from '../../../Icons/LoadingIcon';
import ProductFieldInput from './inputs/ProductFieldInput';
import ProductFieldQuantity from './inputs/ProductFieldQuantity';
import ProductFieldSwitch from './inputs/ProductFieldSwitch';

import * as productActionCreators from '../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class ProductDetailRowInventoryRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rowLoading: false,

      signalIndex: null,
      signalValue: null,
    }

    this.maxFieldIndex = 6;

    this.resetTimeout = null;

    this.controller = null;
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
    if(this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
  }

  async refreshRow(val) {
    
    const getData = this.props.inventory.getFetchApiData(this.props.product);
    if(!getData) {
      return null;
    }

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    const updateResp = await this.props.productFetchInventorySingle(getData, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        console.error(errResp);
      })

    if(!updateResp) {
      return null;
    }

    this.setState({
      rowLoading: false,
    }, () => {

      const newProduct = new Product(this.props.product);
      newProduct.updateInventory(updateResp);

      this.props.hotSwap(newProduct, 'permalink', newProduct.permalink);

    });
  }

  setLoading(val) {
    if(this.state.rowLoading !== val) {
      this.setState({ rowLoading: val });
    }
  }

  getTabIndex(fieldIdx) {
    const maxRows = 25;
    return this.props.tabStart + (fieldIdx * maxRows * this.props.pageSize) + (this.props.idx * maxRows) + this.props.rowIndex;
  }

  setHorizontalNav(idx) {
    if(idx >= 0 && idx <= this.maxFieldIndex) {
      this.setState({
        signalIndex: idx,
        signalValue: Date.now(),
      }, () => {
        this.resetTimeout = setTimeout(() => {
          this.setState({
            signalIndex: null,
            signalValue: null,
          });
        }, 100);
      });
    }
  }

  setTabFocus(idx) {
    if(this.state.signalValue && idx === this.state.signalIndex) {
      return this.state.signalValue;
    }
    return null;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductDetailRowInventoryRow invRow'}>
      <div className='rowLiner'>
        <div className='nameElement'>
          <div className={`nameLoading ${this.state.rowLoading ? 'loading' : ''}`}>
            <LoadingIcon />
          </div>
          <div className={`nameLiner ${this.state.rowLoading ? 'loading' : ''}`}>{getProductInventoryLabel(this.props.product, this.props.inventory, { abbreviateCondition: true, showAllLabels: true, translate: t })}</div>
        </div>
        <div className='controlElement'>        
          <div className='controlLiner'>
            <div className={'quantityElement controlElement'}>
              <ProductFieldQuantity
                product={this.props.product}
                inventory={this.props.inventory}
                elemKey={'quantity'}
                elemValue={this.props.inventory.totalQuantity}
                fieldIndex={0}
                tabIndex={this.getTabIndex(0)}
                indexNav={this.setHorizontalNav.bind(this)}
                focusSignal={this.setTabFocus(0)} />
            </div>
            <div className={'manualElement controlElement'}>
              {this.props.product.productLine.automatedPricing ?
                <ProductFieldSwitch
                  product={this.props.product}
                  inventory={this.props.inventory}
                  elemKey={'automated_pricing'}
                  elemValue={this.props.inventory.automatedPricing}
                  inverted={true}
                  refreshRow={this.refreshRow.bind(this)}
                  setLoading={this.setLoading.bind(this)} /> :
                null
              }
            </div>
            <div className={'inputElement controlElement'}>
              <ProductFieldInput
                product={this.props.product}
                inventory={this.props.inventory}
                currency={true}
                elemKey={'sell_price'}
                elemValue={this.props.inventory.sellPrice}
                readOnly={this.props.inventory.automatedPricing}
                fieldIndex={1}
                tabIndex={this.getTabIndex(1)}
                indexNav={this.setHorizontalNav.bind(this)}
                focusSignal={this.setTabFocus(1)}
                refreshData={this.props.refreshData} />
            </div>
            <div className={'inputElement controlElement'}>
              <ProductFieldInput
                product={this.props.product}
                inventory={this.props.inventory}
                currency={true}
                elemKey={'buy_price'}
                elemValue={this.props.inventory.buyPrice}
                allowEmpty={false}
                readOnly={this.props.inventory.automatedPricing}
                fieldIndex={2}
                tabIndex={this.getTabIndex(2)}
                indexNav={this.setHorizontalNav.bind(this)}
                focusSignal={this.setTabFocus(2)}
                refreshData={this.props.refreshData} />
            </div>
            <div className={'inputElement controlElement second'}>
              <ProductFieldInput
                product={this.props.product}
                inventory={this.props.inventory}
                elemKey={'reserve_quantity_ebay'}
                elemValue={this.props.inventory.reserveEbay}
                allowEmpty={true}
                readOnly={this.props.inventory.automatedPricing}
                fieldIndex={3}
                tabIndex={this.getTabIndex(3)}
                indexNav={this.setHorizontalNav.bind(this)}
                focusSignal={this.setTabFocus(3)} />
            </div>
            <div className={'inputElement controlElement second'}>
              <ProductFieldInput
                product={this.props.product}
                inventory={this.props.inventory}
                elemKey={'reserve_quantity_amazon'}
                elemValue={this.props.inventory.reserveAmazon}
                allowEmpty={true}
                readOnly={this.props.inventory.automatedPricing}
                fieldIndex={4}
                tabIndex={this.getTabIndex(4)}
                indexNav={this.setHorizontalNav.bind(this)}
                focusSignal={this.setTabFocus(4)} />
            </div>
            <div className={'inputElement controlElement third'}>
              <ProductFieldInput
                product={this.props.product}
                inventory={this.props.inventory}
                elemKey={'min_quantity'}
                elemValue={this.props.inventory.targetMin}
                allowEmpty={true}
                readOnly={this.props.inventory.automatedPricing}
                fieldIndex={5}
                tabIndex={this.getTabIndex(5)}
                indexNav={this.setHorizontalNav.bind(this)}
                focusSignal={this.setTabFocus(5)} />
            </div>
            <div className={'inputElement controlElement third'}>
              <ProductFieldInput
                product={this.props.product}
                inventory={this.props.inventory}
                elemKey={'max_quantity'}
                elemValue={this.props.inventory.targetMax}
                allowEmpty={true}
                readOnly={this.props.inventory.automatedPricing}
                fieldIndex={6}
                tabIndex={this.getTabIndex(6)}
                indexNav={this.setHorizontalNav.bind(this)}
                focusSignal={this.setTabFocus(6)} />
            </div>
          </div>
        </div>
        <div className='actionElement'>

        </div>
        {this.state.rowLoading ?
          <div className='loadingWrapper'>
            <div className='loadingIcon'>
              <LoadingIcon />
            </div>
            <div className='loadingValue'>{t(tx.TX_INV_UPDATING_INVENTORY)}</div>
          </div> :
          null
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailRowInventoryRow));