import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { getStoreLanguage } from '../../../utils/language';

import CartSummary from '../../Cart/CartSummary';
import LoadingIcon from '../../Icons/LoadingIcon';
import MiniCart from '../../Cart/MiniCart';
import OrderHistory from './OrderHistory';

let allActionCreators = Object.assign({});

export class OrderDetailsView extends Component {

  constructor(props) {
    super(props);

    this.ACTION_KEY_BUYLIST_PAYMENT = 'buylist-payment';
    this.ACTION_KEY_CANCEL = 'cancel';
    this.ACTION_KEY_REFUND = 'refund';
    this.ACTION_KEY_MESSAGE = 'message';
    this.ACTION_KEY_PAYMENT = 'payment';
    this.ACTION_KEY_PRINT_INVOICE = 'print-invoice';
    this.ACTION_KEY_RECEIVE_GOODS = 'receive-goods';
    this.ACTION_KEY_GRADE_ITEMS = 'grade-items';
    this.ACTION_KEY_SHIPMENT = 'shipment';

    this.state = {};
  }

  getTitle() {
    if(this.props.order && this.props.order.displayNumber) {
      return this.props.t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: this.props.order.displayNumber });
    }
    return this.props.t(tx.TX_ORDER_ORDER_DETAILS);
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  shouldShowShippingAddress() {
    if(!this.props.order || !this.props.order.hasShippingAddress()) { return false; }

    if(this.props.order.hasShippingMethod() && this.props.order.shippingMethod.isPickup) {
      return false;
    }
    return true;
  }

  getOrderActions() {
    const dropdownOptions = [
      {
        display: tx.TX_ACTIONS,
        value: '',
      },
      {
        display: tx.TX_ORDER_PRINT_INVOICE,
        value: this.ACTION_KEY_PRINT_INVOICE,
      },
      {
        display: tx.TX_ORDER_SEND_MESSAGE,
        value: this.ACTION_KEY_MESSAGE,
      },
    ];

    if(this.props.order && this.props.order.paymentRecord && !this.props.order.isBuylist) {
      dropdownOptions.push({
        display: tx.TX_ORDER_REFUNDS_AND_RETURNS,
        value: this.ACTION_KEY_REFUND,
      });
    }

    if(this.props.order && this.props.order.requiresPayment && !this.props.order.isComplete) {
      dropdownOptions.push({
        display: tx.TX_ORDER_RECORD_PAYMENT,
        value: this.ACTION_KEY_PAYMENT,
      });
    }

    if(this.props.order && this.props.order.canMakeBuylistPayment && !this.props.order.isComplete) {
      dropdownOptions.push({
        display: tx.TX_MAKE_PAYMENT,
        value: this.ACTION_KEY_BUYLIST_PAYMENT,
      });
    }    

    if(this.props.order && this.props.order.readyForShipment && !this.props.order.isComplete) {
      dropdownOptions.push({
        display: tx.TX_ORDER_CREATE_SHIPMENT,
        value: this.ACTION_KEY_SHIPMENT,
      });
    }

    if(this.props.order && this.props.order.waitingForItems && !this.props.order.isComplete) {
      dropdownOptions.push({
        display: tx.TX_ORDER_ITEMS_RECEIVED,
        value: this.ACTION_KEY_RECEIVE_GOODS,
      });
    }

    if(this.props.order && this.props.order.requiresGrading && !this.props.order.isComplete) {
      dropdownOptions.push({
        display: tx.TX_ORDER_GRADE_ITEMS,
        value: this.ACTION_KEY_GRADE_ITEMS,
      });
    }

    if(this.props.order && this.props.order.canCancel && !this.props.order.isComplete) {
      dropdownOptions.push({
        display: tx.TX_ORDER_CANCEL_ORDER_PROMPT,
        value: this.ACTION_KEY_CANCEL,
      });
    }

    return dropdownOptions;
  }

  triggerOrderAction(evt) {
    const actionKey = evt.target.value;

    switch(actionKey) {
      case this.ACTION_KEY_CANCEL:
        this.props.openCancelOrderModal();
        break;
      case this.ACTION_KEY_REFUND:
        this.props.openRefundModal();
        break;
      case this.ACTION_KEY_MESSAGE:
        this.props.openMessagesModal();
        break;
      case this.ACTION_KEY_PAYMENT:
        this.props.openCreatePaymentModal();
        break;
      case this.ACTION_KEY_BUYLIST_PAYMENT:
        this.props.openCreateBuylistPaymentModal();
        break;
      case this.ACTION_KEY_RECEIVE_GOODS:
        this.props.openGoodsReceivedModal();
        break;
      case this.ACTION_KEY_SHIPMENT:
        this.props.openCreateShipmentModal();
        break;
      case this.ACTION_KEY_GRADE_ITEMS:
        this.props.openGradeItemsModal();
        break;
      case this.ACTION_KEY_PRINT_INVOICE:
        this.printInvoice();
        break;
      default:
        return null;
    }
  }

  async printInvoice() {

    if(this.props.order) {

      const invoice = await this.props.order.generateInvoice(this.props.t);

      // Prompt the user to print the PDF
      invoice.autoPrint();

      // Open the print dialog
      window.open(invoice.output('bloburl'), '_blank');
    }
  }

  render() {

    const {t} = this.props;
    const itemCount = this.props.order && this.props.order.orderCart ? this.props.order.orderCart.quantity : 0;

    return <div className={'OrderDetailsView adminSection'}>
    	<div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{this.getTitle()}</div>
        <div className='asTitleActionsWrapper'>
          <div className='asTitleActions'>
            <select 
              className='asTitleActionElement'
              value={''} 
              disabled={this.props.orderPending}
              onChange={this.triggerOrderAction.bind(this)}>
              {this.getOrderActions().map((actionObj, i) => {
                return <option key={i} value={actionObj.value}>{t(actionObj.display)}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper odvWrapper'>
          
          	<div className='odvColumn'>
          		<div className='odvColumnWrapper'>
          			<div className='odvColTitle'>
                  <div className='odvTitle'>{t(tx.TX_DETAILS)}</div>
                </div>
          			<div className='odvColBody'>
                  {this.props.order ?
            				<div className='odvDetailTable'>
            					<div className='odvDetailRow'>
            						<div className='odvDetailRowKey'>{t(tx.TX_DATE)}</div>
            						<div className='odvDetailRowValue'>
            							<div className='odvDateWrapper'>
            								{this.props.order.date.toLocaleString(this.getLanguage(), {
            									dateStyle: 'long',
            								})}
            							</div>
            							<div className='odvTimeWrapper'>
            								{this.props.order.date.toLocaleString(this.getLanguage(), {
            									timeStyle: 'long',
            								})}
            							</div>
            							
            						</div>
            					</div>
            					<div className='odvDetailRow'>
            						<div className='odvDetailRowKey'>{t(tx.TX_NUMBER)}</div>
            						<div className='odvDetailRowValue'>{this.props.order.displayNumber}</div>
            					</div>
            					<div className='odvDetailRow'>
            						<div className='odvDetailRowKey'>{t(tx.TX_NAME)}</div>
            						<div className='odvDetailRowValue'>{this.props.order.user.fullName(this.getLanguage())}</div>
            					</div>
            					<div className='odvDetailRow'>
            						<div className='odvDetailRowKey'>{t(tx.TX_USER_TYPE)}</div>
            						<div className='odvDetailRowValue'>{this.props.order.user.isGuest() ? t(tx.TX_USER_TYPE_GUEST) : t(tx.TX_USER_TYPE_REGISTERED)}</div>
            					</div>
            					<div className='odvDetailRow'>
            						<div className='odvDetailRowKey'>{t(tx.TX_SHIPPING_METHOD)}</div>
            						<div className='odvDetailRowValue'>{this.props.order.shippingMethod.name}</div>
            					</div>
            					<div className='odvDetailRow'>
            						<div className='odvDetailRowKey'>{t(tx.TX_PAYMENT_METHOD)}</div>
            						<div className='odvDetailRowValue'>{this.props.order.paymentMethod ? this.props.order.paymentMethod.getDisplayName(t) : ''}</div>
            					</div>
            					<div className='odvDetailRow'>
            						<div className='odvDetailRowKey'>{t(tx.TX_STATUS)}</div>
            						<div className='odvDetailRowValue'>
            							{this.props.order.status ? t(this.props.order.status.name) : ''}
            						</div>
            					</div>
            				</div> :
                    null
                  }
          			</div>
          		</div>
              {this.shouldShowShippingAddress() ?
                <>
                  <div className={'odvColumnVr equal'} />
                  <div className='odvColumnWrapper'>
                    <div className='odvColTitle'>
                      <div className='odvTitle'>{t(tx.TX_SHIPPING_ADDRESS)}</div>
                    </div>
                    <div className='odvColBody'>
                      <div className='odvAddress'>
                        {this.props.order.shippingAddress.format().map((ln, i) => {
                          return <div key={i} className='odvAddressLine'>{ln}</div>;
                        })}
                      </div>
                    </div>
                  </div>
                </> :
                null
              }
          	</div>
            <div className={'odvColumn cartColumn'}>
              <div className='odvColumnWrapper'>
                <div className='odvColTitle'>
                  <div className='odvTitle'>{t(tx.TX_ORDER)}</div>
                  <div className='odvTitleAddition'>({itemCount === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: itemCount })})</div>
                </div>
                <div className='odvColBody'>
                  <div className='odvCartWrapper'>
                    {this.props.order && !this.props.orderPending ?
                      <MiniCart
                        isBuylist={this.props.order && this.props.order.isBuylist}
                        order={this.props.order}
                        cartOverride={this.props.order ? this.props.order.orderCart : null}
                        readOnly={true} /> :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={'odvColumn totalColumn'}>
              <div className='odvColumnWrapper'>
                <div className='odvColTitle'>
                  <div className='odvTitle'>{t(tx.TX_ORDER_TOTALS)}</div>
                </div>
                <div className='odvColBody'>
                  <CartSummary
                    className={'odvCartSummary'}
                    isBuylist={this.props.order && this.props.order.isBuylist}
                    order={this.props.order}
                    cartOverride={this.props.order ? this.props.order.orderCart : null}
                    readOnly={true} />
                </div>
              </div>
              <div className='odvColumnVr' />
              <div className='odvColumnWrapper historyColumn'>
                <div className='odvColTitle'>
                  <div className='odvTitle'>{t(tx.TX_HISTORY)}</div>
                </div>
                <div className='odvColBody'>
                  <OrderHistory
                    order={this.props.order}
                    openCreatePaymentModal={this.props.openCreatePaymentModal}
                    openCreateShipmentModal={this.props.openCreateShipmentModal} />
                </div>
              </div>
            </div>

          	<div className={'odvR v1'} />
          	<div className={'odvR v2'} />
          </div>
          <div className={'odvWrapper odvFooter'}>
            <div className='odvColumnWrapper'>
              <div className='odvColTitle'>
                <div className='odvTitle'>{t(tx.TX_ORDER)}</div>
                <div className='odvTitleAddition'>({itemCount === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: itemCount })})</div>
              </div>
              <div className='odvColBody'>
                <div className='odvCartWrapper'>
                  <MiniCart
                    isBuylist={this.props.order && this.props.order.isBuylist}
                    order={this.props.order}
                    cartOverride={this.props.order ? this.props.order.orderCart : null}
                    readOnly={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.orderPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderDetailsView));