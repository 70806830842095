import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { CONDITIONS_PRODUCT_GENERIC } from '../../../constants/conditions';
import { ERROR_CONDITION_MISSING, ERROR_LANGUAGE_MISSING } from '../../../constants/errors';
import { LANG_PRODUCT_GENERIC } from '../../../constants/languages';
import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../../constants/urls';

import { Inventory } from '../../../models/products';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../utils/currency';
import { 
  isFormValid, 
  getPriceError, 
  getQuantityError, 
} from '../../../utils/form-validation';
import {
  normalizeQuantity,
  normalizePrice,
} from '../../../utils/formatting';

import Toggle from '../../Input/Toggle';

let allActionCreators = Object.assign({});

export class AddProductPricingGeneric extends Component {

  constructor(props) {
    super(props);

    this.initState = {

      inputSealed: this.props.inventoryObj ? this.props.inventoryObj.isSealed : true,
      inputCondition: this.props.inventoryObj ? this.props.inventoryObj.condition : '',
      inputPrice: this.props.inventoryObj ? this.props.inventoryObj.sellPrice : '',
      inputBuyPrice: this.props.inventoryObj && this.props.inventoryObj.buyPrice ? this.props.inventoryObj.buyPrice : '',
      inputBuylist: this.props.inventoryObj ? this.props.inventoryObj.buylist : false,
      inputQuantity: this.props.inventoryObj ? this.props.inventoryObj.totalQuantity : '',
      inputQuantityMin: this.props.inventoryObj ? this.props.inventoryObj.targetMin : '',
      inputQuantityMax: this.props.inventoryObj ? this.props.inventoryObj.targetMax : '',
      inputLanguage: this.props.inventoryObj ? this.props.inventoryObj.language : this.getDefaultLanguage(),

      errorCondition: '',
      errorPrice: '',
      errorBuyPrice: '', 
      errorQuantity: '',
      errorQuantityMin: '',
      errorQuantityMax: '',
      errorLanguage: '',
    }

    this.state = this.initState;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.validationSignal !== this.props.validationSignal) {
      this.validateAll();
    }
  }

  getDefaultLanguage() {
    for(const lang of LANG_PRODUCT_GENERIC) {
      if(lang.code === process.env.REACT_APP_DEFAULT_LANGUAGE) {
        return lang;
      }
    }
    return '';
  }

  moveToNext(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {
      this.props.moveNext();
    }
  }

  validateAll() {
    const errorObj = {
      errorCondition: this.getConditionError(),
      errorPrice: getPriceError(this.state.inputPrice),
      errorBuyPrice: this.state.inputBuylist ? getPriceError(this.state.inputBuyPrice) : '', 
      errorQuantity: getQuantityError(this.state.inputQuantity),
      errorQuantityMin: getQuantityError(this.state.inputQuantityMin, true),
      errorQuantityMax: getQuantityError(this.state.inputQuantityMax, true),
      errorLanguage: this.getLanguageError(),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeSealed(evt) {
    this.setState({
      inputSealed: !this.state.inputSealed, 
    }, () => {
      if(!this.state.inputSealed) {
        this.setState({ 
          inputCondition: '',
          errorCondition: '',
        }, () => {
          this.syncInventory();
        });
      } else {
        this.syncInventory();
      }

    });
    this.props.makeDirty();
  }

  changePrice(evt) {
    this.setState({
      inputPrice: evt.target.value,
    }, () => {
      if(this.state.errorPrice) {
        this.validatePrice(false);
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validatePrice(shouldNormalize = true) {
    this.setState({ 
      inputPrice: shouldNormalize ? normalizePrice(this.state.inputPrice) : this.state.inputPrice, 
      errorPrice: getPriceError(this.state.inputPrice), 
    }, () => {
      this.syncInventory();
    });
  }

  changeBuyPrice(evt) {
    this.setState({
      inputBuyPrice: evt.target.value,
    }, () => {
      if(this.state.errorBuyPrice) {
        this.validateBuyPrice(false);
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateBuyPrice(shouldNormalize = true) {
    this.setState({ 
      inputBuyPrice: shouldNormalize? normalizePrice(this.state.inputBuyPrice) : this.state.inputBuyPrice, 
      errorBuyPrice: getPriceError(this.state.inputBuyPrice), 
    }, () => {
      this.syncInventory();
    });
  }

  changeQuantity(evt) {
    this.setState({
      inputQuantity: evt.target.value,
    }, () => {
      if(this.state.errorQuantity) {
        this.validateQuantity();
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateQuantity() {
    this.setState({ 
      inputQuantity: normalizeQuantity(this.state.inputQuantity),
      errorQuantity: getQuantityError(normalizeQuantity(this.state.inputQuantity)),
    }, () => {
      this.syncInventory();
    });
  }

  changeQuantityMin(evt) {
    this.setState({
      inputQuantityMin: evt.target.value,
    }, () => {
      if(this.state.errorQuantityMin) {
        this.validateQuantityMin();
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateQuantityMin() {
    this.setState({ 
      inputQuantityMin: normalizeQuantity(this.state.inputQuantityMin),
      errorQuantityMin: getQuantityError(this.state.inputQuantityMin, true),
    });
  }

  changeQuantityMax(evt) {
    this.setState({
      inputQuantityMax: evt.target.value,
    }, () => {
      if(this.state.errorQuantityMax) {
        this.validateQuantityMax();
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateQuantityMax() {
    this.setState({ 
      inputQuantityMax: normalizeQuantity(this.state.inputQuantityMax),
      errorQuantityMax: getQuantityError(this.state.inputQuantityMax, true),
    });
  }

  syncInventory() {
    const inventoryObj = new Inventory({
      isSealed: this.state.inputSealed,
      condition: this.state.inputCondition,
      isBuylist: this.state.inputBuylist, 
      sellPrice: this.state.inputPrice,
      totalQuantity: this.state.inputQuantity,
      targetMin: this.state.inputQuantityMin,
      targetMax: this.state.inputQuantityMax,
      language: this.state.inputLanguage,

      foreignModelCode: null,
      foreignModel: null,
    });

    if(this.state.inputBuylist) {
      inventoryObj.buyPrice = this.state.inputBuyPrice;
    }

    this.props.setInventory(inventoryObj);
  }

  changeCondition(evt) {

    if(evt.target.value === '') {
      this.setState({ inputCondition: evt.target.value }, () => {
        this.syncInventory();
        this.validateCondition();
      });
      return;
    }

    for(const co of CONDITIONS_PRODUCT_GENERIC) {
      if(co.key === evt.target.value) {
        this.setState({ inputCondition: co }, () => {
          this.syncInventory();
          this.validateCondition();
        });
        break;
      }
    }
  }

  getConditionError() {
    if(this.state.inputSealed === false) {
      if(!this.state.inputCondition) {
        return ERROR_CONDITION_MISSING;
      }
    }
    return '';
  }

  validateCondition() {
    this.setState({ errorCondition: this.getConditionError() });
  }

  changeLanguage(evt) {

    if(evt.target.value === '') {
      this.setState({ inputLanguage: evt.target.value }, () => {
        this.syncInventory();
        this.validateLanguage();
      });
      return;
    }

    for(const ln of LANG_PRODUCT_GENERIC) {
      if(ln.code === evt.target.value) {
        this.setState({ inputLanguage: ln }, () => {
          this.syncInventory();
          this.validateLanguage();
        });
        break;
      }
    }
  }

  getLanguageError() {
    if(!this.state.inputLanguage) {
      return ERROR_LANGUAGE_MISSING;
    }
    return '';
  }

  changeBuylist(evt) {
    this.setState({
      inputBuylist: !this.state.inputBuylist, 
    }, () => {
      if(!this.state.inputBuylist) {
        this.setState({ 
          inputBuyPrice: '', 
          errorBuyPrice: '', 
        }, () => {
          this.syncInventory();
        });
      } else {
        this.syncInventory();
      }
    });
    this.props.makeDirty();
  }

  validateLanguage() {
    this.setState({ errorLanguage: this.getLanguageError() });
  }

  getConditionLabel(conditionObj) {
    if(!conditionObj) { return ''; }
    const abrv = this.props.t(conditionObj.short);
    const cName = this.props.t(conditionObj.name);
    return abrv ? `${cName} (${abrv})` : cName;
  }

  buylistAllowed() {
    return this.props.productLine ? this.props.productLine.hasBuylist : false;
  }

  render() {

    const {t} = this.props;

    return <div className={'AddProductPricingGeneric'}>
      <form onSubmit={this.moveToNext.bind(this)}>
        <div className='ap2Pricing'>

          <div className='adminFieldWrapper'>
            <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_SEALED_QUESTION)}</div>
            <div className='adminInputWrapper'>
              <div className='adminInputToggleWrapper'>
                <Toggle
                  checked={this.state.inputSealed}
                  onToggle={this.changeSealed.bind(this)}
                  trueValue={tx.TX_INV_ADD_PRODUCT_FACTORY_SEALED}
                  falseValue={tx.TX_INV_ADD_PRODUCT_SINGLE} />
              </div>
            </div>
          </div>

          <div className={this.state.inputSealed ? 'adminCollapseable hidden' : 'adminCollapseable'}>
            <div className={'adminFieldWrapper'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_CONDITION)}</div>
              <div className={'adminInputWrapper'}>
                <div className='adminDropdown'>
                  <select
                    value={this.state.inputCondition ? this.state.inputCondition.key : ''}
                    onChange={this.changeCondition.bind(this)}
                    className={this.state.errorCondition ? 'InputError' : ''}>
                    <option value=''>{t(tx.TX_PLACEHOLDER_PRODUCT_CONDITION)}</option>
                    {CONDITIONS_PRODUCT_GENERIC.map((cond, i) => {
                      return <option key={i} value={cond.value}>{this.getConditionLabel(cond)}</option>;
                    })}
                  </select>
                  {this.state.errorCondition ?
                    <div className={'adminError FieldError'}>{t(this.state.errorCondition)}</div> :
                    null
                  }
                  <div className='aiDropdownArrowWrapper'>
                    <div className='aiArrow'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.buylistAllowed() ?
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_BUYLIST_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputBuylist}
                    onToggle={this.changeBuylist.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
            </div> :
            null
          }

          <div className={'adminFieldWrapper'}>
            <div className={'adminInputWrapper halfWidth'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRICE)}</div>
              <div className='currencyWrapper'>
                <input
                  type='number'
                  min={0}
                  step={getCurrencyIncrement()}
                  className={this.state.errorPrice ? 'currencyInput InputError' : 'currencyInput'}
                  value={this.state.inputPrice}
                  onChange={this.changePrice.bind(this)}
                  onBlur={this.validatePrice.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                <div className='currencyOverlay'>{getCurrencySymbol()}</div>
              </div>
              {this.state.errorPrice ?
                <div className={'adminError FieldError'}>{t(this.state.errorPrice)}</div> :
                null
              }
            </div>

            {this.state.inputBuylist ? 
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}</div>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    step={getCurrencyIncrement()}
                    className={this.state.errorBuyPrice ? 'currencyInput InputError' : 'currencyInput'}
                    value={this.state.inputBuyPrice}
                    onChange={this.changeBuyPrice.bind(this)}
                    onBlur={this.validateBuyPrice.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_BUY_PRICE)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorBuyPrice ?
                  <div className={'adminError FieldError'}>{t(this.state.errorBuyPrice)}</div> :
                  null
                }
              </div> :
              null
            }
          </div>


          <div className={'adminFieldWrapper'}>
            <div className={'adminInputWrapper halfWidth'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SELLABLE_QUANTITY)}</div>
              <input
                type='number'
                min={0}
                step={1}
                className={this.state.errorQuantity ? 'quantityInput InputError' : 'quantityInput'}
                value={this.state.inputQuantity}
                onChange={this.changeQuantity.bind(this)}
                onBlur={this.validateQuantity.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_QUANTITY)}
                maxLength={32} />
              {this.state.errorQuantity ?
                <div className={'adminError FieldError'}>{t(this.state.errorQuantity)}</div> :
                null
              }
            </div>

            <div className={'adminInputWrapper halfWidth'}>
              <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_INV_ADD_PRODUCT_TARGET_QUANTITY)}</div>
              <div className={'adminInputWrapper halfWidth'}>
                <input
                  type='number'
                  min={0}
                  step={1}
                  className={this.state.errorQuantityMin ? 'quantityInput InputError' : 'quantityInput'}
                  value={this.state.inputQuantityMin}
                  onChange={this.changeQuantityMin.bind(this)}
                  onBlur={this.validateQuantityMin.bind(this)}
                  placeholder={t(tx.TX_MIN)}
                  maxLength={32} />
                {this.state.errorQuantityMin ?
                  <div className={'adminError FieldError'}>{t(this.state.errorQuantityMin)}</div> :
                  null
                }
              </div>
              <div className={'adminInputWrapper halfWidth'}>
                <input
                  type='number'
                  min={0}
                  step={1}
                  className={this.state.errorQuantityMax ? 'quantityInput InputError' : 'quantityInput'}
                  value={this.state.inputQuantityMax}
                  onChange={this.changeQuantityMax.bind(this)}
                  onBlur={this.validateQuantityMax.bind(this)}
                  placeholder={t(tx.TX_MAX)}
                  maxLength={32} />
                {this.state.errorQuantityMax ?
                  <div className={'adminError FieldError'}>{t(this.state.errorQuantityMax)}</div> :
                  null
                }
              </div>
            </div>
          </div>



          <div className={'adminFieldWrapper'}>
            <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_LANGUAGE)}</div>
            <div className='adminInputWrapper'>
              <div className='adminDropdown'>
                <select
                  value={this.state.inputLanguage ? this.state.inputLanguage.code : ''}
                  onChange={this.changeLanguage.bind(this)}
                  className={this.state.errorLanguage ? 'InputError' : ''}>
                  <option value=''>{t(tx.TX_PLACEHOLDER_LANGUAGE_DROPDOWN)}</option>
                  {LANG_PRODUCT_GENERIC.map((lang, j) => {
                    return <option key={j} value={lang.code}>{t(lang.nameTranslation)}</option>
                  })}
                </select>
                {this.state.errorLanguage ?
                  <div className={'adminError FieldError'}>{t(this.state.errorLanguage)}</div> :
                  null
                }
                <div className='aiDropdownArrowWrapper'>
                  <div className='aiArrow'></div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='adminActionRow'>
          <Link 
            className={'adminAction adminActionCancel'} 
            to={URL_ADMIN_INVENTORY}>
            {t(tx.TX_CANCEL)}
          </Link>
          <button 
            className={'adminAction adminActionSave'} 
            type='submit'>
            {t(tx.TX_NEXT_COLON_NOUN, {noun: t(tx.TX_INV_ADD_PRODUCT_STEP_3)})}
          </button>
          <button 
            className={'adminAction adminActionBack adminLeft'} 
            type='button'
            onClick={this.props.movePrev.bind(this)}>
            {t(tx.TX_BACK)}
          </button>
        </div>
      </form>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductPricingGeneric));