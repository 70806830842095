import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_COUPON, 
  ICON_NOTICE, 
} from '../../constants/icons';
import * as tx from '../../constants/strings';
import {
  URL_ADMIN_MARKETING_COUPONS, 
  URL_ADMIN_MARKETING_NOTICES, 
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import { Icon } from '../Icons/Icon';

import './style/_marketing.scss';

let allActionCreators = Object.assign({});

export class Marketing extends Component {

  render() {

    const {t} = this.props;

    return <div className={'Marketing AdminPage'}>
      <AdminTitle
        title={tx.TX_MARKETING}
        breadcrumbs={[]} />
      <div className={'settingsIconRow adminIconRow'}>
        <Link to={URL_ADMIN_MARKETING_COUPONS} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_COUPON}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_MARKETING_COUPONS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_MARKETING_NOTICES} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_NOTICE}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_MARKETING_NOTICES)} 
            ellipsisLabel={false} />
        </Link>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Marketing));