import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_CHECK } from '../../../../constants/icons';
import { WEIGHT_UNITS_ALL } from '../../../../constants/measurements';
import * as tx from '../../../../constants/strings';

import { 
  getWeightError,
  isFormValid, 
} from '../../../../utils/form-validation';
import { normalizeWeight } from '../../../../utils/formatting';
import { getStoreDefaultWeightUnit } from '../../../../utils/general';
import { 
  convertWeightBetweenUnits,
  convertWeightToG,
  getWeightUnitFromKey, 
} from '../../../../utils/measurements';

import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class ProductLineDefaultField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      editing: false,

      inputValue: this.getInitValue() ? `${normalizeWeight(this.getInitValue(), getStoreDefaultWeightUnit())}` : '',
      inputValueUnit: getStoreDefaultWeightUnit(),

      errorValue: '',

      requestOriginated: false,
    }

    this.defaultObj = this.props.productLineObj && this.props.productLineObj.getDefaultObj(this.props.defaultKey, this.props.segment);

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.requestOriginated === true && prevProps.productLinePending === true && this.props.productLinePending === false) {
      this.setState({
        requestOriginated: false,
      });
      this.toggleEdit();
    }
  }

  getInitValue() {
    if(!this.props.productLineObj) { return ''; }
    return this.props.productLineObj.getDefaultValue(this.props.defaultKey, this.props.segment);
  }

  getDefaultDisplayValue() {
    if(this.getInitValue()) {
      return `${normalizeWeight(this.getInitValue(), getStoreDefaultWeightUnit())} ${getStoreDefaultWeightUnit().display}`;
    }
    return '';
  }

  toggleEdit() {
    this.setState({
      editing: !this.state.editing,
    }, () => {
      if(this.state.editing && this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      } else if(this.state.editing === false) {
        this.setState({
          inputValue: this.getInitValue() ? `${normalizeWeight(this.getInitValue(), getStoreDefaultWeightUnit())}` : '',
          errorValue: '',
        });
      }
    });
  }

  changeInput(evt) {
    this.setState({
      inputValue: evt.target.value,
    }, () => {
      if(this.state.errorValue) {
        this.validateInput();
      }
    });
  }

  validateInput() {
    this.setState({ errorValue: getWeightError(this.state.inputValue, true) });
  }

  changeUnit(evt) {
    const previousUnit = this.state.inputValueUnit;
    this.setState({
      inputValueUnit: getWeightUnitFromKey(evt.target.value),
    }, () => {
      this.setState({
        inputValue: normalizeWeight(convertWeightBetweenUnits(this.state.inputValue, previousUnit, this.state.inputValueUnit), this.state.inputValueUnit),
      });
    });
  }

  getWeightUnitOptions() {
    const weightOptions = [];
    for(const ut of WEIGHT_UNITS_ALL) {
      weightOptions.push({
        display: ut.display,
        value: ut.key,
      });
    }
    return weightOptions;
  }

  validateAll() {
    let errorObj = {
      errorValue: getWeightError(this.state.inputValue, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  submitDefault(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.props.productLinePending) {
        return null;
      }

      this.setState({
        requestOriginated: true,
      });

      const updateData = {
        weight: Math.round(convertWeightToG(this.state.inputValue, this.state.inputValueUnit)),
        segment_uuid: this.props.segment && this.props.segment.publicUuid ? this.props.segment.publicUuid : null,
      }
      this.props.updateDefault(updateData);
    }
  }

  getSegmentName(t) {
    if(!this.props.segment) {
      return t(tx.TX_INV_PL_ALL_PRODUCTS);
    }
    return this.props.segment.nameLocalizationKey ? t(this.props.segment.nameLocalizationKey) : this.props.segment.name;
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.submitDefault();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductLineDefaultField'}>
      <div className={'defaultLabel'}>{this.getSegmentName(t)}</div>
      {this.state.editing ?
        <div className='cfEdit'>
          <div className='cfEditInputWrapper'>
            <input 
              type='text'
              ref={this.inputRef}
              className='cfEditInput'
              value={this.state.inputValue}
              onChange={this.changeInput.bind(this)}
              onKeyPress={this.handleKeyPress.bind(this)} />
            <div className='cfEditInputUnitWrapper'>
              <select 
                className='cfUnitSelect' 
                name={t(tx.TX_WEIGHT)}
                value={this.state.inputValueUnit.key}
                onChange={this.changeUnit.bind(this)}>
                {this.getWeightUnitOptions().map((opt, j) => {
                  return <option key={j} value={opt.key}>{opt.display}</option>;
                })}
              </select>
            </div>
          </div>
          <div>
            {this.state.errorValue ?
              <div className={'cfEditError FieldError'}>{t(this.state.errorValue)}</div> :
              null
            }
          </div>
          <div className='cfEditSaveAction' onClick={this.submitDefault.bind(this)}>
            <div className='cfEditSaveActionIconWrapper'>
              {this.state.requestOriginated ?
                <LoadingIcon
                  iconClass={'cfEditSaveActionIcon'} /> :
                <Icon 
                  value={ICON_CHECK}  
                  iconClass={'cfEditSaveActionIcon'} />
              }
            </div>
          </div>
          <div className='cfEditCancelAction'>
            <div className={'cfEditCancelActionIconWrapper FlexCenter'} onClick={this.toggleEdit.bind(this)}>&times;</div>
          </div>
        </div> :
        <div className={'adminViewValue configValue'}>
          <span className={'adminViewValueData EllipsisElement'}>{this.getDefaultDisplayValue()}</span>
          <span 
            className={this.getInitValue() ? 'adminViewValueToggle' : 'adminViewValueToggle noMainValue'}
            onClick={this.toggleEdit.bind(this)}>
            {t(this.getInitValue() ? tx.TX_EDIT : tx.TX_ADD)}
          </span>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLineDefaultField));