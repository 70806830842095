import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERROR_EMAIL_EXISTS } from '../../constants/errors';
import * as tx from '../../constants/strings';
import { URL_HOME, URL_LOGIN } from '../../constants/urls';

import { setReAuth } from '../../utils/auth';
import { isFormValid, getNameError, getEmailError, getPasswordSetError, getPasswordMatchError } from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import './style/_auth.scss';

import * as authActionCreators from '../../actions/auth';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, userActionCreators);

export class Register extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      passwordAgain: '',
      passwordAgainError: '',

      registerReqError: '',
      registerReqPending: false,
    };

    this.controller = null;
    this.destination = props.location && props.location.state && props.location.state.from ? props.location.state.from : null;
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const { i18n } = this.props;

      const register_data = {
        email_address: this.state.email,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        password: this.state.password,
        lang: i18n.language,
        store_id: process.env.REACT_APP_STORE_UUID,
      };

      this.setState({ 
        registerReqPending: true,
        registerReqError: null,
      });

      this.props.usersRegister(register_data)
      .then((resp) => {
        
        const login_data = {
          email_address: this.state.email,
          password: this.state.password,
          store_id: process.env.REACT_APP_STORE_UUID,
        };

        this.props.usersLogin(login_data)
        .then((loginResp) => {
          
          // Record login in store, push to homepage or "from" location sent in location state
          this.props.authSet(loginResp);
          
          // Init reAuth timer
          setReAuth();

          if(this.destination) {
            this.props.history.push(this.destination);
          } else {
            this.props.history.push(URL_HOME);
          }
        })
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            registerReqPending: false,
            registerReqError: formatServerError(errResp),
          });
        });
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          loginReqPending: false,
          loginReqError: formatServerError(errResp),
        });
      });
    }
  }

  validateAll() {
    let errorObj = {
      firstNameError: getNameError(this.state.firstName),
      lastNameError: getNameError(this.state.lastName),
      emailError: this.state.emailError === '' ? getEmailError(this.state.email) : this.state.emailError,
      passwordError: getPasswordSetError(this.state.password),
      passwordAgainError: getPasswordMatchError(this.state.passwordAgain, this.state.password),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeFirstName(evt) {
    this.setState({ firstName: evt.target.value }, () => {
      if(this.state.firstName) {
        this.validateFirstName();
      }
    });
  }

  validateFirstName() {
    this.setState({ firstNameError: getNameError(this.state.firstName) });
  }

  changeLastName(evt) {
    this.setState({ lastName: evt.target.value }, () => {
      if(this.state.lastNameError) {
        this.validateLastName();
      }
    });
  }

  validateLastName() {
    this.setState({ lastNameError: getNameError(this.state.lastName) });
  }

  changeEmail(evt) {
    this.setState({ email: evt.target.value }, () => {
      if(this.state.emailError) {
        this.validateEmail();
      }
    });
  }

  validateEmail() {
    this.setState({ emailError: getEmailError(this.state.email) }, () => {
      if(!this.state.emailError) {
        
        // Asynchronous call to check if email exists

        if(this.controller) {
          this.controller.abort();
        }
        const controller = new AbortController();
        this.controller = controller;

        this.props.userEmailExists(this.state.email, controller.signal)
        .then((resp) => {
          if(resp.exists) {
            this.setState({ emailError: ERROR_EMAIL_EXISTS });
          }
        })
        .catch((errResp) => {
          // Do nothing
        });
      }
    });
  }

  changePassword(evt) {
    this.setState({ password: evt.target.value }, () => {
      if(this.state.passwordError) {
        this.validatePassword();
      }
    });
  }

  validatePassword() {
    this.setState({ passwordError: getPasswordSetError(this.state.password) }, () => {
      if(this.state.passwordAgain !== '' && this.state.passwordAgainError !== '') {
        this.validatePasswordAgain();
      }
    });
  }

  changePasswordAgain(evt) {
    this.setState({ passwordAgain: evt.target.value }, () => {
      if(this.state.passwordAgainError) {
        this.validatePasswordAgain();
      }
    });
  }

  validatePasswordAgain() {
    this.setState({ passwordAgainError: getPasswordMatchError(this.state.passwordAgain, this.state.password) });
  }

  render() {

    const {t} = this.props;

    return <div className={'Register authPage'}>
      <div className='authPageTitle'>{t(tx.TX_CREATE_ACCOUNT)}</div>
      <div className='authPageBody'>
        <div className='loginWrapper'>

          <div className={'registerToggle registerToggleTop'}>
            <div className='toggleHeader'>{t(tx.TX_SIGN_UP)}</div>
            <div className='toggleCopy'>{t(tx.TX_AUTH_JOIN_COMMUNITY, {storeName: process.env.REACT_APP_WEBSITE_NAME})}</div>
          </div>

          <form className='loginForm' onSubmit={this.handleSubmit.bind(this)}>
            <div className={this.state.registerReqError ? 'loginFormError present' : 'loginFormError'}>{t(this.state.registerReqError)}</div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_FIRST_NAME)}</div>
              <input
                type='text'
                autoComplete='given-name'
                className={this.state.firstNameError ? 'InputError' : ''}
                value={this.state.firstName}
                onChange={this.changeFirstName.bind(this)}
                onBlur={this.validateFirstName.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_FIRST_NAME)}
                maxLength={100} />
              {this.state.firstNameError ?
                <div className={'loginError FieldError'}>{t(this.state.firstNameError)}</div> :
                null
              }
            </div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_LAST_NAME)}</div>
              <input
                type='text'
                autoComplete='family-name'
                className={this.state.lastNameError ? 'InputError' : ''}
                value={this.state.lastName}
                onChange={this.changeLastName.bind(this)}
                onBlur={this.validateLastName.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_LAST_NAME)}
                maxLength={100} />
              {this.state.lastNameError ?
                <div className={'loginError FieldError'}>{t(this.state.lastNameError)}</div> :
                null
              }
            </div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
              <input
                type='email'
                autoComplete='email'
                className={this.state.emailError ? 'InputError' : ''}
                value={this.state.email}
                onChange={this.changeEmail.bind(this)}
                onBlur={this.validateEmail.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_EMAIL)}
                maxLength={100} />
              {this.state.emailError ?
                <div className={'loginError FieldError'}>{t(this.state.emailError)}</div> :
                null
              }
            </div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='new-password'
                className={this.state.passwordError ? 'InputError' : ''}
                value={this.state.password}
                onChange={this.changePassword.bind(this)}
                onBlur={this.validatePassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)}
                maxLength={100} />
              {this.state.passwordError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordError)}</div> :
                null
              }
            </div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_RETYPE_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='new-password'
                className={this.state.passwordAgainError ? 'InputError' : ''}
                value={this.state.passwordAgain}
                onChange={this.changePasswordAgain.bind(this)}
                onBlur={this.validatePasswordAgain.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)}
                maxLength={100} />
              {this.state.passwordAgainError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordAgainError)}</div> :
                null
              }
            </div>
            <div className='loginFieldWrapper'>
              <button 
                className='loginSubmitButton'
                disabled={this.state.registerReqPending}>
                {t(tx.TX_SIGN_UP)}
              </button>
            </div>
            
          </form>
          <div className='registerToggle'>
            <div className='toggleHeader'>{t(tx.TX_AUTH_ALREADY_HAVE_ACCOUNT)}</div>

            <Link to={URL_LOGIN}>
              <button className={'toggleButton soloButton'}>{t(tx.TX_LOGIN)}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Register));