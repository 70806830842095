import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ANALYTICS_CONFIG_SHOW_ALL } from '../../../../constants/analytics';
import { API_KEY_IS_BUYLIST } from '../../../../constants/api';
import { 
  TX_ANALYTICS_ORDERS_BY_STATUS,
  TX_ANALYTICS_ORDERS_BY_STATUS_PENDING, 
  TX_STATUS,
} from '../../../../constants/strings';

import { formatServerError } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import { ordersAnalyticsOrdersByStatus } from '../../../../actions/order';

import LoadingIcon from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockAnalyticsOrderStatusCounts.module.scss';

export const BlockAnalyticsOrderStatusCounts = (props) => {
  
  // Dispatch
  const dispatch = useDispatch();

  // Props
  // const {
  //   block, 
  // } = props;

  // State
  const [data, setData] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  // Refs
  const controllerRef = useRef(null);

  // Internationalization
  const { i18n, t } = useTranslation();

  // Methods
  const fetchData = useCallback(async (requestConfig) => {

    setDataError(null);
    setDataLoading(true);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const getData = {
      [API_KEY_IS_BUYLIST]: false,
    };

    // Fetch data
    const resp = await dispatch(ordersAnalyticsOrdersByStatus(getData, requestConfig, controller.signal))
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        setDataError(formatServerError(errResp));
        setDataLoading(false);
      });

    if(!resp) {
      return null;
    }

    setData(resp);
    setDataLoading(false);

  }, [ dispatch ]);

  // Effects
  useEffect(() => {
    if(data === null) {
      fetchData({});
    }
  }, [ data, fetchData ]);

  // Render
  return (
    <div className={styles.BlockAnalyticsOrderStatusCounts}>
      <div className={styles.headerWrapper}>
        <div className={styles.titleWrapper}>{t(data && data.getConfigValue(ANALYTICS_CONFIG_SHOW_ALL) ? TX_ANALYTICS_ORDERS_BY_STATUS : TX_ANALYTICS_ORDERS_BY_STATUS_PENDING)}</div>
      </div>
      <div className={styles.bodyWrapper}>
        {dataLoading || data === null ?
          <div className={styles.loadingWrapper}>
            <div className={styles.iconWrapper}>
              <LoadingIcon />
            </div>
          </div> :
          <>
            {dataError ?
              <div className={styles.errorWrapper}>{t(dataError)}</div> :
              <div className={styles.dataWrapper}>
                <div className={`${styles.dataRow} ${styles.dataHeader}`}>
                  <div className={styles.dataRowLiner}>
                    <div className={`${styles.dataLabel}`}>{t(TX_STATUS)}</div>
                    <div className={styles.dataValue}>{'#'}</div>
                  </div>
                </div>
                {data.displayData().map((datum, i) => {
                  return <div key={i} className={styles.dataRow}>
                    <div className={styles.dataRowLiner}>
                      <div className={`${styles.dataLabel} EllipsisElement`}>{t(datum.status.name)}</div>
                      <div className={styles.dataValue}>{datum.count.toLocaleString(getStoreLanguage(i18n))}</div>
                    </div>
                  </div>
                })}
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};

export default BlockAnalyticsOrderStatusCounts;




