import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import {
  URL_PLAY_GENERAL,
  URL_PLAY_PRODUCT_LINE, 
} from '../../constants/urls';

import { stringFormat } from '../../utils/formatting';
import { getOrderedPlayMenu } from '../../utils/product';

import EventCalendar from './EventCalendar';

import './style/_events.scss';

const allActionCreators = Object.assign({});

export class Events extends Component {

  constructor(props) {
    super(props);

    this.IMG_OPTIONS = 3;

    this.selectedIndex = Math.floor(Math.random() * this.IMG_OPTIONS);

    this.state = {
      permalink: this.props.match.params.productLinePermalink ? this.props.match.params.productLinePermalink : '',
    };
  }

  componentDidUpdate(prevProps, prevState) {

    // After loading product lines, check that the route is valid
    if(prevProps.productLine.menus.length === 0 && this.props.productLine.menus.length && this.props.match.params.productLinePermalink) {

      const permalinks = [];
      for(const pl of getOrderedPlayMenu(this.props.productLine.enabled)) {
        permalinks.push(pl.permalink);
      }
      if(!permalinks.includes(this.props.match.params.productLinePermalink)) {
        history.replace(URL_PLAY_GENERAL);
      }
    }

    // Listen for route changes
    if(prevProps.match.params.productLinePermalink !== this.props.match.params.productLinePermalink && this.props.match.params.productLinePermalink !== this.state.permalink) {
      this.setState({ permalink: this.props.match.params.productLinePermalink ? this.props.match.params.productLinePermalink : '' });
    }
  }

  getPlPermalink() {
    return this.props.match.params.productLinePermalink ? this.props.match.params.productLinePermalink : '';
  }

  setPermalink(permalink) {
    if(!permalink) {
      history.replace(URL_PLAY_GENERAL);
    } else {
      history.replace(stringFormat(URL_PLAY_PRODUCT_LINE, { productLine: permalink }));
    }
  }

  render() {
    return <div className={'Events'}>
      <div className={`calendarWrapper bg${this.selectedIndex}`}>
        <div className='calendarScreen'></div>
        <EventCalendar
          admin={false}
          plPermalink={this.state.permalink}
          setPlPermalink={this.setPermalink.bind(this)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Events));