


import { FILTERS_ALL } from '../constants/filters';





export function getFilterPlaceholder(filterConfig) {
	return filterConfig.placeholder ? filterConfig.placeholder : '';
}

export function getFilterDontTranslateTags(filterConfig) {
	return filterConfig.noTranslate ? filterConfig.noTranslate : false;
}

export function hasStaticFilterOptions(filterConfig) {
	// Want to return exactly true/false, not true-ish crap
	return filterConfig.options ? true : false;
}

export function getStaticFilterOptions(filterConfig) {
	return filterConfig.options ? filterConfig.options : [];
}

export function normalizeFilterValuesConditions(conditionsArray, isBuylist = false) {
	const valuesArray = [];
	for(const cd of conditionsArray) {

		if(isBuylist && cd.buylistExclude === true) {
			continue;
		}

		valuesArray.push({
			display: cd['name'],
			value: cd['value'],
			count: null,
		});
	}
	return valuesArray;
}

export function normalizeFilterValuesFinish(finishArray) {
	const valuesArray = [];
	for(const fn of finishArray) {
		valuesArray.push({
			display: fn['name'],
			value: fn['key'],
			count: null,
		});
	}
	return valuesArray;
}

export function normalizeFilterValuesProductLines(productLinesArray) {
	const valuesArray = [];
	for(const pl of productLinesArray) {
		valuesArray.push({
			display: pl['name'],
			value: pl['permalink'],
			count: null,
		});
	}
	return valuesArray;
}

export function normalizeFilterValuesLanguages(languagesArray) {
	const valuesArray = [];
	for(const ln of languagesArray) {
		valuesArray.push({
			display: ln['nameTranslation'],
			value: ln['code'],
			count: null,
		});
	}
	return valuesArray;
}

export function getFilterConfigByKey(filterKey) {
	if(!filterKey) {
		return null;
	}
	for(const filterConfig of FILTERS_ALL) {
		if(filterKey === filterConfig.key) {
			return filterConfig;
		}
	}
	return null;
}








