import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { 
  ERROR_COUPON_CODE_EXISTS,
  ERROR_DISCOUNT_TYPE_MISSING,
} from '../../constants/errors';
import {
  COUPON_DISCOUNT_TYPE_FIXED,
  COUPON_DISCOUNT_TYPE_PERCENTAGE,
} from '../../constants/promotions';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_MARKETING, 
  URL_ADMIN_MARKETING_COUPONS, 
} from '../../constants/urls';

import { Coupon } from '../../models/promotions';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../utils/currency';
import { 
  isFormValid, 
  getNameError, 
  getCouponCodeError,
  getDiscountAmountError, 
  getNotEmptyError, 
  getPurchaseAmountError,
  getShortDescriptionError,
  getUsageCountError, 
} from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import Dropdown from '../Input/Dropdown';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';
import Toggle from '../Input/Toggle';

import './style/_coupons.scss';

import * as promotionActionCreators from '../../actions/promotion';
let allActionCreators = Object.assign({}, promotionActionCreators);

export class AddCoupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      savePromptOpen: false,

      inputName: '',
      inputCode: '',
      inputDescription: '', 
      inputDiscountType: COUPON_DISCOUNT_TYPE_FIXED, 
      inputDiscountAmount: '', 
      inputMinPurchase: '', 
      inputLimitPerUserUses: false,
      inputPerUserUses: '',

      errorName: '',
      errorCode: '',
      errorDescription: '', 
      errorDiscountType: '',
      errorDiscountAmount: '',
      errorMinPurchase: '', 
      errorLimitPerUserUses: '',
      errorPerUserUses: '',

      lastBlock: '',
      saveRedirect: false,

      requestPending: false,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidMount() {
    
    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });
  }

  componentWillUnmount() {
    this.unblock();
    if(this.controller) {
      this.controller.abort();
    }
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveAction();
    });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const newCoupon = new Coupon({
        name: this.state.inputName,
        code: this.state.inputCode.toLowerCase(),
        description: this.state.inputDescription,
        discountType: this.state.inputDiscountType, 
        discountAmount: this.state.inputDiscountAmount, 
        minPurchase: this.state.inputMinPurchase,
        perUserUses: this.state.inputPerUserUses,
        isEnabled: true,
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const addResp = await this.props.promoAdminAddCoupon(newCoupon.getApiData())
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!addResp) {
        return null;
      }

      this.setState({ isDirty: false }, () => {
        if(!this.state.saveRedirect) {
          history.push(URL_ADMIN_MARKETING_COUPONS);
        } else {
          history.push(this.state.lastBlock);
        }
      });
    }
  }

  validateAll() {
    const errorObj = {
      errorName: getNameError(this.state.inputName),
      errorCode: getCouponCodeError(this.state.inputCode), 
      errorDescription: getShortDescriptionError(this.state.inputDescription, true),
      errorDiscountType: getNotEmptyError(this.state.inputDiscountType, ERROR_DISCOUNT_TYPE_MISSING),
      errorDiscountAmount: getDiscountAmountError(this.state.inputDiscountAmount, this.state.inputDiscountType),
      errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase),
      errorPerUserUses: getUsageCountError(this.state.inputPerUserUses, this.state.inputLimitPerUserUses === false),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorName) {
        this.validateName();
      }
    });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  changeCode(evt) {
    this.setState({
      inputCode: evt.target.value.toUpperCase(),
      isDirty: true,
    }, () => {
      if(this.state.errorCode) {
        this.validateCode();
      }
    });
  }

  validateCode() {
    this.setState({ errorCode: getCouponCodeError(this.state.inputCode) }, async () => {
      if(!this.state.errorCode) {

        // Async call to check if product with that SKU exists
        if(this.controller) {
          this.controller.abort();
        }
        const controller = new AbortController();
        this.controller = controller;

        await this.props.promoAdminCouponAvailable(this.state.inputCode, controller.signal)
          .catch((errResp) => {
            this.setState({ errorCode: ERROR_COUPON_CODE_EXISTS });
          });
      }
    });
  }

  changeDescription(evt) {
    this.setState({
      inputDescription: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorDescription) {
        this.validateDescription();
      }
    });
  }

  validateDescription() {
    this.setState({ errorDescription: getShortDescriptionError(this.state.inputDescription, true) });
  }

  changeDiscountType(evt) {
    this.setState({
      inputDiscountType: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorDiscountType) {
        this.validateDiscountType();
      }
    });
  }

  validateDiscountType() {
    this.setState({ errorDiscountType: getNotEmptyError(this.state.inputDiscountType, ERROR_DISCOUNT_TYPE_MISSING) });
  }

  getDiscountTypeOptions() {
    
    const typeOptions = [
      {
        display: tx.TX_MARKETING_COUPON_TYPE_FIXED,
        value: COUPON_DISCOUNT_TYPE_FIXED,
      },
      {
        display: tx.TX_MARKETING_COUPON_TYPE_PERCENTAGE,
        value: COUPON_DISCOUNT_TYPE_PERCENTAGE,
      },
    ];

    return typeOptions;
  }

  changeDiscountAmount(evt) {
    this.setState({
      inputDiscountAmount: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorDiscountAmount) {
        this.validateDiscountAmount();
      }
    });
  }

  validateDiscountAmount() {
    this.setState({ errorDiscountAmount: getDiscountAmountError(this.state.inputDiscountAmount, this.state.inputDiscountType) });
  }

  changeMinPurchase(evt) {
    this.setState({
      inputMinPurchase: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorMinPurchase) {
        this.validateMinPurchase();
      }
    });
  }

  validateMinPurchase() {
    this.setState({ errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase) });
  }

  changeLimitPerUserUses(evt) {
    this.setState({
      inputLimitPerUserUses: !this.state.inputLimitPerUserUses, 
      isDirty: true,
    }, () => {
      if(this.state.inputLimitPerUserUses === false) {
        this.setState({ 
          inputPerUserUses: '',
          errorPerUserUses: '',
        });
      }
    });
  }

  changePerUserUses(evt) {
    this.setState({
      inputPerUserUses: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorPerUserUses) {
        this.validatePerUserUses();
      }
    });
  }

  validatePerUserUses(evt) {
    this.setState({ errorPerUserUses: getUsageCountError(this.state.inputPerUserUses) });
  }

  render() {

    const {t} = this.props;

    return <div className={'AddCoupon AdminPage'}>

      <AdminTitle
        title={tx.TX_ADD}
        breadcrumbs={[
          {
            url: URL_ADMIN_MARKETING,
            title: tx.TX_MARKETING,
          },
          {
            url: URL_ADMIN_MARKETING_COUPONS,
            title: tx.TX_MARKETING_COUPONS,
          },
        ]} />

      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className='addCouponForm'
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_MARKETING_ADD_COUPON)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.state.inputName}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_COUPON_NAME)}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_MARKETING_COUPON_CODE)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorCode ? 'InputError' : ''}
                  value={this.state.inputCode}
                  onChange={this.changeCode.bind(this)}
                  onBlur={this.validateCode.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_COUPON_CODE)}
                  maxLength={16} />
                {this.state.errorCode ?
                  <div className={'adminError FieldError'}>{t(this.state.errorCode)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_DESCRIPTION)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorDescription ? 'InputError' : ''}
                  value={this.state.inputDescription}
                  onChange={this.changeDescription.bind(this)}
                  onBlur={this.validateDescription.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_COUPON_DESCRIPTION)}
                  maxLength={1024} />
                {this.state.errorDescription ?
                  <div className={'adminError FieldError'}>{t(this.state.errorDescription)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'> 
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_MARKETING_COUPON_DISCOUNT_TYPE)}</div>
                <div className='adminDropdownWrapper'>
                  <Dropdown 
                    className={'adminDropdownSelect'}
                    options={this.getDiscountTypeOptions()}
                    name={t(tx.TX_MARKETING_COUPON_DISCOUNT_TYPE)}
                    error={this.state.errorDiscountType}
                    value={this.state.inputDiscountType}
                    onChange={this.changeDiscountType.bind(this)}
                    required={true} />
                </div>
                {this.state.errorDiscountType ?
                  <div className={'adminError FieldError'}>{t(this.state.errorDiscountType)}</div> :
                  null
                }
              </div>
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_MARKETING_COUPON_DISCOUNT_AMOUNT)}</div>
                <div className={this.state.inputDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE ? 'percentWrapper' : 'currencyWrapper'}>
                  <input
                    type='number'
                    min={0}
                    max={this.state.inputDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE ? 100 : 100000000}
                    step={this.state.inputDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE ? 0.1 : getCurrencyIncrement()}
                    className={`${this.state.discountType} ${this.state.inputDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE ? 'percentInput' : 'currencyInput'} ${this.state.errorDiscountAmount ? 'InputError' : ''}`}
                    value={this.state.inputDiscountAmount}
                    onChange={this.changeDiscountAmount.bind(this)}
                    onBlur={this.validateDiscountAmount.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_COUPON_DISCOUNT_AMOUNT)} />
                  <div className={this.state.inputDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE ? 'percentOverlay' : 'currencyOverlay'}>
                    {this.state.inputDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE ? '%' : getCurrencySymbol()}
                  </div>
                </div>
                {this.state.errorDiscountAmount ?
                  <div className={'adminError FieldError'}>{t(this.state.errorDiscountAmount)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_SETTINGS_MIN_PURCHASE)}</div>
              <div className='adminInputWrapper'>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    max={100000000}
                    step={getCurrencyIncrement()}
                    className={this.state.errorMinPurchase ? 'InputError currencyInput' : 'currencyInput'}
                    value={this.state.inputMinPurchase}
                    onChange={this.changeMinPurchase.bind(this)}
                    onBlur={this.validateMinPurchase.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_MIN_PURCHASE)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorMinPurchase ?
                  <div className={'adminError FieldError'}>{t(this.state.errorMinPurchase)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_MARKETING_COUPON_LIMIT_PER_USER_QUESTION)}</div>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputLimitPerUserUses}
                    onToggle={this.changeLimitPerUserUses.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                  <div className={'subtleSupplement fieldSupplement'}>{t(tx.TX_MARKETING_COUPON_USERS_MUST_BE_LOGGED_IN)}</div>
                </div>
              </div>
              {this.state.inputLimitPerUserUses ?
                <div className='adminInputWrapper halfWidth'>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_MARKETING_COUPON_USES_PER_USER)}</div>
                    <input
                      type='number'
                      min={0}
                      max={100000000}
                      step={1}
                      className={`quantityInput ${this.state.errorPerUserUses ? 'InputError' : ''}`}
                      value={this.state.inputPerUserUses}
                      onChange={this.changePerUserUses.bind(this)}
                      onBlur={this.validatePerUserUses.bind(this)}
                      placeholder={t(tx.TX_PLACEHOLDER_COUPON_PER_USER_USES)} />
                  {this.state.errorPerUserUses ?
                    <div className={'adminError FieldError'}>{t(this.state.errorPerUserUses)}</div> :
                    null
                  }
                </div> :
                null
              }
            </div>
            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_MARKETING_COUPONS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddCoupon));