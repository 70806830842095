import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { CSS_TABLET_BREAK } from '../../../constants/css';

import HeaderSubmenuUser from './HeaderSubmenuUser';

import '../style/_headerusermenu.scss';

import * as headerActionCreators from '../../../actions/header';
let allActionCreators = Object.assign({}, headerActionCreators);

export class HeaderUserMenu extends Component {

  constructor(props) {
    super(props);

    this.menuBodyRef = React.createRef();
    this.checkClick = this.checkClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
  }

  checkClick(evt) {
    if(window.innerWidth > CSS_TABLET_BREAK && this.props.header.headerUserMenuOpen === true) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.menuBodyRef.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.props.headerToggleUserMenu();
    }  
  }

  render() {
    return <div className='HeaderUserMenu'>
      <div 
        className={this.props.header.headerUserMenuOpen ? 'humOuterWrapper open' : 'humOuterWrapper'}
        ref={this.menuBodyRef}>
        <div className='humInnerWrapper'>
          <HeaderSubmenuUser />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderUserMenu));


