import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class CheckoutStep extends Component {

  getStepComponent() {
    const StepComponent = this.props.config.component;
    return <StepComponent
              config={this.props.config}
              isBuylist={this.props.isBuylist}
              validationSignal={null}
              setStepData={this.props.setStepData} />;
  }

  render() {
    return <div className={'CheckoutStep'}>
      <div className='checkoutStepLiner'>
        {this.getStepComponent()}
      </div>
      {this.props.loading ?
        <div className='csLoading'>
          <div className='csLoadingIconWrapper'>
            <LoadingIcon />
          </div>
        </div> :
        null
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStep));