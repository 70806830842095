import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { StarwarsCardImages } from '../../models/syncs/starwars';

import StaticImage from '../Image/StaticImage';
import ProgressiveImage from '../Image/ProgressiveImage';

import './style/_starwars.scss';

let allActionCreators = Object.assign({});

export class StarWarsCardImage extends Component {

  render() {

    const {t} = this.props;

    // The thumbnail uses primary since most starwars doesn't have thumbnail

    return <div className={'StarWarsCardImage'}>      
      <div className='starwarsObjImageWrapper'>
        {this.props.thumbnail ?
          <StaticImage
            imgObj={{
              src: this.props.starwarsObj.primaryImageSrc(), 
              alt: this.props.starwarsObj.localizedName, 
              noTranslate: true,
            }}
            onImgLoad={this.props.onLoadLow} /> :
          <ProgressiveImage
            alt={this.props.starwarsObj.imageAlt(t)}
            className='starwarsObjImageImg'
            src={this.props.starwarsObj.primaryImageSrc()}
            thumbnailSrc={this.props.starwarsObj.thumbnailImageSrc()}
            loadDelay={this.props.loadDelay}
            placeholder={StarwarsCardImages.defaultThumbnailSrc()}
            onLoadLow={this.props.onLoadLow} />
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StarWarsCardImage));