

import {
	DISTANCE_UNIT_MM,
	DISTANCE_UNITS_ALL,
	WEIGHT_UNIT_G,
	WEIGHT_UNITS_ALL,
} from '../constants/measurements';

import { safeParseFloat } from '../utils/general';



export function getWeightUnitFromKey(unitKey) {
	if(!unitKey) { return WEIGHT_UNIT_G; }

	for(const un of WEIGHT_UNITS_ALL) {
		if(un.key === unitKey) {
			return un;
		}
	}
	return WEIGHT_UNIT_G;
}


export function convertWeightToG(weight, weightUnit) {
	if(!weight || !weightUnit) { return weight; }
	try {
		return safeParseFloat(weight)*weightUnit.grams;
	} catch(err) {
		console.error(err);
	}
	return weight;
}


export function convertWeightFromG(weight, weightUnit) {
	if(!weight || !weightUnit) { return weight; }
	try {
		return safeParseFloat(weight)/weightUnit.grams;
	} catch(err) {
		console.error(err);
	}
	return weight;
}


export function convertWeightBetweenUnits(weight, fromUnit, toUnit) {
	if(!fromUnit || !toUnit) { return weight; }
	try {
		return convertWeightFromG(convertWeightToG(weight, fromUnit), toUnit);
	} catch(err) {
		console.error(err);
	}
	return weight;
}



export function getDistanceUnitFromKey(unitKey) {
	if(!unitKey) { return DISTANCE_UNIT_MM; }

	for(const un of DISTANCE_UNITS_ALL) {
		if(un.key === unitKey) {
			return un;
		}
	}
	return DISTANCE_UNIT_MM;
}


export function convertDistanceToMM(distance, distanceUnit) {
	if(!distance || !distanceUnit) { return distance; }
	try {
		return safeParseFloat(distance)*distanceUnit.mms;
	} catch(err) {
		console.error(err);
	}
	return distance;
}


export function convertDistanceFromMM(distance, distanceUnit) {
	if(!distance || !distanceUnit) { return distance; }
	try {
		return safeParseFloat(distance)/distanceUnit.mms;
	} catch(err) {
		console.error(err);
	}
	return distance;
}


export function convertDistanceBetweenUnits(distance, fromUnit, toUnit) {
	if(!fromUnit || !toUnit) { return distance; }
	try {
		return convertDistanceFromMM(convertDistanceToMM(distance, fromUnit), toUnit);
	} catch(err) {
		console.error(err);
	}
	return distance;
}

export function getWeightUnitOptions() {
    const weightOptions = [];
    for(const ut of WEIGHT_UNITS_ALL) {
      weightOptions.push({
        display: ut.display,
        value: ut.key,
      });
    }
    return weightOptions;
  }





