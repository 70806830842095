



import { toDate } from 'date-fns-tz';

import { 
	API_FOREIGN_MODEL_STARWARS_CARD,
	API_FOREIGN_MODEL_STARWARS_SEALED,
	API_FOREIGN_MODEL_STARWARS_SET, 
} from '../../constants/api';
import { CONDITIONS_PRODUCT_TCG } from '../../constants/conditions';
import { FINISHES_STARWARS } from '../../constants/finishes';
import { 
	LANG_EN,
	LANG_PRODUCT_STARWARS, 
} from '../../constants/languages';
import { STARWARS_CARD_SKU_PREFIX } from '../../constants/syncs/starwars';
import { 
	PROD_PAGE_LAYOUT_CLASS_TCG,
	PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG,
	PROD_SKU_MANAGED_DEMARKATION, 
} from '../../constants/product';
import { 
	TX_STARWARS, 
	TX_null, 
} from '../../constants/strings';

import { 
	CatalogTag,
	Product,
	ProductForeignModel,
	ProductSet,
} from '../products';

import { dynamicSort } from '../../utils/sort';

import StarWarsCardImage from '../../components/StarWars/StarWarsCardImage';
import StarWarsCardThumbnail from '../../components/Gallery/blocks/elements/StarWarsCardThumbnail';
import ProductPageDetailsStarWars from '../../components/ProductPage/blocks/ProductPageDetailsStarWars';



export class StarwarsSet extends ProductSet {

	constructor(props) {

		super(props);
		
		this.id = props.id || null;
		this.code = props.code || props.set_code || this.code || '';
		this.name = props.name || props.set_name || this.name || '';
		this.cardCount = parseInt(props.cardCount) || parseInt(props.card_count) || 0;

		const releaseDateValue = props.releaseDate || props.release_date || null;
		this.releaseDate = null;
		if(releaseDateValue) {
			this.releaseDate = releaseDateValue instanceof Date ? releaseDateValue : toDate(releaseDateValue);
		}	
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_STARWARS_SET;
	}

	get lookupKey() {
		return this.code;
	}

	set lookupKey(val) {
		this.code = val;
	}

	toOption(config = {}) {

		const idValue = config.idValue || false;
		const selfValue = config.selfValue || false;

		let value = this.code;
		if(idValue) {
			value = this.id;
		} else if(selfValue) {
			value = this;
		}

		return {
			display: this.name,
			value: value,
			count: null,
		};
	}
}


export class StarwarsCard extends ProductForeignModel {

	constructor(props = {}) {

		super(props);

		this.id = props.id || null;
		this.name = props.name || '';
		this.bodyText = props.bodyText || props.body_text || '';
		this.languageCode = props.languageCode || props.lang || LANG_EN;
		this.rarity = props.rarity || '';
		this.collectorNumber = props.collectorNumber || props.collector_number || '';
		this.tcgplayerId = props.tcgplayerId || props.tcg_product_id || null;
		this.cost = parseInt(props.cost) || 0;
		this.power = parseInt(props.power) || 0;
		this.hp = parseInt(props.hp) || 0;
				
		this.starwarsSet = new StarwarsSet(props.starwarsSet || props.starwars_set || {});
		this.images = new StarwarsCardImages(props.images || props.card_media || {});

		const arenaTypeObj = props.arenaType || props.arena_type || null;
		this.arenaType = arenaTypeObj ? new StarwarsCardArenaType(arenaTypeObj) : null;

		const cardTypeObj = props.cardType || props.card_type || null;
		this.type = cardTypeObj ? new StarwarsCardType(cardTypeObj) : null;

		const aspectsArray = props.aspects || [];
		this.aspects = [];
		for(const aa of [ ...aspectsArray ].sort(dynamicSort('sequence'))) {
			this.aspects.push(new StarwarsCardAspect(aa));
		}

		const traitsArray = props.traits || [];
		this.traits = [];
		for(const tr of [ ...traitsArray ].sort(dynamicSort('sequence'))) {
			this.traits.push(new StarwarsCardTrait(tr));
		}

		this.tags = [];
		const tagsArray = props.tags || [];
		for(const tg of tagsArray) {
			this.tags.push(new CatalogTag(tg));
		}
	}

	get isStarwarsCard() {
		return true;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_STARWARS_CARD;
	}

	get languageObj() {
		for(const lang of LANG_PRODUCT_STARWARS) {
			if(lang.code === this.languageCode) {
				return lang;
			}
		}
		return LANG_PRODUCT_STARWARS[0];
	}

	get exportInventoryDetails() {
		return true;
	}

	allConditions() {
		return CONDITIONS_PRODUCT_TCG;
	}

	hasFinish() {
		return true;
	}

	allFinishes() {
		return FINISHES_STARWARS;
	}

	get allowSealed() {
		return false;
	}

	get alwaysDisplayLanguage() {
		return true;
	}

	get setName() {
		return this.starwarsSet.name || '';
	}

	primaryImageSrc(isBack = false) {
		return this.images && this.images.primarySrc ? this.images.primarySrc : StarwarsCardImages.defaultPrimarySrc();
	}

	thumbnailImageSrc(isBack = false) {
		return this.images && this.images.thumbnailSrc ? this.images.thumbnailSrc : StarwarsCardImages.defaultThumbnailSrc();
	}

	getPrimaryImage(props = {}) {
		return <StarWarsCardImage 
		          starwarsObj={this}
		          loadDelay={0}
		          {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <StarWarsCardImage 
		          starwarsObj={this}
		          loadDelay={0}
		          noFlip={true}
		          thumbnail={true}
		          {...props} />;
	}

	imageAlt(t = null) {
	
		const cardName = this.localizedName;
		const setName = this.set && this.set.name ? this.set.name : '';

		if(!cardName) {
			return t ? t(TX_STARWARS) : '';
		}

		const respArray = [ cardName ];
		if(setName) {
			respArray.push(setName);
		}
		if(t) {
			respArray.push(t(TX_STARWARS));
		}

		return respArray.join(' | ');
	}

	get componentThumbnailGallery() {
		return StarWarsCardThumbnail;
	}

	get componentProductPageDetails() {
		return ProductPageDetailsStarWars;
	}

	get productPageLayoutClass() {
		return PROD_PAGE_LAYOUT_CLASS_TCG;
	}

	get productPageDetailLayoutClass() {
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG;
	}

	createProduct(productLine) {
		return new Product({
			name: this.name,
			title: this.name,
			sku: this.productSku,
			permalink: this.productPermalink,
			description: '',

			product_line: productLine,

			foreignModelCode: this.foreignModelCode,
			foreignModel: this,

			languageObj: this.languageObj,
			languageCode: this.languageObj.code,			
		});
	}

	get productSku() {
		const idBase = '000000000';
		const idString = this.id ? this.id.toString() : '';
		const leadingZeros = idBase.substring(0, idBase.length - idString.length);

		return `${STARWARS_CARD_SKU_PREFIX}${PROD_SKU_MANAGED_DEMARKATION}${leadingZeros}${idString}`.toLowerCase();
	}

	get productPermalink() {
		return this.productSku.replaceAll(PROD_SKU_MANAGED_DEMARKATION, '');
	}

	get set() {
		return this.starwarsSet || null;
	}

	getSetName() {
		return this.starwarsSet && this.starwarsSet.name ? this.starwarsSet.name : '';
	}

	// get compoundName() {
	// 	return [ this.name ];
	// }

	nameWithTags(t = null) {
		
		let nameString = this.name;
		for(const tag of this.tags) {
			if(tag.shouldDisplay) {
				if(t) {
					nameString += ` (${tag.nameTranslationKey ? t(tag.nameTranslationKey) : tag.name})`;
				} else {
					nameString += ` (${tag.name})`;
				}
			}
		}
		return nameString;
	}
}


export class StarwarsSealed {

	constructor(props) {

		if(!props) { props = {}; }

		this.id = props.id || null;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_STARWARS_SEALED;
	}
}


export class StarwarsCardTrait {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class StarwarsCardAspect {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class StarwarsCardType {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class StarwarsCardArenaType {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class StarwarsCardImages {

	constructor(props) {

		if(!props) { props = {}; }

		this.default = props.default || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/starwars/cardback.png';
	}

	static defaultThumbnailSrc() {
		return '/img/product/starwars/cardback.png';
	}

	get primarySrc() {
		return this.default || StarwarsCardImages.defaultPrimarySrc();
	}

	get thumbnailSrc() {
		return this.default || StarwarsCardImages.defaultThumbnailSrc();
	}
}


export class StarwarsImages {

	constructor(props) {

		if(!props) { props = {}; }

		this.default = props.default || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/noimage.png';
	}

	static defaultThumbnailSrc() {
		return '/img/product/noimage.png';
	}

	get primarySrc() {
		return this.default || StarwarsCardImages.defaultPrimarySrc();
	}

	get thumbnailSrc() {
		return this.default || StarwarsCardImages.defaultThumbnailSrc();
	}
}
































