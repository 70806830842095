import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { PAGES_ALL } from '../../constants/pages';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_PAGES, 
  TABLE_SCHEMA_PAGES, 
} from '../../constants/tables';
import {
  URL_ADMIN_SETTINGS, 
} from '../../constants/urls';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';


const allActionCreators = Object.assign({});

export class Pages extends Component {

  render() {

    return <div className={'Pages AdminPage'}>
      <AdminTitle
        title={tx.TX_PAGES}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_PAGES}
          schema={TABLE_SCHEMA_PAGES}
          data={PAGES_ALL} 
          dataLoading={false} />
      </div>
      
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Pages));