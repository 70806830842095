import 'whatwg-fetch';

import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

import { Coupon } from '../models/promotions';

import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/



// None yet





/******************************
*******  Asynchronous  ********
******************************/




export function promoAdminFetchCouponPage(getParams, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_PROMOTIONS_ADMIN_FETCH_COUPON_LIST + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const respModels = [];
        for(const r of resp.data.data) {
          respModels.push(new Coupon(r));
        }
        return Promise.resolve(Object.assign({}, resp.data, { data: respModels }));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function promoAdminAddCoupon(addData, orderUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_PROMOTIONS_ADMIN_ADD_COUPON;
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(addData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Coupon(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function promoAdminUpdateCoupon(updateData, couponCode, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PROMOTIONS_ADMIN_EDIT_COUPON, { code: couponCode });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(updateData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function promoAdminDeleteCoupon(couponCode, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PROMOTIONS_ADMIN_DELETE_COUPON, { code: couponCode });
    return fetch(url, {
      method: 'delete',
      headers: authReq(a.API_HEADERS),
      signal, 
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function promoAdminFetchCoupon(couponCode, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PROMOTIONS_ADMIN_FETCH_COUPON_SINGLE, { code: couponCode });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Coupon(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function promoAdminCouponAvailable(couponCode, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PROMOTIONS_ADMIN_COUPON_AVAILABLE, { code: couponCode.toLowerCase() });
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}







