import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_CUSTOMER_BULK_UPLOADS, 
  TABLE_SCHEMA_CUSTOMER_BULK_UPLOADS, 
  TC_PAGINATION_CONFIGS, 
  TC_DEFAULT_PAGE_SIZE, 
} from '../../constants/tables';
import { URL_ADMIN_CUSTOMERS } from '../../constants/urls';

import { 
  getPageLimit, 
  getPageOffset, 
} from '../../utils/request';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import * as customerActionCreators from '../../actions/customer';
let allActionCreators = Object.assign({}, customerActionCreators);

export class CustomerBulkUploads extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestData: [],
      requestPending: true,
      requestCount: 0,

      requestPage: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getBulkUploadPage(this.state.requestPage);
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  async getBulkUploadPage(pageNum = 1, pageSize = TABLE_CONFIG_CUSTOMER_BULK_UPLOADS[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestData: [],
      requestPending: true,
      requestCount: 0,
    });

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    const bulkResp = await this.props.customerBulkUploadFetchPage(getParams, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) {
          // Request aborted; do nothing
        } else if(errResp) {
          console.error(errResp);
          this.setState({
            requestData: [],
            requestPending: false,
            requestCount: 0,
          });
        }
      });

    if(!bulkResp) {
      return null;
    }

    this.setState({
      requestData: bulkResp.data,
      requestPending: false,
      requestCount: bulkResp.count,
    });
  }

  render() {
    return <div className={'CustomerBulkUploads AdminPage'}>
      <AdminTitle
        title={tx.TX_INV_BULK_UPLOAD_BULK_UPLOADS}
        breadcrumbs={[
          {
            url: URL_ADMIN_CUSTOMERS,
            title: tx.TX_CUSTOMERS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_CUSTOMER_BULK_UPLOADS}
          schema={TABLE_SCHEMA_CUSTOMER_BULK_UPLOADS}
          data={this.state.requestData} 
          dataCount={this.state.requestCount} 
          dataLoading={this.state.requestPending}
          callApi={this.getBulkUploadPage.bind(this)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerBulkUploads));