
import * as e from '../constants/errors';
import {
	COUPON_DISCOUNT_TYPE_FIXED,
	COUPON_DISCOUNT_TYPE_PERCENTAGE,
} from '../constants/promotions';

import { getCurrencyMinorCount } from './currency';
import { stringToDigits } from './formatting';
import { 
	getDigitString,
	isVarArray,
	isVarObject,
	safeParseFloat,
	safeParseInt,
} from './general';



// Validation logic
// Always return error constants or '' for false.

export function validateNull() { return ''; }

export function isFormValid(errorObj) {

	// Can pass either an object or array of errors
	// Object mostly used in components; array mostly in functions

	let errorSum = '';
	if(isVarArray(errorObj)) {
		for(const val of errorObj) {
			errorSum += val === null ? '' : val;
		}
	} else if(isVarObject(errorObj)) {
		// eslint-disable-next-line
		for(const [key, value] of Object.entries(errorObj)) {
		  errorSum += value === null ? '' : value;
		}
	} else {
		// Something weird was passed
		return false;
	}
	return errorSum === '';
}

export function getNameError(testName, allowEmpty = false) {
	
	if(allowEmpty && testName === '') {
		return '';
	}

	if(testName === null || testName === '' || !testName) {
		return e.ERROR_NAME_MISSING;
	}
	if(testName.trim().length === 0) {
		return e.ERROR_NAME_MISSING;
	}
	if(testName.trim().length > 100) {
		return e.ERROR_STRING_LENGTH_100;
	}
	return '';
}

export function getPhoneError(testPhone, allowEmpty = false) {
	
	const minLength = 10;
	const maxLength = 32;

	if(allowEmpty && testPhone === '') {
		return '';
	}
	if(!testPhone) {
		return e.ERROR_PHONE_MISSING;
	}

	const digitString = stringToDigits(testPhone);

	if(digitString.length <= 0) {
		return e.ERROR_PHONE_MISSING;
	}
	if(digitString.length < minLength || digitString.length > maxLength) {
		return e.ERROR_PHONE_INVALID;
	}
	return '';
}

export function getPasswordInputError(testPassword) {
	if(testPassword === '') {
		return e.ERROR_PASSWORD_MISSING;
	}
	return '';
}

export function getPasswordSetError(testPassword) {
	if(testPassword === null) {
		return e.ERROR_PASSWORD_MISSING;
	}
	if(testPassword !== testPassword.trim()) {
		return e.ERROR_PASSWORD_RULE_SPACE;
	}
	if(testPassword.trim().length < 8) {
		return e.ERROR_PASSWORD_RULE_MIN;
	}
	if(testPassword.trim().length > 100) {
		return e.ERROR_STRING_LENGTH_100;
	}
	return '';
}

export function getPasswordMatchError(testPassword, originalPassword) {
	if(testPassword === null || originalPassword === null) {
		return e.ERROR_PASSWORD_MATCH;
	}
	if(testPassword.trim() !== originalPassword.trim()) {
		return e.ERROR_PASSWORD_MATCH;
	}
	return '';
}

export function getEmailError(testEmail) {
	if(!testEmail) {
		return e.ERROR_EMAIL_MISSING;
	}
	if(testEmail.trim().length <= 0) {
		return e.ERROR_EMAIL_MISSING;
	}
	if(testEmail.trim().length > 100) {
		return e.ERROR_STRING_LENGTH_100;
	}
	// eslint-disable-next-line
	const re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	if(!re.test(testEmail)) {
		return e.ERROR_EMAIL_INVALID;
	}
	return '';
}

export function getSyncTypeError(testSync) {
	if(!testSync) {
		return e.ERROR_PL_SYNC_TYPE_MISSING;
	}
	return '';
}

export function getSKUError(testSKU) {
	if(!testSKU) {
		return e.ERROR_SKU_MISSING;
	}
	let testSKUtrimmed = testSKU.trim();
	if(testSKUtrimmed.length < 4) {
		return e.ERROR_SKU_MIN;
	}
	if(testSKUtrimmed.length > 32) {
		return e.ERROR_STRING_LENGTH_32;
	}
	if(testSKUtrimmed.indexOf(' ') > -1) {
		return e.ERROR_SKU_NO_SPACE;
	}

	const bodyRegex = new RegExp(/[^\p{L}\p{N}\-_]+/ug);
	const endRegex = new RegExp(/[^\p{L}\p{N}]+/ug);

	if(bodyRegex.test(testSKUtrimmed)) {
		return e.ERROR_SKU_ALPHANUM;
	}
	if(endRegex.test(testSKUtrimmed[0]) || endRegex.test(testSKUtrimmed[testSKUtrimmed.length - 1])) {
		return e.ERROR_SKU_BEGIN_END_CHAR;
	}

	return '';
}

export function getCouponCodeError(testCode) {

	if(!testCode) {
		return e.ERROR_COUPON_CODE_MISSING;
	}
	let testCodetrimmed = testCode.trim();
	if(testCodetrimmed.length < 4) {
		return e.ERROR_COUPON_CODE_MIN;
	}
	if(testCodetrimmed.length > 16) {
		return e.ERROR_STRING_LENGTH_16;
	}
	if(testCodetrimmed.indexOf(' ') > -1) {
		return e.ERROR_COUPON_CODE_NO_SPACE;
	}

	const bodyRegex = new RegExp(/[^\p{L}\p{N}\-_]+/ug);
	const endRegex = new RegExp(/[^\p{L}\p{N}]+/ug);

	if(bodyRegex.test(testCodetrimmed)) {
		return e.ERROR_COUPON_CODE_ALPHANUM;
	}
	if(endRegex.test(testCodetrimmed[0]) || endRegex.test(testCodetrimmed[testCodetrimmed.length - 1])) {
		return e.ERROR_COUPON_CODE_BEGIN_END_CHAR;
	}

	return '';
}

// Max fixed discount: 100,000,000.00 (currency agnostic)
export function getMaxDiscountAmount(discountType) {
	const defaultMax = 100;

	const maxMapping = {
		[COUPON_DISCOUNT_TYPE_FIXED]: 100000000,
		[COUPON_DISCOUNT_TYPE_PERCENTAGE]: 100,
	}
	return maxMapping[discountType] || defaultMax;
}

export function getDiscountAmountError(testAmount, discountType) {

	if(testAmount === '') { return e.ERROR_DISCOUNT_AMOUNT_MISSING; }

	const maxDiscount = getMaxDiscountAmount(discountType);

	try {

		const intAmount = safeParseInt(Math.round(testAmount));
		
		if(!Number.isInteger(intAmount)) { return e.ERROR_DISCOUNT_AMOUNT_NUMERIC; }
		if(intAmount < 0) { return e.ERROR_DISCOUNT_AMOUNT_MIN; }
		if(intAmount > maxDiscount)  { return e.ERROR_DISCOUNT_AMOUNT_MAX; }

	} catch(err) {
		return e.ERROR_DISCOUNT_AMOUNT_GENERAL;
	}
	return '';
}

export function getManagedSKUError(testSKU) {
	if(!testSKU) {
		return e.ERROR_SKU_MISSING;
	}
	let testSKUtrimmed = testSKU.trim();
	if(testSKUtrimmed.length < 4) {
		return e.ERROR_SKU_MIN;
	}
	if(testSKUtrimmed.length > 32) {
		return e.ERROR_STRING_LENGTH_32;
	}
	if(testSKUtrimmed.indexOf(' ') > -1) {
		return e.ERROR_SKU_NO_SPACE;
	}

	const bodyRegex = new RegExp(/[^\p{L}\p{N}\-#_]+/ug);
	const endRegex = new RegExp(/[^\p{L}\p{N}]+/ug);

	if(bodyRegex.test(testSKUtrimmed)) {
		return e.ERROR_SKU_ALPHANUM;
	}
	if(endRegex.test(testSKUtrimmed[0]) || endRegex.test(testSKUtrimmed[testSKUtrimmed.length - 1])) {
		return e.ERROR_SKU_BEGIN_END_CHAR;
	}
	return '';
}

export function getPermalinkError(testPermalink, reservedPermalinks = []) {
	if(!testPermalink) {
		return e.ERROR_PERMALINK_MISSING;
	}
	let testPermalinkTrimmed = testPermalink.trim();
	if(testPermalinkTrimmed.length === 0) {
		return e.ERROR_PERMALINK_MISSING;
	}
	if(testPermalinkTrimmed.length > 64) {
		return e.ERROR_STRING_LENGTH_64;
	}

	const notAllowed = new RegExp(/[^a-z0-9\-_]+/g);
	if(notAllowed.test(testPermalinkTrimmed)) {
		return e.ERROR_PERMALINK_FORMATTING;
	}

	if(reservedPermalinks.includes(testPermalinkTrimmed)) {
		return e.ERROR_PERMALINK_TAKEN;
	}

	return '';
}

export function getDescriptionError(testDesc, allowEmpty = false) {
	if(allowEmpty && testDesc === '') {
		return '';
	}

	const testDescTrimmed = testDesc.trim();
	if(testDescTrimmed.length === 0) {
		return e.ERROR_DESCRIPTION_MISSING;
	}
	if(testDescTrimmed.length > 8192) {
		return e.ERROR_DESCRIPTION_LENGTH;
	}
	return '';
}

export function getShortDescriptionError(testDesc, allowEmpty = false) {
	if(allowEmpty && testDesc === '') {
		return '';
	}

	const testDescTrimmed = testDesc.trim();
	if(testDescTrimmed.length === 0) {
		return e.ERROR_DESCRIPTION_MISSING;
	}
	if(testDescTrimmed.length > 1024) {
		return e.ERROR_DESCRIPTION_LENGTH;
	}
	return '';
}

export function getMessageError(testDesc, allowEmpty = false) {
	if(allowEmpty && testDesc === '') {
		return '';
	}

	const testDescTrimmed = testDesc.trim();
	if(testDescTrimmed.length === 0) {
		return e.ERROR_MESSAGE_MISSING;
	}
	if(testDescTrimmed.length > 8192) {
		return e.ERROR_MESSAGE_LENGTH;
	}
	return '';
}

// Max price: 100,000,000.00 (currency agnostic)
export function getPriceError(testPrice, allowEmpty = false) {

	if(allowEmpty && testPrice === '') { return ''; }
	if(testPrice === '') { return e.ERROR_PRICE_MISSING; }

	const maxPrice = 100000000*getCurrencyMinorCount();

	try {

		const intPrice = safeParseInt(Math.round(testPrice));
		
		if(!Number.isInteger(intPrice)) { return e.ERROR_PRICE_NUMERIC; }
		if(intPrice < 0) { return e.ERROR_PRICE_MIN; }
		if(intPrice > maxPrice)  { return e.ERROR_PRICE_MAX; }

	} catch(err) {
		return e.ERROR_PRICE_GENERAL;
	}
	return '';
}

// Max cost: 100,000,000.00 (currency agnostic)
export function getCostError(testCost, allowEmpty = false) {

	if(allowEmpty && testCost === '') { return ''; }
	if(testCost === '') { return e.ERROR_COST_MISSING; }

	const maxCost = 100000000*getCurrencyMinorCount();

	try {

		const intCost = safeParseInt(Math.round(testCost));
		
		if(!Number.isInteger(intCost)) { return e.ERROR_COST_NUMERIC; }
		if(intCost < 0) { return e.ERROR_COST_MIN; }
		if(intCost > maxCost)  { return e.ERROR_COST_MAX; }

	} catch(err) {
		return e.ERROR_COST_GENERAL;
	}
	return '';
}

export function getPurchaseAmountError(testMinPurchase, allowEmpty = false) {

	if(allowEmpty && testMinPurchase === '') { return ''; }
	if(testMinPurchase === '') { return e.ERROR_PURCHASE_AMOUNT_MISSING; }

	const maxMinPurchase = 100000000*getCurrencyMinorCount();

	try {

		const intMinPurchase = safeParseInt(Math.round(testMinPurchase));
		
		if(!Number.isInteger(intMinPurchase)) { return e.ERROR_PURCHASE_AMOUNT_NUMERIC; }
		if(intMinPurchase < 0) { return e.ERROR_PURCHASE_AMOUNT_MIN; }
		if(intMinPurchase > maxMinPurchase)  { return e.ERROR_PURCHASE_AMOUNT_MAX; }

	} catch(err) {
		return e.ERROR_PURCHASE_AMOUNT_GENERAL;
	}
	return '';
}

// Max quantity: 1,000,000
export function getQuantityError(testQty, allowEmpty = false) {

	if(allowEmpty && (testQty === '' || testQty === null)) { return ''; }
	if(!allowEmpty && testQty === '') { return e.ERROR_QTY_MISSING; }

	const maxQty = 1000000;

	try {

		const intQty = safeParseInt(testQty);
		
		if(!Number.isInteger(intQty)) { return e.ERROR_QTY_NUMERIC; }
		if(intQty < 0) { return e.ERROR_QTY_MIN; }
		if(intQty > maxQty)  { return e.ERROR_QTY_MAX; }

	} catch(err) {
		return e.ERROR_QTY_GENERAL;
	}
	return '';
}

export function getStreetError(testStreet, allowEmpty = false) {

	if(allowEmpty && testStreet === '') { return ''; }

	const MAX_LENGTH = 190;

	try {
		let testStreetTrimmed = testStreet.trim();
		if(testStreetTrimmed.length === 0) {
			return e.ERROR_ADDRESS_STREET_MISSING;
		}
		if(testStreetTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_STREET_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_STREET_INVALID;
	}
	return '';
}

export function getCityError(testCity, allowEmpty = false) {
	
	if(allowEmpty && testCity === '') { return ''; }

	const MAX_LENGTH = 190;

	try {
		let testCityTrimmed = testCity.trim();
		if(testCityTrimmed.length === 0) {
			return e.ERROR_ADDRESS_CITY_MISSING;
		}
		if(testCityTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_CITY_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_CITY_INVALID;
	}
	return '';
}

export function getRegionError(testRegion, allowEmpty = false) {
	
	if(allowEmpty && testRegion === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		let testRegionTrimmed = testRegion.trim();
		if(testRegionTrimmed.length === 0) {
			return e.ERROR_ADDRESS_REGION_COUNTY_MISSING;
		}
		if(testRegionTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_REGION_COUNTY_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_REGION_COUNTY_INVALID;
	}
	return '';
}

export function getRegionCountyError(testRegion, allowEmpty = false) {
	
	if(allowEmpty && testRegion === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		let testRegionTrimmed = testRegion.trim();
		if(testRegionTrimmed.length === 0) {
			return e.ERROR_ADDRESS_REGION_STATE_MISSING;
		}
		if(testRegionTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_REGION_STATE_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_REGION_STATE_INVALID;
	}
	return '';
}

export function getRegionStateError(testRegion, allowEmpty = false) {
	
	if(allowEmpty && testRegion === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		let testRegionTrimmed = testRegion.trim();
		if(testRegionTrimmed.length === 0) {
			return e.ERROR_ADDRESS_REGION_STATE_MISSING;
		}
		if(testRegionTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_REGION_STATE_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_REGION_STATE_INVALID;
	}
	return '';
}

export function getLevel3ComunaError(testComuna, allowEmpty = false) {
	
	if(allowEmpty && testComuna === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testComunaTrimmed = testComuna.trim();
		if(testComunaTrimmed.length === 0) {
			return e.ERROR_ADDRESS_LEVEL_3_COMUNA_MISSING;
		}
		if(testComunaTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_LEVEL_3_COMUNA_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_LEVEL_3_COMUNA_INVALID;
	}
	return '';
}

export function getTaxIdRutError(testRut, allowEmpty = false) {

	if(allowEmpty && testRut === '') { return ''; }

	const FIXED_LENGTH = 9;

	try {
		const testRutDigits = getDigitString(testRut);
		if(testRutDigits.length === 0) {
			return e.ERROR_ADDRESS_TAX_ID_RUT_MISSING;
		}
		if(testRutDigits.length !== FIXED_LENGTH) {
			return e.ERROR_ADDRESS_TAX_ID_RUT_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_TAX_ID_RUT_INVALID;
	}
	return '';
}

export function getPostCodeError(testPostCode, allowEmpty = false) {
	
	if(allowEmpty && testPostCode === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testPostCodeTrimmed = testPostCode.trim();
		if(testPostCodeTrimmed.length === 0) {
			return e.ERROR_ADDRESS_POSTCODE_MISSING;
		}
		if(testPostCodeTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_POSTCODE_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_POSTCODE_INVALID;
	}
	return '';
}

export function getPostCodeZipError(testPostCode, allowEmpty = false) {
	
	if(allowEmpty && testPostCode === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testPostCodeTrimmed = testPostCode.trim();
		if(testPostCodeTrimmed.length === 0) {
			return e.ERROR_ADDRESS_POSTCODE_ZIP_MISSING;
		}
		if(testPostCodeTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_POSTCODE_ZIP_INVALID;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_POSTCODE_ZIP_INVALID;
	}
	return '';
}

export function getCountryError(testCountry, allowEmpty = false) {
	
	if(allowEmpty && testCountry === '') { return ''; }

	const MAX_LENGTH = 32;

	try {
		const testCountryTrimmed = testCountry.trim();
		if(testCountryTrimmed.length === 0) {
			return e.ERROR_ADDRESS_COUNTRY_MISSING;
		}
		if(testCountryTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ADDRESS_COUNTRY_MISSING;
		}
	} catch(err) {
		return e.ERROR_ADDRESS_COUNTRY_MISSING;
	}
	return '';
}

export function getSquareAccessTokenError(testToken, allowEmpty = false) {
	
	if(allowEmpty && testToken === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testTokenTrimmed = testToken.trim();
		if(testTokenTrimmed.length === 0) {
			return e.ERROR_ACCESS_TOKEN_MISSING;
		}
		if(testTokenTrimmed.length > MAX_LENGTH) {
			return e.ERROR_ACCESS_TOKEN_INVALID;
		}
	} catch(err) {
		return e.ERROR_ACCESS_TOKEN_INVALID;
	}
	return '';
}

export function getSquareWebhookSignatureError(testSign, allowEmpty = false) {
	
	if(allowEmpty && testSign === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testSignTrimmed = testSign.trim();
		if(testSignTrimmed.length === 0) {
			return e.ERROR_WEBHOOK_SIGNATURE_MISSING;
		}
		if(testSignTrimmed.length > MAX_LENGTH) {
			return e.ERROR_WEBHOOK_SIGNATURE_INVALID;
		}
	} catch(err) {
		return e.ERROR_WEBHOOK_SIGNATURE_INVALID;
	}
	return '';
}

export function getSquareApplicationIdError(testApplicationId, allowEmpty = false) {
	
	if(allowEmpty && testApplicationId === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testApplicationIdTrimmed = testApplicationId.trim();
		if(testApplicationIdTrimmed.length === 0) {
			return e.ERROR_APPLICATION_ID_MISSING;
		}
		if(testApplicationIdTrimmed.length > MAX_LENGTH) {
			return e.ERROR_APPLICATION_ID_INVALID;
		}
	} catch(err) {
		return e.ERROR_APPLICATION_ID_INVALID;
	}
	return '';
}

export function getSquareLocationIdError(testLocationId, allowEmpty = false) {
	
	if(allowEmpty && testLocationId === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testLocationIdTrimmed = testLocationId.trim();
		if(testLocationIdTrimmed.length === 0) {
			return e.ERROR_LOCATION_ID_MISSING;
		}
		if(testLocationIdTrimmed.length > MAX_LENGTH) {
			return e.ERROR_LOCATION_ID_INVALID;
		}
	} catch(err) {
		return e.ERROR_LOCATION_ID_INVALID;
	}
	return '';
}

export function getTransbankCommerceCodeError(testCommerceCode, allowEmpty = false) {
	
	if(allowEmpty && testCommerceCode === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testCommerceCodeTrimmed = testCommerceCode.trim();
		if(testCommerceCodeTrimmed.length === 0) {
			return e.ERROR_COMMERCE_CODE_MISSING;
		}
		if(testCommerceCodeTrimmed.length > MAX_LENGTH) {
			return e.ERROR_COMMERCE_CODE_INVALID;
		}
	} catch(err) {
		return e.ERROR_COMMERCE_CODE_INVALID;
	}
	return '';
}

export function getTransbankApiKeyError(testApiKey, allowEmpty = false) {
	
	if(allowEmpty && testApiKey === '') { return ''; }

	const MAX_LENGTH = 128;

	try {
		const testApiKeyTrimmed = testApiKey.trim();
		if(testApiKeyTrimmed.length === 0) {
			return e.ERROR_API_KEY_MISSING;
		}
		if(testApiKeyTrimmed.length > MAX_LENGTH) {
			return e.ERROR_API_KEY_INVALID;
		}
	} catch(err) {
		return e.ERROR_API_KEY_INVALID;
	}
	return '';
}

export function getNotEmptyError(testVal, errorResp = null) {
	const errorValue = errorResp ? errorResp : e.ERROR_VALUE_MISSING;
	return !testVal ? errorValue : '';
}

export function getCCNumberError(testNum) {

	// Validation rules: https://www.w3resource.com/javascript/form/credit-card-validation.php
	// or
	// https://www.regular-expressions.info/creditcard.html

	try {

		if(!testNum) { return e.ERROR_CC_NUMBER_MISSING; }

		const digitString = stringToDigits(testNum);
		if(digitString.length === 0) {
			return e.ERROR_CC_NUMBER_MISSING;
		}

		// Visa
		const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
		if(digitString.match(visaRegex)) { return ''; }

		// Mastercard
		const mcRegex = /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
		if(digitString.match(mcRegex)) { return ''; }

		// Amex
		const amexRegex = /^3[47][0-9]{13}$/;
		if(digitString.match(amexRegex)) { return ''; }

		// Discover
		const discRegex = /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;
		if(digitString.match(discRegex)) { return ''; }

		// Diners Club
		const dcRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
		if(digitString.match(dcRegex)) { return ''; }

		// JCB
		const jcbRegex = /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/;
		if(digitString.match(jcbRegex)) { return ''; }

		// Maestro
		const maestroRegex = /^(5018|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)[0-9]{8,15}$/;
		if(digitString.match(maestroRegex)) { return ''; }
		
	} catch(err) {
		return e.ERROR_CC_NUMBER_INVALID;
	}
	return e.ERROR_CC_NUMBER_INVALID;
}

export function getCVVError(testCVV) {
	
	try {

		if(!testCVV) { return e.ERROR_CC_CVV_MISSING; }

		const digitStringLength = stringToDigits(testCVV).length;
		if(digitStringLength === 0) {
			return e.ERROR_CC_CVV_MISSING;
		}

		if(digitStringLength < 3 || digitStringLength > 4) {
			return e.ERROR_CC_CVV_INVALID;
		}
		
	} catch(err) {
		return e.ERROR_CC_NUMBER_INVALID;
	}
	return '';
}

export function getDateError(testDate, allowEmpty = false) {

	if(allowEmpty && !testDate) { return ''; }

	try {

		if(!testDate) {
			return e.ERROR_DATE_MISSING;
		}

		const newDate = new Date(testDate);
		
		if(newDate === 'Invalid Date' || isNaN(newDate)) {
			return e.ERROR_DATE_INVALID;
		}

		// Should throw error if not proper Date object
		newDate.getTime();

		return '';

	} catch(err) {
		return e.ERROR_DATE_INVALID;
	}
}

export function getFutureDateError(testDate, allowEmpty = false, allowToday = true) {

	if(allowEmpty && !testDate) { return ''; }

	try {

		if(!testDate) {
			return e.ERROR_DATE_MISSING;
		}

		const newDate = new Date(testDate);
		
		if(newDate === 'Invalid Date' || isNaN(newDate)) {
			return e.ERROR_DATE_INVALID;
		}

		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
		
		if(allowToday && newDate < today) {
			return e.ERROR_DATE_PAST;
		} else if(!allowToday && newDate < tomorrow) {
			return e.ERROR_DATE_PAST;
		}

		return '';

	} catch(err) {
		return e.ERROR_DATE_INVALID;
	}
}

export function getTimeError(hour, minute) {
	try {

		const hr = safeParseInt(hour);
		const min = safeParseInt(minute);
		const now = new Date();

		const testDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hr, min);
		if(testDate === 'Invalid Date' || isNaN(testDate)) {
			return e.ERROR_TIME_INVALID;
		}

		return '';

	} catch(err) {
		return e.ERROR_TIME_INVALID;
	}
}

export function getEventRegistrationLimitError(testLimit, allowEmpty = false) {

	const REGISTRATION_MIN = allowEmpty ? 0 : 1;
	const REGISTRATION_MAX = 100000;

	if(allowEmpty && !testLimit) { return ''; }

	try {

		if(!testLimit) {
			return e.ERROR_EVENT_REGISTRATION_LIMIT_MISSING;
		}

		const limitInt = safeParseInt(testLimit);
		
		if(limitInt < REGISTRATION_MIN || limitInt > REGISTRATION_MAX) {
			return e.ERROR_EVENT_REGISTRATION_LIMIT_INVALID;
		}

		return '';

	} catch(err) {
		return e.ERROR_EVENT_REGISTRATION_LIMIT_INVALID;
	}
}


export function getUsageCountError(testCount, allowEmpty = false) {

	const COUNT_MIN = 1;
	const COUNT_MAX = 100000;

	if(allowEmpty && !testCount) { return ''; }

	try {

		if(!testCount) {
			return e.ERROR_USAGE_COUNT_MISSING;
		}

		const countInt = safeParseInt(testCount);
		
		if(countInt < COUNT_MIN || countInt > COUNT_MAX) {
			return e.ERROR_USAGE_COUNT_INVALID;
		}

		return '';

	} catch(err) {
		return e.ERROR_USAGE_COUNT_INVALID;
	}
}

// Max credit absolute value (10000000)
export function getStoreCreditAmountError(testAmount) {

	const CREDIT_MAX = 10000000;

	if(testAmount === '') { return e.ERROR_STORE_CREDIT_AMOUNT_MISSING; }

	try {

		const intAmount = safeParseInt(Math.round(testAmount));
		
		if(!Number.isInteger(intAmount)) { return e.ERROR_STORE_CREDIT_AMOUNT_NUMERIC; }
		if(intAmount < -1*CREDIT_MAX) { return e.ERROR_STORE_CREDIT_AMOUNT_MIN; }
		if(intAmount > CREDIT_MAX)  { return e.ERROR_STORE_CREDIT_AMOUNT_MAX; }

	} catch(err) {
		return e.ERROR_STORE_CREDIT_AMOUNT_GENERAL;
	}
	return '';
}


// Max weight: 100,000,000.00 (unit agnostic)
export function getWeightError(testWeight, allowEmpty = false) {

	if(allowEmpty && testWeight === '') { return ''; }
	if(testWeight === '') { return e.ERROR_WEIGHT_MISSING; }

	const maxWeight = 100000000;

	try {

		const intWeight = safeParseInt(Math.round(testWeight));
		
		if(!Number.isInteger(intWeight)) { return e.ERROR_WEIGHT_NUMERIC; }
		if(intWeight < 0) { return e.ERROR_WEIGHT_MIN; }
		if(intWeight > maxWeight)  { return e.ERROR_WEIGHT_MAX; }

	} catch(err) {
		return e.ERROR_WEIGHT_GENERAL;
	}
	return '';
}


// Max distance: 100,000,000.00 (unit agnostic)
export function getDistanceError(testDistance, allowEmpty = false) {

	if(allowEmpty && testDistance === '') { return ''; }
	if(testDistance === '') { return e.ERROR_DISTANCE_MISSING; }

	const maxDistance = 100000000;

	try {

		const intDistance = safeParseInt(Math.round(testDistance));
		
		if(!Number.isInteger(intDistance)) { return e.ERROR_DISTANCE_NUMERIC; }
		if(intDistance < 0) { return e.ERROR_DISTANCE_MIN; }
		if(intDistance > maxDistance)  { return e.ERROR_DISTANCE_MAX; }

	} catch(err) {
		return e.ERROR_DISTANCE_GENERAL;
	}
	return '';
}


export function getMarkupPercentError(testAmount, allowEmpty = false) {

	if(allowEmpty && testAmount === '') { return ''; }
	if(testAmount === '') { return e.ERROR_MARKUP_PERCENT_MISSING; }

	// Allow markups over 100%, but let's not go crazy
	const maxAmount = 300;

	try {

		const intAmount = safeParseInt(Math.round(testAmount));
		
		if(!Number.isInteger(intAmount)) { return e.ERROR_MARKUP_PERCENT_NUMERIC; }
		if(intAmount < 0) { return e.ERROR_MARKUP_PERCENT_MIN; }
		if(intAmount > maxAmount)  { return e.ERROR_MARKUP_PERCENT_MAX; }

	} catch(err) {
		return e.ERROR_MARKUP_PERCENT_GENERAL;
	}
	return '';
}


export function getPercentError(testPercent, allowEmpty = false, min = 0, max = 100) {

	if(allowEmpty && testPercent === '') { return ''; }
	if(testPercent === '') { return e.ERROR_PERCENT_MISSING; }

	const minAmount = min;
	const maxAmount = max;

	try {

		const intPercent = safeParseInt(Math.round(testPercent));
		
		if(!Number.isInteger(intPercent)) { return e.ERROR_PERCENT_NUMERIC; }
		if(intPercent < minAmount) { return e.ERROR_PERCENT_MIN; }
		if(intPercent > maxAmount)  { return e.ERROR_PERCENT_MAX; }

	} catch(err) {
		return e.ERROR_PERCENT_GENERAL;
	}
	return '';
}


export function getGenericStringError(testString, allowEmpty = false, min = 0, max = 100) {

	if(allowEmpty && testString === '') { return ''; }
	if(testString === '') { return e.ERROR_STRING_MISSING; }

	try {

		const trimmedString = testString.trim();

		if(trimmedString.length < min) { return e.ERROR_STRING_MIN; }
		if(trimmedString.length > max)  { return e.ERROR_STRING_MAX; }

	} catch(err) {
		return e.ERROR_STRING_GENERAL;
	}
	return '';
}


export function getGenericNumberError(testValue, config = {}) {

	const allowEmpty = config.allowEmpty || false;
	const min = config.min || 0;
	const max = config.max || 10000000;
	const maxError = config.maxError || e.ERROR_NUMBER_MAX; 

	if(allowEmpty && testValue === '') { return ''; }
	if(testValue === '') { return e.ERROR_NUMBER_MISSING; }

	try {

		const numericValue = safeParseFloat(testValue);

		if(numericValue < min) { return e.ERROR_NUMBER_MIN; }
		if(numericValue > max)  { return maxError; }

	} catch(err) {
		return e.ERROR_NUMBER_GENERAL;
	}
	return '';
}


export function getUrlError(urlString, allowEmpty = false) {

	if(allowEmpty && urlString === '') { return ''; }
	if(urlString === '') { return e.ERROR_URL_MISSING; }

  try {
    // eslint-disable-next-line
    const url = new URL(urlString);
  } catch (err) {
  	return e.ERROR_URL_GENERAL;
  }
  return '';
}


export function getRgbError(hexString, allowEmpty = false) {

	if(allowEmpty && hexString === '') { return ''; }
	if(hexString === '') { return e.ERROR_RGB_MISSING; }
	
	const regex = /^([a-fA-F0-9]{6})$/;

	if(!regex.test(hexString)) {
		return e.ERROR_RGB_GENERAL;
	}
	return '';
}




























