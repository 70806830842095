import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';


let allActionCreators = Object.assign({});


export class LocalImage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      imageURI: null,
    };
  }

  componentDidMount() {
    if(this.props.imgFile) {
      this.readURI(this.props.imgFile);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.imgFile && this.props.imgFile) {
      if(prevProps.imgFile.name !== this.props.imgFile.name || prevProps.imgFile.size !== this.props.imgFile.size) {
        this.readURI(this.props.imgFile);
      }
    }
  }

  getCaption() {
    if(!this.props.caption) {
      return tx.TX_ALT_PRODUCT_IMAGE;
    }
    return this.props.caption;
  }
  
  buildImgTag() {
    let imgTag = null;    
    if(this.state.imageURI !== null) {
      imgTag = (<img className='localImageImg' src={this.state.imageURI} alt={this.props.t(this.getCaption())} />);
    }
    return imgTag;
  }
  
  readURI(fl) {

    if(!fl) { return null; }

    let reader = new FileReader();
    reader.onload = function(ev) {
      this.setState({
        imageURI: ev.target.result,
      });
    }.bind(this);
    reader.readAsDataURL(fl);
  }
  
  render() {
    return this.buildImgTag();
  }
}


function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(LocalImage));