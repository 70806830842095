import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { formatPrice } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import Checkbox from '../../../Input/Checkbox';

import '../../style/_vieworder.scss';

import * as paymentsActionCreators from '../../../../actions/payments';
const allActionCreators = Object.assign({}, paymentsActionCreators);

export class OrderModalRefundStepPayment extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalRefundStepPayment OrderModalStep'}>
      <div className='omsLiner'>

        <div className='refundConfigWrapper'>
          <div className='refundConfig'>

            <div className='refundTotalWrapper'>
              <div className={'orderTotalWrapper totalWrapper'}>
                <div className='totalLabel'>{t(tx.TX_ORDER_TOTAL_REFUND)}</div>
                <div className='totalValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.refundTotal, { addTags: true, language: this.getLanguage() }) }} />
              </div>
            </div>

            <div className='refundConfigLine'>
              <div className='omFieldWrapper'>
                <div className={'omFieldLabel omOptional'}>{t(tx.TX_ORDER_ADD_NOTE)}</div>
                <div className='omInputWrapper'>
                  <textarea
                    type='text'
                    className={this.props.errorNote ? 'InputError' : ''}
                    value={this.props.inputNote}
                    onChange={this.props.changeNote.bind(this)}
                    onBlur={this.props.validateNote.bind(this)}
                    placeholder={t(tx.TX_OPTIONAL)}
                    maxLength={190}></textarea>
                </div>
                {this.props.errorNote ?
                  <div className={'FieldError'}>{t(this.props.errorNote)}</div> :
                  null
                }
              </div>
            </div>
            <div className='refundConfigLine'>
              <div className='configInputWrapper'>
                <div className='configCheckboxWrapper'>
                  <Checkbox 
                    adminTheme={true}
                    name={'all-credit'}
                    id={`refund-modal-store-credit-only`}
                    value={'all-credit'}
                    checked={this.props.inputStoreCreditOnly}
                    ambiguousCheck={false}
                    onChange={this.props.toggleStoreCreditOnly.bind(this)} />
                </div>
              </div>
              <label className='configLabelWrapper' htmlFor={`refund-modal-store-credit-only`}>{t(tx.TX_ORDER_REFUND_WITH_STORE_CREDIT)}</label>
            </div>
          </div>
        </div>

      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalRefundStepPayment));