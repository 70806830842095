import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../constants/images';
import { MEDIA_DATA_TYPE_FILE } from '../../../constants/media';
import * as tx from '../../../constants/strings';

import { getCurrencySymbol } from '../../../utils/currency';
import { formatPrice } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import LocalImage from '../../Image/LocalImage';
import StaticImage from '../../Image/StaticImage';

let allActionCreators = Object.assign({});

export class AddProductSidebar extends Component {

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.inventoryObj === null && this.props.inventoryObj !== null) {
      this.sidebarImageLoaded();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  saveAction() {
    this.props.saveProduct();
  }

  sidebarImageLoaded() {
    if(this.props.imageLoaded) {
      this.props.imageLoaded();
    }
  }

  getProductImage() {
    if(!this.props.productObj || !this.props.productObj.productLine) {
      return <div className='genericProduct'>
              <StaticImage 
                imgObj={IMG_GENERIC_PRODUCT}
                onImgLoad={this.sidebarImageLoaded.bind(this)} />
             </div>;
    }

    if(this.props.productObj.foreignModel) {

      const imgProps = {
        onLoadLow: this.sidebarImageLoaded.bind(this),
      }

      return <div className='foreignProduct'>
              {this.props.productObj.foreignModel.getPrimaryImage(imgProps)}
             </div>;
    } else if(this.props.mediaArray && this.props.mediaArray.length > 0 && this.props.mediaType === MEDIA_DATA_TYPE_FILE) {
      return <div className='genericProduct'>
              <LocalImage
                imgFile={this.props.mediaArray[0].file} />
             </div>;
    } else {
      return <div className='genericProduct'>
                <StaticImage imgObj={IMG_GENERIC_PRODUCT} />
             </div>;
    }
  }

  // For magic, returns set name
  getSkuValue() {
    if(this.props.productObj && this.props.productObj.productSet) {
      return this.props.productObj.productSet.name;
    }
    return this.props.productObj && this.props.productObj.sku ? this.props.productObj.sku : this.props.t(tx.TX_SKU);
  }

  shouldAllowSave() {
    if(!this.props.productObj || !this.props.inventoryObj) { return false; }
    return this.props.productObj.isValid() && this.props.inventoryObj.isValid();
  }

  getProductNameNonCompound() {
    return this.props.productObj && this.props.productObj.name ? this.props.productObj.name : this.props.t(tx.TX_INV_ADD_PRODUCT_NEW_PRODUCT);
  }

  render() {

    const {t} = this.props;

    return <div className={'AddProductSidebar'}>
      <div className='sidebarImage'>{this.getProductImage()}</div>
      <div className={'sbTitleRow EllipsisElement'}>
        {this.props.productObj && this.props.productObj.foreignModel && this.props.productObj.foreignModel.compoundName ?
          <>
            {this.props.productObj.foreignModel.compoundName.map((name, i) => {
              return <div key={i} className={`titleElement${i}`}>{name}</div>;
            })}
          </> :
          <>{this.getProductNameNonCompound()}</>
        }
      </div>
      <div className='sbSubtitleRow'>
        <div className='skuElement'>
          {this.getSkuValue()}
        </div>
      </div>
      {this.props.inventoryObj ?
        <div className='conditionsWrapper'>
          <div className={'sbConditionsHeader condRow'}>
            {this.props.inventoryObj.isSealed === false ?
              <div className={'headerElem conditionElem'}>{t(tx.TX_CONDITION)}</div> :
              null
            }
            <div className={'headerElem qtyElem'}>{t(tx.TX_QUANTITY)}</div>
            <div className={'headerElem priceElem'}>{t(tx.TX_PRICE)}</div>
          </div>
          <div className={'sbPriceRow condRow'}>
            {this.props.inventoryObj.isSealed === false ?
              <div className={'bodyElem conditionElem EllipsisElement'}>
                {this.props.inventoryObj.condition ? t(this.props.inventoryObj.condition.name) : ''}
              </div> :
              null
            }
            <div 
              className={'bodyElem qtyElem'}
              dangerouslySetInnerHTML={{__html: this.props.inventoryObj.totalQuantity !== '' && this.props.inventoryObj.totalQuantity !== null ? parseInt(this.props.inventoryObj.totalQuantity).toLocaleString(this.getLanguage()) : '&nbsp;'}} />
            {this.props.inventoryObj.sellPrice ?
              <div className={'bodyElem priceElem'}>
                <div className='currencyMark'>{getCurrencySymbol()}</div>
                <div className='priceValue'>{formatPrice(this.props.inventoryObj.sellPrice, { language: this.getLanguage(), omitSymbol: true })}</div>
              </div> :
              null
            }
          </div>
        </div> :
        null
      }
      <div className='actionsWrapper'>
        <button 
          type='button' 
          onClick={this.saveAction.bind(this)}
          className={'sbAction'}
          disabled={!this.shouldAllowSave()}>{t(tx.TX_SAVE)}</button>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductSidebar));