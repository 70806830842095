

import {
	WEIGHT_UNIT_G,
	DISTANCE_UNIT_MM,
} from '../constants/measurements';
import { STORAGE_STORE } from '../constants/storage';
import {
	TX_CAL_DAY_SUNDAY,
	TX_CAL_DAY_MONDAY,
	TX_CAL_DAY_TUESDAY,
	TX_CAL_DAY_WEDNESDAY,
	TX_CAL_DAY_THURSDAY,
	TX_CAL_DAY_FRIDAY,
	TX_CAL_DAY_SATURDAY,
	TX_null,
} from '../constants/strings';

import { Address } from './geographies';

import { isVarString } from '../utils/general';
import { 
	getDistanceUnitFromKey,
	getWeightUnitFromKey, 
} from '../utils/measurements';

import * as storage from '../persistance/storage';


export class Store {

	constructor(props) {

		// As props, should accept another User object or a server resp
		if(!props) { 
			props = {}; 
		}

		const addressObj = {
			firstName: props.name || '',
			street1: props.street1 || '',
			street2: props.street2 || '',
			city: props.city || '',
			regionValue: props.regionValue || props.region || props.state || '',
			postCode: props.postCode || props.post_code || props.zipcode || '',
			country: props.country || '',
			placeId: props.placeId || props.place_id || '',
		}

		const defaultDistanceUnitValue = props.defaultDistanceUnit || props.default_distance_unit || DISTANCE_UNIT_MM;
		const defaultWeightUnitValue = props.defaultWeightUnit || props.default_weight_unit || WEIGHT_UNIT_G;

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
		this.email = props.email || props.email_address || '';
		this.phone = props.phone || props.phone_number || '';
		this.defaultCurrency = props.defaultCurrency || props.default_currency || '';
		this.timezone = props.timezone || '';
		this.countryCode = props.country || '';
		
		this.defaultDistanceUnit = isVarString(defaultDistanceUnitValue) ? getDistanceUnitFromKey(defaultDistanceUnitValue) : defaultDistanceUnitValue;
		this.defaultWeightUnit = isVarString(defaultWeightUnitValue) ? getWeightUnitFromKey(defaultWeightUnitValue) : defaultWeightUnitValue;

		this.address = new Address(props.address || addressObj);
		this.hours = [];
	}

	saveLocal() {
		storage.put(STORAGE_STORE, this.toJson());
	}

	static getLocal() {
		const storeJson = storage.get(STORAGE_STORE);
		if(!storeJson) {
			return new Store();
		}
		return new Store(storeJson);
	}

	mapsLink() {
		return this.address.placeId ? `https://www.google.com/maps/place/?q=place_id:${this.address.placeId}` : `https://maps.google.com`;
	}

	// schedule returns an object of days, each with a StoreDaySchedule or null
	// this.hours is an unsorted array of StoreDaySchedule objects returned from server
	get schedule() {
		const scheduleObj = {};
		const week = StoreDaySchedule.WEEK;

		for(const day of week) {
			scheduleObj[day] = this.daySchedule(day);
		}
		return scheduleObj;
	}

	daySchedule(day) {
		const dayInt = StoreDaySchedule.dayInteger(day);
		if(dayInt === 0) { return null; }

		for(const schedule of this.hours) {
			if(dayInt === schedule.dayInt) {
				return schedule;
			}
		}
		return null;
	}

	toJson() {
		return {
			publicUuid: this.publicUuid,
			name: this.name,
			email: this.email,
			phone: this.phone,
			defaultCurrency: this.defaultCurrency,
			timezone: this.timezone,
			street1: this.street1,
			street2: this.street2,
			city: this.city,
			regionValue: this.regionValue,
			postCode: this.postCode,
			country: this.country,
			placeId: this.placeId,
			defaultDistanceUnit: this.defaultDistanceUnit,	
			defaultWeightUnit: this.defaultWeightUnit,	
		};
	}
}


export class StoreDaySchedule {

	constructor(props) {

		// As props, should accept another User object or a server resp
		if(!props) { 
			props = {}; 
		}

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
		this.comments = props.comments || '';
		this.startTime = props.startTime || props.start_time || '';
		this.endTime = props.endTime || props.end_time || '';
		this.dayInt = parseInt(props.dayInt) || parseInt(props.days_of_week) || '';
	}

	static get MON() { return 'M'; }
	static get TUES() { return 'T'; }
	static get WED() { return 'W'; }
	static get THUR() { return 'R'; }
	static get FRI() { return 'F'; }
	static get SAT() { return 'Sa'; }
	static get SUN() { return 'Su'; }

	static get WEEK() {
		return [ StoreDaySchedule.MON, StoreDaySchedule.TUES, StoreDaySchedule.WED, StoreDaySchedule.THUR, StoreDaySchedule.FRI, StoreDaySchedule.SAT, StoreDaySchedule.SUN ];
	}

	static get DAY_INT_MAPPING() {
		return {
			[StoreDaySchedule.MON]: 1,
			[StoreDaySchedule.TUES]: 2,
			[StoreDaySchedule.WED]: 4,
			[StoreDaySchedule.THUR]: 8,
			[StoreDaySchedule.FRI]: 16,
			[StoreDaySchedule.SAT]: 32,
			[StoreDaySchedule.SUN]: 64,
		};
	}

	static get DAY_COPY_MAPPING() {
		return {
			[StoreDaySchedule.MON]: TX_CAL_DAY_MONDAY,
			[StoreDaySchedule.TUES]: TX_CAL_DAY_TUESDAY,
			[StoreDaySchedule.WED]: TX_CAL_DAY_WEDNESDAY,
			[StoreDaySchedule.THUR]: TX_CAL_DAY_THURSDAY,
			[StoreDaySchedule.FRI]: TX_CAL_DAY_FRIDAY,
			[StoreDaySchedule.SAT]: TX_CAL_DAY_SATURDAY,
			[StoreDaySchedule.SUN]: TX_CAL_DAY_SUNDAY,
		};
	}

	static dayCopy(dayCode) {
		return StoreDaySchedule.DAY_COPY_MAPPING[dayCode] || TX_null;
	}

	static get day() {
		if(!this.dayInt) { return null; }
		return this.dayCode(this.dayInt);
	}

	static dayInteger(dayCode) {
		return StoreDaySchedule.DAY_INT_MAPPING[dayCode] || 0;
	}

	static dayCode(dayInt) {
		// Right now we don't support multi-day schedules on frontend (ie dayInt=3 would be Mon and Tues)
		// In the future, if we do this, we'll have to update
		const mapping = StoreDaySchedule.DAY_INT_MAPPING;
		for(const k in mapping) {
			if(mapping[k] === dayInt) {
				return k;
			}
		}
		return null;
	}

	toJson() {
		return {
						
		};
	}
}



















