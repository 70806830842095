import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_OPEN_EXTERNAL } from '../../constants/icons';
import * as tx from '../../constants/strings';
import { 
  URL_ACCOUNT_ORDERS,
  URL_ACCOUNT_ORDERS_BUYLIST, 
} from '../../constants/urls';

import { 
  dateShort,
  formatServerError,
  stringFormat,
} from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';

import AccountTitle from './blocks/AccountTitle';
import CartSummary from '../Cart/CartSummary';
import { Icon } from '../Icons/Icon';
import LoadingIcon from '../Icons/LoadingIcon';
import MiniCart from '../Cart/MiniCart';

import './style/_account.scss';

import * as orderActionCreators from '../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class AccountOrderView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestPending: true,
      requestError: null,
      responseObject: null,

      reviewMode: false,
    };

    // Controllers
    this.controller = null;
  }

  componentDidMount() {
    this.fetchOrder();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async fetchOrder() {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
    });

    // Get order details
    if(!this.props.match.params.orderUUID) {
      return null;
    }

    const orderResp = await this.props.ordersFetchUserOrder(this.props.match.params.orderUUID, controller.signal)
      .catch((errResp) => {

        if(controller.signal.aborted) { return null; }
        console.error(errResp);

        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responseObject: null,
        });
      });

    if(!orderResp) {
      return null;
    }

    this.setState({
      requestPending: false,
      requestError: null,
      responseObject: orderResp,
    });
  }

  getPageTitle() {
    if(this.state.responseObject && this.state.responseObject.displayNumber) {
      return tx.TX_ORDER_ORDER_NUMBER_DYNAMIC;
    }
    return tx.TX_ORDER;
  }

  getPageTitleData() {
    if(this.state.responseObject && this.state.responseObject.displayNumber) {
      return {
        orderNum: this.state.responseObject.displayNumber,
      };
    }
    return null;
  }

  getShipment() {
    if(!this.state.responseObject || !this.state.responseObject.shipments || !this.state.responseObject.shipments.length) { return null; }
    for(const sh of this.state.responseObject.shipments) {
      if(!sh.isCancelled) {
        return sh;
      }
    }
    return null;
  }

  render() {

    const {t} = this.props;

    return <div className={'AccountOrderView AccountPage'}>
      <AccountTitle
        title={this.getPageTitle()}
        titleData={this.getPageTitleData()}
        breadcrumbs={[
          {
            url: this.props.isBuylist ? URL_ACCOUNT_ORDERS_BUYLIST : URL_ACCOUNT_ORDERS,
            title: tx.TX_ORDERS,
          },
        ]} />
      <div className='accountBody'>
        <div className={'detailsBlock accountBlock'}>
          <div className='accountBlockLiner'>
            <div className='accountBlockTitle'>{t(tx.TX_ORDER_ORDER_DETAILS)}</div>
            <div className='accountBlockBody'>
              {this.state.responseObject ?
                <div className='detailsWrapper'>

                  <div className={'detailInnerBlock metaBlock'}>

                    <div className='detailBlockTitle'>{t(tx.TX_DATE)}</div>
                    <div className='detailBlockBody'>
                      <div className='orderDateLine'>{dateShort(this.state.responseObject.date, this.getLanguage())}</div>
                    </div>

                    <div className='detailBlockTitle'>{t(tx.TX_STATUS)}</div>
                    <div className='detailBlockBody'>
                      <div className='orderStatusLine'>{t(this.state.responseObject.status.nameCustomer)}</div>
                    </div>

                  </div>

                  <div className={'detailInnerBlock shippingBlock'}>
                    {this.state.responseObject.hasShippingMethod() ?
                      <>
                        <div className='detailBlockTitle'>{t(tx.TX_SHIPPING_METHOD)}</div>
                        <div className='detailBlockBody'>
                          <div className='methodName'>{this.state.responseObject.shippingMethod.name}</div>
                          <div className='methodDescription'>{this.state.responseObject.shippingMethod.description}</div>
                          {this.getShipment() && this.getShipment().trackingNumber ?
                            <div className='methodDataBlock'>
                              <div className='methodDataLabel'>{t(tx.TX_ORDER_SHIPMENT_TRACKING_NUMBER)}</div>
                              <div className='methodDataValueWrapper'>
                                <a 
                                  target='_blank' 
                                  rel='noreferrer'
                                  href={stringFormat(this.getShipment().carrier.trackingUrl, { trackingNumber: this.getShipment().trackingNumber })}>
                                  <div className='methodDataValueIcon'>{this.getShipment().trackingNumber}</div>
                                  <div className='methodDataIcon'>
                                    <Icon value={ICON_OPEN_EXTERNAL} iconClass='trackingIcon' />
                                  </div>
                                </a>
                              </div>
                            </div> :
                            null
                          }
                        </div>
                      </> :
                      null
                    }
                    <div className='detailBlockTitle'>{t(this.props.isBuylist ? tx.TX_CHECKOUT_BUYLIST_RETURN_ADDRESS : tx.TX_SHIPPING_ADDRESS)}</div>
                    <div className='detailBlockBody'>
                      <div 
                        className='addressBlock'
                        dangerouslySetInnerHTML={{ __html: this.state.responseObject.shippingAddress.format({ addTags: true }) }} />
                    </div>
                  </div>

                  <div className={'detailInnerBlock paymentBlock'}>
                    {this.state.responseObject.hasPaymentRecord() ?
                      <>
                        <div className='detailBlockTitle'>{t(tx.TX_PAYMENT_METHOD)}</div>
                        <div className='detailBlockBody'>
                          <div 
                            className='paymentRecordLine'
                            dangerouslySetInnerHTML={{ __html: this.state.responseObject.paymentRecord.format(t, { addTags: true, language: this.getLanguage() }) }} />                            
                        </div>
                      </> :
                      null
                    }
                    {this.state.responseObject.billingAddress ?
                      <>
                        <div className='detailBlockTitle'>{t(tx.TX_BILLING_ADDRESS)}</div>
                        <div className='detailBlockBody'>
                          <div 
                            className='addressBlock'
                            dangerouslySetInnerHTML={{ __html: this.state.responseObject.billingAddress.format({ addTags: true }) }} />                            
                        </div>
                      </> :
                      null
                    }
                  </div>
                </div> :
                null
              }
            </div>
          </div>
          <div className='accountBlockPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
            <div className='accountBlockPendingScreen'></div>
            <div className='accountBlockPendingWrapper'>
              <LoadingIcon />
            </div>
          </div>
        </div>
        <div className={'itemsBlock accountBlock'}>
          <div className='accountBlockLiner'>
            <div className='accountBlockTitle'>{t(tx.TX_ORDER_ORDER_ITEMS)}</div>
            <div className='accountBlockBody'>
              {this.state.responseObject ?
                <div className='itemsWrapper'>
                  {this.state.reviewMode ?
                    <>
                      <div className='cartReviewViewWrapper'>
                        
                      </div>
                      <div className='summaryReviewViewWrapper'>
                        
                      </div>
                    </> :
                    <>
                      <div className='cartViewWrapper'>
                        <MiniCart
                          isBuylist={this.props.isBuylist}
                          hideDisclaimer={true}
                          order={this.state.responseObject}
                          cartOverride={this.state.responseObject ? (this.props.isBuylist ? this.state.responseObject.buylistCart : this.state.responseObject.cart) : null}
                          readOnly={true} />
                      </div>
                      <div className='summaryViewWrapper'>
                        <div className='summaryTitle'>
                          <div className='summaryTitleValue'>{t(tx.TX_ORDER_ORDER_SUMMARY)}</div>
                        </div>
                        <CartSummary
                          isBuylist={this.props.isBuylist}
                          order={this.state.responseObject}
                          cartOverride={this.state.responseObject ? this.state.responseObject.orderCart : null}
                          readOnly={true} />
                      </div>
                    </>
                  }
                </div> :
                null
              }
            </div>
          </div>
          <div className='accountBlockPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
            <div className='accountBlockPendingScreen'></div>
            <div className='accountBlockPendingWrapper'>
              <LoadingIcon />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountOrderView));