import React from 'react';

import LoadingIcon from '../Icons/LoadingIcon';

import styles from './style/LayoutComponent.module.scss';

export const LayoutComponent = (props) => {
  
  // Props
  const {
    adminTheme,
    layout,
    layoutLoading,
  } = props;

  const getTypeComponent = () => {
    const LayoutType = layout ? layout.typeComponent : null;
    return LayoutType ? <LayoutType layout={layout} adminTheme={adminTheme} /> : null;
  }

  return (
    <div className={`${styles.LayoutComponent} ${adminTheme ? styles.titanTheme : ''}`}>
      <div className={styles.layoutLiner}>
        {!layout || layoutLoading ?
          <div className={styles.layoutLoading}>
            <div className={styles.loadingIconWrapper}>
              <LoadingIcon />
            </div>
          </div> :
          <>
            {getTypeComponent()}
          </>
        }
      </div>
    </div>
  );
};

export default LayoutComponent;




