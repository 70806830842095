import React, { Component } from 'react';

import AutocompleteNull from './autocomplete/AutocompleteNull';

import './style/_autocomplete.scss';

const autocompleteFilterProps = [
	'adminTheme',
	'className',
	'inputClass',
	'options',
	'onSelect',
	'lastQuery',
	'onChange',
	'nullComponent',
];

const omit = (obj, omitKeys) =>
	Object.keys(obj).reduce((result, key) => {
		if(!omitKeys.includes(key)) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export default class AutocompleteField extends Component {

	constructor(props) {
    super(props);

    this.OPEN_DELAY = 200;

    this.state = {
    	autocompleteActive: false,
      autocompleteClosable: false,

      navIndex: -1,
    };

    this.closeTimeout = null;

    this.autocompleteWrapperRef = React.createRef();

    this.checkClick = this.checkClick.bind(this);
    this.checkKeypress = this.checkKeypress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
    window.addEventListener('keydown', this.checkKeypress, false);
  }

  componentDidUpdate(prevProps, prevState) {
  	if(prevProps.options === null && this.props.options !== null) {
  		this.openAutocomplete();
  	} else if(prevProps.options !== null && this.props.options === null) {
  		this.closeAutocomplete();
  	}
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
    window.removeEventListener('keydown', this.checkKeypress, false);

    if(this.closeTimeout) {
    	clearTimeout(this.closeTimeout);
    }
  }

  checkClick(evt) {
    if(this.state.autocompleteActive && this.state.autocompleteClosable) {
      let targetElement = evt.target;
      do {
        if(this.autocompleteWrapperRef && targetElement === this.autocompleteWrapperRef.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeAutocomplete();
    }
  }

  checkKeypress(evt) {
    if(evt) {
      switch(evt.key) {
        case 'ArrowUp':
        	// evt.preventDefault();
          this.setState({ navIndex: Math.max(this.state.navIndex - 1, 0) });
          break;
        case 'ArrowDown':
        	// evt.preventDefault();
          this.setState({ navIndex: Math.min(this.state.navIndex + 1, this.props.options ? this.props.options.length - 1 : 0) });
          break;
        case 'Enter':
        	// evt.preventDefault();
          this.handleSelect(this.state.navIndex);
          break;
        case 'Escape':
        	// evt.preventDefault();
          this.closeAutocomplete();
          break;
        default:
          break;
      }
    }
  }

  openAutocomplete() {
  	this.setState({
      autocompleteActive: true,
      autocompleteClosable: false,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({ autocompleteClosable: true });
      }, this.OPEN_DELAY);
    });
  }

  closeAutocomplete() {
    this.setState({ 
      autocompleteActive: false,
      autocompleteClosable: false,
      navIndex: -1,
    });
  }

	handleChange(evt) {
		if(this.props.onChange) {
			this.props.onChange(evt);
		}
		if(this.props.options !== null && !this.state.autocompleteActive) {
			this.openAutocomplete();
		}
	}

  handleSelect(idx) {
  	if(this.props.onSelect) {
    	this.props.onSelect(idx);
    }
  }

  mouseEnterAction(idx) {
  	if(idx !== this.state.navIndex) {
  		this.setState({ navIndex: idx });
  	}
  }
	
	mouseLeaveAction(idx) {
		if(idx === this.state.navIndex) {
			this.setState({ navIndex: -1 });
		}
	}

	render() {
		return (<div className={`AutocompleteField ${this.props.adminTheme ? 'adminTheme' : ''} ${this.props.className || ''}`} ref={this.autocompleteWrapperRef}>
			<div className='autocompleteLiner'>
				<div className='autocompleteInputWrapper'>
			    <input 
			    	type='text' 
			    	placeholder={this.props.placeholder || ''}
			    	className={`autocompleteInput ${this.props.inputClass || ''}`}
			    	value={this.props.value}
			    	onChange={this.handleChange.bind(this)}
			    	{...omit(this.props, autocompleteFilterProps)} />
		    </div>
		    <div className={`optionsWrapper ${this.state.autocompleteActive ? 'open' : ''}`}>
		    	{this.props.options !== null ?
			    	<div className='optionsLiner'>
			    		{this.props.options.length ?
			    			<div className='optionsList'>
			    				{this.props.options.map((op, i) => {
			    					return <div 
			    										key={i} 
			    										className={`optionElementWrapper ${this.state.navIndex === i ? 'hovered' : ''}`}
			    										onMouseEnter={() => this.mouseEnterAction(i)}
                    					onMouseLeave={() => this.mouseLeaveAction(i)}
                    					onClick={() => this.handleSelect(i)}>{op}</div>
			    				})}
			    			</div> :
			    			<div className='optionsNull'>
			    				{this.props.nullComponent ?
			    					<div className='nullWrapper'>{this.props.nullComponent}</div> :
			    					<div className='nullWrapper'>
			    						<AutocompleteNull lastQuery={this.props.lastQuery} />
			    					</div>
			    				}
			    				
			    			</div>
			    		}
			    	</div> :
			    	null
			    }
		    </div>
			</div>
		</div>);
	}
}