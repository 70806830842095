import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { formatPrice } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import {
  COUPON_DISCOUNT_TYPE_FIXED,
  COUPON_DISCOUNT_TYPE_PERCENTAGE,
} from '../../../../constants/promotions';
import { 
  TR_NUMERIC_STYLE,
  TR_VAL_NUMERIC_STYLE_PERCENT, 
} from '../../../../constants/tables';

let allActionCreators = Object.assign({});

export class NumericElement extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  isPercent() {
    if(!this.props.schema) { return null; }

    if(this.props.customAttr && this.props.customAttr.discounttype && this.props.customAttr.discounttype === COUPON_DISCOUNT_TYPE_PERCENTAGE) {
      return true;
    }
    return this.props.schema[TR_NUMERIC_STYLE] === TR_VAL_NUMERIC_STYLE_PERCENT;
  }

  isCurrency() {
    if(!this.props.schema) { return null; }

    if(this.props.customAttr && this.props.customAttr.discounttype && this.props.customAttr.discounttype === COUPON_DISCOUNT_TYPE_FIXED) {
      return true;
    }
    return false;
  }

  formatValue() {
    if(this.isCurrency()) {
      return formatPrice(this.props.dataValue, { addTags: false, language: this.getLanguage() });
    }
    if(this.isPercent()) {
      return this.props.dataValue || this.props.dataValue === 0 ? `${parseFloat(this.props.dataValue).toFixed(1)}` : '';
    }
    return this.props.dataValue || this.props.dataValue === 0 ? this.props.dataValue.toLocaleString(this.getLanguage()) : '';
  }

  getSuffix() {
    if(this.isPercent()) {
      return '%';
    }
    return '';
  }

  render() {  
    return <div className={'NumericElement'} {...this.props.customAttr}>
      <div className='numericElementLiner'>{`${this.formatValue()}${this.getSuffix()}`}</div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(NumericElement));


