
import * as x from '../constants/actions';
import { CSS_SLIDE_DURATION } from '../constants/css';

const initialState = {
  headerUserMenuOpen: false,
  headerUserMenuLastToggle: 0,
  headerMenuScreenActive: false, 
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_HEADER_TOGGLE_USER_MENU:
      return headerUserMenuToggle(state, action);
    case x.ACTION_HEADER_SET_MENU_SCREEN:
      return headerSetMenuScreen(state, action);
    default:
      return state;
  }
}

function headerUserMenuToggle(state, action) {

  // action.payload can be a config object (is often also a click event)
  // 
  // Config options:
  //
  // freezeScreen - pass true if you don't want to toggle screen with menu opening

  if(Date.now() < (CSS_SLIDE_DURATION + state.headerUserMenuLastToggle)) {
    return Object.assign({}, state);
  }

	const newStatus = !state.headerUserMenuOpen;
  const menuStatus = action.payload && action.payload.freezeScreen ? state.headerMenuScreenActive : newStatus;

  return Object.assign({}, state, {
    headerUserMenuOpen: newStatus,
    headerUserMenuLastToggle: Date.now(),
    headerMenuScreenActive: menuStatus, 
  });
}

function headerSetMenuScreen(state, action) {
  return Object.assign({}, state, {
    headerMenuScreenActive: action.payload,
  });
}