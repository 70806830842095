import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import {
  API_KEY_BUY_PRICE_MIN,
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_QUANTITY_MAX,
} from '../../../../constants/api';
import { CSS_HOMEPAGE_MOBILE_BREAK } from '../../../../constants/css';
import { FV_KEY_IS_BUYLIST } from '../../../../constants/filters';
import * as tx from '../../../../constants/strings';
import { URL_SELL_GENERAL } from '../../../../constants/urls';

import BlockElementVerticalProduct from '../BlockElementVerticalProduct';
import { LoadingIcon } from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockTypeProductsHorizontal.module.scss';

import * as productActionCreators from '../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class BlockFeaturedBuylistHorizontal extends Component {

  constructor(props) {
    super(props);

    this.DISPLAY_COUNT_DESKTOP = 8;
    this.DISPLAY_COUNT_MOBILE = 4;

    this.DISPLAY_OFFSET_UNITS = 1;

    this.REQUEST_COUNT = (this.DISPLAY_OFFSET_UNITS + 1) * Math.max(this.DISPLAY_COUNT_DESKTOP, this.DISPLAY_COUNT_MOBILE);

    this.state = {
      loading: true,

      productData: null,

      displayCount: this.REQUEST_COUNT,
    };    

    this.productWrapper = React.createRef();

    // Controllers
    this.controller = null;

    this.checkRowsThrottled = _.throttle(this.checkRows.bind(this), 50);
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkRowsThrottled, false);
    this.checkRows();
    this.fetchBuylistProducts();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
    window.removeEventListener('resize', this.checkRowsThrottled, false);
  }

  checkRows() {
    if(window.innerWidth <= CSS_HOMEPAGE_MOBILE_BREAK && this.state.displayCount !== this.DISPLAY_COUNT_MOBILE) {
      this.setState({ displayCount: this.DISPLAY_COUNT_MOBILE });
    } else if(window.innerWidth > CSS_HOMEPAGE_MOBILE_BREAK && this.state.displayCount !== this.DISPLAY_COUNT_DESKTOP) {
      this.setState({ displayCount: this.DISPLAY_COUNT_DESKTOP });
    }
  }

  async fetchBuylistProducts() {
    
    // Cancel any existing requests
    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({ 
      loading: true, 
    });

    const buylistFilters = { 
      [FV_KEY_IS_BUYLIST]: true,
    };

    const paginationData = {
      [API_KEY_LIMIT]: this.REQUEST_COUNT,
      [API_KEY_OFFSET]: 0,
      [API_KEY_BUY_PRICE_MIN]: 2000, // Store-specific; this should be parameterized when this is configurable by user
      [API_KEY_QUANTITY_MAX]: 0, // Store-specific; this should be parameterized when this is configurable by user
    }

    const reqFilterObject = Object.assign({}, buylistFilters, paginationData);
    const productResp = await this.props.productSearchInventory(reqFilterObject, controller.signal)
      .catch((errResp) => {
        this.setState({
          productData: [],
          loading: false, 
        });
      });

    if(!productResp) {
      return null;
    }

    this.setState({
      productData: productResp.data,
      loading: false, 
    });
  }

  getDisplayArray() {
    if(!this.state.productData || !this.state.productData.length) { return null; }
    return this.state.productData.slice(-1 * this.state.displayCount);
  }

  render() {

    const {t} = this.props;

    let productsRendered = 0;

    return <div className={`BlockFeaturedBuylistHorizontal ${styles.BlockTypeProductsHorizontal}`}>

        <div className={this.props.blockStyles.blockTitle}>
          <div className={this.props.blockStyles.blockTitleValue}>
            {this.props.block.isDefaultConfig('title') ? t(this.props.block.getConfigAttr('title')) : this.props.block.getConfigAttr('title')}
          </div>
          <div className={this.props.blockStyles.blockTitleButton}>
            <Link to={URL_SELL_GENERAL}>
              <button type='button' className={this.props.blockStyles.blockButton}>{t(tx.TX_SEE_ALL)}</button>
            </Link>
          </div>
        </div>
        
        <div className={this.props.blockStyles.blockBodyHorizontal}>
          {this.state.loading ?
            <div className={this.props.blockStyles.blockBodyHorizontalLoadingIcon}>
              <LoadingIcon />
            </div> :
            <div className={this.props.blockStyles.blockBodyHorizontalLiner}>
              {this.state.productData && this.state.productData.length > 0 ?
                <div ref={this.productWrapper}>
                  {this.getDisplayArray().map((prod, i) => {

                    if(productsRendered >= this.state.displayCount) {
                      return null;
                    }
                    productsRendered++;

                    return <div key={i} className={styles.elementWrapper}>
                      <BlockElementVerticalProduct
                        isBuylist={true}
                        product={prod} />
                    </div>
                  })}
                </div> :
                <div className={styles.noResults}>
                  {t(tx.TX_GALLERY_NO_RESULTS)}
                </div>
              }
            </div>
          }
        </div>

    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockFeaturedBuylistHorizontal));