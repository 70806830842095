
import {
	ANALYTICS_CONFIG_SHOW_ALL,
} from '../../constants/analytics';
import {
	ORDER_STATUS_COMPLETE,
	ORDER_STATUS_PENDING,
	ORDER_STATUS_PREORDER,
	ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS,
	ORDER_STATUS_BUYLIST_READY_TO_GRADE,
	ORDER_STATUS_READY_FOR_PICKUP,
	ORDER_STATUS_PENDING_PAYMENT,
	ORDER_STATUS_SHIPPED,
	ORDER_STATUS_CANCELLED,
	ORDER_STATUS_REFUNDED,
} from '../../constants/orders';

const STATUSES_PENDING = [
	ORDER_STATUS_PENDING,
	ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS,
	ORDER_STATUS_BUYLIST_READY_TO_GRADE,
	ORDER_STATUS_READY_FOR_PICKUP,
	ORDER_STATUS_PENDING_PAYMENT,
	ORDER_STATUS_PREORDER,
];

const STATUSES_ALL = [
	ORDER_STATUS_PENDING,
	ORDER_STATUS_PREORDER,
	ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS,
	ORDER_STATUS_BUYLIST_READY_TO_GRADE,
	ORDER_STATUS_READY_FOR_PICKUP,
	ORDER_STATUS_PENDING_PAYMENT,
	ORDER_STATUS_SHIPPED,
	ORDER_STATUS_COMPLETE,
	ORDER_STATUS_CANCELLED,
	ORDER_STATUS_REFUNDED,
];

export class AnalyticsDataOrdersByStatus {

	constructor(props) {
		
		if(!props) { props = {}; }

		this.INIT_CONFIG = {
			[ANALYTICS_CONFIG_SHOW_ALL]: false,
		};

		this.config = props.config ? Object.assign({}, this.INIT_CONFIG, props.config) : this.INIT_CONFIG;
		this.data = props.data || [];
	}

	displayData() {
		
		const resp = [];
		const orderedStatuses = this.getConfigValue(ANALYTICS_CONFIG_SHOW_ALL) ? STATUSES_ALL : STATUSES_PENDING;
		
		for(const status of orderedStatuses) {

			let foundStatus = false;

			for(const datum of this.data) {
				if(datum.status_key === status.key) {
					resp.push({
						status: status,
						count: parseInt(datum.count) || 0,
					});
					foundStatus = true;
					continue;
				}
			}
			if(!foundStatus) {
				resp.push({
					status: status,
					count: 0,
				})
			}
		}
		return resp;
	}

	getConfigValue(key) {
		return this.config[key] || this.INIT_CONFIG[key];
	}

	setConfigValue(key, value) {
		this.config = Object.assign({}, this.config, { [key]: value });
	}

	resetConfig() {
		this.config = this.INIT_CONFIG;
	}
}









