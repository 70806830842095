export const loadGooglePlaces = (apiKey, loadFunction) => {
  
  //loads the Google Places JavaScript Library
  (function () {

    const id = 'google-places-js';
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=apiLoadedTalaria&libraries=places&v=3`;
    
    // We have at least one script (React)
    const firstJs = document.getElementsByTagName('script')[0];
    
    // Prevent script from loading twice;
    // Should still execute loadFunction as that attaches to a component's input
    if(document.getElementById(id)) { 
      if(loadFunction) { loadFunction(); }
      return null; 
    }
    const js = document.createElement('script'); 
    js.id = id;
    js.src = src;
    js.onload = loadFunction; 
    firstJs.parentNode.insertBefore(js, firstJs);
  }());
}