import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { formatServerError, stringFormat } from '../../utils/formatting';
import { hasPermission } from '../../utils/permissions';

import { P_MANAGE_SALES } from '../../constants/permissions';
import * as tx from '../../constants/strings';
import {
  URL_ADMIN_INVENTORY, 
  URL_ADMIN_INVENTORY_VIEW, 
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import Confirm from '../Popups/Confirm';
import ProductControlModal from './blocks/product-modal/ProductControlModal';
import ProductDetailsEdit from './blocks/view/ProductDetailsEdit';
import ProductDetailsView from './blocks/view/ProductDetailsView';
import ProductInventoryEdit from './blocks/view/ProductInventoryEdit';
import ProductInventoryView from './blocks/view/ProductInventoryView';
import ProductMediaEdit from './blocks/view/ProductMediaEdit';
import ProductMediaView from './blocks/view/ProductMediaView';
import ProductModalChangeSet from './blocks/product-modal/ProductModalChangeSet';
import ProductModalTagManager from './blocks/product-modal/ProductModalTagManager';
import ProductRecentOrdersView from './blocks/view/ProductRecentOrdersView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_viewproduct.scss';

import * as commonActionCreators from '../../actions/common';
import * as productActionCreators from '../../actions/product';
let allActionCreators = Object.assign({}, commonActionCreators, productActionCreators);

export class ViewProduct extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirtyDetails: false,
      isDirtyInventory: false,
      isDirtyMedia: false,

      savePromptOpen: false,
      deletePromptOpen: false,
      lastBlock: '',

      editDetails: false,
      editInventory: false,
      editMedia: false,

      inventoryExpanded: true,
      mediaExpanded: false,
      ordersExpanded: false,

      editModalComponent: null,
      editModalCustom: false,
      editModalOpen: false,
      editModalProps: null,
      editModalTitle: tx.TX_null,

      requestPending: true,
      requestError: null,
      responseObject: null,

      currentInventory: null,
      currentPermalink: null,

      savingAll: false,
      remoteSaveDetails: false,
      remoteSaveInventory: false,
      remoteSaveMedia: false,
    };

    this.controller = null;
  }

  componentDidMount() {
    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyDetails === false && this.state.isDirtyInventory === false && this.state.isDirtyMedia === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    // Fetch product
    this.fetchProduct();
  }

  componentWillUnmount() {
    this.unblock();

    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyDetails === false && this.state.isDirtyInventory === false && this.state.isDirtyMedia === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchProduct();
      }
    }
  }

  async fetchProduct(service = '', updatedPermalink = false) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
    });

    if(updatedPermalink) {
      this.setState({
        isDirtyDetails: false,
        isDirtyInventory: false, 
        isDirtyMedia: false, 
      }, () => {
        history.push(stringFormat(URL_ADMIN_INVENTORY_VIEW, { 
          productLine: this.props.match.params.productLinePermalink, 
          permalink: updatedPermalink, 
        }));
      });
    }

    // Get product details
    if(this.props.match.params.productLinePermalink && this.props.match.params.productPermalink) {

      const prodPermalink = updatedPermalink ? updatedPermalink : this.props.match.params.productPermalink;
      
      const resp = await this.props.productFetchSingle(prodPermalink, this.props.match.params.productLinePermalink, controller.signal)
        .catch((errResp) => {
          
          if(controller.signal.aborted) { return null; }

          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
            responseObject: null,
          });
        });

      if(!resp) {
        return null;
      }

      this.setState({
        requestPending: false,
        requestError: null,
        responseObject: resp,
        currentPermalink: resp.permalink,
      });
      if(service === 'details') {
        this.setState({ isDirtyDetails: false });
      } else if(service === 'inventory') {
        this.setState({ isDirtyInventory: false });
      } else if(service === 'media') {
        this.setState({ isDirtyMedia: false });
      }
    }
  }

  makeDetailsDirty() {
    if(this.state.isDirtyDetails === false) {
      this.setState({ isDirtyDetails: true });
    }
  }

  makeInventoryDirty() {
    if(this.state.isDirtyInventory === false) {
      this.setState({ isDirtyInventory: true });
    }
  }

  makeMediaDirty() {
    if(this.state.isDirtyMedia === false) {
      this.setState({ isDirtyMedia: true });
    }
  }

  toggleDetails() {
    this.setState({ editDetails: !this.state.editDetails }, () => {
      if(this.state.editDetails === false) {
        this.setState({ isDirtyDetails: false, });
      }
    });
  }

  toggleInventory(inventoryObj) {
    const invObj = inventoryObj ? inventoryObj : null;
    this.setState({ 
      editInventory: !this.state.editInventory, 
      currentInventory: invObj,
    }, () => {
      if(this.state.editInventory === false) {
        this.setState({ isDirtyInventory: false, });
      }
    });
  }

  toggleMedia() {
    this.setState({ editMedia: !this.state.editMedia }, () => {
      if(this.state.editMedia === false) {
        this.setState({ isDirtyMedia: false, });
      }
    });
  }

  toggleInventoryExpanded() {
    this.setState({ inventoryExpanded: !this.state.inventoryExpanded });
  }

  toggleMediaExpanded() {
    this.setState({ mediaExpanded: !this.state.mediaExpanded });
  }

  toggleOrdersExpanded() {
    this.setState({ ordersExpanded: !this.state.ordersExpanded });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }

    // If you're saving product details, wait for it to complete before calling others since the permalink may change
    if(this.state.isDirtyDetails) {
      this.setState({
        savingAll: true, 
        remoteSaveDetails: this.state.isDirtyDetails,
      });
    } else {
      this.setState({
        savingAll: true, 
        remoteSaveDetails: this.state.isDirtyDetails,
        remoteSaveInventory: this.state.isDirtyInventory,
        remoteSaveMedia: this.state.isDirtyMedia,
      });
    }

    this.setState({
      savingAll: true, 
      remoteSaveDetails: this.state.isDirtyDetails,
      remoteSaveInventory: this.state.isDirtyInventory,
      remoteSaveMedia: this.state.isDirtyMedia,
    });
  }

  saveDisabled() {
    return this.state.editDetails || this.state.editInventory || this.state.editMedia;
  }

  finishRemote(service, didFail = false, updatedPermalink = null) {

    if(service === 'details') {
      this.setState({ 
        isDirtyDetails: didFail !== false, 
        remoteSaveDetails: false, 
      }, () => {
        // Once details completed, if inventory or media need save, do them then
        if(this.state.isDirtyInventory || this.state.isDirtyMedia) {
          this.setState({
            currentPermalink: updatedPermalink ? updatedPermalink : this.state.currentPermalink,
            remoteSaveDetails: this.state.isDirtyDetails,
            remoteSaveInventory: this.state.isDirtyInventory,
            remoteSaveMedia: this.state.isDirtyMedia,
          });
        } else {
          this.setState({ 
            savingAll: false, 
          });
        }
      });
    }

    if(service === 'inventory') {
      this.setState({ 
        isDirtyInventory: didFail !== false, 
        remoteSaveInventory: false, 
      }, () => {
        this.setState({ 
          savingAll: this.state.remoteSaveDetails === true || this.state.remoteSaveInventory === true || this.state.remoteSaveMedia === true, 
        });
      });
    }

    if(service === 'media') {
      this.setState({ 
        isDirtyMedia: didFail !== false, 
        remoteSaveMedia: false, 
      }, () => {
        this.setState({ 
          savingAll: this.state.remoteSaveDetails === true || this.state.remoteSaveInventory === true || this.state.remoteSaveMedia === true, 
        });
      });
    }
  }

  toggleDeletePrompt() {
    if(!this.state.deletePromptOpen && this.saveDisabled()) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_INV_VIEW_PRODUCT_DELETE_ALERT_TITLE, 
        alertCopy: tx.TX_SETTINGS_SHIPPING_METHOD_DELETE_ALERT_COPY, 
      });
    } else {
      this.setState({ deletePromptOpen: !this.state.deletePromptOpen });
    }
  }

  deleteProduct(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.productDelete(this.state.responseObject.permalink, this.state.responseObject.productLine.permalink)
    .then((resp) => {
      history.push(URL_ADMIN_INVENTORY);
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  shouldDisplayRecentOrdersBlock() {
    return hasPermission(P_MANAGE_SALES);
  }

  shouldDisplayMediaBlock() {
    if(!this.state.responseObject || this.state.responseObject.foreignModel) {
      return false;
    }
    return true;
  }

  getModalConfirmLabel() {
    if(this.state.editModalComponent && this.state.editModalComponent.WrappedComponent && this.state.editModalComponent.WrappedComponent.confirmLabel) {
      return this.state.editModalComponent.WrappedComponent.confirmLabel();
    }
    return tx.TX_SAVE;
  }

  openTagManagerModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: ProductModalTagManager,
      editModalTitle: tx.TX_INV_EDIT_TAGS,
    });
  }

  openChangeSetModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: ProductModalChangeSet,
      editModalTitle: tx.TX_INV_CHANGE_SET,
    });
  }

  closeProductControlModal() {
    this.setState({
      editModalOpen: false, 
    });
  }

  clearModalData() {
    this.setState({
      editModalComponent: null, 
      editModalCustom: false,
      editModalTitle: tx.TX_null,
      editModalProps: null,
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewProduct AdminPage'}>
      <AdminTitle
        title={tx.TX_INV_VIEW_PRODUCT}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: tx.TX_CATALOG,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>
          {this.state.editDetails ? 
            <ProductDetailsEdit
              productObj={this.state.responseObject}
              productPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)}
              makeDirty={this.makeDetailsDirty.bind(this)}
              makeClean={this.fetchProduct.bind(this)} 
              remoteSave={this.state.remoteSaveDetails}
              finishRemote={this.finishRemote.bind(this)} /> :
            <ProductDetailsView
              productObj={this.state.responseObject}
              productPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)}
              openChangeSetModal={this.openChangeSetModal.bind(this)}
              openTagManagerModal={this.openTagManagerModal.bind(this)} />
          }
          {this.state.editInventory ?
            <ProductInventoryEdit
              productObj={this.state.responseObject}
              productPermalink={this.state.currentPermalink}
              productPending={this.state.requestPending}
              inventoryObj={this.state.currentInventory}
              toggleEdit={this.toggleInventory.bind(this)}
              makeDirty={this.makeInventoryDirty.bind(this)}
              makeClean={this.fetchProduct.bind(this)} 
              remoteSave={this.state.remoteSaveInventory}
              finishRemote={this.finishRemote.bind(this)}
              expanded={this.state.inventoryExpanded}
              toggleExpanded={this.toggleInventoryExpanded.bind(this)} /> :
            <ProductInventoryView
              productObj={this.state.responseObject}
              productPending={this.state.requestPending}
              toggleEdit={this.toggleInventory.bind(this)}
              expanded={this.state.inventoryExpanded}
              toggleExpanded={this.toggleInventoryExpanded.bind(this)} />
          }
          {this.shouldDisplayMediaBlock() ?
            <>
              {this.state.editMedia ?
                <ProductMediaEdit
                  productObj={this.state.responseObject}
                  productPermalink={this.state.currentPermalink}
                  productPending={this.state.requestPending}
                  toggleEdit={this.toggleMedia.bind(this)}
                  makeDirty={this.makeMediaDirty.bind(this)}
                  makeClean={this.fetchProduct.bind(this)} 
                  remoteSave={this.state.remoteSaveMedia}
                  finishRemote={this.finishRemote.bind(this)}
                  expanded={this.state.mediaExpanded}
                  toggleExpanded={this.toggleMediaExpanded.bind(this)} /> :
                <ProductMediaView
                  productObj={this.state.responseObject}
                  productPending={this.state.requestPending}
                  toggleEdit={this.toggleMedia.bind(this)}
                  expanded={this.state.mediaExpanded}
                  toggleExpanded={this.toggleMediaExpanded.bind(this)} />
              }
            </> :
            null
          }
          {this.shouldDisplayRecentOrdersBlock() ? 
            <ProductRecentOrdersView
              product={this.state.responseObject}
              productPending={this.state.requestPending} 
              expanded={this.state.ordersExpanded}
              toggleExpanded={this.toggleOrdersExpanded.bind(this)} /> :
            null
          }
        </div>
      </div>
      <div className='adminActions'>
        <button 
          type='button'
          className={this.saveDisabled() ? 'disabled blockButton' : 'blockButton'} 
          onClick={this.toggleDeletePrompt.bind(this)}>
          {t(tx.TX_DELETE)}
        </button>
        <Link to={URL_ADMIN_INVENTORY} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <Confirm
        title={tx.TX_DELETE_PROMPT_TITLE}
        copy={tx.TX_DELETE_PROMPT_COPY}
        open={this.state.deletePromptOpen}
        confirmValue={tx.TX_DELETE}
        closeMethod={this.toggleDeletePrompt.bind(this)}
        onConfirm={this.deleteProduct.bind(this)} />
      <ProductControlModal
        open={this.state.editModalOpen}
        modalComponent={this.state.editModalComponent}
        modalTitle={this.state.editModalTitle}
        modalSuccessLabel={this.getModalConfirmLabel()}
        closeMethod={this.closeProductControlModal.bind(this)}
        clearModalData={this.clearModalData.bind(this)}
        product={this.state.responseObject}
        productPending={this.state.requestPending}
        makeClean={this.fetchProduct.bind(this)}
        additionalProps={this.state.editModalProps} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewProduct));