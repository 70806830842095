import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isLoggedIn } from '../../../../../utils/auth';
import {
  isStepComplete, 
  isStepValid, 
} from '../../../../../utils/checkout';

import CheckoutStepAuthInput from './CheckoutStepAuthInput';

let allActionCreators = Object.assign({});

export class CheckoutStepAuth extends Component {

  componentDidMount() {
    if(isLoggedIn()) {
      this.props.setStepData(this.props.config, { 
        isGuest: false, 
      });
    }
  }

  getStepComponent() {
    if(isStepValid(this.props.config, this.props.checkout.stepData) && isStepComplete(this.props.config, this.props.checkout.stepData)) {
      return null;
    }
    return <CheckoutStepAuthInput 
            config={this.props.config}
            setStepData={this.props.setStepData} />
  }

  render() {
    return <div className={'CheckoutStepAuth CheckoutStepComponent'}>{this.getStepComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepAuth));