import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { formatPrice } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import '../../style/_orderlineitems.scss';

import Checkbox from '../../../Input/Checkbox';

const allActionCreators = Object.assign({});

export class OrderLineItemShippingRefund extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  toggleSelected() {
    this.props.toggleRow(this.props.pseudoItem);
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderLineItem OrderLineItemShippingRefund'}>
      <div className='oliLiner'>
        <div className='checkboxBlock'>
          <div className='checkboxElement'>
            <Checkbox 
              adminTheme={true}
              name={`select-${this.props.pseudoItem ? this.props.pseudoItem.id : 'x'}`}
              id={`ci-return-select-toggle-${this.props.pseudoItem ? this.props.pseudoItem.id : 'x'}`}
              value={t(tx.TX_SELECT)}
              checked={this.props.isSelected}
              ambiguousCheck={false}
              onChange={this.toggleSelected.bind(this)} />
          </div>
        </div>
        <div className='itemBlock'>
          <div className='shippingWrapper'>
            <div className='shippingLabel'>{t(tx.TX_SHIPPING)}</div>
            <div className='shippingValue'>{this.props.order && this.props.order.shippingMethod ? this.props.order.shippingMethod.name : ''}</div>
          </div>
        </div>
        <div className='quantityBlock'></div>
        <div className='priceBlock'>
          <div className='priceReadOnly'>
            <div 
              className='priceReadOnlyValue'
              dangerouslySetInnerHTML={{ 
                __html: formatPrice(this.props.order ? this.props.order.totalShipping : 0, { addTags: true, language: this.getLanguage() }) 
              }} />
          </div>
        </div>
        <div className='returnBlock'></div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderLineItemShippingRefund));