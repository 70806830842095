

import { 
	toDate,
	utcToZonedTime, 
} from 'date-fns-tz';

import { Month } from '../models/calendar';

import { 
	getStoreTimeZone,
	twoDigitInt, 
} from './general';
import { dynamicSort } from './sort';


export function getDaysEvents(day, eventObj, scheduleObj, filters) {
	
	const daysEvents = [];

	try {

		const mon = new Month({ month: day.getMonth() });

		const checkLastMonth = day.getDate() === 1;
		const checkNextMonth = day.getDate() === mon.daysCount;

		if(checkLastMonth) {
			const yesterday = new Date(day.getTime() - (24 * 60 * 60 * 1000));
			if(eventObj[yesterday.getFullYear()] && eventObj[yesterday.getFullYear()][yesterday.getMonth()]) {
				for(const evt of eventObj[yesterday.getFullYear()][yesterday.getMonth()]) {

					const zonedStart = utcToZonedTime(evt.startTime, getStoreTimeZone());
					if(day.getDate() === zonedStart.getDate() && day.getMonth() === zonedStart.getMonth() && evt.includedInFilters(filters)) {
						daysEvents.push(evt);
					}
				}
			}
		}
		if(eventObj[day.getFullYear()] && eventObj[day.getFullYear()][day.getMonth()]) {
			for(const evt of eventObj[day.getFullYear()][day.getMonth()]) {

				const zonedStart = utcToZonedTime(evt.startTime, getStoreTimeZone());
				if(day.getDate() === zonedStart.getDate() && evt.includedInFilters(filters)) {
					daysEvents.push(evt);
				}
			}
		}
		if(checkNextMonth) {
			const tomorrow = new Date(day.getTime() + (24 * 60 * 60 * 1000));

			if(eventObj[tomorrow.getFullYear()] && eventObj[tomorrow.getFullYear()][tomorrow.getMonth()]) {
				for(const evt of eventObj[tomorrow.getFullYear()][tomorrow.getMonth()]) {

					const zonedStart = utcToZonedTime(evt.startTime, getStoreTimeZone());
					if(day.getDate() === zonedStart.getDate() && day.getMonth() === zonedStart.getMonth() && evt.includedInFilters(filters)) {
						daysEvents.push(evt);
					}
				}
			}
		}

		if(scheduleObj[day.getFullYear()] && scheduleObj[day.getFullYear()][day.getMonth()]) {
			for(const schedule of scheduleObj[day.getFullYear()][day.getMonth()]) {
				const eventInstance = schedule.getEvent(day);
				if(eventInstance && eventInstance.includedInFilters(filters)) {
					daysEvents.push(eventInstance);
				}
			}
		}

		return daysEvents.sort(dynamicSort('startTime'));

	} catch(err) {
		console.error(err);
		return daysEvents;
	}
}


export function eventMonthFetched(month, eventObj) {
	try {
		if(eventObj[month.year] && eventObj[month.year][month.monthIndex]) {
			return true;
		}
		return false;
	} catch(err) {
		return false;
	}
}


export function encodeUrlDate(dateObj) {
	try {
		return `${dateObj.getFullYear()}${twoDigitInt(dateObj.getMonth() + 1)}${twoDigitInt(dateObj.getDate())}`;
	} catch(err) {
		return '';
	}
}

export function decodeUrlDate(dateStr) {
	try {
		if(dateStr.length !== 8) {
			return null;
		}
		return toDate(`${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`);
	} catch(err) {
		return null;
	}
}













