import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import {
  API_KEY_LIMIT,
  API_KEY_SEARCH,
} from '../../../../constants/api';
import { 
  ICON_CART,
  ICON_USER,
} from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';

import { 
  formatServerError,
  dateShort, 
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import AutocompleteField from '../../../Input/AutocompleteField';
import AutocompleteOptionUser from '../../../Input/autocomplete/AutocompleteOptionUser';
import CartModalChangeCustomerNullResults from './CartModalChangeCustomerNullResults';
import { Icon } from '../../../Icons/Icon';
import { LoadingIcon } from '../../../Icons/LoadingIcon';
import MiniCart from '../../../Cart/MiniCart';
import Toggle from '../../../Input/Toggle';

import '../../style/_vieworder.scss';

import * as cartActionCreators from '../../../../actions/cart';
import * as customerActionCreators from '../../../../actions/customer';
const allActionCreators = Object.assign({}, cartActionCreators, customerActionCreators);

export class CartModalChangeCustomer extends Component {

  constructor(props) {
    super(props);

    this.MIN_INPUT_LENGTH = 3;

    this.UPDATE_TYPE_MERGE = 'merge';
    this.UPDATE_TYPE_REPLACE = 'replace';

    this.state = {

      inputCustomer: '',
      inputUserUpdateType: this.UPDATE_TYPE_MERGE,

      errorCustomer: '',
      
      selectedUser: null,
      options: null,
      lastAutocomplete: null,

      requestPending: false,
      requestError: null,

      userCartError: null,
      userCartPending: false,
      userCartObj: null,
    };

    this.controllerAutocomplete = null;
    this.controllerUserCart = null;
    this.controller = null;
  }

  componentDidMount() {
    this.props.allowConfirm(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
    if(!_.isEqual(prevState, this.state)) {
      this.props.didUpdate();
    }
  }

  componentWillUnmount() {
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    if(this.controllerUserCart) {
      this.controllerUserCart.abort();
    }
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_SAVE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }

    if(this.state.selectedUser && this.props.cart && !this.state.requestPending) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.props.allowConfirm(false);
      this.setState({
        requestPending: true,
        requestError: null,
      });

      const updateData = {
        user_uuid: this.state.selectedUser.publicUuid,
        user_update_type:this.state.inputUserUpdateType,
      };

      let updateResp = null;
      if(this.props.cart.isBuylist) {
        updateResp = await this.props.cartBuylistAdminUpdate(updateData, this.props.cart.publicUuid, controller.signal)
          .catch((errResp) => {
            console.error(errResp);
            this.setState({
              requestPending: false,
              requestError: formatServerError(errResp),
            });
            this.props.completeAction();
            this.props.allowConfirm(true);
          });
      } else {
        updateResp = await this.props.cartAdminUpdate(updateData, this.props.cart.publicUuid, controller.signal)
          .catch((errResp) => {
            console.error(errResp);
            this.setState({
              requestPending: false,
              requestError: formatServerError(errResp),
            });
            this.props.completeAction();
            this.props.allowConfirm(true);
          });
      }

      if(updateResp) { 
        // Only close for a valid response, in this case, will just be: {}
        this.props.allowConfirm(true);
        this.props.makeClean(true);
        this.props.closeMethod();
        this.props.completeAction();
      }

    } else {
      this.props.completeAction();
    }
  }

  async fetchAutocomplete() {

    // Async call to autocomplete users
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerAutocomplete = new AbortController();
    this.controllerAutocomplete = controllerAutocomplete;

    const searchVal = this.state.inputCustomer.trim();
    const searchParams = {
      [API_KEY_SEARCH]: searchVal,
      [API_KEY_LIMIT]: 5,
    }
    
    const searchResp = await this.props.customerQuickSearch(searchParams, controllerAutocomplete.signal)
      .catch((errResp) => {
        // Do nothing
      });

    if(!searchResp) { return null; }

    this.setState({ 
      options: searchResp.data, 
      autocompleteActive: true, 
      lastAutocomplete: searchVal,
    });
  }

  changeCustomerInput(evt) {
    this.setState({ inputCustomer: evt.target.value }, () => {
      if(this.state.inputCustomer.trim().length >= this.MIN_INPUT_LENGTH) {
        this.fetchAutocomplete();
      } else {
        this.setState({ options: null });
      }
    });
  }

  changeUpdateType(evt) {
    const newValue = this.state.inputUserUpdateType === this.UPDATE_TYPE_MERGE ? this.UPDATE_TYPE_REPLACE : this.UPDATE_TYPE_MERGE;
    this.setState({ inputUserUpdateType: newValue });
  }

  getOptionComponents() {
    if(!this.state.options) { return null; }

    const components = [];
    for(const op of this.state.options) {
      components.push(<AutocompleteOptionUser user={op} lastQuery={this.state.lastAutocomplete} />);
    }
    return components;
  }

  getNullComponent() {
    return <CartModalChangeCustomerNullResults lastQuery={this.state.lastAutocomplete} cart={this.props.cart} />;
  }

  selectUser(idx) {
    if(!this.state.options || !this.state.options[idx]) { return null; }

    const user = this.state.options[idx];

    this.setState({      
      selectedUser: user,
      inputCustomer: '',
      errorCustomer: '',
      options: null,
      lastAutocomplete: null,

      userCartError: null,
      userCartPending: true,
      userCartObj: null,
    }, () => {
      this.fetchUserCart();
    });
  }

  deselectUser() {
    this.setState({ 
      selectedUser: null,
      requestError:null, 
    });
  }

  async fetchUserCart() {
    if(!this.state.selectedUser) { return null; }

    // Also done above in selectUser, but here as well in case we call this on its own
    if(!this.state.userCartPending) {
      this.setState({
        userCartError: null,
        userCartPending: true,
        userCartObj: null,
      });
    }

    if(this.controllerUserCart) {
      this.controllerUserCart.abort();
    }
    const controllerUserCart = new AbortController();
    this.controllerUserCart = controllerUserCart;

    let userCart = null;

    if(this.props.cart.isBuylist) {

      userCart = await this.props.cartBuylistAdminFetchByUser(this.state.selectedUser.publicUuid, controllerUserCart.signal)
        .catch((errResp) => {
          
          if(controllerUserCart.signal.aborted) { return null; }

          console.error(errResp);
          this.setState({ 
            userCartPending: false,
            userCartError: formatServerError(errResp),
            userCartObj: null,
          });
        });
    } else {

      userCart = await this.props.cartAdminFetchByUser(this.state.selectedUser.publicUuid, controllerUserCart.signal)
        .catch((errResp) => {
          
          if(controllerUserCart.signal.aborted) { return null; }

          console.error(errResp);
          this.setState({ 
            userCartPending: false,
            userCartError: formatServerError(errResp),
            userCartObj: null,
          });
        });
    }

    this.setState({
      userCartError: null,
      userCartPending: false,
      userCartObj: userCart,
    }, () => {

      if(this.state.userCartObj && this.state.userCartObj.publicUuid === this.props.cart.publicUuid) {
        // Should not allow assigning a user cart already assigned to them
        this.setState({ requestError: tx.TX_CART_USER_ALREADY_ASSIGNED });
      } else {
        // Enable save
        this.props.allowConfirm(true);
      } 
    });
  }

  isEmptyCart() {
    if(!this.state.userCartObj) {
      return true;
    }
    if(!this.state.userCartObj.items || this.state.userCartObj.items.length === 0) {
      return true;
    }
    return false;
  }

  render() {

    const {t} = this.props;

    return <div className={'CartModalChangeCustomer CartModalView'}>
      <div className='cmvWrapper'>
        <form 
          className={'changeCustomerForm cartModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'cmFormError present' : 'cmFormError'}>{t(this.state.requestError)}</div>

          {this.state.selectedUser === null ?
            <div className='cmFieldWrapper'>
              <div className={'cmFieldLabel cmOptional'}>{t(tx.TX_CUSTOMER)}</div>
              <div className='cmInputWrapper'>
                <AutocompleteField
                  value={this.state.inputCustomer}
                  onChange={this.changeCustomerInput.bind(this)} 
                  onSelect={this.selectUser.bind(this)}
                  options={this.getOptionComponents()}
                  placeholder={t(tx.TX_PLACEHOLDER_CUSTOMER_SEARCH)}
                  lastQuery={this.state.lastAutocomplete}
                  adminTheme={true}
                  nullComponent={this.getNullComponent()} />
              </div>
              {this.state.errorMessage ?
                <div className={'FieldError'}>{t(this.state.errorMessage)}</div> :
                null
              }
            </div> :
            <>
              <div className='selectedUserSection'>
                <div className='cartUserWrapper'>
                  <div className='cartUserIconWrapper'>
                    <div className='cartUserIcon'>
                      <Icon value={ICON_USER} />
                    </div>
                  </div>
                  <div className='cartUserBodyWrapper'>
                    <div className='userNameWrapper'>
                      <div className='userNameValue'>{this.state.selectedUser.fullName(this.getLanguage())}</div>
                      <div className='userAction' onClick={this.deselectUser.bind(this)}>{t(tx.TX_CHANGE)}</div>
                    </div>
                    <div className='userEmailWrapper'>{this.state.selectedUser.email}</div>
                  </div>
                </div>
                <div className='existingCartWrapper'>
                  <div className='existingCartIconWrapper'>
                    <div className='existingCartIcon'>
                      <Icon value={ICON_CART} />
                    </div>
                  </div>
                  <div className='existingCartBodyWrapper'>
                    {this.state.userCartPending ?
                      <div className='cartLoadingWrapper'>
                        <div className='loadingIconWrapper'>
                          <div className='loadingIcon'>
                            <LoadingIcon />
                          </div>
                        </div>
                        <div className='loadingCopyWrapper'>
                          <div className={'loadingCopy'}>{t(tx.TX_LOADING)}</div>
                        </div>
                      </div> :
                      <div className='cartLoadedWrapper'>
                        {this.isEmptyCart() ?
                          <div className='emptyCartWrapper'>
                            <div className='emptyCartLabel'>{t(tx.TX_CART_EXISTING_CART)}</div>
                            <div className='emptyCartValue'>{t(tx.TX_NONE)}</div>
                          </div> :
                          <div className='activeCartWrapper'>
                            <div className='activeCartStatus'>
                              <div className='statusLabel'>{t(tx.TX_CART_EXISTING_CART)}</div>
                              <div className='statusValue'>{t(this.state.userCartObj.status)}</div>
                            </div>
                            <div className='activeCartUpdate'>
                              <div className='updateLabel'>{t(tx.TX_CART_LAST_UPDATE)}</div>
                              <div className='updateValue'>{dateShort(this.state.userCartObj.lastUpdate, this.getLanguage())}</div>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                    
                </div>
              </div>
              {this.props.cart && this.state.userCartObj && !this.state.userCartPending && !this.isEmptyCart() ?
                <div className='cartControlWrapper'>
                  <div className='cartControlMinicartWrapper'>
                    <div className='cartControlMinicartTitleWrapper'>
                      <div className='titleValue'>{t(tx.TX_CART_EXISTING_CART_ITEMS)}</div>
                      <div className='titleCount'>({this.state.userCartObj.quantity === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: this.state.userCartObj.quantity })})</div>
                    </div>
                    <div className='cartControlMinicartBodyWrapper'>
                      <div className='cartControlMinicartLiner'>
                        <MiniCart
                          isBuylist={this.props.cart.isBuylist}
                          order={null}
                          cartOverride={this.state.userCartObj}
                          readOnly={true} />
                      </div>
                    </div>
                  </div>
                  <div className='mergeControlWrapper'>
                    <div className='cmFieldWrapper'>
                      <div className={'cmFieldLabel cmOptional'}>{t(tx.TX_CART_MERGE_CARTS_QUESTION)}</div>
                      <div className='cmInputWrapper'>
                        <Toggle
                          checked={this.state.inputUserUpdateType === this.UPDATE_TYPE_MERGE}
                          disabled={false}
                          onToggle={this.changeUpdateType.bind(this)}
                          trueValue={tx.TX_CART_MERGE}
                          falseValue={tx.TX_CART_DISCARD} />
                      </div>
                    </div>
                    <div className='mergeCartCopy'>{t(this.state.inputUserUpdateType === this.UPDATE_TYPE_MERGE ? tx.TX_CART_MERGE_EXPLANATION_COPY : tx.TX_CART_DISCARD_EXPLANATION_COPY)}</div>
                  </div>
                </div> :
                null
              }
            </>
          }
        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CartModalChangeCustomer));
