
import * as _ from 'underscore';

import {
	SHIPMENT_CARRIER_KEY_OTHER,
	SHIPMENT_CARRIERS_ALL, 
	SHIPMENT_STATUS_SHIPPED, 
	SHIPMENT_STATUSES_ALL, 
} from '../constants/shipments';
import { STORE_INTEGRATION_STAMPS_KEY } from '../constants/store';

import { StampsLabel } from './integration-providers/stamps';
import { User } from './users';

import { 
	isVarBool, 
	isVarString, 
} from '../utils/general';


export class Shipment {

	constructor(props) {

		// As props, should accept another Shipment object or a server resp
		if(!props) { props = {}; }

		const carrierProps = props.carrier || {};
		const statusProps = props.status || SHIPMENT_STATUS_SHIPPED;

		const labelValue = props.label || null;

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.trackingNumber = props.trackingNumber || props.tracking_number || '';
		this.carrierAlt = props.carrierAlt || props.carrier_alt || '';
		this.createDate = props.createDate || props.create_date || '';
		this.integration = props.integration || null;
		this.configData = props.configData || props.config_data || {};

		const cancelDateValue = props.createDate || props.cancel_time || null;
		this.cancelDate = cancelDateValue ? new Date(cancelDateValue) : null;
		this.cancelNote = props.cancelNote || props.cancel_note || null;

		this.date = new Date(this.createDate);
		this.carrier = new ShipmentCarrier(carrierProps);
		this.status = new ShipmentStatus(statusProps);

		if(labelValue && this.integration && this.integration.provider) {
			switch(this.integration.provider.key) {
				case STORE_INTEGRATION_STAMPS_KEY:
					this.label = new StampsLabel(labelValue);
					break;
				default:
					this.label = null;
			}
		}

		this.trackingNumber = this.trackingNumber.toUpperCase();
	}

	get isCancelled() {
		return !!this.cancelDate;
	}

	getApiData() {
		const apiData = {
      carrier: this.carrier.key,
      status: this.status.key,
		};
		if(this.publicUuid) {
			apiData['public_uuid'] = this.publicUuid;
		}
		if(this.carrierAlt) {
			apiData['carrier_alt'] = this.carrierAlt;
		}
		if(this.trackingNumber) {
			apiData['tracking_number'] = this.trackingNumber.toUpperCase();
		}
		return apiData;
	}

	get carrierName() {
		if(!this.carrier) { return ''; }

		if(this.carrier.isOther()) {
			return this.carrierAlt;
		}
		return this.carrier.name;
	}
}


export class ShipmentCarrier {

	constructor(props) {

		// As props, only takes a constant defined in constants/shipments or key to lookup constant;
		// Pretty useless right now, but will probably build on it later
		if(!props) { props = {}; }

		if(isVarString(props)) {
			const foundConfig = ShipmentCarrier.getConfig(props);
			if(foundConfig) {
				props = foundConfig;
			}
		}

		this.key = props.key || '';
		this.name = props.name || '';
		this.hasTracking = props.hasTracking || '';
		this.trackingUrl = props.trackingUrl || props.tracking_url || '';
	}

	static getConfig(key) {
		for(const sc of SHIPMENT_CARRIERS_ALL) {
			if(sc.key === key) {
				return sc;
			}
		}
		return null;
	}

	static getByCounty(countryCode) {
		const carriers = [];
		for(const sc of SHIPMENT_CARRIERS_ALL) {
			if(sc.countries) {
				for(const cn of sc.countries) {
					if(cn.key === countryCode) {
						carriers.push(sc);
						break;
					}
				}
			}
		}
		return carriers;
	}

	isOther() {
		return this.key === SHIPMENT_CARRIER_KEY_OTHER;
	}
}


export class ShipmentStatus {

	constructor(props) {

		// As props, only takes a constant defined in constants/shipments or key to lookup constant;
		// Pretty useless right now, but will probably build on it later
		if(!props) { props = {}; }

		if(isVarString(props)) {
			const foundConfig = ShipmentStatus.getConfig(props);
			if(foundConfig) {
				props = foundConfig;
			}
		}

		this.key = props.key || '';
		this.name = props.name || '';
	}

	static getConfig(key) {
		for(const st of SHIPMENT_STATUSES_ALL) {
			if(st.key === key) {
				return st;
			}
		}
		return null;
	}
}


export class Pickup {

	constructor(props) {

		// As props, should accept another Shipment object or a server resp
		if(!props) { props = {}; }

		const initUserProps = props.initUser || props.init_user || {};
		const completeUserProps = props.completeUser || props.complete_user || {};

		this.publicUuid = props.publicUuid || props.public_uuid || '';

		this.initTime = props.initTime || props.init_time || '';
		this.initNote = props.initNote || props.init_note || '';
		this.initUser = _.isEmpty(initUserProps) === false ? new User(initUserProps) : null;
		this.initDate = this.initTime ? new Date(this.initTime) : null;

		this.completeTime = props.completeTime || props.complete_time || '';
		this.completeNote = props.completeNote || props.complete_note || '';
		this.completeUser = _.isEmpty(completeUserProps) === false ? new User(completeUserProps) : null;
		this.completeDate = this.completeTime ? new Date(this.completeTime) : null;

		if(isVarBool(props.isDropoff)) {
  		this.isDropoff = props.isDropoff;
  	} else if(isVarBool(props.is_dropoff)) {
  		this.isDropoff = props.is_dropoff;
  	} else {
  		this.isDropoff = false;
  	}

  	if(isVarBool(props.isCompleteOrder)) {
  		this.isCompleteOrder = props.isCompleteOrder;
  	} else if(isVarBool(props.is_complete_order)) {
  		this.isCompleteOrder = props.is_complete_order;
  	} else {
  		this.isCompleteOrder = true;
  	}
	}

	get isComplete() {
		return this.completeDate !== null;
	}

	getApiDataCreate() {
		const apiData = {
      is_dropoff: this.isDropoff,
      is_complete: this.isCompleteOrder,
      description: this.initNote,
		};
		return apiData;
	}

	getApiDataComplete() {
		const apiData = {
			public_uuid: this.publicUuid,
      description: this.completeNote,
		};
		return apiData;
	}
}


export class GoodsReceipt {

	constructor(props) {

		// As props, should accept another Shipment object or a server resp
		if(!props) { props = {}; }

		const receiptUserProps = props.receiptUser || props.receipt_user || {};

		this.publicUuid = props.publicUuid || props.public_uuid || '';

		this.receiptTime = props.receiptTime || props.receipt_time || '';
		this.receiptNote = props.receiptNote || props.receipt_note || '';
		this.receiptUser = _.isEmpty(receiptUserProps) === false ? new User(receiptUserProps) : null;
		this.receiptDate = this.receiptTime ? new Date(this.receiptTime) : null;
	}

	get date() {
		return this.receiptDate;
	}

	getApiDataCreate() {
		const apiData = {
      description: this.receiptNote,
		};
		return apiData;
	}
}














