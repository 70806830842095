import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  CSS_SLIDE_DURATION 
} from '../../constants/css';

import { 
  TX_OKAY, 
  TX_null, 
} from '../../constants/strings';

import './style/_alert.scss';

import * as commonActionCreators from '../../actions/common';
let allActionCreators = Object.assign({}, commonActionCreators);

export class Alert extends Component {

  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,

      tempTitle: '',
      tempCopy: '',
      tempButtonValue: '',
    };

    // Create refs
    this.modalBody = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.common.alertOpen !== prevProps.common.alertOpen) {
      if(this.props.common.alertOpen === true) {
        this.openAlert();
      } else {
        this.setState({
          tempTitle: prevProps.common.alertTitle,
          tempCopy: prevProps.common.alertCopy,
          tempButtonValue: prevProps.common.alertButtonValue,
        }, () => {
          this.closeAlert();
        });
      }
    }
  }

  openAlert() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
      listenerEnabled: false,
      tempTitle: '',
      tempCopy: '',
      tempButtonValue: '',
    }, () => {

      // Delay to trigger CSS animation
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, 25);

      // Delay listening for clicks
      setTimeout(() => {
        this.setState({
          listenerEnabled: true,
        });
      }, CSS_SLIDE_DURATION);

      // Fix body
      document.body.style.overflow = 'hidden';
    });
  }

  closeAlert() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
      listenerEnabled: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          shouldDisplay: false,
          tempTitle: '',
          tempCopy: '',
          tempButtonValue: '',
        });
      }, CSS_SLIDE_DURATION);
    });
  }

  getScreenClass() {
    return this.state.hasOpacity ? 'alertScreen withOpacity' : 'alertScreen';
  }

  getModalStyle() {

    const baseConfig = {
      opacity: this.state.hasOpacity ? '1' : '0',
      top: '250px',
    };

    let verticalConfig = {};

    if(this.props.common.alertOpen && this.modalBody && this.modalBody.current && this.modalBody.current.clientHeight > 0) {
      
      const modalHeight = this.modalBody.current.clientHeight;
      verticalConfig = {
        top: (window.innerHeight - modalHeight)/2 + 'px',
      }
    }

    return Object.assign({}, baseConfig, verticalConfig);
  }

  getTitle() {
    if(this.state.tempTitle) {
      return this.state.tempTitle;
    }
    return this.props.common.alertTitle ? this.props.common.alertTitle : TX_null;
  }

  getCopy() {
    if(this.state.tempCopy) {
      return this.state.tempCopy;
    }
    return this.props.common.alertCopy ? this.props.common.alertCopy : TX_null; 
  }

  getButtonValue() {
    if(this.state.tempButtonValue) {
      return this.state.tempButtonValue;
    }
    return this.props.common.alertButtonValue ? this.props.common.alertButtonValue : TX_OKAY; 
  }

  hasTitle() {
    if(this.state.tempTitle) {
      return true;
    }
    return this.props.common.alertTitle !== '';
  }

  render() {

    const {t} = this.props;

    return <div 
            className={`Alert${this.props.common.alertStoreTheme ? ' storeTheme': ''}`}
            style={{
              display: this.state.shouldDisplay ? 'block' : 'none'
            }}>
      <div className={this.getScreenClass()}></div>
      <div className='alertModalWrapper'>
        <div className='alertModal' style={this.getModalStyle()} ref={this.modalBody}>
          <div className='alertModalInnerWrapper'>
            <div className='alertModalTitle'>{t(this.getTitle())}</div>
            {this.hasTitle() ?
              <div className='alertModalHr'></div> :
              null
            }
            <div className='alertModalCopy'>{t(this.getCopy())}</div>
            <div className='alertModalActions'>
              <button className={'alertAction alertActionSave'} onClick={() => this.props.commonAlert()}>{t(this.getButtonValue())}</button>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Alert));