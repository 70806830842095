

import { CURRENCIES } from '../constants/currency';


export function getCurrencyObj() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return CURRENCIES[currencyCode];
}

export function getCurrencyObjFromCode(currencyCode) {
	return CURRENCIES[currencyCode.toUpperCase()];
}

export function getCurrencyCode() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return CURRENCIES[currencyCode].code;
}

export function getCurrencySymbol() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return CURRENCIES[currencyCode].symbol;
}

export function getCurrencyIncrement() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return (1 / CURRENCIES[currencyCode].minorCurrencyCount);
}

export function getCurrencyPrecision() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return Math.log10(CURRENCIES[currencyCode].minorCurrencyCount);
}

export function getCurrencyMinorCount() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return CURRENCIES[currencyCode].minorCurrencyCount;
}

export function getCurrencyDecimalTruncation() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return CURRENCIES[currencyCode].decimalTruncation || false;
}

export function getCurrencyDecimalSeparator() {
	const currencyCode = process.env.REACT_APP_CURRENCY_CODE;
	return CURRENCIES[currencyCode].decimalSeparator || '.';
}

export function priceFloatToInt(priceFloat) {
	return parseInt(priceFloat*getCurrencyMinorCount()) || 0;
}
