import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { getConditionsOrderedDeduped } from '../../../../utils/condition';

let allActionCreators = Object.assign({});

export class ProductPageInventoryConfigCondition extends Component {


  getInventoryConditions() {
    
    if(!this.props.inventoryOptions) { return []; }

    const conditionArray = [];
    for(const inv of this.props.inventoryOptions) {
      if(inv.condition) {
        conditionArray.push(inv.condition);
      }
    }
    return getConditionsOrderedDeduped(conditionArray);
  }

  isAvailable(conditionObj) {

    if(!conditionObj || !conditionObj.key) {
      return false;
    }

    const conditions = this.getInventoryConditions();
    for(const cd of conditions) {
      if(cd.key === conditionObj.key) {
        return true;
      }
    }
    return false;
  }

  getOptionClass(conditionObj) {

    const baseClass = 'optionButton';

    if(!conditionObj || !conditionObj.key) {
      return baseClass;
    }

    const isDisabled = this.isAvailable(conditionObj) ? '' : ' disabled';
    const isSelected = this.props.value && this.props.value.key && this.props.value.key === conditionObj.key ? ' selected' : '';

    return `${baseClass}${isDisabled}${isSelected}`;
  }

  selectCondition(conditionObj) {
    if(this.isAvailable(conditionObj)) {
      this.props.setAttr(conditionObj);
    }
  }

  getSelectedConditionName() {
    return this.props.value ? this.props.value.name : tx.TX_null;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductPageInventoryConfig ProductPageInventoryConfigCondition'}>
      <div className='ppiConfigElementWrapper'>
        <div className='ppiConfigSelectable'>
          <div className='ppiConfigInlineLabel'>
            <div className='ppiConfigLabel'>{t(tx.TX_CONDITION)}</div>
            <div className='ppiSelectedValue'>{t(this.getSelectedConditionName())}</div>
          </div>
          <div className={'ppiConfigSelectWrapper ppiConfigOptionSelect'}>
            {this.props.productObj.productLine.getAllConditions(this.props.isBuylist).map((cd, i) => {
              return <button key={i} className={this.getOptionClass(cd)} type='button' onClick={() => this.selectCondition(cd)}>
                {t(cd.short)}
              </button>
            })}
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageInventoryConfigCondition));