import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../constants/strings';

import { 
  galleryIsInfiniteScroll,
  getGalleryPageSizeOptions, 
} from '../../../utils/gallery';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class GalleryPagination extends Component {

  constructor(props) {
    super(props);

    this.state = {
      infiniteRequestMade: false,
    };

    this.initTimeout = null;
    this.reqMadeTimeout = null;


    this.infiniteRef = React.createRef();

    this.checkScrollPosThrottled = _.throttle(this.checkScrollPos.bind(this), 100);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkScrollPosThrottled, false);

    this.initTimeout = setTimeout(this.checkScrollPosThrottled, 2000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScrollPosThrottled, false);

    if(this.initTimeout) { clearTimeout(this.initTimeout) }
    if(this.reqMadeTimeout) { clearTimeout(this.reqMadeTimeout) }
  }

  checkScrollPos() {
    if(!galleryIsInfiniteScroll(this.props.config) || this.props.dataLoading || this.props.infiniteLoading || this.state.infiniteRequestMade) { return null; }

    if(this.infiniteRef && this.infiniteRef.current) {

      const LOAD_TRIGGER_DISTANCE = 2000;

      const distancePastBottom = this.infiniteRef.current.getBoundingClientRect().y - window.innerHeight;

      if(distancePastBottom < LOAD_TRIGGER_DISTANCE) {
        this.makeInfiniteScrollReq();
      }
    }
  }

  makeInfiniteScrollReq() {
    // This whole song and dance is probably overkill since this is already throttled, but meant to avoid race conditions.  Fuck race conditions.
    this.setState({ infiniteRequestMade: true }, () => {
      
      this.props.addInfinitePage();

      this.reqMadeTimeout = setTimeout(() => {
        this.setState({ infiniteRequestMade: false });
      }, 50);
    });
  }

  getTotalPages() {
    if(!this.props.dataCount) {
      return 0;
    }
    return Math.ceil(parseInt(this.props.dataCount) / parseInt(this.props.pageSize));
  }

  changePageSize(evt) {
    this.props.changePage(1, evt.target.value);
  }

  changePageNum(pageNum) {
    this.props.changePage(parseInt(pageNum), parseInt(this.props.pageSize));
  }

  canGoBack() {
    return this.props.pageNum > 1;
  }

  canGoForward() {
    return this.getTotalPages() > this.props.pageNum;
  }

  goBackOne() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(parseInt(this.props.pageNum - 1));
  }

  goBackAll() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(1);
  }

  goForwardOne() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.props.pageNum + 1));
  }

  goForwardAll() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.getTotalPages()));
  }

  allInfiniteDataLoaded() {
    if(this.props.dataCount <= (this.props.pageSize * this.props.pageNum)) {
      return true;
    }
    return false;
  }

  render() {

    const {t} = this.props;

    return <div className={'GalleryPagination'}>
      <div className='gpPagination'>
        {galleryIsInfiniteScroll(this.props.config) ?
          <div className='gpInfiniteWrapper' ref={this.infiniteRef}>
            <div className='gpInfiniteActions'>
              {this.allInfiniteDataLoaded() === false && this.props.dataLoading === false ?
                <div className='gpInfiniteLoadMoreAction'>
                  {this.props.infiniteLoading ?
                    <div className='gpInfiniteLoadMoreActionIcon'>
                      <LoadingIcon />
                    </div> :
                    <button className='gpInfiniteLoadMoreActionButton' type='button' onClick={this.makeInfiniteScrollReq.bind(this)}>{t(tx.TX_GALLERY_LOAD_MORE_RESULTS)}</button>
                  }
                </div> :
                null
              }
            </div>
          </div> :
          <>
            <div className='gpControls'>
              <div className={this.canGoBack() ? 'lhsControls' : 'lhsControls disabled'}>
                <div className={'backAll'} onClick={this.goBackAll.bind(this)}>
                  <div className='leftArrow'></div>
                </div>
                <div className={'backOne'} onClick={this.goBackOne.bind(this)}>
                  <div className='leftArrow'></div>
                </div>
              </div>
              {parseInt(this.getTotalPages()) ?
                <div className='currentPage' dangerouslySetInnerHTML={
                  {__html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, {
                    current: parseInt(this.props.pageNum), 
                    total: parseInt(this.getTotalPages()), 
                    interpolation: {escapeValue: false}
                  })}} /> :
                <div className={'currentPage loadingPage'}>{t(tx.TX_LOADING)}</div>
              }
              <div className={this.canGoForward() ? 'rhsControls' : 'rhsControls disabled'}>
                <div className={'forwardOne'} onClick={this.goForwardOne.bind(this)}>
                  <div className='rightArrow'></div>
                </div>
                <div className={'forwardAll'} onClick={this.goForwardAll.bind(this)}>
                  <div className='rightArrow'></div>
                </div>
              </div>
            </div>
            <div className='gpSize'>
              <div className='gpSizeCopy'>{t(tx.TX_ADMIN_TABLE_RESULTS_PER_PAGE)}</div>
              <div className='gpSizeDropdown'>
                <select value={this.props.pageSize} onChange={this.changePageSize.bind(this)}>
                  {getGalleryPageSizeOptions(this.props.config).map((size, i) => {
                    return <option key={i} value={size}>{size}</option>;
                  })}
                </select>
              </div>
            </div>
          </>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(GalleryPagination));