
import CheckoutStepAuth from '../components/Checkout/blocks/steps/auth/CheckoutStepAuth';
import CheckoutStepShipingAddress from '../components/Checkout/blocks/steps/shipping-address/CheckoutStepShippingAddress';
import CheckoutStepShipingMethod from '../components/Checkout/blocks/steps/shipping-method/CheckoutStepShippingMethod';
import CheckoutStepPaymentMethod from '../components/Checkout/blocks/steps/payment-method/CheckoutStepPaymentMethod';
import CheckoutStepPaymentDetails from '../components/Checkout/blocks/steps/payment-details/CheckoutStepPaymentDetails';
import CheckoutStepReview from '../components/Checkout/blocks/steps/review/CheckoutStepReview';

import CheckoutStepBuylistAuth from '../components/Checkout/blocks/steps/buylist-auth/CheckoutStepBuylistAuth';
import CheckoutStepBuylistAddress from '../components/Checkout/blocks/steps/buylist-address/CheckoutStepBuylistAddress';
import CheckoutStepBuylistPaymentMethod from '../components/Checkout/blocks/steps/buylist-payment-method/CheckoutStepBuylistPaymentMethod';
import CheckoutStepBuylistConfirm from '../components/Checkout/blocks/steps/buylist-confirm/CheckoutStepBuylistConfirm';


export const CHECKOUT_KEY_AUTH = 'auth';
export const CHECKOUT_KEY_SHIPPING_ADDRESS = 'shipping-address';
export const CHECKOUT_KEY_SHIPPING_METHOD = 'shipping-method';
export const CHECKOUT_KEY_PAYMENT_METHOD = 'payment-method';
export const CHECKOUT_KEY_PAYMENT_DETAILS = 'payment-details';
export const CHECKOUT_KEY_REVIEW = 'review';

export const CHECKOUT_KEY_BUYLIST_AUTH = 'buylist-auth';
export const CHECKOUT_KEY_BUYLIST_ADDRESS = 'buylist-address';
export const CHECKOUT_KEY_BUYLIST_PAYMENT_METHOD = 'buylist-payment-method';
export const CHECKOUT_KEY_BUYLIST_CONFIRM = 'buylist-confirm';


// Checkout step objects

export const CHECKOUT_STEP_AUTH = {
	key: CHECKOUT_KEY_AUTH,
	component: CheckoutStepAuth,
};

export const CHECKOUT_STEP_SHIPPING_ADDRESS = {
	key: CHECKOUT_KEY_SHIPPING_ADDRESS,
	component: CheckoutStepShipingAddress,
	prereqKey: CHECKOUT_KEY_AUTH,
};

export const CHECKOUT_STEP_SELECT_SHIPPING_METHOD = {
	key: CHECKOUT_KEY_SHIPPING_METHOD,
	component: CheckoutStepShipingMethod,
	prereqKey: CHECKOUT_KEY_SHIPPING_ADDRESS,
};

export const CHECKOUT_STEP_SELECT_PAYMENT_METHOD = {
	key: CHECKOUT_KEY_PAYMENT_METHOD,
	component: CheckoutStepPaymentMethod,
	prereqKey: CHECKOUT_KEY_SHIPPING_METHOD,
};

export const CHECKOUT_STEP_SELECT_PAYMENT_DETAILS = {
	key: CHECKOUT_KEY_PAYMENT_DETAILS,
	component: CheckoutStepPaymentDetails,
	prereqKey: CHECKOUT_KEY_PAYMENT_METHOD,
};

export const CHECKOUT_STEP_REVIEW = {
	key: CHECKOUT_KEY_REVIEW,
	component: CheckoutStepReview,
};

// Buylist step objects

export const CHECKOUT_STEP_BUYLIST_AUTH = {
	key: CHECKOUT_KEY_BUYLIST_AUTH,
	component: CheckoutStepBuylistAuth,
};

export const CHECKOUT_STEP_BUYLIST_ADDRESS = {
	key: CHECKOUT_KEY_BUYLIST_ADDRESS,
	component: CheckoutStepBuylistAddress,
	prereqKey: CHECKOUT_KEY_BUYLIST_AUTH,
};

export const CHECKOUT_STEP_BUYLIST_SELECT_PAYMENT_METHOD = {
	key: CHECKOUT_KEY_BUYLIST_PAYMENT_METHOD,
	component: CheckoutStepBuylistPaymentMethod,
	prereqKey: CHECKOUT_KEY_BUYLIST_ADDRESS,
};

export const CHECKOUT_STEP_BUYLIST_CONFIRM = {
	key: CHECKOUT_KEY_BUYLIST_CONFIRM,
	component: CheckoutStepBuylistConfirm,
	prereqKey: CHECKOUT_KEY_BUYLIST_PAYMENT_METHOD,
};

// Step lists

export const CHECKOUT_STEPS = [
	CHECKOUT_STEP_AUTH, 
	CHECKOUT_STEP_SHIPPING_ADDRESS, 
	CHECKOUT_STEP_SELECT_SHIPPING_METHOD, 
	CHECKOUT_STEP_SELECT_PAYMENT_METHOD, 
	CHECKOUT_STEP_SELECT_PAYMENT_DETAILS, 
];

export const CHECKOUT_BUYLIST_STEPS = [
	CHECKOUT_STEP_BUYLIST_AUTH, 
	CHECKOUT_STEP_BUYLIST_ADDRESS, 
	CHECKOUT_STEP_BUYLIST_SELECT_PAYMENT_METHOD,
	CHECKOUT_STEP_BUYLIST_CONFIRM,
];