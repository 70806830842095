import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_SEARCH } from '../../../../../constants/icons';
import { PL_PERMALINK_MAGIC } from '../../../../../constants/product';
import * as tx from '../../../../../constants/strings';

import { ProductLine } from '../../../../../models/products';

import { 
  simulateTab,
} from '../../../../../utils/general';

import Checkbox from '../../../../Input/Checkbox';
import Icon from '../../../../Icons/Icon';
import LoadingIcon from '../../../../Icons/LoadingIcon';

import * as productActionCreators from '../../../../../actions/product';
import * as productLineActionCreators from '../../../../../actions/product-line';
const allActionCreators = Object.assign({}, productActionCreators, productLineActionCreators);

export class FilterElementMagicSet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputSearch: '',
      loading: false,

      expanded: true,
    }
  }

  async componentDidMount() {
    if(!this.getSetData()) {

      this.setState({ loading: true });

      const fixedLine = new ProductLine({ permalink: PL_PERMALINK_MAGIC });

      const setData = await this.props.productSetsByProductLine(fixedLine)
        .catch((errResp) => {
          console.error(errResp);
        });

      this.setState({ loading: false });

      if(!setData) { return null; }

      this.props.setCustomFilterOptions({
        [this.props.config.key]: setData.data,
      });

      this.props.productLineSetAdHocData({
        [PL_PERMALINK_MAGIC]: {
          sets: setData.data,
        }
      });

    } else {
      this.props.setCustomFilterOptions({
        [this.props.config.key]: this.getSetData(),
      });
    }
  }

  getCompositeId(opt) {
    const paramKey = this.props.config.key;
    const valueStr = opt.toString();
    return `${paramKey}|${valueStr}`;
  }

  getSetData() {
    if(this.props.productLine && this.props.productLine.data && this.props.productLine.data[PL_PERMALINK_MAGIC] && this.props.productLine.data[PL_PERMALINK_MAGIC].sets) {
      return this.props.productLine.data[PL_PERMALINK_MAGIC].sets;
    }
    return null;
  }

  changeSearch(evt) {
    this.setState({ inputSearch: evt.target.value });
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      simulateTab();
    }
  }

  clearInput() {
    this.setState({ inputSearch: '' });
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  getOptions() {

    if(!this.state.inputSearch) {
      return this.getSetData() || [];
    }

    const filteredOptions = [];
    const needle = this.state.inputSearch.toLowerCase();

    for(const elem of this.getSetData()) {

      const displayString = elem.name.toLowerCase();
      if(displayString.includes(needle)) {
        filteredOptions.push(elem);
      }
    }
    return filteredOptions;
  }

  getCurrentValue() {
    // try {
    //   return this.props.filterValue;
    // } catch(err) {
    //   console.error(err);
    // }
    return [];
  }

  toggleCheckbox(evt) {

    if(evt) { evt.stopPropagation(); }

    const updatedFilters = [...this.props.filterValue];
    const idx = updatedFilters.indexOf(evt.target.value);

    if(idx > -1) {
      updatedFilters.splice(idx, 1);
    } else {
      updatedFilters.push(evt.target.value);
    }

    const filterKey = this.props.config.key;
    this.props.setFilter({
      [filterKey]: updatedFilters,
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterElement FilterElementCheckboxes FilterElementMagicSet'}>
      <div className='feFilterTitle'>
        <div className={'feFilterTitleValue EllipsisElement'}>{t(this.props.config.label)}</div>
        <div className='feFilterTitleToggle' onClick={this.toggleExpanded.bind(this)}>
          <div className='feFilterTitleToggleElement FlexCenter'>
            {this.state.expanded ? 
              <>&mdash;</> :
              <>+</>
            }
          </div>
        </div>
      </div>
      <div className={`feFilterBody ${this.state.expanded ? 'open' : ''}`}>
        {this.state.loading ?
          <div className='feFilterLoading'>
            <div className='feLoadingWrapper'>
              <div className='feLoadingIcon'>
                <LoadingIcon />
              </div>
              <div className='feLoadingLabel'>{t(tx.TX_LOADING)}</div>
            </div>
          </div> :

          <div className='feFilterBodyLiner'>
            <div className='multiQuickSearchInput'>
              <input
                type='text'
                name={t(tx.TX_SEARCH)}
                value={this.state.inputSearch}
                placeholder={t(tx.TX_SEARCH)}
                onChange={this.changeSearch.bind(this)}
                onKeyPress={this.handleKeyPress.bind(this)} />
              <div className='searchOverlay'>
                <Icon value={ICON_SEARCH} iconClass='searchIcon' />
              </div>
              {this.state.inputSearch ?
                <div className='clearOverlay' onClick={this.clearInput.bind(this)}>&times;</div> :
                null 
              }
            </div>
            <div className='feFilterBodyLinerScroll'>
              {this.getOptions().map((opt, i) => {
                return <div className='feCheckboxWrapper' key={i}>
                  <div className='feCheckboxInputWrapper'>
                    <Checkbox 
                      name={this.props.config.key}
                      id={this.getCompositeId(opt.code)}
                      value={opt.code}
                      checked={this.props.filterValue.includes(opt.code.toString())}
                      onChange={this.toggleCheckbox.bind(this)} />
                  </div>
                  <label 
                    htmlFor={this.getCompositeId(opt.code)}
                    className='feCheckboxValueWrapper'>
                    {opt.name}
                  </label>
                </div>;
              })}
            </div>
          </div>
        }
      </div>




      
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterElementMagicSet));

