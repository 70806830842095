import React from 'react';

import {
  STORE_CODE,
  STORE_CODE_FLYINGBEAR,
  STORE_CODE_OASIS,
} from '../../constants/store';

import MaintenanceDefault from './MaintenanceDefault';
import MaintenanceOasisLaunch from './custom/oasis/MaintenanceOasisLaunch';

import './style/_maintenance.scss';

export const Maintenance = () => {

  const selectPage = () => {
    switch(STORE_CODE) {
      case STORE_CODE_FLYINGBEAR:
        return <MaintenanceOasisLaunch />;
      case STORE_CODE_OASIS:
        return <MaintenanceOasisLaunch />;
      default:
        return <MaintenanceDefault />;
    }
  }

  return (
    <div className='Maintenance'>
      {selectPage()}
    </div>
  );
};

export default Maintenance;




