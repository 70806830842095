import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
} from '../../constants/api';
import { 
  ICON_EMPTY,
  ICON_SEARCH, 
} from '../../constants/icons';
import * as tx from '../../constants/strings';

import { getStoreLanguage } from '../../utils/language';
import { 
  getPageLimit, 
  getPageOffset, 
} from '../../utils/request';
import { 
  getPageSizeOptions, 
} from '../../utils/table';

import AccountTitle from './blocks/AccountTitle';
import AccountOrdersLineItem from './blocks/AccountOrdersLineItem';
import { Icon } from '../Icons/Icon';
import LoadingIcon from '../Icons/LoadingIcon';

import './style/_account.scss';

import * as commonActionCreators from '../../actions/common';
import * as orderActionCreators from '../../actions/order';
const allActionCreators = Object.assign({}, commonActionCreators, orderActionCreators);

export class AccountOrders extends Component {

  constructor(props) {
    super(props);

    this.defaultPageSize = 10;

    this.state = {
      
      requestPending: false,
      requestError: null,

      orders: null,
      ordersCount: null,

      buylistOrders: null,
      buylistOrdersCount: null,

      inputQuickSearch: '', 

      quickSearchValue: '', 

      pageSize: this.defaultPageSize,
      pageNum: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.fetchMyOrders();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isBuylist !== this.props.isBuylist) {
      this.setState({
        pageNum: 1,
        inputQuickSearch: '', 
        quickSearchValue: '', 
      }, () => {
        this.fetchMyOrders();
      });
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async fetchMyOrders() {

    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      pageSize: this.state.pageSize,
      pageNum: this.state.pageNum,
    });

    const filters = {};

    if(this.state.quickSearchValue) {
      filters['quick'] = this.state.quickSearchValue;
    }

    if(this.props.isBuylist) {
      filters['buylist'] = 'true';
    }

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(this.state.pageNum, this.state.pageSize),
      [API_KEY_OFFSET]: getPageOffset(this.state.pageNum, this.state.pageSize),
    };

    const getParams = Object.assign({}, filters, controlParams);

    const ordersResp = await this.props.ordersFetchUserOrders(getParams, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }
      });

    if(!ordersResp) {
      return null;
    }

    this.setState({
      requestPending: false,
      requestError: null,
    });

    if(this.props.isBuylist) {
      this.setState({
        buylistOrders: ordersResp.data,
        buylistOrdersCount: parseInt(ordersResp.count),
      });
    } else {
      this.setState({
        orders: ordersResp.data,
        ordersCount: parseInt(ordersResp.count),
      });
    }
  }

  setQuickSearchFilter() {

  }

  changeQuickSearch() {

  }

  getOrders() {
    return this.props.isBuylist ? this.state.buylistOrders : this.state.orders;
  }

  getCount() {
    return this.props.isBuylist ? this.state.buylistOrdersCount : this.state.ordersCount;
  }

  getTotalPages() {
    if(!this.getCount()) {
      return 0;
    }
    return Math.ceil(parseInt(this.getCount()) / parseInt(this.state.pageSize));
  }

  canGoBack() {
    return this.state.pageNum > 1;
  }

  canGoForward() {
    return this.getTotalPages() > this.state.pageNum;
  }

  goBackOne() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(parseInt(this.state.pageNum - 1));
  }

  goBackAll() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(1);
  }

  goForwardOne() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.state.pageNum + 1));
  }

  goForwardAll() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.getTotalPages()));
  }

  changePageSize(evt) {
    this.setState({
      pageSize: evt.target.value,
      pageNum: 1,
    }, () => {
      this.fetchMyOrders();
    });
  }

  changePageNum(pageNum) {
    this.setState({
      pageNum: pageNum,
    }, () => {
      this.fetchMyOrders();
    });
  }

  render() {

    const {t} = this.props;
    const orders = this.getOrders();

    return <div className={'AccountOrders AccountPage'}>
      <AccountTitle
        title={this.props.isBuylist ? tx.TX_ACCOUNT_BUYLIST_ORDERS : tx.TX_MENU_ITEM_ORDERHISTORY}
        breadcrumbs={[]} />
      <div className='accountBody'>
        <div className='orderControlWrapper'>
          <form className='quickSearchForm' onSubmit={this.setQuickSearchFilter.bind(this)}>
            <div className='qsInnerWrapper'>
              <div className='qsInputWrapper'>
                <input 
                  placeholder={t(tx.TX_PLACEHOLDER_QUICK_SEARCH)}
                  value={this.props.quickSearchValue}
                  onChange={this.changeQuickSearch.bind(this)} />
              </div>
              <div className='qsButtonWrapper' onClick={this.setQuickSearchFilter.bind(this)}>
                <div className='qsButtonInnerWrapper'>
                  <Icon 
                    value={ICON_SEARCH} 
                    iconClass='qsIcon' />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className={'accountBlock orderBlock'}>
          <div className='accountBlockLiner'>
            <div className='accountBlockTitle'>{t(this.props.isBuylist ? tx.TX_ACCOUNT_BUYLIST_ORDERS : tx.TX_ORDERS)}</div>
            <div className='accountBlockBody'>
              {orders !== null ?
                <div className='ordersListWrapper'>
                  {orders.length === 0 ?
                    <div className='ordersListEmpty'>
                      <div className='ordersEmptyIconWrapper'>
                        <Icon 
                          value={ICON_EMPTY} 
                          iconClass='emptyIcon' />
                      </div>
                      <div className='ordersEmptyLabel'>{t(tx.TX_ACCOUNT_ORDERS_NOT_FOUND)}</div>
                    </div> :
                    <div className='ordersList'>
                      {orders.map((order, i) => {
                        return <div className='orderListItem' key={order.publicUuid}>
                          <AccountOrdersLineItem
                            isBuylist={this.props.isBuylist}
                            order={order} />
                        </div>
                      })}
                    </div>
                  }
                </div> :
                null
              }
            </div>
          </div>

          <div className='accountBlockPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
            <div className='accountBlockPendingScreen'></div>
            <div className='accountBlockPendingWrapper'>
              <LoadingIcon />
            </div>
          </div>
        </div>

        <div className='accountPagination'>
          <div className='atpControls'>
            <div className={this.canGoBack() ? 'lhsControls' : 'lhsControls disabled'}>
              <div className={'backAll'} onClick={this.goBackAll.bind(this)}>
                <div className='leftArrow'></div>
              </div>
              <div className={'backOne'} onClick={this.goBackOne.bind(this)}>
                <div className='leftArrow'></div>
              </div>
            </div>
            {parseInt(this.getTotalPages()) ?
              <div className='currentPage' dangerouslySetInnerHTML={
                {__html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, {
                  current: parseInt(this.state.pageNum).toLocaleString(this.getLanguage()), 
                  total: parseInt(this.getTotalPages()).toLocaleString(this.getLanguage()), 
                  interpolation: {escapeValue: false}
                })}} /> :
              <div className={'currentPage loadingPage'}>{t(tx.TX_LOADING)}</div>
            }
            <div className={this.canGoForward() ? 'rhsControls' : 'rhsControls disabled'}>
              <div className={'forwardOne'} onClick={this.goForwardOne.bind(this)}>
                <div className='rightArrow'></div>
              </div>
              <div className={'forwardAll'} onClick={this.goForwardAll.bind(this)}>
                <div className='rightArrow'></div>
              </div>
            </div>
          </div>
          <div className='atpSize'>
            <div className='atpSizeCopy'>{t(tx.TX_ADMIN_TABLE_RESULTS_PER_PAGE)}</div>
            <div className='atpSizeDropdown'>
              <select value={this.state.pageSize} onChange={this.changePageSize.bind(this)}>
                {getPageSizeOptions().map((size, i) => {
                  return <option key={i} value={size}>{size}</option>;
                })}
              </select>
            </div>
          </div>
        </div>

      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountOrders));