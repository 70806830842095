import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  COUPON_DISCOUNT_TYPE_FIXED,
  COUPON_DISCOUNT_TYPE_PERCENTAGE,
} from '../../../constants/promotions';
import * as tx from '../../../constants/strings';

import { formatPrice } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class CouponDetailsView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {

    const {t} = this.props;

    return <div className={'CouponDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_COUPON)}</div>
      </div>
      {this.props.coupon.isEnabled === false ?
        <div className='detailsMethodDisabled'>{t(tx.TX_CURRENTLY_DISABLED)}</div> : 
        null
      }
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_NAME)}</div>
                  <div className='adminViewValue'>{this.props.coupon.name}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_MARKETING_COUPON_CODE)}</div>
                  <div className='adminViewValue'>{this.props.coupon.code.toUpperCase()}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_MARKETING_COUPON_DISCOUNT_TYPE)}</div>
                  <div className='adminViewValue'>{t(this.props.coupon.displayType)}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_MARKETING_COUPON_DISCOUNT_AMOUNT)}</div>
                  {this.props.coupon.discountType === COUPON_DISCOUNT_TYPE_FIXED ?
                    <div className='adminViewValue'dangerouslySetInnerHTML={{ __html: formatPrice(this.props.coupon.discountAmount, { addTags: true, language: this.getLanguage() }) }} /> :
                    null
                  }
                  {this.props.coupon.discountType === COUPON_DISCOUNT_TYPE_PERCENTAGE ?
                    <div className='adminViewValue'>{`${this.props.coupon.discountAmount.toFixed(1)}%`}</div> :
                    null
                  }
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_SETTINGS_MIN_PURCHASE)}</div>
                  <div className='adminViewValue'dangerouslySetInnerHTML={{ __html: formatPrice(this.props.coupon.minPurchase, { addTags: true, language: this.getLanguage() }) }} />
                </div>
                {this.props.coupon.perUserUses ?
                  <div className='adminViewItem'>
                    <div className='adminViewLabel'>{t(tx.TX_MARKETING_COUPON_USES_PER_USER)}</div>
                    <div className='adminViewValue'>{t(this.props.coupon.perUserUses)}</div>
                  </div> :
                  null
                }
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_DESCRIPTION)}</div>
                  <div className={this.props.coupon.description ? 'adminViewValue' : 'adminViewValue nullValue'}>{this.props.coupon.description ? this.props.coupon.description : t(tx.TX_NONE)}</div>
                </div>
              </div>
              <div className='adminViewButtonWrapper'>
                <div className={'couponDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.couponPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CouponDetailsView));