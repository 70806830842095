import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Pickup } from '../../../../models/shipments';

import { 
  getDescriptionError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import * as tx from '../../../../constants/strings';

import '../../style/_vieworder.scss';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class OrderModalPickupCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      inputNote: '',

      errorNote: '',

      requestPending: false,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_CREATE;
  }

  changeNote(evt) {
    this.setState({
      inputNote: evt.target.value,
    }, () => {
      if(this.state.errorNote) {
        this.validateNote();
      }
    });
  }

  validateNote() {
    this.setState({ errorNote: getDescriptionError(this.state.inputNote, true) });
  }

  validateAll() {
    const errorObj = {
      errorNote: getDescriptionError(this.state.inputNote, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const newPickup = new Pickup({
        isDropoff: false,
        isCompleteOrder: true,
        initNote: this.state.inputNote,
      });

      const createResp = await this.props.ordersAddPickup(newPickup.getApiDataCreate(), this.props.order.publicUuid, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!createResp) { return null; }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalPickupCreate OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'pickupsCreateForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          <div className='orderModalCopy'>{t(tx.TX_ORDER_PICKUPS_CREATE_COPY)}</div>

          <div className='omFieldWrapper'>
            <div className={'omFieldLabel omOptional'}>{t(tx.TX_ORDER_PICKUPS_ADD_CUSTOMER_NOTE)}</div>
            <div className='omInputWrapper'>
              <textarea
                type='text'
                className={this.state.errorNote ? 'InputError' : ''}
                value={this.state.inputNote}
                onChange={this.changeNote.bind(this)}
                onBlur={this.validateNote.bind(this)}
                placeholder={t(tx.TX_OPTIONAL)}
                maxLength={190}></textarea>
            </div>
            {this.state.errorNote ?
              <div className={'FieldError'}>{t(this.state.errorNote)}</div> :
              null
            }
          </div>

        </form>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalPickupCreate));