import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { CHECKOUT_STEP_SHIPPING_ADDRESS } from '../../../../../constants/checkout';
import * as tx from '../../../../../constants/strings';

import { addPaymentInfoEvent } from '../../../../../utils/analytics';
import { 
  checkoutGetIsStoreCreditApplied, 
  checkoutGetSelectedShippingMethod, 
  getCartGrandTotal,  
  getCheckoutStepData,
} from '../../../../../utils/checkout';
import { formatPrice } from '../../../../../utils/formatting';
import { getStoreLanguage } from '../../../../../utils/language';

import Checkbox from '../../../../Input/Checkbox';
import LoadingIcon from '../../../../Icons/LoadingIcon';
import Radio from '../../../../Input/Radio';
import StaticImage from '../../../../Image/StaticImage';

import * as checkoutActionCreators from '../../../../../actions/checkout';
import * as userActionCreators from '../../../../../actions/user';
let allActionCreators = Object.assign({}, checkoutActionCreators, userActionCreators);

export class CheckoutStepPaymentMethodInput extends Component {

  constructor(props) {
    super(props);

    let currentPaymentMethod = null;
    const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);

    if(stepData && stepData.data && stepData.data.paymentMethod) {
      currentPaymentMethod = stepData.data.paymentMethod;
    }

    this.state = {
      
      selectedMethod: currentPaymentMethod, 

      paymentMethods: null,
      paymentMethodsLoading: true,

      applyStoreCredit: checkoutGetIsStoreCreditApplied(this.props.checkout.stepData),
    };

    this.controller = null;
  }

  componentDidMount() {
    this.fetchPaymentMethods();
    this.fetchMe();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(checkoutGetSelectedShippingMethod(prevProps.checkout.stepData), checkoutGetSelectedShippingMethod(this.props.checkout.stepData))) {
      const visibleMethods = [ ...this.getApplicablePaymentMethods(), ...this.getConditionalPaymentMethods() ];

      let foundMethod = false;
      for(const pm of visibleMethods) {
        if(this.state.selectedMethod && this.state.selectedMethod.publicUuid === pm.publicUuid) {
          foundMethod = true;
        }
      }

      if(this.state.selectedMethod !== null && !foundMethod) {
        this.setState({ selectedMethod: null }, () => {
          this.props.setStepData(this.props.config, {
            paymentMethod: null, 
          });
        });
      }
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async fetchMe() {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    await this.props.usersFetchMe(controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }
      });
  }

  fetchPaymentMethods() {

    this.setState({
      paymentMethods: null,
      paymentMethodsLoading: true,
    });

    if(!this.state.paymentMethods) {     
      this.props.checkoutFetchPaymentMethods()
      .then((resp) => {

        this.setState({
          paymentMethods: resp,
          paymentMethodsLoading: false,
        }, () => {
          
          let validatedSelectedMethod = null;
          const applicableMethods = this.getApplicablePaymentMethods();

          if(this.state.selectedMethod && this.state.selectedMethod.public_uuid && applicableMethods) {

            for(const payMethod of applicableMethods) {
              if(payMethod.public_uuid && payMethod.public_uuid === this.state.selectedMethod.public_uuid) {
                validatedSelectedMethod = this.state.selectedMethod;
                break;
              }
            }
            if(validatedSelectedMethod === null) {
              this.props.setStepData(this.props.config, {
                paymentMethod: null, 
              });
              this.setState({
                selectedMethod: null, 
              });
            }
          }
        });
      })
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
        this.setState({ paymentMethodsLoading: false, });
      });
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.state.selectedMethod && this.state.selectedMethod) {
      this.props.setStepData(this.props.config, {
        paymentMethod: this.state.selectedMethod, 
      });
    }
  }

  async getStoreTaxRate() {
    // TODO: store tax rate method
  }

  async setPaymentMethod(evt) {
    const methodUuid = evt.target.value;
    if(!methodUuid || !this.state.paymentMethods || !this.state.paymentMethods.length) { return null; }

    for(const payMethod of this.state.paymentMethods) {
      if(payMethod.public_uuid === methodUuid) {

        if(payMethod.allowPaymentAtPickup) {
          const shippingMethod = checkoutGetSelectedShippingMethod(this.props.checkout.stepData);
          if(shippingMethod.isEnabled && shippingMethod.isPickup) {

            // TODO: hard-coded to oasis; should use getStoreTaxRate method above to call server
            const taxRateSales = 7.75;
            // const taxRate = await this.getStoreTaxRate()
            //   .catch((err) => {
            //     console.error(err);
            //   });

            if(taxRateSales) {

              const shippingAddressData = getCheckoutStepData(CHECKOUT_STEP_SHIPPING_ADDRESS, this.props.checkout.stepData);

              this.props.setStepData(CHECKOUT_STEP_SHIPPING_ADDRESS, Object.assign({}, shippingAddressData, {
                taxRateSales: taxRateSales || 0,
              }));
            }
          }
        }

        // Fire add_payment_info analytics event when user selects payment method
        addPaymentInfoEvent(this.props.cart.currentCart, payMethod, {});

        this.setState({ selectedMethod: payMethod }, () => {
          this.props.setStepData(this.props.config, {
            paymentMethod: payMethod, 
          });
        });
        return null;
      }
    }
  }

  toggleApplyStoreCredit(evt) {
    this.setState({ applyStoreCredit: !this.state.applyStoreCredit }, () => {

      const cartTotal = getCartGrandTotal(this.props.cart.currentCart, this.props.checkout.stepData);
      const noPaymentRequired = this.state.applyStoreCredit && cartTotal <= this.props.user.user.storeCredit.balance;

      const stepData = {
        applyStoreCredit: this.state.applyStoreCredit, 
      };

      if(this.state.applyStoreCredit) {

        stepData['availableStoreCredit'] = noPaymentRequired ? cartTotal : this.props.user.user.storeCredit.balance;
        stepData['noPaymentRequired'] = noPaymentRequired;

        if(noPaymentRequired) {
          stepData['paymentMethod'] = null;

          this.setState({ selectedMethod: null });
        }

      } else {
        stepData['availableStoreCredit'] = 0;
        stepData['noPaymentRequired'] = false;
      }

      this.props.setStepData(this.props.config, stepData);
    });
  }

  hasPaymentMethods() {
    if(!this.state.paymentMethods || !this.state.paymentMethods.length) { return false; }
    return this.getConditionalPaymentMethods().length > 0 || this.getApplicablePaymentMethods().length > 0;
  }

  getApplicablePaymentMethods() {
    if(!this.state.paymentMethods || !this.state.paymentMethods.length) { return []; }

    const respMethods = [];
    for(const pm of this.state.paymentMethods) {
      if(pm.conditionalDisplay === false) {
        respMethods.push(pm);
      }
    }
    return respMethods;
  }

  getConditionalPaymentMethods() {
    if(!this.state.paymentMethods || !this.state.paymentMethods.length) { return []; }

    const respMethods = [];
    const shippingMethod = checkoutGetSelectedShippingMethod(this.props.checkout.stepData);

    for(const pm of this.state.paymentMethods) {
      if(pm.conditionalDisplay === true) {
        if(shippingMethod && shippingMethod.allowPaymentAtPickup === true && pm.allowPaymentAtPickup) {
          respMethods.push(pm);
        }
      }
    }
    return respMethods;
  }

  isOptionSelected(option) {
    if(!option || !option.public_uuid) { return false; }
    if(!this.state.selectedMethod || !this.state.selectedMethod.public_uuid) { return false; }
    return this.state.selectedMethod.public_uuid === option.public_uuid ? true : false;
  }

  render() {

    const {t} = this.props;

    return <div className={'CheckoutStepPaymentMethodInput CheckoutStepComponent'}>
      <div className='cspmLiner'>
        <form className={'checkoutStepRadioForm'} onSubmit={this.handleSubmit.bind(this)}>
          <div className='checkoutFormSubheader'>{t(tx.TX_CHECKOUT_PAYMENT_METHOD)}</div>
          {this.state.paymentMethodsLoading ?
            <div className='checkoutRadioLoadingWrapper'>
              <LoadingIcon iconClass='iconElement' />
            </div> :
            <>
              {this.props.user.user && this.props.user.user.storeCredit && this.props.user.user.storeCredit.balance ?
                <div className='storeCreditWrapper'>
                  <div 
                    className='storeCreditPrompt' 
                    dangerouslySetInnerHTML={{ 
                      __html: t(tx.TX_CHECKOUT_STORE_CREDIT_PROMPT, { 
                        balance: `<span class='balanceEm'>${formatPrice(this.props.user.user.storeCredit.balance, { language: this.getLanguage() })}</span>` 
                      })
                    }} />
                  <div className='storeCreditToggle'>
                    <div className='toggleInputWrapper'>
                      <Checkbox
                        id={`store-credit-apply`}
                        className='cbInput'
                        name='apply-store-credit'
                        adminTheme={false}
                        disabled={false}
                        value={'apply'}
                        checked={this.state.applyStoreCredit}
                        onChange={this.toggleApplyStoreCredit.bind(this)} />
                    </div>
                    <label htmlFor={`store-credit-apply`}>
                      <div className='toggleLabelWrapper'>{t(tx.TX_CHECKOUT_STORE_CREDIT_APPLY)}</div>
                    </label>
                  </div>
                </div> :
                null
              }
              {this.state.applyStoreCredit && getCartGrandTotal(this.props.cart.currentCart, this.props.checkout.stepData) <= this.props.user.user.storeCredit.balance ?
                <></> :
                <>
                  {this.hasPaymentMethods() ?
                    <div className='radioOptionsWrapper'>
                      
                      {this.getConditionalPaymentMethods().map((opt, j) => {
                        return <div key={j} className={this.isOptionSelected(opt) ? 'radioOptionElementWrapper selected' : 'radioOptionElementWrapper'}>
                          <div className='radioInputWrapper'>
                            <Radio
                              id={`paymentMethod-cond-${j}`}
                              className='pmInput'
                              name='payment-method'
                              adminTheme={false}
                              disabled={false}
                              value={opt.public_uuid}
                              checked={this.isOptionSelected(opt)}
                              onChange={this.setPaymentMethod.bind(this)} />
                          </div>
                          <label htmlFor={`paymentMethod-cond-${j}`}>
                            <div className='radioLabelWrapper'>
                              <div className='radioTitle'>{t(opt.name)}</div>
                              <div className='radioSubtitle'>{opt.description}</div>
                            </div>
                          </label>
                        </div>;
                      })}

                      {this.getApplicablePaymentMethods().map((opt, i) => {
                        return <div key={i} className={this.isOptionSelected(opt) ? 'radioOptionElementWrapper selected' : 'radioOptionElementWrapper'}>
                          <div className='radioInputWrapper'>
                            <Radio
                              id={`paymentMethod-${i}`}
                              className='pmInput'
                              name='payment-method'
                              adminTheme={false}
                              disabled={false}
                              value={opt.public_uuid}
                              checked={this.isOptionSelected(opt)}
                              onChange={this.setPaymentMethod.bind(this)} />
                          </div>
                          <label htmlFor={`paymentMethod-${i}`}>
                            {opt.schema && opt.schema.displayLogo ?
                              <div className='radioLabelWrapper'>
                                <div className='radioTitle imgTitle'>
                                  <div className='imgTitleWrapper'>
                                    <StaticImage 
                                      imgObj={{
                                        src: opt.schema.displayLogo, 
                                        alt: opt.getDisplayName(t), 
                                        noTranslate: true,
                                      }} />
                                  </div>
                                  {!opt.schema.hideLabel ?
                                    <div className='imgTitleLabel'>{opt.getDisplayName(t)}</div> :
                                    null
                                  }
                                </div>
                                <div className='radioSubtitle'>{opt.description}</div>
                              </div> :
                              <div className='radioLabelWrapper'>
                                <div className='radioTitle'>{opt.getDisplayName(t)}</div>
                                <div className='radioSubtitle'>{opt.description}</div>
                              </div>
                            }
                          </label>
                        </div>;
                      })}
                    </div> :
                    <div className='radioNoOptions'>{t(tx.TX_CHECKOUT_NO_PAYMENT_METHODS)}</div>
                  }
                </>
              }
            </>
          }
        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    checkout: state.checkout,
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepPaymentMethodInput));