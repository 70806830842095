import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';

import StoreHoursChile from './blocks/StoreHoursChile';

import './style/_storehours.scss';

const allActionCreators = Object.assign({});

export class StoreHours extends Component {

  static hasHours() {
    return process.env.REACT_APP_STORE_CODE === 'oasischile';
  }

  render() {

    if(process.env.REACT_APP_STORE_CODE === 'oasischile') {
      return <StoreHoursChile />;
    }

    const {t} = this.props;

    return <div className={'StoreHours'}>
      <div className={`storeHoursWrapper`}>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StoreHours));