



// 
// COMMON STRINGS
// 

// Action verbs
export const TX_BUY = 'TX_BUY';
export const TX_SELL = 'TX_SELL';
export const TX_PLAY = 'TX_PLAY';

// Common words/single concpets
export const TX_ACTION = 'TX_ACTION';
export const TX_ACTIONS = 'TX_ACTIONS';
export const TX_ADD = 'TX_ADD';
export const TX_ADD_NOUN = 'TX_ADD_NOUN';
export const TX_ADD_TO_BUYLIST = 'TX_ADD_TO_BUYLIST';
export const TX_ADD_TO_CART = 'TX_ADD_TO_CART';
export const TX_ADDED = 'TX_ADDED';
export const TX_ADDRESS = 'TX_ADDRESS';
export const TX_ADMIN = 'TX_ADMIN';
export const TX_AFTER_NOUN = 'TX_AFTER_NOUN';
export const TX_ALL = 'TX_ALL';
export const TX_AMAZON = 'TX_AMAZON';
export const TX_AMOUNT = 'TX_AMOUNT';
export const TX_BACK = 'TX_BACK';
export const TX_BALANCE = 'TX_BALANCE';
export const TX_BILLING_ADDRESS = 'TX_BILLING_ADDRESS';
export const TX_BUY_PRICE = 'TX_BUY_PRICE';
export const TX_BUYLIST = 'TX_BUYLIST';
export const TX_BUYLIST_CART = 'TX_BUYLIST_CART';
export const TX_BUYLIST_CARTS = 'TX_BUYLIST_CARTS';
export const TX_BUYLIST_TITLE = 'TX_BUYLIST_TITLE';
export const TX_CANCEL = 'TX_CANCEL';
export const TX_CART = 'TX_CART';
export const TX_CATALOG = 'TX_CATALOG';
export const TX_CHANGE = 'TX_CHANGE';
export const TX_CHARACTERS_REMAINING = 'TX_CHARACTERS_REMAINING';
export const TX_CLEAR = 'TX_CLEAR';
export const TX_CLEAR_ALL = 'TX_CLEAR_ALL';
export const TX_CLOSE = 'TX_CLOSE';
export const TX_CLOSED = 'TX_CLOSED';
export const TX_CODE = 'TX_CODE';
export const TX_COLLECTOR_NUMBER = 'TX_COLLECTOR_NUMBER';
export const TX_COMING_SOON = 'TX_COMING_SOON';
export const TX_COMPLETE = 'TX_COMPLETE';
export const TX_CONDITION = 'TX_CONDITION';
export const TX_CONFIGURATION = 'TX_CONFIGURATION';
export const TX_CONFIRM = 'TX_CONFIRM';
export const TX_CONNECT = 'TX_CONNECT';
export const TX_CONTACT_US = 'TX_CONTACT_US';
export const TX_COST = 'TX_COST';
export const TX_COUPON = 'TX_COUPON';
export const TX_CREATE = 'TX_CREATE';
export const TX_CREATE_ACCOUNT = 'TX_CREATE_ACCOUNT';
export const TX_CREDIT_CARD = 'TX_CREDIT_CARD';
export const TX_CURRENT_PASSWORD = 'TX_CURRENT_PASSWORD';
export const TX_CURRENTLY_DISABLED = 'TX_CURRENTLY_DISABLED';
export const TX_CUSTOMER = 'TX_CUSTOMER';
export const TX_CUSTOMERS = 'TX_CUSTOMERS';
export const TX_DASHBOARD = 'TX_DASHBOARD';
export const TX_DATE = 'TX_DATE';
export const TX_DEFAULT = 'TX_DEFAULT';
export const TX_DELETE = 'TX_DELETE';
export const TX_DELIVERY = 'TX_DELIVERY';
export const TX_DESCRIPTION = 'TX_DESCRIPTION';
export const TX_DETAILS = 'TX_DETAILS';
export const TX_DIMENSIONS = 'TX_DIMENSIONS';
export const TX_DIMENSIONS_UNIT = 'TX_DIMENSIONS_UNIT';
export const TX_DISABLED = 'TX_DISABLED';
export const TX_DISCARD = 'TX_DISCARD';
export const TX_DISCARD_CHANGES = 'TX_DISCARD_CHANGES';
export const TX_DISCOUNT = 'TX_DISCOUNT';
export const TX_DONE = 'TX_DONE';
export const TX_DOWNLOAD = 'TX_DOWNLOAD';
export const TX_DURATION = 'TX_DURATION';
export const TX_EBAY = 'TX_EBAY';
export const TX_EDIT = 'TX_EDIT';
export const TX_EMAIL_ADDRESS = 'TX_EMAIL_ADDRESS';
export const TX_ENABLED = 'TX_ENABLED';
export const TX_ENABLED_QUESTION = 'TX_ENABLED_QUESTION';
export const TX_ERROR = 'TX_ERROR';
export const TX_EVENTS = 'TX_EVENTS';
export const TX_EXPORT = 'TX_EXPORT';
export const TX_EXPORT_FILTERED = 'TX_EXPORT_FILTERED';
export const TX_EXPORT_SELECTED = 'TX_EXPORT_SELECTED';
export const TX_FILENAME = 'TX_FILENAME';
export const TX_FILTER = 'TX_FILTER';
export const TX_FILTERS = 'TX_FILTERS';
export const TX_FILTERS_SPECIFIC = 'TX_FILTERS_SPECIFIC';
export const TX_FINISH_VERB = 'TX_FINISH_VERB';
export const TX_FIRST = 'TX_FIRST';
export const TX_FIRST_NAME = 'TX_FIRST_NAME';
export const TX_FORGOT_PASSWORD = 'TX_FORGOT_PASSWORD';
export const TX_FORGOT_PASSWORD_QUESTION = 'TX_FORGOT_PASSWORD_QUESTION';
export const TX_FREE = 'TX_FREE';
export const TX_GUEST_USER = 'TX_GUEST_USER';
export const TX_HEIGHT = 'TX_HEIGHT';
export const TX_HEIGHT_UNIT = 'TX_HEIGHT_UNIT';
export const TX_HELLO = 'TX_HELLO';
export const TX_HELLO_NAME = 'TX_HELLO_NAME';
export const TX_HELLO_EXCLAIMATION = 'TX_HELLO_EXCLAIMATION';
export const TX_HISTORY = 'TX_HISTORY';
export const TX_HOME = 'TX_HOME';
export const TX_HOURS = 'TX_HOURS';
export const TX_ID = 'TX_ID';
export const TX_IN_STOCK = 'TX_IN_STOCK';
export const TX_IN_STORE = 'TX_IN_STORE';
export const TX_INITIALIZING = 'TX_INITIALIZING';
export const TX_INTEGRATION = 'TX_INTEGRATION';
export const TX_INVENTORY = 'TX_INVENTORY';
export const TX_LANGUAGE = 'TX_LANGUAGE';
export const TX_LAST_LOGIN = 'TX_LAST_LOGIN';
export const TX_LAST_NAME = 'TX_LAST_NAME';
export const TX_LAST_ORDER = 'TX_LAST_ORDER';
export const TX_LENGTH = 'TX_LENGTH';
export const TX_LENGTH_UNIT = 'TX_LENGTH_UNIT';
export const TX_LOADING = 'TX_LOADING';
export const TX_LOGIN = 'TX_LOGIN';
export const TX_LOGIN_SHORT = 'TX_LOGIN_SHORT';
export const TX_LOGOUT = 'TX_LOGOUT';
export const TX_MAKE_PAYMENT = 'TX_MAKE_PAYMENT';
export const TX_MANAGE = 'TX_MANAGE';
export const TX_MANUAL = 'TX_MANUAL';
export const TX_MARKUP = 'TX_MARKUP';
export const TX_MAX = 'TX_MAX';
export const TX_MESSAGE = 'TX_MESSAGE';
export const TX_MESSAGE_SENT = 'TX_MESSAGE_SENT';
export const TX_MIN = 'TX_MIN';
export const TX_MINIMUM = 'TX_MINIMUM';
export const TX_MORE = 'TX_MORE';
export const TX_NAME = 'TX_NAME';
export const TX_NEW_PASSWORD = 'TX_NEW_PASSWORD';
export const TX_NEXT = 'TX_NEXT';
export const TX_NEXT_NOUN = 'TX_NEXT_NOUN';
export const TX_NEXT_COLON_NOUN = 'TX_NEXT_COLON_NOUN';
export const TX_NO = 'TX_NO';
export const TX_NO_RESULTS = 'TX_NO_RESULTS';
export const TX_NONE = 'TX_NONE';
export const TX_NOT_ALLOWED = 'TX_NOT_ALLOWED';
export const TX_NOTES = 'TX_NOTES';
export const TX_NOTICE = 'TX_NOTICE';
export const TX_NUMBER = 'TX_NUMBER';
export const TX_OFF = 'TX_OFF';
export const TX_OKAY = 'TX_OKAY';
export const TX_ON = 'TX_ON';
export const TX_OPTIONAL = 'TX_OPTIONAL';
export const TX_OR = 'TX_OR';
export const TX_ORDER = 'TX_ORDER';
export const TX_ORDERS = 'TX_ORDERS';
export const TX_OTHER = 'TX_OTHER';
export const TX_OUT_OF_STOCK = 'TX_OUT_OF_STOCK';
export const TX_PAGE = 'TX_PAGE';
export const TX_PAGES = 'TX_PAGES';
export const TX_PAYMENT = 'TX_PAYMENT';
export const TX_PASSWORD = 'TX_PASSWORD';
export const TX_PAYMENT_DETAILS = 'TX_PAYMENT_DETAILS';
export const TX_PAYMENT_METHOD = 'TX_PAYMENT_METHOD';
export const TX_PERMALINK = 'TX_PERMALINK';
export const TX_PICKUP = 'TX_PICKUP';
export const TX_PICKUPS = 'TX_PICKUPS';
export const TX_PHONE_NUMBER = 'TX_PHONE_NUMBER';
export const TX_PREORDER = 'TX_PREORDER';
export const TX_PRICE = 'TX_PRICE';
export const TX_PRICE_RANGE = 'TX_PRICE_RANGE';
export const TX_PRINT = 'TX_PRINT';
export const TX_PRINTING = 'TX_PRINTING';
export const TX_PRODUCT = 'TX_PRODUCT';
export const TX_PRODUCT_LINE = 'TX_PRODUCT_LINE';
export const TX_PRODUCT_LINES = 'TX_PRODUCT_LINES';
export const TX_PURCHASE = 'TX_PURCHASE';
export const TX_QUANTITY = 'TX_QUANTITY';
export const TX_QUANTITY_ABBR = 'TX_QUANTITY_ABBR';
export const TX_RANGE = 'TX_RANGE';
export const TX_RECENTLY_ADDED = 'TX_RECENTLY_ADDED';
export const TX_REFUNDED = 'TX_REFUNDED';
export const TX_REFUNDED_QTY = 'TX_REFUNDED_QTY';
export const TX_REGISTRATION_DATE = 'TX_REGISTRATION_DATE';
export const TX_RELEASE_DATE = 'TX_RELEASE_DATE';
export const TX_REMOVE = 'TX_REMOVE';
export const TX_RESERVE_N = 'TX_RESERVE_N';
export const TX_RESET = 'TX_RESET';
export const TX_RESET_PASSWORD = 'TX_RESET_PASSWORD';
export const TX_RESULT = 'TX_RESULT';
export const TX_RESULTS = 'TX_RESULTS';
export const TX_RESULTS_NUM = 'TX_RESULTS_NUM';
export const TX_RESULTS_ONE = 'TX_RESULTS_ONE';
export const TX_RETYPE_PASSWORD = 'TX_RETYPE_PASSWORD';
export const TX_RULE = 'TX_RULE';
export const TX_SALES = 'TX_SALES';
export const TX_SAVE = 'TX_SAVE';
export const TX_SAVING = 'TX_SAVING';
export const TX_SEARCH = 'TX_SEARCH';
export const TX_SEE_ALL = 'TX_SEE_ALL';
export const TX_SELECT = 'TX_SELECT';
export const TX_SELECT_ALL = 'TX_SELECT_ALL';
export const TX_SELECTED = 'TX_SELECTED';
export const TX_SELL_PRICE = 'TX_SELL_PRICE';
export const TX_SELLABLE_QUANTITY = 'TX_SELLABLE_QUANTITY';
export const TX_SEND = 'TX_SEND';
export const TX_SET = 'TX_SET';
export const TX_SET_NAME = 'TX_SET_NAME';
export const TX_SETTINGS = 'TX_SETTINGS';
export const TX_SHIPMENT = 'TX_SHIPMENT';
export const TX_SHIPPING = 'TX_SHIPPING';
export const TX_SHIPPING_ADDRESS = 'TX_SHIPPING_ADDRESS';
export const TX_SHIPPING_METHOD = 'TX_SHIPPING_METHOD';
export const TX_SHIPPING_METHODS = 'TX_SHIPPING_METHODS';
export const TX_SHOP = 'TX_SHOP';
export const TX_SHOP_ALL = 'TX_SHOP_ALL';
export const TX_SHOP_NOUN = 'TX_SHOP_NOUN';
export const TX_SHOP_NOW = 'TX_SHOP_NOW';
export const TX_SIGN_UP = 'TX_SIGN_UP';
export const TX_SKU = 'TX_SKU';
export const TX_STATUS = 'TX_STATUS';
export const TX_STORE = 'TX_STORE';
export const TX_SUBMIT_REQUEST = 'TX_SUBMIT_REQUEST';
export const TX_SUBTOTAL = 'TX_SUBTOTAL';
export const TX_SUCCESS = 'TX_SUCCESS';
export const TX_REQUIRED = 'TX_REQUIRED';
export const TX_RETURN = 'TX_RETURN';
export const TX_TARGET_QUANTITY = 'TX_TARGET_QUANTITY';
export const TX_TAX = 'TX_TAX';
export const TX_TCGPLAYER_ID = 'TX_TCGPLAYER_ID';
export const TX_TERMS = 'TX_TERMS';
export const TX_THUMBNAIL = 'TX_THUMBNAIL';
export const TX_TIME = 'TX_TIME';
export const TX_TODAY = 'TX_TODAY';
export const TX_TOTAL = 'TX_TOTAL';
export const TX_TOTAL_QUANTITY = 'TX_TOTAL_QUANTITY';
export const TX_TYPE = 'TX_TYPE';
export const TX_UPDATE = 'TX_UPDATE';
export const TX_USER = 'TX_USER';
export const TX_USERS = 'TX_USERS';
export const TX_VIEW = 'TX_VIEW';
export const TX_WARNING = 'TX_WARNING';
export const TX_WEIGHT = 'TX_WEIGHT';
export const TX_WEIGHT_UNIT = 'TX_WEIGHT_UNIT';
export const TX_WIDTH = 'TX_WIDTH';
export const TX_WIDTH_UNIT = 'TX_WIDTH_UNIT';
export const TX_YES = 'TX_YES';

// Account
export const TX_ACCOUNT_BUYLIST_ORDERS = 'TX_ACCOUNT_BUYLIST_ORDERS';
export const TX_ACCOUNT_MANAGE = 'TX_ACCOUNT_MANAGE';
export const TX_ACCOUNT_MY_PROFILE = 'TX_ACCOUNT_MY_PROFILE';
export const TX_ACCOUNT_ORDERS_NOT_FOUND = 'TX_ACCOUNT_ORDERS_NOT_FOUND';
export const TX_ACCOUNT_PASSWORD_UPDATED = 'TX_ACCOUNT_PASSWORD_UPDATED';
export const TX_ACCOUNT_PROFILE = 'TX_ACCOUNT_PROFILE';
export const TX_ACCOUNT_REAUTH_TITLE = 'TX_ACCOUNT_REAUTH_TITLE';
export const TX_ACCOUNT_STORE_CREDIT_HISTORY = 'TX_ACCOUNT_STORE_CREDIT_HISTORY';
export const TX_ACCOUNT_UPDATE_PASSWORD = 'TX_ACCOUNT_UPDATE_PASSWORD';
export const TX_ACCOUNT_USER_DETAILS = 'TX_ACCOUNT_USER_DETAILS';

// Addresses
export const TX_ADDRESS_CITY = 'TX_ADDRESS_CITY';
export const TX_ADDRESS_COMUNA = 'TX_ADDRESS_COMUNA';
export const TX_ADDRESS_COUNTRY = 'TX_ADDRESS_COUNTRY';
export const TX_ADDRESS_POSTCODE = 'TX_ADDRESS_POSTCODE';
export const TX_ADDRESS_POSTCODE_ZIP = 'TX_ADDRESS_POSTCODE_ZIP';
export const TX_ADDRESS_PROVINCE = 'TX_ADDRESS_PROVINCE';
export const TX_ADDRESS_REGION = 'TX_ADDRESS_REGION';
export const TX_ADDRESS_REGION_COUNTY = 'TX_ADDRESS_REGION_COUNTY';
export const TX_ADDRESS_REGION_STATE = 'TX_ADDRESS_REGION_STATE';
export const TX_ADDRESS_RUT = 'TX_ADDRESS_RUT';
export const TX_ADDRESS_STREET = 'TX_ADDRESS_STREET';
export const TX_ADDRESS_STREET_LINE_2 = 'TX_ADDRESS_STREET_LINE_2';
export const TX_ADDRESS_TAX_ID = 'TX_ADDRESS_TAX_ID';

// Alt Tags
export const TX_ALT_PRODUCT_IMAGE = 'TX_ALT_PRODUCT_IMAGE';

// Blocks
export const TX_BLOCK_FEATURED_BUYLIST_TITLE = 'TX_BLOCK_FEATURED_BUYLIST_TITLE';
export const TX_BLOCK_MAGIC_SEALED_TITLE = 'TX_BLOCK_MAGIC_SEALED_TITLE';
export const TX_BLOCK_MAGIC_SINGLES_TITLE = 'TX_BLOCK_MAGIC_SINGLES_TITLE';
export const TX_BLOCK_MAGIC_TOP_EDH_TITLE = 'TX_BLOCK_MAGIC_TOP_EDH_TITLE';
export const TX_BLOCK_RECENTLY_ADDED_TITLE = 'TX_BLOCK_RECENTLY_ADDED_TITLE';
export const TX_BLOCK_UPCOMING_EVENTS_TITLE = 'TX_BLOCK_UPCOMING_EVENTS_TITLE';

// Calendar
export const TX_CAL_DAY_SUNDAY = 'TX_CAL_DAY_SUNDAY';
export const TX_CAL_DAY_MONDAY = 'TX_CAL_DAY_MONDAY';
export const TX_CAL_DAY_TUESDAY = 'TX_CAL_DAY_TUESDAY';
export const TX_CAL_DAY_WEDNESDAY = 'TX_CAL_DAY_WEDNESDAY';
export const TX_CAL_DAY_THURSDAY = 'TX_CAL_DAY_THURSDAY';
export const TX_CAL_DAY_FRIDAY = 'TX_CAL_DAY_FRIDAY';
export const TX_CAL_DAY_SATURDAY = 'TX_CAL_DAY_SATURDAY';
export const TX_CAL_FREQUENCY_DAILY = 'TX_CAL_FREQUENCY_DAILY';
export const TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FIRST = 'TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FIRST';
export const TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_SECOND = 'TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_SECOND';
export const TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_THIRD = 'TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_THIRD';
export const TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FOURTH = 'TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FOURTH';
export const TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_LAST = 'TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_LAST';
export const TX_CAL_FREQUENCY_WEEKLY = 'TX_CAL_FREQUENCY_WEEKLY';
export const TX_CAL_MONTH_ONE = 'TX_CAL_MONTH_ONE';
export const TX_CAL_MONTH_TWO = 'TX_CAL_MONTH_TWO';
export const TX_CAL_MONTH_THREE = 'TX_CAL_MONTH_THREE';
export const TX_CAL_WEEK_ONE = 'TX_CAL_WEEK_ONE';

// Conditions
export const TX_CONDITION_NEW = 'TX_CONDITION_NEW';
export const TX_CONDITION_MINT = 'TX_CONDITION_MINT';
export const TX_CONDITION_NEAR_MINT = 'TX_CONDITION_NEAR_MINT';
export const TX_CONDITION_LIGHTLY_PLAYED = 'TX_CONDITION_LIGHTLY_PLAYED';
export const TX_CONDITION_MODERATELY_PLAYED = 'TX_CONDITION_MODERATELY_PLAYED';
export const TX_CONDITION_HEAVILY_PLAYED = 'TX_CONDITION_HEAVILY_PLAYED';
export const TX_CONDITION_DAMAGED = 'TX_CONDITION_DAMAGED';

export const TX_CONDITION_NEW_ABRV = 'TX_CONDITION_NEW_ABRV';
export const TX_CONDITION_MINT_ABRV = 'TX_CONDITION_MINT_ABRV';
export const TX_CONDITION_NEAR_MINT_ABRV = 'TX_CONDITION_NEAR_MINT_ABRV';
export const TX_CONDITION_LIGHTLY_PLAYED_ABRV = 'TX_CONDITION_LIGHTLY_PLAYED_ABRV';
export const TX_CONDITION_MODERATELY_PLAYED_ABRV = 'TX_CONDITION_MODERATELY_PLAYED_ABRV';
export const TX_CONDITION_HEAVILY_PLAYED_ABRV = 'TX_CONDITION_HEAVILY_PLAYED_ABRV';
export const TX_CONDITION_DAMAGED_ABRV = 'TX_CONDITION_DAMAGED_ABRV';

// Filters
export const TX_FILTER_APPLIED_FILTERS = 'TX_FILTER_APPLIED_FILTERS';
export const TX_FILTER_APPLIED_FILTERS_SINGLE = 'TX_FILTER_APPLIED_FILTERS_SINGLE';
export const TX_FILTER_APPLIED_FILTERS_PLURAL = 'TX_FILTER_APPLIED_FILTERS_PLURAL';
export const TX_FILTER_APPLIED_FILTERS_ZERO = 'TX_FILTER_APPLIED_FILTERS_ZERO';
export const TX_FILTER_AVAILABILITY = 'TX_FILTER_AVAILABILITY';
export const TX_FILTER_FINISH = 'TX_FILTER_FINISH';
export const TX_FILTER_LOADING = 'TX_FILTER_LOADING';
export const TX_FILTER_NAME = 'TX_FILTER_NAME';
export const TX_FILTER_NO_CURRENT_FILTERS = 'TX_FILTER_NO_CURRENT_FILTERS';
export const TX_FILTER_PRODUCT_LINE_ALL = 'TX_FILTER_PRODUCT_LINE_ALL';
export const TX_FILTER_PRODUCT_TYPE = 'TX_FILTER_PRODUCT_TYPE';
export const TX_FILTER_SEALED = 'TX_FILTER_SEALED';
export const TX_FILTER_SHOW_OUT_OF_STOCK = 'TX_FILTER_SHOW_OUT_OF_STOCK';
export const TX_FILTER_SINGLE = 'TX_FILTER_SINGLE';

// Finishes
export const TX_FINISH_COLD_FOIL = 'TX_FINISH_COLD_FOIL';
export const TX_FINISH_NONFOIL = 'TX_FINISH_NONFOIL';
export const TX_FINISH_FOIL = 'TX_FINISH_FOIL';
export const TX_FINISH_ETCHED_FOIL = 'TX_FINISH_ETCHED_FOIL';
export const TX_FINISH_HOLOFOIL = 'TX_FINISH_HOLOFOIL';
export const TX_FINISH_REVERSE_HOLOFOIL = 'TX_FINISH_REVERSE_HOLOFOIL';
export const TX_FINISH_TEXTURED_FOIL = 'TX_FINISH_TEXTURED_FOIL';

// Geographies
export const TX_GEOGRAPHY_COUNTRY_CL = 'TX_GEOGRAPHY_COUNTRY_CL';
export const TX_GEOGRAPHY_COUNTRY_RO = 'TX_GEOGRAPHY_COUNTRY_RO';
export const TX_GEOGRAPHY_COUNTRY_US = 'TX_GEOGRAPHY_COUNTRY_US';

// Integrations
export const TX_INTEGRATION_CONFIG_LABEL_BALANCE_REFILL = 'TX_INTEGRATION_CONFIG_LABEL_BALANCE_REFILL';
export const TX_INTEGRATION_CONFIG_LABEL_PARCEL_SIZES = 'TX_INTEGRATION_CONFIG_LABEL_PARCEL_SIZES';
export const TX_INTEGRATION_CONFIG_LABEL_PARCEL_WEIGHT = 'TX_INTEGRATION_CONFIG_LABEL_PARCEL_WEIGHT';
export const TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD = 'TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD';
export const TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_ENABLED = 'TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_ENABLED';
export const TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_INSURED_AMOUNT = 'TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_INSURED_AMOUNT';
export const TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_TERMS_NOTICE = 'TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_TERMS_NOTICE';
export const TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_TERMS_OPEN = 'TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_TERMS_OPEN';
export const TX_INTEGRATION_CONFIG_LABEL_TEST_PRINT = 'TX_INTEGRATION_CONFIG_LABEL_TEST_PRINT';
export const TX_INTEGRATION_CONFIG_STAMPS_CLIENT_ID = 'TX_INTEGRATION_CONFIG_STAMPS_CLIENT_ID';
export const TX_INTEGRATION_CONFIG_STAMPS_CLIENT_SECRET = 'TX_INTEGRATION_CONFIG_STAMPS_CLIENT_SECRET';
export const TX_INTEGRATION_MUST_BE_DISABLED_TO_EDIT = 'TX_INTEGRATION_MUST_BE_DISABLED_TO_EDIT';
export const TX_INTEGRATION_TYPE_COMMON = 'TX_INTEGRATION_TYPE_COMMON';
export const TX_INTEGRATION_TYPE_SHIPPING = 'TX_INTEGRATION_TYPE_SHIPPING';

// Languages
export const TX_LANG_ANCIENT_GREEK = 'TX_LANG_ANCIENT_GREEK';
export const TX_LANG_ARABIC = 'TX_LANG_ARABIC';
export const TX_LANG_CHINESE = 'TX_LANG_CHINESE';
export const TX_LANG_CHINESE_SIMPLIFIED = 'TX_LANG_CHINESE_SIMPLIFIED';
export const TX_LANG_CHINESE_TRADITIONAL = 'TX_LANG_CHINESE_TRADITIONAL';
export const TX_LANG_ENGLISH = 'TX_LANG_ENGLISH';
export const TX_LANG_FRENCH = 'TX_LANG_FRENCH';
export const TX_LANG_GERMAN = 'TX_LANG_GERMAN';
export const TX_LANG_HEBREW = 'TX_LANG_HEBREW';
export const TX_LANG_ITALIAN = 'TX_LANG_ITALIAN';
export const TX_LANG_JAPANESE = 'TX_LANG_JAPANESE';
export const TX_LANG_KOREAN = 'TX_LANG_KOREAN';
export const TX_LANG_LATIN = 'TX_LANG_LATIN';
export const TX_LANG_PHYREXIAN = 'TX_LANG_PHYREXIAN';
export const TX_LANG_PORTUGUESE = 'TX_LANG_PORTUGUESE';
export const TX_LANG_ROMANIAN = 'TX_LANG_ROMANIAN';
export const TX_LANG_RUSSIAN = 'TX_LANG_RUSSIAN';
export const TX_LANG_SANSKRIT = 'TX_LANG_SANSKRIT';
export const TX_LANG_SPANISH = 'TX_LANG_SPANISH';

// Layouts
export const TX_LAYOUT_DASHBOARD_DESCRIPTION = 'TX_LAYOUT_DASHBOARD_DESCRIPTION';
export const TX_LAYOUT_DASHBOARD_LABEL = 'TX_LAYOUT_DASHBOARD_LABEL';
export const TX_LAYOUT_DASHBOARD_NAME = 'TX_LAYOUT_DASHBOARD_NAME';
export const TX_LAYOUT_HOME_DESCRIPTION = 'TX_LAYOUT_HOME_DESCRIPTION';
export const TX_LAYOUT_HOME_LABEL = 'TX_LAYOUT_HOME_LABEL';
export const TX_LAYOUT_HOME_NAME = 'TX_LAYOUT_HOME_NAME';

// Legal
export const TX_LEGAL_PRIVACY_POLICY = 'TX_LEGAL_PRIVACY_POLICY';
export const TX_LEGAL_RETURN_POLICY = 'TX_LEGAL_RETURN_POLICY';
export const TX_LEGAL_TERMS_OF_SERVICE = 'TX_LEGAL_TERMS_OF_SERVICE';

// Lorcana
export const TX_LORCANA = 'TX_LORCANA';
export const TX_LORCANA_MENU_SEALED = 'TX_LORCANA_MENU_SEALED';
export const TX_LORCANA_MENU_SINGLES = 'TX_LORCANA_MENU_SINGLES';

// Magic
export const TX_MAGIC_CARD = 'TX_MAGIC_CARD';
export const TX_MAGIC_CARD_COLOR = 'TX_MAGIC_CARD_COLOR';
export const TX_MAGIC_CARD_RARITY = 'TX_MAGIC_CARD_RARITY';
export const TX_MAGIC_COLORS_BLACK = 'TX_MAGIC_COLORS_BLACK';
export const TX_MAGIC_COLORS_BLUE = 'TX_MAGIC_COLORS_BLUE';
export const TX_MAGIC_COLORS_COLORLESS = 'TX_MAGIC_COLORS_COLORLESS';
export const TX_MAGIC_COLORS_GREEN = 'TX_MAGIC_COLORS_GREEN';
export const TX_MAGIC_COLORS_RED = 'TX_MAGIC_COLORS_RED';
export const TX_MAGIC_COLORS_WHITE = 'TX_MAGIC_COLORS_WHITE';
export const TX_MAGIC_FORMAT_CASUAL = 'TX_MAGIC_FORMAT_CASUAL';
export const TX_MAGIC_FORMAT_COMMANDER = 'TX_MAGIC_FORMAT_COMMANDER';
export const TX_MAGIC_FORMAT_DRAFT = 'TX_MAGIC_FORMAT_DRAFT';
export const TX_MAGIC_FORMAT_EXPLORER = 'TX_MAGIC_FORMAT_EXPLORER';
export const TX_MAGIC_FORMAT_LEGACY = 'TX_MAGIC_FORMAT_LEGACY';
export const TX_MAGIC_FORMAT_LIMITED = 'TX_MAGIC_FORMAT_LIMITED';
export const TX_MAGIC_FORMAT_MODERN = 'TX_MAGIC_FORMAT_MODERN';
export const TX_MAGIC_FORMAT_PAUPER = 'TX_MAGIC_FORMAT_PAUPER';
export const TX_MAGIC_FORMAT_PIONEER = 'TX_MAGIC_FORMAT_PIONEER';
export const TX_MAGIC_FORMAT_SEALED = 'TX_MAGIC_FORMAT_SEALED';
export const TX_MAGIC_FORMAT_STANDARD = 'TX_MAGIC_FORMAT_STANDARD';
export const TX_MAGIC_FORMAT_TWO_HEADED_GIANT = 'TX_MAGIC_FORMAT_TWO_HEADED_GIANT';
export const TX_MAGIC_FORMAT_VINTAGE = 'TX_MAGIC_FORMAT_VINTAGE';
export const TX_MAGIC_MENU_SEALED = 'TX_MAGIC_MENU_SEALED';
export const TX_MAGIC_MENU_SECRET_LAIR = 'TX_MAGIC_MENU_SECRET_LAIR';
export const TX_MAGIC_MENU_SINGLES = 'TX_MAGIC_MENU_SINGLES';
export const TX_MAGIC_RARITY_COMMON = 'TX_MAGIC_RARITY_COMMON';
export const TX_MAGIC_RARITY_MYTHIC = 'TX_MAGIC_RARITY_MYTHIC';
export const TX_MAGIC_RARITY_RARE = 'TX_MAGIC_RARITY_RARE';
export const TX_MAGIC_RARITY_SPECIAL = 'TX_MAGIC_RARITY_SPECIAL';
export const TX_MAGIC_RARITY_UNCOMMON = 'TX_MAGIC_RARITY_UNCOMMON';

// Order states/statuses
export const TX_ORDER_STATE_COMPLETE = 'TX_ORDER_STATE_COMPLETE';
export const TX_ORDER_STATE_OPEN = 'TX_ORDER_STATE_OPEN';
export const TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS = 'TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS';
export const TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS_CUSTOMER = 'TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS_CUSTOMER';
export const TX_ORDER_STATUS_CANCELLED = 'TX_ORDER_STATUS_CANCELLED';
export const TX_ORDER_STATUS_CANCELLED_CUSTOMER = 'TX_ORDER_STATUS_CANCELLED_CUSTOMER';
export const TX_ORDER_STATUS_COMPLETE = 'TX_ORDER_STATUS_COMPLETE';
export const TX_ORDER_STATUS_COMPLETE_CUSTOMER = 'TX_ORDER_STATUS_COMPLETE_CUSTOMER';
export const TX_ORDER_STATUS_CUSTOMER_REVIEWING = 'TX_ORDER_STATUS_CUSTOMER_REVIEWING';
export const TX_ORDER_STATUS_CUSTOMER_REVIEWING_CUSTOMER = 'TX_ORDER_STATUS_CUSTOMER_REVIEWING_CUSTOMER';
export const TX_ORDER_STATUS_MESSAGE_CREATED_ON = 'TX_ORDER_STATUS_MESSAGE_CREATED_ON';
export const TX_ORDER_STATUS_MESSAGE_LAST_UPDATE = 'TX_ORDER_STATUS_MESSAGE_LAST_UPDATE';
export const TX_ORDER_STATUS_MESSAGE_SHIPPED = 'TX_ORDER_STATUS_MESSAGE_SHIPPED';
export const TX_ORDER_STATUS_PENDING = 'TX_ORDER_STATUS_PENDING';
export const TX_ORDER_STATUS_PENDING_CUSTOMER = 'TX_ORDER_STATUS_PENDING_CUSTOMER';
export const TX_ORDER_STATUS_PENDING_PAYMENT = 'TX_ORDER_STATUS_PENDING_PAYMENT';
export const TX_ORDER_STATUS_PENDING_PAYMENT_CUSTOMER = 'TX_ORDER_STATUS_PENDING_PAYMENT_CUSTOMER';
export const TX_ORDER_STATUS_PREORDER = 'TX_ORDER_STATUS_PREORDER';
export const TX_ORDER_STATUS_READY_FOR_PICKUP = 'TX_ORDER_STATUS_READY_FOR_PICKUP';
export const TX_ORDER_STATUS_READY_FOR_PICKUP_CUSTOMER = 'TX_ORDER_STATUS_READY_FOR_PICKUP_CUSTOMER';
export const TX_ORDER_STATUS_READY_TO_GRADE = 'TX_ORDER_STATUS_READY_TO_GRADE';
export const TX_ORDER_STATUS_READY_TO_GRADE_CUSTOMER = 'TX_ORDER_STATUS_READY_TO_GRADE_CUSTOMER';
export const TX_ORDER_STATUS_REFUNDED = 'TX_ORDER_STATUS_REFUNDED';
export const TX_ORDER_STATUS_REFUNDED_CUSTOMER = 'TX_ORDER_STATUS_REFUNDED_CUSTOMER';
export const TX_ORDER_STATUS_SHIPPED = 'TX_ORDER_STATUS_SHIPPED';
export const TX_ORDER_STATUS_SHIPPED_CUSTOMER = 'TX_ORDER_STATUS_SHIPPED_CUSTOMER';

// Permissions
export const TX_PERMISSION_EVENTS = 'TX_PERMISSION_EVENTS';
export const TX_PERMISSION_EVENTS_DESC = 'TX_PERMISSION_EVENTS_DESC';
export const TX_PERMISSION_PERMISSIONS = 'TX_PERMISSION_PERMISSIONS';
export const TX_PERMISSION_PERMISSIONS_SHORT = 'TX_PERMISSION_PERMISSIONS_SHORT';
export const TX_PERMISSION_PERMISSIONS_DESC = 'TX_PERMISSION_PERMISSIONS_DESC';
export const TX_PERMISSION_PRODUCTS = 'TX_PERMISSION_PRODUCTS';
export const TX_PERMISSION_PRODUCTS_DESC = 'TX_PERMISSION_PRODUCTS_DESC';
export const TX_PERMISSION_SALES = 'TX_PERMISSION_SALES';
export const TX_PERMISSION_SALES_DESC = 'TX_PERMISSION_SALES_DESC';
export const TX_PERMISSION_USERS = 'TX_PERMISSION_USERS';
export const TX_PERMISSION_USERS_DESC = 'TX_PERMISSION_USERS_DESC';

// Placeholders
export const TX_PLACEHOLDER_ACTIONS = 'TX_PLACEHOLDER_ACTIONS';
export const TX_PLACEHOLDER_ADDRESS_CITY = 'TX_PLACEHOLDER_ADDRESS_CITY';
export const TX_PLACEHOLDER_ADDRESS_COMUNA = 'TX_PLACEHOLDER_ADDRESS_COMUNA';
export const TX_PLACEHOLDER_ADDRESS_POST_CODE = 'TX_PLACEHOLDER_ADDRESS_POST_CODE';
export const TX_PLACEHOLDER_ADDRESS_POST_CODE_CL = 'TX_PLACEHOLDER_ADDRESS_POST_CODE_CL';
export const TX_PLACEHOLDER_ADDRESS_REGION = 'TX_PLACEHOLDER_ADDRESS_REGION';
export const TX_PLACEHOLDER_ADDRESS_REGION_COUNTY = 'TX_PLACEHOLDER_ADDRESS_REGION_COUNTY';
export const TX_PLACEHOLDER_ADDRESS_REGION_SELECT = 'TX_PLACEHOLDER_ADDRESS_REGION_SELECT';
export const TX_PLACEHOLDER_ADDRESS_REGION_STATE = 'TX_PLACEHOLDER_ADDRESS_REGION_STATE';
export const TX_PLACEHOLDER_ADDRESS_STREET = 'TX_PLACEHOLDER_ADDRESS_STREET';
export const TX_PLACEHOLDER_ADDRESS_STREET_2 = 'TX_PLACEHOLDER_ADDRESS_STREET_2';
export const TX_PLACEHOLDER_ADDRESS_TAX_ID_RUT = 'TX_PLACEHOLDER_ADDRESS_TAX_ID_RUT';
export const TX_PLACEHOLDER_BACKGROUND_COLOR = 'TX_PLACEHOLDER_BACKGROUND_COLOR';
export const TX_PLACEHOLDER_BEST_MATCH = 'TX_PLACEHOLDER_BEST_MATCH';
export const TX_PLACEHOLDER_BILLING_ADDRESS = 'TX_PLACEHOLDER_BILLING_ADDRESS';
export const TX_PLACEHOLDER_BUY_PRICE = 'TX_PLACEHOLDER_BUY_PRICE';
export const TX_PLACEHOLDER_CARRIER_NAME = 'TX_PLACEHOLDER_CARRIER_NAME';
export const TX_PLACEHOLDER_CC_CVV = 'TX_PLACEHOLDER_CC_CVV';
export const TX_PLACEHOLDER_CC_CVV4 = 'TX_PLACEHOLDER_CC_CVV4';
export const TX_PLACEHOLDER_CC_EXP_MONTH = 'TX_PLACEHOLDER_CC_EXP_MONTH';
export const TX_PLACEHOLDER_CC_EXP_YEAR = 'TX_PLACEHOLDER_CC_EXP_YEAR';
export const TX_PLACEHOLDER_CC_NUMBER = 'TX_PLACEHOLDER_CC_NUMBER';
export const TX_PLACEHOLDER_CONDITION_SELECT = 'TX_PLACEHOLDER_CONDITION_SELECT';
export const TX_PLACEHOLDER_COUPON_CODE = 'TX_PLACEHOLDER_COUPON_CODE';
export const TX_PLACEHOLDER_COUPON_DESCRIPTION = 'TX_PLACEHOLDER_COUPON_DESCRIPTION';
export const TX_PLACEHOLDER_COUPON_DISCOUNT_AMOUNT = 'TX_PLACEHOLDER_COUPON_DISCOUNT_AMOUNT';
export const TX_PLACEHOLDER_COUPON_NAME = 'TX_PLACEHOLDER_COUPON_NAME';
export const TX_PLACEHOLDER_COUPON_PER_USER_USES = 'TX_PLACEHOLDER_COUPON_PER_USER_USES';
export const TX_PLACEHOLDER_CUSTOMER_SEARCH = 'TX_PLACEHOLDER_CUSTOMER_SEARCH';
export const TX_PLACEHOLDER_DATE_SELECT = 'TX_PLACEHOLDER_DATE_SELECT';
export const TX_PLACEHOLDER_EMAIL = 'TX_PLACEHOLDER_EMAIL';
export const TX_PLACEHOLDER_EVENT_DESCRIPTION = 'TX_PLACEHOLDER_EVENT_DESCRIPTION';
export const TX_PLACEHOLDER_EVENT_NAME = 'TX_PLACEHOLDER_EVENT_NAME';
export const TX_PLACEHOLDER_EVENT_REPEAT_FREQUENCY = 'TX_PLACEHOLDER_EVENT_REPEAT_FREQUENCY';
export const TX_PLACEHOLDER_FINISH_DROPDOWN = 'TX_PLACEHOLDER_FINISH_DROPDOWN';
export const TX_PLACEHOLDER_FIRST_NAME = 'TX_PLACEHOLDER_FIRST_NAME';
export const TX_PLACEHOLDER_FORMAT = 'TX_PLACEHOLDER_FORMAT';
export const TX_PLACEHOLDER_HEIGHT = 'TX_PLACEHOLDER_HEIGHT';
export const TX_PLACEHOLDER_INTEGRATION_PROVIDER = 'TX_PLACEHOLDER_INTEGRATION_PROVIDER';
export const TX_PLACEHOLDER_INV_DESCRIPTION = 'TX_PLACEHOLDER_INV_DESCRIPTION';
export const TX_PLACEHOLDER_INV_NAME = 'TX_PLACEHOLDER_INV_NAME';
export const TX_PLACEHOLDER_INV_SKU = 'TX_PLACEHOLDER_INV_SKU';
export const TX_PLACEHOLDER_LANGUAGE_DROPDOWN = 'TX_PLACEHOLDER_LANGUAGE_DROPDOWN';
export const TX_PLACEHOLDER_LAST_NAME = 'TX_PLACEHOLDER_LAST_NAME';
export const TX_PLACEHOLDER_LENGTH = 'TX_PLACEHOLDER_LENGTH';
export const TX_PLACEHOLDER_LINK_URL = 'TX_PLACEHOLDER_LINK_URL';
export const TX_PLACEHOLDER_MARKUP_PERCENT = 'TX_PLACEHOLDER_MARKUP_PERCENT';
export const TX_PLACEHOLDER_MENU_ORDER_DROPDOWN = 'TX_PLACEHOLDER_MENU_ORDER_DROPDOWN';
export const TX_PLACEHOLDER_NAME = 'TX_PLACEHOLDER_NAME';
export const TX_PLACEHOLDER_NOTICE = 'TX_PLACEHOLDER_NOTICE';
export const TX_PLACEHOLDER_ORDER_MESSAGE = 'TX_PLACEHOLDER_ORDER_MESSAGE';
export const TX_PLACEHOLDER_ORDER_NUMBER = 'TX_PLACEHOLDER_ORDER_NUMBER';
export const TX_PLACEHOLDER_PASSWORD = 'TX_PLACEHOLDER_PASSWORD';
export const TX_PLACEHOLDER_PASSWORD_LAST_FOUR = 'TX_PLACEHOLDER_PASSWORD_LAST_FOUR';
export const TX_PLACEHOLDER_PAYMENT_METHOD = 'TX_PLACEHOLDER_PAYMENT_METHOD';
export const TX_PLACEHOLDER_PAYMENT_PROCESSOR = 'TX_PLACEHOLDER_PAYMENT_PROCESSOR';
export const TX_PLACEHOLDER_PERCENT_BUY_PRICE = 'TX_PLACEHOLDER_PERCENT_BUY_PRICE';
export const TX_PLACEHOLDER_PERCENT_SELL_PRICE = 'TX_PLACEHOLDER_PERCENT_SELL_PRICE';
export const TX_PLACEHOLDER_PHONE = 'TX_PLACEHOLDER_PHONE';
export const TX_PLACEHOLDER_PL_DROPDOWN = 'TX_PLACEHOLDER_PL_DROPDOWN';
export const TX_PLACEHOLDER_PL_NAME = 'TX_PLACEHOLDER_PL_NAME';
export const TX_PLACEHOLDER_PL_PERMALINK = 'TX_PLACEHOLDER_PL_PERMALINK';
export const TX_PLACEHOLDER_PL_SYNC = 'TX_PLACEHOLDER_PL_SYNC';
export const TX_PLACEHOLDER_PRICE = 'TX_PLACEHOLDER_PRICE';
export const TX_PLACEHOLDER_PRINTING_DROPDOWN = 'TX_PLACEHOLDER_PRINTING_DROPDOWN';
export const TX_PLACEHOLDER_PRODUCT_CONDITION = 'TX_PLACEHOLDER_PRODUCT_CONDITION';
export const TX_PLACEHOLDER_PRODUCT_LINE_OPTIONS = 'TX_PLACEHOLDER_PRODUCT_LINE_OPTIONS';
export const TX_PLACEHOLDER_PRODUCT_NAME = 'TX_PLACEHOLDER_PRODUCT_NAME';
export const TX_PLACEHOLDER_PRODUCT_NAME_LORCANA = 'TX_PLACEHOLDER_PRODUCT_NAME_LORCANA';
export const TX_PLACEHOLDER_PRODUCT_NAME_MAGIC = 'TX_PLACEHOLDER_PRODUCT_NAME_MAGIC';
export const TX_PLACEHOLDER_PRODUCT_NAME_POKEMON = 'TX_PLACEHOLDER_PRODUCT_NAME_POKEMON';
export const TX_PLACEHOLDER_PRODUCT_NAME_STARWARS = 'TX_PLACEHOLDER_PRODUCT_NAME_STARWARS';
export const TX_PLACEHOLDER_PRODUCT_PERMALINK = 'TX_PLACEHOLDER_PRODUCT_PERMALINK';
export const TX_PLACEHOLDER_PRODUCT_SET = 'TX_PLACEHOLDER_PRODUCT_SET';
export const TX_PLACEHOLDER_QUANTITY = 'TX_PLACEHOLDER_QUANTITY';
export const TX_PLACEHOLDER_QUICK_SEARCH = 'TX_PLACEHOLDER_QUICK_SEARCH';
export const TX_PLACEHOLDER_REFILL_FLOOR = 'TX_PLACEHOLDER_REFILL_FLOOR';
export const TX_PLACEHOLDER_REFILL_INCREMENT = 'TX_PLACEHOLDER_REFILL_INCREMENT';
export const TX_PLACEHOLDER_RETURN_ADDRESS = 'TX_PLACEHOLDER_RETURN_ADDRESS';
export const TX_PLACEHOLDER_SEARCH = 'TX_PLACEHOLDER_SEARCH';
export const TX_PLACEHOLDER_SELECT_CONDITION = 'TX_PLACEHOLDER_SELECT_CONDITION';
export const TX_PLACEHOLDER_SELECT_COUNTRY = 'TX_PLACEHOLDER_SELECT_COUNTRY';
export const TX_PLACEHOLDER_SELECT_FINISH = 'TX_PLACEHOLDER_SELECT_FINISH';
export const TX_PLACEHOLDER_SELECT_LANGUAGE = 'TX_PLACEHOLDER_SELECT_LANGUAGE';
export const TX_PLACEHOLDER_SELECT_PRINTING = 'TX_PLACEHOLDER_SELECT_PRINTING';
export const TX_PLACEHOLDER_SHIPMENT_CARRIER = 'TX_PLACEHOLDER_SHIPMENT_CARRIER';
export const TX_PLACEHOLDER_SHIPPING_ADDRESS = 'TX_PLACEHOLDER_SHIPPING_ADDRESS';
export const TX_PLACEHOLDER_SHIPPING_METHOD_COST = 'TX_PLACEHOLDER_SHIPPING_METHOD_COST';
export const TX_PLACEHOLDER_SHIPPING_METHOD_DESCRIPTION = 'TX_PLACEHOLDER_SHIPPING_METHOD_DESCRIPTION';
export const TX_PLACEHOLDER_SHIPPING_METHOD_MIN_PURCHASE = 'TX_PLACEHOLDER_SHIPPING_METHOD_MIN_PURCHASE';
export const TX_PLACEHOLDER_SHIPPING_METHOD_NAME = 'TX_PLACEHOLDER_SHIPPING_METHOD_NAME';
export const TX_PLACEHOLDER_STORE_CREDIT_NOTE = 'TX_PLACEHOLDER_STORE_CREDIT_NOTE';
export const TX_PLACEHOLDER_TAG_NAME = 'TX_PLACEHOLDER_TAG_NAME';
export const TX_PLACEHOLDER_TEXT_COLOR = 'TX_PLACEHOLDER_TEXT_COLOR';
export const TX_PLACEHOLDER_TIME_HOUR = 'TX_PLACEHOLDER_TIME_HOUR';
export const TX_PLACEHOLDER_TIME_HOUR_24 = 'TX_PLACEHOLDER_TIME_HOUR_24';
export const TX_PLACEHOLDER_TIME_MINUTES = 'TX_PLACEHOLDER_TIME_MINUTES';
export const TX_PLACEHOLDER_TRACKING_NUMBER = 'TX_PLACEHOLDER_TRACKING_NUMBER';
export const TX_PLACEHOLDER_UPLOAD_FORMAT = 'TX_PLACEHOLDER_UPLOAD_FORMAT';
export const TX_PLACEHOLDER_WEIGHT = 'TX_PLACEHOLDER_WEIGHT';
export const TX_PLACEHOLDER_WIDTH = 'TX_PLACEHOLDER_WIDTH';

// Printings
export const TX_PRINTING_1ST_EDITION = 'TX_PRINTING_1ST_EDITION';
export const TX_PRINTING_UNLIMITED = 'TX_PRINTING_UNLIMITED';

// Pokémon
export const TX_POKEMON = 'TX_POKEMON';
export const TX_POKEMON_ATTRIBUTE_ATTACK = 'TX_POKEMON_ATTRIBUTE_ATTACK';
export const TX_POKEMON_ATTRIBUTE_ATTACKS = 'TX_POKEMON_ATTRIBUTE_ATTACKS';
export const TX_POKEMON_ATTRIBUTE_RESISTANCE = 'TX_POKEMON_ATTRIBUTE_RESISTANCE';
export const TX_POKEMON_ATTRIBUTE_RETREAT = 'TX_POKEMON_ATTRIBUTE_RETREAT';
export const TX_POKEMON_ATTRIBUTE_WEAKNESS = 'TX_POKEMON_ATTRIBUTE_WEAKNESS';
export const TX_POKEMON_MENU_SEALED = 'TX_POKEMON_MENU_SEALED';
export const TX_POKEMON_MENU_SINGLES = 'TX_POKEMON_MENU_SINGLES';

// Popups
export const TX_CONFIRM_COPY = 'TX_CONFIRM_COPY';
export const TX_CONFIRM_TITLE = 'TX_CONFIRM_TITLE';
export const TX_DELETE_PROMPT_COPY = 'TX_DELETE_PROMPT_COPY';
export const TX_DELETE_PROMPT_ERROR = 'TX_DELETE_PROMPT_ERROR';
export const TX_DELETE_PROMPT_PROMPT = 'TX_DELETE_PROMPT_PROMPT';
export const TX_DELETE_PROMPT_TITLE = 'TX_DELETE_PROMPT_TITLE';
export const TX_SAVE_PROMPT_COPY = 'TX_SAVE_PROMPT_COPY';
export const TX_SAVE_PROMPT_TITLE = 'TX_SAVE_PROMPT_TITLE';

// Shipments
export const TX_SHIPMENT_CARRIER_CHILEXPRESS = 'TX_SHIPMENT_CARRIER_CHILEXPRESS';
export const TX_SHIPMENT_CARRIER_FEDEX = 'TX_SHIPMENT_CARRIER_FEDEX';
export const TX_SHIPMENT_CARRIER_UPS = 'TX_SHIPMENT_CARRIER_UPS';
export const TX_SHIPMENT_CARRIER_USPS = 'TX_SHIPMENT_CARRIER_USPS';
export const TX_SHIPMENT_STATUS_SHIPPED = 'TX_SHIPMENT_STATUS_SHIPPED';

// Stamps integration
export const TX_STAMPS_CARRIER_CANADA_POST = 'TX_STAMPS_CARRIER_CANADA_POST';
export const TX_STAMPS_CARRIER_DHL = 'TX_STAMPS_CARRIER_DHL';
export const TX_STAMPS_CARRIER_FEDEX = 'TX_STAMPS_CARRIER_FEDEX';
export const TX_STAMPS_CARRIER_GLOBAL_POST = 'TX_STAMPS_CARRIER_GLOBAL_POST';
export const TX_STAMPS_CARRIER_UPS = 'TX_STAMPS_CARRIER_UPS';
export const TX_STAMPS_CARRIER_USPS = 'TX_STAMPS_CARRIER_USPS';

export const TX_STAMPS_SERVICE_FEDEX_FIRST_OVERNIGHT = 'TX_STAMPS_SERVICE_FEDEX_FIRST_OVERNIGHT';
export const TX_STAMPS_SERVICE_FEDEX_PRIORITY_OVERNIGHT = 'TX_STAMPS_SERVICE_FEDEX_PRIORITY_OVERNIGHT';
export const TX_STAMPS_SERVICE_FEDEX_STANDARD_OVERNIGHT = 'TX_STAMPS_SERVICE_FEDEX_STANDARD_OVERNIGHT';
export const TX_STAMPS_SERVICE_FEDEX_TWO_DAY_AM = 'TX_STAMPS_SERVICE_FEDEX_TWO_DAY_AM';
export const TX_STAMPS_SERVICE_FEDEX_TWO_DAY = 'TX_STAMPS_SERVICE_FEDEX_TWO_DAY';
export const TX_STAMPS_SERVICE_FEDEX_EXPRESS_SAVER = 'TX_STAMPS_SERVICE_FEDEX_EXPRESS_SAVER';
export const TX_STAMPS_SERVICE_FEDEX_GROUND = 'TX_STAMPS_SERVICE_FEDEX_GROUND';
export const TX_STAMPS_SERVICE_FEDEX_HOME_DELIVERY = 'TX_STAMPS_SERVICE_FEDEX_HOME_DELIVERY';
export const TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_FIRST = 'TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_FIRST';
export const TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_PRIORITY = 'TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_PRIORITY';
export const TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_ECONOMY = 'TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_ECONOMY';
export const TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_GROUND = 'TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_GROUND';

export const TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR_EARLY = 'TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR_EARLY';
export const TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR = 'TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR';
export const TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR_SAVER = 'TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR_SAVER';
export const TX_STAMPS_SERVICE_UPS_2ND_DAY_AIR_AM = 'TX_STAMPS_SERVICE_UPS_2ND_DAY_AIR_AM';
export const TX_STAMPS_SERVICE_UPS_2ND_DAY_AIR = 'TX_STAMPS_SERVICE_UPS_2ND_DAY_AIR';
export const TX_STAMPS_SERVICE_UPS_THREE_DAY_SELECT = 'TX_STAMPS_SERVICE_UPS_THREE_DAY_SELECT';
export const TX_STAMPS_SERVICE_UPS_GROUND = 'TX_STAMPS_SERVICE_UPS_GROUND';
export const TX_STAMPS_SERVICE_UPS_STANDARD = 'TX_STAMPS_SERVICE_UPS_STANDARD';
export const TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPRESS = 'TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPRESS';
export const TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPRESS_PLUS = 'TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPRESS_PLUS';
export const TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPEDITED = 'TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPEDITED';
export const TX_STAMPS_SERVICE_UPS_WORLDWIDE_SAVER = 'TX_STAMPS_SERVICE_UPS_WORLDWIDE_SAVER';

export const TX_STAMPS_SERVICE_USPS_FIRST_CLASS_MAIL = 'TX_STAMPS_SERVICE_USPS_FIRST_CLASS_MAIL';
export const TX_STAMPS_SERVICE_USPS_GROUND_ADVANTAGE = 'TX_STAMPS_SERVICE_USPS_GROUND_ADVANTAGE';
export const TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL = 'TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL';
export const TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_EXPRESS = 'TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_EXPRESS';
export const TX_STAMPS_SERVICE_USPS_PARCEL_SELECT = 'TX_STAMPS_SERVICE_USPS_PARCEL_SELECT';
export const TX_STAMPS_SERVICE_USPS_MEDIA_MAIL = 'TX_STAMPS_SERVICE_USPS_MEDIA_MAIL';
export const TX_STAMPS_SERVICE_USPS_FIRST_CLASS_MAIL_INTERNATIONAL = 'TX_STAMPS_SERVICE_USPS_FIRST_CLASS_MAIL_INTERNATIONAL';
export const TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_INTERNATIONAL = 'TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_INTERNATIONAL';
export const TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL = 'TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL';
export const TX_STAMPS_SERVICE_USPS_PAY_ON_USE_RETURN = 'TX_STAMPS_SERVICE_USPS_PAY_ON_USE_RETURN';

// Star Wars
export const TX_STARWARS = 'TX_STARWARS';
export const TX_STARWARS_MENU_SEALED = 'TX_STARWARS_MENU_SEALED';
export const TX_STARWARS_MENU_SINGLES = 'TX_STARWARS_MENU_SINGLES';

// Stores
export const TX_STORE_HOURS = 'TX_STORE_HOURS';

// Tables
export const TX_ADMIN_TABLE_PAGE_STATUS = 'TX_ADMIN_TABLE_PAGE_STATUS';
export const TX_ADMIN_TABLE_RESULTS_PER_PAGE = 'TX_ADMIN_TABLE_RESULTS_PER_PAGE';
export const TX_ADMIN_TABLE_SEARCHING = 'TX_ADMIN_TABLE_SEARCHING';
export const TX_ADMIN_TABLE_VIEW_DETAIL = 'TX_ADMIN_TABLE_VIEW_DETAIL';
export const TX_ADMIN_TABLE_VIEW_LIST = 'TX_ADMIN_TABLE_VIEW_LIST';

// Tags
export const TX_TAG_LORCANA_ENCHANTED = 'TX_TAG_LORCANA_ENCHANTED';
export const TX_TAG_MAGIC_CONCEPT_PRAETOR = 'TX_TAG_MAGIC_CONCEPT_PRAETOR';
export const TX_TAG_MAGIC_CONFETTI_FOIL = 'TX_TAG_MAGIC_CONFETTI_FOIL';
export const TX_TAG_MAGIC_ETCHED_FOIL = 'TX_TAG_MAGIC_ETCHED_FOIL';
export const TX_TAG_MAGIC_GALAXY_FOIL = 'TX_TAG_MAGIC_GALAXY_FOIL';
export const TX_TAG_MAGIC_HALO_FOIL = 'TX_TAG_MAGIC_HALO_FOIL';
export const TX_TAG_MAGIC_INVISIBLE_INK = 'TX_TAG_MAGIC_INVISIBLE_INK';
export const TX_TAG_MAGIC_PRERELEASE = 'TX_TAG_MAGIC_PRERELEASE';
export const TX_TAG_MAGIC_RAISED_FOIL = 'TX_TAG_MAGIC_RAISED_FOIL';
export const TX_TAG_MAGIC_RETRO_FRAME = 'TX_TAG_MAGIC_RETRO_FRAME';
export const TX_TAG_MAGIC_RIPPLE_FOIL = 'TX_TAG_MAGIC_RIPPLE_FOIL';
export const TX_TAG_MAGIC_SHOWCASE = 'TX_TAG_MAGIC_SHOWCASE';
export const TX_TAG_MAGIC_STEP_COMPLEAT_FOIL = 'TX_TAG_MAGIC_STEP_COMPLEAT_FOIL';
export const TX_TAG_MAGIC_SURGE_FOIL = 'TX_TAG_MAGIC_SURGE_FOIL';
export const TX_TAG_MAGIC_TEXTURED_FOIL = 'TX_TAG_MAGIC_TEXTURED_FOIL';
export const TX_TAG_STARWARS_HYPERSPACE = 'TX_TAG_STARWARS_HYPERSPACE';
export const TX_TAG_STARWARS_SHOWCASE = 'TX_TAG_STARWARS_SHOWCASE';
export const TX_TAG_TCG_BORDERLESS = 'TX_TAG_TCG_BORDERLESS';
export const TX_TAG_TCG_EXTENDED_ART = 'TX_TAG_TCG_EXTENDED_ART';
export const TX_TAG_TCG_FULL_ART = 'TX_TAG_TCG_FULL_ART';
export const TX_TAG_TCG_SERIALIZED = 'TX_TAG_TCG_SERIALIZED';

// Users
export const TX_USER_TYPE = 'TX_USER_TYPE';
export const TX_USER_TYPE_GUEST = 'TX_USER_TYPE_GUEST';
export const TX_USER_TYPE_REGISTERED = 'TX_USER_TYPE_REGISTERED';


// 
// COMPONENT STRINGS
// 


// About Us Component

export const TX_ABOUT_US = 'TX_ABOUT_US';
export const TX_ABOUT_US_VISIT_STORE = 'TX_ABOUT_US_VISIT_STORE';


// Analytics Component

export const TX_ANALYTICS_CONFIG_GENERAL_LABEL = 'TX_ANALYTICS_CONFIG_GENERAL_LABEL';
export const TX_ANALYTICS_CONFIG_METRIC_LABEL = 'TX_ANALYTICS_CONFIG_METRIC_LABEL';
export const TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT = 'TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT';
export const TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT_ITEMS = 'TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT_ITEMS';
export const TX_ANALYTICS_CONFIG_METRIC_VALUE_TOTAL = 'TX_ANALYTICS_CONFIG_METRIC_VALUE_TOTAL';
export const TX_ANALYTICS_CONFIG_MODE_LABEL = 'TX_ANALYTICS_CONFIG_MODE_LABEL';
export const TX_ANALYTICS_CONFIG_MODE_VALUE_ORDERS = 'TX_ANALYTICS_CONFIG_MODE_VALUE_ORDERS';
export const TX_ANALYTICS_CONFIG_MODE_VALUE_PRODUCT_LINES = 'TX_ANALYTICS_CONFIG_MODE_VALUE_PRODUCT_LINES';
export const TX_ANALYTICS_CONFIG_SHOW_TODAY = 'TX_ANALYTICS_CONFIG_SHOW_TODAY';
export const TX_ANALYTICS_SALES_TITLE = 'TX_ANALYTICS_SALES_TITLE';
export const TX_ANALYTICS_ORDERS_BY_STATUS = 'TX_ANALYTICS_ORDERS_BY_STATUS';
export const TX_ANALYTICS_ORDERS_BY_STATUS_PENDING = 'TX_ANALYTICS_ORDERS_BY_STATUS_PENDING';
export const TX_ANALYTICS_ORDERS_TOTAL_TITLE = 'TX_ANALYTICS_ORDERS_TOTAL_TITLE';
export const TX_ANALYTICS_TOP_PRODUCT = 'TX_ANALYTICS_TOP_PRODUCT';
export const TX_ANALYTICS_TOP_PRODUCT_LINES = 'TX_ANALYTICS_TOP_PRODUCT_LINES';


// Auth Component

export const TX_AUTH_ALREADY_HAVE_ACCOUNT = 'TX_AUTH_ALREADY_HAVE_ACCOUNT';
export const TX_AUTH_DONT_HAVE_ACCOUNT = 'TX_AUTH_DONT_HAVE_ACCOUNT';
export const TX_AUTH_FORGOT_COPY_1 = 'TX_AUTH_FORGOT_COPY_1';
export const TX_AUTH_FORGOT_COPY_2 = 'TX_AUTH_FORGOT_COPY_2';
export const TX_AUTH_JOIN_COMMUNITY = 'TX_AUTH_JOIN_COMMUNITY';
export const TX_AUTH_RESET_SENT = 'TX_AUTH_RESET_SENT';
export const TX_AUTH_NOT_READY = 'TX_AUTH_NOT_READY';
export const TX_AUTH_SET_PASSWORD = 'TX_AUTH_SET_PASSWORD';
export const TX_AUTH_SET_PASSWORD_COPY = 'TX_AUTH_SET_PASSWORD_COPY';
export const TX_AUTH_LOGIN_TO_CONTINUE = 'TX_AUTH_LOGIN_TO_CONTINUE';


// Cart Component

export const TX_CART_ALREADY_ORDER_ASSOCIATED = 'TX_CART_ALREADY_ORDER_ASSOCIATED';
export const TX_CART_APPLY_COUPON = 'TX_CART_APPLY_COUPON';
export const TX_CART_CART_ITEMS = 'TX_CART_CART_ITEMS';
export const TX_CART_CHANGE_CUSTOMER = 'TX_CART_CHANGE_CUSTOMER';
export const TX_CART_CHANGE_CUSTOMER_TITLE = 'TX_CART_CHANGE_CUSTOMER_TITLE';
export const TX_CART_CHECKOUT_BUTTON = 'TX_CART_CHECKOUT_BUTTON';
export const TX_CART_CLEAR_CONFIRM_COPY = 'TX_CART_CLEAR_CONFIRM_COPY';
export const TX_CART_CLEAR_CONFIRM_TITLE = 'TX_CART_CLEAR_CONFIRM_TITLE';
export const TX_CART_CREATE_ORDER = 'TX_CART_CREATE_ORDER';
export const TX_CART_DISCARD = 'TX_CART_DISCARD';
export const TX_CART_DISCARD_EXPLANATION_COPY = 'TX_CART_DISCARD_EXPLANATION_COPY';
export const TX_CART_EMPTY = 'TX_CART_EMPTY';
export const TX_CART_EMPTY_CART = 'TX_CART_EMPTY_CART';
export const TX_CART_EXISTING_CART = 'TX_CART_EXISTING_CART';
export const TX_CART_EXISTING_CART_ITEMS = 'TX_CART_EXISTING_CART_ITEMS';
export const TX_CART_GRADING_ADJUSTMENT = 'TX_CART_GRADING_ADJUSTMENT';
export const TX_CART_ITEM_COUNT = 'TX_CART_ITEM_COUNT';
export const TX_CART_ITEM_COUNT_ONE = 'TX_CART_ITEM_COUNT_ONE';
export const TX_CART_LAST_UPDATE = 'TX_CART_LAST_UPDATE';
export const TX_CART_MERGE = 'TX_CART_MERGE';
export const TX_CART_MERGE_CARTS_QUESTION = 'TX_CART_MERGE_CARTS_QUESTION';
export const TX_CART_MERGE_EXPLANATION_COPY = 'TX_CART_MERGE_EXPLANATION_COPY';
export const TX_CART_SHOPPING_CART = 'TX_CART_SHOPPING_CART';
export const TX_CART_STATUS_ABANDONED = 'TX_CART_STATUS_ABANDONED';
export const TX_CART_STATUS_ACTIVE = 'TX_CART_STATUS_ACTIVE';
export const TX_CART_STATUS_COMPLETE = 'TX_CART_STATUS_COMPLETE';
export const TX_CART_STATUS_INACTIVE = 'TX_CART_STATUS_INACTIVE';
export const TX_CART_SUMMARY_TITLE = 'TX_CART_SUMMARY_TITLE';
export const TX_CART_TYPE = 'TX_CART_TYPE';
export const TX_CART_TYPE_PURCHASE = 'TX_CART_TYPE_PURCHASE';
export const TX_CART_USER_ALREADY_ASSIGNED = 'TX_CART_USER_ALREADY_ASSIGNED';
export const TX_CART_USER_REQUIRED_FOR_ORDER = 'TX_CART_USER_REQUIRED_FOR_ORDER';
export const TX_CART_VIEW_BUYLIST_CART = 'TX_CART_VIEW_BUYLIST_CART';
export const TX_CART_VIEW_SHOPPING_CART = 'TX_CART_VIEW_SHOPPING_CART';



// Checkout Component

export const TX_CHECKOUT = 'TX_CHECKOUT';
export const TX_CHECKOUT_ADDRESS_VERIFICATION = 'TX_CHECKOUT_ADDRESS_VERIFICATION';
export const TX_CHECKOUT_ADDRESS_VERIFICATION_ADDRESS_ENTERED = 'TX_CHECKOUT_ADDRESS_VERIFICATION_ADDRESS_ENTERED';
export const TX_CHECKOUT_ADDRESS_VERIFICATION_DOES_NOT_MATCH = 'TX_CHECKOUT_ADDRESS_VERIFICATION_DOES_NOT_MATCH';
export const TX_CHECKOUT_BILLING_ADDRESS_PROMPT = 'TX_CHECKOUT_BILLING_ADDRESS_PROMPT';
export const TX_CHECKOUT_BILLING_ADDRESS_SAME = 'TX_CHECKOUT_BILLING_ADDRESS_SAME';
export const TX_CHECKOUT_BILLING_ADDRESS_SAME_SHORT = 'TX_CHECKOUT_BILLING_ADDRESS_SAME_SHORT';
export const TX_CHECKOUT_BUYLIST_ADDRESS_PROMPT = 'TX_CHECKOUT_BUYLIST_ADDRESS_PROMPT';
export const TX_CHECKOUT_BUYLIST_ADDRESS_PROMPT_2 = 'TX_CHECKOUT_BUYLIST_ADDRESS_PROMPT_2';
export const TX_CHECKOUT_BUYLIST_CONFIRMATION_AGREEMENT = 'TX_CHECKOUT_BUYLIST_CONFIRMATION_AGREEMENT';
export const TX_CHECKOUT_BUYLIST_EMAIL_NOTICE = 'TX_CHECKOUT_BUYLIST_EMAIL_NOTICE';
export const TX_CHECKOUT_BUYLIST_NEXT_STEPS = 'TX_CHECKOUT_BUYLIST_NEXT_STEPS';
export const TX_CHECKOUT_BUYLIST_RETURN_ADDRESS = 'TX_CHECKOUT_BUYLIST_RETURN_ADDRESS';
export const TX_CHECKOUT_BUYLIST_STEP_SEND = 'TX_CHECKOUT_BUYLIST_STEP_SEND';
export const TX_CHECKOUT_BUYLIST_STEP_SEND_TO = 'TX_CHECKOUT_BUYLIST_STEP_SEND_TO';
export const TX_CHECKOUT_BUYLIST_STEP_SEND_PACK = 'TX_CHECKOUT_BUYLIST_STEP_SEND_PACK';
export const TX_CHECKOUT_BUYLIST_STEP_SEND_DELIVER = 'TX_CHECKOUT_BUYLIST_STEP_SEND_DELIVER';
export const TX_CHECKOUT_BUYLIST_STEP_GRADE = 'TX_CHECKOUT_BUYLIST_STEP_GRADE';
export const TX_CHECKOUT_BUYLIST_STEP_GRADE_INDIVIDUALLY = 'TX_CHECKOUT_BUYLIST_STEP_GRADE_INDIVIDUALLY';
export const TX_CHECKOUT_BUYLIST_STEP_GRADE_CHANGE_PRICE = 'TX_CHECKOUT_BUYLIST_STEP_GRADE_CHANGE_PRICE';
export const TX_CHECKOUT_BUYLIST_STEP_APPROVE = 'TX_CHECKOUT_BUYLIST_STEP_APPROVE';
export const TX_CHECKOUT_BUYLIST_STEP_APPROVE_INDIVIDUALLY = 'TX_CHECKOUT_BUYLIST_STEP_APPROVE_INDIVIDUALLY';
export const TX_CHECKOUT_BUYLIST_STEP_APPROVE_ORDER = 'TX_CHECKOUT_BUYLIST_STEP_APPROVE_ORDER';
export const TX_CHECKOUT_BUYLIST_STEP_PAY = 'TX_CHECKOUT_BUYLIST_STEP_PAY';
export const TX_CHECKOUT_BUYLIST_STEP_PAY_PAYMENT = 'TX_CHECKOUT_BUYLIST_STEP_PAY_PAYMENT';
export const TX_CHECKOUT_BUYLIST_STEP_PAY_RETURN = 'TX_CHECKOUT_BUYLIST_STEP_PAY_RETURN';
export const TX_CHECKOUT_BUYLIST_TITLE = 'TX_CHECKOUT_BUYLIST_TITLE';
export const TX_CHECKOUT_CHECKOUT_AS_GUEST = 'TX_CHECKOUT_CHECKOUT_AS_GUEST';
export const TX_CHECKOUT_COMPLETE_BACK_HOME = 'TX_CHECKOUT_COMPLETE_BACK_HOME';
export const TX_CHECKOUT_COMPLETE_COPY = 'TX_CHECKOUT_COMPLETE_COPY';
export const TX_CHECKOUT_COMPLETE_TITLE = 'TX_CHECKOUT_COMPLETE_TITLE';
export const TX_CHECKOUT_COMPLETE_VIEW_ORDERS = 'TX_CHECKOUT_COMPLETE_VIEW_ORDERS';
export const TX_CHECKOUT_COMPLETE_VIEW_STATUS = 'TX_CHECKOUT_COMPLETE_VIEW_STATUS';
export const TX_CHECKOUT_COMPLETING_ORDER = 'TX_CHECKOUT_COMPLETING_ORDER';
export const TX_CHECKOUT_COMPLETING_ORDER_ERROR_COPY = 'TX_CHECKOUT_COMPLETING_ORDER_ERROR_COPY';
export const TX_CHECKOUT_COMPLETING_ORDER_NONAV_COPY = 'TX_CHECKOUT_COMPLETING_ORDER_NONAV_COPY';
export const TX_CHECKOUT_ENTER_NEW_ADDRESS = 'TX_CHECKOUT_ENTER_NEW_ADDRESS';
export const TX_CHECKOUT_NO_PAYMENT_METHODS = 'TX_CHECKOUT_NO_PAYMENT_METHODS';
export const TX_CHECKOUT_NO_SHIPPING_METHODS = 'TX_CHECKOUT_NO_SHIPPING_METHODS';
export const TX_CHECKOUT_PAYMENT_METHOD = 'TX_CHECKOUT_PAYMENT_METHOD';
export const TX_CHECKOUT_PAYMENT_METHOD_CARD_NUMBER = 'TX_CHECKOUT_PAYMENT_METHOD_CARD_NUMBER';
export const TX_CHECKOUT_PAYMENT_METHOD_CVV = 'TX_CHECKOUT_PAYMENT_METHOD_CVV';
export const TX_CHECKOUT_PAYMENT_METHOD_EXPIRATION_DATE = 'TX_CHECKOUT_PAYMENT_METHOD_EXPIRATION_DATE';
export const TX_CHECKOUT_PAYMENT_METHOD_NAME_ON_CARD = 'TX_CHECKOUT_PAYMENT_METHOD_NAME_ON_CARD';
export const TX_CHECKOUT_PLACE_ORDER = 'TX_CHECKOUT_PLACE_ORDER';
export const TX_CHECKOUT_PLACING_ORDER = 'TX_CHECKOUT_PLACING_ORDER';
export const TX_CHECKOUT_SAVE_ADDRESS = 'TX_CHECKOUT_SAVE_ADDRESS';
export const TX_CHECKOUT_SAVE_ADDRESS_SHORT = 'TX_CHECKOUT_SAVE_ADDRESS_SHORT';
export const TX_CHECKOUT_SHIPPING_ADDRESS_PROMPT = 'TX_CHECKOUT_SHIPPING_ADDRESS_PROMPT';
export const TX_CHECKOUT_STORE_CREDIT_APPLY = 'TX_CHECKOUT_STORE_CREDIT_APPLY';
export const TX_CHECKOUT_STORE_CREDIT_PROMPT = 'TX_CHECKOUT_STORE_CREDIT_PROMPT';


// Customer Component

export const TX_CUSTOMER_ACCOUNT_LOCKED = 'TX_CUSTOMER_ACCOUNT_LOCKED';
export const TX_CUSTOMER_ADD_CUSTOMER = 'TX_CUSTOMER_ADD_CUSTOMER';
export const TX_CUSTOMER_ADD_NO_PASSWORD = 'TX_CUSTOMER_ADD_NO_PASSWORD';
export const TX_CUSTOMER_ADD_PERMISSIONS = 'TX_CUSTOMER_ADD_PERMISSIONS';
export const TX_CUSTOMER_BULK_UPLOAD = 'TX_CUSTOMER_BULK_UPLOAD';
export const TX_CUSTOMER_BULK_UPLOAD_BACK = 'TX_CUSTOMER_BULK_UPLOAD_BACK';
export const TX_CUSTOMER_BULK_UPLOAD_CREATED = 'TX_CUSTOMER_BULK_UPLOAD_CREATED';
export const TX_CUSTOMER_BULK_UPLOAD_DRAG_HERE = 'TX_CUSTOMER_BULK_UPLOAD_DRAG_HERE';
export const TX_CUSTOMER_CANNOT_LOCK_SELF = 'TX_CUSTOMER_CANNOT_LOCK_SELF';
export const TX_CUSTOMER_CREATE_NEW_USERS = 'TX_CUSTOMER_CREATE_NEW_USERS';
export const TX_CUSTOMER_CREATE_NEW_USERS_FALSE = 'TX_CUSTOMER_CREATE_NEW_USERS_FALSE';
export const TX_CUSTOMER_CREATE_NEW_USERS_NOTICE = 'TX_CUSTOMER_CREATE_NEW_USERS_NOTICE';
export const TX_CUSTOMER_CREATE_NEW_USERS_TRUE = 'TX_CUSTOMER_CREATE_NEW_USERS_TRUE';
export const TX_CUSTOMER_CUSTOMER_DETAILS = 'TX_CUSTOMER_CUSTOMER_DETAILS';
export const TX_CUSTOMER_LOCK_ACCOUNT = 'TX_CUSTOMER_LOCK_ACCOUNT';
export const TX_CUSTOMER_LOCK_ACCOUNT_QUESTION = 'TX_CUSTOMER_LOCK_ACCOUNT_QUESTION';
export const TX_CUSTOMER_LOCK_ACCOUNT_WARNING = 'TX_CUSTOMER_LOCK_ACCOUNT_WARNING';
export const TX_CUSTOMER_NO_ORDERS_COPY = 'TX_CUSTOMER_NO_ORDERS_COPY';
export const TX_CUSTOMER_NO_ORDERS_TITLE = 'TX_CUSTOMER_NO_ORDERS_TITLE';
export const TX_CUSTOMER_NO_PERMISSIONS_COPY = 'TX_CUSTOMER_NO_PERMISSIONS_COPY';
export const TX_CUSTOMER_NO_PERMISSIONS_COPY_2 = 'TX_CUSTOMER_NO_PERMISSIONS_COPY_2';
export const TX_CUSTOMER_NO_PERMISSIONS_TITLE = 'TX_CUSTOMER_NO_PERMISSIONS_TITLE';
export const TX_CUSTOMER_NO_STORE_CREDIT_COPY = 'TX_CUSTOMER_NO_STORE_CREDIT_COPY';
export const TX_CUSTOMER_NO_STORE_CREDIT_TITLE = 'TX_CUSTOMER_NO_STORE_CREDIT_TITLE';
export const TX_CUSTOMER_PASSWORD_RESET_SENT_COPY = 'TX_CUSTOMER_PASSWORD_RESET_SENT_COPY';
export const TX_CUSTOMER_SEND_RESET_COPY = 'TX_CUSTOMER_SEND_RESET_COPY';
export const TX_CUSTOMER_SEND_RESET_REQUEST = 'TX_CUSTOMER_SEND_RESET_REQUEST';
export const TX_CUSTOMER_SEND_RESET_REQUEST_QUESTION = 'TX_CUSTOMER_SEND_RESET_REQUEST_QUESTION';
export const TX_CUSTOMER_STORE_CREDIT_ADD_TO_BALANCE = 'TX_CUSTOMER_STORE_CREDIT_ADD_TO_BALANCE';
export const TX_CUSTOMER_STORE_CREDIT_AMOUNT_CAN_BE_NEGATIVE = 'TX_CUSTOMER_STORE_CREDIT_AMOUNT_CAN_BE_NEGATIVE';
export const TX_CUSTOMER_STORE_CREDIT_DESCRIPTION = 'TX_CUSTOMER_STORE_CREDIT_DESCRIPTION';
export const TX_CUSTOMER_STORE_CREDIT_SET_TOTAL = 'TX_CUSTOMER_STORE_CREDIT_SET_TOTAL';
export const TX_CUSTOMER_STORE_CREDIT_STORE_UPDATE = 'TX_CUSTOMER_STORE_CREDIT_STORE_UPDATE';
export const TX_CUSTOMER_UNLOCK_ACCOUNT = 'TX_CUSTOMER_UNLOCK_ACCOUNT';
export const TX_CUSTOMER_UNLOCK_COPY = 'TX_CUSTOMER_UNLOCK_COPY';
export const TX_CUSTOMER_UNLOCK_TITLE = 'TX_CUSTOMER_UNLOCK_TITLE';
export const TX_CUSTOMER_VIEW_CUSTOMER = 'TX_CUSTOMER_VIEW_CUSTOMER';


// Error Component

export const TX_ERR_404_ACTIONS_HEADER = 'TX_ERR_404_ACTIONS_HEADER';
export const TX_ERR_404_COPY = 'TX_ERR_404_COPY';
export const TX_ERR_404_TITLE = 'TX_ERR_404_TITLE';


// Events Component

export const TX_EVENTS_ADDITIONAL_FILTERS = 'TX_EVENTS_ADDITIONAL_FILTERS';
export const TX_EVENTS_CALENDAR_MONTHLY = 'TX_EVENTS_CALENDAR_MONTHLY';
export const TX_EVENTS_CALENDAR_WEEKLY = 'TX_EVENTS_CALENDAR_WEEKLY';
export const TX_EVENTS_CREATE_EVENT = 'TX_EVENTS_CREATE_EVENT';
export const TX_EVENTS_DELETE_ALERT_COPY = 'TX_EVENTS_DELETE_ALERT_COPY';
export const TX_EVENTS_DELETE_ALL_BUTTON = 'TX_EVENTS_DELETE_ALL_BUTTON';
export const TX_EVENTS_DELETE_ALL_COPY = 'TX_EVENTS_DELETE_ALL_COPY';
export const TX_EVENTS_DELETE_ALL_TITLE = 'TX_EVENTS_DELETE_ALL_TITLE';
export const TX_EVENTS_DELETE_PASSED_ALERT_COPY = 'TX_EVENTS_DELETE_PASSED_ALERT_COPY';
export const TX_EVENTS_DELETE_SINGLE_BUTTON = 'TX_EVENTS_DELETE_SINGLE_BUTTON';
export const TX_EVENTS_END_DATE = 'TX_EVENTS_END_DATE';
export const TX_EVENTS_EVENT_CALENDAR = 'TX_EVENTS_EVENT_CALENDAR';
export const TX_EVENTS_EVENT_DETAILS = 'TX_EVENTS_EVENT_DETAILS';
export const TX_EVENTS_EVENT_NAME = 'TX_EVENTS_EVENT_NAME';
export const TX_EVENTS_EVENT_PASSED = 'TX_EVENTS_EVENT_PASSED';
export const TX_EVENTS_EVENT_TIME = 'TX_EVENTS_EVENT_TIME';
export const TX_EVENTS_FORMAT = 'TX_EVENTS_FORMAT';
export const TX_EVENTS_MORE_FILTERS = 'TX_EVENTS_MORE_FILTERS';
export const TX_EVENTS_NO_EVENTS_SCHEDULED = 'TX_EVENTS_NO_EVENTS_SCHEDULED';
export const TX_EVENTS_PREMIUM_EVENT = 'TX_EVENTS_PREMIUM_EVENT';
export const TX_EVENTS_PREMIUM_ONLY = 'TX_EVENTS_PREMIUM_ONLY';
export const TX_EVENTS_PREMIUM_QUESTION = 'TX_EVENTS_PREMIUM_QUESTION';
export const TX_EVENTS_RECURRING_EVENTS = 'TX_EVENTS_RECURRING_EVENTS';
export const TX_EVENTS_REGISTRATION_FEE = 'TX_EVENTS_REGISTRATION_FEE';
export const TX_EVENTS_REGISTRATION_LIMIT = 'TX_EVENTS_REGISTRATION_LIMIT';
export const TX_EVENTS_REGISTRATION_LIMIT_NOTICE = 'TX_EVENTS_REGISTRATION_LIMIT_NOTICE';
export const TX_EVENTS_REPEAT_FREQUENCY = 'TX_EVENTS_REPEAT_FREQUENCY';
export const TX_EVENTS_REPEAT_QUESTION = 'TX_EVENTS_REPEAT_QUESTION';
export const TX_EVENTS_UPCOMING_EVENTS = 'TX_EVENTS_UPCOMING_EVENTS';


// Footer Component

export const TX_FOOTER_AND_BURRITOS = 'TX_FOOTER_AND_BURRITOS';
export const TX_FOOTER_POWERED_BY = 'TX_FOOTER_POWERED_BY';
export const TX_FOOTER_POWERED_BY_TALARIA = 'TX_FOOTER_POWERED_BY_TALARIA';


// Gallery Component

export const TX_GALLERY_NO_RESULTS = 'TX_GALLERY_NO_RESULTS';
export const TX_GALLERY_LOAD_MORE_RESULTS = 'TX_GALLERY_LOAD_MORE_RESULTS';
export const TX_GALLERY_SORTED_BY = 'TX_GALLERY_SORTED_BY';


// Inventory Component

export const TX_INV_ADD_INVENTORY = 'TX_INV_ADD_INVENTORY';
export const TX_INV_BULK_UPLOAD = 'TX_INV_BULK_UPLOAD';
export const TX_INV_CHANGE_SET = 'TX_INV_CHANGE_SET';
export const TX_INV_EDIT_PRODUCT_LINE = 'TX_INV_EDIT_PRODUCT_LINE';
export const TX_INV_EDIT_TAGS = 'TX_INV_EDIT_TAGS';
export const TX_INV_NEW_PRODUCT_LINE = 'TX_INV_NEW_PRODUCT_LINE';
export const TX_INV_SHOWING_ALL = 'TX_INV_SHOWING_ALL';
export const TX_INV_SHOWING_ALL_ACTION = 'TX_INV_SHOWING_ALL_ACTION';
export const TX_INV_SHOWING_FILTERED = 'TX_INV_SHOWING_FILTERED';
export const TX_INV_SHOWING_FILTERED_ACTION = 'TX_INV_SHOWING_FILTERED_ACTION';
export const TX_INV_UPDATING_INVENTORY = 'TX_INV_UPDATING_INVENTORY';

export const TX_INV_ADD_SELECT_PL = 'TX_INV_ADD_SELECT_PL';
export const TX_INV_ADD_NO_PL = 'TX_INV_ADD_NO_PL';
export const TX_INV_ADD_NO_PL_NOTICE = 'TX_INV_ADD_NO_PL_NOTICE';

export const TX_INV_ADD_PRODUCT = 'TX_INV_ADD_PRODUCT';
export const TX_INV_ADD_PRODUCT_ADD_CUSTOM = 'TX_INV_ADD_PRODUCT_ADD_CUSTOM';
export const TX_INV_ADD_PRODUCT_ALREADY_EXISTS_COPY = 'TX_INV_ADD_PRODUCT_ALREADY_EXISTS_COPY';
export const TX_INV_ADD_PRODUCT_ALREADY_EXISTS_TITLE = 'TX_INV_ADD_PRODUCT_ALREADY_EXISTS_TITLE';
export const TX_INV_ADD_PRODUCT_ANY_LANGUAGE = 'TX_INV_ADD_PRODUCT_ANY_LANGUAGE';
export const TX_INV_ADD_PRODUCT_BACK_TO_SEARCH = 'TX_INV_ADD_PRODUCT_BACK_TO_SEARCH';
export const TX_INV_ADD_PRODUCT_BUY_PRICE = 'TX_INV_ADD_PRODUCT_BUY_PRICE';
export const TX_INV_ADD_PRODUCT_BUYLIST_QUESTION = 'TX_INV_ADD_PRODUCT_BUYLIST_QUESTION';
export const TX_INV_ADD_PRODUCT_DIDNT_FIND = 'TX_INV_ADD_PRODUCT_DIDNT_FIND';
export const TX_INV_ADD_PRODUCT_DRAG_HERE = 'TX_INV_ADD_PRODUCT_DRAG_HERE';
export const TX_INV_ADD_PRODUCT_FACTORY_SEALED = 'TX_INV_ADD_PRODUCT_FACTORY_SEALED';
export const TX_INV_ADD_PRODUCT_FINISHES = 'TX_INV_ADD_PRODUCT_FINISHES';
export const TX_INV_ADD_PRODUCT_NEW_PRODUCT = 'TX_INV_ADD_PRODUCT_NEW_PRODUCT';
export const TX_INV_ADD_PRODUCT_NO_IMAGES_YET = 'TX_INV_ADD_PRODUCT_NO_IMAGES_YET';
export const TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING = 'TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING';
export const TX_INV_ADD_PRODUCT_SEALED_QUESTION = 'TX_INV_ADD_PRODUCT_SEALED_QUESTION';
export const TX_INV_ADD_PRODUCT_SEARCH_ABOVE = 'TX_INV_ADD_PRODUCT_SEARCH_ABOVE';
export const TX_INV_ADD_PRODUCT_SEARCH_AGAIN = 'TX_INV_ADD_PRODUCT_SEARCH_AGAIN';
export const TX_INV_ADD_PRODUCT_SEARCH_BY_PRODUCT_NAME = 'TX_INV_ADD_PRODUCT_SEARCH_BY_PRODUCT_NAME';
export const TX_INV_ADD_PRODUCT_SINGLE = 'TX_INV_ADD_PRODUCT_SINGLE';
export const TX_INV_ADD_PRODUCT_STEP_1 = 'TX_INV_ADD_PRODUCT_STEP_1';
export const TX_INV_ADD_PRODUCT_STEP_1_TITLE = 'TX_INV_ADD_PRODUCT_STEP_1_TITLE';
export const TX_INV_ADD_PRODUCT_STEP_2 = 'TX_INV_ADD_PRODUCT_STEP_2';
export const TX_INV_ADD_PRODUCT_STEP_2_TITLE = 'TX_INV_ADD_PRODUCT_STEP_2_TITLE';
export const TX_INV_ADD_PRODUCT_STEP_3 = 'TX_INV_ADD_PRODUCT_STEP_3';
export const TX_INV_ADD_PRODUCT_TARGET_QUANTITY = 'TX_INV_ADD_PRODUCT_TARGET_QUANTITY';
export const TX_INV_ADD_PRODUCT_UPLOAD_IMAGE = 'TX_INV_ADD_PRODUCT_UPLOAD_IMAGE';

export const TX_INV_BULK_UPLOAD_BACK_TO_PRODUCTS = 'TX_INV_BULK_UPLOAD_BACK_TO_PRODUCTS';
export const TX_INV_BULK_UPLOAD_BULK_UPLOADS = 'TX_INV_BULK_UPLOAD_BULK_UPLOADS';
export const TX_INV_BULK_UPLOAD_CSV_ROW_DATA = 'TX_INV_BULK_UPLOAD_CSV_ROW_DATA';
export const TX_INV_BULK_UPLOAD_DOWNLOAD_DATA = 'TX_INV_BULK_UPLOAD_DOWNLOAD_DATA';
export const TX_INV_BULK_UPLOAD_DOWNLOAD_ERRORS = 'TX_INV_BULK_UPLOAD_DOWNLOAD_ERRORS';
export const TX_INV_BULK_UPLOAD_DOWNLOAD_PREPARING = 'TX_INV_BULK_UPLOAD_DOWNLOAD_PREPARING';
export const TX_INV_BULK_UPLOAD_DOWNLOAD_READY = 'TX_INV_BULK_UPLOAD_DOWNLOAD_READY';
export const TX_INV_BULK_UPLOAD_DOWNLOAD_SKIPPED = 'TX_INV_BULK_UPLOAD_DOWNLOAD_SKIPPED';
export const TX_INV_BULK_UPLOAD_DOWNLOAD_TEMPALTE = 'TX_INV_BULK_UPLOAD_DOWNLOAD_TEMPALTE';
export const TX_INV_BULK_UPLOAD_DRAG_HERE = 'TX_INV_BULK_UPLOAD_DRAG_HERE';
export const TX_INV_BULK_UPLOAD_LINE_NUMBER = 'TX_INV_BULK_UPLOAD_LINE_NUMBER';
export const TX_INV_BULK_UPLOAD_NO_UPLOAD_ERRORS = 'TX_INV_BULK_UPLOAD_NO_UPLOAD_ERRORS';
export const TX_INV_BULK_UPLOAD_PERCENT_COMPLETE = 'TX_INV_BULK_UPLOAD_PERCENT_COMPLETE';
export const TX_INV_BULK_UPLOAD_PROCESSING_CSV = 'TX_INV_BULK_UPLOAD_PROCESSING_CSV';
export const TX_INV_BULK_UPLOAD_PROCESSING_UPLOAD = 'TX_INV_BULK_UPLOAD_PROCESSING_UPLOAD';
export const TX_INV_BULK_UPLOAD_PRODUCT = 'TX_INV_BULK_UPLOAD_PRODUCT';
export const TX_INV_BULK_UPLOAD_RECORDS = 'TX_INV_BULK_UPLOAD_RECORDS';
export const TX_INV_BULK_UPLOAD_RESPONSE_TYPE = 'TX_INV_BULK_UPLOAD_RESPONSE_TYPE';
export const TX_INV_BULK_UPLOAD_ROW_HEADER = 'TX_INV_BULK_UPLOAD_ROW_HEADER';
export const TX_INV_BULK_UPLOAD_SEARCH_RESULTS = 'TX_INV_BULK_UPLOAD_SEARCH_RESULTS';
export const TX_INV_BULK_UPLOAD_SEE_REQUIREMENTS = 'TX_INV_BULK_UPLOAD_SEE_REQUIREMENTS';
export const TX_INV_BULK_UPLOAD_SET_QUANTITY = 'TX_INV_BULK_UPLOAD_SET_QUANTITY';
export const TX_INV_BULK_UPLOAD_SET_QUANTITY_ADD = 'TX_INV_BULK_UPLOAD_SET_QUANTITY_ADD';
export const TX_INV_BULK_UPLOAD_SET_QUANTITY_TOTAL = 'TX_INV_BULK_UPLOAD_SET_QUANTITY_TOTAL';
export const TX_INV_BULK_UPLOAD_STATUS_COMPLETE = 'TX_INV_BULK_UPLOAD_STATUS_COMPLETE';
export const TX_INV_BULK_UPLOAD_STATUS_INCOMPLETE = 'TX_INV_BULK_UPLOAD_STATUS_INCOMPLETE';
export const TX_INV_BULK_UPLOAD_STATUS_PREPARING = 'TX_INV_BULK_UPLOAD_STATUS_PREPARING';
export const TX_INV_BULK_UPLOAD_STATUS_UPLOADING = 'TX_INV_BULK_UPLOAD_STATUS_UPLOADING';
export const TX_INV_BULK_UPLOAD_SUPPLEMENTAL_DATA = 'TX_INV_BULK_UPLOAD_SUPPLEMENTAL_DATA';
export const TX_INV_BULK_UPLOAD_SUPPLEMENTAL_HIDE = 'TX_INV_BULK_UPLOAD_SUPPLEMENTAL_HIDE';
export const TX_INV_BULK_UPLOAD_SUPPLEMENTAL_SHOW = 'TX_INV_BULK_UPLOAD_SUPPLEMENTAL_SHOW';
export const TX_INV_BULK_UPLOAD_SUPPORTED_LINES = 'TX_INV_BULK_UPLOAD_SUPPORTED_LINES';
export const TX_INV_BULK_UPLOAD_TYPE_BINDER = 'TX_INV_BULK_UPLOAD_TYPE_BINDER';
export const TX_INV_BULK_UPLOAD_TYPE_CRYSTAL_COMMERCE = 'TX_INV_BULK_UPLOAD_TYPE_CRYSTAL_COMMERCE';
export const TX_INV_BULK_UPLOAD_TYPE_GENERAL = 'TX_INV_BULK_UPLOAD_TYPE_GENERAL';
export const TX_INV_BULK_UPLOAD_TYPE_TALARIA = 'TX_INV_BULK_UPLOAD_TYPE_TALARIA';
export const TX_INV_BULK_UPLOAD_TYPE_TCG_PLAYER = 'TX_INV_BULK_UPLOAD_TYPE_TCG_PLAYER';
export const TX_INV_BULK_UPLOAD_UPLOAD_CSV = 'TX_INV_BULK_UPLOAD_UPLOAD_CSV';
export const TX_INV_BULK_UPLOAD_UPLOAD_FORMAT = 'TX_INV_BULK_UPLOAD_UPLOAD_FORMAT';
export const TX_INV_BULK_UPLOAD_UPLOAD_PRODUCT_LINE_VALUE = 'TX_INV_BULK_UPLOAD_UPLOAD_PRODUCT_LINE_VALUE';
export const TX_INV_BULK_UPLOAD_UPLOAD_RESULTS = 'TX_INV_BULK_UPLOAD_UPLOAD_RESULTS';
export const TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_CREATED = 'TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_CREATED';
export const TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SUCCESS = 'TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SUCCESS';
export const TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_ERROR = 'TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_ERROR';
export const TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SKIPPED = 'TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SKIPPED';
export const TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_TOTAL = 'TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_TOTAL';
export const TX_INV_BULK_UPLOAD_UPLOADING_CSV = 'TX_INV_BULK_UPLOAD_UPLOADING_CSV';

export const TX_INV_SETS_ADD_SET = 'TX_INV_SETS_ADD_SET';
export const TX_INV_SETS_CURRENT_SETS = 'TX_INV_SETS_CURRENT_SETS';
export const TX_INV_SETS_MUST_BE_SAME_PRODUCT_LINE = 'TX_INV_SETS_MUST_BE_SAME_PRODUCT_LINE';

export const TX_INV_TAGS_ADD_TAG = 'TX_INV_TAGS_ADD_TAG';
export const TX_INV_TAGS_DELETE_TAG_PROMPT = 'TX_INV_TAGS_DELETE_TAG_PROMPT';
export const TX_INV_TAGS_MANAGE_TAGS = 'TX_INV_TAGS_MANAGE_TAGS';
export const TX_INV_TAGS_NO_PRODUCTS_SELECTED = 'TX_INV_TAGS_NO_PRODUCTS_SELECTED';
export const TX_INV_TAGS_NO_SHARED_TAGS = 'TX_INV_TAGS_NO_SHARED_TAGS';
export const TX_INV_TAGS_NO_TAGS = 'TX_INV_TAGS_NO_TAGS';
export const TX_INV_TAGS_ONLY_SHARED_SHOWN = 'TX_INV_TAGS_ONLY_SHARED_SHOWN';

export const TX_INV_VIEW_PRODUCT = 'TX_INV_VIEW_PRODUCT';
export const TX_INV_VIEW_PRODUCT_CURRENTLY_EDITING = 'TX_INV_VIEW_PRODUCT_CURRENTLY_EDITING';
export const TX_INV_VIEW_PRODUCT_DELETE_ALERT_COPY = 'TX_INV_VIEW_PRODUCT_DELETE_ALERT_COPY';
export const TX_INV_VIEW_PRODUCT_DELETE_ALERT_TITLE = 'TX_INV_VIEW_PRODUCT_DELETE_ALERT_TITLE';
export const TX_INV_VIEW_PRODUCT_LANGUAGES_LOADING = 'TX_INV_VIEW_PRODUCT_LANGUAGES_LOADING';
export const TX_INV_VIEW_PRODUCT_NO_INVENTORY_YET = 'TX_INV_VIEW_PRODUCT_NO_INVENTORY_YET';
export const TX_INV_VIEW_PRODUCT_NO_RECENT_ORDERS = 'TX_INV_VIEW_PRODUCT_NO_RECENT_ORDERS';
export const TX_INV_VIEW_PRODUCT_ORDERS_LOADING = 'TX_INV_VIEW_PRODUCT_ORDERS_LOADING';
export const TX_INV_VIEW_PRODUCT_PRICE = 'TX_INV_VIEW_PRODUCT_PRICE';
export const TX_INV_VIEW_PRODUCT_PRICE_RANGE = 'TX_INV_VIEW_PRODUCT_PRICE_RANGE';
export const TX_INV_VIEW_PRODUCT_PRODUCT_DETAILS = 'TX_INV_VIEW_PRODUCT_PRODUCT_DETAILS';
export const TX_INV_VIEW_PRODUCT_RECENT_ORDERS = 'TX_INV_VIEW_PRODUCT_RECENT_ORDERS';
export const TX_INV_VIEW_PRODUCT_TOTAL_QUANTITY = 'TX_INV_VIEW_PRODUCT_TOTAL_QUANTITY';

export const TX_INV_VIEW_PRODUCT_LINE = 'TX_INV_VIEW_PRODUCT_LINE';
export const TX_INV_VIEW_PRODUCT_LINE_DETAILS = 'TX_INV_VIEW_PRODUCT_LINE_DETAILS';
export const TX_INV_VIEW_PRODUCT_LINE_PRICING = 'TX_INV_VIEW_PRODUCT_LINE_PRICING';
export const TX_INV_VIEW_PRODUCT_LINE_PRICING_ALL_OTHER = 'TX_INV_VIEW_PRODUCT_LINE_PRICING_ALL_OTHER';
export const TX_INV_VIEW_PRODUCT_LINE_PRICING_DELETE_COPY = 'TX_INV_VIEW_PRODUCT_LINE_PRICING_DELETE_COPY';
export const TX_INV_VIEW_PRODUCT_LINE_PRICING_LOADING = 'TX_INV_VIEW_PRODUCT_LINE_PRICING_LOADING';
export const TX_INV_VIEW_PRODUCT_LINE_PRICING_RULES = 'TX_INV_VIEW_PRODUCT_LINE_PRICING_RULES';
export const TX_INV_VIEW_PRODUCT_LINE_PRICING_UPDATING_COPY = 'TX_INV_VIEW_PRODUCT_LINE_PRICING_UPDATING_COPY';
export const TX_INV_VIEW_PRODUCT_LINE_PRICING_UPDATING_TITLE = 'TX_INV_VIEW_PRODUCT_LINE_PRICING_UPDATING_TITLE';

export const TX_INV_PL_ALL_PRODUCTS = 'TX_INV_PL_ALL_PRODUCTS';
export const TX_INV_PL_DEFAULT_PRODUCT_WEIGHT = 'TX_INV_PL_DEFAULT_PRODUCT_WEIGHT';
export const TX_INV_PL_SYNC_TYPE = 'TX_INV_PL_SYNC_TYPE';
export const TX_INV_PL_LORCANA = 'TX_INV_PL_LORCANA';
export const TX_INV_PL_LORCANA_SYNC_DESC = 'TX_INV_PL_LORCANA_SYNC_DESC';
export const TX_INV_PL_MAGIC = 'TX_INV_PL_MAGIC';
export const TX_INV_PL_MAGIC_SYNC_DESC = 'TX_INV_PL_MAGIC_SYNC_DESC';
export const TX_INV_PL_POKEMON = 'TX_INV_PL_POKEMON';
export const TX_INV_PL_POKEMON_SYNC_DESC = 'TX_INV_PL_POKEMON_SYNC_DESC';
export const TX_INV_PL_STARWARS = 'TX_INV_PL_STARWARS';
export const TX_INV_PL_STARWARS_SYNC_DESC = 'TX_INV_PL_STARWARS_SYNC_DESC';
export const TX_INV_PL_MANUAL = 'TX_INV_PL_MANUAL';
export const TX_INV_PL_MANUAL_SYNC_DESC = 'TX_INV_PL_MANUAL_SYNC_DESC';
export const TX_INV_PL_ALREADY_IN_USE = 'TX_INV_PL_ALREADY_IN_USE';
export const TX_INV_PL_SYNC_TYPE_NO_CHANGE = 'TX_INV_PL_SYNC_TYPE_NO_CHANGE';
export const TX_INV_PL_DELETE_COPY = 'TX_INV_PL_DELETE_COPY';
export const TX_INV_PL_DELETE_ALERT_COPY = 'TX_INV_PL_DELETE_ALERT_COPY';
export const TX_INV_PL_DELETE_ALERT_TITLE = 'TX_INV_PL_DELETE_ALERT_TITLE';
export const TX_INV_PL_PERMALINK = 'TX_INV_PL_PERMALINK';
export const TX_INV_PL_URL = 'TX_INV_PL_URL';
export const TX_INV_PL_IN_MENU = 'TX_INV_PL_IN_MENU';
export const TX_INV_PL_MENU_ORDER = 'TX_INV_PL_MENU_ORDER';
export const TX_INV_PL_HAS_BUYLIST = 'TX_INV_PL_HAS_BUYLIST';
export const TX_INV_PL_HAS_EVENTS = 'TX_INV_PL_HAS_EVENTS';

export const TX_INV_PRODUCT_PERMALINK = 'TX_INV_PRODUCT_PERMALINK';
export const TX_INV_PRODUCT_URL = 'TX_INV_PRODUCT_URL';


// Marketing Component
export const TX_MARKETING = 'TX_MARKETING';
export const TX_MARKETING_ADD_COUPON = 'TX_MARKETING_ADD_COUPON';
export const TX_MARKETING_COUPON_CODE = 'TX_MARKETING_COUPON_CODE';
export const TX_MARKETING_COUPON_DELETE_ALERT_COPY = 'TX_MARKETING_COUPON_DELETE_ALERT_COPY';
export const TX_MARKETING_COUPON_DELETE_ALERT_TITLE = 'TX_MARKETING_COUPON_DELETE_ALERT_TITLE';
export const TX_MARKETING_COUPON_DISCOUNT_AMOUNT = 'TX_MARKETING_COUPON_DISCOUNT_AMOUNT';
export const TX_MARKETING_COUPON_DISCOUNT_TYPE = 'TX_MARKETING_COUPON_DISCOUNT_TYPE';
export const TX_MARKETING_COUPON_LIMIT_PER_USER_QUESTION = 'TX_MARKETING_COUPON_LIMIT_PER_USER_QUESTION';
export const TX_MARKETING_COUPON_TYPE_FIXED = 'TX_MARKETING_COUPON_TYPE_FIXED';
export const TX_MARKETING_COUPON_TYPE_PERCENTAGE = 'TX_MARKETING_COUPON_TYPE_PERCENTAGE';
export const TX_MARKETING_COUPON_USERS_MUST_BE_LOGGED_IN = 'TX_MARKETING_COUPON_USERS_MUST_BE_LOGGED_IN';
export const TX_MARKETING_COUPON_USES_PER_USER = 'TX_MARKETING_COUPON_USES_PER_USER';
export const TX_MARKETING_COUPONS = 'TX_MARKETING_COUPONS';
export const TX_MARKETING_NOTICES = 'TX_MARKETING_NOTICES';
export const TX_MARKETING_NOTICES_BACKGROUND_COLOR = 'TX_MARKETING_NOTICES_BACKGROUND_COLOR';
export const TX_MARKETING_NOTICES_HEADER = 'TX_MARKETING_NOTICES_HEADER';
export const TX_MARKETING_NOTICES_LINK_URL = 'TX_MARKETING_NOTICES_LINK_URL';
export const TX_MARKETING_NOTICES_NO_LINK_COPY = 'TX_MARKETING_NOTICES_NO_LINK_COPY';
export const TX_MARKETING_NOTICES_TEXT_COLOR = 'TX_MARKETING_NOTICES_TEXT_COLOR';


// Menu Component

export const TX_MENU_SECTION_MANAGE = 'TX_MENU_SECTION_MANAGE';
export const TX_MENU_ITEM_STOREDASHBOARD = 'TX_MENU_ITEM_STOREDASHBOARD';
export const TX_MENU_ITEM_STOREINVENTORY = 'TX_MENU_ITEM_STOREINVENTORY';
export const TX_MENU_ITEM_OPENORDERS = 'TX_MENU_ITEM_OPENORDERS';
export const TX_MENU_ITEM_MANAGEEVENTS = 'TX_MENU_ITEM_MANAGEEVENTS';

export const TX_MENU_SECTION_ACCOUNT = 'TX_MENU_SECTION_ACCOUNT';
export const TX_MENU_ITEM_ACCOUNTDETAILS = 'TX_MENU_ITEM_ACCOUNTDETAILS';
export const TX_MENU_ITEM_ORDERHISTORY = 'TX_MENU_ITEM_ORDERHISTORY';
export const TX_MENU_ITEM_STORECREDIT = 'TX_MENU_ITEM_STORECREDIT';

export const TX_MENU_NO_ITEMS = 'TX_MENU_NO_ITEMS';


// Orders Component

export const TX_ORDER_ADD_NOTE = 'TX_ORDER_ADD_NOTE';
export const TX_ORDER_ADD_PAYMENT_NOTE = 'TX_ORDER_ADD_PAYMENT_NOTE';
export const TX_ORDER_ALREADY_REFUNDED = 'TX_ORDER_ALREADY_REFUNDED';
export const TX_ORDER_AMOUNT_APPLIED = 'TX_ORDER_AMOUNT_APPLIED';
export const TX_ORDER_BUYLIST_ORDERS = 'TX_ORDER_BUYLIST_ORDERS';
export const TX_ORDER_CANCEL_ORDER_COPY = 'TX_ORDER_CANCEL_ORDER_COPY';
export const TX_ORDER_CANCEL_ORDER_PROMPT = 'TX_ORDER_CANCEL_ORDER_PROMPT';
export const TX_ORDER_CANCEL_ORDER_TITLE = 'TX_ORDER_CANCEL_ORDER_TITLE';
export const TX_ORDER_CART_OVERVIEW = 'TX_ORDER_CART_OVERVIEW';
export const TX_ORDER_CARTS = 'TX_ORDER_CARTS';
export const TX_ORDER_COMPLETE_GRADING = 'TX_ORDER_COMPLETE_GRADING';
export const TX_ORDER_COMPLETE_ORDER = 'TX_ORDER_COMPLETE_ORDER';
export const TX_ORDER_COMPLETE_PICKUP = 'TX_ORDER_COMPLETE_PICKUP';
export const TX_ORDER_CREATE_ORDER = 'TX_ORDER_CREATE_ORDER';
export const TX_ORDER_CREATE_PICKUP = 'TX_ORDER_CREATE_PICKUP';
export const TX_ORDER_CREATE_PICKUPS = 'TX_ORDER_CREATE_PICKUPS';
export const TX_ORDER_CREATE_PULL_SHEET = 'TX_ORDER_CREATE_PULL_SHEET';
export const TX_ORDER_CREATE_SHIPMENT = 'TX_ORDER_CREATE_SHIPMENT';
export const TX_ORDER_CREATE_SHIPMENTS = 'TX_ORDER_CREATE_SHIPMENTS';
export const TX_ORDER_CREATING_PICKUPS = 'TX_ORDER_CREATING_PICKUPS';
export const TX_ORDER_CREATING_SHIPMENTS = 'TX_ORDER_CREATING_SHIPMENTS';
export const TX_ORDER_CUSTOMER_EMAIL = 'TX_ORDER_CUSTOMER_EMAIL';
export const TX_ORDER_CUSTOMER_NAME = 'TX_ORDER_CUSTOMER_NAME';
export const TX_ORDER_CUSTOMER_PICKING_UP = 'TX_ORDER_CUSTOMER_PICKING_UP';
export const TX_ORDER_CUSTOMER_PICKUP_LOCATION = 'TX_ORDER_CUSTOMER_PICKUP_LOCATION';
export const TX_ORDER_CUSTOMER_PICKUP_QUESTION = 'TX_ORDER_CUSTOMER_PICKUP_QUESTION';
export const TX_ORDER_DATE = 'TX_ORDER_DATE';
export const TX_ORDER_EXPORT_INVENTORY = 'TX_ORDER_EXPORT_INVENTORY';
export const TX_ORDER_EXPORT_INVOICES = 'TX_ORDER_EXPORT_INVOICES';
export const TX_ORDER_EXPORT_ORDERS = 'TX_ORDER_EXPORT_ORDERS';
export const TX_ORDER_EXPORT_READY = 'TX_ORDER_EXPORT_READY';
export const TX_ORDER_GRADE_ADD_FALSE = 'TX_ORDER_GRADE_ADD_FALSE';
export const TX_ORDER_GRADE_ADD_TRUE = 'TX_ORDER_GRADE_ADD_TRUE';
export const TX_ORDER_GRADE_CONFIRM_COPY = 'TX_ORDER_GRADE_CONFIRM_COPY';
export const TX_ORDER_GRADE_ITEMS = 'TX_ORDER_GRADE_ITEMS';
export const TX_ORDER_GRADE_ITEMS_COPY = 'TX_ORDER_GRADE_ITEMS_COPY';
export const TX_ORDER_GRADE_ITEMS_NO_ITEMS = 'TX_ORDER_GRADE_ITEMS_NO_ITEMS';
export const TX_ORDER_GRADE_ITEMS_TITLE = 'TX_ORDER_GRADE_ITEMS_TITLE';
export const TX_ORDER_HAS_TRACKING_QUESTION = 'TX_ORDER_HAS_TRACKING_QUESTION';
export const TX_ORDER_HISTORY_CREATED = 'TX_ORDER_HISTORY_CREATED';
export const TX_ORDER_HISTORY_GRADED = 'TX_ORDER_HISTORY_GRADED';
export const TX_ORDER_HISTORY_PAYMENT_RECEIVED = 'TX_ORDER_HISTORY_PAYMENT_RECEIVED';
export const TX_ORDER_HISTORY_PICKUP_CLOSED = 'TX_ORDER_HISTORY_PICKUP_CLOSED';
export const TX_ORDER_HISTORY_PICKUP_CREATED = 'TX_ORDER_HISTORY_PICKUP_CREATED';
export const TX_ORDER_HISTORY_REFUND_ISSUED_AMOUNT = 'TX_ORDER_HISTORY_REFUND_ISSUED_AMOUNT';
export const TX_ORDER_HISTORY_SHIPMENT = 'TX_ORDER_HISTORY_SHIPMENT';
export const TX_ORDER_HISTORY_SHIPMENT_CANCELLED = 'TX_ORDER_HISTORY_SHIPMENT_CANCELLED';
export const TX_ORDER_ITEMS_RECEIVED = 'TX_ORDER_ITEMS_RECEIVED';
export const TX_ORDER_ITEMS_RECEIVED_COPY = 'TX_ORDER_ITEMS_RECEIVED_COPY';
export const TX_ORDER_ITEMS_RECEIVED_TITLE = 'TX_ORDER_ITEMS_RECEIVED_TITLE';
export const TX_ORDER_INVOICE_DOWNLOAD = 'TX_ORDER_INVOICE_DOWNLOAD';
export const TX_ORDER_INVOICE_DOWNLOAD_PLURAL = 'TX_ORDER_INVOICE_DOWNLOAD_PLURAL';
export const TX_ORDER_MESSAGES = 'TX_ORDER_MESSAGES';
export const TX_ORDER_NO_MESSAGES = 'TX_ORDER_NO_MESSAGES';
export const TX_ORDER_NO_ORDERS_SELECTED = 'TX_ORDER_NO_ORDERS_SELECTED';
export const TX_ORDER_NO_PAYMENTS = 'TX_ORDER_NO_PAYMENTS';
export const TX_ORDER_NO_PICKUPS = 'TX_ORDER_NO_PICKUPS';
export const TX_ORDER_NO_PICKUPS_REQUIRED = 'TX_ORDER_NO_PICKUPS_REQUIRED';
export const TX_ORDER_NO_SHIPMENTS = 'TX_ORDER_NO_SHIPMENTS';
export const TX_ORDER_NO_SHIPMENTS_REQUIRED = 'TX_ORDER_NO_SHIPMENTS_REQUIRED';
export const TX_ORDER_NOT_READY_FOR_PICKUP = 'TX_ORDER_NOT_READY_FOR_PICKUP';
export const TX_ORDER_ONLINE_ORDER = 'TX_ORDER_ONLINE_ORDER';
export const TX_ORDER_ORDER_DETAILS = 'TX_ORDER_ORDER_DETAILS';
export const TX_ORDER_ORDER_ITEMS = 'TX_ORDER_ORDER_ITEMS';
export const TX_ORDER_ORDER_NUMBER = 'TX_ORDER_ORDER_NUMBER';
export const TX_ORDER_ORDER_NUMBER_DYNAMIC = 'TX_ORDER_ORDER_NUMBER_DYNAMIC';
export const TX_ORDER_ORDER_SUMMARY = 'TX_ORDER_ORDER_SUMMARY';
export const TX_ORDER_ORIGINAL_TOTAL = 'TX_ORDER_ORIGINAL_TOTAL';
export const TX_ORDER_PAYMENT_AMOUNT = 'TX_ORDER_PAYMENT_AMOUNT';
export const TX_ORDER_PAYMENT_RECEIVED = 'TX_ORDER_PAYMENT_RECEIVED';
export const TX_ORDER_PAYMENTS = 'TX_ORDER_PAYMENTS';
export const TX_ORDER_PICKUPS_ADD_CUSTOMER_NOTE = 'TX_ORDER_PICKUPS_ADD_CUSTOMER_NOTE';
export const TX_ORDER_PICKUPS_ADD_PICKUP_NOTE = 'TX_ORDER_PICKUPS_ADD_PICKUP_NOTE';
export const TX_ORDER_PICKUPS_CUSTOMER_NOTE = 'TX_ORDER_PICKUPS_CUSTOMER_NOTE';
export const TX_ORDER_PICKUPS_CLOSE_COPY = 'TX_ORDER_PICKUPS_CLOSE_COPY';
export const TX_ORDER_PICKUPS_CREATE_COPY = 'TX_ORDER_PICKUPS_CREATE_COPY';
export const TX_ORDER_PICKUPS_NOT_PAID_COPY = 'TX_ORDER_PICKUPS_NOT_PAID_COPY';
export const TX_ORDER_PICKUPS_NOT_PAID_TITLE = 'TX_ORDER_PICKUPS_NOT_PAID_TITLE';
export const TX_ORDER_PICKUPS_PICKUP_NOTE = 'TX_ORDER_PICKUPS_PICKUP_NOTE';
export const TX_ORDER_PREORDERS = 'TX_ORDER_PREORDERS';
export const TX_ORDER_PREPARING_EXPORT = 'TX_ORDER_PREPARING_EXPORT';
export const TX_ORDER_PRINT_INVOICE = 'TX_ORDER_PRINT_INVOICE';
export const TX_ORDER_PULL_SHEET = 'TX_ORDER_PULL_SHEET';
export const TX_ORDER_READY_FOR_PICKUP = 'TX_ORDER_READY_FOR_PICKUP';
export const TX_ORDER_RECORD_PAYMENT = 'TX_ORDER_RECORD_PAYMENT';
export const TX_ORDER_RECORD_PICKUP = 'TX_ORDER_RECORD_PICKUP';
export const TX_ORDER_RECORD_RECEIPT = 'TX_ORDER_RECORD_RECEIPT';
export const TX_ORDER_RECORDING_PICKUPS = 'TX_ORDER_RECORDING_PICKUPS';
export const TX_ORDER_RECORD_PICKUP_TITLE = 'TX_ORDER_RECORD_PICKUP_TITLE';
export const TX_ORDER_REFUND_CUSTOM_AMOUNT = 'TX_ORDER_REFUND_CUSTOM_AMOUNT';
export const TX_ORDER_REFUND_ORDER_NUMBER_DYNAMIC = 'TX_ORDER_REFUND_ORDER_NUMBER_DYNAMIC';
export const TX_ORDER_REFUND_NO_ITEMS = 'TX_ORDER_REFUND_NO_ITEMS';
export const TX_ORDER_REFUND_PAYMENT_TITLE = 'TX_ORDER_REFUND_PAYMENT_TITLE';
export const TX_ORDER_REFUND_SUBTOTAL = 'TX_ORDER_REFUND_SUBTOTAL';
export const TX_ORDER_REFUND_WITH_STORE_CREDIT = 'TX_ORDER_REFUND_WITH_STORE_CREDIT';
export const TX_ORDER_REFUNDS_AND_RETURNS = 'TX_ORDER_REFUNDS_AND_RETURNS';
export const TX_ORDER_REFUNDS_AND_RETURNS_TITLE = 'TX_ORDER_REFUNDS_AND_RETURNS_TITLE';
export const TX_ORDER_RETURN_TO_INVENTORY_QUESTION = 'TX_ORDER_RETURN_TO_INVENTORY_QUESTION';
export const TX_ORDER_SEND_MESSAGE = 'TX_ORDER_SEND_MESSAGE';
export const TX_ORDER_SHIPMENT_CANCEL = 'TX_ORDER_SHIPMENT_CANCEL';
export const TX_ORDER_SHIPMENT_CANCELED = 'TX_ORDER_SHIPMENT_CANCELED';
export const TX_ORDER_SHIPMENT_CANCELED_FIRST_CLASS_COPY = 'TX_ORDER_SHIPMENT_CANCELED_FIRST_CLASS_COPY';
export const TX_ORDER_SHIPMENT_CARRIER = 'TX_ORDER_SHIPMENT_CARRIER';
export const TX_ORDER_SHIPMENT_CARRIER_NAME = 'TX_ORDER_SHIPMENT_CARRIER_NAME';
export const TX_ORDER_SHIPMENT_CARRIER_SHORT = 'TX_ORDER_SHIPMENT_CARRIER_SHORT';
export const TX_ORDER_SHIPMENT_CUSTOMER_COST = 'TX_ORDER_SHIPMENT_CUSTOMER_COST';
export const TX_ORDER_SHIPMENT_ESTIMATED_DELIVERY_DAYS = 'TX_ORDER_SHIPMENT_ESTIMATED_DELIVERY_DAYS';
export const TX_ORDER_SHIPMENT_HAS_TRACKING = 'TX_ORDER_SHIPMENT_HAS_TRACKING';
export const TX_ORDER_SHIPMENT_LABEL_ERROR_STAMPS = 'TX_ORDER_SHIPMENT_LABEL_ERROR_STAMPS';
export const TX_ORDER_SHIPMENT_OPTION_REQUIRE_SIGNATURE = 'TX_ORDER_SHIPMENT_OPTION_REQUIRE_SIGNATURE';
export const TX_ORDER_SHIPMENT_OPTIONS = 'TX_ORDER_SHIPMENT_OPTIONS';
export const TX_ORDER_SHIPMENT_OPTIONS_NONE = 'TX_ORDER_SHIPMENT_OPTIONS_NONE';
export const TX_ORDER_SHIPMENT_PARCEL_TYPE = 'TX_ORDER_SHIPMENT_PARCEL_TYPE';
export const TX_ORDER_SHIPMENT_PARCEL_TYPE_ENVELOPE = 'TX_ORDER_SHIPMENT_PARCEL_TYPE_ENVELOPE';
export const TX_ORDER_SHIPMENT_PARCEL_TYPE_PACKAGE = 'TX_ORDER_SHIPMENT_PARCEL_TYPE_PACKAGE';
export const TX_ORDER_SHIPMENT_PROMPT_DEFAULT_ALTERNATE = 'TX_ORDER_SHIPMENT_PROMPT_DEFAULT_ALTERNATE';
export const TX_ORDER_SHIPMENT_PROMPT_STAMPS = 'TX_ORDER_SHIPMENT_PROMPT_STAMPS';
export const TX_ORDER_SHIPMENT_PROMPT_STAMPS_ALTERNATE = 'TX_ORDER_SHIPMENT_PROMPT_STAMPS_ALTERNATE';
export const TX_ORDER_SHIPMENT_SHIPPING_LABEL = 'TX_ORDER_SHIPMENT_SHIPPING_LABEL';
export const TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_MAKE_CLAIM = 'TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_MAKE_CLAIM';
export const TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_MAKE_CLAIM_COPY = 'TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_MAKE_CLAIM_COPY';
export const TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_VIEW_CLAIM = 'TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_VIEW_CLAIM';
export const TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_VIEW_CLAIM_COPY = 'TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_VIEW_CLAIM_COPY';
export const TX_ORDER_SHIPMENT_SHIPPING_LABEL_PRINT = 'TX_ORDER_SHIPMENT_SHIPPING_LABEL_PRINT';
export const TX_ORDER_SHIPMENT_SHIPPING_LABEL_VIEW = 'TX_ORDER_SHIPMENT_SHIPPING_LABEL_VIEW';
export const TX_ORDER_SHIPMENT_TRACKING_NUMBER = 'TX_ORDER_SHIPMENT_TRACKING_NUMBER';
export const TX_ORDER_SHIPMENTS = 'TX_ORDER_SHIPMENTS';
export const TX_ORDER_SHIPMENTS_NOT_REQUIRED = 'TX_ORDER_SHIPMENTS_NOT_REQUIRED';
export const TX_ORDER_SHIPPED_ITEMS = 'TX_ORDER_SHIPPED_ITEMS';
export const TX_ORDER_STORE_CREDIT = 'TX_ORDER_STORE_CREDIT';
export const TX_ORDER_STORE_CREDIT_AMOUNT = 'TX_ORDER_STORE_CREDIT_AMOUNT';
export const TX_ORDER_STORE_CREDIT_APPLIED = 'TX_ORDER_STORE_CREDIT_APPLIED';
export const TX_ORDER_STORE_CREDIT_APPLY_QUESTION = 'TX_ORDER_STORE_CREDIT_APPLY_QUESTION';
export const TX_ORDER_STORE_CREDIT_CUSTOMER_BALANCE = 'TX_ORDER_STORE_CREDIT_CUSTOMER_BALANCE';
export const TX_ORDER_TAKE_PAYMENT = 'TX_ORDER_TAKE_PAYMENT';
export const TX_ORDER_TOTAL_REFUND = 'TX_ORDER_TOTAL_REFUND';
export const TX_ORDER_TOTALS = 'TX_ORDER_TOTALS';
export const TX_ORDER_TRACKING_ONLY_FOR_SINGLE_ORDER = 'TX_ORDER_TRACKING_ONLY_FOR_SINGLE_ORDER';
export const TX_ORDER_VIEW_ORDER = 'TX_ORDER_VIEW_ORDER';


// Product Page Component

export const TX_PRODUCT_PAGE_BUYLIST_NOTICE = 'TX_PRODUCT_PAGE_BUYLIST_NOTICE';
export const TX_PRODUCT_PAGE_BUYLIST_NOTICE_2 = 'TX_PRODUCT_PAGE_BUYLIST_NOTICE_2';
export const TX_PRODUCT_PAGE_BUYLIST_NOTICE_IN_STORE = 'TX_PRODUCT_PAGE_BUYLIST_NOTICE_IN_STORE';
export const TX_PRODUCT_PAGE_NO_DESCRIPTION = 'TX_PRODUCT_PAGE_NO_DESCRIPTION';
export const TX_PRODUCT_PAGE_NOT_BUYLIST = 'TX_PRODUCT_PAGE_NOT_BUYLIST';
export const TX_PRODUCT_PAGE_NOT_FOUND = 'TX_PRODUCT_PAGE_NOT_FOUND';
export const TX_PRODUCT_PAGE_NOT_FOUND_COPY = 'TX_PRODUCT_PAGE_NOT_FOUND_COPY';
export const TX_PRODUCT_PAGE_NUMBER_IN_BUYLIST = 'TX_PRODUCT_PAGE_NUMBER_IN_BUYLIST';
export const TX_PRODUCT_PAGE_NUMBER_IN_CART = 'TX_PRODUCT_PAGE_NUMBER_IN_CART';
export const TX_PRODUCT_PAGE_NUMBER_IN_STOCK = 'TX_PRODUCT_PAGE_NUMBER_IN_STOCK';
export const TX_PRODUCT_PAGE_PREORDER_NOTICE = 'TX_PRODUCT_PAGE_PREORDER_NOTICE';
export const TX_PRODUCT_PAGE_PREORDER_NOTICE_2 = 'TX_PRODUCT_PAGE_PREORDER_NOTICE_2';
export const TX_PRODUCT_PAGE_SEARCH_PRODUCTS = 'TX_PRODUCT_PAGE_SEARCH_PRODUCTS';


// Settings Component

export const TX_SETTINGS_ADD_ADMIN = 'TX_SETTINGS_ADD_ADMIN';
export const TX_SETTINGS_ADD_INTEGRATION = 'TX_SETTINGS_ADD_INTEGRATION';
export const TX_SETTINGS_ADD_PAYMENT_METHOD = 'TX_SETTINGS_ADD_PAYMENT_METHOD';
export const TX_SETTINGS_ADD_SHIPPING_METHOD = 'TX_SETTINGS_ADD_SHIPPING_METHOD';
export const TX_SETTINGS_ADD_SHIPPING_METHOD_CONNECT_INTEGRATION = 'TX_SETTINGS_ADD_SHIPPING_METHOD_CONNECT_INTEGRATION';
export const TX_SETTINGS_ADD_SHIPPING_METHOD_INTEGRATION = 'TX_SETTINGS_ADD_SHIPPING_METHOD_INTEGRATION';
export const TX_SETTINGS_ADMIN_LOOKUP_USER = 'TX_SETTINGS_ADMIN_LOOKUP_USER';
export const TX_SETTINGS_ADMIN_NEED_TO_ADD = 'TX_SETTINGS_ADMIN_NEED_TO_ADD';
export const TX_SETTINGS_ADMIN_PERMISSIONS = 'TX_SETTINGS_ADMIN_PERMISSIONS';
export const TX_SETTINGS_ADMIN_SELECT_USER = 'TX_SETTINGS_ADMIN_SELECT_USER';
export const TX_SETTINGS_ADMIN_USERS = 'TX_SETTINGS_ADMIN_USERS';
export const TX_SETTINGS_API_ACCESS_TOKEN = 'TX_SETTINGS_API_ACCESS_TOKEN';
export const TX_SETTINGS_API_KEY = 'TX_SETTINGS_API_KEY';
export const TX_SETTINGS_CANNOT_REMOVE_PERMISSION = 'TX_SETTINGS_CANNOT_REMOVE_PERMISSION';
export const TX_SETTINGS_COMMERCE_CODE = 'TX_SETTINGS_COMMERCE_CODE';
export const TX_SETTINGS_EDIT_PERMISSIONS = 'TX_SETTINGS_EDIT_PERMISSIONS';
export const TX_SETTINGS_INTEGRATION_ADD_COPY = 'TX_SETTINGS_INTEGRATION_ADD_COPY';
export const TX_SETTINGS_INTEGRATION_STAMPS_OAUTH_CONNECTING = 'TX_SETTINGS_INTEGRATION_STAMPS_OAUTH_CONNECTING';
export const TX_SETTINGS_INTEGRATION_STAMPS_OAUTH_PROMPT = 'TX_SETTINGS_INTEGRATION_STAMPS_OAUTH_PROMPT';
export const TX_SETTINGS_INTEGRATIONS = 'TX_SETTINGS_INTEGRATIONS';
export const TX_SETTINGS_INTEGRATIONS_CHECKING = 'TX_SETTINGS_INTEGRATIONS_CHECKING';
export const TX_SETTINGS_INTEGRATIONS_MARKUP_COPY = 'TX_SETTINGS_INTEGRATIONS_MARKUP_COPY';
export const TX_SETTINGS_INTEGRATIONS_NO_CONFIG = 'TX_SETTINGS_INTEGRATIONS_NO_CONFIG';
export const TX_SETTINGS_INTEGRATIONS_NO_MORE = 'TX_SETTINGS_INTEGRATIONS_NO_MORE';
export const TX_SETTINGS_INTEGRATIONS_PARCEL = 'TX_SETTINGS_INTEGRATIONS_PARCEL';
export const TX_SETTINGS_INTEGRATIONS_PARCEL_COPY = 'TX_SETTINGS_INTEGRATIONS_PARCEL_COPY';
export const TX_SETTINGS_INTEGRATIONS_PARCEL_COPY_2 = 'TX_SETTINGS_INTEGRATIONS_PARCEL_COPY_2';
export const TX_SETTINGS_INTEGRATIONS_PARCEL_DELETE_COPY = 'TX_SETTINGS_INTEGRATIONS_PARCEL_DELETE_COPY';
export const TX_SETTINGS_INTEGRATIONS_REFILL_COPY_DISABLED = 'TX_SETTINGS_INTEGRATIONS_REFILL_COPY_DISABLED';
export const TX_SETTINGS_INTEGRATIONS_REFILL_COPY_ENABLED = 'TX_SETTINGS_INTEGRATIONS_REFILL_COPY_ENABLED';
export const TX_SETTINGS_INTEGRATIONS_REFILL_COPY_ENABLED_2 = 'TX_SETTINGS_INTEGRATIONS_REFILL_COPY_ENABLED_2';
export const TX_SETTINGS_INTEGRATIONS_REFILL_FLOOR_LABEL = 'TX_SETTINGS_INTEGRATIONS_REFILL_FLOOR_LABEL';
export const TX_SETTINGS_MAX_WEIGHT = 'TX_SETTINGS_MAX_WEIGHT';
export const TX_SETTINGS_MIN_PURCHASE = 'TX_SETTINGS_MIN_PURCHASE';
export const TX_SETTINGS_MIN_WEIGHT = 'TX_SETTINGS_MIN_WEIGHT';
export const TX_SETTINGS_PAGE_LAYOUTS = 'TX_SETTINGS_PAGE_LAYOUTS';
export const TX_SETTINGS_PAYMENT_METHOD_CASH = 'TX_SETTINGS_PAYMENT_METHOD_CASH';
export const TX_SETTINGS_PAYMENT_METHOD_EXTERNAL_GENERAL = 'TX_SETTINGS_PAYMENT_METHOD_EXTERNAL_GENERAL';
export const TX_SETTINGS_PAYMENT_METHOD_PAY_AT_PICKUP = 'TX_SETTINGS_PAYMENT_METHOD_PAY_AT_PICKUP';
export const TX_SETTINGS_PAYMENT_METHOD_STORE_CREDIT = 'TX_SETTINGS_PAYMENT_METHOD_STORE_CREDIT';
export const TX_SETTINGS_PAYMENT_METHOD_WEBPAY = 'TX_SETTINGS_PAYMENT_METHOD_WEBPAY';
export const TX_SETTINGS_PAYMENT_METHODS = 'TX_SETTINGS_PAYMENT_METHODS';
export const TX_SETTINGS_PAYMENT_METHODS_ALLOW_AT_PICKUP_QUESTION = 'TX_SETTINGS_PAYMENT_METHODS_ALLOW_AT_PICKUP_QUESTION';
export const TX_SETTINGS_PAYMENT_METHODS_CUSTOM_NAME = 'TX_SETTINGS_PAYMENT_METHODS_CUSTOM_NAME';
export const TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_COPY = 'TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_COPY';
export const TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_TITLE = 'TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_TITLE';
export const TX_SETTINGS_PAYMENT_METHODS_DELETE_COPY = 'TX_SETTINGS_PAYMENT_METHODS_DELETE_COPY';
export const TX_SETTINGS_PAYMENT_METHODS_NO_CONFIG = 'TX_SETTINGS_PAYMENT_METHODS_NO_CONFIG';
export const TX_SETTINGS_PAYMENT_METHODS_NO_MORE = 'TX_SETTINGS_PAYMENT_METHODS_NO_MORE';
export const TX_SETTINGS_PAYMENT_PROCESSOR = 'TX_SETTINGS_PAYMENT_PROCESSOR';
export const TX_SETTINGS_PAYMENT_PROCESSOR_NEED_ACCOUNT = 'TX_SETTINGS_PAYMENT_PROCESSOR_NEED_ACCOUNT';
export const TX_SETTINGS_PAYMENT_PROCESSOR_SQUARE = 'TX_SETTINGS_PAYMENT_PROCESSOR_SQUARE';
export const TX_SETTINGS_PAYMENT_PROCESSOR_TRANSBANK = 'TX_SETTINGS_PAYMENT_PROCESSOR_TRANSBANK';
export const TX_SETTINGS_SHIPPING_METHOD_DELETE_ALERT_COPY = 'TX_SETTINGS_SHIPPING_METHOD_DELETE_ALERT_COPY';
export const TX_SETTINGS_SQUARE_APPLICATION_ID = 'TX_SETTINGS_SQUARE_APPLICATION_ID';
export const TX_SETTINGS_SQUARE_LOCATION_ID = 'TX_SETTINGS_SQUARE_LOCATION_ID';
export const TX_SETTINGS_SQUARE_REDIRECT_NOTICE = 'TX_SETTINGS_SQUARE_REDIRECT_NOTICE';
export const TX_SETTINGS_STORE_SETTINGS = 'TX_SETTINGS_STORE_SETTINGS';
export const TX_SETTINGS_STORE_SETTINGS_DETAILS = 'TX_SETTINGS_STORE_SETTINGS_DETAILS';
export const TX_SETTINGS_STORE_SETTINGS_HOURS = 'TX_SETTINGS_STORE_SETTINGS_HOURS';
export const TX_SETTINGS_STORE_SETTINGS_HOURS_ADD = 'TX_SETTINGS_STORE_SETTINGS_HOURS_ADD';
export const TX_SETTINGS_STORE_SETTINGS_HOURS_NOT_SET = 'TX_SETTINGS_STORE_SETTINGS_HOURS_NOT_SET';
export const TX_SETTINGS_WEBHOOK_SIGNATURE_KEY = 'TX_SETTINGS_WEBHOOK_SIGNATURE_KEY';
export const TX_SETTINGS_WEBHOOK_URL = 'TX_SETTINGS_WEBHOOK_URL';


// Social Media Component

export const TX_SOCIAL_MEDIA = 'TX_SOCIAL_MEDIA';


// Null Component

export const TX_null = 'TX_null';