import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import {
  ERROR_CATCH_ALL, 
  ERROR_PAYMENT_METHOD_DATA_NOT_LOADED,
  ERROR_PAYMENT_METHOD_INVALID_STATE,
} from '../../constants/errors';
import * as tx from '../../constants/strings';
import {
  URL_ADMIN_SETTINGS_PAYMENT_METHODS,
  URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW, 
} from '../../constants/urls';

import { PaymentMethod } from '../../models/payment-methods';

import { 
  stringFormat, 
  formatServerError, 
} from '../../utils/formatting';
import { urlQueryParams } from '../../utils/general';

import { LoadingIcon } from '../Icons/LoadingIcon';

import './style/_paymentmethods.scss';

import * as checkoutActionCreators from '../../actions/checkout';
import * as paymentsActionCreators from '../../actions/payments';
const allActionCreators = Object.assign({}, checkoutActionCreators, paymentsActionCreators);

export class PaymentMethodOauthRedirect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      oauthError: '',
    };

    this.getParams = urlQueryParams();
    this.paymentMethod = PaymentMethod.getByKey(this.props.paymentMethodKey);
  }

  componentDidMount() {
    if(this.getParams && this.paymentMethod) { 

      if(Object.keys(this.getParams).includes("error")) {
        this.setState({ oauthError: this.getParams.error_description || ERROR_CATCH_ALL });
        return null;
      }

      if(!this.getParams.code || this.getParams.response_type !== 'code') {
        this.setState({ oauthError: ERROR_CATCH_ALL });
        return null;
      }

      if(this.paymentMethod.validateRequestState(this.getParams.state) === false) {
        this.setState({ oauthError: ERROR_PAYMENT_METHOD_INVALID_STATE });
      } else {
        this.authorizePaymentMethod();
      }
    } else {
      this.setState({ oauthError: ERROR_PAYMENT_METHOD_DATA_NOT_LOADED });
    }
  }

  async authorizePaymentMethod() {

    // First create payment method
    const addResp = await this.props.checkoutAddPaymentMethod(this.paymentMethod.getApiData())
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          oauthError: formatServerError(errResp),
        });
      });

    if(!addResp) {
      return null;
    }

    // Then authorize with Square
    await this.props.paymentsSquareAuthorize(addResp.public_uuid, this.getParams.code)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          oauthError: formatServerError(errResp),
        });
      });

    history.push(stringFormat(URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW, { public_uuid: addResp.public_uuid }));
  }

  render() {

    const {t} = this.props;

    return <div className={'PaymentMethodOauthRedirect'}>
      <div className='loadingBlockWrapper'>
        {this.state.oauthError ?
          <div className='errorBlock'>
            <div className='errorLabel'>{t(tx.TX_ERROR)}</div>
            <div className='errorValue'>{t(this.state.oauthError)}</div>
            <div className='errorAction'>
              <Link to={URL_ADMIN_SETTINGS_PAYMENT_METHODS} className='backButton'>{t(tx.TX_BACK)}</Link>
            </div>
          </div> :
          <div className='loadingBlock'>
            <div className='iconWrapper'>
              <LoadingIcon />
            </div>
            <div className='labelBlock'>{t(tx.TX_INITIALIZING)}</div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PaymentMethodOauthRedirect));