import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
	ICON_EMPTY,
	ICON_SEARCH, 
} from '../../../constants/icons';
import {
	PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_DEFAULT,
	PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_OPTIONS,
	PROD_BULK_UPLOAD_STATUS_ERROR,
	PROD_BULK_UPLOAD_STATUS_SKIPPED,
} from '../../../constants/product';
import * as tx from '../../../constants/strings';

import { getStoreLanguage } from '../../../utils/language';

import BulkUploadResultListItem from './BulkUploadResultListItem';
import Dropdown from '../../Input/Dropdown';
import { Icon } from '../../Icons/Icon';
import { LoadingIcon } from '../../Icons/LoadingIcon';

const allActionCreators = Object.assign({});

export class BulkUploadResultList extends Component {

	constructor(props) {
    super(props);

    this.state = {
    	
    	inputSearch: '',
    	searchTerm: '',

    	page: 1,
    	pageSize: PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_DEFAULT,
    };
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  changeType(evt) {

  	const newFilters = Object.assign({}, this.props.filters, {
  		status: evt.target.value,
  	});

  	if(!evt.target.value) {
  		delete newFilters.status;
  	}

  	this.props.callApi(1, this.props.pageSize, this.props.pageSort, newFilters, false);
  }

  getTypeDropdownOptions() {
  	const options = [
  		{
  			display: tx.TX_ALL,
  			value: '',
  		},
  	];

  	if(!this.props.results) {
  		return options;
  	}

  	if(this.props.requestUpload && this.props.requestUpload.countError) {
  		options.push({
				display: PROD_BULK_UPLOAD_STATUS_ERROR.name,
				value: PROD_BULK_UPLOAD_STATUS_ERROR.key,
			});
  	}

  	if(this.props.requestUpload && this.props.requestUpload.countSkipped) {
  		options.push({
				display: PROD_BULK_UPLOAD_STATUS_SKIPPED.name,
				value: PROD_BULK_UPLOAD_STATUS_SKIPPED.key,
			});
  	}
  	return options;
  }

  getTotalPages() {
  	try {
  		if(!this.props.requestUpload || !this.props.count) { return 1; }
	  	return Math.ceil(this.props.count / this.props.pageSize);
  	} catch(err) {
  		console.error(err);
  		return 1;
  	}
  }

  changeSearch(evt) {
  	this.setState({ 
  		inputSearch: evt.target.value, 
  	});
  }

  clearQuickSearch() {
  	this.setState({ 
  		inputSearch: '', 
  	}, () => {
  		this.setQuickSearch();
  	});
  }

  setQuickSearch(evt) {

  	if(evt) { evt.preventDefault(); }

  	this.setState({ searchTerm: this.state.inputSearch });
  	const newFilters = Object.assign({}, this.props.filters, {
  		quick: this.state.inputSearch,
  	});

  	if(!this.state.inputSearch) {
  		delete newFilters.quick;
  	}
  	this.props.callApi(1, this.props.pageSize, this.props.pageSort, newFilters, false);
  }

  setPage(pageNum) {
  	const newPage = parseInt(Math.max(Math.min(pageNum, this.getTotalPages()), 1)) || 1;
  	this.props.callApi(newPage, this.props.pageSize, this.props.pageSort, this.props.filters, false);
  	this.scrollToTop();
  }

  changePageSize(evt) {
  	const pageInt = parseInt(evt.target.value);
  	if(pageInt && PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_OPTIONS.includes(pageInt)) {
	  	this.props.callApi(1, pageInt, this.props.pageSort, this.props.filters, false);
	  	this.scrollToTop();
  	}
  }

  getResultsPage() {
  	try {
  		if(!this.props.results) { return []; }
  		return this.props.results;
  	} catch(err) {
  		return [];
  	}
  }

  scrollToTop() {
  	window.scrollTo({ top: 0, behavior: 'smooth'});
  }

  render() {

    const {t} = this.props;
    const resultsLength = this.props.count ? this.props.count : 0;
    const totalPages = this.getTotalPages();

    return <div className={'BulkUploadResultList'}>
      <div className='burlLiner'>
      	<div className='burlHeader'>
      		<div className='appliedFilterRow'>
      			{this.state.searchTerm ?
	      			<div className='afTagWrapper'>
		      			<div className='afTagLabel'></div>
		      			<div className='afTag'>
		      				<div className='afTagLiner'>
		      					<div className='afTagValue'>{this.state.searchTerm}</div>
		      					<div className='afTagAction' onClick={this.clearQuickSearch.bind(this)}>&times;</div>
		      				</div>
		      			</div>
		      		</div> :
		      		null 
		      	}
      		</div>
      		<div className='filterRow'>
      			<div className='typeWrapper'>
      				<div className='filterLabel'>{t(tx.TX_TYPE)}</div>
      				<div className='filterInputWrapper'>
      					<Dropdown 
                  className={'filterTypeDropdown'}
                  options={this.getTypeDropdownOptions()}
                  name={t(tx.TX_TYPE)}
                  value={this.props.filters && this.props.filters.status ? this.props.filters.status : ''}
                  onChange={this.changeType.bind(this)} />
      				</div>
      			</div>
      			<div className='searchWrapper'>
      				<form onSubmit={this.setQuickSearch.bind(this)}>
	      				<input
	      					type='text'
	      					className='searchInput'
	      					placeholder={t(tx.TX_INV_BULK_UPLOAD_SEARCH_RESULTS)}
	      					value={this.state.inputSearch}
	      					onChange={this.changeSearch.bind(this)} />
	      				<div className='searchIconWrapper' onClick={this.setQuickSearch.bind(this)}>
	      					<Icon value={ICON_SEARCH} />
	      				</div>
	      			</form>
      			</div>
      		</div>
      		<div className='pageRow'>
      			<div className='countWrapper'>
      				{this.props.processing ?
      					<div className='countLoading'>
      						<LoadingIcon />
      					</div> :
      					<div className='countLoading'></div>
      				}
      				{this.props.results ?
      					<>
      						{resultsLength === 0 ?
      							<div className='countValue'>{t(tx.TX_GALLERY_NO_RESULTS)}</div> :
      							<>
      								{resultsLength === 1 ?
      									<div className='countValue'>{t(tx.TX_RESULTS_ONE)}</div> :
      									<div className='countValue'>{t(tx.TX_RESULTS_NUM, { num: resultsLength.toLocaleString(this.getLanguage()) })}</div>
      								}
      							</>
      						}
      					</> :
      					null
      				}
      			</div>
      			{resultsLength > 0 ?
	      			<div className={'burlPaginationWrapper pageWrapper'}>
	      				{this.props.pageNum > 1 ?
	      					<div className='pageArrow' onClick={() => this.setPage(this.props.pageNum - 1)}>
		      					<div className={'pageArrowElem leftArrow'}></div>
		      				</div> :
		      				null
	      				}
	      				<div className='pageStatus' dangerouslySetInnerHTML={
		              {__html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, {
		                current: parseInt(this.props.pageNum).toLocaleString(this.getLanguage()), 
		                total: parseInt(totalPages).toLocaleString(this.getLanguage()), 
		                interpolation: {escapeValue: false}
		              })}} />
		            {this.props.pageNum < totalPages ?
			            <div className='pageArrow' onClick={() => this.setPage(this.props.pageNum + 1)}>
			            	<div className='pageArrowElem'></div>
		      				</div> :
		      				null
		      			}
	      			</div> :
	      			null
	      		}
      		</div>
      	</div>
      	<div className='burlResultsWrapper'>
      		<div className='burlResultsLiner'>
	      		{this.props.results ?
	      			<>
	      				{resultsLength === 0 ?
	      					<>
	    							{this.props.processing ?
			      					<div className='loadingView'>
			      						<div className='loadingIconWrapper'>
			      							<LoadingIcon />
			      						</div>
			      						<div className='loadingCopy'>{t(tx.TX_INV_BULK_UPLOAD_PROCESSING_UPLOAD)}</div>
			      					</div> :
			      					<div className='loadingView'>
			      						<div className='loadingIconWrapper emptyIcon'>
			      							<Icon value={ICON_EMPTY} />
			      						</div>
			      						<div className='loadingCopy'>{t(tx.TX_INV_BULK_UPLOAD_NO_UPLOAD_ERRORS)}</div>
			      					</div>
			      				}
	      					</> :
	      					<div className='resultsView'>
	      						{this.getResultsPage().map((resp, j) => {
	      							return <div className='listItemWrapper' key={resp.publicUuid}>
	      								<BulkUploadResultListItem
	      									debugMode={this.props.debugMode}
	      									result={resp} />
	      							</div>;
	      						})}
	      						{this.props.processing && this.getResultsPage().length < this.props.pageSize ?
	      							<div className='rowProgressIndicator'>
	      								<div className='rowIconWrapper'>
	      									<LoadingIcon />
	      								</div>
	      							</div> :
	      							null
	      						}
	      					</div>
	      				}
	      			</> :
	      			<div className='noResultsView'></div>
	      		}
	      		{this.props.requestPending ?
    					<div className='burlResultsLoading'>
    						<div className='loadingIconWrapper'>
    							<LoadingIcon />
    						</div>
      				</div> :
      				null
    				}
      		</div>
      	</div>
      	{(totalPages > 1 || !this.props.processing) && resultsLength > 0 ?
	      	<div className='burlPagination'>
	      		<div className='paginationWrapper'>
	      			<div className='burlPaginationWrapper'>
	    					<div className='pageArrow'>
	      					<div 
	      						className={this.props.pageNum > 1 ? 'pageArrowElem leftArrow' : 'pageArrowElem leftArrow noArrow'}
	      						onClick={() => this.setPage(this.props.pageNum - 1)}></div>
	      				</div>
	      				<div className='pageStatus' dangerouslySetInnerHTML={
		              {__html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, {
		                current: parseInt(this.props.pageNum).toLocaleString(this.getLanguage()), 
		                total: parseInt(totalPages).toLocaleString(this.getLanguage()), 
		                interpolation: {escapeValue: false}
		              })}} />
		            
		            <div className={'pageArrow rightArrow'}>
		            	<div 
		            		className={this.props.pageNum < totalPages ?'pageArrowElem' : 'pageArrowElem noArrow'}
		            		onClick={() => this.setPage(this.props.pageNum + 1)}></div>
	      				</div>
	      			</div>
	      		</div>
      			<div className='paginationSizeWrapper'>
	      			<div className='atpSizeCopy'>{t(tx.TX_ADMIN_TABLE_RESULTS_PER_PAGE)}</div>
		          <div className='atpSizeDropdown'>
		            <select value={this.props.pageSize} onChange={this.changePageSize.bind(this)}>
		              {PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_OPTIONS.map((size, i) => {
		                return <option key={i} value={size}>{size}</option>;
		              })}
		            </select>
		          </div>
	      		</div>
	      	</div> :
	      	null
	      }
	     </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkUploadResultList));