import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP } from '../../../../../constants/store';
import * as tx from '../../../../../constants/strings';

import { 
  getMarkupPercentError, 
  isFormValid, 
} from '../../../../../utils/form-validation';

const allActionCreators = Object.assign({});

export class ShippingMethodChilexpress extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputMarkup: this.getInitMarkupValue(),

      errorMarkup: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.validationSignal && prevProps.validationSignal !== this.props.validationSignal) {
      this.validateAll();
    }
  }

  validateAll() {
    const errorObj = {
      errorMarkup: getMarkupPercentError(this.state.inputMarkup, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  getInitMarkupValue() {
    if(this.props.integrationParent && this.props.integrationParent.getIntegrationConfig(STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP)) {
      return this.props.integrationParent.getIntegrationConfig(STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP);
    }
    return null;
  }

  changeMarkup(evt) {
    this.setState({
      inputMarkup: evt.target.value,
    }, () => {
      if(this.state.errorMarkup) {
        this.validateMarkup();
      }
      this.updateConfig();
    });
  }

  validateMarkup(shouldNormalize = false) {
    this.setState({ errorMarkup: getMarkupPercentError(this.state.inputMarkup, true) });
  }

  updateConfig() {
    this.props.setInegrationConfig({
      [STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP]: this.state.inputMarkup,
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ShippingMethodChilexpress'}>
      <div className='adminFieldWrapper'> 
        <div className='adminInputWrapper halfWidth'>
          <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_MARKUP)}</div>
          <div className='percentWrapper'>
            <input
              type='number'
              min={0}
              max={300}
              step={0.1}
              className={this.state.errorMarkup ? 'InputError percentInput' : 'percentInput'}
              value={this.state.inputMarkup}
              onChange={this.changeMarkup.bind(this)}
              onBlur={this.validateMarkup.bind(this)}
              placeholder={t(tx.TX_PLACEHOLDER_MARKUP_PERCENT)} />
            <div className={'percentOverlay'}>%</div>
          </div>
          {this.state.errorMarkup ?
            <div className={'adminError FieldError'}>{t(this.state.errorMarkup)}</div> :
            null
          }
          <div className={'FieldNotice fieldSupplement subtleSupplement'}>{t(tx.TX_SETTINGS_INTEGRATIONS_MARKUP_COPY)}</div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ShippingMethodChilexpress));