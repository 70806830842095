



export class Checkout {

	// Not implemented yet; lightly integrated into reducers/checkout, but not in a functional way

	// Checkout doesn't map to DB model; it's basically a wrapper for building an Order object over multiple steps
	// Should migrate over the isComplete type functions from utils to this model as well as helper functions, like "getShippingAddress"

	constructor(props) {
		
		if(!props) { 
			props = {}; 
		}

		this.isBuylist = props.isBuylist || props.is_buylist || false;
		this.isComplete = props.isComplete || props.is_complete || false;

		// stepData

		// this.cart = ;
		// this.order = ;
	}

	get steps() {
		return [];
	}
}