import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';

let allActionCreators = Object.assign({});

export class AdminTitle extends Component {

	getTitle() {
    if(this.props.titleData) {
      return this.props.t(this.props.title, this.props.titleData);
    }
    return this.props.t(this.props.title);
  }

  render() {

    const {t} = this.props;

    return <div className={'AdminTitle'}>

      <div className='adminTitleFull'>
        {this.props.breadcrumbs.map((crumb, i) => {
          return <span className='adminTitleCrumb' key={i}>
            <Link to={crumb.urlDynamic || crumb.url} className='titleBreadcrumbLink'>{t(crumb.title)}</Link>
            <span className='titleBreadcrumbChevron'>&gt;</span>
          </span>;
        })}
        <span className='titleMain'>{this.props.title ? this.getTitle() : ''}</span>
      </div>

      <div className='adminTitleMobile'>
        {this.props.breadcrumbs.length ?
          <Link className='atBack' to={this.props.breadcrumbs[this.props.breadcrumbs.length - 1].url}>
            <div className='atBackArrow'>&lt;</div>
            <div className='atBackLabel'>{t(tx.TX_BACK)}</div>
          </Link> :
          null
        }
        <div className={this.props.breadcrumbs.length ? 'atMobileTitle' : 'atMobileTitle noBack'}>{this.props.title ? this.getTitle() : ''}</div>
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTitle));