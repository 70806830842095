
import {
	CHECKOUT_KEY_AUTH,
	CHECKOUT_KEY_SHIPPING_ADDRESS,
	CHECKOUT_KEY_SHIPPING_METHOD,
	CHECKOUT_KEY_PAYMENT_METHOD,
	CHECKOUT_KEY_PAYMENT_DETAILS,
	CHECKOUT_KEY_REVIEW,
	CHECKOUT_STEPS,

	CHECKOUT_KEY_BUYLIST_AUTH,
	CHECKOUT_KEY_BUYLIST_ADDRESS,
	CHECKOUT_KEY_BUYLIST_PAYMENT_METHOD,
	CHECKOUT_BUYLIST_STEPS,
} from '../constants/checkout';
import {
	STORE_CODE_DEFAULT,
	STORE_CODE_FLYINGBEAR,
	STORE_CODE_OASIS,
} from '../constants/store';

import { 
  isLoggedIn 
} from './auth';



export function getCheckoutStepData(stepConfig, checkoutObj) {
	if(!stepConfig || !checkoutObj) { return null; }
	return checkoutObj[stepConfig.key];
}

export function isStepValid(stepConfig, checkoutObj) {
	if(!stepConfig || !checkoutObj) { return false; }

	switch(stepConfig.key) {
		case CHECKOUT_KEY_AUTH:
			return isStepValidAuth(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_SHIPPING_ADDRESS:
			return isStepValidShippingAddress(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_SHIPPING_METHOD:
			return isStepValidShippingMethod(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_PAYMENT_METHOD:
			return isStepValidPaymentMethod(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_PAYMENT_DETAILS:
			return isStepValidPaymentDetails(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_REVIEW:
			return isStepValidReview(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_BUYLIST_AUTH:
			return isStepValidBuylistAuth(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_BUYLIST_ADDRESS:
			return isStepValidBuylistAddress(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_BUYLIST_PAYMENT_METHOD:
			return isStepValidBuylistPaymentMethod(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		default:
			return false;
	}
}

export function isStepValidAuth(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }
	if(stepData.data.isGuest === true && stepData.data.guestUser) {
		return true;
	} else if(stepData.data.isGuest === false && isLoggedIn()) {
		return true;
	}
	return false;
}

export function isStepValidShippingAddress(stepConfig, stepData, checkoutObj) {
	try {
		if(stepData.data.shippingAddress.isValid()) {
			return true;
		}
	} catch(err) {
		return false;
	}
	return false;
}

export function isStepValidShippingMethod(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(checkoutPrereqMet(stepConfig, checkoutObj) === false) { return false; }
	if(stepData.data.shippingMethod && stepData.data.shippingMethod.publicUuid) {
		return true;
	}
	return false;
}

export function isStepValidPaymentMethod(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(checkoutPrereqMet(stepConfig, checkoutObj) === false) { return false; }
	if(stepData.data.paymentMethod && stepData.data.paymentMethod.public_uuid) {
		return true;
	} else if(stepData.data.noPaymentRequired) {
		return true;
	}
	return false;
}

export function isStepValidPaymentDetails(stepConfig, stepData, checkoutObj) {

}

export function isStepValidReview(stepConfig, stepData, checkoutObj) {

}

export function isStepValidBuylistAuth(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }
	if(isLoggedIn()) {
		return true;
	}
	return false;
}

export function isStepValidBuylistAddress(stepConfig, stepData, checkoutObj) {
	try {
		if(stepData.data.shippingAddress.isValid()) {
			return true;
		}
	} catch(err) {
		return false;
	}
	return false;
}

export function isStepValidBuylistPaymentMethod(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(checkoutPrereqMet(stepConfig, checkoutObj) === false) { return false; }
	if(stepData.data.paymentMethod && stepData.data.paymentMethod.public_uuid) {
		return true;
	}
	return false;
}

export function isStepComplete(stepConfig, checkoutObj) {
	if(!stepConfig || !checkoutObj) { return false; }

	switch(stepConfig.key) {
		case CHECKOUT_KEY_AUTH:
			return isStepCompleteAuth(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_SHIPPING_ADDRESS:
			return isStepCompleteShippingAddress(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_SHIPPING_METHOD:
			return isStepCompleteShippingMethod(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_PAYMENT_METHOD:
			return isStepCompletePaymentMethod(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_PAYMENT_DETAILS:
			return isStepCompletePaymentDetails(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_REVIEW:
			return isStepCompleteReview(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_BUYLIST_AUTH:
			return isStepCompleteBuylistAuth(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_BUYLIST_PAYMENT_METHOD:
			return isStepCompleteBuylistPaymentMethod(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		case CHECKOUT_KEY_BUYLIST_ADDRESS:
			return isStepCompleteBuylistAddress(stepConfig, checkoutObj[stepConfig.key], checkoutObj);
		default:
			return false;
	}
}

export function isStepCompleteAuth(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(stepData.data.isGuest === true && stepData.data.guestUser) {
		return true;
	} else if(stepData.data.isGuest === false && isLoggedIn()) {
		return true;
	}
	return false;
}

export function isStepCompleteShippingAddress(stepConfig, stepData, checkoutObj) {
	try {
		if(stepData.data.shippingAddress.isValid() && !stepData.data.editing) {
			return true;
		}
	} catch(err) {
		return false;
	}
	return false;
}

export function isStepCompleteShippingMethod(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(checkoutPrereqMet(stepConfig, checkoutObj) === false) { return false; }
	if(stepData.data.shippingMethod && stepData.data.shippingMethod.publicUuid) {
		return true;
	}
	return false;
}

export function isStepCompletePaymentMethod(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(checkoutPrereqMet(stepConfig, checkoutObj) === false) { return false; }
	if(stepData.data.paymentMethod && stepData.data.paymentMethod.public_uuid) {
		return true;
	} else if(stepData.data.noPaymentRequired) {
		return true;
	}
	
	return false;
}

export function isStepCompletePaymentDetails(stepConfig, stepData, checkoutObj) {

}

export function isStepCompleteReview(stepConfig, stepData, checkoutObj) {

}

export function isStepCompleteBuylistAuth(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(isLoggedIn()) {
		return true;
	}
	return false;
}

export function isStepCompleteBuylistAddress(stepConfig, stepData, checkoutObj) {
	try {
		if(stepData.data.shippingAddress.isValid() && !stepData.data.editing) {
			return true;
		}
	} catch(err) {
		return false;
	}
	return false;
}

export function isStepCompleteBuylistPaymentMethod(stepConfig, stepData, checkoutObj) {
	if(!stepData || !stepData.data) { return false; }

	if(checkoutPrereqMet(stepConfig, checkoutObj) === false) { return false; }
	if(stepData.data.paymentMethod && stepData.data.paymentMethod.public_uuid) {
		return true;
	}
	
	return false;
}

export function getCheckoutStepConfigFromKey(stepKey) {
	if(!stepKey) { return null; }

	const allSteps = CHECKOUT_STEPS.concat(CHECKOUT_BUYLIST_STEPS);
	for(const step of allSteps) {
		if(step.key === stepKey) {
			return step;
		}
	}
	return null;
}

export function checkoutPrereqMet(stepConfig, checkoutObj) {
	if(!stepConfig || !checkoutObj) { return false; }

	if(!stepConfig.prereqKey) {
		return true;
	}

	const preReqConfig = getCheckoutStepConfigFromKey(stepConfig.prereqKey);
	if(preReqConfig === null) {
		return false;
	}

	return isStepComplete(preReqConfig, checkoutObj) && isStepValid(preReqConfig, checkoutObj) ? true : false;
}

export function checkoutGetSelectedShippingMethod(checkoutObj) {
	if(!checkoutObj) { return null; }

	if(checkoutObj[CHECKOUT_KEY_SHIPPING_METHOD]) {
		const shippingMethodData = checkoutObj[CHECKOUT_KEY_SHIPPING_METHOD].data;
		if(shippingMethodData && shippingMethodData.shippingMethod) {
			return shippingMethodData.shippingMethod;
		}
	}	
	return null;
}

export function checkoutGetSelectedPaymentMethod(checkoutObj) {
	if(!checkoutObj) { return null; }

	if(checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD]) {
		const paymentMethodData = checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD].data;
		if(paymentMethodData && paymentMethodData.paymentMethod) {
			return paymentMethodData.paymentMethod;
		}
	}	
	return null;
}

export function checkoutGetNoPaymentsRequired(checkoutObj) {
	if(!checkoutObj) { return false; }

	if(checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD]) {
		const paymentMethodData = checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD].data;
		if(paymentMethodData && paymentMethodData.noPaymentRequired) {
			return paymentMethodData.noPaymentRequired;
		}
	}	
	return false;
}

export function checkoutGetIsStoreCreditApplied(checkoutObj) {
	if(!checkoutObj) { return false; }

	if(checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD]) {
		const paymentMethodData = checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD].data;
		if(paymentMethodData && paymentMethodData.applyStoreCredit) {
			return paymentMethodData.applyStoreCredit;
		}
	}	
	return false;	
}

export function checkoutGetAppliedStoreCredit(checkoutObj) {
	if(!checkoutObj) { return 0; }

	if(checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD]) {
		const paymentMethodData = checkoutObj[CHECKOUT_KEY_PAYMENT_METHOD].data;
		if(paymentMethodData && paymentMethodData.availableStoreCredit) {
			return paymentMethodData.availableStoreCredit;
		}
	}	
	return 0;
}

export function checkoutGetTaxRateSales(checkoutObj) {
	if(!checkoutObj) { return 0; }

	if(checkoutObj[CHECKOUT_KEY_SHIPPING_ADDRESS]) {
		const shippingAddressData = checkoutObj[CHECKOUT_KEY_SHIPPING_ADDRESS].data;
		if(shippingAddressData && shippingAddressData.taxRateSales) {
			return shippingAddressData.taxRateSales;
		}
	}	
	return 0;
}

export function getCartGrandTotalAfterStoreCredit(cart, checkoutObj) {
	if(!cart || !checkoutObj) { return null; }

	let shippingMethodCost = 0.0;
	const shippingMethod = checkoutGetSelectedShippingMethod(checkoutObj);
	if(shippingMethod) {
		shippingMethodCost = parseFloat(shippingMethod.cost) || 0.0;
	}

	let taxCost = 0.0;
	const salesTaxRate = checkoutGetTaxRateSales(checkoutObj);
	const totalTaxable = getCartTaxableGrandTotal(cart, checkoutObj);
	if(salesTaxRate) {
		taxCost += totalTaxable * (salesTaxRate / 100);
	}

	const rawTotal = cart.subTotal - cart.couponDiscount - checkoutGetAppliedStoreCredit(checkoutObj) + shippingMethodCost + taxCost;

	return rawTotal >= 0 ? rawTotal : 0.0;
}

export function getCartTaxableGrandTotal(cart, checkoutObj) {
	if(!cart || !checkoutObj) { return null; }

	// Factors in total:
	// Subtotal
	// Shipping method cost
	// Coupon

	let shippingMethodCost = 0.0;
	const shippingMethod = checkoutGetSelectedShippingMethod(checkoutObj);
	if(shippingMethod) {
		shippingMethodCost = parseFloat(shippingMethod.cost) || 0.0;
	}

	const rawTotal = cart.subTotal - cart.couponDiscount + shippingMethodCost;

	return rawTotal >= 0 ? rawTotal : 0.0;
}

export function getCartGrandTotal(cart, checkoutObj) {
	if(!cart || !checkoutObj) { return null; }

	// Factors in total:
	// Subtotal
	// Shipping method cost
	// Coupon
	// Tax

	// Should NOT factor store credit; that is applied on backend during payment
	// To display total after store credit, use above: getCartGrandTotalAfterStoreCredit

	let shippingMethodCost = 0.0;
	const shippingMethod = checkoutGetSelectedShippingMethod(checkoutObj);
	if(shippingMethod) {
		shippingMethodCost = parseFloat(shippingMethod.cost) || 0.0;
	}

	let taxCost = 0.0;
	const salesTaxRate = checkoutGetTaxRateSales(checkoutObj);
	const totalTaxable = getCartTaxableGrandTotal(cart, checkoutObj);
	if(salesTaxRate) {
		taxCost += totalTaxable * (salesTaxRate / 100);
	}

	const rawTotal = cart.subTotal - cart.couponDiscount + shippingMethodCost + taxCost;

	return rawTotal >= 0 ? rawTotal : 0.0;
}

export function isBuylistCheckoutEnabled() {

	// This sucks and is temporary; 
	// This should be controlled from the settings panel where users have to add a payment method (store credit, paypal, etc) before enabling
	const allowBuylist = [
		STORE_CODE_DEFAULT,
		STORE_CODE_FLYINGBEAR,
		STORE_CODE_OASIS,
	];

	return allowBuylist.includes(process.env.REACT_APP_STORE_CODE);
}












