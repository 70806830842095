import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  getQuantityError, 
} from '../../../../../utils/form-validation';

import * as productActionCreators from '../../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class ProductFieldQuantity extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      inputField: this.props.elemValue === null ? '' : this.props.elemValue, 
      errorField: '',

      lastValue: null,

      isDirty: false,
      isPending: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.focusSignal && this.props.focusSignal !== prevProps.focusSignal) {
      if(this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }

    if(prevProps.elemValue !== this.props.elemValue) {
      const elemValue = this.props.elemValue === null ? '' : this.props.elemValue;
      if(elemValue !== this.state.inputField) {
        this.setState({
          inputField: elemValue,
          lastValue: null,
        })
      }
    }
  }

  getValue() {
    if(this.state.lastValue === null) {
      return this.props.elemValue === null ? '' : this.props.elemValue;
    }
    return this.state.lastValue;
  }

  changeAction(evt) {
    this.setState({
      inputField: evt.target.value,
      isDirty: true,
    }, () => {

    });
  }

  blurAction(evt) {
    if(this.state.isDirty && parseInt(this.state.inputField) !== parseInt(this.getValue())) {
      this.saveAction();
    }
  }

  keyPressAction(evt) {
    if(evt.keyCode === 37) {
      // Left arrow
      if(this.props.fieldIndex >= 0) {
        this.props.indexNav(this.props.fieldIndex - 1);
      }
    } else if(evt.keyCode === 39) {
      // Right arrow
      this.props.indexNav(this.props.fieldIndex + 1);
    }
  }

  isValid() {

    const allowEmpty = this.props.allowEmpty || false;

    const inputError = getQuantityError(this.state.inputField, allowEmpty);
    if(inputError) {
      this.setState({ errorField: 'error' });
      return false;
    }

    return true;
  }

  async saveAction() {

    if(this.isValid()) {

      this.setState({ 
        lastValue: this.state.inputField, 
        errorField: '',
        isDirty: false,
        isPending: true,
      });

      const updateData = {
        [this.props.elemKey]: this.state.inputField,
      }

      const pResp = await this.props.productInventoryEdit(this.props.inventory.getUpdateApiData(updateData, this.props.product))
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            errorField: 'error',
            isPending: false,
          });
        });

      if(!pResp) {
        return null;
      }

      this.setState({
        isPending: false,
      });
    }
  }

  render() {
    return <div className={'ProductFieldQuantity ProductField'}>
      <div className='inputLiner'>
        <div className='editableWrapper'>
          <div className='inputWrapper'>
            <input
              type='number'
              ref={this.inputRef}
              className={`${this.state.errorField ? 'error' : ''} ${this.state.isPending ? 'pending' : ''}`}
              min={0}
              step={1}
              value={this.state.inputField}
              onChange={this.changeAction.bind(this)}
              onKeyDown={this.keyPressAction.bind(this)}
              onBlur={this.blurAction.bind(this)}
              tabIndex={this.props.tabIndex} />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductFieldQuantity));