import React, { Component } from 'react';

import './style/_image.scss';

const imgFilterProps = [
	'thumbnailSrc',
	'loadDelay', 
	'onLoadLow', 
	'onLoadHigh', 
	'onLoadPlaceholder',
]

const omit = (obj, omitKeys) =>
	Object.keys(obj).reduce((result, key) => {
		if(!omitKeys.includes(key)) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export default class ProgressiveImage extends Component {

	constructor(props) {
		super(props);

		this.initState = { 
			shouldLoad: this.props.loadDelay ? false : true,
			lowResImageLoaded: false, 
			highResImageLoaded: false, 
		};

		this.state = this.initState;
	}

	componentDidMount() {
		if(this.state.shouldLoad === false) {
			this.loadTimeout = setTimeout(() => {
				this.setState({ shouldLoad: true }, () => {
					if(this.props.placeholder && this.props.onLoadPlaceholder) {
						this.props.onLoadPlaceholder();
					}
				});
			}, this.props.loadDelay);
		} else {
			if(this.props.placeholder && this.props.onLoadPlaceholder) {
				this.props.onLoadPlaceholder();
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
    if(this.props.thumbnailSrc !== prevProps.thumbnailSrc) {
      this.setState(this.initState);
    }
  }

  componentWillUnmount() {
    if(this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
  }

	lowResLoaded() {
		this.setState({ lowResImageLoaded: true }, () => {
			if(this.props.onLoadLow) {
				this.props.onLoadLow();
			}
		});
	}

	highResLoaded() {
		this.setState({ highResImageLoaded: true }, () => {
			if(this.props.onLoadHigh) {
				this.props.onLoadHigh();
			}
		});
	}

	render() {
		
		const { thumbnailSrc, alt } = this.props;
		let filteredProps = omit(this.props, imgFilterProps);
		
		return (
			<div className='ProgressiveImage'>
				{this.state.lowResImageLoaded ?
					<img
						{...filteredProps}
						onLoad={this.highResLoaded.bind(this)}
						ref={img => {
							this.highResImage = img;
						}}
						style={this.state.highResImageLoaded ? {} : { opacity: '0' }}
						src={this.props.src}
						alt={alt}
					/> :
					null
				}
				{this.state.shouldLoad ?
					<img
						{...filteredProps}
						onLoad={this.lowResLoaded.bind(this)}
						className={`${this.props.className} thumbImg`}
						style={this.state.highResImageLoaded || !this.state.lowResImageLoaded ? { opacity: '0' } : {}}
						src={thumbnailSrc}
						alt={alt}
					/> :
					null
				}
				{this.state.shouldLoad && this.props.placeholder ?
					<img
						{...filteredProps}
						className={`${this.props.className} placeholderImg`}
						style={this.state.lowResImageLoaded || this.state.highResImageLoaded ? { opacity: '0' } : {}}
						src={this.props.placeholder}
						alt={alt}
					/> :
					null
				}
			</div>
		);
	}
}