
import * as x from '../constants/actions';
import { CSS_SLIDE_DURATION } from '../constants/css';

import { 
  BuylistCart,
  Cart, 
} from '../models/carts';

const localCart = Cart.getLocal();
const localBuylist = BuylistCart.getLocal();

const initialState = {
  
  minicartSliderOpen: false, 
  minicartSliderLastToggle: 0, 
  minicartMenuScreenActive: false, 

  pendingGetCart: false,
  pendingCreateCart: false,
  currentCart: localCart,

  pendingGetBuylistCart: false,
  pendingCreateBuylistCart: false,
  currentBuylistCart: localBuylist,

  buylistMode: localCart.isEmpty() && !localBuylist.isEmpty(),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_CART_FETCH_PENDING:
      return cartGetRequestPending(state, action);
    case x.ACTION_CART_TOGGLE_MINICART_SLIDER:
      return cartToggleMinicartSlider(state, action);
    case x.ACTION_CART_SET_CART:
      return cartSetCart(state, action);
    case x.ACTION_CART_BUYLIST_SET_CART:
      return cartSetBuylistCart(state, action);
    case x.ACTION_CART_CREATE_PENDING:
      return cartCreateRequestPending(state, action);
    case x.ACTION_CART_BUYLIST_CREATE_PENDING:
      return cartCreateBuylistRequestPending(state, action);
    case x.ACTION_CART_SET_BUYLIST_MODE:
      return cartSetBuylistMode(state, action);
    default:
      return state;
  }
}

function cartGetRequestPending(state, action) {
 return Object.assign({}, state, {
    pendingGetCart: action.payload,
  });
}

function cartCreateRequestPending(state, action) {
 return Object.assign({}, state, {
    pendingCreateCart: action.payload,
  });
}

function cartCreateBuylistRequestPending(state, action) {
 return Object.assign({}, state, {
    pendingCreateBuylistCart: action.payload,
  });
}

function cartToggleMinicartSlider(state, action) {
  
  if(Date.now() < (CSS_SLIDE_DURATION + state.minicartSliderLastToggle)) {
    return Object.assign({}, state);
  }

  const newValue = action.payload !== true && action.payload !== false ? !state.minicartSliderOpen : action.payload;
  
  return Object.assign({}, state, {
    minicartSliderOpen: newValue,
    minicartSliderLastToggle: Date.now(),
    minicartMenuScreenActive: newValue,
  });
}

function cartSetCart(state, action) {
  action.payload.saveLocal();
  return Object.assign({}, state, {
    currentCart: action.payload,
  });
}

function cartSetBuylistCart(state, action) {
  action.payload.saveLocal();
  return Object.assign({}, state, {
    currentBuylistCart: action.payload,
  });
}

function cartSetBuylistMode(state, action) {
  return Object.assign({}, state, {
    buylistMode: action.payload,
  });
}