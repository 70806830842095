



import { toDate } from 'date-fns-tz';

import { 
	API_FOREIGN_MODEL_LORCANA_CARD,
	API_FOREIGN_MODEL_LORCANA_SET, 
} from '../../constants/api';
import { CONDITIONS_PRODUCT_TCG } from '../../constants/conditions';
import { FINISHES_LORCANA } from '../../constants/finishes';
import { 
	LANG_EN,
	LANG_PRODUCT_LORCANA, 
} from '../../constants/languages';
import { LORCANA_CARD_SKU_PREFIX } from '../../constants/syncs/lorcana';
import { 
	PROD_PAGE_LAYOUT_CLASS_TCG,
	PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG,
	PROD_SKU_MANAGED_DEMARKATION, 
} from '../../constants/product';
import { 
	TX_LORCANA, 
	TX_null, 
} from '../../constants/strings';

import { 
	CatalogTag,
	Product,
	ProductForeignModel,
	ProductSet,
} from '../products';

import { dynamicSort } from '../../utils/sort';

import LorcanaImage from '../../components/Lorcana/LorcanaImage';
import LorcanaCardThumbnail from '../../components/Gallery/blocks/elements/LorcanaCardThumbnail';
import ProductPageDetailsLorcana from '../../components/ProductPage/blocks/ProductPageDetailsLorcana';



export class LorcanaSet extends ProductSet {

	constructor(props) {

		super(props);
		
		this.id = props.id || null;
		this.code = props.code || props.set_code || this.code || '';
		this.name = props.name || props.set_name || this.name || '';
		this.cardCount = parseInt(props.cardCount) || parseInt(props.card_count) || 0;

		const releaseDateValue = props.releaseDate || props.release_date || null;
		this.releaseDate = null;
		if(releaseDateValue) {
			this.releaseDate = releaseDateValue instanceof Date ? releaseDateValue : toDate(releaseDateValue);
		}	
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_LORCANA_SET;
	}

	get lookupKey() {
		return this.code;
	}

	set lookupKey(val) {
		this.code = val;
	}

	toOption(config = {}) {

		const idValue = config.idValue || false;
		const selfValue = config.selfValue || false;

		let value = this.code;
		if(idValue) {
			value = this.id;
		} else if(selfValue) {
			value = this;
		}

		return {
			display: this.name,
			value: value,
			count: null,
		};
	}
}


export class LorcanaCard extends ProductForeignModel {

	constructor(props = {}) {

		super(props);

		this.id = props.id || null;
		this.name = props.name || '';
		this.subtitle = props.subtitle || '';
		this.collectorNumber = props.collectorNumber || props.collector_number || '';
		this.strength = parseInt(props.strength) || 0;
		this.willpower = parseInt(props.willpower) || 0;
		this.inkable = props.inkable || false;
		this.inkCost = parseInt(props.inkCost) || parseInt(props.ink_cost) || 0;
		this.loreValue = props.loreValue || props.lore_value || '';		
		this.bodyText = props.bodyText || props.body_text || '';
		this.flavorText = props.flavorText || props.flavor_text || '';
		this.languageCode = props.languageCode || props.lang || LANG_EN;
		

		const classificationsArray = props.classifications || [];
		const abilitiesArray = props.abilities || [];
		const traitsArray = props.traits || [];

		this.classifications = [];
		for(const cl of classificationsArray) {
			this.classifications.push(new LorcanaClassification(cl));
		}

		this.abilities = [];
		for(const ab of abilitiesArray) {
			this.abilities.push(new LorcanaCardAbility(ab));
		}

		this.traits = [];
		for(const tr of [ ...traitsArray ].sort(dynamicSort('sequence'))) {
			this.traits.push(new LorcanaCardTrait(tr));
		}

		const cardTypeObj = props.cardType || props.card_type || null;
		const colorObj = props.color || null;

		this.type = cardTypeObj ? new LorcanaCardType(cardTypeObj) : null;
		this.color = colorObj ? new LorcanaCardColor(colorObj) : null;

		this.lorcanaSet = new LorcanaSet(props.lorcanaSet || props.lorcana_set || {});
		this.images = new LorcanaCardImages(props.images || {});

		this.tags = [];
		const tagsArray = props.tags || [];
		for(const tg of tagsArray) {
			this.tags.push(new CatalogTag(tg));
		}
	}

	get isLorcanaCard() {
		return true;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_LORCANA_CARD;
	}

	get languageObj() {
		for(const lang of LANG_PRODUCT_LORCANA) {
			if(lang.code === this.languageCode) {
				return lang;
			}
		}
		return LANG_PRODUCT_LORCANA[0];
	}

	get exportInventoryDetails() {
		return true;
	}

	allConditions() {
		return CONDITIONS_PRODUCT_TCG;
	}

	hasFinish() {
		return true;
	}

	allFinishes() {
		return FINISHES_LORCANA;
	}

	get allowSealed() {
		return false;
	}

	get alwaysDisplayLanguage() {
		return true;
	}

	get setName() {
		return this.lorcanaSet.name || '';
	}

	primaryImageSrc(isBack = false) {
		return this.images && this.images.primarySrc ? this.images.primarySrc : LorcanaCardImages.defaultPrimarySrc();
	}

	thumbnailImageSrc(isBack = false) {
		return this.images && this.images.thumbnailSrc ? this.images.thumbnailSrc : LorcanaCardImages.defaultThumbnailSrc();
	}

	getPrimaryImage(props = {}) {
		return <LorcanaImage 
		          lorcanaObj={this}
		          loadDelay={0}
		          {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <LorcanaImage 
		          lorcanaObj={this}
		          loadDelay={0}
		          noFlip={true}
		          thumbnail={true}
		          {...props} />;
	}

	imageAlt(t = null) {
	
		const cardName = this.localizedName;
		const setName = this.set && this.set.name ? this.set.name : '';

		if(!cardName) {
			return t ? t(TX_LORCANA) : '';
		}

		const respArray = [ cardName ];
		if(setName) {
			respArray.push(setName);
		}
		if(t) {
			respArray.push(t(TX_LORCANA));
		}

		return respArray.join(' | ');
	}

	get componentThumbnailGallery() {
		return LorcanaCardThumbnail;
	}

	get componentProductPageDetails() {
		return ProductPageDetailsLorcana;
	}

	get productPageLayoutClass() {
		return PROD_PAGE_LAYOUT_CLASS_TCG;
	}

	get productPageDetailLayoutClass() {
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG;
	}

	createProduct(productLine) {
		return new Product({
			name: this.name,
			title: this.name,
			sku: this.productSku,
			permalink: this.productPermalink,
			description: '',

			product_line: productLine,

			foreignModelCode: this.foreignModelCode,
			foreignModel: this,

			languageObj: this.languageObj,
			languageCode: this.languageObj.code,			
		});
	}

	get productSku() {
		const idBase = '000000000';
		const idString = this.id ? this.id.toString() : '';
		const leadingZeros = idBase.substring(0, idBase.length - idString.length);

		return `${LORCANA_CARD_SKU_PREFIX}${PROD_SKU_MANAGED_DEMARKATION}${leadingZeros}${idString}`.toLowerCase();
	}

	get productPermalink() {
		return this.productSku.replaceAll(PROD_SKU_MANAGED_DEMARKATION, '');
	}

	get set() {
		return this.lorcanaSet || null;
	}

	getSetName() {
		return this.lorcanaSet && this.lorcanaSet.name ? this.lorcanaSet.name : '';
	}

	get compoundName() {
		// return this.subtitle ? [ this.name, this.subtitle ] : [ this.name ];
		return [ this.name ];
	}

	nameWithTags(t = null) {
		
		let nameString = this.name;
		for(const tag of this.tags) {
			if(tag.shouldDisplay) {
				if(t) {
					nameString += ` (${tag.nameTranslationKey ? t(tag.nameTranslationKey) : tag.name})`;
				} else {
					nameString += ` (${tag.name})`;
				}
			}
		}
		return nameString;
	}
}


export class LorcanaSealed {

	constructor(props) {

		if(!props) { props = {}; }

		this.id = props.id || null;
	}
}


export class LorcanaCardTrait {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class LorcanaClassification {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class LorcanaCardAbility {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.description = props.description || '';
		this.sequence = parseInt(props.sequence) || 0;
	}
}


export class LorcanaCardType {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class LorcanaCardColor {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class LorcanaCardImages {

	constructor(props) {

		if(!props) { props = {}; }

		this.small = props.small || '';
		this.medium = props.medium || '';
		this.large = props.large || '';
		this.noArt = props.noArt || props.no_art || '';
		this.artCrop = props.artCrop || props.art_crop || '';
		this.foil = props.foil || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/lorcana/cardback.png';
	}

	static defaultThumbnailSrc() {
		return '/img/product/lorcana/cardback.png';
	}

	get primarySrc() {
		return this.large || LorcanaCardImages.defaultPrimarySrc();
	}

	get thumbnailSrc() {
		return this.small || LorcanaCardImages.defaultThumbnailSrc();
	}
}



































