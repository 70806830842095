import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { STORE_INTEGRATION_TYPE_SHIPPING_KEY } from '../../../constants/store';
import * as tx from '../../../constants/strings';

import LoadingIcon from '../../Icons/LoadingIcon';

import { formatPrice } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

let allActionCreators = Object.assign({});

export class IntegrationCommonView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getIntegration() {
    // 
    // this.props.integration is the integration provider
    // 
    // This is hard-coded to the shipping integration because that's all there is
    // When that's not the case, is_enabled should be moved to the individual blocks
    // 

    if(this.props.integration) {
      return this.props.integration.getIntegration(STORE_INTEGRATION_TYPE_SHIPPING_KEY);
    }
    return null;
  }

  getDisplayValue(itemConfig) {
    if(!itemConfig) { return null; }

    const integration = this.getIntegration();
    if(!integration) {
      return null;
    }

    if(itemConfig.remoteKey && integration.remote && integration.remote[itemConfig.remoteKey]) {
      if(itemConfig.formatPrice) {
        return formatPrice(integration.remote[itemConfig.remoteKey], { addTags: false, language: this.getLanguage() });
      }
      return integration.remote[itemConfig.remoteKey];
    }
    if(itemConfig.component) {
      const DisplayComponent = itemConfig.component;
      return <DisplayComponent 
                integration={integration}
                makeClean={this.props.makeClean}
                initialLoad={this.props.initialLoad} />;
    }
    return null;
  }

  shouldDisplayElement(itemConfig) {
    if(!itemConfig) { return false; }

    if(itemConfig.requireEnabled && !this.getIntegration().isEnabled) {
      return false;
    }
    return true;
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationCommonView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INTEGRATION)}</div>
      </div>
      {this.props.integration && this.getIntegration().isEnabled === false ?
        <div className='detailsMethodDisabled'>{t(tx.TX_CURRENTLY_DISABLED)}</div> : 
        null
      }
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_NAME)}</div>
                  <div className='adminViewValue'>{this.props.integration ? this.props.integration.name : ''}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_ENABLED_QUESTION)}</div>
                  {!this.props.integrationPending ?
                    <div className='adminViewValue'>{this.props.integration && this.getIntegration().isEnabled ? t(tx.TX_ENABLED) : t(tx.TX_DISABLED)}</div> :
                    null
                  }
                </div>
                {!this.props.integrationPending && this.getIntegration() ?
                  <>
                    {this.getIntegration().adminDisplay.filter(ad => this.shouldDisplayElement(ad)).map((ad, i) => {
                      return <div key={ad.key} className={`adminViewItem ${ad.isHidden ? 'hidden' : ''}`}>
                        {ad.label ?
                          <div className='adminViewLabel'>{t(ad.label)}</div> :
                          null
                        }
                        <div className='adminViewValue'>{this.getDisplayValue(ad)}</div>
                      </div>
                    })}
                  </> :
                  null
                }
              </div>
              <div className='adminViewButtonWrapper'>
                <div className={'integrationDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.integrationPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationCommonView));