

import { getCurrencyCode } from './currency';


export function analyticsEvent(eventKey, eventData) {
	if(window.gtag) {
		window.gtag("event", eventKey, eventData);
	}
}

export function viewItemListEvent(products, config = {}) {

	const eventKey = 'view_item_list';

	const itemsArray = [];

	try {

		for(let i = 0; i < products.length; i++) {
			itemsArray.push(products[i].getEventItemData({
				index: i,
				isBuylist: config.isBuylist || false,
				item_list_id: config.item_list_id || '',
				item_list_name: config.item_list_name || '',
			}));
		}

		const eventData = {
			item_list_id: config.item_list_id || 'product_gallery',
			item_list_name: 'Product gallery',
			items: itemsArray,
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function viewItemEvent(products, config = {}) {

	const eventKey = 'view_item';

	const itemsArray = [];

	try {

		let subtotal = 0;
		for(let i = 0; i < products.length; i++) {
			itemsArray.push(products[i].getEventItemData({
				index: i,
				isBuylist: config.isBuylist || false,
				item_list_id: config.item_list_id || '',
				item_list_name: config.item_list_name || '',
			}));
			subtotal += parseFloat(products[i].getMaxPrice()) || 0;
		}

		const eventData = {
			currency: getCurrencyCode(),
			value: subtotal,
			items: itemsArray,
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function addToCartEvent(inventory, product, config = {}) {

	const eventKey = 'add_to_cart';

	const itemsArray = [];

	try {

		itemsArray.push(product.getEventItemData({
			isBuylist: config.isBuylist || false,
			price: config.price || inventory.sellPrice,
			quantity: config.quantity || 1,
		}));

		const eventData = {
			currency: getCurrencyCode(),
			value: parseFloat(config.price || inventory.sellPrice) || 0,
			items: itemsArray,
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function removeFromCartEvent(inventory, product, config = {}) {

	const eventKey = 'remove_from_cart';

	const itemsArray = [];

	try {

		itemsArray.push(product.getEventItemData({
			isBuylist: config.isBuylist || false,
			price: config.price || inventory.sellPrice,
			quantity: config.quantity || 1,
		}));

		const eventData = {
			currency: getCurrencyCode(),
			value: parseFloat(config.price || inventory.sellPrice) || 0,
			items: itemsArray,
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function viewCartEvent(cart, config = {}) {

	const eventKey = 'view_cart';

	const itemsArray = [];

	try {

		for(let i = 0; i < cart.items.length; i++) {
			itemsArray.push(cart.items[i].product.getEventItemData({
				index: i,
				isBuylist: config.isBuylist || false,
				price: config.price || cart.items[i].price || 0,
				quantity: config.quantity || cart.items[i].quantity || 1,
			}));
		}

		const eventData = {
			currency: getCurrencyCode(),
			value: cart.subTotal,
			items: itemsArray,
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function beginCheckoutEvent(cart, config = {}) {

	const eventKey = 'begin_checkout';

	const itemsArray = [];

	try {

		for(let i = 0; i < cart.items.length; i++) {
			itemsArray.push(cart.items[i].product.getEventItemData({
				index: i,
				isBuylist: config.isBuylist || false,
				price: config.price || cart.items[i].price || 0,
				quantity: config.quantity || cart.items[i].quantity || 1,
			}));
		}

		const eventData = {
			currency: getCurrencyCode(),
			value: cart.subTotal,
			items: itemsArray,
		}
		if(cart.coupon) {
			eventData['coupon'] = cart.coupon.code.toUpperCase();
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function addShippingInfoEvent(cart, shippingMethod, config = {}) {

	const eventKey = 'add_shipping_info';

	const itemsArray = [];

	try {

		for(let i = 0; i < cart.items.length; i++) {
			itemsArray.push(cart.items[i].product.getEventItemData({
				index: i,
				isBuylist: config.isBuylist || false,
				price: config.price || cart.items[i].price || 0,
				quantity: config.quantity || cart.items[i].quantity || 1,
			}));
		}

		const eventData = {
			currency: getCurrencyCode(),
			value: cart.subTotal,
			items: itemsArray,
			shipping_tier: shippingMethod.name,
		}
		if(cart.coupon) {
			eventData['coupon'] = cart.coupon.code.toUpperCase();
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function addPaymentInfoEvent(cart, paymentMethod, config = {}) {

	const eventKey = 'add_payment_info';

	const itemsArray = [];

	try {

		for(let i = 0; i < cart.items.length; i++) {
			itemsArray.push(cart.items[i].product.getEventItemData({
				index: i,
				isBuylist: config.isBuylist || false,
				price: config.price || cart.items[i].price || 0,
				quantity: config.quantity || cart.items[i].quantity || 1,
			}));
		}

		const eventData = {
			currency: getCurrencyCode(),
			value: cart.subTotal,
			items: itemsArray,
			payment_type: paymentMethod.name,
		}
		if(cart.coupon) {
			eventData['coupon'] = cart.coupon.code.toUpperCase();
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}

export function purchaseEvent(order, config = {}) {

	const eventKey = 'purchase';

	const itemsArray = [];

	try {

		// TODO: modify so we can accomodate buylist carts, if needed
		if(!order || !order.cart) {
			return null;
		}

		for(let i = 0; i < order.cart.items.length; i++) {
			itemsArray.push(order.cart.items[i].product.getEventItemData({
				index: i,
				isBuylist: config.isBuylist || false,
				price: config.price || order.cart.items[i].price || 0,
				quantity: config.quantity || order.cart.items[i].quantity || 1,
			}));
		}

		const eventData = {
			transaction_id: order.displayNumber,
			currency: getCurrencyCode(),
			value: order.totalCart,
			tax: order.totalTax,
			shipping: order.totalShipping,
			items: itemsArray,
		}
		if(order.coupon) {
			eventData['coupon'] = order.coupon.code.toUpperCase();
		}
		analyticsEvent(eventKey, eventData);

	} catch(err) {
		console.error(err);
	}
}




















