import 'whatwg-fetch';

import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

import { 
	CustomerBulkUpload, 
	CustomerBulkUploadResult,
	StoreCreditEntry, 
	User,
} from '../models/users';

import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/





/******************************
*******  Asynchronous  ********
******************************/


export function customerFetchPage(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_ADMIN_GET_PAGE + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const respModels = [];
        for(const c of resp.data.data) {
          respModels.push(new User(c));
        }
        return Promise.resolve(Object.assign({}, resp.data, { data: respModels }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerFetchSingle(uuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_GET_SINGLE, { uuid: uuid });
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new User(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerQuickSearch(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_SEARCH + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const userArray = [];
	    	for(const u of resp.data.data) {
	    		userArray.push(new User(u));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: userArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerFetchAdminSingle(uuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_GET_SINGLE_ADMIN, { uuid: uuid });
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerAdminAdd(addData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_ADMIN_CREATE;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerFetchAdminUsers(limit, offset) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_ADMIN_GET_ADMIN;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerAdminEdit(editData, uuid) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_EDIT, { uuid: uuid });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(editData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerAdminPermissionsEdit(permissionsData, uuid) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_EDIT_PERMISSIONS, { uuid: uuid });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(permissionsData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerAdminLockAccount(status, uuid) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const lockData = {
			enabled: status,
		};

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_LOCK_ACCOUNT, { uuid: uuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(lockData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerAdminPasswordReset(uuid) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_PASSWORD_RESET, { uuid: uuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerStoreCreditHistory(uuid, getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_STORE_CREDIT_HISTORY, { publicUuid: uuid }) + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const entryArray = [];
	    	for(const e of resp.data.data) {
	    		entryArray.push(new StoreCreditEntry(e));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: entryArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerStoreCreditAdd(uuid, addData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_STORE_CREDIT_ADD, { publicUuid: uuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerBulkUploadFetchPage(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_USER_ADMIN_GET_BULK_UPLOAD_PAGE + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const buArray = [];
	    	for(const bu of resp.data.data) {
	    		buArray.push(new CustomerBulkUpload(bu));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: buArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerProcessBulkUploadCSV(bulkImportData, file, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_USER_ADMIN_BULK_UPLOAD_PROCESS;

		const formData = new FormData();
		formData.append('file', file);
		formData.append('Content-Type', file.type);

		for(const key in bulkImportData) {
			formData.append(key, bulkImportData[key]);
		}

		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_FILE_HEADERS),
		  body: formData,
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(Object.assign({}, resp.data, { customer_upload: new CustomerBulkUpload(resp.data.customer_upload) }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function customerFetchBulkUpload(getParams, uploadUuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_USER_ADMIN_GET_BULK_UPLOAD, { publicUuid: uploadUuid }) + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const resultsArray = [];
	    	for(const res of resp.data.results_data) {
	    		resultsArray.push(new CustomerBulkUploadResult(res));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { 
	    		upload: new CustomerBulkUpload(resp.data.upload),
	    		results_data: resultsArray,
	    	}));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}













