import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  CAL_DISPLAY_TYPE_MOBILE,
  CAL_DISPLAY_TYPE_MONTHLY,
  CAL_DISPLAY_TYPE_WEEKLY,
} from '../../../constants/calendar';
import {
  EVENT_FILTER_KEY_FORMAT,
  EVENT_FILTER_KEY_PREMIUM,
  EVENT_FILTER_KEY_PRODUCT_LINE,
} from '../../../constants/events';
import {
  ICON_CALENDAR_MONTHLY,
  ICON_CALENDAR_WEEKLY,
  ICON_FILTER,
} from '../../../constants/icons';
import { PL_PERMALINK_MAGIC } from '../../../constants/product';
import * as tx from '../../../constants/strings';

import { ProductLine } from '../../../models/products';

import { getOrderedPlayMenu } from '../../../utils/product';

import Dropdown from '../../Input/Dropdown';
import { Icon } from '../../Icons/Icon';
import Toggle from '../../Input/Toggle';

let allActionCreators = Object.assign({});

export class EventCalendarControls extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filtersExpanded: false,
    };
  }

  isMobileView() {
    return this.props.displayMode === CAL_DISPLAY_TYPE_MOBILE;
  }

  isMidSizedView() {
    const midBreak = 1200;

    if(this.props.width && this.props.width <= midBreak) {
      return true;
    }
    return false;
  }

  formatViewDropdown() {
    return [
      {
        display: tx.TX_EVENTS_CALENDAR_WEEKLY,
        value: CAL_DISPLAY_TYPE_WEEKLY,
      },
      {
        display: tx.TX_EVENTS_CALENDAR_MONTHLY,
        value: CAL_DISPLAY_TYPE_MONTHLY,
      },
    ];
  }

  changeView(evt) {
    this.props.setDisplayMode(evt.target.value);
  }

  getProductLineOptions() {

    const plOptions = [
      {
        display: this.props.t(tx.TX_ALL),
        value: '',
      }
    ];

    for(const pl of getOrderedPlayMenu(this.props.productLine.enabled)) {
      plOptions.push({
        display: pl.name,
        value: pl.permalink,
      });
    }
    return plOptions;
  }

  changeProductLine(evt) {
    this.props.setFilter(EVENT_FILTER_KEY_PRODUCT_LINE, evt.target.value, () => {
      this.props.setFilter(EVENT_FILTER_KEY_FORMAT, '');
    });
  }

  getFormatOptions() {

    const options = [
      {
        display: tx.TX_ALL,
        value: '',
      }
    ];

    const magicPl = new ProductLine({ permalink: PL_PERMALINK_MAGIC });
    for(const format of magicPl.eventFormats) {
      options.push({
        display: format.name,
        value: format.key,
      });
    }

    return options;
  }

  changeFormat(evt) {
    this.props.setFilter(EVENT_FILTER_KEY_FORMAT, evt.target.value);
  }

  changePremium(evt) {
    this.props.setFilter(EVENT_FILTER_KEY_PREMIUM, !this.getFilterValue(EVENT_FILTER_KEY_PREMIUM));
  }

  getFilterValue(key) {
    return this.props.filters[key] ? this.props.filters[key] : '';
  }

  toggleAdditionalFilters() {
    this.setState({ filtersExpanded: !this.state.filtersExpanded });
  }

  render() {

    const {t} = this.props;

    return <div className={this.props.admin ? 'EventCalendarControls adminTheme' : 'EventCalendarControls'}>
      <div className='eccLiner'>
        <div className='eccAppliedFilters'>
          
        </div>
        <div className={`eccControlRow ${this.isMobileView() ? 'mobile' : ''}`}>
          <div className={`eccFilters ${this.isMobileView() ? 'mobileFilters' : ''}`}>
            <div className='eccFilterWrapper'>
              <div className='eccFilterLabel'>{t(tx.TX_PRODUCT_LINE)}</div>
              <div className='eccFilterInput'>
                <Dropdown 
                  className='eccFilterDropdown'
                  options={this.getProductLineOptions()}
                  name={t(tx.TX_PRODUCT_LINE)}
                  value={this.getFilterValue(EVENT_FILTER_KEY_PRODUCT_LINE)}
                  onChange={this.changeProductLine.bind(this)}
                  noTranslate={true} />
              </div>
            </div>
            {this.getFilterValue(EVENT_FILTER_KEY_PRODUCT_LINE) === PL_PERMALINK_MAGIC ?
              <div className={`eccFilterWrapper${this.isMidSizedView() ? ' supressed' : ''}`}>
                <div className='eccFilterLabel'>{t(tx.TX_EVENTS_FORMAT)}</div>
                <div className='eccFilterInput'>
                  <Dropdown 
                    className='eccFilterDropdown'
                    options={this.getFormatOptions()}
                    name={t(tx.TX_EVENTS_FORMAT)}
                    value={this.getFilterValue(EVENT_FILTER_KEY_FORMAT)}
                    onChange={this.changeFormat.bind(this)} />
                </div>
              </div> :
              null
            }
            <div className={`eccFilterWrapper${this.isMidSizedView() ? ' supressed' : ''}`}>
              <div className='eccFilterLabel'>{t(tx.TX_EVENTS_PREMIUM_EVENT)}</div>
              <div className='eccFilterInput'>
                <Toggle 
                  className='eccFilterToggle'
                  checked={this.getFilterValue(EVENT_FILTER_KEY_PREMIUM)}
                  name={t(tx.TX_EVENTS_PREMIUM_EVENT)}
                  onToggle={this.changePremium.bind(this)}
                  trueValue={tx.TX_EVENTS_PREMIUM_ONLY}
                  falseValue={tx.TX_ALL} />
              </div>
            </div>
            <div className={`eccFilterToggle${this.isMidSizedView() ? ' visible' : ''}`} onClick={this.toggleAdditionalFilters.bind(this)}>
              <div className='eccFilterIcon'>
                <Icon value={ICON_FILTER} />
              </div>
              <div className='eccFilterToggleLabel'>{t(tx.TX_EVENTS_MORE_FILTERS)}</div>
            </div>
          </div>
          {!this.isMobileView() ?
            <div className='eccViewControls'>
              <div className='eccViewDropdownLabel'>
                {t(tx.TX_VIEW)}
              </div>
              <div className='eccViewDropdownWrapper'>
                <Dropdown 
                  className='eccViewDropdown'
                  options={this.formatViewDropdown()}
                  name={t(tx.TX_VIEW)}
                  value={this.props.displayMode}
                  onChange={this.changeView.bind(this)} />
              </div>

              <div className='eccViewIconGroup'>
                <div className='eccViewIconWrapper' onClick={() => this.props.setDisplayMode(CAL_DISPLAY_TYPE_WEEKLY)}>
                  <Icon value={ICON_CALENDAR_WEEKLY} iconClass={this.props.displayMode === CAL_DISPLAY_TYPE_WEEKLY ?'viewIcon selected' : 'viewIcon'} />
                </div>
                <div className='eccViewIconWrapper' onClick={() => this.props.setDisplayMode(CAL_DISPLAY_TYPE_MONTHLY)}>
                  <Icon value={ICON_CALENDAR_MONTHLY} iconClass={this.props.displayMode === CAL_DISPLAY_TYPE_MONTHLY ?'viewIcon selected' : 'viewIcon'} />
                </div>
              </div>
            </div> :
            null
          }
        </div>
        <div className={`eccAdditionalFilters${this.isMidSizedView() ? ' available' : ''}${this.state.filtersExpanded && !this.isMobileView() ? ' expanded' : ''}`}>
          <div className='additionalLiner'>
            <div className='additionalTitleWrapper'>
              <div className='additionalTitle'>{t(tx.TX_EVENTS_ADDITIONAL_FILTERS)}</div>
            </div>
            <div className={`eccFilterWrapper`}>
              <div className='eccFilterLabel'>{t(tx.TX_EVENTS_FORMAT)}</div>
              <div className='eccFilterInput'>
                <Dropdown 
                  className='eccFilterDropdown'
                  options={this.getFormatOptions()}
                  name={t(tx.TX_EVENTS_FORMAT)}
                  value={this.getFilterValue(EVENT_FILTER_KEY_FORMAT)}
                  onChange={this.changeFormat.bind(this)} />
              </div>
            </div>
            <div className={`eccFilterWrapper`}>
              <div className='eccFilterLabel'>{t(tx.TX_EVENTS_PREMIUM_EVENT)}</div>
              <div className='eccFilterInput'>
                <Toggle 
                  className='eccFilterToggle'
                  checked={this.getFilterValue(EVENT_FILTER_KEY_PREMIUM)}
                  name={t(tx.TX_EVENTS_PREMIUM_EVENT)}
                  onToggle={this.changePremium.bind(this)}
                  trueValue={tx.TX_EVENTS_PREMIUM_ONLY}
                  falseValue={tx.TX_ALL} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventCalendarControls));