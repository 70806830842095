

import { 
	DISTANCE_UNIT_MM,
	WEIGHT_UNIT_G,
} from '../constants/measurements';
import { STORE_INTEGRATION_TYPE_SHIPPING_KEY } from '../constants/store';

import { Integration } from './integrations';

import { 
  normalizePrice, 
} from '../utils/formatting';
import {
	isNumeric,
	isVarString,
	safeParseFloat,
} from '../utils/general';
import {
	convertDistanceToMM,
	convertWeightToG,
	getDistanceUnitFromKey,
	getWeightUnitFromKey,
} from '../utils/measurements';


export class ShippingMethod {

	constructor(props) {

		// As props, should accept another ShippingMethod object or a server resp
		if(!props) { props = {}; }

		const integrationObj = props.integration || null;
		const minWeightUnitValue = props.minWeightUnit || props.min_weight_unit || '';
		const maxWeightUnitValue = props.maxWeightUnit || props.max_weight_unit || '';

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
		this.description = props.description || '';
		this.cost = parseFloat(props.dynamic_cost) || parseFloat(props.cost) || 0.0;
		this.minPurchase = parseFloat(props.minPurchase) || parseFloat(props.min_purchase) || 0.0;		
		this.minWeight = parseFloat(props.minWeight) || parseFloat(props.min_weight) || 0;
		this.minWeightUnit = isVarString(minWeightUnitValue) ? getWeightUnitFromKey(minWeightUnitValue) : minWeightUnitValue;
		this.maxWeight = parseFloat(props.maxWeight) || parseFloat(props.max_weight) || 0;
		this.maxWeightUnit = isVarString(maxWeightUnitValue) ? getWeightUnitFromKey(maxWeightUnitValue) : maxWeightUnitValue;
		this.isEnabled = props.isEnabled || props.is_enabled || false;
		this.isPickup = props.isPickup || props.is_pickup || false;
		this.allowPaymentAtPickup = props.allowPaymentAtPickup || props.allow_payment_at_pickup || false;

		this.integration = integrationObj ? new Integration(integrationObj) : null;
		this.integrationConfig = props.integrationConfig || props.integration_config || {};
	}

	get minWeightG() {
		return convertWeightToG(this.minWeight, this.minWeightUnit);
	}

	get maxWeightG() {
		return convertWeightToG(this.maxWeight, this.maxWeightUnit);
	}

	getApiData() {

		const apiData = {
			is_enabled: this.isEnabled,
      name: this.name,
      description: this.description,
      cost: safeParseFloat(normalizePrice(this.cost)),
      min_purchase: safeParseFloat(normalizePrice(this.minPurchase)),
      min_weight: parseFloat(this.minWeightG) ? Math.round(this.minWeightG) : null,
      max_weight: parseFloat(this.maxWeightG) ? Math.round(this.maxWeightG) : null,
      is_pickup: this.isPickup,
      allow_payment_at_pickup: this.allowPaymentAtPickup,
      integration_config: this.integrationConfig,
		};
		if(this.publicUuid) {
			apiData['public_uuid'] = this.publicUuid;
		}
		if(this.integration) {
			apiData['integration'] = this.integration.publicUuid;
		}
		return apiData;
	}

	getIntegrationConfig(integrationConfigKey) {
		return this.integrationConfig[integrationConfigKey] || null;
	}

	hasLocalIntegrationConfig(configKey) {
		const config = this.integration.providerConfig;

		if(config.localConfig && config.localConfig[STORE_INTEGRATION_TYPE_SHIPPING_KEY]) {
			for(const attr of config.localConfig[STORE_INTEGRATION_TYPE_SHIPPING_KEY]) {
				if(attr.key === configKey) {
					return true;
				}
			}
		}
		return false;
	}
}



export class ShippingParcel {

	constructor(props) {

		// As props, should accept another ShippingParcel object or a server resp
		// Server stores as JSON since they are integration configs; if we move to SQL, will probably need to change some

		if(!props) { props = {}; }

		const distanceUnitValue = props.distanceUnit || props.distance_unit || '';
		const weightUnitValue = props.weightUnit || props.weight_unit || '';

		this.isDefault = props.isDefault || false;

		this.distanceUnit = isVarString(distanceUnitValue) ? getDistanceUnitFromKey(distanceUnitValue) : distanceUnitValue;
		this.weightUnit = isVarString(weightUnitValue) ? getWeightUnitFromKey(weightUnitValue) : weightUnitValue;

		this.minWeight = parseFloat(props.minWeight) || 0;
		this.minWeightG = convertWeightToG(this.minWeight, this.weightUnit);

		this.length = parseFloat(props.length) || 0;
		this.lengthMM = convertDistanceToMM(this.length, this.distanceUnit);

		this.width = parseFloat(props.width) || 0;
		this.widthMM = convertDistanceToMM(this.width, this.distanceUnit);

		this.height = parseFloat(props.height) || 0;
		this.heightMM = convertDistanceToMM(this.height, this.distanceUnit);
	}

	toJson() {
		const apiData = {
			isDefault: this.isDefault,
			distanceUnit: DISTANCE_UNIT_MM.key,
			weightUnit: WEIGHT_UNIT_G.key,
			minWeight: isNumeric(this.minWeightG) ? Math.round(this.minWeightG) : null,
			length: isNumeric(this.lengthMM) ? Math.round(this.lengthMM) : 1,
			width: isNumeric(this.widthMM) ? Math.round(this.widthMM) : 1,
			height: isNumeric(this.heightMM) ? Math.round(this.heightMM) : 1,
		};
		return apiData;
	}
}