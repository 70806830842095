import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { utcToZonedTime } from 'date-fns-tz';

import * as tx from '../../../../constants/strings';
import { URL_PLAY_GENERAL } from '../../../../constants/urls';

import { Month } from '../../../../models/calendar';

import { getDaysEvents } from '../../../../utils/events';
import { 
  getStoreTimeZone,
  isDateToday, 
} from '../../../../utils/general';
import { getStoreLanguage } from '../../../../utils/language';

import { LoadingIcon } from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockEventsUpcoming.module.scss';

import * as eventActionCreators from '../../../../actions/event';
const allActionCreators = Object.assign({}, eventActionCreators);

export class BlockEventsUpcoming extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      targetEvents: 15,

      selectedMonth: new Month(),
    };

    this.bodyElement = React.createRef();
    this.scrollElement = React.createRef();

    // Controllers
    this.controllers = {};
  }

  componentDidMount() {
    this.getUpcomingEvents();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.bodyElement && this.bodyElement.current && this.scrollElement && this.scrollElement.current) {
      if(this.scrollElement.current.getBoundingClientRect().height > this.bodyElement.current.getBoundingClientRect().height) {
        this.setState({ targetEvents: this.state.targetEvents - 1 });
      }
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async getUpcomingEvents() {

    await this.getEventForMonth(this.state.selectedMonth, null, {});
    await this.getEventForMonth(this.state.selectedMonth.nextMonth(), null, {});
  
    this.setState({
      loading: false,
    });
  }

  async getEventForMonth(month = this.state.selectedMonth, sort = null, filters = {}) {

    if(this.controllers[month.year] && this.controllers[month.year][month.monthIndex]) {
      this.controllers[month.year][month.monthIndex].abort();
    }

    if(!this.controllers[month.year]) {
      this.controllers[month.year] = {};
    }

    const controller = new AbortController();
    this.controllers[month.year][month.monthIndex] = controller;

    // Add the +1 for backend since python month index starts at 1, not 0
    const controlParams = {
      month: month.monthIndex + 1,
      year: month.year,
    };

    const getParams = Object.assign({}, filters, controlParams);
    const fetchResp = await this.props.eventAdminFetchMonth(getParams, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) {
          // Request aborted; do nothing
        } else if(errResp) {
          console.error(errResp);
        }
      });

    if(!fetchResp) {
      return null;
    }
  
    this.props.eventSetMonthEvents({
      month: month.monthIndex,
      year: month.year,
      events: fetchResp.events,
      schedules: fetchResp.schedules,
    });
  }

  getDayWrapperClass(day, dayIndex) {

    const baseClass = styles.dayWrapper;
    const isTodayClass = isDateToday(day) ? styles.todayWrapper : '';

    return `${baseClass} ${isTodayClass}`;
  }

  getFormattedTime(startTime) {
    const zonedTimeString = utcToZonedTime(startTime, getStoreTimeZone()).toLocaleString(this.getLanguage(), { hour: 'numeric', minute: 'numeric' });
    return zonedTimeString.replace(' PM', 'p').replace(' AM', 'a');
  }

  render() {

    let eventsRendered = 0;
    const {t} = this.props;

    return <div className={styles.BlockEventsUpcoming}>
      <div className={styles.beuLiner}>
        <div className={this.props.blockStyles.blockTitleUnderline}>
          <div className='FlexCenter'>{this.props.block.isDefaultConfig('title') ? t(this.props.block.getConfigAttr('title')) : this.props.block.getConfigAttr('title')}</div>
        </div>

        <div className={this.props.blockStyles.blockBodyVertical} ref={this.bodyElement}>
          {this.state.loading ?
            <div className={this.props.blockStyles.verticalBodyIcon}>
              <LoadingIcon />
            </div> :
            <div className={this.props.blockStyles.blockBodyVerticalLiner} ref={this.scrollElement}>
              {this.state.selectedMonth.days.map((day, i) => {

                const isToday = isDateToday(day);
                if(!isToday && day < new Date()) {
                  return null;
                }

                const daysEvents = getDaysEvents(day, this.props.event.calendarEvents, this.props.event.calendarSchedules, {});
                if(daysEvents.length === 0) {
                  return null;
                }

                if(isToday) {
                  let allPast = true;
                  for(const dayEvt of daysEvents) {
                    if(!dayEvt.hasPassed()) {
                      allPast = false;
                    }
                  }
                  if(allPast) {
                    return null;
                  }
                }

                if(eventsRendered >= this.state.targetEvents) {
                  return null;
                }

                return <div className={this.getDayWrapperClass(day, i)} key={`day${i}wkMmon${this.state.selectedMonth.monthIndex}`}>
                  
                  {isToday ?
                    <div className={`${styles.dateLabelWrapper} ${styles.today}`}>
                      <div className={styles.dateLabel}>{t(tx.TX_TODAY)}</div>
                    </div> :
                    <div className={styles.dateLabelWrapper}>
                      <div className={styles.dateLabel}>
                        {day.toLocaleString(this.getLanguage(), {
                          month: 'long',
                          day: 'numeric',
                          weekday: 'long',
                        })}
                      </div>
                    </div>
                  }

                  {daysEvents.map((evt, j) => {

                    if(eventsRendered >= this.state.targetEvents || evt.hasPassed()) {
                      return null;
                    }

                    eventsRendered++;

                    return <Link className={styles.eventWrapper} to={evt.getUrl(day)}  key={`${i}:${j}`}>
                      <div className={styles.eventTime}>{this.getFormattedTime(evt.startTime)}</div>
                      <div className={styles.eventLabel}>{evt.name}</div>
                    </Link>
                  })}

                </div>
              })}

              {eventsRendered < this.state.targetEvents ?
                <>
                  {this.state.selectedMonth.nextMonth().days.map((day, i) => {
                    
                    const daysEvents = getDaysEvents(day, this.props.event.calendarEvents, this.props.event.calendarSchedules, {});
                    if(daysEvents.length === 0) {
                      return null;
                    }

                    if(eventsRendered >= this.state.targetEvents) {
                      return null;
                    }

                    return <div className={this.getDayWrapperClass(day, i)} key={`day${i}wkMmon${this.state.selectedMonth.monthIndex}`}>

                      <div className={styles.dateLabelWrapper}>
                        <div className={styles.dateLabel}>
                          {day.toLocaleString(this.getLanguage(), {
                            month: 'long',
                            day: 'numeric',
                            weekday: 'long',
                          })}
                        </div>
                      </div>

                      {daysEvents.map((evt, j) => {

                        if(eventsRendered >= this.state.targetEvents) {
                          return null;
                        }

                        eventsRendered++;

                        return <Link className={styles.eventWrapper} to={evt.getUrl(day)} key={`${i}:${j}`}>
                          <div className={styles.eventTime}>{this.getFormattedTime(evt.startTime)}</div>
                          <div className={styles.eventLabel}>{evt.name}</div>
                        </Link>
                      })}

                    </div>
                  })}
                </> :
                null
              }

              {eventsRendered === 0 ?
                <div className={styles.noDaysRendered}>
                  {t(tx.TX_EVENTS_NO_EVENTS_SCHEDULED)}
                </div> :
                null
              }
            </div>
          }
        </div>

        <div className={this.props.blockStyles.blockFooter}>
          <Link className={this.props.blockStyles.blockAction} to={URL_PLAY_GENERAL}>{t(tx.TX_SEE_ALL)}</Link>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockEventsUpcoming));