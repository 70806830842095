import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  getDefaultStyle,
  getDefaultTranslate,
  getDefaultValue,
  getElementTranslate, 
} from '../../../../utils/table';

let allActionCreators = Object.assign({});

export class GenericElement extends Component {

  render() {  

    const {t} = this.props;

    return <div className={'GenericElement'} {...this.props.customAttr}>
      <div className='genericElementWrapper' style={this.props.dataValue ? {} : getDefaultStyle(this.props.schema)}>
        {this.props.dataValue ?
          <>{getElementTranslate(this.props.schema) ? t(this.props.dataValue) : this.props.dataValue}</> :
          <>{getDefaultTranslate(this.props.schema) ? t(getDefaultValue(this.props.schema)) : getDefaultValue(this.props.schema)}</>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(GenericElement));


