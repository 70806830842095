import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../constants/images';
import { LANG_EN } from '../../../constants/languages';

import { getStoreLanguage } from '../../../utils/language';
import { 
  getMediaAlt,
  getMediaUrl, 
  getMaxPriceFromInventory, 
  getMinPriceFromInventory,
  getOrderedMedia, 
} from '../../../utils/product';

import StaticImage from '../../Image/StaticImage';

import styles from '../style/BlockElementHorizontalProduct.module.scss';

const allActionCreators = Object.assign({});

export class BlockElementHorizontalProduct extends Component {

  getItemImage(product) {
    if(!product || !product.inventory) { return null; }

    if(product.foreignModel) {
      return product.foreignModel.getPrimaryImage({ noFlip: true });
    }

    const productMedia = getOrderedMedia(product);

    if(productMedia.length > 0) {
      return <StaticImage 
                imgObj={{
                  src: getMediaUrl(productMedia[0].key), 
                  alt: getMediaAlt(product, productMedia[0]), 
                  noTranslate: true, 
                }} />
    }
    return <StaticImage 
              imgObj={IMG_GENERIC_PRODUCT} />;
  }

  getUserLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getPriceString(product) {
    if(!product) { return null; }

    const priceConfig = {
      addTags: true, 
      available: !this.props.isBuylist,
      buylist: this.props.isBuylist,
      language: this.getUserLanguage(),
    };
    if(this.props.isBuylist) {
      return getMaxPriceFromInventory(product.inventory, priceConfig);
    } else {
      return getMinPriceFromInventory(product.inventory, priceConfig);
    }
  }

  render() {

    if(!this.props.product) { return null; }

    return <div className={styles.BlockElementHorizontalProduct}>
      <Link 
        className={styles.productWrapper}
        to={{
          pathname: this.props.product.getProductPageUrl(this.props.isBuylist),
          state: { allowBack: true },
        }}>
        <div className={styles.productLiner}>
          <div className={styles.productImgWrapper}>
            {this.getItemImage(this.props.product)}
          </div>
          <div className={styles.productBodyWrapper}>
            <div className={styles.productTitle} lang={this.props.product.lang ? this.props.product.lang : LANG_EN}>{this.props.product.name}</div>
            <div className={styles.productLineWrapper}>{this.props.product.productLine.name}</div>
            {this.props.product.productSet ?
              <div className={styles.productTitleSupplement}>{this.props.product.productSet.name}</div> :
              null
            }
          </div>
          <div className={styles.productPriceWrapper}>
            <div className={styles.priceWrapper} dangerouslySetInnerHTML={{ __html: this.getPriceString(this.props.product) }} />
          </div>
        </div>
      </Link>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockElementHorizontalProduct));