import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { addHighlightTags } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

const allActionCreators = Object.assign({});

export class AutocompleteOptionUser extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getHighlightedAutocomplete(suggestString) {
    if(!this.props.lastQuery) { return suggestString; }
    return addHighlightTags(suggestString, this.props.lastQuery, 'suggestStrong');
  }

  render() {
    return <div className={'AutocompleteOptionUser AutocompleteOption'}>
      <div className='optionLiner'>
        <div 
          className='optionLinePrimary'
          dangerouslySetInnerHTML={{ __html: this.getHighlightedAutocomplete(this.props.user ? this.props.user.fullName(this.getLanguage()) : '') }} />
        <div 
          className='optionLineSecondary'
          dangerouslySetInnerHTML={{ __html: this.getHighlightedAutocomplete(this.props.user ? this.props.user.email : '') }} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AutocompleteOptionUser));