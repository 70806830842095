import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';

import {
  MEDIA_TYPE_IMAGE, 
  MEDIA_DATA_TYPE_FILE, 
  MEDIA_DATA_TYPE_OBJECT, 
} from '../../../constants/media';
import { 
  TX_ALT_PRODUCT_IMAGE, 
  TX_THUMBNAIL 
} from '../../../constants/strings';

import { getMediaUrl } from '../../../utils/product';

import LocalImage from '../../Image/LocalImage';

export function ProductSortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  const {t} = useTranslation();
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function removeThis(evt) {

    evt.stopPropagation()

    if(props.removeObj) {
      props.removeObj(props.id);
    }
  }
  
  return (
    <div 
      className={props.inMotion ? 'ProductSortableItem inMotion' : 'ProductSortableItem'}
      ref={setNodeRef} 
      style={style}>
      {props.isActive ?
        <div className='psiDragOverlay'></div> :
        <div className='psiMediaWrapper'>
          {props.itemType === MEDIA_TYPE_IMAGE && props.objectType === MEDIA_DATA_TYPE_FILE ?
            <div className='localImageWrapper' {...attributes} {...listeners}>
              {props.itemObj ? 
                <LocalImage imgFile={props.itemObj} /> : 
                null
              }
            </div> :
            null
          }
          {props.itemType === MEDIA_TYPE_IMAGE && props.objectType === MEDIA_DATA_TYPE_OBJECT ?
            <div className='objImageWrapper' {...attributes} {...listeners}>
              {props.itemObj ? 
                <img 
                  src={getMediaUrl(props.itemObj.key)} 
                  alt={props.itemObj.caption ? props.itemObj.caption : t(TX_ALT_PRODUCT_IMAGE)} /> : 
                null
              }
            </div> :
            null
          }
          <div className='psiMediaLabel'>{props.orderIndex === 0 ? t(TX_THUMBNAIL) : ''}</div>
          <div className='psiRemoveX' onClick={removeThis}><div>&times;</div></div>
        </div>
      }
        
    </div>
  );
}