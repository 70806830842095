

import { 
	subMonths,
	subWeeks, 
} from 'date-fns';

import { LANG_EN } from '../constants/languages';

import { Store } from '../models/stores';


export function isVarString(testVar) {
	return Object.prototype.toString.call(testVar) === '[object String]';
}

export function isVarArray(testVar) {
	return Object.prototype.toString.call(testVar) === '[object Array]';
}

export function isVarNumber(testVar) {
	return Object.prototype.toString.call(testVar) === '[object Number]';
}

export function isVarObject(testVar) {
	return Object.prototype.toString.call(testVar) === '[object Object]';
}

export function canUseAwait(func) {
  if(typeof func === 'function') {
    const constructorName = func.constructor && func.constructor.name;
    if(constructorName && constructorName.includes('AsyncFunction')) {
      return false;
    } else {
      return true;
    }
  } else {
  	return false;
  }
}


export function isJsEvent(testVar) {
	try {
		return (
	    testVar && 
	    typeof testVar === 'object' &&
	    'nativeEvent' in testVar && 
	    'currentTarget' in testVar && 
	    typeof testVar.stopPropagation === 'function' &&
	    typeof testVar.preventDefault === 'function'
	  );
	} catch(err) {
		console.error(err);
		return false;
	}
}

export function isVarBool(testBool) {
	if(testBool === true || testBool === false) {
		return true;
	}
	return false;
}

export function safeParseInt(valToParse) {
	const parsedValue = parseInt(valToParse);
	if(isNaN(parsedValue)) {
		throw new Error(`safeParseInt found NaN (${valToParse})`);
	}
	return parsedValue;
}

export function safeParseFloat(valToParse) {
	const parsedValue = parseFloat(valToParse);
	if(isNaN(parsedValue)) {
		throw new Error(`safeParseFloat found NaN (${valToParse})`);
	}
	return parsedValue;
}

export function isNumeric(testVar) {
	try {
		safeParseFloat(testVar);
		return true;
	} catch(err) {
		return false;
	}
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function amIHere(testPath, exactMatch = false) {

	if(!testPath) { return false; }

	const currentPathArray = window.location.pathname.split('/');
	const testPathArray = testPath.split('/');

	if(currentPathArray.length !== testPathArray.length) {
		return false;
	}

	for(let i = 0; i < testPathArray.length; i++) {

		if(i > 0 && !testPathArray[i]) { return false; }

		const isRegex = testPathArray[i].substring(0, 2) === '{{' && testPathArray[i].substring(testPathArray[i].length - 2) === '}}';
		if(exactMatch || !isRegex) {
			if(currentPathArray[i] !== testPathArray[i]) {
				return false;
			}
		} else {
			// Only way this would fail with regex if there's an empty string... I hope; already handled above
		}
	}
	return true;
}

export function dateGetDaysAgo(pastDate) {
	if(pastDate instanceof Date) {
		try {
			const now = new Date();
			return parseInt( (now.getTime() - pastDate.getTime()) / (24 * 3600 * 1000) );
		} catch(err) {
			console.error(err);
			return 0;
		}
	} else {
		return 0;
	}
}

export function dateDelta(start, delta = 0) {

	try {

		const startDate  = new Date(start);
		const deltaInt = safeParseInt(delta);

		return new Date( startDate.getTime() + deltaInt * (24 * 3600 * 1000) );
	} catch(err) {
		console.error(err);
		return new Date();
	}
}

export function weekDelta(start, delta = 0) {

	try {

		const startDate  = new Date(start);
		const deltaInt = safeParseInt(delta);

		return subWeeks(startDate, deltaInt);
	} catch(err) {
		console.error(err);
		return new Date();
	}
}

export function monthDelta(start, delta = 0) {

	try {

		const startDate  = new Date(start);
		const deltaInt = safeParseInt(delta);

		return subMonths(startDate, deltaInt);
	} catch(err) {
		console.error(err);
		return new Date();
	}
}

export function isDateToday(testDate) {
	try {
		const now = new Date();
		return now.getDate() === testDate.getDate() && now.getMonth() === testDate.getMonth() && now.getFullYear() === testDate.getFullYear() ? true : false;
	} catch(err) {
		console.error(err);
		return false;
	}
}

export function is24HourTime(language = LANG_EN) {
	// should return either h12 or h23
	return Intl.DateTimeFormat(language, { hour: 'numeric' }).resolvedOptions().hourCycle === 'h23';
}

export function getStoreTimeZone() {
	const defaultTZ = 'America/Los_Angeles';
	return process.env.REACT_APP_TZ ? process.env.REACT_APP_TZ : defaultTZ;
}

export function twoDigitInt(intVal) {
	try {
		const parsedInt = safeParseInt(intVal);
		if(parsedInt < 0 || parsedInt >= 10) {
			return intVal;
		}
		return `0${parsedInt.toString()}`;
	} catch(err) {
		return intVal;
	}
}

export function urlQueryParams() {
	
	const respObj = {};
	const searchVal = window.location.search;
	
	try {
		if(!searchVal) { return respObj; }
		for(const param of searchVal.substring(1).split('&')) {
			const paramComponents = param.split('=');
			respObj[paramComponents[0]] = decodeURIComponent(paramComponents[1]);
		}
	} catch(err) {
		console.error(err);
	}
	return respObj;
}

export function simulateTab() {

	// This is currently very limited; should be expanded if used more
	// Does not repect tabIndex and probably missing some tab-able elements

	try {

		const allElements = document.querySelectorAll('input, button, a, area, object, select, textarea, [contenteditable]');
		const currentIndex = [...allElements].findIndex(el => el === document.activeElement);
		const targetIndex = (currentIndex + 1) % allElements.length;

    allElements[targetIndex].focus();

    return null;

	} catch(err) {
		console.error(err);
	}
}

export function generalToString(val) {
	try {
		return val.toString ? val.toString() : val;
	} catch(err) {
		return val;
	}
}


export function getStoreDefaultDistanceUnit() {
	const st = Store.getLocal();
	return st.defaultDistanceUnit;
}


export function getStoreDefaultWeightUnit() {
	const st = Store.getLocal();
	return st.defaultWeightUnit;
}


export function getDigitString(digitValue) {
	try {
		return digitValue.toString().replace(/\D/g,'');
	} catch(err) {
		return digitValue;
	}
}


export function rgbToHex(rgb) {

  let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  rgb = rgb.substr(4).split(")")[0].split(sep);

  let r = (+rgb[0]).toString(16).toUpperCase(),
      g = (+rgb[1]).toString(16).toUpperCase(),
      b = (+rgb[2]).toString(16).toUpperCase();

  if (r.length === 1)
      r = '0' + r;
  if (g.length === 1)
      g = '0' + g;
  if (b.length === 1)
      b = '0' + b;

  return `${r}${g}${b}`;
}

export function generateCombinations(obj) {

	// This method takes an object of arrays and returns all combinations (not permutations)
	// The keys of the resulting objects are the same as the keys of the original object

	const results = [];

	try {

	  const keys = Object.keys(obj);

	  function combine(currentIndex, currentCombination) {
	    if (currentIndex === keys.length) {
	      results.push(currentCombination);
	      return;
	    }

	    const key = keys[currentIndex];
	    const values = obj[key];

	    for (let value of values) {
	      combine(currentIndex + 1, { ...currentCombination, [key]: value });
	    }
	  }

	  combine(0, {});
	  return results;

	} catch(err) {
		console.error(err);
		return results;
	}
}

export function isCurrentRoute(testUrl) {

  // Normalize the current window URL (without query parameters or hash)
  const currentUrl = window.location.pathname;

  // Escape special regex characters for URL patterns
  const escapeRegExp = (string) =>
    string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Convert test URL into a regex pattern
  const pattern = escapeRegExp(testUrl)
    .replace(/\\{\\{[^}]+\\}\\}/g, '[^/]+'); // Replace {{params}} with regex pattern

  // Create a regular expression for matching
  const regex = new RegExp(`^${pattern}$`);

  // Test if the current URL matches the generated pattern
  return regex.test(currentUrl);
}




















