import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_SHIPPING_METHODS, 
  TABLE_SCHEMA_SHIPPING_METHODS, 
} from '../../constants/tables';
import { URL_ADMIN_SETTINGS } from '../../constants/urls';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import './style/_shippingmethods.scss';

import * as checkoutActionCreators from '../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class ShippingMethods extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shippingMethods: null,
      shippingMethodsPending: false,
    };
  }

  componentDidMount() {

    this.setState({
      shippingMethodsPending: true, 
    });

    this.props.checkoutFetchAdminShippingMethods()
    .then((resp) => {
      
      this.setState({
        shippingMethods: resp,
        shippingMethodsPending: false, 
      });
    })
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
      this.setState({
        shippingMethodsPending: false, 
      });
    });
  }

  render() {
    return <div className={'ShippingMethods AdminPage'}>
      <AdminTitle
        title={tx.TX_SHIPPING_METHODS}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_SHIPPING_METHODS}
          schema={TABLE_SCHEMA_SHIPPING_METHODS}
          data={this.state.shippingMethods} 
          dataLoading={this.state.shippingMethodsPending} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ShippingMethods));