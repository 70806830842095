import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { MKT_NOTICES } from '../../constants/marketing';
import * as tx from '../../constants/strings';
import { URL_ADMIN_MARKETING } from '../../constants/urls';

import { formatServerError } from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import NoticeEdit from './blocks/NoticeEdit';
import NoticeView from './blocks/NoticeView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_notices.scss';

import * as commonActionCreators from '../../actions/common';
import * as storeActionCreators from '../../actions/store';
let allActionCreators = Object.assign({}, commonActionCreators, storeActionCreators);

export class Notices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: {},
      savePromptOpen: false,
      lastBlock: '',

      editNotice: {},

      requestPending: false,
      requestError: null,
      requestArray: [],

      remoteSave: {},
    };

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });

      if(Object.values(this.state.isDirty).includes(true) === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchNotices();
  }

  componentWillUnmount() {
    this.unblock();
    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!Object.values(this.state.remoteSave).includes(true) && Object.values(prevState.remoteSave).includes(true)) {
      if(Object.values(this.state.isDirty).includes(true) === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchNotices();
      }
    }
  }

  async fetchNotices(noticeKey = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
    });

    // Get all admin notices
    const noticeResp = await this.props.storeFetchAdminNotices(controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted || errResp === null) { return null; }

        if(errResp) { console.error(errResp); }
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          requestArray: [],
        });
      });

    this.setState({
      requestPending: false,
      requestError: null,
      requestArray: noticeResp || [],
    });

    if(noticeKey) {
      this.setState({ isDirty: Object.assign({}, this.state.isDirty, { [noticeKey]: false }) });
    }
  }

  makeDirty(noticeKey) {

    if(!noticeKey) { return null; }

    if(!this.state.isDirty[noticeKey]) {
      this.setState({ isDirty: Object.assign({}, this.state.isDirty, { [noticeKey]: true }) });
    }
  }

  toggleBlock(noticeKey) {

    if(!noticeKey) { return null; }

    this.setState({ editNotice: Object.assign({}, this.state.editNotice, { [noticeKey]: !this.state.editNotice[noticeKey] })}, () => {
      if(this.state.editNotice[noticeKey] === false) {
        this.setState({ isDirty: Object.assign({}, this.state.isDirty, { [noticeKey]: false }) });
      }
    });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }

    const remoteSaveObj = {};
    for(const dirtyKey in this.state.isDirty) {
      remoteSaveObj[dirtyKey] = this.state.isDirty[dirtyKey];
    }

    this.setState({
      remoteSave: remoteSaveObj,
    });
  }

  finishRemote(noticeKey, didFail = false) {

    if(!noticeKey) { return null; }

    this.setState({ 
      isDirty: Object.assign({}, this.state.isDirty, { [noticeKey]: didFail !== false }),
      remoteSave: Object.assign({}, this.state.remoteSave, { [noticeKey]: false }),
    });
  }

  getNoticeByKey(noticeKey) {
    if(!this.state.requestArray || !noticeKey) { return null; }

    for(const nt of this.state.requestArray) {
      if(nt.position === noticeKey) {
        return nt;
      }
    }
    return null;
  }

  render() {

    const {t} = this.props;

    return <div className={'Notices AdminPage'}>
      <AdminTitle
        title={tx.TX_MARKETING_NOTICES}
        breadcrumbs={[
          {
            url: URL_ADMIN_MARKETING,
            title: tx.TX_MARKETING,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>

          {MKT_NOTICES.map((noticeConfig, i) => {
            return <div key={i} className='blockWrapper'>
              {this.state.editNotice[noticeConfig.key] ?
                <NoticeEdit
                  notice={this.getNoticeByKey(noticeConfig.key)}
                  noticeConfig={noticeConfig}
                  noticePending={this.state.requestPending}
                  toggleEdit={this.toggleBlock.bind(this)}
                  makeDirty={this.makeDirty.bind(this)}
                  makeClean={this.fetchNotices.bind(this)} 
                  remoteSave={!!this.state.remoteSave[noticeConfig.key]}
                  finishRemote={this.finishRemote.bind(this)} /> :
                <NoticeView
                  notice={this.getNoticeByKey(noticeConfig.key)}
                  noticeConfig={noticeConfig}
                  noticePending={this.state.requestPending}
                  toggleEdit={this.toggleBlock.bind(this)} />
              }
            </div>;
          })}
        </div>
      </div>
      <div className='adminActions'>
        <Link to={URL_ADMIN_MARKETING} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    store: state.store,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Notices));