import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { utcToZonedTime } from 'date-fns-tz';

import * as tx from '../../../constants/strings';

import { formatPrice } from '../../../utils/formatting';
import { getStoreTimeZone } from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';

import LoadingIcon from '../../Icons/LoadingIcon';

import * as commonActionCreators from '../../../actions/common';
let allActionCreators = Object.assign({}, commonActionCreators);

export class EventScheduleDetailsView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  tryToToggle() {
    if(this.props.eventObj.hasPassed()) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_EVENTS_EVENT_PASSED, 
        alertCopy: tx.TX_EVENTS_DELETE_PASSED_ALERT_COPY, 
      });
      return false;
    }
    this.props.toggleEdit();
  }

  render() {

    const {t} = this.props;

    return <div className={'EventScheduleDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_EVENTS_EVENT_DETAILS)}</div>
      </div>
      {this.props.eventObj.isEnabled === false ?
        <div className='detailsMethodDisabled'>{t(tx.TX_CURRENTLY_DISABLED)}</div> : 
        null
      }
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_NAME)}</div>
                  <div className='adminViewValue'>{this.props.eventObj.name}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_EVENTS_EVENT_TIME)}</div>
                  <div className='adminViewValue'>{utcToZonedTime(this.props.eventObj.startTime, getStoreTimeZone()).toLocaleString(this.getLanguage(), { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_EVENTS_REPEAT_FREQUENCY)}</div>
                  <div className='adminViewValue'>{this.props.eventObj.schedule ? this.props.eventObj.schedule.getRepeatFrequencyCopy(t) : ''}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_EVENTS_REGISTRATION_FEE)}</div>
                  <div className='adminViewValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.eventObj.cost, { addTags: true, language: this.getLanguage() }) }} />
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_EVENTS_REGISTRATION_LIMIT)}</div>
                  <div className={this.props.eventObj.capacity ? 'adminViewValue' : 'adminViewValue nullValue'}>{this.props.eventObj.capacity ? parseInt(this.props.eventObj.capacity).toLocaleString(this.getLanguage()) : t(tx.TX_NONE)}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_PRODUCT_LINE)}</div>
                  <div className='adminViewValue'>{this.props.eventObj.productLine ? this.props.eventObj.productLine.name : ''}</div>
                </div>
                {this.props.eventObj.hasFormat() ?
                  <div className='adminViewItem'>
                    <div className='adminViewLabel'>{t(tx.TX_EVENTS_FORMAT)}</div>
                    <div className='adminViewValue'>{this.props.eventObj.format ? t(this.props.eventObj.format.name) : ''}</div>
                  </div> :
                  null
                }
                <div className={'adminViewItem adminDescriptionRow'}>
                  <div className='adminViewLabel'>{t(tx.TX_DESCRIPTION)}</div>
                  <div className={this.props.eventObj.description ? 'adminViewValue' : 'adminViewValue nullValue'}>{this.props.eventObj.description ? this.props.eventObj.description : t(tx.TX_NONE)}</div>
                </div>
              </div>
              <div className='adminViewButtonWrapper'>
                <button type='button' className={this.props.eventObj.hasPassed() ? 'shippingMethodDetailsButton adminButton adminButtonDisabled' : 'shippingMethodDetailsButton adminButton'} onClick={this.tryToToggle.bind(this)}>
                  {t(tx.TX_EDIT)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.eventPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventScheduleDetailsView));