

import * as gl from '../constants/gallery';

import { FV_KEY_SORT } from '../constants/filters';
import { TX_null } from '../constants/strings';



export function getGalleryDefaultSortKey(configObj, filters = {}) {
	if(Object.keys(filters).includes(FV_KEY_SORT)) {
		return filters[FV_KEY_SORT][0] === '-' ? filters[FV_KEY_SORT].substring(1) : filters[FV_KEY_SORT];
	}
	return configObj[gl.GAL_HEADER_CONFIGS][gl.GAL_HEADER_SORT_KEY];
}

export function getGalleryDefaultSortDir(configObj, filters = {}) {
	if(Object.keys(filters).includes(FV_KEY_SORT)) {
		return filters[FV_KEY_SORT][0] === '-' ? gl.GV_HEADER_SORT_DIR_DESC : gl.GV_HEADER_SORT_DIR_ASC;
	}
	return configObj[gl.GAL_HEADER_CONFIGS][gl.GAL_HEADER_SORT_DIR];
}

export function getGalleryDisplayType(configObj) {
	return configObj[gl.GAL_DISPLAY_TYPE];
}

export function getGalleryWidthClass(galleryWidth) {
	if(galleryWidth <= gl.GD_BREAK_SMALL) {
		return gl.GD_BREAK_SMALL_CLASS;
	}
	return '';
}

export function getGallerySortOptions(configObj) {

	if(configObj[gl.GAL_HEADER_CONFIGS][gl.GAL_HEADER_SORT_PLACEHOLDER]) {
		return [
			{
				[gl.GAL_HEADER_SORT_OPTION_KEY]: '',
				[gl.GAL_HEADER_SORT_OPTION_DISPLAY]: getGallerySortPlaceholder(configObj),
			},
		].concat(configObj[gl.GAL_HEADER_CONFIGS][gl.GAL_HEADER_SORT_OPTIONS]);
	}

	return configObj[gl.GAL_HEADER_CONFIGS][gl.GAL_HEADER_SORT_OPTIONS];
}

export function getGallerySortValue(sortObj) {
	return sortObj[gl.GAL_HEADER_SORT_OPTION_KEY];
}

export function getGallerySortDisplay(sortObj) {
	return sortObj[gl.GAL_HEADER_SORT_OPTION_DISPLAY];
}

export function getGallerySortPlaceholder(configObj) {
	return configObj[gl.GAL_HEADER_CONFIGS][gl.GAL_HEADER_SORT_PLACEHOLDER] ? configObj[gl.GAL_HEADER_CONFIGS][gl.GAL_HEADER_SORT_PLACEHOLDER] : TX_null;
}

export function getGalleryDefaultPageSize(configObj) {
	return configObj[gl.GAL_PAGINATION_CONFIGS][gl.GAL_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE];
}

export function getGalleryPageSizeOptions(configObj) {
	return configObj[gl.GAL_PAGINATION_CONFIGS][gl.GAL_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS];
}

export function getGalleryElementMinWidth(configObj) {
	return configObj[gl.GAL_ELEMENT_CONFIGS][gl.GAL_ELEMENT_CONFIG_MIN_WIDTH];
}

export function getGalleryElementMaxWidth(configObj) {
	return configObj[gl.GAL_ELEMENT_CONFIGS][gl.GAL_ELEMENT_CONFIG_MAX_WIDTH];
}

export function getGalleryElementMinAcross(configObj) {
	return configObj[gl.GAL_ELEMENT_CONFIGS][gl.GAL_ELEMENT_CONFIG_MIN_ACROSS];
}

export function getGalleryLoadOffset(configObj) {
	return configObj[gl.GAL_LOAD_OFFSET] ? configObj[gl.GAL_LOAD_OFFSET] : null;
}

export function getGallerySelectLabel(configObj) {
	return configObj[gl.GAL_ELEMENT_CONFIGS][gl.GAL_ELEMENT_CONFIG_ACTION_LABEL];
}

export function getGalleryActionType(configObj) {
	return configObj[gl.GAL_ELEMENT_CONFIGS][gl.GAL_ELEMENT_CONFIG_ACTION_LABEL];
}

export function getGalleryFilterStorageKey(configObj) {
	return configObj[gl.GAL_FILTER_KEY] || null;
}

export function getGalleryDefaultSearchKey(configObj) {

	const sortKey = getGalleryDefaultSortKey(configObj);
  const sortDir = getGalleryDefaultSortDir(configObj);

	if(sortDir === gl.GV_HEADER_SORT_DIR_ASC) {
    return sortKey;
  } else {
    return `-${sortKey}`;
  }
}

export function getGalleryStoreThemeClass(configObj) {
	return configObj[gl.GAL_STORE_THEME] ? configObj[gl.GAL_STORE_THEME] : '';
}

export function getGalleryComponentThemeClass(configObj) {
	return configObj[gl.GAL_COMPONENT_THEME] ? configObj[gl.GAL_COMPONENT_THEME] : '';
}

export function galleryIsInfiniteScroll(configObj) {
	return configObj[gl.GAL_PAGINATION_CONFIGS][gl.GAL_PAGINATION_CONFIG_TYPE] || false;
}











