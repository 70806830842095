import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { serverDateFull, serverTimeFull } from '../../../utils/formatting';

import * as tx from '../../../constants/strings';

import { formatPrice } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class CustomerDetailsView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getFirstName() {
    return this.props.customer ? this.props.customer.firstName : '';
  }

  getLastName() {
    return this.props.customer ? this.props.customer.lastName : '';
  }

  getEmail() {
    return this.props.customer ? this.props.customer.email : '';
  }

  getLastLogin() {
    return this.props.customer ? this.props.customer.lastLogin : '';
  }

  getRegisterDate() {
    return this.props.customer ? this.props.customer.registeredDate : '';
  }

  render() {

    const {t} = this.props;

    return <div className={'CustomerDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_CUSTOMER_CUSTOMER_DETAILS)}</div>
      </div>
      {this.props.customer && this.props.customer.isActive === false ?
        <div className='detailsAccountLocked'>{t(tx.TX_CUSTOMER_ACCOUNT_LOCKED)}</div> : 
        null
      }
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_FIRST_NAME)}</div>
                  <div className='adminViewValue'>{this.getFirstName()}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_LAST_NAME)}</div>
                  <div className='adminViewValue'>{this.getLastName()}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
                  <div className='adminViewValue'>{this.getEmail()}</div>
                </div>
              </div>
              <div className='adminViewButtonWrapper'>
                <div className={'customerDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div>
              <div className='adminViewVr'></div>
            </div>

            <div className={'detailBlock adminViewMeta'}>
              <div className='detailBlockWrapper'>
                <div className={'adminViewItem tallItem'}>
                  <div className='adminViewLabel'>{t(tx.TX_LAST_LOGIN)}</div>
                  <div className='adminViewValue'>
                    <div className='adminViewValueDate'>{serverDateFull(this.getLastLogin(), this.getLanguage())}</div>
                    <div className='adminViewValueTime'>{serverTimeFull(this.getLastLogin(), this.getLanguage())}</div>
                  </div>
                </div>
                <div className={'adminViewItem tallItem'}>
                  <div className='adminViewLabel'>{t(tx.TX_REGISTRATION_DATE)}</div>
                  <div className='adminViewValue'>
                    <div className='adminViewValueDate'>{serverDateFull(this.getRegisterDate(), this.getLanguage())}</div>
                    <div className='adminViewValueTime'>{serverTimeFull(this.getRegisterDate(), this.getLanguage())}</div>
                  </div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_MENU_ITEM_STORECREDIT)}</div>
                  <div className='adminViewValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.customer ? this.props.customer.storeCredit.balance : 0, { addTags: true, language: this.getLanguage() }) }} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.customerPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerDetailsView));