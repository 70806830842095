import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../../constants/urls';

import { getStoreLanguage } from '../../../utils/language';

import BulkUploadResultList from './BulkUploadResultList';

const allActionCreators = Object.assign({});

export class BulkUploadResults extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkUploadResults'}>
      <div className='burLiner'>
        <div className='burSummaryWrapper'>
          {this.props.requestPending ?
            <div className='burSummaryLoading'>
              <div className='burSummaryLoadingValue'>{t(tx.TX_LOADING)}</div>
            </div> :
            <div className='burSummary'>
              <div className='summaryItem'>
                <div className='summaryItemValue'>{this.props.requestUpload ? this.props.requestUpload.countSuccess.toLocaleString(this.getLanguage()) : 0}</div>
                <div className='summaryItemLabel'>{t(tx.TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SUCCESS)}</div>
              </div>
              <div className='summaryItem'>
                <div className='summaryItemValue'>{this.props.requestUpload ? this.props.requestUpload.countError.toLocaleString(this.getLanguage()) : 0}</div>
                <div className='summaryItemLabel'>{t(tx.TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_ERROR)}</div>
              </div>
              <div className='summaryItem'>
                <div className='summaryItemValue'>{this.props.requestUpload ? this.props.requestUpload.countSkipped.toLocaleString(this.getLanguage()) : 0}</div>
                <div className='summaryItemLabel'>{t(tx.TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SKIPPED)}</div>
              </div>
              <div className='summaryItem'>
                <div className='summaryItemValue'>{this.props.requestUpload ? this.props.requestUpload.records.toLocaleString(this.getLanguage()) : 0}</div>
                <div className='summaryItemLabel'>{t(tx.TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_TOTAL)}</div>
              </div>
            </div>
          }
        </div>
        <div className={this.props.requestUpload && this.props.requestUpload.isComplete() ? 'burSuccessWrapper open' : 'burSuccessWrapper'}>
          <div className='burSuccess'>
            <div className='burSuccessTitle'>{t(tx.TX_INV_BULK_UPLOAD_STATUS_COMPLETE)}</div>
            <div className='burSuccessActions'>
              <Link className='burSuccessLink' to={URL_ADMIN_INVENTORY}>{t(tx.TX_INV_BULK_UPLOAD_BACK_TO_PRODUCTS)}</Link>
            </div>
          </div>
        </div>
        <div className='burDetailWrapper'>
          <BulkUploadResultList
            callApi={this.props.callApi.bind(this)}
            processing={this.props.requestUpload && !this.props.requestUpload.isComplete() && this.props.requestUpload.isFresh()}
            requestUpload={this.props.requestUpload}
            requestPending={this.props.requestPending}
            results={this.props.results}
            count={this.props.count}
            filters={this.props.filters}
            pageNum={this.props.pageNum}
            pageSize={this.props.pageSize}
            pageSort={this.props.pageSort}
            debugMode={this.props.debugMode} />
        </div>
      </div>   
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkUploadResults));