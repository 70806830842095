import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { CAL_DAY_NAMES } from '../../constants/calendar';
import { CSS_SLIDE_DURATION } from '../../constants/css';
import { 
  ERROR_EVENT_FORMAT_MISSING,
  ERROR_EVENT_REPEAT_FREQUENCY, 
  ERROR_PRODUCT_LINE_PLEASE_SELECT,
} from '../../constants/errors';
import {
  EVENT_FREQUENCY_DAILY, 
  EVENT_FREQUENCY_WEEKLY, 
  EVENT_FREQUENCY_MONTHLY_WEEK, 
  EVENT_MONTHLY_WEEK_OPTIONS, 
} from '../../constants/events';
import { ICON_CALENDAR } from '../../constants/icons';
import * as tx from '../../constants/strings';
import { URL_ADMIN_EVENTS } from '../../constants/urls';

import { Month } from '../../models/calendar';
import { 
  StoreEvent,
  StoreEventSchedule, 
} from '../../models/events';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../utils/currency';
import { 
  isFormValid, 
  getCostError,
  getDescriptionError,
  getEventRegistrationLimitError, 
  getFutureDateError, 
  getNameError,
  getNotEmptyError, 
  getQuantityError,
  getTimeError,
} from '../../utils/form-validation';
import { 
  formatServerError,
  normalizePrice,
} from '../../utils/formatting';
import { 
  dateDelta,
  is24HourTime, 
} from '../../utils/general';
import { 
  getLanguageObjectFromCode,
  getStoreLanguage, 
} from '../../utils/language';
import { 
  getOrderedPlayMenu,
  getOrderedProductLines, 
} from '../../utils/product';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import AdminTitle from '../Admin/AdminTitle';
import Dropdown from '../Input/Dropdown';
import { Icon } from '../Icons/Icon';
import { LoadingIcon } from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';
import Toggle from '../Input/Toggle';

import * as eventActionCreators from '../../actions/event';
import * as productLineActionCreators from '../../actions/product-line';
const allActionCreators = Object.assign({}, eventActionCreators, productLineActionCreators);

export class CreateEvent extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,
      savePromptOpen: false,

      inputName: '',
      inputDate: '',
      inputTimeHour: '',
      inputTimeMinutes: '',
      inputTimePost: 'pm',
      inputRepeat: false,
      inputRepeatFrequency: '', 
      inputEndDate: '',
      inputDescription: '', 
      inputProductLine: '', 
      inputFormat: '', 
      inputPremium: '', 
      inputCost: '', 
      inputLimit: '',

      errorName: '',
      errorDate: '',
      errorTime: '',
      errorRepeatFrequency: '', 
      errorDescription: '', 
      errorProductLine: '', 
      errorFormat: '', 
      errorCost: '',
      errorLimit: '',

      is24HourTime: is24HourTime(this.getLanguage()),

      lastBlock: '',
      saveRedirect: false,

      requestPending: false,
      requestError: null,

      repeatSectionOpen: false,
    };

    this.descriptionLimit = 8192;

    this.repeatTimeout = null;

    this.hourInput = React.createRef();
  }

  componentDidMount() {
    
    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });

    // Fetch product line data
    if(this.props.productLine && this.props.productLine.productLines === null) {
      
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const check24HourTime = is24HourTime(this.getLanguage());
    if(this.state.is24HourTime !== check24HourTime) {

      const timeChangeState = {
        is24HourTime: check24HourTime,
      }

      if(check24HourTime) {
        timeChangeState['inputTimeHour'] = this.getDateHour();
      } else {
        if(parseInt(this.state.inputTimeHour) === 0) {
          timeChangeState['inputTimeHour'] = 12;
          timeChangeState['inputTimePost'] = 'am';
        } else if(parseInt(this.state.inputTimeHour) > 12) {
          timeChangeState['inputTimeHour'] = parseInt(this.state.inputTimeHour) - 12;
          timeChangeState['inputTimePost'] = 'pm';
        } else if(this.state.inputTimeHour && parseInt(this.state.inputTimeHour) === 12) {
          timeChangeState['inputTimePost'] = 'pm';
        } else if(this.state.inputTimeHour && parseInt(this.state.inputTimeHour) < 12) {
          timeChangeState['inputTimePost'] = 'am';
        }
      }

      this.setState(timeChangeState);
    }
  }

  componentWillUnmount() {
    this.unblock();
    clearTimeout(this.repeatTimeout);
  }

  checkTimeClick(evt) {
      
    let targetElement = evt.target;
    do {
      if(targetElement.tagName === 'INPUT' || targetElement.tagName === 'SELECT') {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    if(this.hourInput && this.hourInput.current) {
      this.hourInput.current.focus()
    }
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveAction();
    });
  }

  getDateHour() {
    if(this.state.inputTimePost === 'pm' && parseInt(this.state.inputTimeHour) < 12) {
      return parseInt(this.state.inputTimeHour) + 12;
    } else if(this.state.inputTimePost === 'am' && parseInt(this.state.inputTimeHour) === 12) {
      return 0;
    } else {
      return parseInt(this.state.inputTimeHour);
    }
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const timeHour = this.state.is24HourTime ? this.state.inputTimeHour : this.getDateHour();
      const selectedTime = new Date(this.state.inputDate.getFullYear(), this.state.inputDate.getMonth(), this.state.inputDate.getDate(), timeHour, parseInt(this.state.inputTimeMinutes));

      let newSchedule = null;
      if(this.state.inputRepeat) {
        newSchedule = new StoreEventSchedule({
          selectedTime: selectedTime,
          name: this.state.inputName,
          description: this.state.inputDescription,
          productLine: this.state.inputProductLine,
          capacity: this.state.inputLimit,
          cost: this.state.inputCost,
          format: this.state.inputFormat,
          endDate: this.state.inputEndDate,
          scheduleType: this.state.inputRepeatFrequency,
        });
      }

      const newEvent = new StoreEvent({
        selectedTime: selectedTime,
        name: this.state.inputName,
        description: this.state.inputDescription,
        productLine: this.state.inputProductLine,
        capacity: this.state.inputLimit,
        cost: this.state.inputCost,
        format: this.state.inputFormat,
        isPremium: this.state.inputPremium,
        schedule: newSchedule,        
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const createResp = await this.props.eventCreate(newEvent.getApiData())
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!createResp) {
        return null;
      }

      this.setState({ isDirty: false }, () => {
        if(!this.state.saveRedirect) {
          history.push(URL_ADMIN_EVENTS);
        } else {
          history.push(this.state.lastBlock);
        }
      });
    }
  }

  validateAll() {
    const errorObj = {
      errorName: getNameError(this.state.inputName),
      errorDate: getFutureDateError(this.state.inputDate),
      errorTime: getTimeError(this.state.is24HourTime ? this.state.inputTimeHour : this.getDateHour(), this.state.inputTimeMinutes),
      errorRepeatFrequency: this.state.inputRepeat ? getNotEmptyError(this.state.inputRepeatFrequency, ERROR_EVENT_REPEAT_FREQUENCY) : '',
      errorEndDate: getFutureDateError(this.state.inputEndDate, true),
      errorDescription: getDescriptionError(this.state.inputDescription, true), 
      errorProductLine: getNotEmptyError(this.state.inputProductLine, ERROR_PRODUCT_LINE_PLEASE_SELECT), 
      errorFormat: this.hasFormat() ? getNotEmptyError(this.state.inputFormat, ERROR_EVENT_FORMAT_MISSING) : '', 
      errorCost: getCostError(this.state.inputCost, true),
      errorLimit: getEventRegistrationLimitError(this.state.inputLimit, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getLocale() {
    const lang = getLanguageObjectFromCode(this.getLanguage());

    if(!lang) { return ''; }
    return lang.locale || '';
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorName) {
        this.validateName();
      }
    });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  changeDate(selectedDate) {
    this.setState({
      inputDate: selectedDate,
      isDirty: true,
    }, () => {
      if(this.state.errorDate) {
        this.validateDate();
      }
    });
  }

  validateDate() {
    this.setState({ errorDate: getFutureDateError(this.state.inputDate) });
  }

  changeTimeHour(evt) {
    this.setState({
      inputTimeHour: evt.target.value,
      isDirty: true,
    });
  }

  validateTimeHour() {
    
    const hrValue = parseInt(this.state.inputTimeHour);

    const minValue = this.state.is24HourTime ? 0 : 1;
    const maxValue = this.state.is24HourTime ? 23 : 12;
    
    if(!hrValue && hrValue !== 0) {
      this.setState({ inputTimeHour: '' });
    } else if(hrValue < minValue) {
      this.setState({ inputTimeHour: minValue });
    } else if(hrValue > maxValue) {
      this.setState({ inputTimeHour: maxValue });
    }
  }

  changeTimeMinutes(evt) {
    this.setState({
      inputTimeMinutes: evt.target.value,
      isDirty: true,
    });
  }

  validateTimeMinutes() {

    const minutes = parseInt(this.state.inputTimeMinutes);

    const minValue = 0;
    const maxValue = 59;
    
    if(!minutes && minutes !== 0) {
      this.setState({ inputTimeMinutes: '' });
    } else if(minutes < minValue) {
      this.setState({ inputTimeMinutes: minValue });
    } else if(minutes > maxValue) {
      this.setState({ inputTimeMinutes: maxValue });
    }
  }

  changeTimePost(evt) {
    this.setState({
      inputTimePost: evt.target.value,
    });
  }

  getTimePostOptions() {
    return [
      {
        display: 'am',
        value: 'am',
      },
      {
        display: 'pm',
        value: 'pm',
      },
    ];
  }

  changeRepeat() {
    this.setState({
      inputRepeat: !this.state.inputRepeat,
      isDirty: true,
      repeatSectionOpen: false,
    }, () => {
      if(this.state.inputRepeat) {
        this.repeatTimeout = setTimeout(() => {
          this.setState({ repeatSectionOpen: true });
        }, CSS_SLIDE_DURATION);
      } else {
        this.setState({
          inputRepeatFrequency: '',
          errorRepeatFrequency: '',
        });
      }
    });
  }

  changeRepeatFrequency(evt) {
    this.setState({
      inputRepeatFrequency: evt.target.value,
      isDirty: true,
    });
  }

  changeEndDate(selectedDate) {
    this.setState({
      inputEndDate: selectedDate,
      isDirty: true,
    }, () => {
      if(this.state.errorEndDate) {
        this.validateEndDate();
      }
    });
  }

  validateEndDate() {
    this.setState({ errorEndDate: getFutureDateError(this.state.inputEndDate, true) });
  }

  changeDescription(evt) {
    this.setState({
      inputDescription: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorDescription) {
        this.validateDescription();
      }
    });
  }

  validateDescription() {
    this.setState({ errorDescription: getDescriptionError(this.state.inputDescription, true) });
  }

  getDescritpionLength() {
    return this.state.inputDescription ? this.state.inputDescription.length : 0;
  }

  changeProductLine(evt) {

    if(!this.props.productLine.productLines) {
      return;
    }

    if(evt.target.value === '') {
      this.setState({ inputProductLine: evt.target.value });
      return;
    }

    for(const pl of this.props.productLine.productLines) {
      if(pl.id === parseInt(evt.target.value)) {
        this.setState({ inputProductLine: pl });
        break;
      }
    }
  }

  getProductLineOptions() {

    const options = [];

    if(getOrderedPlayMenu(this.props.productLine.enabled).length === 0) {
      options.push({
        display: this.props.t(tx.TX_INV_ADD_NO_PL),
        value: '',
      });
    } else {
      options.push({
        display: this.props.t(tx.TX_PLACEHOLDER_PL_DROPDOWN),
        value: '',
      });
    }

    for(const pl of getOrderedPlayMenu(this.props.productLine.enabled)) {
      options.push({
        display: pl.name,
        value: pl.id,
      });
    }

    return options;
  }

  changeFormat(evt) {
    this.setState({
      inputFormat: evt.target.value,
      isDirty: true,
    });
  }

  getFormatOptions() {

    const options = [
      {
        display: tx.TX_PLACEHOLDER_FORMAT,
        value: '',
      }
    ];

    if(this.state.inputProductLine) {
      for(const format of this.state.inputProductLine.eventFormats) {
        options.push({
          display: format.name,
          value: format.key,
        });
      }
    }

    return options;
  }

  changeCost(evt) {
    this.setState({
      inputCost: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorCost) {
        this.validateCost();
      }
    });
  }

  validateCost() {
    this.setState({ 
      inputCost: normalizePrice(this.state.inputCost), 
      errorCost: getCostError(this.state.inputCost, true), 
    });
  }

  changeLimit(evt) {
    this.setState({
      inputLimit: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorLimit) {
        this.validateLimit();
      }
    });
  }

  validateLimit() {
    this.setState({ errorLimit: getQuantityError(this.state.inputLimit, true) });
  }

  hasFormat() {
    if(this.state.inputProductLine && this.state.inputProductLine.eventFormats && this.state.inputProductLine.eventFormats.length) {
      return true;
    }
    return false;
  }

  changePremium(evt) {
    this.setState({
      inputPremium: !this.state.inputPremium,
      isDirty: true,
    });
  }

  getRepeatOptions() {
    
    const t =  this.props.t;

    const repeatOptions = [
      {
        display: t(tx.TX_PLACEHOLDER_EVENT_REPEAT_FREQUENCY),
        value: '',
      },
      {
        display: t(tx.TX_CAL_FREQUENCY_DAILY),
        value: EVENT_FREQUENCY_DAILY,
      },
      {
        display: t(tx.TX_CAL_FREQUENCY_WEEKLY),
        value: EVENT_FREQUENCY_WEEKLY,
      },
    ];

    if(this.state.inputDate) {
      const occurance = Month.nthDay(this.state.inputDate);
      if(occurance) {
        repeatOptions.push({
          display: t(EVENT_MONTHLY_WEEK_OPTIONS[occurance], { day: t(CAL_DAY_NAMES[this.state.inputDate.getDay()]) }),
          value: EVENT_FREQUENCY_MONTHLY_WEEK,
        })
      }
    }

    return repeatOptions;
  }

  render() {

    const {t} = this.props;

    return <div className={'CreateEvent AdminPage'}>
      <AdminTitle
        title={tx.TX_EVENTS_CREATE_EVENT}
        breadcrumbs={[
          {
            url: URL_ADMIN_EVENTS,
            title: tx.TX_MENU_ITEM_MANAGEEVENTS,
          },
        ]} />

      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className='createEventForm'
            onSubmit={this.saveAction.bind(this)}
            noValidate={true}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_EVENTS_CREATE_EVENT)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_EVENTS_EVENT_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.state.inputName}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EVENT_NAME)}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_DATE)}</div>
                <div className='adminInputDate'>
                  <div className={this.state.errorDate ? 'datePickerWrapper error' : 'datePickerWrapper'}>
                    <DatePicker 
                      selected={this.state.inputDate} 
                      onChange={(date) => this.changeDate(date)}
                      onBlur={this.validateDate.bind(this)}
                      locale={this.getLocale()}
                      closeOnScroll={true}
                      dateFormat='P'
                      placeholderText={t(tx.TX_PLACEHOLDER_DATE_SELECT)}
                      excludeDateIntervals={[{
                        start: new Date(0), 
                        end: dateDelta(new Date(), -1), 
                      }]} />
                  </div>
                  <div className='calendarOverlay'>
                    <Icon value={ICON_CALENDAR} />
                  </div>
                  {this.state.errorDate ?
                    <div className={'adminError FieldError'}>{t(this.state.errorDate)}</div> :
                    null
                  }
                </div>
              </div>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_TIME)}</div>
                <div className={this.state.errorTime ? 'adminInputTime fieldError' : 'adminInputTime'} onClick={this.checkTimeClick.bind(this)}>
                  <div className='aitLiner'>
                    <div className={'aitHourWrapper aitElement'}>
                      <input
                        type='number'
                        ref={this.hourInput}
                        className={this.state.errorTimeHour ? 'InputError' : ''}
                        value={this.state.inputTimeHour}
                        onChange={this.changeTimeHour.bind(this)}
                        onBlur={this.validateTimeHour.bind(this)}
                        placeholder={t(this.state.is24HourTime ? tx.TX_PLACEHOLDER_TIME_HOUR_24 : tx.TX_PLACEHOLDER_TIME_HOUR)}
                        min={this.state.is24HourTime ? 0 : 1}
                        max={this.state.is24HourTime ? 23 : 12} />
                    </div>
                    <div className={'aitDelimiterWrapper aitElement'}>
                      <div className={'delimWrapper FlexCenter'}>:</div>
                    </div>
                    <div className={'aitMinutesWrapper aitElement'}>
                      <input
                        type='number'
                        className={this.state.errorTimeMinutes ? 'InputError' : ''}
                        value={this.state.inputTimeMinutes}
                        onChange={this.changeTimeMinutes.bind(this)}
                        onBlur={this.validateTimeMinutes.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_TIME_MINUTES)}
                        min={0}
                        max={59} />
                    </div>
                    {!this.state.is24HourTime ?
                      <div className={'aitPostScriptWrapper aitElement'}>
                        <Dropdown 
                          className={'aitPostSelect'}
                          options={this.getTimePostOptions()}
                          name={t(tx.TX_TIME)}
                          value={this.state.inputTimePost}
                          noTranslate={true}
                          onChange={this.changeTimePost.bind(this)} />
                      </div> :
                      null
                    }
                  </div>
                </div>
                {this.state.errorTime ?
                  <div className={'adminError FieldError'}>{t(this.state.errorTime)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_EVENTS_REPEAT_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputRepeat}
                    disabled={!this.state.inputDate}
                    onToggle={this.changeRepeat.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
            </div>


            <div className={`repeatSection${this.state.inputRepeat ? ' expanded' : ''}${this.state.repeatSectionOpen ? ' still' : ''}`}>
              <div className='adminFieldWrapper'>
                <div className='adminInputWrapper'>
                  <div className={'adminFieldLabel'}>{t(tx.TX_EVENTS_REPEAT_FREQUENCY)}</div>
                  <div className='adminDropdownWrapper'>
                    <Dropdown 
                      className={'adminDropdownSelect'}
                      options={this.getRepeatOptions()}
                      name={t(tx.TX_EVENTS_REPEAT_FREQUENCY)}
                      error={this.state.errorRepeatFrequency}
                      value={this.state.inputRepeatFrequency}
                      onChange={this.changeRepeatFrequency.bind(this)}
                      required={true}
                      noTranslate={true} />
                  </div>
                  {this.state.errorRepeatFrequency ?
                    <div className={'adminError FieldError'}>{t(this.state.errorRepeatFrequency)}</div> :
                    null
                  }
                </div>
              </div>

              <div className='adminFieldWrapper'>
                <div className={'adminInputWrapper'}>
                  <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_EVENTS_END_DATE)}</div>
                  <div className='adminInputDate'>
                    <div className={this.state.errorEndDate ? 'datePickerWrapper error' : 'datePickerWrapper'}>
                      <DatePicker 
                        selected={this.state.inputEndDate} 
                        onChange={(date) => this.changeEndDate(date)}
                        onBlur={this.validateEndDate.bind(this)}
                        locale={this.getLocale()}
                        closeOnScroll={true}
                        dateFormat='P'
                        placeholderText={t(tx.TX_PLACEHOLDER_DATE_SELECT)}
                        excludeDateIntervals={[{
                          start: new Date(0), 
                          end: dateDelta(new Date(), -1), 
                        }]}
                        popperModifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [25, 0],
                            },
                          },
                        ]} />
                    </div>
                    <div className='calendarOverlay'>
                      <Icon value={ICON_CALENDAR} />
                    </div>
                    {this.state.errorEndDate ?
                      <div className={'adminError FieldError'}>{t(this.state.errorEndDate)}</div> :
                      null
                    }
                  </div>
                </div>
              </div>

            </div>

            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_DESCRIPTION)}</div>
              <div className='adminInputWrapper'>
                <textarea
                  className={this.state.errorDescription ? 'InputError' : ''}
                  value={this.state.inputDescription}
                  onChange={this.changeDescription.bind(this)}
                  onBlur={this.validateDescription.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EVENT_DESCRIPTION)}
                  maxLength={this.descriptionLimit} />
                {this.state.errorDescription ?
                  <div className={'FieldError'}>{t(this.state.errorDescription)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  <div className='productCharacterCount'>
                    <span className='countNum'>{(this.descriptionLimit - this.getDescritpionLength()).toLocaleString(this.getLanguage())}</span>
                    <span className='countSlash'>/</span>
                    <span className='countNum'>{this.descriptionLimit.toLocaleString(this.getLanguage())}</span>
                    <span className='countCopy'>{t(tx.TX_CHARACTERS_REMAINING)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className='adminInputWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRODUCT_LINE)}</div>
                <div className='adminInputProductLineWrapper'>
                  {this.props.productLine.productLinesPending ?
                    <div className='aiProductLineLoading'>
                      <div className='aiProductLineLoadingIcon'>
                        <LoadingIcon />
                      </div>
                      <div className='aiProductLoadingLabel'>{t(tx.TX_LOADING)}</div>
                    </div> :
                    <div> 
                      <div className='adminDropdownWrapper'>
                        <Dropdown 
                          className={'adminDropdownSelect'}
                          options={this.getProductLineOptions()}
                          name={t(tx.TX_PRODUCT_LINE)}
                          error={this.state.errorProductLine}
                          value={this.state.inputProductLine ? this.state.inputProductLine.id : ''}
                          onChange={this.changeProductLine.bind(this)}
                          required={true}
                          noTranslate={true}
                          disabled={getOrderedProductLines(this.props.productLine.productLines).length === 0} />
                      </div>
                      {this.state.errorProductLine ?
                        <div className={'adminError FieldError'}>{t(this.state.errorProductLine)}</div> :
                        null
                      }
                    </div>
                  }
                </div>
              </div>
            </div>

            {this.hasFormat() ?
              <div className='adminFieldWrapper'>
                <div className={`adminInputWrapper${this.state.inputRepeat ? '' : ' halfWidth'}`}>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_EVENTS_FORMAT)}</div>
                  <div className='adminDropdownWrapper'>
                    <Dropdown 
                      className={'adminDropdownSelect'}
                      options={this.getFormatOptions()}
                      name={t(tx.TX_EVENTS_FORMAT)}
                      value={this.state.inputFormat}
                      onChange={this.changeFormat.bind(this)}
                      required={true} />
                  </div>
                  {this.state.errorFormat ?
                    <div className={'adminError FieldError'}>{t(this.state.errorFormat)}</div> :
                    null
                  }
                </div>
                {!this.state.inputRepeat ?
                  <div className={'adminInputWrapper halfWidth'}>
                    <div className='adminInputToggleWrapper'>
                      <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_EVENTS_PREMIUM_QUESTION)}</div>
                      <div className='adminToggleWrapper'>
                        <Toggle
                          checked={this.state.inputPremium}
                          onToggle={this.changePremium.bind(this)}
                          trueValue={tx.TX_YES}
                          falseValue={tx.TX_NO} />
                      </div>
                    </div>
                  </div> :
                  null
                }
              </div> :
              null
            }

            <div className='adminFieldWrapper'>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_EVENTS_REGISTRATION_FEE)}</div>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    step={getCurrencyIncrement()}
                    className={this.state.errorCost ? 'currencyInput InputError' : 'currencyInput'}
                    value={this.state.inputCost}
                    onChange={this.changeCost.bind(this)}
                    onBlur={this.validateCost.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorCost ?
                  <div className={'adminError FieldError'}>{t(this.state.errorCost)}</div> :
                  null
                }
              </div>

              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_EVENTS_REGISTRATION_LIMIT)}</div>
                <input
                  type='text'
                  className={this.state.errorLimit ? 'registrationInput InputError' : 'registrationInput'}
                  value={this.state.inputLimit}
                  onChange={this.changeLimit.bind(this)}
                  onBlur={this.validateLimit.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_QUANTITY)}
                  maxLength={100} />
                {this.state.errorLimit ?
                  <div className={'adminError FieldError'}>{t(this.state.errorLimit)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_EVENTS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}


function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CreateEvent));