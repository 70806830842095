

import { 
	TX_CUSTOMER_BULK_UPLOAD_CREATED,
	TX_ERROR,
	TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SKIPPED,
	TX_SUCCESS,
	TX_null,
} from './strings';


export const CUSTOMERS_BULK_UPLOAD_STATUS_SUCCESS_KEY = 'success';
export const CUSTOMERS_BULK_UPLOAD_STATUS_ERROR_KEY = 'error';
export const CUSTOMERS_BULK_UPLOAD_STATUS_SKIPPED_KEY = 'skipped';
export const CUSTOMERS_BULK_UPLOAD_STATUS_CREATED_KEY = 'created';

export const CUSTOMERS_BULK_UPLOAD_STATUS_SUCCESS = {
	key: CUSTOMERS_BULK_UPLOAD_STATUS_SUCCESS_KEY,
	name: TX_SUCCESS,
}
export const CUSTOMERS_BULK_UPLOAD_STATUS_ERROR = {
	key: CUSTOMERS_BULK_UPLOAD_STATUS_ERROR_KEY,
	name: TX_ERROR,
}
export const CUSTOMERS_BULK_UPLOAD_STATUS_CREATED = {
	key: CUSTOMERS_BULK_UPLOAD_STATUS_CREATED_KEY,
	name: TX_CUSTOMER_BULK_UPLOAD_CREATED,
}
export const CUSTOMERS_BULK_UPLOAD_STATUS_SKIPPED = {
	key: CUSTOMERS_BULK_UPLOAD_STATUS_SKIPPED_KEY,
	name: TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SKIPPED,
}

export const CUSTOMERS_BULK_UPLOAD_STATUSES = [
	CUSTOMERS_BULK_UPLOAD_STATUS_SUCCESS,
	CUSTOMERS_BULK_UPLOAD_STATUS_ERROR,
	CUSTOMERS_BULK_UPLOAD_STATUS_SKIPPED,
	CUSTOMERS_BULK_UPLOAD_STATUS_CREATED,
];

export const CUSTOMERS_BULK_UPLOAD_RESULT_PAGE_SIZE_DEFAULT = 50;
export const CUSTOMERS_BULK_UPLOAD_RESULT_PAGE_SIZE_OPTIONS = [ 25, 50, 100, 200 ];

export const CUSTOMERS_BULK_UPLOAD_FORMAT_KEY_TITAN = 'titan';

export const CUSTOMERS_BULK_UPLOAD_FORMAT_TITAN = {
	key: CUSTOMERS_BULK_UPLOAD_FORMAT_KEY_TITAN,
	name: TX_null,
	schema: [
		{
			key: 'Email Address',
			required: true,
			display: true,
			description: TX_null,
		},
		{
			key: 'First Name',
			required: false,
			display: false,
			description: TX_null,
		},
		{
			key: 'Last Name',
			required: false,
			display: false,
			description: TX_null,
		},
		{
			key: 'Add Store Credit',
			required: false,
			display: false,
			description: TX_null,
		},
		{
			key: 'Total Store Credit',
			required: false,
			display: false,
			description: TX_null,
		},
	],
};

export const CUSTOMERS_BULK_UPLOAD_FORMATS_ALL = [
	CUSTOMERS_BULK_UPLOAD_FORMAT_TITAN,
];