
import * as _ from 'underscore';
import jsPDF from 'jspdf';

import { CD_NEW } from '../constants/conditions';
import { LANG_EN } from '../constants/languages';
import {
	ORDER_EXPORT_BACKGROUND_COLOR, 
	ORDER_EXPORT_FONT_FAMILY,
	ORDER_EXPORT_FONT_SIZE, 
	ORDER_EXPORT_HEADER_COLOR,
	ORDER_EXPORT_HEADER_TEXT_COLOR, 
	ORDER_EXPORT_HEADER_FONT_SIZE, 
	ORDER_EXPORT_HEADER_HEIGHT,
	ORDER_EXPORT_HEADER_TITLE_FONT_SIZE, 
	ORDER_EXPORT_HEADER_SUBTITLE_FONT_SIZE, 
	ORDER_EXPORT_PAGE_MARGIN, 
	ORDER_EXPORT_ROW_ALLOTMENT, 
	ORDER_EXPORT_SECTION_MARGIN,
	ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY, 
	ORDER_EXPORT_SECTION_ROW_QTY_KEY,
	ORDER_EXPORT_SECTION_ROW_QTY_WIDTH,
	ORDER_EXPORT_SECTION_ROW_NUMBER_KEY,
	ORDER_EXPORT_SECTION_ROW_NUMBER_WIDTH,
	ORDER_EXPORT_SECTION_ROW_NAME_KEY,
	ORDER_EXPORT_SECTION_ROW_NAME_WIDTH,
	ORDER_EXPORT_SECTION_ROW_DETAIL_WIDTH, 
	ORDER_EXPORT_SECTION_TITLE_FONT_SIZE,
	ORDER_EXPORT_TABLE_COLUMN_COLOR_ROW_ALT,
	ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE,
	ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL, 
	ORDER_EXPORT_TEXT_COLOR, 
} from '../constants/orders';
import { STORE_LOGO_URL } from '../constants/store';
import {
	TX_CONDITION,
	TX_DETAILS,
	TX_FILTER_FINISH, 
	TX_LANGUAGE,
	TX_NAME,
	TX_NUMBER,
	TX_ORDER_PULL_SHEET,
	TX_OTHER, 
	TX_PRINTING,
	TX_SKU, 
} from '../constants/strings';

import { dynamicSort } from './sort';


function getOrderedProductLines(orders) {
	
	if(!orders) { return []; }

	const productLines = [];
	for(const order of orders) {

		if(order.isBuylist) { continue; }

		for(const ci of order.cart.items) {
			
			let foundPl = false;
			const testPl = ci.product.productLine;
			for(const pl of productLines) {
				if(pl.permalink === testPl.permalink) {
					foundPl = true;
					break;
				}
			}

			if(!foundPl) {
				productLines.push(testPl);
			}
		}
	}
	return productLines.sort(dynamicSort('name'));
}

function getOrderedSetNames(orders, productLine) {

	if(!orders || !productLine) { return []; }

	const setNames = [];
	for(const order of orders) {

		if(order.isBuylist) { continue; }

		for(const ci of order.cart.items) {
			
			if(ci.product.productLine && ci.product.productLine.permalink === productLine.permalink) {
							
				const testSetName = ci.product.setName || null;
				if(!testSetName){
					continue;
				}

				if(!setNames.includes(testSetName)) {
					setNames.push(testSetName);
				}
			}
		}
	}

	return setNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
}

function getOrderProductLineInventory(orders, productLine) {

	if(!orders) { return []; }

	let rowsArray = [];
	for(const order of orders) {

		if(order.isBuylist) { continue; }

		for(const ci of order.cart.items) {
			
			if(ci.product.productLine && ci.product.productLine.permalink === productLine.permalink) {						

				let foundInventory = false;
				rowsArray.forEach((row) => {
					if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].id === ci.inventory.id) {
						foundInventory = true;
						row[ORDER_EXPORT_SECTION_ROW_QTY_KEY] += ci.quantity;
					}
				});

				if(!foundInventory) {
					rowsArray.push({
						[ORDER_EXPORT_SECTION_ROW_QTY_KEY]: ci.quantity,
						[ORDER_EXPORT_SECTION_ROW_NUMBER_KEY]: ci.product.sku.toUpperCase(),
						[ORDER_EXPORT_SECTION_ROW_NAME_KEY]: ci.product.nameWithTags,
						[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY]: ci.inventory,
					});
				}
			}
		}
	}
	return rowsArray.sort(dynamicSort(ORDER_EXPORT_SECTION_ROW_NAME_KEY));
}

function getOrderedSetInventory(orders, productLine, setName) {

	if(!orders) { return []; }

	let rowsArray = [];
	for(const order of orders) {

		if(order.isBuylist) { continue; }

		for(const ci of order.cart.items) {
			
			if(ci.product.productLine && ci.product.productLine.permalink === productLine.permalink) {
							
				const invSetName = ci.product.setName || null;

				if(invSetName === setName) {

					let foundInventory = false;
					rowsArray.forEach((row) => {
						if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].id === ci.inventory.id) {
							foundInventory = true;
							row[ORDER_EXPORT_SECTION_ROW_QTY_KEY] += ci.quantity;
						}
					});

					if(!foundInventory) {
						rowsArray.push({
							[ORDER_EXPORT_SECTION_ROW_QTY_KEY]: ci.quantity,
							[ORDER_EXPORT_SECTION_ROW_NUMBER_KEY]: ci.product.foreignModel && ci.product.foreignModel.collectorNumber ? ci.product.foreignModel.collectorNumber : '',
							[ORDER_EXPORT_SECTION_ROW_NAME_KEY]: ci.product.nameWithTags,
							[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY]: ci.inventory,
						});
					}
				}
			}
		}
	}
	return rowsArray.sort(dynamicSort(ORDER_EXPORT_SECTION_ROW_NAME_KEY));
}

export async function generatePullSheet(t = null, orders = [], config = {}) {

	if(!t) { return null; }

	const lang = config.language || LANG_EN;

	const doc = new jsPDF('portrait', 'pt', 'letter');

  const pageWidth = doc.internal.pageSize.width;   // 612
	const pageHeight = doc.internal.pageSize.height; // 792

	const tableFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE;
	const tableSmallFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;

	const tableQtyX = ORDER_EXPORT_PAGE_MARGIN;
	const tableQtyWidth = ORDER_EXPORT_SECTION_ROW_QTY_WIDTH;

	const tableNumberX = tableQtyX + tableQtyWidth;
	const tableNumberWidth = ORDER_EXPORT_SECTION_ROW_NUMBER_WIDTH;

	const tableNameX = tableNumberX + tableNumberWidth;
	const tableNameWidth = ORDER_EXPORT_SECTION_ROW_NAME_WIDTH;

	const tableDetailsX = tableNameX + tableNameWidth;
	const tableDetailsWidth = ORDER_EXPORT_SECTION_ROW_DETAIL_WIDTH;

	const tableX = ORDER_EXPORT_PAGE_MARGIN;
	const tableWidth = pageWidth - 2*ORDER_EXPORT_PAGE_MARGIN;				

	let cursorY = ORDER_EXPORT_PAGE_MARGIN;

	const productLines = getOrderedProductLines(orders);

	// CREATE HEADER

	// Set font size and type
  doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'normal');
  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);

  const fontStyes = doc.getFontList()[ORDER_EXPORT_FONT_FAMILY] ? doc.getFontList()[ORDER_EXPORT_FONT_FAMILY] : [];

  doc.setFontSize(ORDER_EXPORT_HEADER_TITLE_FONT_SIZE);

  if(fontStyes.includes('bold')) {
  	doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'bold');
  }

  doc.text(t(TX_ORDER_PULL_SHEET), pageWidth/2, cursorY, { align: 'center', baseline: 'top' });
  const titleFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_HEADER_TITLE_FONT_SIZE;

  const now = new Date();
  const nowString = now.toLocaleString(lang, { dateStyle: 'medium', timeStyle: 'medium' });

  doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'normal');
  doc.setFontSize(ORDER_EXPORT_HEADER_SUBTITLE_FONT_SIZE);
  
  doc.text(nowString, pageWidth/2, cursorY + titleFontHeight, { align: 'center', baseline: 'top' });
  const dateFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_HEADER_SUBTITLE_FONT_SIZE;

  // Draw logo
  const logoHeight = titleFontHeight + dateFontHeight;
  const imgProps = await doc.getImageProperties(STORE_LOGO_URL);

  const logoWidth = (logoHeight * imgProps.width) / imgProps.height;
  const logoX = ORDER_EXPORT_PAGE_MARGIN;
  const logoY = ORDER_EXPORT_PAGE_MARGIN;

  doc.addImage(STORE_LOGO_URL, 'PNG', logoX, logoY, logoWidth, logoHeight);
  cursorY += logoHeight + 2*ORDER_EXPORT_SECTION_MARGIN;


	productLines.forEach((pl, i) => {

		if(i !== 0) {
			cursorY += ORDER_EXPORT_SECTION_MARGIN/2;
		}

		const orderedSets = pl.isManaged ? getOrderedSetNames(orders, pl) : [];

		const hasSets = orderedSets.length > 0;
		
		const sectionTitleFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_SECTION_TITLE_FONT_SIZE; // This assumes we don't change line height... so don't
		const listHeaderFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_FONT_SIZE;

		// Should only add header once per product line per page

	  let shouldAddListHeader = true;


		// Paginate, if needed

		const newPageCutoff = pageHeight - ORDER_EXPORT_HEADER_HEIGHT - ORDER_EXPORT_SECTION_MARGIN - sectionTitleFontHeight - ORDER_EXPORT_ROW_ALLOTMENT - ORDER_EXPORT_PAGE_MARGIN;
		if(cursorY > newPageCutoff) {
			doc.addPage();
			cursorY = ORDER_EXPORT_PAGE_MARGIN;
		}

		// Draw product line header

		const headerX = ORDER_EXPORT_PAGE_MARGIN;
	  const headerY = cursorY;
	  const headerWidth = pageWidth - 2*ORDER_EXPORT_PAGE_MARGIN;
	  const headerHeight = ORDER_EXPORT_HEADER_HEIGHT;

	  doc.setFillColor(ORDER_EXPORT_HEADER_COLOR);
	  doc.rect(headerX, headerY, headerWidth, headerHeight, 'F');

	  doc.setFillColor(ORDER_EXPORT_BACKGROUND_COLOR);
  	doc.triangle(headerX + headerWidth + 1, headerY - 1, headerX + headerWidth - headerHeight/2 - 1, headerY - 1, headerX + headerWidth + 1, headerY + headerHeight/2 + 1, 'F');

	  doc.setTextColor(ORDER_EXPORT_HEADER_TEXT_COLOR);
	  doc.setFontSize(ORDER_EXPORT_HEADER_FONT_SIZE);
	  doc.text(pl.name, headerX + ORDER_EXPORT_SECTION_MARGIN/2, headerY + headerHeight/2, { align: 'left', baseline: 'middle' });

	  cursorY += headerHeight + ORDER_EXPORT_SECTION_MARGIN;

	  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);

	  if(hasSets) {

	  	orderedSets.forEach((setName, j) => {

	  		// Draw section header
	  		const sectionPageCutoff = pageHeight - 3*ORDER_EXPORT_SECTION_MARGIN - sectionTitleFontHeight - ORDER_EXPORT_ROW_ALLOTMENT - ORDER_EXPORT_PAGE_MARGIN;
				if(cursorY > sectionPageCutoff) {
					doc.addPage();
					cursorY = ORDER_EXPORT_PAGE_MARGIN;
					shouldAddListHeader = true;
				}


	  		if(j !== 0) {
	  			cursorY += ORDER_EXPORT_SECTION_MARGIN/2;
	  		}


			  // Draw list header
			  if(shouldAddListHeader) {

				  doc.setFontSize(ORDER_EXPORT_FONT_SIZE);
				  if(fontStyes.includes('bold')) {
				  	doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'bold');
				  }

				  doc.text('Qty', tableQtyX + tableQtyWidth/2, cursorY, { align: 'center', baseline: 'top' });
				  doc.text(t(TX_NUMBER), tableNumberX + tableNumberWidth/2, cursorY, { align: 'center', baseline: 'top' });
				  doc.text(t(TX_NAME), tableNameX, cursorY, { align: 'left', baseline: 'top' });
				  doc.text(t(TX_DETAILS), tableDetailsX, cursorY, { align: 'left', baseline: 'top' });

				  cursorY += listHeaderFontHeight + ORDER_EXPORT_SECTION_MARGIN/2;

				  doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'normal');

				  shouldAddListHeader = false;
				}

				doc.setFontSize(ORDER_EXPORT_SECTION_TITLE_FONT_SIZE);
			  doc.text(setName, headerX, cursorY, { align: 'left', baseline: 'top' });

			  cursorY += sectionTitleFontHeight + ORDER_EXPORT_SECTION_MARGIN/2;


			  const rows = getOrderedSetInventory(orders, pl, setName);

			  rows.forEach((row, k) => {
  	
			  	// Get inventory configs
			  	const invConfigs = [];
			  	const nameArray = doc.splitTextToSize(row[ORDER_EXPORT_SECTION_ROW_NAME_KEY], tableNameWidth, { fontSize: ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE });

		  		invConfigs.push({
		  			label: `${t(TX_LANGUAGE)}: `,
		  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].language ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].language.nameTranslation) : '',
		  		});

			  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition && !_.isEqual(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition, CD_NEW)) {

			  		const conditionElement = {
			  			label: `${t(TX_CONDITION)}: `,
			  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition ? row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.value : '',
			  		}

			  		if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition && row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.fontColor) {
			  			conditionElement['fontColor'] = row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.fontColor;
			  		}

			  		invConfigs.push(conditionElement);
			  	}

			  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish) {
			  		invConfigs.push({
			  			label: `${t(TX_FILTER_FINISH)}: `,
			  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish.name) : '',
			  		});
			  	}

			  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing) {
			  		invConfigs.push({
			  			label: `${t(TX_PRINTING)}: `,
			  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing.name) : '',
			  		});
			  	}

			  	// Calculate config lines layout before writing
				  let configLineCount = 1;
				  let configLineLength = 0;
				  invConfigs.forEach((configLine, k) => {
				  	const elementLength = doc.getStringUnitWidth(configLine.label + configLine.value)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
				  	if(configLineLength) {
				  		if(configLineLength + elementLength + ORDER_EXPORT_SECTION_MARGIN/2 > tableDetailsWidth) {
				  			configLineCount = configLineCount + 1;
				  			configLineLength = elementLength;
				  		} else {
				  			configLineLength = configLineLength + elementLength + ORDER_EXPORT_SECTION_MARGIN/2;
				  		}
				  	} else {
				  		configLineLength = elementLength;
				  	}
				  });

			  	// Get height of new row
			  	const rowHeight = Math.max(nameArray.length*tableFontHeight, configLineCount*tableSmallFontHeight) + ORDER_EXPORT_SECTION_MARGIN/2;

			  	if(cursorY + rowHeight > pageHeight - 2*ORDER_EXPORT_PAGE_MARGIN) {
			  		doc.addPage();
			  		cursorY = ORDER_EXPORT_PAGE_MARGIN;
			  		shouldAddListHeader = true;
			  	}

			  	if(k % 2 === 0) {
			  		// Draw alternate color background
			  		doc.setFillColor(ORDER_EXPORT_TABLE_COLUMN_COLOR_ROW_ALT);
			  		doc.rect(tableX, cursorY, tableWidth, rowHeight, 'F');
			  	}

			  	// Set primary table font size
				  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE);

			  	// Write primary line text
				  const rowY = cursorY + tableFontHeight/2 + ORDER_EXPORT_SECTION_MARGIN/4;
			  					  
				  doc.text(row[ORDER_EXPORT_SECTION_ROW_QTY_KEY].toString(), tableQtyX + tableQtyWidth/2, rowY, { align: 'center', baseline: 'middle' });
				  doc.text(row[ORDER_EXPORT_SECTION_ROW_NUMBER_KEY], tableNumberX + tableNumberWidth/2, rowY, { align: 'center', baseline: 'middle' });
				  doc.text(nameArray, tableNameX, rowY, { align: 'left', baseline: 'middle' });

				  // Set config table font size
				  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);
				  
				  // Write config lines text
				  let configLineXCursor = 0;
				  let configLineYCursor = configLineCount === 1 ? rowY : rowY - (tableFontHeight - tableSmallFontHeight)/2;  // If a single line, align to middle of name font, otherwise align top
				  // let configLineYCursor = configLineCount === 1 ? rowY + tableFontHeight/2 : rowY + tableSmallFontHeight/2;  // If a single line, align to middle of name font, otherwise align top
				  invConfigs.forEach((configLine, m) => {
				  	
				  	const labelWidth = doc.getStringUnitWidth(configLine.label)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
				  	const totalWidth = doc.getStringUnitWidth(configLine.label + configLine.value)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
				  	
				  	if(configLineXCursor > 0 && configLineXCursor + totalWidth > tableDetailsWidth) {
				  		// New line required
				  		configLineXCursor = 0;
				  		configLineYCursor = configLineYCursor + tableSmallFontHeight;
				  	}

				  	doc.text(configLine.label, tableDetailsX + configLineXCursor, configLineYCursor, { baseline: 'middle' });

				  	if(configLine.fontColor) {
				  		doc.setTextColor(configLine.fontColor);
				  	}

				  	doc.text(configLine.value, tableDetailsX + configLineXCursor + labelWidth, configLineYCursor, { baseline: 'middle' });

				  	doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);
				  	configLineXCursor = configLineXCursor + totalWidth + ORDER_EXPORT_SECTION_MARGIN/2;
				  });

				  cursorY += rowHeight;
			  });

	  	});

			const noSetrows = getOrderedSetInventory(orders, pl, null);
		  if(noSetrows && noSetrows.length) {

		  	cursorY += ORDER_EXPORT_SECTION_MARGIN/2;

		  	// Draw section header
	  		const sectionPageCutoff = pageHeight - 3*ORDER_EXPORT_SECTION_MARGIN - sectionTitleFontHeight - ORDER_EXPORT_ROW_ALLOTMENT - ORDER_EXPORT_PAGE_MARGIN;
				if(cursorY > sectionPageCutoff) {
					doc.addPage();
					cursorY = ORDER_EXPORT_PAGE_MARGIN;
					shouldAddListHeader = true;
				}


			  // Draw list header
			  if(shouldAddListHeader) {
				  doc.setFontSize(ORDER_EXPORT_FONT_SIZE);
				  if(fontStyes.includes('bold')) {
				  	doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'bold');
				  }

				  doc.text('Qty', tableQtyX + tableQtyWidth/2, cursorY, { align: 'center', baseline: 'top' });
				  doc.text(t(TX_NUMBER), tableNumberX + tableNumberWidth/2, cursorY, { align: 'center', baseline: 'top' });
				  doc.text(t(TX_NAME), tableNameX, cursorY, { align: 'left', baseline: 'top' });
				  doc.text(t(TX_DETAILS), tableDetailsX, cursorY, { align: 'left', baseline: 'top' });

				  cursorY += listHeaderFontHeight + ORDER_EXPORT_SECTION_MARGIN/2;

				  doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'normal');

				  shouldAddListHeader = false;
				}

				doc.setFontSize(ORDER_EXPORT_SECTION_TITLE_FONT_SIZE);
			  doc.text(t(TX_OTHER), headerX, cursorY, { align: 'left', baseline: 'top' });

			  cursorY += sectionTitleFontHeight + ORDER_EXPORT_SECTION_MARGIN/2;


			  noSetrows.forEach((row, k) => {

			  	// Get inventory configs
			  	const invConfigs = [];
			  	const nameArray = doc.splitTextToSize(row[ORDER_EXPORT_SECTION_ROW_NAME_KEY], tableNameWidth, { fontSize: ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE });

		  		invConfigs.push({
		  			label: `${t(TX_LANGUAGE)}: `,
		  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].language ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].language.nameTranslation) : '',
		  		});

			  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition && !_.isEqual(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition, CD_NEW)) {

			  		const conditionElement = {
			  			label: `${t(TX_CONDITION)}: `,
			  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition ? row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.value : '',
			  		}

			  		if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition && row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.fontColor) {
			  			conditionElement['fontColor'] = row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.fontColor;
			  		}

			  		invConfigs.push(conditionElement);
			  	}

			  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish) {
			  		invConfigs.push({
			  			label: `${t(TX_FILTER_FINISH)}: `,
			  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish.name) : '',
			  		});
			  	}

			  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing) {
			  		invConfigs.push({
			  			label: `${t(TX_PRINTING)}: `,
			  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing.name) : '',
			  		});
			  	}

			  	// Calculate config lines layout before writing
				  let configLineCount = 1;
				  let configLineLength = 0;
				  invConfigs.forEach((configLine, k) => {
				  	const elementLength = doc.getStringUnitWidth(configLine.label + configLine.value)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
				  	if(configLineLength) {
				  		if(configLineLength + elementLength + ORDER_EXPORT_SECTION_MARGIN/2 > tableDetailsWidth) {
				  			configLineCount = configLineCount + 1;
				  			configLineLength = elementLength;
				  		} else {
				  			configLineLength = configLineLength + elementLength + ORDER_EXPORT_SECTION_MARGIN/2;
				  		}
				  	} else {
				  		configLineLength = elementLength;
				  	}
				  });

			  	// Get height of new row
			  	const rowHeight = Math.max(nameArray.length*tableFontHeight, configLineCount*tableSmallFontHeight) + ORDER_EXPORT_SECTION_MARGIN/2;

			  	if(cursorY + rowHeight > pageHeight - 2*ORDER_EXPORT_PAGE_MARGIN) {
			  		doc.addPage();
			  		cursorY = ORDER_EXPORT_PAGE_MARGIN;
			  		shouldAddListHeader = true;
			  	}

			  	if(k % 2 === 0) {
			  		// Draw alternate color background
			  		doc.setFillColor(ORDER_EXPORT_TABLE_COLUMN_COLOR_ROW_ALT);
			  		doc.rect(tableX, cursorY, tableWidth, rowHeight, 'F');
			  	}

			  	// Set primary table font size
				  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE);

			  	// Write primary line text
				  const rowY = cursorY + tableFontHeight/2 + ORDER_EXPORT_SECTION_MARGIN/4;
			  					  
				  doc.text(row[ORDER_EXPORT_SECTION_ROW_QTY_KEY].toString(), tableQtyX + tableQtyWidth/2, rowY, { align: 'center', baseline: 'middle' });
				  doc.text(row[ORDER_EXPORT_SECTION_ROW_NUMBER_KEY], tableNumberX + tableNumberWidth/2, rowY, { align: 'center', baseline: 'middle' });
				  doc.text(nameArray, tableNameX, rowY, { align: 'left', baseline: 'middle' });
				  
				  // Set config table font size
				  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);

				  // Write config lines text
				  let configLineXCursor = 0;
				  let configLineYCursor = configLineCount === 1 ? rowY : rowY - (tableFontHeight - tableSmallFontHeight)/2;  // If a single line, align to middle of name font, otherwise align top
				  // let configLineYCursor = configLineCount === 1 ? rowY + tableFontHeight/2 : rowY + tableSmallFontHeight/2;  // If a single line, align to middle of name font, otherwise align top
				  invConfigs.forEach((configLine, m) => {
				  	
				  	const labelWidth = doc.getStringUnitWidth(configLine.label)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
				  	const totalWidth = doc.getStringUnitWidth(configLine.label + configLine.value)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
				  	
				  	if(configLineXCursor > 0 && configLineXCursor + totalWidth > tableDetailsWidth) {
				  		// New line required
				  		configLineXCursor = 0;
				  		configLineYCursor = configLineYCursor + tableSmallFontHeight;
				  	}

				  	doc.text(configLine.label, tableDetailsX + configLineXCursor, configLineYCursor, { baseline: 'middle' });

				  	if(configLine.fontColor) {
				  		doc.setTextColor(configLine.fontColor);
				  	}

				  	doc.text(configLine.value, tableDetailsX + configLineXCursor + labelWidth, configLineYCursor, { baseline: 'middle' });

				  	doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);
				  	configLineXCursor = configLineXCursor + totalWidth + ORDER_EXPORT_SECTION_MARGIN/2;
				  });

				  cursorY += rowHeight;
			  });
		  }
		  	
	  } else {

	  	// Draw list header
	  	if(shouldAddListHeader) {

			  doc.setFontSize(ORDER_EXPORT_FONT_SIZE);
			  if(fontStyes.includes('bold')) {
			  	doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'bold');
			  }

			  doc.text('Qty', tableQtyX + tableQtyWidth/2, cursorY, { align: 'center', baseline: 'top' });
			  doc.text(t(TX_SKU), tableNumberX + tableNumberWidth/2, cursorY, { align: 'center', baseline: 'top' });
			  doc.text(t(TX_NAME), tableNameX, cursorY, { align: 'left', baseline: 'top' });
			  doc.text(t(TX_DETAILS), tableDetailsX, cursorY, { align: 'left', baseline: 'top' });

			  cursorY += listHeaderFontHeight + ORDER_EXPORT_SECTION_MARGIN/2;

			  doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'normal');

			  shouldAddListHeader = false;
			}
		  
		  const rows = getOrderProductLineInventory(orders, pl);

		  rows.forEach((row, k) => {
	
		  	// Get inventory configs
		  	const invConfigs = [];
		  	const nameArray = doc.splitTextToSize(row[ORDER_EXPORT_SECTION_ROW_NAME_KEY], tableNameWidth, { fontSize: ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE });

	  		invConfigs.push({
	  			label: `${t(TX_LANGUAGE)}: `,
	  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].language ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].language.nameTranslation) : '',
	  		});

		  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition && !_.isEqual(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition, CD_NEW)) {

		  		const conditionElement = {
		  			label: `${t(TX_CONDITION)}: `,
		  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition ? row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.value : '',
		  		}

		  		if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition && row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.fontColor) {
		  			conditionElement['fontColor'] = row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].condition.fontColor;
		  		}

		  		invConfigs.push(conditionElement);
		  	}

		  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish) {
		  		invConfigs.push({
		  			label: `${t(TX_FILTER_FINISH)}: `,
		  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].finish.name) : '',
		  		});
		  	}

		  	if(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing) {
			  		invConfigs.push({
			  			label: `${t(TX_PRINTING)}: `,
			  			value: row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing ? t(row[ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY].printing.name) : '',
			  		});
			  	}

		  	// Calculate config lines layout before writing
			  let configLineCount = 1;
			  let configLineLength = 0;
			  invConfigs.forEach((configLine, k) => {
			  	const elementLength = doc.getStringUnitWidth(configLine.label + configLine.value)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
			  	if(configLineLength) {
			  		if(configLineLength + elementLength + ORDER_EXPORT_SECTION_MARGIN/2 > tableDetailsWidth) {
			  			configLineCount = configLineCount + 1;
			  			configLineLength = elementLength;
			  		} else {
			  			configLineLength = configLineLength + elementLength + ORDER_EXPORT_SECTION_MARGIN/2;
			  		}
			  	} else {
			  		configLineLength = elementLength;
			  	}
			  });

		  	// Get height of new row
		  	const rowHeight = Math.max(nameArray.length*tableFontHeight, configLineCount*tableSmallFontHeight) + ORDER_EXPORT_SECTION_MARGIN/2;

		  	if(cursorY + rowHeight > pageHeight - 2*ORDER_EXPORT_PAGE_MARGIN) {
		  		doc.addPage();
		  		cursorY = ORDER_EXPORT_PAGE_MARGIN;
		  		shouldAddListHeader = true;
		  	}

		  	if(k % 2 === 0) {
		  		// Draw alternate color background
		  		doc.setFillColor(ORDER_EXPORT_TABLE_COLUMN_COLOR_ROW_ALT);
		  		doc.rect(tableX, cursorY, tableWidth, rowHeight, 'F');
		  	}

		  	// Set primary table font size
			  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE);

		  	// Write primary line text
			  const rowY = cursorY + tableFontHeight/2 + ORDER_EXPORT_SECTION_MARGIN/4;
		  					  
			  doc.text(row[ORDER_EXPORT_SECTION_ROW_QTY_KEY].toString(), tableQtyX + tableQtyWidth/2, rowY, { align: 'center', baseline: 'middle' });
			  doc.text(row[ORDER_EXPORT_SECTION_ROW_NUMBER_KEY], tableNumberX + tableNumberWidth/2, rowY, { align: 'center', baseline: 'middle' });
			  doc.text(nameArray, tableNameX, rowY, { align: 'left', baseline: 'middle' });
			  
			  // Set config table font size
			  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);

			  // Write config lines text
			  let configLineXCursor = 0;
			  let configLineYCursor = configLineCount === 1 ? rowY : rowY - (tableFontHeight - tableSmallFontHeight)/2;  // If a single line, align to middle of name font, otherwise align top
			  // let configLineYCursor = configLineCount === 1 ? rowY + tableFontHeight/2 : rowY + tableSmallFontHeight/2;  // If a single line, align to middle of name font, otherwise align top
			  invConfigs.forEach((configLine, m) => {
			  	
			  	const labelWidth = doc.getStringUnitWidth(configLine.label)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
			  	const totalWidth = doc.getStringUnitWidth(configLine.label + configLine.value)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
			  	
			  	if(configLineXCursor > 0 && configLineXCursor + totalWidth > tableDetailsWidth) {
			  		// New line required
			  		configLineXCursor = 0;
			  		configLineYCursor = configLineYCursor + tableSmallFontHeight;
			  	}

			  	doc.text(configLine.label, tableDetailsX + configLineXCursor, configLineYCursor, { baseline: 'middle' });

			  	if(configLine.fontColor) {
			  		doc.setTextColor(configLine.fontColor);
			  	}

			  	doc.text(configLine.value, tableDetailsX + configLineXCursor + labelWidth, configLineYCursor, { baseline: 'middle' });

			  	doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);
			  	configLineXCursor = configLineXCursor + totalWidth + ORDER_EXPORT_SECTION_MARGIN/2;
			  });

			  cursorY += rowHeight;
		  });
	  }
	});

  return doc;
}