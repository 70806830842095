

const CLP = {
	code: 'CLP',
	name: 'Peso',
	nameFull: 'Peso',
	namePlural: 'Peso',
	symbol: '$',
	minorCurrencyCount: 1,
	decimalTruncation: true,
	decimalSeparator: ',',
}

const EUR = {
	code: 'EUR',
	name: 'Euro',
	nameFull: 'Euro',
	namePlural: 'Euros',
	symbol: '€',
	minorCurrencyCount: 100,
}

const RON = {
	code: 'RON',
	name: 'Leu',
	nameFull: 'Leu românesc',
	namePlural: 'Lei',
	symbol: 'lei',
	minorCurrencyCount: 100,
}

const USD = {
	code: 'USD',
	name: 'Dollar',
	nameFull: 'US Dollar',
	namePlural: 'Dollars',
	symbol: '$',
	minorCurrencyCount: 100,
}


export const CURRENCIES = {
	CLP: CLP,
	EUR: EUR,
	RON: RON,
	USD: USD,
}