
import {
	ANALYTICS_DURATION_UNIT_MONTH,
  	ANALYTICS_DURATION_UNIT_WEEK,
} from '../../constants/analytics';
import { 
	TX_CAL_MONTH_ONE,
	TX_CAL_MONTH_TWO,
	TX_CAL_MONTH_THREE,
	TX_CAL_WEEK_ONE,
} from '../../constants/strings';


export class AnalyticsDataOrdersTotal {

	constructor(props) {
		
		if(!props) { props = {}; }

		this.total = parseInt(props.total) || 0;
	}

	getDurationOptions() {
		const options = [
			{
				display: TX_CAL_WEEK_ONE,
				value: `1${ANALYTICS_DURATION_UNIT_WEEK}`,
			},
			{
				display: TX_CAL_MONTH_ONE,
				value: `1${ANALYTICS_DURATION_UNIT_MONTH}`,
			},
			{
				display: TX_CAL_MONTH_TWO,
				value: `2${ANALYTICS_DURATION_UNIT_MONTH}`,
			},
			{
				display: TX_CAL_MONTH_THREE,
				value: `3${ANALYTICS_DURATION_UNIT_MONTH}`,
			},
		];
		return options;
	}
}









