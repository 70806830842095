import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import {
  ICON_BUY,
  ICON_BUYLIST_CART,
  ICON_CALENDAR,
  ICON_CART,
  ICON_INVENTORY,
  ICON_SELL,
} from '../../constants/icons';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_ORDERS, 
  TABLE_SCHEMA_ORDERS, 
  TC_PAGINATION_CONFIGS, 
  TC_DEFAULT_PAGE_SIZE, 
} from '../../constants/tables';
import {
  URL_ADMIN_ORDERS_BUYLIST,
  URL_ADMIN_ORDERS_BUYLIST_CARTS,
  URL_ADMIN_ORDERS_CARTS,
  URL_ADMIN_ORDERS_OPEN,
  URL_ADMIN_ORDERS_PREORDER,
  URL_ADMIN_ORDERS_PURCHASE,
} from '../../constants/urls';

import { isBuylistCheckoutEnabled } from '../../utils/checkout';
import { 
  getPageLimit, 
  getPageOffset,
} from '../../utils/request';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';
import { Icon } from '../Icons/Icon';

import * as orderActionCreators from '../../actions/order';
let allActionCreators = Object.assign({}, orderActionCreators);

export class Orders extends Component {

  constructor(props) {
    super(props);

    this.state = {
      orderData: [],
      orderPending: true,
      orderCount: 0,

      orderPage: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getOrderPage(this.state.orderPage);
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getOrderPage(pageNum = 1, pageSize = TABLE_CONFIG_ORDERS[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      orderData: [],
      orderPending: true,
      orderCount: 0,
    });

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    this.props.ordersAdminFetchPage(getParams, controller.signal)
    .then((resp) => {
      this.setState({
        orderData: resp.data,
        orderPending: false,
        orderCount: resp.count,
      });
    })
    .catch((errResp) => {
      if(controller.signal.aborted) {
        // Request aborted; do nothing
      } else if(errResp) {
        console.error(errResp);
        this.setState({
          orderData: [],
          orderPending: false,
          orderCount: 0,
        });
      }
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'Orders AdminPage'}>
      <AdminTitle
        title={tx.TX_ORDERS}
        breadcrumbs={[]} />
      <div className={'inventoryIconRow adminIconRow'}>
        <Link to={URL_ADMIN_ORDERS_PURCHASE} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_SELL}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SALES)} 
            ellipsisLabel={false} />
        </Link>
        {isBuylistCheckoutEnabled() ?
          <Link to={URL_ADMIN_ORDERS_BUYLIST} className={'inventoryIconWrapper adminIconWrapper'}>
            <Icon 
              value={ICON_INVENTORY}  
              iconClass={'inventoryIcon adminIcon'} 
              iconLabel={t(tx.TX_ORDER_BUYLIST_ORDERS)} 
              ellipsisLabel={false} />
          </Link> :
          null
        }
        <Link to={URL_ADMIN_ORDERS_OPEN} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_BUY}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_MENU_ITEM_OPENORDERS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_ORDERS_PREORDER} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_CALENDAR}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_ORDER_PREORDERS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_ORDERS_CARTS} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_CART}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_ORDER_CARTS)} 
            ellipsisLabel={false} />
        </Link>
        {isBuylistCheckoutEnabled() ?
          <Link to={URL_ADMIN_ORDERS_BUYLIST_CARTS} className={'inventoryIconWrapper adminIconWrapper'}>
            <Icon 
              value={ICON_BUYLIST_CART}  
              iconClass={'inventoryIcon adminIcon'} 
              iconLabel={t(tx.TX_BUYLIST_CARTS)} 
              ellipsisLabel={false} />
          </Link> :
          null
        }
      </div>
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_ORDERS}
          schema={TABLE_SCHEMA_ORDERS}
          data={this.state.orderData}
          dataCount={this.state.orderCount} 
          dataLoading={this.state.orderPending}
          callApi={this.getOrderPage.bind(this)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Orders));