import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ERROR_MENU_ORDER_MISSING,
  ERROR_PERMALINK_TAKEN, 
} from '../../../../constants/errors';
import { PROD_DEFAULT_KEY_WEIGHT } from '../../../../constants/product';
import * as tx from '../../../../constants/strings';
import { URL_NS_SHOP } from '../../../../constants/urls';

import { ProductLine } from '../../../../models/products';

import { 
  isFormValid,
  getNameError, 
  getPermalinkError, 
} from '../../../../utils/form-validation';
import { formatServerError, stringToPermalink } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import { getOrderedMenu } from '../../../../utils/product';

import LoadingIcon from '../../../Icons/LoadingIcon';
import ProductLineDefaultField from './ProductLineDefaultField';
import SavePrompt from '../../../Popups/SavePrompt';
import Toggle from '../../../Input/Toggle';

import * as commonActionCreators from '../../../../actions/common';
import * as productLineActionCreators from '../../../../actions/product-line';
let allActionCreators = Object.assign({}, commonActionCreators, productLineActionCreators);

export class ProductLineDetailsEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      isDirty: false,

      inputStatus: this.props.productLineObj ? this.props.productLineObj.isEnabled : false,
      inputName: this.props.productLineObj ? this.props.productLineObj.name : '',
      inputPermalink: this.props.productLineObj ? this.props.productLineObj.permalink : '',
      inputInBuyMenu: this.props.productLineObj ? this.props.productLineObj.inMenu : false,
      inputDisplayOrder: this.props.productLineObj ? this.props.productLineObj.displayOrder === 0 ? this.props.productLineObj.displayOrder : this.props.productLineObj.displayOrder || '' : '',
      inputHasBuylist: this.props.productLineObj ? this.props.productLineObj.hasBuylist : false,
      inputHasEvents: this.props.productLineObj ? this.props.productLineObj.hasEvents : false,

      errorName: '',
      errorPermalink: '',
      errorDisplayOrder: '', 

      requestPending: false,
      requestError: null,

      savePromptOpen: false, 
    };

    this.controllerSku = null;
    this.controllerPermalink = null;
  }

  componentDidMount() {

    // Get managed lines
    if(this.props.productLine && this.props.productLine.productLinesManaged === null) {      
      this.props.productLinesFetchManaged()
        .catch((errResp) => {
          if(errResp) {
            console.error(errResp);
          }
        });
    }

    // Get menu data if needed
    // Should not be needed, but if for some reason not present
    // Needed to populate menu dropdown
    if(!this.props.productLine.menus) {
      this.props.productLineFetchMenus()
      .catch((errResp) => {
        console.error(errResp);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  componentWillUnmount() {
    if(this.controllerSku) {
      this.controllerSku.abort();
    }
    if(this.controllerPermalink) {
      this.controllerPermalink.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  isFixedSyncOption() {
    return this.props.productLineObj && this.props.productLineObj.isManaged ? this.props.productLineObj.isManaged : false;
  }

  changeStatus(evt) {
    this.setState({
      inputStatus: !this.state.inputStatus, 
    });
    this.makeDirty();
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
    }, () => {
      if(this.state.errorName) {
        this.validateName();
      }
    });
    this.makeDirty();
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  getSelectedName() {
    return this.isFixedSyncOption() ? this.props.t(this.getSyncName()) : this.state.inputName;
  }

  getSyncName() {
    if(this.props.productLine && this.props.productLine.productLinesManaged) {
      for(const pl of this.props.productLine.productLinesManaged) {
        if(this.props.productLineObj && this.props.productLineObj.isManaged && this.props.productLineObj.managedLineId && pl.id === this.props.productLineObj.managedLineId) {
          return pl.name;
        }
      }
      if(this.props.productLineObj && this.props.productLineObj.isManaged === false) {
        return tx.TX_INV_PL_MANUAL;
      }
    }
    return tx.TX_null;
  }

  changePermalink(evt) {
    this.setState({
      inputPermalink: stringToPermalink(evt.target.value, true, 32),
    }, () => {
      if(this.state.errorPermalink) {
        this.validatePermalink();
      }
    });
    this.makeDirty();
  }

  validatePermalink() {
    this.setState({ errorPermalink: getPermalinkError(this.state.inputPermalink, this.getReservedPermalinks()) }, () => {
      if(this.state.errorPermalink === '') {
        this.props.productLineFetch(this.state.inputPermalink)
        .then((resp) => {
          // Product line already exists
          this.setState({ errorPermalink: ERROR_PERMALINK_TAKEN });
        })
        .catch((errResp) => {
          // No matching permalink found; do nothing
        });
      }
    });
  }

  getReservedPermalinks() {
    let reservedPermalinks = [];
    if(this.props.productLine && this.props.productLine.productLinesManaged) {
      for(const managedSync of this.props.productLine.productLinesManaged) {
        reservedPermalinks.push(managedSync.permalink);
      }
    }
    return reservedPermalinks;
  }

  getSelectedPermalink() {
    return this.isFixedSyncOption() ? this.props.productLineObj.permalink : this.state.inputPermalink;
  }

  changeInBuyMenu(evt) {
    this.setState({
      inputInBuyMenu: !this.state.inputInBuyMenu, 
    }, () => {
      if(!this.state.inputInBuyMenu) {
        this.setState({ 
          inputDisplayOrder: '',  
        });
      }
    });
  }

  changeHasBuylist(evt) {
    this.setState({
      inputHasBuylist: !this.state.inputHasBuylist, 
    });
  }

  changeHasEvents(evt) {
    this.setState({
      inputHasEvents: !this.state.inputHasEvents, 
    });
  }

  changeDisplayOrder(evt) {
    this.setState({
      inputDisplayOrder: evt.target.value, 
    });
  }

  getOrderList() { 
    if(!this.props.productLine.menus) {
      return [];
    }
    return getOrderedMenu(this.props.productLine.menus);
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const prodLine = new ProductLine({
        pk: this.props.productLineObj.id,
        name: this.getSelectedName(),
        permalink: this.getSelectedPermalink(),
        is_managed: this.isFixedSyncOption(),
        managed_line_id: this.props.productLineObj.managedLineId,
        is_enabled: this.state.inputStatus,
        in_menu: this.state.inputStatus && this.state.inputInBuyMenu,
        display_order: this.state.inputDisplayOrder === '' ? null : parseInt(this.state.inputDisplayOrder),
        has_buylist: this.state.inputHasBuylist,
        has_events: this.state.inputHasEvents,
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const editResp = await this.props.productLineEdit(prodLine.getApiData())
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });

          if(this.props.remoteSave) {
            this.props.finishRemote('details', true);
          }
        });

      if(!editResp) { return null; }


      this.props.productLinesSetAll(null);

      // Pick up any menu changes
      this.props.productLineFetchMenus()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });

      // Pick up any enabled product line changes
      this.props.productLineFetchEnabled()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });

      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {

        if(this.props.remoteSave) {
          this.props.finishRemote('details', false, this.props.productLineObj.permalink !== this.state.inputPermalink ? this.state.inputPermalink : this.props.productLineObj.permalink);
        } else {
          this.props.makeClean('details', this.props.productLineObj.permalink !== this.state.inputPermalink ? this.state.inputPermalink : false);
        }
        this.props.toggleEdit();
      });

    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('details', true);
    }
  }

  async updateDefault(updateData) {

    const updateResp = await this.props.productLineUpdateDefaults(updateData, this.props.productLineObj.id)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

    if(!updateResp) { return null; }

    this.props.makeClean('details', false, false);
  }

  validateAll() {
    const errorObj = {
      errorName: getNameError(this.getSelectedName()),
      errorPermalink: getPermalinkError(this.state.inputPermalink),
      errorDisplayOrder: this.state.inputInBuyMenu && this.state.inputDisplayOrder === '' ? ERROR_MENU_ORDER_MISSING : '',
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  render() {

    const {t} = this.props;

    if(!this.props.productLineObj) { return null; }

    return <div className={'ProductLineDetailsEdit adminSection'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'productLineDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_INV_VIEW_PRODUCT_LINE_DETAILS)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputStatus}
                    onToggle={this.changeStatus.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.getSelectedName()}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_PL_NAME)}
                  disabled={this.isFixedSyncOption()}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_PERMALINK)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='given-name'
                  className={this.state.errorPermalink && !this.isFixedSyncOption(this.state.inputSyncType) ? 'InputError' : ''}
                  value={this.getSelectedPermalink()}
                  onChange={this.changePermalink.bind(this)}
                  onBlur={this.validatePermalink.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_PL_PERMALINK)}
                  disabled={this.isFixedSyncOption(this.state.inputSyncType)}
                  maxLength={32} />
                {this.state.errorPermalink && !this.isFixedSyncOption(this.state.inputSyncType) ?
                  <div className={'adminError FieldError'}>{t(this.state.errorPermalink)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  <span className='plUrlLabel'>{t(tx.TX_INV_PL_URL)}:</span>
                  <span className='plUrlValue'>{`${window.location.origin}/${URL_NS_SHOP}/${this.getSelectedPermalink()}`}</span>
                </div>
              </div>
            </div>
            {this.state.inputStatus ?
              <div className='adminFieldWrapper'>
                <div className={'adminInputWrapper halfWidth'}>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_IN_MENU)}</div>
                  <div className='adminInputToggleWrapper'>
                    <Toggle
                      checked={this.state.inputInBuyMenu}
                      onToggle={this.changeInBuyMenu.bind(this)}
                      trueValue={tx.TX_YES}
                      falseValue={tx.TX_NO} />
                  </div>
                </div>    
              </div> :
              null
            }
            {this.state.inputInBuyMenu && this.state.inputStatus ?
              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_MENU_ORDER)}</div>
                <div className='adminInputWrapper'>
                  <div className='adminDropdownWrapper'>
                    <select
                      value={this.state.inputDisplayOrder}
                      onChange={this.changeDisplayOrder.bind(this)}
                      className={this.state.errorDisplayOrder ? 'InputError' : ''}
                      required={true}>
                      <option className='placeholderOption' value=''>{t(tx.TX_PLACEHOLDER_MENU_ORDER_DROPDOWN)}</option>
                      <option value={0}>{t(tx.TX_FIRST)}</option>
                      {this.getOrderList().map((pl, i) => {
                        if(this.props.productLineObj.id === pl.id) {
                          return null;
                        }
                        return <option key={i} value={i + 1}>{t(tx.TX_AFTER_NOUN, { noun: pl.name })}</option>;
                      })}
                    </select>
                    {this.state.errorDisplayOrder ?
                      <div className={'adminError FieldError'}>{t(this.state.errorDisplayOrder)}</div> :
                      null
                    }
                    <div className='adminDropdownArrowWrapper'>
                      <div className='adminArrow'></div>
                    </div>
                  </div>
                </div>
              </div> :
              null
            }
            <div className='adminFieldWrapper'>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_HAS_BUYLIST)}</div>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputHasBuylist}
                    onToggle={this.changeHasBuylist.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_HAS_EVENTS)}</div>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputHasEvents}
                    onToggle={this.changeHasEvents.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
            </div>

            

            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_INV_PL_DEFAULT_PRODUCT_WEIGHT)}</div>
              {this.props.productLineObj.segments && this.props.productLineObj.segments.length === 0 ?
                <div className={'adminInputWrapper'}>
                  <ProductLineDefaultField
                    defaultKey={PROD_DEFAULT_KEY_WEIGHT}
                    productLineObj={this.props.productLineObj}
                    productLinePending={this.props.productLinePending}
                    updateDefault={this.updateDefault.bind(this)}
                    segment={null} />
                </div> :
                <>
                  {this.props.productLineObj.segments.map((segment, i) => {
                    return <div key={i}>
                      <div className={'adminInputWrapper'}>
                        <ProductLineDefaultField
                          defaultKey={PROD_DEFAULT_KEY_WEIGHT}
                          productLineObj={this.props.productLineObj}
                          productLinePending={this.props.productLinePending}
                          updateDefault={this.updateDefault.bind(this)}
                          segment={segment} />
                      </div>
                    </div>;
                  })}
                </>
              }
            </div>

            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLineDetailsEdit));