import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';
import { URL_SELL_PRODUCT_LINE } from '../../../constants/urls';

import { stringFormat } from '../../../utils/formatting';
import { getOrderedSellMenu } from '../../../utils/product';

import '../style/_headersubmenu.scss';

import * as headerActionCreators from '../../../actions/header';
let allActionCreators = Object.assign({}, headerActionCreators);

export class HeaderSubmenuSell extends Component {

  getMenuLinks() {
    return getOrderedSellMenu(this.props.productLine.menus);
  }

  toggleMenu() {
    if(this.props.toggleFullMenu) {
      this.props.headerToggleUserMenu();
    }

    if(this.props.closeMenu) {
      this.props.closeMenu();
    }
  }

  getLinkUrl(link, appliedFilters = null) {

    const clearFiltersState = {
      clearFilters: true,
    }
    const linkState = appliedFilters ? Object.assign({}, clearFiltersState, { filters: appliedFilters }) : clearFiltersState;

    return {
      pathname: stringFormat(URL_SELL_PRODUCT_LINE, { productLine: link.permalink }),
      state: linkState,
    }
  }

  render() {

  	const {t} = this.props;
    const links = this.getMenuLinks();
  	
    return <div className={'HeaderSubmenuSell HeaderSubmenuCore'}>
      <div className='humSection'>
        <div className='humSectionTitle' style={this.props.hideTitle ? { display: 'none' } : {}}>{t(tx.TX_SELL)}</div>
        <div className='humSectionBody'>
          {links.length === 0 ?
            <div className='noLinksItem'>{t(tx.TX_MENU_NO_ITEMS)}</div> :
            <>
              {links.map((link, i) => {
                return <NavLink 
                        key={i}
                        to={this.getLinkUrl(link)} 
                        className='humSectionLink' 
                        onClick={this.toggleMenu.bind(this)}>
                          {link.name}
                       </NavLink>;
              })}
            </>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderSubmenuSell));


