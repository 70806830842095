import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import {
  URL_ADMIN_SETTINGS, 
  URL_ADMIN_SETTINGS_PAYMENT_METHODS, 
} from '../../constants/urls';

import { PaymentMethod } from '../../models/payment-methods';

import { formatServerError } from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import DeletePrompt from '../Popups/DeletePrompt';
import PaymentMethodDetailsEdit from './blocks/PaymentMethodDetailsEdit';
import PaymentMethodDetailsView from './blocks/PaymentMethodDetailsView';
import PaymentMethodConfigView from './blocks/PaymentMethodConfigView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_paymentmethods.scss';

import * as commonActionCreators from '../../actions/common';
import * as checkoutActionCreators from '../../actions/checkout';
let allActionCreators = Object.assign({}, commonActionCreators, checkoutActionCreators);

export class ViewPaymentMethod extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirtyDetails: false,
      
      savePromptOpen: false,
      deletePromptOpen: false,
      lastBlock: '',

      editDetails: false,
      editConfig: false,

      configExpanded: false,

      requestPending: true,
      requestError: null,
      responsePaymentMethod: new PaymentMethod(),

      savingAll: false,
      remoteSaveDetails: false,
    };

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyDetails === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchPaymentMethod();
  }

  componentWillUnmount() {
    this.unblock();
    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyDetails === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchPaymentMethod();
      }
    }
  }

  fetchPaymentMethod(service = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    // Don't set requestObj to null; will close individual config fields
    this.setState({
      requestPending: true,
      requestError: null,
    });

    // Get payment method details
    if(this.props.match.params.paymentMethodUUID) {
      this.props.checkoutFetchSinglePaymentMethod(this.props.match.params.paymentMethodUUID, controller.signal)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
          responsePaymentMethod: new PaymentMethod(resp),
        });
        if(service === 'details') {
          this.setState({ isDirtyDetails: false });
        }
      })
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responsePaymentMethod: new PaymentMethod(),
        });
      });
    }
  }

  makeDetailsDirty() {
    if(this.state.isDirtyDetails === false) {
      this.setState({ isDirtyDetails: true });
    }
  }

  toggleDetails() {
    this.setState({ editDetails: !this.state.editDetails }, () => {
      if(this.state.editDetails === false) {
        this.setState({ isDirtyDetails: false, });
      }
    });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      savingAll: true, 
      remoteSaveDetails: this.state.isDirtyDetails,
    });
  }

  finishRemote(service, didFail = false) {

    if(service === 'details') {
      this.setState({ 
        isDirtyDetails: didFail !== false, 
        remoteSaveDetails: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true });
      });
    }
  }

  toggleDeletePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      deletePromptOpen: !this.state.deletePromptOpen,
    });
  }

  deleteButtonAction(evt) {
    if(evt) { evt.preventDefault(); }

    if(this.state.responsePaymentMethod.isEnabled) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_TITLE, 
        alertCopy: tx.TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_COPY, 
      });
    } else {
      this.toggleDeletePrompt();
    }
  }

  deleteAction(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.checkoutPaymentMethodDelete(this.state.responsePaymentMethod.publicUuid)
    .then((resp) => {
      this.setState({ isDirty: false }, () => {
        history.push(URL_ADMIN_SETTINGS_PAYMENT_METHODS);
      });
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewPaymentMethod AdminPage'}>
      <AdminTitle
        title={tx.TX_VIEW}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
          {
            url: URL_ADMIN_SETTINGS_PAYMENT_METHODS,
            title: tx.TX_SETTINGS_PAYMENT_METHODS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>

          {this.state.editDetails ? 
            <PaymentMethodDetailsEdit
              paymentMethod={this.state.responsePaymentMethod}
              paymentMethodPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)}
              makeDirty={this.makeDetailsDirty.bind(this)}
              makeClean={this.fetchPaymentMethod.bind(this)} 
              remoteSave={this.state.remoteSaveDetails}
              finishRemote={this.finishRemote.bind(this)} /> :
            <PaymentMethodDetailsView
              paymentMethod={this.state.responsePaymentMethod}
              paymentMethodPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)} />
          }

          <PaymentMethodConfigView
            paymentMethod={this.state.responsePaymentMethod}
            paymentMethodPending={this.state.requestPending}
            makeClean={this.fetchPaymentMethod.bind(this)}  />

        </div>
      </div>
      <div className='adminActions'>
        <button className='blockButton' onClick={this.deleteButtonAction.bind(this)}>{t(tx.TX_DELETE)}</button>
        <Link to={URL_ADMIN_SETTINGS_PAYMENT_METHODS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <DeletePrompt
        open={this.state.deletePromptOpen}
        copy={tx.TX_SETTINGS_PAYMENT_METHODS_DELETE_COPY}
        closeMethod={this.toggleDeletePrompt.bind(this)}
        onConfirm={this.deleteAction.bind(this)}
        onCancel={this.toggleDeletePrompt.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewPaymentMethod));