

import { ERROR_INTEGRATION_CONFIG } from '../constants/errors';
import { 
	STORE_INTEGRATION_CONFIG_TYPE_TEXT,
	STORE_INTEGRATION_STAMPS_KEY,
	STORE_INTEGRATIONS_ALL, 
} from '../constants/store';
import { TX_null } from '../constants/strings';


import {
	encryptString, 
	decryptString, 
} from '../utils/crypto';
import { stringFormat } from '../utils/formatting';
import { 
	isVarArray,
	isVarObject, 
	isVarString,
} from '../utils/general';


export class Integration {

	constructor(props) {
		
		if(!props) { props = {}; }

		const providerObj = props.provider || {};
		const typeObj = props.type || props.integration_type || {};

		this.publicUuid = props.publicUuid || props.public_uuid || null;

		this.name = props.name || '';
		this.remote = props.remote || {};
		this.configData = props.configData || props.config_data || {};
		this.privateData = props.privateData || props.config_data_2 || [];
		this.isEnabled = props.isEnabled || props.is_enabled || false;

		this.provider = new IntegrationProvider(providerObj);
		this.type = new IntegrationType(typeObj);

		const configObj = {};
		for(const conf of this.configSchema) {
			configObj[conf.key] = new IntegrationConfig({
				...conf,
				public: this.configData,
				private: this.privateData,
				parentData: {
					publicUuid: this.publicUuid,
				},
			});
		}
		this.config = configObj;

		const localConfigObj = {};
		for(const conf of this.localConfigSchema) {
			localConfigObj[conf.key] = conf;
		}
		this.localConfig = localConfigObj;
	}

	get providerConfig() {
		if(!this.provider.key || !this.type.key) { return {}; }

		for(const ing of STORE_INTEGRATIONS_ALL) {
			if(ing.key === this.provider.key) {	
				return ing;
			}
		}
		return {};
	}

	get hidePickup() {
		return this.providerConfig.hidePickup || false;
	}

	get hideCosts() {
		return this.providerConfig.hideCosts || false;
	}

	get oauth() {
		return this.providerConfig.oauth || null;
	}

	get adminDisplay() {
		return this.providerConfig.adminDisplay || null;
	}

	get oauthRedirectUri() {
		if(!this.oauth || !this.oauth.redirectUri) {
			return '';
		}
		return `${window.location.origin}${this.oauth.redirectUriData ? stringFormat(this.oauth.redirectUri, this.oauth.redirectUriData) : this.oauth.redirectUri}`;
	}

	get configSchema() {

		if(!this.provider.key || !this.type.key) { return []; }

		for(const ing of STORE_INTEGRATIONS_ALL) {
			if(ing.key === this.provider.key && Object.keys(ing.config).includes(this.type.key)) {
				const schemaArray = [];
				for(const ingConf of ing.config[this.type.key]) {
					schemaArray.push(new IntegrationConfig(Object.assign({}, ingConf, { public: this.configData, private: this.privateData })));
				}
				return schemaArray;
			}
		}
		return [];
	}

	get localConfigSchema() {

		if(!this.provider.key || !this.type.key) { return []; }

		for(const ing of STORE_INTEGRATIONS_ALL) {
			if(ing.key === this.provider.key && Object.keys(ing.localConfig).includes(this.type.key)) {
				const schemaArray = [];
				for(const ingConf of ing.localConfig[this.type.key]) {
					schemaArray.push(ingConf);
				}
				return schemaArray;
			}
		}
		return [];
	}

	get saveShippingMethodComponent() {
		return this.providerConfig.components && this.providerConfig.components.saveShippingMethod ? this.providerConfig.components.saveShippingMethod : null;
	}

	get createShipmentComponent() {
		return this.providerConfig.components && this.providerConfig.components.createShipment ? this.providerConfig.components.createShipment : null;
	}

	static apiDataCreate(providerKey = '', typeKey = '') {
		const apiData = {
			integration_type: typeKey,
			integration_provider: providerKey,
		};
		return apiData;
	}

	get isStamps() {
		return this.provider && this.provider.key && this.provider.key === STORE_INTEGRATION_STAMPS_KEY;
	}

	getCalcConfigs() {
		const configResp = [];
				
		for(const key in this.configSchema) {
			if(this.configSchema[key] && !this.configSchema[key].component && this.configSchema[key].isCalculated) {
				configResp.push(this.configSchema[key]);
			}
		}
		return configResp;
	}

	getVariableConfigs() {
		const configResp = [];
				
		for(const key in this.configSchema) {
			if(this.configSchema[key] && !this.configSchema[key].component && !this.configSchema[key].isCalculated) {
				if(this.configSchema[key].hideWhenDisabled && !this.isEnabled) {
					continue;
				}
				configResp.push(this.configSchema[key]);
			}
		}
		return configResp;
	}

	getCustomConfigs() {
		const configResp = [];
				
		for(const key in this.configSchema) {
			if(this.configSchema[key] && this.configSchema[key].component) {
				if(this.configSchema[key].hideWhenDisabled && !this.isEnabled) {
					continue;
				}
				configResp.push(this.configSchema[key]);
			}
		}
		return configResp;
	}

	getConfigError() {
		return this.isValid() ? '' : ERROR_INTEGRATION_CONFIG;
	}

	isValid() {

		for(const conf of this.configSchema) {

			if(conf.isRequired) {

				if(conf.isPrivate) {
					if(this.privateData.includes(conf.key) === false) {
						return false;
					}
				} else {
					if(!this.configData[conf.key]) {
						return false
					} else if(isVarArray(this.configData[conf.key]) && this.configData[conf.key].length === 0) {
						return false;
					}
				}
			}
		}
		return true;
	}
}


export class IntegrationConfig {

	constructor(props) {		
		if(!props) { props = {}; }

		this.key = props.key || '';
		this.isCalculated = props.isCalculated || false;
		this.isPrivate = props.isPrivate || false;
		this.isRequired = props.isRequired || false;
		this.isEncrypted = props.isEncrypted || false;
		this.label = props.label || TX_null;
		this.placeholder = props.placeholder || TX_null;
		this.validationMethod = props.validationMethod || null;
		this.component = props.component || null;
		this.displayLastFour = props.displayLastFour || false;
		this.disableWhenEnabled = props.disableWhenEnabled || false;
		this.dataType = props.dataType || STORE_INTEGRATION_CONFIG_TYPE_TEXT;
		this.hideWhenDisabled = props.hideWhenDisabled || false;

		this.publicData = props.public || {};
		this.privateData = props.private || [];
		this.parentData = props.parentData || {};
	}

	hasValue() {
		if(this.isPrivate) {
			return this.privateData.includes(this.key);
		} else {
			return Object.keys(this.publicData).includes(this.key);
		}
	}

	hasLastFourValue() {
		if(this.isPrivate && this.displayLastFour) {
			return this.privateData.includes(this.key) && Object.keys(this.publicData).includes(`${this.key}__lastFour`);
		} else {
			return Object.keys(this.publicData).includes(this.key);
		}
	}

	get value() {
		if(this.isPrivate === true && this.isCalculated === false) {
			return '';
		} else if(this.isCalculated === true) {
			const values = {};
			for(const key of this.calcTemplateVars) {
				values[key] = this.parentData[key];
			}
			return stringFormat(this.calcTemplate, values);
		}
		if(this.hasValue()) {
			if(this.isEncrypted === true) {
				return decryptString(this.publicData[this.key]);
			} else {
				return this.publicData[this.key];
			}
		} else {
			return '';
		}
	}

	get lastFour() {
		if(this.isPrivate && this.displayLastFour && this.hasLastFourValue()) {
			if(this.isEncrypted === true) {
				return decryptString(this.publicData[`${this.key}__lastFour`]);
			} else {
				return this.publicData[`${this.key}__lastFour`];
			}
		} 
		return '';
	}

	getApiData(value) {

		try {

			let stringValue = value;

			if(isVarObject(value)) {
				stringValue = JSON.stringify(value);
			}

			stringValue = stringValue.trim ? stringValue.trim() : stringValue;

			const apiData = {
	    	[this.isPrivate ? 'config_data_2' : 'config_data']: {
	    		[this.key]: this.isEncrypted ? encryptString(stringValue) : stringValue,
	    	}
	    }
	    if(this.isPrivate && this.displayLastFour) {
	    	const lastFourValue = stringValue.slice(-4);
	    	apiData['config_data'] = {
	    		[`${this.key}__lastFour`]: this.isEncrypted ? encryptString(lastFourValue) : lastFourValue,
	    	}
	    }
	    return apiData;

		} catch(err) {
			console.error(err);
			return {};
		}
	}
}


export class IntegrationType {

	constructor(props) {
		
		if(!props) { props = {}; }

		this.key = props.key || '';
		this.name = props.name || '';
		this.isPrivate = props.isPrivate || props.is_private || false;
	}
}


export class IntegrationProvider {

	constructor(props) {
		
		if(!props) { props = {}; }

		const integrationArray = props.integrations || [];

		this.key = props.key || '';
		this.name = props.name || '';

		const integrationModels = [];
		for(const ing of integrationArray) {
			integrationModels.push(new Integration(ing));
		}
		this.integrations = integrationModels;
	}

	hasIntegration(typeKey) {
		for(const ing of this.integrations) {
			if(ing.type && typeKey === ing.type.key) {
				return true;
			}
		}
		return false;
	}

	getIntegration(typeKey) {
		for(const ing of this.integrations) {
			if(ing.type && typeKey === ing.type.key) {
				return ing;
			}
		}
		return null;
	}

	get config() {
		if(!this.key) { return {}; }

		for(const ing of STORE_INTEGRATIONS_ALL) {
			if(ing.key === this.key) {	
				return ing;
			}
		}
		return {};
	}

	allowedInCountry(testCountry) {

		if(!testCountry) { return true; }

		const testKey = isVarString(testCountry) ? testCountry : testCountry.key;

		const allowedList = this.config.countriesAllowed || [];
		const excludedList = this.config.countriesExcluded || [];

		if(allowedList.length) {
			if(!allowedList.map(m => m.key).includes(testKey)) {
				// Return false if country IS NOT in allowed countries
				return false;
			}
		}

		if(excludedList.length) {
			if(excludedList.map(m => m.key).includes(testKey)) {
				// Return false if country IS in exluded countries
				return false;
			}
		}

		return true;
	}

	allowedByCountryArray(testCountries) {

		if(!testCountries || !testCountries.length) { return true; }

		let isAllowed = false;

		const allowedList = this.config.countriesAllowed || [];
		const excludedList = this.config.countriesExcluded || [];

		for(const testCountry of testCountries) {

			const testKey = isVarString(testCountry) ? testCountry : testCountry.key;

			if(allowedList.length) {
				if(allowedList.map(m => m.key).includes(testKey)) {
					// Return false if country IS NOT in allowed countries
					isAllowed = true;
				}
			}

			if(excludedList.length) {
				if(excludedList.map(m => m.key).includes(testKey)) {
					// Return false if country IS in exluded countries
					return false;
				}
			}
		}

		if(allowedList.length && isAllowed === false) {
			return false;
		}

		return true;
	}
}





















