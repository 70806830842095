import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import {
  CHECKOUT_BUYLIST_STEPS, 
  CHECKOUT_STEPS, 
} from '../../constants/checkout';
import { MKT_NOTICE_KEY_HEADER } from '../../constants/marketing';
import * as tx from '../../constants/strings';
import {
  URL_BUYLIST_CART, 
  URL_CART, 
} from '../../constants/urls';

import { beginCheckoutEvent } from '../../utils/analytics';

import CheckoutSidebar from './blocks/CheckoutSidebar';
import CheckoutStep from './blocks/CheckoutStep';

import './style/_checkout.scss';

import * as cartActionCreators from '../../actions/cart';
import * as checkoutActionCreators from '../../actions/checkout';
let allActionCreators = Object.assign({}, cartActionCreators, checkoutActionCreators);

export class Checkout extends Component {

  constructor(props) {
    super(props);

    this.DEFAULT_MIN_HEIGHT = 600;
    this.isBuylist = this.props.isBuylist ? true : false;

    this.initState = {
      bodyMinHeight: this.DEFAULT_MIN_HEIGHT,
    };
    this.state = this.initState;
  }

  componentDidMount() {
    if(this.isBuylist) {
      if(!this.props.cart.buylistMode) {
        this.props.setBuylistMode(true);
      }
      if(!this.props.cart || this.props.cart.currentBuylistCart.quantity === 0) {
        history.replace(URL_BUYLIST_CART);
      }
    } else {
      if(this.props.cart.buylistMode) {
        this.props.setBuylistMode(false);
      }
      if(!this.props.cart || this.props.cart.currentCart.quantity === 0) {
        history.replace(URL_CART);
      } else {
        // Fire begin_checkout analytics event page loads
        beginCheckoutEvent(this.props.cart.currentCart, {});
      }
    }
  }

  getCheckoutSteps() {
    return this.isBuylist ? CHECKOUT_BUYLIST_STEPS : CHECKOUT_STEPS;
  }

  setBodyMinHeight(heightInt) {
    this.setState({ bodyMinHeight: heightInt });
  }

  setStepData(stepConfig, dataObj) {
    this.props.checkoutSetStepData({
      key: stepConfig.key,
      data: dataObj, 
    });
  }

  headerNoticeOpen() {
    if(this.props.store.noticesDismissed.includes(MKT_NOTICE_KEY_HEADER)) { return null; }
    const allNotices = this.props.store.notices;
    for(const nt of allNotices) {
      if(nt.position === MKT_NOTICE_KEY_HEADER) {
        return true;
      }
    }
    return false;
  }

  render() {

    const {t} = this.props;

    return <div className={'Checkout'}>
      <div className='checkoutWrapper'>
        <div className={`checkoutSidebarWrapper ${this.headerNoticeOpen() ? 'notified' : ''}`}>
          <CheckoutSidebar
            isBuylist={this.isBuylist}
            setMinHeight={this.setBodyMinHeight.bind(this)} />
        </div>
        <div className='checkoutBody' style={{ minHeight: `${this.state.bodyMinHeight}px` }}>
          <div className='checkoutBodyInnerWrapper'>
            <div className='checkoutTitle'>{t(this.isBuylist ? tx.TX_CHECKOUT_BUYLIST_TITLE : tx.TX_CHECKOUT)}</div>
            {this.getCheckoutSteps().map((stepConfig, i) => {
              return <div className='checkoutStepWrapper' key={i}>
                <CheckoutStep 
                  config={stepConfig}
                  isBuylist={this.isBuylist}
                  setStepData={this.setStepData.bind(this)} />
              </div>;
            })}
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    store: state.store,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Checkout));