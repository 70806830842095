
import * as x from '../constants/actions';

import { Checkout } from '../models/checkout';


// stepData formatting
// { 
//    ...  
//    (key): {
//      data: (step data)
//    },
//    ...
// }


const initialState = {
  stepData: {},
  checkout: new Checkout({}),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_CHECKOUT_CLEAR_DATA:
      return checkoutClearData(state, action);
    case x.ACTION_CHECKOUT_SET_STEP_DATA:
      return checkoutSetStepData(state, action);
    default:
      return state;
  }
}

function checkoutSetStepData(state, action) {

  const existingData = action.payload.key && state.stepData[action.payload.key] ? state.stepData[action.payload.key] : {};
  const existingDataObj = action.payload.key && state.stepData[action.payload.key] && state.stepData[action.payload.key].data ? state.stepData[action.payload.key].data : {};
  const newData = {
    [action.payload.key]: Object.assign({}, existingData, {
      data: Object.assign({}, existingDataObj, action.payload.data),
    }),
  };

  return Object.assign({}, state, {
    stepData: Object.assign({}, state.stepData, newData),
    // checkout: ,
  });
}

function checkoutClearData(state, action) {
  return Object.assign({}, state, {
    stepData: {},
    checkout: new Checkout({}),
  });
}