import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { 
	ERR_5500,
	ERROR_PRODUCT_SET_MISSING, 
} from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';

import { ProductSet } from '../../../../models/products';

import { getNameError } from '../../../../utils/form-validation';
import { getStoreLanguage } from '../../../../utils/language';
import { dynamicSort } from '../../../../utils/sort';

import SearchableDropdown from '../../../Input/SearchableDropdown';

import * as productActionCreators from '../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class ProductModalChangeSet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    	inputSet: this.getInitialSet(),
      inputCustomSet: '', 

    	errorSet: '', 
      errorCustomSet: '', 

      requestError: null,
      requestPending: false,

      setData: null,
      setDataLoading: false,

      addSet: false,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getSetData();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
    if(!_.isEqual(prevState, this.state)) {
      this.props.didUpdate();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_SAVE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getInitialSet() {
    return this.props.product ? this.props.product.productSet : null;
  }

  async submitAction(evt) {
    if(evt) { evt.preventDefault(); }

    if((this.state.inputSet || this.state.addSet) && this.props.product) {

      this.props.allowConfirm(false);
      this.setState({
        requestPending: true,
        requestError: null,
      });

      let selectedSet = this.state.inputSet || null;

      if(this.state.addSet) {

        const setNameError = getNameError(this.state.inputCustomSet);
        if(setNameError) {
          this.setState({
            requestPending: false,
            requestError: setNameError,
          });
          this.props.completeAction();
          this.props.allowConfirm(true);
        }

        const newSet = new ProductSet({
          name: this.state.inputCustomSet,
        });

        const createSetResp = await this.props.productSetCreate(this.props.product.productLine, newSet.getApiData())
          .catch((errResp) => {
            this.setState({
              requestPending: false,
              requestError: errResp && errResp.error ? errResp.error : ERR_5500,
            });
            this.props.completeAction();
            this.props.allowConfirm(true);
          });

        if(!createSetResp) {
          return null;
        }

        selectedSet = createSetResp;
      }

      const attrReqArray = [];
      const setAttributes = selectedSet.getProductSetAttributes(this.props.product);

	    for(const attr of setAttributes) {
	      attrReqArray.push(this.props.productAttributeUpdate(this.props.product.productLine.permalink, this.props.product.permalink, attr.getApiData()));
	    }
	    const attrRespArray = await Promise.all(attrReqArray)
	      .catch((errResp) => {
	        this.setState({
	          requestPending: false,
	          requestError: errResp && errResp.error ? errResp.error : ERR_5500,
	        });
	        this.props.completeAction();
          this.props.allowConfirm(true);
	      });

	    if(!attrRespArray) {
	      return null;
	    }

      // Only close for a valid response, in this case, will just be: {}
      this.props.allowConfirm(true);
      this.props.makeClean(true);
      this.props.closeMethod();
      this.props.completeAction();

    } else {
    	this.setState({
    		requestError: this.props.t(ERROR_PRODUCT_SET_MISSING),
    	}, () => {
    		this.props.completeAction();
    	});
    }
  }

  async getSetData() {

    this.setState({
      setData: null,
      setDataLoading: true,
    });

    const setData = await this.props.productSetsByProductLine(this.props.product.productLine)
      .catch((errResp) => {
        console.error(errResp);
      });

    this.setState({
      setData: setData ? setData.data : null,
      setDataLoading: false,
    });
  }

  getSetOptions() {
    if(!this.state.setData) { return []; }

    const setResp = [];
    for(const st of this.state.setData) {
      setResp.push(st.toOption({ selfValue: true }))
    }
    return setResp.sort(dynamicSort('display'));
  }

  changeSet(val) {
    this.setState({
      inputSet: val,
    });
  }

  toggleAdd(initValue) {
    this.setState({ 
      addSet: !this.state.addSet, 
      inputCustomSet: initValue || '',
    }, () => {
      if(this.state.addSet === false) {
        this.getSetData();
      }
    });
  }

  changeCustomSet(evt) {
    this.setState({
      inputCustomSet: evt.target.value,
    }, () => {
      if(this.state.errorCustomSet) {
        this.validateCustomSet();
      }
    });
  }

  validateCustomSet() {
    this.setState({ errorCustomSet: getNameError(this.state.inputCustomSet) });
  }

  resetSet() {
    this.setState({
      inputSet: this.getInitialSet(),
      addSet: false,
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductModalChangeSet ProductModalView'}>
      <div className='pmvWrapper'>
        <form className={'changeSetForm productModalForm'} onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'pmFormError present' : 'pmFormError'}>{t(this.state.requestError)}</div>

          <div className='pmFieldWrapper'>
            <div className={'pmFieldLabel pmRequired'}>{t(tx.TX_SET)}</div>
            {!this.state.inputSet ?
              <>
                <div className='pmFieldAction' onClick={this.resetSet.bind(this)}>{t(tx.TX_RESET)}</div>
                {this.state.addSet ?
                  <div className='pmInputWrapper'>
                    <input 
                      type={'text'}
                      className={'pmInput'}
                      name={t(tx.TX_SET)}
                      value={this.state.inputCustomSet}
                      onChange={this.changeCustomSet.bind(this)}
                      onBlur={this.validateCustomSet.bind(this)}
                      placeholder={t(tx.TX_PLACEHOLDER_PRODUCT_SET)}
                      required={false} />
                    {this.state.errorCustomSet ?
                      <div className={'pmError FieldError'}>{t(this.state.errorCustomSet)}</div> :
                      null
                    }
                  </div> :
                  <div className='pmInputWrapper'>
                    <div className='pmDropdownWrapper'>
                      <SearchableDropdown 
                        className={'pmDropdownSelect'}
                        options={this.getSetOptions()}
                        selectOption={this.changeSet.bind(this)}
                        loading={this.state.setDataLoading}
                        name={t(tx.TX_SET)}
                        placeholder={t(tx.TX_PLACEHOLDER_PRODUCT_SET)}
                        required={false}
                        noTranslate={true}
                        adminTheme={true}
                        addAction={this.toggleAdd.bind(this)}
                        addLabel={tx.TX_INV_SETS_ADD_SET} />
                    </div>
                    {this.state.errorSet ?
                      <div className={'pmError FieldError'}>{t(this.state.errorSet)}</div> :
                      null
                    }
                  </div>
                }
              </> :
              <div className='selectedSet'>
                <div className='selectedSetLiner'>
                  <div className='setName'>{this.state.inputSet.name}</div>
                  <div className='setClear' onClick={() => this.changeSet(null)}>{t(tx.TX_CHANGE)}</div>
                </div>
              </div>
            }
          </div>
        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductModalChangeSet));
