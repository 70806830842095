import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { getMyId } from '../../utils/auth';
import { getCustomerFirstName, getCustomerLastName, getCustomerEmail } from '../../utils/customer';
import { formatServerError, getFullName } from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';

import * as tx from '../../constants/strings';
import {
  URL_ADMIN_SETTINGS, 
  URL_ADMIN_SETTINGS_ADMIN_USERS, 
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';
import Toggle from '../Input/Toggle';

import './style/_adminuseredit.scss';

import * as authActionCreators from '../../actions/auth';
import * as commonActionCreators from '../../actions/common';
import * as customerActionCreators from '../../actions/customer';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, commonActionCreators, customerActionCreators, userActionCreators);

export class AdminUsersEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,

      inputProducts: false,
      inputSales: false,
      inputUsers: false,
      inputEvents: false,
      inputPermissions: false,

      requestPending: true,
      requestError: null,
      responseObject: null,

      savePromptOpen: false, 
    };

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchCustomer();
  }

  componentWillUnmount() {
    this.unblock();

    if(this.controller) {
      this.controller.abort();
    }
  }

  fetchCustomer() {

    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      responseObject: null,
    });

    // Get customer details
    if(this.props.match.params.customerUUID) {
      this.props.customerFetchAdminSingle(this.props.match.params.customerUUID, controller.signal)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
          responseObject: resp,

          inputProducts: resp && resp.permissions && resp.permissions.manage_products ? resp.permissions.manage_products : false,
          inputSales: resp && resp.permissions && resp.permissions.manage_sales ? resp.permissions.manage_sales : false,
          inputUsers: resp && resp.permissions && resp.permissions.manage_users ? resp.permissions.manage_users : false,
          inputEvents: resp && resp.permissions && resp.permissions.manage_events ? resp.permissions.manage_events : false,
          inputPermissions: resp && resp.permissions && resp.permissions.manage_permissions ? resp.permissions.manage_permissions : false,

        });
      })
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responseObject: null,
        });
      });
    }
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }

    const permissionsData = {
      manage_products: this.state.inputProducts,
      manage_sales: this.state.inputSales,
      manage_users: this.state.inputUsers,
      manage_events: this.state.inputEvents,
      manage_permissions: this.state.inputPermissions,
    };

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.customerAdminPermissionsEdit(permissionsData, this.props.match.params.customerUUID)
    .then((resp) => {

      if(this.isThisMe()) {
        this.props.usersFetchMe()
        .then((resp) => {          
          this.setState({
            requestPending: false,
            requestError: null,
            isDirty: false, 
          }, () => {
            history.push(URL_ADMIN_SETTINGS_ADMIN_USERS);
          });
        })
        .catch((errResp) => {
          if(errResp) {
            console.error(errResp);
          }
        });
      } else {
        this.setState({
          requestPending: false,
          requestError: null,
          isDirty: false, 
        }, () => {
          history.push(URL_ADMIN_SETTINGS_ADMIN_USERS);
        });
      }
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  toggleProducts() {
    this.setState({ 
      inputProducts: !this.state.inputProducts, 
      isDirty: true, 
    });
  }

  toggleSales() {
    this.setState({ 
      inputSales: !this.state.inputSales, 
      isDirty: true, 
    });
  }

  toggleUsers() {
    this.setState({ 
      inputUsers: !this.state.inputUsers, 
      isDirty: true, 
    });
  }

  toggleEvents() {
    this.setState({ 
      inputEvents: !this.state.inputEvents, 
      isDirty: true, 
    });
  }

  togglePermissions() {
    if(this.state.inputPermissions === true && this.isThisMe() === true) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_NOT_ALLOWED, 
        alertCopy: tx.TX_SETTINGS_CANNOT_REMOVE_PERMISSION, 
      });
    } else {
      this.setState({ 
        inputPermissions: !this.state.inputPermissions, 
        isDirty: true, 
      });
    }
  }

  isThisMe() {
    return getMyId() && this.state.responseObject && this.state.responseObject.id && getMyId() === this.state.responseObject.id;
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {

    const {t} = this.props;

    return <div className={'AdminUsersEdit AdminPage'}>
      <AdminTitle
        title={tx.TX_SETTINGS_EDIT_PERMISSIONS}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
          {
            url: URL_ADMIN_SETTINGS_ADMIN_USERS,
            title: tx.TX_SETTINGS_ADMIN_USERS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className={'customerPermissionsForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_SETTINGS_ADMIN_PERMISSIONS)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

            <div className='adminUserLabelWrapper'>
              <div className='adminUserLabel'>{t(tx.TX_USER)}:</div>
              <div className='adminUserValue'>
                <div className='adminUserName'>{getFullName(getCustomerFirstName(this.state.responseObject), getCustomerLastName(this.state.responseObject), this.getLanguage())}</div>
                <div className='adminUserEmail'>{getCustomerEmail(this.state.responseObject)}</div>
              </div>
            </div>

            <div className='editPermissionWrapper'>
              <div className='editToggleWrapper'>
                <div className='editToggleInputWrapper'>
                  <Toggle
                    checked={this.state.inputProducts}
                    onToggle={this.toggleProducts.bind(this)}
                    bottomLabel={true}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
              <div className='editToggleLabelWraper'>
                <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_PRODUCTS)}</div>
                <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_PRODUCTS_DESC)}</div>
              </div>
            </div>

            <div className='editPermissionWrapper'>
              <div className='editToggleWrapper'>
                <div className='editToggleInputWrapper'>
                  <Toggle
                    checked={this.state.inputSales}
                    onToggle={this.toggleSales.bind(this)}
                    bottomLabel={true}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
              <div className='editToggleLabelWraper'>
                <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_SALES)}</div>
                <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_SALES_DESC)}</div>
              </div>
            </div>

            <div className='editPermissionWrapper'>
              <div className='editToggleWrapper'>
                <div className='editToggleInputWrapper'>
                  <Toggle
                    checked={this.state.inputUsers}
                    onToggle={this.toggleUsers.bind(this)}
                    bottomLabel={true}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
              <div className='editToggleLabelWraper'>
                <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_USERS)}</div>
                <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_USERS_DESC)}</div>
              </div>
            </div>

            <div className='editPermissionWrapper'>
              <div className='editToggleWrapper'>
                <div className='editToggleInputWrapper'>
                  <Toggle
                    checked={this.state.inputEvents}
                    onToggle={this.toggleEvents.bind(this)}
                    bottomLabel={true}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
              <div className='editToggleLabelWraper'>
                <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_EVENTS)}</div>
                <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_EVENTS_DESC)}</div>
              </div>
            </div>

            <div className='editPermissionWrapper'>
              <div className='editToggleWrapper'>
                <div className='editToggleInputWrapper'>
                  <Toggle
                    checked={this.state.inputPermissions}
                    onToggle={this.togglePermissions.bind(this)}
                    bottomLabel={true}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
              <div className='editToggleLabelWraper'>
                <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_PERMISSIONS)}</div>
                <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_PERMISSIONS_DESC)}</div>
              </div>
            </div>

            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_SETTINGS_ADMIN_USERS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminUsersEdit));