import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';
import {
  URL_ACCOUNT_ORDERS_SINGLE,
  URL_ACCOUNT_ORDERS_BUYLIST_SINGLE,
} from '../../../constants/urls';

import { 
  dateShort,
  formatPrice, 
  stringFormat, 
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import MiniCart from '../../Cart/MiniCart';

const allActionCreators = Object.assign({});

export class AccountOrdersLineItem extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getViewUrl() {
    return this.props.isBuylist ? URL_ACCOUNT_ORDERS_BUYLIST_SINGLE : URL_ACCOUNT_ORDERS_SINGLE;
  }

  render() {

    const {t} = this.props;

    if(!this.props.order) {
      return null;
    }

    return <div className={'AccountOrdersLineItem'}>
      <div className='aoLiner'>
        <div className='aoHeader'>
          <div className='aoHeaderLiner'>
            <div className={'headerBlock dateBlock'}>
              <div className='headerTitle'>{t(tx.TX_DATE)}</div>
              <div className='headerValue'>{dateShort(this.props.order.date, this.getLanguage())}</div>
            </div>
            <div className='headerBlock'>
              <div className='headerTitle'>{t(tx.TX_ORDER_ORDER_NUMBER)}</div>
              <div className='headerValue'>{this.props.order.displayNumber}</div>
            </div>
            {this.props.order.status ?
              <div className='headerBlock'>
                <div className='headerTitle'>{t(tx.TX_STATUS)}</div>
                <div className='headerValue'>{t(this.props.order.status.nameCustomer)}</div>
              </div> :
              null
            }
            <div className={'headerBlock fixed'}>
              <div className='headerTitle'>{t(tx.TX_TOTAL)}</div>
              <div className='headerValue'>{formatPrice(this.props.order.totalGrand)}</div>
            </div>
          </div>
        </div>
        <div className='aoSubheader'>
          <div className='aoSubheaderLiner'>
            <div className='subheaderDetails'>
              {this.props.order.hasShippingMethod() ?
                <div className='subheaderBlock'>
                  <div className='subheaderTitle'>{t(tx.TX_SHIPPING_METHOD)}</div>
                  <div className='subheaderValue'>{this.props.order.shippingMethod.name}</div>
                </div> :
                null
              }
              <div className='subheaderBlock'>
                <div className='subheaderTitle'>{t(this.props.isBuylist ? tx.TX_CHECKOUT_BUYLIST_RETURN_ADDRESS : tx.TX_SHIPPING_ADDRESS)}</div>
                <div 
                  className='subheaderValue'
                  dangerouslySetInnerHTML={{ __html: this.props.order.shippingAddress.format({ addTags: true }) }} />
              </div>
              {this.props.order.hasPaymentRecord() ?
                <div className='subheaderBlock'>
                  <div className='subheaderTitle'>{t(tx.TX_PAYMENT_METHOD)}</div>
                  <div 
                    className='subheaderValue'
                    dangerouslySetInnerHTML={{ __html: this.props.order.paymentRecord.format(t, { addTags: true, language: this.getLanguage() }) }} />
                </div> :
                null
              }
            </div>
            <div className='subheaderActions'>
              <Link to={stringFormat(this.getViewUrl(), { publicUuid: this.props.order.publicUuid })} className='actionElement'>
                <div className='FlexCenter'>{t(tx.TX_VIEW)}</div>
              </Link>
            </div>
          </div>
        </div>
        <div className='aoCartWrapper'>
          <div className='aoCartTitleWrapper'>
            <div className='aoCartTitleValue'>{t(tx.TX_ORDER_ORDER_ITEMS)}</div>
            {this.props.order ?
              <div className='aoCartTitleCount'>({this.props.order.orderCart.quantity === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: this.props.order.orderCart.quantity })})</div> :
              null
            }
          </div>
          {this.props.order && this.props.order.orderCart.lineItemCount > 3 ?
            <div className={'aoCart aoCartTruncated'}>
              <MiniCart
                isBuylist={this.isBuylist}
                order={this.props.order}
                cartOverride={this.props.order ? (this.props.isBuylist ? this.props.order.buylistCart : this.props.order.cart) : null}
                readOnly={true}
                truncateLines={3} />
              <div className='seeAllWrapper'>
                <Link to={stringFormat(this.getViewUrl(), { publicUuid: this.props.order.publicUuid })} className='seeAllButton'>{t(tx.TX_SEE_ALL)}</Link>
              </div>
            </div> :
            <div className='aoCart'>
              <MiniCart
                isBuylist={this.isBuylist}
                order={this.props.order}
                cartOverride={this.props.order ? (this.props.isBuylist ? this.props.order.buylistCart : this.props.order.cart) : null}
                readOnly={true} />
            </div>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountOrdersLineItem));