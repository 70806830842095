import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { history } from '../../../../store';

import { ERR_5500 } from '../../../../constants/errors';
import { 
  ICON_CART,
  ICON_USER,
} from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';
import { URL_ADMIN_ORDERS_ORDER_VIEW } from '../../../../constants/urls';

import { Order } from '../../../../models/orders';

import { 
  dateShort, 
  formatServerError,
  stringFormat,
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import Dropdown from '../../../Input/Dropdown';
import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';
import MiniCart from '../../../Cart/MiniCart';

import '../../style/_vieworder.scss';

import * as cartActionCreators from '../../../../actions/cart';
import * as checkoutActionCreators from '../../../../actions/checkout';
const allActionCreators = Object.assign({}, cartActionCreators, checkoutActionCreators);

export class CartModalCreateOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestPending: false,
      requestError: null,

      paymentMethods: null,
      paymentMethodsLoading: false,

      selectedMethod: '', 
    };

    this.controller = null;
  }

  componentDidMount() {
    this.props.allowConfirm(false);
    if(!this.getStatusError()) {
      this.fetchPaymentMethods();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
    if(!_.isEqual(prevState, this.state)) {
      this.props.didUpdate();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_CHECKOUT_PLACE_ORDER;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async fetchPaymentMethods() {

    this.setState({
      paymentMethods: null,
      paymentMethodsLoading: true,
    });

    const paymentMethods = await this.props.checkoutFetchBuylistPaymentMethods()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
        this.setState({ paymentMethodsLoading: false, });
      });

    this.setState({
      paymentMethods: paymentMethods || [],
      paymentMethodsLoading: false,
    });
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }

    if(this.props.cart && !this.getStatusError() && this.state.selectedMethod) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      let checkoutResp = null;
      if(this.props.cart.isBuylist) {

        const orderData = {
          user_uuid: this.props.cart.user.publicUuid,
          payment_method_uuid: this.state.selectedMethod,
          cart_uuid: this.props.cart.publicUuid,
          status: Order.initialBuylistStatus,
        };

        checkoutResp = await this.props.checkoutAdminPlaceBuylistOrder(orderData)
          .catch((errResp) => {
            console.error(errResp);
            this.setState({ 
              requestPending: false,
              requestError: formatServerError(errResp),
            });
            this.props.completeAction();
            this.props.allowConfirm(true);
          });
      } else {
         // Nothing here yet, intentionally
      }

      if(checkoutResp && checkoutResp.public_uuid) { 
        // Only close for a valid response, in this case, will just be order uuid
        this.props.allowConfirm(true);
        this.props.makeClean(true);
        this.props.closeMethod();
        this.props.completeAction();

        history.push(stringFormat(URL_ADMIN_ORDERS_ORDER_VIEW, { publicUuid: checkoutResp.public_uuid }));
      }

    } else {
      this.props.completeAction();
    }
  }

  isEmptyCart() {
    if(!this.props.cart) {
      return true;
    }
    if(!this.props.cart.items || this.props.cart.items.length === 0) {
      return true;
    }
    return false;
  }

  getStatusError() {
    if(!this.props.cart) {
      // This should never happen, so we just display general error
      return ERR_5500;
    } else if(this.props.cart.order) {
      return tx.TX_CART_ALREADY_ORDER_ASSOCIATED;
    } else if(!this.props.cart.user) {
      return tx.TX_CART_USER_REQUIRED_FOR_ORDER;
    } else if(this.isEmptyCart()) {
      return tx.TX_CART_EMPTY_CART;
    }
    return '';
  }

  getPaymentMethods() {
    const options = [];

    if(this.state.paymentMethodsLoading || !this.state.paymentMethods) {
      options.push({
        display: tx.TX_LOADING,
        value: '',
      });
    } else {
      options.push({
        display: tx.TX_PLACEHOLDER_PAYMENT_METHOD,
        value: '',
      });

      for(const pm of this.state.paymentMethods) {
        options.push({
          display: pm.nameBuylist || pm.name,
          value: pm.publicUuid,
        });
      }
    }
    return options;
  }

  selectPaymentMethod(evt) {
    this.setState({ selectedMethod: evt.target.value }, () => {
      this.props.allowConfirm(!!this.state.selectedMethod);
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'CartModalCreateOrder CartModalView'}>
      <div className='cmvWrapper'>
        <form 
          className={'createOrderForm cartModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'cmFormError present' : 'cmFormError'}>{t(this.state.requestError)}</div>

            <div className='cartHeaderSection'>
              <div className='cartUserWrapper'>
                <div className='cartUserIconWrapper'>
                  <div className='cartUserIcon'>
                    <Icon value={ICON_USER} />
                  </div>
                </div>
                <div className='cartUserBodyWrapper'>
                  {this.props.cart && this.props.cart.user ?
                    <>
                      <div className='userNameWrapper'>
                        <div className='userNameValue'>{this.props.cart.user.fullName(this.getLanguage())}</div>
                      </div>
                      <div className='userEmailWrapper'>{this.props.cart.user.email}</div>
                    </> :
                    <div className='userNameWrapper'>
                      <div className='userGuestValue'>{t(tx.TX_GUEST_USER)}</div>
                    </div>
                  }
                </div>
              </div>
              <div className='cartStatusWrapper'>
                <div className='cartStatusIconWrapper'>
                  <div className='cartStatusIcon'>
                    <Icon value={ICON_CART} />
                  </div>
                </div>
                <div className='cartStatusBodyWrapper'>
                  <div className='cartLoadedWrapper'>
                    {this.isEmptyCart() ?
                      <div className='emptyCartWrapper'>
                        <div className='emptyCartLabel'>{t(tx.TX_CART_EMPTY_CART)}</div>
                      </div> :
                      <div className='activeCartWrapper'>
                        <div className='activeCartStatus'>
                          <div className='statusLabel'>{t(this.props.cart.isBuylist ? tx.TX_BUYLIST_CART : tx.TX_CART_SHOPPING_CART)}</div>
                          <div className='statusValue'>{t(this.props.cart.status)}</div>
                        </div>
                        <div className='activeCartUpdate'>
                          <div className='updateLabel'>{t(tx.TX_CART_LAST_UPDATE)}</div>
                          <div className='updateValue'>{dateShort(this.props.cart.lastUpdate, this.getLanguage())}</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>   
              </div>
            </div>
            {this.getStatusError() ?
              <div className='cartStatusError'>
                <div className='cartStatusValue'>{t(this.getStatusError())}</div>
              </div> :
              <div className='cartStatusValid'>
                <div className='cartOrderMinicartWrapper'>
                  <div className='cartOrderMinicartTitleWrapper'>
                    <div className='titleValue'>{t(tx.TX_CART_CART_ITEMS)}</div>
                    <div className='titleCount'>({this.props.cart.quantity === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: this.props.cart.quantity })})</div>
                  </div>
                  <div className='cartOrderMinicartBodyWrapper'>
                    <div className='cartOrderMinicartLiner'>
                      <MiniCart
                        isBuylist={this.props.cart.isBuylist}
                        order={null}
                        cartOverride={this.props.cart}
                        readOnly={true} />
                    </div>
                  </div>
                </div>
                <div className='cartPaymentMethodWrapper'>
                  <div className='pmLabel'>{t(tx.TX_SETTINGS_PAYMENT_METHODS)}</div>
                  {this.state.paymentMethodsPending ?
                    <div className='pmLoadingWrapper'>
                      <div className='pmIconWrapper'>
                        <LoadingIcon />
                      </div>
                      <div className='pmCopyWrapper'>
                        <div className={'pmCopyLiner FlexLeft'}>{t(tx.TX_LOADING)}</div>
                      </div>
                    </div> :
                    <div className='pmLoadedWrapper'>
                      <div className='pmDropdownWrapper'>
                        <Dropdown 
                          className={'pmDropdown'}
                          options={this.getPaymentMethods()}
                          name={t(tx.TX_PAYMENT_METHOD)}
                          value={this.state.selectedMethod}
                          noTranslate={false}
                          required={true}
                          onChange={this.selectPaymentMethod.bind(this)} />
                      </div>
                    </div>
                  }
                </div>
              </div>
            }

        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CartModalCreateOrder));
