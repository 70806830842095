import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as tx from '../../constants/strings';
import { 
  URL_BUYLIST_CHECKOUT,
  URL_CHECKOUT, 
} from '../../constants/urls';

import { viewCartEvent } from '../../utils/analytics';
import { getCurrencySymbol } from '../../utils/currency';
import { formatPrice } from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';

import LoadingIcon from '../Icons/LoadingIcon';
import MiniCart from './MiniCart';

import './style/_cart.scss';

import * as cartActionCreators from '../../actions/cart';
let allActionCreators = Object.assign({}, cartActionCreators);

export class Cart extends Component {

  constructor(props) {
    super(props);

    this.isBuylist = this.props.isBuylist ? true : false;

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.props.setBuylistMode(this.isBuylist);

    // Fire view_cart analytics event when slider opens
    if(!this.props.cart.buylistMode) {
      viewCartEvent(this.getCart(), {});
    }
  }

  getCart() {
    return this.isBuylist ? this.props.cart.currentBuylistCart : this.props.cart.currentCart;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  setLoading(isLoading) {
    this.setState({ loading: isLoading });
  }

  render() {

    const {t} = this.props;
    const itemCount = this.getCart() ? this.getCart().quantity : 0;

    return <div className={'Cart'}>
      <div className='cartLiner'>
        <div className='cartBody'>
          <div className='cartTitle'>{t(this.isBuylist ? tx.TX_BUYLIST_TITLE : tx.TX_CART_SHOPPING_CART)}</div>
          <div className='cartBodyWrapper'>
            <MiniCart
              isBuylist={this.isBuylist}
              setParentLoading={this.setLoading.bind(this)} />
          </div>
          <div className='cartFooter'>
            <div className='cartFooterLiner'>
              <div className='cartTotalLine'>
                <div className='cartTotalLabelWrapper'>
                  <div className='cartTotalLabel'>{t(tx.TX_SUBTOTAL)}</div>
                  <div className='cartTotalCount'>({itemCount === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: itemCount })})</div>
                </div>
                <div className='cartTotalPriceWrapper'>
                  <div className='cartTotalPrice'>
                    <div className='cartCurrencySymbol'>{getCurrencySymbol()}</div>
                    <div className='cartPriceValue'>{formatPrice(this.getCart().subTotal, { language: this.getLanguage(), omitSymbol: true })}</div>
                  </div>
                </div>
              </div>
              <Link to={this.isBuylist ? URL_BUYLIST_CHECKOUT : URL_CHECKOUT}>
                <div className='cartCheckoutAction'>
                  <div className={'cartCheckoutActionLabel FlexCenter'}>{t(tx.TX_CART_CHECKOUT_BUTTON)}</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className='cartLoadingWrapper' style={{ display: this.state.loading ? 'block' : 'none' }}>
          <div className='cartIconWrapper'>
            <LoadingIcon />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Cart));