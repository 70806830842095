
import * as x from '../constants/actions';

import { Store } from '../models/stores';

const initialState = {
  user: null,
  userPending: false,
  store: Store.getLocal(),
  storePending: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_USER_SET:
      return userSet(state, action);
    case x.ACTION_USER_SET_PENDING:
      return userSetPending(state, action);
    case x.ACTION_USER_SET_STORE:
      return userSetStore(state, action);
    case x.ACTION_USER_STORE_FETCH_PENDING:
      return userSetStorePending(state, action);
    default:
      return state;
  }
}

function userSet(state, action) {
  return Object.assign({}, state, {
    user: action.payload,
  });
}

function userSetPending(state, action) {
  return Object.assign({}, state, {
    userPending: action.payload,
  });
}

function userSetStore(state, action) {
  action.payload.saveLocal();
  return Object.assign({}, state, {
    store: action.payload,
  })
}

function userSetStorePending(state, action) {
  return Object.assign({}, state, {
    storePending: action.payload,
  })
}