export const loadGoogleAnalytics = (trackingId) => {
  
  // Loads Google Analytics
  if(!trackingId) { return null; }

  const id = `google-analytics-js-${trackingId}`;
  const src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    
  // Prevent script from loading twice;
  if(document.getElementById(id)) { 
    return null; 
  }

  // Create script to load analytics
  const js = document.createElement('script');
  js.id = id;
  js.src = src;
  js.async = true;
  document.head.appendChild(js);

  // Initialize the dataLayer and gtag function
  window.dataLayer = window.dataLayer || [];
  window.gtag = function() {
    window.dataLayer.push(arguments);
  }

  // Initialize Google Analytics
  window.gtag('js', new Date());
  window.gtag('config', trackingId);
}