

import { 
	safeParseFloat, 
	safeParseInt, 
} from './general';



export function dynamicSort(property, descOrder = false, dataType = null) {
	
	// dataType values: 'float', 'int'

	const sortOrder = descOrder ? -1 : 1;

	function castElem(elemValue) {
		try {
			switch(dataType) {
				case 'int':
					return safeParseInt(elemValue);
				case 'float':
					return safeParseFloat(elemValue);
				default:
					return elemValue;
			}
		} catch(err) {
			return '';
		}
	}

	return function (a,b) {
		a[property] = a[property] === null ? '' : castElem(a[property]);
		b[property] = b[property] === null ? '' : castElem(b[property]);
		let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
		if (typeof a[property] === 'string' && typeof b[property] === 'string') {
			result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
		}
	  return result * sortOrder;
	}
}