import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_EMAIL, 
  ICON_HOURS, 
  ICON_LOCATION, 
  ICON_PHONE, 
} from '../../constants/icons';
import * as tx from '../../constants/strings';

import { formatPhone } from '../../utils/formatting';

import { Icon } from '../Icons/Icon';
import StoreHours from '../Store/StoreHours';

import './style/_contact.scss';

import * as userActionCreators from '../../actions/user';
const allActionCreators = Object.assign({}, userActionCreators);

export class ContactUs extends Component {

  componentDidMount() {

    // Get store data
    if(!this.props.user.store || !this.props.user.store.publicUuid) {
      // Get store data
      this.props.userFetchStore()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className='ContactUs'>
      <div className='contactHeader'>
        <div className='titleWrapper'>
          <div className='pageTitle'>
            <div className='pageTitleCopy'>{t(tx.TX_CONTACT_US)}</div>
          </div>
        </div>
      </div>
      <div className='contactBody'>

        <div className='contactDigital'>
          <div className='contactBlockWrapper'>
            <a href={`mailto:${this.props.user.store.email}`}>
              <div className='contactBlock'>
                <div className='contactBlockBackground'></div>
                <div className='contactIconWrapper'>
                  <div className='contactIcon'>
                    <Icon value={ICON_EMAIL} iconClass={'icon'} />
                  </div>
                </div>
                <div className='contactValueWrapper'>
                  <div className='contactValue'>{this.props.user.store.email}</div>
                </div>
              </div>
            </a>
          </div>
          <div className='contactBlockWrapper'>
            <a href={`tel:${this.props.user.store.phone}`}>
              <div className='contactBlock'>
                <div className='contactBlockBackground'></div>
                <div className='contactIconWrapper'>
                  <div className='contactIcon'>
                    <Icon value={ICON_PHONE} iconClass={'icon'} />
                  </div>
                </div>
                <div className='contactValueWrapper'>
                  <div className='contactValue'>{formatPhone(this.props.user.store.phone, this.props.user.store.countryCode)}</div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className='contactPhysical'>
          <div className='contactPhysicalTitle'>{t(tx.TX_ABOUT_US_VISIT_STORE)}</div>
          <div className='contactPhysicalBody'>
            <div className='cpBodyLiner'>
              <div className='cpInfoWrapper'>
                <div className='cpInfo'>
                  <div className='addressBlock'>
                    <a href={this.props.user.store.mapsLink()} className='addressLink' target='_blank' rel='noreferrer'>
                      <div className='addressIconWrapper'>
                        <Icon value={ICON_LOCATION} iconClass='addressIcon' />
                      </div>
                      <div className='addressTextWrapper'>
                        {this.props.user.store.address.format().map((line, j) => {
                          return <div key={j} className='addressLine'>{line}</div>;
                        })}
                      </div>
                    </a>
                  </div>
                  {StoreHours.WrappedComponent.hasHours && StoreHours.WrappedComponent.hasHours() ?
                    <div className='hoursBlock'>
                      <div className='hoursIconWrapper'>
                        <Icon value={ICON_HOURS} iconClass='hoursIcon' />
                      </div>
                      <div className='hoursTextWrapper'>
                        <div className='hoursTitle'>{t(tx.TX_STORE_HOURS)}</div>
                        <StoreHours />
                      </div>
                    </div> :
                    null
                  }
                </div>
              </div>
              <div className='cpMapWrapper'>
                <iframe
                  className='mapIframe'
                  title={t(tx.TX_STORE)}
                  frameBorder='0' 
                  style={{border: '0'}}
                  referrerPolicy='no-referrer-when-downgrade'
                  src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&q=place_id:${encodeURIComponent(this.props.user.store.address.placeId)}`}
                  allowFullScreen>
                </iframe>
              </div>
            </div>
          </div>
        </div>

        <div className='contactForm'></div>

      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ContactUs));