import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERROR_PRODUCT_PLEASE_SELECT } from '../../../constants/errors';
import { GALLERY_CONFIG_ADD_PRODUCT } from '../../../constants/gallery';
import { ICON_SEARCH } from '../../../constants/icons';
import { LANG_DEFAULT_CODE } from '../../../constants/languages';
import {
  PL_PERMALINK_LORCANA,
  PL_PERMALINK_MAGIC, 
  PL_PERMALINK_POKEMON, 
  PL_PERMALINK_STARWARS,
} from '../../../constants/product';
import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../../constants/urls';

import { addHighlightTags } from '../../../utils/formatting';
import { 
  getGalleryDefaultPageSize, 
  getGalleryDefaultSearchKey, 
} from '../../../utils/gallery';
import { 
  getPageLimit, 
  getPageOffset,  
} from '../../../utils/request';

import Gallery from '../../Gallery/Gallery';
import Icon from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';

import * as productActionCreators from '../../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class AddProductDetailsSyncedSearch extends Component {

  constructor(props) {
    super(props);

    this.initState = {

      inputSearch: '',
      inputLanguage: this.getDefaultLanguage(),

      autocompleteActive: false,
      autocompletePending: false,
      autocompleteResults: [],

      lastAutocomplete: '',
      lastSearch: '',

      searchFilters: {},
      searchPending: false,
      searchResults: [],
      searchCount: 0,

      hasSearched: false, 
      
      inputObj: '', 
      errorSelect: '',
    }

    this.state = this.initState;

    this.autocompleteBody = React.createRef();
    this.checkClick = this.checkClick.bind(this);

    this.controllerAutocomplete = null;
    this.controllerSearch = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    // Reset state if users changes product line
    if(prevProps && prevProps.productLine && prevProps.productLine.id && prevProps.productLine.id !== this.props.productLine.id) {
      this.setState(this.initState);
    }

    if(prevProps.validationSignal !== this.props.validationSignal) {
      this.validateAll();
    }
  }

  componentWillUnmount() {
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    if(this.controllerSearch) {
      this.controllerSearch.abort();
    }
    document.removeEventListener('click', this.checkClick, false);
  }

  checkClick(evt) {
    // Removed; uncomment to close when clicked outside of modal
    if(this.state.autocompleteActive && this.autocompleteBody) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.autocompleteBody.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeAutocomplete();
    }  
  }

  getDefaultLanguage() {

    if(!this.props.productLine) { return ''; }

    const languages = this.props.productLine.languages;

    if(languages.length === 1) {
      return languages[0];
    }

    for(const lang of languages) {
      if(lang.code === LANG_DEFAULT_CODE) {
        return lang;
      }
    }
    return '';
  }

  moveToNext(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {
      this.props.moveNext();
    }
  }

  validateAll() {

    if(this.props.productObj && this.props.productObj.foreignModel) {
      return true;
    }

    if(!this.state.inputObj) {
      this.setState({ errorSelect: ERROR_PRODUCT_PLEASE_SELECT });
      return false;
    }
    return true;
  }

  async fetchAutocompleteMagic(searchTerm) {

    if(!searchTerm || searchTerm.length < 3) {
      this.setState({ autocompleteActive: false });
      return null;
    }

    // Async call to autocomplete magic cards
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerAutocomplete = new AbortController();
    this.controllerAutocomplete = controllerAutocomplete;

    const searchVal = searchTerm.trim();
    
    const searchResp = await this.props.productMagicNameAutocomplete(searchVal, this.state.inputLanguage, controllerAutocomplete.signal)
      .catch((errResp) => {
        // Do nothing
      });

    if(!searchResp) { return null; }

    this.setState({ 
      autocompleteResults: searchResp.data, 
      autocompleteActive: true, 
      lastAutocomplete: searchVal,
    });
  }

  async fetchAutocompletePokemon(searchTerm) {

    if(!searchTerm || searchTerm.length < 3) {
      this.setState({ autocompleteActive: false });
      return null;
    }

    // Async call to autocomplete magic cards
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerAutocomplete = new AbortController();
    this.controllerAutocomplete = controllerAutocomplete;

    const searchVal = searchTerm.trim();
    
    const searchResp = await this.props.productPokemonNameAutocomplete(searchVal, this.state.inputLanguage, controllerAutocomplete.signal)
      .catch((errResp) => {
        // Do nothing
      });

    if(!searchResp) { return null; }

    this.setState({ 
      autocompleteResults: searchResp.data, 
      autocompleteActive: true, 
      lastAutocomplete: searchVal,
    });
  }

  async fetchAutocompleteLorcana(searchTerm) {

    if(!searchTerm || searchTerm.length < 3) {
      this.setState({ autocompleteActive: false });
      return null;
    }

    // Async call to autocomplete magic cards
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerAutocomplete = new AbortController();
    this.controllerAutocomplete = controllerAutocomplete;

    const searchVal = searchTerm.trim();
    
    const searchResp = await this.props.productLorcanaNameAutocomplete(searchVal, this.state.inputLanguage, controllerAutocomplete.signal)
      .catch((errResp) => {
        // Do nothing
      });

    if(!searchResp) { return null; }

    this.setState({ 
      autocompleteResults: searchResp.data, 
      autocompleteActive: true, 
      lastAutocomplete: searchVal,
    });
  }

  async fetchAutocompleteStarwars(searchTerm) {

    if(!searchTerm || searchTerm.length < 3) {
      this.setState({ autocompleteActive: false });
      return null;
    }

    // Async call to autocomplete magic cards
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerAutocomplete = new AbortController();
    this.controllerAutocomplete = controllerAutocomplete;

    const searchVal = searchTerm.trim();
    
    const searchResp = await this.props.productStarwarsNameAutocomplete(searchVal, this.state.inputLanguage, controllerAutocomplete.signal)
      .catch((errResp) => {
        // Do nothing
      });

    if(!searchResp) { return null; }

    this.setState({ 
      autocompleteResults: searchResp.data, 
      autocompleteActive: true, 
      lastAutocomplete: searchVal,
    });
  }

  getFirstPage(searchTerm) {
    const searchParams = {
      name: encodeURIComponent(searchTerm.trim()), 
    };
    this.fetchSearch(searchParams);
  }

  fetchSearch(filters, pageNum = 1, pageSize = getGalleryDefaultPageSize(GALLERY_CONFIG_ADD_PRODUCT)) {
    switch(this.props.productLine.permalink) {
      case PL_PERMALINK_LORCANA:
        this.fetchSearchLorcana(filters, pageNum, pageSize);
        break;
      case PL_PERMALINK_MAGIC:
        this.fetchSearchMagic(filters, pageNum, pageSize);
        break;
      case PL_PERMALINK_POKEMON:
        this.fetchSearchPokemon(filters, pageNum, pageSize);
        break;
      case PL_PERMALINK_STARWARS:
        this.fetchSearchStarwars(filters, pageNum, pageSize);
        break;
      default:
        break;
    }
  }

  async fetchSearchMagic(filters, pageNum = 1, pageSize = getGalleryDefaultPageSize(GALLERY_CONFIG_ADD_PRODUCT)) {

    // Async call to autocomplete magic cards
    if(this.controllerSearch) {
      this.controllerSearch.abort();
    }
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerSearch = new AbortController();
    this.controllerSearch = controllerSearch;

    this.setState({ 
      searchPending: true, 
      autocompleteActive: false, 
    });

    const paginationData = {
      limit: getPageLimit(pageNum, pageSize),
      offset: getPageOffset(pageNum, pageSize),
    }

    const combinedFilters = Object.assign({}, this.state.searchFilters, filters, paginationData);

    if(!combinedFilters.sort) {
      combinedFilters.sort = getGalleryDefaultSearchKey(GALLERY_CONFIG_ADD_PRODUCT);
    }
    if(this.state.inputLanguage && this.state.inputLanguage.code) {
      combinedFilters.language = this.state.inputLanguage.scryfallCode ? this.state.inputLanguage.scryfallCode : this.state.inputLanguage.code;
    }

    // Save filters
    this.setState({ searchFilters: combinedFilters });

    const searchResp = await this.props.productMagicSearchProducts(combinedFilters, controllerSearch.signal)
      .catch((errResp) => {
        this.setState({
          autocompleteResults: [],
          searchPending: false, 
          searchResults: [],
          searchCount: 0,
          hasSearched: true, 
        });
      });

    if(!searchResp) { return null; }

    this.setState({
      autocompleteResults: [],
      searchPending: false, 
      searchResults: searchResp.data,
      searchCount: searchResp.count,
      lastSearch: combinedFilters.name,
      hasSearched: true, 
    });
  }

  async fetchSearchPokemon(filters, pageNum = 1, pageSize = getGalleryDefaultPageSize(GALLERY_CONFIG_ADD_PRODUCT)) {

    // Async call to autocomplete magic cards
    if(this.controllerSearch) {
      this.controllerSearch.abort();
    }
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerSearch = new AbortController();
    this.controllerSearch = controllerSearch;

    this.setState({ 
      searchPending: true, 
      autocompleteActive: false, 
    });

    const paginationData = {
      limit: getPageLimit(pageNum, pageSize),
      offset: getPageOffset(pageNum, pageSize),
    }

    const combinedFilters = Object.assign({}, this.state.searchFilters, filters, paginationData);

    if(!combinedFilters.sort) {
      combinedFilters.sort = getGalleryDefaultSearchKey(GALLERY_CONFIG_ADD_PRODUCT);
    }
    if(this.state.inputLanguage && this.state.inputLanguage.code) {
      combinedFilters.language = this.state.inputLanguage.code;
    }

    // Save filters
    this.setState({ searchFilters: combinedFilters });

    const searchResp = await this.props.productPokemonSearchProducts(combinedFilters, controllerSearch.signal)
      .catch((errResp) => {
        this.setState({
          autocompleteResults: [],
          searchPending: false, 
          searchResults: [],
          searchCount: 0,
          hasSearched: true, 
        });
      });

    if(!searchResp) { return null; }

    this.setState({
      autocompleteResults: [],
      searchPending: false, 
      searchResults: searchResp.data,
      searchCount: searchResp.count,
      lastSearch: combinedFilters.name,
      hasSearched: true, 
    });
  }

  async fetchSearchLorcana(filters, pageNum = 1, pageSize = getGalleryDefaultPageSize(GALLERY_CONFIG_ADD_PRODUCT)) {

    // Async call to autocomplete magic cards
    if(this.controllerSearch) {
      this.controllerSearch.abort();
    }
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerSearch = new AbortController();
    this.controllerSearch = controllerSearch;

    this.setState({ 
      searchPending: true, 
      autocompleteActive: false, 
    });

    const paginationData = {
      limit: getPageLimit(pageNum, pageSize),
      offset: getPageOffset(pageNum, pageSize),
    }

    const combinedFilters = Object.assign({}, this.state.searchFilters, filters, paginationData);

    if(!combinedFilters.sort) {
      combinedFilters.sort = getGalleryDefaultSearchKey(GALLERY_CONFIG_ADD_PRODUCT);
    }
    if(this.state.inputLanguage && this.state.inputLanguage.code) {
      combinedFilters.language = this.state.inputLanguage.code;
    }

    // Save filters
    this.setState({ searchFilters: combinedFilters });

    const searchResp = await this.props.productLorcanaSearchProducts(combinedFilters, controllerSearch.signal)
      .catch((errResp) => {
        this.setState({
          autocompleteResults: [],
          searchPending: false, 
          searchResults: [],
          searchCount: 0,
          hasSearched: true, 
        });
      });

    if(!searchResp) { return null; }

    this.setState({
      autocompleteResults: [],
      searchPending: false, 
      searchResults: searchResp.data,
      searchCount: searchResp.count,
      lastSearch: combinedFilters.name,
      hasSearched: true, 
    });
  }

  async fetchSearchStarwars(filters, pageNum = 1, pageSize = getGalleryDefaultPageSize(GALLERY_CONFIG_ADD_PRODUCT)) {

    // Async call to autocomplete magic cards
    if(this.controllerSearch) {
      this.controllerSearch.abort();
    }
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerSearch = new AbortController();
    this.controllerSearch = controllerSearch;

    this.setState({ 
      searchPending: true, 
      autocompleteActive: false, 
    });

    const paginationData = {
      limit: getPageLimit(pageNum, pageSize),
      offset: getPageOffset(pageNum, pageSize),
    }

    const combinedFilters = Object.assign({}, this.state.searchFilters, filters, paginationData);

    if(!combinedFilters.sort) {
      combinedFilters.sort = getGalleryDefaultSearchKey(GALLERY_CONFIG_ADD_PRODUCT);
    }
    if(this.state.inputLanguage && this.state.inputLanguage.code) {
      combinedFilters.language = this.state.inputLanguage.code;
    }

    // Save filters
    this.setState({ searchFilters: combinedFilters });

    const searchResp = await this.props.productStarwarsSearchProducts(combinedFilters, controllerSearch.signal)
      .catch((errResp) => {
        this.setState({
          autocompleteResults: [],
          searchPending: false, 
          searchResults: [],
          searchCount: 0,
          hasSearched: true, 
        });
      });

    if(!searchResp) { return null; }

    this.setState({
      autocompleteResults: [],
      searchPending: false, 
      searchResults: searchResp.data,
      searchCount: searchResp.count,
      lastSearch: combinedFilters.name,
      hasSearched: true, 
    });
  }

  changeSearch(evt) {
    this.setState({ 
      inputSearch: evt.target.value, 
    }, () => {
      this.fetchAutocomplete();
    });
  }

  fetchAutocomplete() {

    switch(this.props.productLine.permalink) {
      case PL_PERMALINK_LORCANA:
        this.fetchAutocompleteLorcana(this.state.inputSearch);
        break;
      case PL_PERMALINK_MAGIC:
        this.fetchAutocompleteMagic(this.state.inputSearch);
        break;
      case PL_PERMALINK_POKEMON:
        this.fetchAutocompletePokemon(this.state.inputSearch);
        break;
      case PL_PERMALINK_STARWARS:
        this.fetchAutocompleteStarwars(this.state.inputSearch);
        break;
      default:
        break;
    }
  }

  changeLanguage(evt) {

    if(evt.target.value === '') {
      this.setState({ inputLanguage: evt.target.value }, () => {
        this.fetchAutocomplete();
      });
      return;
    }

    for(const ln of this.props.productLine.languages) {
      if(ln.code === evt.target.value) {
        this.setState({ inputLanguage: ln }, () => {
          this.fetchAutocomplete();
        });
        break;
      }
    }
  }

  getAutocompleteResults() {

    let deduped = [];
    for(const op of this.state.autocompleteResults) {
      if(!deduped.includes(op)) {
        deduped.push(op);
      }
    }
    return deduped;
  }

  selectSuggestion(suggestString) {
    this.setState({
      inputSearch: suggestString,
    }, () => {
      this.getFirstPage(this.state.inputSearch);
    });
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.getFirstPage(this.state.inputSearch);
    }
  }

  getHighlightedAutocomplete(suggestString) {
    return addHighlightTags(suggestString, this.state.lastAutocomplete, 'suggestStrong');
  }

  closeAutocomplete() {
    this.setState({ autocompleteActive: false });
  }

  selectThumbnail(elemObj) {

    let productObj = elemObj.createProduct(this.props.productLine);

    if(productObj) {
      this.setState({ 
        inputObj: productObj, 
        errorSelect: '', 
      }, () => {
        this.syncProduct(productObj);
        this.props.makeDirty();
        this.moveToNext();
      });
    }
  }

  syncProduct(productObj) {
    this.props.setProduct(productObj);
    this.props.setInventory(null);
  }

  render() {

    const {t} = this.props;

    return <div className={'AddProductDetailsSyncedSearch'}>
      <form onSubmit={this.moveToNext.bind(this)}>
        
        {this.props.productLine && this.props.productLine.languages && this.props.productLine.languages.length > 1 ?
          <div className={'adminFieldWrapper'}>
            <div className={'adminFieldLabel'}>{t(tx.TX_LANGUAGE)}</div>
            <div className='adminInputWrapper'>
              <div className='adminDropdown'>
                <select
                  value={this.state.inputLanguage ? this.state.inputLanguage.code : ''}
                  onChange={this.changeLanguage.bind(this)}>
                  <option value=''>{t(tx.TX_INV_ADD_PRODUCT_ANY_LANGUAGE)}</option>
                  {this.props.productLine.languages.map((lang, j) => {
                    return <option key={j} value={lang.code}>{t(lang.nameTranslation)}</option>
                  })}
                </select>
                <div className='aiDropdownArrowWrapper'>
                  <div className='aiArrow'></div>
                </div>
              </div>
            </div>
          </div> :
          null
        }  

        <div className='adminFieldWrapper'>
          <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_SEARCH_BY_PRODUCT_NAME)}</div>
          <div className={'adminInputWrapper searchWrapper'}>
            <input
              type='text'
              className={this.state.errorSelect ? 'InputError' : ''}
              value={this.state.inputSearch}
              onChange={this.changeSearch.bind(this)}
              onKeyPress={this.handleKeyPress.bind(this)}
              placeholder={t(this.props.productLine && this.props.productLine.placeholderProduct ? this.props.productLine.placeholderProduct : tx.TX_PLACEHOLDER_PRODUCT_NAME)}
              maxLength={100} />
            <div className='searchIcon'>
              <div className='searchIconWrapper'>
                {this.state.searchPending ?
                  <LoadingIcon /> :
                  <Icon 
                    value={ICON_SEARCH}  
                    iconClass={'searchIconElement'} 
                    iconLabel={null} 
                    ellipsisLabel={false}
                    onClick={() => this.getFirstPage(this.state.inputSearch)} />
                }
              </div>
            </div>
          </div>
          <div className={this.state.autocompleteActive ? 'adminInputAutocomplete active' : 'adminInputAutocomplete'}>
            <div className='adminInputAutocompleteWrapper' ref={this.autocompleteBody}>
              {this.getAutocompleteResults().length === 0 ?
                <div className='autocompleteNoResultsWrapper'>
                  <div className='noResultsCopy'>{t(tx.TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING)}</div>
                  <div className='noResultsNeedle'>{this.state.lastAutocomplete}</div>
                </div> :
                <div>
                  {this.getAutocompleteResults().map((suggestion, i) => {
                    return <div 
                            key={i}
                            className={'autocompleteSuggestion EllipsisElement'}
                            onClick={() => this.selectSuggestion(suggestion)}>
                            <div 
                              className='autocompleteSuggestionValue'
                              dangerouslySetInnerHTML={{__html: this.getHighlightedAutocomplete(suggestion)}} />
                          </div>;
                  })}
                </div>
              }
            </div>
          </div>
          {this.state.errorSelect ?
            <div className={'adminError FieldError'}>{t(this.state.errorSelect)}</div> :
            null
          }
        </div>

        <div className='adminLoadableSection'>
          {this.state.hasSearched === false ?
            <div className={'aiNotices searchNotices'}>
              <div className={'aiNotice aiNoticeVisible'}>{t(tx.TX_INV_ADD_PRODUCT_SEARCH_ABOVE, {productLine: this.props.productLine.name})}</div>
              <div className={'aiNotice aiNoticeOr aiNoticeVisible'}>{t(tx.TX_OR)}</div>
              <div className={'aiNotice aiNoticeVisible'}>{t(tx.TX_INV_ADD_PRODUCT_DIDNT_FIND)}</div>
              <div className='detailsSearchButtonWrapper'>
                <button 
                  className={'aiNoticeButton aiNoticeVisible aiNoticeButtonSecondary'} 
                  type='button'
                  onClick={this.props.toggleGenericOverride.bind(this)}>
                  {t(tx.TX_INV_ADD_PRODUCT_ADD_CUSTOM)}
                </button>
              </div>
            </div> :
            <div className={'ssResultsWrapper'}>
              {this.state.searchResults.length === 0 ?
                <div className={'ssResults aiNotices'}>
                  <div className={'aiNotice aiNoticeVisible'}>{t(tx.TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING)}</div>
                  <div className={'noResultsLastSearch'}>{decodeURIComponent(this.state.lastSearch)}</div>
                  <div className={'aiNotice aiNoticeVisible'}>{t(tx.TX_INV_ADD_PRODUCT_SEARCH_AGAIN)}</div>
                  <div className='detailsSearchButtonWrapper'>
                    <button 
                      className={'aiNoticeButton aiNoticeVisible aiNoticeButtonSecondary'} 
                      type='button'
                      onClick={this.props.toggleGenericOverride.bind(this)}>
                      {t(tx.TX_INV_ADD_PRODUCT_ADD_CUSTOM)}
                    </button>
                  </div>
                </div> :
                <div className='ssResults'>
                  <Gallery 
                    config={GALLERY_CONFIG_ADD_PRODUCT}
                    callApi={this.fetchSearch.bind(this)}
                    data={this.state.searchResults}
                    dataCount={this.state.searchCount} 
                    dataLoading={this.state.searchPending}
                    selectAction={this.selectThumbnail.bind(this)} />
                </div>
              }
            </div>
          }

          <div className={this.state.searchPending && this.state.hasSearched === false ? 'adminLoadableLoading loading' : 'adminLoadableLoading'}>
            <div className='adminLoadableScreen'></div>
            <div className='adminLoadableWrapper'>
              <LoadingIcon />
            </div>
          </div>
        </div>
        
        <div className='adminActionRow'>
          <Link 
            className={'adminAction adminActionCancel'} 
            to={URL_ADMIN_INVENTORY}>
            {t(tx.TX_CANCEL)}
          </Link>
          {this.props.productObj && this.props.productObj.foreignModel ?
            <button 
              className={'adminAction adminActionSave'} 
              type='submit'>
              {t(tx.TX_NEXT_COLON_NOUN, {noun: t(tx.TX_INV_ADD_PRODUCT_STEP_2)})}
            </button> :
            null
          }
        </div>
      </form>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductDetailsSyncedSearch));