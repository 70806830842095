import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_COUPONS, 
  TABLE_SCHEMA_COUPONS, 
  TC_PAGINATION_CONFIGS, 
  TC_DEFAULT_PAGE_SIZE, 
} from '../../constants/tables';
import { URL_ADMIN_MARKETING } from '../../constants/urls';

import { 
  getPageLimit, 
  getPageOffset,
} from '../../utils/request';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import * as promotionActionCreators from '../../actions/promotion';
let allActionCreators = Object.assign({}, promotionActionCreators);

export class Coupons extends Component {

  constructor(props) {
    super(props);

    this.state = {
      couponData: [],
      couponPending: true,
      couponCount: 0,

      couponPage: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getCouponPage(this.state.couponPage);
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  async getCouponPage(pageNum = 1, pageSize = TABLE_CONFIG_COUPONS[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      couponData: [],
      couponPending: true,
      couponCount: 0,
    });

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    const couponResp = await this.props.promoAdminFetchCouponPage(getParams, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) {
          // Request aborted; do nothing
        } else if(errResp) {
          console.error(errResp);
          this.setState({
            couponData: [],
            couponPending: false,
            couponCount: 0,
          });
        }
      });

    if(!couponResp) {
      return null;
    }

    this.setState({
      couponData: couponResp.data,
      couponPending: false,
      couponCount: couponResp.count,
    });
  }

  render() {
    return <div className={'Coupons AdminPage'}>
      <AdminTitle
        title={tx.TX_MARKETING_COUPONS}
        breadcrumbs={[
          {
            url: URL_ADMIN_MARKETING,
            title: tx.TX_MARKETING,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_COUPONS}
          schema={TABLE_SCHEMA_COUPONS}
          data={this.state.couponData}
          dataCount={this.state.couponCount} 
          dataLoading={this.state.couponPending}
          callApi={this.getCouponPage.bind(this)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Coupons));