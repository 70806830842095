import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { CD_NM, CONDITIONS_PRODUCT_GENERIC } from '../../../constants/conditions';
import { 
  ERROR_CONDITION_MISSING, 
  ERROR_FINISH_MISSING, 
  ERROR_PRINTING_MISSING, 
} from '../../../constants/errors';
import { LANG_PRODUCT_GENERIC } from '../../../constants/languages';
import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../../constants/urls';

import { Inventory } from '../../../models/products';

import { getCurrencyIncrement, getCurrencySymbol } from '../../../utils/currency';
import {
  getAttrFinish, 
} from '../../../utils/product';
import { 
  isFormValid, 
  getPriceError, 
  getQuantityError,
} from '../../../utils/form-validation';
import { 
  normalizeQuantity, 
  normalizePrice, 
} from '../../../utils/formatting';

import Radio from '../../Input/Radio';
import Toggle from '../../Input/Toggle';

let allActionCreators = Object.assign({});

export class AddProductPricingSynced extends Component {

  constructor(props) {
    super(props);

    const availableFinishes = getAttrFinish(this.props.productObj);
    const defaultFinish = availableFinishes.length === 1 ? availableFinishes[0] : '';

    this.initState = {

      inputCondition: this.props.inventoryObj ? this.props.inventoryObj.condition : CD_NM,
      inputFinish: this.props.inventoryObj ? this.props.inventoryObj.finish : defaultFinish,
      inputPrinting: this.props.inventoryObj ? this.props.inventoryObj.printing : '',
      inputBuylist: this.props.inventoryObj ? this.props.inventoryObj.isBuylist : false,
      inputPrice: this.props.inventoryObj ? this.props.inventoryObj.sellPrice : '',
      inputBuyPrice: this.props.inventoryObj ? this.props.inventoryObj.buyPrice : '',
      inputQuantity: this.props.inventoryObj ? this.props.inventoryObj.totalQuantity : '',
      inputQuantityMin: this.props.inventoryObj ? this.props.inventoryObj.targetMin : '',
      inputQuantityMax: this.props.inventoryObj ? this.props.inventoryObj.targetMax : '',

      errorCondition: '',
      errorFinish: '',
      errorPrinting: '',
      errorPrice: '',
      errorBuyPrice: '', 
      errorQuantity: '',
      errorQuantityMin: '',
      errorQuantityMax: '',
    }

    this.state = this.initState;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.validationSignal !== this.props.validationSignal) {
      this.validateAll();
    }
  }

  getDefaultLanguage() {
    for(const lang of LANG_PRODUCT_GENERIC) {
      if(lang.code === process.env.REACT_APP_DEFAULT_LANGUAGE) {
        return lang;
      }
    }
    return '';
  }

  validateAll() {
    let errorObj = {
      errorCondition: this.getConditionError(),
      errorFinish: this.props.productObj.hasFinish() && !this.state.inputFinish ? ERROR_FINISH_MISSING : '',
      errorPrinting: this.getPrintingError(),
      errorPrice: getPriceError(this.state.inputPrice),
      errorBuyPrice: this.state.inputBuylist ? getPriceError(this.state.inputBuyPrice) : '', 
      errorQuantity: getQuantityError(this.state.inputQuantity),
      errorQuantityMin: getQuantityError(this.state.inputQuantityMin, true),
      errorQuantityMax: getQuantityError(this.state.inputQuantityMax, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  submitForm(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {
      this.props.saveProduct();
    }
  }

  changeBuylist(evt) {
    this.setState({
      inputBuylist: !this.state.inputBuylist, 
    }, () => {
      if(!this.state.inputBuylist) {
        this.setState({ 
          inputBuyPrice: '', 
          errorBuyPrice: '', 
        }, () => {
          this.syncInventory();
        });
      } else {
        this.syncInventory();
      }
    });
    this.props.makeDirty();
  }

  changePrice(evt) {
    this.setState({
      inputPrice: evt.target.value,
    }, () => {
      if(this.state.errorPrice) {
        this.validatePrice(false);
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validatePrice(shouldNormalize = true) {
    this.setState({ 
      inputPrice: normalizePrice(this.state.inputPrice), 
      errorPrice: shouldNormalize ? getPriceError(this.state.inputPrice) : this.state.inputPrice, 
    }, () => {
      this.syncInventory();
    });
  }

  changeBuyPrice(evt) {
    this.setState({
      inputBuyPrice: evt.target.value,
    }, () => {
      if(this.state.errorBuyPrice) {
        this.validateBuyPrice(false);
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateBuyPrice(shouldNormalize = true) {
    this.setState({ 
      inputBuyPrice: normalizePrice(this.state.inputBuyPrice), 
      errorBuyPrice: shouldNormalize ? getPriceError(this.state.inputBuyPrice) : this.state.inputBuyPrice, 
    }, () => {
      this.syncInventory();
    });
  }

  changeQuantity(evt) {
    this.setState({
      inputQuantity: evt.target.value,
    }, () => {
      if(this.state.errorQuantity) {
        this.validateQuantity();
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateQuantity() {
    this.setState({ 
      inputQuantity: normalizeQuantity(this.state.inputQuantity),
      errorQuantity: getQuantityError(normalizeQuantity(this.state.inputQuantity)),
    }, () => {
      this.syncInventory();
    });
  }

  changeQuantityMin(evt) {
    this.setState({
      inputQuantityMin: evt.target.value,
    }, () => {
      if(this.state.errorQuantityMin) {
        this.validateQuantityMin();
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateQuantityMin() {
    this.setState({ 
      inputQuantityMin: normalizeQuantity(this.state.inputQuantityMin),
      errorQuantityMin: getQuantityError(this.state.inputQuantityMin, true),
    });
  }

  changeQuantityMax(evt) {
    this.setState({
      inputQuantityMax: evt.target.value,
    }, () => {
      if(this.state.errorQuantityMax) {
        this.validateQuantityMax();
      }
      this.syncInventory();
    });
    this.props.makeDirty();
  }

  validateQuantityMax() {
    this.setState({ 
      inputQuantityMax: normalizeQuantity(this.state.inputQuantityMax),
      errorQuantityMax: getQuantityError(this.state.inputQuantityMax, true),
    });
  }

  getProductLanguage() {
    if(!this.props.productObj) {
      return null;
    }
    if(this.props.productObj.foreignModel) {
      return this.props.productObj.foreignModel.languageObj;
    }
    return null;
  }

  syncInventory() {

    if(!this.props.productObj) { return null; }

    const inventoryObj = new Inventory({
      isSealed: false,
      condition: this.state.inputCondition,
      finish: this.state.inputFinish,
      printing: this.state.inputPrinting,
      isBuylist: this.state.inputBuylist, 
      sellPrice: this.state.inputPrice,
      totalQuantity: this.state.inputQuantity,
      targetMin: this.state.inputQuantityMin,
      targetMax: this.state.inputQuantityMax,

      foreignModelCode: this.props.productObj.foreignModelCode,
      foreignModel: this.props.productObj.foreignModel,

    });

    const lang = this.getProductLanguage();
    if(lang) {
      inventoryObj.language = lang;
    }

    if(this.state.inputBuylist) {
      inventoryObj.buyPrice = this.state.inputBuyPrice;
    }
    this.props.setInventory(inventoryObj);
  }

  changeCondition(evt) {

    if(evt.target.value === '') {
      this.setState({ inputCondition: evt.target.value }, () => {
        this.syncInventory();
        this.validateCondition();
      });
      return null;
    }

    for(const co of this.getConditionList()) {
      if(co.key === evt.target.value) {
        this.setState({ inputCondition: co }, () => {
          this.syncInventory();
          this.validateCondition();
        });
        break;
      }
    }
  }

  changeFinish(evt) {

    if(evt.target.value === '') {
      this.setState({ inputFinish: evt.target.value }, () => {
        this.syncInventory();
      });
      return null;
    }

    for(const fn of getAttrFinish(this.props.productObj)) {
      if(fn.key === evt.target.value) {
        this.setState({ inputFinish: fn }, () => {
          this.syncInventory();
          if(this.state.errorFinish) {
            this.setState({ errorFinish: '' });
          }
        });
        break;
      }
    }
  }

  getConditionError() {
    if(!this.state.inputCondition) {
      return ERROR_CONDITION_MISSING;
    }
    return '';
  }

  validateCondition() {
    this.setState({ errorCondition: this.getConditionError() });
  }

  getConditionLabel(conditionObj) {
    if(!conditionObj) { return ''; }
    const abrv = this.props.t(conditionObj.short);
    const cName = this.props.t(conditionObj.name);
    return abrv ? `${cName} (${abrv})` : cName;
  }

  getConditionList() {
    if(this.props.productObj && this.props.productObj.foreignModel && this.props.productObj.foreignModel.allConditions) {
      return this.props.productObj.foreignModel.allConditions();
    }
    return CONDITIONS_PRODUCT_GENERIC;
  }

  buylistAllowed() {
    return this.props.productLine ? this.props.productLine.hasBuylist : false;
  }

  changePrinting(evt) {

    if(evt.target.value === '') {
      this.setState({ inputPrinting: evt.target.value }, () => {
        if(this.validatePrinting()) {
          this.syncInventory();
        }
      });
      return null;
    }

    for(const pr of this.getSelectablePrintings()) {
      if(pr.key === evt.target.value) {
        this.setState({ inputPrinting: pr }, () => {
          if(this.validatePrinting()) {
            this.syncInventory();
          }
        });
        break;
      }
    }
  }

  getPrintingError() {
    if(this.isPrintingRequired()) {
      if(this.getSelectablePrintings().length > 1 && !this.state.inputPrinting) {
        return ERROR_PRINTING_MISSING;
      }
    }
    return '';
  }

  validatePrinting() {
    this.setState({ errorPrinting: this.getPrintingError() });
  }

  isPrintingRequired() {
    return this.props.productObj.allPrintings().length > 0;
  }

  getSelectablePrintings() {
    if(!this.props.productObj) { return []; }
    return this.props.productObj.allPrintings();
  }

  render() {

    const {t} = this.props;
    const availableFinishes = getAttrFinish(this.props.productObj);

    return <div className={'AddProductPricingSynced'}>
      <form onSubmit={this.submitForm.bind(this)}>
        <div className='ap2Pricing'>

          <div className={'adminFieldWrapper'}>
            <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_CONDITION)}</div>
            <div className={'adminInputWrapper'}>
              <div className='adminDropdown'>
                <select
                  value={this.state.inputCondition ? this.state.inputCondition.key : ''}
                  onChange={this.changeCondition.bind(this)}
                  className={this.state.errorCondition ? 'InputError' : ''}>
                  {this.getConditionList().map((cond, i) => {
                    return <option key={i} value={cond.value}>{this.getConditionLabel(cond)}</option>;
                  })}
                </select>
                {this.state.errorCondition ?
                  <div className={'adminError FieldError'}>{t(this.state.errorCondition)}</div> :
                  null
                }
                <div className='aiDropdownArrowWrapper'>
                  <div className='aiArrow'></div>
                </div>
              </div>
            </div>
          </div>

          {this.isPrintingRequired() && this.getSelectablePrintings().length > 1 ?
            <div className={'adminFieldWrapper'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRINTING)}</div>
              <div className='adminInputWrapper'>
                <div className='adminRadioWrapper'>
                  {this.getSelectablePrintings().map((printing, j) => {
                    return <div className='adminRadioElementWrapper' key={j}>
                      <div className='adminRadioElement'>
                        <div className='adminRadioInputWrapper'>
                          <div className='adminRadioInput'>
                            <Radio
                              id={`printing|${j}`}
                              className='printingInput'
                              name={t(tx.TX_PRINTING)}
                              adminTheme={true}
                              error={this.state.errorPrinting}
                              value={printing.key}
                              checked={this.state.inputPrinting && this.state.inputPrinting.key && this.state.inputPrinting.key === printing.key}
                              onChange={this.changePrinting.bind(this)} />
                          </div>
                        </div>
                        <label htmlFor={`printing|${j}`} className={'adminRadioLabelWrapper'}>
                          <div className='adminRadioLabel'>{t(printing.name)}</div>
                        </label>
                      </div>
                    </div>
                  })}
                  {this.state.errorPrinting ?
                    <div className={'adminError FieldError'}>{t(this.state.errorPrinting)}</div> :
                    null
                  }
                </div>
              </div>
            </div> :
            null
          }

          {availableFinishes.length ?
            <div className={'adminFieldWrapper'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_FINISHES)}</div>
              <div className='adminInputWrapper'>
                <div className='adminRadioWrapper'>
                  {availableFinishes.map((finish, i) => {

                    const isDisabled = availableFinishes.length === 1;

                    return <div className='adminRadioElementWrapper' key={i}>
                      <div className='adminRadioElement'>
                        <div className='adminRadioInputWrapper'>
                          <div className='adminRadioInput'>
                            <Radio
                              id={`finish|${i}`}
                              className='finishInput'
                              name='finish'
                              adminTheme={true}
                              error={this.state.errorFinish}
                              disabled={isDisabled}
                              value={finish.key}
                              checked={this.state.inputFinish && this.state.inputFinish.key && this.state.inputFinish.key === finish.key}
                              onChange={this.changeFinish.bind(this)} />
                          </div>
                        </div>
                        <label htmlFor={`finish|${i}`} className={isDisabled ? 'adminRadioLabelWrapper disabled' : 'adminRadioLabelWrapper'}>
                          <div className='adminRadioLabel'>{t(finish.name)}</div>
                        </label>
                      </div>
                    </div>
                  })}
                  {this.state.errorFinish ?
                    <div className={'adminError FieldError'}>{t(this.state.errorFinish)}</div> :
                    null
                  }
                </div>
              </div>
            </div> :
            null
          }

          {this.buylistAllowed() ?
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_BUYLIST_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputBuylist}
                    onToggle={this.changeBuylist.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
            </div> :
            null
          }

          <div className={'adminFieldWrapper'}>
            <div className={'adminInputWrapper halfWidth'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRICE)}</div>
              <div className='currencyWrapper'>
                <input
                  type='number'
                  min={0}
                  step={getCurrencyIncrement()}
                  className={this.state.errorPrice ? 'currencyInput InputError' : 'currencyInput'}
                  value={this.state.inputPrice}
                  onChange={this.changePrice.bind(this)}
                  onBlur={this.validatePrice.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                <div className='currencyOverlay'>{getCurrencySymbol()}</div>
              </div>
              {this.state.errorPrice ?
                <div className={'adminError FieldError'}>{t(this.state.errorPrice)}</div> :
                null
              }
            </div>

            {this.state.inputBuylist ? 
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}</div>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    step={getCurrencyIncrement()}
                    className={this.state.errorBuyPrice ? 'currencyInput InputError' : 'currencyInput'}
                    value={this.state.inputBuyPrice}
                    onChange={this.changeBuyPrice.bind(this)}
                    onBlur={this.validateBuyPrice.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_BUY_PRICE)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorBuyPrice ?
                  <div className={'adminError FieldError'}>{t(this.state.errorBuyPrice)}</div> :
                  null
                }
              </div> :
              null
            }
          </div>

          

          <div className={'adminFieldWrapper'}>
            <div className={'adminInputWrapper halfWidth'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SELLABLE_QUANTITY)}</div>
              <input
                type='number'
                min={0}
                step={1}
                className={this.state.errorQuantity ? 'quantityInput InputError' : 'quantityInput'}
                value={this.state.inputQuantity}
                onChange={this.changeQuantity.bind(this)}
                onBlur={this.validateQuantity.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_QUANTITY)}
                maxLength={32} />
              {this.state.errorQuantity ?
                <div className={'adminError FieldError'}>{t(this.state.errorQuantity)}</div> :
                null
              }
            </div>

            <div className={'adminInputWrapper halfWidth'}>
              <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_INV_ADD_PRODUCT_TARGET_QUANTITY)}</div>
              <div className={'adminInputWrapper halfWidth'}>
                <input
                  type='number'
                  min={0}
                  step={1}
                  className={this.state.errorQuantityMin ? 'quantityInput InputError' : 'quantityInput'}
                  value={this.state.inputQuantityMin}
                  onChange={this.changeQuantityMin.bind(this)}
                  onBlur={this.validateQuantityMin.bind(this)}
                  placeholder={t(tx.TX_MIN)}
                  maxLength={32} />
                {this.state.errorQuantityMin ?
                  <div className={'adminError FieldError'}>{t(this.state.errorQuantityMin)}</div> :
                  null
                }
              </div>
              <div className={'adminInputWrapper halfWidth'}>
                <input
                  type='number'
                  min={0}
                  step={1}
                  className={this.state.errorQuantityMax ? 'quantityInput InputError' : 'quantityInput'}
                  value={this.state.inputQuantityMax}
                  onChange={this.changeQuantityMax.bind(this)}
                  onBlur={this.validateQuantityMax.bind(this)}
                  placeholder={t(tx.TX_MAX)}
                  maxLength={32} />
                {this.state.errorQuantityMax ?
                  <div className={'adminError FieldError'}>{t(this.state.errorQuantityMax)}</div> :
                  null
                }
              </div>
            </div>
          </div>

        </div>
        <div className='adminActionRow'>
          <Link 
            className={'adminAction adminActionCancel'} 
            to={URL_ADMIN_INVENTORY}>
            {t(tx.TX_CANCEL)}
          </Link>
          <button 
            className={'adminAction adminActionSave'} 
            type='submit'>
            {t(tx.TX_SAVE)}
          </button>
          <button 
            className={'adminAction adminActionBack adminLeft'} 
            type='button'
            onClick={this.props.movePrev.bind(this)}>
            {t(tx.TX_BACK)}
          </button>
        </div>
      </form>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductPricingSynced));