

import { API_KEY_QUICK_SEARCH } from '../constants/api';
import { 
	TX_NONE,
	TX_SEARCH, 
	TX_null,
} from '../constants/strings';


export class AdminFilterElement {

	constructor(props) {
		
		if(!props) { 
			props = {}; 
		}

		this.key = props.key || '';
		this.component = props.component || null;
		this.quickLookup = props.quickLookup || props.quick_lookup || false;
		this.name = props.name || '';
		this.minKey = props.minKey || props.min_key || '';
		this.maxKey = props.maxKey || props.max_key || '';
		this.options = props.options || [];
		this.optionsTranslate = props.optionsTranslate || props.options_translate || false;
		this.inputPlaceholder = props.inputPlaceholder || props.input_placeholder || '';
		this.inputType = props.inputType || props.input_type || '';

		this.displayLookup = props.displayLookup || props.display_lookup || '';
		this.displayKey = props.displayKey || props.display_key || '';
		this.displayTrue = props.displayTrue || props.display_true || TX_null;
		this.displayFalse = props.displayFalse || props.display_false || TX_null;
	}

	static quickSearch() {
		const qsProps = {
			key: API_KEY_QUICK_SEARCH,
			name: TX_SEARCH,
		}

		return new AdminFilterElement(qsProps);
	}

	get apiKeys() {
		const keys = [];
		if(this.minKey && this.maxKey) {
			keys.push(this.minKey);
			keys.push(this.maxKey);
		} else {
			keys.push(this.key);
		}
		return keys;
	}

	get elements() {
		// map elements to standard objects
		// Keys: key, value
		
		if(!this.displayKey || !this.displayLookup) { return []; }

		const elementArray = [];

		try {
			for(const opt of this.options) {

				const elemObj = {
					key: opt[this.displayLookup],
					value: opt[this.displayKey],
				};

				elementArray.push(elemObj);
			}
		} catch(err) {
			console.error(err);
		}
		return elementArray;
	}

	getElementName(key, options = []) {

		if(key === 'null') {
			return TX_NONE;
		}

		if(options.length > 0) {
			// Dynamic elements options (set, etc)
			if(!this.displayLookup || !this.displayKey) {
				return '';
			}

			for(const opt of options) {
				if(opt[this.displayLookup] === key) {
					return opt[this.displayKey];
				}
			}

		} else {
			// Static elements options (condition, language, etc)
			for(const elem of this.elements) {
				if(elem.key === key) {
					return elem.value;
				}
			}
		}
		return '';
	}

	getFilterDisplayValue(value, options = []) {
		try {

			if(!this.displayLookup || !this.displayKey) {
				return this.component.WrappedComponent.formatTagValue ? this.component.WrappedComponent.formatTagValue(value) : value;
			}

			for(const opt of options) {
				if(opt[this.displayLookup] === value) {
					return opt[this.displayKey];
				}
			}

		} catch(err) {
			console.error(err);
		}
		return value;
	}

	isActive(filterSet) {
		return this.getActiveKeys(filterSet).length > 0;
	}

	getActiveKeys(filterSet) {

		const setKeys = Object.keys(filterSet);
		const activeKeys = [];

		if(setKeys.includes(this.key)) {
			activeKeys.push(this.key);
		} 
		if(this.minKey && setKeys.includes(this.minKey)) {
			activeKeys.push(this.minKey);
		} 
		if(this.maxKey && setKeys.includes(this.maxKey)) {
			activeKeys.push(this.maxKey);
		}
		return activeKeys;
	}

	isListElement() {
		if(!this.component) { return false; }
		try {
			return this.component.WrappedComponent.isListElement ? this.component.WrappedComponent.isListElement() : false;
		} catch(err) {
			console.error(err);
		}
		return false;
	}
}




























