import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { API_EXTERNAL_STAMPS_API } from '../../../../constants/api';
import { EXTERNAL_ENDPOINT_STAMPS_AUTHORIZE } from '../../../../constants/endpoints';
import * as tx from '../../../../constants/strings';

import { getUrlParams } from '../../../../utils/request';

const allActionCreators = Object.assign({});

export class IntegrationConfigOauthStampsAuthorize extends Component {

  async getLoginUrl() {

    if(!this.props.integration || !this.props.integration.oauth) { return false; }

    const getParams = {
      client_id: this.props.integration.oauth.clientId,
      response_type: 'code',
      redirect_uri: this.props.integration.oauthRedirectUri,
      scope: 'offline_access',
    };

    window.location.href = API_EXTERNAL_STAMPS_API + EXTERNAL_ENDPOINT_STAMPS_AUTHORIZE + getUrlParams(getParams);
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationConfigOauthStampsAuthorize AdminComponentSupplement'}>
      <div className='oauthInitWrapper'>
        <div className='oauthCopy'>{t(tx.TX_SETTINGS_INTEGRATION_STAMPS_OAUTH_PROMPT)}</div>
        <div className='oauthActionWrapper'>
          <button 
            type='button' 
            className='oauthAction'
            onClick={this.getLoginUrl.bind(this)}>{t(tx.TX_CONNECT)}</button>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationConfigOauthStampsAuthorize));