import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getMyId } from '../../../utils/auth';
import { formatServerError } from '../../../utils/formatting';

import * as tx from '../../../constants/strings';

import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';
import Toggle from '../../Input/Toggle';

import '../style/_customerpermissionsedit.scss';

import * as authActionCreators from '../../../actions/auth';
import * as commonActionCreators from '../../../actions/common';
import * as customerActionCreators from '../../../actions/customer';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, commonActionCreators, customerActionCreators, userActionCreators);

export class CustomerPermissionsEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,

      inputProducts: this.props.customer && this.props.customer.permissions && this.props.customer.permissions.manage_products ? this.props.customer.permissions.manage_products : false,
      inputSales: this.props.customer && this.props.customer.permissions && this.props.customer.permissions.manage_sales ? this.props.customer.permissions.manage_sales : false,
      inputUsers: this.props.customer && this.props.customer.permissions && this.props.customer.permissions.manage_users ? this.props.customer.permissions.manage_users : false,
      inputEvents: this.props.customer && this.props.customer.permissions && this.props.customer.permissions.manage_events ? this.props.customer.permissions.manage_events : false,
      inputPermissions: this.props.customer && this.props.customer.permissions && this.props.customer.permissions.manage_permissions ? this.props.customer.permissions.manage_permissions : false,

      requestPending: false,
      requestError: null,

      savePromptOpen: false, 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }

    const permissionsData = {
      manage_products: this.state.inputProducts,
      manage_sales: this.state.inputSales,
      manage_users: this.state.inputUsers,
      manage_events: this.state.inputEvents,
      manage_permissions: this.state.inputPermissions,
    };

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.customerAdminPermissionsEdit(permissionsData, this.props.customer.publicUuid)
    .then((resp) => {
      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        if(this.props.remoteSave) {
          this.props.finishRemote('permissions');
        } else {
          this.props.makeClean('permissions');
        }

        if(this.isThisMe()) {
          this.props.usersFetchMe()
          .then((resp) => {
            this.props.toggleEdit();
          })
          .catch((errResp) => {
            if(errResp) {
              console.error(errResp);
            }
          });
        } else {
          this.props.toggleEdit();
        }
      });
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });

      if(this.props.remoteSave) {
        this.props.finishRemote('permissions', true);
      }
    });
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  toggleProducts() {
    this.setState({ inputProducts: !this.state.inputProducts }, () => {
      this.makeDirty();
    });
  }

  toggleSales() {
    this.setState({ inputSales: !this.state.inputSales }, () => {
      this.makeDirty();
    });
  }

  toggleUsers() {
    this.setState({ inputUsers: !this.state.inputUsers }, () => {
      this.makeDirty();
    });
  }

  toggleEvents() {
    this.setState({ inputEvents: !this.state.inputEvents }, () => {
      this.makeDirty();
    });
  }

  togglePermissions() {
    if(this.state.inputPermissions === true && this.isThisMe() === true) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_NOT_ALLOWED, 
        alertCopy: tx.TX_SETTINGS_CANNOT_REMOVE_PERMISSION, 
      });
    } else {
      this.setState({ inputPermissions: !this.state.inputPermissions }, () => {
        this.makeDirty();
      });
    }
  }

  isThisMe() {
    return getMyId() && this.props.customer && this.props.customer.id && getMyId() === this.props.customer.id;
  }

  render() {

    const {t} = this.props;

    return <div className={'CustomerPermissionsEdit adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_SETTINGS_ADMIN_PERMISSIONS)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          {this.props.customerPending === false ?
            <form 
              className={'customerPermissionsForm adminForm'}
              onSubmit={this.saveAction.bind(this)}>

              <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>


              <div className='editPermissionWrapper'>
                <div className='editToggleWrapper'>
                  <div className='editToggleInputWrapper'>
                    <Toggle
                      checked={this.state.inputProducts}
                      onToggle={this.toggleProducts.bind(this)}
                      bottomLabel={true}
                      trueValue={tx.TX_ENABLED}
                      falseValue={tx.TX_DISABLED} />
                  </div>
                </div>
                <div className='editToggleLabelWraper'>
                  <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_PRODUCTS)}</div>
                  <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_PRODUCTS_DESC)}</div>
                </div>
              </div>

              <div className='editPermissionWrapper'>
                <div className='editToggleWrapper'>
                  <div className='editToggleInputWrapper'>
                    <Toggle
                      checked={this.state.inputSales}
                      onToggle={this.toggleSales.bind(this)}
                      bottomLabel={true}
                      trueValue={tx.TX_ENABLED}
                      falseValue={tx.TX_DISABLED} />
                  </div>
                </div>
                <div className='editToggleLabelWraper'>
                  <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_SALES)}</div>
                  <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_SALES_DESC)}</div>
                </div>
              </div>

              <div className='editPermissionWrapper'>
                <div className='editToggleWrapper'>
                  <div className='editToggleInputWrapper'>
                    <Toggle
                      checked={this.state.inputUsers}
                      onToggle={this.toggleUsers.bind(this)}
                      bottomLabel={true}
                      trueValue={tx.TX_ENABLED}
                      falseValue={tx.TX_DISABLED} />
                  </div>
                </div>
                <div className='editToggleLabelWraper'>
                  <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_USERS)}</div>
                  <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_USERS_DESC)}</div>
                </div>
              </div>

              <div className='editPermissionWrapper'>
                <div className='editToggleWrapper'>
                  <div className='editToggleInputWrapper'>
                    <Toggle
                      checked={this.state.inputEvents}
                      onToggle={this.toggleEvents.bind(this)}
                      bottomLabel={true}
                      trueValue={tx.TX_ENABLED}
                      falseValue={tx.TX_DISABLED} />
                  </div>
                </div>
                <div className='editToggleLabelWraper'>
                  <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_EVENTS)}</div>
                  <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_EVENTS_DESC)}</div>
                </div>
              </div>

              <div className='editPermissionWrapper'>
                <div className='editToggleWrapper'>
                  <div className='editToggleInputWrapper'>
                    <Toggle
                      checked={this.state.inputPermissions}
                      onToggle={this.togglePermissions.bind(this)}
                      bottomLabel={true}
                      trueValue={tx.TX_ENABLED}
                      falseValue={tx.TX_DISABLED} />
                  </div>
                </div>
                <div className='editToggleLabelWraper'>
                  <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_PERMISSIONS)}</div>
                  <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_PERMISSIONS_DESC)}</div>
                </div>
              </div>



              <div className='adminActionRow'>
                <div 
                  className={'adminAction adminActionCancel'} 
                  onClick={this.tryToToggle.bind(this)}>
                  {t(tx.TX_CANCEL)}
                </div>
                <button 
                  className={'adminAction adminActionSave'} 
                  type='submit'
                  disabled={this.state.requestPending}>
                  {t(tx.TX_SAVE)}
                </button>
              </div>
              <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
                <div className='adminFormPendingScreen'></div>
                <div className='adminFormPendingWrapper'>
                  <LoadingIcon />
                </div>
              </div>
            </form> :
            null
          }
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.customerPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerPermissionsEdit));