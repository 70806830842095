

import {
	API_FOREIGN_MODEL_LORCANA_CARD,
	API_FOREIGN_MODEL_LORCANA_SEALED,
	API_FOREIGN_MODEL_LORCANA_SET,
	API_FOREIGN_MODEL_MAGIC_CARD,
	API_FOREIGN_MODEL_MAGIC_SEALED,
	API_FOREIGN_MODEL_MAGIC_SET,
	API_FOREIGN_MODEL_POKEMON_CARD,
	API_FOREIGN_MODEL_POKEMON_SEALED,
	API_FOREIGN_MODEL_POKEMON_SET,
	API_FOREIGN_MODEL_STARWARS_CARD,
	API_FOREIGN_MODEL_STARWARS_SEALED,
	API_FOREIGN_MODEL_STARWARS_SET,
} from './api';
import { 
	CD_NM,
	CD_LP,
	CD_MP,
	CD_HP,
	CD_D,
	CONDITIONS_ALL, 
	CONDITIONS_PRODUCT_TCG, 
} from './conditions';
import { 
	FINISHES_LORCANA, 
	FINISHES_MAGIC,
	FINISHES_POKEMON,
	FINISHES_STARWARS,
} from './finishes';
import { 
	LANG_EN, 
	LANG_ES,
	LANG_PRODUCT_ALL, 
	LANG_PRODUCT_LORCANA, 
	LANG_PRODUCT_MAGIC, 
	LANG_PRODUCT_POKEMON,
	LANG_PRODUCT_STARWARS,
} from './languages';
import { PRINTINGS_POKEMON } from './printings';
import {
	TX_ERROR,
	TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SKIPPED,
	// TX_INV_BULK_UPLOAD_TYPE_BINDER,
	TX_INV_BULK_UPLOAD_TYPE_CRYSTAL_COMMERCE,
	TX_INV_BULK_UPLOAD_TYPE_GENERAL, 
	TX_INV_BULK_UPLOAD_TYPE_TALARIA,
	TX_INV_BULK_UPLOAD_TYPE_TCG_PLAYER,
	TX_MAGIC_FORMAT_CASUAL,
	TX_MAGIC_FORMAT_COMMANDER,
	TX_MAGIC_FORMAT_DRAFT,
	TX_MAGIC_FORMAT_EXPLORER,
	TX_MAGIC_FORMAT_LEGACY,
	TX_MAGIC_FORMAT_LIMITED,
	TX_MAGIC_FORMAT_MODERN,
	TX_MAGIC_FORMAT_PAUPER,
	TX_MAGIC_FORMAT_PIONEER,
	TX_MAGIC_FORMAT_SEALED,
	TX_MAGIC_FORMAT_STANDARD,
	TX_MAGIC_FORMAT_TWO_HEADED_GIANT, 
	TX_MAGIC_FORMAT_VINTAGE,
	TX_PLACEHOLDER_PRODUCT_NAME_LORCANA, 
	TX_PLACEHOLDER_PRODUCT_NAME_MAGIC,
	TX_PLACEHOLDER_PRODUCT_NAME_POKEMON,
	TX_PLACEHOLDER_PRODUCT_NAME_STARWARS,
	TX_SELL_PRICE,
	TX_SET_NAME,
	TX_SUCCESS,
	TX_null,
} from './strings';


import { 
	LorcanaCard, 
	LorcanaSealed,
	LorcanaSet,
} from '../models/syncs/lorcana';
import { 
	MagicCard, 
	MagicSealed,
	MagicSet,
} from '../models/syncs/magic';
import { 
	PokemonCard, 
	PokemonSealed,
	PokemonSet,
} from '../models/syncs/pokemon';
import { 
	StarwarsCard, 
	StarwarsSealed,
	StarwarsSet,
} from '../models/syncs/starwars';

export const PROD_ATTR_KEY_SET_CODE = 'set_code';
export const PROD_ATTR_KEY_SET_NAME = 'set_name';

export const PROD_PRICE_CLASS_CENTS = 'priceFormatCents';
export const PROD_PRICE_CLASS_DECIMAL = 'priceFormatDecimal';
export const PROD_PRICE_CLASS_DOLLAR = 'priceFormatDollar';
export const PROD_PRICE_CLASS_FREE = 'priceFormatFree';
export const PROD_PRICE_CLASS_LESS_THAN_ONE = 'priceFormatLessThanOne';
export const PROD_PRICE_CLASS_NEGATIVE = 'priceFormatNegative';
export const PROD_PRICE_CLASS_SYMBOL = 'priceFormatSymbol';

export const PROD_QTY_LIMIT = 25;
export const PROD_BUYLIST_QTY_LIMIT = 1000;

export const PROD_SKU_MANAGED_DEMARKATION = '#';

export const PROD_TYPE_GENERAL = 'PROD_TYPE_GENERAL';
export const PROD_TYPE_LORCANA = 'PROD_TYPE_LORCANA';
export const PROD_TYPE_MAGIC = 'PROD_TYPE_MAGIC';
export const PROD_TYPE_POKEMON = 'PROD_TYPE_POKEMON';
export const PROD_TYPE_STARWARS = 'PROD_TYPE_STARWARS';

export const PROD_KEY_CONDITION = 'condition';
export const PROD_KEY_FINISH = 'finish';
export const PROD_KEY_LANGUAGE = 'language';
export const PROD_KEY_PRINTING = 'printing';

export const PL_PERMALINK_LORCANA = 'lorcana';
export const PL_PERMALINK_MAGIC = 'magic';
export const PL_PERMALINK_POKEMON = 'pokemon';
export const PL_PERMALINK_STARWARS = 'star-wars-unlimited'

export const PROD_BACKEND_RESERVED_KEYWORDS = [
	'inventory',
	'manage',
	'media',
	'shop',
];

export const PROD_SEGMENT_MODEL_PRODUCT = 'product';
export const PROD_SEGMENT_MODEL_PRODUCT_ATTR = 'product_attr';
export const PROD_SEGMENT_MODEL_INVENTORY = 'inventory';

export const PROD_SEGMENT_VALUE_TYPE_BOOL = 'bool';
export const PROD_SEGMENT_VALUE_TYPE_INT = 'int';
export const PROD_SEGMENT_VALUE_TYPE_DOUBLE = 'double';
export const PROD_SEGMENT_VALUE_TYPE_LIST = 'list';

export const PROD_DEFAULT_KEY_WEIGHT = 'weight';

export const PROD_PAGE_LAYOUT_DETAILS_CLASS_GENERAL = 'ppDetailsGeneralWrapper';

export const PROD_PAGE_LAYOUT_CLASS_TCG = 'productTcg';
export const PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG = 'ppDetailsTcgWrapper';

export const PROD_BULK_UPLOAD_CHUNK_SIZE = 50;

export const PROD_BULK_UPLOAD_STATUS_SUCCESS_KEY = 'success';
export const PROD_BULK_UPLOAD_STATUS_ERROR_KEY = 'error';
export const PROD_BULK_UPLOAD_STATUS_SKIPPED_KEY = 'skipped';

export const PROD_BULK_UPLOAD_SCHEMA_KEY_PRODUCT_LINE = 'Product Line';

export const PROD_BULK_UPLOAD_STATUS_SUCCESS = {
	key: PROD_BULK_UPLOAD_STATUS_SUCCESS_KEY,
	name: TX_SUCCESS,
}
export const PROD_BULK_UPLOAD_STATUS_ERROR = {
	key: PROD_BULK_UPLOAD_STATUS_ERROR_KEY,
	name: TX_ERROR,
}
export const PROD_BULK_UPLOAD_STATUS_SKIPPED = {
	key: PROD_BULK_UPLOAD_STATUS_SKIPPED_KEY,
	name: TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_SKIPPED,
}

export const PROD_BULK_UPLOAD_STATUSES = [
	PROD_BULK_UPLOAD_STATUS_SUCCESS,
	PROD_BULK_UPLOAD_STATUS_ERROR,
	PROD_BULK_UPLOAD_STATUS_SKIPPED,
];

export const PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_DEFAULT = 50;
export const PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_OPTIONS = [ 25, 50, 100, 200 ];

export const PL_RESERVED_PERMALINKS = [
	PL_PERMALINK_MAGIC, 
	PL_PERMALINK_POKEMON, 
	PL_PERMALINK_LORCANA, 
	PL_PERMALINK_STARWARS, 
].concat(PROD_BACKEND_RESERVED_KEYWORDS);

export const PL_EVENT_FORMATS = {
	[PL_PERMALINK_MAGIC]: [
		{
			key: 'mtglimited',
			name: TX_MAGIC_FORMAT_LIMITED,
		},
		{
			key: 'mtgdraft',
			name: TX_MAGIC_FORMAT_DRAFT,
		},
		{
			key: 'mtgsealed',
			name: TX_MAGIC_FORMAT_SEALED,
		},
		{
			key: 'mtg2hg',
			name: TX_MAGIC_FORMAT_TWO_HEADED_GIANT,
		},
		{
			key: 'mtgpauper',
			name: TX_MAGIC_FORMAT_PAUPER,
		},
		{
			key: 'mtgcommander',
			name: TX_MAGIC_FORMAT_COMMANDER,
		},
		{
			key: 'mtgstandard',
			name: TX_MAGIC_FORMAT_STANDARD,
		},
		{
			key: 'mtgexplorer',
			name: TX_MAGIC_FORMAT_EXPLORER,
		},
		{
			key: 'mtgpioneer',
			name: TX_MAGIC_FORMAT_PIONEER,
		},
		{
			key: 'mtgmodern',
			name: TX_MAGIC_FORMAT_MODERN,
		},
		{
			key: 'mtglegacy',
			name: TX_MAGIC_FORMAT_LEGACY,
		},
		{
			key: 'mtgvintage',
			name: TX_MAGIC_FORMAT_VINTAGE,
		},
		{
			key: 'mtgcasual',
			name: TX_MAGIC_FORMAT_CASUAL,
		},
	],
	[PL_PERMALINK_POKEMON]: [],
};

export const PL_CONDITIONS_PRODUCT_PAGE = {
	[PL_PERMALINK_MAGIC]: CONDITIONS_PRODUCT_TCG,
	[PL_PERMALINK_POKEMON]: CONDITIONS_PRODUCT_TCG,
	[PL_PERMALINK_LORCANA]: CONDITIONS_PRODUCT_TCG,
	[PL_PERMALINK_STARWARS]: CONDITIONS_PRODUCT_TCG,
};

export const PL_COLORS = {
	[PL_PERMALINK_MAGIC]: '#D8643C',
	[PL_PERMALINK_POKEMON]: '#009620',
	[PL_PERMALINK_LORCANA]: '#0694C8',
	[PL_PERMALINK_STARWARS]: '#BE72D4',
};

export const BULK_UPLOAD_SCHEMA_MAGIC_BINDER = [
	{
		key: 'Product Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Category',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Total Qty',
		required: true,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_MAGIC_CC = [
	{
		key: 'Product Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Category',
		required: true,
		display: false,
		description: TX_SET_NAME,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Qty',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add Qty',
		required: false,
		display: false,
		description: TX_null,
	},
	// {
	// 	key: 'Weight',
	// 	required: false,
	// 	display: false,
	// 	description: TX_null,
	// },
	{
		key: 'Target Qty',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Ebay Reserve',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Amazon Reserve',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_LORCANA_CC = [
	{
		key: 'Product Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Category',
		required: true,
		display: false,
		description: TX_SET_NAME,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Qty',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add Qty',
		required: false,
		display: false,
		description: TX_null,
	},
	// {
	// 	key: 'Weight',
	// 	required: false,
	// 	display: false,
	// 	description: TX_null,
	// },
	{
		key: 'Target Qty',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Ebay Reserve',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Amazon Reserve',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_MAGIC_TCG = [
	{
		key: 'TCGplayer Id',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Product Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Set Name',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Number',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Rarity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Total Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add to Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'TCG Marketplace Price',
		required: false,
		display: false,
		description: TX_SELL_PRICE,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_POKEMON_TCG = [
	{
		key: 'TCGplayer Id',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Product Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Set Name',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Number',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Rarity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Total Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add to Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'TCG Marketplace Price',
		required: false,
		display: false,
		description: TX_SELL_PRICE,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_MAGIC_TITAN = [
	{
		key: 'SKU',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Finish',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Total Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_POKEMON_TITAN = [
	{
		key: 'SKU',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Finish',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Printing',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Total Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_LORCANA_TITAN = [
	{
		key: 'SKU',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Finish',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Total Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_STARWARS_TITAN = [
	{
		key: 'SKU',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Finish',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Total Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const BULK_UPLOAD_SCHEMA_GENERAL = [
	{
		key: PROD_BULK_UPLOAD_SCHEMA_KEY_PRODUCT_LINE,
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'SKU',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Name',
		required: true,
		display: true,
		description: TX_null,
	},
	{
		key: 'Permalink',
		required: false,
		display: true,
		description: TX_null,
	},
	{
		key: 'Sell Price',
		required: true,
		display: false,
		description: TX_null,
	},
	{
		key: 'Buy Price',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Condition',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Language',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Weight',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
	{
		key: 'Add Quantity',
		required: false,
		display: false,
		description: TX_null,
	},
];

export const PL_LANGUAGUES = {
	[PL_PERMALINK_LORCANA]: LANG_PRODUCT_LORCANA,
	[PL_PERMALINK_MAGIC]: LANG_PRODUCT_MAGIC,
	[PL_PERMALINK_POKEMON]: LANG_PRODUCT_POKEMON,
	[PL_PERMALINK_STARWARS]: LANG_PRODUCT_STARWARS,
}

export const PL_PLACEHOLDER_VALUES = {
	[PL_PERMALINK_LORCANA]: {
		productName: TX_PLACEHOLDER_PRODUCT_NAME_LORCANA,
	},
	[PL_PERMALINK_MAGIC]: {
		productName: TX_PLACEHOLDER_PRODUCT_NAME_MAGIC,
	},
	[PL_PERMALINK_POKEMON]: {
		productName: TX_PLACEHOLDER_PRODUCT_NAME_POKEMON,
	},
	[PL_PERMALINK_STARWARS]: {
		productName: TX_PLACEHOLDER_PRODUCT_NAME_STARWARS,
	},
};

export const PL_SET_MODELS = {
	[PL_PERMALINK_LORCANA]: LorcanaSet,
	[PL_PERMALINK_MAGIC]: MagicSet,
	[PL_PERMALINK_POKEMON]: PokemonSet,
	[PL_PERMALINK_STARWARS]: StarwarsSet,
};

export const PL_BULK_UPLOAD_FORMATS = {
	[PL_PERMALINK_MAGIC]: [
		{
			key: 'titan',
			name: TX_INV_BULK_UPLOAD_TYPE_TALARIA,
			schema: BULK_UPLOAD_SCHEMA_MAGIC_TITAN,
			allowAddQty: true,
		},
		{
			key: 'tcg',
			name: TX_INV_BULK_UPLOAD_TYPE_TCG_PLAYER,
			schema: BULK_UPLOAD_SCHEMA_MAGIC_TCG,
			allowAddQty: true,
		},
		{
			key: 'crystal',
			name: TX_INV_BULK_UPLOAD_TYPE_CRYSTAL_COMMERCE,
			schema: BULK_UPLOAD_SCHEMA_MAGIC_CC,
			allowAddQty: true,
		},
		// {
		// 	key: 'binder',
		// 	name: TX_INV_BULK_UPLOAD_TYPE_BINDER,
		// 	schema: BULK_UPLOAD_SCHEMA_MAGIC_BINDER,
		// },
		{
			key: 'general',
			name: TX_INV_BULK_UPLOAD_TYPE_GENERAL,
			schema: BULK_UPLOAD_SCHEMA_GENERAL,
			allowAddQty: true,
			defineProductLineValue: true,
		},
	],
	[PL_PERMALINK_POKEMON]: [
		{
			key: 'titan',
			name: TX_INV_BULK_UPLOAD_TYPE_TALARIA,
			schema: BULK_UPLOAD_SCHEMA_POKEMON_TITAN,
			allowAddQty: true,
		},
		{
			key: 'tcg',
			name: TX_INV_BULK_UPLOAD_TYPE_TCG_PLAYER,
			schema: BULK_UPLOAD_SCHEMA_POKEMON_TCG,
			allowAddQty: true,
		},
		{
			key: 'general',
			name: TX_INV_BULK_UPLOAD_TYPE_GENERAL,
			schema: BULK_UPLOAD_SCHEMA_GENERAL,
			allowAddQty: true,
			defineProductLineValue: true,
		},
	],
	[PL_PERMALINK_LORCANA]: [
		{
			key: 'titan',
			name: TX_INV_BULK_UPLOAD_TYPE_TALARIA,
			schema: BULK_UPLOAD_SCHEMA_LORCANA_TITAN,
			allowAddQty: true,
		},
		{
			key: 'crystal',
			name: TX_INV_BULK_UPLOAD_TYPE_CRYSTAL_COMMERCE,
			schema: BULK_UPLOAD_SCHEMA_LORCANA_CC,
			allowAddQty: true,
		},
		{
			key: 'general',
			name: TX_INV_BULK_UPLOAD_TYPE_GENERAL,
			schema: BULK_UPLOAD_SCHEMA_GENERAL,
			allowAddQty: true,
			defineProductLineValue: true,
		},
	],
	[PL_PERMALINK_STARWARS]: [
		{
			key: 'general',
			name: TX_INV_BULK_UPLOAD_TYPE_GENERAL,
			schema: BULK_UPLOAD_SCHEMA_GENERAL,
			allowAddQty: true,
			defineProductLineValue: true,
		},
		{
			key: 'titan',
			name: TX_INV_BULK_UPLOAD_TYPE_TALARIA,
			schema: BULK_UPLOAD_SCHEMA_STARWARS_TITAN,
			allowAddQty: true,
		},
	],
};

export const PL_BULK_UPLOAD_FORMAT_GENERAL = {
	key: 'general',
	name: TX_INV_BULK_UPLOAD_TYPE_GENERAL,
	schema: BULK_UPLOAD_SCHEMA_GENERAL,
	allowAddQty: true,
	defineProductLineValue: true,
}

export const PROD_MAPPING_FOREIGN_MODELS = {
	[API_FOREIGN_MODEL_LORCANA_CARD]: LorcanaCard,
	[API_FOREIGN_MODEL_LORCANA_SEALED]: LorcanaSealed,
	[API_FOREIGN_MODEL_LORCANA_SET]: LorcanaSet,
	[API_FOREIGN_MODEL_MAGIC_CARD]: MagicCard,
	[API_FOREIGN_MODEL_MAGIC_SEALED]: MagicSealed,
	[API_FOREIGN_MODEL_MAGIC_SET]: MagicSet,
	[API_FOREIGN_MODEL_POKEMON_CARD]: PokemonCard,
	[API_FOREIGN_MODEL_POKEMON_SEALED]: PokemonSealed,
	[API_FOREIGN_MODEL_POKEMON_SET]: PokemonSet,
	[API_FOREIGN_MODEL_STARWARS_CARD]: StarwarsCard,
	[API_FOREIGN_MODEL_STARWARS_SEALED]: StarwarsSealed,
	[API_FOREIGN_MODEL_STARWARS_SET]: StarwarsSet,
};

export const PROD_MAPPING_FOREIGN_MODEL_SETS = {
	[API_FOREIGN_MODEL_LORCANA_CARD]: LorcanaSet,
	[API_FOREIGN_MODEL_LORCANA_SEALED]: LorcanaSet,
	[API_FOREIGN_MODEL_MAGIC_CARD]: MagicSet,
	[API_FOREIGN_MODEL_MAGIC_SEALED]: MagicSet,
	[API_FOREIGN_MODEL_POKEMON_CARD]: PokemonSet,
	[API_FOREIGN_MODEL_POKEMON_SEALED]: PokemonSet,
	[API_FOREIGN_MODEL_STARWARS_CARD]: StarwarsSet,
	[API_FOREIGN_MODEL_STARWARS_SEALED]: StarwarsSet,
};

export const PROD_INV_CONFIG_GENERAL = {
	configParams: [
		PROD_KEY_LANGUAGE, 
		PROD_KEY_CONDITION, 
	],
	inventoryOrder: [
		{
			key: PROD_KEY_LANGUAGE,
			labelKey: 'nameTranslation', 
			sequence: LANG_PRODUCT_ALL,
			sortKey: 'code',
		},
		{
			key: PROD_KEY_CONDITION,
			labelKey: 'name', 
			abbrKey: 'short',
			sequence: CONDITIONS_ALL,
			sortKey: 'name',
		},
	],
	labelOrder: [
		PROD_KEY_LANGUAGE, 
		PROD_KEY_CONDITION, 
	],
}

export const PROD_INV_CONFIG_MAGIC = {
	configParams: [
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
		PROD_KEY_CONDITION, 
	],
	inventoryOrder: [
		{
			key: PROD_KEY_LANGUAGE,
			labelKey: 'nameTranslation', 
			sequence: LANG_PRODUCT_MAGIC,
			sortKey: 'code',
		},
		{
			key: PROD_KEY_FINISH,
			labelKey: 'name', 
			sequence: FINISHES_MAGIC,
			sortKey: 'key',
		},
		{
			key: PROD_KEY_CONDITION,
			labelKey: 'name', 
			abbrKey: 'short',
			sequence: CONDITIONS_PRODUCT_TCG,
			sortKey: 'key',
		},
	],
	labelOrder: [
		PROD_KEY_CONDITION, 
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
	],
}

export const PROD_INV_CONFIG_POKEMON = {
	configParams: [
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
		PROD_KEY_CONDITION, 
		PROD_KEY_PRINTING, 
	],
	inventoryOrder: [
		{
			key: PROD_KEY_LANGUAGE,
			labelKey: 'nameTranslation', 
			sequence: LANG_PRODUCT_POKEMON,
			sortKey: 'code',
		},
		{
			key: PROD_KEY_PRINTING,
			labelKey: 'name', 
			sequence: PRINTINGS_POKEMON,
			sortKey: 'key',
		},
		{
			key: PROD_KEY_FINISH,
			labelKey: 'name', 
			sequence: FINISHES_POKEMON,
			sortKey: 'key',
		},
		{
			key: PROD_KEY_CONDITION,
			labelKey: 'name', 
			abbrKey: 'short',
			sequence: CONDITIONS_PRODUCT_TCG,
			sortKey: 'name',
		},
	],
	labelOrder: [
		PROD_KEY_CONDITION, 
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
		PROD_KEY_PRINTING, 
	],
}

export const PROD_INV_CONFIG_LORCANA = {
	configParams: [
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
		PROD_KEY_CONDITION, 
	],
	inventoryOrder: [
		{
			key: PROD_KEY_LANGUAGE,
			labelKey: 'nameTranslation', 
			sequence: LANG_PRODUCT_LORCANA,
			sortKey: 'code',
		},
		{
			key: PROD_KEY_FINISH,
			labelKey: 'name', 
			sequence: FINISHES_LORCANA,
			sortKey: 'key',
		},
		{
			key: PROD_KEY_CONDITION,
			labelKey: 'name', 
			abbrKey: 'short',
			sequence: CONDITIONS_PRODUCT_TCG,
			sortKey: 'name',
		},
	],
	labelOrder: [
		PROD_KEY_CONDITION, 
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
	],
}

export const PROD_INV_CONFIG_STARWARS = {
	configParams: [
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
		PROD_KEY_CONDITION, 
	],
	inventoryOrder: [
		{
			key: PROD_KEY_LANGUAGE,
			labelKey: 'nameTranslation', 
			sequence: LANG_PRODUCT_STARWARS,
			sortKey: 'code',
		},
		{
			key: PROD_KEY_FINISH,
			labelKey: 'name', 
			sequence: FINISHES_STARWARS,
			sortKey: 'key',
		},
		{
			key: PROD_KEY_CONDITION,
			labelKey: 'name', 
			abbrKey: 'short',
			sequence: CONDITIONS_PRODUCT_TCG,
			sortKey: 'name',
		},
	],
	labelOrder: [
		PROD_KEY_CONDITION, 
		PROD_KEY_LANGUAGE, 
		PROD_KEY_FINISH, 
	],
}


export const PROD_PRICING_DEFAULT_MATRIX = {
	segment_uuid: null,
	finish: null,
	is_default: true,
	is_enabled: true,
	rules: [
		{
			is_parent: true,
			condition: CD_NM.key,
			language: LANG_EN,
			mod_percent_sell: 100.0,
			mod_amount_sell: 0,
			mod_percent_buy: 100.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_LP.key,
			language: LANG_EN,
			mod_percent_sell: 90.0,
			mod_amount_sell: 0,
			mod_percent_buy: 85.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_MP.key,
			language: LANG_EN,
			mod_percent_sell: 75.0,
			mod_amount_sell: 0,
			mod_percent_buy: 70.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_HP.key,
			language: LANG_EN,
			mod_percent_sell: 60.0,
			mod_amount_sell: 0,
			mod_percent_buy: 50.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_D.key,
			language: LANG_EN,
			mod_percent_sell: 40.0,
			mod_amount_sell: 0,
			mod_percent_buy: 1.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_NM.key,
			language: LANG_ES,
			mod_percent_sell: 100.0,
			mod_amount_sell: 0,
			mod_percent_buy: 80.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_LP.key,
			language: LANG_ES,
			mod_percent_sell: 90.0,
			mod_amount_sell: 0,
			mod_percent_buy: 65.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_MP.key,
			language: LANG_ES,
			mod_percent_sell: 75.0,
			mod_amount_sell: 0,
			mod_percent_buy: 50.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_HP.key,
			language: LANG_ES,
			mod_percent_sell: 60.0,
			mod_amount_sell: 0,
			mod_percent_buy: 35.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_D.key,
			language: LANG_ES,
			mod_percent_sell: 40.0,
			mod_amount_sell: 0,
			mod_percent_buy: 1.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_NM.key,
			language: null,
			mod_percent_sell: 80.0,
			mod_amount_sell: 0,
			mod_percent_buy: 50.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_LP.key,
			language: null,
			mod_percent_sell: 72.0,
			mod_amount_sell: 0,
			mod_percent_buy: 42.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_MP.key,
			language: null,
			mod_percent_sell: 60.0,
			mod_amount_sell: 0,
			mod_percent_buy: 35.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_HP.key,
			language: null,
			mod_percent_sell: 46.0,
			mod_amount_sell: 0,
			mod_percent_buy: 25.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: CD_D.key,
			language: null,
			mod_percent_sell: 30.0,
			mod_amount_sell: 0,
			mod_percent_buy: 1.0,
			mod_amount_buy: 0,
		},
		{
			is_parent: false,
			condition: null,
			language: null,
			mod_percent_sell: 40.0,
			mod_amount_sell: 0,
			mod_percent_buy: 1.0,
			mod_amount_buy: 0,
		},
	],
}







