import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isFormValid } from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import * as tx from '../../../../constants/strings';

import '../../style/_vieworder.scss';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class OrderModalCancelOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestPending: false,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_ORDER_CANCEL_ORDER_PROMPT;
  }

  validateAll() {
    const errorObj = {};
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const cancelResp = await this.props.ordersCancelOrder({}, this.props.order.publicUuid, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!cancelResp) { return null; }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalCancelOrder OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'pickupsCreateForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          <div className='orderModalCopy'>{t(tx.TX_ORDER_CANCEL_ORDER_COPY)}</div>

        </form>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalCancelOrder));