import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';
import { TABLE_CONFIG_PRODUCT_LINE, TABLE_SCHEMA_PRODUCT_LINE } from '../../constants/tables';
import { URL_ADMIN_INVENTORY } from '../../constants/urls';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import * as productLineActionCreators from '../../actions/product-line';
let allActionCreators = Object.assign({}, productLineActionCreators);

export class ProductLines extends Component {

  componentDidMount() {
    if(this.props.productLine && this.props.productLine.productLines === null) {     
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  render() {
    return <div className={'ProductLines AdminPage'}>
      <AdminTitle
        title={tx.TX_PRODUCT_LINES}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: tx.TX_CATALOG,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_PRODUCT_LINE}
          schema={TABLE_SCHEMA_PRODUCT_LINE}
          data={this.props.productLine.productLines} 
          dataLoading={this.props.productLine.productLinesPending} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLines));