import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { logout } from '../../utils/auth';
import { 
  isFormValid, 
  getPasswordInputError, 
  getPasswordSetError, 
  getPasswordMatchError, 
} from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import * as tx from '../../constants/strings';
import { URL_HOME } from '../../constants/urls';

import './style/_auth.scss';

import * as authActionCreators from '../../actions/auth';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, userActionCreators);

export class SetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {

      passwordOld: '',
      passwordOldError: '',
      password: '',
      passwordError: '',
      passwordAgain: '',
      passwordAgainError: '',

      setPasswordReqError: null,
      setPasswordReqPending: false,
    };
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const password_data = {
        old_password: this.state.passwordOld,
        new_password: this.state.password,
      };

      this.setState({ 
        setPasswordReqPending: true,
        setPasswordReqError: null,
      });

      this.props.usersSetPassword(password_data)
      .then((resp) => {
        
        // Record new auth, push to homepage
        this.props.authSet({ need_password_reset: false });
        this.props.history.push(URL_HOME);
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          setPasswordReqPending: false,
          setPasswordReqError: formatServerError(errResp),
        });
      });
    }
  }

  validateAll() {
    let errorObj = {
      passwordOldError: getPasswordInputError(this.state.passwordOld),
      passwordError: getPasswordSetError(this.state.password),
      passwordAgainError: getPasswordMatchError(this.state.passwordAgain, this.state.password),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeOldPassword(evt) {
    this.setState({ passwordOld: evt.target.value }, () => {
      if(this.state.passwordOldError) {
        this.validateOldPassword();
      }
    });
  }

  validateOldPassword() {
    this.setState({ passwordOldError: getPasswordInputError(this.state.passwordOld) });
  }

  changePassword(evt) {
    this.setState({ password: evt.target.value }, () => {
      if(this.state.passwordError) {
        this.validatePassword();
      }
    });
  }

  validatePassword() {
    this.setState({ passwordError: getPasswordSetError(this.state.password) }, () => {
      if(this.state.passwordAgain !== '' && this.state.passwordAgainError !== '') {
        this.validatePasswordAgain();
      }
    });
  }

  changePasswordAgain(evt) {
    this.setState({ passwordAgain: evt.target.value }, () => {
      if(this.state.passwordAgainError) {
        this.validatePasswordAgain();
      }
    });
  }

  validatePasswordAgain() {
    this.setState({ passwordAgainError: getPasswordMatchError(this.state.passwordAgain, this.state.password) });
  }

  logoutUser() {
    this.props.usersLogout()
    .then(() => {
      window.location.href = URL_HOME;
    })
    .catch((errResp) => {
      console.error(errResp);
      window.location.href = URL_HOME;
    });
    logout();
  }

  render() {

    const {t} = this.props;

    return <div className={'SetPassword authPage'}>
      <div className='authPageTitle'>{t(tx.TX_AUTH_SET_PASSWORD)}</div>
      <div className='authPageBody'>
        <div className='loginWrapper'>

          <div className={'registerToggle registerToggleTop'}>
            <div className='toggleHeader'>{t(tx.TX_AUTH_SET_PASSWORD)}</div>
            <div className='toggleCopy'>{t(tx.TX_AUTH_SET_PASSWORD_COPY, {storeName: process.env.REACT_APP_WEBSITE_NAME})}</div>
          </div>

          <form className='loginForm' onSubmit={this.handleSubmit.bind(this)}>
            <div className={this.state.setPasswordReqError ? 'loginFormError present' : 'loginFormError'}>{t(this.state.setPasswordReqError)}</div>

            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_CURRENT_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='current-password'
                className={this.state.passwordOldError ? 'InputError' : ''}
                value={this.state.passwordOld}
                onChange={this.changeOldPassword.bind(this)}
                onBlur={this.validateOldPassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)}
                maxLength={100} />
              {this.state.passwordOldError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordOldError)}</div> :
                null
              }
            </div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_NEW_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='new-password'
                className={this.state.passwordError ? 'InputError' : ''}
                value={this.state.password}
                onChange={this.changePassword.bind(this)}
                onBlur={this.validatePassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)}
                maxLength={100} />
              {this.state.passwordError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordError)}</div> :
                null
              }
            </div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_RETYPE_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='new-password'
                className={this.state.passwordAgainError ? 'InputError' : ''}
                value={this.state.passwordAgain}
                onChange={this.changePasswordAgain.bind(this)}
                onBlur={this.validatePasswordAgain.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)}
                maxLength={100} />
              {this.state.passwordAgainError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordAgainError)}</div> :
                null
              }
            </div>
            <div className='loginFieldWrapper'>
              <button 
                className='loginSubmitButton'
                disabled={this.state.setPasswordReqPending}>
                {t(tx.TX_AUTH_SET_PASSWORD)}
              </button>
            </div>
            
          </form>
          <div className='registerToggle'>
            <div className='toggleHeader'>{t(tx.TX_AUTH_NOT_READY)}</div>

            <div className='logoutWrapper'>
              <button className={'toggleButton soloButton'} onClick={this.logoutUser.bind(this)}>{t(tx.TX_LOGOUT)}</button>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(SetPassword));