import React, { Component } from 'react';
import { connect } from 'react-redux';

import Play from './lib/Play';

import './style/_icons.scss';

let allActionCreators = Object.assign({});

export class LoadingIcon extends Component {

  static defaultProps = {
    onClick: () => {},
  }

  getLoadingIconValue() {
    return <Play />;
  }

  render() {
    return <div className={this.props.iconClass ? 'LoadingIcon Icon TitanIcon ' + this.props.iconClass : 'LoadingIcon Icon TitanIcon'} onClick={this.props.onClick.bind(this)}>
      <div className='iconSVGWrapper'>
        {this.getLoadingIconValue()}
      </div>
      {this.props.iconLabel ?
        <div className={this.props.ellipsisLabel ? 'iconLabel EllipsisElement' : 'iconLabel'}>{this.props.iconLabel}</div> :
        null
      }
      <div className='iconBottom'></div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(LoadingIcon);