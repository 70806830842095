import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL } from '../../constants/gallery';
import { ICON_ERROR } from '../../constants/icons';
import { PROD_PAGE_LAYOUT_DETAILS_CLASS_GENERAL } from '../../constants/product';
import * as tx from '../../constants/strings';
import { URL_BUY_GENERAL } from '../../constants/urls';

import { viewItemEvent } from '../../utils/analytics';
import { formatServerError } from '../../utils/formatting';
import { getGalleryFilterStorageKey } from '../../utils/gallery';

import Icon from '../Icons/Icon';
import LoadingIcon from '../Icons/LoadingIcon';
import ProductPageDetails from './blocks/ProductPageDetails';
import ProductPageInventory from './blocks/ProductPageInventory';
import ProductPageMedia from './blocks/ProductPageMedia';
import ProductPageMediaForeignModel from './blocks/ProductPageMediaForeignModel';
import ProductPageTitle from './blocks/ProductPageTitle';

import './style/_productpage.scss';

import * as productActionCreators from '../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class ProductPage extends Component {

  constructor(props) {
    super(props);

    this.isBuylist = this.props.isBuylist ? true : false;
    this.allowBack = props.location && props.location.state && props.location.state.allowBack ? props.location.state.allowBack : '';
    this.primaryFilterKey = getGalleryFilterStorageKey(GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL);

    this.state = {
      requestPending: true,
      requestError: null,
      responseObject: null,

      inventoryOptions: [],
      selectedInvObj: null, 
      modelOverride: null,

      invHeight: null,
    }

    // Controllers
    this.controller = null;

    // Element reference
    this.invRef = React.createRef();

    this.heightInterval = null;
    this.initTimeout = null;

    this.checkTopSectionHeightThrottled = _.throttle(this.checkTopSectionHeight.bind(this), 100);
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkTopSectionHeightThrottled, false);
    this.fetchProduct();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.match.params.productLinePermalink !== prevProps.match.params.productLinePermalink || this.props.match.params.productPermalink !== prevProps.match.params.productPermalink) {
      this.fetchProduct();
    }
    this.checkTopSectionHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkTopSectionHeightThrottled, false);
    if(this.controller) {
      this.controller.abort();
    }
    if(this.heightInterval) {
      clearInterval(this.heightInterval);
    }
    if(this.initTimeout) {
      clearTimeout(this.initTimeout);
    }
  }

  checkTopSectionHeight() {
    if(this.invRef && this.invRef.current) {
      if(this.invRef.current.getBoundingClientRect().height !== this.state.invHeight) {
        this.setState({ invHeight: this.invRef.current.getBoundingClientRect().height });
      }
    }
  }

  getTopSectionStyle() {
    const SPACING_BUFFER = 20;
    return this.state.invHeight ? { minHeight: `${this.state.invHeight + SPACING_BUFFER}px` } : {};
  }

  fetchProduct(service = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      responseObject: null,
      inventoryOptions: [],
      modelOverride: null,
    });

    // Get product details
    if(this.props.match.params.productLinePermalink && this.props.match.params.productPermalink) {

      this.props.productFetchSingleExternal(this.props.match.params.productPermalink, this.props.match.params.productLinePermalink, this.isBuylist, controller.signal)
      .then((resp) => {

        this.setState({
          requestPending: false,
          requestError: null,
          responseObject: resp,
          inventoryOptions: resp.inventory,
          modelOverride: resp.foreignModel,
        });

        // Start height interval
        this.heightInterval = setInterval(this.checkTopSectionHeightThrottled, 100);
        this.initTimeout = setTimeout(() => {
          if(this.heightInterval) {
            clearInterval(this.heightInterval);
          }
        }, 10*1000);

        // Fire view_item_list analytics event
        viewItemEvent([ resp ], {});
      })
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responseObject: null,
          inventoryOptions: [],
          modelOverride: null,
        });
      });
    }
  }

  selectInventory(invObj) {
    this.setState({ selectedInvObj: invObj });
  }

  setInventoryOptions(invArray) {
    this.setState({ inventoryOptions: invArray });
  }

  getForeignModel() {
    // Foreign model used to only be on product, but now since it's on inventory have to do a little dancing to get the right one

    if(!this.state.inventoryOptions || !this.state.inventoryOptions.length) { return this.state.modelOverride; }

    let useOptionModel = false;

    let singleLanguage = true;
    const languageValue = this.state.inventoryOptions[0] && this.state.inventoryOptions[0].language ? this.state.inventoryOptions[0].language : null;
    if(!languageValue) {
      return this.state.modelOverride;
    }

    for(const op of this.state.inventoryOptions) {
      if(op.language && op.language.code !== languageValue.code) {
        singleLanguage = false;
        break;
      }
    }

    if(this.state.inventoryOptions[0].foreignModel) {

      // There may be future variations of choosing an inventory model, that's why there's a simple if inside a simple if

      if(singleLanguage) {
        useOptionModel = true;
      }
    }

    if(useOptionModel) {
      return this.state.inventoryOptions[0].foreignModel;
    }

    return this.state.modelOverride;
  }

  getMediaBlock() {
    
    if(!this.state.responseObject) {
      return null;
    }

    // Look at FK objects, not product line to account for generic products
    if(this.state.responseObject.foreignModel) {
      return <ProductPageMediaForeignModel
                productObj={this.state.responseObject}
                modelOverride={this.state.selectedInvObj && this.state.selectedInvObj.foreignModel ? this.state.selectedInvObj.foreignModel : this.getForeignModel()} />;
    }
    return <ProductPageMedia
              productObj={this.state.responseObject} />;
  }

  getDetailsBlock() {
    
    if(!this.state.responseObject) {
      return null;
    }

    // Look at FK objects, not product line to account for generic products
    if(this.state.responseObject.foreignModel) {

      const ForeignDetailComponent = this.state.responseObject.foreignModel.componentProductPageDetails;
      if(ForeignDetailComponent) {
        return <ForeignDetailComponent
                  productObj={this.state.responseObject}
                  modelOverride={this.state.selectedInvObj && this.state.selectedInvObj.foreignModel ? this.state.selectedInvObj.foreignModel : this.getForeignModel()} />;
      }
    }
    return <ProductPageDetails
              productObj={this.state.responseObject} />;
  }

  getPageBodyClass() {
    
    const baseClass = 'productPageBody';
    if(!this.state.responseObject) {
      return baseClass;
    }

    if(this.state.responseObject.foreignModel && this.state.responseObject.foreignModel.productPageLayoutClass) {
      return `${baseClass} ${this.state.responseObject.foreignModel.productPageLayoutClass}`;
    }
    return baseClass;
  }

  getDetailClass() {
    if(this.state.responseObject.foreignModel) {
      return this.state.responseObject.foreignModel.productPageDetailLayoutClass;
    }
    return PROD_PAGE_LAYOUT_DETAILS_CLASS_GENERAL;
  }

  getSearchFilters() {
    return this.primaryFilterKey && this.props.product.filters[this.primaryFilterKey] ? this.props.product.filters[this.primaryFilterKey] : {};
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductPage'}>
      <div className='productPageWrapper'>

        {this.state.requestPending ?
          <div className='productPageLoading'>
            <div className='loadingIcon'>
              <LoadingIcon />
            </div>
            <div className='loadingLabel'>{t(tx.TX_LOADING)}</div>
          </div> :
          <>
            {this.state.responseObject === null || this.state.requestError !== null ?
              <div className='productPageError'>
                <div className='errorIcon'>
                  <Icon
                    value={ICON_ERROR} />
                </div>
                <div className='errorLabel'>{t(tx.TX_PRODUCT_PAGE_NOT_FOUND)}</div>
                <div className='errorCopy'>{t(tx.TX_PRODUCT_PAGE_NOT_FOUND_COPY)}</div>
                <div className='errorAction'>
                  <Link to={URL_BUY_GENERAL}>
                    <button type='button' className='errorActionButton'>{t(tx.TX_PRODUCT_PAGE_SEARCH_PRODUCTS)}</button>
                  </Link>
                </div>
              </div> :
              <div className={this.getPageBodyClass()}>
                <div className='ppTitleWrapper'>
                  <ProductPageTitle
                    productObj={this.state.responseObject} />
                </div>

                <div className='ppTopSectionWrapper' style={this.getTopSectionStyle()}>
                  <div className='ppMediaWrapper'>
                    {this.getMediaBlock()}
                  </div>
                  <div className={`ppDetailsWrapper ${this.getDetailClass()}`}>
                    {this.getDetailsBlock()}
                  </div>
                  <div className='ppInventoryWrapper' ref={this.invRef}>
                    <ProductPageInventory
                      isBuylist={this.isBuylist}
                      productObj={this.state.responseObject}
                      inventoryOptions={this.state.inventoryOptions}
                      setInventoryOptions={this.setInventoryOptions.bind(this)}
                      selectInvObj={this.selectInventory.bind(this)}
                      selectedInvObj={this.state.selectedInvObj}
                      allowBack={this.allowBack}
                      searchFilters={this.getSearchFilters()} />
                  </div>
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    product: state.product,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPage));