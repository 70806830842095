import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
} from '../../../constants/api';
import * as tx from '../../../constants/strings';

import { 
  breaksToBrs,
  formatPrice,
  formatServerError, 
  serverDateFull,
  serverTimeFull,
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';
import { 
  getPageLimit, 
  getPageOffset, 
} from '../../../utils/request';

import LoadingIcon from '../../Icons/LoadingIcon';

import * as customerActionCreators from '../../../actions/customer';
const allActionCreators = Object.assign({}, customerActionCreators);

export class CustomerStoreCreditView extends Component {

  constructor(props) {
    super(props);

    this.state = {

      pageNum: 1,

      requestPending: true,
      requestError: null,
      responseObject: null,
      responseCount: null,
    };

    this.PAGE_SIZE = 10;

    this.controller = null;
  }

  componentDidMount() {
    if(this.props.customer) {
      this.fetchCustomerStoreCredit();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.customer === null && this.props.customer !== null) {
      this.fetchCustomerStoreCredit();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async fetchCustomerStoreCredit() {

    if(!this.props.customer) { return null; }

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      responseObject: null,
    });

    const getParams = {
      [API_KEY_LIMIT]: getPageLimit(this.state.pageNum, this.PAGE_SIZE),
      [API_KEY_OFFSET]: getPageOffset(this.state.pageNum, this.PAGE_SIZE),
    };

    // Get store credit list
    const creditListResp = await this.props.customerStoreCreditHistory(this.props.customer.publicUuid, getParams, controller.signal)
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responseObject: null,
        });
      });

    if(!creditListResp) {
      return null;
    }

    this.setState({
      requestPending: false,
      requestError: null,
      responseObject: creditListResp.data,
      responseCount: creditListResp.count,
    });
  }

  getTotalPages() {
    if(!this.state.requestPending && this.state.responseCount > 0) {
      return Math.ceil(this.state.responseCount / this.PAGE_SIZE);
    }
    return 0;
  }

  decreasePage() {
    if(this.state.pageNum > 1) {
      this.setState({
        pageNum: this.state.pageNum - 1,
      }, () => {
        this.fetchCustomerStoreCredit();
      });
    }
  }

  increasePage() {
    if(this.state.pageNum < this.getTotalPages()) {
      this.setState({
        pageNum: this.state.pageNum + 1,
      }, () => {
        this.fetchCustomerStoreCredit();
      });
    }
  }

  getEntryNote(entry) {

    if(!entry) { return ''; }

    if(entry.order) {
      return this.props.t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: entry.note });
    } else if(entry.refund) {
      return this.props.t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: entry.note });
    } else if(entry.bulkUpload) {
      return this.props.t(tx.TX_CUSTOMER_STORE_CREDIT_STORE_UPDATE);
    }
    return breaksToBrs(entry.note);
  }

  render() {

    const {t} = this.props;

    return <div className={'CustomerStoreCreditView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>
          <div className='asTitleValue'>{t(tx.TX_MENU_ITEM_STORECREDIT)}</div>
        </div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          {this.state.responseObject ?
            <>
              <div className='entryHeader'>
                <div className='entryBalanceWrapper'>
                  <div className='balanceLabel'>{t(tx.TX_BALANCE)}</div>
                  <div className='balanceValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.customer ? this.props.customer.storeCredit.balance : 0, { addTags: true, language: this.getLanguage() }) }} />
                </div>
                <div className='entryActionWrapper'>
                  <div className={'addStoreCreditButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_ADD)}</div>
                </div>
              </div>
              {this.state.responseCount === 0 ?
                <div className='noOrders'>
                  <div className='noOrdersTitle'>{t(tx.TX_CUSTOMER_NO_STORE_CREDIT_TITLE)}</div>
                  <div className='noOrdersCopy'>{t(tx.TX_CUSTOMER_NO_STORE_CREDIT_COPY)}</div>
                </div> :
                <div className='entryList'>
                  <div className='entryListLiner'>
                    <div className='entryListHeader'>
                      <div className='entryListHeaderLiner'>
                        <div className={'dateCell headerCell'}>{t(tx.TX_DATE)}</div>
                        <div className={'balanceCell headerCell'}>{t(tx.TX_BALANCE)}</div>
                        <div className={'changeCell headerCell'}>{t(tx.TX_CHANGE)}</div>
                        <div className={'noteCell headerCell'}>{t(tx.TX_DESCRIPTION)}</div>
                      </div>
                    </div>
                    <div className='entryListBody'>
                      <div className='entryListBodyLiner'>
                        {this.state.responseObject.map((entry, i) => {
                          return <div key={i} className='entryLine'>
                            <div className='dateCell'>
                              <div className='dateLongValue'>
                                <div className='dateDate'>{serverDateFull(entry.date, this.getLanguage())}</div>
                                <div className='dateTime'>{serverTimeFull(entry.date, this.getLanguage())}</div>
                              </div>
                            </div>
                            <div className='balanceCell'>
                              <div className='balanceValue' dangerouslySetInnerHTML={{ __html: formatPrice(entry.balance, { addTags: true, language: this.getLanguage() }) }} />
                            </div>
                            <div className='changeCell'>
                              <div className='changeValue' dangerouslySetInnerHTML={{ __html: formatPrice(entry.delta, { addTags: true, language: this.getLanguage() }) }} />
                            </div>
                            <div className='noteCell'>
                              <div className='noteValue' dangerouslySetInnerHTML={{ __html: this.getEntryNote(entry) }} />
                            </div>
                          </div>;
                        })}
                      </div>
                    </div>
                  </div>
                  {!this.state.requestPending && this.state.responseCount > 0 && this.getTotalPages() > 1 ?
                    <div className='paginationWrapper'>
                      <div className='paginationLiner'>
                        <div className={`leftArrow ${this.state.pageNum <= 1 ? 'noNav' : ''}`} onClick={this.decreasePage.bind(this)}>&lt;</div>
                        <div className='pageStatus' dangerouslySetInnerHTML={{ __html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, { current: this.state.pageNum, total: this.getTotalPages() }) }} />
                        <div className={`rightArrow ${this.state.pageNum >= this.getTotalPages() ? 'noNav' : ''}`} onClick={this.increasePage.bind(this)}>&gt;</div>
                      </div>
                    </div> :
                    null
                  }
                </div>
              }
            </> :
            <div className='noOrders'></div>
          }
        </div>
      </div>
      <div className='adminSectionPending' style={{display: (this.props.customerPending || this.state.requestPending) && this.props.expanded ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerStoreCreditView));