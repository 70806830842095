import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { productFetchSingle } from '../../../../actions/product';

import BulkTagManager from '../../../Inventory/blocks/tag-manager/BulkTagManager';
import ProductTagManager from '../../../Inventory/blocks/tag-manager/ProductTagManager';

export const BulkActionManageTags = (props) => {

  const dispatch = useDispatch();
  
  // Props
  const {
    closeMethod, 
    refreshData,
    selectedRows, 
  } = props;

  // State
  const [requestError, setRequestError] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [products, setProducts] = useState(selectedRows);

  const { t } = useTranslation();

  const refreshTableData = async () => {

    setRequestPending(true);
    
    // Refresh table
    refreshData(false, false, true, true);

    // productPermalink, plPermalink
    // productFetchSingle

    // Refresh products manually;
    // Once we refresh table, there's no guarantee that the new products will match filters
    // So we refresh manually 
    const productRefreshes = [];
    for(const prod of products) {
      productRefreshes.push(dispatch(productFetchSingle(prod.permalink, prod.productLine.permalink)));
    }

    const productRespArray = await Promise.all(productRefreshes)
      .catch((errResp) => {
        console.error(errResp);
      });

    setProducts(productRespArray);
    setRequestPending(false);
  };

  return (
    <div className={'BulkActionManageTags BulkActionModal'}>
      <div className='baWrapper'>

        <div className={`baFormError ${requestError ? 'present' : ''}`}>{t(requestError)}</div>
        
        {!products || products.length === 0 ?
          <div className='errorSelection'>
            <div className='errorBody'>
              <div className='errorLabel'>{t(tx.TX_INV_TAGS_NO_PRODUCTS_SELECTED)}</div>
              <button className={'errorAction'} type='button' onClick={closeMethod}>{t(tx.TX_CLOSE)}</button>
            </div>
          </div> :
          <div className='tagManagerWrapper'>
            {products.length === 1 ?
              <ProductTagManager
                product={products[0]}
                productPending={requestPending}
                makeClean={refreshTableData}
                setRequestError={setRequestError} /> :
              <BulkTagManager
                products={products}
                productPending={requestPending}
                makeClean={refreshTableData}
                setRequestError={setRequestError} />
            }
          </div>
        }

      </div>
    </div>
  );
};

export default BulkActionManageTags;




