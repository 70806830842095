

import { APP_CRYPT_KEY } from '../constants/app';

const CryptoJS = require('crypto-js');


export function encryptObj(plainText) {
	return CryptoJS.AES.encrypt(JSON.stringify(plainText), APP_CRYPT_KEY).toString();
}

export function decryptObj(cipherText) {
	if(!cipherText) { return cipherText; }
	return JSON.parse(CryptoJS.AES.decrypt(cipherText, APP_CRYPT_KEY).toString(CryptoJS.enc.Utf8));
}

export function encryptString(plainText) {
	return CryptoJS.AES.encrypt(plainText, APP_CRYPT_KEY).toString();
}

export function decryptString(cipherText) {
	if(!cipherText) { return cipherText; }
	return CryptoJS.AES.decrypt(cipherText, APP_CRYPT_KEY).toString(CryptoJS.enc.Utf8);
}