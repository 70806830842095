import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { breaksToBrs } from '../../../utils/formatting';

let allActionCreators = Object.assign({});

export class ProductPageDetailsPokemon extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getProductName() {
    return this.props.productObj.foreignModel.name;
  }

  getProductTypeLine() {
    return this.props.productObj.foreignModel.type || '';
  }

  getProductRules() {
    return this.props.productObj.foreignModel.rules;
  }

  getAttacks() {
    if(!this.props.productObj || !this.props.productObj.foreignModel) { return []; }
    return this.props.productObj.foreignModel.attacks || [];
  }

  getProductSecondaryDescription() {
    const txt = this.props.productObj.foreignModel.flavorText;
    return breaksToBrs(txt, this.descriptionBreakStyle);
  }

  hasBoardedCopy() {
    return this.getProductRules().length > 0 || this.getProductSecondaryDescription();
  }

  render() {

    if(!this.props.productObj || !this.props.productObj.foreignModel || !this.props.productObj.foreignModel.isPokemonCard) { return null; }

    const {t} = this.props;

    return <div className={'ProductPageDetailsPokemon ProductPageDetails'}>
      <div className='productPageDetailsWrapper'>
        <div className='ppPokemonWrapper' lang={this.props.productObj.foreignModel.language}>
          <div className='ppPokemonFaceWrapper'>
            <div className='ppHeaderWrapper'>
              <div className='ppmTitle' lang={this.props.productObj.foreignModel.language}>
                <span className='ppmTitleText'>{this.getProductName()}</span>
              </div>
              <div className='ppmTypeLine'>{this.getProductTypeLine()}</div>
            </div>
            {this.getAttacks().length ?
              <div className={'ppPokemonAttackBlockWrapper ppPokemonAttributeBlock'}>
                <div className='attributeTitleWrapper'>
                  <div className='attributeTitleValue'>{t(this.getAttacks().length === 1 ? tx.TX_POKEMON_ATTRIBUTE_ATTACK : tx.TX_POKEMON_ATTRIBUTE_ATTACKS)}</div>
                </div>
                {this.getAttacks().map((att, i) => {
                  return <div key={i} className='ppPokemonAttackWrapper'>
                    <div className='attackTitle'>
                      <div className='titleValue'>{att.name}</div>
                      <div className='titleDamage'>{att.damage}</div>
                    </div>
                    {att.text ?
                      <div className='attackText'>{att.text}</div> :
                      null
                    }
                  </div>
                })}
              </div> :
              null
            }
            {this.hasBoardedCopy() ?
              <div className='ppBorderWrapper'>
                <div className='ppPokemonDetails'>
                  {this.getProductRules().map((rule, j) => {
                    return <div key={j} className='ppmDescription'>{rule.rule}</div>
                  })}
                  <div 
                    className='ppmSecondaryDescription'
                    dangerouslySetInnerHTML={{ __html: this.getProductSecondaryDescription() }} />
                </div>
              </div> :
              null
            }
          </div>
          {this.props.productObj.foreignModel.collectorNumber ?
            <div className='collectorNumberWrapper'>
              <div className='collectorNumber'>
                <div className='collectorNumberLabel'>{t(tx.TX_COLLECTOR_NUMBER)}</div>
                <div className='collectorNumberValue'>{this.props.productObj.foreignModel.collectorNumber}</div>
              </div>
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetailsPokemon));