export const loadGoogleTagManager = (gtmId) => {
  
  // Loads Google GMT
  if(!gtmId) { return null; }

  const id = `google-tag-manager-${gtmId}`;
  const src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

  // We have at least one script (React)
  const firstJs = document.getElementsByTagName('script')[0];
    
  // Prevent script from loading twice;
  if(document.getElementById(id)) { 
    return null; 
  }

  // Create script to load tag manager
  const js = document.createElement('script');
  js.id = id;
  js.src = src;
  js.async = true;
  firstJs.parentNode.insertBefore(js, firstJs);

  // Initialize the dataLayer and gtag function
  window.dataLayer = window.dataLayer || [];
  window.gtag = function() {
    window.dataLayer.push(arguments);
  }

  // Initialize Google Tag manager
  window.gtag({'gtm.start': new Date().getTime(), event: 'gtm.js'});
}