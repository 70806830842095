import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as p from '../../constants/permissions';
import * as tx from '../../constants/strings';
import { TABLE_CONFIG_ADMIN_USERS, TABLE_SCHEMA_ADMIN_USERS } from '../../constants/tables';
import {
  URL_ADMIN_SETTINGS, 
} from '../../constants/urls';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import * as customerActionCreators from '../../actions/customer';
let allActionCreators = Object.assign({}, customerActionCreators);

export class AdminUsers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adminData: [],
      adminPending: true,
    };
  }

  componentDidMount() {
    this.props.customerFetchAdminUsers()
    .then((resp) => {
      this.setState({
        adminData: this.flattenAdminResp(resp),
        adminPending: false,
      });
    })
    .catch((errResp) => {
      if(errResp) {
        console.error(errResp);
      }
    });
  }

  flattenAdminResp(respData) {
    let flattenedResp = [];

    for(const adminLine of respData) {
      let flatAdmin = Object.assign({}, adminLine, {
        [p.P_MANAGE_EVENTS]: adminLine.permissions[p.P_MANAGE_EVENTS] ? true : false,
        [p.P_MANAGE_PERMISSIONS]: adminLine.permissions[p.P_MANAGE_PERMISSIONS] ? true : false,
        [p.P_MANAGE_PRODUCTS]: adminLine.permissions[p.P_MANAGE_PRODUCTS] ? true : false,
        [p.P_MANAGE_SALES]: adminLine.permissions[p.P_MANAGE_SALES] ? true : false,
        [p.P_MANAGE_USERS]: adminLine.permissions[p.P_MANAGE_USERS] ? true : false,
      });
      flattenedResp.push(flatAdmin);
    }
    return flattenedResp;
  }

  render() {
    return <div className={'AdminUsers AdminPage'}>
      <AdminTitle
        title={tx.TX_SETTINGS_ADMIN_USERS}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_ADMIN_USERS}
          schema={TABLE_SCHEMA_ADMIN_USERS}
          data={this.state.adminData}
          dataLoading={this.state.adminPending} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminUsers));