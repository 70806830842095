import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { 
  breaksToBrs,
  formatPrice, 
  serverDateFull,
  serverTimeFull,
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

const allActionCreators = Object.assign({});

export class AccountStoreCreditLineItem extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getFormattedNote() {
    if(!this.props.entry) { return ''; }

    if(this.props.entry.order) {
      return this.props.t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: this.props.entry.note });
    } else if(this.props.entry.refund) {
      return this.props.t(tx.TX_ORDER_REFUND_ORDER_NUMBER_DYNAMIC, { orderNum: this.props.entry.note });
    } else if(this.props.entry.bulkUpload) {
      return this.props.t(tx.TX_CUSTOMER_STORE_CREDIT_STORE_UPDATE);
    } else {
      return breaksToBrs(this.props.entry.note);
    }
  }

  render() {

    if(!this.props.entry) {
      return null;
    }

    return <div className={'AccountStoreCreditLineItem'}>
      <div className='ascLiner'>
        <div className='rowWrapper'>

            <div className={'rowCell dateCell'}>
              <div className='dateWrapper'>
                <div className='dateDate'>{serverDateFull(this.props.entry.date, this.getLanguage())}</div>
                <div className='dateTime'>{serverTimeFull(this.props.entry.date, this.getLanguage())}</div>
              </div>
            </div>
            <div className='rowCell descriptionCell'>
              <div className='descriptionValue' dangerouslySetInnerHTML={{ __html: this.getFormattedNote() }} />
            </div>
            <div className='rowCell changeCell'>
              <div className='changeValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.entry.delta, { addTags: true, language: this.getLanguage() }) }} />
            </div>
            <div className={'rowCell balanceCell'}>
              <div className='balanceValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.entry.balance, { addTags: true, language: this.getLanguage() }) }} />
            </div>

        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountStoreCreditLineItem));