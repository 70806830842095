import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  CSS_SLIDE_DURATION 
} from '../../../../constants/css';
import { 
  TX_CANCEL, 
  TX_null, 
} from '../../../../constants/strings';

import '../../../Popups/style/_saveprompt.scss';

let allActionCreators = Object.assign({});

export class OrderControlModal extends Component {

  // REQUIRED PROPS
  // open: bool
  // closeMethod: function

  // OPTIONAL PROPS
  // copy: ARRAY (overwrites default copy; different than SavePrompt)
  // title: string (overwrites default title)
  // onCancel: function
  // onConfirm: function


  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,
      listenerEnabled: false,
      preventClose: false,

      confirmAllowed: true,
      
      customSuccessLabel: null,
      customTitle: null,
      supplementalActionLabel: null,

      confirmSignal: 0,
      supplementalSignal: 0,

      lastChildUpdate: Date.now(),
    };

    // Create refs
    this.modalBody = React.createRef();
    this.checkClick = this.checkClick.bind(this);

    // Timeout management
    this.openTimeout = null;
    this.closeTimeout = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    document.removeEventListener('click', this.checkClick, false);

    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.open !== prevProps.open) {
      if(this.props.open === true) {
        this.openPrompt();
      } else {
        this.closePrompt();
      }
    }
  }

  checkClick(evt) {
    // Removed; uncomment to close when clicked outside of modal
    // if(this.props.open && this.state.listenerEnabled) {
    //   let targetElement = evt.target;
    //   do {
    //       if(targetElement === this.modalBody.current) {
    //           return;
    //       }
    //       targetElement = targetElement.parentNode;
    //   } while (targetElement);
    //   this.props.closeMethod();
    // }  
  }

  openPrompt() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
      listenerEnabled: false,
      supplementalActionLabel: null,
    }, () => {

      // Delay to trigger CSS animation
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, 25);

      // Delay listening for clicks
      this.openTimeout = setTimeout(() => {
        this.setState({
          listenerEnabled: true,
        });
      }, CSS_SLIDE_DURATION);

      // Fix body
      document.body.style.overflow = 'hidden';
    });
  }

  closePrompt() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
      listenerEnabled: false,
      supplementalActionLabel: null,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({
          shouldDisplay: false,
          customSuccessLabel: null,
          customTitle: null,
          supplementalActionLabel: null,
        });
        if(this.props.clearModalData) {
          this.props.clearModalData();
        }
      }, CSS_SLIDE_DURATION);
    });
  }

  childDidUpdate() {
    this.setState({ lastChildUpdate: Date.now() });
  }

  getScreenClass() {
    return this.state.hasOpacity ? 'spScreen withOpacity' : 'spScreen';
  }

  getModalStyle() {

    const baseConfig = {
      opacity: this.state.hasOpacity ? '1' : '0',
      top: '250px',
    };

    let verticalConfig = {};

    if(this.props.open && this.modalBody && this.modalBody.current && this.modalBody.current.clientHeight > 0) {
      
      const modalHeight = this.modalBody.current.clientHeight;
      verticalConfig = {
        top: (window.innerHeight - modalHeight)/2 + 'px',
      }
    }

    return Object.assign({}, baseConfig, verticalConfig);
  }

  getTitle() {
    return this.state.customTitle || this.props.modalTitle || TX_null;
  }

  cancelAction(evt) {
    evt.preventDefault();

    if(this.state.preventClose) {
      return null;
    }

    if(this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.closeMethod();
  }

  allowConfirm(isAllowed) {
    this.setState({ confirmAllowed: !!isAllowed });
  }

  getChildComponent() {
    if(!this.props.modalComponent) { return null; }

    const additionalProps = this.props.additionalProps || {};

    const ModalComponent = this.props.modalComponent;
    return <ModalComponent 
              order={this.props.order}
              orderPending={this.props.orderPending}
              orderPendingSilently={this.props.orderPendingSilently}
              confirmSignal={this.state.confirmSignal}
              completeAction={this.clearActions.bind(this)}
              makeClean={this.props.makeClean}
              blockClose={this.props.blockClose}
              closeMethod={this.tryToClose.bind(this)}
              allowConfirm={this.allowConfirm.bind(this)}
              didUpdate={this.childDidUpdate.bind(this)}
              setSuccessLabel={this.setSuccessLabel.bind(this)}
              setCustomTitle={this.setCustomTitle.bind(this)}
              setSupplementalActionLabel={this.setSupplementalActionLabel.bind(this)}
              supplementalSignal={this.state.supplementalSignal}
              toggleShippingDefault={this.props.toggleShippingDefault}
              {...additionalProps} />
  }

  blockClose(val) {
    this.setState({ preventClose: val });
  }

  tryToClose() {
    if(this.state.preventClose) {
      return null;
    }
    this.props.closeMethod();
  }

  confirmAction() {
    this.setState({
      confirmSignal: Date.now(),
    });
  }

  clearActions() {
    this.setState({
      confirmSignal: null,
    });
  }

  setSuccessLabel(val) {
    this.setState({ customSuccessLabel: val || null });
  }

  getSuccessLabel() {
    return this.state.customSuccessLabel || this.props.modalSuccessLabel;
  }

  setCustomTitle(val) {
    this.setState({ customTitle: val || null });
  }

  setSupplementalActionLabel(val) {
    this.setState({ supplementalActionLabel: val || null });
  }

  supplementalAction(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      supplementalSignal: Date.now(),
    });
  }

  render() {

    const {t} = this.props;

    return <div 
            className='OrderControlModal'
            style={{
              display: this.state.shouldDisplay ? 'block' : 'none'
            }}
            >
      <div className={this.getScreenClass()}></div>
      <div className='spModalWrapper'>
        <div className='spModal' style={this.getModalStyle()} ref={this.modalBody}>
          <div className='spModalInnerWrapper'>
            <div className='spClose' onClick={this.tryToClose.bind(this)}>&times;</div>
            <div className='spModalTitle'>{t(this.getTitle())}</div>
            <div className='spModalHr'></div>
            <div className='bulkBodyWrapper'>
              {this.getChildComponent()}
              <div className='spModalActions'>
                {this.state.supplementalActionLabel ?
                  <button type='button' className={'spAction spActionSave spActionSupplemental'} onClick={this.supplementalAction.bind(this)}>{t(this.state.supplementalActionLabel)}</button> :
                  null
                }
                <button type='button' className={'spAction spActionCancel'} onClick={this.cancelAction.bind(this)}>{t(TX_CANCEL)}</button>
                {this.props.modalSuccessLabel ?
                  <button 
                    type='button' 
                    className={'spAction spActionSave'} 
                    onClick={this.confirmAction.bind(this)}
                    disabled={this.state.confirmSignal || !this.state.confirmAllowed}>
                    {t(this.getSuccessLabel())}
                  </button> :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderControlModal));