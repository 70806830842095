
// Notes on naming
// ERROR_ used for frontend errors
// ERR_ used for backend errors



export const ERROR_CATCH_ALL = 'ERROR_CATCH_ALL';

export const ERROR_ACCESS_TOKEN_INVALID = 'ERROR_ACCESS_TOKEN_INVALID';
export const ERROR_ACCESS_TOKEN_MISSING = 'ERROR_ACCESS_TOKEN_MISSING';

export const ERROR_ADDRESS_CITY_INVALID = 'ERROR_ADDRESS_CITY_INVALID';
export const ERROR_ADDRESS_CITY_MISSING = 'ERROR_ADDRESS_CITY_MISSING';
export const ERROR_ADDRESS_COUNTRY_MISSING = 'ERROR_ADDRESS_COUNTRY_MISSING';
export const ERROR_ADDRESS_LEVEL_3_COMUNA_INVALID = 'ERROR_ADDRESS_LEVEL_3_COMUNA_INVALID';
export const ERROR_ADDRESS_LEVEL_3_COMUNA_MISSING = 'ERROR_ADDRESS_LEVEL_3_COMUNA_MISSING';
export const ERROR_ADDRESS_STREET_INVALID = 'ERROR_ADDRESS_STREET_INVALID';
export const ERROR_ADDRESS_STREET_MISSING = 'ERROR_ADDRESS_STREET_MISSING';
export const ERROR_ADDRESS_POSTCODE_INVALID = 'ERROR_ADDRESS_POSTCODE_INVALID';
export const ERROR_ADDRESS_POSTCODE_MISSING = 'ERROR_ADDRESS_POSTCODE_MISSING';
export const ERROR_ADDRESS_POSTCODE_ZIP_INVALID = 'ERROR_ADDRESS_POSTCODE_ZIP_INVALID';
export const ERROR_ADDRESS_POSTCODE_ZIP_MISSING = 'ERROR_ADDRESS_POSTCODE_ZIP_MISSING';
export const ERROR_ADDRESS_REGION_INVALID = 'ERROR_ADDRESS_REGION_INVALID';
export const ERROR_ADDRESS_REGION_MISSING = 'ERROR_ADDRESS_REGION_MISSING';
export const ERROR_ADDRESS_REGION_COUNTY_INVALID = 'ERROR_ADDRESS_REGION_COUNTY_INVALID';
export const ERROR_ADDRESS_REGION_COUNTY_MISSING = 'ERROR_ADDRESS_REGION_COUNTY_MISSING';
export const ERROR_ADDRESS_REGION_STATE_INVALID = 'ERROR_ADDRESS_REGION_STATE_INVALID';
export const ERROR_ADDRESS_REGION_STATE_MISSING = 'ERROR_ADDRESS_REGION_STATE_MISSING';
export const ERROR_ADDRESS_TAX_ID_RUT_INVALID = 'ERROR_ADDRESS_TAX_ID_RUT_INVALID';
export const ERROR_ADDRESS_TAX_ID_RUT_MISSING = 'ERROR_ADDRESS_TAX_ID_RUT_MISSING';

export const ERROR_API_KEY_INVALID = 'ERROR_API_KEY_INVALID';
export const ERROR_API_KEY_MISSING = 'ERROR_API_KEY_MISSING';

export const ERROR_APPLICATION_ID_INVALID = 'ERROR_APPLICATION_ID_INVALID';
export const ERROR_APPLICATION_ID_MISSING = 'ERROR_APPLICATION_ID_MISSING';

export const ERROR_BILLING_ADDRESS_SELECT = 'ERROR_BILLING_ADDRESS_SELECT';

export const ERROR_CC_CVV_INVALID = 'ERROR_CC_CVV_INVALID';
export const ERROR_CC_CVV_MISSING = 'ERROR_CC_CVV_MISSING';
export const ERROR_CC_EXPIRATION_INVALID = 'ERROR_CC_EXPIRATION_INVALID';
export const ERROR_CC_EXPIRATION_MISSING = 'ERROR_CC_EXPIRATION_MISSING';
export const ERROR_CC_NUMBER_INVALID = 'ERROR_CC_NUMBER_INVALID';
export const ERROR_CC_NUMBER_MISSING = 'ERROR_CC_NUMBER_MISSING';

export const ERROR_CHECKOUT_BUYLIST_CONFIRMATION = 'ERROR_CHECKOUT_BUYLIST_CONFIRMATION';

export const ERROR_COMMERCE_CODE_INVALID = 'ERROR_COMMERCE_CODE_INVALID';
export const ERROR_COMMERCE_CODE_MISSING = 'ERROR_COMMERCE_CODE_MISSING';

export const ERROR_CONDITION_MISSING = 'ERROR_CONDITION_MISSING';

export const ERROR_COST_GENERAL = 'ERROR_COST_GENERAL';
export const ERROR_COST_MAX = 'ERROR_COST_MAX';
export const ERROR_COST_MIN = 'ERROR_COST_MIN';
export const ERROR_COST_MISSING = 'ERROR_COST_MISSING';
export const ERROR_COST_NUMERIC = 'ERROR_COST_NUMERIC';

export const ERROR_COUPON_CODE_ALPHANUM = 'ERROR_COUPON_CODE_ALPHANUM';
export const ERROR_COUPON_CODE_BEGIN_END_CHAR = 'ERROR_COUPON_CODE_BEGIN_END_CHAR';
export const ERROR_COUPON_CODE_EXISTS = 'ERROR_COUPON_CODE_EXISTS';
export const ERROR_COUPON_CODE_MIN = 'ERROR_COUPON_CODE_MIN';
export const ERROR_COUPON_CODE_MISSING = 'ERROR_COUPON_CODE_MISSING';
export const ERROR_COUPON_CODE_NO_SPACE = 'ERROR_COUPON_CODE_NO_SPACE';

export const ERROR_DATE_INVALID = 'ERROR_DATE_INVALID';
export const ERROR_DATE_MISSING = 'ERROR_DATE_MISSING';
export const ERROR_DATE_PAST = 'ERROR_DATE_PAST';

export const ERROR_DESCRIPTION_LENGTH = 'ERROR_DESCRIPTION_LENGTH';
export const ERROR_DESCRIPTION_MISSING = 'ERROR_DESCRIPTION_MISSING';

export const ERROR_DISCOUNT_AMOUNT_GENERAL = 'ERROR_DISCOUNT_AMOUNT_GENERAL';
export const ERROR_DISCOUNT_AMOUNT_MAX = 'ERROR_DISCOUNT_AMOUNT_MAX';
export const ERROR_DISCOUNT_AMOUNT_MIN = 'ERROR_DISCOUNT_AMOUNT_MIN';
export const ERROR_DISCOUNT_AMOUNT_MISSING = 'ERROR_DISCOUNT_AMOUNT_MISSING';
export const ERROR_DISCOUNT_AMOUNT_NUMERIC = 'ERROR_DISCOUNT_AMOUNT_NUMERIC';

export const ERROR_DISCOUNT_TYPE_MISSING = 'ERROR_DISCOUNT_TYPE_MISSING';

export const ERROR_DISTANCE_GENERAL = 'ERROR_DISTANCE_GENERAL';
export const ERROR_DISTANCE_MAX = 'ERROR_DISTANCE_MAX';
export const ERROR_DISTANCE_MIN = 'ERROR_DISTANCE_MIN';
export const ERROR_DISTANCE_MISSING = 'ERROR_DISTANCE_MISSING';
export const ERROR_DISTANCE_NUMERIC = 'ERROR_DISTANCE_NUMERIC';

export const ERROR_EMAIL_EXISTS = 'ERROR_EMAIL_EXISTS';
export const ERROR_EMAIL_INVALID = 'ERROR_EMAIL_INVALID';
export const ERROR_EMAIL_MISSING = 'ERROR_EMAIL_MISSING';

export const ERROR_EVENT_FORMAT_MISSING = 'ERROR_EVENT_FORMAT_MISSING';
export const ERROR_EVENT_REGISTRATION_LIMIT_INVALID = 'ERROR_EVENT_REGISTRATION_LIMIT_INVALID';
export const ERROR_EVENT_REGISTRATION_LIMIT_MISSING = 'ERROR_EVENT_REGISTRATION_LIMIT_MISSING';
export const ERROR_EVENT_REPEAT_FREQUENCY = 'ERROR_EVENT_REPEAT_FREQUENCY';

export const ERROR_FILE_BULK_CSV_UPLOAD_INVALID = 'ERROR_FILE_BULK_CSV_UPLOAD_INVALID';
export const ERROR_FILE_BULK_CSV_UPLOAD_MISSING = 'ERROR_FILE_BULK_CSV_UPLOAD_MISSING';
export const ERROR_FILE_BULK_CSV_UPLOAD_MISSING_COLUMNS = 'ERROR_FILE_BULK_CSV_UPLOAD_MISSING_COLUMNS';
export const ERROR_FILE_BULK_CSV_UPLOAD_SINGLE = 'ERROR_FILE_BULK_CSV_UPLOAD_SINGLE';
export const ERROR_FILE_BULK_CSV_UPLOAD_SIZE = 'ERROR_FILE_BULK_CSV_UPLOAD_SIZE';
export const ERROR_FILE_BULK_CSV_UPLOAD_TYPE = 'ERROR_FILE_BULK_CSV_UPLOAD_TYPE';
export const ERROR_FILE_IMAGE_PRODUCT_SIZE = 'ERROR_FILE_IMAGE_PRODUCT_SIZE';
export const ERROR_FILE_IMAGE_PRODUCT_TYPE = 'ERROR_FILE_IMAGE_PRODUCT_TYPE';

export const ERROR_FINISH_MISSING = 'ERROR_FINISH_MISSING';

export const ERROR_INTEGRATION_CONFIG = 'ERROR_INTEGRATION_CONFIG';
export const ERROR_INTEGRATION_PROVIDER_MISSING = 'ERROR_INTEGRATION_PROVIDER_MISSING';

export const ERROR_LANGUAGE_MISSING = 'ERROR_LANGUAGE_MISSING';

export const ERROR_LOCATION_ID_INVALID = 'ERROR_LOCATION_ID_INVALID';
export const ERROR_LOCATION_ID_MISSING = 'ERROR_LOCATION_ID_MISSING';

export const ERROR_MARKUP_PERCENT_GENERAL = 'ERROR_MARKUP_PERCENT_GENERAL';
export const ERROR_MARKUP_PERCENT_MAX = 'ERROR_MARKUP_PERCENT_MAX';
export const ERROR_MARKUP_PERCENT_MIN = 'ERROR_MARKUP_PERCENT_MIN';
export const ERROR_MARKUP_PERCENT_MISSING = 'ERROR_MARKUP_PERCENT_MISSING';
export const ERROR_MARKUP_PERCENT_NUMERIC = 'ERROR_MARKUP_PERCENT_NUMERIC';

export const ERROR_MENU_ORDER_MISSING = 'ERROR_MENU_ORDER_MISSING';

export const ERROR_MESSAGE_LENGTH = 'ERROR_MESSAGE_LENGTH';
export const ERROR_MESSAGE_MISSING = 'ERROR_MESSAGE_MISSING';

export const ERROR_NAME_MISSING = 'ERROR_NAME_MISSING';

export const ERROR_NUMBER_GENERAL = 'ERROR_NUMBER_GENERAL';
export const ERROR_NUMBER_MAX = 'ERROR_NUMBER_MAX';
export const ERROR_NUMBER_MIN = 'ERROR_NUMBER_MIN';
export const ERROR_NUMBER_MISSING = 'ERROR_NUMBER_MISSING';

export const ERROR_ORDER_CARRIER_MISSING = 'ERROR_ORDER_CARRIER_MISSING';
export const ERROR_ORDER_CARRIER_SELECT = 'ERROR_ORDER_CARRIER_SELECT';
export const ERROR_ORDER_SHIPMENT_MISSING = 'ERROR_ORDER_SHIPMENT_MISSING';
export const ERROR_ORDER_TRACKING_NUMBER_MISSING = 'ERROR_ORDER_TRACKING_NUMBER_MISSING';

export const ERROR_PASSWORD_MATCH = 'ERROR_PASSWORD_MATCH';
export const ERROR_PASSWORD_MISSING = 'ERROR_PASSWORD_MISSING';
export const ERROR_PASSWORD_RULE_SPACE = 'ERROR_PASSWORD_RULE_SPACE';
export const ERROR_PASSWORD_RULE_MIN = 'ERROR_PASSWORD_RULE_MIN';

export const ERROR_PAYMENT_STORE_CREDIT_MAX = 'ERROR_PAYMENT_STORE_CREDIT_MAX';

export const ERROR_PAYMENT_METHOD_CONFIG = 'ERROR_PAYMENT_METHOD_CONFIG';
export const ERROR_PAYMENT_METHOD_DATA_NOT_LOADED = 'ERROR_PAYMENT_METHOD_DATA_NOT_LOADED';
export const ERROR_PAYMENT_METHOD_INVALID_STATE = 'ERROR_PAYMENT_METHOD_INVALID_STATE';
export const ERROR_PAYMENT_METHOD_MISSING = 'ERROR_PAYMENT_METHOD_MISSING';
export const ERROR_PAYMENT_METHOD_NOT_LOADED = 'ERROR_PAYMENT_METHOD_NOT_LOADED';

export const ERROR_PAYMENT_PROCESSOR_MISSING = 'ERROR_PAYMENT_PROCESSOR_MISSING';

export const ERROR_PERCENT_GENERAL = 'ERROR_PERCENT_GENERAL';
export const ERROR_PERCENT_MAX = 'ERROR_PERCENT_MAX';
export const ERROR_PERCENT_MIN = 'ERROR_PERCENT_MIN';
export const ERROR_PERCENT_MISSING = 'ERROR_PERCENT_MISSING';
export const ERROR_PERCENT_NUMERIC = 'ERROR_PERCENT_NUMERIC';

export const ERROR_PERMALINK_FORMATTING = 'ERROR_PERMALINK_FORMATTING';
export const ERROR_PERMALINK_MISSING = 'ERROR_PERMALINK_MISSING';
export const ERROR_PERMALINK_TAKEN = 'ERROR_PERMALINK_TAKEN';

export const ERROR_PHONE_INVALID = 'ERROR_PHONE_INVALID';
export const ERROR_PHONE_MISSING = 'ERROR_PHONE_MISSING';

export const ERROR_PL_SYNC_TYPE_MISSING = 'ERROR_PL_SYNC_TYPE_MISSING';

export const ERROR_PRICE_GENERAL = 'ERROR_PRICE_GENERAL';
export const ERROR_PRICE_MAX = 'ERROR_PRICE_MAX';
export const ERROR_PRICE_MIN = 'ERROR_PRICE_MIN';
export const ERROR_PRICE_MISSING = 'ERROR_PRICE_MISSING';
export const ERROR_PRICE_NUMERIC = 'ERROR_PRICE_NUMERIC';

export const ERROR_PRINTING_MISSING = 'ERROR_PRINTING_MISSING';

export const ERROR_PRODUCT_EXISTS_PERMALINK = 'ERROR_PRODUCT_EXISTS_PERMALINK';
export const ERROR_PRODUCT_EXISTS_SKU = 'ERROR_PRODUCT_EXISTS_SKU';
export const ERROR_PRODUCT_IMAGE_UPLOAD = 'ERROR_PRODUCT_IMAGE_UPLOAD';
export const ERROR_PRODUCT_PLEASE_SELECT = 'ERROR_PRODUCT_PLEASE_SELECT';

export const ERROR_PRODUCT_SET_INVALID = 'ERROR_PRODUCT_SET_INVALID';
export const ERROR_PRODUCT_SET_MISSING = 'ERROR_PRODUCT_SET_MISSING';
export const ERROR_PRODUCT_SET_NAME_MISSING = 'ERROR_PRODUCT_SET_NAME_MISSING';

export const ERROR_PRODUCT_LINE_PLEASE_SELECT = 'ERROR_PRODUCT_LINE_PLEASE_SELECT';

export const ERROR_PURCHASE_AMOUNT_GENERAL = 'ERROR_PURCHASE_AMOUNT_GENERAL';
export const ERROR_PURCHASE_AMOUNT_MAX = 'ERROR_PURCHASE_AMOUNT_MAX';
export const ERROR_PURCHASE_AMOUNT_MIN = 'ERROR_PURCHASE_AMOUNT_MIN';
export const ERROR_PURCHASE_AMOUNT_MISSING = 'ERROR_PURCHASE_AMOUNT_MISSING';
export const ERROR_PURCHASE_AMOUNT_NUMERIC = 'ERROR_PURCHASE_AMOUNT_NUMERIC';

export const ERROR_QTY_GENERAL = 'ERROR_QTY_GENERAL';
export const ERROR_QTY_MAX = 'ERROR_QTY_MAX';
export const ERROR_QTY_MIN = 'ERROR_QTY_MIN';
export const ERROR_QTY_MISSING = 'ERROR_QTY_MISSING';
export const ERROR_QTY_NUMERIC = 'ERROR_QTY_NUMERIC';

export const ERROR_RETURNS_NO_ITEMS_SELECTED = 'ERROR_RETURNS_NO_ITEMS_SELECTED';
export const ERROR_RETURNS_REFUND_MAX = 'ERROR_RETURNS_REFUND_MAX';

export const ERROR_RGB_GENERAL = 'ERROR_RGB_GENERAL';
export const ERROR_RGB_MISSING = 'ERROR_RGB_MISSING';

export const ERROR_SKU_ALPHANUM = 'ERROR_SKU_ALPHANUM';
export const ERROR_SKU_BEGIN_END_CHAR = 'ERROR_SKU_BEGIN_END_CHAR';
export const ERROR_SKU_NO_SPACE = 'ERROR_SKU_NO_SPACE';
export const ERROR_SKU_MIN = 'ERROR_SKU_MIN';
export const ERROR_SKU_MISSING = 'ERROR_SKU_MISSING';

export const ERROR_STORE_CREDIT_AMOUNT_GENERAL = 'ERROR_STORE_CREDIT_AMOUNT_GENERAL';
export const ERROR_STORE_CREDIT_AMOUNT_MAX = 'ERROR_STORE_CREDIT_AMOUNT_MAX';
export const ERROR_STORE_CREDIT_AMOUNT_MIN = 'ERROR_STORE_CREDIT_AMOUNT_MIN';
export const ERROR_STORE_CREDIT_AMOUNT_MISSING = 'ERROR_STORE_CREDIT_AMOUNT_MISSING';
export const ERROR_STORE_CREDIT_AMOUNT_NUMERIC = 'ERROR_STORE_CREDIT_AMOUNT_NUMERIC';

export const ERROR_STRING_GENERAL = 'ERROR_STRING_GENERAL';
export const ERROR_STRING_LENGTH_16 = 'ERROR_STRING_LENGTH_16';
export const ERROR_STRING_LENGTH_32 = 'ERROR_STRING_LENGTH_32';
export const ERROR_STRING_LENGTH_64 = 'ERROR_STRING_LENGTH_64';
export const ERROR_STRING_LENGTH_100 = 'ERROR_STRING_LENGTH_100';
export const ERROR_STRING_MAX = 'ERROR_STRING_MAX';
export const ERROR_STRING_MIN = 'ERROR_STRING_MIN';
export const ERROR_STRING_MISSING = 'ERROR_STRING_MISSING';

export const ERROR_TAG_NAME_MISSING = 'ERROR_TAG_NAME_MISSING';

export const ERROR_TIME_INVALID = 'ERROR_TIME_INVALID';

export const ERROR_UPLOAD_FORMAT_MISSING = 'ERROR_UPLOAD_FORMAT_MISSING';

export const ERROR_URL_GENERAL = 'ERROR_URL_GENERAL';
export const ERROR_URL_MISSING = 'ERROR_URL_MISSING';

export const ERROR_USAGE_COUNT_INVALID = 'ERROR_USAGE_COUNT_INVALID';
export const ERROR_USAGE_COUNT_MISSING = 'ERROR_USAGE_COUNT_MISSING';

export const ERROR_VALUE_MISSING = 'ERROR_VALUE_MISSING';

export const ERROR_WEBHOOK_SIGNATURE_INVALID = 'ERROR_WEBHOOK_SIGNATURE_INVALID';
export const ERROR_WEBHOOK_SIGNATURE_MISSING = 'ERROR_WEBHOOK_SIGNATURE_MISSING';

export const ERROR_WEIGHT_GENERAL = 'ERROR_WEIGHT_GENERAL';
export const ERROR_WEIGHT_MAX = 'ERROR_WEIGHT_MAX';
export const ERROR_WEIGHT_MIN = 'ERROR_WEIGHT_MIN';
export const ERROR_WEIGHT_MISSING = 'ERROR_WEIGHT_MISSING';
export const ERROR_WEIGHT_NUMERIC = 'ERROR_WEIGHT_NUMERIC';


export const ERR_1400 = 'ERR_1400';
export const ERR_1401 = 'ERR_1401';
export const ERR_1402 = 'ERR_1402';
export const ERR_1403 = 'ERR_1403';
export const ERR_1404 = 'ERR_1404';
export const ERR_1405 = 'ERR_1405';
export const ERR_1406 = 'ERR_1406';
export const ERR_1407 = 'ERR_1407';
export const ERR_1408 = 'ERR_1408';
export const ERR_1409 = 'ERR_1409';
export const ERR_1410 = 'ERR_1410';
export const ERR_1411 = 'ERR_1411';
export const ERR_1412 = 'ERR_1412';
export const ERR_1413 = 'ERR_1413';
export const ERR_1414 = 'ERR_1414';
export const ERR_1415 = 'ERR_1415';
export const ERR_1416 = 'ERR_1416';
export const ERR_1417 = 'ERR_1417';
export const ERR_1418 = 'ERR_1418';
export const ERR_1419 = 'ERR_1419';
export const ERR_1420 = 'ERR_1420';
export const ERR_1421 = 'ERR_1421';
export const ERR_1422 = 'ERR_1422';
export const ERR_1423 = 'ERR_1423';
export const ERR_1424 = 'ERR_1424';
export const ERR_1425 = 'ERR_1425';
export const ERR_1426 = 'ERR_1426';
export const ERR_1427 = 'ERR_1427';
export const ERR_1428 = 'ERR_1428';
export const ERR_1429 = 'ERR_1429';
export const ERR_1430 = 'ERR_1430';
export const ERR_1431 = 'ERR_1431';
export const ERR_1432 = 'ERR_1432';
export const ERR_1433 = 'ERR_1433';
export const ERR_1434 = 'ERR_1434';

export const ERR_1435 = 'ERR_1435';
export const ERR_1436 = 'ERR_1436';
export const ERR_1437 = 'ERR_1437';
export const ERR_1438 = 'ERR_1438';
export const ERR_1439 = 'ERR_1439';

export const ERR_1440 = 'ERR_1440';
export const ERR_1441 = 'ERR_1441';
export const ERR_1442 = 'ERR_1442';
export const ERR_1443 = 'ERR_1443';
export const ERR_1444 = 'ERR_1444';
export const ERR_1445 = 'ERR_1445';
export const ERR_1446 = 'ERR_1446';
export const ERR_1447 = 'ERR_1447';
export const ERR_1448 = 'ERR_1448';
export const ERR_1449 = 'ERR_1449';

export const ERR_1450 = 'ERR_1450';
export const ERR_1451 = 'ERR_1451';
export const ERR_1452 = 'ERR_1452';
export const ERR_1453 = 'ERR_1453';
export const ERR_1454 = 'ERR_1454';
export const ERR_1455 = 'ERR_1455';
export const ERR_1456 = 'ERR_1456';
export const ERR_1457 = 'ERR_1457';
export const ERR_1458 = 'ERR_1458';
export const ERR_1459 = 'ERR_1459';

export const ERR_1460 = 'ERR_1460';
export const ERR_1461 = 'ERR_1461';
export const ERR_1462 = 'ERR_1462';
export const ERR_1463 = 'ERR_1463';
export const ERR_1464 = 'ERR_1464';
export const ERR_1465 = 'ERR_1465';
export const ERR_1466 = 'ERR_1466';
export const ERR_1467 = 'ERR_1467';
export const ERR_1468 = 'ERR_1468';

// start here
export const ERR_1469 = 'ERR_1469';

export const ERR_1470 = 'ERR_1470';
export const ERR_1471 = 'ERR_1471';
export const ERR_1472 = 'ERR_1472';
export const ERR_1473 = 'ERR_1473';
export const ERR_1474 = 'ERR_1474';
export const ERR_1475 = 'ERR_1475';
export const ERR_1476 = 'ERR_1476';
export const ERR_1477 = 'ERR_1477';
export const ERR_1478 = 'ERR_1478';
export const ERR_1479 = 'ERR_1479';

export const ERR_1480 = 'ERR_1480';
export const ERR_1481 = 'ERR_1481';
export const ERR_1482 = 'ERR_1482';
export const ERR_1483 = 'ERR_1483';
export const ERR_1484 = 'ERR_1484';
export const ERR_1485 = 'ERR_1485';
export const ERR_1486 = 'ERR_1486';
export const ERR_1487 = 'ERR_1487';
export const ERR_1488 = 'ERR_1488';
export const ERR_1489 = 'ERR_1489';

export const ERR_1490 = 'ERR_1490';
export const ERR_1491 = 'ERR_1491';
export const ERR_1492 = 'ERR_1492';
export const ERR_1493 = 'ERR_1493';
export const ERR_1494 = 'ERR_1494';
export const ERR_1495 = 'ERR_1495';
export const ERR_1496 = 'ERR_1496';
export const ERR_1497 = 'ERR_1497';
export const ERR_1498 = 'ERR_1498';
export const ERR_1499 = 'ERR_1499';

export const ERR_1500 = 'ERR_1500';
export const ERR_1501 = 'ERR_1501';
export const ERR_1502 = 'ERR_1502';
export const ERR_1503 = 'ERR_1503';
export const ERR_1504 = 'ERR_1504';
export const ERR_1505 = 'ERR_1505';
export const ERR_1506 = 'ERR_1506';
export const ERR_1507 = 'ERR_1507';
export const ERR_1508 = 'ERR_1508';
export const ERR_1509 = 'ERR_1509';



export const ERR_1601 = 'ERR_1601';
export const ERR_1602 = 'ERR_1602';
export const ERR_1603 = 'ERR_1603';
export const ERR_1604 = 'ERR_1604';
export const ERR_1605 = 'ERR_1605';
export const ERR_1606 = 'ERR_1606';
export const ERR_1607 = 'ERR_1607';

export const ERR_1613 = 'ERR_1613';
export const ERR_1614 = 'ERR_1614';
export const ERR_1615 = 'ERR_1615';

export const ERR_1630 = 'ERR_1630';
export const ERR_1631 = 'ERR_1631';
export const ERR_1632 = 'ERR_1632';
export const ERR_1633 = 'ERR_1633';
export const ERR_1634 = 'ERR_1634';
export const ERR_1635 = 'ERR_1635';
export const ERR_1636 = 'ERR_1636';
export const ERR_1637 = 'ERR_1637';
export const ERR_1638 = 'ERR_1638';
export const ERR_1639 = 'ERR_1639';

export const ERR_1640 = 'ERR_1640';
export const ERR_1641 = 'ERR_1641';
export const ERR_1642 = 'ERR_1642';
export const ERR_1643 = 'ERR_1643';
export const ERR_1644 = 'ERR_1644';

export const ERR_1700 = 'ERR_1700';


export const ERR_1716 = 'ERR_1716';
export const ERR_1717 = 'ERR_1717';

export const ERR_1734 = 'ERR_1734';

export const ERR_1772 = 'ERR_1772';



export const ERR_4300 = 'ERR_4300';
export const ERR_5500 = 'ERR_5500';

export const ERR_OBJECT = {
	1400: ERR_1400,
	1401: ERR_1401,
	1402: ERR_1402,
	1403: ERR_1403,
	1404: ERR_1404,
	1405: ERR_1405,
	1406: ERR_1406,
	1407: ERR_1407,
	1408: ERR_1408,
	1409: ERR_1409,
	
	1410: ERR_1410,
	1411: ERR_1411,
	1412: ERR_1412,
	1413: ERR_1413,
	1414: ERR_1414,
	1415: ERR_1415,
	1416: ERR_1416,
	1417: ERR_1417,
	1418: ERR_1418,
	1419: ERR_1419,

	1420: ERR_1420,
	1421: ERR_1421,
	1422: ERR_1422,
	1423: ERR_1423,
	1424: ERR_1424,
	1425: ERR_1425,
	1426: ERR_1426,
	1427: ERR_1427,
	1428: ERR_1428,
	1429: ERR_1429,

	1430: ERR_1430,
	1431: ERR_1431,
	1432: ERR_1432,
	1433: ERR_1433,
	1434: ERR_1434,
	1435: ERR_1435,
	1436: ERR_1436,
	1437: ERR_1437,
	1438: ERR_1438,
	1439: ERR_1439,

	1440: ERR_1440,
	1441: ERR_1441,
	1442: ERR_1442,
	1443: ERR_1443,
	1444: ERR_1444,
	1445: ERR_1445,
	1446: ERR_1446,
	1447: ERR_1447,
	1448: ERR_1448,
	1449: ERR_1449,

	1450: ERR_1450,
	1451: ERR_1451,
	1452: ERR_1452,
	1453: ERR_1453,
	1454: ERR_1454,
	1455: ERR_1455,
	1456: ERR_1456,
	1457: ERR_1457,
	1458: ERR_1458,
	1459: ERR_1459,

	1460: ERR_1460,
	1461: ERR_1461,
	1462: ERR_1462,
	1463: ERR_1463,
	1464: ERR_1464,
	1465: ERR_1465,
	1466: ERR_1466,
	1467: ERR_1467,
	1468: ERR_1468,
	1469: ERR_1469,

	1470: ERR_1470,
	1471: ERR_1471,
	1472: ERR_1472,
	1473: ERR_1473,
	1474: ERR_1474,
	1475: ERR_1475,
	1476: ERR_1476,
	1477: ERR_1477,
	1478: ERR_1478,
	1479: ERR_1479,

	1480: ERR_1480,
	1481: ERR_1481,
	1482: ERR_1482,
	1483: ERR_1483,
	1484: ERR_1484,
	1485: ERR_1485,
	1486: ERR_1486,
	1487: ERR_1487,
	1488: ERR_1488,
	1489: ERR_1489,

	1490: ERR_1490,
	1491: ERR_1491,
	1492: ERR_1492,
	1493: ERR_1493,
	1494: ERR_1494,
	1495: ERR_1495,
	1496: ERR_1496,
	1497: ERR_1497,
	1498: ERR_1498,
	1499: ERR_1499,

	1500: ERR_1500,
	1501: ERR_1501,
	1502: ERR_1502,
	1503: ERR_1503,
	1504: ERR_1504,
	1505: ERR_1505,
	1506: ERR_1506,
	1507: ERR_1507,
	1508: ERR_1508,
	1509: ERR_1509,


	1601: ERR_1601,
	1602: ERR_1602,
	1603: ERR_1603,
	1604: ERR_1604,
	1605: ERR_1605,
	1606: ERR_1606,
	1607: ERR_1607,


	1613: ERR_1613,
	1614: ERR_1614,
	1615: ERR_1615,

	1630: ERR_1630,
	1631: ERR_1631,
	1632: ERR_1632,
	1633: ERR_1633,
	1634: ERR_1634,
	1635: ERR_1635,
	1636: ERR_1636,
	1637: ERR_1637,
	1638: ERR_1638,
	1639: ERR_1639,

	1640: ERR_1640,
	1641: ERR_1641,
	1642: ERR_1642,
	1643: ERR_1643,
	1644: ERR_1644,

	1700: ERR_1700,


	1716: ERR_1716,
	1717: ERR_1717,

	1734: ERR_1734,

	1772: ERR_1772,


	4300: ERR_4300,
	5500: ERR_5500,
}