import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import {
  URL_ADMIN_CUSTOMERS_ADD, 
  URL_ADMIN_SETTINGS, 
  URL_ADMIN_SETTINGS_ADMIN_USERS, 
} from '../../constants/urls';

import { getMyId } from '../../utils/auth';
import { 
  addHighlightTags,
  formatServerError, 
} from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';

import AdminTitle from '../Admin/AdminTitle';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';
import Toggle from '../Input/Toggle';

import './style/_adminuseredit.scss';

import * as commonActionCreators from '../../actions/common';
import * as customerActionCreators from '../../actions/customer';
let allActionCreators = Object.assign({}, commonActionCreators, customerActionCreators);

export class AdminUsersAdd extends Component {

  constructor(props) {
    super(props);

    this.OPEN_DELAY = 200;

    this.state = {
      isDirty: false,

      inputSearch: '',

      autocompleteActive: false,
      autocompleteUsers: [], 
      selectedUser: null,
      lastAutocomplete: '',
      navIndex: -1,

      inputProducts: false,
      inputSales: false,
      inputUsers: false,
      inputEvents: false,
      inputPermissions: false,

      requestPending: false,
      requestError: null,
      responseObject: null,

      savePromptOpen: false, 
    };

    this.closeTimeout = null;

    this.autocompleteWrapperRef = React.createRef();

    this.checkClick = this.checkClick.bind(this);

    this.controller = null;
    this.controllerAutocomplete = null;
  }

  componentDidMount() {

    document.addEventListener('click', this.checkClick, false);

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });
  }

  componentWillUnmount() {
    
    this.unblock();

    document.removeEventListener('click', this.checkClick, false);

    if(this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }

    if(this.controller) {
      this.controller.abort();
    }
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  checkClick(evt) {
    if(this.state.autocompleteActive && this.state.autocompleteClosable) {
      let targetElement = evt.target;
      do {
        if(this.autocompleteWrapperRef && targetElement === this.autocompleteWrapperRef.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeAutocomplete();
    }
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }

    if(!this.state.selectedUser) { return null; }

    const permissionsData = {
      manage_products: this.state.inputProducts,
      manage_sales: this.state.inputSales,
      manage_users: this.state.inputUsers,
      manage_events: this.state.inputEvents,
      manage_permissions: this.state.inputPermissions,
    };

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.customerAdminPermissionsEdit(permissionsData, this.state.selectedUser.publicUuid)
    .then((resp) => {
      this.setState({
        requestPending: false,
        requestError: null,
        isDirty: false, 
      }, () => {
        history.push(URL_ADMIN_SETTINGS_ADMIN_USERS);
      });
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  changeSearch(evt) {
    this.setState({
      inputSearch: evt.target.value,
    }, () => {
      if(this.state.inputSearch && this.state.inputSearch.trim().length >= 3) {
        this.callAutocomplete(this.state.inputSearch.trim());        
      } else {
        this.closeAutocomplete();
      }
    });
  }

  closeAutocomplete() {
    this.setState({ 
      autocompleteActive: false,
      autocompleteClosable: false,
      autocompleteUsers: [],
    });
  }

  async callAutocomplete(searchVal) {

    // Async call to autocomplete magic cards
    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }
    const controllerAutocomplete = new AbortController();
    this.controllerAutocomplete = controllerAutocomplete;

    const searchParams = {
      search: searchVal,
      limit: 10,
    }
    const resp = await this.props.customerQuickSearch(searchParams, controllerAutocomplete.signal)
    .catch((errResp) => {
      if(controllerAutocomplete.signal.aborted) {
        return null;
      }
    });

    if(!resp) {
      return null;
    }

    this.setState({
      autocompleteActive: true,
      autocompleteClosable: false,
      autocompleteUsers: resp.data, 
      lastAutocomplete: searchVal,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({ autocompleteClosable: true });
      }, this.MENU_DELAY);
    });
  }

  selectUser(user) {

    if(this.controllerAutocomplete) {
      this.controllerAutocomplete.abort();
    }

    this.setState({
      selectedUser: user,
    }, () => {
      if(user && user.permissions) {
        this.setState({
          inputProducts: user.permissions.manage_products ? true : false,
          inputSales: user.permissions.manage_sales ? true : false,
          inputUsers: user.permissions.manage_users ? true : false,
          inputEvents: user.permissions.manage_events ? true : false,
          inputPermissions: user.permissions.manage_permissions ? true : false,
        });
      } else if(user === null) {
        this.setState({
          inputSearch: '',
          inputProducts: false,
          inputSales: false,
          inputUsers: false,
          inputEvents: false,
          inputPermissions: false,
        });
      }
    });
    this.closeAutocomplete();
  }

  toggleProducts() {
    this.setState({ 
      inputProducts: !this.state.inputProducts, 
      isDirty: true, 
    });
  }

  toggleSales() {
    this.setState({ 
      inputSales: !this.state.inputSales, 
      isDirty: true, 
    });
  }

  toggleUsers() {
    this.setState({ 
      inputUsers: !this.state.inputUsers, 
      isDirty: true, 
    });
  }

  toggleEvents() {
    this.setState({ 
      inputEvents: !this.state.inputEvents, 
      isDirty: true, 
    });
  }

  togglePermissions() {
    if(this.state.inputPermissions === true && this.isThisMe() === true) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_NOT_ALLOWED, 
        alertCopy: tx.TX_SETTINGS_CANNOT_REMOVE_PERMISSION, 
      });
    } else {
      this.setState({ 
        inputPermissions: !this.state.inputPermissions, 
        isDirty: true, 
      });
    }
  }

  isThisMe() {
    return getMyId() && this.state.selectedUser && getMyId() === this.state.selectedUser.id;
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  getAutocompleteResults() {
    return this.state.autocompleteUsers;
  }

  getHighlightedAutocomplete(suggestString) {
    return addHighlightTags(suggestString, this.state.lastAutocomplete, 'suggestStrong');
  }

  autocompleteNav(evt) {
    if(evt.key === 'ArrowDown') {
      this.setState({ navIndex: Math.min(this.state.navIndex + 1, this.state.autocompleteUsers.length - 1) });
    } else if(evt.key === 'ArrowUp') {
      this.setState({ navIndex: Math.max(this.state.navIndex - 1, -1) });
    } else if(evt.key === 'Enter') {
      evt.preventDefault();
      this.selectUser(this.state.autocompleteUsers[this.state.navIndex]);
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'AdminUsersAdd AdminPage'}>
      <AdminTitle
        title={tx.TX_SETTINGS_ADD_ADMIN}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
          {
            url: URL_ADMIN_SETTINGS_ADMIN_USERS,
            title: tx.TX_SETTINGS_ADMIN_USERS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className={'customerPermissionsForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_SETTINGS_ADMIN_PERMISSIONS)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

            {this.state.selectedUser ?
              
              <div className='adminUserLabelWrapper'>
                <div className='adminUserLabel'>
                  <div className='adminUserLabelValue'>{t(tx.TX_USER)}:</div>
                  <div className='adminUserLabelLink' onClick={() => this.selectUser(null)}>{t(tx.TX_CHANGE)}</div>
                </div>
                <div className='adminUserValue'>
                  <div className='adminUserName'>{this.state.selectedUser.fullName(this.getLanguage())}</div>
                  <div className='adminUserEmail'>{this.state.selectedUser.email}</div>
                </div>
              </div> :
              <div className='adminFieldWrapper' ref={this.autocompleteWrapperRef}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_ADMIN_LOOKUP_USER)}</div>
                <div className='adminInputWrapper'>
                  <input
                    type='text'
                    value={this.state.inputSearch}
                    onChange={this.changeSearch.bind(this)}
                    onKeyDown={this.autocompleteNav.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_CUSTOMER_SEARCH)}
                    maxLength={100} />
                </div>
                <div className={this.state.autocompleteActive ? 'adminInputAutocomplete active' : 'adminInputAutocomplete'}>
                  <div className='adminInputAutocompleteWrapper'>
                    {this.getAutocompleteResults().length === 0 ?
                      <div className='autocompleteNoResultsWrapper'>
                        <div className='noResultsCopy'>{t(tx.TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING)}</div>
                        <div className='noResultsNeedle'>{this.state.lastAutocomplete}</div>
                      </div> :
                      <div>
                        {this.getAutocompleteResults().map((user, i) => {
                          return <div 
                                  key={user.publicUuid}
                                  className={`autocompleteSuggestion EllipsisElement${this.state.navIndex === i ? ' active' : ''}`}
                                  onClick={() => this.selectUser(user)}>
                                  <div 
                                    className='autocompleteSuggestionValue'
                                    dangerouslySetInnerHTML={{ __html: this.getHighlightedAutocomplete(user.fullName(this.getLanguage())) }} />
                                  <div 
                                    className='autocompleteSuggestionValueSecondary'
                                    dangerouslySetInnerHTML={{ __html: this.getHighlightedAutocomplete(user.email) }} />
                                </div>;
                        })}
                      </div>
                    }
                  </div>
                </div>
              </div>
            }

            {this.state.selectedUser ?
              <div className='permissionsTogglesWrapper'>
                <div className='editPermissionWrapper'>
                  <div className='editToggleWrapper'>
                    <div className='editToggleInputWrapper'>
                      <Toggle
                        checked={this.state.inputProducts}
                        onToggle={this.toggleProducts.bind(this)}
                        bottomLabel={true}
                        trueValue={tx.TX_ENABLED}
                        falseValue={tx.TX_DISABLED} />
                    </div>
                  </div>
                  <div className='editToggleLabelWraper'>
                    <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_PRODUCTS)}</div>
                    <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_PRODUCTS_DESC)}</div>
                  </div>
                </div>

                <div className='editPermissionWrapper'>
                  <div className='editToggleWrapper'>
                    <div className='editToggleInputWrapper'>
                      <Toggle
                        checked={this.state.inputSales}
                        onToggle={this.toggleSales.bind(this)}
                        bottomLabel={true}
                        trueValue={tx.TX_ENABLED}
                        falseValue={tx.TX_DISABLED} />
                    </div>
                  </div>
                  <div className='editToggleLabelWraper'>
                    <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_SALES)}</div>
                    <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_SALES_DESC)}</div>
                  </div>
                </div>

                <div className='editPermissionWrapper'>
                  <div className='editToggleWrapper'>
                    <div className='editToggleInputWrapper'>
                      <Toggle
                        checked={this.state.inputUsers}
                        onToggle={this.toggleUsers.bind(this)}
                        bottomLabel={true}
                        trueValue={tx.TX_ENABLED}
                        falseValue={tx.TX_DISABLED} />
                    </div>
                  </div>
                  <div className='editToggleLabelWraper'>
                    <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_USERS)}</div>
                    <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_USERS_DESC)}</div>
                  </div>
                </div>

                <div className='editPermissionWrapper'>
                  <div className='editToggleWrapper'>
                    <div className='editToggleInputWrapper'>
                      <Toggle
                        checked={this.state.inputEvents}
                        onToggle={this.toggleEvents.bind(this)}
                        bottomLabel={true}
                        trueValue={tx.TX_ENABLED}
                        falseValue={tx.TX_DISABLED} />
                    </div>
                  </div>
                  <div className='editToggleLabelWraper'>
                    <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_EVENTS)}</div>
                    <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_EVENTS_DESC)}</div>
                  </div>
                </div>

                <div className='editPermissionWrapper'>
                  <div className='editToggleWrapper'>
                    <div className='editToggleInputWrapper'>
                      <Toggle
                        checked={this.state.inputPermissions}
                        onToggle={this.togglePermissions.bind(this)}
                        bottomLabel={true}
                        trueValue={tx.TX_ENABLED}
                        falseValue={tx.TX_DISABLED} />
                    </div>
                  </div>
                  <div className='editToggleLabelWraper'>
                    <div className='editPermissionTitle'>{t(tx.TX_PERMISSION_PERMISSIONS)}</div>
                    <div className='editPermissionDesc'>{t(tx.TX_PERMISSION_PERMISSIONS_DESC)}</div>
                  </div>
                </div>
              </div> :
              <div className='noUserSelected'>
                <div className='noUserWrapper'>
                  <div className='noUserCopy'>{t(tx.TX_SETTINGS_ADMIN_SELECT_USER)}</div>
                  <div className='noUserCopy'>{t(tx.TX_CUSTOMER_NO_PERMISSIONS_COPY)}</div>
                  <div className='noUserCopy'>{t(tx.TX_CUSTOMER_NO_PERMISSIONS_COPY_2)}</div>
                  <div className='noUserHeader'>{t(tx.TX_SETTINGS_ADMIN_NEED_TO_ADD)}</div>
                  <Link className={'noUserButton'} to={URL_ADMIN_CUSTOMERS_ADD}>{t(tx.TX_ADD_NOUN, { noun: t(tx.TX_USER) })}</Link>
                </div>
              </div>
            }
            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_SETTINGS_ADMIN_USERS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending || this.state.selectedUser === null}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminUsersAdd));