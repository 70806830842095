import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { serverDateFull, serverTimeFull } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

let allActionCreators = Object.assign({});

export class DateElement extends Component {

  getLanguage() {
  	const { i18n } = this.props;
  	return getStoreLanguage(i18n);
  }

  render() {  
    return <div className={'DateElement'}>
      <div className='dateElementWrapper'>
      	<div className='deDate'>{serverDateFull(this.props.dataValue, this.getLanguage())}</div>
      	<div className='deTime'>{serverTimeFull(this.props.dataValue, this.getLanguage())}</div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(DateElement));


