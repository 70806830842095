import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { rgbToHex } from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class NoticeView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      defaultBackgroundColor: null,
      defaultTextColor: null,
    };

    this.primaryColorRef = React.createRef();
  }

  componentDidMount() {
    this.setDefaultTextColor();
    this.setDefaultBackgroundColor();
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  setDefaultTextColor() {
    try {

      const styleObj = this.primaryColorRef && this.primaryColorRef.current ? window.getComputedStyle(this.primaryColorRef.current) : null;
      const textColor = styleObj ? rgbToHex(styleObj.getPropertyValue('color')) : null;

      if(this.state.defaultTextColor !== textColor) {
        this.setState({ defaultTextColor: textColor });
      }
    } catch(err) {
      console.error(err);
    }
    return null;
  }

  setDefaultBackgroundColor() {
    try {
      const styleObj = this.primaryColorRef && this.primaryColorRef.current ? window.getComputedStyle(this.primaryColorRef.current) : null;
      const backgroundColor = styleObj ? rgbToHex(styleObj.getPropertyValue('background-color')) : null;

      if(this.state.defaultBackgroundColor !== backgroundColor) {
        this.setState({ defaultBackgroundColor: backgroundColor });
      }
    } catch(err) {
      console.error(err);
    }
    return null;
  }

  getTextColor() {
    if(!this.props.notice) { return null; }

    if(this.props.notice.textColor) {
      return `#${this.props.notice.textColor}`;
    }
    if(this.state.defaultTextColor) {
      return `#${this.state.defaultTextColor}`;
    }
    return null;
  }

  getBackgroundColor() {
    if(!this.props.notice) { return null; }

    if(this.props.notice.backgroundColor) {
      return `#${this.props.notice.backgroundColor}`;
    }
    if(this.state.defaultBackgroundColor) {
      return `#${this.state.defaultBackgroundColor}`;
    }
    return null;
  }

  render() {

    const {t} = this.props;

    return <div className={'NoticeView adminSection'}>
      <div ref={this.primaryColorRef} className={'invisibleSwatch'} />
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(this.props.noticeConfig && this.props.noticeConfig.label ? this.props.noticeConfig.label : tx.TX_null)}</div>
      </div>
      {!this.props.notice || !this.props.notice.isEnabled ?
        <div className='adminSectionDisabled'>{t(tx.TX_CURRENTLY_DISABLED)}</div> : 
        null
      }
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
 
            <div className={'detailBlock adminViewEditable'}>
              {this.props.notice && this.props.notice.isEnabled ?
                <div className='detailBlockWrapper'>
                  <div className='adminViewItem tallItem'>
                    <div className='adminViewLabel'>{t(tx.TX_NOTICE)}</div>
                    <div className='adminViewValue'>{this.props.notice.copy}</div>
                  </div>
                  <div className='adminViewItem'>
                    <div className='adminViewLabel'>{t(tx.TX_MARKETING_NOTICES_LINK_URL)}</div>
                    <div className={this.props.notice.href ? 'adminViewValue' : 'adminViewValue nullValue'}>{this.props.notice.fullHrefUrl || t(tx.TX_NONE)}</div>
                  </div>
                  <div className='adminViewItem'>
                    <div className='adminViewLabel'>{t(tx.TX_MARKETING_NOTICES_TEXT_COLOR)}</div>
                    {this.getTextColor() ?
                      <div className='adminViewValue'>
                        <div className='adminViewColorSwatch' style={{ background: this.getTextColor() }}></div>
                        <div className='adminViewColorValue'>{this.getTextColor()}</div>
                      </div> :
                      <div className='adminViewValue'></div>
                    }
                  </div>
                  <div className='adminViewItem'>
                    <div className='adminViewLabel'>{t(tx.TX_MARKETING_NOTICES_BACKGROUND_COLOR)}</div>
                    {this.getBackgroundColor() ?
                      <div className='adminViewValue'>
                        <div className='adminViewColorSwatch' style={{ background: this.getBackgroundColor() }}></div>
                        <div className='adminViewColorValue'>{this.getBackgroundColor()}</div>
                      </div> :
                      <div className='adminViewValue'></div>
                    }
                  </div>
                </div> :
                null
              }
              <div className='adminViewButtonWrapper'>
                <div className={'couponDetailsButton adminButton'} onClick={() => this.props.toggleEdit(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : null)}>{t(tx.TX_EDIT)}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='adminSectionPending' style={{display: this.props.noticePending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(NoticeView));