
import React from 'react';


const Coupon = ({ component: Component, render, ...rest }) => {

  return <svg 
    aria-hidden='true' 
    focusable='false' 
    data-prefix='fas' 
    data-icon='coupon' 
    className='iconSVG'
    role='img' 
    xmlns='http://www.w3.org/2000/svg' 
    viewBox='0 0 384 512'>
    <path 
      d='M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128c0-35.3-28.7-64-64-64S0 92.7 0 128s28.7 64 64 64s64-28.7 64-64zM384 384c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z'>
    </path>
  </svg>;
};

export default Coupon;