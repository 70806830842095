
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './style/_dropdown.scss';

let allActionCreators = Object.assign({});

// Props to be included, all other ignored
const dropdownFilterProps = [
	'onChange', 
	'name',
	'required', 
	'value',
	'id',
	'disabled',
];

const includes = (obj, includeKeys) =>
	Object.keys(obj).reduce((result, key) => {
		if(includeKeys.includes(key)) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export class Dropdown extends Component {

	getRootClass() {
		const propClass = this.props.className ? ` ${this.props.className}` : '';
		const themeClass = this.props.adminTheme ? ' titanTheme' : '';
		const errorClass = this.props.error ? ' error' : '';

		return `Dropdown${themeClass}${errorClass}${propClass}`;
	}

	render() {
		
		const {t} = this.props;
		const filteredProps = includes(this.props, dropdownFilterProps);
		
		return (<div className={this.getRootClass()}>
			<select {...filteredProps} className={this.props.error ? 'dropdownInput error' : 'dropdownInput'}>
				{this.props.options ?
					<>
						{this.props.options.map((option, i) => {
							return <option 
											key={i}
											required={this.props.required ? true : false}
											value={option.value}>
												{this.props.noTranslate ? option.display : t(option.display)}
							</option>
						})}
					</> :
					null
				}
			</select>
			<div className='dropdownButt'>
				<div className='dropdownFade'></div>
				<div className='dropdownButtBody'>
					<div className='dropdownArrowWrapper'>
						<div className='dropdownArrow'></div>
					</div>
				</div>
			</div>
		</div>);
	}
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Dropdown));