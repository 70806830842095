import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_CHECK, 
} from '../../../constants/icons';
import {
  STORE_INTEGRATION_CONFIG_TYPE_BOOL,
  STORE_INTEGRATION_CONFIG_TYPE_TEXT,
} from '../../../constants/store';
import * as tx from '../../../constants/strings';

import { 
  getNotEmptyError,
  isFormValid, 
} from '../../../utils/form-validation';

import Icon from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';
import Toggle from '../../Input/Toggle';

import * as commonActionCreators from '../../../actions/common';
const allActionCreators = Object.assign({}, commonActionCreators);

export class IntegrationConfigField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      editing: false,

      inputValue: this.getInitValue(),

      errorValue: '',

      requestOriginated: false,
    }

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.requestOriginated === true && prevProps.integrationPending === true && this.props.integrationPending === false) {
      this.setState({
        requestOriginated: false,
      });
      this.toggleEdit();
    }

    if(prevProps.integrationPending === true && this.props.integrationPending === false) {
      if(this.props.config.dataType === STORE_INTEGRATION_CONFIG_TYPE_BOOL && this.getInitValue() !== this.state.inputValue) {
        this.setState({
          inputValue: this.getInitValue(),
        });
      }
    }
  }

  getInitValue() {
    if(!this.props.integration) { return ''; }
    return this.props.config.value;
  }

  getConfigDisplayValue() {
    if(this.props.config.isPrivate) {
      if(this.props.config.hasValue()) {
        return this.props.config.hasLastFourValue() ? `${this.props.t(tx.TX_PLACEHOLDER_PASSWORD_LAST_FOUR, { lastFour: this.props.config.lastFour })}` : this.props.t(tx.TX_PLACEHOLDER_PASSWORD);
      } else {
        return '';
      }
    } else {
      if(this.props.config.hasValue()) {
        const lastFourValue = this.props.config.value.slice(-4);
        return this.props.config.displayLastFour ? this.props.t(tx.TX_PLACEHOLDER_PASSWORD_LAST_FOUR, { lastFour: lastFourValue }) : this.props.config.value;
      } else {
        return '';
      }
    }
  }

  toggleEdit() {

    if(this.props.config.disableWhenEnabled && this.props.integration.isEnabled) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_NOT_ALLOWED, 
        alertCopy: tx.TX_INTEGRATION_MUST_BE_DISABLED_TO_EDIT, 
      });
      return null;
    }

    if(this.props.config.dataType === STORE_INTEGRATION_CONFIG_TYPE_BOOL) {
      return null;
    }

    this.setState({
      editing: !this.state.editing,
    }, () => {
      if(this.state.editing && this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      } else if(this.state.editing === false) {
        this.setState({
          inputValue: this.getInitValue(),
          errorValue: '',
        });
      }
    });
  }

  changeInput(evt) {
    this.setState({
      inputValue: evt.target.value,
    }, () => {
      if(this.state.errorValue) {
        this.validateInput();
      }
    });
  }

  toggleInput() {
    this.setState({ inputValue: !this.state.inputValue}, () => {
      this.submitConfig();
    });
  }

  validateInput() {
    this.setState({ errorValue: getNotEmptyError(this.state.inputValue) });
  }

  validateAll() {
    let errorObj = {
      errorValue: this.props.config.dataType !== STORE_INTEGRATION_CONFIG_TYPE_BOOL ? getNotEmptyError(this.state.inputValue) : '',
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  submitConfig(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.props.integrationPending) {
        return null;
      }

      this.setState({
        requestOriginated: true,
      });

      this.props.setConfigAttribute(this.props.config, this.state.inputValue);
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationConfigField adminViewItem'}>
      <div className={this.props.config.isRequired ? 'adminViewLabel adminRequired' : 'adminViewLabel'}>{t(this.props.config.label)}</div>
      {this.state.editing ?
        <form className='cfEdit' onSubmit={this.submitConfig.bind(this)}>
          <div className='cfEditInputWrapper'>
            <input 
              type='text'
              ref={this.inputRef}
              className='cfEditInput'
              value={this.state.inputValue}
              onChange={this.changeInput.bind(this)} />
            {this.state.errorValue ?
              <div className={'cfEditError FieldError'}>{t(this.state.errorValue)}</div> :
              null
            }
          </div>
          <div className='cfEditSaveAction' onClick={this.submitConfig.bind(this)}>
            <div className='cfEditSaveActionIconWrapper'>
              {this.state.requestOriginated ?
                <LoadingIcon
                  iconClass={'cfEditSaveActionIcon'} /> :
                <Icon 
                  value={ICON_CHECK}  
                  iconClass={'cfEditSaveActionIcon'} />
              }
            </div>
          </div>
          <div className='cfEditCancelAction'>
            <div className={'cfEditCancelActionIconWrapper FlexCenter'} onClick={this.toggleEdit.bind(this)}>&times;</div>
          </div>
        </form> :
        <div className={'adminViewValue configValue'}>
          {this.props.config.dataType === STORE_INTEGRATION_CONFIG_TYPE_TEXT ?
            <>
              <span className={'adminViewValueData EllipsisElement'}>{this.getConfigDisplayValue()}</span>
              <span 
                className={`adminViewValueToggle ${this.props.config.hasValue() ? '' : 'noMainValue'} ${this.props.config.disableWhenEnabled && this.props.integration.isEnabled ? 'disabled' : ''}`}
                onClick={this.toggleEdit.bind(this)}>
                {t(this.props.config.hasValue() ? tx.TX_EDIT : tx.TX_ADD)}
              </span>
            </> :
            null
          }
          {this.props.config.dataType === STORE_INTEGRATION_CONFIG_TYPE_BOOL ?
            <>
              <div className='adminViewValueBooleanToggle'>
                <Toggle
                  checked={this.state.inputValue}
                  onToggle={this.toggleInput.bind(this)}
                  trueValue={tx.TX_ENABLED}
                  falseValue={tx.TX_DISABLED} />
              </div>
            </> :
            null
          }
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationConfigField));