


export function getCustomerFirstName(customer) {
	if(!customer) {
		return '';
	}
  return customer.first_name ? customer.first_name : '';
}

export function getCustomerLastName(customer) {
	if(!customer) {
		return '';
	}
  return customer.last_name ? customer.last_name : '';
}

export function getCustomerEmail(customer) {
	if(!customer) {
		return '';
	}
	return customer.email_address ? customer.email_address : '';
}