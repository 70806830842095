import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class StoreSettingsDetailsView extends Component {

  render() {

    const {t} = this.props;

    return <div className={'StoreSettingsDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_SETTINGS_STORE_SETTINGS_DETAILS)}</div>
      </div>
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_NAME)}</div>
                  <div className='adminViewValue'>{this.props.user.store.name}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
                  <div className='adminViewValue'>{this.props.user.store.email}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_PHONE_NUMBER)}</div>
                  <div className='adminViewValue'>{this.props.user.store.phone}</div>
                </div>
                <div className={'adminViewItem flexHeight'}>
                  <div className='adminViewLabel'>{t(tx.TX_ADDRESS)}</div>
                  <div className='adminViewValue'>
                    {this.props.user.store.address.format().map((line, i) => {
                      return <div key={i} className='addressLine'>{line}</div>;
                    })}
                  </div>
                </div>
              </div>
              <div className='adminViewButtonWrapper' style={{ display: 'none' }}>
                <div className={'storeSettingsDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.user.storePending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StoreSettingsDetailsView));