import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isCustomerAdmin, customerHasPermission } from '../../../utils/permissions';

import { ICON_CHECK } from '../../../constants/icons';
import * as p from '../../../constants/permissions';
import * as tx from '../../../constants/strings';

import Icon from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class CustomerPermissionsView extends Component {

  render() {

    const {t} = this.props;

    return <div className={'CustomerPermissionsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_SETTINGS_ADMIN_PERMISSIONS)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          {this.props.customerPending === false ?
            <div>
              {isCustomerAdmin(this.props.customer) ?
                <div className='hasPermissions'>
                  <div className={customerHasPermission(this.props.customer, p.P_MANAGE_PRODUCTS) ? 'permissionItem' : 'permissionItem excluded'}>
                    <div className='permissionCheck'>
                      {customerHasPermission(this.props.customer, p.P_MANAGE_PRODUCTS) ?
                        <Icon 
                          value={ICON_CHECK}  
                          iconClass={'permissionCheckIcon'} 
                          iconLabel={null} 
                          ellipsisLabel={false} /> :
                        null
                      }
                    </div>
                    <div className='permissionName'>{t(tx.TX_PERMISSION_PRODUCTS)}</div>
                    <div className='permissionDesc'>{t(tx.TX_PERMISSION_PRODUCTS_DESC)}</div>
                  </div>
                  <div className={customerHasPermission(this.props.customer, p.P_MANAGE_SALES) ? 'permissionItem' : 'permissionItem excluded'}>
                    <div className='permissionCheck'>
                      {customerHasPermission(this.props.customer, p.P_MANAGE_SALES) ?
                        <Icon 
                          value={ICON_CHECK}  
                          iconClass={'permissionCheckIcon'} 
                          iconLabel={null} 
                          ellipsisLabel={false} /> :
                        null
                      }
                    </div>
                    <div className='permissionName'>{t(tx.TX_PERMISSION_SALES)}</div>
                    <div className='permissionDesc'>{t(tx.TX_PERMISSION_SALES_DESC)}</div>
                  </div>
                  <div className={customerHasPermission(this.props.customer, p.P_MANAGE_USERS) ? 'permissionItem' : 'permissionItem excluded'}>
                    <div className='permissionCheck'>
                      {customerHasPermission(this.props.customer, p.P_MANAGE_USERS) ?
                        <Icon 
                          value={ICON_CHECK}  
                          iconClass={'permissionCheckIcon'} 
                          iconLabel={null} 
                          ellipsisLabel={false} /> :
                        null
                      }
                    </div>
                    <div className='permissionName'>{t(tx.TX_PERMISSION_USERS)}</div>
                    <div className='permissionDesc'>{t(tx.TX_PERMISSION_USERS_DESC)}</div>
                  </div>
                  <div className={customerHasPermission(this.props.customer, p.P_MANAGE_EVENTS) ? 'permissionItem' : 'permissionItem excluded'}>
                    <div className='permissionCheck'>
                      {customerHasPermission(this.props.customer, p.P_MANAGE_EVENTS) ?
                        <Icon 
                          value={ICON_CHECK}  
                          iconClass={'permissionCheckIcon'} 
                          iconLabel={null} 
                          ellipsisLabel={false} /> :
                        null
                      }
                    </div>
                    <div className='permissionName'>{t(tx.TX_PERMISSION_EVENTS)}</div>
                    <div className='permissionDesc'>{t(tx.TX_PERMISSION_EVENTS_DESC)}</div>
                  </div>
                  <div className={customerHasPermission(this.props.customer, p.P_MANAGE_PERMISSIONS) ? 'permissionItem' : 'permissionItem excluded'}>
                    <div className='permissionCheck'>
                      {customerHasPermission(this.props.customer, p.P_MANAGE_PERMISSIONS) ?
                        <Icon 
                          value={ICON_CHECK}  
                          iconClass={'permissionCheckIcon'} 
                          iconLabel={null} 
                          ellipsisLabel={false} /> :
                        null
                      }
                    </div>
                    <div className='permissionName'>{t(tx.TX_PERMISSION_PERMISSIONS)}</div>
                    <div className='permissionDesc'>{t(tx.TX_PERMISSION_PERMISSIONS_DESC)}</div>
                  </div>
                  <div className='hasPermissionsButtonWrapper'>
                    <div className={'hasPermissionsButton adminButton adminButtonDesktop'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_SETTINGS_EDIT_PERMISSIONS)}</div>
                  </div>
                </div> :
                <div className='noPermissions'>
                  <div className='noPermissionsTitle'>{t(tx.TX_CUSTOMER_NO_PERMISSIONS_TITLE)}</div>
                  <div className='noPermissionsCopy'>{t(tx.TX_CUSTOMER_NO_PERMISSIONS_COPY)}</div>
                  <div className='noPermissionsCopy'>{t(tx.TX_CUSTOMER_NO_PERMISSIONS_COPY_2)}</div>
                  <div className='noPermissionsButtonWrapper'>
                    <div className={'noPermissionsButton adminButton adminButtonDesktop'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_CUSTOMER_ADD_PERMISSIONS)}</div>
                  </div>
                </div>
              }
            </div> :
            null
          }
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.customerPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerPermissionsView));