
import * as x from '../constants/actions';

import { 
  getStoredEnabledProductLines, 
  getStoredMenu, 
  saveEnabledProductLines, 
  saveMenu, 
} from '../utils/product';

const initialState = {

  productLines: null,
  productLinesPending: false,

  productLinesManaged: null,
  productLinesManagedPending: false,

  menus: getStoredMenu(),
  menusPending: false,

  enabled: getStoredEnabledProductLines(),
  enabledPending: false,

  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_PRODUCT_LINE_SET_ALL:
      return plSetAll(state, action);
    case x.ACTION_PRODUCT_LINE_ALL_SET_PENDING:
      return plAllSetPending(state, action);
    case x.ACTION_PRODUCT_LINE_SET_MANAGED:
      return plSetManaged(state, action);
    case x.ACTION_PRODUCT_LINE_MANAGED_SET_PENDING:
      return plManagedSetPending(state, action);
    case x.ACTION_PRODUCT_LINE_SET_MENUS:
      return plSetMenus(state, action);
    case x.ACTION_PRODUCT_LINE_MENUS_SET_PENDING:
      return plMenusPending(state, action);
    case x.ACTION_PRODUCT_LINE_SET_ENABLED:
      return plSetEnabled(state, action);
    case x.ACTION_PRODUCT_LINE_ENABLED_SET_PENDING:
      return plEnabledPending(state, action);
    case x.ACTION_PRODUCT_LINE_SET_DATA:
      return plSetData(state, action);
    default:
      return state;
  }
}


function plSetAll(state, action) {
  return Object.assign({}, state, {
    productLines: action.payload,
  });
}

function plAllSetPending(state, action) {
  return Object.assign({}, state, {
    productLinesPending: action.payload,
  });
}

function plSetManaged(state, action) {
  return Object.assign({}, state, {
    productLinesManaged: action.payload,
  });
}

function plManagedSetPending(state, action) {
  return Object.assign({}, state, {
    productLinesManagedPending: action.payload,
  });
}

function plSetMenus(state, action) {
  saveMenu(action.payload);
  return Object.assign({}, state, {
    menus: action.payload,
  });
}

function plMenusPending(state, action) {
  return Object.assign({}, state, {
    menusPending: action.payload,
  });
}

function plSetEnabled(state, action) {
  saveEnabledProductLines(action.payload);
  return Object.assign({}, state, {
    enabled: action.payload,
  });
}

function plEnabledPending(state, action) {
  return Object.assign({}, state, {
    enabledPending: action.payload,
  });
}

function plSetData(state, action) {
  return Object.assign({}, state, {
    data: Object.assign({}, state.data, action.payload),
  });
}



