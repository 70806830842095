import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { breaksToBrs } from '../../../utils/formatting';

let allActionCreators = Object.assign({});

export class ProductPageDetails extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductPageDetails'}>
      <div className='productPageDetailsWrapper'>
        <div className='ppHeaderWrapper'></div>
        <div className='ppBorderWrapper'>
          {!this.props.productObj.description ?
            <div className='ppGenericDetails'>
              <div className={'ppGenericNoDesc FlexCenter'}>{t(tx.TX_PRODUCT_PAGE_NO_DESCRIPTION)}</div>
            </div> :
            <div className='ppGenericDetails' dangerouslySetInnerHTML={{ __html: breaksToBrs(this.props.productObj.description, this.descriptionBreakStyle) }} />
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetails));