
import React, { Component } from 'react';

import './style/_checkbox.scss';

const checkboxFilterProps = [
	'adminTheme',
	'ambiguousCheck',
	'className',
];

const omit = (obj, omitKeys) =>
	Object.keys(obj).reduce((result, key) => {
		if(!omitKeys.includes(key)) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export default class Checkbox extends Component {

	render() {
		return (<div className={`Checkbox ${this.props.adminTheme ? 'adminTheme' : ''}`}>
			<label htmlFor={this.props.id}>
				<div 
					className='checkbox__input'>
			    <input 
			    	type='checkbox' 
			    	className={this.props.className || ''}
			    	{...omit(this.props, checkboxFilterProps)} 
			    />
			    <span className={`checkbox__control${this.props.error ? ' error' : ''}`}>
			    	{this.props.ambiguousCheck ?
			    		<div className='ambiguousDotWrapper'>
			    			<div className='ambiguousDot'></div>
			    		</div> :
			    		<svg
				        xmlns='http://www.w3.org/2000/svg'
				        viewBox='0 0 24 24'
				        aria-hidden='true'
				        focusable='false'
				        width='100%'
				        height='100%'
				        fill='none'>
				        <path
				          strokeWidth='3'
				          d='M1.73 12.91l6.37 6.37L22.79 4.59' />
				      </svg>
			    	}
			    </span>
			  </div>
			</label>
		</div>);
	}
}