import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import {
  STORE_INTEGRATION_TYPE_COMMON_KEY,
  STORE_INTEGRATION_TYPE_SHIPPING_KEY,
} from '../../constants/store';
import * as tx from '../../constants/strings';
import {
  URL_ADMIN_SETTINGS, 
  URL_ADMIN_SETTINGS_INTEGRATIONS, 
} from '../../constants/urls';

import { formatServerError } from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import DeletePrompt from '../Popups/DeletePrompt';
import IntegrationCommonEdit from './blocks/IntegrationCommonEdit';
import IntegrationCommonView from './blocks/IntegrationCommonView';
import IntegrationShippingConfigView from './blocks/IntegrationShippingConfigView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_integrations.scss';

import * as commonActionCreators from '../../actions/common';
import * as storeActionCreators from '../../actions/store';
let allActionCreators = Object.assign({}, commonActionCreators, storeActionCreators);

export class ViewIntegration extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirtyCommon: false,
      isDirtyShipping: false,
      
      savePromptOpen: false,
      deletePromptOpen: false,
      lastBlock: '',

      editCommon: false,

      configExpanded: false,

      requestPending: true,
      requestError: null,
      responseObj: null,

      savingAll: false,
      remoteSaveCommon: false,

      loadCount: 0,
    };

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyCommon === false && this.state.isDirtyShipping === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchIntegration();
  }

  componentWillUnmount() {
    this.unblock();
    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyCommon === false && this.state.isDirtyShipping === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchIntegration();
      }
    }
  }

  async fetchIntegration(service = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    // Don't set requestObj to null; will close individual config fields
    this.setState({
      requestPending: true,
      requestError: null,
    });

    // Get integration object
    if(this.props.match.params.providerKey) {
      const fetchResp = await this.props.storeFetchProvider(this.props.match.params.providerKey, controller.signal)
        .catch((errResp) => {
          
          if(controller.signal.aborted) { return null; }

          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
            responseObj: null,
          });
        });

      if(!fetchResp) { return null; }

      this.setState({
        requestPending: false,
        requestError: null,
        responseObj: fetchResp,
        loadCount: this.state.loadCount + 1,
      });
      if(service === STORE_INTEGRATION_TYPE_COMMON_KEY) {
        this.setState({ isDirtyCommon: false });
      } else if(service === STORE_INTEGRATION_TYPE_SHIPPING_KEY) {
        this.setState({ isDirtyShipping: false });
      }
    }
  }

  makeCommonDirty() {
    if(this.state.isDirtyCommon === false) {
      this.setState({ isDirtyCommon: true });
    }
  }

  toggleCommon() {
    this.setState({ editCommon: !this.state.editCommon }, () => {
      if(this.state.editCommon === false) {
        this.setState({ isDirtyCommon: false, });
      }
    });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    // this.setState({
    //   savingAll: true, 
    //   remoteSaveCommon: this.state.isDirtyCommon,
    // });
  }

  finishRemote(service, didFail = false) {

    // if(service === STORE_INTEGRATION_TYPE_COMMON_KEY) {
    //   this.setState({ 
    //     isDirtyCommon: didFail !== false, 
    //     remoteSaveCommon: false, 
    //   }, () => {
    //     this.setState({ savingAll: this.state.remoteSaveCommon === true });
    //   });
    // } else if(service === STORE_INTEGRATION_TYPE_SHIPPING_KEY) {

    // }
  }

  toggleDeletePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    // this.setState({
    //   deletePromptOpen: !this.state.deletePromptOpen,
    // });
  }

  deleteButtonAction(evt) {
    if(evt) { evt.preventDefault(); }

    // if(this.state.responseObj.isEnabled) {
    //   this.props.commonAlert({ 
    //     alertTitle: tx.TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_TITLE, 
    //     alertCopy: tx.TX_SETTINGS_PAYMENT_METHODS_DELETE_ALERT_COPY, 
    //   });
    // } else {
    //   this.toggleDeletePrompt();
    // }
  }

  deleteAction(evt) {
    if(evt) { evt.preventDefault(); }

    // this.setState({
    //   requestPending: true,
    //   requestError: null,
    // });

    // this.props.checkoutPaymentMethodDelete(this.state.responseObj.publicUuid)
    // .then((resp) => {
    //   this.setState({ isDirty: false }, () => {
    //     history.push(URL_ADMIN_SETTINGS_PAYMENT_METHODS);
    //   });
    // })
    // .catch((errResp) => {
    //   console.error(errResp);
    //   this.setState({ 
    //     requestPending: false,
    //     requestError: formatServerError(errResp),
    //   });
    // });
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewIntegration AdminPage'}>
      <AdminTitle
        title={tx.TX_VIEW}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
          {
            url: URL_ADMIN_SETTINGS_INTEGRATIONS,
            title: tx.TX_SETTINGS_INTEGRATIONS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>

          {this.state.editCommon ? 
            <IntegrationCommonEdit
              integration={this.state.responseObj}
              integrationPending={this.state.requestPending}
              toggleEdit={this.toggleCommon.bind(this)}
              makeDirty={this.makeCommonDirty.bind(this)}
              makeClean={this.fetchIntegration.bind(this)} 
              remoteSave={this.state.remoteSaveCommon}
              finishRemote={this.finishRemote.bind(this)} /> :
            <IntegrationCommonView
              integration={this.state.responseObj}
              integrationPending={this.state.requestPending}
              toggleEdit={this.toggleCommon.bind(this)}
              makeClean={this.fetchIntegration.bind(this)}
              initialLoad={this.state.loadCount <= 1} />
          }

          {this.state.responseObj && this.state.responseObj.hasIntegration(STORE_INTEGRATION_TYPE_SHIPPING_KEY) ?
            <IntegrationShippingConfigView
              integration={this.state.responseObj.getIntegration(STORE_INTEGRATION_TYPE_SHIPPING_KEY)}
              integrationPending={this.state.requestPending}
              makeClean={this.fetchIntegration.bind(this)} /> :
            null
          }

        </div>
      </div>
      <div className='adminActions'>
        <button className='blockButton' onClick={this.deleteButtonAction.bind(this)} style={{ display: 'none' }}>{t(tx.TX_DELETE)}</button>
        <Link to={URL_ADMIN_SETTINGS_INTEGRATIONS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <DeletePrompt
        open={this.state.deletePromptOpen}
        copy={tx.TX_SETTINGS_PAYMENT_METHODS_DELETE_COPY}
        closeMethod={this.toggleDeletePrompt.bind(this)}
        onConfirm={this.deleteAction.bind(this)}
        onCancel={this.toggleDeletePrompt.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewIntegration));