export const loadSquareCardPayments = (loadFunction) => {
  
  //loads the Square Web Payments SDK
  (function () {

    const id = 'square-web-payments';
    const src = process.env.REACT_APP_BUILD_SQUARE_CARD_PAYMENT_SDK;
    
    //we have at least one script (React)
    const firstJs = document.getElementsByTagName('script')[0];
    
    // Prevent script from loading twice;
    // Should still execute loadFunction as that attaches to a component's input
    if(document.getElementById(id)) { 
      if(loadFunction) { loadFunction(); }
      return null; 
    }
    const js = document.createElement('script'); 
    js.id = id;
    js.src = src;
    js.onload = loadFunction; 
    firstJs.parentNode.insertBefore(js, firstJs);
  }());
}