import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { StoreDaySchedule } from '../../../models/stores';

import { 
  dateDelta,
  getStoreTimeZone,
  is24HourTime, 
  twoDigitInt, 
} from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';

import Dropdown from '../../Input/Dropdown';

const allActionCreators = Object.assign({});

export class StoreSettingsHourLine extends Component {

  constructor(props) {
    super(props);

    const is24hr = is24HourTime(this.getLanguage());

    const initStart = new Date();
    const initEnd = new Date();

    let startHourValue = initStart.getHours();
    if(is24hr === false) {
      if(startHourValue === 0) {
        startHourValue = 12;
      } else if(startHourValue > 12) {
        startHourValue = startHourValue - 12;
      }
    }

    let endHourValue = initEnd.getHours();
    if(is24hr === false) {
      if(endHourValue === 0) {
        endHourValue = 12;
      } else if(endHourValue > 12) {
        endHourValue = endHourValue - 12;
      }
    }

    this.state = {

      isClosed: true,

      inputStartTimeHour: startHourValue,
      inputStartTimeMinutes: twoDigitInt(initStart.getMinutes()),
      inputStartTimePost: initStart.getHours() >= 12 && initStart.getHours() !== 24 ? 'pm' : 'am',

      inputEndTimeHour: endHourValue,
      inputEndTimeMinutes: twoDigitInt(initEnd.getMinutes()),
      inputEndTimePost: initEnd.getHours() >= 12 && initEnd.getHours() !== 24 ? 'pm' : 'am',

      errorStartTime: '',
      errorEndTime: '',

      is24HourTime: is24hr,
    };

    this.startHourInput = React.createRef();
    this.endHourInput = React.createRef();
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  checkStartTimeClick() {

  }

  toggleEdit() {
    this.setState({ isClosed: !this.state.isClosed });
  }

  changeStartTimeHour(evt) {
    // this.setState({
    //   inputTimeHour: evt.target.value,
    // }, () => {
    //   this.makeDirty();
    // });
  }

  validateStartTimeHour() {
    
    // const hrValue = parseInt(this.state.inputTimeHour);

    // const minValue = this.state.is24HourTime ? 0 : 1;
    // const maxValue = this.state.is24HourTime ? 23 : 12;
    
    // if(!hrValue && hrValue !== 0) {
    //   this.setState({ inputTimeHour: '' });
    // } else if(hrValue < minValue) {
    //   this.setState({ inputTimeHour: minValue });
    // } else if(hrValue > maxValue) {
    //   this.setState({ inputTimeHour: maxValue });
    // }
  }

  changeStartTimeMinutes(evt) {
    // this.setState({
    //   inputTimeMinutes: evt.target.value,
    // }, () => {
    //   this.makeDirty();
    // });
  }

  validateStartTimeMinutes() {

    // const minutes = parseInt(this.state.inputTimeMinutes);

    // const minValue = 0;
    // const maxValue = 59;
    
    // if(!minutes && minutes !== 0) {
    //   this.setState({ inputTimeMinutes: '' });
    // } else if(minutes < minValue) {
    //   this.setState({ inputTimeMinutes: minValue });
    // } else if(minutes > maxValue) {
    //   this.setState({ inputTimeMinutes: maxValue });
    // }
  }

  changeStartTimePost(evt) {
    // this.setState({
    //   inputTimePost: evt.target.value,
    // }, () => {
    //   this.makeDirty();
    // });
  }

  getTimePostOptions() {
    return [
      {
        display: 'am',
        value: 'am',
      },
      {
        display: 'pm',
        value: 'pm',
      },
    ];
  }

  changeEndTimeHour(evt) {
    // this.setState({
    //   inputTimeHour: evt.target.value,
    // }, () => {
    //   this.makeDirty();
    // });
  }

  validateEndTimeHour() {
    
    // const hrValue = parseInt(this.state.inputTimeHour);

    // const minValue = this.state.is24HourTime ? 0 : 1;
    // const maxValue = this.state.is24HourTime ? 23 : 12;
    
    // if(!hrValue && hrValue !== 0) {
    //   this.setState({ inputTimeHour: '' });
    // } else if(hrValue < minValue) {
    //   this.setState({ inputTimeHour: minValue });
    // } else if(hrValue > maxValue) {
    //   this.setState({ inputTimeHour: maxValue });
    // }
  }

  changeEndTimeMinutes(evt) {
    // this.setState({
    //   inputTimeMinutes: evt.target.value,
    // }, () => {
    //   this.makeDirty();
    // });
  }

  validateEndTimeMinutes() {

    // const minutes = parseInt(this.state.inputTimeMinutes);

    // const minValue = 0;
    // const maxValue = 59;
    
    // if(!minutes && minutes !== 0) {
    //   this.setState({ inputTimeMinutes: '' });
    // } else if(minutes < minValue) {
    //   this.setState({ inputTimeMinutes: minValue });
    // } else if(minutes > maxValue) {
    //   this.setState({ inputTimeMinutes: maxValue });
    // }
  }

  changeEndTimePost(evt) {
    // this.setState({
    //   inputTimePost: evt.target.value,
    // }, () => {
    //   this.makeDirty();
    // });
  }

  render() {

    const {t} = this.props;

    return <div className={'StoreSettingsHourLine'}>
      <div className='storeHourRow'>
          
        <div className='shDayLabel'>{t(StoreDaySchedule.dayCopy(this.props.dayCode))}</div>
        <div className='shDayControls'>
          {this.state.isClosed ?
            <div className='shDayClosed'>
              <div className='closedLabel'>{t(tx.TX_CLOSED)}</div>
              <div className='closedToggle' onClick={this.toggleEdit.bind(this)}>{t(tx.TX_SETTINGS_STORE_SETTINGS_HOURS_ADD)}</div>
            </div> :
            <div className='shDayEdit'>
              <div className='adminFieldWrapper'>
                <div className={'adminInputWrapper halfWidth'}>
                  <div className={this.state.errorStartTime ? 'adminInputTime fieldError' : 'adminInputTime'} onClick={this.checkStartTimeClick.bind(this)}>

                    <div className='aitLiner'>
                      <div className={'aitHourWrapper aitElement'}>
                        <input
                          type='number'
                          ref={this.startHourInput}
                          className={this.state.errorStartTimeHour ? 'InputError' : ''}
                          value={this.state.inputStartTimeHour}
                          onChange={this.changeStartTimeHour.bind(this)}
                          onBlur={this.validateStartTimeHour.bind(this)}
                          placeholder={t(this.state.is24HourTime ? tx.TX_PLACEHOLDER_TIME_HOUR_24 : tx.TX_PLACEHOLDER_TIME_HOUR)}
                          min={this.state.is24HourTime ? 0 : 1}
                          max={this.state.is24HourTime ? 23 : 12} />
                      </div>
                      <div className={'aitDelimiterWrapper aitElement'}>
                        <div className={'delimWrapper FlexCenter'}>:</div>
                      </div>
                      <div className={'aitMinutesWrapper aitElement'}>
                        <input
                          type='number'
                          className={this.state.errorStartTimeMinutes ? 'InputError' : ''}
                          value={this.state.inputStartTimeMinutes}
                          onChange={this.changeStartTimeMinutes.bind(this)}
                          onBlur={this.validateStartTimeMinutes.bind(this)}
                          placeholder={t(tx.TX_PLACEHOLDER_TIME_MINUTES)}
                          min={0}
                          max={59} />
                      </div>
                      {!this.state.is24HourTime ?
                        <div className={'aitPostScriptWrapper aitElement'}>
                          <Dropdown 
                            className={'aitPostSelect'}
                            options={this.getTimePostOptions()}
                            name={t(tx.TX_TIME)}
                            value={this.state.inputStartTimePost}
                            noTranslate={true}
                            onChange={this.changeStartTimePost.bind(this)} />
                        </div> :
                        null
                      }
                    </div>

                    {this.state.errorTime ?
                      <div className={'adminError FieldError'}>{t(this.state.errorTime)}</div> :
                      null
                    }
                  </div>
                </div>
                <div className={'adminInputWrapper halfWidth'}>
                  <div className={this.state.errorStartTime ? 'adminInputTime fieldError' : 'adminInputTime'} onClick={this.checkStartTimeClick.bind(this)}>

                    <div className='aitLiner'>
                      <div className={'aitHourWrapper aitElement'}>
                        <input
                          type='number'
                          ref={this.startHourInput}
                          className={this.state.errorStartTimeHour ? 'InputError' : ''}
                          value={this.state.inputStartTimeHour}
                          onChange={this.changeStartTimeHour.bind(this)}
                          onBlur={this.validateStartTimeHour.bind(this)}
                          placeholder={t(this.state.is24HourTime ? tx.TX_PLACEHOLDER_TIME_HOUR_24 : tx.TX_PLACEHOLDER_TIME_HOUR)}
                          min={this.state.is24HourTime ? 0 : 1}
                          max={this.state.is24HourTime ? 23 : 12} />
                      </div>
                      <div className={'aitDelimiterWrapper aitElement'}>
                        <div className={'delimWrapper FlexCenter'}>:</div>
                      </div>
                      <div className={'aitMinutesWrapper aitElement'}>
                        <input
                          type='number'
                          className={this.state.errorStartTimeMinutes ? 'InputError' : ''}
                          value={this.state.inputStartTimeMinutes}
                          onChange={this.changeStartTimeMinutes.bind(this)}
                          onBlur={this.validateStartTimeMinutes.bind(this)}
                          placeholder={t(tx.TX_PLACEHOLDER_TIME_MINUTES)}
                          min={0}
                          max={59} />
                      </div>
                      {!this.state.is24HourTime ?
                        <div className={'aitPostScriptWrapper aitElement'}>
                          <Dropdown 
                            className={'aitPostSelect'}
                            options={this.getTimePostOptions()}
                            name={t(tx.TX_TIME)}
                            value={this.state.inputStartTimePost}
                            noTranslate={true}
                            onChange={this.changeStartTimePost.bind(this)} />
                        </div> :
                        null
                      }
                    </div>

                    {this.state.errorTime ?
                      <div className={'adminError FieldError'}>{t(this.state.errorTime)}</div> :
                      null
                    }
                  </div>
                </div>
                <div className={'shRange FlexCenter'}>&mdash;</div>
                <div className={'shDayToggle FlexCenter'} onClick={this.toggleEdit.bind(this)}>&times;</div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StoreSettingsHourLine));

