
import { 
	toDate, 
	utcToZonedTime, 
	zonedTimeToUtc, 
} from 'date-fns-tz';

import { CAL_DAY_NAMES } from '../constants/calendar';
import { 
	EVENT_EXCEPTION_TYPE_DELETION,
	EVENT_EXCEPTION_TYPE_MODIFICATION,
	EVENT_FILTER_KEY_FORMAT,
	EVENT_FILTER_KEY_PREMIUM,
	EVENT_FILTER_KEY_PRODUCT_LINE,
	EVENT_FREQUENCY_API_MONTHLY,
	EVENT_FREQUENCY_DAILY,
	EVENT_FREQUENCY_MAP_TO_BACKEND, 
	EVENT_FREQUENCY_MONTHLY_WEEK, 
	EVENT_FREQUENCY_WEEKLY,
	EVENT_MONTHLY_WEEK_OPTIONS,
} from '../constants/events';
import { PL_EVENT_FORMATS } from '../constants/product';
import {
	TX_CAL_FREQUENCY_DAILY,
	TX_CAL_FREQUENCY_WEEKLY,
	TX_null,
} from '../constants/strings';
import { 
  URL_PARAM_DAY,
  URL_PARAM_MONTH,
  URL_PARAM_RECURRING,
  URL_PARAM_VIEW, 
  URL_PARAM_YEAR,
  URL_PLAY_GENERAL, 
} from '../constants/urls';

import { 
	Month, 
} from './calendar';
import { ProductLine } from './products';

import { 
	dateDelta,
	getStoreTimeZone,
	isVarString,
	twoDigitInt, 
} from '../utils/general';
import { getUrlParams } from '../utils/request';


// StoreEventSchedule dates and time are in local because they can span daylight savings times whereas StoreEvent dates and times are UTC since they're single events
export class StoreEvent {

	constructor(props) {

		if(!props) { props = {}; }

		// Both selectedTime and startTime map to models startTime attribute;
		// Use selectedTime when passing user input so their input (ie 7pm) is always in store local time
		// Also for making a virtual event from a schedule
		const selectedTime = props.selectedTime || null;

		// Start date and time either Date or DB string;
		const startTime = props.startTime || props.start_time || null;

		const productLineObj = props.productLine || props.product_line || null;
		const formatKey = props.format || props.event_format || '';

		this.id = props.id || null;
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.duration = props.duration || 180; // Duration in minutes
		this.name = props.name || '';
		this.description = props.description || '';
		this.capacity = parseInt(props.capacity) || 0;
		this.cost = parseFloat(props.cost) || 0;
		this.isPremium = props.isPremium || props.is_premium || false;
		
		this.productLine = new ProductLine(productLineObj);
		this.format = new EventFormat(formatKey);
		this.schedule = props.schedule ? new StoreEventSchedule(props.schedule) : null;
		

		if(startTime) {
			this.startTime = startTime instanceof Date ? startTime : new Date(startTime);
		} else if(selectedTime && selectedTime instanceof Date) {
			this.startTime = zonedTimeToUtc(selectedTime, getStoreTimeZone());
		} else {
			this.startTime = null;
		}
	}

	get isRepeating() {
		return this.schedule !== null;
	}

	getUrl(day = null) {
		if(this.schedule) {
			return this.schedule.getUrl(day);
		}
		if(this.publicUuid) {
			return `${URL_PLAY_GENERAL}${getUrlParams(this.getUrlParams())}`;
		}
		return URL_PLAY_GENERAL;
	}

	getUrlParams() {
		if(this.schedule) {
			return this.schedule.getUrlParams(this.startTime);
		}
		if(this.publicUuid) {
			const params = {
				[URL_PARAM_VIEW]: this.publicUuid,
			}
			if(this.startTime) {

				const zonedDate = utcToZonedTime(this.startTime, getStoreTimeZone());

				params[URL_PARAM_DAY] = zonedDate.getDate();
				params[URL_PARAM_MONTH] = zonedDate.getMonth();
				params[URL_PARAM_YEAR] = zonedDate.getFullYear();
			}
			return params;
		}
		return {};
	}

	hasFormat() {
		return this.format && this.format.key ? true : false;
	}

	hasPassed() {
		if(!this.startTime) {
			return true;
		}
    return this.startTime < new Date();
  }

  includedInFilters(filters = {}) {
  	for(const key in filters) {
  		if(key === EVENT_FILTER_KEY_PRODUCT_LINE && filters[key]) {
  			if(filters[key] !== this.productLine.permalink) {
  				return false;
  			}
  		} else if(key === EVENT_FILTER_KEY_FORMAT && filters[key]) {
  			if(filters[key] !== this.format.key) {
  				return false;
  			}
  		} else if(key === EVENT_FILTER_KEY_PREMIUM && filters[key]) {
  			if(filters[key] === true && filters[key] !== this.isPremium) {
  				return false;
  			}
  		}
  	}
  	return true;
  }

  getApiDaysOfWeek() {
  	const daysArray = [];
  	try {
  		for(const day of this.schedule.daysOfWeek) {
  			if(day === 0) {
  				daysArray.push(6);
  			} else {
  				daysArray.push(day - 1);
  			}
  		}
  		return daysArray;
  	} catch(err) {
  		console.error(err);
  		return daysArray;
  	}
  }

	getApiData() {

		const zonedDate = utcToZonedTime(this.startTime, getStoreTimeZone());

  	const apiData = {
      duration: this.duration,
	    start_date: `${zonedDate.getFullYear()}-${twoDigitInt(zonedDate.getMonth() + 1)}-${twoDigitInt(zonedDate.getDate())}`,
	    start_time: `${twoDigitInt(zonedDate.getHours())}:${twoDigitInt(zonedDate.getMinutes())}`,
	    name: this.name,
	    description: this.description,
	    capacity: this.capacity,
	    cost: this.cost,
	    recurrent: this.isRepeating,
	    is_premium: this.isPremium,
		};
		if(this.productLine.id) {
			apiData['product_line_id'] = this.productLine.id;
		}
		if(this.hasFormat()) {
			apiData['event_format'] = this.format.key;
		}
		if(this.isRepeating) {
			apiData['schedule_type'] = EVENT_FREQUENCY_MAP_TO_BACKEND[this.schedule.scheduleType];
			apiData['days_of_week'] = this.getApiDaysOfWeek();
			apiData['period_of_number'] = this.schedule.periodOfNumber;
			apiData['period_of_type'] = this.schedule.periodOfType;

			if(this.schedule.endDate) {
				apiData['end_date'] = `${this.schedule.endDate.getFullYear()}-${twoDigitInt(this.schedule.endDate.getMonth() + 1)}-${twoDigitInt(this.schedule.endDate.getDate())}`;				
			}
		}
		return apiData;
	}
}

// StoreEventSchedule dates and time are in local because they can span daylight savings times whereas StoreEvent dates and times are UTC since they're single events
export class StoreEventSchedule {

	constructor(props) {

		if(!props) { props = {}; }

		// Use selectedTime when passing user input datetime so their input (ie 7pm) is always in store local time
		const selectedDatetime = props.selectedTime || null;

		// Start date and time either Date or DB string;
		const startDate = props.startDate || props.start_date || null;
		const startTime = props.startTime || props.start_time || null;
		const endDate = props.endDate || props.end_date || null;

		const exceptionArray = props.exceptions || [];
		const formatKey = props.format || props.event_format || '';
		const productLineObj = props.productLine || props.product_line || null;

		this.publicUuid = props.publicUuid || props.public_uuid || '';

		this.name = props.name || '';
		this.description = props.description || '';
		this.capacity = parseInt(props.capacity) || 0;
		this.cost = parseFloat(props.cost) || 0;
		this.duration = props.duration || 180; // Duration in minutes

		this.productLine = new ProductLine(productLineObj);
		this.format = new EventFormat(formatKey);
		
		if(startDate) {
			this.startDate = startDate instanceof Date ? startDate : toDate(startDate);
		} else if(selectedDatetime && selectedDatetime instanceof Date) {
			this.startDate = selectedDatetime;
		} else {
			this.startDate = null;
		}

		if(startTime) {
			if(startTime instanceof Date) {
				this.startTime = startTime;
			} else {
				const baseDate = new Date();
				const timeArray = startTime.split(':');
				if(timeArray.length === 3) {
					baseDate.setHours(parseInt(timeArray[0]), parseInt(timeArray[1]), parseInt(timeArray[2]));
					this.startTime = baseDate;
				} else {
					this.startTime = null;
				}
			}
		} else if(selectedDatetime && selectedDatetime instanceof Date) {
			this.startTime = selectedDatetime;
		} else {
			this.startTime = null;
		}

		if(endDate) {
			// End date is a date, not a datetime, so it should not be made timezone aware
			this.endDate = endDate instanceof Date ? endDate : new Date(endDate);
		} else {
			this.endDate = null;
		}

		this.periodOfType = 'week';
		
		const periodOfNumberVal = props.periodOfNumber || props.period_of_number || 0;
		this.periodOfNumber = periodOfNumberVal !== 0 ? periodOfNumberVal : StoreEventSchedule.defaultPeriodOfNumber(this.startDate);

		const scheduleTypeVal = props.scheduleType || props.schedule_type || '';
		this.scheduleType = scheduleTypeVal === EVENT_FREQUENCY_API_MONTHLY ? `${scheduleTypeVal}:${this.periodOfType}` : scheduleTypeVal;

		const daysOfWeekVal = props.daysOfWeek || props.days_of_week || [];
		if(daysOfWeekVal && daysOfWeekVal.length > 0) {
			this.daysOfWeek = props.days_of_week ? StoreEventSchedule.adjustServerDays(daysOfWeekVal) : daysOfWeekVal;
		} else {
			this.daysOfWeek = StoreEventSchedule.defaultDaysOfWeek(this.startDate);
		}

		this.exceptions = [];
		for(const ex of exceptionArray) {
			this.exceptions.push(new StoreEventScheduleException(ex));
		}
	}

	getRepeatFrequencyCopy(t) {
		try {
			switch(this.scheduleType) {
				case EVENT_FREQUENCY_DAILY:
					return t(TX_CAL_FREQUENCY_DAILY);
				case EVENT_FREQUENCY_WEEKLY:
					return t(TX_CAL_FREQUENCY_WEEKLY);
				case EVENT_FREQUENCY_MONTHLY_WEEK:
					const occurance = this.periodOfNumber < 0 ? 5 : this.periodOfNumber;
	        return t(EVENT_MONTHLY_WEEK_OPTIONS[occurance], { day: t(CAL_DAY_NAMES[this.daysOfWeek[0].getDay()]) });
				default:
					return TX_null;
			}
		} catch(err) {
			return TX_null;
		}
	}

	static defaultDaysOfWeek(testDate) {
		try{
			return [ testDate.getDay() ];
		} catch(err) {
			return [];
		}
	}

	static defaultPeriodOfNumber(testDate) {
		try {
			return Month.nthDay(testDate) < 5 ? Month.nthDay(testDate) : -1;
		} catch(err) {
			return 0;
		}
	}

	static adjustServerDays(dayArray) {
		const adjustedArray = [];
		for(const day of dayArray) {
			adjustedArray.push(day === 6 ? 0 : day + 1);
		}
		return adjustedArray;
	}

	getUrl(day = null) {
		const eventDate = day || this.nextEventDate();
		if(eventDate) {
			return `${URL_PLAY_GENERAL}${getUrlParams(this.getUrlParams(eventDate))}`;
		}
		return URL_PLAY_GENERAL;
	}

	getUrlParams(day = null) {
		const eventDate = day || this.nextEventDate();
		if(eventDate) {
			return {
				[URL_PARAM_RECURRING]: this.publicUuid,
				[URL_PARAM_DAY]: eventDate.getDate(),
				[URL_PARAM_MONTH]: eventDate.getMonth(),
				[URL_PARAM_YEAR]: eventDate.getFullYear(),
			};
		}
		return {};
	}

	nextEventDate() {

		let testDate = new Date();
		const endDate = this.endDate ? new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate()) : null;

		while(endDate !== null && testDate > dateDelta(endDate, 1)) {
			let testEvent = this.getEvent(testDate);
			if(testEvent !== null) {
				return testDate;
			}
			testDate = dateDelta(testDate, 1);
		}
		return null;
	}

	hasFormat() {
		return this.format && this.format.key ? true : false;
	}

	hasPassed() {
		if(!this.endDate) {
			return false;
		}
    return this.endDate < new Date();
  }

	getEvent(day) {
		try {

			// Strip time so time doesn't interfere with comparisions
			const startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
			const endDate = this.endDate ? new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate()) : null;

			if(this.exceptions && this.exceptions.length) {
				for(const ex of this.exceptions) {
					switch(ex.type) {
						case EVENT_EXCEPTION_TYPE_DELETION:
							if(ex.date.getFullYear() === day.getFullYear() && ex.date.getMonth() === day.getMonth() && ex.date.getDate() === day.getDate()) {
								return null;
							}
							break;
						case EVENT_EXCEPTION_TYPE_MODIFICATION:
							break;
						default:
							break;
					}
				}
			}

			if(day < startDate || (endDate !== null && day > dateDelta(endDate, 1))) {
				return null;
			}

			switch(this.scheduleType) {
				case EVENT_FREQUENCY_DAILY:
					return this.createEvent(day);
				case EVENT_FREQUENCY_WEEKLY:
					return this.daysOfWeek.includes(day.getDay()) ? this.createEvent(day) : null;
				case EVENT_FREQUENCY_MONTHLY_WEEK:
					if(this.daysOfWeek.includes(day.getDay())) {
						const nth = Month.nthDay(day);
						if(this.periodOfNumber === nth) {
							return this.createEvent(day);
						} else if(this.periodOfNumber === -1 && nth === Month.nthDayCount(day)) {
							return this.createEvent(day);
						}
					}
					return null;
				default:
					return null;
			}

		} catch(err) {
			console.error(err);
			return null;
		}
	}

	createEvent(dayArg) {
		
		// Convert to start date
		const day = new Date(dayArg);
		if(this.startTime) {
			day.setHours(this.startTime.getHours(), this.startTime.getMinutes(), this.startTime.getSeconds());
		}

		const eventProps = {
			name: this.name,
			description: this.description,
			capacity: this.capacity,
			cost: this.cost,
			duration: this.duration,
			productLine: this.productLine,
			format: this.format,
			selectedTime: day,
			schedule: this,
		};
		return new StoreEvent(eventProps);
	}
}


export class StoreEventScheduleException {
	
	constructor(props) {

		if(!props) { props = {}; }

		const exceptionDate = props.date || props.exception_date || '';

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.type = props.exception_type || '';
		
		if(exceptionDate) {
			this.date = exceptionDate instanceof Date ? exceptionDate : toDate(exceptionDate);
		} else {
			this.date = null;
		}
	}
}


export class EventFormat {

	constructor(props) {

		// As props, only takes a constant defined in constants/products or key to lookup constant;
		// Pretty useless right now, but will probably build on it later
		if(!props) { props = {}; }

		if(isVarString(props)) {
			const foundConfig = EventFormat.getByKey(props);
			if(foundConfig) {
				props = foundConfig;
			}
		}

		this.key = props.key || null;
		this.name = props.name || '';
	}

	static getByKey(key) {
		for(const plPermalink in PL_EVENT_FORMATS) {
			for(const ft of PL_EVENT_FORMATS[plPermalink]) {
				if(ft.key === key) {
					return new EventFormat(ft);
				}
			}
		}
		return new EventFormat();
	}
}














