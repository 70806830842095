import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  CSS_SLIDE_DURATION 
} from '../../constants/css';
import { 
  TX_ACCOUNT_UPDATE_PASSWORD, 
  TX_CANCEL, 
  TX_CURRENT_PASSWORD,
  TX_NEW_PASSWORD,
  TX_PLACEHOLDER_PASSWORD,
  TX_RETYPE_PASSWORD,
  TX_SAVE,
} from '../../constants/strings';

import { 
  isFormValid, 
  getPasswordInputError, 
  getPasswordSetError, 
  getPasswordMatchError, 
} from '../../utils/form-validation';

import './style/_saveprompt.scss';

let allActionCreators = Object.assign({});

export class SetPasswordModal extends Component {

  // REQUIRED PROPS
  // open: bool
  // closeMethod: function

  // OPTIONAL PROPS
  // copy: ARRAY (overwrites default copy; different than SavePrompt)
  // title: string (overwrites default title)
  // onCancel: function
  // onConfirm: function


  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,
      listenerEnabled: false,

      inputCurrent: '',
      inputPassword1: '',
      inputPassword2: '',

      errorCurrent: '',
      errorPassword1: '',
      errorPassword2: '',
    };

    // Create refs
    this.modalBody = React.createRef();
    this.checkClick = this.checkClick.bind(this);

    // Timeout management
    this.openTimeout = null;
    this.closeTimeout = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    document.removeEventListener('click', this.checkClick, false);

    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.open !== prevProps.open) {
      if(this.props.open === true) {
        this.openPrompt();
      } else {
        this.closePrompt();
      }
    }
  }

  checkClick(evt) {
    if(this.props.open && this.state.listenerEnabled) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.modalBody.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.props.closeMethod();
    }  
  }

  openPrompt() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
      listenerEnabled: false,
    }, () => {

      // Delay to trigger CSS animation
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, 25);

      // Delay listening for clicks
      this.openTimeout = setTimeout(() => {
        this.setState({
          listenerEnabled: true,
        });
      }, CSS_SLIDE_DURATION);

      // Fix body
      document.body.style.overflow = 'hidden';
    });
  }

  closePrompt() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
      listenerEnabled: false,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({
          shouldDisplay: false,
          
          inputCurrent: '',
          inputPassword1: '',
          inputPassword2: '',

          errorCurrent: '',
          errorPassword1: '',
          errorPassword2: '',
        });
      }, CSS_SLIDE_DURATION);
    });
  }

  getScreenClass() {
    return this.state.hasOpacity ? 'spScreen withOpacity' : 'spScreen';
  }

  getModalStyle() {

    const baseConfig = {
      opacity: this.state.hasOpacity ? '1' : '0',
      top: '250px',
    };

    let verticalConfig = {};

    if(this.props.open && this.modalBody && this.modalBody.current && this.modalBody.current.clientHeight > 0) {
      
      const modalHeight = this.modalBody.current.clientHeight;
      verticalConfig = {
        top: (window.innerHeight - modalHeight)/2 + 'px',
      }
    }

    return Object.assign({}, baseConfig, verticalConfig);
  }

  getTitle() {
    return this.props.title ? this.props.title : TX_ACCOUNT_UPDATE_PASSWORD;
  }

  cancelAction(evt) {
    evt.preventDefault();
    if(this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.closeMethod();
  }

  validateAll() {
    const errorObj = {
      errorCurrent: getPasswordInputError(this.state.inputCurrent),
      errorPassword1: getPasswordSetError(this.state.inputPassword1),
      errorPassword2: getPasswordMatchError(this.state.inputPassword1, this.state.inputPassword2),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  confirmAction(evt) {
    evt.preventDefault();
    if(this.validateAll()) {
      if(this.props.onConfirm) {
        this.props.onConfirm(this.state.inputCurrent, this.state.inputPassword1);
      }
      this.props.closeMethod();
    }
  }

  changeCurrent(evt) {
    this.setState({ inputCurrent: evt.target.value });
  }

  changePassword1(evt) {
    this.setState({ inputPassword1: evt.target.value });
  }

  changePassword2(evt) {
    this.setState({ inputPassword2: evt.target.value });
  }

  render() {

    const {t} = this.props;

    return <div 
            className='SetPasswordModal'
            style={{
              display: this.state.shouldDisplay ? 'block' : 'none'
            }}>
      <div className={this.getScreenClass()}></div>
      <div className='spModalWrapper'>
        <div className='spModal' style={this.getModalStyle()} ref={this.modalBody}>
          <div className='spModalInnerWrapper'>
            <div className='spClose' onClick={this.props.closeMethod.bind(this)}>&times;</div>
            <div className='spModalTitle'>{t(this.getTitle())}</div>
            <div className='spModalHr'></div>


            <form onSubmit={this.confirmAction.bind(this)}>
              <div className='spModalInput'>
                <div className='spFieldLabel'>{t(TX_CURRENT_PASSWORD)}</div>
                <input 
                  type='password'
                  value={this.state.inputCurrent}
                  onChange={this.changeCurrent.bind(this)}
                  placeholder={t(TX_PLACEHOLDER_PASSWORD)} 
                  className={this.state.errorKey ? 'InputError' : ''} />
                {this.state.errorKey ?
                  <div className={'spModalError FieldError'}>{t(this.state.errorKey)}</div> :
                  null
                }
              </div>
              <div className='spModalInput'>
                <div className='spFieldLabel'>{t(TX_NEW_PASSWORD)}</div>
                <input 
                  type='password'
                  value={this.state.inputPassword1}
                  onChange={this.changePassword1.bind(this)}
                  placeholder={t(TX_PLACEHOLDER_PASSWORD)} 
                  className={this.state.errorPassword1 ? 'InputError' : ''} />
                {this.state.errorPassword1 ?
                  <div className={'spModalError FieldError'}>{t(this.state.errorPassword1)}</div> :
                  null
                }
              </div>
              <div className='spModalInput'>
                <div className='spFieldLabel'>{t(TX_RETYPE_PASSWORD)}</div>
                <input 
                  type='password'
                  value={this.state.inputPassword2}
                  onChange={this.changePassword2.bind(this)}
                  placeholder={t(TX_PLACEHOLDER_PASSWORD)} 
                  className={this.state.errorPassword2 ? 'InputError' : ''} />
                {this.state.errorPassword2 ?
                  <div className={'spModalError FieldError'}>{t(this.state.errorPassword2)}</div> :
                  null
                }
              </div>

              <div className='spModalActions'>
                <button className={'spAction spActionCancel'} onClick={this.cancelAction.bind(this)}>{t(TX_CANCEL)}</button>
                <button className={'spAction spActionSave'} type='submit'>{t(TX_SAVE)}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(SetPasswordModal));