


import * as pm from '../constants/payment-methods';
import {
	TX_null, 
} from '../constants/strings';


export function getPaymentProcessorDropdownOptions() {
	const optionResp = [];
	for(const pp of pm.PAYMENT_PROCESSORS_ALL) {
		optionResp.push({
			display: getPaymentProcessorName(pp),
			value: getPaymentProcessorKey(pp),
		});
	}
	return optionResp;
}

export function getPaymentProcessorMethodDropdownOptions(processorKey, excludeKeys = []) {
	if(!processorKey) { return []; }
	const optionResp = [];
	for(const mtd of pm.PAYMENT_METHODS_ALL) {
		if(excludeKeys.includes(getPaymentMethodKey(mtd))) {
			continue;
		}
		if(processorKey === getPaymentMethodProcessor(mtd)) {
			optionResp.push({
				display: getPaymentMethodName(mtd),
				value: getPaymentMethodKey(mtd),
			});
		}
	}
	return optionResp;
}

export function getPaymentMethodByKey(methodKey) {
	if(!methodKey) { return null; }
	for(const mtd of pm.PAYMENT_METHODS_ALL) {
		if(methodKey === getPaymentMethodKey(mtd)) {
			return mtd;
		}
	}
	return null;
}

export function getPaymentProcessorKey(processorObj) {
	return processorObj.key;
}

export function getPaymentProcessorName(processorObj) {
	return processorObj.name;
}

export function getPaymentMethodKey(methodConfig) {
	return methodConfig.key;
}

export function getPaymentMethodName(methodConfig) {
	return methodConfig.name;
}

export function getPaymentMethodProcessor(methodConfig) {
	return methodConfig.processor;
}

export function getAllPaymentMethods() {
	return pm.PAYMENT_METHODS_ALL;
}

export function getPaymentMethodConfigLabel(configObj) {
	if(!configObj) { return TX_null; }
	return configObj.label;
}










