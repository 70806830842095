
// The less you add to this file, the happier you will be.  
// Look for workarounds if possible and always describe funcational parameters



// CSS TRANSITION DURATIONS

export const CSS_SLIDE_DURATION = 400;
export const CSS_SLIDE_FAST_DURATION = 400;
export const CSS_TOGGLE_DURATION = 100;


// LAYOUT BREAKS 

export const CSS_HOMEPAGE_MOBILE_BREAK = 600;
export const CSS_CALENDAR_BREAK = 768;
export const CSS_SEARCH_FILTER_TOGGLE = 900;
export const CSS_TABLET_BREAK = 1200;


// HEADER DEFINITIONS

export const CSS_HEADER_HEIGHT = 80;
export const CSS_HEADER_HEIGHT_MOBILE = 40;
export const CSS_HEADER_NOTICE_HEIGHT = 30;
export const CSS_HEADER_NOTICE_HEIGHT_MOBILE = 45;