import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { 
  generalToString, 
  isVarObject, 
} from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';

const allActionCreators = Object.assign({});

export class BulkUploadResultListItem extends Component {

  constructor(props) {
    super(props);

    this.hiddenData = [
      // 'query',
      // 'sql',
    ];

    this.state = {
      expanded: false,
    };
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  toggleExpanded(evt) {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  getPrimaryFieldValue() {
    const name_fields = [
      'Product Name',
    ];
    const dataFields = Object.keys(this.props.result.lineData);

    for(const field of name_fields) {
      if(dataFields.includes(field)) {
        return this.props.result.lineData[field];
      }
    }
    return null;
  }

  render() {

    const {t} = this.props;

    if(!this.props.result) {
      return null;
    }

    return <div className={'BulkUploadResultListItem'}>
      <div className='burliLiner'>
        <div className='headlineWrapper' onClick={this.toggleExpanded.bind(this)}>
          <div className='statusWrapper'>
            <div className='statusLiner'>
              <div className='statusValue'>{t(this.props.result.status.name)}</div>
              <div className='statusLineNumber'>{this.props.result.lineNumber ? t(tx.TX_INV_BULK_UPLOAD_LINE_NUMBER, { number: this.props.result.lineNumber.toLocaleString(this.getLanguage()) }) : ''}</div>
            </div>
          </div>
          <div className='messageWrapper'>
            <div className='messageTitle'>{t(this.props.result.message)}</div>
            <div className='messageValue'>{this.getPrimaryFieldValue()}</div>
          </div>
          <div className='toggleWrapper'>
            {this.state.expanded ?
              <div 
                className='toggleElement FlexCenter'
                dangerouslySetInnerHTML={{ __html: '&mdash;' }} /> :
              <div 
                className='toggleElement FlexCenter'
                dangerouslySetInnerHTML={{ __html: '&plus;' }} />
            }
          </div>
        </div>
        <div className={this.state.expanded ? 'drawerWrapper expanded' : 'drawerWrapper'}>
          <div className='drawerLiner'>
            <div className='rowDataWrapper'>
              <div className='rowDataLiner'>
                <div className='rowDataTitle'>{t(tx.TX_INV_BULK_UPLOAD_CSV_ROW_DATA)}</div>
                {Object.keys(this.props.result.lineData).map((key, i) => {
                  return <div className='rowDataItem' key={`${this.props.result.lineNumber}|${i}`}>
                    <div className='rowDataItemKey'>{key}</div>
                    <div className='rowDataItemValue'>{this.props.result.lineData[key]}</div>
                  </div>
                })}
              </div>
            </div>
            <div className='detailWrapper'>
              <div className='detailLiner'>
                <div className='summaryWrapper'>
                  <div className='summaryLine'>
                    <span className='summaryLabel'>{t(tx.TX_INV_BULK_UPLOAD_RESPONSE_TYPE)}</span>
                    <span className='summaryValue'>{t(this.props.result.status.name)}</span>
                  </div>
                  <div className='summaryLine'>
                    <span className='summaryLabel'>{t(tx.TX_CODE)}</span>
                    <span className='summaryValue'>{this.props.result.errorCode}</span>
                  </div>
                  <div className='summaryLine'>
                    <span className='summaryLabel'>{t(tx.TX_DESCRIPTION)}</span>
                    <span className='summaryValue'>{t(this.props.result.message)}</span>
                  </div>
                  <div className='summaryLine'>
                    <span className='summaryLabel'>{t(tx.TX_INV_BULK_UPLOAD_LINE_NUMBER, { number: '' }).trim()}</span>
                    <span className='summaryValue'>{this.props.result.lineNumber.toLocaleString(this.getLanguage())}</span>
                  </div>
                </div>
                {this.props.debugMode ?
                  <div className='supplementalWrapper'>
                    <div className='supplementalTitle'>{t(tx.TX_INV_BULK_UPLOAD_SUPPLEMENTAL_DATA)}</div>
                    {this.props.result.hasDebugData() === false ?
                      <div className='supplementalNone'>{t(tx.TX_NONE)}</div> :
                      <>
                        {Object.keys(this.props.result.debugData).sort().map((key, i) => {
                          return <div className='supplementalDatum' key={i} style={this.hiddenData.includes(key) ? { display: 'none'} : {}}>
                            <div className='supKey'>{key}</div>
                            {isVarObject(this.props.result.debugData[key]) ?
                              <>
                                {Object.keys(this.props.result.debugData[key]).length > 0 ?
                                  <>
                                    {Object.keys(this.props.result.debugData[key]).sort().map((valueKey, j) => {
                                      return <div className='supValue'>
                                        <div className='supValueKey'>{valueKey}</div>
                                        <div className='supValueValue'>{generalToString(this.props.result.debugData[key][valueKey])}</div>
                                      </div>;
                                    })}
                                  </> :
                                  <div className='supValue'>{t(tx.TX_NONE)}</div>
                                }
                              </> :
                              <div className='supValue'>{this.props.result.debugData[key]}</div>
                            }
                          </div>;
                        })}
                      </>
                    }
                  </div> :
                  null
                }
                <div className='resolutionWrapper'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkUploadResultListItem));