import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { breaksToBrs } from '../../../utils/formatting';
import { addMagicManaSymbols } from '../../../utils/magic';

let allActionCreators = Object.assign({});

export class ProductPageDetailsMagic extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getForeignModel() {
    return this.props.modelOverride || this.props.productObj.foreignModel;
  }

  getProductName() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face1.localizedName : this.getForeignModel().localizedName;
  }

  getFlipProductName() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face2.localizedName : '';
  }

  getProductTypeLine() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face1.localizedTypeLine : this.getForeignModel().localizedTypeLine;
  }

  getFlipProductTypeLine() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face2.localizedTypeLine : '';
  }

  getProductDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face1.localizedText : this.getForeignModel().localizedText;
    return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
  }

  getFlipProductDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face2.localizedText : '';
    return txt ? addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle)) : '';
  }

  getProductSecondaryDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face1.flavorText : this.getForeignModel().flavorText;
    return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
  }

  getFlipProductSecondaryDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face2.flavorText : '';
    return txt ? addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle)) : '';
  }

  getCost() {
    return <div 
            className='ppmTitleCost'
            dangerouslySetInnerHTML={{__html: addMagicManaSymbols(this.getForeignModel().multipleFaces ? this.getForeignModel().face1.manaCost : this.getForeignModel().manaCost, { shadow: true })}} />;
  }

  getFlipCost() {
    if(this.getForeignModel().multipleFaces) {
      return <div 
              className='ppmTitleCost'
              dangerouslySetInnerHTML={{__html: addMagicManaSymbols(this.getForeignModel().face2.manaCost, { shadow: true })}} />;
    }    
    return null;
  }

  render() {

    if(!this.props.productObj || !this.getForeignModel() || !this.getForeignModel().isMagicCard) { return null; }

    return <div className={'ProductPageDetailsMagic ProductPageDetails'}>
      <div className='productPageDetailsWrapper'>
        <div className='ppMagicWrapper' lang={this.getForeignModel().language}>
          <div className='ppMagicFaceWrapper'>
            <div className='ppHeaderWrapper'>
              <div className='ppmTitle' lang={this.getForeignModel().language}>
                <span className='ppmTitleText'>{this.getProductName()}</span>
                {this.getCost()}
              </div>
              <div className='ppmTypeLine'>{this.getProductTypeLine()}</div>
            </div>
            <div className='ppBorderWrapper'>
              <div className='ppMagicDetails'>
                <div 
                  className='ppmDescription'
                  dangerouslySetInnerHTML={{ __html: this.getProductDescription() }} />
                <div 
                  className='ppmSecondaryDescription'
                  dangerouslySetInnerHTML={{ __html: this.getProductSecondaryDescription() }} />
              </div>
            </div>
          </div>
          {this.getForeignModel().multipleFaces ?
            <div className={'ppMagicFaceWrapper face2'}>
              <div className='ppHeaderWrapper'>
                <div className={'ppmTitle face2Title'}>
                  <span className='ppmTitleText'>{this.getFlipProductName()}</span>
                  {this.getFlipCost()}
                </div>
                <div className='ppmTypeLine'>{this.getFlipProductTypeLine()}</div>
              </div>
              <div className='ppBorderWrapper'>
                <div className='ppMagicDetails'>
                  <div 
                    className='ppmDescription'
                    dangerouslySetInnerHTML={{ __html: this.getFlipProductDescription() }} />
                  <div 
                    className='ppmSecondaryDescription'
                    dangerouslySetInnerHTML={{ __html: this.getFlipProductSecondaryDescription() }} />
                </div>
              </div>
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetailsMagic));