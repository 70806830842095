import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_HASHTAG, 
  ICON_INTEGRATION, 
  ICON_PAGES, 
  ICON_PAYMENT_METHOD, 
  ICON_SETTINGS, 
  ICON_SHIPPING, 
  ICON_USER, 
} from '../../constants/icons';
import * as tx from '../../constants/strings';
import {
  URL_ADMIN_SETTINGS_ADMIN_USERS, 
  URL_ADMIN_SETTINGS_INTEGRATIONS, 
  URL_ADMIN_SETTINGS_PAGES, 
  URL_ADMIN_SETTINGS_PAYMENT_METHODS, 
  URL_ADMIN_SETTINGS_SHIPPING_METHODS, 
  URL_ADMIN_SETTINGS_STORE_SOCIAL_MEDIA, 
  URL_ADMIN_SETTINGS_STORE_SETTINGS, 
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import { Icon } from '../Icons/Icon';

import './style/_settings.scss';

let allActionCreators = Object.assign({});

export class Settings extends Component {

  render() {

    const {t} = this.props;

    return <div className={'Settings AdminPage'}>
      <AdminTitle
        title={tx.TX_SETTINGS}
        breadcrumbs={[]} />
      <div className={'settingsIconRow adminIconRow'}>
        <Link to={URL_ADMIN_SETTINGS_ADMIN_USERS} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_USER}
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SETTINGS_ADMIN_USERS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_SETTINGS_PAGES} className={'inventoryIconWrapper adminIconWrapper'} style={{ display: 'none' }}>
          <Icon 
            value={ICON_PAGES}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SETTINGS_PAGE_LAYOUTS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_SETTINGS_INTEGRATIONS} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_INTEGRATION}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SETTINGS_INTEGRATIONS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_SETTINGS_SHIPPING_METHODS} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_SHIPPING}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SHIPPING_METHODS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_SETTINGS_PAYMENT_METHODS} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_PAYMENT_METHOD}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SETTINGS_PAYMENT_METHODS)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_SETTINGS_STORE_SOCIAL_MEDIA} className={'inventoryIconWrapper adminIconWrapper'} style={{ display: 'none' }}>
          <Icon 
            value={ICON_HASHTAG}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SOCIAL_MEDIA)} 
            ellipsisLabel={false} />
        </Link>
        <Link to={URL_ADMIN_SETTINGS_STORE_SETTINGS} className={'inventoryIconWrapper adminIconWrapper'} style={{ display: 'none' }}>
          <Icon 
            value={ICON_SETTINGS}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_SETTINGS_STORE_SETTINGS)} 
            ellipsisLabel={false} />
        </Link>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Settings));