import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../../constants/images';
import * as tx from '../../../../constants/strings';

import { getStoreLanguage } from '../../../../utils/language';
import { 
  getMediaUrl, 
  getOrderedMedia, 
} from '../../../../utils/product';

import LoadingIcon from '../../../Icons/LoadingIcon';
import StaticImage from '../../../Image/StaticImage';

let allActionCreators = Object.assign({});


export class ProductMediaView extends Component {

  getProductImages() {
    if(this.props.productObj && this.props.productObj.media && this.props.productObj.media.length) {
      let mediaArray = [];
      for(const mediaObj of getOrderedMedia(this.props.productObj)) {
        mediaArray.push(<StaticImage imgObj={{
                          src: getMediaUrl(mediaObj.key), 
                          alt: mediaObj.caption ? mediaObj.caption : this.props.t(tx.TX_ALT_PRODUCT_IMAGE), 
                          noTranslate: true,
                        }} />);
      }
      return mediaArray;
    } else {
      return [<StaticImage imgObj={IMG_GENERIC_PRODUCT} />];
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductMediaView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INV_ADD_PRODUCT_STEP_3)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='productMediaViewWrapper'>
              
              <div className='pviWrapper'>
                {this.getProductImages().map((img, i) => {
                  return <div className='pviSingleImgWrapper' key={i}>
                    {img}
                  </div>;
                })}
              </div>
              
            </div>
            <div className='adminViewButtonWrapper'>
              <div className={'productDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.productPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductMediaView));