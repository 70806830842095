

import { 
	STORE_CODE,
	STORE_CODE_OASIS,
	STORE_CODE_OASISCHILE,
	STORE_RETURN_POLICIES, 
} from '../constants/store';

import PrivacyOasis from '../components/Legal/blocks/oasis/PrivacyOasis';
import PrivacyOasisChile from '../components/Legal/blocks/oasis-chile/PrivacyOasisChile';
import TermsOasis from '../components/Legal/blocks/oasis/TermsOasis';
import TermsOasisChile from '../components/Legal/blocks/oasis-chile/TermsOasisChile';


export function legalHasReturnPolicy(storeCode) {
	if(!storeCode) { return false; }

	return Object.keys(STORE_RETURN_POLICIES).includes(storeCode);
}

export function legalGetReturnPolicyComponent(storeCode) {

	if(!legalHasReturnPolicy(storeCode)) { return null; }

	return STORE_RETURN_POLICIES[storeCode] || null;
}

export function legalHasTerms() {

	if(!STORE_CODE) { return false; }

	const supportedStores = [
		STORE_CODE_OASIS,
		STORE_CODE_OASISCHILE,
	];

	return supportedStores.includes(STORE_CODE);
}

export function legalGetTermsComponent() {

	if(!STORE_CODE) { return null; }

	switch(STORE_CODE) {
		case STORE_CODE_OASIS:
			return TermsOasis;
		case STORE_CODE_OASISCHILE:
			return TermsOasisChile;
		default:
			return null;
	}
}

export function legalHasPrivacy() {
	
	if(!STORE_CODE) { return false; }

	const supportedStores = [
		STORE_CODE_OASIS,
		STORE_CODE_OASISCHILE,
	];

	return supportedStores.includes(STORE_CODE);
}

export function legalGetPrivacyComponent() {

	if(!STORE_CODE) { return null; }

	switch(STORE_CODE) {
		case STORE_CODE_OASIS:
			return PrivacyOasis;
		case STORE_CODE_OASISCHILE:
			return PrivacyOasisChile;
		default:
			return null;
	}
}