import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_MESSAGE } from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  breaksToBrs,
  serverDateShort,
  timeShort, 
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import { Icon } from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class OrderMessagesView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getMessages() {
    if(!this.props.order) { return []; }

    return this.props.order.messages || [];
  }

  getMessageBreakStyle() {
    return { margin: '0 0 0.5em 0' };
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderMessagesView adminSection'}>
    	<div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_ORDER_MESSAGES)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='omvLiner'>
              {this.getMessages().length === 0 ?
                <div className='noMessagesWrapper'>
                  <div className='messageIconWrapper'>
                    <Icon 
                      value={ICON_MESSAGE}  
                      iconClass={'messageIcon'} />
                  </div>
                  <div className='noMessagesCopy'>{t(tx.TX_ORDER_NO_MESSAGES)}</div>
                  <button 
                    type='button'
                    className={'noMessagesAction adminButton'}
                    onClick={this.props.openCreateModal.bind(this)}>
                    {t(tx.TX_ORDER_SEND_MESSAGE)}
                  </button>
                </div> :
                <div className='messageListWrapper'>
                  <div className='messageList'>
                    <div className={'slHeader'}>
                      <div className={'slHeaderCell dateCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_DATE)}</div>
                      </div>
                      <div className={'slHeaderCell noteCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_MESSAGE)}</div>
                      </div>
                    </div>
                    {this.getMessages().map((message, i) => {
                      return <div 
                              key={i}
                              className={`slItem`}>
                        <div className={'slItemCell dateCell'}>
                          <div className='slNonAbsValue'>
                            <div className='dateDate'>{serverDateShort(message.date, this.getLanguage())}</div>
                            <div className='dateTime'>{timeShort(message.date, this.getLanguage())}</div>
                          </div>
                        </div>
                        <div className={'slItemCell noteCell'}>
                          <div className='slNonAbsValue'>
                            <div 
                              className='noteBlock'
                              dangerouslySetInnerHTML={{ __html: breaksToBrs(message.text, this.getMessageBreakStyle()) }} />
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                </div>
              }
            </div>
            <div className='adminViewButtonWrapper'>
              <button 
                type='button'
                className={'productDetailsButton adminButton'} 
                onClick={() => this.props.openCreateModal(null)}>
                {t(tx.TX_ORDER_SEND_MESSAGE)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.orderPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderMessagesView));
