

import { 
	GEO_ADDRESS_SCHEMA_DEFAULT, 
	GEO_CLASS_ADDRESS_LINE,
	GEO_COUNTRIES_ALL, 
	GEO_COUNTRY_CL_KEY, 
} from '../constants/geographies';
import { TX_null } from '../constants/strings';

import { 
  getCountryError, 
  getNameError, 
  getPhoneError,
  isFormValid, 
} from '../utils/form-validation';
import { isVarString } from '../utils/general';

export class Address {

	constructor(props) {
		
		if(!props) { props = {}; }

		const country = new Country(props.countryObj || props.country || {});

		this.publicUuid = props.publicUuid || props.public_uuid || null;
		this.placeId = props.placeId || props.place_id || null;

		this.firstName = props.firstName || props.first_name || '';
		this.lastName = props.lastName || props.last_name ||  '';
		this.street1 = props.street1 || props.address_line1 || '';
		this.street2 = props.street2 || props.address_line2 || '';
		this.administrativeAreaLevel3 = props.administrativeAreaLevel3 || props.administrative_area_level_3 || props.address_line3 || '';
		this.city = props.city || '';
		this.regionValue = props.regionValue || props.region || props.state || props.state_province || '';
		this.postCode = props.postCode || props.post_code || props.postal_code || '';
		this.countryObj = country;
		this.phone = props.phone || props.phone_number || '';
		this.taxId = props.taxId || props.tax_id || props.tax_code || '';

		this.isDefault = props.isDefault || props.is_default || false;
		this.isSaved = props.isSaved || props.is_saved || false;
	}
	
	get streetArray() {
		return this.street2 ? [ this.street1, this.street2 ] : [ this.street1 ];
	}

	get region() {
		return this.regionValue;
	}

	set region(region) {
		this.regionValue = region;
	}

	get country() {
		return this.countryObj;
	}

	set country(country) {
		const countryObj = new Country(country);
		this.countryObj = countryObj.key !== '' ? countryObj : new Country();
	}

	isValid() {
		const errorObj = {
			errorFirstName: getNameError(this.firstName),
			errorLastName: getNameError(this.lastName),
			errorPhone: getPhoneError(this.phone),
	    errorStreet1: this.country.getAddressSchemaElement('street1').validationMethod(this.street1, !this.country.getAddressSchemaElement('street1').required),
	    errorStreet2: this.country.getAddressSchemaElement('street2').validationMethod(this.street2, !this.country.getAddressSchemaElement('street2').required),
	    errorLevel3: this.country.getAddressSchemaElement('administrativeAreaLevel3').validationMethod(this.administrativeAreaLevel3, !this.country.getAddressSchemaElement('administrativeAreaLevel3').required),
	    errorCity: this.country.getAddressSchemaElement('city').validationMethod(this.city, !this.country.getAddressSchemaElement('city').required),
	    errorRegion: this.country.getAddressSchemaElement('region').validationMethod(this.regionValue, !this.country.getAddressSchemaElement('region').required),
	    errorPostalCode: this.country.getAddressSchemaElement('postCode').validationMethod(this.postCode, !this.country.getAddressSchemaElement('postCode').required),
	    errorCountry: getCountryError(this.countryObj ? this.countryObj.key : ''),
	    errorTaxId: this.country.getAddressSchemaElement('taxId').validationMethod(this.taxId, !this.country.getAddressSchemaElement('taxId').required),
		}
		return isFormValid(errorObj);
	}

	format(config = {}) {
		
		const addTags = config.addTags ? config.addTags : false;
		const lineClass = config.lineClass ? `${config.lineClass} ${GEO_CLASS_ADDRESS_LINE}` : GEO_CLASS_ADDRESS_LINE;

		const addressLines = this.formatDefault();

		if(addTags) {
			let addressResp = '';
			for(const line of addressLines) {
				addressResp += `<div class='${lineClass}'>${line}</div>`;
			}
			return addressResp;
		} else {
			return addressLines;
		}
	}

	formatDefault() {

		const addressLines = [];

		// Name line
		addressLines.push(`${this.firstName} ${this.lastName}`);

		// Street 1
		addressLines.push(`${this.street1}`);

		// Street 2
		if(this.street2) {
			addressLines.push(`${this.street2}`);
		}

		// City/State(ish) line
		if(this.country.key === GEO_COUNTRY_CL_KEY) {
			addressLines.push(this.administrativeAreaLevel3 ? `${this.administrativeAreaLevel3}, ${this.city} ${this.postCode}` : `${this.city} ${this.postCode}`);
		} else {
			addressLines.push(`${this.city}, ${this.regionValue} ${this.postCode}`);
		}
		if(this.taxId) {
			addressLines.push(`${this.taxId}`);
		}
		return addressLines;
	}

	formatSingleLine() {
		return this.formatSingleLineDefault();
	}

	formatSingleLineDefault() {
		if(this.street2) {
			return `${this.firstName} ${this.lastName}; ${this.street1}; ${this.street2}; ${this.city}, ${this.regionValue} ${this.postCode}`;
		} else {
			return `${this.firstName} ${this.lastName}; ${this.street1}; ${this.city}, ${this.regionValue} ${this.postCode}`;
		}
	}

	getApiData() {
		const apiData = {
			first_name: this.firstName,
			last_name: this.lastName,
			street1: this.street1,
			street2: this.street2,
			city: this.city,
			state: this.regionValue,
			post_code: this.postCode || null,
			country: this.countryObj.key,
			phone_number: this.phone,
			place_id: this.placeId ? this.placeId : '',
			is_default: this.is_default,
			is_saved: this.isSaved,
			tax_code: this.taxId || null,
		};
		if(this.publicUuid) {
			apiData['public_uuid'] = this.publicUuid;
		}
		if(this.administrativeAreaLevel3) {
			apiData['administrative_area_level_3'] = this.administrativeAreaLevel3;
		}
		return apiData;
	}
}

export class Country {

	constructor(props) {
		
		// props should be either key (string) or country const (object)
		if(isVarString(props)) {
			props = Country.getByKey(props);
		}

		if(!props) { 
			props = {}; 
		}

		this.key = props.key || '';
		this.iso2 = props.iso2 || '';
		this.name = props.name || TX_null;

		this.errorMapping = props.errorMapping || {};
		this.stringMapping = props.stringMapping || {};

		this.addressSchema = props.addressSchema || props.address_schema || {}
	}

	static getByKey(key) {
		if(!key) { return null; }
		for(const countryConfig of GEO_COUNTRIES_ALL) {
			const country = new Country(countryConfig);
			if(country.key === key) {
				return country;
			}
		}
		return null;
	}

	getAddressSchemaElement(field) {
		return Object.assign({}, GEO_ADDRESS_SCHEMA_DEFAULT[field] || {}, this.addressSchema[field] || {});
	}

	mapString(lookupKey) {
		return this.stringMapping[lookupKey] ? this.stringMapping[lookupKey] : lookupKey;
	}

	mapError(lookupKey) {
		return this.errorMapping[lookupKey] ? this.errorMapping[lookupKey] : lookupKey;
	}
}