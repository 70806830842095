

import { FINISHES_ALL } from '../constants/finishes';

import { 
	dbStringToArray, 
} from './formatting';
import { 
	isVarString, 
} from './general';



export function getManaFontClass(code, params = {}) {
	if(!code) {
		return '';
	}

	// Params
	// shadow: true (defaults to false)
	// cost: false (defaults to true)

	const shadowClass = params.shadow ? ' ms-shadow' : '';
	const costClass = params.cost !== false ? ' ms-cost' : '';

	const halfCodes = ['hw', 'hu', 'hb', 'hr', 'hg'];
	const halfClass = halfCodes.includes(code) ? ' ms-half' : '';

	const mappedCode = mapScryfallToMana(code);

	return `MagicSymbol ms ms-${mappedCode}${costClass}${shadowClass}${halfClass}`;
}

export function addMagicManaSymbols(unformattedString, params = {}) {

	try {
		const regex = /\{.*?\}/g;
		const matches = [...new Set(unformattedString.match(regex))];

		let formatResp = unformattedString;
		for(const sym of matches) {
			const manaClass = getManaFontClass(sym.replace(/[{}]/g, '').toLowerCase(), params);
			formatResp = formatResp.replaceAll(sym, `<i class='${manaClass}'></i>`);
		}
		return formatResp;
	} catch(err) {
		console.error(err);
		return '';
	}
}

export function mapScryfallToMana(scryfallKey) {

	// There's gotta be a better way to do this
	// But we can't use / or ∞ as object keys, so here we are
	switch(scryfallKey) {
		case 't':
			return 'tap';
		case '∞':
			return 'infinity';
		case 'w/u':
			return 'wu';
		case 'w/b':
			return 'wb';
		case 'b/r':
			return 'br';
		case 'b/g':
			return 'bg';
		case 'u/b':
			return 'ub';
		case 'u/r':
			return 'ur';
		case 'r/g':
			return 'rg';
		case 'r/w':
			return 'rw';
		case 'g/w':
			return 'gw';
		case 'g/u':
			return 'gu';
		case '2/w':
			return '2w';
		case '2/u':
			return '2u';
		case '2/b':
			return '2b';
		case '2/r':
			return '2r';
		case '2/g':
			return '2g';
		case 'w/p':
			return 'wp';
		case 'u/p':
			return 'up';
		case 'b/p':
			return 'bp';
		case 'r/p':
			return 'rp';
		case 'g/p':
			return 'gp';
		case 'hw':
			return 'w';
		case 'hu':
			return 'u';
		case 'hb':
			return 'b';
		case 'hr':
			return 'r';
		case 'hg':
			return 'g';
		default:
			return scryfallKey;
	}
}

export function getMagicFinishes(magicObj) {
	
	if(!magicObj.finishes) {
		return [];
	}

	const finishArray = [];
	const finishKeys = isVarString(magicObj.finishes) ? dbStringToArray(magicObj.finishes) : magicObj.finishes;

	for(const fKey of finishKeys) {
		for(const fObj of FINISHES_ALL) {
			if(fKey === fObj.key) {
				finishArray.push(fObj);
				break;
			}
		}
	}
	return finishArray;
}







































