import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_CUSTOM_NAME,
  API_KEY_IS_ENABLED,
} from '../../../constants/api';
import * as tx from '../../../constants/strings';

import { 
  getNameError, 
  isFormValid, 
} from '../../../utils/form-validation';
import {
  formatServerError
} from '../../../utils/formatting';

import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';
import Toggle from '../../Input/Toggle';

import * as checkoutActionCreators from '../../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class PaymentMethodDetailsEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      inputStatus: this.props.paymentMethod ? this.props.paymentMethod.isEnabled : false,
      inputName: this.props.paymentMethod ? this.props.paymentMethod.nameCustom : '',

      errorName: '',
      errorConfig: '', 

      requestPending: false,
      requestError: null,

      resetConfirmOpen: false,
      savePromptOpen: false, 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  changeStatus(evt) {
    this.setState({
      inputStatus: !this.state.inputStatus, 
    }, () => {
      if(this.state.inputStatus === true) {
        const configError = this.props.paymentMethod.getConfigError();
        if(configError) {
          this.setState({
            inputStatus: false,
            errorConfig: configError,
          });
        } else {
          this.makeDirty();
        }
      } else {
        this.makeDirty();
      }
    });
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
      isDirty: true,
    }, () => {
      this.makeDirty();
      if(this.state.errorName) {
        this.validateName();
      }
    });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName, true) });
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll(true)) {

      const editData = {
        [API_KEY_IS_ENABLED]: this.state.inputStatus,
      };

      if(this.props.paymentMethod && this.props.paymentMethod.schema && this.props.paymentMethod.schema.allowCustomName) {
        editData[API_KEY_CUSTOM_NAME] = this.state.inputName || '';
      }

      this.setState({
        requestPending: true,
        requestError: null,
      });

      this.props.checkoutUpdatePaymentMethod(editData, this.props.paymentMethod.publicUuid)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
        }, () => {
          if(this.props.remoteSave) {
            this.props.finishRemote('details');
          } else {
            this.props.makeClean('details');
          }
          this.props.toggleEdit();
        });
      })
      .catch((errResp) => {
        
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });

        if(this.props.remoteSave) {
          this.props.finishRemote('details', true);
        }
      });
    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('details', true);
    }
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorName: getNameError(this.state.inputName, true),
      errorConfig: this.props.paymentMethod.getConfigError(),
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  getGeneralError() {
    if(this.state.requestError) {
      return this.state.requestError;
    }
    if(this.state.errorConfig) {
      return this.state.errorConfig;
    }
    return '';
  }

  render() {

    const {t} = this.props;

    return <div className={'PaymentMethodDetailsEdit'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'paymentMethodDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_CHECKOUT_PAYMENT_METHOD)}</div>
            </div>
            <div className={this.getGeneralError() ? 'adminFormError present' : 'adminFormError'}>{this.getGeneralError() ? t(this.getGeneralError()) : ''}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputStatus}
                    onToggle={this.changeStatus.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>

            {this.props.paymentMethod && this.props.paymentMethod.schema && this.props.paymentMethod.schema.allowCustomName ? 
              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_PAYMENT_METHODS_CUSTOM_NAME)}</div>
                <div className='adminInputWrapper'>
                  <input
                    type='text'
                    className={this.state.errorName ? 'InputError' : ''}
                    value={this.state.inputName}
                    onChange={this.changeName.bind(this)}
                    onBlur={this.validateName.bind(this)}
                    placeholder={t(tx.TX_SETTINGS_PAYMENT_METHOD_PAY_AT_PICKUP)}
                    maxLength={100} />
                  {this.state.errorName ?
                    <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                    null
                  }
                </div>
              </div> :
              null
            }
            
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.validateAll() === false || this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PaymentMethodDetailsEdit));