

import {
	GS_KEY,
	GS_NAME,
	GEO_COUNTRY_CL_KEY,
	GEO_COUNTRIES_ALL,
} from '../constants/geographies';

import {
	Country, 
} from '../models/geographies';


export function getShippingCountries() {
	
	const countriesResp = [];
	const countryKeys = process.env.REACT_APP_SHIPPING_COUNTRIES;

	if(!countryKeys) {
		return countriesResp;
	}
	for(const key of countryKeys.split(',')) {
		for(const countryConfig of GEO_COUNTRIES_ALL) {
			const country = new Country(countryConfig);
			if(country.key === key) {
				countriesResp.push(country);
				break;
			}
		}
	}
	return countriesResp;
}

export function getRegionKey(regionObj) {
	if(!regionObj) { return ''; }
	return regionObj[GS_KEY];
}

export function getRegionName(regionObj) {
	if(!regionObj) { return ''; }
	return regionObj[GS_NAME];
}

export function googleComponentObj(componentArray, placeId = null) {
	// Converts address component array received from Google to object for easier access and DB storage;
	// Use long_name by default, short accessable through __short suffix
	// Use first type as key

	// // Example
	// long_name: "Utah"
	// short_name: "UT"
	// types: Array(2)
	// 	0: "administrative_area_level_1"
	// 	1: "political"

	const componentObj = { data_type: 'google' };

	try {

		if(placeId) {
			componentObj['place_id'] = placeId;
		}

		for(const ln of componentArray) {
			componentObj[`${ln.types[0]}`] = ln.long_name;
			componentObj[`${ln.types[0]}__short`] = ln.short_name;
		}
	} catch(err) {
		console.error(err);
	}
	return componentObj;
}

export function googleElementStreet1(componentObj) {
	const countryCode = googleElementCountryShort(componentObj);
	if(countryCode === GEO_COUNTRY_CL_KEY) {
		return componentObj.street_number ? `${componentObj.route || ''} ${componentObj.street_number}` : `${componentObj.route || ''}`;
	}
	return componentObj.street_number ? `${componentObj.street_number} ${componentObj.route || ''}` : `${componentObj.route || ''}`;
}

export function googleElementLevel3(componentObj) {
	return componentObj.administrative_area_level_3 || '';
}

export function googleElementCity(componentObj) {
	const countryCode = googleElementCountryShort(componentObj);
	if(countryCode === GEO_COUNTRY_CL_KEY) {
		return componentObj.administrative_area_level_2 || '';
	}
	return componentObj.locality || '';
}

export function googleElementRegion(componentObj) {
	return componentObj.administrative_area_level_1 || '';
}

export function googleElementRegionShort(componentObj) {
	return componentObj.administrative_area_level_1__short || '';
}

export function googleElementPostCode(componentObj) {
	return componentObj.postal_code_suffix ? `${componentObj.postal_code || ''}-${componentObj.postal_code_suffix}` : `${componentObj.postal_code || ''}`;
}

export function googleElementCountry(componentObj) {
	return componentObj.country || '';
}

export function googleElementCountryShort(componentObj) {
	return componentObj.country__short || '';
}

































