import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { Pickup } from '../../../../models/shipments';

import { 
  getDescriptionError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { 
  formatPrice,
  formatServerError,
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import Toggle from '../../../Input/Toggle';

import '../../style/_vieworder.scss';

import * as orderActionCreators from '../../../../actions/order';
import * as paymentsActionCreators from '../../../../actions/payments';
const allActionCreators = Object.assign({}, orderActionCreators, paymentsActionCreators);

export class OrderModalPaymentCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputIncludePickup: false, 
      inputStoreCredit: false,
      inputPayNote: '',
      inputNote: '',
      
      errorPayNote: '',
      errorNote: '',

      requestPending: false,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_CREATE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  toggleStoreCredit(evt) {
    this.setState({ inputStoreCredit: !this.state.inputStoreCredit });
  }

  changePayNote(evt) {
    this.setState({
      inputPayNote: evt.target.value,
    }, () => {
      if(this.state.errorPayNote) {
        this.validateNote();
      }
    });
  }

  validatePayNote() {
    this.setState({ errorPayNote: getDescriptionError(this.state.inputPayNote, true) });
  }

  changeIncludePickup(evt) {
    this.setState({
      inputIncludePickup: !this.state.inputIncludePickup,
    });
  }

  changeNote(evt) {
    this.setState({
      inputNote: evt.target.value,
    }, () => {
      if(this.state.errorNote) {
        this.validateNote();
      }
    });
  }

  validateNote() {
    this.setState({ errorNote: getDescriptionError(this.state.inputNote, true) });
  }

  validateAll() {
    const errorObj = {
      errorNote: getDescriptionError(this.state.inputNote, true),
      errorPayNote: getDescriptionError(this.state.inputPayNote, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const paymentData = {
        order_uuid: this.props.order.publicUuid,
        note: this.state.inputPayNote,
        use_store_credit: this.state.inputStoreCredit,
      }

      const createResp = await this.props.paymentsAddExternal(paymentData, this.props.order.paymentMethod ? this.props.order.paymentMethod.publicUuid : null, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!createResp) { return null; }


      if(this.state.inputIncludePickup) {

        const closedPickup = new Pickup(this.getSinglePickup());
        closedPickup.completeNote = this.state.inputNote;

        const closeResp = await this.props.ordersClosePickup(closedPickup.getApiDataComplete(), this.props.order.publicUuid)
          .catch((errResp) => {
            console.error(errResp);
            this.setState({
              requestPending: false,
              requestError: formatServerError(errResp),
            });
            this.props.completeAction();
          });

        if(!closeResp) { return null; }
      }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  getSinglePickup() {
    if(!this.props.order) { return null; }

    for(const pu of this.props.order.pickups) {
      if(!pu.isComplete) {
        return pu;
      }
    }
    return null;
  }

  getPaymentAmount() {

    const storeCredit = this.getStoreCreditAmount();
    if(storeCredit) {
      return this.props.order.totalPayable - storeCredit > 0 ? this.props.order.totalPayable - storeCredit : 0;
    }
    return this.props.order.totalPayable;
  }

  getStoreCreditAmount() {

    if(!this.state.inputStoreCredit) {
      return 0;
    }

    if(this.props.order && !this.props.order.user.isGuest() && this.props.order.user.storeCredit && this.props.order.user.storeCredit.balance) {
      return this.props.order.user.storeCredit.balance > this.props.order.totalPayable ? this.props.order.totalPayable : this.props.order.user.storeCredit.balance;
    }

    return 0;
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalPaymentCreate OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'paymentCreateForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          {this.props.order ?
            <>
              <div className='paymentHeadlineWrapper'>
                <div className='headlineLabel'>{t(tx.TX_ORDER_PAYMENT_RECEIVED)}</div>
                <div 
                  className='headlineValue'
                  dangerouslySetInnerHTML={{ __html: formatPrice(this.getPaymentAmount(), { addTags: true, zeroValue: t(tx.TX_NONE) }) }} />
              </div>
              {this.state.inputStoreCredit ?
                <div className='paymentSubheadlineWrapper'>
                  <div className='headlineLabel'>{t(tx.TX_ORDER_STORE_CREDIT_APPLIED)}</div>
                  <div 
                    className='headlineValue'
                    dangerouslySetInnerHTML={{ __html: formatPrice(this.getStoreCreditAmount(), { addTags: true }) }} />
                </div> :
                null
              }
            </> :
            null
          }

          {this.props.order && !this.props.order.user.isGuest() && this.props.order.user.storeCredit && this.props.order.user.storeCredit.balance ?
            <>
              <div className='omFieldWrapper'>
                <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_STORE_CREDIT_APPLY_QUESTION)}</div>
                
                <div className='omInputWrapper'>
                  <div className='omInputToggleWrapper'>
                    <Toggle
                      checked={this.state.inputStoreCredit}
                      onToggle={this.toggleStoreCredit.bind(this)}
                      trueValue={tx.TX_YES}
                      falseValue={tx.TX_NO} />
                  </div>
                </div>

                <div className='omBalanceWrapper'>
                  <div 
                    className='omBalanceCopy' 
                    dangerouslySetInnerHTML={{ 
                      __html: t(tx.TX_ORDER_STORE_CREDIT_CUSTOMER_BALANCE, { 
                        balance: `<span class='balanceEm'>${formatPrice(this.props.order.user.storeCredit.balance, { language: this.getLanguage() })}</span>` 
                      })
                    }} />
                </div>

              </div>
            </> :
            null
          }

          <div className='omFieldWrapper'>
            <div className={'omFieldLabel omOptional'}>{t(tx.TX_ORDER_ADD_PAYMENT_NOTE)}</div>
            <div className='omInputWrapper'>
              <textarea
                type='text'
                className={this.state.errorPayNote ? 'InputError' : ''}
                value={this.state.inputPayNote}
                onChange={this.changePayNote.bind(this)}
                onBlur={this.validatePayNote.bind(this)}
                placeholder={t(tx.TX_OPTIONAL)}
                maxLength={190}></textarea>
            </div>
            {this.state.errorPayNote ?
              <div className={'FieldError'}>{t(this.state.errorPayNote)}</div> :
              null
            }
          </div>

          {this.props.order && this.props.order.readyForPickup ?
            <>
              <div className='omFieldWrapper'>
                <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_CUSTOMER_PICKING_UP)}</div>
                <div className='omInputWrapper'>
                  <div className='omInputToggleWrapper'>
                    <Toggle
                      checked={this.state.inputIncludePickup}
                      onToggle={this.changeIncludePickup.bind(this)}
                      trueValue={tx.TX_YES}
                      falseValue={tx.TX_NO} />
                  </div>
                </div>
              </div>
              {this.state.inputIncludePickup ?
                <div className='omFieldWrapper'>
                  <div className={'omFieldLabel omOptional'}>{t(tx.TX_ORDER_PICKUPS_ADD_CUSTOMER_NOTE)}</div>
                  <div className='omInputWrapper'>
                    <textarea
                      type='text'
                      className={this.state.errorNote ? 'InputError' : ''}
                      value={this.state.inputNote}
                      onChange={this.changeNote.bind(this)}
                      onBlur={this.validateNote.bind(this)}
                      placeholder={t(tx.TX_OPTIONAL)}
                      maxLength={190}></textarea>
                  </div>
                  {this.state.errorNote ?
                    <div className={'FieldError'}>{t(this.state.errorNote)}</div> :
                    null
                  }
                </div> :
                null
              }
            </> :
            null
          }

        </form>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalPaymentCreate));