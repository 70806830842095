import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  TX_OFF, 
  TX_ON, 
} from '../../constants/strings';

import './style/_toggle.scss';

let allActionCreators = Object.assign({});

// Don't pass width and height if you're using a label... you need to adapt .toggleDisplay to work with percentages

export class Toggle extends Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  getTrueValue() {
    return this.props.trueValue ? this.props.t(this.props.trueValue) : this.props.t(TX_ON);
  }

  getFalseValue() {
    return this.props.falseValue ? this.props.t(this.props.falseValue) : this.props.t(TX_OFF);
  }

  changeMethod(evt) {
    if(this.props.onToggle) {
      this.props.onToggle(evt);
    }
  }

  getStyleObj() {
    const styleObj = {};
    if(this.props.width) {
      styleObj['width'] = `${this.props.width}px`;
    }
    if(this.props.height) {
      styleObj['height'] = `${this.props.height}px`; 
    }
    return styleObj;
  }

  render() {
    return <div className={`Toggle ${this.props.absolute ? 'absolute' : ''}`} style={this.getStyleObj()}>
      <div className={this.props.disabled ? 'toggleWrapper disabled' : 'toggleWrapper'}>
        <label className='toggleLabel'>
          <input 
            type='checkbox'
            checked={this.props.checked}
            disabled={this.props.disabled}
            onChange={this.changeMethod.bind(this)}
            className='toggleCheckbox' />
          <div className='toggleSlider'></div>
          {!this.props.hideLabels ?
            <div className={this.props.bottomLabel ? 'toggleDisplay bottomLabel' : 'toggleDisplay'}>{this.props.checked ? this.getTrueValue() : this.getFalseValue()}</div> :
            null
          }
        </label>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Toggle));

