import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { formatServerError } from '../../utils/formatting';

import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_ORDERS,
  URL_ADMIN_ORDERS_BUYLIST_CARTS,
  URL_ADMIN_ORDERS_CARTS,
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import CartControlModal from './blocks/cart-modal/CartControlModal';
import CartModalCreateOrder  from './blocks/cart-modal/CartModalCreateOrder';
import CartDetailsView from './blocks/CartDetailsView';
import CartItemsView from './blocks/CartItemsView';
import CartModalChangeCustomer from './blocks/cart-modal/CartModalChangeCustomer';

import './style/_viewcart.scss';

import * as cartActionCreators from '../../actions/cart';
import * as commonActionCreators from '../../actions/common';
const allActionCreators = Object.assign({}, cartActionCreators, commonActionCreators);

export class ViewCart extends Component {

  constructor(props) {
    super(props);

    this.isBuylist = !!this.props.isBuylist;

    this.state = {

      itemsExpanded: true,

      editModalComponent: null,
      editModalOpen: false,
      editModalProps: null,
      editModalTitle: tx.TX_null,

      requestPending: true,
      requestError: null,
      responseObject: null,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.fetchCart();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  async fetchCart(refreshUserCart = false) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      responseObject: null,
    });

    // Get cart details
    if(this.props.match.params.cartUUID) {
      
      let cartResp = null;

      if(this.isBuylist) {

        cartResp = await this.props.cartBuylistAdminFetchSingle(this.props.match.params.cartUUID, controller.signal)
          .catch((errResp) => {
            
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              requestPending: false,
              requestError: formatServerError(errResp),
              responseObject: null,
            });
          });
      } else {

        cartResp = await this.props.cartAdminFetchSingle(this.props.match.params.cartUUID, controller.signal)
          .catch((errResp) => {
            
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              requestPending: false,
              requestError: formatServerError(errResp),
              responseObject: null,
            });
          });
      }

      if(refreshUserCart) {
        this.props.cartFetchCart()
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }
        });
      }

      this.setState({
        requestPending: false,
        requestError: null,
        responseObject: cartResp,
      }, () => {
        if(cartResp && cartResp.hasMerged) {
          const cartListUrl = this.isBuylist ? URL_ADMIN_ORDERS_BUYLIST_CARTS : URL_ADMIN_ORDERS_CARTS;
          history.replace(cartListUrl);
        }
      });
    }
  }

  toggleItemsExpanded() {
    this.setState({ itemsExpanded: !this.state.itemsExpanded });
  }

  getModalConfirmLabel() {
    if(this.state.editModalComponent && this.state.editModalComponent.WrappedComponent && this.state.editModalComponent.WrappedComponent.confirmLabel) {
      return this.state.editModalComponent.WrappedComponent.confirmLabel();
    }
    return tx.TX_SAVE;
  }

  clearModalData() {
    this.setState({
      editModalComponent: null, 
      editModalTitle: tx.TX_null,
      editModalProps: null,
    });
  }

  closeCartControlModal() {
    this.setState({
      editModalOpen: false, 
    });
  }

  openChangeCustomerModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: CartModalChangeCustomer,
      editModalTitle: tx.TX_CART_CHANGE_CUSTOMER_TITLE,
    });
  }

  openCreateOrderModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: CartModalCreateOrder,
      editModalTitle: tx.TX_ORDER_CREATE_ORDER,
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewCart AdminPage'}>
      <AdminTitle
        title={this.isBuylist ? tx.TX_BUYLIST_CART : tx.TX_CART}
        breadcrumbs={[
          {
            url: URL_ADMIN_ORDERS,
            title: tx.TX_ORDERS,
          },
          {
            url: this.isBuylist ? URL_ADMIN_ORDERS_BUYLIST_CARTS : URL_ADMIN_ORDERS_CARTS,
            title: this.isBuylist ? tx.TX_BUYLIST_CARTS : tx.TX_ORDER_CARTS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>
          
          <CartDetailsView
            cart={this.state.responseObject}
            cartPending={this.state.requestPending}
            isBuylist={this.isBuylist}
            openChangeCustomerModal={this.openChangeCustomerModal.bind(this)}
            openCreateOrderModal={this.openCreateOrderModal.bind(this)} />

          {this.state.responseObject ?
            <CartItemsView
              cart={this.state.responseObject}
              cartPending={this.state.requestPending}
              expanded={this.state.itemsExpanded}
              isBuylist={this.isBuylist}
              toggleExpanded={this.toggleItemsExpanded.bind(this)} /> :
            null
          }

        </div>
      </div>
      <div className='adminActions'>
        <Link to={this.isBuylist ? URL_ADMIN_ORDERS_BUYLIST_CARTS : URL_ADMIN_ORDERS_CARTS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <CartControlModal
        open={this.state.editModalOpen}
        modalComponent={this.state.editModalComponent}
        modalTitle={this.state.editModalTitle}
        modalSuccessLabel={this.getModalConfirmLabel()}
        closeMethod={this.closeCartControlModal.bind(this)}
        clearModalData={this.clearModalData.bind(this)}
        cart={this.state.responseObject}
        cartPending={this.state.requestPending}
        makeClean={this.fetchCart.bind(this)}
        additionalProps={this.state.editModalProps} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewCart));