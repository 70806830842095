import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { formatPrice } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

let allActionCreators = Object.assign({});

export class SimpleCurrencyElement extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {  
    return <div className={'SimpleCurrencyElement'} {...this.props.customAttr}>
      <div className='simpleCurrencyElementWrapper' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.dataValue, { addTags: true, language: this.getLanguage() }) }} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(SimpleCurrencyElement));


