import 'whatwg-fetch';

import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

import { PaymentRecord } from '../models/payment-methods';

import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/






/******************************
*******  Asynchronous  ********
******************************/



export function paymentsSquareAuthorize(uuid, code, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const getParams = { code: code };

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PAYMENT_SQUARE_AUTHORIZE, { uuid: uuid }) + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function paymentsTransbankConfirm(confirmData, uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PAYMENT_TRANSBANK_CONFIRM_PAYMENT, { uuid: uuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(confirmData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new PaymentRecord(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function paymentsAddExternal(paymentData, methodUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PAYMENT_ADD_EXTERNAL, { uuid: methodUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(paymentData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function paymentsAddBuylistPayment(paymentData, methodUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PAYMENT_ADD_BUYLIST, { paymentMethodUuid: methodUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(paymentData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function paymentsRefundPayment(refundData, paymentRecordUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PAYMENT_REFUND_PAYMENT, { publicUuid: paymentRecordUuid });
    return fetch(url, {
      method: 'post',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(refundData),
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}











