

import { 
	LANG_EN, 
} from '../constants/languages';
import {
	PROD_PRICE_CLASS_SYMBOL, 
	PROD_INV_CONFIG_GENERAL, 
	PROD_INV_CONFIG_LORCANA, 
	PROD_INV_CONFIG_MAGIC, 
	PROD_INV_CONFIG_POKEMON, 
	PROD_INV_CONFIG_STARWARS, 
	PROD_KEY_CONDITION, 
	PROD_KEY_FINISH, 
	PROD_KEY_LANGUAGE, 
	PROD_KEY_PRINTING, 
	PROD_TYPE_GENERAL, 
	PROD_TYPE_LORCANA,
	PROD_TYPE_MAGIC, 
	PROD_TYPE_POKEMON, 
	PROD_TYPE_STARWARS, 
} from '../constants/product';
import { 
	STORAGE_ENABLED_PRODUCT_LINES, 
	STORAGE_MENU, 
} from '../constants/storage';


import { getCurrencySymbol } from './currency';
import { isFinishDefault } from './finish';
import { formatPrice } from './formatting';
import { isVarNumber } from './general';
import { dynamicSort } from './sort';

import * as storage from '../persistance/storage';



export function getPricesCount(invArray) {
	
	if(!invArray || invArray.length === 0) {
		return 0;
	}

	let valArray = [];
	for(const inv of invArray) {
		if(valArray.includes(parseFloat(inv.sellPrice)) === false) {
			valArray.push(parseFloat(inv.sellPrice));
		}
	}

	return valArray.length;
}

export function getPriceRangeFromInventory(invArray, config = {}) {
	
	// Config schema
	// addTags: boolean; default false- not used here, just 
	// available: only include available inventory
	// buylist: only look at buylist inventory; filter only buy price
	// language: LANG_ constant; default LANG_EN
	// omitSymbol: boolean; default false

	if(!invArray) {
		return '';
	}

	const addTags = config.addTags ? true : false;
	const available = config.available ? true : false;
	const buylist = config.buylist ? true : false;
	const language = config.language ? config.language : LANG_EN;
	const omitSymbol = config.omitSymbol ? true : false;

	const symbol = omitSymbol ? '' : getCurrencySymbol();
	const priceKey = buylist ? 'buyPrice' : 'sellPrice';

	const filteredInventory = [];
	for(const inv of invArray) {
		if(available && inv.totalQuantity <= 0) {
			continue;
		}
		if(buylist) {
			if(!inv.isBuylist || !inv[priceKey]) {
				continue;
			}
		}
		filteredInventory.push(inv);
	}

	if(filteredInventory.length === 0) {
		if(addTags) {
			return symbol ? `<span class='${PROD_PRICE_CLASS_SYMBOL}'>${symbol}</span> -` : `-`;
		} else {
			return symbol ? `${symbol} -` : `-`;
		}
	}

	let minVal = parseFloat(filteredInventory[0][priceKey]);
	let maxVal = parseFloat(filteredInventory[0][priceKey]);
	for(const inv of filteredInventory) {
		if(parseFloat(inv[priceKey]) < minVal) {
			minVal = parseFloat(inv[priceKey]);
		}
		if(parseFloat(inv[priceKey]) > maxVal) {
			maxVal = parseFloat(inv[priceKey]);
		}
	}
	return maxVal === minVal ? `${formatPrice(maxVal, { addTags: addTags, language: language, omitSymbol: omitSymbol })}` : `${formatPrice(minVal, { addTags: addTags, language: language, omitSymbol: omitSymbol })} — ${formatPrice(maxVal, { addTags: addTags, language: language, omitSymbol: true })}`;
}

export function getMinPriceFromInventory(invArray, config = {}) {
	
	// Config schema
	// addTags: boolean; default false- not used here, just 
	// available: only include available inventory
	// buylist: only look at buylist inventory; filter only buy price
	// language: LANG_ constant; default LANG_EN
	// omitSymbol: boolean; default false

	if(!invArray) {
		return '';
	}

	const addTags = config.addTags ? true : false;
	const available = config.available ? true : false;
	const buylist = config.buylist ? true : false;
	const language = config.language ? config.language : LANG_EN;
	const omitSymbol = config.omitSymbol ? true : false;

	const symbol = omitSymbol ? '' : getCurrencySymbol();
	const priceKey = buylist ? 'buyPrice' : 'sellPrice';

	const filteredInventory = [];
	for(const inv of invArray) {
		if(available && inv.totalQuantity <= 0) {
			continue;
		}
		if(buylist) {
			if(!inv.isBuylist || !inv[priceKey]) {
				continue;
			}
		}
		filteredInventory.push(inv);
	}

	if(filteredInventory.length === 0) {
		if(addTags) {
			return symbol ? `<span class='${PROD_PRICE_CLASS_SYMBOL}'>${symbol}</span> -` : `-`;
		} else {
			return symbol ? `${symbol} -` : `-`;
		}
	}

	let minVal = parseFloat(filteredInventory[0][priceKey]);
	for(const inv of filteredInventory) {
		if(parseFloat(inv[priceKey]) < minVal) {
			minVal = parseFloat(inv[priceKey]);
		}
	}

	return `${formatPrice(minVal, { addTags: addTags, language: language, omitSymbol: omitSymbol })}`;
}

export function getMaxPriceFromInventory(invArray, config = {}) {
	
	// Config schema
	// addTags: boolean; default false- not used here, just 
	// available: only include available inventory
	// buylist: only look at buylist inventory; filter only buy price
	// language: LANG_ constant; default LANG_EN
	// omitSymbol: boolean; default false

	if(!invArray) {
		return '';
	}

	const addTags = config.addTags ? true : false;
	const available = config.available ? true : false;
	const buylist = config.buylist ? true : false;
	const language = config.language ? config.language : LANG_EN;
	const omitSymbol = config.omitSymbol ? true : false;

	const symbol = omitSymbol ? '' : getCurrencySymbol();
	const priceKey = buylist ? 'buyPrice' : 'sellPrice';

	const filteredInventory = [];
	for(const inv of invArray) {
		if(available && inv.totalQuantity <= 0) {
			continue;
		}
		if(buylist) {
			if(!inv.isBuylist || !inv[priceKey]) {
				continue;
			}
		}
		filteredInventory.push(inv);
	}

	if(filteredInventory.length === 0) {
		if(addTags) {
			return symbol ? `<span class='${PROD_PRICE_CLASS_SYMBOL}'>${symbol}</span> -` : `-`;
		} else {
			return symbol ? `${symbol} -` : `-`;
		}
	}

	let maxVal = parseFloat(filteredInventory[0][priceKey]);
	for(const inv of filteredInventory) {
		if(parseFloat(inv[priceKey]) > maxVal) {
			maxVal = parseFloat(inv[priceKey]);
		}
	}

	return `${formatPrice(maxVal, { addTags: addTags, language: language, omitSymbol: omitSymbol })}`;
}

export function getTotalQuantityFromInventory(invArray, language = LANG_EN) {
	
	if(!invArray || invArray.length === 0) {
		return `0`;
	}

	let qtyCount = 0;
	for(const inv of invArray) {
		qtyCount += parseInt(inv.totalQuantity);
	}
	return qtyCount.toLocaleString(language);
}

export function getProductType(productObj) {
	if(productObj && productObj.magic) {
		return PROD_TYPE_MAGIC;
	} else if(productObj && productObj.pokemon) {
		return PROD_TYPE_POKEMON;
	} else if(productObj && productObj.lorcana) {
		return PROD_TYPE_LORCANA;
	} else if(productObj && productObj.starwars) {
		return PROD_TYPE_STARWARS;
	}
	return PROD_TYPE_GENERAL;
}

export function getProductConfig(productObj) {
	let productConfig = null;
	switch(getProductType(productObj)) {
		case PROD_TYPE_MAGIC:
			productConfig = PROD_INV_CONFIG_MAGIC;
			break;
		case PROD_TYPE_POKEMON:
			productConfig = PROD_INV_CONFIG_POKEMON;
			break;
		case PROD_TYPE_LORCANA:
			productConfig = PROD_INV_CONFIG_LORCANA;
			break;
		case PROD_TYPE_STARWARS:
			productConfig = PROD_INV_CONFIG_STARWARS;
			break;
		default:
			productConfig = PROD_INV_CONFIG_GENERAL;
	}
	return productConfig;
}

// accepts an array of arrays; sorts each array an returns an array of arrays;
// returns an array of arrays
export function sortInventoryByParam(dataArray, orderConfig) {
	let resultsArray = [];
	
	for(const dataGroup of dataArray) {
		// Sort by sequence
		for(const sortObj of orderConfig.sequence) {
			const foundMatches = [];
			for(const dataItem of dataGroup) {

				if(dataItem[orderConfig.key] && dataItem[orderConfig.key][orderConfig.sortKey] === sortObj[orderConfig.sortKey]) {
					foundMatches.push(dataItem);
				}
			}
			if(foundMatches.length > 0) {
				resultsArray.push(foundMatches);
			}
		}
		// Add null results at the end
		const nullMatches = [];
		for(const dataItem of dataGroup) {
			if(!dataItem[orderConfig.key]) {
				nullMatches.push(dataItem);
			}
		}
		if(nullMatches.length > 0) {
			resultsArray.push(nullMatches);
		}
	}
	return resultsArray;
}

export function flattenSortedInventory(dataArray) {
	let flattenedArray = [];
	for(const subArray of dataArray) {
		for(const dataItem of subArray) {
			flattenedArray.push(dataItem);
		}
	}
	return flattenedArray;
}

export function getOrderedInventory(productObj, config = {}) {
	if(!productObj || !productObj.inventory) {
		return [];
	}

	const inventoryAlt = config.inventoryAlt || false;

	const productConfig = getProductConfig(productObj);
	let inventoryToBeSorted = [ inventoryAlt || productObj.inventory ]; // intentionally wrapped in array

	for(const orderConfig of productConfig.inventoryOrder) {
		inventoryToBeSorted = sortInventoryByParam(inventoryToBeSorted, orderConfig);
	}

	return flattenSortedInventory(inventoryToBeSorted);
}


export function getOrderedMedia(productObj) {
	if(!productObj || !productObj.media) {
		return [];
	}
	return productObj.media.sort(dynamicSort('order', false));
}


export function getMediaUrl(mediaKey) {
	const origin = process.env.REACT_APP_BUILD_MEDIA_ORIGIN;
	return `${origin}/${mediaKey}`;
}


export function getMediaAlt(productObj, mediaObj) {
	try {
		return mediaObj.caption ? mediaObj.caption : productObj.name;
	} catch(err) {
		console.error(err);
		return '';
	}
}


export function getStoredMenu() {
	const menuArray = storage.get(STORAGE_MENU);
	if(!menuArray) {
		return [];
	}
	return menuArray;
}

export function getStoredEnabledProductLines() {
	const productLinesArray = storage.get(STORAGE_ENABLED_PRODUCT_LINES);
	if(!productLinesArray) {
		return [];
	}
	return productLinesArray;
}

export function saveMenu(menuArray) {
	storage.put(STORAGE_MENU, getOrderedMenu(menuArray));
}

export function saveEnabledProductLines(productLinesArray) {
	storage.put(STORAGE_ENABLED_PRODUCT_LINES, productLinesArray);
}

// Used for admin editing; should remain agnostic to buy/sell/play
// If you need specific to buy menu, create getOrderedBuyMenu
export function getOrderedMenu(menuArray) {
	if(!menuArray || menuArray.length === 0) {
		return [];
	}
	return menuArray.sort(dynamicSort('displayOrder', false));
}

export function getOrderedSellMenu(menuArray) {
	if(!menuArray || menuArray.length === 0) {
		return [];
	}

	const prunedArray = [];
	for(const pl of menuArray) {
		if(pl.hasBuylist) {
			prunedArray.push(pl);
		}
	}
	return prunedArray.sort(dynamicSort('displayOrder', false));
}

export function getOrderedPlayMenu(menuArray) {

	// Can be used for other places outside of menu for a "menu-like" sort

	if(!menuArray || menuArray.length === 0) {
		return [];
	}

	const prunedArray = [];
	for(const pl of menuArray) {
		if(pl.hasEvents) {
			prunedArray.push(pl);
		}
	}
	return prunedArray.sort(dynamicSort('displayOrder', false));
}

export function getAttrFinish(productObj) {

  if(!productObj.hasFinish()) { return []; }

  if(productObj.foreignModel && productObj.foreignModel.allFinishes) {
  	return productObj.foreignModel.allFinishes() || [];
  }
  return [];
}

export function getOrderedProductLines(plArray) {
	if(!plArray || plArray.length === 0) {
		return [];
	}

	const orderedElements = [];
	const unorderedElements = [];

	for(const pl of plArray) {
		if(isVarNumber(pl.displayOrder)) {
			orderedElements.push(pl);
		} else {
			unorderedElements.push(pl);
		}
	}
	return orderedElements.sort(dynamicSort('displayOrder', false)).concat(unorderedElements);
}

export function getProductLanguage(productObj) {
	if(!productObj) {
		return null;
	}

	if(productObj.foreignModel) {
		return productObj.foreignModel.languageObj;
	}
	return productObj.language;
}

export function productInventoryLabelLanguageRequired(productObj) {
	for(const inv of productObj.inventory) {
    if(inv.language && inv.language.code !== process.env.REACT_APP_DEFAULT_LANGUAGE) {
      return true;
    }
  }
  return false;
}

export function productInventoryLabelConditionRequired(productObj) {
	for(const inv of productObj.inventory) {
    if(inv.isSealed !== true) {
      return true;
    }
  }
  return false;
}

// t: translation function passed from frontend
export function getProductInventoryLabel(productObj, inventoryObj, dispConfig = {}) {
	if(!productObj || !inventoryObj) {
		return '';
	}

	const labelValues = {};
	const config = getProductConfig(productObj);
	const displayConfig = dispConfig || {};

	const abbreviateCondition = displayConfig.abbreviateCondition || false;
	const showAllLabels = displayConfig.showAllLabels || false;
	const t = displayConfig.translate || null;

	for(const invAttr of config.inventoryOrder) {
		if(!inventoryObj[invAttr.key] || !inventoryObj[invAttr.key][invAttr.sortKey]) {
			continue;
		}

		const valueKey = abbreviateCondition && invAttr.key === PROD_KEY_CONDITION ? 'abbrKey' : 'labelKey';
		const invValue = t ? t(inventoryObj[invAttr.key][invAttr[valueKey]]) : inventoryObj[invAttr.key][invAttr[valueKey]];
		labelValues[invAttr.key] = invValue;
	}

	let labelResp = '';

	for(const labelKey of config.labelOrder) {

		if(!labelValues[labelKey]) {
			continue;
		}

		if(showAllLabels) {

			// Still have to check printing because so rarely shown
			if(PROD_KEY_PRINTING === labelKey) {
				if(productObj.allPrintings().length <= 1) {
					continue;
				}
			}

			labelResp += ` ${labelValues[labelKey]}`;
			continue;
		}

		if(PROD_KEY_LANGUAGE === labelKey && productInventoryLabelLanguageRequired(productObj)) {
	    labelResp += ` ${labelValues[labelKey]}`;
		} else if(PROD_KEY_FINISH === labelKey) {
			if(productObj.hasFinish() && isFinishDefault(inventoryObj.finish) === false) {
				labelResp += ` ${labelValues[labelKey]}`;	
			}
		} else if(PROD_KEY_CONDITION === labelKey && productInventoryLabelConditionRequired(productObj)) {
    	labelResp += ` ${labelValues[labelKey]}`;
		} else if(PROD_KEY_PRINTING === labelKey) {
			if(productObj.allPrintings().length > 1) {
				labelResp += ` ${labelValues[labelKey]}`;
			}
		} else {
			labelResp += ` ${labelValues[labelKey]}`;
		}
	}

	return labelResp.trim();
}















