import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { checkoutPrereqMet } from '../../../../../utils/checkout';

import CheckoutStepBuylistPaymentMethodInput from './CheckoutStepBuylistPaymentMethodInput';

let allActionCreators = Object.assign({});

export class CheckoutStepBuylistPaymentMethod extends Component {

  getStepComponent() {

    // If pre-requisites not met, do not display
    if(checkoutPrereqMet(this.props.config, this.props.checkout.stepData) === false) {
      return null;
    }

    return <CheckoutStepBuylistPaymentMethodInput 
            config={this.props.config}
            setStepData={this.props.setStepData} />;
  }

  render() {
    return <div className={'CheckoutStepBuylistPaymentMethod'}>{this.getStepComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepBuylistPaymentMethod));