import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import { ICON_UPLOAD } from '../../constants/icons';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_CUSTOMERS, 
  TABLE_SCHEMA_CUSTOMERS, 
  TC_PAGINATION_CONFIGS, 
  TC_DEFAULT_PAGE_SIZE, 
} from '../../constants/tables';
import { URL_ADMIN_CUSTOMERS_BULK_UPLOAD } from '../../constants/urls';

import { 
  getPageLimit, 
  getPageOffset,
} from '../../utils/request';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';
import { Icon } from '../Icons/Icon';

import * as customerActionCreators from '../../actions/customer';
let allActionCreators = Object.assign({}, customerActionCreators);

export class Customers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      customerData: [],
      customerPending: true,
      customerCount: 0,

      customerPage: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getCustomerPage(this.state.customerPage);
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getCustomerPage(pageNum = 1, pageSize = TABLE_CONFIG_CUSTOMERS[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      customerData: [],
      customerPending: true,
      customerCount: 0,
    });

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    this.props.customerFetchPage(getParams, controller.signal)
    .then((resp) => {
      this.setState({
        customerData: resp.data,
        customerPending: false,
        customerCount: resp.count,
      });
    })
    .catch((errResp) => {
      if(controller.signal.aborted) {
        // Request aborted; do nothing
      } else if(errResp) {
        console.error(errResp);
        this.setState({
          customerData: [],
          customerPending: false,
          customerCount: 0,
        });
      }
    });
  }

  render() {

    const {t} = this.props;
    
    return <div className={'Customers AdminPage'}>
      <AdminTitle
        title={tx.TX_CUSTOMERS}
        breadcrumbs={[]} />
      <div className={'inventoryIconRow adminIconRow'}>
        <Link to={URL_ADMIN_CUSTOMERS_BULK_UPLOAD} className={'inventoryIconWrapper adminIconWrapper'}>
          <Icon 
            value={ICON_UPLOAD}  
            iconClass={'inventoryIcon adminIcon'} 
            iconLabel={t(tx.TX_INV_BULK_UPLOAD_BULK_UPLOADS)} 
            ellipsisLabel={false} />
        </Link>
      </div>
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_CUSTOMERS}
          schema={TABLE_SCHEMA_CUSTOMERS}
          data={this.state.customerData}
          dataCount={this.state.customerCount} 
          dataLoading={this.state.customerPending}
          callApi={this.getCustomerPage.bind(this)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Customers));