import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_BUY, 
  ICON_SELL, 
  ICON_STORE_CREDIT, 
  ICON_USER, 
} from '../../constants/icons';
import * as tx from '../../constants/strings';
import { 
  URL_ACCOUNT, 
  URL_ACCOUNT_ORDERS,
  URL_ACCOUNT_ORDERS_BUYLIST,
  URL_ACCOUNT_STORE_CREDIT,
} from '../../constants/urls';

import { isBuylistCheckoutEnabled } from '../../utils/checkout';

import { Icon } from '../Icons/Icon';

import './style/_account.scss';

import * as headerActionCreators from '../../actions/header';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, headerActionCreators, userActionCreators);

export class AccountMenu extends Component {

	render() {

		const {t} = this.props;
	
    return <div className={'AccountMenu'}>

      <div className='adminMenuWrapper'>

        <div className='adminMenuTitle'>{t(tx.TX_ACCOUNT_MANAGE)}</div>

        <NavLink exact to={URL_ACCOUNT} className='adminMenuLink' activeClassName='selected'>
          <div className='amLinkIconWrapper'>
            <Icon 
              value={ICON_USER}  
              iconClass={'amIcon amIconDesktop'} 
              ellipsisLabel={false} />
            <Icon 
              value={ICON_USER}  
              iconClass={'amIcon amIconMobile'} 
              iconLabel={t(tx.TX_ACCOUNT_PROFILE)} 
              ellipsisLabel={true} />
          </div>
          <div className='amLinkLabel'>{t(tx.TX_ACCOUNT_MY_PROFILE)}</div>
        </NavLink>

        <NavLink to={URL_ACCOUNT_ORDERS} className='adminMenuLink' activeClassName='selected'>
          <div className='amLinkIconWrapper'>
            <Icon 
              value={ICON_BUY}  
              iconClass={'amIcon amIconDesktop'} 
              ellipsisLabel={false} />
            <Icon 
              value={ICON_BUY}  
              iconClass={'amIcon amIconMobile'} 
              iconLabel={t(tx.TX_ORDERS)} 
              ellipsisLabel={true} />
          </div>
          <div className='amLinkLabel'>{t(tx.TX_MENU_ITEM_ORDERHISTORY)}</div>
        </NavLink>

        {isBuylistCheckoutEnabled() ?
          <NavLink to={URL_ACCOUNT_ORDERS_BUYLIST} className='adminMenuLink' activeClassName='selected'>
            <div className='amLinkIconWrapper'>
              <Icon 
                value={ICON_SELL}  
                iconClass={'amIcon amIconDesktop'} 
                ellipsisLabel={false} />
              <Icon 
                value={ICON_SELL}  
                iconClass={'amIcon amIconMobile'} 
                iconLabel={t(tx.TX_BUYLIST)}
                ellipsisLabel={true} />
            </div>
            <div className='amLinkLabel'>{t(tx.TX_ACCOUNT_BUYLIST_ORDERS)}</div>
          </NavLink> :
          null
        }

        <NavLink to={URL_ACCOUNT_STORE_CREDIT} className='adminMenuLink' activeClassName='selected'>
          <div className='amLinkIconWrapper'>
            <Icon 
              value={ICON_STORE_CREDIT}  
              iconClass={'amIcon amIconDesktop'} 
              ellipsisLabel={false} />
            <Icon 
              value={ICON_STORE_CREDIT}  
              iconClass={'amIcon amIconMobile'} 
              iconLabel={t(tx.TX_MENU_ITEM_STORECREDIT)}
              ellipsisLabel={true} />
          </div>
          <div className='amLinkLabel'>{t(tx.TX_MENU_ITEM_STORECREDIT)}</div>
        </NavLink>

      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountMenu));


