

import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

// Reducers
import createRootReducer from '../reducers';

// Middleware
import { serverObjectSetterMiddleware } from '../middleware';


const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();
export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    storeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        serverObjectSetterMiddleware,
      ),
    ),
  )

  return store
}