
import { CONDITIONS_ALL } from '../constants/conditions';

const CONDITION_KEY = 'key';
const CONDITION_KEY_LEGACY = 'name';

export function getConditionObjectFromServerResp(conditionCode) {

	if(!conditionCode) {
		return null;
	}

	for(const cond of CONDITIONS_ALL) {
		if(conditionCode === cond[CONDITION_KEY]) {
			return cond;
		}
	}

	for(const cond of CONDITIONS_ALL) {
		if(conditionCode === cond[CONDITION_KEY_LEGACY]) {
			return cond;
		}
	}

	return null;
}

export function getConditionsOrderedDeduped(conditionArray) {
	
	const keysToSort = [];
	for(const cond of conditionArray) {
		if(!keysToSort.includes(cond.key)) {
			keysToSort.push(cond.key);
		}
	}

	const orderedKeys = [];
	for(const cond of CONDITIONS_ALL) {
		if(keysToSort.includes(cond.key)) {
			orderedKeys.push(cond.key);
		}
	}
	
	const orderedResp = [];
	for(const key of orderedKeys) {
		for(const cond of CONDITIONS_ALL) {
			if(key === cond.key) {
				orderedResp.push(cond);
				break;
			}
		}
	}
	return orderedResp;
}

export function getConditionObjectFromKey(conditionCode, defaultResp = null) {

	if(!conditionCode) {
		return defaultResp;
	}

	for(const cond of CONDITIONS_ALL) {
		if(conditionCode === cond[CONDITION_KEY]) {
			return cond;
		}
	}

	return defaultResp;
}