import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Dropzone from 'react-dropzone';
import Papa from 'papaparse';

import { 
  ERROR_FILE_BULK_CSV_UPLOAD_INVALID,
  ERROR_FILE_BULK_CSV_UPLOAD_MISSING,
  ERROR_FILE_BULK_CSV_UPLOAD_MISSING_COLUMNS, 
  ERROR_FILE_BULK_CSV_UPLOAD_SINGLE,
  ERROR_PRODUCT_LINE_PLEASE_SELECT,
  ERROR_UPLOAD_FORMAT_MISSING,
} from '../../../constants/errors';
import { 
  ICON_CHEVRON_DOWN,
  ICON_DOWNLOAD,
  ICON_FILE,
} from '../../../constants/icons';
import { PROD_BULK_UPLOAD_SCHEMA_KEY_PRODUCT_LINE } from '../../../constants/product';
import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../../constants/urls';

import { 
  getErrorBulkCSVImport,
} from '../../../utils/files';
import {
  getNotEmptyError,
  isFormValid,
} from '../../../utils/form-validation';
import { formatFilesize } from '../../../utils/formatting';
import { getOrderedProductLines } from '../../../utils/product';

import Dropdown from '../../Input/Dropdown';
import { Icon } from '../../Icons/Icon';
import { LoadingIcon } from '../../Icons/LoadingIcon';
import Toggle from '../../Input/Toggle';

import * as productLineActionCreators from '../../../actions/product-line';
const allActionCreators = Object.assign({}, productLineActionCreators);

export class BulkUploadInput extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputProductLine: '', 
      inputImportType: '', 
      inputAddQtyDelta: true, 
      inputCSVFile: null,
      inputProductLineMapping: '', 

      errorProductLine: '', 
      errorImportType: '', 
      errorCSVFile: '',
      errorCSVSchema: '', 
      errorCSVSchemaColumns: [], 
      errorProductLineMapping: '', 

      dragCount: 0,

      guidanceOpen: false,

      productLineOptions: [],
    };
  }

  componentDidMount() {

    // Fetch product line data
    if(this.props.productLine && this.props.productLine.productLines === null) {
      
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  changeProductLine(evt) {

    if(!this.props.productLine.productLines) {
      return;
    }

    for(const pl of this.props.productLine.productLines) {
      if(pl.id === parseInt(evt.target.value)) {
        this.setState({ 
          inputProductLine: pl,
          inputImportType: pl.bulkUploadFormats.length === 1 ? pl.bulkUploadFormats[0].key : '',
          inputAddQtyDelta: true, 
          inputCSVFile: null,
          inputProductLineMapping: '', 

          productLineOptions: [],

          errorProductLine: '', 
          errorImportType: '', 
          errorCSVFile: '',
          errorCSVSchema: '', 
          errorCSVSchemaColumns: [], 
          errorProductLineMapping: '', 
        });
        this.props.makeDirty();
        break;
      }
    }
  }

  getProductLineOptions() {

    const options = [];

    const orderedLines = getOrderedProductLines(this.props.productLine.productLines);

    const applicableLines = [];
    for(const ol of orderedLines) {      
      if(ol.bulkUploadFormats.length > 0) {
        applicableLines.push(ol);
      }
    }

    if(applicableLines.length === 0) {
      options.push({
        display: this.props.t(tx.TX_INV_ADD_NO_PL),
        value: '',
      });
    } else {
      options.push({
        display: this.props.t(tx.TX_PLACEHOLDER_PL_DROPDOWN),
        value: '',
      });
    }

    for(const pl of applicableLines) {
      options.push({
        display: pl.name,
        value: pl.id,
      });
    }

    return options;
  }

  changeFormat(evt) {
    this.setState({
      inputImportType: evt.target.value,
      inputCSVFile: null,
      inputAddQtyDelta: true, 
      inputProductLineMapping: '', 

      productLineOptions: [],

      errorImportType: '', 
      errorCSVFile: '',
      errorCSVSchema: '', 
      errorCSVSchemaColumns: [], 
      errorProductLineMapping: '', 
    });
    this.props.makeDirty();
  }

  getFormatOptions() {

    const options = [
      {
        display: tx.TX_PLACEHOLDER_UPLOAD_FORMAT,
        value: '',
      }
    ];

    if(this.state.inputProductLine) {
      for(const format of this.state.inputProductLine.bulkUploadFormats) {
        options.push({
          display: format.name,
          value: format.key,
        });
      }
    }

    return options;
  }

  changeProductLineMapping(evt) {
    this.setState({ inputProductLineMapping: evt.target.value });
    this.props.makeDirty();
  }

  getProductLineMappingOptions() {

    const options = [
      {
        display: this.props.t(tx.TX_PLACEHOLDER_PRODUCT_LINE_OPTIONS),
        value: '',
      }
    ];

    if(this.state.productLineOptions) {
      for(const opt of this.state.productLineOptions) {
        options.push({
          display: opt,
          value: opt,
        });
      }
    }

    return options;
  }

  dragEnterInput(evt) {
    for(const tp of evt.dataTransfer.types) {
      if(tp === 'Files') {
        this.setState({ dragCount: this.state.dragCount + 1 });
        break;
      }
    }
  }

  dragLeaveInput(evt) {
    this.setState({ dragCount: Math.max(this.state.dragCount - 1, 0) });
  }

  async dragDropInput(files) {

    if(files.length > 1) {
      this.setState({
        errorCSVFile: ERROR_FILE_BULK_CSV_UPLOAD_SINGLE,
        dragCount: 0,
      });
      return null;
    }

    if(files.length === 1) {
      const fileError = getErrorBulkCSVImport(files[0]);
      this.setState({
        inputCSVFile: fileError === '' ? files[0] : null,
        errorCSVFile: fileError,
        dragCount: 0,
        inputProductLineMapping: '', 
      }, () => {

        if(this.state.inputCSVFile && this.state.inputProductLine && this.state.inputImportType) {

          Papa.parse(this.state.inputCSVFile, {
            worker: true,
            header: false,
            skipEmptyLines: true,
            preview: 1,
            complete: (results) => {

              try {

                const missingFields = [];
                const schema = this.state.inputProductLine.getBulkUploadFormat(this.state.inputImportType).schema;

                for(const req of schema) {
                  const headerKeys = results.data[0];
                  if(req.required && !headerKeys.includes(req.key)) {
                    missingFields.push(req.key);
                  }
                }

                if(missingFields.length > 0) {
                  this.setState({
                    inputCSVFile: null,
                    errorCSVSchema: ERROR_FILE_BULK_CSV_UPLOAD_MISSING_COLUMNS,
                    errorCSVSchemaColumns: missingFields,
                  });
                } else {
                  this.setState({
                    errorCSVSchema: '',
                    errorCSVSchemaColumns: [],
                  });
                }
                
              } catch(err) {
                this.setState({
                  inputCSVFile: null,
                  errorCSVFile: ERROR_FILE_BULK_CSV_UPLOAD_INVALID,
                });
              }
            },
            error: (err) => {
              this.setState({
                inputCSVFile: null,
                errorCSVFile: ERROR_FILE_BULK_CSV_UPLOAD_INVALID,
              });
            },
          });

          if(this.defineProductLineValueConfig()) {

            Papa.parse(this.state.inputCSVFile, {
              worker: true,
              header: false,
              skipEmptyLines: true,
              preview: 0,
              complete: (results) => {
                const productLineIndex = results.data[0].indexOf(PROD_BULK_UPLOAD_SCHEMA_KEY_PRODUCT_LINE);

                this.setState({
                  productLineOptions: [...new Set(results.data.slice(1).map(row => row[productLineIndex]))],
                });
              },
              error: (err) => {
                console.error(err);
              },
            });


            // let productLineIndex = -1;
          }
        }
      });
    }
  }

  removeFile(evt) {
    evt.stopPropagation();
    this.setState({
      inputCSVFile: null,
      errorCSVFile: '',
      errorCSVSchema: '', 
      errorCSVSchemaColumns: [],
    });
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll(true)) {
      this.props.initiateUpload(this.state.inputProductLine, this.state.inputImportType, !this.state.inputAddQtyDelta, this.state.inputCSVFile, this.state.inputProductLineMapping);
    }
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorProductLine: getNotEmptyError(this.state.inputProductLine, ERROR_PRODUCT_LINE_PLEASE_SELECT), 
      errorImportType: getNotEmptyError(this.state.inputImportType, ERROR_UPLOAD_FORMAT_MISSING), 
      errorCSVFile: getNotEmptyError(this.state.inputCSVFile, ERROR_FILE_BULK_CSV_UPLOAD_MISSING) || getErrorBulkCSVImport(this.state.inputCSVFile),
      errorCSVSchema: this.state.errorCSVSchema,
      errorProductLineMapping: this.defineProductLineValueConfig() ? getNotEmptyError(this.state.inputProductLineMapping, ERROR_PRODUCT_LINE_PLEASE_SELECT) : '',
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  toggleGuidance() {
    this.setState({ guidanceOpen: !this.state.guidanceOpen });
  }

  downloadTemplate() {

    if(!this.state.inputProductLine || !this.state.inputImportType) { return null; }

    const firstRow = {};
    for(const row of this.state.inputProductLine.getBulkUploadFormat(this.state.inputImportType).schema) {
      firstRow[row.key] = '';
    }

    const template = Papa.unparse([ firstRow ]);
    const templateData = new Blob([ template ], { type: 'text/csv;charset=utf-8;' });
    const templateURL = navigator.msSaveBlob ? navigator.msSaveBlob(templateData, `${this.state.inputProductLine.permalink}-${this.state.inputImportType}-template.csv`) : window.URL.createObjectURL(templateData);

    let tempLink = document.createElement('a');
    tempLink.href = templateURL;
    tempLink.setAttribute('download', `${this.state.inputProductLine.permalink}-${this.state.inputImportType}-template.csv`);
    tempLink.click();
  }

  changeAddQtyType(evt) {
    this.setState({ inputAddQtyDelta: !this.state.inputAddQtyDelta });
  }

  allowQuantityConfig() {

    if(!this.state.inputProductLine || !this.state.inputImportType) { return false; }

    for(const format of this.state.inputProductLine.bulkUploadFormats) {
      if(format.key === this.state.inputImportType) {
        return !!format.allowAddQty;
      }
    }
    return false;
  }

  defineProductLineValueConfig() {

    if(!this.state.inputProductLine || !this.state.inputImportType) { return false; }

    for(const format of this.state.inputProductLine.bulkUploadFormats) {
      if(format.key === this.state.inputImportType) {
        return !!format.defineProductLineValue;
      }
    }
    return false;
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkUploadInput'}>
      <div className='adminForm'>
        <form 
          className='bulkUploadInputForm'
          onSubmit={this.saveAction.bind(this)}>

          <div className='adminFieldWrapper'>
            <div className='adminInputWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRODUCT_LINE)}</div>
              <div className='adminInputProductLineWrapper'>
                {this.props.productLine.productLinesPending ?
                  <div className='aiProductLineLoading'>
                    <div className='aiProductLineLoadingIcon'>
                      <LoadingIcon />
                    </div>
                    <div className='aiProductLoadingLabel'>{t(tx.TX_LOADING)}</div>
                  </div> :
                  <div> 
                    <div className='adminDropdownWrapper'>
                      <Dropdown 
                        className={'adminDropdownSelect'}
                        options={this.getProductLineOptions()}
                        name={t(tx.TX_PRODUCT_LINE)}
                        value={this.state.inputProductLine ? this.state.inputProductLine.id : ''}
                        onChange={this.changeProductLine.bind(this)}
                        required={true}
                        noTranslate={true}
                        disabled={getOrderedProductLines(this.props.productLine.productLines).length === 0} />
                    </div>
                    {this.state.errorProductLine ?
                      <div className={'adminError FieldError'}>{t(this.state.errorProductLine)}</div> :
                      null
                    }
                  </div>
                }
              </div>
            </div>
          </div>

          {!this.props.productLine.productLinesPending && !this.state.inputProductLine ?
            <div className='adminFieldWrapper'>
              <div className='adminFieldMediumNotice'>{t(tx.TX_INV_BULK_UPLOAD_SUPPORTED_LINES)}</div>
            </div> :
            null
          }

          {this.state.inputProductLine.id ?
            <div className='adminFieldWrapper'>
              <div className={'adminInputWrapper'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_BULK_UPLOAD_UPLOAD_FORMAT)}</div>
                <div className='adminDropdownWrapper'>
                  <Dropdown 
                    className={'adminDropdownSelect'}
                    options={this.getFormatOptions()}
                    name={t(tx.TX_INV_BULK_UPLOAD_UPLOAD_FORMAT)}
                    value={this.state.inputImportType}
                    onChange={this.changeFormat.bind(this)}
                    required={true} />
                </div>
                {this.state.errorFormat ?
                  <div className={'adminError FieldError'}>{t(this.state.errorFormat)}</div> :
                  null
                }
              </div>
            </div> :
            null
          }

          {this.state.inputImportType ?
            <div className='uploadGuidanceWrapper'>
              <div className='guidanceHeader'>
                <div className='guidanceHeaderLiner' onClick={this.toggleGuidance.bind(this)}>
                  <div className='ghIcon'>
                    <div className={this.state.guidanceOpen ? 'ghIconWrapper open' : 'ghIconWrapper'}>
                      <Icon value={ICON_CHEVRON_DOWN} />
                    </div>
                  </div>
                  <div className='ghLabel'>{t(tx.TX_INV_BULK_UPLOAD_SEE_REQUIREMENTS)}</div>
                </div>
              </div>
              <div className={this.state.guidanceOpen ? 'guidanceBody open' : 'guidanceBody'}>
                <div className='guidanceBodyLiner'>
                  <div className='guidanceRowExplanation'>
                    <div className='schemaTable'>
                      <div className='schemaRow'>
                        <div className='cellKey headerCell'>{t(tx.TX_INV_BULK_UPLOAD_ROW_HEADER)} <span className='requiredWrapper'>{t(tx.TX_REQUIRED).toLowerCase()}</span></div>
                        <div className='cellDesc headerCell'></div>
                      </div>
                      {this.state.inputProductLine.getBulkUploadFormat(this.state.inputImportType).schema.map((col, i) => {
                        return <div key={i} className='schemaRow'>
                          <div className='cellKey'>
                            <div className={col.required ? 'cellKeyWrapper required' : 'cellKeyWrapper'}>{col.key}</div>
                          </div>
                          <div className='cellDesc'>{t(col.description)}</div>
                        </div>
                      })}
                    </div>
                  </div>
                  <div className='templateWrapper' onClick={this.downloadTemplate.bind(this)}>
                    <div className='templateLabel'>{t(tx.TX_INV_BULK_UPLOAD_DOWNLOAD_TEMPALTE)}</div>
                    <div className='templateIcon'>
                      <Icon value={ICON_DOWNLOAD} />
                    </div>
                  </div>
                </div>
              </div>
            </div> :
            null
          }

          {this.allowQuantityConfig() ?
            <div className={'adminFieldWrapper firstConfigElement'}>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_QUANTITY)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={!this.state.inputAddQtyDelta}
                    disabled={false}
                    onToggle={this.changeAddQtyType.bind(this)}
                    trueValue={tx.TX_INV_BULK_UPLOAD_SET_QUANTITY_TOTAL}
                    falseValue={tx.TX_INV_BULK_UPLOAD_SET_QUANTITY_ADD} />
                </div>
              </div>
            </div> :
            null
          }

          {this.state.inputProductLine.id && this.state.inputImportType ?
            <div className='fileDropzoneWrapper'>
              <Dropzone onDrop={acceptedFiles => this.dragDropInput(acceptedFiles)} disabled={this.state.inputCSVFile !== null}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div 
                        ref={this.fileDropRef}
                        className={this.state.dragCount ? 'dropWrapper hoverFile' : 'dropWrapper'}
                        onDragEnter={this.dragEnterInput.bind(this)}
                        onDragLeave={this.dragLeaveInput.bind(this)}>

                        {this.state.inputCSVFile ?
                          <div className='dropFileDisplay'>
                            <div className='dropFileIconWrapper'>
                              <Icon value={ICON_FILE} />
                            </div>
                            <div className='dropFileDetailsWrapper'>
                              <div className={'nameLabel EllipsisElement'}>{this.state.inputCSVFile.name}</div>
                              <div className='metaLabel'>{formatFilesize(this.state.inputCSVFile.size)}</div>
                              <div className='removeLabel' onClick={this.removeFile.bind(this)}>{t(tx.TX_REMOVE)}</div>
                            </div>
                            <div className='dropFileCloseWrapper' onClick={this.removeFile.bind(this)}>
                              <div className='FlexCenter'>&times;</div>
                            </div>
                          </div> :
                          <div className='dropPrompt'>{t(tx.TX_INV_BULK_UPLOAD_DRAG_HERE)}</div>
                        }
                          
                        {this.state.errorCSVFile ?
                          <div className='dropError'>{t(this.state.errorCSVFile)}</div> :
                          null
                        }

                        {this.state.errorCSVSchema ?
                          <div className='dropError'>{t(this.state.errorCSVSchema, { columns: this.state.errorCSVSchemaColumns.join(', ') })}</div> :
                          null
                        }

                        {!this.state.inputCSVFile ?
                          <button 
                            className='dropButton'
                            type='button'>
                            {t(tx.TX_INV_BULK_UPLOAD_UPLOAD_CSV)}
                          </button> :
                          null
                        }
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div> :
            null
          }

          {this.defineProductLineValueConfig() && this.state.inputCSVFile && this.state.productLineOptions.length ?
            <div className='adminFieldWrapper'>
              <div className={'adminInputWrapper'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_BULK_UPLOAD_UPLOAD_PRODUCT_LINE_VALUE)}</div>
                <div className='adminDropdownWrapper'>
                  <Dropdown 
                    className={'adminDropdownSelect'}
                    options={this.getProductLineMappingOptions()}
                    name={t(tx.TX_INV_BULK_UPLOAD_UPLOAD_PRODUCT_LINE_VALUE)}
                    value={this.state.inputProductLineMapping}
                    onChange={this.changeProductLineMapping.bind(this)}
                    required={true}
                    noTranslate={true} />
                </div>
                {this.state.errorProductLineMapping ?
                  <div className={'adminError FieldError'}>{t(this.state.errorProductLineMapping)}</div> :
                  null
                }
              </div>
            </div> :
            null
          }

          <div className='adminActionRow'>
            <Link 
              className={'adminAction adminActionCancel'} 
              to={URL_ADMIN_INVENTORY}>
              {t(tx.TX_CANCEL)}
            </Link>
            <button 
              className={'adminAction adminActionSave'} 
              type='submit'
              disabled={this.validateAll() === false}>
              {t(tx.TX_NEXT)}
            </button>
          </div>
        </form>
      </div>

        
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkUploadInput));