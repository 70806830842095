import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../../constants/strings';

import {
  getFinishesOrderedDeduped, 
} from '../../../../utils/finish';

import Dropdown from '../../../Input/Dropdown';

let allActionCreators = Object.assign({});

export class ProductPageInventoryConfigFinish extends Component {

  constructor(props) {
    super(props);

    this.state = {
      finishes: this.getInventoryFinishes(),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(this.props.productObj, prevProps.productObj) || !_.isEqual(this.props.exclusionFilters, prevProps.exclusionFilters) || !_.isEqual(this.props.value, prevProps.value)) {
      this.setState({
        finishes: this.getInventoryFinishes(),
      });
    }
  }

  getInventoryFinishes() {
    
    if(!this.props.productObj || !this.props.productObj.inventory) { return []; }

    if(this.props.productObj.hasFinish()) {

      const finishArray = [];
      for(const inv of this.props.productObj.inventory) {

        if(!inv.finish) {
          continue;
        }

        // Loop through exclusion filters, and exclude any inventory that doesn't match
        let shouldExclude = false;
        for(const exKey in this.props.exclusionFilters) {
          if(this.props.exclusionFilters[exKey]) {

            // If inventory doesn't have a valid attribute to filter, exclude it
            if(!inv[exKey]) {
              shouldExclude = true;
              break;
            }

            if(this.props.exclusionFilters[exKey].key && inv[exKey].key !== this.props.exclusionFilters[exKey].key) {
              shouldExclude = true;
            } else if(this.props.exclusionFilters[exKey].code && inv[exKey].code !== this.props.exclusionFilters[exKey].code) {
              shouldExclude = true;
            }
          }
        }

        if(!shouldExclude) {
          finishArray.push(inv.finish);
        }
      }

      const orderedFinishes = getFinishesOrderedDeduped(finishArray);
  
      // If finish isn't viabled based on exclusion filters, either unset or set as the only option
      if(this.props.value && this.props.value.key) {
        let validFilter = false;
        for(const fn of orderedFinishes) {
          if(this.props.value.key === fn.key) {
            validFilter = true;
            break;
          }
        }

        if(!validFilter) {
          if(orderedFinishes.length === 1) {
            // Set finish to only remaining option
            this.props.setAttr(orderedFinishes[0]);
          } else {
            // Unset finish
            this.props.setAttr(null);
          }
        }
      }

      // // If finish is unset and only one finish avilable, set it
      if(this.props.value === null && orderedFinishes.length === 1) {
        this.props.setAttr(orderedFinishes[0]);
      }

      return orderedFinishes;
    }
    return [];
  }

  shouldDisplayDropdown() {

    if(!this.props.productObj || !this.props.productObj.inventory) { return false; }

    if(this.props.productObj.hasFinish()) {

      const finishArray = [];
      for(const inv of this.props.productObj.inventory) {
        if(!inv.finish) {
          continue;
        }
        finishArray.push(inv.finish);
      }

      const allFinishes = getFinishesOrderedDeduped(finishArray);
      return allFinishes.length > 1;
    }
    return false;
  }

  getStaticOption() {
    if(!this.state.finishes || this.state.finishes.length < 1) {
      return tx.TX_null;
    }
    return this.state.finishes[0].name;
  }

  formatOptions() {
    if(!this.state.finishes) {
      return [];
    }
    
    const valueOptions = [];
    for(const fn of this.state.finishes) {
      valueOptions.push({
        display: fn.name, 
        value: fn.key,
      });
    }

    valueOptions.unshift({
      display: tx.TX_PLACEHOLDER_SELECT_FINISH,
      value: '',
    });
    return valueOptions;
  }

  getSelectedFinishName() {
    return this.props.value ? this.props.value.name : tx.TX_null;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductPageInventoryConfig ProductPageInventoryConfigFinish'}>
      <div className='ppiConfigElementWrapper'>
        {this.shouldDisplayDropdown() ?
          <div className='ppiConfigSelectable'>
            <div className='ppiConfigInlineLabel'>
              <div className='ppiConfigLabel'>{t(tx.TX_INV_ADD_PRODUCT_FINISHES)}</div>
              <div className='ppiSelectedValue'>{t(this.getSelectedFinishName())}</div>
            </div>
            <div className='ppiConfigSelectWrapper'>
              <Dropdown 
                className='ppiConfigSelect'
                options={this.formatOptions()}
                name={t(tx.TX_FILTER_FINISH)}
                value={this.props.value ? this.props.value.key : ''}
                required={true}
                onChange={this.props.setAttr.bind(this)} />
            </div>
          </div> :
          <div className='ppiConfigStatic'>
            <div className='ppiConfigLabel'>{t(tx.TX_INV_ADD_PRODUCT_FINISHES)}</div>
            <div className='ppiStaticValue'>{t(this.getStaticOption())}</div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageInventoryConfigFinish));