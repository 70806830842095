

import { LANG_EN } from '../constants/languages';

import { dateDelta } from '../utils/general';



// Real simple objects; just return arrays of Date objects currently

export class Month {

	constructor(props) {
		
		if(!props) { 
			props = {}; 
		}

		const now = new Date();
		let monthValue = now.getMonth();

		if(parseInt(props.month) || parseInt(props.month) === 0) {
			monthValue = parseInt(props.month);
		} else if(parseInt(props.monthIndex) || parseInt(props.monthIndex) === 0) {
			monthValue = parseInt(props.monthIndex);
		}

		this.monthIndex = monthValue;
		this.year = parseInt(props.year) || now.getFullYear();

		this.weekStart = parseInt(process.env.REACT_APP_WEEK_START) || 0;
	}

	get days() {
		
		const dayArray = [];
		const start = new Date(this.year, this.monthIndex, 1);

		for(let i = 0; i < this.daysCount; i++) {
			dayArray.push(dateDelta(start, i));
		}
		return dayArray;
	}

	get daysCount() {
		return new Date(this.year, this.monthIndex + 1, 0).getDate();
	}

	get weeksCount() {
		const first = new Date(this.year, this.monthIndex, 1);
		const daysBack = first.getDay() - this.weekStart >= 0 ? first.getDay() - this.weekStart : 7 + first.getDay() - this.weekStart;

		return Math.ceil( ( this.daysCount + daysBack ) / 7 );
	}

	get firstDate() {
		return new Date(this.year, this.monthIndex, 1);
	}

	formatName(language = LANG_EN, config = {}) {
		
		const defaultOptions = {
			month: 'long',
		}
		const options = Object.assign({}, defaultOptions, config);
		const first = new Date(this.year, this.monthIndex, 1);

		return first.toLocaleString(language, options);
	}

	formatYear(language = LANG_EN, config = {}) {
		
		const defaultOptions = {
			year: 'numeric',
		}
		const options = Object.assign({}, defaultOptions, config);
		const first = new Date(this.year, this.monthIndex, 1);

		return first.toLocaleString(language, options);
	}

	calendarWeeks() {
		const first = new Date(this.year, this.monthIndex, 1);

		const weekArray = [];
		for(let i = 0; i < this.weeksCount; i++) {
			weekArray.push(new Week({
				date: dateDelta(first, 7*i),
				weekStart: this.weekStart,
			}));
		}
		return weekArray;
	}

	inThisMonth(testDate) {
		try {
			return this.monthIndex === testDate.getMonth() && this.year === testDate.getFullYear() ? true : false;
		} catch(err) {
			console.error(err);
			return false;
		}
	}

	lastMonth() {
		if(this.monthIndex === 0) {
			return new Month({
				monthIndex: 11,
				year: this.year - 1,
			});
		}
		return new Month({
			monthIndex: this.monthIndex - 1,
			year: this.year,
		});
	}

	nextMonth() {
		if(this.monthIndex === 11) {
			return new Month({
				monthIndex: 0,
				year: this.year + 1,
			});
		}
		return new Month({
			monthIndex: this.monthIndex + 1,
			year: this.year,
		});
	}

	// Returns 1 - 5; the nth occurance of that day in its respective month
	static nthDay(testDate) {
		try {
			
			const dayOfWeek = testDate.getDay();
			const month = new Month({ month: testDate.getMonth() });

			let i = 0;
			for(const d of month.days) {
				if(d.getDay() === dayOfWeek) {
					i++;
				}
				if(d.getDate() === testDate.getDate()) {
					return i;
				}
			}
			return i;
		} catch(err) {
			return 0;
		}
	}

	// Returns 4 or 5 based on how many days of that type in its respective month
	static nthDayCount(testDate) {
		try {
			
			const dayOfWeek = testDate.getDay();
			const month = new Month({ month: testDate.getMonth() });

			let i = 0;
			for(const d of month.days) {
				if(d.getDay() === dayOfWeek) {
					i++;
				}
			}
			return i;
		} catch(err) {
			return 0;
		}
	}
}


export class Week {

	constructor(props) {
		
		if(!props) { 
			props = {}; 
		}

		this.date = props.date ? new Date(props.date) : new Date();
		this.weekStart = parseInt(process.env.REACT_APP_WEEK_START) || 0;
	}

	get days() {
		
		const dayArray = [];
		const start = new Date(this.startDate);

		for(let i = 0; i < 7; i++) {
			dayArray.push(dateDelta(start, i));
		}
		return dayArray;
	}

	get monthIndex() {
		let monthSum = 0;
		for(const day of this.days) {
			monthSum += day.getMonth();
		}
		return Math.round(monthSum / this.days.length);
	}

	get year() {
		let yearSum = 0;
		for(const day of this.days) {
			yearSum += day.getFullYear();
		}
		return Math.round(yearSum / this.days.length);
	}

	get startDate() {
		const daysBack = this.date.getDay() - this.weekStart >= 0 ? this.date.getDay() - this.weekStart : 7 + this.date.getDay() - this.weekStart;
		return dateDelta(this.date, -1 * daysBack);
	}

	formatMonth(language = LANG_EN, config = {}) {
		
		const defaultOptions = {
			month: 'long',
		}
		const options = Object.assign({}, defaultOptions, config);
		const first = new Date(this.year, this.monthIndex, 1);

		return first.toLocaleString(language, options);
	}

	formatYear(language = LANG_EN, config = {}) {
		
		const defaultOptions = {
			year: 'numeric',
		}
		const options = Object.assign({}, defaultOptions, config);
		const first = new Date(this.year, this.monthIndex, 1);

		return first.toLocaleString(language, options);
	}

	lastWeek() {
		return new Week({
			date: dateDelta(this.date, -7),
		});
	}

	nextWeek() {
		return new Week({
			date: dateDelta(this.date, 7),
		});
	}
}