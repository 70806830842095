import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../../../store';

import { 
  CHECKOUT_STEP_BUYLIST_ADDRESS, 
  CHECKOUT_STEP_BUYLIST_SELECT_PAYMENT_METHOD, 
} from '../../../../../constants/checkout';
import { ERROR_CHECKOUT_BUYLIST_CONFIRMATION } from '../../../../../constants/errors';
import * as tx from '../../../../../constants/strings';
import { URL_SUCCESS } from '../../../../../constants/urls';

import { Order } from '../../../../../models/orders';

import { getCheckoutStepData } from '../../../../../utils/checkout';
import { isFormValid } from '../../../../../utils/form-validation';
import { formatServerError } from '../../../../../utils/formatting';

import Checkbox from '../../../../Input/Checkbox';

import * as checkoutActionCreators from '../../../../../actions/checkout';
import * as userActionCreators from '../../../../../actions/user';
const allActionCreators = Object.assign({}, checkoutActionCreators, userActionCreators);

export class CheckoutStepBuylistConfirmInput extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputConfirm: false,

      errorConfirm: '',

      placeOrderError: null,
      placeOrderPending: false,
    };

    this.shippingAddress = this.getShippingAddress();
    this.paymentMethod = this.getPaymentMethod();
  }

  componentDidMount() {

    // Get store data
    this.props.userFetchStore()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });
  }

  getShippingAddress() {
    const stepData = getCheckoutStepData(CHECKOUT_STEP_BUYLIST_ADDRESS, this.props.checkout.stepData);
    if(stepData && stepData.data && stepData.data.shippingAddress) {
      return stepData.data.shippingAddress;
    }
    return null;
  }

  getPaymentMethod() {
    const stepData = getCheckoutStepData(CHECKOUT_STEP_BUYLIST_SELECT_PAYMENT_METHOD, this.props.checkout.stepData);
    if(stepData && stepData.data && stepData.data.paymentMethod) {
      return stepData.data.paymentMethod;
    }
    return null;
  }

  validateAll() {
    const errorObj = {
      errorConfirm: this.state.inputConfirm === false ? ERROR_CHECKOUT_BUYLIST_CONFIRMATION : '',
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async handleSubmit(evt) {

    evt.preventDefault();
    if(this.validateAll()) {

      this.setState({ 
        placeOrderPending: true, 
        placeOrderError: null,
      });

      const orderData = {
        shipping_address_uuid: this.shippingAddress.publicUuid,
        payment_method_uuid: this.paymentMethod.publicUuid,
        cart_uuid: this.props.cart.currentBuylistCart.publicUuid,
        status: Order.initialBuylistStatus,
      };

      const orderResp = await this.props.checkoutPlaceBuylistOrder(orderData)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            placeOrderPending: false,
            placeOrderError: formatServerError(errResp),
          });
        });
      if(!orderResp || !orderResp.public_uuid) { return null; }

      this.props.checkoutClearStepData();

      history.push({
        pathname: URL_SUCCESS,
        state: { 
          orderUuid: orderResp.public_uuid, 
          isBuylist: true,
        },
      });
    }
  }

  changeConfirmation() {
    this.setState({ inputConfirm: !this.state.inputConfirm }, () => {
      if(this.state.inputConfirm && this.state.errorConfirm) {
        this.setState({ errorConfirm: '' });
      }
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'CheckoutStepBuylistConfirmInput CheckoutStepComponent'}>
      <div className='confirmLiner'>
        <form className={'confirmBuylistForm checkoutStepInputForm singleColumn'} onSubmit={this.handleSubmit.bind(this)}>
          <div className='checkoutFormSubheader'>{t(tx.TX_CHECKOUT_BUYLIST_NEXT_STEPS)}</div>
          <div className='stepsWrapper'>
            <div className='stepElement'>
              <div className='stepHeader'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_SEND, { storeName: process.env.REACT_APP_WEBSITE_NAME })}</div>
              <div className='stepUl'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_SEND_TO)}</div>
              <div className='stepAddress'>
                <div className='addressLine'>{this.props.user.store.name}</div>
                {this.props.user.store.address.format().map((line, i) => {
                  return <div key={i} className='addressLine'>{line}</div>;
                })}
              </div>
              <div className='stepUl'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_SEND_PACK)}</div>
              <div className='stepUl'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_SEND_DELIVER)}</div>
            </div>
            <div className='stepElement'>
              <div className='stepHeader'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_GRADE, { storeName: process.env.REACT_APP_WEBSITE_NAME })}</div>
              <div className='stepUl'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_GRADE_INDIVIDUALLY)}</div>
              <div className='stepUl'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_GRADE_CHANGE_PRICE)}</div>
            </div>
            <div className='stepElement' style={{ display: 'none' }}>
              <div className='stepHeader'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_APPROVE)}</div>
              <div className='stepUl'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_APPROVE_INDIVIDUALLY)}</div>
            </div>
            <div className='stepElement'>
              <div className='stepHeader'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_PAY)}</div>
              <div className='stepUl' style={{ display: 'none' }}>{t(tx.TX_CHECKOUT_BUYLIST_STEP_PAY_PAYMENT)}</div>
              <div className='stepUl'>{t(tx.TX_CHECKOUT_BUYLIST_STEP_PAY_RETURN)}</div>
            </div>
          </div>
          <div className={this.state.placeOrderError || this.state.errorConfirm ? 'checkoutFormServerError present' : 'checkoutFormServerError'}>
            <div className='FlexCenter'>{t(this.state.placeOrderError || this.state.errorConfirm || tx.TX_null)}</div>
          </div>
          <div className={'checkoutFormConfirmLine checkoutFormInput fullWidth'}>
            <div className='checkboxInputWrapper'>
              <div className='checkboxInput'>
                <Checkbox 
                  name={t(tx.TX_CHECKOUT_BUYLIST_CONFIRMATION_AGREEMENT)}
                  id={'confirmBuylistInput'}
                  value={'confirm-buylist'}
                  checked={this.state.inputConfirm}
                  error={this.state.errorConfirm}
                  onChange={this.changeConfirmation.bind(this)} />
              </div>
              <label 
                htmlFor={'confirmBuylistInput'}
                className={`checkboxLabel${this.state.errorConfirm ? ' error' : ''}`}>
                {t(tx.TX_CHECKOUT_BUYLIST_CONFIRMATION_AGREEMENT)}
              </label>
            </div>
          </div>
          <div className={'checkoutFormInput checkoutFormAction'}>
            <button 
              className='checkoutFormButton'
              disabled={this.state.placeOrderPending}>
              {t(tx.TX_CHECKOUT_PLACE_ORDER)}
            </button>
          </div>
        </form>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    checkout: state.checkout,
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepBuylistConfirmInput));