import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_AGGREGATION_KEY,
  API_KEY_AGGREGATION_VALUES,
  API_KEY_BUY_PRICE_MAX,
  API_KEY_BUY_PRICE_MIN,
  API_KEY_FOREIGN_MODEL,
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_OMIT_PROMOS_MAGIC,
  API_KEY_OMIT_TAGS,
  API_KEY_PRODUCT_LINE,
  API_KEY_SORT,
  API_KEY_TOTAL_QTY_MAX,
  API_RESP_KEY_AGGREGATIONS,
  API_RESP_KEY_DEFAULT,
} from '../../../../constants/api';
import * as tx from '../../../../constants/strings';
import { URL_SELL_GENERAL } from '../../../../constants/urls';

import BlockElementHorizontalProduct from '../BlockElementHorizontalProduct';
import { LoadingIcon } from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockTypeProductsVertical.module.scss';

import * as productActionCreators from '../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class BlockFeaturedBuylistVertical extends Component {

  constructor(props) {
    super(props);

    this.REQUEST_COUNT = 10;

    this.state = {
      loading: true,

      targetProducts: this.REQUEST_COUNT,

      productData: null,
    };

    this.bodyElement = React.createRef();
    this.scrollElement = React.createRef();

    // Controllers
    this.controller = null;
  }

  componentDidMount() {
    this.fetchBuylistProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.bodyElement && this.bodyElement.current && this.scrollElement && this.scrollElement.current) {
      if(this.scrollElement.current.getBoundingClientRect().height > this.bodyElement.current.getBoundingClientRect().height) {
        this.setState({ targetProducts: this.state.targetProducts - 1 });
      }
    }
  }

  async fetchBuylistProducts() {

    if(!this.props || !this.props.block) {
      return null;
    }
    
    // Cancel any existing requests
    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({ 
      loading: true, 
    });

    // Use these for an inventory search response
    const reqConfigData = {};
    const reqConfigKeys = Object.keys(this.props.block.config);

    if(reqConfigKeys.includes('product_line_permalink')) {
      reqConfigData[API_KEY_PRODUCT_LINE] = this.props.block.config.product_line_permalink;
    }

    if(reqConfigKeys.includes('foreign_model')) {
      reqConfigData[API_KEY_FOREIGN_MODEL] = this.props.block.config.foreign_model;
    }

    if(reqConfigKeys.includes('aggregation_key')) {
      reqConfigData[API_KEY_AGGREGATION_KEY] = this.props.block.config.aggregation_key;
    }

    if(reqConfigKeys.includes('aggregation_value')) {
      reqConfigData[API_KEY_AGGREGATION_VALUES] = this.props.block.config.aggregation_value;
    }

    if(reqConfigKeys.includes('buy_price_min')) {
      reqConfigData[API_KEY_BUY_PRICE_MIN] = this.props.block.config.buy_price_min;
    }

    if(reqConfigKeys.includes('buy_price_max')) {
      reqConfigData[API_KEY_BUY_PRICE_MAX] = this.props.block.config.buy_price_max;
    }

    if(reqConfigKeys.includes('omit_tags')) {
      reqConfigData[API_KEY_OMIT_TAGS] = this.props.block.config.omit_tags;
    }

    if(reqConfigKeys.includes('total_qty_max')) {
      reqConfigData[API_KEY_TOTAL_QTY_MAX] = this.props.block.config.total_qty_max;
    }
    
    if(reqConfigKeys.includes('omit_promos_magic')) {
      reqConfigData[API_KEY_OMIT_PROMOS_MAGIC] = this.props.block.config.omit_promos_magic;
    }

    const paginationData = {
      [API_KEY_LIMIT]: this.REQUEST_COUNT,
      [API_KEY_OFFSET]: 0,
      [API_KEY_SORT]: '-buy_price',
    }

    const reqGetParams = Object.assign({}, reqConfigData, paginationData);
    const productResp = await this.props.productSellFeaturedBuylist(reqGetParams, controller.signal)
      .catch((errResp) => {
        this.setState({
          productData: [],
          loading: false, 
        });
      });

    if(!productResp) {
      return null;
    }

    // Product lists
    const skusList = [];
    const productList = [];

    // If aggregated, add those first
    if(this.props.block.config.aggregation_key && productResp[API_RESP_KEY_AGGREGATIONS]) {

      // If a particular aggregation doesn't hit it's limit, it creates a debt that is filled from the next aggregation, and so on
      let aggregation_debt = 0;

      const aggregation_limit = this.props.block.config.aggregation_count || 2;
      const aggregation_values = this.props.block.config.aggregation_value.split(',') || [];

      for(const val of aggregation_values) {
        if(productResp[API_RESP_KEY_AGGREGATIONS][val]) {

          let additions_count = 0;
          let section_limit = aggregation_limit - aggregation_debt;

          for(const product of productResp[API_RESP_KEY_AGGREGATIONS][val].data) {
            if(!skusList.includes(product.sku) && additions_count < section_limit) {
              productList.push(product);
              skusList.push(product.sku)
              additions_count += 1;
            }
          }

          aggregation_debt += additions_count - aggregation_limit;
        }
      }
    }

    // Add default responses
    if(productResp[API_RESP_KEY_DEFAULT]) {
      for(const product of productResp[API_RESP_KEY_DEFAULT].data) {
        if(!skusList.includes(product.sku)) {
          productList.push(product);
          skusList.push(product.sku)
        }
      }
    }

    this.setState({
      productData: productList,
      loading: false, 
    });
  }

  render() {

    let productsRendered = 0;
    const {t} = this.props;

    return <div className={`BlockFeaturedBuylistVertical ${styles.BlockTypeProductsVertical}`}>
      <div className={styles.btpvLiner}>
        <div className={this.props.blockStyles.blockTitleUnderline}>
          <div className='FlexCenter'>{this.props.block.isDefaultConfig('title') ? t(this.props.block.getConfigAttr('title')) : this.props.block.getConfigAttr('title')}</div>
        </div>

        <div className={this.props.blockStyles.blockBodyVertical} ref={this.bodyElement}>
          {this.state.loading ?
            <div className={this.props.blockStyles.verticalBodyIcon}>
              <LoadingIcon />
            </div> :
            <div className={this.props.blockStyles.blockBodyVerticalLiner} ref={this.scrollElement}>
              {this.state.productData && this.state.productData.length > 0 ?
                <>
                  {this.state.productData.map((prod, i) => {

                    if(productsRendered >= this.state.targetProducts) {
                      return null;
                    }
                    productsRendered++;

                    return <div key={i} className={styles.elementWrapper}>
                      <BlockElementHorizontalProduct
                        isBuylist={true}
                        product={prod} />
                    </div>
                  })}
                </> :
                <div className={styles.noResults}>
                  {t(tx.TX_GALLERY_NO_RESULTS)}
                </div>
              }
            </div>
          }
        </div>

        <div className={this.props.blockStyles.blockFooter}>
          <Link to={URL_SELL_GENERAL} className={this.props.blockStyles.blockAction}>{t(tx.TX_VIEW)}</Link>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockFeaturedBuylistVertical));