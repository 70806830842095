import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { WebsiteNotice } from '../../../models/notices';

import { 
  isFormValid, 
  getGenericStringError,
  getUrlError,
  getRgbError,
} from '../../../utils/form-validation';
import { formatServerError } from '../../../utils/formatting';
import { rgbToHex } from '../../../utils/general';

import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';
import Toggle from '../../Input/Toggle';

import * as storeActionCreators from '../../../actions/store';
let allActionCreators = Object.assign({}, storeActionCreators);

export class NoticeEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      inputIsEnabled: this.props.notice && this.props.notice.isEnabled ? this.props.notice.isEnabled : false,
      inputCopy: this.props.notice && this.props.notice.copy ? this.props.notice.copy : '',
      inputHref: this.props.notice && this.props.notice.href ? this.props.notice.href : '',
      inputTextColor: this.props.notice && this.props.notice.textColor ? this.props.notice.textColor : '',
      inputBackgroundColor: this.props.notice && this.props.notice.backgroundColor ? this.props.notice.backgroundColor : '',

      errorCopy: '',
      errorHref: '',
      errorTextColor: '', 
      errorBackgroundColor: '',

      requestPending: false,
      requestError: null,

      resetConfirmOpen: false,
      savePromptOpen: false, 

      defaultBackgroundColor: null,
      defaultTextColor: null,
    };

    this.noticeCharacterLimit = 128;

    this.primaryColorRef = React.createRef();
  }

  componentDidMount() {
    this.setDefaultTextColor();
    this.setDefaultBackgroundColor();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : '');
  }

  changeIsEnabled(evt) {
    this.setState({
      inputIsEnabled: !this.state.inputIsEnabled, 
    });
    this.makeDirty();
  }

  changeCopy(evt) {
    this.setState({
      inputCopy: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorCopy) {
        this.validateCopy();
      }
    });
  }

  validateCopy() {
    this.setState({ errorCopy: getGenericStringError(this.state.inputCopy, true, 0, 128) });
  }

  getNoticeLength() {
    return this.state.inputCopy ? this.state.inputCopy.length : 0;
  }

  changeHref(evt) {
    this.setState({
      inputHref: evt.target.value.toLowerCase(),
    }, () => {
      this.makeDirty();
      if(this.state.errorHref) {
        this.validateHref();
      }
    });
  }

  getFullUrl() {
    return `${window.location.origin}/${this.state.inputHref}`;
  }

  validateHref() {
    this.setState({ errorHref: getUrlError(this.getFullUrl(), true) });
  }

  changeTextColor(evt) {
    this.setState({
      inputTextColor: evt.target.value.replace(/[^0-9a-f]/gi, '').toUpperCase(),
    }, () => {
      this.makeDirty();
      if(this.state.errorTextColor) {
        this.validateTextColor();
      }
    });
  }

  validateTextColor() {
    this.setState({ errorTextColor: getRgbError(this.state.inputTextColor, true) });
  }

  changeBackgroundColor(evt) {
    this.setState({
      inputBackgroundColor: evt.target.value.replace(/[^0-9a-f]/gi, '').toUpperCase(),
    }, () => {
      this.makeDirty();
      if(this.state.errorBackgroundColor) {
        this.validateBackgroundColor();
      }
    });
  }

  validateBackgroundColor() {
    this.setState({ errorBackgroundColor: getRgbError(this.state.inputBackgroundColor, true) });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const noticeModel = new WebsiteNotice({
        position: this.props.noticeConfig.key,
        copy: this.state.inputCopy,
        href: this.state.inputHref,
        textColor: this.state.inputTextColor, 
        backgroundColor: this.state.inputBackgroundColor, 
        isEnabled: this.state.inputIsEnabled,
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      if(this.props.notice && this.props.notice.publicUuid) {
        await this.props.storeUpdateNotice(noticeModel.getApiData(), this.props.notice.publicUuid)
                .catch((errResp) => {
              
                  console.error(errResp);
                  this.setState({ 
                    requestPending: false,
                    requestError: formatServerError(errResp),
                  });

                  if(this.props.remoteSave) {
                    this.props.finishRemote(this.props.noticeConfig.key, true);
                  }
                });
      } else {
        await this.props.storeCreateNotice(noticeModel.getApiData())
                .catch((errResp) => {
              
                  console.error(errResp);
                  this.setState({ 
                    requestPending: false,
                    requestError: formatServerError(errResp),
                  });

                  if(this.props.remoteSave) {
                    this.props.finishRemote(this.props.noticeConfig.key, true);
                  }
                });
      }

      // Re-fetch notices to immediately display if active
      await this.props.storeFetchNotices()
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }
        });

      this.props.storeNoticesResetDismissals();

      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        if(this.props.remoteSave) {
          this.props.finishRemote(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : '');
        } else {
          this.props.makeClean(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : '');
        }
        this.props.toggleEdit(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : '');
      });
      
    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : '', true);
    }
  }

  validateAll() {
    const errorObj = {
      errorCopy: getGenericStringError(this.state.inputCopy, true, 0, 128),
      errorHref: getUrlError(this.getFullUrl(), true),
      errorTextColor: getRgbError(this.state.inputTextColor, true),
      errorBackgroundColor: getRgbError(this.state.inputBackgroundColor, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : '');
    }
  }

  setDefaultTextColor() {
    try {

      const styleObj = this.primaryColorRef && this.primaryColorRef.current ? window.getComputedStyle(this.primaryColorRef.current) : null;
      const textColor = styleObj ? rgbToHex(styleObj.getPropertyValue('color')) : null;

      if(this.state.defaultTextColor !== textColor) {
        this.setState({ defaultTextColor: textColor });
      }
    } catch(err) {
      console.error(err);
    }
    return null;
  }

  setDefaultBackgroundColor() {
    try {
      const styleObj = this.primaryColorRef && this.primaryColorRef.current ? window.getComputedStyle(this.primaryColorRef.current) : null;
      const backgroundColor = styleObj ? rgbToHex(styleObj.getPropertyValue('background-color')) : null;

      if(this.state.defaultBackgroundColor !== backgroundColor) {
        this.setState({ defaultBackgroundColor: backgroundColor });
      }
    } catch(err) {
      console.error(err);
    }
    return null;
  }

  render() {

    const {t} = this.props;

    return <div className={'NoticeEdit'}>
      <div ref={this.primaryColorRef} className={'invisibleSwatch'} />
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'couponDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(this.props.noticeConfig && this.props.noticeConfig.label ? this.props.noticeConfig.label : tx.TX_null)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputIsEnabled}
                    onToggle={this.changeIsEnabled.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_NOTICE)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorCopy ? 'InputError' : ''}
                  value={this.state.inputCopy}
                  onChange={this.changeCopy.bind(this)}
                  onBlur={this.validateCopy.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_NOTICE)}
                  maxLength={128} />
                {this.state.errorCopy ?
                  <div className={'adminError FieldError'}>{t(this.state.errorCopy)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  <div className='productCharacterCount'>
                    <span className='countNum'>{(this.noticeCharacterLimit - this.getNoticeLength()).toLocaleString()}</span>
                    <span className='countSlash'>/</span>
                    <span className='countNum'>{this.noticeCharacterLimit.toLocaleString()}</span>
                    <span className='countCopy'>{t(tx.TX_CHARACTERS_REMAINING)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_MARKETING_NOTICES_LINK_URL)}</div>
              <div className='adminInputWrapper'>
                <div className='urlWrapper'>
                  <input
                    type='text'
                    className={this.state.errorHref ? 'InputError urlInput' : 'urlInput'}
                    value={this.state.inputHref}
                    onChange={this.changeHref.bind(this)}
                    onBlur={this.validateHref.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_LINK_URL)}
                    maxLength={128} />
                  <div className='urlOverlay'>/</div>
                </div>
                {this.state.errorHref ?
                  <div className={'adminError FieldError'}>{t(this.state.errorHref)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  {this.state.inputHref ?
                    <>
                      <span className='plUrlLabel'>{t(tx.TX_MARKETING_NOTICES_LINK_URL)}:</span>
                      <span className='plUrlValue'>{this.getFullUrl()}</span>
                    </> :
                    <>
                      <span className='plUrlValue'>{t(tx.TX_MARKETING_NOTICES_NO_LINK_COPY)}</span>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_MARKETING_NOTICES_TEXT_COLOR)}</div>
                <div className='rgbWrapper'>
                  <input
                    type='text'
                    className={this.state.errorTextColor ? 'InputError rgbInput' : 'rgbInput'}
                    value={this.state.inputTextColor}
                    onChange={this.changeTextColor.bind(this)}
                    onBlur={this.validateTextColor.bind(this)}
                    placeholder={this.state.defaultTextColor || t(tx.TX_PLACEHOLDER_TEXT_COLOR)}
                    maxLength={6} />
                  <div className='rgbOverlay'>#</div>
                  <div 
                    className={this.state.errorTextColor ? 'rgbSwatch error' : 'rgbSwatch'} 
                    style={getRgbError(this.state.inputTextColor) ? { background: `#${this.state.defaultTextColor || 'FFFFFF'}` } : { background: `#${this.state.inputTextColor}` }}>
                    <input
                      type='color'
                      className={'rgbInputCover'}
                      value={getRgbError(this.state.inputTextColor) ? `#${this.state.defaultTextColor || 'FFFFFF'}` : `#${this.state.inputTextColor}`}
                      onChange={this.changeTextColor.bind(this)} />
                  </div>
                </div>
                {this.state.errorTextColor ?
                  <div className={'adminError FieldError'}>{t(this.state.errorTextColor)}</div> :
                  null
                }
              </div>
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_MARKETING_NOTICES_BACKGROUND_COLOR)}</div>
                <div className='rgbWrapper'>
                  <input
                    type='text'
                    className={this.state.errorBackgroundColor ? 'InputError rgbInput' : 'rgbInput'}
                    value={this.state.inputBackgroundColor}
                    onChange={this.changeBackgroundColor.bind(this)}
                    onBlur={this.validateBackgroundColor.bind(this)}
                    placeholder={this.state.defaultBackgroundColor || t(tx.TX_PLACEHOLDER_BACKGROUND_COLOR)}
                    maxLength={6} />
                  <div className='rgbOverlay'>#</div>
                  <div 
                    className={this.state.errorBackgroundColor ? 'rgbSwatch error' : 'rgbSwatch'} 
                    style={getRgbError(this.state.inputBackgroundColor) ? { background: `#${this.state.defaultBackgroundColor || 'FFFFFF'}` } : { background: `#${this.state.inputBackgroundColor}` }}>
                    <input
                      type='color'
                      className={'rgbInputCover'}
                      value={getRgbError(this.state.inputBackgroundColor) ? `#${this.state.defaultBackgroundColor || 'FFFFFF'}` : `#${this.state.inputBackgroundColor}`}
                      onChange={this.changeBackgroundColor.bind(this)} />
                  </div>
                </div>
                {this.state.errorBackgroundColor ?
                  <div className={'adminError FieldError'}>{t(this.state.errorBackgroundColor)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={() => this.props.toggleEdit(this.props.noticeConfig && this.props.noticeConfig.key ? this.props.noticeConfig.key : '')} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(NoticeEdit));