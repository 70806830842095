import 'whatwg-fetch';

import * as x from '../constants/actions';
import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

import { 
	BuylistCart,
	Cart,
} from '../models/carts';

import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/




export function cartPendingFetchRequest(payload) {
	return {
    type: x.ACTION_CART_FETCH_PENDING,
    payload
  };
}

export function cartPendingCreateRequest(payload) {
	return {
    type: x.ACTION_CART_CREATE_PENDING,
    payload
  };
}

export function cartPendingCreateBuylistRequest(payload) {
	return {
    type: x.ACTION_CART_BUYLIST_CREATE_PENDING,
    payload
  };
}

export function cartToggleMinicartSlider(payload) {
	return {
    type: x.ACTION_CART_TOGGLE_MINICART_SLIDER,
    payload
  };
}

export function cartSetCart(payload) {
	return {
    type: x.ACTION_CART_SET_CART,
    payload
  };
}

export function cartSetBuylistCart(payload) {
	return {
    type: x.ACTION_CART_BUYLIST_SET_CART,
    payload
  };
}

export function setBuylistMode(payload) {
	return {
    type: x.ACTION_CART_SET_BUYLIST_MODE,
    payload
  };
}



/******************************
*******  Asynchronous  ********
******************************/


export function cartFetchCart(signal = null) {
	return async (dispatch, getState) => {

		if(getState().cart.pendingGetCart) {
			return Promise.reject(null);
		}
		dispatch(cartPendingFetchRequest(true));

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_CART_FETCH;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(cartPendingFetchRequest(false));
	    if(isOk(resp.status)) {
	    	dispatch(cartSetCart(new Cart(resp.data.cart)));
	    	dispatch(cartSetBuylistCart(new BuylistCart(resp.data.buylist)));
	    	return Promise.resolve();
	    } else {
	    	if(resp.status === 404) {
	    		return Promise.resolve(new Cart());
	    	} else {
	    		return Promise.reject(resp.data);
	    	}
	    }
	  });
	}
}

// Should not be called directly, only called when adding to cart if cart is null
export async function cartCreateCart(dispatch, getState, signal = null) {
	return async () => {

		if(getState().cart.pendingCreateCart) {
			return Promise.reject(null);
		}
		dispatch(cartPendingCreateRequest(true));

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const cartCreateData = {};

		const url = a.API_TITAN_API + n.ENDPOINT_CART_CREATE;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(cartCreateData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(cartPendingCreateRequest(false));
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

// Should not be called directly, only called when adding to cart if cart is null
export async function cartCreateBuylistCart(dispatch, getState, signal = null) {
	return async () => {

		if(getState().cart.pendingCreateBuylistCart) {
			return Promise.reject(null);
		}
		dispatch(cartPendingCreateBuylistRequest(true));

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const cartCreateData = {};

		const url = a.API_TITAN_API + n.ENDPOINT_CART_BUYLIST_CREATE;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(cartCreateData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(cartPendingCreateBuylistRequest(false));
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartAddProduct(addToCartData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		let cartObj = getState().cart.currentCart;
		if(cartObj === null || !cartObj.publicUuid) {
			const createCart = await cartCreateCart(dispatch, getState, signal);
			cartObj = new Cart(await createCart());
		}

		if(!cartObj) {
			return Promise.reject(null);
		}

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_ADD, { uuid: cartObj.publicUuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addToCartData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartAddBuylistProduct(addToCartData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		let buylistCartObj = getState().cart.currentBuylistCart;
		if(buylistCartObj === null || !buylistCartObj.publicUuid) {
			const createCart = await cartCreateBuylistCart(dispatch, getState, signal);
			buylistCartObj = new BuylistCart(await createCart());
		}

		if(!buylistCartObj) {
			return Promise.reject(null);
		}

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_BUYLIST_ADD, { uuid: buylistCartObj.publicUuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addToCartData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartItemUpdate(cartUuid, itemId, itemDataObj) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_ITEM_UPDATE, { uuid: cartUuid, itemId: itemId });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(itemDataObj),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartBuylistItemUpdate(cartUuid, itemId, itemDataObj) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_BUYLIST_ITEM_UPDATE, { uuid: cartUuid, itemId: itemId });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(itemDataObj),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartItemDelete(cartUuid, itemId) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_ITEM_DELETE, { uuid: cartUuid, itemId: itemId });
		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartBuylistItemDelete(cartUuid, itemId) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_BUYLIST_ITEM_DELETE, { uuid: cartUuid, itemId: itemId });
		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartClear(cartUuid) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_CLEAR, { uuid: cartUuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify({}),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartBuylistClear(cartUuid) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_BUYLIST_CLEAR, { uuid: cartUuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify({}),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function cartApplyCoupon(couponCode, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		let cartObj = getState().cart.currentCart;
		if(cartObj === null || !cartObj.publicUuid) {
			return Promise.reject(null);
		}

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_COUPON_APPLY, { 
			uuid: cartObj.publicUuid, 
			code: couponCode,
		});
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify({}),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function cartRemoveCoupon(couponCode, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		let cartObj = getState().cart.currentCart;
		if(cartObj === null || !cartObj.publicUuid) {
			return Promise.reject(null);
		}

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_COUPON_REMOVE, { 
			uuid: cartObj.publicUuid, 
			code: couponCode,
		});
		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify({}),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function cartAdminFetchPage(getParams = {}, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + n.ENDPOINT_CART_ADMIN_FETCH_LIST + getUrlParams(getParams);
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        const respModels = [];
        for(const c of resp.data.data) {
          respModels.push(getParams[a.API_KEY_IS_BUYLIST] ? new BuylistCart(c) : new Cart(c));
        }
        return Promise.resolve(Object.assign({}, resp.data, { data: respModels }));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function cartAdminFetchSingle(cartUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_ADMIN_FETCH, { 
			publicUuid: cartUuid, 
		});
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Cart(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function cartBuylistAdminFetchSingle(cartUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_BUYLIST_ADMIN_FETCH, { 
			publicUuid: cartUuid, 
		});
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new BuylistCart(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function cartAdminFetchByUser(userUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_ADMIN_FETCH_BY_USER, { 
			publicUuid: userUuid, 
		});
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Cart(resp.data));
      } else if(resp.status === 404) {
      	return Promise.resolve(null);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function cartBuylistAdminFetchByUser(userUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_BUYLIST_ADMIN_FETCH_BY_USER, { 
			publicUuid: userUuid, 
		});
    return fetch(url, {
      method: 'get',
      headers: authReq(a.API_HEADERS),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new BuylistCart(resp.data));
      } else if(resp.status === 404) {
      	return Promise.resolve(null);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function cartAdminUpdate(cartData, cartUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_ADMIN_UPDATE, { 
			publicUuid: cartUuid, 
		});
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(cartData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new Cart(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function cartBuylistAdminUpdate(cartData, cartUuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_CART_BUYLIST_ADMIN_UPDATE, { 
			publicUuid: cartUuid, 
		});
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(cartData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(new BuylistCart(resp.data));
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}















