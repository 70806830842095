import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const allActionCreators = Object.assign({});

export class ReturnPolicyOasisChile extends Component {

  constructor(props) {
    super(props);

    this.storeName = this.props.user.store.name || process.env.REACT_APP_WEBSITE_NAME;
  }

  render() {
    return <div className={'ReturnPolicyOasisChile legalBody'}>
      <div className='legalSection'>
        <div className='sectionTitle'>Política de Devolución</div>
        <div className='sectionP'>Puedes regresar artículos nuevos o sellados en su empaque original, vendidos por {this.storeName} dentro de los primeros diez días después de la compra, por un reembolso total siempre y cuando conserves tu boleta.</div>
        <div className='sectionP'>{this.storeName} se reserva el derecho a no aceptar el retorno de artículos si estos presentan daños en su empaque.</div>
        <div className='sectionP'>{this.storeName} correrá con cualquier costo de envío de la devolución, si es resultado de un error nuestro (usted recibió un artículo incorrecto, defectuoso, etc.).</div>
        <div className='sectionP'>NO SE EFECTUARÁN CAMBIOS NI DEVOLUCIONES SIN BOLETA.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Reembolsos</div>
        <div className='sectionP'>Los reembolsos son efectuados después de que recibimos y procesamos el pedido que deseas devolver. Los reembolsos pueden tardar hasta cuatro semanas, una vez se haya efectuado el envío del producto.</div>
        <div className={'sectionP listHeader'}>Este período de tiempo incluye:</div>
        <ul className='sectionUL'>
          <li>El tiempo en el que nosotros recibimos el paquete (5 a 10 días hábiles).</li>
          <li>El tiempo que nos toma procesar el pedido una vez en nuestro poder (3 a 5 días hábiles).</li>
          <li>El tiempo que le toma a su banco o a Transbank procesar nuestra solicitud de reembolso en caso de pago con Tarjeta.</li>
        </ul>
        <div className='sectionP'>Te notificaremos de tu reembolso vía email una vez que hayamos recibido y procesado el artículo devuelto.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionP'>En caso de que un pedido tenga cartas faltantes, te reembolsaremos automáticamente en forma de crédito de tienda el valor pagado de la o las cartas faltantes, si deseas otro tipo de reembolso, puedes contactarnos a <a className='inlineLink' href='mailto:soporte@mtgoasis.cl'>soporte@mtgoasis.cl</a> para procesarlo de manera particular.</div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ReturnPolicyOasisChile));