import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import { URL_ADMIN_ORDERS } from '../../constants/urls';

import { formatServerError } from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';

import OrderControlModal from './blocks/order-modal/OrderControlModal';
import OrderDetailsView from './blocks/OrderDetailsView';
import OrderMessagesView from './blocks/OrderMessagesView';
import OrderModalBuylistPaymentCreate from './blocks/order-modal/OrderModalBuylistPaymentCreate';
import OrderModalCancelOrder from './blocks/order-modal/OrderModalCancelOrder';
import OrderModalGradeItems from './blocks/order-modal/OrderModalGradeItems';
import OrderModalMessageCreate from './blocks/order-modal/OrderModalMessageCreate';
import OrderModalPaymentCreate from './blocks/order-modal/OrderModalPaymentCreate';
import OrderModalPickupComplete from './blocks/order-modal/OrderModalPickupComplete';
import OrderModalPickupCreate from './blocks/order-modal/OrderModalPickupCreate';
import OrderModalReceiveGoods from './blocks/order-modal/OrderModalReceiveGoods';
import OrderModalRefundCreate from './blocks/order-modal/OrderModalRefundCreate';
import OrderModalShipmentCancel from './blocks/order-modal/OrderModalShipmentCancel';
import OrderModalShipmentCreate from './blocks/order-modal/OrderModalShipmentCreate';
import OrderPaymentsView from './blocks/OrderPaymentsView';
import OrderPickupsView from './blocks/OrderPickupsView';
import OrderShipmentsView from './blocks/OrderShipmentsView';

import AdminTitle from '../Admin/AdminTitle';
import SavePrompt from '../Popups/SavePrompt';

import './style/_vieworder.scss';

import * as commonActionCreators from '../../actions/common';
import * as orderActionCreators from '../../actions/order';
let allActionCreators = Object.assign({}, commonActionCreators, orderActionCreators);

export class ViewOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirtyShipments: false,
      savePromptOpen: false,
      lastBlock: '',

      shipmentsExpanded: true,
      pickupsExpanded: true,
      messagesExpanded: true,

      editModalComponent: null,
      editModalCustom: false,
      editModalOpen: false,
      editModalProps: null,
      editModalTitle: tx.TX_null,

      requestPending: true,
      requestPendingSilently: true,
      requestError: null,
      responseObject: null,

      noticeOpen: false,
      cursorX: null,
      cursorY: null,
    };

    this.destination = props.location && props.location.state && props.location.state.from ? props.location.state.from : null;

    // Controllers
    this.controller = null;

    // Listeners
    this.checkPosThrottled = _.throttle(this.getMousePos.bind(this), 50);
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyShipments === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    window.addEventListener('mousemove', this.checkPosThrottled, false);

    this.fetchOrder();
  }

  componentWillUnmount() {

    this.unblock();

    window.removeEventListener('mousemove', this.checkPosThrottled, false);

    if(this.controller) {
      this.controller.abort();
    }
  }

  getMousePos(evt) {
    if(!evt || !evt.clientX || !evt.clientY) { return null; }
    this.setState({
      cursorX: evt.clientX,
      cursorY: evt.clientY,
    });
  }

  getNoticeStyle() {
    const styleObj = {
      display: this.state.noticeOpen ? 'block' : 'none',
    };
    if(this.state.noticeOpen && this.state.cursorX && this.state.cursorY) {
      styleObj['right'] = `${window.innerWidth - this.state.cursorX - 3}px`;
      styleObj['top'] = `${this.state.cursorY + 15}px`;
    }
    return styleObj;
  }

  async fetchOrder(fetchSilent = false) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    if(!fetchSilent) {
      this.setState({
        requestPending: true,
        requestError: null,
        responseObject: null,
      });
    }

    this.setState({ requestPendingSilently: true });

    // Get order details
    if(this.props.match.params.orderUUID) {
      this.props.ordersAdminFetchOrder(this.props.match.params.orderUUID, controller.signal)
      .then((resp) => {

        this.setState({
          requestPending: false,
          requestPendingSilently: false,
          requestError: null,
          responseObject: resp,
        });
      })
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestPendingSilently: false,
          requestError: formatServerError(errResp),
          responseObject: null,
        });
      });
    }
  }

  makeShipmentsDirty() {
    if(this.state.isDirtyShipments === false) {
      this.setState({ isDirtyShipments: true });
    }
  }

  toggleShipmentsExpanded() {
    this.setState({ shipmentsExpanded: !this.state.shipmentsExpanded });
  }

  togglePickupsExpanded() {
    this.setState({ pickupsExpanded: !this.state.pickupsExpanded });
  }
  
  toggleMessagesExpanded() {
    this.setState({ messagesExpanded: !this.state.messagesExpanded });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  getPageTitle() {
    if(this.state.responseObject && this.state.responseObject.displayNumber) {
      return tx.TX_ORDER_ORDER_NUMBER_DYNAMIC;
    }
    return tx.TX_ORDER_VIEW_ORDER;
  }

  getPageTitleData() {
    if(this.state.responseObject && this.state.responseObject.displayNumber) {
      return {
        orderNum: this.state.responseObject.displayNumber,
      };
    }
    return null;
  }

  getStatusMessageObj() {
    if(this.state.responseObject && this.state.responseObject.status) {
      return this.state.responseObject.status.getStatusMessage(this.state.responseObject, this.getLanguage());
    }
    return null;
  }

  toggleNotice(val) {

    if(!this.state.responseObject) { return null };
    
    const toggleValue = val === true || val === false ? val : !this.state.noticeOpen;
    const statusObj = this.getStatusMessageObj();

    if(toggleValue && !statusObj.value) {
      return null;
    }

    this.setState({
      noticeOpen: toggleValue,
    });
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  openPickupsModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalPickupCreate,
      editModalTitle: tx.TX_ORDER_CREATE_PICKUP,
    });
  }

  openMessagesModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalMessageCreate,
      editModalTitle: tx.TX_ORDER_SEND_MESSAGE,
    });
  }

  openPickupCompleteModal(pickup = null) {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalPickupComplete,
      editModalTitle: tx.TX_ORDER_COMPLETE_PICKUP,
      editModalProps: {
        pickup: pickup,
      },
    });
  }

  openCreatePaymentModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalPaymentCreate,
      editModalTitle: tx.TX_ORDER_TAKE_PAYMENT,
    });
  }

  openCreateBuylistPaymentModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalBuylistPaymentCreate,
      editModalTitle: tx.TX_MAKE_PAYMENT,
    });
  }

  openCreateShipmentModal() {

    let customComponent = null;
    if(this.state.responseObject && this.state.responseObject.shippingMethod && this.state.responseObject.shippingMethod.integration && this.state.responseObject.shippingMethod.integration.createShipmentComponent) {
      customComponent = this.state.responseObject.shippingMethod.integration.createShipmentComponent;
    }

    this.setState({
      editModalOpen: true, 
      editModalCustom: !!customComponent,
      editModalComponent: customComponent || OrderModalShipmentCreate,
      editModalTitle: tx.TX_ORDER_CREATE_SHIPMENT,
    });
  }

  openCancelShipmentModal(shipment = null) {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalShipmentCancel,
      editModalTitle: tx.TX_ORDER_SHIPMENT_CANCEL,
      editModalProps: {
        shipment: shipment,
      },
    });
  }

  openRefundModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalRefundCreate,
      editModalTitle: tx.TX_ORDER_REFUNDS_AND_RETURNS_TITLE,
    });
  }

  openGoodsReceivedModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalReceiveGoods,
      editModalTitle: tx.TX_ORDER_ITEMS_RECEIVED_TITLE,
    });
  }

  openGradeItemsModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalGradeItems,
      editModalTitle: tx.TX_ORDER_GRADE_ITEMS_TITLE,
    });
  }

  openCancelOrderModal() {
    this.setState({
      editModalOpen: true, 
      editModalComponent: OrderModalCancelOrder,
      editModalTitle: tx.TX_ORDER_CANCEL_ORDER_TITLE,
    });
  }

  toggleShippingDefault() {

    let customComponent = null;
    if(!this.state.editModalCustom) {
      if(this.state.responseObject && this.state.responseObject.shippingMethod && this.state.responseObject.shippingMethod.integration && this.state.responseObject.shippingMethod.integration.createShipmentComponent) {
        customComponent = this.state.responseObject.shippingMethod.integration.createShipmentComponent;
      }
    } 

    this.setState({
      editModalOpen: true, 
      editModalCustom: !this.state.editModalCustom,
      editModalComponent: customComponent || OrderModalShipmentCreate,
      editModalTitle: tx.TX_ORDER_CREATE_SHIPMENT,
    });
  }
  
  clearModalData() {
    this.setState({
      editModalComponent: null, 
      editModalCustom: false,
      editModalTitle: tx.TX_null,
      editModalProps: null,
    });
  }

  closeOrderControlModal() {
    this.setState({
      editModalOpen: false, 
    });
  }

  getModalConfirmLabel() {
    if(this.state.editModalComponent && this.state.editModalComponent.WrappedComponent && this.state.editModalComponent.WrappedComponent.confirmLabel) {
      return this.state.editModalComponent.WrappedComponent.confirmLabel();
    }
    return tx.TX_SAVE;
  }

  render() {

    const {t} = this.props;

    if(this.state.editModalComponent) {
      this.getModalConfirmLabel();
    }

    const statusMessageObj = this.getStatusMessageObj(this.state.responseObject, this.getLanguage());

    return <div className={'ViewOrder AdminPage'}>
      <div className='viewOrderTitleWrapper'>
        <AdminTitle
          title={this.getPageTitle()}
          titleData={this.getPageTitleData()}
          breadcrumbs={[
            {
              url: URL_ADMIN_ORDERS,
              urlDynamic: this.destination,
              title: tx.TX_ORDERS,
            },
          ]} />
        <div className='orderActions'>
          <div className='oaLiner'>

          </div>
        </div>
        <div 
          className='orderStatusIndicator'
          onMouseEnter={() => this.toggleNotice(true)} 
          onMouseLeave={() => this.toggleNotice(false)}>
          <div className='osiLiner'>
            <div className='osiBody'>
              <div className='osiBodyStatusNameWidthPlaceholder'>
                <span className='osiStatusLabel'>{t(tx.TX_STATUS)}</span>
                {this.state.responseObject && this.state.responseObject.status ? t(this.state.responseObject.status.name) : ''}
              </div>
              <div className={'osiBodyStatusName FlexCenter'}>
                <span className='osiStatusLabel'>{t(tx.TX_STATUS)}</span>
                {this.state.responseObject && this.state.responseObject.status ? t(this.state.responseObject.status.name) : ''}
              </div>
            </div>
            <div className='osiIndicator'>
              <div className='indicatorWrapper'>
                <div className={this.state.responseObject && this.state.responseObject.status ? `indicatorElement ${this.state.responseObject.status.statusClass(this.state.responseObject.date)}` : 'indicatorElement'}>
                  <div className='ieBall'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminBody'>
        <div className='adminView'>
          <OrderDetailsView
            order={this.state.responseObject}
            orderPending={this.state.requestPending}
            openRefundModal={this.openRefundModal.bind(this)}
            openMessagesModal={this.openMessagesModal.bind(this)}
            openCreatePaymentModal={this.openCreatePaymentModal.bind(this)}
            openCreateBuylistPaymentModal={this.openCreateBuylistPaymentModal.bind(this)}
            openCreateShipmentModal={this.openCreateShipmentModal.bind(this)}
            openGoodsReceivedModal={this.openGoodsReceivedModal.bind(this)}
            openGradeItemsModal={this.openGradeItemsModal.bind(this)}
            openCancelOrderModal={this.openCancelOrderModal.bind(this)} />
          {this.state.responseObject ? 
            <OrderPaymentsView
              order={this.state.responseObject}
              orderPending={this.state.requestPending}
              openCreatePaymentModal={this.openCreatePaymentModal.bind(this)}
              openCreateBuylistPaymentModal={this.openCreateBuylistPaymentModal.bind(this)}
              expanded={this.state.pickupsExpanded}
              toggleExpanded={this.togglePickupsExpanded.bind(this)} /> :
            null
          }
          {this.state.responseObject && this.state.responseObject.requiresShipping ? 
            <OrderShipmentsView
              order={this.state.responseObject}
              orderPending={this.state.requestPending}
              expanded={this.state.shipmentsExpanded}
              toggleExpanded={this.toggleShipmentsExpanded.bind(this)}
              openCreateShipmentModal={this.openCreateShipmentModal.bind(this)}
              openCancelShipmentModal={this.openCancelShipmentModal.bind(this)} /> :
            null
          }
          {this.state.responseObject && this.state.responseObject.requiresPickup ? 
            <OrderPickupsView
              order={this.state.responseObject}
              orderPending={this.state.requestPending}
              openEditModal={this.openPickupsModal.bind(this)}
              openCloseModal={this.openPickupCompleteModal.bind(this)}
              expanded={this.state.pickupsExpanded}
              toggleExpanded={this.togglePickupsExpanded.bind(this)} /> :
            null
          }
          <OrderMessagesView
            order={this.state.responseObject}
            orderPending={this.state.requestPending}
            openCreateModal={this.openMessagesModal.bind(this)}
            expanded={this.state.messagesExpanded}
            toggleExpanded={this.toggleMessagesExpanded.bind(this)} />
        </div>
      </div>
      <div className='adminActions'>
        <Link to={this.destination || URL_ADMIN_ORDERS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={null}
        onCancel={this.leaveWithoutSave.bind(this)}
        confirmLabel={tx.TX_BACK} />
      <OrderControlModal
        open={this.state.editModalOpen}
        modalComponent={this.state.editModalComponent}
        modalTitle={this.state.editModalTitle}
        modalSuccessLabel={this.getModalConfirmLabel()}
        closeMethod={this.closeOrderControlModal.bind(this)}
        clearModalData={this.clearModalData.bind(this)}
        order={this.state.responseObject}
        orderPending={this.state.requestPending}
        orderPendingSilently={this.state.requestPendingSilently}
        makeClean={this.fetchOrder.bind(this)}
        additionalProps={this.state.editModalProps}
        toggleShippingDefault={this.toggleShippingDefault.bind(this)} />
      {this.state.responseObject ?
        <div 
          className='statusNoticeWrapper' 
          style={this.getNoticeStyle()}>
          <div className='statusNotice'>
            {statusMessageObj && statusMessageObj.label ?
              <div className='statusNoticeLabel'>{t(statusMessageObj.label || tx.TX_null)}</div> : null
            }
            {statusMessageObj && statusMessageObj.valueConfig ?
              <div className='statusNoticeValue'>{t(statusMessageObj.value || tx.TX_null, statusMessageObj.valueConfig)}</div> : 
              <div className='statusNoticeValue'>{t(statusMessageObj && statusMessageObj.value ? statusMessageObj.value : tx.TX_null)}</div>
            }
          </div>
        </div> :
        null
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewOrder));