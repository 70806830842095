import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_CHECK, 
} from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  getNotEmptyError,
  isFormValid, 
} from '../../../utils/form-validation';

import Icon from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class PaymentMethodConfigField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      editing: false,

      inputValue: this.getInitValue(),

      errorValue: '',

      requestOriginated: false,
    }

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.requestOriginated === true && prevProps.paymentMethodPending === true && this.props.paymentMethodPending === false) {
      this.setState({
        requestOriginated: false,
      });
      this.toggleEdit();
    }
  }

  getInitValue() {
    if(!this.props.paymentMethod) { return ''; }
    return this.props.config.value;
  }

  getConfigDisplayValue() {
    if(this.props.config.isPrivate) {
      return this.props.config.hasValue() ? this.props.t(tx.TX_PLACEHOLDER_PASSWORD) : '';
    } else {
      return this.props.config.value;
    }
  }

  toggleEdit() {
    this.setState({
      editing: !this.state.editing,
    }, () => {
      if(this.state.editing && this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      } else if(this.state.editing === false) {
        this.setState({
          inputValue: this.getInitValue(),
          errorValue: '',
        });
      }
    });
  }

  changeInput(evt) {
    this.setState({
      inputValue: evt.target.value,
    }, () => {
      if(this.state.errorValue) {
        this.validateInput();
      }
    });
  }

  validateInput() {
    this.setState({ errorValue: getNotEmptyError(this.state.inputValue) });
  }

  validateAll() {
    let errorObj = {
      errorValue: getNotEmptyError(this.state.inputValue),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  submitConfig(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.props.paymentMethodPending) {
        return null;
      }

      this.setState({
        requestOriginated: true,
      });

      this.props.setConfigAttribute(this.props.config, this.state.inputValue);
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'PaymentMethodConfigField adminViewItem'}>
      <div className={this.props.config.isRequired ? 'adminViewLabel adminRequired' : 'adminViewLabel'}>{t(this.props.config.label)}</div>
      {this.state.editing ?
        <form className='cfEdit' onSubmit={this.submitConfig.bind(this)}>
          <div className='cfEditInputWrapper'>
            <input 
              type='text'
              ref={this.inputRef}
              className='cfEditInput'
              value={this.state.inputValue}
              onChange={this.changeInput.bind(this)} />
            {this.state.errorValue ?
              <div className={'cfEditError FieldError'}>{t(this.state.errorValue)}</div> :
              null
            }
          </div>
          <div className='cfEditSaveAction' onClick={this.submitConfig.bind(this)}>
            <div className='cfEditSaveActionIconWrapper'>
              {this.state.requestOriginated ?
                <LoadingIcon
                  iconClass={'cfEditSaveActionIcon'} /> :
                <Icon 
                  value={ICON_CHECK}  
                  iconClass={'cfEditSaveActionIcon'} />
              }
            </div>
          </div>
          <div className='cfEditCancelAction'>
            <div className={'cfEditCancelActionIconWrapper FlexCenter'} onClick={this.toggleEdit.bind(this)}>&times;</div>
          </div>
        </form> :
        <div className={'adminViewValue configValue'}>
          <span className={'adminViewValueData EllipsisElement'}>{this.getConfigDisplayValue()}</span>
          <span 
            className={this.props.config.hasValue() ? 'adminViewValueToggle' : 'adminViewValueToggle noMainValue'}
            onClick={this.toggleEdit.bind(this)}>
            {t(this.props.config.hasValue() ? tx.TX_EDIT : tx.TX_ADD)}
          </span>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PaymentMethodConfigField));