import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERR_5500 } from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';

import { 
  getDescriptionError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import '../../style/_vieworder.scss';

import * as commonActionCreators from '../../../../actions/common';
import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, commonActionCreators, orderActionCreators);

export class OrderModalShipmentCancel extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputNote: '',

      errorNote: '',

      inputCarrier: '',
      inputCarrierAltName: '', 
      inputHasTracking: false,
      inputTracking: '',
      
      errorCarrier: '',
      errorCarrierAltName: '',
      errorTracking: '',

      requestPending: false,
      requestError: null,
    }

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.saveAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_SAVE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  validateAll() {
    const errorObj = {
      errorNote: getDescriptionError(this.state.inputNote, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async saveAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll() && this.props.shipment) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const cancelData = {
        "description": this.state.inputNote,
      }

      if(this.props.shipment && this.props.shipment.label && this.props.shipment.label.serviceType && this.props.shipment.label.serviceType === 'usps_first_class_mail') {
        this.props.commonAlert({
          alertTitle: tx.TX_ORDER_SHIPMENT_CANCELED, 
          alertCopy: tx.TX_ORDER_SHIPMENT_CANCELED_FIRST_CLASS_COPY, 
        });
      }

      const cancelResp = await this.props.ordersCancelShipment(cancelData, this.props.order.publicUuid, this.props.shipment.publicUuid, controller.signal)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: errResp && errResp.error ? formatServerError(errResp.error) : ERR_5500,
          });
          this.props.completeAction();
        });

      if(!cancelResp) {
        this.props.completeAction();
        return null;
      }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();
      
    } else {
      this.props.completeAction();
    }
  }

  changeNote(evt) {
    this.setState({
      inputNote: evt.target.value,
    }, () => {
      if(this.state.errorNote) {
        this.validateNote();
      }
    });
  }

  validateNote() {
    this.setState({ errorNote: getDescriptionError(this.state.inputNote, true) });
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalShipmentCancel OrderModalView'}>
      <div className='omvWrapper'>        
        <form 
          className={'shipmentCreateForm orderModalForm'}
          onSubmit={this.saveAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          <div className='omFieldWrapper'>
            <div className={'omFieldLabel omOptional'}>{t(tx.TX_ORDER_PICKUPS_ADD_CUSTOMER_NOTE)}</div>
            <div className='omInputWrapper'>
              <textarea
                type='text'
                className={this.state.errorNote ? 'InputError' : ''}
                value={this.state.inputNote}
                onChange={this.changeNote.bind(this)}
                onBlur={this.validateNote.bind(this)}
                placeholder={t(tx.TX_OPTIONAL)}
                maxLength={190}></textarea>
            </div>
            {this.state.errorNote ?
              <div className={'FieldError'}>{t(this.state.errorNote)}</div> :
              null
            }
          </div>

        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalShipmentCancel));