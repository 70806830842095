import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_FULL_EXPORT,
  API_KEY_LIMIT,
  API_KEY_OFFSET,
} from '../../../../constants/api';
import { ICON_OPEN_EXTERNAL } from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';
import { URL_ADMIN_ORDERS_ORDER_VIEW } from '../../../../constants/urls';

import { 
  formatPrice,
  formatServerError, 
  serverDateShort,
  serverTimeFull,
  stringFormat, 
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import { 
  getPageLimit, 
  getPageOffset, 
} from '../../../../utils/request';

import { Icon } from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';
import MiniCart from '../../../Cart/MiniCart';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class ProductRecentOrdersView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      expanded: false,

      selectedRow: 0,

      pageNum: 1,

      requestPending: true,
      requestError: null,
      responseData: null,
      responseCount: null,
    };

    this.PAGE_SIZE = 10;

    this.ordersList = React.createRef();

    this.controller = null;
  }

  componentDidMount() {
    if(this.props.product) {
      this.fetchProductOrders();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.product === null && this.props.product !== null) {
      this.fetchProductOrders();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async fetchProductOrders() {

    if(!this.props.product) { return null; }    

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
    });

    const filters = {
      product_sku: this.props.product.sku,
    }

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(this.state.pageNum, this.PAGE_SIZE),
      [API_KEY_OFFSET]: getPageOffset(this.state.pageNum, this.PAGE_SIZE),
    };

    const getParams = Object.assign({ [API_KEY_FULL_EXPORT]: true }, filters, controlParams);
    const ordersResp = await this.props.ordersAdminFetchPage(getParams, controller.signal)
      .catch((errResp) => {

        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({
          requestPending: false,
          requestError: formatServerError(errResp),
          responseData: null,
          responseCount: null,
          selectedRow: 0,
        });
      });

    if(!ordersResp) {
      return null;
    }

    this.setState({
      requestPending: false,
      requestError: null,
      responseData: ordersResp.data,
      responseCount: ordersResp.count,
      selectedRow: 0,
    });
  }

  getTotalPages() {
    if(!this.state.requestPending && this.state.responseCount > 0) {
      return Math.ceil(this.state.responseCount / this.PAGE_SIZE);
    }
    return 0;
  }

  decreasePage() {
    if(this.state.pageNum > 1) {
      this.setState({
        pageNum: this.state.pageNum - 1,
      }, () => {
        this.fetchProductOrders();
      });
    }
  }

  increasePage() {
    if(this.state.pageNum < this.getTotalPages()) {
      this.setState({
        pageNum: this.state.pageNum + 1,
      }, () => {
        this.fetchProductOrders();
      });
    }
  }

  toggleExpansion() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  selectOrder(idx) {
    if(idx !== this.state.selectedRow) {
      this.setState({
        selectedRow: idx,
      });
    }
  }

  getSelectedCart() {

    if(!this.props.product || !this.state.responseData) { return null; }

    if(this.state.responseData.length > this.state.selectedRow && this.state.responseData[this.state.selectedRow]) {
      return this.state.responseData[this.state.selectedRow].orderCart;
    }
    return null;
  }

  getSelectedOrder() {

    if(!this.props.product || !this.state.responseData) { return null; }

    if(this.state.responseData.length > this.state.selectedRow && this.state.responseData[this.state.selectedRow]) {
      return this.state.responseData[this.state.selectedRow];
    }
    return null;
  }

  getCartStyles() {

    if(this.ordersList && this.ordersList.current) {
      return {
        maxHeight: `${this.ordersList.current.clientHeight}px`,
      };
    }

    return {};
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductRecentOrdersView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INV_VIEW_PRODUCT_RECENT_ORDERS)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          {this.props.product ?
            <>
              {!this.state.responseCount ?
                <div className='noOrders'>
                  <div className='noOrdersCopy'>{t(tx.TX_INV_VIEW_PRODUCT_NO_RECENT_ORDERS)}</div>
                </div> :
                <div className='ordersListWrapper'>
                  <div className='orderList' ref={this.ordersList}>
                    <div className={'slHeader'}>
                      <div className={'slHeaderCell dateCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_DATE)}</div>
                      </div>
                      <div className={'slHeaderCell numberCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_NUMBER)}</div>
                      </div>
                      <div className={'slHeaderCell amountCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_TOTAL)}</div>
                      </div>
                      <div className={'slHeaderCell quantityCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_QUANTITY)}</div>
                      </div>
                      <div className={'slHeaderCell actionCell'}>
                        <div className={'slHeaderValue slItemValue'}></div>
                      </div>
                    </div>
                    {this.state.responseData.map((order, i) => {
                      return <div 
                              key={i}
                              className={this.state.selectedRow === i ? 'slItem slSelected' : 'slItem'}
                              onClick={() => this.selectOrder(i)}>
                        <div className={'slItemCell dateCell'}>
                          <div className='slItemValue'>
                            <div className='dateLongValue'>
                              <div className='dateDate'>{serverDateShort(order.date, this.getLanguage())}</div>
                              <div className='dateTime'>{serverTimeFull(order.date, this.getLanguage())}</div>
                            </div>
                          </div>
                        </div>
                        <div className={'slItemCell numberCell'}>
                          <div className='slItemValue'>{order.displayNumber}</div>
                        </div>
                        <div className={'slItemCell amountCell'}>
                        <div className='slItemValue' dangerouslySetInnerHTML={{ __html: formatPrice(order.totalGrand, { addTags: true, language: this.getLanguage() }) }} />
                        </div>
                        <div className={'slItemCell quantityCell'}>
                          <div className={'slItemValue quantityValue'}>{order.orderCart.quantity}</div>
                        </div>
                        <div className={'slItemCell actionCell'}>
                          <div className={`actionIconWrapper ${this.state.selectedRow !== i ? 'noShow' : ''}`}>
                            <Link to={stringFormat(URL_ADMIN_ORDERS_ORDER_VIEW, { publicUuid: order.publicUuid })} target='_blank' rel='noopener noreferrer'>
                              <Icon value={ICON_OPEN_EXTERNAL} iconClass='actionIcon' />
                            </Link>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                  <div className='orderInfo'>
                    <div className='infoSectionTitle'>
                      <div className='infoSectionTitleValue'>{t(tx.TX_ORDER)}</div>
                      {this.getSelectedCart() ?
                        <div className='infoSectionTitleItems'>({this.getSelectedCart().quantity === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: this.getSelectedCart().quantity })})</div> :
                        null
                      }
                    </div>
                    <div className='orderItemsWrapper' style={this.getCartStyles()}>
                      {this.getSelectedCart() ?
                        <MiniCart
                          order={this.getSelectedOrder()}
                          cartOverride={this.getSelectedCart()}
                          readOnly={true} /> :
                        null
                      }
                    </div>
                  </div>
                </div>
              }
            </> :
            null
          }
          {!this.state.requestPending && this.state.responseCount > 0 && this.getTotalPages() > 1 ?
            <div className='paginationWrapper'>
              <div className='paginationLiner'>
                <div className={`leftArrow ${this.state.pageNum <= 1 ? 'noNav' : ''}`} onClick={this.decreasePage.bind(this)}>&lt;</div>
                <div className='pageStatus' dangerouslySetInnerHTML={{ __html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, { current: this.state.pageNum, total: this.getTotalPages() }) }} />
                <div className={`rightArrow ${this.state.pageNum >= this.getTotalPages() ? 'noNav' : ''}`} onClick={this.increasePage.bind(this)}>&gt;</div>
              </div>
            </div> :
            null
          }
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.expanded && (this.props.productPending || this.state.requestPending) ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductRecentOrdersView));