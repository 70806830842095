import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { P_MANAGE_USERS } from '../../../constants/permissions';
import * as tx from '../../../constants/strings';
import { 
  URL_ADMIN_CUSTOMERS_VIEW,
  URL_ADMIN_ORDERS_ORDER_VIEW, 
} from '../../../constants/urls';

import { 
  getFullName,
  serverDateFull,
  serverTimeFull,
  stringFormat, 
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';
import { hasPermission } from '../../../utils/permissions';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class CartDetailsView extends Component {

  constructor(props) {
    super(props);

    this.isBuylist = !!this.props.isBuylist;

    this.ACTION_KEY_CHANGE_USER = 'change-user';
    this.ACTION_KEY_COMPLETE_ORDER = 'complete-order';

    this.state = {};
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getCustomerName() {
    if(!this.props.cart || this.props.cartPending) { return '' }
    
    if(this.props.cart.user) {
      return getFullName(this.props.cart.user.firstName || '', this.props.cart.user.lastName || '', this.getLanguage()).trim()
    } else {
      return this.props.t(tx.TX_GUEST_USER);
    }
  }

  getCustomerEmail() {
    if(!this.props.cart || this.props.cartPending) { return '' }
    
    if(this.props.cart.user) {
      return this.props.cart.user.email;
    } else {
      return this.props.t(tx.TX_NONE);
    }
  }

  getCartActions() {
    if(!this.props.cart || this.props.cartPending) { return []; }

    const dropdownOptions = [];

    if(hasPermission(P_MANAGE_USERS) && !this.props.cart.order) {
      dropdownOptions.push({
        display: tx.TX_CART_CHANGE_CUSTOMER,
        value: this.ACTION_KEY_CHANGE_USER,
      });
    }

    if(this.isBuylist && !this.props.cart.order) {
      dropdownOptions.push({
        display: tx.TX_CART_CREATE_ORDER,
        value: this.ACTION_KEY_COMPLETE_ORDER,
      });
    }
    return dropdownOptions;
  }

  triggerCartAction(evt) {
    const actionKey = evt.target.value;

    switch(actionKey) {
      case this.ACTION_KEY_CHANGE_USER:
        this.props.openChangeCustomerModal();
        break;
      case this.ACTION_KEY_COMPLETE_ORDER:
        this.props.openCreateOrderModal();
        break;
      default:
        return null;
    }
  }

  getCartStatus() {
    if(!this.props.cart || this.props.cartPending) { return tx.TX_null; }
    return this.props.cart.status || tx.TX_null;
  }

  getCartType() {
    if(!this.props.cart || this.props.cartPending) { return tx.TX_null; }
    return this.props.cart.isBuylist ? tx.TX_BUYLIST : tx.TX_CART_TYPE_PURCHASE;
  }

  render() {

    const {t} = this.props;

    return <div className={'CartDetailsView adminSection'}>
    	<div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_ORDER_CART_OVERVIEW)}</div>
        {this.getCartActions().length > 0 ?
          <div className='asTitleActionsWrapper'>
            <div className='asTitleActions'>
              <select 
                className='asTitleActionElement'
                value={''} 
                disabled={!this.props.cart || this.props.cartPending}
                onChange={this.triggerCartAction.bind(this)}>
                <option value={''}>{t(tx.TX_ACTIONS)}</option>
                {this.getCartActions().map((actionObj, i) => {
                  return <option key={i} value={actionObj.value}>{t(actionObj.display)}</option>;
                })}
              </select>
            </div>
          </div> :
          null
        }
      </div>
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            
            <div className='adminViewItem'>
              <div className='adminViewLabel'>{t(tx.TX_ORDER_CUSTOMER_NAME)}</div>
              <div className='adminViewValue'>
                <div className='interactionWrapper'>
                  <div className='interactionValue'>{this.getCustomerName()}</div>
                  {this.props.cart && this.props.cart.user && hasPermission(P_MANAGE_USERS) ?
                    <a 
                      className='interactionAction'
                      href={stringFormat(URL_ADMIN_CUSTOMERS_VIEW, { publicUuid: this.props.cart.user.publicUuid })}
                      target='_blank'
                      rel='noopener noreferrer'>
                        {t(tx.TX_VIEW)}
                    </a> :
                    null
                  }
                </div>
              </div>
            </div>

            <div className='adminViewItem'>
              <div className='adminViewLabel'>{t(tx.TX_ORDER_CUSTOMER_EMAIL)}</div>
              <div className={`adminViewValue ${this.props.cart && !this.props.cart.user ? 'nullValue' : ''}`}>{this.getCustomerEmail()}</div>
            </div>
                
            <div className='adminViewItem'>
              <div className='adminViewLabel'>{t(tx.TX_CART_TYPE)}</div>
              <div className='adminViewValue'>{t(this.getCartType())}</div>
            </div>

            <div className='adminViewItem'>
              <div className='adminViewLabel'>{t(tx.TX_STATUS)}</div>
              <div className='adminViewValue'>{t(this.getCartStatus())}</div>
            </div>

            {this.props.cart && this.props.cart.order ?
              <div className='adminViewItem'>
                <div className='adminViewLabel'>{t(tx.TX_ORDER)}</div>
                <div className='adminViewValue'>
                  <div className='interactionWrapper'>
                    <div className='interactionValue'>{this.props.cart.order.displayNumber}</div>
                    <a 
                      className='interactionAction'
                      href={stringFormat(URL_ADMIN_ORDERS_ORDER_VIEW, { publicUuid: this.props.cart.order.publicUuid })}
                      target='_blank'
                      rel='noopener noreferrer'>
                        {t(tx.TX_VIEW)}
                    </a>
                  </div>
                </div>
              </div> :
              null
            }

            <div className={'adminViewItem tallItem'}>
              <div className='adminViewLabel'>{t(tx.TX_CART_LAST_UPDATE)}</div>
              {this.props.cart && this.props.cart.lastUpdate ?
                <div className='adminViewValue'>
                  <div className='adminViewValueDate'>{serverDateFull(this.props.cart.lastUpdate, this.getLanguage())}</div>
                  <div className='adminViewValueTime'>{serverTimeFull(this.props.cart.lastUpdate, this.getLanguage())}</div>
                </div> :
                <div className='adminViewValue nullValue'>{t(tx.TX_NONE)}</div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.cartPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CartDetailsView));