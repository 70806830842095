import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP } from '../../../constants/store';
import * as tx from '../../../constants/strings';

import { 
  formatPrice,
  normalizeWeight, 
} from '../../../utils/formatting';
import { getStoreDefaultWeightUnit } from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';
import { convertWeightBetweenUnits } from '../../../utils/measurements';

import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class ShippingMethodDetailsView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getMarkupPercent() {

    if(!this.props.shippingMethod || !this.props.shippingMethod.integration) { return null; }

    const val = this.props.shippingMethod.getIntegrationConfig(STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP);
    if(val) {
      return `${parseFloat(val).toFixed(1)}%`;
    }
  }

  getMinWeightValue() {
    if(this.props.shippingMethod && this.props.shippingMethod.minWeight) {
      return convertWeightBetweenUnits(this.props.shippingMethod.minWeight, this.props.shippingMethod.minWeightUnit, getStoreDefaultWeightUnit());
    }
    return null;
  }

  getMinWeightDisplay() {
    const defaultUnit = getStoreDefaultWeightUnit();
    return `${normalizeWeight(this.getMinWeightValue(), defaultUnit)} ${defaultUnit.display}`;
  }

  getMaxWeightValue() {
    if(this.props.shippingMethod && this.props.shippingMethod.maxWeight) {
      return convertWeightBetweenUnits(this.props.shippingMethod.maxWeight, this.props.shippingMethod.maxWeightUnit, getStoreDefaultWeightUnit());
    }
    return null;
  }

  getMaxWeightDisplay() {
    const defaultUnit = getStoreDefaultWeightUnit();
    return `${normalizeWeight(this.getMaxWeightValue(), defaultUnit)} ${defaultUnit.display}`;
  }

  render() {

    const {t} = this.props;

    return <div className={'ShippingMethodDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_SHIPPING_METHOD)}</div>
      </div>
      {this.props.shippingMethod.isEnabled === false ?
        <div className='detailsMethodDisabled'>{t(tx.TX_CURRENTLY_DISABLED)}</div> : 
        null
      }
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_NAME)}</div>
                  <div className='adminViewValue'>{this.props.shippingMethod.name}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_DESCRIPTION)}</div>
                  <div className={this.props.shippingMethod.description ? 'adminViewValue' : 'adminViewValue nullValue'}>{this.props.shippingMethod.description ? this.props.shippingMethod.description : t(tx.TX_NONE)}</div>
                </div>
                {this.props.shippingMethod.integration && this.props.shippingMethod.integration.hideCosts ?
                  null :
                  <>
                    <div className='adminViewItem'>
                      <div className='adminViewLabel'>{t(tx.TX_COST)}</div>
                      <div className='adminViewValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.shippingMethod.cost, { addTags: true, language: this.getLanguage() }) }} />
                    </div>
                    <div className='adminViewItem'>
                      <div className='adminViewLabel'>{t(tx.TX_SETTINGS_MIN_PURCHASE)}</div>
                      <div className='adminViewValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.shippingMethod.minPurchase, { addTags: true, language: this.getLanguage() }) }} />
                    </div>
                    <div className='adminViewItem'>
                      <div className='adminViewLabel'>{t(tx.TX_SETTINGS_MIN_WEIGHT)}</div>
                      <div className={`adminViewValue ${!this.getMinWeightValue() ? 'nullValue' : ''}`}>{this.getMinWeightValue() ? this.getMinWeightDisplay() : t(tx.TX_NONE)}</div>
                    </div>
                    <div className='adminViewItem'>
                      <div className='adminViewLabel'>{t(tx.TX_SETTINGS_MAX_WEIGHT)}</div>
                      <div className={`adminViewValue ${!this.getMaxWeightValue() ? 'nullValue' : ''}`}>{this.getMaxWeightValue() ? this.getMaxWeightDisplay() : t(tx.TX_NONE)}</div>
                    </div>
                  </>
                }
                {this.props.shippingMethod.integration && this.props.shippingMethod.integration.hidePickup ?
                  null :
                  <>
                    <div className='adminViewItem'>
                      <div className='adminViewLabel'>{t(tx.TX_ORDER_CUSTOMER_PICKUP_QUESTION)}</div>
                      <div className='adminViewValue'>{t(this.props.shippingMethod.isPickup ? tx.TX_YES : tx.TX_NO )}</div>
                    </div>
                    <div className='adminViewItem'>
                      <div className='adminViewLabel'>{t(tx.TX_SETTINGS_PAYMENT_METHODS_ALLOW_AT_PICKUP_QUESTION)}</div>
                      <div className='adminViewValue'>{t(this.props.shippingMethod.allowPaymentAtPickup ? tx.TX_YES : tx.TX_NO )}</div>
                    </div>
                  </>
                }
                {this.props.shippingMethod.integration ?
                  <>
                    <div className='adminViewItem'>
                      <div className='adminViewLabel'>{t(tx.TX_INTEGRATION)}</div>
                      <div className='adminViewValue'>{this.props.shippingMethod.integration.provider.name}</div>
                    </div>
                    {this.props.shippingMethod.hasLocalIntegrationConfig(STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP) ?
                      <div className='adminViewItem'>
                        <div className='adminViewLabel'>{t(tx.TX_MARKUP)}</div>
                        <div className='adminViewValue'>{this.getMarkupPercent() || t(tx.TX_NONE)}</div>
                      </div> :
                      null
                    }
                  </> :
                  null
                }
              </div>
              <div className='adminViewButtonWrapper'>
                <div className={'shippingMethodDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.shippingMethodPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ShippingMethodDetailsView));