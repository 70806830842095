import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { CHECKOUT_STEP_SHIPPING_ADDRESS } from '../../../../../constants/checkout';
import * as tx from '../../../../../constants/strings';

import { Address } from '../../../../../models/geographies';
import { ShippingMethod } from '../../../../../models/shipping-methods';

import { addShippingInfoEvent } from '../../../../../utils/analytics';
import { getCheckoutStepData } from '../../../../../utils/checkout';
import { formatPrice } from '../../../../../utils/formatting';
import { getStoreLanguage } from '../../../../../utils/language';

import LoadingIcon from '../../../../Icons/LoadingIcon';
import Modal from '../../../../Popups/Modal';
import Radio from '../../../../Input/Radio';

import * as checkoutActionCreators from '../../../../../actions/checkout';
import * as storeActionCreators from '../../../../../actions/store';
import * as userActionCreators from '../../../../../actions/user';
let allActionCreators = Object.assign({}, checkoutActionCreators, storeActionCreators, userActionCreators);

export class CheckoutStepShippingMethodInput extends Component {

  constructor(props) {
    super(props);

    let currentShippingMethod = null;
    const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);

    if(stepData && stepData.data && stepData.data.shippingMethod) {
      currentShippingMethod = stepData.data.shippingMethod;
    }

    this.shippingAddress = this.getShippingAddress();

    this.state = {
      
      selectedMethod: currentShippingMethod, 

      shippingMethods: null,
      shippingMethodsLoading: true,

      addressModalOpen: false,
      addressModalCandidates: [],

      selectedCandidateIndex: -1,
    };
  }

  componentDidMount() {
    this.fetchShippingMethods();
  }

  componentDidUpdate(prevProps, prevState) {
    try {

      const previousSubtotal = prevProps.cart.currentCart.subTotal;
      const currentSubtotal = this.props.cart.currentCart.subTotal;

      if(currentSubtotal !== previousSubtotal) {
                
        this.props.setStepData(this.props.config, {
          shippingMethod: null, 
        });
        this.setState({ selectedMethod: null });
        this.fetchShippingMethods();
      }

    } catch(err) {
      console.error(err);
    }
  }

  async fetchShippingMethods() {

    this.setState({
      shippingMethods: null,
      shippingMethodsLoading: true,
    }, () => {

      const shippingAddressUuid = this.shippingAddress && this.shippingAddress.publicUuid ? this.shippingAddress.publicUuid : null;

      this.props.checkoutFetchShippingMethods(shippingAddressUuid)
      .then((resp) => {

        const methods = [];
        for(const sm of resp) {
          methods.push(new ShippingMethod(sm));
        }

        this.setState({
          shippingMethods: methods,
          shippingMethodsLoading: false,
        }, () => {
          
          let validatedSelectedMethod = null;
          const applicableMethods = this.getApplicableShippingMethods();

          if(this.state.selectedMethod && this.state.selectedMethod.publicUuid && applicableMethods) {

            for(const ship of applicableMethods) {
              if(ship.publicUuid && ship.publicUuid === this.state.selectedMethod.publicUuid) {
                validatedSelectedMethod = this.state.selectedMethod;
                break;
              }
            }
            if(validatedSelectedMethod === null) {
              this.props.setStepData(this.props.config, {
                shippingMethod: null, 
              });
              this.setState({
                selectedMethod: null, 
              });
            }
          }
        });
      })
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
        this.setState({ shippingMethodsLoading: false, });
      });
    });
  }

  getShippingAddress() {
    const stepData = getCheckoutStepData(CHECKOUT_STEP_SHIPPING_ADDRESS, this.props.checkout.stepData);
    if(stepData && stepData.data && stepData.data.shippingAddress) {
      return stepData.data.shippingAddress;
    }
    return null;
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.state.selectedMethod && this.state.selectedMethod) {
      this.props.setStepData(this.props.config, {
        shippingMethod: this.state.selectedMethod, 
      });
    }
  }

  async fetchAddressValidation() {

    const validationResp = await this.props.storeIntegrationStampsAddressVerification(this.state.selectedMethod.publicUuid, this.shippingAddress.publicUuid)
      .catch((errResp) => {
        console.error(errResp);
      });

    if(!validationResp || !validationResp.length) {
      return null;
    }

    const success_codes = [ 'V100' ];

    const resp = validationResp[0];

    if(resp.validation_results && resp.validation_results.result_code && !success_codes.includes(resp.validation_results.result_code)) {

      if(!resp.candidate_addresses || !resp.candidate_addresses.length) {
        return null;
      }

      // Convert addresses to models
      const candidates = [];
      for(const ca of resp.candidate_addresses) {
        candidates.push(new Address(Object.assign({}, ca, {
          publicUuid: this.shippingAddress.publicUuid, 
          placeId: this.shippingAddress.placeId,
          firstName: this.shippingAddress.firstName,
          lastName: this.shippingAddress.lastName,
          country: this.shippingAddress.country,
          phone: this.shippingAddress.phone,
        })));
      }

      this.setState({ 
        addressModalOpen: true, 
        addressModalCandidates: candidates, 
        selectedCandidateIndex: -1,
      });
    }
  }

  closeAddressModal() {
    this.setState({ 
      addressModalOpen: false,
      addressModalCandidates: [], 
      selectedCandidateIndex: -1,
    });
  }

  async updateAddress() {
    try {

      const updateAddressResp = await this.props.usersUpdateAddress(this.state.addressModalCandidates[this.state.selectedCandidateIndex].getApiData())
        .catch((errResp) => {
          console.error(errResp);
        });

      this.props.setStepData(CHECKOUT_STEP_SHIPPING_ADDRESS, {
        shippingAddress: updateAddressResp, 
      });
    } catch(err) {
      console.error(err);
    }
  }

  setShippingMethod(evt) {
    const methodUuid = evt.target.value;
    if(!methodUuid || !this.state.shippingMethods || !this.state.shippingMethods.length) { return null; }

    for(const ship of this.state.shippingMethods) {
      if(ship.publicUuid === methodUuid) {
        this.setState({ selectedMethod: ship }, () => {
          
          // Check for integration and fire popup if necessary for address validation
          // Currently, just for stamps.com linked shipping methods
          // Can be abstracted more in the future
          if(this.state.selectedMethod.integration && this.state.selectedMethod.integration.isEnabled && this.state.selectedMethod.integration.isStamps) {
            this.fetchAddressValidation(this.shippingAddress);
          }

          // Fire add_shipping_info analytics event when user selects shipping method
          addShippingInfoEvent(this.props.cart.currentCart, ship, {});
            
          this.props.setStepData(this.props.config, {
            shippingMethod: ship, 
          });
        });
        return null;
      }
    }
  }

  getApplicableShippingMethods() {
    if(!this.state.shippingMethods || !this.state.shippingMethods.length) {
      return null;
    }

    const methodsResp = [];
    const cartSubtotal = this.props.cart.currentCart.subTotal;

    for(const ship of this.state.shippingMethods) {
      const minPriceFloat = parseFloat(ship.minPurchase);
      if(ship.cost < 0) {
        continue;
      }
      if(!isNaN(minPriceFloat) && cartSubtotal >= minPriceFloat) {
        methodsResp.push(ship);
      }
    }
    return methodsResp.length ? methodsResp : null;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  isOptionSelected(option) {
    if(!option || !option.publicUuid) { return false; }
    if(!this.state.selectedMethod || !this.state.selectedMethod.publicUuid) { return false; }
    return this.state.selectedMethod.publicUuid === option.publicUuid ? true : false;
  }

  hasDeliveryOptions() {
    if(!this.state.shippingMethods || !this.state.shippingMethods.length) {
      return false;
    }

    for(const ship of this.state.shippingMethods) {
      if(ship.isPickup === false) {
        return true;
      }
    }
    return false;
  }

  hasPickupOptions() {
    if(!this.state.shippingMethods || !this.state.shippingMethods.length) {
      return false;
    }

    for(const ship of this.state.shippingMethods) {
      if(ship.isPickup === true) {
        return true;
      }
    }
    return false;
  }

  getApplicablePickupMethods() {
    const pickupMethods = [];
    
    for(const sm of this.getApplicableShippingMethods()) {
      if(sm.isPickup === true) {
        pickupMethods.push(sm);
      }
    }
    return pickupMethods;
  }
  getApplicableDeliveryMethods() {
    const deliveryMethods = [];
    
    for(const sm of this.getApplicableShippingMethods()) {
      if(sm.isPickup === false) {
        deliveryMethods.push(sm);
      }
    }
    return deliveryMethods;
  }

  selectCandidateAddress(idx) {
    this.setState({ selectedCandidateIndex: idx });
  }

  render() {

    const {t} = this.props;

    const hasDeliveryOptions = this.hasDeliveryOptions();
    const hasPickupOptions = this.hasPickupOptions();

    return <div className={'CheckoutStepShippingMethodInput CheckoutStepComponent'}>
      <div className='cssmLiner'>
        <form className={'checkoutStepRadioForm'} onSubmit={this.handleSubmit.bind(this)}>
          <div className='checkoutFormSubheader'>{t(tx.TX_SHIPPING_METHOD)}</div>
          {this.state.shippingMethodsLoading ?
            <div className='checkoutRadioLoadingWrapper'>
              <LoadingIcon iconClass='iconElement' />
            </div> :
            <>
              {this.getApplicableShippingMethods() ?
                <div className='radioOptionsWrapper'>
                  {hasDeliveryOptions && hasPickupOptions ?
                    <div className='radioOptionLabel'>{t(tx.TX_PICKUP)}</div> :
                    null
                  }
                  {this.getApplicablePickupMethods().map((opt, i) => {
                    return <div key={i} className={this.isOptionSelected(opt) ? 'radioOptionElementWrapper selected' : 'radioOptionElementWrapper'}>
                      <div className='radioInputWrapper'>
                        <Radio
                          id={`shippingMethod-pu-${i}`}
                          className='smInput'
                          name='shipping-method'
                          adminTheme={false}
                          disabled={false}
                          value={opt.publicUuid}
                          checked={this.isOptionSelected(opt)}
                          onChange={this.setShippingMethod.bind(this)} />
                      </div>
                      <label htmlFor={`shippingMethod-pu-${i}`}>
                        <div className='radioLabelWrapper'>
                          <div className='radioTitle'>
                            <span className='smName'>{opt.name}</span>
                            <span className='smCost'>
                              <span className='priceValue' dangerouslySetInnerHTML={{__html: formatPrice(opt.cost, { addTags: true, language: this.getLanguage(), zeroValue: t(tx.TX_FREE) })}} />
                            </span>
                          </div>
                          <div className='radioSubtitle'>{opt.description}</div>
                        </div>
                      </label>
                    </div>;
                  })}
                  {hasDeliveryOptions && hasPickupOptions ?
                    <div className='radioOptionLabel'>{t(tx.TX_DELIVERY)}</div> :
                    null
                  }
                  {this.getApplicableDeliveryMethods().map((opt, j) => {
                    return <div key={j} className={this.isOptionSelected(opt) ? 'radioOptionElementWrapper selected' : 'radioOptionElementWrapper'}>
                      <div className='radioInputWrapper'>
                        <Radio
                          id={`shippingMethod-del-${j}`}
                          className='smInput'
                          name='shipping-method'
                          adminTheme={false}
                          disabled={false}
                          value={opt.publicUuid}
                          checked={this.isOptionSelected(opt)}
                          onChange={this.setShippingMethod.bind(this)} />
                      </div>
                      <label htmlFor={`shippingMethod-del-${j}`}>
                        <div className='radioLabelWrapper'>
                          <div className='radioTitle'>
                            <span className='smName'>{opt.name}</span>
                            <span className='smCost'>
                              <span className='priceValue' dangerouslySetInnerHTML={{__html: formatPrice(opt.cost, { addTags: true, language: this.getLanguage(), zeroValue: t(tx.TX_FREE) })}} />
                            </span>
                          </div>
                          <div className='radioSubtitle'>{opt.description}</div>
                        </div>
                      </label>
                    </div>;
                  })}
                </div> :
                <div className='radioNoOptions'>{t(tx.TX_CHECKOUT_NO_SHIPPING_METHODS)}</div>
              }
            </>
          }
        </form>
      </div>
      <Modal
        title={tx.TX_CHECKOUT_ADDRESS_VERIFICATION}
        open={this.state.addressModalOpen}
        confirmValue={tx.TX_SAVE}
        closeMethod={this.closeAddressModal.bind(this)}
        onConfirm={this.updateAddress.bind(this)}>

        <div className='addressValidationWrapper'>
          <div className='addressValidation'>
            <div className='avP'>{t(tx.TX_CHECKOUT_ADDRESS_VERIFICATION_ADDRESS_ENTERED)}</div>
            <div 
              className='addressBlock'
              dangerouslySetInnerHTML={{ __html: this.shippingAddress.format({ addTags: true }) }} />
            <div className='avP'>{t(tx.TX_CHECKOUT_ADDRESS_VERIFICATION_DOES_NOT_MATCH)}</div>
            <div className='addressForm'>
              <div className='formLiner'>
                {this.state.addressModalCandidates && this.state.addressModalCandidates.length ?
                  <>
                    {this.state.addressModalCandidates.map((addr, k) => {

                      if(k > 5) {
                        return null;
                      }

                      return <div key={k} className={`candidateEntry ${this.state.selectedCandidateIndex === k ? 'selected' : ''}`}>
                        <div className='candidateInput'>
                          <div className='radioWrapper'>
                            <Radio
                              id={`candidate-option-${k}`}
                              className='candidateRadio'
                              name='candidate-address'
                              adminTheme={false}
                              disabled={false}
                              value={k}
                              checked={this.state.selectedCandidateIndex === k}
                              onChange={() => this.selectCandidateAddress(k)} />
                          </div>
                        </div>
                        <div className='candidateValue'>
                          <label htmlFor={`candidate-option-${k}`}>
                            <div 
                              className='cadidateAddressValue'
                              dangerouslySetInnerHTML={{ __html: addr.format({ addTags: true }) }} />
                          </label>
                        </div>
                      </div>
                    })}
                  </> :
                  null
                }
              </div>
            </div>
          </div>
        </div>

      </Modal>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepShippingMethodInput));