import React, {forwardRef} from 'react';

import { ProductSortableItem } from './ProductSortableItem';

export const ProductSortableItemWrapper = forwardRef(({id, itemObj, itemType, objectType, ...props}, ref) => {

  return (
    <div {...props} ref={ref}>
      <ProductSortableItem 
        noid={id}
        inMotion={true}
        itemObj={itemObj}
        itemType={itemType}
        objectType={objectType} />
    </div>
  )
});