import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Dropzone from 'react-dropzone';
import Papa from 'papaparse';

import { 
  ERROR_FILE_BULK_CSV_UPLOAD_INVALID,
  ERROR_FILE_BULK_CSV_UPLOAD_MISSING,
  ERROR_FILE_BULK_CSV_UPLOAD_MISSING_COLUMNS, 
  ERROR_FILE_BULK_CSV_UPLOAD_SINGLE,
} from '../../../constants/errors';
import { 
  ICON_CHEVRON_DOWN,
  ICON_DOWNLOAD,
  ICON_FILE,
} from '../../../constants/icons';
import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../../constants/urls';

import { CustomerBulkUploadFormat } from '../../../models/users';

import { 
  getErrorBulkCSVImport,
} from '../../../utils/files';
import {
  getNotEmptyError,
  isFormValid,
} from '../../../utils/form-validation';
import { formatFilesize } from '../../../utils/formatting';

import { Icon } from '../../Icons/Icon';
import Toggle from '../../Input/Toggle';

import * as productLineActionCreators from '../../../actions/product-line';
const allActionCreators = Object.assign({}, productLineActionCreators);

export class CustomerBulkUploadInput extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputCreateCustomer: false, 
      inputAddQtyDelta: false, 
      inputCSVFile: null,

      errorCSVFile: '',
      errorCSVSchema: '', 
      errorCSVSchemaColumns: [], 

      dragCount: 0,

      guidanceOpen: false,
    };

    this.uploadFormat = CustomerBulkUploadFormat.getByKey();
  }

  dragEnterInput(evt) {
    for(const tp of evt.dataTransfer.types) {
      if(tp === 'Files') {
        this.setState({ dragCount: this.state.dragCount + 1 });
        break;
      }
    }
  }

  dragLeaveInput(evt) {
    this.setState({ dragCount: Math.max(this.state.dragCount - 1, 0) });
  }

  async dragDropInput(files) {

    if(files.length > 1) {
      this.setState({
        errorCSVFile: ERROR_FILE_BULK_CSV_UPLOAD_SINGLE,
        dragCount: 0,
      });
      return null;
    }

    if(files.length === 1) {
      const fileError = getErrorBulkCSVImport(files[0], 10);
      this.setState({
        inputCSVFile: fileError === '' ? files[0] : null,
        errorCSVFile: fileError,
        dragCount: 0,
      }, () => {

        if(this.state.inputCSVFile) {

          Papa.parse(this.state.inputCSVFile, {
            worker: true,
            header: false,
            skipEmptyLines: true,
            preview: 1,
            complete: (results) => {

              try {

                const missingFields = [];
                const schema = this.uploadFormat.schema;

                for(const req of schema) {
                  const headerKeys = results.data[0];
                  if(req.required && !headerKeys.includes(req.key)) {
                    missingFields.push(req.key);
                  }
                }

                if(missingFields.length > 0) {
                  this.setState({
                    inputCSVFile: null,
                    errorCSVSchema: ERROR_FILE_BULK_CSV_UPLOAD_MISSING_COLUMNS,
                    errorCSVSchemaColumns: missingFields,
                  });
                } else {
                  this.setState({
                    errorCSVSchema: '',
                    errorCSVSchemaColumns: [],
                  });
                }
                
              } catch(err) {
                this.setState({
                  inputCSVFile: null,
                  errorCSVFile: ERROR_FILE_BULK_CSV_UPLOAD_INVALID,
                });
              }
            },
            error: (err) => {
              this.setState({
                inputCSVFile: null,
                errorCSVFile: ERROR_FILE_BULK_CSV_UPLOAD_INVALID,
              });
            },
          });
        }
      });
    }
  }

  removeFile(evt) {
    evt.stopPropagation();
    this.setState({
      inputCSVFile: null,
      errorCSVFile: '',
      errorCSVSchema: '', 
      errorCSVSchemaColumns: [],
    });
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll(true)) {
      this.props.initiateUpload(this.state.inputCSVFile, !this.state.inputAddQtyDelta, this.state.inputCreateCustomer);
    }
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorCSVFile: getNotEmptyError(this.state.inputCSVFile, ERROR_FILE_BULK_CSV_UPLOAD_MISSING) || getErrorBulkCSVImport(this.state.inputCSVFile, 10),
      errorCSVSchema: this.state.errorCSVSchema,
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  toggleGuidance() {
    this.setState({ guidanceOpen: !this.state.guidanceOpen });
  }

  downloadTemplate() {

    if(!this.uploadFormat) { return null; }

    const firstRow = {};
    for(const row of this.uploadFormat.schema) {
      firstRow[row.key] = '';
    }

    const template = Papa.unparse([ firstRow ]);
    const templateData = new Blob([ template ], { type: 'text/csv;charset=utf-8;' });
    const templateURL = navigator.msSaveBlob ? navigator.msSaveBlob(templateData, `customer-template.csv`) : window.URL.createObjectURL(templateData);

    let tempLink = document.createElement('a');
    tempLink.href = templateURL;
    tempLink.setAttribute('download', `customer-template.csv`);
    tempLink.click();
  }

  changeCreateCustomer(evt) {
    this.setState({ inputCreateCustomer: !this.state.inputCreateCustomer });
  }

  changeAddQtyType(evt) {
    this.setState({ inputAddQtyDelta: !this.state.inputAddQtyDelta });
  }

  render() {

    const {t} = this.props;

    return <div className={'CustomerBulkUploadInput'}>
      <div className='adminForm'>
        <form 
          className='bulkUploadInputForm'
          onSubmit={this.saveAction.bind(this)}>

          <div className={'adminFieldWrapper firstConfigElement'}>
            <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_CUSTOMER_CREATE_NEW_USERS)}</div>
            <div className='adminInputWrapper'>
              <div className='adminInputToggleWrapper'>
                <Toggle
                  checked={this.state.inputCreateCustomer}
                  disabled={false}
                  onToggle={this.changeCreateCustomer.bind(this)}
                  trueValue={tx.TX_CUSTOMER_CREATE_NEW_USERS_TRUE}
                  falseValue={tx.TX_CUSTOMER_CREATE_NEW_USERS_FALSE} />
              </div>
              {this.state.inputCreateCustomer ?
                <div className={'FieldNotice fieldSupplement subtleSupplement'}>{t(tx.TX_CUSTOMER_CREATE_NEW_USERS_NOTICE)}</div> :
                null
              }
            </div>
          </div>

          <div className={'adminFieldWrapper firstConfigElement'}>
            <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ORDER_STORE_CREDIT)}</div>
            <div className='adminInputWrapper'>
              <div className='adminInputToggleWrapper'>
                <Toggle
                  checked={!this.state.inputAddQtyDelta}
                  disabled={false}
                  onToggle={this.changeAddQtyType.bind(this)}
                  trueValue={tx.TX_CUSTOMER_STORE_CREDIT_SET_TOTAL}
                  falseValue={tx.TX_CUSTOMER_STORE_CREDIT_ADD_TO_BALANCE} />
              </div>
              {this.state.inputAddQtyDelta ?
                <div className={'FieldNotice fieldSupplement subtleSupplement'}>{t(tx.TX_CUSTOMER_STORE_CREDIT_AMOUNT_CAN_BE_NEGATIVE)}</div> :
                null
              }
            </div>
          </div>

          <div className='uploadGuidanceWrapper'>
            <div className='guidanceHeader'>
              <div className='guidanceHeaderLiner' onClick={this.toggleGuidance.bind(this)}>
                <div className='ghIcon'>
                  <div className={this.state.guidanceOpen ? 'ghIconWrapper open' : 'ghIconWrapper'}>
                    <Icon value={ICON_CHEVRON_DOWN} />
                  </div>
                </div>
                <div className='ghLabel'>{t(tx.TX_INV_BULK_UPLOAD_SEE_REQUIREMENTS)}</div>
              </div>
            </div>
            <div className={this.state.guidanceOpen ? 'guidanceBody open' : 'guidanceBody'}>
              <div className='guidanceBodyLiner'>
                <div className='guidanceRowExplanation'>
                  <div className='schemaTable'>
                    <div className='schemaRow'>
                      <div className='cellKey headerCell'>{t(tx.TX_INV_BULK_UPLOAD_ROW_HEADER)} <span className='requiredWrapper'>{t(tx.TX_REQUIRED).toLowerCase()}</span></div>
                      <div className='cellDesc headerCell'></div>
                    </div>
                    {this.uploadFormat.schema.map((col, i) => {
                      return <div key={i} className='schemaRow'>
                        <div className='cellKey'>
                          <div className={col.required ? 'cellKeyWrapper required' : 'cellKeyWrapper'}>{col.key}</div>
                        </div>
                        <div className='cellDesc'>{t(col.description)}</div>
                      </div>
                    })}
                  </div>
                </div>
                <div className='templateWrapper' onClick={this.downloadTemplate.bind(this)}>
                  <div className='templateLabel'>{t(tx.TX_INV_BULK_UPLOAD_DOWNLOAD_TEMPALTE)}</div>
                  <div className='templateIcon'>
                    <Icon value={ICON_DOWNLOAD} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='fileDropzoneWrapper'>
            <Dropzone onDrop={acceptedFiles => this.dragDropInput(acceptedFiles)} disabled={this.state.inputCSVFile !== null}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div 
                      ref={this.fileDropRef}
                      className={this.state.dragCount ? 'dropWrapper hoverFile' : 'dropWrapper'}
                      onDragEnter={this.dragEnterInput.bind(this)}
                      onDragLeave={this.dragLeaveInput.bind(this)}>

                      {this.state.inputCSVFile ?
                        <div className='dropFileDisplay'>
                          <div className='dropFileIconWrapper'>
                            <Icon value={ICON_FILE} />
                          </div>
                          <div className='dropFileDetailsWrapper'>
                            <div className={'nameLabel EllipsisElement'}>{this.state.inputCSVFile.name}</div>
                            <div className='metaLabel'>{formatFilesize(this.state.inputCSVFile.size)}</div>
                            <div className='removeLabel' onClick={this.removeFile.bind(this)}>{t(tx.TX_REMOVE)}</div>
                          </div>
                          <div className='dropFileCloseWrapper' onClick={this.removeFile.bind(this)}>
                            <div className='FlexCenter'>&times;</div>
                          </div>
                        </div> :
                        <div className='dropPrompt'>{t(tx.TX_CUSTOMER_BULK_UPLOAD_DRAG_HERE)}</div>
                      }
                        
                      {this.state.errorCSVFile ?
                        <div className='dropError'>{t(this.state.errorCSVFile)}</div> :
                        null
                      }

                      {this.state.errorCSVSchema ?
                        <div className='dropError'>{t(this.state.errorCSVSchema, { columns: this.state.errorCSVSchemaColumns.join(', ') })}</div> :
                        null
                      }

                      {!this.state.inputCSVFile ?
                        <button 
                          className='dropButton'
                          type='button'>
                          {t(tx.TX_INV_BULK_UPLOAD_UPLOAD_CSV)}
                        </button> :
                        null
                      }
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          <div className='adminActionRow'>
            <Link 
              className={'adminAction adminActionCancel'} 
              to={URL_ADMIN_INVENTORY}>
              {t(tx.TX_CANCEL)}
            </Link>
            <button 
              className={'adminAction adminActionSave'} 
              type='submit'
              disabled={this.validateAll() === false}>
              {t(tx.TX_NEXT)}
            </button>
          </div>
        </form>
      </div>

        
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerBulkUploadInput));