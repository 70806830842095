import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { checkoutPrereqMet } from '../../../../../utils/checkout';

import CheckoutStepPaymentMethodInput from './CheckoutStepPaymentMethodInput';

let allActionCreators = Object.assign({});

export class CheckoutStepPaymentMethod extends Component {

  getStepComponent() {

    // If pre-requisites not met, do not display
    if(checkoutPrereqMet(this.props.config, this.props.checkout.stepData) === false) {
      return null;
    }

    return <CheckoutStepPaymentMethodInput 
            config={this.props.config}
            setStepData={this.props.setStepData} />;
  }

  render() {
    return <div className={'CheckoutStepPaymentMethod'}>{this.getStepComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepPaymentMethod));