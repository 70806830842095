import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';
import { URL_BUY_PRODUCT_LINE } from '../../../../constants/urls';

import { stringFormat } from '../../../../utils/formatting';

import BlockElementHorizontalProduct from '../BlockElementHorizontalProduct';
import { LoadingIcon } from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockTypeProductsVertical.module.scss';

import * as productActionCreators from '../../../../actions/product';
import * as productLineActionCreators from '../../../../actions/product-line';
const allActionCreators = Object.assign({}, productActionCreators, productLineActionCreators);

export class BlockMagicTopEDH extends Component {

  constructor(props) {
    super(props);

    this.FORMAT_KEY = 'commander';
    this.REQUEST_COUNT = 10;

    this.state = {
      loading: true,

      targetProducts: this.REQUEST_COUNT,

      productData: null,
      productLine: null,
    };

    this.bodyElement = React.createRef();
    this.scrollElement = React.createRef();

    // Controllers
    this.controller = null;
  }

  componentDidMount() {
    if(this.props.productLine.enabled && this.props.productLine.enabled.length) {
      const pl = this.getProductLine();
      if(pl) {
        this.setState({ productLine: pl }, () => {
          this.fetchTopEDHProducts();
        });
      }
    } else {
      this.props.productLineFetchEnabled()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.bodyElement && this.bodyElement.current && this.scrollElement && this.scrollElement.current) {
      if(this.scrollElement.current.getBoundingClientRect().height > this.bodyElement.current.getBoundingClientRect().height) {
        this.setState({ targetProducts: this.state.targetProducts - 1 });
      }
    }

    // If enabled product lines saved from elsewhere, fire request when saved
    if(!prevProps.productLine.enabled && this.props.productLine.enabled && this.props.productLine.enabled.length) {
      const pl = this.getProductLine();
      if(pl) {
        this.setState({ productLine: pl }, () => {
          this.fetchTopEDHProducts();
        });
      }
    }
  }

  getProductLine() {
    for(const pl of this.props.productLine.enabled) {
      if(pl.permalink === this.props.block.requiredProductLine) {
        return pl;
      }
    }
    return null;
  }

  async fetchTopEDHProducts() {

    if(!this.state.productLine) { return null; }
    
    // // Cancel any existing requests
    // if(this.controller) {
    //   this.controller.abort();
    // }
    // const controller = new AbortController();
    // this.controller = controller;

    // this.setState({ 
    //   loading: true, 
    // });

    // const productResp = await this.props.productTopInventory(this.state.productLine.permalink, this.FORMAT_KEY, {}, controller.signal)
    //   .catch((errResp) => {
    //     this.setState({
    //       productData: [],
    //       loading: false, 
    //     });
    //   });

    // if(!productResp) {
    //   return null;
    // }

    // this.setState({
    //   productData: productResp.data,
    //   loading: false, 
    // });
  }

  render() {

    let productsRendered = 0;
    const {t} = this.props;

    return <div className={`BlockMagicTopEDH ${styles.BlockTypeProductsVertical}`}>
      <div className={styles.btpvLiner}>
        <div className={this.props.blockStyles.blockTitleUnderline}>
          <div className='FlexCenter'>{this.props.block.isDefaultConfig('title') ? t(this.props.block.getConfigAttr('title')) : this.props.block.getConfigAttr('title')}</div>
        </div>

        <div className={this.props.blockStyles.blockBodyVertical} ref={this.bodyElement}>
          {this.state.loading ?
            <div className={this.props.blockStyles.verticalBodyIcon}>
              <LoadingIcon />
            </div> :
            <div className={this.props.blockStyles.blockBodyVerticalLiner} ref={this.scrollElement}>
              {this.state.productData && this.state.productData.length > 0 ?
                <>
                  {this.state.productData.map((prod, i) => {

                    if(productsRendered >= this.state.targetProducts) {
                      return null;
                    }
                    productsRendered++;

                    return <div key={i} className={styles.elementWrapper}>
                      <BlockElementHorizontalProduct
                        isBuylist={false}
                        product={prod} />
                    </div>
                  })}
                </> :
                <div className={styles.noResults}>
                  {t(tx.TX_GALLERY_NO_RESULTS)}
                </div>
              }
            </div>
          }
        </div>

        <div className={this.props.blockStyles.blockFooter}>
          <Link 
            to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: this.props.block.requiredProductLine })} 
            className={this.props.blockStyles.blockAction}>
            {this.state.productLine ? t(tx.TX_SHOP_NOUN, { noun: this.state.productLine.name }) : t(tx.TX_SHOP_NOW)}
          </Link>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockMagicTopEDH));