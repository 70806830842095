import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { utcToZonedTime } from 'date-fns-tz';

import {
  CAL_DISPLAY_TYPE_MOBILE,
} from '../../../constants/calendar';
import { 
  URL_PARAM_DAY,
  URL_PARAM_MONTH,
  URL_PARAM_RECURRING,
  URL_PARAM_VIEW, 
  URL_PARAM_YEAR,
} from '../../../constants/urls';

import { 
  getStoreTimeZone,
  urlQueryParams,
} from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';

import EventCalendarEventSummary from './EventCalendarEventSummary';

let allActionCreators = Object.assign({});

export class EventCalendarEventEntry extends Component {

  constructor(props) {
    super(props);

    this.openDelay = 150;

    this.state = {
      summaryOpen: false,
    }

    this.openTimeout = null;

    this.entryRef = React.createRef();
  }

  componentDidMount() {
    if(!this.props.eventObj) { return null; }

    const getParams = urlQueryParams();
    let shouldOpen = false;

    if(getParams[URL_PARAM_VIEW] && getParams[URL_PARAM_VIEW] === this.props.eventObj.publicUuid) {
      shouldOpen = true;
    } else if(getParams[URL_PARAM_RECURRING] && this.props.eventObj.schedule && getParams[URL_PARAM_RECURRING] === this.props.eventObj.schedule.publicUuid) {

      const startDay = getParams[URL_PARAM_DAY] || null;
      const startMonth = getParams[URL_PARAM_MONTH] || null;
      const startYear = getParams[URL_PARAM_YEAR] || null;

      const startDate = startDay !== null && startMonth && startYear ? new Date(startYear, startMonth, startDay) : null;

      if(startDate.getFullYear() === this.props.eventObj.startTime.getFullYear() && startDate.getMonth() === this.props.eventObj.startTime.getMonth() && startDate.getDate() === this.props.eventObj.startTime.getDate()) {
        shouldOpen = true;
      }
    }

    if(shouldOpen && this.entryRef && this.entryRef.current) {
      this.openTimeout = setTimeout(() => {
        this.openAction({ clientY: this.entryRef.current.getBoundingClientRect().top });
      }, this.openDelay);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.displayMode !== this.props.displayMode) {
      if(!this.isMobileView()) {
        this.toggleSummary(false);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.openTimeout);
  }

  isMobileView() {
    return this.props.displayMode === CAL_DISPLAY_TYPE_MOBILE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getFormattedTime(startTime) {
    const zonedTimeString = utcToZonedTime(startTime, getStoreTimeZone()).toLocaleString(this.getLanguage(), { hour: 'numeric', minute: 'numeric' });
    return zonedTimeString.replace(' PM', 'p').replace(' AM', 'a');
  }

  openAction(evt) {
    if(this.isMobileView()) {
      this.toggleSummary();
    } else {
      if(this.props.openModal && this.props.eventObj) {
        this.props.openModal(evt, this.props.eventObj, this.props.weekIndex, this.props.date);
      }
    }
  }

  toggleSummary(val) {
    const toggleVal = val === true || val === false ? val : !this.state.summaryOpen;
    this.setState({
      summaryOpen: toggleVal,
    });
  }

  getRootClass() {
    const baseClass = 'EventCalendarEventEntry';
    const adminClass = this.props.admin ? 'adminTheme' : '';
    const mobileClass = this.isMobileView() ? 'mobileView' : '';
    const permalinkClass = this.props.eventObj && this.props.eventObj.productLine && this.props.eventObj.productLine.permalink ? this.props.eventObj.productLine.permalink : '';

    return `${baseClass} ${adminClass} ${mobileClass} ${permalinkClass}`;
  }

  render() {

    return <div className={this.getRootClass()} onClick={this.openAction.bind(this)} ref={this.entryRef}>
      <div className='eceeLiner'>
        <div className='eceeLabel'>{this.props.eventObj.name}</div>
        <div className='eceeTimeWrapper'>{this.getFormattedTime(this.props.eventObj.startTime)}</div>
        <div className={this.state.summaryOpen && this.isMobileView() ? 'eceeSummaryWrapper expanded' : 'eceeSummaryWrapper'}>
          <div className='eceeSummary'>
            <EventCalendarEventSummary
              admin={this.props.admin}
              closeAction={() => this.toggleSummary(false)}
              date={this.props.date}
              displayMode={this.props.displayMode}
              eventObj={this.props.eventObj} />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventCalendarEventEntry));