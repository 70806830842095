
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import authReducer from './auth';
import cartReducer from './cart';
import checkoutReducer from './checkout';
import commonReducer from './common';
import eventReducer from './event';
import headerReducer from './header';
import productReducer from './product';
import productLineReducer from './product-line';
import storeReducer from './store';
import userReducer from './user';



const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  common: commonReducer,
  event: eventReducer,
  header: headerReducer,
  product: productReducer, 
  productLine: productLineReducer,
  store: storeReducer,
  user: userReducer,
})
export default createRootReducer