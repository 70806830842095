

// Config types

export const ANALYTICS_CONFIG_AGGREGATION_TYPE = 'aggregation_type';
export const ANALYTICS_CONFIG_LIMIT = 'limit';
export const ANALYTICS_CONFIG_METRIC = 'metric';
export const ANALYTICS_CONFIG_MODE = 'mode';
export const ANALYTICS_CONFIG_SHOW_ALL = 'show-all';
export const ANALYTICS_CONFIG_SHOW_TODAY = 'show-today';


// Config values
export const ANALYTICS_AGGREGATION_TYPE_PRODUCT = 'product';
export const ANALYTICS_AGGREGATION_TYPE_PRODUCT_LINE = 'product_line';

export const ANALYTICS_DURATION_UNIT_DAY = 'd';
export const ANALYTICS_DURATION_UNIT_WEEK = 'w';
export const ANALYTICS_DURATION_UNIT_MONTH = 'm';
export const ANALYTICS_DURATION_UNIT_YEAR = 'y';

export const ANALYTICS_METRIC_COUNT = 'count';
export const ANALYTICS_METRIC_COUNT_ITEMS = 'count_items';
export const ANALYTICS_METRIC_TOTAL_SALES = 'total_sales';

export const ANALYTICS_SALES_MODE_ORDERS = 'orders';
export const ANALYTICS_SALES_MODE_PRODUCT_LINES = 'product-lines';

export const ANALYTICS_DATA_DECIMAL_KEYS = [
	'total_sales',
];