import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_USER } from '../../../constants/icons';
import * as tx from '../../../constants/strings';
import { URL_LOGIN } from '../../../constants/urls';

import { Icon } from '../../Icons/Icon';

let allActionCreators = Object.assign({});

export class HeaderAccountLoggedOut extends Component {

  render() {
    const {t} = this.props;
    return <div className='HeaderAccountLoggedOut'>
      <Link to={URL_LOGIN}>
        <Icon 
          value={ICON_USER} 
          iconClass='accountIconWrapper' />
        <div className='accountCopyWrapper'>
          <div className='loggedOutWrapper'>{t(tx.TX_LOGIN_SHORT)}</div>
        </div>
      </Link>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderAccountLoggedOut));