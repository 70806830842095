import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import {
  getLanguagesOrderedDeduped, 
} from '../../../../utils/language';

import Dropdown from '../../../Input/Dropdown';

let allActionCreators = Object.assign({});

export class ProductPageInventoryConfigLanguage extends Component {

  getInventoryLanguages() {
    
    if(!this.props.productObj || !this.props.productObj.inventory) { return []; }

    const languageArray = [];
    for(const inv of this.props.productObj.inventory) {
      if(inv.language) {
        languageArray.push(inv.language);
      }
    }
    return getLanguagesOrderedDeduped(languageArray);
  }

  hasOptions(languageArray) {
    return languageArray && languageArray.length > 1;
  }

  getStaticOption(languageArray) {
    if(!languageArray || languageArray.length < 1) {
      return tx.TX_null;
    }
    return languageArray[0].nameTranslation;
  }

  formatOptions(languageArray) {
    if(!languageArray) {
      return [];
    }
    
    const valueOptions = [];
    for(const lang of languageArray) {
      valueOptions.push({
        display: lang.nameTranslation, 
        value: lang.code,
      });
    }

    valueOptions.unshift({
      display: tx.TX_PLACEHOLDER_SELECT_LANGUAGE,
      value: '',
    });
    return valueOptions;
  }

  getSelectedLanguageName() {
    return this.props.value ? this.props.value.nameTranslation : tx.TX_null;
  }

  render() {

    const {t} = this.props;
    const languages = this.getInventoryLanguages();

    return <div className={'ProductPageInventoryConfig ProductPageInventoryConfigLanguage'}>
      <div className='ppiConfigElementWrapper'>
        {this.hasOptions(languages) ?
          <div className='ppiConfigSelectable'>
            <div className='ppiConfigInlineLabel'>
              <div className='ppiConfigLabel'>{t(tx.TX_LANGUAGE)}</div>
              <div className='ppiSelectedValue'>{t(this.getSelectedLanguageName())}</div>
            </div>
            <div className='ppiConfigSelectWrapper'>
              <Dropdown 
                className='ppiConfigSelect'
                options={this.formatOptions(languages)}
                name={t(tx.TX_LANGUAGE)}
                value={this.props.value ? this.props.value.code : ''}
                required={true}
                onChange={this.props.setAttr.bind(this)} />
            </div>
          </div> :
          <div className='ppiConfigStatic'>
            <div className='ppiConfigLabel'>{t(tx.TX_LANGUAGE)}</div>
            <div className='ppiStaticValue'>{t(this.getStaticOption(languages))}</div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageInventoryConfigLanguage));