import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  CD_NEW, 
  CONDITIONS_PRODUCT_GENERIC, 
} from '../../../../constants/conditions';
import { 
  ERROR_CONDITION_MISSING, 
  ERROR_FINISH_MISSING, 
  ERROR_LANGUAGE_MISSING, 
  ERROR_PRINTING_MISSING, 
} from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';

import { 
  Product,
  Inventory, 
} from '../../../../models/products';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol,
} from '../../../../utils/currency';
import { 
  isFormValid, 
  getNotEmptyError, 
  getPriceError, 
  getQuantityError, 
} from '../../../../utils/form-validation';
import { 
  formatServerError,
  normalizeQuantity, 
  normalizePrice, 
} from '../../../../utils/formatting';
import { getAttrFinish } from '../../../../utils/product';

import LoadingIcon from '../../../Icons/LoadingIcon';

import * as productActionCreators from '../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class ProductDetailRowInventoryAdd extends Component {

  constructor(props) {
    super(props);

    this.initState = {

      rowLoading: false,

      editOpen: false,

      inputCondition: '',
      inputLanguage: '',
      inputFinish: '',
      inputPrinting: '', 
      inputPrice: '',
      inputQuantity: '',

      errorCondition: '',
      errorLanguage: '',
      errorFinish: '',
      errorPrinting: '', 
      errorPrice: '',
      errorQuantity: '',

      variantsData: [],
      variantsPending: false,

      seeAllLanguages: false, 

      requestPending: false,
      requestError: null,
    }

    this.state = this.initState;

    this.seeAllKey = 'see-all';

    this.controller = null;
    this.variantController = null;
  }

  componentDidMount() {
    if(this.props.product) {
      const finishes = getAttrFinish(this.props.product);
      if(finishes.length === 1) {
        this.setState({ inputFinish: finishes[0] });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.openSignal && this.props.openSignal !== prevProps.openSignal && this.state.editOpen === false) {
        this.startEdit();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
    if(this.variantController) {
      this.variantController.abort();
    }
  }

  async fetchVariants() {

    if(!this.props.product || !this.props.product.foreignModel) { return null; }

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.variantController = controller;

    this.setState({
      variantsPending: true,
    });

    // Get product variants
    const variantResp = await this.props.productFetchForeignVariants(this.props.product.permalink, this.props.product.productLine.permalink, controller.signal)
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          variantsPending: false,
          variantsData: [],
        });
      });

    if(!variantResp) { return null; }

    this.setState({
      variantsPending: false,
      variantsData: variantResp,
    });
  }

  getForeignModel() {
    try {

      if(this.state.variantsPending || this.state.variantsData.length === 0) {
        return null;
      }

      // Right now, just language; add more logic here if we add any other
      // variant-dependent inventory configurations, possibly finish
      for(const vt of this.state.variantsData) {
        if(this.state.inputLanguage.code === vt.languageObj.code) {
          return vt;
        }
      }
    } catch(err) {
      console.error(err);
      return null;
    }      
  }

  validateAll() {
    const errorObj = {
      errorCondition: this.getConditionError(),
      errorFinish: this.getFinishError(),
      errorPrinting: this.getPrintingError(),
      errorPrice: getPriceError(this.state.inputPrice),
      errorQuantity: getQuantityError(this.state.inputQuantity),
      errorLanguage: getNotEmptyError(this.state.inputLanguage, ERROR_LANGUAGE_MISSING),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async saveInventory(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const sealedValue = this.state.inputCondition.key === CD_NEW.key;

      const printing = this.isPrintingRequired() ? this.state.inputPrinting || this.getSelectablePrintings()[0] : null;

      const inventory = new Inventory({
        isSealed: sealedValue,
        isBuylist: this.props.product.isBuylist,
        condition: this.state.inputCondition,
        finish: this.state.inputFinish,
        printing: printing,
        language: this.state.inputLanguage, 
        sellPrice: this.state.inputPrice,
        totalQuantity: this.state.inputQuantity,
        foreignModel: this.getForeignModel(),
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });
    
      const controller = new AbortController();
      this.controller = controller;

      let invResp = null;
      if(!this.isNewOrphan()) {

        invResp = await this.props.productInventoryAdd(inventory.getCreateApiData(this.props.product), controller.signal)
          .catch((errResp) => {
            if(controller.signal.aborted) { return null; }
            console.error(errResp);

            this.setState({
              requestPending: false,
              requestError: formatServerError(errResp),
            });
          });
      } else {

        invResp = await this.props.productInventoryVariantAdd(inventory.getCreateApiData(this.props.product), this.props.product.permalink, this.props.product.productLine.permalink, controller.signal)
          .catch((errResp) => {
            if(controller.signal.aborted) { return null; }
            console.error(errResp);

            this.setState({
              requestPending: false,
              requestError: formatServerError(errResp),
            });
          });
      }

      if(!invResp) {
        return null;
      }

      this.setState({
        rowLoading: false,
      }, () => {

        const newProduct = new Product(this.props.product);
        newProduct.updateInventory(invResp);

        this.props.hotSwap(newProduct, 'permalink', newProduct.permalink);

        this.resetComponent();
      });
    }
  }

  changePrice(evt) {
    this.setState({
      inputPrice: evt.target.value,
    }, () => {
      if(this.state.errorPrice) {
        this.validatePrice(false);
      }
    });
  }

  validatePrice(normalize = true) {
    this.setState({ 
      inputPrice: normalize ? normalizePrice(this.state.inputPrice) : this.state.inputPrice, 
      errorPrice: getPriceError(this.state.inputPrice), 
    });
  }

  changeQuantity(evt) {
    this.setState({
      inputQuantity: evt.target.value,
    }, () => {
      if(this.state.errorQuantity) {
        this.validateQuantity(false);
      }
    });
  }

  validateQuantity(normalize = true) {
    this.setState({ 
      inputQuantity: normalize ? normalizeQuantity(this.state.inputQuantity) : this.state.inputQuantity,
      errorQuantity: getQuantityError(normalizeQuantity(this.state.inputQuantity)),
    });
  }

  changeCondition(evt) {

    if(evt.target.value === '') {
      this.setState({ inputCondition: evt.target.value }, () => {
        this.validateCondition();
      });
      return;
    }

    for(const co of CONDITIONS_PRODUCT_GENERIC) {
      if(co.key === evt.target.value) {
        this.setState({ inputCondition: co }, () => {
          this.validateCondition();
        });
        break;
      }
    }
  }

  getConditionError() {
    if(this.state.inputSealed === false) {
      if(!this.state.inputCondition) {
        return ERROR_CONDITION_MISSING;
      }
    }
    return '';
  }

  validateCondition() {
    this.setState({ errorCondition: this.getConditionError(this.state.inputCondition) });
  }

  getSelectableConditions() {
    if(!this.props.product || !this.props.product.productLine) { return []; }
    return this.props.product.productLine.getAllConditions();
  }

  changeFinish(evt) {

    if(evt.target.value === '') {
      this.setState({ inputFinish: evt.target.value }, () => {
        this.validateFinish();
      });
      return null;
    }

    for(const fn of getAttrFinish(this.props.product)) {
      if(fn.key === evt.target.value) {
        this.setState({ inputFinish: fn }, () => {
          this.validateFinish();
        });
        break;
      }
    }
  }

  getFinishError() {
    if(this.isFinishRequired() && !this.state.inputFinish) {
      return ERROR_FINISH_MISSING;
    }
    return '';
  }

  validateFinish() {
    this.setState({ errorFinish: this.getFinishError() });
  }

  isFinishRequired() {
    return getAttrFinish(this.props.product).length > 0;
  }

  getSelectableFinishes() {
    if(!this.props.product) { return []; }
    return getAttrFinish(this.props.product);
  }

  changePrinting(evt) {

    if(evt.target.value === '') {
      this.setState({ inputPrinting: evt.target.value }, () => {
        this.validatePrinting();
      });
      return null;
    }

    for(const pr of this.getSelectablePrintings()) {
      if(pr.key === evt.target.value) {
        this.setState({ inputPrinting: pr }, () => {
          this.validatePrinting();
        });
        break;
      }
    }
  }

  getPrintingError() {
    if(this.isPrintingRequired()) {
      if(this.getSelectablePrintings().length > 1 && !this.state.inputPrinting) {
        return ERROR_PRINTING_MISSING;
      }
    }
    return '';
  }

  validatePrinting() {
    this.setState({ errorPrinting: this.getPrintingError() });
  }

  isPrintingRequired() {
    return this.props.product.allPrintings().length > 0;
  }

  getSelectablePrintings() {
    if(!this.props.product) { return []; }
    return this.props.product.allPrintings();
  }

  changeLanguage(evt) {

    if(evt.target.value === '') {
      this.setState({ inputLanguage: evt.target.value });
      return;
    }

    if(evt.target.value === this.seeAllKey) {
      this.setState({ seeAllLanguages: true });
      return;
    }

    for(const ln of this.getSelectableLanguages()) {
      if(ln.code === evt.target.value) {
        this.setState({ inputLanguage: ln });
        break;
      }
    }
  }

  getSelectableLanguages() {

    try {

      if(!this.props.product || !this.props.product.productLine || this.state.variantsPending) { return []; }

      const codesSelected = [];
      const languageArray = [];

      if(this.state.seeAllLanguages) {
        return this.props.product.productLine.languages;
      }

      for(const vt of this.state.variantsData) {

        const lang = vt.languageObj;

        if(!codesSelected.includes(lang.code)) {
          codesSelected.push(lang.code);
          languageArray.push(lang);
        }
      }
      return languageArray.length > 0 ? languageArray : this.props.product.productLine.languages;

    } catch(err) {
      console.error(err);
    }
    return [];
  }

  setLoading(val) {
    if(this.state.rowLoading !== val) {
      this.setState({ rowLoading: val });
    }
  }

  startEdit() {
    this.setState({ editOpen: true }, () => {
      this.fetchVariants();
    });
  }

  resetComponent() {

    if(this.state.rowLoading) { return null; }

    const overrideObj = { variantsData: this.state.variantsData };
    if(this.props.product) {
      const finishes = getAttrFinish(this.props.product);
      if(finishes.length === 1) {
        overrideObj['inputFinish'] = finishes[0];
      }
    }
    this.setState(Object.assign({}, this.initState, overrideObj));
  }

  isNewOrphan() {

    if(this.state.variantsPending || !this.props.product || !this.state.inputLanguage || !this.state.seeAllLanguages) { return false; }

    for(const vt of this.state.variantsData) {
      const lang = vt.languageObj;
      if(lang.code === this.state.inputLanguage.code) {
        return false;
      }
    }
    return true;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductDetailRowInventoryAdd'}>
      <div className='addLiner'>
        {this.state.editOpen ?
          <div className='addFormWrapper'>
            <form className='addForm' noValidate={true} onSubmit={this.saveInventory.bind(this)}>
              
              <div className='inlineInputs'>

                <div className={'inlineFieldWrapper'}>
                  <div className={'inlineFieldLabel'}>{t(tx.TX_LANGUAGE)}</div>
                  <div className='inlineInputWrapper'>
                    <div className='inlineDropdown'>
                      <select
                        value={this.state.inputLanguage ? this.state.inputLanguage.code : ''}
                        onChange={this.changeLanguage.bind(this)}
                        required={true}>
                        <option value=''>{this.state.variantsPending ? t(tx.TX_INV_VIEW_PRODUCT_LANGUAGES_LOADING) : t(tx.TX_PLACEHOLDER_LANGUAGE_DROPDOWN)}</option>
                        {this.getSelectableLanguages().map((lang, i) => {
                          return <option key={i} value={lang.code}>{t(lang.nameTranslation)}</option>
                        })}
                        {this.props.product && this.props.product.foreignModel && this.props.product.foreignModel.allowOrphanCreation && !this.state.seeAllLanguages ?
                          <option value={this.seeAllKey}>{t(tx.TX_OTHER)}</option> :
                          null
                        }
                      </select>
                      <div className='arrow'>&#8964;</div>
                      {this.state.errorLanguage ?
                        <div className={'inlineError'}>{t(this.state.errorLanguage)}</div> :
                        null
                      }
                    </div>
                  </div>
                </div>

                <div className={'inlineFieldWrapper'}>
                  <div className={'inlineFieldLabel'}>{t(tx.TX_CONDITION)}</div>
                  <div className='inlineInputWrapper'>
                    <div className='inlineDropdown'>
                      <select
                        value={this.state.inputCondition ? this.state.inputCondition.key : ''}
                        onChange={this.changeCondition.bind(this)}
                        required={true}>
                        <option value=''>{t(tx.TX_PLACEHOLDER_PRODUCT_CONDITION)}</option>
                        {this.getSelectableConditions().map((cond, j) => {
                          return <option key={j} value={cond.key}>{t(cond.name)}</option>
                        })}
                      </select>
                      <div className='arrow'>&#8964;</div>
                      {this.state.errorCondition ?
                        <div className={'inlineError'}>{t(this.state.errorCondition)}</div> :
                        null
                      }
                    </div>
                  </div>
                </div>

                {this.isFinishRequired() ?
                  <div className={'inlineFieldWrapper'}>
                    <div className={'inlineFieldLabel'}>{t(tx.TX_FILTER_FINISH)}</div>
                    <div className='inlineInputWrapper'>
                      <div className='inlineDropdown'>
                        <select
                          value={this.state.inputFinish ? this.state.inputFinish.key : ''}
                          onChange={this.changeFinish.bind(this)}
                          required={true}>
                          <option value=''>{t(tx.TX_PLACEHOLDER_FINISH_DROPDOWN)}</option>
                          {this.getSelectableFinishes().map((fin, k) => {
                            return <option key={k} value={fin.key}>{t(fin.name)}</option>
                          })}
                        </select>
                        <div className='arrow'>&#8964;</div>
                        {this.state.errorFinish ?
                          <div className={'inlineError'}>{t(this.state.errorFinish)}</div> :
                          null
                        }
                      </div>
                    </div>
                  </div> :
                  null
                }

                {this.isPrintingRequired() && this.getSelectablePrintings().length > 1 ?
                  <div className={'inlineFieldWrapper'}>
                    <div className={'inlineFieldLabel'}>{t(tx.TX_PRINTING)}</div>
                    <div className='inlineInputWrapper'>
                      <div className='inlineDropdown'>
                        <select
                          value={this.state.inputPrinting ? this.state.inputPrinting.key : ''}
                          onChange={this.changePrinting.bind(this)}
                          required={true}>
                          <option value=''>{t(tx.TX_PLACEHOLDER_PRINTING_DROPDOWN)}</option>
                          {this.getSelectablePrintings().map((pr, k) => {
                            return <option key={k} value={pr.key}>{t(pr.name)}</option>
                          })}
                        </select>
                        <div className='arrow'>&#8964;</div>
                        {this.state.errorPrinting ?
                          <div className={'inlineError'}>{t(this.state.errorPrinting)}</div> :
                          null
                        }
                      </div>
                    </div>
                  </div> :
                  null
                }

                <div className={'inlineFieldWrapper'}>
                  <div className={'inlineFieldLabel'}>{t(tx.TX_PRICE)}</div>
                  <div className={'inlineInputWrapper'}>
                    <div className='currencyWrapper'>
                      <input
                        type='number'
                        min={0}
                        step={getCurrencyIncrement()}
                        className={this.state.errorPrice ? 'currencyInput InputError' : 'currencyInput'}
                        value={this.state.inputPrice}
                        onChange={this.changePrice.bind(this)}
                        onBlur={this.validatePrice.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                      <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                    </div>
                    {this.state.errorPrice ?
                      <div className={'inlineError'}>{t(this.state.errorPrice)}</div> :
                      null
                    }
                  </div>
                </div>


                <div className={'inlineFieldWrapper'}>
                  <div className={'inlineFieldLabel'}>{t(tx.TX_QUANTITY)}</div>
                  <div className={'inlineInputWrapper'}>
                    
                    <input
                      type='number'
                      min={0}
                      step={1}
                      className={this.state.errorQuantity ? 'quantityInput InputError' : 'quantityInput'}
                      value={this.state.inputQuantity}
                      onChange={this.changeQuantity.bind(this)}
                      onBlur={this.validateQuantity.bind(this)}
                      placeholder={t(tx.TX_PLACEHOLDER_QUANTITY)}
                      maxLength={32} />
                    {this.state.errorQuantity ?
                      <div className={'inlineError'}>{t(this.state.errorQuantity)}</div> :
                      null
                    }
                  </div>
                </div>

              </div>

              <div className='inlineActions'>
                <div className='actionsWrapper'>
                  <button className='saveButton addInvButton' type='submit'>{t(tx.TX_SAVE)}</button>
                  <button className='cancelButton addInvButton' type='button' onClick={this.resetComponent.bind(this)}>&times;</button>
                </div>
              </div>

            </form>
          </div> :
          <div className='addPromptWrapper'>
            <div className='addPrompt' onClick={this.startEdit.bind(this)}>
              <div className='addPlus'>
                <div className={'plusChar FlexCenter'}>+</div>
              </div>
              <div className='addCopy'>{t(tx.TX_INVENTORY)}</div>
            </div>
          </div>
        }

        {this.state.rowLoading ?
          <div className='loadingWrapper'>
            <div className='loadingIcon'>
              <LoadingIcon />
            </div>
            <div className='loadingValue'>{t(tx.TX_INV_UPDATING_INVENTORY)}</div>
          </div> :
          null
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailRowInventoryAdd));