

import * as tx from './strings';

export const CD_NEW = {
	key: 'NEW',
	value: null,
	short: tx.TX_CONDITION_NEW_ABRV,
	name: tx.TX_CONDITION_NEW,
	description: '',
	exportName: 'Unopened',
}

export const CD_M = {
	key: 'M',
	value: 'M',
	short: tx.TX_CONDITION_MINT_ABRV,
	name: tx.TX_CONDITION_MINT,
	description: '',
	exportName: 'Mint',
}

export const CD_NM = {
	key: 'NM',
	value: 'NM',
	short: tx.TX_CONDITION_NEAR_MINT_ABRV,
	name: tx.TX_CONDITION_NEAR_MINT,
	description: '',
	exportName: 'Near Mint',
	fontColor: '#000000',
}

export const CD_LP = {
	key: 'LP',
	value: 'LP',
	short: tx.TX_CONDITION_LIGHTLY_PLAYED_ABRV,
	name: tx.TX_CONDITION_LIGHTLY_PLAYED,
	description: '',
	exportName: 'Lightly Played',
	fontColor: '#C300FF',
}

export const CD_MP = {
	key: 'MP',
	value: 'MP',
	short: tx.TX_CONDITION_MODERATELY_PLAYED_ABRV,
	name: tx.TX_CONDITION_MODERATELY_PLAYED,
	description: '',
	exportName: 'Moderately Played',
	fontColor: '#FF5EA4',
}

export const CD_HP = {
	key: 'HP',
	value: 'HP',
	short: tx.TX_CONDITION_HEAVILY_PLAYED_ABRV,
	name: tx.TX_CONDITION_HEAVILY_PLAYED,
	description: '',
	exportName: 'Heavily Played',
	fontColor: '#00AAFF',
	buylistExclude: true,
}

export const CD_D = {
	key: 'D',
	value: 'DMG',
	short: tx.TX_CONDITION_DAMAGED_ABRV,
	name: tx.TX_CONDITION_DAMAGED,
	description: '',
	exportName: 'Damaged',
	fontColor: '#00991C',
	buylistExclude: true,
}


export const CONDITIONS_ALL = [ CD_NEW, CD_M, CD_NM, CD_LP, CD_MP, CD_HP, CD_D ];
export const CONDITIONS_PRODUCT_GENERIC = [ CD_NM, CD_LP, CD_MP, CD_HP, CD_D ];
export const CONDITIONS_PRODUCT_PAGE_GENERIC = [ CD_NEW, CD_NM, CD_LP, CD_MP, CD_HP, CD_D ];
export const CONDITIONS_PRODUCT_TCG = [ CD_NM, CD_LP, CD_MP, CD_HP, CD_D ];


