

import {
	CUSTOMERS_BULK_UPLOAD_FORMAT_KEY_TITAN,
	CUSTOMERS_BULK_UPLOAD_FORMATS_ALL,
	CUSTOMERS_BULK_UPLOAD_STATUS_SUCCESS,
	CUSTOMERS_BULK_UPLOAD_STATUS_ERROR,
	CUSTOMERS_BULK_UPLOAD_STATUS_SKIPPED,
	CUSTOMERS_BULK_UPLOAD_STATUSES,
} from '../constants/customers';
import { 
	LANG_EN, 
	LANG_ZH, 
} from '../constants/languages';
import { TX_null } from '../constants/strings';

import { RefundRecord } from './payment-methods';
import { Order } from './orders';

import { getCurrencyMinorCount } from '../utils/currency';
import { 
	formatServerError,
	latinCount,
} from '../utils/formatting';


export class User {

	constructor(props) {

		// As props, should accept another User object or a server resp
		if(!props) { 
			props = {}; 
		}

		const emailValue = props.email || props.email_address || '';
		const storeCreditObj = props.storeCredit || props.store_credit || null;

		const lastLoginValue = props.lastLogin || props.last_login || '';
		const registeredDateValue = props.registeredDate || props.registered_date || '';

		this.id = props.id || null;
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.firstName = props.firstName || props.first_name || '';
		this.lastName = props.lastName || props.last_name || '';
		this.email = emailValue.trim().toLowerCase();
		this.phone = props.phone || props.phone_number || '';

		this.isActive = true;
		if(props.is_active === false) {
			this.isActive = false;
		} else if(props.isActive === false) {
			this.isActive = false;
		}

		this.lastLogin = lastLoginValue ? new Date(lastLoginValue) : null;
		this.registeredDate = registeredDateValue ? new Date(registeredDateValue) : null;

		this.permissionsObj = props.permissionsObj || props.permissions || {};
		this.storeCredit = new StoreCreditEntry(storeCreditObj);
	}

	get permissions() {
		return this.permissionsObj;
	}

	isGuest() {
		return false;
	}

	fullName(language = LANG_EN) {
		if(language.includes(LANG_ZH)) {
			if(latinCount(this.firstName + this.lastName) === 0) {
				return `${this.lastName}${this.firstName}`;
			} else {
				return `${this.lastName} ${this.firstName}`;
			}
		}
		return `${this.firstName} ${this.lastName}`;
	}

	getApiData() {
		const apiData = {
			first_name: this.firstName,
			last_name: this.lastName,
			email_address: this.email,
			phone_number: this.phone,
		};
		if(this.publicUuid) {
			apiData['public_uuid'] = this.publicUuid;
		}
		return apiData;
	}
}


export class GuestUser extends User {

	constructor(props) {
		
		if(!props) { props = {}; }
		super(props);
	}

	isGuest() {
		return true;
	}

	get permissions() {
		return {};
	}
}


export class StoreCreditEntry {

	constructor(props) {

		// As props, should accept another StoreCreditEntry object or a server resp
		if(!props) { 
			props = {}; 
		}

		const orderObj = props.order || null;
		const refundObj = props.refund || null;
		const userObj = props.user || null;
		const updatedByObj = props.updatedBy || props.updated_by || null;
		const bulkUploadObj = props.bulkUpload || props.bulk_upload || null;

		const balanceValue = parseInt(props.balance) || 0;
		const deltaValue = parseInt(props.delta) || 0;

		this.balance = balanceValue ? parseFloat(balanceValue / getCurrencyMinorCount()) : 0;
		this.delta = deltaValue ? parseFloat(deltaValue / getCurrencyMinorCount()) : 0;

		this.note = props.note || '';
		this.createDate = props.createDate || props.create_date || '';

		this.date = new Date(this.createDate);
		this.order = orderObj ? new Order(orderObj) : null;
		this.refund = refundObj ? new RefundRecord(refundObj) : null;
		this.updatedBy = updatedByObj ? new User(updatedByObj) : null;
		this.user = userObj ? new User(userObj) : null;
		this.bulkUpload = bulkUploadObj ? new CustomerBulkUpload(bulkUploadObj) : null;
	}
}


export class CustomerBulkUpload {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.records = parseInt(props.records) || 0;
		this.createDate = props.createDate || props.create_date || '';
		
		this.countUserCreated = parseInt(props.countUserCreated) || parseInt(props.user_create_count) || 0;
		this.countUserUpdated = parseInt(props.countUserUpdated) || parseInt(props.user_update_count) || 0;

		this.countError = parseInt(props.countError) || parseInt(props.count_error) || parseInt(props.total_error) || 0;
		this.countSkipped = parseInt(props.countSkipped) || parseInt(props.count_skipped) || parseInt(props.total_skipped) || 0;

		this.date = new Date(this.createDate);
	}

	get countSuccess() {
		if(!this.records) { return 0; }
		return this.records - this.countError - this.countSkipped;
	}
}


export class CustomerBulkUploadFormat {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.key = props.key || null;
		this.name = props.name || '';
		this.schema = props.schema || [];
	}

	static getByKey(formatKey) {
		const lookupKey = formatKey || CUSTOMERS_BULK_UPLOAD_FORMAT_KEY_TITAN;

		for(const format of CUSTOMERS_BULK_UPLOAD_FORMATS_ALL) {
			if(format.key === lookupKey) {
				return format;
			}
		}
		return null;
	}
}



export class CustomerBulkUploadResult {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.statusValue = props.status || '';

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.serverError = props.error || '';
		this.lineData = props.lineData || props.import_data || {};
		this.lineNumber = props.lineNumber || props.line_number || {};
		this.debugData = props.debugData || props.debug_data || {};
	}

	get status() {
		if(!this.statusValue) { return {}; }
		for(const statusObj of CUSTOMERS_BULK_UPLOAD_STATUSES) {
			if(statusObj.key === this.statusValue) {
				return statusObj;
			}
		}
		return {};
	}

	get message() {
		if(!this.statusValue) { return TX_null; }
		if((this.isError || this.isSkipped) && this.serverError) {
			return formatServerError(this.serverError);
		}
		return TX_null;
	}

	get errorCode() {
		try {
			return this.serverError && this.serverError.code ? this.serverError.code : '';
		} catch(err) {
			return '';
		}
	}

	get isSuccess() {
		return this.statusValue === CUSTOMERS_BULK_UPLOAD_STATUS_SUCCESS.key;
	}

	get isError() {
		return this.statusValue === CUSTOMERS_BULK_UPLOAD_STATUS_ERROR.key;
	}

	get isSkipped() {
		return this.statusValue === CUSTOMERS_BULK_UPLOAD_STATUS_SKIPPED.key;
	}

	hasDebugData() {
		return Object.keys(this.debugData).length > 0;
	}

	containsData(needle, config = {}) {

		if(!needle) { return false; }

		const searchTerm = needle.toLowerCase();

		for(const dataKey in this.lineData) {
			if(this.lineData[dataKey].toLowerCase().includes(searchTerm)) {
				return true;
			}
		}
		return false;
	}
}



















