import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const allActionCreators = Object.assign({});

export class PrivacyOasisChile extends Component {

  constructor(props) {
    super(props);

    this.storeName = this.props.user.store.name || process.env.REACT_APP_WEBSITE_NAME;
  }

  render() {
    return <div className={'PrivacyOasisChile legalBody'}>
      <div className='legalSection'>
        <div className='sectionTitle'>Tu privacidad</div>
        <div className='sectionP'>{this.storeName} se compromete a proteger tu privacidad. Solamente usaremos la información que nos entregas para procesar pedidos, proveer apoyo y actualizaciones sobre nuestros productos. Por favor continúe leyendo para más detalles sobre nuestra política de privacidad.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>¿Qué información obtenemos de usted? ¿Cómo la usamos?</div>
        <div className='sectionP'>Cuando realizas un pedido; necesitamos saber tu nombre, dirección de email, dirección de envío, número de la tarjeta de crédito y fecha de expiración de la misma. Esto nos permite procesar y verificar tu pedido así como notificarte del estado de este. Cuando entres a algún concurso o algún otro evento promocional, también podríamos pedir tu nombre, dirección y dirección de email para que podamos administrar el concurso y notificar a los ganadores. De esta manera nos permites añadirte a nuestra lista de envíos para futuras promociones. Cuando ordenas a través de nuestro sitio web estás optando en recibir notificaciones sobre promociones, cupones e importantes anuncios de nuestra compañía. Todos los emails son enviados a través de <a className='inlineLink' href='mailto:contacto@mtgoasis.cl'>contacto@mtgoasis.cl</a> y tú puedes optar para no seguir recibiendo notificaciones de nosotros o de nuestros colaboradores.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>¿Cómo es que {this.storeName} protege la información de sus clientes?</div>
        <div className='sectionP'>Utilizamos un servidor seguro para obtener toda tu información personal. La capa del servidor seguro (SSL) encripta toda la información que tú ingresas antes de que sea enviada a nosotros. Además toda la información que recibimos de nuestros clientes es protegida en contra de acceso no autorizado.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Acerca de los "Cookies"</div>
        <div className='sectionP'>Los Cookies son pequeñas piezas de información que son almacenadas por tu navegador en el disco duro del computador. No utilizamos cookies para obtener o almacenar información sobre nuestros visitantes o clientes.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>{this.storeName} revela la información recibida a terceros?</div>
        <div className='sectionP'>{this.storeName} no vende, intercambia o renta tu información personal a compañías no asociadas con {this.storeName}. {this.storeName} podría proveer estadísticas acerca de nuestros clientes, ventas, patrones de tráfico e información relacionada con nuestro sitio web a otros vendedores de prestigio, pero esas estadísticas no incluirán información que permita identificarte como persona.</div>
        <div className='sectionP'>{this.storeName} podría otorgar información sobre su cuenta cuando creamos, de buena forma, que tal acto es obligatoriamente necesario para (I) cumplir con la ley, (II) reforzar o aplicar los términos o acuerdos con cualquiera de nuestros usuarios o (III) proteger los derechos, propiedad o seguridad de {this.storeName}, nuestros usuarios u otros.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>En resumen</div>
        <div className='sectionP'>{this.storeName} está comprometido en proteger tu privacidad. Utilizamos la información que obtenemos en el sitio para hacer que tus compras en mtgoasis.cl sean tan simple como sea posible y para mejorar tu experiencia de compras en general. No vendemos, intercambiamos o rentamos tu información personal a otros.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Tu consentimiento</div>
        <div className='sectionP'>Al usar nuestro sitio web permites la recopilación y uso de esta información por {this.storeName}. Si decidimos cambiar nuestra política de privacidad pondremos esos cambios en esta página para que siempre estés consciente de qué información obtenemos, cómo la usamos y sobre qué circunstancias podría ser revelada.</div>
        <div className='sectionP'>{this.storeName} también posee vínculos con sitios asociados. La política de privacidad de esos sitios son responsabilidad de ellos y {this.storeName} no tiene control o influencia sobre sus políticas. Por favor revisa la política de cada sitio que visitas para información específica. {this.storeName} no se considera responsable por daño o errores de otros sitios web.</div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PrivacyOasisChile));