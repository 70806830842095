import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import {
  ICON_CHECK,
  ICON_EDIT,
} from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';

import { ProductLinePricingMatrixRule } from '../../../../models/products';

import {
  getNotEmptyError, 
  getPercentError,
  isFormValid,
} from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';

import Confirm from '../../../Popups/Confirm';
import Dropdown from '../../../Input/Dropdown';
import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';

import * as productLineActionCreators from '../../../../actions/product-line';
const allActionCreators = Object.assign({}, productLineActionCreators);

export class ProductLinePricingMatrixRuleEdit extends Component {

  constructor(props) {
    super(props);

    this.ALL_KEY = 'all';

    this.isNewRule = this.props.rule && !this.props.rule.publicUuid;

    this.state = this.getInitState();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevProps.rule, this.props.rule)) {
      this.setState(this.getInitState());
    }
  }

  getInitState() {
    return {
      rowOpen: this.isNewRule,

      deletePromptOpen: false,

      inputLanguage: this.props.rule.language === null ? null : this.props.rule.language || '',
      inputCondition: this.props.rule.condition === null ? null : this.props.rule.condition || '',
      inputSellPercent: this.props.rule.percentSell || '',
      inputBuyPercent: this.props.rule.percentBuy || '',

      errorLanguage: '',
      errorCondition: '',
      errorSellPercent: '',
      errorBuyPercent: '',

      requestPending: false,
      requestError: '',
    };
  }

  changeLanguage(evt) {

    if(evt.target.value === '') {
      this.setState({ inputLanguage: evt.target.value });
      return;
    }

    if(evt.target.value === this.ALL_KEY) {
      this.setState({ inputLanguage: null }, () => {   
        if(this.state.inputCondition) {
          for(const op of this.getConditionOptions()) {
            if(op.value === this.state.inputCondition.key) {
              this.setState({ inputCondition: '' });
            }
          }
        }
      });
      return;
    }

    for(const ln of this.props.productLineObj.languages) {
      if(ln.code === evt.target.value) {
        this.setState({ inputLanguage: ln }, () => {
          if(this.state.inputCondition) {
            for(const op of this.getConditionOptions()) {
              if(op.value === this.state.inputCondition.key) {
                this.setState({ inputCondition: '' });
              }
            }
          }
        });
        break;
      }
    }
  }

  getLanguageOptions() {

    const placeholder = [{
      display: tx.TX_PLACEHOLDER_SELECT_LANGUAGE,
      value: '',
    }];

    const selectableOptions = [];

    const availableConditions = this.props.productLineObj.getAllConditions();

    for(const ln of this.props.productLineObj.languages) {
      
      let foundCount = 0;

      for(const ru of this.props.priceMatrix.rules) {
        if(ru.language && ru.language.code === ln.code) {
          foundCount++;
        }
      }
      if(foundCount < availableConditions.length) {
        selectableOptions.push({
          display: ln.nameTranslation,
          value: ln.code,
        });
      }
    }

    let foundNull = 0;
    for(const ru of this.props.priceMatrix.rules) {
      if(ru.language === null && ru.condition !== null) {
        foundNull++;
      }
    }

    if(foundNull < availableConditions.length) {
      selectableOptions.push({
        display: tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_ALL_OTHER,
        value: this.ALL_KEY,
      });
    }

    return placeholder.concat(selectableOptions);
  }

  getLanguageValue() {
    if(this.state.inputLanguage === '') {
      return this.state.inputLanguage;
    } else if(this.state.inputLanguage === null) {
      return this.ALL_KEY;
    } else if(this.state.inputLanguage && this.state.inputLanguage.code) {
      return this.state.inputLanguage.code;
    }
    return '';
  }

  changeCondition(evt) {

    if(evt.target.value === '') {
      this.setState({ inputCondition: evt.target.value });
      return;
    }

    if(evt.target.value === this.ALL_KEY) {
      this.setState({ inputCondition: null });
      return;
    }

    for(const co of this.props.productLineObj.getAllConditions()) {
      if(co.key === evt.target.value) {
        this.setState({ inputCondition: co });
        break;
      }
    }
  }

  getConditionOptions() {

    const placeholder = [{
      display: tx.TX_PLACEHOLDER_CONDITION_SELECT,
      value: '',
    }];

    const foundConditionKeys = [];
    for(const cd of this.props.productLineObj.getAllConditions()) {
      
      if(this.state.inputLanguage === null) {
        // All other selected
        for(const ru of this.props.priceMatrix.rules) {
          if(ru.condition && ru.language === null && ru.condition.key === cd.key) {
            foundConditionKeys.push(cd.key);
          }
        }
      } else if(this.state.inputLanguage) {
        // Language selected
        for(const ru of this.props.priceMatrix.rules) {
          if(ru.condition && ru.language && ru.condition.key === cd.key && ru.language.code === this.state.inputLanguage.code) {
            foundConditionKeys.push(cd.key);
          }
        }
      } else {
        // No language selected
        // Don't need to do anything, just here for completeness
      }
    }

    // Now that we've found the conditions taken, loop through and make options
    const selectableOptions = [];
    for(const cd of this.props.productLineObj.getAllConditions()) {
      if(!foundConditionKeys.includes(cd.key)) {
        selectableOptions.push({
          display: cd.name,
          value: cd.key,
        });
      }
    }

    return placeholder.concat(selectableOptions);
  }

  getConditionValue() {
    if(this.state.inputCondition === '') {
      return this.state.inputCondition;
    } else if(this.state.inputCondition === null) {
      return this.ALL_KEY;
    } else if(this.state.inputCondition && this.state.inputCondition.key) {
      return this.state.inputCondition.key;
    }
    return '';
  }

  changeSellPercent(evt) {
    this.setState({
      inputSellPercent: evt.target.value,
    }, () => {
      if(this.state.errorSellPercent) {
        this.validateSellPercent();
      }
    });
  }

  validateSellPercent() {
    this.setState({ 
      errorSellPercent: getPercentError(this.state.inputSellPercent, false, 1, 100),
    });
  }

  changeBuyPercent(evt) {
    this.setState({
      inputBuyPercent: evt.target.value,
    }, () => {
      if(this.state.errorBuyPercent) {
        this.validateBuyPercent();
      }
    });
  }

  validateBuyPercent() {
    this.setState({ 
      errorBuyPercent: getPercentError(this.state.inputBuyPercent, false, 0, 100),
    });
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorLanguage: getNotEmptyError(this.state.inputLanguage !== null ? this.state.inputLanguage : this.ALL_KEY),
      errorCondition: getNotEmptyError(this.state.inputCondition !== null ? this.state.inputCondition : this.ALL_KEY),
      errorSellPercent: getPercentError(this.state.inputSellPercent, false, 1, 100),
      errorBuyPercent: getPercentError(this.state.inputBuyPercent, false, 0, 100),
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  async saveRow(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll(true)) {

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const rule = new ProductLinePricingMatrixRule(this.props.rule);
      rule.language = this.state.inputLanguage;
      rule.condition = this.state.inputCondition;
      rule.percentSell = parseFloat(this.state.inputSellPercent);
      rule.percentBuy = parseFloat(this.state.inputBuyPercent);

      if(this.isNewRule) {

        const addResp = await this.props.productLineAddPricingMatrixRule(rule.getCreateApiData(), this.props.productLineObj.permalink, this.props.priceMatrix.publicUuid)
          .catch((errResp) => {
            console.error(errResp);
            this.setState({ 
              requestPending: false,
              requestError: formatServerError(errResp),
            });
          });      

        if(!addResp) { return null; }

      } else {

        const updateResp = await this.props.productLineUpdatePricingMatrixRule(rule.getUpdateApiData(), this.props.productLineObj.permalink, this.props.rule.publicUuid)
          .catch((errResp) => {
            console.error(errResp);
            this.setState({ 
              requestPending: false,
              requestError: formatServerError(errResp),
            });
          });      

        if(!updateResp) { return null; }
      }

      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        this.props.makeClean('', false, false);
      });
    }
  }

  singleError() {
    return this.state.errorLanguage || this.state.errorCondition || this.state.errorSellPercent || this.state.errorBuyPercent || this.state.requestError || '';
  }

  openRow() {
    this.setState(this.getInitState(), () => {
      this.setState({ rowOpen: true });
    });
  }

  closeRow() {
    if(this.props.parentCloseMethod) {
      this.props.parentCloseMethod();
    } else {
      this.setState(this.getInitState());
    }
  }

  toggleDeletePrompt() {
    this.setState({ deletePromptOpen: !this.state.deletePromptOpen });
  }

  async deleteAction() {

    this.setState({
      requestPending: true,
      requestError: null,
    });

    const updateResp = await this.props.productLineDeletePricingMatrixRule(this.props.productLineObj.permalink, this.props.rule.publicUuid)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });
      });      

    if(!updateResp) { return null; }

    this.setState({
      requestPending: false,
      requestError: null,
    }, () => {
      this.props.makeClean('', false, false);
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductLinePricingMatrixRuleEdit'}>
      <div className='priceRuleWrapper'>
        {this.state.rowOpen ?
          <div className='rowOpenWrapper'>
            <form className='rowWrapper' onSubmit={this.saveRow.bind(this)}>
              
              <div className='languageWrapper'>
                {this.isNewRule ?
                  <div className='languageInput'>
                    <Dropdown 
                      className='ruleInputDropdown'
                      options={this.getLanguageOptions()}
                      name={t(tx.TX_LANGUAGE)}
                      noTranslate={false}
                      value={this.getLanguageValue()}
                      required={true}
                      onChange={this.changeLanguage.bind(this)} />
                  </div> :
                  <div className='languageValue'>{this.props.rule && this.props.rule.language ? t(this.props.rule.language.nameTranslation) : t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_ALL_OTHER)}</div>
                }
              </div>

              <div className='conditionWrapper'>
                {this.isNewRule ?
                  <div className='conditionInput'>
                    <Dropdown 
                      className='ruleInputDropdown'
                      options={this.getConditionOptions()}
                      name={t(tx.TX_CONDITION)}
                      noTranslate={false}
                      value={this.getConditionValue()}
                      required={true}
                      onChange={this.changeCondition.bind(this)} />
                  </div> :
                  <div className='conditionValue'>{this.props.rule && this.props.rule.condition ? t(this.props.rule.condition.short) : t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_ALL_OTHER)}</div>
                }
              </div>

              <div className='priceWrapper'>
                <div className='priceInput'>
                  <div className='priceInputLabel'>{t(tx.TX_SELL_PRICE)}</div>
                  <input
                    type='number'
                    min={1}
                    max={100}
                    step={0.1}
                    name={t(tx.TX_SELL_PRICE)}
                    className={this.state.errorSellPercent ? 'InputError' : ''}
                    value={this.state.inputSellPercent}
                    onChange={this.changeSellPercent.bind(this)}
                    onBlur={this.validateSellPercent.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_PERCENT_BUY_PRICE)} />
                  <div className='percentOverlay'>%</div>
                </div>
              </div>

              <div className='priceWrapper'>
                <div className='priceInput'>
                  <div className='priceInputLabel'>{t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}</div>
                  <input
                    type='number'
                    min={0}
                    max={100}
                    step={0.1}
                    name={t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}
                    className={this.state.errorBuyPercent ? 'InputError' : ''}
                    value={this.state.inputBuyPercent}
                    onChange={this.changeBuyPercent.bind(this)}
                    onBlur={this.validateBuyPercent.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_PERCENT_SELL_PRICE)} />
                  <div className='percentOverlay'>%</div>
                </div>
              </div>

              <div className='actionsWrapper'>
                <div className='actionsLiner'>
                  <button className='editButton' type='submit'>
                    <Icon value={ICON_CHECK} iconClass='buttonIcon' />
                  </button>
                  <button className='deleteButton' type='button' onClick={this.closeRow.bind(this)}>&times;</button>
                </div>
              </div>

              {this.singleError() ?
                <div className='rowError'>{t(this.singleError())}</div> :
                null
              }
            </form>
          </div> :
          <div className='rowClosedWrapper'>
            <div className='languageWrapper'>
              {this.props.rule ?
                <>
                  <div className='languageValue'>{this.props.rule && this.props.rule.language ? t(this.props.rule.language.nameTranslation) : t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_ALL_OTHER)}</div>
                </> :
                null
              }
            </div>
            <div className='conditionWrapper'>
              {this.props.rule ?
                <div className='conditionValue'>{this.props.rule && this.props.rule.condition ? t(this.props.rule.condition.short) : t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_ALL_OTHER)}</div> :
                null
              }
            </div>
            <div className='priceWrapper'>
              {this.props.rule ?
                <div className='priceValue'>
                  <span className='priceNumber'>{this.props.rule.percentSell && this.props.rule.percentSell.toFixed(1)}</span>
                  <span className='pricePercent'>%</span>
                </div> :
                null
              }
            </div>
            <div className='priceWrapper'>
              {this.props.rule ?
                <div className='priceValue'>
                  <span className='priceNumber'>{this.props.rule.percentBuy && this.props.rule.percentBuy.toFixed(1)}</span>
                  <span className='pricePercent'>%</span>
                </div> :
                null
              }
            </div>
            <div className='actionsWrapper'>
              <div className='actionsLiner'>
                {this.props.rule.isParent === false ?
                  <button className='editButton' type='button' onClick={this.openRow.bind(this)}>
                    <Icon value={ICON_EDIT} iconClass='buttonIcon' />
                  </button> :
                  null
                }
                {this.props.rule.isParent === false && (this.props.rule.language || this.props.rule.condition) ?
                  <button className='deleteButton' type='button' onClick={this.toggleDeletePrompt.bind(this)}>&times;</button> :
                  <div className='deleteSpacer'></div>
                }
              </div>
            </div>
          </div>
        }
        {this.state.requestPending ?
          <div className='rowLoadingWrapper'>
            <div className='loadingIconWrapper'>
              <LoadingIcon />
            </div>
          </div> :
          null
        }
      </div>
      <Confirm
        title={tx.TX_DELETE_PROMPT_TITLE}
        copy={tx.TX_INV_VIEW_PRODUCT_LINE_PRICING_DELETE_COPY}
        open={this.state.deletePromptOpen}
        confirmValue={tx.TX_DELETE}
        closeMethod={this.toggleDeletePrompt.bind(this)}
        onConfirm={this.deleteAction.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLinePricingMatrixRuleEdit));