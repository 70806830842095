// import 'whatwg-fetch';

import * as x from '../constants/actions';
// import * as n from '../constants/endpoints';
// import * as a from '../constants/api';

// import { parseJSON, isOk, authReq } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/

export function commonAlert(payload) {
  return {
    type: x.ACTION_COMMON_ALERT_TOGGLE,
    payload
  };
}



/******************************
*******  Asynchronous  ********
******************************/


