

import * as tx from './strings';

import {
  getCityError, 
  getLevel3ComunaError, 
  getPostCodeError, 
  getPostCodeZipError, 
  getRegionError,
  getRegionCountyError, 
  getRegionStateError, 
  getStreetError,
  getTaxIdRutError, 
  validateNull,
} from '../utils/form-validation';
import {
  formatNull,
  formatRut, 
} from '../utils/formatting';



export const G_ERROR_MAPPING = 'G_ERROR_MAPPING';
export const G_POSTCODE_LABEL = 'G_POSTCODE_LABEL';
export const G_REGION_LABEL = 'G_REGION_LABEL';
export const G_REGION_LIST = 'G_REGION_LIST';
export const G_STRING_MAPPING = 'G_STRING_MAPPING';

export const GS_KEY = 'value';
export const GS_NAME = 'display';


// CSS Classes

export const GEO_CLASS_ADDRESS_LINE = 'addressLine';


// Regions

export const GEO_REGIONS_CL = [
  {
    [GS_NAME]: 'Arica and Parinacota',
    [GS_KEY]: 'Arica and Parinacota',
  },
  {
    [GS_NAME]: 'Tarapacá',
    [GS_KEY]: 'Tarapacá',
  },
  {
    [GS_NAME]: 'Antofagasta',
    [GS_KEY]: 'Antofagasta',
  },
  {
    [GS_NAME]: 'Atacama',
    [GS_KEY]: 'Atacama',
  },
  {
    [GS_NAME]: 'Coquimbo',
    [GS_KEY]: 'Coquimbo',
  },
  {
    [GS_NAME]: 'Valparaíso',
    [GS_KEY]: 'Valparaíso',
  },
  {
    [GS_NAME]: 'Región Metropolitana',
    [GS_KEY]: 'Región Metropolitana',
  },
  {
    [GS_NAME]: `O'Higgins`,
    [GS_KEY]: `O'Higgins`,
  },
  {
    [GS_NAME]: 'Maule',
    [GS_KEY]: 'Maule',
  },
  {
    [GS_NAME]: 'Biobío',
    [GS_KEY]: 'Biobío',
  },
  {
    [GS_NAME]: 'Araucanía',
    [GS_KEY]: 'Araucanía',
  },
  {
    [GS_NAME]: 'Los Ríos',
    [GS_KEY]: 'Los Ríos',
  },
  {
    [GS_NAME]: 'Los Lagos',
    [GS_KEY]: 'Los Lagos',
  },
  {
    [GS_NAME]: 'Aisén',
    [GS_KEY]: 'Aisén',
  },
  {
    [GS_NAME]: 'Magallanes',
    [GS_KEY]: 'Magallanes',
  },
  {
    [GS_NAME]: 'Ñuble',
    [GS_KEY]: 'Ñuble',
  },
];

export const GEO_REGIONS_RO = [
	{
    [GS_NAME]: 'Alba',
    [GS_KEY]: 'AB',
  },
  {
    [GS_NAME]: 'Arad',
    [GS_KEY]: 'AR',
  },
  {
    [GS_NAME]: 'Argeș',
    [GS_KEY]: 'AG',
  },
  {
    [GS_NAME]: 'Bacău',
    [GS_KEY]: 'BC',
  },
  {
    [GS_NAME]: 'Bihor',
    [GS_KEY]: 'BH',
  },
  {
    [GS_NAME]: 'Bistrița-Năsăud',
    [GS_KEY]: 'BN',
  },
  {
    [GS_NAME]: 'Botoșani',
    [GS_KEY]: 'BT',
  },
  {
    [GS_NAME]: 'Brașov',
    [GS_KEY]: 'BV',
  },
  {
    [GS_NAME]: 'Brăila',
    [GS_KEY]: 'BR',
  },
  {
    [GS_NAME]: 'București',
    [GS_KEY]: 'București',
  },
  {
    [GS_NAME]: 'Buzău',
    [GS_KEY]: 'BZ',
  },
  {
    [GS_NAME]: 'Caraș-Severin',
    [GS_KEY]: 'CS',
  },
  {
    [GS_NAME]: 'Călărași',
    [GS_KEY]: 'CL',
  },
  {
    [GS_NAME]: 'Cluj',
    [GS_KEY]: 'CJ',
  },
  {
    [GS_NAME]: 'Constanța',
    [GS_KEY]: 'CT',
  },
  {
    [GS_NAME]: 'Covasna',
    [GS_KEY]: 'CV',
  },
  {
    [GS_NAME]: 'Dâmbovița',
    [GS_KEY]: 'DB',
  },
  {
    [GS_NAME]: 'Dolj',
    [GS_KEY]: 'DJ',
  },
  {
    [GS_NAME]: 'Galați',
    [GS_KEY]: 'GL',
  },
  {
    [GS_NAME]: 'Giurgiu',
    [GS_KEY]: 'GR',
  },
  {
    [GS_NAME]: 'Gorj',
    [GS_KEY]: 'GJ',
  },
  {
    [GS_NAME]: 'Harghita',
    [GS_KEY]: 'HR',
  },
  {
    [GS_NAME]: 'Hunedoara',
    [GS_KEY]: 'HD',
  },
  {
    [GS_NAME]: 'Ialomița',
    [GS_KEY]: 'IL',
  },
  {
    [GS_NAME]: 'Iași',
    [GS_KEY]: 'IS',
  },
  {
    [GS_NAME]: 'Ilfov',
    [GS_KEY]: 'IF',
  },
  {
    [GS_NAME]: 'Maramureș',
    [GS_KEY]: 'MM',
  },
  {
    [GS_NAME]: 'Mehedinți',
    [GS_KEY]: 'MH',
  },
  {
    [GS_NAME]: 'Mureș',
    [GS_KEY]: 'MS',
  },
  {
    [GS_NAME]: 'Neamț',
    [GS_KEY]: 'NT',
  },
  {
    [GS_NAME]: 'Olt',
    [GS_KEY]: 'OT',
  },
  {
    [GS_NAME]: 'Prahova',
    [GS_KEY]: 'PH',
  },
  {
    [GS_NAME]: 'Satu Mare',
    [GS_KEY]: 'SM',
  },
  {
    [GS_NAME]: 'Sălaj',
    [GS_KEY]: 'SJ',
  },
  {
    [GS_NAME]: 'Sibiu',
    [GS_KEY]: 'SB',
  },
  {
    [GS_NAME]: 'Suceava',
    [GS_KEY]: 'SV',
  },
  {
    [GS_NAME]: 'Teleorman',
    [GS_KEY]: 'TR',
  },
  {
    [GS_NAME]: 'Timiș',
    [GS_KEY]: 'TM',
  },
  {
    [GS_NAME]: 'Tulcea',
    [GS_KEY]: 'TL',
  },
  {
    [GS_NAME]: 'Vaslui',
    [GS_KEY]: 'VS',
  },
  {
    [GS_NAME]: 'Vâlcea',
    [GS_KEY]: 'VL',
  },
  {
    [GS_NAME]: 'Vrancea',
    [GS_KEY]: 'VN',
  },
];

export const GEO_REGIONS_US = [
  {
    [GS_NAME]: 'Alabama',
    [GS_KEY]: 'AL',
  },
  {
    [GS_NAME]: 'Alaska',
    [GS_KEY]: 'AK',
  },
  {
    [GS_NAME]: 'American Samoa',
    [GS_KEY]: 'AS',
  },
  {
    [GS_NAME]: 'Arizona',
    [GS_KEY]: 'AZ',
  },
  {
    [GS_NAME]: 'Arkansas',
    [GS_KEY]: 'AR',
  },
  {
    [GS_NAME]: 'California',
    [GS_KEY]: 'CA',
  },
  {
    [GS_NAME]: 'Colorado',
    [GS_KEY]: 'CO',
  },
  {
    [GS_NAME]: 'Connecticut',
    [GS_KEY]: 'CT',
  },
  {
    [GS_NAME]: 'Delaware',
    [GS_KEY]: 'DE',
  },
  {
    [GS_NAME]: 'District Of Columbia',
    [GS_KEY]: 'DC',
  },
  {
    [GS_NAME]: 'Federated States Of Micronesia',
    [GS_KEY]: 'FM',
  },
  {
    [GS_NAME]: 'Florida',
    [GS_KEY]: 'FL',
  },
  {
    [GS_NAME]: 'Georgia',
    [GS_KEY]: 'GA',
  },
  {
    [GS_NAME]: 'Guam',
    [GS_KEY]: 'GU',
  },
  {
    [GS_NAME]: 'Hawaii',
    [GS_KEY]: 'HI',
  },
  {
    [GS_NAME]: 'Idaho',
    [GS_KEY]: 'ID',
  },
  {
    [GS_NAME]: 'Illinois',
    [GS_KEY]: 'IL',
  },
  {
    [GS_NAME]: 'Indiana',
    [GS_KEY]: 'IN',
  },
  {
    [GS_NAME]: 'Iowa',
    [GS_KEY]: 'IA',
  },
  {
    [GS_NAME]: 'Kansas',
    [GS_KEY]: 'KS',
  },
  {
    [GS_NAME]: 'Kentucky',
    [GS_KEY]: 'KY',
  },
  {
    [GS_NAME]: 'Louisiana',
    [GS_KEY]: 'LA',
  },
  {
    [GS_NAME]: 'Maine',
    [GS_KEY]: 'ME',
  },
  {
    [GS_NAME]: 'Marshall Islands',
    [GS_KEY]: 'MH',
  },
  {
    [GS_NAME]: 'Maryland',
    [GS_KEY]: 'MD',
  },
  {
    [GS_NAME]: 'Massachusetts',
    [GS_KEY]: 'MA',
  },
  {
    [GS_NAME]: 'Michigan',
    [GS_KEY]: 'MI',
  },
  {
    [GS_NAME]: 'Minnesota',
    [GS_KEY]: 'MN',
  },
  {
    [GS_NAME]: 'Mississippi',
    [GS_KEY]: 'MS',
  },
  {
    [GS_NAME]: 'Missouri',
    [GS_KEY]: 'MO',
  },
  {
    [GS_NAME]: 'Montana',
    [GS_KEY]: 'MT',
  },
  {
    [GS_NAME]: 'Nebraska',
    [GS_KEY]: 'NE',
  },
  {
    [GS_NAME]: 'Nevada',
    [GS_KEY]: 'NV',
  },
  {
    [GS_NAME]: 'New Hampshire',
    [GS_KEY]: 'NH',
  },
  {
    [GS_NAME]: 'New Jersey',
    [GS_KEY]: 'NJ',
  },
  {
    [GS_NAME]: 'New Mexico',
    [GS_KEY]: 'NM',
  },
  {
    [GS_NAME]: 'New York',
    [GS_KEY]: 'NY',
  },
  {
    [GS_NAME]: 'North Carolina',
    [GS_KEY]: 'NC',
  },
  {
    [GS_NAME]: 'North Dakota',
    [GS_KEY]: 'ND',
  },
  {
    [GS_NAME]: 'Northern Mariana Islands',
    [GS_KEY]: 'MP',
  },
  {
    [GS_NAME]: 'Ohio',
    [GS_KEY]: 'OH',
  },
  {
    [GS_NAME]: 'Oklahoma',
    [GS_KEY]: 'OK',
  },
  {
    [GS_NAME]: 'Oregon',
    [GS_KEY]: 'OR',
  },
  {
    [GS_NAME]: 'Palau',
    [GS_KEY]: 'PW',
  },
  {
    [GS_NAME]: 'Pennsylvania',
    [GS_KEY]: 'PA',
  },
  {
    [GS_NAME]: 'Puerto Rico',
    [GS_KEY]: 'PR',
  },
  {
    [GS_NAME]: 'Rhode Island',
    [GS_KEY]: 'RI',
  },
  {
    [GS_NAME]: 'South Carolina',
    [GS_KEY]: 'SC',
  },
  {
    [GS_NAME]: 'South Dakota',
    [GS_KEY]: 'SD',
  },
  {
    [GS_NAME]: 'Tennessee',
    [GS_KEY]: 'TN',
  },
  {
    [GS_NAME]: 'Texas',
    [GS_KEY]: 'TX',
  },
  {
    [GS_NAME]: 'Utah',
    [GS_KEY]: 'UT',
  },
  {
    [GS_NAME]: 'Vermont',
    [GS_KEY]: 'VT',
  },
  {
    [GS_NAME]: 'Virgin Islands',
    [GS_KEY]: 'VI',
  },
  {
    [GS_NAME]: 'Virginia',
    [GS_KEY]: 'VA',
  },
  {
    [GS_NAME]: 'Washington',
    [GS_KEY]: 'WA',
  },
  {
    [GS_NAME]: 'West Virginia',
    [GS_KEY]: 'WV',
  },
  {
    [GS_NAME]: 'Wisconsin',
    [GS_KEY]: 'WI',
  },
  {
    [GS_NAME]: 'Wyoming',
    [GS_KEY]: 'WY',
  }
];

export const GEO_ADDRESS_SCHEMA_DEFAULT = {
  street1: {
    display: true,
    required: true,
    label: tx.TX_ADDRESS_STREET,
    formatMethod: formatNull,
    validationMethod: getStreetError,
    autoComplete: 'address-line1',
    placeholder: tx.TX_PLACEHOLDER_ADDRESS_STREET,
  },
  street2: {
    display: true,
    required: false,
    label: tx.TX_ADDRESS_STREET_LINE_2,
    formatMethod: formatNull,
    validationMethod: getStreetError,
    autoComplete: 'address-line2',
    placeholder: tx.TX_PLACEHOLDER_ADDRESS_STREET_2,
  },
  administrativeAreaLevel3: {
    display: false,
    required: false,
    label: tx.TX_null,
    formatMethod: formatNull,
    validationMethod: validateNull,
    autoComplete: 'address-level3',
    placeholder: tx.TX_null,
  },
  city: {
    display: true,
    required: true,
    label: tx.TX_ADDRESS_CITY,
    formatMethod: formatNull,
    validationMethod: getCityError,
    autoComplete: 'address-level2',
    placeholder: tx.TX_PLACEHOLDER_ADDRESS_CITY,
  },
  region: {
    display: true,
    required: true,
    label: tx.TX_ADDRESS_REGION,
    formatMethod: formatNull,
    validationMethod: getRegionError,
    autoComplete: 'address-level1',
    placeholder: tx.TX_PLACEHOLDER_ADDRESS_REGION,
    options: null,
  },
  postCode: {
    display: true,
    required: true,
    label: tx.TX_ADDRESS_POSTCODE,
    formatMethod: formatNull,
    validationMethod: getPostCodeError,
    autoComplete: 'postal-code',
    placeholder: tx.TX_PLACEHOLDER_ADDRESS_POST_CODE,
  },
  taxId: {
    display: false,
    required: false,
    label: tx.TX_ADDRESS_TAX_ID,
    formatMethod: formatNull,
    validationMethod: validateNull,
    autoComplete: '',
    placeholder: tx.TX_null,
  },
}

export const GEO_COUNTRY_CL_KEY = 'CL';

export const GEO_COUNTRY_CL = {
	key: GEO_COUNTRY_CL_KEY,
	iso2: GEO_COUNTRY_CL_KEY,
	name: tx.TX_GEOGRAPHY_COUNTRY_CL,

  addressSchema: {
    street1: {},
    street2: {},
    city: {
      label: tx.TX_ADDRESS_CITY,
    },
    administrativeAreaLevel3: {
      display: true,
      required: true,
      label: tx.TX_ADDRESS_COMUNA,
      validationMethod: getLevel3ComunaError,
      autoComplete: 'address-level3',
      placeholder: tx.TX_PLACEHOLDER_ADDRESS_COMUNA,
    },
    region: {
      label: tx.TX_ADDRESS_REGION,
      validationMethod: getRegionError,
      placeholder: tx.TX_PLACEHOLDER_ADDRESS_REGION_SELECT,
      options: GEO_REGIONS_CL,
    },
    postCode: {
      required: false,
      display: false,
      placeholder: tx.TX_PLACEHOLDER_ADDRESS_POST_CODE_CL,
    },
    taxId: {
      display: true,
      required: false,
      label: tx.TX_ADDRESS_RUT,
      formatMethod: formatRut,
      validationMethod: getTaxIdRutError,
      placeholder: tx.TX_PLACEHOLDER_ADDRESS_TAX_ID_RUT,
    },
  }
}


export const GEO_COUNTRY_RO_KEY = 'RO';

export const GEO_COUNTRY_RO = {
	key: GEO_COUNTRY_RO_KEY,
	iso2: GEO_COUNTRY_RO_KEY,
	name: tx.TX_GEOGRAPHY_COUNTRY_RO,

  addressSchema: {
    region: {
      label: tx.TX_ADDRESS_REGION_COUNTY,
      validationMethod: getRegionCountyError,
      placeholder: tx.TX_PLACEHOLDER_ADDRESS_REGION_COUNTY,
      options: GEO_REGIONS_RO,
    },
  },
}


export const GEO_COUNTRY_US_KEY = 'US';

export const GEO_COUNTRY_US = {
	key: GEO_COUNTRY_US_KEY,
	iso2: GEO_COUNTRY_US_KEY,
	name: tx.TX_GEOGRAPHY_COUNTRY_US,

  addressSchema: {
    region: {
      label: tx.TX_ADDRESS_REGION_STATE,
      validationMethod: getRegionStateError,
      placeholder: tx.TX_PLACEHOLDER_ADDRESS_REGION_STATE,
      options: GEO_REGIONS_US,
    },
    postCode: {
      label: tx.TX_ADDRESS_POSTCODE_ZIP,
      validationMethod: getPostCodeZipError,
    }
  },
}

export const GEO_COUNTRIES_ALL = [
	GEO_COUNTRY_CL,
	GEO_COUNTRY_RO,
	GEO_COUNTRY_US,
];
