

import { 
	COUPON_DISCOUNT_TYPE_FIXED, 
	COUPON_DISCOUNT_TYPE_PERCENTAGE, 
} from '../constants/promotions';
import {
	TX_MARKETING_COUPON_TYPE_FIXED,
	TX_MARKETING_COUPON_TYPE_PERCENTAGE,
	TX_null,
} from '../constants/strings';

import { 
  normalizePrice, 
} from '../utils/formatting';
import { safeParseFloat } from '../utils/general';


export class Coupon {

	constructor(props) {

		// As props, should accept another Coupon object or a server resp
		if(!props) { props = {}; }

		this.code = props.code || '';
		this.code = this.code.toLowerCase();

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
		this.description = props.description || '';
		
		this.discountType = props.discountType || props.discount_type || COUPON_DISCOUNT_TYPE_FIXED;
		this.discountAmount = parseFloat(props.discountAmount) || parseFloat(props.discount_amount) || 0.0;

		this.minPurchase = parseFloat(props.minPurchase) || parseFloat(props.min_purchase) || 0.0;
		this.perUserUses = parseInt(props.perUserUses) || parseInt(props.per_user_uses) || null;		
		this.isEnabled = props.isEnabled || props.is_enabled || false;
	}

	get displayType() {
		const displayMapping = {
			[COUPON_DISCOUNT_TYPE_FIXED]: TX_MARKETING_COUPON_TYPE_FIXED,
			[COUPON_DISCOUNT_TYPE_PERCENTAGE]: TX_MARKETING_COUPON_TYPE_PERCENTAGE,
		};
		return displayMapping[this.discountType] || TX_null;
	}

	get isPercentage() {
		return this.discountType === TX_MARKETING_COUPON_TYPE_PERCENTAGE;
	}

	get isFixed() {
		return this.discountType === COUPON_DISCOUNT_TYPE_FIXED;
	}

	getApiData() {
		const apiData = {
			code: this.code,
			name: this.name,
			description: this.description,
			discount_type: this.discountType,
			discount_amount: safeParseFloat(this.discountAmount),
			min_purchase: safeParseFloat(normalizePrice(this.minPurchase)),
			per_user_uses: this.perUserUses,
			is_enabled: this.isEnabled, 
		};
		return apiData;
	}
}