import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../../../store';

import { ERROR_CATCH_ALL } from '../../../../../constants/errors';
import * as tx from '../../../../../constants/strings';
import { 
  URL_CART,
  URL_CONTACT_US,
  URL_SUCCESS,
} from '../../../../../constants/urls';

import { Order } from '../../../../../models/orders';

import { urlQueryParams } from '../../../../../utils/general';
import { 
  formatServerError,
  shouldTranslateServerError, 
} from '../../../../../utils/formatting';

import LoadingIcon from '../../../../Icons/LoadingIcon';

import '../../../style/_transbank.scss';

import * as checkoutActionCreators from '../../../../../actions/checkout';
import * as paymentsActionCreators from '../../../../../actions/payments';
let allActionCreators = Object.assign({}, checkoutActionCreators, paymentsActionCreators);

export class TransbankPaymentComplete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestError: null,
      requestErrorTranslate: true, 
      requestPending: true,
    };

    this.getParams = urlQueryParams();
  }

  componentDidMount() {
    if(!this.props.match.params.paymentRecordUUID || !this.getParams.token_ws) {
      this.setState({
        requestError: ERROR_CATCH_ALL,
        requestPending: false,
      });
      return null;
    }

    this.completePurchase();
  }

  async completePurchase() {

    const confirmData = {
      token: this.getParams.token_ws,
    };

    const confirmResp = await this.props.paymentsTransbankConfirm(confirmData, this.props.match.params.paymentRecordUUID)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          requestErrorTranslate: shouldTranslateServerError(errResp),
        });
      });

    if(!confirmResp) { return null; }

    // No tax added; Sales tax not calculated at checkout
    
    const orderData = {
      shipping_address_uuid: confirmResp.additionalData.shipping_address_uuid,
      shipping_method_uuid: confirmResp.additionalData.shipping_method_uuid,
      billing_address_uuid: null,
      payment_method_uuid: confirmResp.additionalData.payment_method_uuid,
      cart_uuid: this.props.cart.currentCart.publicUuid,
      payment_record_uuid: this.props.match.params.paymentRecordUUID,
      status: this.props.cart.currentCart.hasPreorderItems() ? Order.preorderStatus : Order.initialStatus,
      use_store_credit: confirmResp.additionalData.use_store_credit,
    };

    const orderResp = await this.props.checkoutPlaceOrder(orderData)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });
      });
    if(!orderResp || !orderResp.public_uuid) { return null; }

    this.props.checkoutClearStepData();

    history.push({
      pathname: URL_SUCCESS,
      state: { 
        orderUuid: orderResp.public_uuid, 
      },
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'TransbankPaymentComplete'}>
      <div className='noNavScreen'>
        {this.state.requestPending ?
          <div className={'loadingBlock block'}>
            <div className='blockLiner'>
              <div className='loadingIconWrapper'>
                <LoadingIcon />
              </div>
              <div className='loadingLabel'>{t(tx.TX_CHECKOUT_COMPLETING_ORDER)}</div>
              <div className='loadingCopy'>{t(tx.TX_CHECKOUT_COMPLETING_ORDER_NONAV_COPY)}</div>
            </div>
          </div> :
          <div className={'errorBlock block'}>
            <div className='errorCopy'>{t(tx.TX_CHECKOUT_COMPLETING_ORDER_ERROR_COPY)}</div>
            <div className='errorLabel'>{t(tx.TX_ERROR)}</div>
            <div className='errorBody'>{this.state.requestErrorTranslate ? t(this.state.requestError) : this.state.requestError}</div>
            <div className='errorActions'>
              <Link to={URL_CART} className='errorAction'>{t(tx.TX_BACK)}</Link>
              <Link to={URL_CONTACT_US} className='errorAction'>{t(tx.TX_CONTACT_US)}</Link>
            </div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(TransbankPaymentComplete));