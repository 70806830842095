
import * as x from '../constants/actions';


const initialState = {
  alertOpen: false,
  alertTitle: '',
  alertCopy: '',
  alertButtonValue: '',
  alertStoreTheme: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_COMMON_ALERT_TOGGLE:
      return commonAlertToggle(state, action);
    default:
      return state;
  }
}

function commonAlertToggle(state, action) {

  let alertConfig = {};

  if(state.alertOpen) {
    alertConfig = {
      alertOpen: false,
      alertTitle: '',
      alertCopy: '',
      alertButtonValue: '',
      alertStoreTheme: false,
    }
  } else {
    alertConfig = {
      alertOpen: true,
      alertTitle: action.payload.alertTitle ? action.payload.alertTitle : '',
      alertCopy: action.payload.alertCopy ? action.payload.alertCopy : '',
      alertButtonValue: action.payload.alertButtonValue ? action.payload.alertButtonValue : '',
      alertStoreTheme: action.payload.alertStoreTheme ? action.payload.alertStoreTheme : false,
    }
  }
  return Object.assign({}, state, alertConfig);
}

