import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Icon } from '../../../Icons/Icon';

let allActionCreators = Object.assign({});

export class BinaryElement extends Component {

	render() {

		const {t} = this.props;

    return <div className={'BinaryElement'}>
    	{this.props.useIcon ?
      	<div className='binaryElementIconWrapper'>
      		<Icon 
            value={this.props.dataValue}  
            iconClass={'binaryElementIcon'} 
            iconLabel={null} 
            ellipsisLabel={false} />
      	</div> :
      	<div className='binaryElementWrapper'>{t(this.props.dataValue)}</div>
    	}
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BinaryElement));


