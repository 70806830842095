import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

let allActionCreators = Object.assign({});

export class CheckoutStepReview extends Component {

  render() {
    return <div className={'CheckoutStepReview'}></div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepReview));