import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import { 
  URL_FORGOT_PASSWORD, 
  URL_LOGIN, 
} from '../../constants/urls';

import { setReAuth } from '../../utils/auth';
import { 
  isFormValid, 
  getPasswordInputError, 
} from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import './style/_auth.scss';

import * as authActionCreators from '../../actions/auth';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, userActionCreators);

export class ReAuth extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordError: '',

      reAuthReqError: null,
      reAuthReqPending: false,
    };

    this.destination = props.location && props.location.state && props.location.state.from ? props.location.state.from : null;
    this.email = this.props.user && this.props.user.user ? this.props.user.user.email : '';
  }

  componentDidMount() {
    if(!this.email) {
      history.replace(URL_LOGIN);
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const reAuthData = {
        email_address: this.email,
        password: this.state.password,
      };

      this.setState({ 
        reAuthReqPending: true, 
        reAuthReqError: null, 
      });

      this.props.usersReAuth(reAuthData)
      .then((resp) => {        
        setReAuth();
        if(this.destination) {
          history.push(this.destination);
        } else {
          history.goBack();
        }
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          reAuthReqPending: false,
          reAuthReqError: formatServerError(errResp),
        });
      });
    }
  }

  validateAll() {
    let errorObj = {
      passwordError: getPasswordInputError(this.state.password),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changePassword(evt) {
    this.setState({ password: evt.target.value }, () => {
      if(this.state.passwordError) {
        this.validatePassword(this.state.password);
      }
    });
  }

  validatePassword() {
    this.setState({ passwordError: getPasswordInputError(this.state.password) });
  }

  backNav() {
    history.goBack();
  }

  render() {

    const {t} = this.props;

    return <div className={'ReAuth authPage'}>
      <div className='authPageTitle'>{t(tx.TX_ACCOUNT_REAUTH_TITLE)}</div>
      <div className='authPageBody'>
        <div className='loginWrapper'>
          <form className='loginForm' onSubmit={this.handleSubmit.bind(this)}>
            <div className={this.state.reAuthReqError ? 'loginFormError present' : 'loginFormError'}>{t(this.state.reAuthReqError)}</div>
            <div className='loginText'>{t(tx.TX_AUTH_LOGIN_TO_CONTINUE)}</div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
              <div className='loginValue'>{this.email}</div>
            </div>
            <div className='loginFieldWrapper'>
              <div className='loginFieldLabel'>{t(tx.TX_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='current-password'
                className={this.state.passwordError ? 'InputError' : ''}
                value={this.state.password}
                onChange={this.changePassword.bind(this)}
                onBlur={this.validatePassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)} />
              {this.state.passwordError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordError)}</div> :
                null
              }
            </div>
            <div className='loginFieldWrapper'>
              <div className='forgotPasswordWrapper'>
                <Link to={{
                  pathname: URL_FORGOT_PASSWORD,
                  emailVal: this.state.email,
                }}>{t(tx.TX_FORGOT_PASSWORD_QUESTION)}</Link>
              </div>
            </div>
            <div className='loginFieldWrapper'>
              <button 
                className='loginSubmitButton'
                disabled={this.state.reAuthReqPending}>
                {t(tx.TX_CONFIRM)}
              </button>
            </div>
          </form>
          <div className='registerToggle'>
            <button 
              type='button' 
              className='toggleButton'
              onClick={this.backNav.bind(this)}>
              {t(tx.TX_BACK)}
            </button>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ReAuth));