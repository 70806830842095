import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';

import './style/_manageevents.scss';

import AdminTitle from '../Admin/AdminTitle';
import EventCalendar from './EventCalendar';

let allActionCreators = Object.assign({});

export class ManageEvents extends Component {

  // constructor(props) {
  //   super(props);
  // }

  // componentDidMount() {

  // }

  render() {

    const {t} = this.props;

    return <div className={'ManageEvents AdminPage'}>
      <AdminTitle
        title={tx.TX_MENU_ITEM_MANAGEEVENTS}
        breadcrumbs={[]} />
      <div className='adminBody'>
        <div className='eventTopWrapper'>
          <div className='eventSmallBlock'>
            <div className='eventsTitle'>{t(tx.TX_EVENTS_UPCOMING_EVENTS)}</div>
            <div className='sbBody'>
              
            </div>
          </div>
          <div className='eventSmallBlock'>
            <div className='eventsTitle'>{t(tx.TX_EVENTS_RECURRING_EVENTS)}</div>
            <div className='sbBody'>
              
            </div>
          </div>
        </div>
        <div className='eventsCalendarBlock'>
          <div className='eventsCalendarWrapper'>
            <EventCalendar
              admin={true} />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ManageEvents));