import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  CSS_SLIDE_DURATION 
} from '../../constants/css';
import { 
  TX_CANCEL, 
  TX_DOWNLOAD,
  TX_INV_BULK_UPLOAD_DOWNLOAD_DATA, 
  TX_INV_BULK_UPLOAD_DOWNLOAD_PREPARING,
  TX_INV_BULK_UPLOAD_DOWNLOAD_READY,
} from '../../constants/strings';


import LoadingIcon from '../Icons/LoadingIcon';

import './style/_saveprompt.scss';

let allActionCreators = Object.assign({});

export class BulkUploadDownloadResultsModal extends Component {

  // REQUIRED PROPS
  // open: bool
  // closeMethod: function

  // OPTIONAL PROPS
  // copy: ARRAY (overwrites default copy; different than SavePrompt)
  // title: string (overwrites default title)
  // onCancel: function
  // onConfirm: function


  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,
      listenerEnabled: false,

      inputCurrent: '',
      inputPassword1: '',
      inputPassword2: '',

      errorCurrent: '',
      errorPassword1: '',
      errorPassword2: '',
    };

    // Create refs
    this.modalBody = React.createRef();
    this.checkClick = this.checkClick.bind(this);

    // Timeout management
    this.openTimeout = null;
    this.closeTimeout = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    document.removeEventListener('click', this.checkClick, false);

    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.open !== prevProps.open) {
      if(this.props.open === true) {
        this.openPrompt();
      } else {
        this.closePrompt();
      }
    }
  }

  checkClick(evt) {
    // Removed; uncomment to close when clicked outside of modal
    // if(this.props.open && this.state.listenerEnabled) {
    //   let targetElement = evt.target;
    //   do {
    //       if(targetElement === this.modalBody.current) {
    //           return;
    //       }
    //       targetElement = targetElement.parentNode;
    //   } while (targetElement);
    //   this.props.closeMethod();
    // }  
  }

  openPrompt() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
      listenerEnabled: false,
    }, () => {

      // Delay to trigger CSS animation
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, 25);

      // Delay listening for clicks
      this.openTimeout = setTimeout(() => {
        this.setState({
          listenerEnabled: true,
        });
      }, CSS_SLIDE_DURATION);

      // Fix body
      document.body.style.overflow = 'hidden';
    });
  }

  closePrompt() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
      listenerEnabled: false,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({
          shouldDisplay: false,
          
          inputCurrent: '',
          inputPassword1: '',
          inputPassword2: '',

          errorCurrent: '',
          errorPassword1: '',
          errorPassword2: '',
        });
      }, CSS_SLIDE_DURATION);
    });
  }

  getScreenClass() {
    return this.state.hasOpacity ? 'spScreen withOpacity' : 'spScreen';
  }

  getModalStyle() {

    const baseConfig = {
      opacity: this.state.hasOpacity ? '1' : '0',
      top: '250px',
    };

    let verticalConfig = {};

    if(this.props.open && this.modalBody && this.modalBody.current && this.modalBody.current.clientHeight > 0) {
      
      const modalHeight = this.modalBody.current.clientHeight;
      verticalConfig = {
        top: (window.innerHeight - modalHeight)/2 + 'px',
      }
    }

    return Object.assign({}, baseConfig, verticalConfig);
  }

  getTitle() {
    return this.props.title ? this.props.title : TX_INV_BULK_UPLOAD_DOWNLOAD_DATA;
  }

  cancelAction(evt) {
    evt.preventDefault();
    if(this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.closeMethod();
  }

  confirmAction(evt) {
    evt.preventDefault();
    if(this.props.onConfirm) {
      this.props.onConfirm(this.state.inputCurrent, this.state.inputPassword1);
    }
    this.props.closeMethod();
  }

  render() {

    const {t} = this.props;

    return <div 
            className='BulkUploadDownloadResultsModal'
            style={{
              display: this.state.shouldDisplay ? 'block' : 'none'
            }}>
      <div className={this.getScreenClass()}></div>
      <div className='spModalWrapper'>
        <div className='spModal' style={this.getModalStyle()} ref={this.modalBody}>
          <div className='spModalInnerWrapper'>
            <div className='spClose' onClick={this.props.closeMethod.bind(this)}>&times;</div>
            <div className='spModalTitle'>{t(this.getTitle())}</div>
            <div className='spModalHr'></div>
            <form onSubmit={this.confirmAction.bind(this)}>
              {this.props.downloadPending ?
                <div className='loadingBody'>
                  <div className='loadingIconLabel'>{t(TX_INV_BULK_UPLOAD_DOWNLOAD_PREPARING)}</div>
                  <div className='loadingIconWrapper'>
                    <LoadingIcon />
                  </div>
                </div> :
                <div className='completeBody'>
                  <div className='completeLabel'>{t(TX_INV_BULK_UPLOAD_DOWNLOAD_READY)}</div>
                  <button className={'completeAction'} type='button' onClick={this.confirmAction.bind(this)}>{t(TX_DOWNLOAD)}</button>
                </div>
              }
              <div className='spModalActions'>
                <button className={'spAction spActionCancel'} onClick={this.cancelAction.bind(this)}>{t(TX_CANCEL)}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkUploadDownloadResultsModal));