import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { CD_NEW, CONDITIONS_PRODUCT_GENERIC } from '../../../../constants/conditions';
import { 
  ERROR_CONDITION_MISSING, 
  ERROR_FINISH_MISSING, 
} from '../../../../constants/errors';
import { 
  LANG_DEFAULT_CODE,
  LANG_PRODUCT_GENERIC, 
} from '../../../../constants/languages';
import * as tx from '../../../../constants/strings';

import { Inventory } from '../../../../models/products';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol,
} from '../../../../utils/currency';
import { 
  isFormValid, 
  getPriceError, 
  getQuantityError, 
} from '../../../../utils/form-validation';
import { 
  formatServerError,
  normalizeQuantity, 
  normalizePrice, 
} from '../../../../utils/formatting';
import {
  getAttrFinish, 
  getProductInventoryLabel, 
} from '../../../../utils/product';

import LoadingIcon from '../../../Icons/LoadingIcon';
import Radio from '../../../Input/Radio';
import SavePrompt from '../../../Popups/SavePrompt';
import Toggle from '../../../Input/Toggle';

import * as commonActionCreators from '../../../../actions/common';
import * as productActionCreators from '../../../../actions/product';
let allActionCreators = Object.assign({}, commonActionCreators, productActionCreators);

export class ProductInventoryEdit extends Component {

  constructor(props) {
    super(props);

    const availableFinishes = getAttrFinish(this.props.productObj);
    const defaultFinish = availableFinishes.length === 1 ? availableFinishes[0] : '';

    this.state = {

      isDirty: false,

      inputSealed: this.props.inventoryObj && this.props.inventoryObj.condition ? this.props.inventoryObj.condition.key === CD_NEW.key : this.sealedAllowed(),
      inputCondition: this.props.inventoryObj ? this.props.inventoryObj.condition : '',
      inputFinish: this.props.inventoryObj ? this.props.inventoryObj.finish : defaultFinish,
      inputBuylist: this.props.inventoryObj ? this.props.inventoryObj.isBuylist : false,
      inputPrice: this.props.inventoryObj ? this.props.inventoryObj.sellPrice : '',
      inputBuyPrice: this.props.inventoryObj ? this.props.inventoryObj.buyPrice : '',
      inputQuantity: this.props.inventoryObj ? this.props.inventoryObj.totalQuantity : '',
      inputQuantityMin: this.props.inventoryObj && this.props.inventoryObj.minQuantity ? this.props.inventoryObj.minQuantity : '',
      inputQuantityMax: this.props.inventoryObj && this.props.inventoryObj.maxQuantity ? this.props.inventoryObj.maxQuantity : '',
      inputLanguage: this.props.inventoryObj ? this.props.inventoryObj.language : this.getDefaultLanguage(),
      
      errorCondition: '',
      errorFinish: '',
      errorPrice: '',
      errorBuyPrice: '', 
      errorQuantity: '',
      errorQuantityMin: '',
      errorQuantityMax: '',
      errorLanguage: '',

      requestPending: false,
      requestError: null,

      variantsData: [],
      variantsPending: false,

      savePromptOpen: false, 
    };

    this.controller = null;
  }

  componentDidMount() {
    this.fetchVariants();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.remoteSave === false && this.props.remoteSave === true) {
      this.saveAction();
    }

    if(prevProps.productPending === true && this.props.productPending === true) {
      this.fetchVariants();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  async fetchVariants() {

    if(!this.props.productPermalink || (this.props.productObj && this.props.productObj.foreignModel === null)) {
      return null;
    }

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      variantsPending: true,
    });

    // Get product variants
    const variantResp = await this.props.productFetchForeignVariants(this.props.productPermalink, this.props.productObj.productLine.permalink, controller.signal)
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          variantsPending: false,
          variantsData: [],
        });
      });

    if(!variantResp) { return null; }

    this.setState({
      variantsPending: false,
      variantsData: variantResp,
    });
  }

  makeDirty() {
    if(!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
    this.props.makeDirty();
  }

  changeSealed(evt) {
    this.setState({
      inputSealed: !this.state.inputSealed, 
    }, () => {
      if(this.state.inputSealed === false && this.state.errorCondition !== '') {
        this.setState({ errorCondition: '' });
      }
    });
    this.makeDirty();
  }

  changePrice(evt) {
    this.setState({
      inputPrice: evt.target.value,
    }, () => {
      if(this.state.errorPrice) {
        this.validatePrice(false);
      }
    });
    this.makeDirty();
  }

  validatePrice(normalize = true) {
    this.setState({ 
      inputPrice: normalize ? normalizePrice(this.state.inputPrice) : this.state.inputPrice, 
      errorPrice: getPriceError(this.state.inputPrice), 
    });
  }

  changeBuyPrice(evt) {
    this.setState({
      inputBuyPrice: evt.target.value,
    }, () => {
      if(this.state.errorBuyPrice) {
        this.validateBuyPrice(false);
      }
    });
    this.makeDirty();
  }

  validateBuyPrice(normalize = true) {
    this.setState({ 
      inputBuyPrice: normalize ? normalizePrice(this.state.inputBuyPrice) : this.state.inputBuyPrice, 
      errorBuyPrice: getPriceError(this.state.inputBuyPrice), 
    });
  }

  changeQuantity(evt) {
    this.setState({
      inputQuantity: evt.target.value,
    }, () => {
      if(this.state.errorQuantity) {
        this.validateQuantity(false);
      }
    });
    this.makeDirty();
  }

  validateQuantity(normalize = true) {
    this.setState({ 
      inputQuantity: normalize ? normalizeQuantity(this.state.inputQuantity) : this.state.inputQuantity,
      errorQuantity: getQuantityError(normalizeQuantity(this.state.inputQuantity)),
    });
  }

  changeQuantityMin(evt) {
    this.setState({
      inputQuantityMin: evt.target.value,
    }, () => {
      if(this.state.errorQuantityMin) {
        this.validateQuantityMin();
      }
    });
    this.makeDirty();
  }

  validateQuantityMin() {
    this.setState({ 
      inputQuantityMin: normalizeQuantity(this.state.inputQuantityMin),
      errorQuantityMin: getQuantityError(this.state.inputQuantityMin, true),
    });
  }

  changeQuantityMax(evt) {
    this.setState({
      inputQuantityMax: evt.target.value,
    }, () => {
      if(this.state.errorQuantityMax) {
        this.validateQuantityMax();
      }
    });
    this.makeDirty();
  }

  validateQuantityMax() {
    this.setState({ 
      inputQuantityMax: normalizeQuantity(this.state.inputQuantityMax),
      errorQuantityMax: getQuantityError(this.state.inputQuantityMax, true),
    });
  }

  changeCondition(evt) {

    if(evt.target.value === '') {
      this.setState({ inputCondition: evt.target.value }, () => {
        this.validateCondition();
      });
      return;
    }

    for(const co of CONDITIONS_PRODUCT_GENERIC) {
      if(co.key === evt.target.value) {
        this.setState({ inputCondition: co }, () => {
          this.validateCondition();
        });
        break;
      }
    }
  }

  getConditionError() {
    if(this.state.inputSealed === false) {
      if(!this.state.inputCondition) {
        return ERROR_CONDITION_MISSING;
      }
    }
    return '';
  }

  validateCondition() {
    this.setState({ errorCondition: this.getConditionError(this.state.inputCondition) });
  }

  changeFinish(evt) {

    if(evt.target.value === '') {
      this.setState({ inputFinish: evt.target.value }, () => {
        this.validateFinish();
      });
      return null;
    }

    for(const fn of getAttrFinish(this.props.productObj)) {
      if(fn.key === evt.target.value) {
        this.setState({ inputFinish: fn }, () => {
          this.validateFinish();
        });
        break;
      }
    }
  }

  getFinishError() {
    if(this.isFinishRequired() && !this.state.inputFinish) {
      return ERROR_FINISH_MISSING;
    }
    return '';
  }

  validateFinish() {
    this.setState({ errorFinish: this.getFinishError(this.state.inputCondition) });
  }

  isFinishRequired() {
    return this.state.inputSealed === false && getAttrFinish(this.props.productObj).length > 0;
  }

  changeLanguage(evt) {

    if(evt.target.value === '') {
      this.setState({ inputLanguage: evt.target.value });
      return;
    }

    for(const ln of this.getSelectableLanguages()) {
      if(ln.code === evt.target.value) {
        this.setState({ inputLanguage: ln });
        break;
      }
    }
  }

  getConditionLabel(conditionObj) {
    if(!conditionObj) { return ''; }
    const abrv = this.props.t(conditionObj.short);
    const cName = this.props.t(conditionObj.name);
    return abrv ? `${cName} (${abrv})` : cName;
  }
  
  getParentLanguage() {

    const dflt = this.getDefaultLanguage();

    if(this.props.productObj && this.props.productObj.foreignModel) {
      return this.props.productObj.foreignModel.languageObj ? this.props.productObj.foreignModel.languageObj.code : dflt.code;
    }
    return dflt.code;
  }

  getForeignModel() {
    try {

      if(this.state.variantsPending || this.state.variantsData.length === 0) {
        return null;
      }

      // Right now, just language; add more logic here if we add any other
      // variant-dependent inventory configurations, possibly finish
      for(const vt of this.state.variantsData) {
        if(this.state.inputLanguage.code === vt.languageObj.code) {
          return vt;
        }
      }
    } catch(err) {
      console.error(err);
      return null;
    }      
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const sealedValue = this.isCreateMode() ? this.state.inputSealed : this.props.inventoryObj.condition.key === CD_NEW.key;
      const conditionValue = this.isCreateMode() ? (this.state.inputSealed === false ? this.state.inputCondition.key : CD_NEW.key) : this.props.inventoryObj.condition.key;


      let inventory = null;
      let updateData = {};

      if(this.isCreateMode()) {
        inventory = new Inventory({
          isSealed: sealedValue,
          isBuylist: this.state.inputBuylist,
          condition: conditionValue,
          finish: this.state.inputFinish,
          language: this.state.inputLanguage, 
          sellPrice: this.state.inputPrice,
          buyPrice: this.state.inputBuyPrice,
          totalQuantity: this.state.inputQuantity,
          targetMin: this.state.inputQuantityMin,
          targetMax: this.state.inputQuantityMax,
          foreignModel: this.getForeignModel(),
        });
      } else {

        inventory = this.props.inventory;

        updateData = {
          sell_price: this.state.inputPrice,
          quantity: this.state.inputQuantity,
          is_buylist: this.state.inputBuylist,
        };

        if(this.state.inputBuylist && this.state.inputBuyPrice) {
          updateData['buy_price'] = parseFloat(this.state.inputBuyPrice);
        }

        if(this.state.inputQuantityMin) {
          updateData['min_quantity'] = parseInt(this.state.inputQuantityMin) || null;
        }

        if(this.state.inputQuantityMax) {
          updateData['max_quantity'] = parseInt(this.state.inputQuantityMax) || null;
        }
      }

      this.setState({
        requestPending: true,
        requestError: null,
      });

      let invResp = null;

      if(this.isCreateMode()) {

        invResp = await this.props.productInventoryAdd(inventory.getCreateApiData(this.props.productObj))
        .catch((errResp) => {
          // Fail response
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });

          if(this.props.remoteSave) {
            this.props.finishRemote('inventory', true);
          }
        });
      } else {
        invResp = await this.props.productInventoryEdit(this.props.inventoryObj.getUpdateApiData(updateData, this.props.productObj))
        .catch((errResp) => {
          // Fail response
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });

          if(this.props.remoteSave) {
            this.props.finishRemote('inventory', true);
          }
        });
      }

      if(!invResp) {
        return null;
      }

      // Success response
      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        if(this.props.remoteSave) {
          this.props.finishRemote('inventory');
        } else {
          this.props.makeClean('inventory');
        }
        this.props.toggleEdit(null);
      });

    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('inventory', true);
    }
  }

  validateAll() {
    const errorObj = {
      errorCondition: this.getConditionError(),
      errorFinish: this.getFinishError(),
      errorPrice: getPriceError(this.state.inputPrice),
      errorBuyPrice: this.state.inputBuylist ? getPriceError(this.state.inputBuyPrice) : '', 
      errorQuantity: getQuantityError(this.state.inputQuantity),
      errorQuantityMin: getQuantityError(this.state.inputQuantityMin, true),
      errorQuantityMax: getQuantityError(this.state.inputQuantityMax, true),
      errorLanguage: '',
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  getDefaultLanguage() {
    for(const lang of LANG_PRODUCT_GENERIC) {
      if(lang.code === LANG_DEFAULT_CODE) {
        return lang;
      }
    }
    return '';
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  isCreateMode() {
    return this.props.inventoryObj === null;
  }

  isSeadedDisabled() {
    return this.isCreateMode() === false;
  }

  buylistAllowed() {
    return this.props.productObj && this.props.productObj.productLine ? this.props.productObj.productLine.hasBuylist : false;
  }

  sealedAllowed() {
    if(this.props.productObj && this.props.productObj.foreignModel) {
      return this.props.productObj.foreignModel.allowSealed;
    }
    return true;
  }

  languageAllowed() {
    return true;
  }

  changeBuylist(evt) {
    this.setState({
      inputBuylist: !this.state.inputBuylist, 
    }, () => {
      if(!this.state.inputBuylist) {
        this.setState({ 
          inputBuyPrice: '', 
          errorBuyPrice: '', 
        });
      }
    });
    this.makeDirty();
  }

  getSelectableLanguages() {

    try {

      if(this.state.variantsPending) {
        return [];
      }

      const codesSelected = [];
      const languageArray = [];

      for(const vt of this.state.variantsData) {

        const lang = vt.languageObj;

        if(!codesSelected.includes(lang.code)) {
          codesSelected.push(lang.code);
          languageArray.push(lang);
        }
      }
      return languageArray.length > 0 ? languageArray : LANG_PRODUCT_GENERIC;

    } catch(err) {
      console.error(err);
    }
    return [];
  }

  render() {

    const {t} = this.props;
    const availableFinishes = getAttrFinish(this.props.productObj);

    return <div className={'ProductInventoryEdit adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INVENTORY)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'productInventoryForm adminForm'}
            onSubmit={this.saveAction.bind(this)}
            noValidate={true}>

            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

            {!this.isCreateMode() ?
              <div className='pieTitle'>
                <span className='pieTitleLabel'>{t(tx.TX_INV_VIEW_PRODUCT_CURRENTLY_EDITING)}</span>
                <span className='pieTitleValue'>{getProductInventoryLabel(this.props.productObj, this.props.inventoryObj, { translate: t, showAllLabels: true })}</span>
              </div> :
              null
            }

            {this.sealedAllowed() ?
              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_SEALED_QUESTION)}</div>
                <div className='adminInputWrapper'>
                  <div className='adminInputToggleWrapper'>
                    <Toggle
                      checked={this.state.inputSealed}
                      disabled={this.isSeadedDisabled()}
                      onToggle={this.changeSealed.bind(this)}
                      trueValue={tx.TX_INV_ADD_PRODUCT_FACTORY_SEALED}
                      falseValue={tx.TX_INV_ADD_PRODUCT_SINGLE} />
                  </div>
                </div>
              </div> :
              null
            }

            {this.isCreateMode() ?
              <div className={this.state.inputSealed ? 'adminCollapseable hidden' : 'adminCollapseable'}>
                <div className={'adminFieldWrapper'}>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_CONDITION)}</div>
                  <div className={'adminInputWrapper'}>
                    <div className='adminDropdown'>
                      <select
                        value={this.state.inputCondition ? this.state.inputCondition.key : ''}
                        disabled={this.isCreateMode() === false}
                        onChange={this.changeCondition.bind(this)}
                        className={this.state.errorCondition ? 'InputError' : ''}
                        required={true}>
                        <option value=''>{t(tx.TX_PLACEHOLDER_PRODUCT_CONDITION)}</option>
                        {CONDITIONS_PRODUCT_GENERIC.map((cond, i) => {
                          return <option key={i} value={cond.value}>{this.getConditionLabel(cond)}</option>;
                        })}
                      </select>
                      {this.state.errorCondition ?
                        <div className={'adminError FieldError'}>{t(this.state.errorCondition)}</div> :
                        null
                      }
                      <div className='adDropdownArrowWrapper'>
                        <div className='adArrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> :
              null
            }

            {this.isCreateMode() ?
              <div className={'adminFieldWrapper'}>
                <div className={'adminFieldLabel'}>{t(tx.TX_LANGUAGE)}</div>
                <div className='adminInputWrapper'>
                  <div className='adminDropdown'>
                    <select
                      value={this.state.inputLanguage ? this.state.inputLanguage.code : ''}
                      disabled={this.isCreateMode() === false || this.state.variantsPending}
                      onChange={this.changeLanguage.bind(this)}>
                      <option value=''>{this.state.variantsPending ? t(tx.TX_INV_VIEW_PRODUCT_LANGUAGES_LOADING) : t(tx.TX_PLACEHOLDER_LANGUAGE_DROPDOWN)}</option>
                      {this.getSelectableLanguages().map((lang, j) => {
                        return <option key={j} value={lang.code}>{t(lang.nameTranslation)}</option>
                      })}
                    </select>
                    {this.state.errorLanguage ?
                      <div className={'adminError FieldError'}>{t(this.state.errorLanguage)}</div> :
                      null
                    }
                    <div className='adDropdownArrowWrapper'>
                      <div className='adArrow'></div>
                    </div>
                  </div>
                </div>
              </div> :
              null
            }

            {this.isCreateMode() ?
              <div className={this.isFinishRequired() ? 'adminCollapseable' : 'adminCollapseable hidden'}>
                <div className={'adminFieldWrapper'}>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_FINISHES)}</div>
                  <div className='adminInputWrapper'>
                    <div className='adminRadioWrapper'>
                      {availableFinishes.map((finish, i) => {

                        const isDisabled = availableFinishes.length === 1 || this.isCreateMode() === false;

                        return <div className='adminRadioElementWrapper' key={i}>
                          <div className='adminRadioElement'>
                            <div className='adminRadioInputWrapper'>
                              <div className='adminRadioInput'>
                                <Radio
                                  id={`finish|${i}`}
                                  className='finishInput'
                                  name='finish'
                                  adminTheme={true}
                                  error={this.state.errorFinish}
                                  disabled={isDisabled}
                                  value={finish.key}
                                  checked={this.state.inputFinish && this.state.inputFinish.key && this.state.inputFinish.key === finish.key}
                                  onChange={this.changeFinish.bind(this)} />
                              </div>
                            </div>
                            <label htmlFor={`finish|${i}`} className={isDisabled ? 'adminRadioLabelWrapper disabled' : 'adminRadioLabelWrapper'}>
                              <div className='adminRadioLabel'>{t(finish.name)}</div>
                            </label>
                          </div>
                        </div>
                      })}
                      {this.state.errorFinish ?
                        <div className={'adminError FieldError'}>{t(this.state.errorFinish)}</div> :
                        null
                      }
                    </div>
                  </div>
                </div>
              </div> :
              null
            }

            {this.buylistAllowed() ?
              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_BUYLIST_QUESTION)}</div>
                <div className='adminInputWrapper'>
                  <div className='adminInputToggleWrapper'>
                    <Toggle
                      checked={this.state.inputBuylist}
                      onToggle={this.changeBuylist.bind(this)}
                      trueValue={tx.TX_YES}
                      falseValue={tx.TX_NO} />
                  </div>
                </div>
              </div> :
              null
            }

            <div className={'adminFieldWrapper'}>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRICE)}</div>
                <div className='currencyWrapper'>
                  <input
                    type='number'
                    min={0}
                    step={getCurrencyIncrement()}
                    className={this.state.errorPrice ? 'currencyInput InputError' : 'currencyInput'}
                    value={this.state.inputPrice}
                    onChange={this.changePrice.bind(this)}
                    onBlur={this.validatePrice.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                  <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorPrice ?
                  <div className={'adminError FieldError'}>{t(this.state.errorPrice)}</div> :
                  null
                }
              </div>

              {this.state.inputBuylist ? 
                <div className={'adminInputWrapper halfWidth'}>
                  <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}</div>
                  <div className='currencyWrapper'>
                    <input
                      type='number'
                      min={0}
                      step={getCurrencyIncrement()}
                      className={this.state.errorBuyPrice ? 'currencyInput InputError' : 'currencyInput'}
                      value={this.state.inputBuyPrice ? this.state.inputBuyPrice : ''}
                      onChange={this.changeBuyPrice.bind(this)}
                      onBlur={this.validateBuyPrice.bind(this)}
                      placeholder={t(tx.TX_PLACEHOLDER_BUY_PRICE)} />
                    <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                  </div>
                  {this.state.errorBuyPrice ?
                    <div className={'adminError FieldError'}>{t(this.state.errorBuyPrice)}</div> :
                    null
                  }
                </div> :
                null
              }
            </div>



            <div className={'adminFieldWrapper'}>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SELLABLE_QUANTITY)}</div>
                <input
                  type='number'
                  min={0}
                  step={1}
                  className={this.state.errorQuantity ? 'quantityInput InputError' : 'quantityInput'}
                  value={this.state.inputQuantity}
                  onChange={this.changeQuantity.bind(this)}
                  onBlur={this.validateQuantity.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_QUANTITY)}
                  maxLength={32} />
                {this.state.errorQuantity ?
                  <div className={'adminError FieldError'}>{t(this.state.errorQuantity)}</div> :
                  null
                }
              </div>

              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_INV_ADD_PRODUCT_TARGET_QUANTITY)}</div>
                <div className={'adminInputWrapper halfWidth'}>
                  <input
                    type='number'
                    min={0}
                    step={1}
                    className={this.state.errorQuantityMin ? 'quantityInput InputError' : 'quantityInput'}
                    value={this.state.inputQuantityMin}
                    onChange={this.changeQuantityMin.bind(this)}
                    onBlur={this.validateQuantityMin.bind(this)}
                    placeholder={t(tx.TX_MIN)}
                    maxLength={32} />
                  {this.state.errorQuantityMin ?
                    <div className={'adminError FieldError'}>{t(this.state.errorQuantityMin)}</div> :
                    null
                  }
                </div>
                <div className={'adminInputWrapper halfWidth'}>
                  <input
                    type='number'
                    min={0}
                    step={1}
                    className={this.state.errorQuantityMax ? 'quantityInput InputError' : 'quantityInput'}
                    value={this.state.inputQuantityMax}
                    onChange={this.changeQuantityMax.bind(this)}
                    onBlur={this.validateQuantityMax.bind(this)}
                    placeholder={t(tx.TX_MAX)}
                    maxLength={32} />
                  {this.state.errorQuantityMax ?
                    <div className={'adminError FieldError'}>{t(this.state.errorQuantityMax)}</div> :
                    null
                  }
                </div>
              </div>
            </div>


            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductInventoryEdit));