import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { 
  getCurrencyIncrement, 
  getCurrencyMinorCount, 
  getCurrencySymbol, 
} from '../../../utils/currency';
import {
  isFormValid, 
  getStoreCreditAmountError,
  getShortDescriptionError,
} from '../../../utils/form-validation';
import { 
  formatServerError,
  normalizePrice,
} from '../../../utils/formatting';

import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';

import '../style/_customerpermissionsedit.scss';

import * as authActionCreators from '../../../actions/auth';
import * as commonActionCreators from '../../../actions/common';
import * as customerActionCreators from '../../../actions/customer';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, commonActionCreators, customerActionCreators, userActionCreators);

export class CustomerStoreCreditAdd extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      inputAmount: '',
      inputNote: '',

      errorAmount: '',
      errorNote: '',

      requestPending: false,
      requestError: null,

      savePromptOpen: false, 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(!this.props.customer) { return null; }

      const addData = {
        delta: Math.round(parseFloat(this.state.inputAmount)*getCurrencyMinorCount()),
        note: this.state.inputNote,
      };

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const addResp = await this.props.customerStoreCreditAdd(this.props.customer.publicUuid, addData)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });

          if(this.props.remoteSave) {
            this.props.finishRemote('store-credit', true);
          }
        });

      if(!addResp) {
        return null;
      }

      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        if(this.props.remoteSave) {
          this.props.finishRemote('store-credit');
        } else {
          this.props.makeClean('store-credit');
        }
        this.props.toggleEdit();
      });
    }
  }

  validateAll() {
    const errorObj = {
      errorAmount: getStoreCreditAmountError(this.state.inputAmount), 
      errorNote: getShortDescriptionError(this.state.inputNote),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  changeAmount(evt) {
    this.setState({
      inputAmount: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorAmount) {
        this.validateAmount(false);
      }
    });
  }

  validateAmount(normalize = true) {
    this.setState({ 
      inputAmount: normalize ? normalizePrice(this.state.inputAmount) : this.state.inputAmount, 
      errorAmount: getStoreCreditAmountError(this.state.inputAmount), 
    });
  }

  changeNote(evt) {
    this.setState({
      inputNote: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorNote) {
        this.validateNote();
      }
    });
  }

  validateNote() {
    this.setState({ errorNote: getShortDescriptionError(this.state.inputNote) });
  }

  render() {

    const {t} = this.props;

    return <div className={'CustomerStoreCreditAdd adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_MENU_ITEM_STORECREDIT)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          {this.props.customerPending === false ?
            <form 
              className={'customerStoreCreditForm adminForm'}
              onSubmit={this.saveAction.bind(this)}>

              <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>


              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_AMOUNT)}</div>
                <div className={'adminInputWrapper'}>
                  <div className='currencyWrapper'>
                    <input
                      type='number'
                      min={-10000000}
                      max={10000000}
                      step={getCurrencyIncrement()}
                      className={this.state.errorAmount ? 'currencyInput InputError' : 'currencyInput'}
                      value={this.state.inputAmount}
                      onChange={this.changeAmount.bind(this)}
                      onBlur={this.validateAmount.bind(this)}
                      placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                    <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                  </div>
                  {this.state.errorAmount ?
                    <div className={'adminError FieldError'}>{t(this.state.errorAmount)}</div> :
                    null
                  }
                  <div className={'subtleSupplement fieldSupplement'}>{t(tx.TX_CUSTOMER_STORE_CREDIT_AMOUNT_CAN_BE_NEGATIVE)}</div>
                </div>

              </div>
              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_CUSTOMER_STORE_CREDIT_DESCRIPTION)}</div>
                <div className='adminInputWrapper'>
                  <textarea
                    className={this.state.errorNote ? 'InputError' : ''}
                    value={this.state.inputNote}
                    onChange={this.changeNote.bind(this)}
                    onBlur={this.validateNote.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_STORE_CREDIT_NOTE)} />
                  {this.state.errorNote ?
                    <div className={'FieldError'}>{t(this.state.errorNote)}</div> :
                    null
                  }
                </div>
              </div>

              <div className='adminActionRow'>
                <div 
                  className={'adminAction adminActionCancel'} 
                  onClick={this.tryToToggle.bind(this)}>
                  {t(tx.TX_CANCEL)}
                </div>
                <button 
                  className={'adminAction adminActionSave'} 
                  type='submit'
                  disabled={this.state.requestPending}>
                  {t(tx.TX_SAVE)}
                </button>
              </div>
              <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
                <div className='adminFormPendingScreen'></div>
                <div className='adminFormPendingWrapper'>
                  <LoadingIcon />
                </div>
              </div>
            </form> :
            null
          }
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.customerPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerStoreCreditAdd));