import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  PROD_BUYLIST_QTY_LIMIT,
  PROD_QTY_LIMIT,
} from '../../../constants/product';
import * as tx from '../../../constants/strings';

let allActionCreators = Object.assign({});

export class ProductPageInventoryQuantity extends Component {

  inventoryQuantity() {
    const cart = this.props.isBuylist ? this.props.cart.currentBuylistCart : this.props.cart.currentCart;
    return cart ? cart.getInventoryQuantity(this.props.selectedInvObj) : 0;
  }

  getMaxQty() {
    if(!this.props.selectedInvObj) {
      return this.props.isBuylist ? PROD_BUYLIST_QTY_LIMIT : PROD_QTY_LIMIT;
    }

    if(this.props.isBuylist) {
      return PROD_BUYLIST_QTY_LIMIT - this.inventoryQuantity() > 0 ? PROD_BUYLIST_QTY_LIMIT : 1;
    }

    if(this.props.selectedInvObj.totalQuantity === 0) {
      return 0;
    }

    const maxWithoutCart = this.props.selectedInvObj.totalQuantity < PROD_QTY_LIMIT ? this.props.selectedInvObj.totalQuantity : PROD_QTY_LIMIT;
    return maxWithoutCart - this.inventoryQuantity() > 0 ? maxWithoutCart - this.inventoryQuantity() : 1;
  }

  increaseQty() {
    if(this.props.qty < this.getMaxQty()) {
      this.props.setQty(this.props.qty + 1);
    }
  }

  decreaseQty() {
    if(this.props.qty > 1) {
      this.props.setQty(this.props.qty - 1);
    }
  }

  setQuantity(evt) {

    if(evt.target.value === '') {
      this.props.setQty(evt.target.value);
      return null;
    }

    let qtyInt = parseInt(evt.target.value);
    if(isNaN(qtyInt)) {
      return null;
    }

    if(qtyInt > this.getMaxQty()) {
      qtyInt = this.getMaxQty();
    }

    this.props.setQty(qtyInt);
  }

  validateQuantity(evt) {
    if(!this.props.qty) {
      this.props.setQty(1);
    }
  }

  render() {

    const {t} = this.props;



    return <div className={'ProductPageInventoryQuantity'}>
      <div className='productPageInventoryQuantityWrapper'>
        <div className='ppiQuantityInput'>
          <div className={this.props.selectedInvObj && this.props.qty > 1 ? 'ppiIncWrapper incMinus' : 'ppiIncWrapper incMinus disabledInc'} onClick={this.decreaseQty.bind(this)}>
            <div className='FlexCenter'>&mdash;</div>
          </div>
          <div className='ppiInput'>
            <input 
              type='number'
              min={1}
              max={this.getMaxQty()}
              step={1}
              value={this.props.qty}
              onChange={this.setQuantity.bind(this)}
              onBlur={this.validateQuantity.bind(this)} />
          </div>
          <div className={this.props.selectedInvObj && this.props.qty < this.getMaxQty() ? 'ppiIncWrapper incPlus' : 'ppiIncWrapper incPlus disabledInc'} onClick={this.increaseQty.bind(this)}>
            <div className='FlexCenter'>+</div>
          </div>
        </div>
        {!this.props.isBuylist && this.props.selectedInvObj && this.props.selectedInvObj.totalQuantity && this.props.selectedInvObj.totalQuantity < PROD_QTY_LIMIT ?
          <div className='ppiQuantityLimit'>
            {t(tx.TX_PRODUCT_PAGE_NUMBER_IN_STOCK, { number: PROD_QTY_LIMIT <= this.props.selectedInvObj.totalQuantity ? `${PROD_QTY_LIMIT}+` : this.props.selectedInvObj.totalQuantity })}
          </div> :
          <div className='ppiQuantityLimit'></div>
        }
        {this.props.selectedInvObj && this.inventoryQuantity() ?
          <div className='ppiCartQuantity'>
            {this.props.isBuylist ? t(tx.TX_PRODUCT_PAGE_NUMBER_IN_BUYLIST, { number: this.inventoryQuantity()}) : t(tx.TX_PRODUCT_PAGE_NUMBER_IN_CART, { number: this.inventoryQuantity()})}
          </div> :
          <div className='ppiCartQuantity'></div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageInventoryQuantity));