import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../../../utils/currency';
import { 
  getPriceError, 
  getQuantityError, 
} from '../../../../../utils/form-validation';
import { 
  formatPrice,
  normalizePrice,
} from '../../../../../utils/formatting';
import { getStoreLanguage } from '../../../../../utils/language';

import * as productActionCreators from '../../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class ProductFieldInput extends Component {

  constructor(props) {
    super(props);

    this.parseFunc = this.props.currency ? parseFloat : parseInt;

    const initValue = this.props.elemValue === null ? '' : this.props.elemValue;

    this.state = {
      
      inputField: this.props.currency ? normalizePrice(initValue) : initValue, 
      errorField: '',

      lastValue: null,

      isDirty: false,
      isPending: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    
    if(this.props.focusSignal && this.props.focusSignal !== prevProps.focusSignal) {
      if(this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }

    if((!prevProps.readOnly && this.props.readOnly) || (prevProps.elemValue !== this.props.elemValue)) {
      const elemValue = this.props.elemValue === null ? '' : this.props.elemValue;
      const fieldValue = this.props.currency ? normalizePrice(elemValue) : elemValue;
      if(fieldValue !== this.state.inputField) {
        this.setState({
          inputField: fieldValue,
          lastValue: null,
        })
      }
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getValue() {
    if(this.state.lastValue === null) {
      return this.props.elemValue === null ? '' : this.props.elemValue;
    }
    return this.state.lastValue;
  }

  changeAction(evt) {
    this.setState({
      inputField: evt.target.value,
      isDirty: true,
    }, () => {

    });
  }

  blurAction(evt) {

    if(this.state.isDirty && this.parseFunc(this.state.inputField) !== this.parseFunc(this.getValue())) {

      if(this.props.currency && normalizePrice(this.state.inputField) !== this.state.inputField) {
        this.setState({
          inputField: normalizePrice(this.state.inputField),
        }, () => {
          this.saveAction();
        });
      } else {
        this.saveAction();
      }
    }
  }

  keyPressAction(evt) {
    if(evt.keyCode === 37) {
      // Left arrow
      if(this.props.fieldIndex >= 0) {
        this.props.indexNav(this.props.fieldIndex - 1);
      }
    } else if(evt.keyCode === 39) {
      // Right arrow
      this.props.indexNav(this.props.fieldIndex + 1);
    }
  }

  isValid() {

    const allowEmpty = this.props.allowEmpty || false;

    const inputError = this.props.currency ? getPriceError(this.state.inputField, allowEmpty) : getQuantityError(this.state.inputField, allowEmpty);
    if(inputError) {
      this.setState({ errorField: 'error' });
      return false;
    }

    return true;
  }

  async saveAction() {

    if(this.isValid()) {

      this.setState({ 
        lastValue: this.state.inputField, 
        errorField: '',
        isDirty: false,
        isPending: true,
      });

      const updateData = {
        [this.props.elemKey]: this.state.inputField,
      }

      const pResp = await this.props.productInventoryEdit(this.props.inventory.getUpdateApiData(updateData, this.props.product))
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            errorField: 'error',
            isPending: false,
          });
        });

      if(!pResp) {
        return null;
      }

      this.setState({
        isPending: false,
      }, () => {
        if(this.props.refreshData) {
          this.props.refreshData(false, false, true, true);
        }
      });
    }
  }

  getReadOnlyValue() {
    
    const val = this.getValue();

    try {
      if(this.props.currency) {
        return formatPrice(val, { language: this.getLanguage() });
      }
      const parsedInt = parseInt(val);
      if(isNaN(parsedInt)) {
        return '';
      }
      return parsedInt.toLocaleString(this.getLanguage());
    } catch(err) {
      console.error(err);
    }
    return val || '';
  }

  render() {

    return <div className={'ProductFieldInput ProductField'}>
      <div className='inputLiner'>
        {this.props.readOnly ?
          <div className='readOnlyWrapper'>
            <div className='readOnlyElement'>
              <div 
                className={'readOnlyValue FlexCenter'}
                dangerouslySetInnerHTML={{ __html: this.getReadOnlyValue() }} />
            </div>
          </div> :
          <div className='editableWrapper'>
            <div className='inputWrapper'>
              <input
                type='number'
                ref={this.inputRef}
                className={`${this.props.currency ? 'currencyInput' : ''} ${this.state.errorField ? 'error' : ''} ${this.state.isPending ? 'pending' : ''}`}
                min={0}
                step={this.props.currency ? getCurrencyIncrement() : 1}
                value={this.state.inputField}
                onChange={this.changeAction.bind(this)}
                onBlur={this.blurAction.bind(this)}
                onKeyDown={this.keyPressAction.bind(this)}
                tabIndex={this.props.tabIndex} />
              {this.props.currency ?
                <div className='currencyIndication'>{getCurrencySymbol()}</div> :
                null
              }
            </div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductFieldInput));