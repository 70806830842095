import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_MENU_DOTS,
  ICON_OPEN_EXTERNAL,
  ICON_SHIPPING, 
} from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  dateShort,
  formatServerError,
  stringFormat, 
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import Confirm from '../../Popups/Confirm';
import { Icon } from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';
import MiniCart from '../../Cart/MiniCart';

import * as commonActionCreators from '../../../actions/common';
import * as storeActionCreators from '../../../actions/store';
const allActionCreators = Object.assign({}, commonActionCreators, storeActionCreators);

export class OrderShipmentsView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: 0,

      labelReqError: null, 
      labelReqPending: false,
      labelReqResp: null,

      makeClaimOpen: false,
      viewClaimOpen: false,
    };

    this.LABEL_ACTION_PRINT = 'print';
    this.LABEL_ACTION_VIEW = 'view';
    this.LABEL_ACTION_CLAIM_CREATE = 'create_claim';
    this.LABEL_ACTION_CLAIM_VIEW = 'view_claim';
    
    this.SHIPMENT_ACTION_CANCEL = 'cancel';

    this.controller = null;
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  selectShipment(idx) {
    if(idx !== this.state.selectedRow) {
      this.setState({
        selectedRow: idx,
      });
    }
  }

  getCarrierName(shipment) {
    return shipment.carrier.name;
  }

  async fetchLabel(label) {
    
    if(!label) { return null; }

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      labelReqPending: true,
      labelReqError: null,
    });

    const labelResp = await this.props.storeIntegrationStampsGetLabel(this.props.order.publicUuid, label.labelId, controller.signal)
      .catch((errResp) => {

        if(controller.signal.aborted) { return null; }
        console.error(errResp);

        this.setState({ 
          labelReqPending: false,
          labelReqError: formatServerError(errResp),
          labelReqResp: null,
        });
      });

    if(!labelResp) {
      return null;
    }

    this.setState({
      labelReqPending: false,
      labelReqError: null,
      labelReqResp: labelResp,
    });

    return labelResp;
  }

  async getMakeClaimUrl(label) {

    if(!label) { return null; }

    this.setState({
      labelReqPending: true,
      labelReqError: null,
    });

    const claimResp = await this.props.storeIntegrationStampsParcelguardMakeClaim(this.props.order.publicUuid, label.labelId)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          labelReqPending: false,
          labelReqError: formatServerError(errResp),
        });
      });

    if(!claimResp) {
      return null;
    }

    this.setState({
      labelReqPending: false,
      labelReqError: null,
    }, () => {
      this.setState({
        makeClaimOpen: true,
        claimUrl: claimResp.url,
      });
    });

    return null;
  }

  async getViewClaimUrl(label) {

    if(!label) { return null; }

    this.setState({
      labelReqPending: true,
      labelReqError: null,
    });

    const claimResp = await this.props.storeIntegrationStampsParcelguardViewClaim(this.props.order.publicUuid, label.labelId)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          labelReqPending: false,
          labelReqError: formatServerError(errResp),
        });
      });

    if(!claimResp) {
      return null;
    }

    this.setState({
      labelReqPending: false,
      labelReqError: null,
    }, () => {
      this.setState({
        viewClaimOpen: true,
        claimUrl: claimResp.url,
      });
    });

    return null;
  }

  async shipmentAction(evt, shipment) {
    if(!shipment) { return null; }

    const labelActionKey = evt.target.value;
    let label = shipment.label;

    const refreshActions = [
      this.LABEL_ACTION_PRINT,
      this.LABEL_ACTION_VIEW,
    ];

    if(refreshActions.includes(labelActionKey)) {

      const labelResp = await this.fetchLabel(label);
      if(!labelResp) {
        this.props.commonAlert({ 
          alertTitle: tx.TX_ERROR, 
          alertCopy: tx.TX_ORDER_SHIPMENT_LABEL_ERROR_STAMPS, 
        });
        return null;
      }
      label = labelResp;
    }

    switch(labelActionKey) {
      case this.LABEL_ACTION_PRINT:
        if(!label) { return null; }
        label.print();
        break;
      case this.LABEL_ACTION_VIEW:
        if(!label) { return null; }
        label.openTab();
        break;
      case this.LABEL_ACTION_CLAIM_CREATE:
        if(!label) { return null; }
        await this.getMakeClaimUrl(label);
        break;
      case this.LABEL_ACTION_CLAIM_VIEW:
        if(!label) { return null; }
        await this.getViewClaimUrl(label);
        break;
      case this.SHIPMENT_ACTION_CANCEL:
        this.props.openCancelShipmentModal(shipment);
        break;
      default:
        return null;
    }
  }

  getShipmentsArray() {

    if(!this.props.order || !this.props.order.shipments) { return []; }

    const shipmentsArray = [];
    for(const ship of this.props.order.shipments) {
      if(!ship.isCancelled) {
        shipmentsArray.push(ship);
      }
    }
    return shipmentsArray;
  }

  closeMakeClaim() {
    this.setState({
      makeClaimOpen: false,
      claimUrl: '',
    });
  }

  closeViewClaim() {
    this.setState({
      viewClaimOpen: false,
      claimUrl: '',
    });
  }

  openClaimUrl(evt) {
    window.open(this.state.claimUrl, '_blank');
  }

  render() {

    const {t} = this.props;
    const itemCount = this.props.order && this.props.order.cart ? this.props.order.cart.quantity : 0;

    return <div className={'OrderShipmentsView adminSection'}>
    	<div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_ORDER_SHIPMENTS)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='osvLiner'>
              {!this.props.order || !this.props.order.shipments || this.getShipmentsArray().length === 0 ?
                <div className='noShipmentsWrapper'>
                  <div className='shippingIconWrapper'>
                    <Icon 
                      value={ICON_SHIPPING}  
                      iconClass={'shippingIcon'} />
                  </div>
                  <div className='noShipmentsCopy'>{t(this.props.order && this.props.order.readyForShipment ? tx.TX_ORDER_NO_SHIPMENTS : tx.TX_ORDER_NO_SHIPMENTS_REQUIRED)}</div>
                  {this.props.order && this.props.order.readyForShipment ?
                    <button 
                      type='button'
                      className={'noShipmentsAction adminButton'}
                      onClick={() => this.props.openCreateShipmentModal(null)}>
                      {t(tx.TX_ORDER_CREATE_SHIPMENT)}
                    </button> :
                    null
                  }
                </div> :
                <div className='shipmentListWrapper'>
                  <div className='shipmentList'>
                    <div className={'slHeader'}>
                      <div className={'slHeaderCell dateCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_DATE)}</div>
                      </div>
                      <div className={'slHeaderCell carrierCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_ORDER_SHIPMENT_CARRIER_SHORT)}</div>
                      </div>
                      <div className={'slHeaderCell trackingCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_ORDER_SHIPMENT_TRACKING_NUMBER)}</div>
                      </div>
                    </div>
                    {this.getShipmentsArray().map((shipment, i) => {
                      return <div 
                              key={i}
                              className={this.state.selectedRow === i ? 'slItem slSelected' : 'slItem'}
                              onClick={() => this.selectShipment(i)}>
                        <div className={'slItemCell dateCell'}>
                          <div className='slItemValue'>{dateShort(shipment.date, this.getLanguage())}</div>
                        </div>
                        <div className={'slItemCell carrierCell'}>
                          <div className='slItemValue'>
                            <div className='carrierNameOnly'>{shipment.carrier.isOther() ? shipment.carrierName : t(shipment.carrierName)}</div>
                            <div className='carrierCompoundName'>
                              <div className='ccnName'>{shipment.carrier.isOther() ? shipment.carrierName : t(shipment.carrierName)}</div>
                              {shipment.trackingNumber ?
                                <div className='ccnTracking'>
                                  <a 
                                    className='ccnTrackingLink'
                                    target='_blank' 
                                    rel='noreferrer'
                                    href={stringFormat(shipment.carrier.trackingUrl, { trackingNumber: shipment.trackingNumber })}>
                                    <div className='ccnTrackingNumber'>{shipment.trackingNumber}</div>
                                    <div className='ccnTrackingIcon'>
                                      <Icon value={ICON_OPEN_EXTERNAL} iconClass='trackingIcon' />
                                    </div>
                                  </a>
                                </div> :
                                null
                              }
                            </div>
                          </div>
                        </div>
                        <div className={'slItemCell trackingCell'}>
                          {shipment.trackingNumber ?
                            <div className='slItemValue hasTrackng'>
                              <a 
                                target='_blank' 
                                rel='noreferrer'
                                href={stringFormat(shipment.carrier.trackingUrl, { trackingNumber: shipment.trackingNumber })}>
                                <div className='slTrackingNumber'>{shipment.trackingNumber}</div>
                                <div className='slTrackingIconWrapper'>
                                  <Icon value={ICON_OPEN_EXTERNAL} iconClass='trackingIcon' />
                                </div>
                              </a>
                            </div> :
                            <div className={'slItemValue noTrackng'}>{t(tx.TX_NONE)}</div>
                          }
                        </div>

                        <div className={'slItemCell menuCell'}>
                          {this.state.labelReqPending ?
                            <div className='menuIconWrapper'>
                              <LoadingIcon />
                            </div> :
                            <>
                              <div className='menuIconWrapper'>
                                <Icon value={ICON_MENU_DOTS} />
                              </div>
                              <select className='menuSelect' value={''} onChange={(evt) => this.shipmentAction(evt, shipment)}>
                                <option value={''}>{t(tx.TX_PLACEHOLDER_ACTIONS)}</option>
                                {shipment.label ?
                                  <>
                                    <option value={this.LABEL_ACTION_PRINT}>{t(tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_PRINT)}</option>
                                    <option value={this.LABEL_ACTION_VIEW}>{t(tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_VIEW)}</option>
                                    {shipment.configData && shipment.configData.parcel_guard ?
                                      <>
                                        <option value={this.LABEL_ACTION_CLAIM_CREATE}>{t(tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_MAKE_CLAIM)}</option>
                                        <option value={this.LABEL_ACTION_CLAIM_VIEW}>{t(tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_VIEW_CLAIM)}</option> 
                                      </> :
                                      null
                                    }
                                  </> :
                                  null
                                }
                                <option value={this.SHIPMENT_ACTION_CANCEL}>{t(tx.TX_ORDER_SHIPMENT_CANCEL)}</option>
                              </select>
                            </>
                          }
                        </div>

                      </div>
                    })}
                  </div>
                  <div className='shipmentInfo'>
                    <div className='infoSectionTitle'>
                      <div className='infoSectionTitleValue'>{t(tx.TX_SHIPMENT)}</div>
                      <div className='infoSectionTitleItems'>({itemCount === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: itemCount })})</div>
                    </div>
                    <div className='shipmentItemsWrapper'>
                      <MiniCart
                        order={this.props.order}
                        cartOverride={this.props.order ? this.props.order.cart : null}
                        readOnly={true} />
                    </div>
                  </div>
                </div>
              }
            </div>
            {this.props.order && this.props.order.readyForShipment ?
              <div className='adminViewButtonWrapper'>
                <button 
                  type='button'
                  className={'productDetailsButton adminButton'} 
                  onClick={() => this.props.openCreateShipmentModal(null)}
                  disabled={this.props.order && this.props.order.readyForShipment === false}>
                  {t(tx.TX_ORDER_CREATE_SHIPMENT)}
                </button>
              </div> :
              null
            }
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.orderPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
      <Confirm
        title={tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_MAKE_CLAIM}
        copy={tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_MAKE_CLAIM_COPY}
        open={this.state.makeClaimOpen}
        confirmValue={tx.TX_CREATE}
        closeMethod={this.closeMakeClaim.bind(this)}
        onConfirm={this.openClaimUrl.bind(this)} />
      <Confirm
        title={tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_VIEW_CLAIM}
        copy={tx.TX_ORDER_SHIPMENT_SHIPPING_LABEL_PARCELGUARD_VIEW_CLAIM_COPY}
        open={this.state.viewClaimOpen}
        confirmValue={tx.TX_VIEW}
        closeMethod={this.closeViewClaim.bind(this)}
        onConfirm={this.openClaimUrl.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderShipmentsView));