import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import {
  URL_ADMIN_SETTINGS, 
} from '../../constants/urls';

import { formatServerError } from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import StoreSettingsDetailsEdit from './blocks/StoreSettingsDetailsEdit';
import StoreSettingsDetailsView from './blocks/StoreSettingsDetailsView';
import StoreSettingsHoursEdit from './blocks/StoreSettingsHoursEdit';
import StoreSettingsHoursView from './blocks/StoreSettingsHoursView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_storesettings.scss';

import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, userActionCreators);

export class StoreSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirtyDetails: false,
      isDirtyHours: false,
      savePromptOpen: false,
      lastBlock: '',

      editDetails: false,
      editHours: false,

      hoursExpanded: true,

      requestError: null,
      responseObject: null,

      savingAll: false,
      remoteSaveDetails: false,
      remoteSaveHours: false,

      lockConfirmOpen: false,
    };

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyDetails === false && this.state.isDirtyHours === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchStore();
  }

  componentWillUnmount() {
    this.unblock();

    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyDetails === false && this.state.isDirtyHours === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchStore();
      }
    }
  }

  async fetchStore(service = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestError: null,
    });

    // Get store
    const storeResp = await this.props.userFetchStore(controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }
        this.setState({ 
          requestError: formatServerError(errResp),
        });
      });

    if(!storeResp) {
      return null;
    }

    this.setState({
      requestError: null,
    });

    if(service === 'details') {
      this.setState({ isDirtyDetails: false });
    } else if(service === 'hours') {
      this.setState({ isDirtyHours: false });
    }
  }

  makeDetailsDirty() {
    if(this.state.isDirtyDetails === false) {
      this.setState({ isDirtyDetails: true });
    }
  }

  makeHoursDirty() {
    if(this.state.isDirtyHours === false) {
      this.setState({ isDirtyHours: true });
    }
  }

  toggleDetails() {
    this.setState({ editDetails: !this.state.editDetails }, () => {
      if(this.state.editDetails === false) {
        this.setState({ isDirtyDetails: false, });
      }
    });
  }

  toggleHours() {
    this.setState({ editHours: !this.state.editHours }, () => {
      if(this.state.editHours === false) {
        this.setState({ isDirtyHours: false, });
      }
    });
  }

  toggleHoursExpanded() {
    this.setState({ hoursExpanded: !this.state.hoursExpanded });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      savingAll: true, 
      remoteSaveDetails: this.state.isDirtyDetails,
      remoteSaveHours: this.state.isDirtyHours,
    });
  }

  finishRemote(service, didFail = false) {

    if(service === 'details') {
      this.setState({ 
        isDirtyDetails: didFail !== false, 
        remoteSaveDetails: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true || this.state.remoteSaveHours === true });
      });
    }

    if(service === 'hours') {
      this.setState({ 
        isDirtyHours: didFail !== false, 
        remoteSaveHours: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true || this.state.remoteSaveHours === true });
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'StoreSettings AdminPage'}>
      <AdminTitle
        title={tx.TX_SETTINGS_STORE_SETTINGS}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>
          {this.state.editDetails ? 
            <StoreSettingsDetailsEdit
              toggleEdit={this.toggleDetails.bind(this)}
              makeDirty={this.makeDetailsDirty.bind(this)}
              makeClean={this.fetchStore.bind(this)} 
              remoteSave={this.state.remoteSaveDetails}
              finishRemote={this.finishRemote.bind(this)} /> :
            <StoreSettingsDetailsView
              toggleEdit={this.toggleDetails.bind(this)} />
          }
          {this.state.editHours ? 
            <StoreSettingsHoursEdit
              toggleEdit={this.toggleHours.bind(this)}
              makeDirty={this.makeHoursDirty.bind(this)}
              makeClean={this.fetchStore.bind(this)} 
              remoteSave={this.state.remoteSaveHours}
              finishRemote={this.finishRemote.bind(this)}
              expanded={this.state.hoursExpanded}
              toggleExpanded={this.toggleHoursExpanded.bind(this)} /> :
            <StoreSettingsHoursView
              toggleEdit={this.toggleHours.bind(this)}
              expanded={this.state.hoursExpanded}
              toggleExpanded={this.toggleHoursExpanded.bind(this)} />
          }
        </div>
      </div>
      <div className='adminActions'>
        <Link to={URL_ADMIN_SETTINGS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StoreSettings));