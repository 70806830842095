

import {
	ERROR_FILE_IMAGE_PRODUCT_SIZE,
	ERROR_FILE_IMAGE_PRODUCT_TYPE,
	ERROR_FILE_BULK_CSV_UPLOAD_SIZE,
	ERROR_FILE_BULK_CSV_UPLOAD_TYPE,
} from '../constants/errors';


export function getErrorProductImage(file) {

	if(!file) {
		return '';
	}

	const flMime = file.type;
	const flSize = file.size;

	const validMimes = [
		'image/jpeg',
		'image/png',
		'image/webp',
	];

	// Upload limit: 50MB
	const maxFileSize = 50 * 1024 * 1024; 

	if(!validMimes.includes(flMime)) {
		return ERROR_FILE_IMAGE_PRODUCT_TYPE;
	} 
	if(flSize > maxFileSize) {
		return ERROR_FILE_IMAGE_PRODUCT_SIZE;
	}

	return '';
}


export function generateMediaFilename(file) {

	if(!file) {
		return '';
	}

	const filenameChars = 'abcdefghijklmnopqrstuvwxyz1234567890';
	const filenameLength = 24;

	let filestem = '';
	for(let i = 0; i < filenameLength; i++) {
		filestem += filenameChars.charAt(Math.floor(Math.random() * filenameChars.length));
	}
	const nameArray = file.name.split('.');
	const fileExtention = nameArray[nameArray.length - 1];

	return `${filestem}.${fileExtention}`;
}


export function getErrorBulkCSVImport(file, mbSizeLimit = 100) {

	if(!file) {
		return '';
	}

	const flMime = file.type;
	const flSize = file.size;

	const validMimes = [
		'text/csv',
	];

	// Default upload limit: 100MB
	const maxFileSize = mbSizeLimit * 1024 * 1024; 

	if(!validMimes.includes(flMime)) {
		return ERROR_FILE_BULK_CSV_UPLOAD_TYPE;
	} 
	if(flSize > maxFileSize) {
		return ERROR_FILE_BULK_CSV_UPLOAD_SIZE;
	}

	return '';
}










