

import {
	TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FIRST,
	TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_SECOND,
	TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_THIRD,
	TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FOURTH,
	TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_LAST,
	TX_null,
} from './strings';

export const EVENT_EXCEPTION_TYPE_DELETION = 'deletion';
export const EVENT_EXCEPTION_TYPE_MODIFICATION = 'modification';

export const EVENT_FILTER_KEY_FORMAT = 'format';
export const EVENT_FILTER_KEY_PREMIUM = 'premium';
export const EVENT_FILTER_KEY_PRODUCT_LINE = 'productLine';

export const EVENT_FREQUENCY_DAILY = 'daily';
export const EVENT_FREQUENCY_WEEKLY = 'weekly';
export const EVENT_FREQUENCY_MONTHLY_WEEK = 'monthly:week';
export const EVENT_FREQUENCY_MONTHLY_DATE = 'monthly:date';

export const EVENT_FREQUENCY_API_DAILY = 'daily';
export const EVENT_FREQUENCY_API_WEEKLY = 'weekly';
export const EVENT_FREQUENCY_API_MONTHLY = 'monthly';

export const EVENT_FREQUENCY_MAP_TO_BACKEND = {
	[EVENT_FREQUENCY_DAILY]: EVENT_FREQUENCY_API_DAILY,
	[EVENT_FREQUENCY_WEEKLY]: EVENT_FREQUENCY_API_WEEKLY,
	[EVENT_FREQUENCY_MONTHLY_WEEK]: EVENT_FREQUENCY_API_MONTHLY,
	[EVENT_FREQUENCY_MONTHLY_DATE]: EVENT_FREQUENCY_API_MONTHLY,
}

export const EVENT_MONTHLY_WEEK_OPTIONS = [
	TX_null,
	TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FIRST,
  TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_SECOND,
  TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_THIRD,
  TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_FOURTH,
  TX_CAL_FREQUENCY_MONTHLY_BY_WEEK_LAST,
]