import React from 'react';

import logos from './img/launch/logos_desk.svg';
import logosMobile from './img/launch/logos_m.svg';

import '../../style/_oasis.scss';

export const MaintenanceOasisLaunch = () => {

  return (
    <div className='MaintenanceOasisLaunch'>
      <div className={'mainLiner FlexCenter'}>
        <div className='mainBoxWrapper'>
          <div className='mainBox'>
            <div className='mainBoxLiner'>
              <div className='mainTitleWrapper'>
                <div className='mainTitle'>
                  <img className='titleImg' src={logos} alt={'Oasis and Talaria'} />
                  <img className='titleImgMobile' src={logosMobile} alt={'Oasis and Talaria'} />
                </div>
              </div>
              <div className='mainCopyWrapper'>
                <div className='mainP'>We are performing scheduled website maintenance, and we'll be back up and swimming shortly.</div>
                <div className='mainP'>For questions, clues, lava vents or cheers, please send us a message at <a href='mailto:info@oasisgamesslc.com' className='mainLink'>info@oasisgamesslc.com</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceOasisLaunch;




