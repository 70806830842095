import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ICON_ADD, 
  ICON_CART, 
  ICON_CHECK,
} from '../../../../constants/icons';
import { 
  STORE_BUYLIST_CONFIGS,
  STORE_CODE, 
} from '../../../../constants/store';
import * as tx from '../../../../constants/strings';

import { 
  formatPrice, 
  formatServerError, 
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import { 
  getOrderedInventory, 
  getProductInventoryLabel, 
} from '../../../../utils/product';

import { Icon } from '../../../Icons/Icon';
import { LoadingIcon } from '../../../Icons/LoadingIcon';

import * as cartActionCreators from '../../../../actions/cart';
const allActionCreators = Object.assign({}, cartActionCreators);

export class GeneralThumbnailAdd extends Component {

  constructor(props) {
    super(props);

    this.MENU_DELAY = 150;

    this.state = {
      availableInventory: this.getApplicableInventory() || [], 
      drawerOpen: false,
      drawerClosable: false,
      drawerSelected: null,

      requestPending: false,
      requestError: null,
      requestSuccess: false,
    };

    this.closeTimeout = null;

    this.dropdownBody = React.createRef();
    this.successElement = React.createRef();

    this.checkClick = this.checkClick.bind(this);
    this.checkKeypress = this.checkKeypress.bind(this);
    this.animationEnd = this.animationEnd.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
    window.addEventListener('keydown', this.checkKeypress, false);

    if(this.successElement && this.successElement.current) {
      this.successElement.current.addEventListener('animationend', this.animationEnd);
    }
  }

  componentWillUnmount() {
    
    document.removeEventListener('click', this.checkClick, false);
    window.removeEventListener('keydown', this.checkKeypress, false);

    if(this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }

    if(this.successElement && this.successElement.current) {
      this.successElement.current.removeEventListener('animationend', this.animationEnd);
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  animationEnd() {
    this.setState({ requestSuccess: false });
  }

  checkClick(evt) {
    if(this.state.drawerOpen && this.state.drawerClosable) {
      let targetElement = evt.target;
      do {
        if(this.dropdownBody && targetElement === this.dropdownBody.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeDropdown();
    }
  }

  checkKeypress(evt) {
    if(evt && this.state.drawerOpen) {

      evt.preventDefault();

      switch(evt.key) {
        case 'ArrowUp':
          this.incrementSelected();
          break;
        case 'ArrowDown':
          this.decrementSelected();
          break;
        case 'Enter':
          this.submitSelected();
          break;
        case 'Escape':
          this.closeDropdown();
          break;
        default:
          break;
      }
    }
  }

  incrementSelected() {
    
    const idx = this.state.drawerSelected ? this.state.availableInventory.map(ai => ai.id).indexOf(this.state.drawerSelected) + 1 : this.state.availableInventory.length - 1;

    if(idx >= this.state.availableInventory.length) {
      this.setState({ drawerSelected: this.state.availableInventory[0].id });
    } else {
      this.setState({ drawerSelected: this.state.availableInventory[idx].id });
    }
  }

  decrementSelected() {

    const idx = this.state.drawerSelected ? this.state.availableInventory.map(ai => ai.id).indexOf(this.state.drawerSelected) - 1 : 0;

    if(idx < 0) {
      this.setState({ drawerSelected: this.state.availableInventory[this.state.availableInventory.length - 1].id });
    } else {
      this.setState({ drawerSelected: this.state.availableInventory[idx].id });
    }
  }

  submitSelected() {
    if(this.state.drawerSelected) {
      const idx = this.state.availableInventory.map(ai => ai.id).indexOf(this.state.drawerSelected);
      this.selectAction(null, this.state.availableInventory[idx]);
    }
  }

  inventoryCombinations() {

    const configObj = this.getConfigObject();
    const keys = Object.keys(configObj);
    const valuesArrays = keys.map(key => configObj[key]);

    function combine(valuesArrays, index = 0, currentCombination = {}) {
        if (index === valuesArrays.length) {
            return [currentCombination];
        }

        const allCombinations = [];
        for(const value of valuesArrays[index]) {
          const newCombination = { ...currentCombination, [keys[index]]: value };
          const combinations = combine(valuesArrays, index + 1, newCombination);
          allCombinations.push(...combinations);
        }

        return allCombinations;
    }

    return combine(valuesArrays);
  }

  getConfigObject() {
    const productConfigs = {};

    if(STORE_BUYLIST_CONFIGS[STORE_CODE]) {
      for(const key in STORE_BUYLIST_CONFIGS[STORE_CODE]) {
        productConfigs[key] = STORE_BUYLIST_CONFIGS[STORE_CODE][key];
      }
    }

    if(this.props.productObj.hasFinish()) {
      productConfigs['finish'] = this.props.productObj.allFinishes();
    }

    if(this.props.productObj.hasPrinting()) {
      productConfigs['printing'] = this.props.productObj.allPrintings();
    }

    return productConfigs;
  }

  clickAction(evt, inv) {
    if(evt) { 
      evt.preventDefault();
      evt.stopPropagation();
    }

    if(this.isDisabledAll()) { return null; }

    // Check to see if drawer needs to be opened
    if(this.state.availableInventory.length === 1) {
      this.selectAction(null, this.state.availableInventory[0]);
    } else if(this.state.availableInventory.length > 1) {
      if(!this.state.drawerOpen) {
        this.openDropdown();
      } else {
        this.closeDropdown();
      }
    }
  }

  openDropdown() {
    this.setState({ 
      drawerOpen: true,
      drawerClosable: false, 
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({ drawerClosable: true });
      }, this.MENU_DELAY);
    });
  }

  closeDropdown() {
    this.setState({ 
      drawerOpen: false, 
      drawerClosable: false, 
      drawerSelected: null,
    });
  }

  getApplicableInventory() {
    
    const inventoryResp = [];
    const configObj = this.getConfigObject();
    
    for(const invConfig of this.inventoryCombinations()) {
      for(const dbInv of getOrderedInventory(this.props.productObj)) {
        if(!dbInv.isBuylist || dbInv.buyPrice <= 0) {
          continue;
        }

        let invMatched = true;
        for(const confKey in configObj) {

          if(dbInv[confKey]) {

            // We check for both code and key; this should be addressed at somepoint so there is something resembling coherance in config structure
            const dbInvKey = dbInv[confKey].key || dbInv[confKey].code || null;
            const combinationInvKey = invConfig[confKey].key || invConfig[confKey].code || null;

            if(dbInvKey !== combinationInvKey) {
              invMatched = false;
              break;
            }
          } else {
            invMatched = false;
            break;
          }
        }

        if(invMatched) {
          inventoryResp.push(dbInv);
          break;
        }
      }
    }
    return inventoryResp;
  }

  async selectAction(evt, inv) {
    
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    if(!inv || this.isDisabledInventory(inv)) { return null; }

    this.closeDropdown();

    this.setState({
      requestPending: true,
      requestError: null, 
      requestSuccess: false,
    });

    const addData = {
      inventory_id: inv.id, 
      quantity: 1, 
      product_id: this.props.productObj.id, 
    };

    let addResp = null;
    if(this.props.isBuylist) {

      addResp = await this.props.cartAddBuylistProduct(addData)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }

          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp), 
          });
        });
    } else {
      addResp = await this.props.cartAddProduct(addData)
        .catch((errResp) => {
          if(errResp) { console.error(errResp); }

          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp), 
          });
        });
    }

    if(!addResp) {
      return null;
    }

    if(this.props.isBuylist) {
      if(!this.props.cart.buylistMode) {
        this.props.setBuylistMode(true);
      }
    } else {
      if(this.props.cart.buylistMode) {
        this.props.setBuylistMode(false);
      }
    }

    this.props.cartFetchCart()
    .then((resp) => {
      // this.props.cartToggleMinicartSlider();
      this.setState({
        requestPending: false,
        requestError: null, 
        requestSuccess: true,
      });
    })
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }

      this.setState({
        requestPending: false,
        requestError: formatServerError(errResp), 
      });
    });
  }

  mouseEnterAction(inv) {
    if(!inv) { return null; }

    if(this.state.drawerSelected !== inv.id) {
      this.setState({ drawerSelected: inv.id });
    }
  }

  mouseLeaveAction(inv) {
    if(!inv) { return null; }

    if(this.state.drawerSelected === inv.id) {
      this.setState({ drawerSelected: null });
    }
  }

  isDisabledAll() {
    for(const inv of this.state.availableInventory) {
      if(!this.isDisabledInventory(inv)) {
        return false;
      }
    }
    return true;
  }

  isDisabledInventory(inv) {
    if(!inv) { return true; }

    if(this.props.isBuylist) {
      const cart = this.props.cart.currentBuylistCart;
      if(!cart) {
        return true;
      }
    } else {
      // Add regular cart logic here when necessary
    }
    return false;
  }

  render() {

    // A lot in here is customized to Oasis; only shows on buylist side, and will need testing when adding other conditions/languages
    if(!this.props.isBuylist || !this.props.productObj || !this.props.productObj.productLine.hasBuylist || !STORE_BUYLIST_CONFIGS[STORE_CODE] || this.state.availableInventory.length === 0) { 
      return null; 
    }

    const {t} = this.props;

    return <div className={'GeneralThumbnailAdd'}>
      <div className={`addSuccess ${this.state.requestSuccess ? 'animate' : ''}`} ref={this.successElement}>
        <div className={'addSuccessLiner'}>
          <div className='successIconWrapper'>
            <Icon 
              value={ICON_CHECK}  
              iconClass={'successIcon'} 
              ellipsisLabel={false} />
          </div>
          <div className='successValueWrapper'>{t(tx.TX_ADDED)}</div>
        </div>
      </div>
      <div className={`addButtonLiner ${this.state.requestPending ? 'loading' : ''} ${this.isDisabledAll() ? 'disabled' : ''}`} onClick={this.clickAction.bind(this)}>
        {!this.state.requestPending ?
          <>
            <div className='addButtonIconWrapper'>
              <Icon 
                value={ICON_ADD}  
                iconClass={'addIcon'} 
                ellipsisLabel={false} />
            </div>
            <div className='addButtonIconWrapper'>
              <Icon 
                value={ICON_CART}  
                iconClass={'addIcon'} 
                ellipsisLabel={false} />
            </div>
          </> :
          <div className={'addButtonIconWrapper'}>
            <LoadingIcon iconClass={'addIcon'} />
          </div>
        }
      </div>
      <div className={`addDrawerWrapper ${this.state.drawerOpen ? 'open' : ''}`} ref={this.dropdownBody}>
        <div className='addDrawerLiner'>
          {this.state.availableInventory.map((inv, i) => {
            return <div 
                    key={i} 
                    className={`addDrawerLineWrapper ${this.isDisabledInventory(inv) ? 'disabled' : ''}`}
                    onClick={(evt) => this.selectAction(evt, inv)}
                    onMouseEnter={() => this.mouseEnterAction(inv)}
                    onMouseLeave={() => this.mouseLeaveAction(inv)}>
              <div className={`addDrawerLine ${this.state.drawerSelected === inv.id ? 'hovered' : ''}`}>
                <div className='addDrawerLabel'>{getProductInventoryLabel(this.props.productObj, inv, { translate: t })}</div>
                <div 
                  className='addDrawerPrice'
                  dangerouslySetInnerHTML={{ 
                    __html: formatPrice(inv.buyPrice, { addTags: true, language: this.getLanguage() }) 
                  }} />
              </div>
            </div>
          })}
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(GeneralThumbnailAdd));