import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { ERROR_INTEGRATION_PROVIDER_MISSING } from '../../constants/errors';
import { 
  STORE_INTEGRATION_TYPE_SHIPPING_KEY, 
  STORE_INTEGRATIONS_ALL,
} from '../../constants/store';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_SETTINGS, 
  URL_ADMIN_SETTINGS_INTEGRATIONS, 
  URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW, 
} from '../../constants/urls';

import { 
  Integration, 
  IntegrationProvider, 
} from '../../models/integrations';

import { 
  getNotEmptyError,
  isFormValid, 
} from '../../utils/form-validation';
import { 
  formatServerError, 
  stringFormat,
} from '../../utils/formatting';
import { getShippingCountries } from '../../utils/geography';

import AdminTitle from '../Admin/AdminTitle';
import Dropdown from '../Input/Dropdown';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';

import './style/_integrations.scss';

import * as storeActionCreators from '../../actions/store';
let allActionCreators = Object.assign({}, storeActionCreators);

export class AddIntegration extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      savePromptOpen: false,

      integrationsPending: false,
      integrations: [],

      inputProvider: '', 

      errorProvider: '', 

      lastBlock: '',
      saveRedirect: false,

      requestPending: false,
      requestError: null,
    };

    // Right now, we check countries by where the store operates; it may also be necessary in the future to filter by the country the store is located in
    this.SHIPPING_COUNTRIES = getShippingCountries();

    this.controller = null;
  }

  componentDidMount() {
    
    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });

    this.fetchIntegrations();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
    this.unblock();
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveAction();
    });
  }

  async fetchIntegrations() {

    this.setState({
      integrationsPending: true, 
    });

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    const integrationResp = await this.props.storeFetchIntegrationProvidersPage({}, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }
        this.setState({
          integrationsPending: false, 
        });
      });

    if(!integrationResp) { return null; }

    this.setState({
      integrations: integrationResp.data,
      integrationsPending: false, 
    });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll(true)) {

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const addData = Integration.apiDataCreate(this.state.inputProvider, STORE_INTEGRATION_TYPE_SHIPPING_KEY);
      
      // Default is create via API
      const addResp = await this.props.storeAddIntegration(addData)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!addResp) { return null; }

      this.setState({ isDirty: false }, () => {
        if(!this.state.saveRedirect) {
          history.push(stringFormat(URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW, { key: this.state.inputProvider }));
        } else {
          history.push(this.state.lastBlock);
        }
      });
    }
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorProvider: getNotEmptyError(this.state.inputProvider, ERROR_INTEGRATION_PROVIDER_MISSING),
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  changeProvider(evt) {
    this.setState({
      inputProvider: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorProvider) {
        this.validateProvider();
      }
    });
  }

  validateProvider() {
    this.setState({ errorProvider: getNotEmptyError(this.state.inputProvider, ERROR_INTEGRATION_PROVIDER_MISSING) });
  }

  getIntegrationOptions(attachPlaceholder = false) {

    const respArray = [];
    try {
      if(attachPlaceholder) {
        respArray.push({
          display: tx.TX_PLACEHOLDER_INTEGRATION_PROVIDER,
          value: '',
        });  
      }

      const enabledKeys = [];
      for(const en of this.state.integrations) {
        enabledKeys.push(en.key);
      }

      for(const ing of STORE_INTEGRATIONS_ALL.map(m => new IntegrationProvider(m))) {
        if(!enabledKeys.includes(ing.key) && ing.allowedByCountryArray(this.SHIPPING_COUNTRIES)) {
          respArray.push({
            display: ing.name,
            value: ing.key,
          });
        }
      }
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  render() {

    const {t} = this.props;

    return <div className={'AddIntegration AdminPage'}>

      <AdminTitle
        title={tx.TX_ADD}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
          {
            url: URL_ADMIN_SETTINGS_INTEGRATIONS,
            title: tx.TX_SETTINGS_INTEGRATIONS,
          },
        ]} />

      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className='addPaymentMethodForm'
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_SETTINGS_ADD_INTEGRATION)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            

            {this.state.integrationsPending ?
              <div className='adminFieldWrapper'>
                <div className='paymentInputLoading'>
                  <div className='paymentInputLoadingIcon'>
                    <LoadingIcon />
                  </div>
                  <div className='paymentInputLoadingLabel'>{t(tx.TX_LOADING)}</div>
                </div>
              </div> :
              <>
                {this.getIntegrationOptions().length === 0 ?
                  <div className='noMethodNotice'>{t(tx.TX_SETTINGS_INTEGRATIONS_NO_MORE)}</div> :
                  <div className='adminFieldWrapper'>
                    <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INTEGRATION)}</div>
                    <div className='adminInputWrapper'>
                      <div className='adminDropdownWrapper'>
                        <Dropdown 
                          className={'adminDropdownSelect'}
                          options={this.getIntegrationOptions(true)}
                          name={t(tx.TX_CHECKOUT_PAYMENT_METHOD)}
                          value={this.state.inputProvider}
                          required={true}
                          onChange={this.changeProvider.bind(this)} />
                      </div>
                      {this.state.errorProvider ?
                        <div className={'adminError FieldError'}>{t(this.state.errorProvider)}</div> :
                        null
                      }
                      <div className={'FieldNotice fieldSupplement'}>
                        <span className='addPaymentMethodNote'>{t(tx.TX_SETTINGS_INTEGRATION_ADD_COPY)}</span>
                      </div>
                    </div>
                  </div>
                }
              </>
            }

            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_SETTINGS_INTEGRATIONS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.validateAll() === false || this.state.requestPending}>
                {t(tx.TX_NEXT)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddIntegration));