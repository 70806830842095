

import {
	TX_ACTIONS,
	TX_ADMIN_TABLE_VIEW_LIST,
} from '../constants/strings';
import * as tb from '../constants/tables';

import { AdminFilterElement } from '../models/admin-table';

import { stringFormat } from './formatting';
import { dynamicSort } from './sort';



export function orderSchema(schemaArray) {
	return schemaArray.sort((a, b) => (a[tb.TR_SEQUENCE] > b[tb.TR_SEQUENCE]) ? 1 : -1)
}

export function getFlexMinWidth(schemaArray) {
	for(let col of schemaArray) {
		if(col[tb.TR_WIDTH] === tb.TR_VAL_WIDTH_FLEX) {
			return col[tb.TR_MIN_WIDTH];
		}
	}
}

export function getFixedWidthByPriority(schemaArray, priority, windowWidth) {
	
	let totalWidth = 0;

	for(let col of schemaArray) {
		if(col[tb.TR_WIDTH] !== tb.TR_VAL_WIDTH_FLEX && col[tb.TR_WIDTH] > 0 && col[tb.TR_PRIORITY] <= priority) {
			totalWidth += getElementWidth(col, windowWidth);
		}
	}

	return totalWidth;
}

export function getCurrentTablePriority(schemaArray, tableParentWidth, windowWidth) {

	// Correction below accounts for border on header/body element; if removed or changed, update math for table width
	const tableWidth = tableParentWidth - 2;

	const elementMinWidth = getFlexMinWidth(schemaArray);

	// Have to use .length since Babel doesn't maintain strict array order through transpilation
	for(let i = 0; i < tb.TR_VAL_PRIORITIES.length; i++) {

		let fixedAllocation = getFixedWidthByPriority(schemaArray, tb.TR_VAL_PRIORITIES[i], windowWidth);
		if(tableWidth >= (fixedAllocation + elementMinWidth)) {
			return tb.TR_VAL_PRIORITIES[i];
		}
	}

	return tb.TR_VAL_PRIORITY_0;
}

export function totalLeftFixedVisible(schemaArray, tablePriority, windowWidth) {
	
	let fixedLeft = 0;

	for(let col of schemaArray) {
		if(col[tb.TR_ALIGN] === tb.TR_VAL_ALIGN_LEFT && col[tb.TR_WIDTH] > 0 && shouldDisplayElement(col, tablePriority, windowWidth)) {
			fixedLeft += getElementWidth(col, windowWidth);
		}
	}
	return fixedLeft;
}

export function totalRightFixedVisible(schemaArray, tablePriority, windowWidth) {
		
	let fixedRight = 0;

	for(let col of schemaArray) {
		if(col[tb.TR_ALIGN] === tb.TR_VAL_ALIGN_RIGHT && col[tb.TR_WIDTH] > 0 && shouldDisplayElement(col, tablePriority, windowWidth)) {
			fixedRight += getElementWidth(col, windowWidth);
		}
	}
	return fixedRight;
}

export function getVisibleOffset(elem, schemaArray, tablePriority, windowWidth) {

	let visibleOffset = 0;

	for(let col of schemaArray) {
		if(elem[tb.TR_ALIGN] === col[tb.TR_ALIGN]) {
			if(elem[tb.TR_ALIGN] === tb.TR_VAL_ALIGN_LEFT && elem[tb.TR_SEQUENCE] > col[tb.TR_SEQUENCE] && shouldDisplayElement(col, tablePriority, windowWidth)) {
				visibleOffset += getElementWidth(col, windowWidth);
			} else if(elem[tb.TR_ALIGN] === tb.TR_VAL_ALIGN_RIGHT && elem[tb.TR_SEQUENCE] < col[tb.TR_SEQUENCE] && shouldDisplayElement(col, tablePriority, windowWidth)) {
				visibleOffset += getElementWidth(col, windowWidth);
			}
		}
	}
	return visibleOffset;
}

export function getElementWidth(schemaElem, windowWidth) {
	if(!windowWidth || windowWidth > tb.TABLE_VALUE_MOBILE_BREAKPOINT) {
		return schemaElem[tb.TR_WIDTH];
	}

	if(schemaElem[tb.TR_WIDTH_MOBILE]) {
		return schemaElem[tb.TR_WIDTH_MOBILE];
	} else {
		return tb.TABLE_VALUE_MOBILE_SCALE * schemaElem[tb.TR_WIDTH];
	}
}

export function getElementPositionStyle(schemaElem, schemaArray, tablePriority, tableWidth, windowWidth) {

	let elemStyle = {};

  // Set element width (in px) if numeric
  if(schemaElem[tb.TR_WIDTH] > 0) {
    elemStyle['width'] = getElementWidth(schemaElem, windowWidth) + 'px';
  }

  if(schemaElem[tb.TR_WIDTH] === tb.TR_VAL_WIDTH_FLEX) {
    elemStyle['left'] = totalLeftFixedVisible(schemaArray, tablePriority, windowWidth).toString() + 'px';
    elemStyle['right'] = totalRightFixedVisible(schemaArray, tablePriority, windowWidth).toString() + 'px';
  } else {
    let orientation = schemaElem[tb.TR_ALIGN] === tb.TR_VAL_ALIGN_LEFT ? 'left' : (schemaElem[tb.TR_ALIGN] === tb.TR_VAL_ALIGN_RIGHT ? 'right' : null);

    if(orientation) {
      elemStyle[orientation] = getVisibleOffset(schemaElem, schemaArray, tablePriority, windowWidth);
    }
  }

  return elemStyle;
}

export function shouldDisplayElement(schemaElem, tablePriority, windowWidth) {

	// Insufficient priority
	if(getPriority(schemaElem) > tablePriority) {
		return false;
	}

	// Not a mobile filed
	if(schemaElem[tb.TR_NO_MOBILE] === true && windowWidth <= tb.TABLE_VALUE_MOBILE_BREAKPOINT) {
		return false;
	}

	return true;
}

export function getMobileAction(schemaArray, dataRow) {
	for(const col of schemaArray) {
		if(col[tb.TR_MOBILE_ACTION] === true) {
			return getActionLinkUrl(col, dataRow);
		}
	}
	return false;
}

export function getQuickSearchDataKeys(schemaArray) {

	let dataKeys = [];

	for(let col of schemaArray) {
		if(col[tb.TR_QUICK_SEARCH] === true) {
			if(col[tb.TR_MULTIVALUED_KEYS]) {
				for(const key of col[tb.TR_MULTIVALUED_KEYS]) {
					dataKeys.push(key);
				}
			} else {
				dataKeys.push(col[tb.TR_KEY]);
			}
		}
	}
	return [...new Set(dataKeys)];
}

export function getQuickFilterLocalData(dataSet, schemaArray, quickSearchValue) {
	
	let responseSet = [];

	const searchValues = quickSearchValue.trim().toLowerCase().split(' ');
	const dataKeys = getQuickSearchDataKeys(schemaArray);

	for(const row of dataSet) {
		for(const val of searchValues) {
			
			let foundValue = false;

			for(const key of dataKeys) {				
				if(row[key].toString().toLowerCase().includes(val)) {
					foundValue = true;
					break;
				}
			}

			if(foundValue) {
				responseSet.push(row);
				break;
			}
		}
	}
	return responseSet;
}

export function getPageinatedData(dataSet) {
	return dataSet;
}

export function getNonPageinatedData(dataSet, schemaArray, sortKey, sortDir, quickSearchValue) {
	const quickFilteredDataSet = getQuickFilterLocalData(dataSet, schemaArray, quickSearchValue);
	return getSortedData(quickFilteredDataSet, schemaArray, sortKey, sortDir);
}

export function getSortedData(dataSet, schemaArray, sortKey, sortDir) {

	let dataType = null;
	for(const col of schemaArray) {
		if(getElementSortKey(col) === sortKey) {
			if(getElementType(col) === tb.TR_VAL_TYPE_CURRENCY) {
				dataType = 'float';
			}
		}
	}

	return dataSet.sort(dynamicSort(sortKey, sortDir === tb.TC_HEADER_SORT_DIR_DESC, dataType));
}

export function isElementSortable(elem) {
	return elem[tb.TR_SORT_KEY] !== null;
}

export function getBinaryDisplay(binaryValue, schemaElem) {
	return binaryValue ? schemaElem[tb.TR_BINARY_TRUE] : schemaElem[tb.TR_BINARY_FALSE];
}

export function shouldUseBinaryIcon(binaryValue, schemaElem) {
	if(schemaElem[tb.TR_BINARY_TRUE_ICON] && binaryValue === true) {
		return true;
	}
	if(schemaElem[tb.TR_BINARY_FALSE_ICON] && binaryValue === false) {
		return true;
	}
	return false;
}

export function getElementKeyArray(schemaElem) {
	return schemaElem[tb.TR_KEY].split(tb.TABLE_VALUE_OBJECT_DELIMITER);
}

export function getElementType(schemaElem) {
	return schemaElem[tb.TR_TYPE];
}

export function getElementSortKey(elem) {
	return elem[tb.TR_SORT_KEY] ? elem[tb.TR_SORT_KEY] : null;
}

export function getElementTranslate(elem) {
	return elem[tb.TR_TRANSLATE] ? elem[tb.TR_TRANSLATE] : false;
}

export function getDefaultStyle(elem) {
	return elem[tb.TR_DEFAULT_STYLE] ? elem[tb.TR_DEFAULT_STYLE] : {};
}

export function getDefaultTranslate(elem) {
	return elem[tb.TR_DEFAULT_TRANSLATE] ? elem[tb.TR_DEFAULT_TRANSLATE] : false;
}

export function getDefaultValue(elem) {
	return elem[tb.TR_DEFAULT_VALUE] ? elem[tb.TR_DEFAULT_VALUE] : false;
}

export function getDefaultSortKey(configObj, schemaArray) {
	return configObj[tb.TC_HEADER_CONFIGS][tb.TC_DEFAULT_SORT_KEY];
}

export function getDefaultSortDir(configObj, schemaArray) {
	return configObj[tb.TC_HEADER_CONFIGS][tb.TC_DEFAULT_SORT_DIR];
}

export function isCheckboxElement(schemaElem) {
	return getElementType(schemaElem) === tb.TR_VAL_TYPE_CHECKBOX;
}

export function getActionType(schemaElem) {
	return schemaElem[tb.TR_ACTION_TYPE];
}

export function getCalcType(schemaElem) {
	return schemaElem[tb.TR_CALC_TYPE] ? schemaElem[tb.TR_CALC_TYPE] : '';
}

export function getActionLabel(schemaElem) {
	return schemaElem[tb.TR_ACTION_LABEL];
}

export function getPriority(schemaElem) {
	return schemaElem[tb.TR_PRIORITY];
}

export function isActionIcon(schemaElem) {
	return schemaElem[tb.TR_ACTION_LABEL_TYPE] === tb.TR_VAL_ACTION_LABEL_TYPE_ICON;
}

export function isMultivaluedElement(schemaElem) {
	return schemaElem[tb.TR_MULTIVALUED_KEYS] && schemaElem[tb.TR_MULTIVALUED_KEYS].length;
}

export function tableGetSingleValue(dataRow, keyArray) {
	try {
		if(keyArray.length === 1) {
			return dataRow[keyArray[0]];
		} else {
			let lastValue = dataRow;
			for(let i = 0; i < keyArray.length; i++) {
				if(!lastValue) {
					return null;
				}
				lastValue = lastValue[keyArray[i]];
			}
			return lastValue;
		}
	} catch(err) {
		console.error(err);
		return null;
	}
}

export function getValueArray(schemaElem, dataRow) {
	if(isMultivaluedElement(schemaElem) === false) {
		return [];
	}

	let valueArray = [];
	for(const key of schemaElem[tb.TR_MULTIVALUED_KEYS]) {
		const keyArray = key.split(tb.TABLE_VALUE_OBJECT_DELIMITER);
		valueArray.push(tableGetSingleValue(dataRow, keyArray));
	}
	return valueArray;
}

export function getDataValue(schemaElem, dataRow, t = false) {
	const keyArray = getElementKeyArray(schemaElem);
	if(t && schemaElem[tb.TR_TRANSLATE_FUNCTION_KEY]) {
		return dataRow[schemaElem[tb.TR_TRANSLATE_FUNCTION_KEY]](t);
	}
	return tableGetSingleValue(dataRow, keyArray);
}

export function controlsIsFilterEnabled(configObj) {
	return configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_ENABLE_FILTER];
}

export function controlsIsAddEnabled(configObj) {
	return configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_ENABLE_ADD];
}

export function controlsIsQuickSearchEnabled(configObj) {
	return configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_ENABLE_QUICK_SEARCH];
}

export function getTableNoun(configObj) {
	return configObj[tb.TC_NOUN];
}

export function getTableDetailViewSelectableWidth(configObj) {
	if(configObj[tb.TC_VIEWS_CONFIGS] && configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS]) {
		for(const opt of configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS]) {
			if(opt[tb.TC_VIEWS_OPTION_KEY] === tb.TC_VIEW_KEY_DETAIL) {
				return opt[tb.TC_VIEWS_OPTION_SELECTABLE_WIDTH] || 0;
			}
		}
	}
	return 0;
}

export function isTableDetailViewSelectable(configObj) {
	if(configObj[tb.TC_VIEWS_CONFIGS] && configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS]) {
		for(const opt of configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS]) {
			if(opt[tb.TC_VIEWS_OPTION_KEY] === tb.TC_VIEW_KEY_DETAIL) {
				return opt[tb.TC_VIEWS_OPTION_SELECTABLE] || false;
			}
		}
	}
	return false;
}

export function isTablePaginated(configObj) {
	return configObj[tb.TC_PAGINATE];
}

export function getDefaultPageSize(configObj) {
	return configObj[tb.TC_PAGINATION_CONFIGS] && configObj[tb.TC_PAGINATION_CONFIGS][tb.TC_DEFAULT_PAGE_SIZE] ? configObj[tb.TC_PAGINATION_CONFIGS][tb.TC_DEFAULT_PAGE_SIZE] : 100;
}

export function getPageSizeOptions() {
	return tb.TP_PAGE_SIZE_OPTIONS;
}

export function getActionLinkUrl(schemaElem, dataRow) {
	if(schemaElem[tb.TR_ACTION_LINK_PARAMS].length > 0) {

		let urlParams = {};
		for(const key of schemaElem[tb.TR_ACTION_LINK_PARAMS]) {
			if(key.includes('||')) {
				const splits = key.split('||');
				urlParams[splits[0]] = encodeURIComponent(dataRow[splits[0]][splits[1]]);
			} else {
				urlParams[key] = encodeURIComponent(dataRow[key]);
			}
		}

		return stringFormat(schemaElem[tb.TR_ACTION_LINK], urlParams);
	} else {
		return schemaElem[tb.TR_ACTION_LINK];
	}
}

export function getTableAddLink(configObj) {
	return (configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_ENABLE_ADD] === true && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_ADD_LINK]) ? configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_ADD_LINK] : '';
}

export function getElementCustomAttr(schemaElem, dataRow) {
	
	const customAttr = {};

	if(!schemaElem[tb.TR_CUSTOM_ATTR]) {
		return customAttr;
	}

	for(const cAttr of schemaElem[tb.TR_CUSTOM_ATTR]) {
		const attrValue = cAttr[tb.TR_CUSTOM_ATTR_VALUE_KEY] ? dataRow[cAttr[tb.TR_CUSTOM_ATTR_VALUE_KEY]] : cAttr[tb.TR_CUSTOM_ATTR_VALUE];
		
		if(attrValue) {
			customAttr[cAttr[tb.TR_CUSTOM_ATTR_KEY]]= attrValue;
		}
	}
	return customAttr;
}

export function hasMultipleViews(configObj) {
	try {
		if(configObj[tb.TC_VIEWS_CONFIGS] && configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS] && configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS].length > 0) {
			return true;
		}
	} catch(err) {
		console.error(err);
	}
	return false;
}

export function getDefaultViewKey(configObj) {
	try {
		if(configObj[tb.TC_VIEWS_CONFIGS] && configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_DEFAULT]) {
			return configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_DEFAULT];
		}
	} catch(err) {
		console.error(err);
	}
	return tb.TC_VIEW_KEY_LIST;
}

export function hasBulkActions(configObj) {
	if(configObj[tb.TC_CONTROLS_CONFIGS] && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_BULK_ACTIONS_CONFIG]) {
		return true;
	}
	return false;
}

export function getBulkActionsOptions(configObj) {
	if(configObj[tb.TC_CONTROLS_CONFIGS] && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_BULK_ACTIONS_CONFIG]) {
		const dropdownOptions = [
			{
				display: TX_ACTIONS,
				value: '',
			}
		];

		for(const ba of configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_BULK_ACTIONS_CONFIG]) {
			dropdownOptions.push({
				display: ba.display,
				value: ba.key,
			});
		}

		return dropdownOptions;
	}
	return [];
}

export function getBulkActionsObject(baKey, configObj) {
	if(configObj[tb.TC_CONTROLS_CONFIGS] && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_BULK_ACTIONS_CONFIG]) {
		for(const ba of configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_BULK_ACTIONS_CONFIG]) {
			if(baKey === ba.key) {
				return ba;
			}
		}
	}
	return null;
}

export function getAlternateTableViewOptions(configObj) {
	try {
		if(configObj[tb.TC_VIEWS_CONFIGS] && configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS]) {
			return configObj[tb.TC_VIEWS_CONFIGS][tb.TC_VIEWS_OPTIONS];
		}
	} catch(err) {
		console.error(err);
	}
	return [];
}

export function getAlternateTableViewOptionComponent(optionObj) {
	if(!optionObj) { return null; }
	try {
		return optionObj[tb.TC_VIEWS_OPTION_COMPONENT] || null;
	} catch(err) {
		console.error(err);
	}
	return null;
}

export function getAlternateTableViewOptionIcon(optionObj) {
	if(!optionObj) { return ''; }
	try {
		return optionObj[tb.TC_VIEWS_OPTION_ICON] || '';
	} catch(err) {
		console.error(err);
	}
	return '';
}

export function getAlternateTableViewOptionKey(optionObj) {
	if(!optionObj) { return ''; }
	try {
		return optionObj[tb.TC_VIEWS_OPTION_KEY] || '';
	} catch(err) {
		console.error(err);
	}
	return '';
}

export function getAlternateTableViewOptionName(optionObj) {
	if(!optionObj) { return TX_ADMIN_TABLE_VIEW_LIST; }
	try {
		return optionObj[tb.TC_VIEWS_OPTION_NAME] || TX_ADMIN_TABLE_VIEW_LIST;
	} catch(err) {
		console.error(err);
	}
	return TX_ADMIN_TABLE_VIEW_LIST;
}

export function getAlternateTableViewOptionSort(optionObj) {
	if(!optionObj) { return null; }
	try {
		return optionObj[tb.TC_VIEWS_OPTION_SORT] || null;
	} catch(err) {
		console.error(err);
	}
	return null;
}

export function getAlternateTableViewOptionSortOptions(optionObj, schemaArray) {
	const sortOptions = [];
	try {
		const customSortOptions = getAlternateTableViewOptionSort(optionObj) || [];
		for(const customOption of customSortOptions) {
			sortOptions.push(customOption);
		}

		for(const schemaObj of schemaArray) {
			if(schemaObj[tb.TR_SORT_KEY]) {
				sortOptions.push({
					display: schemaObj[tb.TR_SORT_NAME] || schemaObj[tb.TR_LABEL],
					value: schemaObj[tb.TR_SORT_KEY],
				});
			}
		}
	} catch(err) {
		console.error(err);
		return [];
	}
	return sortOptions;
}

export function getTableViewByKey(configObj, viewKey) {
	const views = getAlternateTableViewOptions(configObj);
	for(const viewOption of views) {
		if(viewKey === getAlternateTableViewOptionKey(viewOption)) {
			return viewOption;
		}
	}
	return null;
}

export function getTableFiltersDefault(configObj) {
	const modelArray = [];
	try {
		if(configObj[tb.TC_CONTROLS_CONFIGS] && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_DEFAULT]) {			
			for(const elem of configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_DEFAULT]) {
				modelArray.push(new AdminFilterElement(elem));
			}
		}
	} catch(err) {
		console.error(err);
	}
	return modelArray;
}

export function getTableFiltersExtended(configObj, activeFilters) {
	const modelArray = [];
	try {
		if(configObj[tb.TC_CONTROLS_CONFIGS] && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED]) {
			const activeKeys = Object.keys(activeFilters);
			for(const filterKey in configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED]) {
				if(activeKeys.includes(filterKey) && Object.keys(configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED][filterKey]).includes(activeFilters[filterKey])) {
					for(const elem of configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED][filterKey][activeFilters[filterKey]]) {
						modelArray.push(new AdminFilterElement(elem));
					}
				}
			}
		}
	} catch(err) {
		console.error(err);
	}
	return modelArray;
}

export function getTableFilterKeysExtendedByValue(configObj, testKey, testValue) {
	const extKeys = [];
	try {
		if(configObj[tb.TC_CONTROLS_CONFIGS] && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED]) {
			for(const filterKey in configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED]) {
				if(testKey === filterKey && Object.keys(configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED][filterKey]).includes(testValue)) {
					for(const elem of configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_EXTENDED][filterKey][testValue]) {
						extKeys.push(elem.key);
					}
				}
			}
		}
	} catch(err) {
		console.error(err);
	}
	return extKeys;
}

export function getTableFilterStorageKey(configObj) {
	return configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_FILTERS_KEY] || null;
}

export function isRowSelected(schemaElem, needleValue, selectedArray) {
	
	try {
		const needleKey = getElementKeyArray(schemaElem)[getElementKeyArray(schemaElem).length - 1];

		// Only works for top-level attributes, not nested, like productLine -> name
		// This will probably always be sufficient, but if not, this will have to be updated
		for(const val of selectedArray) {
			if(val[needleKey] === needleValue) {
				return true;
			}
		}
	} catch(err) {
		console.error(err);
	}
	return false;
}

export function isRowSelectedByKey(needleValue, needleKey, selectedArray) {

	try {

		// Only works for top-level attributes, not nested, like productLine -> name
		// This will probably always be sufficient, but if not, this will have to be updated
		for(const val of selectedArray) {
			if(val[needleKey] === needleValue) {
				return true;
			}
		}
	} catch(err) {
		console.error(err);
	}
	return false;
}

export function getTableSelectionKey(configObj) {	
	return configObj[tb.TC_CONTROLS_CONFIGS] && configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_SELECTION_KEY] ? configObj[tb.TC_CONTROLS_CONFIGS][tb.TC_CONTROLS_SELECTION_KEY] : null;
}

export function getDataRowBySelectionValue(selectionValue, configObj, dataArray) {
	const selectionKey = getTableSelectionKey(configObj);

	for(const row of dataArray) {
		if(row[selectionKey] === selectionValue) {
			return row;
		}
	}
	return null;
}















