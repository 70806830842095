import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isLoggedIn } from '../../../utils/auth';

import { CSS_TABLET_BREAK } from '../../../constants/css';
import { ICON_ADD, ICON_BUY, ICON_PLAY, ICON_SELL } from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { Icon } from '../../Icons/Icon';

import HeaderSubmenuBuy from './HeaderSubmenuBuy';
import HeaderSubmenuSell from './HeaderSubmenuSell';
import HeaderSubmenuPlay from './HeaderSubmenuPlay';
import HeaderSubmenuUser from './HeaderSubmenuUser';
import HeaderFullMenuFooter from './HeaderFullMenuFooter';

import '../style/_headerfullmenu.scss';

import * as authActionCreators from '../../../actions/auth';
import * as headerActionCreators from '../../../actions/header';
let allActionCreators = Object.assign({}, authActionCreators, headerActionCreators);

export class HeaderFullMenu extends Component {

  constructor(props) {
    super(props);

    // selectedMenu: BUY, SELL, PLAY, ACCOUNT

    this.state = {
      selectedMenu: 'BUY',
      currentWindowHeight: window.innerHeight,
    };

    this.menuBodyRef = React.createRef();
    this.checkClick = this.checkClick.bind(this);
    this.checkSize = this.checkSize.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
    window.addEventListener('resize', this.checkSize, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
    window.removeEventListener('resize', this.checkSize, false);
  }

  checkSize() {
    this.setState({ currentWindowHeight: window.innerHeight });
  }

  checkClick(evt) {
    if(window.innerWidth <= CSS_TABLET_BREAK && this.props.header.headerUserMenuOpen === true) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.menuBodyRef.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.props.headerToggleUserMenu();
    }  
  }

  isSelected(val) {
    return this.state.selectedMenu === val;
  }

  selectMenu(val) {
    this.setState({ selectedMenu: val });
  }

  getSelectedSubmenu() {
    switch(this.state.selectedMenu) {
      case 'BUY':
        return (<HeaderSubmenuBuy
                  toggleFullMenu={true} />);
      case 'SELL':
        return (<HeaderSubmenuSell
                  toggleFullMenu={true} />);
      case 'PLAY':
        return (<HeaderSubmenuPlay
                  toggleFullMenu={true} />);
      case 'MORE':
        return (<HeaderSubmenuUser />);
      default:
        return null;
    }
  }

  getMenuHeight() {
    return this.props.header.headerUserMenuOpen ? (this.state.currentWindowHeight.toString() + 'px') : '0';
  }

  render() {

    const {t} = this.props;

    return <div className={`HeaderFullMenu ${this.props.notified ? 'notified' : ''}`}>
      <div 
        className={'hfmOuterWrapper'}
        ref={this.menuBodyRef}
        style={{
          height: this.getMenuHeight(),
        }}>
        <div className='hfmInnerWrapper'>
          <div className='hfmHeader'>
            <div className='iconsWrapper'>
              <Icon 
                value={ICON_BUY}  
                iconClass={this.isSelected('BUY') ? 'iconElement current' : 'iconElement'} 
                iconLabel={t(tx.TX_BUY)} 
                ellipsisLabel={true}
                onClick={() => this.selectMenu('BUY')} />
              <Icon 
                value={ICON_SELL}  
                iconClass={this.isSelected('SELL') ? 'iconElement current' : 'iconElement'} 
                iconLabel={t(tx.TX_SELL)} 
                ellipsisLabel={true}
                onClick={() => this.selectMenu('SELL')} />
              <Icon 
                value={ICON_PLAY}  
                iconClass={this.isSelected('PLAY') ? 'iconElement current' : 'iconElement'} 
                iconLabel={t(tx.TX_PLAY)} 
                ellipsisLabel={true}
                onClick={() => this.selectMenu('PLAY')} />
              {isLoggedIn() ?
                <Icon 
                  value={ICON_ADD}  
                  iconClass={this.isSelected('MORE') ? 'iconElement current' : 'iconElement'} 
                  iconLabel={t(tx.TX_MORE)} 
                  ellipsisLabel={true}
                  onClick={() => this.selectMenu('MORE')} /> :
                null
              }
            </div>
          </div>
          <div className='hfmVariable'>
            {this.getSelectedSubmenu()}
          </div>
          <div className='hfmFooter'>
            <HeaderFullMenuFooter />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderFullMenu));


