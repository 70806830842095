import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { formatServerError, stringFormat } from '../../utils/formatting';

import * as tx from '../../constants/strings';
import {
  URL_ADMIN_INVENTORY, 
  URL_ADMIN_INVENTORY_PRODUCT_LINES, 
  URL_ADMIN_INVENTORY_PRODUCT_LINES_VIEW, 
} from '../../constants/urls';

import AdminTitle from '../Admin/AdminTitle';
import DeletePrompt from '../Popups/DeletePrompt';
import ProductLineDetailsEdit from './blocks/view/ProductLineDetailsEdit';
import ProductLineDetailsView from './blocks/view/ProductLineDetailsView';
import ProductLinePricingEdit from './blocks/view/ProductLinePricingEdit';
import ProductLinePricingView from './blocks/view/ProductLinePricingView';
import ProductLineSetsView from './blocks/view/ProductLineSetsView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_viewproductline.scss';

import * as commonActionCreators from '../../actions/common';
import * as productLineActionCreators from '../../actions/product-line';
let allActionCreators = Object.assign({}, commonActionCreators, productLineActionCreators);

export class ViewProductLine extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirtyDetails: false,
      isDirtyPricing: false,

      savePromptOpen: false,
      deletePromptOpen: false,
      lastBlock: '',

      editDetails: false,
      editPricing: false,

      pricingExpanded: true,
      setsExpanded: true,

      requestPending: true,
      requestError: null,
      responseObject: null,

      currentPermalink: null,

      savingAll: false,
      remoteSaveDetails: false,
      remoteSavePricing: false,
    };

    this.controller = null;
  }

  componentDidMount() {
    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyDetails === false && this.state.isDirtyPricing === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    // Fetch product line
    this.fetchProductLine();
  }

  componentWillUnmount() {
    this.unblock();

    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyDetails === false && this.state.isDirtyPricing === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchProductLine();
      }
    }
  }

  async fetchProductLine(service = '', updatedPermalink = false, showLoading = true) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: showLoading,
      requestError: null,
      responseObject: null,
    });

    if(updatedPermalink) {
      this.setState({
        isDirtyDetails: false,
        isDirtyPricing: false, 
      }, () => {
        history.push(stringFormat(URL_ADMIN_INVENTORY_PRODUCT_LINES_VIEW, { 
          permalink: updatedPermalink, 
        }));
      });
    }

    // Get product line details
    if(this.props.match.params.productLinePermalink) {

      const plPermalink = updatedPermalink ? updatedPermalink : this.props.match.params.productLinePermalink;
      const plResp = await this.props.productLineFetch(plPermalink, controller.signal)
        .catch((errResp) => {
          
          if(controller.signal.aborted) { return null; }

          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
            responseObject: null,
          });
        });

      if(!plResp) { return null; }

      this.setState({
        requestPending: false,
        requestError: null,
        responseObject: plResp,
        currentPermalink: plResp.permalink,
      });

      if(service === 'details') {
        this.setState({ isDirtyDetails: false });
      } else if(service === 'pricing') {
        this.setState({ isDirtyPricing: false });
      }
    }
  }

  makeDetailsDirty() {
    if(this.state.isDirtyDetails === false) {
      this.setState({ isDirtyDetails: true });
    }
  }

  makePricingDirty() {
    if(this.state.isDirtyPricing === false) {
      this.setState({ isDirtyPricing: true });
    }
  }

  toggleDetails() {
    this.setState({ editDetails: !this.state.editDetails }, () => {
      if(this.state.editDetails === false) {
        this.setState({ isDirtyDetails: false, });
      }
    });
  }

  togglePricing() {    
    this.setState({ 
      editPricing: !this.state.editPricing, 
    }, () => {
      if(this.state.editPricing === false) {
        this.setState({ isDirtyPricing: false, });
      }
    });
  }

  togglePricingExpanded() {
    this.setState({ pricingExpanded: !this.state.pricingExpanded });
  }

  toggleSetsExpanded() {
    this.setState({ setsExpanded: !this.state.setsExpanded });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }

    // If you're saving product line details, wait for it to complete before calling others since the permalink may change
    if(this.state.isDirtyDetails) {
      this.setState({
        savingAll: true, 
        remoteSaveDetails: this.state.isDirtyDetails,
      });
    } else {
      this.setState({
        savingAll: true, 
        remoteSaveDetails: this.state.isDirtyDetails,
        remoteSavePricing: this.state.isDirtyPricing,
      });
    }

    this.setState({
      savingAll: true, 
      remoteSaveDetails: this.state.isDirtyDetails,
      remoteSavePricing: this.state.isDirtyPricing,
    });
  }

  saveDisabled() {
    // return this.state.editDetails || this.state.editPricing;
  }

  finishRemote(service, didFail = false, updatedPermalink = null) {

    if(service === 'details') {
      this.setState({ 
        isDirtyDetails: didFail !== false, 
        remoteSaveDetails: false, 
      }, () => {
        // Once details completed, if pricing or anything else need save, do them then
        if(this.state.isDirtyPricing) {
          this.setState({
            currentPermalink: updatedPermalink ? updatedPermalink : this.state.currentPermalink,
            remoteSavePricing: this.state.isDirtyPricing,
          });
        } else {
          this.setState({ 
            savingAll: false, 
          });
        }
      });
    }

    if(service === 'pricing') {
      this.setState({ 
        isDirtyPricing: didFail !== false, 
        remoteSavePricing: false, 
      }, () => {
        this.setState({ 
          savingAll: this.state.remoteSaveDetails === true || this.state.remoteSavePricing === true, 
        });
      });
    }
  }

  toggleDeletePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      deletePromptOpen: !this.state.deletePromptOpen,
    });
  }

  openDeletePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.state.responseObject.isEnabled) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_INV_PL_DELETE_ALERT_TITLE, 
        alertCopy: tx.TX_INV_PL_DELETE_ALERT_COPY, 
      });
    } else {
      this.toggleDeletePrompt();
    }
  }

  deleteAction(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.productLineDelete(this.state.responseObject.id)
    .then((resp) => {

      this.props.productLinesSetAll(null);

      this.setState({ isDirty: false }, () => {
        history.push(URL_ADMIN_INVENTORY_PRODUCT_LINES);
      });
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  hasAutomatedPricing() {    
    if(this.state.responseObject && this.state.responseObject.isManaged) {
      return true;
    }
    return false;
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewProductLine AdminPage'}>
      <AdminTitle
        title={tx.TX_VIEW}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: tx.TX_CATALOG,
          },
          {
            url: URL_ADMIN_INVENTORY_PRODUCT_LINES,
            title: tx.TX_PRODUCT_LINES,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>
          {this.state.editDetails ? 
            <ProductLineDetailsEdit
              productLineObj={this.state.responseObject}
              productLinePending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)}
              makeDirty={this.makeDetailsDirty.bind(this)}
              makeClean={this.fetchProductLine.bind(this)} 
              remoteSave={this.state.remoteSaveDetails}
              finishRemote={this.finishRemote.bind(this)} /> :
            <ProductLineDetailsView
              productLineObj={this.state.responseObject}
              productLinePending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)} />
          }
          {this.hasAutomatedPricing() ?
            <>
              {this.state.editPricing ?
                <ProductLinePricingEdit
                  productLineObj={this.state.responseObject}
                  productLinePermalink={this.state.currentPermalink}
                  productPending={this.state.requestPending}
                  toggleEdit={this.togglePricing.bind(this)}
                  makeDirty={this.makePricingDirty.bind(this)}
                  makeClean={this.fetchProductLine.bind(this)} 
                  remoteSave={this.state.remoteSavePricing}
                  finishRemote={this.finishRemote.bind(this)}
                  expanded={this.state.pricingExpanded}
                  toggleExpanded={this.togglePricingExpanded.bind(this)} /> :
                <ProductLinePricingView
                  productLineObj={this.state.responseObject}
                  productLinePending={this.state.requestPending}
                  toggleEdit={this.togglePricing.bind(this)}
                  expanded={this.state.pricingExpanded}
                  toggleExpanded={this.togglePricingExpanded.bind(this)} />
              }
            </> :
            null
          }
          {false && this.state.responseObject && this.state.responseObject.hasSets() ?
            <ProductLineSetsView
              productLineObj={this.state.responseObject}
              productLinePending={this.state.requestPending}
              makeClean={this.fetchProductLine.bind(this)} 
              expanded={this.state.setsExpanded}
              toggleExpanded={this.toggleSetsExpanded.bind(this)} /> :
            null
          }
        </div>
      </div>
      <div className='adminActions'>
        <button 
          type='button'
          className={this.saveDisabled() ? 'disabled blockButton' : 'blockButton'} 
          onClick={this.openDeletePrompt.bind(this)}>
          {t(tx.TX_DELETE)}
        </button>
        <Link to={URL_ADMIN_INVENTORY_PRODUCT_LINES} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <DeletePrompt
        open={this.state.deletePromptOpen}
        copy={tx.TX_INV_PL_DELETE_COPY}
        closeMethod={this.toggleDeletePrompt.bind(this)}
        onConfirm={this.deleteAction.bind(this)}
        onCancel={this.toggleDeletePrompt.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewProductLine));