import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_MARKETING, 
  URL_ADMIN_MARKETING_COUPONS, 
  URL_ADMIN_MARKETING_COUPONS_VIEW, 
} from '../../constants/urls';

import { Coupon } from '../../models/promotions';

import { 
  formatServerError, 
  stringFormat,
} from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import Confirm from '../Popups/Confirm';
import CouponDetailsEdit from './blocks/CouponDetailsEdit';
import CouponDetailsView from './blocks/CouponDetailsView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_coupons.scss';

import * as commonActionCreators from '../../actions/common';
import * as promotionActionCreators from '../../actions/promotion';
let allActionCreators = Object.assign({}, commonActionCreators, promotionActionCreators);

export class ViewCoupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirtyDetails: false,
      savePromptOpen: false,
      lastBlock: '',

      editDetails: false,

      requestPending: true,
      requestError: null,
      responseObject: new Coupon(),

      savingAll: false,
      remoteSaveDetails: false,
    };

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyDetails === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchCoupon();
  }

  componentWillUnmount() {
    this.unblock();
    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyDetails === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchCoupon();
      }
    }
  }

  fetchCoupon(service = '', newCode = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      responseObject: new Coupon(),
    });

    // Get coupon details
    if(this.props.match.params.couponCode) {
      this.props.promoAdminFetchCoupon(newCode ? newCode : this.props.match.params.couponCode, controller.signal)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
          responseObject: resp,
        });
        if(service === 'details') {
          this.setState({ isDirtyDetails: false }, () => {
            if(newCode) {
              history.replace(stringFormat(URL_ADMIN_MARKETING_COUPONS_VIEW, { code: newCode }));
            }
          });
        }
      })
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responseObject: new Coupon(),
        });
      });
    }
  }

  makeDetailsDirty() {
    if(this.state.isDirtyDetails === false) {
      this.setState({ isDirtyDetails: true });
    }
  }

  toggleDetails() {
    this.setState({ editDetails: !this.state.editDetails }, () => {
      if(this.state.editDetails === false) {
        this.setState({ isDirtyDetails: false, });
      }
    });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      savingAll: true, 
      remoteSaveDetails: this.state.isDirtyDetails,
    });
  }

  finishRemote(service, didFail = false) {
    if(service === 'details') {
      this.setState({ 
        isDirtyDetails: didFail !== false, 
        remoteSaveDetails: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true });
      });
    }
  }

  toggleDeletePrompt() {
    if(!this.state.deletePromptOpen && this.saveDisabled()) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_MARKETING_COUPON_DELETE_ALERT_TITLE, 
        alertCopy: tx.TX_MARKETING_COUPON_DELETE_ALERT_COPY, 
      });
    } else {
      this.setState({ deletePromptOpen: !this.state.deletePromptOpen });
    }
  }

  saveDisabled() {
    return this.state.editDetails;
  }

  deleteCouponAction(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.promoAdminDeleteCoupon(this.state.responseObject.code)
    .then((resp) => {
      history.push(URL_ADMIN_MARKETING_COUPONS);
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewCoupon AdminPage'}>
      <AdminTitle
        title={tx.TX_VIEW}
        breadcrumbs={[
          {
            url: URL_ADMIN_MARKETING,
            title: tx.TX_MARKETING,
          },
          {
            url: URL_ADMIN_MARKETING_COUPONS,
            title: tx.TX_MARKETING_COUPONS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>
          {this.state.editDetails ? 
            <CouponDetailsEdit
              coupon={this.state.responseObject}
              couponCode={this.props.match.params.couponCode}
              couponPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)}
              makeDirty={this.makeDetailsDirty.bind(this)}
              makeClean={this.fetchCoupon.bind(this)} 
              remoteSave={this.state.remoteSaveDetails}
              finishRemote={this.finishRemote.bind(this)} /> :
            <CouponDetailsView
              coupon={this.state.responseObject}
              couponPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)} />
          }
        </div>
      </div>
      <div className='adminActions'>
        <button 
          type='button'
          className={this.saveDisabled() ? 'disabled blockButton' : 'blockButton'} 
          onClick={this.toggleDeletePrompt.bind(this)}>
          {t(tx.TX_DELETE)}
        </button>
        <Link to={URL_ADMIN_MARKETING_COUPONS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <Confirm
        title={tx.TX_DELETE_PROMPT_TITLE}
        copy={tx.TX_DELETE_PROMPT_COPY}
        open={this.state.deletePromptOpen}
        confirmValue={tx.TX_DELETE}
        closeMethod={this.toggleDeletePrompt.bind(this)}
        onConfirm={this.deleteCouponAction.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewCoupon));