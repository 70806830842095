import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../../store';

import { ERROR_CATCH_ALL } from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';
import { 
  STORE_INTEGRATION_STAMPS_KEY,
  STORE_INTEGRATION_TYPE_SHIPPING_KEY,
} from '../../../../constants/store';
import { URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW } from '../../../../constants/urls';

import { 
  stringFormat, 
  formatServerError, 
} from '../../../../utils/formatting';
import { urlQueryParams } from '../../../../utils/general';

import { LoadingIcon } from '../../../Icons/LoadingIcon';

import * as storeActionCreators from '../../../../actions/store';
const allActionCreators = Object.assign({}, storeActionCreators);

export class IntegrationConfigOauthStampsRedirect extends Component {

  constructor(props) {
    super(props);

    this.INTEGRATION_KEY = STORE_INTEGRATION_STAMPS_KEY;

    this.state = {

      integrationProviderObj: null,

      oauthError: '',

      requestPending: true, 
    };

    this.getParams = urlQueryParams();

    this.controllerIntegration = null;
    this.controllerAuthorize = null;

    // this.paymentMethod = PaymentMethod.getByKey(this.props.paymentMethodKey);
  }

  componentDidMount() {
    if(this.getParams) {
      if(!this.getParams.code) {
        this.setState({ oauthError: ERROR_CATCH_ALL });
        return null;
      }
    }
    this.fetchIntegration();
  }

  componentWillUnmount() {
    if(this.controllerIntegration) {
      this.controllerIntegration.abort();
    }
    if(this.controllerAuthorize) {
      this.controllerAuthorize.abort();
    }
  }

  getIntegration(typeKey) {

    if(!typeKey || !this.state.integrationProviderObj || !this.state.integrationProviderObj.integrations) { return null; }

    for(const integration of this.state.integrationProviderObj.integrations) {
      if(integration.type && integration.type.key === typeKey) {
        return integration;
      }
    }
    return null;
  }

  async fetchIntegration() {

    if(this.controllerIntegration) {
      this.controllerIntegration.abort();
    }
    const controllerIntegration = new AbortController();
    this.controllerIntegration = controllerIntegration;

    // Get provider integrations
    const fetchResp = await this.props.storeFetchProvider(this.INTEGRATION_KEY, controllerIntegration.signal)
      .catch((errResp) => {
        if(controllerIntegration.signal.aborted) { return null; }
        console.error(errResp);
        this.setState({ 
          integrationProviderObj: null,
          requestPending: false,
          oauthError: formatServerError(errResp),
        });
      });

    if(!fetchResp) { return null; }

    this.setState({
      integrationProviderObj: fetchResp,
    }, () => {
      this.authorizeStamps();
    });
  }

  async authorizeStamps() {

    const shippingIntegration = this.getIntegration(STORE_INTEGRATION_TYPE_SHIPPING_KEY);
    if(!shippingIntegration) {
      return null;
    }

    if(this.controllerAuthorize) {
      this.controllerAuthorize.abort();
    }
    const controllerAuthorize = new AbortController();
    this.controllerAuthorize = controllerAuthorize;

    // Authorize with Stamps
    const authorizeData = {
      code: this.getParams.code,
      redirect_uri: shippingIntegration.oauthRedirectUri,
    }
    await this.props.storeIntegrationOauthAuthorize(authorizeData, shippingIntegration.publicUuid, controllerAuthorize.signal)
      .catch((errResp) => {
        if(controllerAuthorize.signal.aborted) { return null; }
        console.error(errResp);
        this.setState({ 
          integrationProviderObj: null,
          requestPending: false,
          oauthError: formatServerError(errResp),
        });
      });

    history.replace(stringFormat(URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW, { key: this.INTEGRATION_KEY }));
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationConfigOauthStampsRedirect'}>
      <div className='loadingBlockWrapper'>
        {this.state.oauthError ?
          <div className='errorBlock'>
            <div className='errorLabel'>{t(tx.TX_ERROR)}</div>
            <div className='errorValue'>{t(this.state.oauthError)}</div>
            <div className='errorAction'>
              <Link to={stringFormat(URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW, { key: this.INTEGRATION_KEY })} className='backButton'>{t(tx.TX_BACK)}</Link>
            </div>
          </div> :
          <div className='loadingBlock'>
            <div className='iconWrapper'>
              <LoadingIcon />
            </div>
            <div className='labelBlock'>{t(tx.TX_SETTINGS_INTEGRATION_STAMPS_OAUTH_CONNECTING)}</div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationConfigOauthStampsRedirect));