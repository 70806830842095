import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_PICKUP } from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  serverDateShort,
  serverTimeFull,
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import { Icon } from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';
import MiniCart from '../../Cart/MiniCart';

import * as commonActionCreators from '../../../actions/common';
let allActionCreators = Object.assign({}, commonActionCreators);

export class OrderPickupsView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: 0,
    };
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  selectShipment(idx) {
    if(idx !== this.state.selectedRow) {
      this.setState({
        selectedRow: idx,
      });
    }
  }

  getCarrierName(shipment) {
    return shipment.carrier.name;
  }

  tryToOpenCloseModal(pickup) {
    if(this.props.order && this.props.order.totalPayable && this.props.order.totalPayable > 0) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_ORDER_PICKUPS_NOT_PAID_TITLE, 
        alertCopy: tx.TX_ORDER_PICKUPS_NOT_PAID_COPY, 
      });
    } else {
      this.props.openCloseModal(pickup);
    }
  }

  render() {

    const {t} = this.props;
    const itemCount = this.props.order && this.props.order.cart ? this.props.order.cart.quantity : 0;

    return <div className={'OrderPickupsView adminSection'}>
    	<div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_PICKUPS)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='osvLiner'>
              {!this.props.order || !this.props.order.pickups || this.props.order.pickups.length === 0 ?
                <div className='noShipmentsWrapper'>
                  <div className='shippingIconWrapper'>
                    <Icon 
                      value={ICON_PICKUP}  
                      iconClass={'shippingIcon'} />
                  </div>
                  <div className='noShipmentsCopy'>{t(this.props.order && this.props.order.pickupReadyToBeCreated ? tx.TX_ORDER_NO_PICKUPS : tx.TX_ORDER_NO_PICKUPS_REQUIRED)}</div>
                  {this.props.order && this.props.order.pickupReadyToBeCreated ?
                    <button 
                      type='button'
                      className={'noShipmentsAction adminButton'}
                      onClick={this.props.openEditModal.bind(this)}>
                      {t(tx.TX_ORDER_READY_FOR_PICKUP)}
                    </button> :
                    null
                  }
                </div> :
                <div className='shipmentListWrapper'>
                  <div className='shipmentList'>
                    <div className={'slHeader'}>
                      <div className={'slHeaderCell dateCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_DATE)}</div>
                      </div>
                      <div className={'slHeaderCell noteCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_NOTES)}</div>
                      </div>
                      <div className={'slHeaderCell actionCell'}>
                        <div className={'slHeaderValue slItemValue'}></div>
                      </div>

                    </div>
                    {this.props.order.pickups.map((pickup, i) => {
                      return <div 
                              key={i}
                              className={`slItem ${this.state.selectedRow === i ? 'slSelected' : ''} ${pickup.completeDate ? 'complete': ''}`}
                              onClick={() => this.selectShipment(i)}>
                        <div className={'slItemCell dateCell'}>
                          <div className='slNonAbsValue'>
                            <div className='dateDate'>{serverDateShort(pickup.initDate, this.getLanguage())}</div>
                            <div className='dateTime'>{serverTimeFull(pickup.initDate, this.getLanguage())}</div>
                          </div>
                        </div>
                        <div className={'slItemCell noteCell'}>
                          <div className='slNonAbsValue'>
                            <div className='noteBlock'>
                              <div className='noteLabel'>{t(tx.TX_ORDER_PICKUPS_CUSTOMER_NOTE)}</div>
                              <div className='noteValue'>{pickup.initNote || t(tx.TX_NONE)}</div>
                            </div>
                            {pickup.completeDate ?
                              <div className='noteBlock'>
                                <div className='noteLabel'>{t(tx.TX_ORDER_PICKUPS_PICKUP_NOTE)}</div>
                                <div className='noteValue'>{pickup.completeNote || t(tx.TX_NONE)}</div>
                              </div> :
                              null
                            }
                          </div>
                        </div>
                        <div className={'slItemCell completeNoteCell'}>
                          <div className='slNonAbsValue'></div>
                        </div>
                        <div className={'slItemCell actionCell'}>
                          <div className='slNonAbsValue'>
                            <button
                              type='button'
                              className='rowButton'
                              onClick={() => this.tryToOpenCloseModal(pickup)}>
                              {t(tx.TX_COMPLETE)}
                            </button>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                  <div className='shipmentInfo'>
                    <div className='infoSectionTitle'>
                      <div className='infoSectionTitleValue'>{t(tx.TX_PICKUP)}</div>
                      <div className='infoSectionTitleItems'>({itemCount === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: itemCount })})</div>
                    </div>
                    <div className='shipmentItemsWrapper'>
                      <MiniCart
                        order={this.props.order}
                        cartOverride={this.props.order ? this.props.order.cart : null}
                        readOnly={true} />
                    </div>
                  </div>
                </div>
              }
            </div>
            {this.props.order && this.props.order.pickupReadyToBeCreated ?
              <div className='adminViewButtonWrapper'>
                <button 
                  type='button'
                  className={'productDetailsButton adminButton'} 
                  onClick={() => this.props.openEditModal()}
                  disabled={this.props.order && this.props.order.pickupReadyToBeCreated === false}>
                  {t(tx.TX_ORDER_CREATE_PICKUP)}
                </button>
              </div> :
              null
            }
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.orderPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderPickupsView));