import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_ORDERS, 
  TABLE_SCHEMA_ORDERS, 
  TC_PAGINATION_CONFIGS, 
  TC_DEFAULT_PAGE_SIZE, 
} from '../../constants/tables';
import { 
  URL_ADMIN_ORDERS, 
  URL_ADMIN_ORDERS_PURCHASE,
} from '../../constants/urls';

import { 
  getPageLimit, 
  getPageOffset,
} from '../../utils/request';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import * as orderActionCreators from '../../actions/order';
let allActionCreators = Object.assign({}, orderActionCreators);

export class OrdersPurchase extends Component {

  constructor(props) {
    super(props);

    this.state = {
      orderData: [],
      orderPending: true,
      orderCount: 0,

      orderPage: 1,
    };

    this.staticFilters = {
      is_buylist: 'False',
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getOrderPage(this.state.orderPage);
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getOrderPage(pageNum = 1, pageSize = TABLE_CONFIG_ORDERS[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      orderData: [],
      orderPending: true,
      orderCount: 0,
    });

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams, this.staticFilters);
    this.props.ordersAdminFetchPage(getParams, controller.signal)
    .then((resp) => {
      this.setState({
        orderData: resp.data,
        orderPending: false,
        orderCount: resp.count,
      });
    })
    .catch((errResp) => {
      if(controller.signal.aborted) {
        // Request aborted; do nothing
      } else if(errResp) {
        console.error(errResp);
        this.setState({
          orderData: [],
          orderPending: false,
          orderCount: 0,
        });
      }
    });
  }

  render() {
    return <div className={'OrdersPurchase AdminPage'}>
      <AdminTitle
        title={tx.TX_SALES}
        breadcrumbs={[
          {
            url: URL_ADMIN_ORDERS,
            title: tx.TX_ORDERS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={TABLE_CONFIG_ORDERS}
          schema={TABLE_SCHEMA_ORDERS}
          data={this.state.orderData}
          dataCount={this.state.orderCount} 
          dataLoading={this.state.orderPending}
          callApi={this.getOrderPage.bind(this)}
          linkFrom={URL_ADMIN_ORDERS_PURCHASE}
          staticFilters={this.staticFilters} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrdersPurchase));