

import {
	TX_MAGIC_COLORS_BLACK,
	TX_MAGIC_COLORS_BLUE,
	TX_MAGIC_COLORS_COLORLESS,
	TX_MAGIC_COLORS_GREEN,
	TX_MAGIC_COLORS_RED,
	TX_MAGIC_COLORS_WHITE,
	TX_MAGIC_RARITY_COMMON,
	TX_MAGIC_RARITY_MYTHIC,
	TX_MAGIC_RARITY_RARE,
	TX_MAGIC_RARITY_SPECIAL,
	TX_MAGIC_RARITY_UNCOMMON,
} from '../strings';


export const MAGIC_LAYOUT_DOUBLE_SIDED = [
	'art_series', 
	'double_sided', 
	'double_faced_token', 
	'meld', 
	'modal_dfc', 
	'transform', 
];

export const MAGIC_CARD_SKU_PREFIX = 'MTG';
export const MAGIC_SEALED_SKU_PREFIX = 'MTGS';

export const MAGIC_COLORS = [
	{
		key: 'W',
		name: TX_MAGIC_COLORS_WHITE, 
	},
	{
		key: 'U',
		name: TX_MAGIC_COLORS_BLUE, 
	},
	{
		key: 'B',
		name: TX_MAGIC_COLORS_BLACK, 
	},
	{
		key: 'R',
		name: TX_MAGIC_COLORS_RED, 
	},
	{
		key: 'G',
		name: TX_MAGIC_COLORS_GREEN, 
	},
	{
		key: 'colorless',
		name: TX_MAGIC_COLORS_COLORLESS, 
	},
];

export const MAGIC_RARITIES = [
	{
		key: 'mythic',
		name: TX_MAGIC_RARITY_MYTHIC, 
	},
	{
		key: 'rare',
		name: TX_MAGIC_RARITY_RARE, 
	},
	{
		key: 'uncommon',
		name: TX_MAGIC_RARITY_UNCOMMON, 
	},
	{
		key: 'common',
		name: TX_MAGIC_RARITY_COMMON, 
	},
	{
		key: 'special',
		name: TX_MAGIC_RARITY_SPECIAL, 
	},
];










