import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { formatPrice } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import LoadingIcon from '../../Icons/LoadingIcon';
import MiniCart from '../../Cart/MiniCart';

let allActionCreators = Object.assign({});

export class CartItemsView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  render() {

    const {t} = this.props;

    return <div className={'CartItemsView adminSection'}>
    	<div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_CART_CART_ITEMS)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='itemsMinicartWrapper'>
            <MiniCart
              isBuylist={this.props.cart.isBuylist}
              order={null}
              cartOverride={this.props.cart}
              readOnly={true} />
          </div>
          <div className='itemsSubtotalWrapper'>
            <div className='itemsSubtotalLiner'>
              <div 
                className='itemsSubtotalValue'
                dangerouslySetInnerHTML={{ __html: formatPrice(this.props.cart.subTotal, { addTags: true, language: this.getLanguage() }) }} />
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.orderPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CartItemsView));