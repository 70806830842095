import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as tx from '../../constants/strings';
import { URL_LOGIN } from '../../constants/urls';

import { isFormValid, getEmailError } from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import './style/_auth.scss';

import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, userActionCreators);

export class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: props.location && props.location.state && props.location.state.emailVal ? props.location.state.emailVal : '',
      emailError: '',

      forgotReqError: null,
      forgotReqPending: false,
      forgotSuccess: false,
    };
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const forgot_data = {
        email: this.state.email,
        store_id: process.env.REACT_APP_STORE_UUID,
      };

      this.setState({ forgotReqPending: true, forgotReqError: null });

      this.props.usersForgotPassword(forgot_data)
      .then((resp) => {

        this.setState({
          forgotReqPending: false,
          forgotSuccess: true,
        })
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          forgotReqPending: false,
          forgotReqError: formatServerError(errResp),
        });
      });
    }
  }

  validateAll() {
    const errorObj = {
      emailError: getEmailError(this.state.email),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeEmail(evt) {
    this.setState({ email: evt.target.value }, () => {
      if(this.state.emailError) {
        this.validateEmail(this.state.email);
      }
    });
  }

  validateEmail() {
    this.setState({ emailError: getEmailError(this.state.email) });
  }

  backNav() {
    history.goBack();
  }

  render() {

    const {t} = this.props;

    return <div className={'ForgotPassword authPage'}>
      <div className='authPageTitle'>{t(tx.TX_FORGOT_PASSWORD)}</div>
      <div className='authPageBody'>
        <div className='loginWrapper'>
          {this.state.forgotSuccess ?
            <div className={'forgotSuccess loginForm'}>
              <div className='forgotSuccessTitle'>{t(tx.TX_AUTH_RESET_SENT)}</div>
              <div className='forgotSuccessText' dangerouslySetInnerHTML={
                {__html: t(tx.TX_AUTH_FORGOT_COPY_1, {
                  email: this.state.email, 
                  interpolation: {escapeValue: false}
                })}
              } />
              <div className='forgotSuccessText'>{t(tx.TX_AUTH_FORGOT_COPY_2)}</div>
              <div className={'loginFieldWrapper hrWrapper'}>
                <div className='hrBody'></div>
              </div>
              <div className={'loginFieldWrapper toggleButtonWrapper'}>
                <Link to={URL_LOGIN}>
                  <button className='toggleButton'>{t(tx.TX_BACK)}</button>
                </Link>
              </div>
            </div> :
            <form className='loginForm' onSubmit={this.handleSubmit.bind(this)}>
              <div className={this.state.forgotReqError ? 'loginFormError present' : 'loginFormError'}>{t(this.state.forgotReqError)}</div>
              <div className={'loginFieldWrapper inputFieldWrapper'}>
                <div className='loginFieldLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
                <input
                  type='email'
                  className={this.state.emailError ? 'InputError' : ''}
                  value={this.state.email}
                  onChange={this.changeEmail.bind(this)}
                  onBlur={this.validateEmail.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EMAIL)} />
                {this.state.emailError ?
                  <div className={'loginError FieldError'}>{t(this.state.emailError)}</div> :
                  null
                }
              </div>
              <div className='loginFieldWrapper'>
                <button 
                  className='loginSubmitButton'
                  disabled={this.state.forgotReqPending}>
                  {t(tx.TX_SUBMIT_REQUEST)}
                </button>
              </div>
              
              <div className={'loginFieldWrapper hrWrapper'}>
                <div className='hrBody'></div>
              </div>

              <div className={'loginFieldWrapper toggleButtonWrapper'}>
                <button className='toggleButton' onClick={this.backNav.bind(this)}>{t(tx.TX_BACK)}</button>
              </div>
              
            </form>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ForgotPassword));