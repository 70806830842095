import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { hasPermission } from '../../utils/permissions';

import { 
  ICON_CALENDAR, 
  ICON_HOME, 
  ICON_INVENTORY, 
  ICON_MARKETING,
  ICON_SELL, 
  ICON_SETTINGS, 
  ICON_USER, 
} from '../../constants/icons';
import * as p from '../../constants/permissions';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_CUSTOMERS, 
  URL_ADMIN_DASHBOARD, 
  URL_ADMIN_EVENTS, 
  URL_ADMIN_INVENTORY, 
  URL_ADMIN_MARKETING, 
  URL_ADMIN_ORDERS, 
  URL_ADMIN_SETTINGS, 
} from '../../constants/urls';

import { Icon } from '../Icons/Icon';

import './style/_admin.scss';

import * as headerActionCreators from '../../actions/header';
import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, headerActionCreators, userActionCreators);

export class AdminMenu extends Component {

	render() {

		const {t} = this.props;
	
    return <div className={'AdminMenu'}>

      <div className='adminMenuWrapper'>

        <div className='adminMenuTitle'>{t(tx.TX_MENU_SECTION_MANAGE)}</div>

        <NavLink exact to={URL_ADMIN_DASHBOARD} className='adminMenuLink' activeClassName='selected'>
          <div className='amLinkIconWrapper'>
            <Icon 
              value={ICON_HOME}  
              iconClass={'amIcon amIconDesktop'} 
              ellipsisLabel={false} />
            <Icon 
              value={ICON_HOME}  
              iconClass={'amIcon amIconMobile'} 
              iconLabel={t(tx.TX_DASHBOARD)} 
              ellipsisLabel={true} />
          </div>
          <div className='amLinkLabel'>{t(tx.TX_DASHBOARD)}</div>
        </NavLink>

        {hasPermission(p.P_MANAGE_SALES) ?
          <NavLink to={URL_ADMIN_ORDERS} className='adminMenuLink' activeClassName='selected'>
            <div className='amLinkIconWrapper'>
              <Icon 
                value={ICON_SELL}  
                iconClass={'amIcon amIconDesktop'} 
                ellipsisLabel={false} />
              <Icon 
                value={ICON_SELL}  
                iconClass={'amIcon amIconMobile'} 
                iconLabel={t(tx.TX_ORDERS)}
                ellipsisLabel={true} />
            </div>
            <div className='amLinkLabel'>{t(tx.TX_ORDERS)}</div>
          </NavLink> :
          null
        }
        {hasPermission(p.P_MANAGE_PRODUCTS) ?
          <NavLink to={URL_ADMIN_INVENTORY} className='adminMenuLink' activeClassName='selected'>
            <div className='amLinkIconWrapper'>
              <Icon 
                value={ICON_INVENTORY}  
                iconClass={'amIcon amIconDesktop'} 
                ellipsisLabel={false} />
              <Icon 
                value={ICON_INVENTORY}  
                iconClass={'amIcon amIconMobile'} 
                iconLabel={t(tx.TX_CATALOG)} 
                ellipsisLabel={true} />
            </div>
            <div className='amLinkLabel'>{t(tx.TX_CATALOG)}</div>
          </NavLink> :
          null
        }
        {hasPermission(p.P_MANAGE_USERS) ?
          <NavLink to={URL_ADMIN_CUSTOMERS} className='adminMenuLink' activeClassName='selected'>
            <div className='amLinkIconWrapper'>
              <Icon 
                value={ICON_USER}  
                iconClass={'amIcon amIconDesktop'} 
                ellipsisLabel={false} />
              <Icon 
                value={ICON_USER}  
                iconClass={'amIcon amIconMobile'} 
                iconLabel={t(tx.TX_CUSTOMERS)} 
                ellipsisLabel={true} />
            </div>
            <div className='amLinkLabel'>{t(tx.TX_CUSTOMERS)}</div>
          </NavLink> :
          null 
        }
        {hasPermission(p.P_MANAGE_EVENTS) ?
          <NavLink to={URL_ADMIN_EVENTS} className='adminMenuLink' activeClassName='selected'>
            <div className='amLinkIconWrapper'>
              <Icon 
                value={ICON_CALENDAR}  
                iconClass={'amIcon amIconDesktop'} 
                ellipsisLabel={false} />
              <Icon 
                value={ICON_CALENDAR}  
                iconClass={'amIcon amIconMobile'} 
                iconLabel={t(tx.TX_EVENTS)} 
                ellipsisLabel={true} />
            </div>
            <div className='amLinkLabel'>{t(tx.TX_MENU_ITEM_MANAGEEVENTS)}</div>
          </NavLink> :
          null
        }
        {hasPermission(p.P_MANAGE_SALES) ?
          <NavLink to={URL_ADMIN_MARKETING} className='adminMenuLink' activeClassName='selected'>
            <div className='amLinkIconWrapper'>
              <Icon 
                value={ICON_MARKETING}  
                iconClass={'amIcon amIconDesktop'} 
                ellipsisLabel={false} />
              <Icon 
                value={ICON_MARKETING}  
                iconClass={'amIcon amIconMobile'} 
                iconLabel={t(tx.TX_MARKETING)} 
                ellipsisLabel={true} />
            </div>
            <div className='amLinkLabel'>{t(tx.TX_MARKETING)}</div>
          </NavLink> :
          null
        }
        {hasPermission(p.P_MANAGE_PERMISSIONS) ?
          <NavLink to={URL_ADMIN_SETTINGS} className='adminMenuLink' activeClassName='selected'>
            <div className='amLinkIconWrapper'>
              <Icon 
                value={ICON_SETTINGS}  
                iconClass={'amIcon amIconDesktop'} 
                ellipsisLabel={false} />
              <Icon 
                value={ICON_SETTINGS}  
                iconClass={'amIcon amIconMobile'} 
                iconLabel={t(tx.TX_SETTINGS)} 
                ellipsisLabel={true} />
            </div>
            <div className='amLinkLabel'>{t(tx.TX_SETTINGS)}</div>
          </NavLink> :
          null
        }
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminMenu));


