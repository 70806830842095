import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { URL_BUY_PRODUCT_LINE } from '../../../constants/urls';

import { stringFormat } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

let allActionCreators = Object.assign({});

export class ProductPageTitle extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getFlexTitle() {
    if(this.props.productObj.productSet) {
      return this.props.productObj.productSet.name;
    }
    return '';
  }

  getProductLanguage() {
    if(this.props.productObj.foreignModel) {
      return this.props.productObj.foreignModel.language;
    }
    return this.getLanguage();
  }

  render() {

    if(!this.props.productObj) { return null; }

    return <div className={'ProductPageTitle'}>
      
      <div className={`ppTitleWrapper`}>
        <div className='ppTitleMain'>{this.props.productObj.nameWithTags}</div>
        <div className='ppSubTitleWrapper'>
          <div className='ppTitleProductLine'>
            <Link to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: this.props.productObj.productLine.permalink })}>
              {this.props.productObj.productLine.name}
            </Link>
          </div>
          {this.getFlexTitle() ?
            <div className='ppTitleSetName'>{this.getFlexTitle()}</div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageTitle));