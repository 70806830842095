

import { STORAGE_USER_PERMISSIONS } from '../constants/storage';

import * as storage from '../persistance/storage';


export function isAdmin() {

	const permissionsObj = storage.get(STORAGE_USER_PERMISSIONS);

	if(permissionsObj === null) {
		return false;
	}

	try {
    if(Object.keys(permissionsObj).length === 0) {
    	return false;
		}
		for(const permissionItem in permissionsObj) {
			if(permissionsObj[permissionItem] === true) {
				return true;
			}
		}
  } catch(err) {
    return false;
  }
	return false;
}

export function isCustomerAdmin(customer) {
	
	if(!customer || !customer.permissions) {
		return false;
	}
	try {
    if(Object.keys(customer.permissions).length === 0) {
    	return false;
		}
		for(const permissionItem in customer.permissions) {
			if(customer.permissions[permissionItem] === true) {
				return true;
			}
		}
  } catch(err) {
    return false;
  }
	return false;
}

export function getPermissionsObj() {
	const permissionsObj = storage.get(STORAGE_USER_PERMISSIONS);
	if(!permissionsObj) {
		return {};
	}
	return permissionsObj;
}

export function savePermissions(newObj) {
	storage.put(STORAGE_USER_PERMISSIONS, newObj);
}

export function hasPermission(permission) {
	const permissionsObj = getPermissionsObj();
	if(permissionsObj[permission]) {
		return true;
	} else {
		return false;
	}
}

export function customerHasPermission(customer, permission) {
	
	if(!customer || !customer.permissions || !permission) {
		return false;
	}
	const permissionsObj = customer.permissions;
	if(permissionsObj[permission]) {
		return true;
	} else {
		return false;
	}
}