
// import * as x from '../constants/actions';


// This logic currently handled in models, not middleware

// function mapUserPayload(serverPayload) {

//   let respObj = {
//     firstName: null,
//     lastName: null,
//     email: null,
//   };

//   const userMapping = {
//     first_name: 'firstName',
//     last_name: 'lastName',
//     email_address: 'email',
//   };

//   for(const [key, value] of Object.entries(userMapping)) {    
//     if(serverPayload[key]) {

//       switch(key) {
//         // case 'first_name':
//         //   break;
//         case 'email_address':
//           respObj[value] = serverPayload[key].toLowerCase();
//           break;
//         default:
//           respObj[value] = serverPayload[key];
//       }
//     }
//   }
//   return respObj;
// }

export function serverObjectSetterMiddleware({ dispatch }) {
  return function(next) {
    return function(action) {
      
      // Sample - delete when no longer necessary
      // if (action.type === x.NOT_REAL_SAMPLE) {
        
      //   const foundWord = forbiddenWords.filter(word =>
      //     action.payload.title.includes(word)
      //   );

      //   if (foundWord.length) {
      //     return dispatch({ type: "FOUND_BAD_WORD" });
      //   }
      // }

      // if(action.type === x.ACTION_USER_SET) {
      //   const reaction = Object.assign({}, action, {
      //     payload: mapUserPayload(action.payload),
      //   });
      //   return next(reaction);
      // }
      return next(action);
    };
  };
}




