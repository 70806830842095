import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERROR_EMAIL_EXISTS } from '../../constants/errors';
import * as tx from '../../constants/strings';

import { User } from '../../models/users';

import {
  getNameError,
  getEmailError,
} from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import AccountInputField from './blocks/AccountInputField';
import AccountTitle from './blocks/AccountTitle';
import LoadingIcon from '../Icons/LoadingIcon';
import SetPasswordModal from '../Popups/SetPasswordModal';

import './style/_account.scss';

import * as commonActionCreators from '../../actions/common';
import * as userActionCreators from '../../actions/user';
const allActionCreators = Object.assign({}, commonActionCreators, userActionCreators);

export class Account extends Component {

  constructor(props) {
    super(props);

    this.state = {

      passwordModalOpen: false,

      requestPending: true,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.fetchMe();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  async fetchMe() {

    const controller = new AbortController();
    this.controller = controller;

    await this.props.usersFetchMe(controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }
      });
  }

  async setUserAttribute(key, value) {

    const updateUser = new User(this.props.user.user);

    if(key === 'email' && updateUser.email !== value.trim().toLowerCase()) {
      
      const takenResp = await this.props.userEmailExists(value.trim().toLowerCase())
      .catch((errResp) => {
        // Do nothing
      });

      if(takenResp && takenResp.exists) {
        this.setState({ requestError: ERROR_EMAIL_EXISTS });
        this.fetchMe();
        return null;
      }
    }

    updateUser[key] = value;
    this.setState({
      requestPending: true,
      requestError: null,
    });

    await this.props.usersUpdateMe(updateUser.getApiData())
      .catch((errResp) => {
        this.setState({
          requestPending: false,
          requestError: formatServerError(errResp),
        });
      });

    this.fetchMe();
  }

  togglePasswordModal() {
    this.setState({ passwordModalOpen: !this.state.passwordModalOpen });
  }

  setPasswordAction(currentPassword, newPassword) {

    const passwordData = {
      old_password: currentPassword,
      new_password: newPassword,
    };

    this.setState({ 
      requestPending: true,
      requestError: null,
    });

    this.props.usersSetPassword(passwordData)
    .then((resp) => {
      this.props.commonAlert({ 
        alertTitle: tx.TX_SUCCESS, 
        alertCopy: tx.TX_ACCOUNT_PASSWORD_UPDATED, 
        alertStoreTheme: true, 
      });
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'Account AccountPage'}>
      <AccountTitle
        title={tx.TX_ACCOUNT_MY_PROFILE}
        breadcrumbs={[]} />
      <div className='accountBody'>
        <div className='accountBlock'>
          <div className='accountBlockLiner'>
            <div className='accountBlockTitle'>{t(tx.TX_ACCOUNT_USER_DETAILS)}</div>
            <div className='accountBlockBody'>
              <div className='accountForm'>
                <div className={`accountFormError${this.state.requestError ? ' present' : ''}`}>
                  <div className='errorWrapper'>{t(this.state.requestError ? this.state.requestError : tx.TX_null)}</div>
                </div>
                <div className='accountFormFieldWrapper'>
                  <AccountInputField 
                    apiKey={'firstName'}
                    label={tx.TX_FIRST_NAME}
                    pending={this.props.user.userPending}
                    setUserAttribute={this.setUserAttribute.bind(this)}
                    validationMethod={getNameError}
                    value={this.props.user.user ? this.props.user.user.firstName : ''} />
                </div>
                <div className='accountFormFieldWrapper'>
                  <AccountInputField 
                    apiKey={'lastName'}
                    label={tx.TX_LAST_NAME}
                    pending={this.props.user.userPending}
                    setUserAttribute={this.setUserAttribute.bind(this)}
                    validationMethod={getNameError}
                    value={this.props.user.user ? this.props.user.user.lastName : ''} />
                </div>
                <div className='accountFormFieldWrapper'>
                  <AccountInputField 
                    apiKey={'email'}
                    label={tx.TX_EMAIL_ADDRESS}
                    pending={this.props.user.userPending}
                    setUserAttribute={this.setUserAttribute.bind(this)}
                    validationMethod={getEmailError}
                    value={this.props.user.user ? this.props.user.user.email : ''} />
                </div>
                <div className='accountFormFieldWrapper'>
                  <AccountInputField 
                    apiKey={'password'}
                    label={tx.TX_PASSWORD}
                    openPasswordModal={this.togglePasswordModal.bind(this)}
                    password={true}
                    pending={this.props.user.userPending}
                    setUserAttribute={this.setUserAttribute.bind(this)}
                    value={null} />
                </div>
              </div>
            </div>
          </div>
          <div className='accountBlockPending' style={{display: this.props.user.userPending ? 'block' : 'none'}}>
            <div className='accountBlockPendingScreen'></div>
            <div className='accountBlockPendingWrapper'>
              <LoadingIcon />
            </div>
          </div>
        </div>
      </div>
      <SetPasswordModal
        open={this.state.passwordModalOpen}
        closeMethod={this.togglePasswordModal.bind(this)}
        onConfirm={this.setPasswordAction.bind(this)}
        onCancel={this.togglePasswordModal.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(Account));