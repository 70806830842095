import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  CSS_SLIDE_DURATION 
} from '../../constants/css';

import { 
  TX_DISCARD, 
  TX_DISCARD_CHANGES, 
  TX_SAVE, 
  TX_SAVE_PROMPT_COPY,
  TX_SAVE_PROMPT_TITLE 
} from '../../constants/strings';

import './style/_saveprompt.scss';

let allActionCreators = Object.assign({});

export class SavePrompt extends Component {

  // REQUIRED PROPS
  // open: bool
  // closeMethod: function

  // OPTIONAL PROPS
  // copy: string (overwrites default copy)
  // title: string (overwrites default title)
  // onCancel: function
  // onConfirm: function


  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,
      listenerEnabled: false,
    };

    this.modalBody = React.createRef();
    this.checkClick = this.checkClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    document.removeEventListener('click', this.checkClick, false);

    if(this.closingTimeout) {
      clearTimeout(this.closingTimeout);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.open !== prevProps.open) {
      if(this.props.open === true) {
        this.openPrompt();
      } else {
        this.closePrompt();
      }
    }
  }

  checkClick(evt) {
    if(this.props.open && this.state.listenerEnabled) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.modalBody.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.props.closeMethod();
    }  
  }

  openPrompt() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
      listenerEnabled: false,
    }, () => {

      // Delay to trigger CSS animation
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, 25);

      // Delay listening for clicks
      setTimeout(() => {
        this.setState({
          listenerEnabled: true,
        });
      }, CSS_SLIDE_DURATION);

      // Fix body
      document.body.style.overflow = 'hidden';
    });
  }

  closePrompt() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
      listenerEnabled: false,
    }, () => {
      this.closingTimeout = setTimeout(() => {
        this.setState({
          shouldDisplay: false,
        });
      }, CSS_SLIDE_DURATION);
    });
  }

  getScreenClass() {
    return this.state.hasOpacity ? 'spScreen withOpacity' : 'spScreen';
  }

  getModalStyle() {

    const baseConfig = {
      opacity: this.state.hasOpacity ? '1' : '0',
      top: '250px',
    };

    let verticalConfig = {};

    if(this.props.open && this.modalBody && this.modalBody.current && this.modalBody.current.clientHeight > 0) {
      
      const modalHeight = this.modalBody.current.clientHeight;
      verticalConfig = {
        top: (window.innerHeight - modalHeight)/2 + 'px',
      }
    }

    return Object.assign({}, baseConfig, verticalConfig);
  }

  getTitle() {
    return this.props.title ? this.props.title : TX_SAVE_PROMPT_TITLE;
  }

  getCopy() {
    return this.props.copy ? this.props.copy : TX_SAVE_PROMPT_COPY; 
  }

  cancelAction() {
    if(this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.closeMethod();
  }

  confirmAction() {
    if(this.props.onConfirm) {
      this.props.onConfirm();
    }
    this.props.closeMethod();
  }

  render() {

    const {t} = this.props;

    return <div 
            className='SavePrompt'
            style={{
              display: this.state.shouldDisplay ? 'block' : 'none'
            }}>
      <div className={this.getScreenClass()}></div>
      <div className='spModalWrapper'>
        <div className='spModal' style={this.getModalStyle()} ref={this.modalBody}>
          <div className='spModalInnerWrapper'>
            <div className='spClose' onClick={this.props.closeMethod.bind(this)}>&times;</div>
            <div className='spModalTitle'>{t(this.getTitle())}</div>
            <div className='spModalHr'></div>
            <div className='spModalCopy'>{t(this.getCopy())}</div>
            <div className='spModalActions'>
              <button className={'spAction spActionCancel shortCopy'} onClick={this.cancelAction.bind(this)}>
                {t(TX_DISCARD)}
              </button>
              <button className={'spAction spActionCancel longCopy'} onClick={this.cancelAction.bind(this)}>
                {t(TX_DISCARD_CHANGES)}
              </button>
              <button className={'spAction spActionSave'} onClick={this.confirmAction.bind(this)}>
                {this.props.confirmLabel ? t(this.props.confirmLabel) : t(TX_SAVE)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(SavePrompt));