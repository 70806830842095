import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Toggle from '../../../../Input/Toggle';

import * as productActionCreators from '../../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class ProductFieldSwitch extends Component {

  constructor(props) {
    super(props);

    // If this doesn't get explicitly cast to true/false, it still works, but React complains
    const initBool = this.props.inverted ? !this.props.elemValue : this.props.elemValue;
    
    this.state = {
      checked: initBool ? true : false,
    };
  }

  async toggleElement(evt) {

    const newValue = !this.state.checked;
    
    this.setState({ checked: newValue });
    this.props.setLoading(true);

    const updateData = {
      [this.props.elemKey]: this.props.inverted ? !newValue : newValue,
    }

    const pResp = await this.props.productInventoryEdit(this.props.inventory.getUpdateApiData(updateData, this.props.product))
      .catch((errResp) => {
        this.props.setLoading(false);
      });

    if(!pResp) {
      return null;
    }

    await this.props.refreshRow();
  }

  render() {
    return <div className={'ProductFieldSwitch ProductField'}>
      <div className='inputLiner'>
        <div className='switchWrapper'>
          <Toggle
            absolute={true}
            checked={this.state.checked}
            onToggle={this.toggleElement.bind(this)}
            hideLabels={true} />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductFieldSwitch));