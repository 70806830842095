
import * as x from '../constants/actions';


/******************************
*******  Synchronous  *********
******************************/

export function headerToggleUserMenu(payload) {
  return {
    type: x.ACTION_HEADER_TOGGLE_USER_MENU,
    payload
  };
}

export function headerSetMenuScreen(payload) {
  return {
    type: x.ACTION_HEADER_SET_MENU_SCREEN,
    payload
  };
}



/******************************
*******  Asynchronous  ********
******************************/


// None yet
