

import * as tx from '../../constants/strings';

import { getCurrencyObjFromCode } from '../../utils/currency';
import { isVarString } from '../../utils/general';


export class StampsCommon {

	static mapService(serviceType) {
		switch(serviceType) {
			case "usps_first_class_mail":
				return tx.TX_STAMPS_SERVICE_USPS_FIRST_CLASS_MAIL;
			case "usps_ground_advantage":
				return tx.TX_STAMPS_SERVICE_USPS_GROUND_ADVANTAGE;
			case "usps_priority_mail":
				return tx.TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL;
			case "usps_priority_mail_express":
				return tx.TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_EXPRESS;
			case "usps_parcel_select":
				return tx.TX_STAMPS_SERVICE_USPS_PARCEL_SELECT;
			case "usps_media_mail":
				return tx.TX_STAMPS_SERVICE_USPS_MEDIA_MAIL;
			case "usps_first_class_mail_international":
				return tx.TX_STAMPS_SERVICE_USPS_FIRST_CLASS_MAIL_INTERNATIONAL;
			case "usps_priority_mail_international":
				return tx.TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_INTERNATIONAL;
			case "usps_priority_mail_express_international":
				return tx.TX_STAMPS_SERVICE_USPS_PRIORITY_MAIL_EXPRESS_INTERNATIONAL;
			case "usps_pay_on_use_return":
				return tx.TX_STAMPS_SERVICE_USPS_PAY_ON_USE_RETURN;
			case "ups_next_day_air_early":
				return tx.TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR_EARLY;
			case "ups_next_day_air":
				return tx.TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR;
			case "ups_next_day_air_saver":
				return tx.TX_STAMPS_SERVICE_UPS_NEXT_DAY_AIR_SAVER;
			case "ups_2nd_day_air_am":
				return tx.TX_STAMPS_SERVICE_UPS_2ND_DAY_AIR_AM;
			case "ups_2nd_day_air":
				return tx.TX_STAMPS_SERVICE_UPS_2ND_DAY_AIR;
			case "ups_3_day_select":
				return tx.TX_STAMPS_SERVICE_UPS_THREE_DAY_SELECT;
			case "ups_ground":
				return tx.TX_STAMPS_SERVICE_UPS_GROUND;
			case "ups_standard":
				return tx.TX_STAMPS_SERVICE_UPS_STANDARD;
			case "ups_worldwide_express":
				return tx.TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPRESS;
			case "ups_worldwide_express_plus":
				return tx.TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPRESS_PLUS;
			case "ups_worldwide_expedited":
				return tx.TX_STAMPS_SERVICE_UPS_WORLDWIDE_EXPEDITED;
			case "ups_worldwide_saver":
				return tx.TX_STAMPS_SERVICE_UPS_WORLDWIDE_SAVER;
			case "fedex_first_overnight":
				return tx.TX_STAMPS_SERVICE_FEDEX_FIRST_OVERNIGHT;
			case "fedex_priority_overnight":
				return tx.TX_STAMPS_SERVICE_FEDEX_PRIORITY_OVERNIGHT;
			case "fedex_standard_overnight":
				return tx.TX_STAMPS_SERVICE_FEDEX_STANDARD_OVERNIGHT;
			case "fedex_2day_am":
				return tx.TX_STAMPS_SERVICE_FEDEX_TWO_DAY_AM;
			case "fedex_2day":
				return tx.TX_STAMPS_SERVICE_FEDEX_TWO_DAY;
			case "fedex_express_saver":
				return tx.TX_STAMPS_SERVICE_FEDEX_EXPRESS_SAVER;
			case "fedex_ground":
				return tx.TX_STAMPS_SERVICE_FEDEX_GROUND;
			case "fedex_home_delivery":
				return tx.TX_STAMPS_SERVICE_FEDEX_HOME_DELIVERY;
			case "fedex_international_first":
				return tx.TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_FIRST;
			case "fedex_international_priority":
				return tx.TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_PRIORITY;
			case "fedex_international_economy":
				return tx.TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_ECONOMY;
			case "fedex_international_ground":
				return tx.TX_STAMPS_SERVICE_FEDEX_INTERNATIONAL_GROUND;
			default:
				return serviceType;
		}
	}

	static mapCarrier(carrier) {
		switch(carrier) {
			case "canada_post":
				return tx.TX_STAMPS_CARRIER_CANADA_POST;
			case "dhl_express":
				return tx.TX_STAMPS_CARRIER_DHL;
			case "fedex":
				return tx.TX_STAMPS_CARRIER_FEDEX;
			case "globalpost":
				return tx.TX_STAMPS_CARRIER_GLOBAL_POST;
			case "ups":
				return tx.TX_STAMPS_CARRIER_UPS;
			case "usps":
				return tx.TX_STAMPS_CARRIER_USPS;
			default:
				return carrier;
		}
	}
}

export class StampsRate extends StampsCommon {

	constructor(props) {

		// As props, should accept another StampsRate object or a server resp
		if(!props) { props = {}; }
		super(props);

		this.carrier = props.carrier || '';
		this.serviceType = props.serviceType || props.service_type || '';
		this.packagingType = props.packagingType || props.packaging_type || '';
		this.estimatedDeliveryDays = props.estimatedDeliveryDays || props.estimated_delivery_days || 0;
		this.isGuaranteedService = props.isGuaranteedService || props.is_guaranteed_service || false;
		this.trackable = props.trackable === 'yes';

		this.isReturnLabel = props.isReturnLabel || props.is_return_label || false;
		this.isCustomsRequired = props.isCustomsRequired || props.is_customs_required || false;
		
		this.shipmentCost = new StampsCost(props.shipmentCost || props.shipment_cost || null);
	}

	get cost() {
		return this.shipmentCost && this.shipmentCost.totalAmount ? this.shipmentCost.totalAmount : 0;
	}

	get name() {
		return StampsCommon.mapService(this.serviceType);
	}

	get displayCarrier() {
		return StampsCommon.mapCarrier(this.carrier);
	}
}


export class StampsCost {

	constructor(props) {

		// As props, should accept another StampsCost object or a server resp
		if(!props) { props = {}; }

		const currencyValue = props.currency || 'usd';

		this.totalAmount = props.totalAmount || props.total_amount || 0;
		this.costDetails = props.costDetails || props.cost_details || {};

		this.currency = isVarString(currencyValue) ? getCurrencyObjFromCode(currencyValue) : currencyValue;
	}
}


export class StampsLabel extends StampsCommon {

	constructor(props) {

		// As props, should accept another StampsRate object or a server resp
		if(!props) { props = {}; }
		super(props);

		const labelsValue = props.labels || null;
		
		this.labelId = props.labelId || props.label_id || null;
		this.trackingNumber = props.trackingNumber || props.tracking_number || '';
		this.carrier = props.carrier || '';
		this.serviceType = props.serviceType || props.service_type || '';
		this.packagingType = props.packagingType || props.packaging_type || '';
		this.estimatedDeliveryDays = props.estimatedDeliveryDays || props.estimated_delivery_days || 0;
		this.estimatedDeliveryDateValue = props.estimatedDeliveryDate || props.estimated_delivery_date || null;
		this.isGuaranteedService = props.isGuaranteedService || props.is_guaranteed_service || false;
		this.trackable = !!props.trackable;
		this.isReturnLabel = props.isReturnLabel || props.is_return_label || false;
		this.isGap = props.isGap || props.is_gap || false;
		this.isSmartsaver = props.isSmartsaver || props.is_smartsaver || false;
		this.isEtoe = props.isEtoe || props.is_etoe || false;
		this.forms = props.forms || null;
		this.brandingId = props.brandingId || props.branding_id || false;
		this.notificationSettingId = props.notificationSettingId || props.notification_setting_id || false;

		this.src = props.src || (labelsValue && labelsValue.length && labelsValue[0].href ? labelsValue[0].href : null);
		this.base64 = props.base64 || (labelsValue && labelsValue.length && labelsValue[0].label_data ? labelsValue[0].label_data : null);
		
		this.shipmentCost = new StampsCost(props.shipmentCost || props.shipment_cost || null);
		this.estimatedDeliveryDate = this.estimatedDeliveryDateValue ? new Date(this.estimatedDeliveryDateValue) : null;
	}

	print(base64Override = null) {
		if(!this.base64 && !base64Override) { return null; }

		const byteCharacters = atob(base64Override || this.base64);

    // Convert that text into a byte array
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
    	byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob with the byte array and open it in a new window/tab
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow.print();
        
        if ('onafterprint' in window) {
          iframe.contentWindow.onafterprint = () => {
            document.body.removeChild(iframe);
          };
        } else {
          // Fallback: Remove the iframe after a long delay
          setTimeout(() => {
          	document.body.removeChild(iframe);
          }, 900000);
        }
      }, 1);
    };
	}

	openTab(base64Override = null) {

		if(!this.base64 && !base64Override) { return null; }

		// Convert the base64 string to a Blob object
    const byteCharacters = atob(base64Override || this.base64);
    const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    
    // Create a URL for the Blob object and open it in a new tab
    const blobURL = URL.createObjectURL(blob);
    const newTab = window.open(blobURL);
    
    // Clean up the blob URL once it's no longer needed
    newTab.addEventListener('unload', () => {
        URL.revokeObjectURL(blobURL);
    });
	}
}











































