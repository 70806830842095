
import { 
	PRINTINGS_ALL, 
	// LANG_EN, 
	// LANG_ES, 
} from '../constants/printings';

const PRINTING_KEY = 'key';

export function getPrintingObjectFromServerResp(printingCode) {

	if(!printingCode) {
		return null;
	}

	for(const pr of PRINTINGS_ALL) {
		if(printingCode === pr[PRINTING_KEY]) {
			return pr;
		}
	}
	return null;
}

export function getServerValueFromPrintingObject(printingObj) {
	return printingObj[PRINTING_KEY] || '';
}

export function getPrintingsOrderedDeduped(printingArray) {
	
	const keysToSort = [];
	for(const printing of printingArray) {
		if(!keysToSort.includes(printing.key)) {
			keysToSort.push(printing.key);
		}
	}

	const dedupedKeys = [...new Set(keysToSort)];

	const orderedKeys = [];
	for(const printing of PRINTINGS_ALL) {
		if(dedupedKeys.includes(printing.key)) {
			orderedKeys.push(printing.key);
		}
	}
	
	const orderedResp = [];
	for(const key of orderedKeys) {
		for(const printing of PRINTINGS_ALL) {
			if(key === printing.key) {
				orderedResp.push(printing);
				break;
			}
		}
	}
	return orderedResp;
}

// Currently the same as getPrintingObjectFromServerResp;
// Different function since server resp could possibly change
export function getPrintingObjectFromKey(printingKey, defaultResp = null) {

	if(!printingKey) {
		return defaultResp;
	}

	for(const prt of PRINTINGS_ALL) {
		if(printingKey === prt[PRINTING_KEY]) {
			return prt;
		}
	}
	return defaultResp;
}















