import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { utcToZonedTime } from 'date-fns-tz';

import {
  CAL_DISPLAY_TYPE_MOBILE,
} from '../../../constants/calendar';
import { 
  ICON_CALENDAR,
  ICON_CURRENCY,
  ICON_FORMAT,
  ICON_LOCATION,
  ICON_PLAY, 
  ICON_VIEW, 
} from '../../../constants/icons';
import * as tx from '../../../constants/strings';
import { 
  URL_ADMIN_EVENTS_VIEW,
  URL_ADMIN_EVENTS_SCHEDULE_VIEW, 
} from '../../../constants/urls';

import { encodeUrlDate } from '../../../utils/events';
import { 
  breaksToBrs,
  formatPrice, 
  stringFormat, 
} from '../../../utils/formatting';
import { getStoreTimeZone } from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';

import Icon from '../../Icons/Icon';

let allActionCreators = Object.assign({});

export class EventCalendarEventSummary extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  isMobileView() {
    return this.props.displayMode === CAL_DISPLAY_TYPE_MOBILE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getRootClass() {
    const baseClass = 'EventCalendarEventSummary';
    const adminClass = this.props.admin ? ' adminTheme' : '';
    const mobileClass = this.isMobileView() ? ' mobileView' : '';

    return `${baseClass}${adminClass}${mobileClass}`;
  }

  getLinkUrl() {
    if(this.props.eventObj && this.props.eventObj.isRepeating) {
      return stringFormat(URL_ADMIN_EVENTS_SCHEDULE_VIEW, { date: encodeUrlDate(this.props.date), publicUuid: this.props.eventObj.schedule.publicUuid });
    } else if(this.props.eventObj && !this.props.eventObj.isRepeating) {
      return stringFormat(URL_ADMIN_EVENTS_VIEW, { publicUuid: this.props.eventObj.publicUuid });
    } else {
      return '';
    }
  }

  render() {

    const {t} = this.props;

    return <div className={this.getRootClass()}>
      <div className='eceLiner'>
        <div className='eceHeaderWrapper'>
          <div className='eceHeader'>
            {this.props.admin ?
              <Link to={this.getLinkUrl()}>
                <div className='eceIconWrapper'>
                  <Icon value={ICON_VIEW} iconClass='eceIcon' />
                </div>
              </Link> :
              null
            }
            <div className='eceIconWrapper' onClick={this.props.closeAction.bind(this)}>
              <div className='textWrapper FlexCenter'>&times;</div>
            </div>
          </div>
        </div>
        <div className='eceBodyScrollWrapper'>
          <div className='eceBodyWrapper'>
            {this.props.eventObj ?
              <div className='eceBody'>
                <div className='titleWrapper'>
                  <div className='nameWrapper'>{this.props.eventObj.name}</div>
                  <div className='timeWrapper'>{utcToZonedTime(this.props.eventObj.startTime, getStoreTimeZone()).toLocaleString(this.getLanguage(), { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>
                </div>
                <div className='configWrapper'>
                  <div className='configDataWrapper'>
                    <div className='configIconWrapper'>
                      <Icon value={ICON_PLAY} />
                    </div>
                    <div className='configLabelWrapper'>
                      {this.props.eventObj.productLine && this.props.eventObj.productLine.name ? this.props.eventObj.productLine.name : ''}
                    </div>
                  </div>
                  {this.props.eventObj.format.name && this.props.eventObj.productLine && this.props.eventObj.productLine.eventFormats && this.props.eventObj.productLine.eventFormats.length > 0 ?
                    <div className='configDataWrapper'>
                      <div className='configIconWrapper'>
                        <Icon value={ICON_FORMAT} />
                      </div>
                      <div className='configLabelWrapper'>
                        {t(this.props.eventObj.format.name)}
                      </div>
                    </div> :
                    null
                  }
                  <div className='configDataWrapper'>
                    <div className='configIconWrapper'>
                      <Icon value={ICON_CURRENCY} width={'20px'} height='20px' />
                    </div>
                    <div className='configLabelWrapper'>
                      {formatPrice(this.props.eventObj.cost, { language: this.getLanguage(), zeroValue: t(tx.TX_FREE) })}
                    </div>
                  </div>
                  <div className='configDataWrapper'>
                    <div className='configIconWrapper'>
                      <Icon value={ICON_CALENDAR} />
                    </div>
                    <div className='configLabelWrapper'>
                      {utcToZonedTime(this.props.eventObj.startTime, getStoreTimeZone()).toLocaleString(this.getLanguage(), { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                    </div>
                  </div>
                  <div className='configDataWrapper' style={{ display: 'none' }}>
                    <div className='configIconWrapper'>
                      <Icon value={ICON_LOCATION} />
                    </div>
                    <div className='configLabelWrapper'>
                      address
                    </div>
                  </div>
                </div>
                {this.props.eventObj.description ?
                  <div 
                    className='descWrapper'
                    dangerouslySetInnerHTML={{ __html: breaksToBrs(this.props.eventObj.description, this.descriptionBreakStyle) }} /> :
                  null
                }
              </div> :
              null
            }
          </div>
        </div>
        <div className='eceFooterWrapper'>
          <div className='eceFooter'></div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventCalendarEventSummary));