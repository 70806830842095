import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { ShippingMethod } from '../../../models/shipping-methods';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../utils/currency';
import { 
  getCostError,
  getNameError, 
  getPurchaseAmountError, 
  getShortDescriptionError, 
  getWeightError,
  isFormValid, 
} from '../../../utils/form-validation';
import { 
  formatServerError,
  normalizePrice,
  normalizeWeight, 
} from '../../../utils/formatting';
import { getStoreDefaultWeightUnit } from '../../../utils/general';
import { 
  convertWeightBetweenUnits,
  getWeightUnitFromKey, 
  getWeightUnitOptions, 
} from '../../../utils/measurements';

import Dropdown from '../../Input/Dropdown';
import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';
import Toggle from '../../Input/Toggle';

import * as checkoutActionCreators from '../../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class ShippingMethodDetailsEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isDirty: false,

      inputStatus: this.props.shippingMethod.isEnabled,
      inputName: this.props.shippingMethod.name,
      inputDescription: this.props.shippingMethod.description, 
      inputIsPickup: this.props.shippingMethod.isPickup,
      inputAllowInStorePayment: this.props.shippingMethod.allowPaymentAtPickup, 
      inputCost: normalizePrice(this.props.shippingMethod.cost), 
      inputMinPurchase: normalizePrice(this.props.shippingMethod.minPurchase), 
      inputMinWeight: this.props.shippingMethod.minWeight ? normalizeWeight(convertWeightBetweenUnits(this.props.shippingMethod.minWeight, this.props.shippingMethod.minWeightUnit, getStoreDefaultWeightUnit()), getStoreDefaultWeightUnit()) : '',
      inputMinWeightUnit: getStoreDefaultWeightUnit(),
      inputMaxWeight: this.props.shippingMethod.maxWeight ? normalizeWeight(convertWeightBetweenUnits(this.props.shippingMethod.maxWeight, this.props.shippingMethod.maxWeightUnit, getStoreDefaultWeightUnit()), getStoreDefaultWeightUnit()) : '',
      inputMaxWeightUnit: getStoreDefaultWeightUnit(),

      errorName: '',
      errorDescription: '', 
      errorCost: '',
      errorMinPurchase: '', 
      errorMinWeight: '', 
      errorMaxWeight: '', 

      requestPending: false,
      requestError: null,

      resetConfirmOpen: false,
      savePromptOpen: false, 

      integrationConfig: this.props.shippingMethod && this.props.shippingMethod.integration && this.props.shippingMethod.integration.integrationConfig ? this.props.shippingMethod.integration.integrationConfig : {},
      integrationValidationSignal: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  changeStatus(evt) {
    this.setState({
      inputStatus: !this.state.inputStatus, 
    });
    this.makeDirty();
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorName) {
        this.validateName();
      }
    });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  changeDescription(evt) {
    this.setState({
      inputDescription: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorDescription) {
        this.validateDescription();
      }
    });
  }

  validateDescription() {
    this.setState({ errorDescription: getShortDescriptionError(this.state.inputDescription, true) });
  }

  changePickup(evt) {
    this.setState({
      inputIsPickup: !this.state.inputIsPickup,
      isDirty: true,
    }, () => {
      this.setState({
        inputAllowInStorePayment: this.state.inputIsPickup,
      })
    });
  }

  changeAllowInStorePayment(evt) {
    this.setState({
      inputAllowInStorePayment: !this.state.inputAllowInStorePayment,
      isDirty: true,
    });
  }

  changeCost(evt) {
    this.setState({
      inputCost: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorCost) {
        this.validateCost();
      }
    });
  }

  validateCost() {
    this.setState({ errorCost: getCostError(this.state.inputCost) });
  }

  changeMinPurchase(evt) {
    this.setState({
      inputMinPurchase: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorMinPurchase) {
        this.validateMinPurchase();
      }
    });
  }

  validateMinPurchase() {
    this.setState({ errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase) });
  }

  changeMinWeight(evt) {
    this.setState({
      inputMinWeight: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorMinWeight) {
        this.validateMinWeight(false);
      }
    });
  }

  validateMinWeight(shouldNormalize = true) {
    this.setState({ 
      inputMinWeight: shouldNormalize ? normalizeWeight(this.state.inputMinWeight, this.state.inputMinWeightUnit) : this.state.inputMinWeight, 
      errorMinWeight: getWeightError(this.state.inputMinWeight, true),
    });
  }

  changeMinWeightUnit(evt) {
    const previousUnit = this.state.inputMinWeightUnit;
    this.setState({
      inputMinWeightUnit: getWeightUnitFromKey(evt.target.value),
    }, () => {
      this.setState({
        inputMinWeight: normalizeWeight(convertWeightBetweenUnits(this.state.inputMinWeight, previousUnit, this.state.inputMinWeightUnit), this.state.inputMinWeightUnit),
      }, () => {
        this.makeDirty();
      });
    });
  }

  changeMaxWeight(evt) {
    this.setState({
      inputMaxWeight: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorMaxWeight) {
        this.validateMaxWeight(false);
      }
    });
  }

  validateMaxWeight(shouldNormalize = true) {
    this.setState({ 
      inputMaxWeight: shouldNormalize ? normalizeWeight(this.state.inputMaxWeight, this.state.inputMaxWeightUnit) : this.state.inputMaxWeight, 
      errorMaxWeight: getWeightError(this.state.inputMaxWeight, true),
    });
  }

  changeMaxWeightUnit(evt) {
    const previousUnit = this.state.inputMaxWeightUnit;
    this.setState({
      inputMaxWeightUnit: getWeightUnitFromKey(evt.target.value),
    }, () => {
      this.setState({
        inputMaxWeight: normalizeWeight(convertWeightBetweenUnits(this.state.inputMaxWeight, previousUnit, this.state.inputMaxWeightUnit), this.state.inputMaxWeightUnit),
      }, () => {
        this.makeDirty();
      });
    });
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const updatedShippingMethod = new ShippingMethod({
        is_enabled: this.state.inputStatus,
        name: this.state.inputName,
        description: this.state.inputDescription,
        cost: this.state.inputCost,
        min_purchase: this.state.inputMinPurchase,
        minWeight: this.state.inputMinWeight || null,
        minWeightUnit: this.state.inputMinWeightUnit || null,
        maxWeight: this.state.inputMaxWeight || null,
        maxWeightUnit: this.state.inputMaxWeightUnit || null,
        isPickup: this.state.inputIsPickup,
        allowPaymentAtPickup: this.state.inputAllowInStorePayment,

        integration: this.props.shippingMethod && this.props.shippingMethod.integration ? this.props.shippingMethod.integration : null,
        integrationConfig: this.state.integrationConfig,
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      this.props.checkoutUpdateShippingMethod(updatedShippingMethod.getApiData(), this.props.shippingMethod.publicUuid)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
        }, () => {
          if(this.props.remoteSave) {
            this.props.finishRemote('details');
          } else {
            this.props.makeClean('details');
          }
          this.props.toggleEdit();
        });
      })
      .catch((errResp) => {
        
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });

        if(this.props.remoteSave) {
          this.props.finishRemote('details', true);
        }
      });
    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('details', true);
    }
  }

  validateAll() {
    const errorObj = {
      errorName: getNameError(this.state.inputName),
      errorDescription: getShortDescriptionError(this.state.inputDescription, true),
      errorCost: getCostError(this.state.inputCost, this.props.shippingMethod.integration && this.props.shippingMethod.integration.hideCosts),
      errorMinPurchase: getPurchaseAmountError(this.state.inputMinPurchase, this.props.shippingMethod.integration && this.props.shippingMethod.integration.hideCosts),
      errorMinWeight: getWeightError(this.state.inputMinWeight, true),
      errorMaxWeight: getWeightError(this.state.inputMaxWeight, true),
      errorIntegrationConfig: this.validateIntegrationConfig(),
    };
    this.setState(Object.assign({}, errorObj, { integrationValidationSignal: Date.now() }));
    return isFormValid(errorObj);
  }

  validateIntegrationConfig() {
    if(!this.props.shippingMethod.integration || !this.props.shippingMethod.integration.localConfig) { return ''; }

    for(const confKey in this.props.shippingMethod.integration.localConfig) {
      const configValue = this.state.integrationConfig[confKey] || '';
      const validationMethod = this.props.shippingMethod.integration.localConfig[confKey].validationMethod;
      const isRequired = this.props.shippingMethod.integration.localConfig[confKey].isRequired;

      if(validationMethod) {
        const err = validationMethod(configValue, !isRequired);
        if(err) {
          return err;
        }
      }
    }
    return '';
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  changeIntegrationConfig(confData) {
    this.setState({ integrationConfig: confData });
  }

  getIntegrationLocalComponent() {
    if(!this.props.shippingMethod || !this.props.shippingMethod.integration) { return null; }

    const LocalComponent = this.props.shippingMethod.integration.saveShippingMethodComponent;
    return <LocalComponent
              integration={this.props.shippingMethod.integration}
              integrationParent={this.props.shippingMethod}
              setInegrationConfig={this.changeIntegrationConfig.bind(this)}
              validationSignal={this.state.integrationValidationSignal} />;
  }

  render() {

    const {t} = this.props;

    return <div className={'ShippingMethodDetailsEdit'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'shippingMethodDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_SHIPPING_METHOD)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputStatus}
                    onToggle={this.changeStatus.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='given-name'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.state.inputName}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_FIRST_NAME)}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_DESCRIPTION)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorDescription ? 'InputError' : ''}
                  value={this.state.inputDescription}
                  onChange={this.changeDescription.bind(this)}
                  onBlur={this.validateDescription.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_DESCRIPTION)}
                  maxLength={1024} />
                {this.state.errorDescription ?
                  <div className={'adminError FieldError'}>{t(this.state.errorDescription)}</div> :
                  null
                }
              </div>
            </div>

            {this.props.shippingMethod.integration && this.props.shippingMethod.integration.hidePickup ?
              null :
              <>
                <div className='adminFieldWrapper'> 
                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ORDER_CUSTOMER_PICKUP_QUESTION)}</div>
                    <div className='adminInputToggleWrapper'>
                      <Toggle
                        checked={this.state.inputIsPickup}
                        onToggle={this.changePickup.bind(this)}
                        trueValue={tx.TX_YES}
                        falseValue={tx.TX_NO} />
                    </div>
                  </div>
                  {this.state.inputIsPickup ?
                    <div className='adminInputWrapper halfWidth'>
                      <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_PAYMENT_METHODS_ALLOW_AT_PICKUP_QUESTION)}</div>
                      <div className='adminInputToggleWrapper'>
                        <Toggle
                          checked={this.state.inputAllowInStorePayment}
                          onToggle={this.changeAllowInStorePayment.bind(this)}
                          trueValue={tx.TX_YES}
                          falseValue={tx.TX_NO} />
                      </div>
                    </div> :
                    null
                  }
                </div>
                {this.state.inputIsPickup ?
                  <div className='adminFieldWrapper'> 
                    <div className='adminInputWrapper halfWidth'>
                      <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_ORDER_CUSTOMER_PICKUP_LOCATION)}</div>
                      <div className='adminInputReplacement'>{t(tx.TX_IN_STORE)}</div>
                    </div>
                  </div> :
                  null
                }
              </>
            }

            {this.props.shippingMethod.integration && this.props.shippingMethod.integration.hideCosts ?
              null :
              <>
                <div className='adminFieldWrapper'> 
                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_COST)}</div>
                    <div className='currencyWrapper'>
                      <input
                        type='number'
                        min={0}
                        max={100000000}
                        step={getCurrencyIncrement()}
                        className={this.state.errorCost ? 'InputError currencyInput' : 'currencyInput'}
                        value={this.state.inputCost}
                        onChange={this.changeCost.bind(this)}
                        onBlur={this.validateCost.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_COST)} />
                      <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                    </div>
                    {this.state.errorCost ?
                      <div className={'adminError FieldError'}>{t(this.state.errorCost)}</div> :
                      null
                    }
                  </div>
                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_MIN_PURCHASE)}</div>
                    <div className='currencyWrapper'>
                      <input
                        type='number'
                        min={0}
                        max={100000000}
                        step={getCurrencyIncrement()}
                        className={this.state.errorMinPurchase ? 'InputError currencyInput' : 'currencyInput'}
                        value={this.state.inputMinPurchase}
                        onChange={this.changeMinPurchase.bind(this)}
                        onBlur={this.validateMinPurchase.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_SHIPPING_METHOD_MIN_PURCHASE)} />
                      <div className='currencyOverlay'>{getCurrencySymbol()}</div>
                    </div>
                    {this.state.errorMinPurchase ?
                      <div className={'adminError FieldError'}>{t(this.state.errorMinPurchase)}</div> :
                      null
                    }
                  </div>
                </div>

                <div className='adminFieldWrapper'>

                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_SETTINGS_MIN_WEIGHT)}</div>
                    <div className={this.state.errorMinWeight ? 'adminInputWeight fieldError' : 'adminInputWeight'}>
                      <div className='aiwLiner'>
                        <div className={'aiwInputElement aiwElement'}>
                          <input
                            type='text'
                            className={this.state.errorMinWeight ? 'InputError' : ''}
                            value={this.state.inputMinWeight}
                            onChange={this.changeMinWeight.bind(this)}
                            onBlur={this.validateMinWeight.bind(this)}
                            placeholder={t(tx.TX_PLACEHOLDER_WEIGHT)}
                            maxLength={100} />
                        </div>
                        <div className={'aiwPostScriptWrapper aiwElement'}>
                          <Dropdown 
                            className={'aiwPostSelect'}
                            options={getWeightUnitOptions()}
                            name={t(tx.TX_SETTINGS_MIN_WEIGHT)}
                            value={this.state.inputMinWeightUnit.key}
                            noTranslate={true}
                            onChange={this.changeMinWeightUnit.bind(this)} />
                        </div>
                      </div>
                    </div>
                    {this.state.errorMinWeight ?
                      <div className={'FieldError'}>{t(this.state.errorMinWeight)}</div> :
                      null
                    }
                  </div>

                  <div className='adminInputWrapper halfWidth'>
                    <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_SETTINGS_MAX_WEIGHT)}</div>
                    <div className={this.state.errorMaxWeight ? 'adminInputWeight fieldError' : 'adminInputWeight'}>
                      <div className='aiwLiner'>
                        <div className={'aiwInputElement aiwElement'}>
                          <input
                            type='text'
                            className={this.state.errorMaxWeight ? 'InputError' : ''}
                            value={this.state.inputMaxWeight}
                            onChange={this.changeMaxWeight.bind(this)}
                            onBlur={this.validateMaxWeight.bind(this)}
                            placeholder={t(tx.TX_PLACEHOLDER_WEIGHT)}
                            maxLength={100} />
                        
                        </div>
                        <div className={'aiwPostScriptWrapper aiwElement'}>
                          <Dropdown 
                            className={'aiwPostSelect'}
                            options={getWeightUnitOptions()}
                            name={t(tx.TX_SETTINGS_MAX_WEIGHT)}
                            value={this.state.inputMaxWeightUnit.key}
                            noTranslate={true}
                            onChange={this.changeMaxWeightUnit.bind(this)} />
                        </div>
                      </div>
                    </div>
                    {this.state.errorMaxWeight ?
                      <div className={'FieldError'}>{t(this.state.errorMaxWeight)}</div> :
                      null
                    }
                  </div>

                </div>
              </>
            }

            {this.props.shippingMethod && this.props.shippingMethod.integration ?
              <>
                <div className='adminFieldWrapper'>
                  <div className='adminInputWrapper'>
                    <div className={'adminFieldLabel'}>{t(tx.TX_SETTINGS_ADD_SHIPPING_METHOD_INTEGRATION)}</div>
                    <div className='adminInputReplacement'>{this.props.shippingMethod.integration.provider.name}</div>
                  </div>
                </div>
                {this.props.shippingMethod.integration.saveShippingMethodComponent ?
                  <>{this.getIntegrationLocalComponent()}</> :
                  null
                }
              </> :
              null
            }
            
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ShippingMethodDetailsEdit));