import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isLoggedIn } from '../../../utils/auth';

import * as tx from '../../../constants/strings';
import { URL_REGISTER } from '../../../constants/urls';

import '../style/_headerhello.scss';

import * as headerActionCreators from '../../../actions/header';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, headerActionCreators, userActionCreators);

export class HeaderHelloUser extends Component {

  componentDidMount() {

    if(this.props.user && this.props.user.user === null) {
      
      this.props.usersFetchMe()
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className='HeaderHelloUser'>
      {isLoggedIn() ?
        <div>
          {!this.props.user.user ?
            <div className={'helloWrapper loadingWrapper'}>
              <div className='FlexCenter'>{t(tx.TX_LOADING)}</div>
            </div> :
            <div className='helloWrapper'>
              <div className='helloInnerWrapper'>
                <div className={'helloName EllipsisElement'}>{this.props.user.user.firstName ? t(tx.TX_HELLO_NAME, {name: this.props.user.user.firstName}) : t(tx.TX_HELLO_EXCLAIMATION)}</div>
                <div className={'helloEmail EllipsisElement'}>{this.props.user.user.email ? this.props.user.user.email : ''}</div>
              </div>
            </div>
          }
        </div> :
        <div className='helloWrapper'>
          <div className='helloInnerWrapper'>
            <div className={'helloName EllipsisElement'}>{t(tx.TX_HELLO_EXCLAIMATION)}</div>
            <Link 
              to={URL_REGISTER} 
              onClick={this.props.headerToggleUserMenu.bind(this)} 
              className='helloLink'><div className={'helloEmail EllipsisElement'}>{t(tx.TX_CREATE_ACCOUNT)}</div></Link>
          </div>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderHelloUser));


