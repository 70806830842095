import { useEffect, useRef } from 'react';

import usePrevious from './usePrevious';

const useSignal = (action, signal) => {
  
  const ref = useRef();

  const prevSignal = usePrevious(signal);

  useEffect(() => {
    if(signal && prevSignal !== undefined && prevSignal !== signal) {
      action();
    }
  }, [action, prevSignal, signal]);

  return ref.current;
}

export default useSignal;