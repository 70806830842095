import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import {
  ERROR_PAYMENT_METHOD_MISSING,
  ERROR_PAYMENT_PROCESSOR_MISSING,
} from '../../constants/errors';
import { 
  PAYMENT_PROCESSORS_ALL, 
  PM_VALUE_KEY_SQUARE_CC, 
} from '../../constants/payment-methods';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_SETTINGS, 
  URL_ADMIN_SETTINGS_PAYMENT_METHODS, 
  URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW, 
} from '../../constants/urls';

import { PaymentMethod } from '../../models/payment-methods';

import { 
  getNotEmptyError,
  isFormValid, 
} from '../../utils/form-validation';
import { 
  formatServerError, 
  stringFormat,
} from '../../utils/formatting';
import { 
  getPaymentProcessorMethodDropdownOptions, 
  getPaymentProcessorDropdownOptions, 
} from '../../utils/payment-method';

import AdminTitle from '../Admin/AdminTitle';
import Dropdown from '../Input/Dropdown';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';

import './style/_shippingmethods.scss';

import * as checkoutActionCreators from '../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class AddPaymentMethod extends Component {

  constructor(props) {
    super(props);

    this.processorOptions = getPaymentProcessorDropdownOptions();

    this.state = {
      isDirty: false,
      savePromptOpen: false,

      inputProcessor: this.processorOptions.length === 1 ? this.processorOptions[0].value : '',
      inputMethod: '', 

      errorProcessor: '',
      errorMethod: '', 

      paymentMethods: null,
      paymentMethodsPending: true,

      lastBlock: '',
      saveRedirect: false,

      requestPending: false,
      requestError: null,
    };

    this.excludeKeys = [];
  }

  componentDidMount() {
    
    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });

    this.props.checkoutFetchAdminPaymentMethods()
    .then((resp) => {

      const eKeys = [];
      for(const pmt of resp) {
        eKeys.push(pmt.key);
      }
      this.excludeKeys = eKeys;

      this.setState({
        paymentMethods: resp,
        paymentMethodsPending: false, 
      });
    })
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
      this.setState({
        paymentMethodsPending: false, 
      });
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveAction();
    });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll(true)) {

      this.setState({
        requestPending: true,
        requestError: null,
      });
      
      const newMethod = PaymentMethod.getByKey(this.state.inputMethod);

      if(newMethod.isOauth()) {
        newMethod.authorizeRedirect();
      } else {
        // Default is create via API
        const addResp = await this.props.checkoutAddPaymentMethod(newMethod.getApiData())
          .catch((errResp) => {
            console.error(errResp);
            this.setState({ 
              requestPending: false,
              requestError: formatServerError(errResp),
            });
          });

        if(!addResp) {
          return null;
        }

        this.setState({ isDirty: false }, () => {
          if(!this.state.saveRedirect) {
            history.push(stringFormat(URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW, { public_uuid: addResp.public_uuid }));
          } else {
            history.push(this.state.lastBlock);
          }
        });
      }
    }
  }

  validateAll(showErrors = false) {
    const errorObj = {
      errorProcessor: getNotEmptyError(this.state.inputProcessor, ERROR_PAYMENT_PROCESSOR_MISSING),
      errorMethod: getNotEmptyError(this.state.inputMethod, ERROR_PAYMENT_METHOD_MISSING),
    };
    if(showErrors) {
      this.setState(errorObj);
    }
    return isFormValid(errorObj);
  }

  changeProcessor(evt) {
    this.setState({
      inputMethod: '',
      inputProcessor: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorProcessor) {
        this.validateProcessor();
      }
    });
  }

  validateProcessor() {
    this.setState({ errorProcessor: getNotEmptyError(this.state.inputProcessor, ERROR_PAYMENT_PROCESSOR_MISSING) });
  }

  getProcessorOptions() {
    const respArray = [];
    try {
      if(!this.processorOptions || !this.processorOptions.length) { return []; }
      respArray.push({
        display: tx.TX_PLACEHOLDER_PAYMENT_PROCESSOR,
        value: '',
      });

      for(const processorOption of this.processorOptions) {
        respArray.push(processorOption);
      }
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  changeMethod(evt) {
    this.setState({
      inputMethod: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorMethod) {
        this.validateMethod();
      }
    });
  }

  validateMethod() {
    this.setState({ errorMethod: getNotEmptyError(this.state.inputMethod, ERROR_PAYMENT_METHOD_MISSING) });
  }

  getProcessorMethodOptions() {
    const respArray = [];
    try {
      const methodOptions = getPaymentProcessorMethodDropdownOptions(this.state.inputProcessor, this.excludeKeys);
      respArray.push({
        display: tx.TX_PLACEHOLDER_PAYMENT_METHOD,
        value: '',
      });
      for(const methodOption of methodOptions) {
        respArray.push(methodOption);
      }
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  getReminderText() {
    return this.state.inputMethod === PM_VALUE_KEY_SQUARE_CC ? tx.TX_SETTINGS_SQUARE_REDIRECT_NOTICE : tx.TX_null;
  }

  getSelectedProcessorName() {
    for(const proc of this.processorOptions) {
      if(proc.value === this.state.inputProcessor) {
        return proc.display;
      }
    }
    return tx.TX_null;
  }

  shouldShowNotice() {
    for(const proc of PAYMENT_PROCESSORS_ALL) {
      if(proc.key === this.state.inputProcessor) {
        return proc.hideNotice !== true;
      }
    }
    return true;
  }

  render() {

    const {t} = this.props;

    return <div className={'AddPaymentMethod AdminPage'}>

      <AdminTitle
        title={tx.TX_ADD}
        breadcrumbs={[
          {
            url: URL_ADMIN_SETTINGS,
            title: tx.TX_SETTINGS,
          },
          {
            url: URL_ADMIN_SETTINGS_PAYMENT_METHODS,
            title: tx.TX_SETTINGS_PAYMENT_METHODS,
          },
        ]} />

      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className='addPaymentMethodForm'
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_SETTINGS_ADD_PAYMENT_METHOD)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SETTINGS_PAYMENT_PROCESSOR)}</div>
              <div className='adminInputWrapper'>
                <div className='adminDropdownWrapper'>
                  <Dropdown 
                    className={'adminDropdownSelect'}
                    options={this.getProcessorOptions()}
                    name={t(tx.TX_SETTINGS_PAYMENT_PROCESSOR)}
                    value={this.state.inputProcessor}
                    required={true}
                    onChange={this.changeProcessor.bind(this)} />
                </div>
                {this.state.errorProcessor ?
                  <div className={'adminError FieldError'}>{t(this.state.errorProcessor)}</div> :
                  null
                }
                {this.state.inputProcessor && this.shouldShowNotice() ?
                  <div className={'FieldNotice fieldSupplement'}>
                    <span className='addPaymentMethodNote'>{t(tx.TX_SETTINGS_PAYMENT_PROCESSOR_NEED_ACCOUNT, { processor: t(this.getSelectedProcessorName()) })}</span>
                  </div> :
                  null
                }
              </div>
            </div>
            {this.state.inputProcessor ?
              <>
                {this.state.paymentMethodsPending ?
                  <div className='adminFieldWrapper'>
                    <div className='paymentInputLoading'>
                      <div className='paymentInputLoadingIcon'>
                        <LoadingIcon />
                      </div>
                      <div className='paymentInputLoadingLabel'>{t(tx.TX_LOADING)}</div>
                    </div>
                  </div> :
                  <>
                    {getPaymentProcessorMethodDropdownOptions(this.state.inputProcessor, this.excludeKeys).length === 0 ?
                      <div className='noMethodNotice'>{t(tx.TX_SETTINGS_PAYMENT_METHODS_NO_MORE)}</div> :
                      <div className='adminFieldWrapper'>
                        <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_CHECKOUT_PAYMENT_METHOD)}</div>
                        <div className='adminInputWrapper'>
                          <div className='adminDropdownWrapper'>
                            <Dropdown 
                              className={'adminDropdownSelect'}
                              options={this.getProcessorMethodOptions()}
                              name={t(tx.TX_CHECKOUT_PAYMENT_METHOD)}
                              value={this.state.inputMethod}
                              required={true}
                              onChange={this.changeMethod.bind(this)} />
                          </div>
                          {this.state.errorMethod ?
                            <div className={'adminError FieldError'}>{t(this.state.errorMethod)}</div> :
                            null
                          }
                        </div>
                      </div>
                    }
                  </>
                }
                  
              </> :
              null
            }
            {this.getReminderText() ?
              <div className='adminFieldWrapper'>
                <div className={'adminFieldSmallNotice'}>{t(this.getReminderText())}</div>
              </div> :
              null
            }
            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_SETTINGS_PAYMENT_METHODS}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.validateAll() === false || this.state.requestPending}>
                {t(tx.TX_NEXT)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddPaymentMethod));