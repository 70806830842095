import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import Dropdown from '../../../Input/Dropdown';

let allActionCreators = Object.assign({});

export class FilterElementDropdown extends Component {

  handleChange(evt) {
    const filterKey = this.props.config.key;
    this.props.setFilter({
      [filterKey]: evt.target.value,
    });
  }

  formatOptions() {
    if(!this.props.options) {
      return [];
    }
    
    const valueOptions = [];
    
    for(const opt of this.props.options) {
      valueOptions.push({
        display: opt.display, 
        value: opt.value,
      });
    }

    if(this.props.placeholder) {
      valueOptions.unshift({
        display: this.props.t(this.props.placeholder),
        value: '',
      });
    }
    return valueOptions;
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterElement FilterElementDropdown'}>
      <div className='feFilterTitle'>
        <div className={'feFilterTitleValue EllipsisElement'}>{t(this.props.config.label)}</div>
      </div>
      <div className='feFilterBody open'>
        {this.props.options ?
          <Dropdown 
            className='fedSelect'
            options={this.formatOptions()}
            noTranslate={true}
            name={this.props.config.key}
            value={this.props.filterValue}
            onChange={this.handleChange.bind(this)} /> :
          null
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterElementDropdown));