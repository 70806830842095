import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import { EVENT_EXCEPTION_TYPE_DELETION } from '../../constants/events';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_EVENTS, 
  URL_ADMIN_EVENTS_SCHEDULE_VIEW, 
} from '../../constants/urls';

import { StoreEvent } from '../../models/events';

import { decodeUrlDate } from '../../utils/events';
import { 
  formatServerError, 
  stringFormat, 
} from '../../utils/formatting';
import { twoDigitInt } from '../../utils/general';

import AdminTitle from '../Admin/AdminTitle';
import Choice from '../Popups/Choice';
import EventScheduleDetailsEdit from './blocks/EventScheduleDetailsEdit';
import EventScheduleDetailsView from './blocks/EventScheduleDetailsView';
import SavePrompt from '../Popups/SavePrompt';

import './style/_viewevents.scss';

import * as commonActionCreators from '../../actions/common';
import * as eventActionCreators from '../../actions/event';
let allActionCreators = Object.assign({}, eventActionCreators, commonActionCreators);

export class ViewEventSchedule extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirtyDetails: false,
      savePromptOpen: false,
      lastBlock: '',

      editDetails: false,

      ordersExpanded: false,

      requestPending: true,
      requestError: null,
      responseObject: new StoreEvent(),

      savingAll: false,
      remoteSaveDetails: false,
    };

    this.eventDate = decodeUrlDate(this.props.match.params.eventDate);

    this.controller = null;
  }

  componentDidMount() {

    // Block navigation if dirty
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirtyDetails === false) {
        return true;
      }
      this.toggleSavePrompt();
      return false;
    });

    this.fetchEventSchedule();
  }

  componentWillUnmount() {
    this.unblock();
    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.savingAll === false && prevState.savingAll === true) {
      if(this.state.isDirtyDetails === false) {
        history.push(this.state.lastBlock);
      } else {
        this.fetchEventSchedule();
      }
    }
  }

  fetchEventSchedule(service = '') {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      responseObject: new StoreEvent(),
    });

    // Get event details
    if(this.props.match.params.scheduleUUID) {
      this.props.eventScheduleAdminFetchSingle(this.props.match.params.scheduleUUID, controller.signal)
      .then((resp) => {

        this.setState({
          requestPending: false,
          requestError: null,
          responseObject: resp.getEvent(this.eventDate) || new StoreEvent(),
        });
        if(service === 'details') {
          this.setState({ isDirtyDetails: false });
        }
      })
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
          responseObject: new StoreEvent(),
        });
      });
    }
  }

  cleanNav(newUuid) {
    this.setState({
      isDirtyDetails: false,
    }, () => {
      history.replace(stringFormat(URL_ADMIN_EVENTS_SCHEDULE_VIEW, { date: this.props.match.params.eventDate, publicUuid: newUuid }));
    });
  }

  makeDetailsDirty() {
    if(this.state.isDirtyDetails === false) {
      this.setState({ isDirtyDetails: true });
    }
  }

  toggleDetails() {
    this.setState({ editDetails: !this.state.editDetails }, () => {
      if(this.state.editDetails === false) {
        this.setState({ isDirtyDetails: false, });
      }
    });
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({
      savingAll: true, 
      remoteSaveDetails: this.state.isDirtyDetails,
    });
  }

  finishRemote(service, didFail = false) {
    if(service === 'details') {
      this.setState({ 
        isDirtyDetails: didFail !== false, 
        remoteSaveDetails: false, 
      }, () => {
        this.setState({ savingAll: this.state.remoteSaveDetails === true });
      });
    }
  }

  toggleDeletePrompt() {
    if(!this.state.deletePromptOpen && this.saveDisabled()) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_INV_VIEW_PRODUCT_DELETE_ALERT_TITLE, 
        alertCopy: tx.TX_EVENTS_DELETE_ALERT_COPY, 
      });
    } else if(this.eventHasPassed()) {
      this.props.commonAlert({ 
        alertTitle: tx.TX_EVENTS_EVENT_PASSED, 
        alertCopy: tx.TX_EVENTS_DELETE_PASSED_ALERT_COPY, 
      });
    } else {
      this.setState({ deletePromptOpen: !this.state.deletePromptOpen });
    }
  }

  eventHasPassed() {
    return this.state.responseObject ? this.state.responseObject.hasPassed() : true;
  }

  saveDisabled() {
    return this.state.editDetails;
  }

  deleteAllEventAction(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.eventDeleteEventSchedule(this.state.responseObject.schedule.publicUuid)
    .then((resp) => {
      history.push(URL_ADMIN_EVENTS);
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  deleteSingleEventAction(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    const exceptionData = {
      exception_type: EVENT_EXCEPTION_TYPE_DELETION,
      exception_date: `${this.eventDate.getFullYear()}-${twoDigitInt(this.eventDate.getMonth() + 1)}-${twoDigitInt(this.eventDate.getDate())}`,
    }

    this.props.eventScheduleCreateException(this.state.responseObject.schedule.publicUuid, exceptionData)
    .then((resp) => {
      history.push(URL_ADMIN_EVENTS);
    })
    .catch((errResp) => {
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ViewEventSchedule AdminPage'}>
      <AdminTitle
        title={tx.TX_VIEW}
        breadcrumbs={[
          {
            url: URL_ADMIN_EVENTS,
            title: tx.TX_MENU_ITEM_MANAGEEVENTS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminView'>
          {this.state.editDetails ? 
            <EventScheduleDetailsEdit
              date={this.eventDate}
              eventObj={this.state.responseObject}
              eventPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)}
              makeDirty={this.makeDetailsDirty.bind(this)}
              makeClean={this.cleanNav.bind(this)} 
              remoteSave={this.state.remoteSaveDetails}
              finishRemote={this.finishRemote.bind(this)} /> :
            <EventScheduleDetailsView
              eventObj={this.state.responseObject}
              eventPending={this.state.requestPending}
              toggleEdit={this.toggleDetails.bind(this)} />
          }
        </div>
      </div>
      <div className='adminActions'>
        <button 
          type='button'
          className={this.saveDisabled() || this.eventHasPassed() ? 'disabled blockButton' : 'blockButton'} 
          onClick={this.toggleDeletePrompt.bind(this)}>
          {t(tx.TX_DELETE)}
        </button>
        <Link to={URL_ADMIN_EVENTS} className='adminButton'>{t(tx.TX_BACK)}</Link>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <Choice
        title={tx.TX_EVENTS_DELETE_ALL_COPY}
        copy={tx.TX_EVENTS_DELETE_ALL_TITLE}
        open={this.state.deletePromptOpen}
        actions={{
          [tx.TX_EVENTS_DELETE_SINGLE_BUTTON]: this.deleteSingleEventAction.bind(this),
          [tx.TX_EVENTS_DELETE_ALL_BUTTON]: this.deleteAllEventAction.bind(this),
        }}
        closeMethod={this.toggleDeletePrompt.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ViewEventSchedule));