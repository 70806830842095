import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

const allActionCreators = Object.assign({});

export class AutocompleteNull extends Component {

  render() {

    const {t} = this.props;

    return <div className={'AutocompleteNull'}>
      <div className='nullLiner'>
        {this.props.lastQuery ?
          <>
            <div className='noResultsCopy'>{t(tx.TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING)}</div>
            <div className='noResultsNeedle'>{this.props.lastQuery}</div>
          </> :
          <div className='noResultsCopy'>{t(tx.TX_NO_RESULTS)}</div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AutocompleteNull));