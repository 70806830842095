

import {
	TX_CAL_DAY_SUNDAY,
	TX_CAL_DAY_MONDAY,
	TX_CAL_DAY_TUESDAY,
	TX_CAL_DAY_WEDNESDAY,
	TX_CAL_DAY_THURSDAY,
	TX_CAL_DAY_FRIDAY,
	TX_CAL_DAY_SATURDAY,
} from './strings';


export const CAL_DISPLAY_TYPE_MOBILE = 'CAL_DISPLAY_TYPE_MOBILE';
export const CAL_DISPLAY_TYPE_MONTHLY = 'CAL_DISPLAY_TYPE_MONTHLY';
export const CAL_DISPLAY_TYPE_WEEKLY = 'CAL_DISPLAY_TYPE_WEEKLY';

export const CAL_DAY_NAMES = [
	TX_CAL_DAY_SUNDAY,
	TX_CAL_DAY_MONDAY,
	TX_CAL_DAY_TUESDAY,
	TX_CAL_DAY_WEDNESDAY,
	TX_CAL_DAY_THURSDAY,
	TX_CAL_DAY_FRIDAY,
	TX_CAL_DAY_SATURDAY,
];
