
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AdminMenu from '../components/Admin/AdminMenu';

import { 
  getNeedsPasswordReset, 
  getNeedsReAuth, 
  isLoggedIn 
} from '../utils/auth';
import { 
  hasPermission, 
  isAdmin, 
} from '../utils/permissions';

import {
  URL_ADMIN_DASHBOARD, 
  URL_LOGIN, 
  URL_LOGIN_REAUTH, 
  URL_SET_PASSWORD, 
} from '../constants/urls';

const AdminRoute = ({ component: Component, render, ...rest }) => {

  const renderContent = props => {

    if(!isLoggedIn() || !isAdmin()) {
      return (
        <Redirect
          to={{
            pathname: URL_LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }

    if(getNeedsPasswordReset()) {
      return (
        <Redirect
          to={{
            pathname: URL_SET_PASSWORD,
            state: { from: props.location }
          }}
        />
      );
    }

    if(rest.requiredPermission) {
      if(hasPermission(rest.requiredPermission) === false) {
        return (
          <Redirect
            to={{
              pathname: URL_ADMIN_DASHBOARD,
              state: { from: props.location }
            }}
          />
        );
      }
    }

    if(isLoggedIn() && rest.reAuth && getNeedsReAuth()) {
      return (
        <Redirect
          to={{
            pathname: URL_LOGIN_REAUTH,
            state: { from: props.location }
          }}
        />
      );
    }

    return typeof render === 'function' ? (
      render(props)
    ) : (
      <div className='adminWrapper'>
        <div className='adminWrapperMenu'>
          <AdminMenu />
        </div>
        <div className='adminWrapperComponent'>
          <Component {...props} />
        </div>
      </div>
    );
  };
  return <Route {...rest} render={renderContent} />;
};

export default AdminRoute;