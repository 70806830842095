import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { formatPrice } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import { 
  getOrderedInventory, 
  getPriceRangeFromInventory, 
  getPricesCount, 
  getProductInventoryLabel, 
  getTotalQuantityFromInventory, 
} from '../../../../utils/product';

import LoadingIcon from '../../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});


export class ProductInventoryView extends Component {

  constructor(props) {
    super(props);

    this.descriptionElement = React.createRef();
    this.descriptionMaxHeight = 100;
  }

  getProductInventory() {
    return this.props.productObj && this.props.productObj.inventory ? getOrderedInventory(this.props.productObj) : [];
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getPriceLabel() {
    if(this.props.productObj && this.props.productObj.inventory && getPricesCount(this.props.productObj.inventory) > 1) {
      return tx.TX_INV_VIEW_PRODUCT_PRICE_RANGE;
    } else {
      return tx.TX_INV_VIEW_PRODUCT_PRICE;
    }
  }

  editInventory(inventoryObj) {
    this.props.toggleEdit(inventoryObj);
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductInventoryView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INVENTORY)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='productViewWrapper'>
              <div className='inventoryHeadline'>
                <div className={'headlineSection qtySection'}>
                  <div className='headlineElement'>
                    <div className='headlineLabel'>{t(tx.TX_INV_VIEW_PRODUCT_TOTAL_QUANTITY)}</div>
                    <div className='headlineValue'>
                      {getTotalQuantityFromInventory(this.props.productObj && this.props.productObj.inventory ? this.props.productObj.inventory : [], this.getLanguage())}
                    </div>
                  </div>
                </div>
                <div className={'headlineSection priceSection'}>
                  <div className='headlineElement'>
                    <div className='headlineLabel'>{t(this.getPriceLabel())}</div>
                    <div className='headlineValue'>
                      {getPriceRangeFromInventory(this.props.productObj && this.props.productObj.inventory ? this.props.productObj.inventory : [], { language: this.getLanguage() })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='inventoryTable'>
                <div className='inventoryTableHeader'>
                  <div className={'itHeaderCell controlCell'}>
                    <div className='headerCellWrapper'>&nbsp;</div>
                  </div>
                  <div className={'itHeaderCell typeCell'}>
                    <div className='headerCellWrapper'>{t(tx.TX_TYPE)}</div>
                  </div>
                  <div className={'itHeaderCell qtyCell'}>
                    <div className='headerCellWrapper'>{t(tx.TX_QUANTITY)}</div>
                  </div>
                  <div className={'itHeaderCell priceCell'}>
                    <div className='headerCellWrapper'>{t(tx.TX_PRICE)}</div>
                  </div>
                  <div className={'itHeaderCell editCell'}>
                    <div className='headerCellWrapper'></div>
                  </div>
                </div>
                <div className='inventoryTableBody'>
                  {this.getProductInventory().length === 0 ?
                    <div className='itBodyEmpty'>{t(tx.TX_INV_VIEW_PRODUCT_NO_INVENTORY_YET)}</div> :
                    <div>
                      {this.getProductInventory().map((inv, i) => {
                        return <div className='itBodyRow' key={i}>
                          <div className={'itBodyCell controlCell'}></div>
                          <div className={'itBodyCell typeCell'}>
                            <div className='typeCellContent'>{getProductInventoryLabel(this.props.productObj, inv, { translate: t })}</div>
                          </div>
                          <div className={'itBodyCell qtyCell'}>{inv.totalQuantity.toLocaleString(this.getLanguage())}</div>
                          <div className={'itBodyCell priceCell'}>{formatPrice(inv.sellPrice, { language: this.getLanguage() })}</div>
                          <div className={'itBodyCell editCell'}>
                            <button 
                              type='button'
                              className='editCellLink'
                              onClick={() => this.props.toggleEdit(inv)}>
                              {t(tx.TX_EDIT)}
                            </button>
                          </div>
                        </div>
                      })}
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='adminViewButtonWrapper'>
              <button 
                type='button'
                className={'productDetailsButton adminButton'} 
                onClick={() => this.props.toggleEdit(null)}>
                {t(tx.TX_ADD_NOUN, { noun: t(tx.TX_INVENTORY) })}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.productPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductInventoryView));