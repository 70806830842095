import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import {
  ERR_5500,
  ERROR_ORDER_SHIPMENT_MISSING,
} from '../../../../../constants/errors';
import { ICON_CHEVRON_DOWN } from '../../../../../constants/icons';
import { ORDER_STATUS_SHIPPED } from '../../../../../constants/orders';
import { STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED } from '../../../../../constants/store';
import * as tx from '../../../../../constants/strings';

import { 
  getCurrencyIncrement, 
  getCurrencyMinorCount,
  getCurrencySymbol, 
} from '../../../../../utils/currency';
import { 
  getGenericNumberError,
  getNotEmptyError, 
  isFormValid, 
} from '../../../../../utils/form-validation';
import { 
  formatPrice,
  formatServerError,
  normalizePrice,
} from '../../../../../utils/formatting';
import { getStoreLanguage } from '../../../../../utils/language';
import { dynamicSort } from '../../../../../utils/sort';

import Checkbox from '../../../../Input/Checkbox';
import Dropdown from '../../../../Input/Dropdown';
import Icon from '../../../../Icons/Icon';
import LoadingIcon from '../../../../Icons/LoadingIcon';
import Radio from '../../../../Input/Radio';

import '../../../style/_integrationcomponents.scss';

import * as orderActionCreators from '../../../../../actions/order';
import * as storeActionCreators from '../../../../../actions/store';
const allActionCreators = Object.assign({}, orderActionCreators, storeActionCreators);

export class CreateShipmentStamps extends Component {

  constructor(props) {
    super(props);

    this.PARCEL_TYPE_ENVELOPE = 'large_envelope';
    this.PARCEL_TYPE_PACKAGE = 'package';

    this.subTotal = this.props.order && this.props.order.cart && this.props.order.cart.subTotal ? this.props.order.cart.subTotal : 0;

    this.state = {

      inputSavedParcel: '',
      inputParcelType: this.PARCEL_TYPE_PACKAGE,
      inputOptionParcelGuard: false,
      inputOptionParcelGuardAmount: normalizePrice(this.subTotal),
      inputOptionRequireSignature: false,
      inputRate: '',

      errorParcelType: '', 
      errorParcelGuardAmount: '',
      errorRate: '',

      optionsOpen: true,

      requestPending: false,
      requestError: null,

      ratesPending: false,
      ratesError: null,
      ratesResp: null,
    }

    this.controllerRates = null;
    this.controllerSubmit = null;

    this.fetchRatesDebounced = _.debounce(this.fetchRates.bind(this), 1000);
  }

  componentDidMount() {
    this.props.allowConfirm(false);
    this.fetchRates();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.saveAction();
    }
  }

  componentWillUnmount() {
    if(this.controllerSubmit) {
      this.controllerSubmit.abort();
    }
    if(this.controllerRates) {
      this.controllerRates.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_CREATE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  validateAll() {
    const errorObj = {
      errorRate: getNotEmptyError(this.state.inputRate, ERROR_ORDER_SHIPMENT_MISSING),
    };

    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async fetchRates(evt) {

    if(this.controllerRates) {
      this.controllerRates.abort();
    }
    const controller = new AbortController();
    this.controllerRates = controller;

    this.setState({
      ratesPending: true,
      ratesError: null,
    });

    // Fetch rates
    const ratesReq = {
      parcel_type: this.state.inputParcelType,
      opt_sign_req: this.state.inputOptionRequireSignature,
    };

    if(this.state.inputOptionParcelGuard && parseFloat(this.state.inputOptionParcelGuardAmount)) {

      if(parseFloat(this.state.inputOptionParcelGuardAmount) > this.subTotal) {
        this.setState({ inputOptionParcelGuardAmount: normalizePrice(this.subTotal) }, () => {
          if(this.state.errorParcelGuardAmount) {
            this.validateParcelGuardAmount();
          }
        });
      }

      ratesReq["parcel_guard_amount"] = Math.round(parseFloat(this.state.inputOptionParcelGuardAmount) * getCurrencyMinorCount());
    }

    const ratesResp = await this.props.storeIntegrationStampsRates(ratesReq, this.props.order.publicUuid, controller.signal)
      .catch((errResp) => {
        this.setState({
          ratesPending: false,
          ratesError: errResp && errResp.error ? errResp.error : ERR_5500,
        });
      });

    if(!ratesResp) {
      return null;
    }

    const selectedRate = this.isSelectedRatePresent(ratesResp) ? this.state.inputRate : '';

    this.setState({ 
      inputRate: selectedRate,
      ratesResp: ratesResp, 
      ratesPending: false,
      ratesError: null,
    }, () => {
      this.props.allowConfirm(!!selectedRate);
    });
  }

  async saveAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controllerSubmit) {
        this.controllerSubmit.abort();
      }
      const controller = new AbortController();
      this.controllerSubmit = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      // Create label and add shipment to order
      const newLabelReq = {
        parcel_type: this.state.inputParcelType,
        service_type: this.state.inputRate.serviceType,
        opt_sign_req: this.state.inputOptionRequireSignature,
      };

      if(this.state.inputOptionParcelGuard && parseFloat(this.state.inputOptionParcelGuardAmount)) {

        if(parseFloat(this.state.inputOptionParcelGuardAmount) > this.subTotal) {
          this.setState({ inputOptionParcelGuardAmount: normalizePrice(this.subTotal) }, () => {
            if(this.state.errorParcelGuardAmount) {
              this.validateParcelGuardAmount();
            }
          });
        }

        newLabelReq["parcel_guard_amount"] = Math.round(parseFloat(this.state.inputOptionParcelGuardAmount) * getCurrencyMinorCount());
      }

      const labelResp = await this.props.storeIntegrationStampsCreateLabel(newLabelReq, this.props.order.publicUuid, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: errResp && errResp.error ? formatServerError(errResp.error) : ERR_5500,
          });
          this.props.completeAction();
        });

      if(!labelResp) {
        this.props.completeAction();
        return null;
      }

      // Print the label (or open in new tab)
      labelResp.print();

      const statusData = {
        status: ORDER_STATUS_SHIPPED.key,
        reason: this.props.t(tx.TX_ORDER_HISTORY_SHIPMENT),
      };

      const statusResp = await this.props.ordersUpdateStatus(statusData, this.props.order.publicUuid)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!statusResp) {
        this.props.completeAction();
        return null;
      }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();
      
    } else {
      this.props.completeAction();
    }
  }

  getSavedParcelOptions() {
    const respArray = [];

    // Get saved parcels

    return respArray;
  }

  hasSavedParcels() {
    return false;
  }

  changeParcelType(evt) {
    this.setState({ inputParcelType: evt.target.value }, () => {
      this.fetchRates();
    });
  }

  getParcelTypeOptions() {
    return [
      {
        display: tx.TX_ORDER_SHIPMENT_PARCEL_TYPE_ENVELOPE,
        value: this.PARCEL_TYPE_ENVELOPE,
      },
      {
        display: tx.TX_ORDER_SHIPMENT_PARCEL_TYPE_PACKAGE,
        value: this.PARCEL_TYPE_PACKAGE,
      },
    ];
  }

  toggleOptionsOpen() {
    this.setState({ optionsOpen: !this.state.optionsOpen });
  }

  toggleRequireSignature() {
    this.setState({ inputOptionRequireSignature: !this.state.inputOptionRequireSignature }, () => {
      this.fetchRates();
    });
  }

  toggleParcelGuard() {
    this.setState({ inputOptionParcelGuard: !this.state.inputOptionParcelGuard }, () => {
      this.fetchRates();
    });
  }

  changeParcelGuardAmount(evt) {
    this.setState({
      inputOptionParcelGuardAmount: evt.target.value,
    }, () => {
      if(this.state.errorParcelGuardAmount) {
        this.validateParcelGuardAmount(false);
      }

      this.fetchRatesDebounced();
    });
  }

  validateParcelGuardAmount(shouldNormalize = true) {
    this.setState({ 
      inputOptionParcelGuardAmount: shouldNormalize ? normalizePrice(this.state.inputOptionParcelGuardAmount) : this.state.inputOptionParcelGuardAmount, 
      errorParcelGuardAmount: getGenericNumberError(this.state.inputOptionParcelGuardAmount, { min: getCurrencyIncrement(), max: this.subTotal }), 
    });
  }

  changeRate(rate) {
    this.setState({ inputRate: rate }, () => {
      this.props.allowConfirm(!!rate);
    });
  }

  isRateSelected(rate) {
    if(!rate || !this.state.inputRate) { return false; }

    return rate.carrier === this.state.inputRate.carrier && rate.serviceType === this.state.inputRate.serviceType;
  }

  isSelectedRatePresent(rateArray) {
    if(!rateArray || !this.state.inputRate) { return false; }

    for(const rt of rateArray) {
      if(rt.carrier === this.state.inputRate.carrier && rt.serviceType === this.state.inputRate.serviceType) {
        return true;
      }
    }
    return false;
  }

  orderedRates() {
    const options = this.state.ratesResp || [];

    const sortable = [];
    for(const rt of options) {
      sortable.push({ rate: rt, cost: rt.cost });
    }
    const sortedWithHelpers = sortable.sort(dynamicSort('cost'))

    const sorted = [];
    for(const rt of sortedWithHelpers) {
      sorted.push(rt.rate);
    }

    return sorted;
  }

  getCustomerCost() {
    return this.props.order && this.props.order.totalShipping ? this.props.order.totalShipping : 0;
  }

  isParcelGuardEnabled() {

    const integration = this.props.order && this.props.order.shippingMethod && this.props.order.shippingMethod.integration ? this.props.order.shippingMethod.integration : null;
    if(!integration) {
      return false;
    }

    const config = integration.config[STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED];
    if(config && config.value) {
      return true;
    }
    return false;
  }

  shouldShowRate(rate) {
    if(!rate) { return false; }

    if(rate.serviceType === 'usps_first_class_mail') {
      if(this.state.inputOptionRequireSignature || this.state.inputOptionParcelGuard) {
        return false;
      }
    }

    if(rate.serviceType === 'usps_pay_on_use_return') {
      return false;
    }

    return true;
  }

  defaultShipping() {
    this.props.allowConfirm(true);
    this.props.toggleShippingDefault();
  }

  render() {

    const {t} = this.props;

    return <div className={'CreateShipmentStamps OrderModalView'}>
      <div className='omvWrapper'>
        
        <div className={'orderModalCopy centerCopy'}>{t(tx.TX_ORDER_SHIPMENT_PROMPT_STAMPS)}</div>

        <div className='orHr'>
          <div className='orHrLiner'>
            <div className='orHrBackground'></div>
            <div className='orHrValue'>{t(tx.TX_OR)}</div>
          </div>
        </div>

        <div className={'orderModalCopy centerCopy'}>
          <div className='copyAction' onClick={this.defaultShipping.bind(this)}>{t(tx.TX_ORDER_SHIPMENT_PROMPT_STAMPS_ALTERNATE)}</div>
        </div>

        <form 
          className={'shipmentCreateFormStamps orderModalForm'}
          onSubmit={this.saveAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          {this.hasSavedParcels() ?
            <div className='omFieldWrapper'>
              <div className={'omFieldLabel omRequired'}>{t(tx.TX_SETTINGS_INTEGRATIONS_PARCEL)}</div>
              <div className='omInputWrapper'>
                <div className='omDropdownWrapper'>
                  <Dropdown 
                    className={'omDropdownSelect'}
                    options={this.getSavedParcelOptions()}
                    name={t(tx.TX_SETTINGS_INTEGRATIONS_PARCEL)}
                    value={this.state.inputParcelType}
                    required={true}
                    onChange={this.changeParcelType.bind(this)} />
                </div>
                {this.state.errorParcelType ?
                  <div className={'FieldError'}>{t(this.state.errorParcelType)}</div> :
                  null
                }
              </div>
            </div> :
            null
          }

          {!this.hasSavedParcels() ?
            <div className='omFieldWrapper'>
              <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_SHIPMENT_PARCEL_TYPE)}</div>
              <div className='omInputWrapper'>
                <div className='omDropdownWrapper'>
                  <Dropdown 
                    className={'omDropdownSelect'}
                    options={this.getParcelTypeOptions()}
                    name={t(tx.TX_ORDER_SHIPMENT_PARCEL_TYPE)}
                    value={this.state.inputParcelType}
                    required={true}
                    disabled={this.state.requestPending}
                    onChange={this.changeParcelType.bind(this)} />
                </div>
                {this.state.errorParcelType ?
                  <div className={'FieldError'}>{t(this.state.errorParcelType)}</div> :
                  null
                }
              </div>
            </div> :
            null
          }

          <div className='optionsWrapper'>
            <div className={`optionsTitleWrapper${this.state.optionsOpen ? ' open' : ''}`} onClick={this.toggleOptionsOpen.bind(this)}>
              <div className='optionsTitleIcon'>
                <Icon value={ICON_CHEVRON_DOWN} />
              </div>
              <div className='optionsTitleValue'>{t(tx.TX_ORDER_SHIPMENT_OPTIONS)}</div>
            </div>
            <div className={`optionsBodyWrapper${this.state.optionsOpen ? ' open' : ''}`}>
              <div className='optionLineWrapper'>
                <div className='optionInputWrapper'>
                  <Checkbox
                    id={`option-require-signature`}
                    className='cbInput'
                    name={t(tx.TX_ORDER_SHIPMENT_OPTION_REQUIRE_SIGNATURE)}
                    adminTheme={true}
                    disabled={this.state.requestPending}
                    value={'sign'}
                    checked={this.state.inputOptionRequireSignature}
                    onChange={this.toggleRequireSignature.bind(this)} />
                </div>
                <label htmlFor={`option-require-signature`}>
                  <div className='optionLabelWrapper'>
                    <div className='optionValue'>{t(tx.TX_ORDER_SHIPMENT_OPTION_REQUIRE_SIGNATURE)}</div>
                  </div>
                </label>
              </div>
              <div className='optionLineWrapper'>
                <div className='optionInputWrapper'>
                  <Checkbox
                    id={`option-parcelguard`}
                    className='cbInput'
                    name={t(tx.TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD)}
                    adminTheme={true}
                    disabled={this.state.requestPending}
                    value={'parcelguard'}
                    checked={this.state.inputOptionParcelGuard}
                    onChange={this.toggleParcelGuard.bind(this)} />
                </div>
                <label htmlFor={`option-parcelguard`}>
                  <div className='optionLabelWrapper'>
                    <div className='optionValue'>{t(tx.TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD)}</div>
                  </div>
                </label>
              </div>
              <div className={`optionLineExtension ${this.state.inputOptionParcelGuard ? 'open': ''}`}>
                <div className='extensionLiner'>
                  <div className='extensionInputWrapper'>
                    <div className='extensionInputLabel'>
                      {t(tx.TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_INSURED_AMOUNT)}
                      <a href={'https://www.parcelguard.com/terms/'} target='_blank' rel='noreferrer' className='labelLink'>{t(tx.TX_TERMS)}</a>
                    </div>
                    <div className='extensionInputElementWrapper'>
                      <input
                        type='number'
                        className={`extensionInput ${this.state.errorParcelGuardAmount ? 'error' : ''}`}
                        min={getCurrencyIncrement()}
                        max={this.subTotal}
                        step={getCurrencyIncrement()}
                        placeholder={normalizePrice(this.subTotal)}
                        value={this.state.inputOptionParcelGuardAmount}
                        onChange={this.changeParcelGuardAmount.bind(this)}
                        onBlur={this.validateParcelGuardAmount.bind(this)} />
                      <div className='currencyIndication'>
                        <div className='FlexCenter'>{getCurrencySymbol()}</div>
                      </div>
                    </div>
                    {this.state.errorParcelGuardAmount ?
                      <div className='extensionError'>{t(this.state.errorParcelGuardAmount)}</div> :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='ratesBlockWrapper'>
            <div className='ratesBlock'>
              {this.state.ratesResp && this.state.ratesResp.length > 0 ?
                <div className='ratesHeaderWrapper'>
                  <div className='ratesHeader'>
                    <div className='costLabel'>{t(tx.TX_ORDER_SHIPMENT_CUSTOMER_COST)}</div>
                    <div className='costValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getCustomerCost(), { addTags: true, language: this.getLanguage() }) }} />
                  </div>
                </div> :
                null
              }
              <div className='ratesBodyWrapper'>
                {this.state.ratesResp === null ?
                  <div className='ratesBodyNull'></div> :
                  <div className='ratesBody'>
                    <div className='ratesBodyLiner'>
                      {this.state.ratesResp.length === 0 ?
                        <div className='ratesNoResults'>{t(tx.TX_ORDER_SHIPMENT_OPTIONS_NONE)}</div> :
                        <div className='ratesList'>
                          {this.orderedRates().filter(rt => this.shouldShowRate(rt)).map((rate, i) => {
                            return <div key={i} className={`rateElement ${this.isRateSelected(rate) ? 'selected' : ''}`}>
                              <div className='rateElementLiner'>
                                <div className='rateSelectionWrapper'>
                                  <div className='rateRadioWrapper'>
                                    <Radio
                                      id={`stamps-rate-${i}`}
                                      className='rateRadio'
                                      name={'stamps-rate'}
                                      adminTheme={true}
                                      disabled={false}
                                      value={`stamps-${i}`}
                                      checked={this.isRateSelected(rate)}
                                      onChange={() => this.changeRate(rate)} />
                                  </div>
                                </div>
                                <div className='rateDescriptionWrapper'>
                                  <div className='rateTitleWrapper'>
                                    <div className='rateService'>{t(rate.name)}</div>
                                  </div>
                                  {rate.estimatedDeliveryDays ?
                                    <div className='rateDurationWrapper'>
                                      <div className='rateDurationLabel'>{t(tx.TX_ORDER_SHIPMENT_ESTIMATED_DELIVERY_DAYS)}</div>
                                      <div className='rateDurationValue'>{rate.estimatedDeliveryDays}</div>
                                    </div> :
                                    null
                                  }
                                  {rate.trackable ?
                                    <div className='rateTrackingWrapper'>
                                      <div className='rateTrackingValue'>{t(tx.TX_ORDER_SHIPMENT_HAS_TRACKING)}</div>
                                    </div> :
                                    null
                                  }
                                </div>
                                <div className='ratePriceWrapper'>
                                  <div 
                                    className={`ratePrice${this.getCustomerCost() && this.getCustomerCost() < rate.cost ? ' over' : ''}`} 
                                    dangerouslySetInnerHTML={{ __html: formatPrice(rate.cost, { addTags: true, language: this.getLanguage() }) }} />
                                </div>
                              </div>
                            </div>;
                          })}
                        </div>
                      }
                    </div>
                  </div>
                }
                
                {this.state.ratesPending || this.state.requestPending ?
                  <div className='ratesLoadingScreen'>
                    <div className='ratesLoadingIconWrapper'>
                      <LoadingIcon />
                    </div>
                  </div> :
                  null
                }
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CreateShipmentStamps));

