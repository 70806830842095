import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  checkoutPrereqMet, 
  isStepComplete, 
  isStepValid, 
} from '../../../../../utils/checkout';

import CheckoutStepShippingAddressComplete from './CheckoutStepShippingAddressComplete';
import CheckoutStepShippingAddressInput from './CheckoutStepShippingAddressInput';

let allActionCreators = Object.assign({});

export class CheckoutStepShippingAddress extends Component {

  constructor(props) {
    super(props);

    this.state = {
      lastUuid: null,
    }
  }

  setNewUuid(uuidValue) {
    this.setState({ lastUuid: uuidValue });
  }

  getStepComponent() {
    
    // If pre-requisites not met, do not display
    if(checkoutPrereqMet(this.props.config, this.props.checkout.stepData) === false) {
      return null;
    }

    if(isStepValid(this.props.config, this.props.checkout.stepData) && isStepComplete(this.props.config, this.props.checkout.stepData)) {
      return <CheckoutStepShippingAddressComplete 
              config={this.props.config}
              setStepData={this.props.setStepData} />;
    }
    return <CheckoutStepShippingAddressInput 
            config={this.props.config}
            lastUuid={this.state.lastUuid}
            setStepData={this.props.setStepData}
            setUuid={this.setNewUuid.bind(this)} />;
  }

  render() {
    return <div className={'CheckoutStepShippingAddress CheckoutStepComponent'}>{this.getStepComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepShippingAddress));