
import { 
	LANG_ALL, 
	LANG_EN, 
	LANG_ES, 
} from '../constants/languages';

const LANGUAGE_KEY = 'code';

export function getLanguageObjectFromServerResp(langCode) {

	if(!langCode) {
		return null;
	}

	for(const lang of LANG_ALL) {
		if(langCode === lang[LANGUAGE_KEY]) {
			return lang;
		}
	}
	return null;
}

export function getServerValueFromLanguageObject(langObj) {
	// Same as above, but backwards
}

export function getLanguagesOrderedDeduped(languageArray) {
	
	const codesToSort = [];
	for(const lang of languageArray) {
		if(!codesToSort.includes(lang.code)) {
			codesToSort.push(lang.code);
		}
	}

	const orderedCodes = [];
	for(const lang of LANG_ALL) {
		if(codesToSort.includes(lang.code)) {
			orderedCodes.push(lang.code);
		}
	}
	
	const orderedResp = [];
	for(const code of orderedCodes) {
		for(const lang of LANG_ALL) {
			if(code === lang.code) {
				orderedResp.push(lang);
				break;
			}
		}
	}
	return orderedResp;
}

// Currently the same as getLanguageObjectFromServerResp;
// Different function since server resp could possibly change
export function getLanguageObjectFromCode(langCode, defaultResp = null) {

	if(!langCode) {
		return defaultResp;
	}

	// First, check for exact match, then repeat for a more general match
	// Figuring out how to better manage and map languages should be a priority
	for(const lang of LANG_ALL) {
		if(langCode === lang[LANGUAGE_KEY]) {
			return lang;
		}
	}

	for(const lang of LANG_ALL) {
		if(langCode.includes(lang[LANGUAGE_KEY])) {
			return lang;
		}
	}
	return defaultResp;
}

// Looks first at scryfallCode, then at code
export function getLanguageObjectFromScryfallCode(langCode, defaultResp = null) {

	const LANGUAGE_SCRYFALL_KEY = 'scryfallCode';

	if(!langCode) {
		return defaultResp;
	}

	for(const lang of LANG_ALL) {
		if(lang[LANGUAGE_SCRYFALL_KEY] && langCode === lang[LANGUAGE_SCRYFALL_KEY]) {
			return lang;
		}
		if(langCode === lang[LANGUAGE_KEY]) {
			return lang;
		}
	}
	return defaultResp;
}

export function getStoreLanguage(i18n) {

	if(!i18n || !i18n.language) { return LANG_EN; }

	const dialectMapping = {
		'oasischile': {
			[LANG_ES]: 'es-CL',
		},
	};

	const storeCode = process.env.REACT_APP_STORE_CODE;

	if(dialectMapping[storeCode] && dialectMapping[storeCode][i18n.language]) {
		return dialectMapping[storeCode][i18n.language];
	}
	return LANG_EN;
}














