import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import CartSummary from '../../Cart/CartSummary';
import MiniCart from '../../Cart/MiniCart';

import { checkoutGetTaxRateSales } from '../../../utils/checkout';

let allActionCreators = Object.assign({});

export class CheckoutSidebar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cartLoading: false,
      cartOpen: false, 
      summaryLoading: false,

      lastHeight: 0,
    };

    this.sidebarElement = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.sidebarElement && this.sidebarElement.current) {
      const measuredHeight = this.sidebarElement.current.clientHeight;
      if(measuredHeight !== this.state.lastHeight) {
        this.setState({ lastHeight: measuredHeight });
        this.props.setMinHeight(measuredHeight);
      }
    }
  }

  getCart() {
    return this.props.isBuylist ? this.props.cart.currentBuylistCart : this.props.cart.currentCart;
  }

  setCartLoading(loadingValue) {
    this.setState({ cartLoading: loadingValue });
  }

  setSummaryLoading(loadingValue) {
    this.setState({ summaryLoading: loadingValue });
  }

  toggleCartOpen() {
    this.setState({ cartOpen: !this.state.cartOpen });
  }

  render() {

    const {t} = this.props;

    return <div className={'CheckoutSidebar'} ref={this.sidebarElement}>
      <div className={'csBlock csBlockCart'}>
        <div className='csBlockTitle'>
          {t(this.props.isBuylist ? tx.TX_BUYLIST_TITLE : tx.TX_CART_SHOPPING_CART)}
          <div className='csBlockTitleRhs' onClick={this.toggleCartOpen.bind(this)}>
            {this.getCart() ?
              <div className='rhsValue'>
                {this.getCart().quantity === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: this.getCart().quantity })}
              </div> :
              null
            }
            <div className='rhsToggle'>{this.state.cartOpen ? '-' : '+'}</div>
          </div>
        </div>
        <div className={this.state.cartOpen ? 'csBlockBody open' : 'csBlockBody'}>
          <MiniCart
            isBuylist={this.props.isBuylist}
            hideDisclaimer={true}
            setParentLoading={this.setCartLoading.bind(this)} />
        </div>
      </div>
      <div className='csBlockHr'></div>
      <div className={'csBlock csBlockSummary'}>
        <div className='csBlockTitle'>
          {t(tx.TX_CART_SUMMARY_TITLE)}
        </div>
        <div className='csBlockBody'>
          <CartSummary
            cartOverride={null}
            cartLoading={this.state.cartLoading}
            isBuylist={this.props.isBuylist}
            taxRate={checkoutGetTaxRateSales(this.props.checkout.stepData)} />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutSidebar));