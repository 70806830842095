
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AccountMenu from '../components/Account/AccountMenu';

import { 
  getNeedsPasswordReset, 
  getNeedsReAuth, 
  isLoggedIn 
} from '../utils/auth';

import { STORE_MAINTENANCE_MODE } from '../constants/store';
import {
  URL_LOGIN, 
  URL_LOGIN_REAUTH, 
  URL_MAINTENANCE, 
  URL_SET_PASSWORD, 
} from '../constants/urls';

const AccountRoute = ({ component: Component, render, ...rest }) => {

  const renderContent = props => {

    if(STORE_MAINTENANCE_MODE) {
      // Use window.location to force hard refresh
      window.location.href = URL_MAINTENANCE;
    }

    if(!isLoggedIn()) {
      return (
        <Redirect
          to={{
            pathname: URL_LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }

    if(getNeedsPasswordReset()) {
      return (
        <Redirect
          to={{
            pathname: URL_SET_PASSWORD,
            state: { from: props.location }
          }}
        />
      );
    }

    if(isLoggedIn() && rest.reAuth && getNeedsReAuth()) {
      return (
        <Redirect
          to={{
            pathname: URL_LOGIN_REAUTH,
            state: { from: props.location }
          }}
        />
      );
    }

    const isBuylist = rest.isBuylist ? true : false;

    return typeof render === 'function' ? (
      render(props)
    ) : (
      <div className='accountWrapper'>
        <div className='accountWrapperMenu'>
          <AccountMenu />
        </div>
        <div className='accountWrapperComponent'>
          <Component {...props} isBuylist={isBuylist} />
        </div>
      </div>
    );
  };
  return <Route {...rest} render={renderContent} />;
};

export default AccountRoute;