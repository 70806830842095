import React from 'react';

import styles from './style/BlockComponent.module.scss';

export const BlockComponent = (props) => {
  
  // Props
  const {
    block,
  } = props;

  // Methods
  const getComponent = () => {
    const DynamicBlock = block ? block.component : null;
    return DynamicBlock ? <DynamicBlock block={block} blockStyles={styles} /> : null;
  }

  return (
    <div className={styles.BlockComponent}>
      <div className={styles.blockLiner}>{getComponent()}</div>
    </div>
  );
};

export default BlockComponent;




