import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_SEARCH } from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';

import { 
  simulateTab,
} from '../../../../utils/general';

import Checkbox from '../../../Input/Checkbox';
import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';

import * as checkoutActionCreators from '../../../../actions/checkout';
const allActionCreators = Object.assign({}, checkoutActionCreators);

export class FilterPaymentMethod extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputSearch: '',
      loading: false,
      respData: null,
    }

    this.controller = null;
  }

  async componentDidMount() {
    if(!this.state.respData) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({ loading: true });

      const methodData = await this.props.checkoutFetchAdminPaymentMethods(controller.signal)
        .catch((errResp) => {
          console.error(errResp);
        });

      if(!methodData) { return null; }

      this.setState({ 
        loading: false, 
        respData: methodData,
      });

      this.props.setCustomFilterOptions({
        [this.props.filterElement.key]: methodData,
      });

    } else {
      this.props.setCustomFilterOptions({
        [this.props.filterElement.key]: this.state.respData,
      });
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static isListElement() {
    return true;
  }

  changeSearch(evt) {
    this.setState({ inputSearch: evt.target.value });
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      simulateTab();
    }
  }

  clearInput() {
    this.setState({ inputSearch: '' });
  }

  getOptions() {

    if(!this.state.inputSearch) {
      return this.state.respData || [];
    }

    const filteredOptions = [];
    const needle = this.state.inputSearch.toLowerCase();

    for(const elem of this.state.respData) {

      const displayString = elem.name.toLowerCase();
      if(displayString.includes(needle)) {
        filteredOptions.push(elem);
      }
    }
    return filteredOptions;
  }

  getCurrentValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.key) === false) {
        return [];
      } else {
        return this.props.filters[this.props.filterElement.key].split(',');
      }
    } catch(err) {
      console.error(err);
    }
    return [];
  }

  toggleOption(evt, key) {

    if(evt) { evt.stopPropagation(); }

    const currentValue = this.getCurrentValue();
    if(currentValue.includes(key)) {
      if(currentValue.length === 1) {
        this.props.clearFilter();  
      } else {

        currentValue.splice(currentValue.indexOf(key), 1);

        this.props.setFilters({
          [this.props.filterElement.key]: currentValue.join(','),
        });
      }
    } else {

      currentValue.push(key);

      this.props.setFilters({
        [this.props.filterElement.key]: currentValue.join(','),
      });
    }
  }

  selectAll() {

    let needsUpdate = false;
    const currentValue = this.getCurrentValue();
    currentValue.push('null');
    
    for(const op of this.getOptions()) {
      if(!currentValue.includes(op.key)) {
        currentValue.push(op.key);
        needsUpdate = true;
      }
    }

    if(needsUpdate) {
      this.props.setFilters({
        [this.props.filterElement.key]: currentValue.join(','),
      });
    }
  }key

  render() {

    const {t} = this.props;

    return <div className={'FilterPaymentMethod FilterMultiselect AdminTableFilter'}>
      <div className='filterElementTitle'>
        <div className='filterElementTitleValue'>{t(this.props.filterElement.name)}</div>
        <div className='filterElementClearLink' onClick={this.props.clearFilter.bind(this)}>{t(tx.TX_CLEAR)}</div>
      </div>
      <div className='filterElementBody'>
        <div className='multiWrapper'>
          <div className='multiInputWrapper'>
            <div className='multiInput'>
              <div className='multiInputLiner'>

                {this.props.filterElement.quickLookup ?
                  <div className='multiQuickSearchInput'>
                    <input
                      type='text'
                      name={t(tx.TX_SEARCH)}
                      value={this.state.inputSearch}
                      placeholder={t(tx.TX_SEARCH)}
                      onChange={this.changeSearch.bind(this)}
                      onKeyPress={this.handleKeyPress.bind(this)} />
                    <div className='searchOverlay'>
                      <Icon value={ICON_SEARCH} iconClass='searchIcon' />
                    </div>
                    {this.state.inputSearch ?
                      <div className='clearOverlay' onClick={this.clearInput.bind(this)}>&times;</div> :
                      null 
                    }
                  </div> :
                  null
                }

                <div className={`multiElements ${this.state.loading ? 'loading' : ''} ${this.props.filterElement.quickLookup !== true ? 'noSearch' : ''}`}>
                  {this.state.loading ?
                    <div className='loadingOptions'>
                      <div className='loadingIconWrapper'>
                        <LoadingIcon />
                      </div>
                      <div className='loadingLabel'>{t(tx.TX_LOADING)}</div>
                    </div> :
                    <>
                      {this.getOptions().length === 0 ?
                        <div className='noneFound'>{t(tx.TX_NO_RESULTS)}</div> :
                        <>
                          {this.getOptions().map((elem, i) => {
                            return <div className={`elementWrapper ${this.getCurrentValue().includes(elem.key) ? 'selected' : ''}`} key={elem.key} onClick={(evt) => this.toggleOption(evt, elem.key)}>
                              <div className='elementSelectLine'>
                                <div className='elementCheckboxWrapper'>
                                  <div className='elementCheckboxLiner'>
                                    <Checkbox 
                                      adminTheme={true}
                                      name={t(elem.name)}
                                      id={`pm-elem-${elem.key}`}
                                      value={elem.key}
                                      checked={this.getCurrentValue().includes(elem.key)}
                                      onChange={() => {}} />
                                  </div>
                                </div>
                                <div className='elementDisplayWrapper'>
                                  <div className='elementDisplayLiner'>{t(elem.name)}</div>
                                </div>
                              </div>
                            </div>;
                          })}
                          <div className={`elementWrapper ${this.getCurrentValue().includes('null') ? 'selected' : ''}`} onClick={(evt) => this.toggleOption(evt, 'null')}>
                            <div className='elementSelectLine'>
                              <div className='elementCheckboxWrapper'>
                                <div className='elementCheckboxLiner'>
                                  <Checkbox 
                                    adminTheme={true}
                                    name={t(tx.TX_NONE)}
                                    id={`pm-elem-null`}
                                    value={'null'}
                                    checked={this.getCurrentValue().includes('null')}
                                    onChange={() => {}} />
                                </div>
                              </div>
                              <div className='elementDisplayWrapper'>
                                <div className='elementDisplayLiner'>{t(tx.TX_NONE)}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='multiResultsWrapper'>
            <div className='multiResults'>
              <div className='multiTitle'>
                <div className='multiTitleValue'>{t(tx.TX_SELECTED)}</div>
                <div className='multiTitleAction' onClick={this.selectAll.bind(this)}>{t(tx.TX_SELECT_ALL)}</div>
              </div>
              <div className='multiResultsBody'>
                {this.getCurrentValue().length === 0 ?
                  <div className='noneFound'>{t(tx.TX_NONE)}</div> :
                  <>
                    {this.getOptions().map((opt, j) => {
                      
                      if(!this.getCurrentValue().includes(opt.key)) {
                        return null;
                      }

                      return <div className='resultRow' key={j}>
                        <div className='removeActionWrapper' onClick={(evt) => this.toggleOption(evt, opt.key)}>
                          <div className='removeX'>&times;</div>
                        </div>
                        <div className='resultNameWrapper'>
                          <div className='resultNameValue'>{this.props.filterElement.optionsTranslate ? t(opt.name) : opt.name}</div>
                        </div>
                      </div>;
                    })}
                    {this.getCurrentValue().includes('null') ?
                      <div className='resultRow'>
                        <div className='removeActionWrapper' onClick={(evt) => this.toggleOption(evt, 'null')}>
                          <div className='removeX'>&times;</div>
                        </div>
                        <div className='resultNameWrapper'>
                          <div className='resultNameValue'>{t(tx.TX_NONE)}</div>
                        </div>
                      </div> :
                      null
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterPaymentMethod));