import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const allActionCreators = Object.assign({});

export class TermsOasis extends Component {

  constructor(props) {
    super(props);

    this.storeName = this.props.user.store.name || process.env.REACT_APP_WEBSITE_NAME;
  }

  render() {
    return <div className={'TermsOasis legalBody'}>
      <div className='legalSection'>
        <div className='sectionTitle'>Card Condition Guide</div>
        <div className='sectionP'>The condition of a card is determined by the number and extent of imperfections that a card exhibits.  Imperfections are any atypical printing or manufacturing defects, and any wear or damage to the card after that printing process.</div>
        <div className='sectionSubtitle'>Near Mint</div>
        <div className='sectionP'>Cards in Near Mint (NM) condition show minimal wear from shuffling, play or handling and can have a nearly unmarked surface, crisp corners and unblemished edges outside of a few slight flaws. A Near Mint card may have slight edge wear or a scratch or three, but overall look nearly unplayed with no major defects.</div>
        <div className='sectionP'>The acceptable range of cards within the Near Mint condition includes both cards with no imperfections and cards with a few slight imperfections.</div>
        <div className='sectionSubtitle'>Lightly Played</div>
        <div className='sectionP'>Cards in Lightly Played (LP) condition may have minor border or corner wear, scuffs or scratches. There are no major defects such as grime, bends or issues with the structural integrity of the card. Noticeable imperfections are okay, but none should be too severe or at too high a volume.</div>
        <div className='sectionP'>The acceptable range of cards within the Lightly Played condition includes both cards with few or a handful of minor imperfections.</div>
        <div className='sectionSubtitle'>Moderately Played</div>
        <div className='sectionP'>Cards in Moderately Played (MP) condition can have border wear, corner wear, scratching or scuffing, creases or whitening or any combination of moderate examples of these flaws.</div>
        <div className='sectionP'>A Moderately Played card may have some form of imperfection impacting a small area of the card from mishandling or poor storage, such as creasing that doesnʼt affect card integrity, in combination with other issues such as scratches, scuffs or border/edge wear.</div>
        <div className='sectionSubtitle'>Heavily Played</div>
        <div className='sectionP'>Cards in Heavily Played (HP) condition show a major amount of wear. Cards can show a variety of moderate imperfections along with creasing, whitening and bends. Heavily Played cards can also have flaws that impact the integrity of the card, but the card can still be sleeve playable.</div>
        <div className='sectionSubtitle'>Damaged</div>
        <div className='sectionP'>Damaged cards show wear or imperfections beyond the standards for other conditions. Cards in Damaged condition can also exhibit an imperfection that may make the card illegal for tournament play, even in a sleeve. Cards in Damaged condition may have major border wear, corner wear, scratching or scuffing, as well as folds, creases, tears or other damage that impacts the structural integrity of the card.</div>
      </div>
      <div className='legalSection'>
        <div className='sectionTitle'>Return Policy</div>
        <div className='sectionP'>You may return most new, sealed, unopened items sold and fulfilled by Oasis Games within 7 days of delivery for a full refund. Single cards purchased can be returned within 48 hours of receipt.  We'll also pay the return shipping costs if the return is a result of our error (you received an incorrect or defective item, etc.).</div>
        <div className='sectionP'>Refunds are requested after we receive and process your return. You should expect to receive your refund within four weeks of giving your package to the return shipper, however, in many cases you will receive a refund more quickly. This time period includes the transit time for us to receive your return from the shipper (5 to 10 business days), the time it takes us to process your return once we receive it (3 to 5 business days), and the time it takes your bank to process our refund request (see below for specific guidelines). We'll notify you via e-mail of your refund once we've received and processed the returned item.</div>
        <div className={'sectionP listHeader'}>If you pay by:</div>
        <ul className='sectionUL'>
          <li><span className='legalEm'>Credit Card:</span> 3-5 business days</li>
          <li><span className='legalEm'>Store Credit:</span> 1 business day</li>
        </ul>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(TermsOasis));