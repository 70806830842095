
import React from 'react';
import { Route } from 'react-router-dom';

import { isLoggedIn } from '../utils/auth';

import { STORE_MAINTENANCE_MODE } from '../constants/store';
import { 
  URL_ADMIN_DASHBOARD,
  URL_HOME, 
} from '../constants/urls';

const MaintenanceRoute = ({ component: Component, render, ...rest }) => {

  const renderContent = props => {

    if(!STORE_MAINTENANCE_MODE) {
      // Use window.location to force hard refresh
      window.location.href = URL_HOME;
    }

    if(isLoggedIn() && rest.checkLogin) {
      // Use window.location to force hard refresh
      window.location.href = URL_ADMIN_DASHBOARD;
    }

    return typeof render === 'function' ? (
      render(props)
    ) : (
      <Component {...props} />
    );
  };
  return <Route {...rest} render={renderContent} />;
};

export default MaintenanceRoute;