
import { 
	FINISHES_ALL, 
	FN_KEY_NONFOIL, 
} from '../constants/finishes';


export function getFinishObjectFromServerResp(finishKey) {

	if(!finishKey) {
		return null;
	}

	for(const fn of FINISHES_ALL) {
		if(finishKey === fn.key) {
			return fn;
		}
	}
	return null;
}


export function getFinishesOrderedDeduped(finishArray) {
	
	const keysToSort = [];
	for(const fn of finishArray) {
		if(!keysToSort.includes(fn.key)) {
			keysToSort.push(fn.key);
		}
	}

	const orderedKeys = [];
	for(const fn of FINISHES_ALL) {
		if(keysToSort.includes(fn.key)) {
			orderedKeys.push(fn.key);
		}
	}
	
	const orderedResp = [];
	for(const key of orderedKeys) {
		for(const fn of FINISHES_ALL) {
			if(key === fn.key) {
				orderedResp.push(fn);
				break;
			}
		}
	}
	return orderedResp;
}


export function getFinishObjectFromKey(finishKey) {

	if(!finishKey) {
		return null;
	}

	for(const fn of FINISHES_ALL) {
		if(finishKey === fn.key) {
			return fn;
		}
	}
	return null;
}


export function isFinishDefault(finishObj) {

	if(!finishObj) { return false; }
	
	const defaultFinishKeys = [
		FN_KEY_NONFOIL, 
	];

	return defaultFinishKeys.includes(finishObj.key);
}