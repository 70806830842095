import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_IS_BUYLIST,
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import * as tx from '../../constants/strings';
import { 
  TABLE_CONFIG_BUYLIST_CARTS, 
  TABLE_CONFIG_CARTS, 
  TABLE_SCHEMA_BUYLIST_CARTS, 
  TABLE_SCHEMA_CARTS, 
  TC_PAGINATION_CONFIGS, 
  TC_DEFAULT_PAGE_SIZE, 
} from '../../constants/tables';
import { URL_ADMIN_ORDERS } from '../../constants/urls';

import { 
  getPageLimit, 
  getPageOffset,
} from '../../utils/request';

import { AdminTable } from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';

import * as cartActionCreators from '../../actions/cart';
let allActionCreators = Object.assign({}, cartActionCreators);

export class OrdersCarts extends Component {

  constructor(props) {
    super(props);

    this.isBuylist = !!this.props.isBuylist;

    this.state = {
      cartData: [],
      cartPending: true,
      cartCount: 0,

      cartPage: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.getCartPage(this.state.cartPage);
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getCartPage(pageNum = 1, pageSize = TABLE_CONFIG_CARTS[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      cartData: [],
      cartPending: true,
      cartCount: 0,
    });

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
      [API_KEY_IS_BUYLIST]: this.isBuylist,
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    this.props.cartAdminFetchPage(getParams, controller.signal)
    .then((resp) => {
      this.setState({
        cartData: resp.data,
        cartPending: false,
        cartCount: resp.count,
      });
    })
    .catch((errResp) => {
      if(controller.signal.aborted) {
        // Request aborted; do nothing
      } else if(errResp) {
        console.error(errResp);
        this.setState({
          cartData: [],
          cartPending: false,
          cartCount: 0,
        });
      }
    });
  }

  render() {
    return <div className={'OrdersCarts AdminPage'}>
      <AdminTitle
        title={this.isBuylist ? tx.TX_BUYLIST_CARTS : tx.TX_ORDER_CARTS}
        breadcrumbs={[
          {
            url: URL_ADMIN_ORDERS,
            title: tx.TX_ORDERS,
          },
        ]} />
      <div className='adminBody'>
        <AdminTable 
          config={this.isBuylist ? TABLE_CONFIG_BUYLIST_CARTS : TABLE_CONFIG_CARTS}
          schema={this.isBuylist ? TABLE_SCHEMA_BUYLIST_CARTS : TABLE_SCHEMA_CARTS}
          data={this.state.cartData}
          dataCount={this.state.cartCount} 
          dataLoading={this.state.cartPending}
          callApi={this.getCartPage.bind(this)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrdersCarts));