import React, { Component } from "react";
import { connect } from "react-redux";

import { CSS_TOGGLE_DURATION } from '../../constants/css';

import './style/_screen.scss';

let allActionCreators = Object.assign({});

export class MenuScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,
    };

    // Bind event listeners
    this.monitorWidth = this.monitorWidth.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.monitorWidth, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.monitorWidth, false);
    document.body.style.overflow = 'visible';
  }

  componentDidUpdate(prevProps, prevState) {

    // let shouldBeEnabled = false;

    // // if(this.props.header.headerUserMenuOpen && window.innerWidth <= CSS_TABLET_BREAK) {
    // if(this.props.header.headerMenuScreenActive || this.props.cart.minicartMenuScreenActive) {
    //   shouldBeEnabled = true;
    // }

    const shouldBeEnabled = this.shouldEnable();

    // Check cart open
    // To be implemented when cart droor implemented
    // Also, make sure it works with monitor width

    if(shouldBeEnabled === true && this.state.enabled === false) {
      this.enableScreen();
    } else if(shouldBeEnabled === false && this.state.enabled === true) {
      this.disableScreen();
    }
  }

  monitorWidth() {

    // // Check for menus that use the screen on all widths:
    // if(this.props.cart.minicartOpen) {
    //   return;
    // }

    // if(this.props.header.headerUserMenuOpen && window.innerWidth <= CSS_TABLET_BREAK && this.state.enabled === false) {
    //   this.enableScreen();
    // } else if(this.props.header.headerUserMenuOpen && window.innerWidth > CSS_TABLET_BREAK && this.state.enabled === true) {
    //   this.disableScreen();
    // }
  }

  shouldEnable() {
    return this.props.header.headerMenuScreenActive || this.props.cart.minicartMenuScreenActive;
  }

  enableScreen() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, CSS_TOGGLE_DURATION / 4);
      document.body.style.overflow = 'hidden';
    });
  }

  disableScreen() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          shouldDisplay: false,
        }, () => {
          if(this.shouldEnable()) {
            this.enableScreen();
          }
        });
      }, CSS_TOGGLE_DURATION);
    });
  }

  getScreenClass() {
    let screenClass = 'MenuScreen';

    if(this.state.hasOpacity) {
      screenClass += ' withOp';
    }

    if(this.state.shouldDisplay) {
      screenClass += ' disp';
    }

    return screenClass;
  }

  render() {
    return <div className={this.getScreenClass()}></div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    header: state.header,
  };
}

export default connect(
  mapStateToProps,
  allActionCreators
)(MenuScreen);