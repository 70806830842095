import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import AddProductMediaGeneric from './AddProductMediaGeneric';
import { LoadingIcon } from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class AddProductStep3 extends Component {

  getMediaForm() {

    if(!this.props.productObj || !this.props.productObj.productLine) {
      return null;
    }

    if(this.props.productObj.productLine.isManaged === false || this.props.genericOverride) {
      return <AddProductMediaGeneric
               productLine={this.props.productObj.productLine} 
               makeDirty={this.props.makeDirty.bind(this)}
               movePrev={this.props.movePrev}
               mediaArray={this.props.mediaArray}
               setMedia={this.props.setMedia}
               saveProduct={this.props.saveProduct}
               validationSignal={this.props.validationSignal} />;
    }
    return null;
  }

  render() {

    const {t} = this.props;

    return <div className={'AddProductStep3'}>
      <div className='adminForm'>
        <div className='adminFormTitle'>
          <div className='afTitleWrapper'>{t(tx.TX_INV_ADD_PRODUCT_STEP_3)}</div>
        </div>
        <div className={this.props.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.props.requestError)}</div>
        <div className='ap3Body'>
          {this.getMediaForm()}
        </div>
        <div className='adminFormPending' style={{display: this.props.requestPending ? 'block' : 'none'}}>
          <div className='adminFormPendingScreen'></div>
          <div className='adminFormPendingWrapper'>
            <LoadingIcon />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductStep3));