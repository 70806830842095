import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../../constants/strings';

import { getStoreLanguage } from '../../../../utils/language';

import ProductTagManager from '../tag-manager/ProductTagManager';

const allActionCreators = Object.assign({});

export class ProductModalTagManager extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestError: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
    if(!_.isEqual(prevState, this.state)) {
      this.props.didUpdate();
    }
  }

  static confirmLabel() {
    return tx.TX_DONE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async submitAction(evt) {
    if(evt) { evt.preventDefault(); }

    // Do nothing except close; all interactions happen in ProductTagManager
    // Not connected to a form submit since there is nothing to submit
    // We can add an "isDirty" check if necessary here coming from ProductTagManager

    this.props.closeMethod();
    this.props.completeAction();
  }

  setRequestError(err) {
    this.setState({ requestError: err });
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductModalTagManager ProductModalView'}>
      <div className='pmvWrapper'>
        <div className={'tagManagerWrapper productModalForm'}>

          <div className={this.state.requestError ? 'pmFormError present' : 'pmFormError'}>{t(this.state.requestError)}</div>

          <ProductTagManager
            product={this.props.product}
            productPending={this.props.productPending}
            makeClean={this.props.makeClean}
            setRequestError={this.setRequestError.bind(this)} />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductModalTagManager));
