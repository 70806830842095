import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERROR_PAYMENT_STORE_CREDIT_MAX } from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
  priceFloatToInt,
} from '../../../../utils/currency';
import { 
  getDescriptionError, 
  getGenericNumberError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { 
  formatPrice,
  formatServerError,
  normalizePrice,
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import Toggle from '../../../Input/Toggle';

import '../../style/_vieworder.scss';

import * as orderActionCreators from '../../../../actions/order';
import * as paymentsActionCreators from '../../../../actions/payments';
const allActionCreators = Object.assign({}, orderActionCreators, paymentsActionCreators);

export class OrderModalBuylistPaymentCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputStoreCredit: false,
      inputStoreCreditAmount: '',
      inputPayNote: '',
      
      errorStoreCreditAmount: '', 
      errorPayNote: '',

      requestPending: false,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_CREATE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  toggleStoreCredit(evt) {
    this.setState({ inputStoreCredit: !this.state.inputStoreCredit });
  }

  changePayNote(evt) {
    this.setState({
      inputPayNote: evt.target.value,
    }, () => {
      if(this.state.errorPayNote) {
        this.validateNote();
      }
    });
  }

  validatePayNote() {
    this.setState({ errorPayNote: getDescriptionError(this.state.inputPayNote, true) });
  }

  changeStoreCreditAmount(evt) {
    this.setState({
      inputStoreCreditAmount: evt.target.value,
    }, () => {
      if(this.state.errorStoreCreditAmount) {
        this.validateStoreCreditAmount(false);
      }
    });
  }

  validateStoreCreditAmount(normalize = true) {
    this.setState({ 
      inputStoreCreditAmount: normalize ? normalizePrice(this.state.inputStoreCreditAmount) : this.state.inputStoreCreditAmount, 
      errorStoreCreditAmount: getGenericNumberError(this.state.inputStoreCreditAmount, { max: this.maxPayment(), maxError: ERROR_PAYMENT_STORE_CREDIT_MAX }), 
    });
  }

  validateAll() {
    const errorObj = {
      errorPayNote: getDescriptionError(this.state.inputPayNote, true),
    };
    if(this.state.inputStoreCredit) {
      errorObj['errorStoreCreditAmount'] = getGenericNumberError(this.state.inputStoreCreditAmount, { max: this.maxPayment(), maxError: ERROR_PAYMENT_STORE_CREDIT_MAX });
    }
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const storeCreditAmount = priceFloatToInt(this.props.order.paymentMethod.isStoreCredit ? this.maxPayment() : this.state.inputStoreCreditAmount);

      const paymentData = {
        order_uuid: this.props.order.publicUuid,
        note: this.state.inputPayNote,
        store_credit_amount: storeCreditAmount,
      }

      const createResp = await this.props.paymentsAddBuylistPayment(paymentData, this.props.order.paymentMethod ? this.props.order.paymentMethod.publicUuid : null, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!createResp) { return null; }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  maxPayment() {
    if(this.props.order && this.props.order.buylistCart) {
      return this.props.order.buylistCart.gradedTotal;
    }
    return this.props.order ? this.props.order.totalGrand : 0;
  }

  getPaymentAmount() {
    if(!this.state.inputStoreCredit || !this.state.inputStoreCreditAmount) {
      return this.maxPayment();
    }

    if(this.props.order && this.props.order.buylistCart) {
      return Math.max(this.maxPayment()  - parseFloat(this.state.inputStoreCreditAmount), 0);
    }
    return this.maxPayment();
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalBuylistPaymentCreate OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'paymentCreateForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          {this.props.order && this.props.order.paymentMethod ?
            <>
              <div className='paymentHeadlineWrapper'>
                <div className='headlineLabel'>{t(tx.TX_PAYMENT_METHOD)}</div>
                <div className='headlineValue'>{t(this.props.order.paymentMethod.nameBuylist || this.props.order.paymentMethod.name)}</div>
              </div>
              <div className='paymentSubheadlineWrapper'>
                <div className='headlineLabel'>{t(tx.TX_ORDER_PAYMENT_AMOUNT)}</div>
                <div 
                  className='headlineValue'
                  dangerouslySetInnerHTML={{ __html: formatPrice(this.getPaymentAmount(), { addTags: true }) }} />
              </div>
              <div className={`paymentSubheadlineWrapper ${!this.state.inputStoreCredit ? 'hidden' : ''}`}>
                <div className='headlineLabel'>{t(tx.TX_ORDER_STORE_CREDIT)}</div>
                <div 
                  className='headlineValue'
                  dangerouslySetInnerHTML={{ __html: formatPrice(Math.min(parseFloat(this.state.inputStoreCreditAmount), this.maxPayment()) || 0, { addTags: true }) }} />
              </div>
            </> :
            null
          }

          {this.props.order && this.props.order.paymentMethod && !this.props.order.paymentMethod.isStoreCredit ?
            <>
              <div className={'omFieldWrapper halfWidth'}>
                <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_STORE_CREDIT_APPLY_QUESTION)}</div>
                
                <div className='omInputWrapper'>
                  <div className='omInputToggleWrapper'>
                    <Toggle
                      checked={this.state.inputStoreCredit}
                      onToggle={this.toggleStoreCredit.bind(this)}
                      trueValue={tx.TX_YES}
                      falseValue={tx.TX_NO} />
                  </div>
                </div>
              </div>

              <div className={`omFieldWrapper halfWidth ${!this.state.inputStoreCredit ? 'hidden' : ''}`}>
                <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_STORE_CREDIT_AMOUNT)}</div>
                
                <div className='omInputWrapper'>
                  <input
                    type='number'
                    className={`summaryInput currency ${this.state.errorStoreCreditAmount ? 'InputError' : ''}`}
                    min={0}
                    max={this.maxPayment()}
                    step={getCurrencyIncrement()}
                    value={this.state.inputStoreCreditAmount}
                    onChange={this.changeStoreCreditAmount.bind(this)}
                    onBlur={this.validateStoreCreditAmount.bind(this)}
                    placeholder={t(tx.TX_PLACEHOLDER_PRICE)} />
                  <div className={'currencyOverlay FlexCenter'}>{getCurrencySymbol()}</div>
                </div>
                {this.state.errorStoreCreditAmount ?
                  <div className={'FieldError'}>{t(this.state.errorStoreCreditAmount)}</div> :
                  null
                }
              </div>
            </> :
            null
          }

          <div className={'omFieldWrapper'}>
            <div className={'omFieldLabel omOptional'}>{t(tx.TX_ORDER_ADD_PAYMENT_NOTE)}</div>
            <div className='omInputWrapper'>
              <textarea
                type='text'
                className={this.state.errorPayNote ? 'InputError' : ''}
                value={this.state.inputPayNote}
                onChange={this.changePayNote.bind(this)}
                onBlur={this.validatePayNote.bind(this)}
                placeholder={t(tx.TX_OPTIONAL)}
                maxLength={190}></textarea>
            </div>
            {this.state.errorPayNote ?
              <div className={'FieldError'}>{t(this.state.errorPayNote)}</div> :
              null
            }
          </div>

        </form>
        
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalBuylistPaymentCreate));