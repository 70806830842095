import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
} from '../../constants/api';
import { ICON_EMPTY } from '../../constants/icons';
import * as tx from '../../constants/strings';
import { URL_BUY_GENERAL } from '../../constants/urls';

import { 
  formatServerError,
  formatPrice, 
} from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';
import { 
  getPageLimit, 
  getPageOffset, 
} from '../../utils/request';
import { 
  getPageSizeOptions, 
} from '../../utils/table';

import AccountTitle from './blocks/AccountTitle';
import AccountStoreCreditLineItem from './blocks/AccountStoreCreditLineItem';
import { Icon } from '../Icons/Icon';
import LoadingIcon from '../Icons/LoadingIcon';

import './style/_account.scss';

import * as commonActionCreators from '../../actions/common';
import * as userActionCreators from '../../actions/user';
const allActionCreators = Object.assign({}, commonActionCreators, userActionCreators);

export class AccountStoreCredit extends Component {

  constructor(props) {
    super(props);

    this.defaultPageSize = 10;

    this.state = {
      
      requestPending: false,
      requestError: null,

      creditHistory: null,
      creditHistoryCount: null,

      pageSize: this.defaultPageSize,
      pageNum: 1,
    };

    this.controller = null;
  }

  componentDidMount() {
    this.fetchMyHistory();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isBuylist !== this.props.isBuylist) {
      this.setState({
        pageNum: 1,
      }, () => {
        this.fetchMyHistory();
      });
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  async fetchMyHistory() {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      requestPending: true,
      requestError: null,
      pageSize: this.state.pageSize,
      pageNum: this.state.pageNum,
    });

    const filters = {};

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(this.state.pageNum, this.state.pageSize),
      [API_KEY_OFFSET]: getPageOffset(this.state.pageNum, this.state.pageSize),
    };

    const getParams = Object.assign({}, filters, controlParams);

    const historyResp = await this.props.userStoreCreditHistory(getParams, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) { return null; }
        if(errResp) { console.error(errResp); }

        this.setState({
          requestPending: false,
          requestError: formatServerError(errResp),
        });
      });

    this.setState({
      requestPending: false,
      requestError: null,
    });

    if(!historyResp) {
      return null;
    }

    this.setState({
      creditHistory: historyResp.data,
      creditHistoryCount: parseInt(historyResp.count),
    });
  }

  getTotalPages() {
    if(!this.state.creditHistoryCount) {
      return 0;
    }
    return Math.ceil(parseInt(this.state.creditHistoryCount) / parseInt(this.state.pageSize));
  }

  canGoBack() {
    return this.state.pageNum > 1;
  }

  canGoForward() {
    return this.getTotalPages() > this.state.pageNum;
  }

  goBackOne() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(parseInt(this.state.pageNum - 1));
  }

  goBackAll() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(1);
  }

  goForwardOne() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.state.pageNum + 1));
  }

  goForwardAll() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.getTotalPages()));
  }

  changePageSize(evt) {
    this.setState({
      pageSize: evt.target.value,
      pageNum: 1,
    }, () => {
      this.fetchMyHistory();
    });
  }

  changePageNum(pageNum) {
    this.setState({
      pageNum: pageNum,
    }, () => {
      this.fetchMyHistory();
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'AccountStoreCredit AccountPage'}>
      <AccountTitle
        title={tx.TX_MENU_ITEM_STORECREDIT}
        breadcrumbs={[]} />
      <div className='accountBody'>

        <div className={'accountBlock totalBlock'}>
          <div className='accountBlockLiner'>
            <div className='accountBlockTitle'>{t(tx.TX_TOTAL)}</div>
            <div className='accountBlockBody'>
              <div className='accountTotalWrapper'>
                {this.props.user.user && this.props.user.user.storeCredit ?
                  <>
                    <div className='scLabel'>{t(tx.TX_MENU_ITEM_STORECREDIT)}:</div>
                    <div className='scValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.props.user.user.storeCredit.balance, { addTags: true, language: this.getLanguage() }) }} />
                  </> :
                  <div className='scLabel'>{t(tx.TX_LOADING)}</div>
                }
                
              </div>
              <div className='accountActionWrapper'>
                {this.props.user.user && this.props.user.user.storeCredit ?
                  <Link to={URL_BUY_GENERAL}>
                    <div className='totalAction'>{t(tx.TX_SHOP_NOW)}</div>
                  </Link> :
                  null
                }
              </div>
            </div>
          </div>
        </div>

        <div className={'accountBlock historyBlock'}>
          <div className='accountBlockLiner'>
            <div className='accountBlockTitle'>{t(tx.TX_ACCOUNT_STORE_CREDIT_HISTORY)}</div>
            <div className='accountBlockBody'>
              {this.state.creditHistory !== null ?
                <div className='historyListWrapper'>
                  {this.state.creditHistory.length === 0 ?
                    <div className='historyListEmpty'>
                      <div className='historyEmptyIconWrapper'>
                        <Icon 
                          value={ICON_EMPTY} 
                          iconClass='emptyIcon' />
                      </div>
                      <div className='historyEmptyLabel'>{t(tx.TX_CUSTOMER_NO_STORE_CREDIT_TITLE)}</div>
                    </div> :
                    <div className='historyList'>
                      <div className='historyHeader'>
                        <div className={'dateHeader headerCell'}>
                          <div className='FlexCenterLeft'>{t(tx.TX_DATE)}</div>
                        </div>
                        <div className={'descriptionHeader headerCell'}>
                          <div className='FlexCenterLeft'>{t(tx.TX_DESCRIPTION)}</div>
                        </div>
                        <div className={'changeHeader headerCell'}>
                          <div className='changeHeaderValue FlexCenterRight'>{t(tx.TX_CHANGE)}</div>
                        </div>
                        <div className={'balanceHeader headerCell'}>
                          <div className='balanceHeaderValue FlexCenterRight'>{t(tx.TX_BALANCE)}</div>
                        </div>
                      </div>
                      {this.state.creditHistory.map((entry, i) => {
                        return <div className='historyListItem' key={entry.publicUuid || i}>
                          <AccountStoreCreditLineItem
                            entry={entry} />
                        </div>
                      })}
                    </div>
                  }
                </div> :
                null
              }
            </div>
          </div>

          <div className='accountBlockPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
            <div className='accountBlockPendingScreen'></div>
            <div className='accountBlockPendingWrapper'>
              <LoadingIcon />
            </div>
          </div>
        </div>

        <div className='accountPagination'>
          <div className='atpControls'>
            <div className={this.canGoBack() ? 'lhsControls' : 'lhsControls disabled'}>
              <div className={'backAll'} onClick={this.goBackAll.bind(this)}>
                <div className='leftArrow'></div>
              </div>
              <div className={'backOne'} onClick={this.goBackOne.bind(this)}>
                <div className='leftArrow'></div>
              </div>
            </div>
            {parseInt(this.getTotalPages()) ?
              <div className='currentPage' dangerouslySetInnerHTML={
                {__html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, {
                  current: parseInt(this.state.pageNum).toLocaleString(this.getLanguage()), 
                  total: parseInt(this.getTotalPages()).toLocaleString(this.getLanguage()), 
                  interpolation: {escapeValue: false}
                })}} /> :
              <div className={'currentPage loadingPage'}>{t(tx.TX_LOADING)}</div>
            }
            <div className={this.canGoForward() ? 'rhsControls' : 'rhsControls disabled'}>
              <div className={'forwardOne'} onClick={this.goForwardOne.bind(this)}>
                <div className='rightArrow'></div>
              </div>
              <div className={'forwardAll'} onClick={this.goForwardAll.bind(this)}>
                <div className='rightArrow'></div>
              </div>
            </div>
          </div>
          <div className='atpSize'>
            <div className='atpSizeCopy'>{t(tx.TX_ADMIN_TABLE_RESULTS_PER_PAGE)}</div>
            <div className='atpSizeDropdown'>
              <select value={this.state.pageSize} onChange={this.changePageSize.bind(this)}>
                {getPageSizeOptions().map((size, i) => {
                  return <option key={i} value={size}>{size}</option>;
                })}
              </select>
            </div>
          </div>
        </div>

      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AccountStoreCredit));