import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

let allActionCreators = Object.assign({});

export class AddressElement extends Component {

	render() {
    return <div className={'AddressElement'}>
    	<div 
        className='aeWrapper'
        dangerouslySetInnerHTML={{__html: this.props.dataValue ? this.props.dataValue.format({ addTags: true, lineClass: 'aeLine' }) : ''}} />
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddressElement));


