import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { OrderMessage } from '../../../../models/orders';

import { 
  getMessageError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import '../../style/_vieworder.scss';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class OrderModalMessageCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputMessage: '',
      
      errorMessage: '',

      requestPending: false,
      requestError: null,
    };

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.submitAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_SEND;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  changeMessage(evt) {
    this.setState({
      inputMessage: evt.target.value,
    }, () => {
      if(this.state.errorMessage) {
        this.validateMessage();
      }
    });
  }

  validateMessage() {
    this.setState({ errorMessage: getMessageError(this.state.inputMessage) });
  }

  validateAll() {
    const errorObj = {
      errorMessage: getMessageError(this.state.inputMessage),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async submitAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const message = new OrderMessage({
        subject: null, // Not used (yet)
        messageText: this.state.inputMessage,
      });

      const sendResp = await this.props.ordersSendAdminMessage(message.getApiData(), this.props.order.publicUuid, controller.signal)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!sendResp) { return null; }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();

    } else {
      this.props.completeAction();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalMessageCreate OrderModalView'}>
      <div className='omvWrapper'>
        <form 
          className={'paymentCreateForm orderModalForm'}
          onSubmit={this.submitAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          <div className='omFieldWrapper'>
            <div className={'omFieldLabel omOptional'}>{t(tx.TX_MESSAGE)}</div>
            <div className='omInputWrapper'>
              <textarea
                type='text'
                className={this.state.errorMessage ? 'InputError tallText' : ' tallText'}
                value={this.state.inputMessage}
                onChange={this.changeMessage.bind(this)}
                onBlur={this.validateMessage.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_ORDER_MESSAGE)}
                maxLength={190}></textarea>
            </div>
            {this.state.errorMessage ?
              <div className={'FieldError'}>{t(this.state.errorMessage)}</div> :
              null
            }
          </div>
        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalMessageCreate));
