import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ERROR_BILLING_ADDRESS_SELECT } from '../../../../../constants/errors';
import * as tx from '../../../../../constants/strings';

import { 
  Address, 
  Country, 
} from '../../../../../models/geographies';

import { isLoggedIn } from '../../../../../utils/auth';
import { getCheckoutStepData } from '../../../../../utils/checkout';
import { 
  getCountryError, 
  getNameError, 
  getPhoneError,
  isFormValid, 
} from '../../../../../utils/form-validation';
import { formatServerError } from '../../../../../utils/formatting';
import { 
  getRegionKey, 
  getShippingCountries, 
  googleComponentObj,
  googleElementStreet1,
  googleElementLevel3,
  googleElementCity,
  googleElementRegionShort,
  googleElementPostCode,
  googleElementCountryShort,
} from '../../../../../utils/geography';

import { loadGooglePlaces } from '../../../../../lib/google-places';

import Dropdown from '../../../../Input/Dropdown';
import LoadingIcon from '../../../../Icons/LoadingIcon';

import * as userActionCreators from '../../../../../actions/user';
let allActionCreators = Object.assign({}, userActionCreators);

export class CheckoutStepPaymentDetailsBillingAddressInput extends Component {

  constructor(props) {
    super(props);

    this.SHIPPING_COUNTRIES = getShippingCountries();
    this.GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
    
    const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);
    if(stepData && stepData.data && stepData.data.billingAddress) {
      this.billingAddress = new Address(stepData.data.billingAddress);
    } else {
      this.billingAddress = new Address({
        countryObj: this.SHIPPING_COUNTRIES && this.SHIPPING_COUNTRIES.length === 1 ? this.SHIPPING_COUNTRIES[0] : new Country(),
      });
    }

    // Excludes savedAddressResp so not to overwrite
    this.defaultState = {

      inputFirstName: '',
      inputLastName: '',
      inputStreet1: '',
      inputStreet2: '',
      inputLevel3: '',
      inputCity: '',
      inputRegion: '',
      inputPostCode: '',
      inputTaxId: '',
      inputCountry: this.SHIPPING_COUNTRIES && this.SHIPPING_COUNTRIES.length === 1 ? this.SHIPPING_COUNTRIES[0] : new Country(),
      inputPhone: '',

      placeId: '',

      errorFirstName: '',
      errorLastName: '',
      errorStreet1: '',
      errorStreet2: '',
      errorLevel3: '', 
      errorCity: '',
      errorRegion: '',
      errorPostCode: '',
      errorTaxId: '',
      errorCountry: '',
      errorPhone: '',

      addressReqError: null,
      addressReqPending: false,

      inputAddressUuid: '',
      errorBillingAddress: '',

      savedAddressReqError: null,
      savedAddressReqPending: false,

      showAddressInput: !isLoggedIn(),
    }

    this.state = {
      
      inputFirstName: this.billingAddress.firstName,
      inputLastName: this.billingAddress.lastName,
      inputStreet1: this.billingAddress.street1,
      inputStreet2: this.billingAddress.street2,
      inputLevel3: this.billingAddress.administrativeAreaLevel3,
      inputCity: this.billingAddress.city,
      inputRegion: this.billingAddress.region,
      inputPostCode: this.billingAddress.postCode,
      inputTaxId: this.billingAddress.taxId,
      inputCountry: this.billingAddress.country,
      inputPhone: this.billingAddress.phone,
      inputSaved: this.billingAddress.isSaved,

      placeId: this.billingAddress.placeId,

      errorFirstName: '',
      errorLastName: '',
      errorStreet1: '',
      errorStreet2: '',
      errorLevel3: '', 
      errorCity: '',
      errorRegion: '',
      errorPostCode: '',
      errorTaxId: '',
      errorCountry: '',
      errorPhone: '',

      addressReqError: null,
      addressReqPending: false,

      inputAddressUuid: '',
      errorBillingAddress: '',

      savedAddressReqError: null,
      savedAddressReqPending: false,
      savedAddressResp: [],

      showAddressInput: !isLoggedIn(),
    };

    this.autocomplete = null;
    
    this.autocompleteInputRef = React.createRef();
    this.street2InputElement = React.createRef();

    this.fillInAddress = this.fillInAddress.bind(this);
  }

  componentDidMount() {

    window.onGooglePlacesScriptLoadBA = () => {
     
      if(!this.autocompleteInputRef || !this.autocompleteInputRef.current) {
        return null;
      }

      const countries = [];
      for(const country of this.SHIPPING_COUNTRIES) {
        countries.push(country.key);
      }

      this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteInputRef.current, {
        componentRestrictions: { country: countries },
        fields: ['address_components', 'geometry', 'place_id'],
        types: ['address'],
      });
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    }

    if(this.GOOGLE_PLACES_API_KEY) {
      loadGooglePlaces(this.GOOGLE_PLACES_API_KEY, window.onGooglePlacesScriptLoadBA);
    }

    if(isLoggedIn()) {
      this.getSavedAddresses();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.saveSignal !== this.props.saveSignal) {
      this.handleSave();
    }
    if(prevProps.validationSignal !== this.props.validationSignal) {
      this.validateAll();
    }
  }

  fillInAddress() {

    const place = this.autocomplete.getPlace();
    if(place.place_id) {
      this.setState({ placeId: place.place_id });
    }

    const componentObj = googleComponentObj(place.address_components, place.place_id);

    // Set address components
    this.changeAddress1(googleElementStreet1(componentObj), true);
    this.changeLevel3(googleElementLevel3(componentObj), true);
    this.changeCity(googleElementCity(componentObj), true);
    this.changeRegion(googleElementRegionShort(componentObj), true);
    this.changePostCode(googleElementPostCode(componentObj), true);
    this.changeCountry(googleElementCountryShort(componentObj), true);

    if(this.street2InputElement && this.street2InputElement.current) {
      this.street2InputElement.current.focus();
    }
  }

  async getSavedAddresses() {
    
    this.setState({
      savedAddressReqError: null,
      savedAddressReqPending: true,
    });

    const addressResp = await this.props.usersFetchSavedAddresses()
      .catch((errResp) => {
        this.setState({
          savedAddressReqPending: false,
          savedAddressReqError: formatServerError(errResp),
        });
        return null;
      });

    if(!addressResp) {
      return null;
    }

    const savedAddresses = [];
    if(addressResp.length === 0) {
      this.setState({
        showAddressInput: true,
        savedAddressReqPending: false,
      });
    } else {
      for(const address of addressResp) {
        savedAddresses.push(new Address(address));
      }
      this.setState({
        savedAddressReqPending: false,
        savedAddressResp: savedAddresses,
      });
    }
  }

  validateAll() {
    if(this.state.showAddressInput) {
      const errorObj = {
        
        errorFirstName: getNameError(this.state.inputFirstName),
        errorLastName: getNameError(this.state.inputLastName),
        errorPhone: getPhoneError(this.state.inputPhone),
        errorCountry: getCountryError(this.getCountryKey()),

        errorStreet1: this.getValidationMethod('street1')(this.state.inputStreet1, !this.getIsRequired('street1')),
        errorStreet2: this.getValidationMethod('street2')(this.state.inputStreet2, !this.getIsRequired('street2')),
        errorCity: this.getValidationMethod('city')(this.state.inputCity, !this.getIsRequired('city')),
        errorRegion: this.getValidationMethod('region')(this.state.inputRegion, !this.getIsRequired('region')),
        errorPostCode: this.getValidationMethod('postCode')(this.state.inputRegion, !this.getIsRequired('postCode')),
        errorLevel3: this.getValidationMethod('administrativeAreaLevel3')(this.state.inputLevel3, !this.getIsRequired('administrativeAreaLevel3')),
        errorTaxId: this.getValidationMethod('taxId')(this.state.inputTaxId, !this.getIsRequired('taxId')),
      };
      this.setState(errorObj);
      return isFormValid(errorObj);
    } else {
      if(this.state.inputAddressUuid === '') {
        this.setState({ errorBillingAddress: ERROR_BILLING_ADDRESS_SELECT }, () => {
          return false;
        });
      } else {
        return true;
      }
    }
  }

  changeFirstName(evt) {
    this.setState({ inputFirstName: evt.target.value }, () => {
      if(this.state.errorFirstName) {
        this.validateFirstName();
      }
    });
  }

  validateFirstName() {
    this.setState({ errorFirstName: getNameError(this.state.inputFirstName) });
  }

  changeLastName(evt) {
    this.setState({ inputLastName: evt.target.value }, () => {
      if(this.state.errorLastName) {
        this.validateLastName();
      }
    });
  }

  validateLastName() {
    this.setState({ errorLastName: getNameError(this.state.inputLastName) });
  }

  changeAddress1(evt, setDirect = false) {
    this.setState({ 
      inputStreet1: setDirect ? evt : evt.target.value, 
      placeId: setDirect ? this.state.placeId : '',
    }, () => {
      if(this.state.errorStreet1) {
        this.validateAddress1();
      }
    });
  }

  validateAddress1() {
    this.setState({ errorStreet1: this.getValidationMethod('street1')(this.state.inputStreet1, !this.getIsRequired('street1')) });
  }

  changeAddress2(evt, setDirect = false) {
    this.setState({ inputStreet2: setDirect ? evt : evt.target.value }, () => {
      if(this.state.errorStreet2) {
        this.validateAddress2();
      }
    });
  }

  validateAddress2() {
    this.setState({ errorStreet2: this.getValidationMethod('street2')(this.state.inputStreet2, !this.getIsRequired('street2')) });
  }

  changeLevel3(evt, setDirect = false) {
    this.setState({ 
      inputLevel3: setDirect ? evt : evt.target.value,
      placeId: setDirect ? this.state.placeId : '',
    }, () => {
      if(this.state.errorLevel3) {
        this.validateLevel3();
      }
    });
  }

  validateLevel3() {
    this.setState({ errorLevel3: this.getValidationMethod('administrativeAreaLevel3')(this.state.inputLevel3, !this.getIsRequired('administrativeAreaLevel3')) });
  }

  changeCity(evt, setDirect = false) {
    this.setState({ 
      inputCity: setDirect ? evt : evt.target.value,
      placeId: setDirect ? this.state.placeId : '', 
    }, () => {
      if(this.state.errorCity) {
        this.validateCity();
      }
    });
  }

  validateCity() {
    this.setState({ errorCity: this.getValidationMethod('city')(this.state.inputCity, !this.getIsRequired('city')) });
  }

  changeRegion(evt, setDirect = false) {
    this.setState({ 
      inputRegion: setDirect ? evt : evt.target.value, 
      placeId: setDirect ? this.state.placeId : '',
    }, () => {
      if(this.state.errorRegion) {
        this.validateRegion();
      }
    });
  }

  validateRegion() {
    this.setState({ errorRegion: this.getValidationMethod('region')(this.state.inputRegion, !this.getIsRequired('region')) });
  }

  checkRegion() {
    const regionList = this.state.inputCountry.regionList;
    if(regionList && regionList.length > 0) {
      for(const region of regionList) {
        if(this.state.inputRegion === getRegionKey(region)) {
          return null;
        }
      }
      this.setState({
        inputRegion: '',
        errorRegion: '',
      });
    }
    return null;
  }

  getRegionDropdownOptions() {
    
    const respArray = [];

    try {

      const regionOptions = this.getOptionsElement('region');
      if(!regionOptions || !regionOptions.length) {
        return [];
      }
      
      respArray.push({
        display: this.props.t(this.getPlaceholderElement('region')),
        value: '',
      });

      for(const regionOption of regionOptions) {
        respArray.push(regionOption);
      }
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  changePostCode(evt, setDirect = false) {
    this.setState({ 
      inputPostCode: setDirect ? evt : evt.target.value, 
      placeId: setDirect ? this.state.placeId : '',
    }, () => {
      if(this.state.errorPostCode) {
        this.validatePostCode();
      }
    });
  }

  validatePostCode() {
    this.setState({ errorPostCode: this.getValidationMethod('postCode')(this.state.inputPostCode, !this.getIsRequired('postCode')) });
  }

  changeTaxId(evt) {
    this.setState({ 
      inputTaxId: evt.target.value, 
    }, () => {
      if(this.state.errorTaxId) {
        this.validateTaxId();
      }
    });
  }

  validateTaxId() {
    this.setState({ errorTaxId: this.getValidationMethod('taxId')(this.state.inputTaxId, !this.getIsRequired('taxId')) });
  }

  changeCountry(evt, setDirect = false) {

    let countryObj = null;
    const countryKey = setDirect ? evt : evt.target.value;

    for(const country of this.SHIPPING_COUNTRIES) {
      if(countryKey === country.key) {
        countryObj = country;
        break;
      }
    }

    this.setState({ 
      inputCountry: countryObj || new Country(), 
      placeId: setDirect ? this.state.placeId : '',
    }, () => {
      this.checkRegion();
      if(this.state.errorCountry) {
        this.validateCountry();
      }
    });
  }

  validateCountry() {
    this.setState({ errorCountry: getCountryError(this.getCountryKey()) });
  }

  getCountryDropdownOptions() {

    const respArray = [];

    try {
      if(!this.SHIPPING_COUNTRIES || !this.SHIPPING_COUNTRIES.length) {
        return [];
      }
      
      respArray.push({
        display: tx.TX_PLACEHOLDER_SELECT_COUNTRY,
        value: '',
      });

      for(const country of this.SHIPPING_COUNTRIES) {
        respArray.push({
          display: country.name,
          value: country.key,
        });
      }
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  getCountryKey() {
    return this.state.inputCountry ? this.state.inputCountry.key : '';
  }

  changePhone(evt) {
    this.setState({ inputPhone: evt.target.value }, () => {
      if(this.state.errorPhone) {
        this.validatePhone();
      }
    });
  }

  validatePhone() {
    this.setState({ errorPhone: getPhoneError(this.state.inputPhone) });
  }

  async handleSave() {
    if(this.validateAll()) {

      if(this.state.showAddressInput) {

        const billingAddress = new Address({
          publicUuid: null,
          firstName: this.state.inputFirstName,
          lastName: this.state.inputLastName,
          phone: this.state.inputPhone,
          street1: this.state.inputStreet1,
          street2: this.state.inputStreet2,
          city: this.state.inputCity,
          region: this.state.inputRegion,
          postCode: this.state.inputPostCode,
          country: this.state.inputCountry,
          placeId: this.state.placeId,
          isSaved: false,
          administrativeAreaLevel3: this.state.inputLevel3,
          taxId: this.state.inputTaxId,
        });

        this.setState({
          addressReqError: null,
          addressReqPending: true,
        });

        const saveAddressResp = await this.props.usersCreateAddress(billingAddress.getApiData())
          .catch((errResp) => {
            this.setState({
              addressReqPending: false,
              addressReqError: formatServerError(errResp),
            });
            return null;
          });

        if(!saveAddressResp) {
          return null;
        }

        const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);
        const currentStepData = stepData && stepData.data ? stepData.data : {};
        const billingAddressResp = new Address(saveAddressResp);

        this.setState({
          addressReqError: null,
          addressReqPending: false,
        });

        this.props.setStepData(this.props.config, Object.assign({}, currentStepData, {
          billingAddress: billingAddressResp, 
        }));

        this.props.saveComplete(billingAddressResp);

      } else if(this.state.inputAddressUuid) {
        const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);
        if(!stepData || !stepData.data || !stepData.data.billingAddress) {
          return null;
        }
        this.props.saveComplete(stepData.data.billingAddress);
      }
    }
  }

  formatSavedAddressDropdown() {
    const addressOptions = [
      {
        display: this.props.t(tx.TX_PLACEHOLDER_BILLING_ADDRESS),
        value: '',
      },
    ];

    for(const address of this.state.savedAddressResp) {
      addressOptions.push({
        display: address.formatSingleLine(),
        value: address.publicUuid,
      });
    }

    addressOptions.push({
      display: this.props.t(tx.TX_CHECKOUT_ENTER_NEW_ADDRESS),
      value: 'new',
    });

    return addressOptions;
  }

  selectSavedAddress(evt) {

    if(!evt) { return null; }

    const uuidValue = evt.target.value;
    if(uuidValue === 'new') {

      this.setState(Object.assign({}, this.defaultState, {
        inputAddressUuid: uuidValue,
        showAddressInput: true, 
      }));
    } else {
      
      this.setState({
        inputAddressUuid: uuidValue,
        showAddressInput: false, 
      });
      
      if(uuidValue) {
        
        let selectedAddress = null;
        for(const address of this.state.savedAddressResp) {
          if(uuidValue === address.publicUuid) {
            selectedAddress = address;
            break;
          }
        }

        if(selectedAddress) {

          const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);
          const currentStepData = stepData && stepData.data ? stepData.data : {};

          this.props.setStepData(this.props.config, Object.assign({}, currentStepData, {
            billingAddress: selectedAddress, 
            editing: false,
          }));
        }
      }
    }
  }

  isSavedAddress() {
    return this.state.inputAddressUuid && this.state.inputAddressUuid !== 'new';
  }

  getBillingAddress() {
    const stepData = getCheckoutStepData(this.props.config, this.props.checkout.stepData);
    if(!stepData || !stepData.data || !stepData.data.billingAddress || !stepData.data.billingAddress.format) {
      return [];
    }
    return stepData.data.billingAddress.format();
  }

  getLabel(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).label;
  }

  getIsRequired(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).required;
  }

  getValidationMethod(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).validationMethod;
  }

  getFormattingMethod(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).formatMethod;
  }

  getDisplayElement(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).display;
  }

  getAutocompleteElement(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).autoComplete;
  }

  getPlaceholderElement(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).placeholder;
  }

  getOptionsElement(key) {
    return this.state.inputCountry.getAddressSchemaElement(key).options;
  }

  render() {

    const {t} = this.props;

    return <div className={'CheckoutStepPaymentDetailsBillingAddressInput CheckoutStepComponent'}>
      <div className='csLiner'>
        {isLoggedIn() ?
          <div className='savedAddressSection'>
            {this.state.savedAddressReqPending ?
              <div className='saLoadingWrapper'>
                <LoadingIcon iconClass='iconElement' />
              </div> :
              <>
                {this.state.savedAddressResp && this.state.savedAddressResp.length ?
                  <div className={'saControlSection checkoutStepInputForm'}>
                    <div className='checkoutFormInput'>
                      <div className='checkoutFormLabel required'>{t(tx.TX_BILLING_ADDRESS)}</div>
                      <div className={'saControlDropdownWrapper checkoutDropdownSelectWrapper'}>
                        <Dropdown 
                          className='saDropdown'
                          options={this.formatSavedAddressDropdown()}
                          name={t(tx.TX_BILLING_ADDRESS)}
                          value={this.state.inputAddressUuid}
                          noTranslate={true}
                          required={true}
                          onChange={this.selectSavedAddress.bind(this)} />
                      </div>
                      <div 
                        className={'checkoutFormError FieldError'}
                        dangerouslySetInnerHTML={{__html: this.state.errorBillingAddress ? t(this.state.errorBillingAddress) : ''}} />
                    </div>
                    {!this.state.showAddressInput ?
                      <>
                        {this.isSavedAddress() ?
                          <div className='saAddressDisplayWrapper'>
                            {this.getBillingAddress().map((line, i) => {
                              return <div key={i} className='billingAddressLine'>{line}</div>;
                            })}
                          </div> :
                          <div className='saControlPrompt'>{t(tx.TX_CHECKOUT_BILLING_ADDRESS_PROMPT)}</div> 
                        }
                      </> :
                      null
                    }
                  </div> :
                  null
                }
              </>
            }
          </div> :
          null
        }
        <div className={'checkoutStepInputForm'} style={{ display: this.state.showAddressInput ? 'block' : 'none' }}>
          <div className='checkoutFormInput'>
            <div className='checkoutFormLabel required'>{t(tx.TX_FIRST_NAME)}</div>
            <input
              type='text'
              autoComplete='given-name'
              className={this.state.errorFirstName ? 'InputError' : ''}
              value={this.state.inputFirstName}
              onChange={this.changeFirstName.bind(this)}
              onBlur={this.validateFirstName.bind(this)}
              placeholder={t(tx.TX_PLACEHOLDER_FIRST_NAME)} />
            <div 
              className={'checkoutFormError FieldError'}
              dangerouslySetInnerHTML={{__html: this.state.errorFirstName ? t(this.state.errorFirstName) : ''}} />
          </div>
          <div className='checkoutFormInput'>
            <div className='checkoutFormLabel required'>{t(tx.TX_LAST_NAME)}</div>
            <input
              type='text'
              autoComplete='family-name'
              className={this.state.errorLastName ? 'InputError' : ''}
              value={this.state.inputLastName}
              onChange={this.changeLastName.bind(this)}
              onBlur={this.validateLastName.bind(this)}
              placeholder={t(tx.TX_PLACEHOLDER_LAST_NAME)} />
            <div 
              className={'checkoutFormError FieldError'}
              dangerouslySetInnerHTML={{__html: this.state.errorLastName ? t(this.state.errorLastName) : ''}} />
          </div>
          {this.getDisplayElement('street1') ?
            <div className='checkoutFormInput'>
              <div className={`checkoutFormLabel ${this.getIsRequired('street1') ? 'required' : 'optional'}`}>{t(this.getLabel('street1'))}</div>
              <input
                ref={this.autocompleteInputRef}
                type='text'
                autoComplete={this.getAutocompleteElement('street1')}
                className={this.state.errorStreet1 ? 'InputError' : ''}
                value={this.state.inputStreet1}
                onChange={this.changeAddress1.bind(this)}
                onBlur={this.validateAddress1.bind(this)}
                placeholder={t(this.getPlaceholderElement('street1'))} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorStreet1 ? t(this.state.errorStreet1) : ''}} />
            </div> :
            null
          }
          {this.getDisplayElement('street2') ?
            <div className='checkoutFormInput'>
              <div className={`checkoutFormLabel ${this.getIsRequired('street2') ? 'required' : 'optional'}`}>{t(this.getLabel('street2'))}</div>
              <input
                ref={this.street2InputElement}
                type='text'
                autoComplete={this.getAutocompleteElement('street2')}
                className={this.state.errorStreet2 ? 'InputError' : ''}
                value={this.state.inputStreet2}
                onChange={this.changeAddress2.bind(this)}
                onBlur={this.validateAddress2.bind(this)}
                placeholder={t(this.getPlaceholderElement('street2'))} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorStreet2 ? t(this.state.errorStreet2) : ''}} />
            </div> :
            null
          }
          {this.getDisplayElement('city') ?
            <div className='checkoutFormInput'>
              <div className={`checkoutFormLabel ${this.getIsRequired('city') ? 'required' : 'optional'}`}>{t(this.getLabel('city'))}</div>
              <input
                type='text'
                autoComplete={this.getAutocompleteElement('city')}
                className={this.state.errorCity ? 'InputError' : ''}
                value={this.state.inputCity}
                onChange={this.changeCity.bind(this)}
                onBlur={this.validateCity.bind(this)}
                placeholder={t(this.getPlaceholderElement('city'))} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorCity ? t(this.state.errorCity) : ''}} />
            </div> :
            null
          }
          {this.getDisplayElement('administrativeAreaLevel3') ?
            <div className='checkoutFormInput'>
              <div className={`checkoutFormLabel ${this.getIsRequired('administrativeAreaLevel3') ? 'required' : 'optional'}`}>{t(this.getLabel('administrativeAreaLevel3'))}</div>
              <input
                type='text'
                autoComplete={this.getAutocompleteElement('administrativeAreaLevel3')}
                className={this.state.errorLevel3 ? 'InputError' : ''}
                value={this.state.inputLevel3}
                onChange={this.changeLevel3.bind(this)}
                onBlur={this.validateLevel3.bind(this)}
                placeholder={t(this.getPlaceholderElement('administrativeAreaLevel3'))} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorLevel3 ? t(this.state.errorLevel3) : ''}} />
            </div> :
            null
          }
          {this.getDisplayElement('region') ?
            <div className={`checkoutFormInput ${this.getDisplayElement('postCode') ? 'halfWidth' : ''}`}>
              <div className={`checkoutFormLabel ${this.getIsRequired('region') ? 'required' : 'optional'}`}>{t(this.getLabel('region'))}</div>
              {this.getOptionsElement('region') ?
                <div className='checkoutDropdownSelectWrapper'>
                  <Dropdown 
                    autoComplete={this.getAutocompleteElement('region')}
                    className='checkoutDropdownSelect'
                    options={this.getRegionDropdownOptions()}
                    name={t(tx.TX_ADDRESS_REGION)}
                    value={this.state.inputRegion}
                    required={true}
                    noTranslate={true}
                    onChange={this.changeRegion.bind(this)} />
                </div> :
                <input
                  type='text'
                  autoComplete={this.getAutocompleteElement('region')}
                  className={this.state.errorRegion ? 'InputError' : ''}
                  value={this.state.inputRegion}
                  onChange={this.changeRegion.bind(this)}
                  onBlur={this.validateRegion.bind(this)}
                  placeholder={t(this.getPlaceholderElement('region'))} />
              }
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorRegion ? t(this.state.errorRegion) : ''}} />
            </div> :
            null
          }
          {this.getDisplayElement('postCode') ?
            <div className={`checkoutFormInput ${this.getDisplayElement('region') ? 'halfWidth' : ''}`}>
              <div className={`checkoutFormLabel ${this.getIsRequired('postCode') ? 'required' : 'optional'}`}>{t(this.getLabel('postCode'))}</div>
              <input
                type='text'
                autoComplete={this.getAutocompleteElement('postCode')}
                className={this.state.errorPostCode ? 'InputError' : ''}
                value={this.state.inputPostCode}
                onChange={this.changePostCode.bind(this)}
                onBlur={this.validatePostCode.bind(this)}
                placeholder={t(this.getPlaceholderElement('postCode'))} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorPostCode ? t(this.state.errorPostCode) : ''}} />
            </div> :
            null
          }
          {this.getDisplayElement('taxId') ?
            <div className='checkoutFormInput'>
              <div className={`checkoutFormLabel ${this.getIsRequired('taxId') ? 'required' : 'optional'}`}>{t(this.getLabel('taxId'))}</div>
              <input
                type='text'
                autoComplete={this.getAutocompleteElement('taxId')}
                className={this.state.errorTaxId ? 'InputError' : ''}
                value={this.state.inputTaxId}
                onChange={this.changeTaxId.bind(this)}
                onBlur={this.validateTaxId.bind(this)}
                placeholder={t(this.getPlaceholderElement('taxId'))} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorTaxId ? t(this.state.errorTaxId) : ''}} />
            </div> :
            null
          }
          {this.SHIPPING_COUNTRIES && this.SHIPPING_COUNTRIES.length !== 1 ?
            <div className='checkoutFormInput'>
              <div className='checkoutFormLabel required'>{t(tx.TX_ADDRESS_COUNTRY)}</div>
              <div className='checkoutDropdownSelectWrapper'>
                <Dropdown 
                  autoComplete='country'
                  className='checkoutDropdownSelect'
                  options={this.getCountryDropdownOptions()}
                  name={t(tx.TX_ADDRESS_COUNTRY)}
                  value={this.getCountryKey()}
                  required={this.state.showAddressInput}
                  onChange={this.changeCountry.bind(this)} />
              </div>
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorCountry ? t(this.state.errorCountry) : ''}} />
            </div> :
            null
          }
          <div className='checkoutFormInput'>
            <div className='checkoutFormLabel required'>{t(tx.TX_PHONE_NUMBER)}</div>
            <input
              type='text'
              autoComplete='tel'
              className={this.state.errorPhone ? 'InputError' : ''}
              value={this.state.inputPhone}
              onChange={this.changePhone.bind(this)}
              onBlur={this.validatePhone.bind(this)}
              placeholder={t(tx.TX_PLACEHOLDER_PHONE)} />
            <div 
              className={'checkoutFormError FieldError'}
              dangerouslySetInnerHTML={{__html: this.state.errorPhone ? t(this.state.errorPhone) : ''}} />
          </div>
            
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepPaymentDetailsBillingAddressInput));