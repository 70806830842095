

import { CONDITIONS_PRODUCT_PAGE_GENERIC } from './conditions';
import {
	FV_KEY_BUY_PRICE_MIN,
	FV_KEY_BUY_PRICE_MAX,
	FV_KEY_CONDITION,
	FV_KEY_FINISH,
	FV_KEY_LANGUAGE,
	FV_KEY_LORCANA_SET,
	FV_KEY_MAGIC_COLOR,
	FV_KEY_MAGIC_RARITY,
	FV_KEY_MAGIC_SET,
	FV_KEY_ORDER_NUMBER,
	FV_KEY_ORDER_CUSTOMER_NAME,
	FV_KEY_ORDER_CUSTOMER_EMAIL,
	FV_KEY_ORDER_PRODUCT_NAME,
	FV_KEY_ORDER_DATE_MIN,
	FV_KEY_ORDER_DATE_MAX,
	FV_KEY_ORDER_STATUS,
	FV_KEY_ORDER_PAYMENT_METHOD,
	FV_KEY_ORDER_SHIPPING_METHOD,
	FV_KEY_POKEMON_SET,
	FV_KEY_PRODUCT_LINE,
	FV_KEY_TOTAL_QUANTITY_MIN,
	FV_KEY_TOTAL_QUANTITY_MAX,
	FV_KEY_SELL_PRICE_MIN,
	FV_KEY_SELL_PRICE_MAX,
	FV_KEY_STARWARS_SET,
} from './filters';
import { 
	FINISHES_LORCANA,
	FINISHES_MAGIC,
	FINISHES_POKEMON, 
	FINISHES_STARWARS,
} from './finishes';
import { 
	ICON_CHECK, 
	ICON_DETAIL, 
	ICON_EDIT, 
	ICON_VIEW, 
} from './icons';
import { LANG_ALL } from './languages';
import {
	MAGIC_COLORS,
	MAGIC_RARITIES,
} from './syncs/magic';
import { ORDER_STATUS_ALL } from './orders';
import * as p from './permissions';
import {
	PL_PERMALINK_LORCANA,
	PL_PERMALINK_MAGIC,
	PL_PERMALINK_POKEMON, 
	PL_PERMALINK_STARWARS, 
} from './product';
import * as tx from './strings';
import { 
	URL_ADMIN_CUSTOMERS_ADD, 
	URL_ADMIN_CUSTOMERS_BULK_UPLOAD_CREATE, 
	URL_ADMIN_CUSTOMERS_BULK_UPLOAD_VIEW, 
	URL_ADMIN_CUSTOMERS_VIEW, 
	URL_ADMIN_INVENTORY_ADD, 
	URL_ADMIN_INVENTORY_BULK_UPLOAD_CREATE, 
	URL_ADMIN_INVENTORY_BULK_UPLOAD_VIEW, 
	URL_ADMIN_INVENTORY_VIEW, 
	URL_ADMIN_INVENTORY_PRODUCT_LINES_ADD, 
	URL_ADMIN_INVENTORY_PRODUCT_LINES_VIEW, 
	URL_ADMIN_MARKETING_COUPONS_ADD, 
	URL_ADMIN_MARKETING_COUPONS_VIEW, 
	URL_ADMIN_ORDERS_BUYLIST_CARTS_VIEW, 
	URL_ADMIN_ORDERS_CARTS_VIEW, 
	URL_ADMIN_ORDERS_ORDER_VIEW, 
	URL_ADMIN_SETTINGS_ADMIN_USERS_ADD, 
	URL_ADMIN_SETTINGS_ADMIN_USERS_EDIT, 
	URL_ADMIN_SETTINGS_INTEGRATIONS_ADD, 
	URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW, 
	URL_ADMIN_SETTINGS_PAGES_VIEW, 
	URL_ADMIN_SETTINGS_PAYMENT_METHODS_ADD,
	URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW,
	URL_ADMIN_SETTINGS_SHIPPING_METHODS_ADD, 
	URL_ADMIN_SETTINGS_SHIPPING_METHODS_VIEW, 
} from './urls';


import BulkActionChangeSet from '../components/Admin/blocks/actions/BulkActionChangeSet';
import BulkActionCreatePullSheet from '../components/Admin/blocks/actions/BulkActionCreatePullSheet';
import BulkActionExportInventory from '../components/Admin/blocks/actions/BulkActionExportInventory';
import BulkActionExportOrders from '../components/Admin/blocks/actions/BulkActionExportOrders';
import BulkActionManageTags from '../components/Admin/blocks/actions/BulkActionManageTags';
import BulkActionOrderInvoices from '../components/Admin/blocks/actions/BulkActionOrderInvoices';
import BulkActionReadyForPickup from '../components/Admin/blocks/actions/BulkActionReadyForPickup';
import BulkActionRecordPickups from '../components/Admin/blocks/actions/BulkActionRecordPickups';
import BulkActionShipOrders from '../components/Admin/blocks/actions/BulkActionShipOrders';

import { ProductDetailRow } from '../components/Admin/blocks/custom-rows/ProductDetailRow';

import FilterDateRange from '../components/Admin/blocks/filters/FilterDateRange';
import FilterInputGeneric from '../components/Admin/blocks/filters/FilterInputGeneric';
import FilterLorcanaSet from '../components/Admin/blocks/filters/FilterLorcanaSet';
import FilterMagicSet from '../components/Admin/blocks/filters/FilterMagicSet';
import FilterMultiselect from '../components/Admin/blocks/filters/FilterMultiselect';
import FilterPaymentMethod from '../components/Admin/blocks/filters/FilterPaymentMethod';
import FilterPokemonSet from '../components/Admin/blocks/filters/FilterPokemonSet';
import FilterPriceRange from '../components/Admin/blocks/filters/FilterPriceRange';
import FilterProductLine from '../components/Admin/blocks/filters/FilterProductLine';
import FilterQuantityRange from '../components/Admin/blocks/filters/FilterQuantityRange';
import FilterShippingMethod from '../components/Admin/blocks/filters/FilterShippingMethod';
import FilterStarWarsSet from '../components/Admin/blocks/filters/FilterStarWarsSet';



// VALUE CONSTANTS

export const TABLE_VALUE_LINK_TARGET = process.env.REACT_APP_ADMIN_TABLE_LINK_TARGET || '_self';
export const TABLE_VALUE_MOBILE_SCALE = 0.8;
export const TABLE_VALUE_MOBILE_BREAKPOINT = 1024;
export const TABLE_VALUE_OBJECT_DELIMITER = '_|_';


// CONFIG CONSTANTS

// Required parameters 

// not quite up to date in terms of required or not
// in general, we should allow most attributes to be optional and default to false when enabling features

export const TC_NOUN = 'TC_NOUN';
export const TC_PAGINATE = 'TC_PAGINATE';

export const TC_CONTROLS_CONFIGS = 'TC_CONTROLS_CONFIGS';
export const TC_HEADER_CONFIGS = 'TC_HEADER_CONFIGS';
export const TC_PAGINATION_CONFIGS = 'TC_PAGINATION_CONFIGS';
export const TC_VIEWS_CONFIGS = 'TC_VIEWS_CONFIGS';

export const TC_DEFAULT_SORT_KEY = 'TC_DEFAULT_SORT_KEY';
export const TC_DEFAULT_SORT_DIR = 'TC_DEFAULT_SORT_DIR';

export const TC_CONTROLS_ADD_LINK = 'TC_CONTROLS_ADD_LINK';
export const TC_CONTROLS_ENABLE_ADD = 'TC_CONTROLS_ENABLE_ADD';
export const TC_CONTROLS_ENABLE_FILTER = 'TC_CONTROLS_ENABLE_FILTER';
export const TC_CONTROLS_ENABLE_QUICK_SEARCH = 'TC_CONTROLS_ENABLE_QUICK_SEARCH';


export const TC_VIEWS_DEFAULT = 'TC_VIEWS_DEFAULT';
export const TC_VIEWS_OPTIONS = 'TC_VIEWS_OPTIONS';
export const TC_VIEWS_OPTION_COMPONENT = 'TC_VIEWS_OPTION_COMPONENT';
export const TC_VIEWS_OPTION_ICON = 'TC_VIEWS_OPTION_ICON';
export const TC_VIEWS_OPTION_KEY = 'TC_VIEWS_OPTION_KEY';
export const TC_VIEWS_OPTION_NAME = 'TC_VIEWS_OPTION_NAME';
export const TC_VIEWS_OPTION_SELECTABLE = 'TC_VIEWS_OPTION_SELECTABLE';
export const TC_VIEWS_OPTION_SELECTABLE_WIDTH = 'TC_VIEWS_OPTION_SELECTABLE_WIDTH';
export const TC_VIEWS_OPTION_SORT = 'TC_VIEWS_OPTION_SORT';

// Optional parameters
export const TC_DEFAULT_PAGE_SIZE = 'TC_DEFAULT_PAGE_SIZE';

export const TC_CONTROLS_BULK_ACTIONS_CONFIG = 'TC_CONTROLS_BULK_ACTIONS_CONFIG';
export const TC_CONTROLS_FILTERS_DEFAULT = 'TC_CONTROLS_FILTERS_DEFAULT';
export const TC_CONTROLS_FILTERS_EXTENDED = 'TC_CONTROLS_FILTERS_EXTENDED';
export const TC_CONTROLS_FILTERS_KEY = 'TC_CONTROLS_FILTERS_KEY';
export const TC_CONTROLS_SELECTION_KEY = 'TC_CONTROLS_SELECTION_KEY';



// Defined values
export const TC_CONTROLS_BULK_ACTION_KEY_CHANGE_SET = 'TC_CONTROLS_BULK_ACTION_KEY_CHANGE_SET';
export const TC_CONTROLS_BULK_ACTION_KEY_EXPORT = 'TC_CONTROLS_BULK_ACTION_KEY_EXPORT';
export const TC_CONTROLS_BULK_ACTION_KEY_INVOICES = 'TC_CONTROLS_BULK_ACTION_KEY_INVOICES';
export const TC_CONTROLS_BULK_ACTION_KEY_MANAGE_TAGS = 'TC_CONTROLS_BULK_ACTION_KEY_MANAGE_TAGS';
export const TC_CONTROLS_BULK_ACTION_KEY_PICKUP = 'TC_CONTROLS_BULK_ACTION_KEY_PICKUP';
export const TC_CONTROLS_BULK_ACTION_KEY_PULL_SHEET = 'TC_CONTROLS_BULK_ACTION_KEY_PULL_SHEET';
export const TC_CONTROLS_BULK_ACTION_KEY_READY_FOR_PICKUP = 'TC_CONTROLS_BULK_ACTION_KEY_READY_FOR_PICKUP';
export const TC_CONTROLS_BULK_ACTION_KEY_SHIP = 'TC_CONTROLS_BULK_ACTION_KEY_SHIP';
export const TC_HEADER_SORT_DIR_ASC = 'TC_HEADER_SORT_DIR_ASC';
export const TC_HEADER_SORT_DIR_DESC = 'TC_HEADER_SORT_DIR_DESC';
export const TC_VIEW_KEY_LIST = 'TC_VIEW_KEY_LIST';
export const TC_VIEW_KEY_DETAIL = 'TC_VIEW_KEY_DETAIL';


// SCHEMA CONSTANTS

// Required parameters
export const TR_KEY = 'TR_KEY';
export const TR_LABEL = 'TR_LABEL';
export const TR_SEQUENCE = 'TR_SEQUENCE';
export const TR_TYPE = 'TR_TYPE';
export const TR_WIDTH = 'TR_WIDTH'; // In pixels
export const TR_ALIGN = 'TR_ALIGN';
export const TR_SORT_KEY = 'TR_SORT_KEY';
export const TR_SORT_NAME = 'TR_SORT_NAME';
export const TR_PRIORITY = 'TR_PRIORITY';


// Optional parameters
export const TR_ACTION_LABEL = 'TR_ACTION_LABEL';
export const TR_ACTION_LABEL_TYPE = 'TR_ACTION_LABEL_TYPE';
export const TR_ACTION_LINK = 'TR_ACTION_LINK';
export const TR_ACTION_LINK_PARAMS = 'TR_ACTION_LINK_PARAMS';
export const TR_ACTION_TYPE = 'TR_ACTION_TYPE';
export const TR_BINARY_TRUE = 'TR_BINARY_TRUE';
export const TR_BINARY_TRUE_ICON = 'TR_BINARY_TRUE_ICON';
export const TR_BINARY_FALSE = 'TR_BINARY_FALSE';
export const TR_BINARY_FALSE_ICON = 'TR_BINARY_FALSE_ICON';
export const TR_CALC_TYPE = 'TR_CALC_TYPE';
export const TR_DEFAULT_STYLE = 'TR_DEFAULT_STYLE';
export const TR_DEFAULT_TRANSLATE = 'TR_DEFAULT_TRANSLATE';
export const TR_DEFAULT_VALUE = 'TR_DEFAULT_VALUE';
export const TR_MIN_WIDTH = 'TR_MIN_WIDTH';
export const TR_MOBILE_ACTION = 'TR_MOBILE_ACTION';
export const TR_MULTIVALUED_KEYS = 'TR_MULTIVALUED_KEYS';
export const TR_NO_MOBILE = 'TR_NO_MOBILE';
export const TR_NUMERIC_STYLE = 'TR_NUMERIC_STYLE';
export const TR_QUICK_SEARCH = 'TR_QUICK_SEARCH';
export const TR_TRANSLATE = 'TR_TRANSLATE';
export const TR_TRANSLATE_FUNCTION_KEY = 'TR_TRANSLATE_FUNCTION_KEY';
export const TR_WIDTH_MOBILE = 'TR_WIDTH_MOBILE'; // In pixels
export const TR_CUSTOM_ATTR = 'TR_CUSTOM_ATTR';
export const TR_CUSTOM_ATTR_KEY = 'TR_CUSTOM_ATTR_KEY';
export const TR_CUSTOM_ATTR_VALUE = 'TR_CUSTOM_ATTR_VALUE';
export const TR_CUSTOM_ATTR_VALUE_KEY = 'TR_CUSTOM_ATTR_VALUE_KEY';


// Defined values
export const TR_VAL_ACTION_LABEL_TYPE_ICON = 'TR_VAL_ACTION_LABEL_TYPE_ICON';
export const TR_VAL_ACTION_LABEL_TYPE_TEXT = 'TR_VAL_ACTION_LABEL_TYPE_TEXT';
export const TR_VAL_ACTION_TYPE_LINK = 'TR_VAL_ACTION_TYPE_LINK';

export const TR_VAL_ALIGN_LEFT = 'TR_VAL_ALIGN_LEFT';
export const TR_VAL_ALIGN_RIGHT = 'TR_VAL_ALIGN_RIGHT';
export const TR_VAL_ALIGN_FLEX = 'TR_VAL_ALIGN_FLEX';

export const TR_VAL_CALC_TYPE_PRICE_RANGE = 'TR_VAL_CALC_TYPE_PRICE_RANGE';
export const TR_VAL_CALC_TYPE_TOTAL_INVENTORY = 'TR_VAL_CALC_TYPE_TOTAL_INVENTORY';

export const TR_VAL_NUMERIC_STYLE_PERCENT = 'TR_VAL_NUMERIC_STYLE_PERCENT';

export const TR_VAL_PRIORITY_0 = 0;  // Highest priority; never removed
export const TR_VAL_PRIORITY_1 = 1;
export const TR_VAL_PRIORITY_2 = 2;
export const TR_VAL_PRIORITY_3 = 3;
export const TR_VAL_PRIORITY_4 = 4;  // Lowest priority; first removed
export const TR_VAL_PRIORITIES = [
	TR_VAL_PRIORITY_4,
	TR_VAL_PRIORITY_3,
	TR_VAL_PRIORITY_2, 
	TR_VAL_PRIORITY_1, 
	TR_VAL_PRIORITY_0, 
];

export const TR_VAL_TYPE_ADDRESS = 'TR_VAL_TYPE_ADDRESS';
export const TR_VAL_TYPE_BINARY = 'TR_VAL_TYPE_BINARY';
export const TR_VAL_TYPE_CHECKBOX = 'TR_VAL_TYPE_CHECKBOX';
export const TR_VAL_TYPE_COMBINED_IDENTITY = 'TR_VAL_TYPE_COMBINED_IDENTITY';
export const TR_VAL_TYPE_COMPOUND_GENERIC = 'TR_VAL_TYPE_COMPOUND_GENERIC';
export const TR_VAL_TYPE_CURRENCY = 'TR_VAL_TYPE_CURRENCY';
export const TR_VAL_TYPE_DATE = 'TR_VAL_TYPE_DATE';
export const TR_VAL_TYPE_GENERIC = 'TR_VAL_TYPE_GENERIC';
export const TR_VAL_TYPE_GENERIC_CALCULATED = 'TR_VAL_TYPE_GENERIC_CALCULATED';
export const TR_VAL_TYPE_LINK = 'TR_VAL_TYPE_LINK';
export const TR_VAL_TYPE_NUMERIC = 'TR_VAL_TYPE_NUMERIC';

export const TR_VAL_WIDTH_FLEX = 'TR_VAL_WIDTH_FLEX';

export const TP_PAGE_SIZE_OPTIONS = [ 10, 25, 50, 100, 200, 400 ];






export const TABLE_CONFIG_ADMIN_USERS = {
	[TC_NOUN]: tx.TX_ADMIN,
	[TC_PAGINATE]: false,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true,
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_SETTINGS_ADMIN_USERS_ADD,
	},
};

export const TABLE_CONFIG_BULK_UPLOADS = {
	[TC_NOUN]: tx.TX_INV_BULK_UPLOAD,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'createDate',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true,
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_INVENTORY_BULK_UPLOAD_CREATE,
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 10,
	},
};

export const TABLE_CONFIG_BUYLIST_CARTS = {
	[TC_NOUN]: tx.TX_CART,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'last_update',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: false,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true, 
		[TC_CONTROLS_ADD_LINK]: null,
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 50,
	},
};

export const TABLE_CONFIG_CARTS = {
	[TC_NOUN]: tx.TX_CART,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'last_update',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: false,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true, 
		[TC_CONTROLS_ADD_LINK]: null,
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 50,
	},
};

export const TABLE_CONFIG_COUPONS = {
	[TC_NOUN]: tx.TX_COUPON,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_ASC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true, 
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_MARKETING_COUPONS_ADD,
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 50,
	},
};

export const TABLE_CONFIG_CUSTOMER_BULK_UPLOADS = {
	[TC_NOUN]: tx.TX_INV_BULK_UPLOAD,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'createDate',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true,
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_CUSTOMERS_BULK_UPLOAD_CREATE,
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 25,
	},
};

export const TABLE_CONFIG_CUSTOMERS = {
	[TC_NOUN]: tx.TX_USER,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true,
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_CUSTOMERS_ADD,
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 50,
	},
};

export const TABLE_CONFIG_INTEGRATIONS = {
	[TC_NOUN]: tx.TX_INTEGRATION,
	[TC_PAGINATE]: false,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_ASC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true, 
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_SETTINGS_INTEGRATIONS_ADD,
	},
};

export const TABLE_CONFIG_OPEN_ORDERS = {
	[TC_NOUN]: tx.TX_ORDER,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'display_number',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: false,
		[TC_CONTROLS_ENABLE_FILTER]: true,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true,
		[TC_CONTROLS_FILTERS_DEFAULT]: [
			{
				key: 'display_number',
				component: FilterInputGeneric,
				displayKey: 'name',
				name: tx.TX_ORDER_ORDER_NUMBER,
				inputPlaceholder: tx.TX_PLACEHOLDER_ORDER_NUMBER,
			},
			{
				key: 'customer_name',
				component: FilterInputGeneric,
				displayKey: 'customer_name',
				name: tx.TX_ORDER_CUSTOMER_NAME,
				inputPlaceholder: tx.TX_PLACEHOLDER_NAME,
			},
			{
				key: 'customer_email',
				component: FilterInputGeneric,
				displayKey: 'customer_email',
				name: tx.TX_ORDER_CUSTOMER_EMAIL,
				inputPlaceholder: tx.TX_PLACEHOLDER_EMAIL,
				inputType: 'email',
			},
			{
				key: 'product_name',
				component: FilterInputGeneric,
				displayKey: 'product_name',
				name: tx.TX_PLACEHOLDER_INV_NAME,
				inputPlaceholder: tx.TX_PLACEHOLDER_PRODUCT_NAME,
			},
			{
				key: 'orderDate',
				minKey: 'order_date_min',
				maxKey: 'order_date_max',
				component: FilterDateRange,
				name: tx.TX_ORDER_DATE,
			},
			{
				key: 'order_status',
				component: FilterMultiselect,
				quickLookup: true,
				name: tx.TX_STATUS,
				options: ORDER_STATUS_ALL,
				optionsTranslate: true,
				displayLookup: 'key',
				displayKey: 'name',
			},
			{
				key: 'payment_method',
				component: FilterPaymentMethod,
				quickLookup: false,
				name: tx.TX_PAYMENT_METHOD,
				optionsTranslate: true,
				displayLookup: 'key',
				displayKey: 'name',
			},
			{
				key: 'shipping_method',
				component: FilterShippingMethod,
				quickLookup: false,
				name: tx.TX_SHIPPING_METHOD,
				optionsTranslate: false,
				displayLookup: 'publicUuid',
				displayKey: 'name',
			},
		],
		[TC_CONTROLS_BULK_ACTIONS_CONFIG]: [
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_EXPORT,
				display: tx.TX_ORDER_EXPORT_ORDERS,
				component: BulkActionExportOrders,
				title: tx.TX_ORDER_EXPORT_ORDERS,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_INVOICES,
				display: tx.TX_ORDER_EXPORT_INVOICES,
				component: BulkActionOrderInvoices,
				title: tx.TX_ORDER_EXPORT_INVOICES,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_PULL_SHEET,
				display: tx.TX_ORDER_CREATE_PULL_SHEET,
				component: BulkActionCreatePullSheet,
				title: tx.TX_ORDER_CREATE_PULL_SHEET,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_SHIP,
				display: tx.TX_ORDER_CREATE_SHIPMENT,
				component: BulkActionShipOrders,
				title: tx.TX_ORDER_CREATE_SHIPMENTS,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_READY_FOR_PICKUP,
				display: tx.TX_ORDER_READY_FOR_PICKUP,
				component: BulkActionReadyForPickup,
				title: tx.TX_ORDER_READY_FOR_PICKUP,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_PICKUP,
				display: tx.TX_ORDER_RECORD_PICKUP,
				component: BulkActionRecordPickups,
				title: tx.TX_ORDER_RECORD_PICKUP_TITLE,
			},
		],
		[TC_CONTROLS_SELECTION_KEY]: 'publicUuid',
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 50,
	},
};

export const TABLE_CONFIG_ORDERS = {
	[TC_NOUN]: tx.TX_ORDER,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'display_number',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: false,
		[TC_CONTROLS_ENABLE_FILTER]: true,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true,
		[TC_CONTROLS_FILTERS_DEFAULT]: [
			{
				key: FV_KEY_ORDER_NUMBER,
				component: FilterInputGeneric,
				displayKey: 'name',
				name: tx.TX_ORDER_ORDER_NUMBER,
				inputPlaceholder: tx.TX_PLACEHOLDER_ORDER_NUMBER,
			},
			{
				key: FV_KEY_ORDER_CUSTOMER_NAME,
				component: FilterInputGeneric,
				displayKey: 'customer_name',
				name: tx.TX_ORDER_CUSTOMER_NAME,
				inputPlaceholder: tx.TX_PLACEHOLDER_NAME,
			},
			{
				key: FV_KEY_ORDER_CUSTOMER_EMAIL,
				component: FilterInputGeneric,
				displayKey: 'customer_email',
				name: tx.TX_ORDER_CUSTOMER_EMAIL,
				inputPlaceholder: tx.TX_PLACEHOLDER_EMAIL,
				inputType: 'email',
			},
			{
				key: FV_KEY_ORDER_PRODUCT_NAME,
				component: FilterInputGeneric,
				displayKey: 'product_name',
				name: tx.TX_PLACEHOLDER_INV_NAME,
				inputPlaceholder: tx.TX_PLACEHOLDER_PRODUCT_NAME,
			},
			{
				key: 'orderDate',
				minKey: FV_KEY_ORDER_DATE_MIN,
				maxKey: FV_KEY_ORDER_DATE_MAX,
				component: FilterDateRange,
				name: tx.TX_ORDER_DATE,
			},
			{
				key: FV_KEY_ORDER_STATUS,
				component: FilterMultiselect,
				quickLookup: true,
				name: tx.TX_STATUS,
				options: ORDER_STATUS_ALL,
				optionsTranslate: true,
				displayLookup: 'key',
				displayKey: 'name',
			},
			{
				key: FV_KEY_ORDER_PAYMENT_METHOD,
				component: FilterPaymentMethod,
				quickLookup: false,
				name: tx.TX_PAYMENT_METHOD,
				optionsTranslate: true,
				displayLookup: 'key',
				displayKey: 'name',
			},
			{
				key: FV_KEY_ORDER_SHIPPING_METHOD,
				component: FilterShippingMethod,
				quickLookup: false,
				name: tx.TX_SHIPPING_METHOD,
				optionsTranslate: false,
				displayLookup: 'publicUuid',
				displayKey: 'name',
			},
		],
		[TC_CONTROLS_BULK_ACTIONS_CONFIG]: [
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_EXPORT,
				display: tx.TX_ORDER_EXPORT_ORDERS,
				component: BulkActionExportOrders,
				title: tx.TX_ORDER_EXPORT_ORDERS,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_INVOICES,
				display: tx.TX_ORDER_EXPORT_INVOICES,
				component: BulkActionOrderInvoices,
				title: tx.TX_ORDER_EXPORT_INVOICES,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_PULL_SHEET,
				display: tx.TX_ORDER_CREATE_PULL_SHEET,
				component: BulkActionCreatePullSheet,
				title: tx.TX_ORDER_CREATE_PULL_SHEET,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_SHIP,
				display: tx.TX_ORDER_CREATE_SHIPMENT,
				component: BulkActionShipOrders,
				title: tx.TX_ORDER_CREATE_SHIPMENTS,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_PICKUP,
				display: tx.TX_ORDER_RECORD_PICKUP,
				component: BulkActionRecordPickups,
				title: tx.TX_ORDER_RECORD_PICKUP_TITLE,
			},
		],
		[TC_CONTROLS_SELECTION_KEY]: 'publicUuid',
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 50,
	},
};

export const TABLE_CONFIG_PAGES = {
	[TC_NOUN]: tx.TX_PAGE,
	[TC_PAGINATE]: false,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_ASC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: false,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: false, 
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_SETTINGS_PAYMENT_METHODS_ADD,
	},
};

export const TABLE_CONFIG_PAYMENT_METHODS = {
	[TC_NOUN]: tx.TX_CHECKOUT_PAYMENT_METHOD,
	[TC_PAGINATE]: false,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_ASC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true, 
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_SETTINGS_PAYMENT_METHODS_ADD,
	},
};

export const TABLE_CONFIG_PRODUCT = {
	[TC_NOUN]: tx.TX_PRODUCT,
	[TC_PAGINATE]: true,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: '',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_DESC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: true,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true,
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_INVENTORY_ADD,

		[TC_CONTROLS_FILTERS_KEY]: 'admin-catalog',
		[TC_CONTROLS_FILTERS_DEFAULT]: [
			{
				key: FV_KEY_PRODUCT_LINE,
				component: FilterProductLine,
				displayLookup: 'permalink',
				displayKey: 'name',
				name: tx.TX_PRODUCT_LINE,
			},
			{
				key: 'totalQuantity',
				minKey: FV_KEY_TOTAL_QUANTITY_MIN,
				maxKey: FV_KEY_TOTAL_QUANTITY_MAX,
				component: FilterQuantityRange,
				name: tx.TX_TOTAL_QUANTITY,
			},
			{
				key: 'sellPrice',
				minKey: FV_KEY_SELL_PRICE_MIN,
				maxKey: FV_KEY_SELL_PRICE_MAX,
				component: FilterPriceRange,
				name: tx.TX_SELL_PRICE,
			},
			{
				key: 'buyPrice',
				minKey: FV_KEY_BUY_PRICE_MIN,
				maxKey: FV_KEY_BUY_PRICE_MAX,
				component: FilterPriceRange,
				name: tx.TX_BUY_PRICE,
			},
			{
				key: FV_KEY_LANGUAGE,
				component: FilterMultiselect,
				quickLookup: true,
				name: tx.TX_LANGUAGE,
				options: LANG_ALL,
				optionsTranslate: true,
				displayLookup: 'code',
				displayKey: 'nameTranslation',
			},
			{
				key: FV_KEY_CONDITION,
				component: FilterMultiselect,
				quickLookup: false,
				name: tx.TX_CONDITION,
				options: CONDITIONS_PRODUCT_PAGE_GENERIC,
				optionsTranslate: true,
				displayLookup: 'key',
				displayKey: 'name',
			},
		],
		[TC_CONTROLS_FILTERS_EXTENDED]: {
			product_line: {
				[PL_PERMALINK_MAGIC]: [
					{
						key: FV_KEY_MAGIC_SET,
						component: FilterMagicSet,
						name: tx.TX_SET,
						optionsTranslate: false,
						displayLookup: 'code',
						displayKey: 'name',
					},
					{
						key: FV_KEY_FINISH,
						component: FilterMultiselect,
						quickLookup: false,
						name: tx.TX_FILTER_FINISH,
						options: FINISHES_MAGIC,
						optionsTranslate: true,
						displayLookup: 'key',
						displayKey: 'name',
					},
					{
						key: FV_KEY_MAGIC_COLOR,
						component: FilterMultiselect,
						quickLookup: false,
						name: tx.TX_MAGIC_CARD_COLOR,
						options: MAGIC_COLORS,
						optionsTranslate: true,
						displayLookup: 'key',
						displayKey: 'name',
					},
					{
						key: FV_KEY_MAGIC_RARITY,
						component: FilterMultiselect,
						quickLookup: false,
						name: tx.TX_MAGIC_CARD_RARITY,
						options: MAGIC_RARITIES,
						optionsTranslate: true,
						displayLookup: 'key',
						displayKey: 'name',
					},
				],
				[PL_PERMALINK_POKEMON]: [
					{
						key: FV_KEY_POKEMON_SET,
						component: FilterPokemonSet,
						name: tx.TX_SET,
						optionsTranslate: false,
						displayLookup: 'extId',
						displayKey: 'name',
					},
					{
						key: FV_KEY_FINISH,
						component: FilterMultiselect,
						quickLookup: false,
						name: tx.TX_FILTER_FINISH,
						options: FINISHES_POKEMON,
						optionsTranslate: true,
						displayLookup: 'key',
						displayKey: 'name',
					},
				],
				[PL_PERMALINK_LORCANA]: [
					{
						key: FV_KEY_LORCANA_SET,
						component: FilterLorcanaSet,
						name: tx.TX_SET,
						optionsTranslate: false,
						displayLookup: 'code',
						displayKey: 'name',
					},
					{
						key: FV_KEY_FINISH,
						component: FilterMultiselect,
						quickLookup: false,
						name: tx.TX_FILTER_FINISH,
						options: FINISHES_LORCANA,
						optionsTranslate: true,
						displayLookup: 'key',
						displayKey: 'name',
					},
				],
				[PL_PERMALINK_STARWARS]: [
					{
						key: FV_KEY_STARWARS_SET,
						component: FilterStarWarsSet,
						name: tx.TX_SET,
						optionsTranslate: false,
						displayLookup: 'code',
						displayKey: 'name',
					},
					{
						key: FV_KEY_FINISH,
						component: FilterMultiselect,
						quickLookup: false,
						name: tx.TX_FILTER_FINISH,
						options: FINISHES_STARWARS,
						optionsTranslate: true,
						displayLookup: 'key',
						displayKey: 'name',
					},
				],
			},
		},
		[TC_CONTROLS_SELECTION_KEY]: 'id',
		[TC_CONTROLS_BULK_ACTIONS_CONFIG]: [
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_EXPORT,
				display: tx.TX_EXPORT,
				component: BulkActionExportInventory,
				title: tx.TX_ORDER_EXPORT_INVENTORY,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_MANAGE_TAGS,
				display: tx.TX_INV_TAGS_MANAGE_TAGS,
				component: BulkActionManageTags,
				title: tx.TX_INV_TAGS_MANAGE_TAGS,
				closeModalLabel: tx.TX_DONE,
			},
			{
				key: TC_CONTROLS_BULK_ACTION_KEY_CHANGE_SET,
				display: tx.TX_INV_CHANGE_SET,
				component: BulkActionChangeSet,
				title: tx.TX_INV_CHANGE_SET,
				closeModalLabel: tx.TX_CANCEL,
				submitModalLabel: tx.TX_SAVE,
			},
		],
	},
	[TC_PAGINATION_CONFIGS]: {
		[TC_DEFAULT_PAGE_SIZE]: 25,
	},
	[TC_VIEWS_CONFIGS]: {
		[TC_VIEWS_DEFAULT]: TC_VIEW_KEY_DETAIL,
		[TC_VIEWS_OPTIONS]: [
			{
				[TC_VIEWS_OPTION_COMPONENT]: ProductDetailRow,
				[TC_VIEWS_OPTION_ICON]: ICON_DETAIL, 
				[TC_VIEWS_OPTION_KEY]: TC_VIEW_KEY_DETAIL, 
				[TC_VIEWS_OPTION_NAME]: tx.TX_ADMIN_TABLE_VIEW_DETAIL,
				[TC_VIEWS_OPTION_SELECTABLE]: true,
				[TC_VIEWS_OPTION_SELECTABLE_WIDTH]: 40,
				[TC_VIEWS_OPTION_SORT]: [
					{
						display: tx.TX_PLACEHOLDER_BEST_MATCH,
						value: '',
					},
					{
						display: tx.TX_RECENTLY_ADDED,
						value: 'id',
					},
				],
			},
		], 
	},
};

export const TABLE_CONFIG_PRODUCT_LINE = {
	[TC_NOUN]: tx.TX_PRODUCT_LINE,
	[TC_PAGINATE]: false,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_ASC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true, 
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_INVENTORY_PRODUCT_LINES_ADD,
	},
};

export const TABLE_CONFIG_SHIPPING_METHODS = {
	[TC_NOUN]: tx.TX_SHIPPING_METHOD,
	[TC_PAGINATE]: false,
	[TC_HEADER_CONFIGS]: {
		[TC_DEFAULT_SORT_KEY]: 'id',
		[TC_DEFAULT_SORT_DIR]: TC_HEADER_SORT_DIR_ASC,
	},
	[TC_CONTROLS_CONFIGS]: {
		[TC_CONTROLS_ENABLE_ADD]: true,
		[TC_CONTROLS_ENABLE_FILTER]: false,
		[TC_CONTROLS_ENABLE_QUICK_SEARCH]: true, 
		[TC_CONTROLS_ADD_LINK]: URL_ADMIN_SETTINGS_SHIPPING_METHODS_ADD,
	},
};



// Schema's need exactly 1 flex column; that element is REQUIRED to have a TR_MIN_WIDTH attribute

export const TABLE_SCHEMA_ADMIN_USERS = [
	{
		[TR_LABEL]: tx.TX_USER,
		[TR_KEY]: 'first_name',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_COMBINED_IDENTITY,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'first_name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 160,
		[TR_MULTIVALUED_KEYS]: [
			'first_name', 
			'last_name', 
			'email_address', 
		],
		[TR_QUICK_SEARCH]: true,
	},	
	{
		[TR_LABEL]: tx.TX_PERMISSION_PRODUCTS,
		[TR_KEY]: p.P_MANAGE_PRODUCTS,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 125,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: p.P_MANAGE_PRODUCTS,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_BINARY_TRUE_ICON]: true,
		[TR_BINARY_TRUE]: ICON_CHECK,
		[TR_BINARY_FALSE]: tx.TX_null,
	},
	{
		[TR_LABEL]: tx.TX_PERMISSION_SALES,
		[TR_KEY]: p.P_MANAGE_SALES,
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 125,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: p.P_MANAGE_SALES,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_BINARY_TRUE_ICON]: true,
		[TR_BINARY_TRUE]: ICON_CHECK,
		[TR_BINARY_FALSE]: tx.TX_null,
	},
	{
		[TR_LABEL]: tx.TX_USERS,
		[TR_KEY]: p.P_MANAGE_USERS,
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 125,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: p.P_MANAGE_USERS,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_BINARY_TRUE_ICON]: true,
		[TR_BINARY_TRUE]: ICON_CHECK,
		[TR_BINARY_FALSE]: tx.TX_null,
	},
	{
		[TR_LABEL]: tx.TX_PERMISSION_EVENTS,
		[TR_KEY]: p.P_MANAGE_EVENTS,
		[TR_SEQUENCE]: 600,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 125,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: p.P_MANAGE_EVENTS,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_BINARY_TRUE_ICON]: true,
		[TR_BINARY_TRUE]: ICON_CHECK,
		[TR_BINARY_FALSE]: tx.TX_null,
	},
	{
		[TR_LABEL]: tx.TX_PERMISSION_PERMISSIONS_SHORT,
		[TR_KEY]: p.P_MANAGE_PERMISSIONS,
		[TR_SEQUENCE]: 700,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 125,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: p.P_MANAGE_PERMISSIONS,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_BINARY_TRUE_ICON]: true,
		[TR_BINARY_TRUE]: ICON_CHECK,
		[TR_BINARY_FALSE]: tx.TX_null,
	},

	{
		[TR_LABEL]: tx.TX_LAST_LOGIN,
		[TR_KEY]: 'last_login',
		[TR_SEQUENCE]: 900,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'last_login',
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},	
	{
		[TR_LABEL]: tx.TX_EDIT,
		[TR_KEY]: 'editAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_SETTINGS_ADMIN_USERS_EDIT,
		[TR_ACTION_LINK_PARAMS]: [
			'public_uuid',
		],

		[TR_ACTION_LABEL]: ICON_EDIT,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_BULK_UPLOADS = [
	{
		[TR_LABEL]: tx.TX_DATE,
		[TR_KEY]: 'date',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'create_date',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,
	},
	{
		[TR_LABEL]: tx.TX_PRODUCT_LINE,
		[TR_KEY]: `productLine${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'product_line__name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 140,
	},
	{
		[TR_LABEL]: tx.TX_FILENAME,
		[TR_KEY]: 'originalFilename',
		[TR_SEQUENCE]: 250,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,
	},
	{
		[TR_LABEL]: tx.TX_INV_BULK_UPLOAD_RECORDS,
		[TR_KEY]: 'records',
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_NUMERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'records',
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,
	},
	{
		[TR_LABEL]: tx.TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_ERROR,
		[TR_KEY]: 'countError',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_NUMERIC,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},
	{
		[TR_LABEL]: tx.TX_INV_BULK_UPLOAD_PERCENT_COMPLETE,
		[TR_KEY]: 'percentComplete',
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_NUMERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_NUMERIC_STYLE]: TR_VAL_NUMERIC_STYLE_PERCENT,
	},
	
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_INVENTORY_BULK_UPLOAD_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_BUYLIST_CARTS = [
	{
		[TR_LABEL]: tx.TX_DATE,
		[TR_KEY]: 'lastUpdate',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'last_update',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,
	},
	{
		[TR_LABEL]: tx.TX_CUSTOMER,
		[TR_KEY]: `user${TABLE_VALUE_OBJECT_DELIMITER}firstName`,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_COMBINED_IDENTITY,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 200,
		[TR_MULTIVALUED_KEYS]: [
			`user${TABLE_VALUE_OBJECT_DELIMITER}firstName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}lastName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}email`, 
		],

		[TR_DEFAULT_TRANSLATE]: true,
		[TR_DEFAULT_VALUE]: tx.TX_GUEST_USER,
	},
	{
		[TR_LABEL]: tx.TX_ORDER,
		[TR_KEY]: `order${TABLE_VALUE_OBJECT_DELIMITER}displayNumber`,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_DEFAULT_STYLE]: {
			fontWeight: '300',
			fontStyle: 'italic',
		},
		[TR_DEFAULT_TRANSLATE]: true,
		[TR_DEFAULT_VALUE]: tx.TX_NONE,
	},
	{
		[TR_LABEL]: tx.TX_QUANTITY,
		[TR_KEY]: `quantity`,
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},
	{
		[TR_LABEL]: tx.TX_TOTAL,
		[TR_KEY]: 'subTotal',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_CURRENCY,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_ORDERS_BUYLIST_CARTS_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_CARTS = [
	{
		[TR_LABEL]: tx.TX_DATE,
		[TR_KEY]: 'lastUpdate',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'last_update',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,
	},
	{
		[TR_LABEL]: tx.TX_CUSTOMER,
		[TR_KEY]: `user${TABLE_VALUE_OBJECT_DELIMITER}firstName`,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_COMBINED_IDENTITY,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 200,
		[TR_MULTIVALUED_KEYS]: [
			`user${TABLE_VALUE_OBJECT_DELIMITER}firstName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}lastName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}email`, 
		],

		[TR_DEFAULT_TRANSLATE]: true,
		[TR_DEFAULT_VALUE]: tx.TX_GUEST_USER,
	},
	{
		[TR_LABEL]: tx.TX_ORDER,
		[TR_KEY]: `order${TABLE_VALUE_OBJECT_DELIMITER}displayNumber`,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_DEFAULT_STYLE]: {
			fontWeight: '300',
			fontStyle: 'italic',
		},
		[TR_DEFAULT_TRANSLATE]: true,
		[TR_DEFAULT_VALUE]: tx.TX_NONE,
	},
	{
		[TR_LABEL]: tx.TX_QUANTITY,
		[TR_KEY]: `quantity`,
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},
	{
		[TR_LABEL]: tx.TX_TOTAL,
		[TR_KEY]: 'subTotal',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_CURRENCY,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_ORDERS_CARTS_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_COUPONS = [
	{
		[TR_LABEL]: tx.TX_MARKETING_COUPON_CODE,
		[TR_KEY]: 'code',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 160,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'code',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_CUSTOM_ATTR]: [
			{
				[TR_CUSTOM_ATTR_KEY]: 'style', 
				[TR_CUSTOM_ATTR_VALUE]: {
					textTransform: 'uppercase',
				},
			},
		],
	},
	{
		[TR_LABEL]: tx.TX_NAME,
		[TR_KEY]: `name`,
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 150,
	},
	{
		[TR_LABEL]: tx.TX_MARKETING_COUPON_DISCOUNT_TYPE,
		[TR_KEY]: 'displayType',
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 160,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_TRANSLATE]: true,
	},
	{
		[TR_LABEL]: tx.TX_MARKETING_COUPON_DISCOUNT_AMOUNT,
		[TR_KEY]: 'discountAmount',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_NUMERIC,
		[TR_WIDTH]: 160,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_CUSTOM_ATTR]: [
			{
				[TR_CUSTOM_ATTR_KEY]: 'discounttype', 
				[TR_CUSTOM_ATTR_VALUE_KEY]: 'discountType',
			},
		],
	},
	{
		[TR_LABEL]: tx.TX_MINIMUM,
		[TR_KEY]: 'minPurchase',
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_CURRENCY,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_MARKETING_COUPONS_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'code',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_CUSTOMER_BULK_UPLOADS = [
	{
		[TR_LABEL]: tx.TX_DATE,
		[TR_KEY]: 'date',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'create_date',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 140,
	},
	{
		[TR_LABEL]: tx.TX_INV_BULK_UPLOAD_RECORDS,
		[TR_KEY]: 'records',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_NUMERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'records',
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,
	},
	{
		[TR_LABEL]: tx.TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_CREATED,
		[TR_KEY]: 'countUserCreated',
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_NUMERIC,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},
	{
		[TR_LABEL]: tx.TX_INV_BULK_UPLOAD_UPLOAD_RESULTS_ERROR,
		[TR_KEY]: 'countError',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_NUMERIC,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},
	
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_CUSTOMERS_BULK_UPLOAD_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_CUSTOMERS = [
	{
		[TR_LABEL]: tx.TX_FIRST_NAME,
		[TR_KEY]: 'firstName',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 160,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'first_name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_QUICK_SEARCH]: true,
	},
	{
		[TR_LABEL]: tx.TX_LAST_NAME,
		[TR_KEY]: 'lastName',
		[TR_SEQUENCE]: 240,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 160,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'last_name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_QUICK_SEARCH]: true,
	},
	{
		[TR_LABEL]: tx.TX_EMAIL_ADDRESS,
		[TR_KEY]: 'email',
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'email_address',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 200,
		[TR_QUICK_SEARCH]: true,
	},
	{
		[TR_LABEL]: tx.TX_LAST_LOGIN,
		[TR_KEY]: 'lastLogin',
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'last_login',
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,

		[TR_QUICK_SEARCH]: false,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 600,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_CUSTOMERS_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_INTEGRATIONS = [
	{
		[TR_LABEL]: tx.TX_NAME,
		[TR_KEY]: 'name',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 250,
		[TR_QUICK_SEARCH]: true,
		[TR_TRANSLATE]: true,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'key',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_OPEN_ORDERS = [
	{
		[TR_LABEL]: tx.TX_ACTION,
		[TR_KEY]: 'publicUuid',
		[TR_SEQUENCE]: 50,
		[TR_TYPE]: TR_VAL_TYPE_CHECKBOX,
		[TR_WIDTH]: 50,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_NO_MOBILE]: true,
	},
	{
		[TR_LABEL]: tx.TX_NUMBER,
		[TR_KEY]: 'displayNumber',
		[TR_SEQUENCE]: 80,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'display_number',
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,
	},
	{
		[TR_LABEL]: tx.TX_DATE,
		[TR_KEY]: 'createDate',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'create_date',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,
	},
	{
		[TR_LABEL]: tx.TX_CUSTOMER,
		[TR_KEY]: `user${TABLE_VALUE_OBJECT_DELIMITER}firstName`,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_COMBINED_IDENTITY,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 200,
		[TR_MULTIVALUED_KEYS]: [
			`user${TABLE_VALUE_OBJECT_DELIMITER}firstName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}lastName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}email`, 
		],
	},
	{
		[TR_LABEL]: tx.TX_TOTAL,
		[TR_KEY]: 'totalGrand',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_CURRENCY,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'total_grand',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,
	},
	{
		[TR_LABEL]: tx.TX_QUANTITY,
		[TR_KEY]: `cart${TABLE_VALUE_OBJECT_DELIMITER}quantity`,
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,
	},
	{
		[TR_LABEL]: tx.TX_PAYMENT_METHOD,
		[TR_KEY]: `paymentMethod${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 600,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'payment_method__name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_TRANSLATE]: true,

		[TR_DEFAULT_STYLE]: {
			fontWeight: '300',
			fontStyle: 'italic',
		},
		[TR_DEFAULT_TRANSLATE]: true,
		[TR_DEFAULT_VALUE]: tx.TX_NONE,
	},
	{
		[TR_LABEL]: tx.TX_SHIPPING_METHOD,
		[TR_KEY]: `shippingMethod${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 700,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'shipping_method__name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_4,
	},
	{
		[TR_LABEL]: tx.TX_SHIPPING_ADDRESS,
		[TR_KEY]: `shippingAddress`,
		[TR_SEQUENCE]: 800,
		[TR_TYPE]: TR_VAL_TYPE_ADDRESS,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},
	{
		[TR_LABEL]: tx.TX_STATUS,
		[TR_KEY]: `status${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 900,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'status',
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_TRANSLATE]: true,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_ORDERS_ORDER_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_ORDERS = [
	{
		[TR_LABEL]: tx.TX_ACTION,
		[TR_KEY]: 'publicUuid',
		[TR_SEQUENCE]: 50,
		[TR_TYPE]: TR_VAL_TYPE_CHECKBOX,
		[TR_WIDTH]: 50,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_NO_MOBILE]: true,
	},
	{
		[TR_LABEL]: tx.TX_NUMBER,
		[TR_KEY]: 'displayNumber',
		[TR_SEQUENCE]: 80,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'display_number',
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,
	},
	{
		[TR_LABEL]: tx.TX_DATE,
		[TR_KEY]: 'createDate',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_DATE,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'create_date',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,
	},
	{
		[TR_LABEL]: tx.TX_TYPE,
		[TR_KEY]: 'isBuylist',
		[TR_SEQUENCE]: 250,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'is_buylist',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_BINARY_TRUE]: tx.TX_BUYLIST,
		[TR_BINARY_FALSE]: tx.TX_PURCHASE,
	},
	{
		[TR_LABEL]: tx.TX_CUSTOMER,
		[TR_KEY]: `user${TABLE_VALUE_OBJECT_DELIMITER}firstName`,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_COMBINED_IDENTITY,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 200,
		[TR_MULTIVALUED_KEYS]: [
			`user${TABLE_VALUE_OBJECT_DELIMITER}firstName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}lastName`, 
			`user${TABLE_VALUE_OBJECT_DELIMITER}email`, 
		],
	},
	{
		[TR_LABEL]: tx.TX_TOTAL,
		[TR_KEY]: 'totalGrand',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_CURRENCY,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'total_grand',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,
	},
	{
		[TR_LABEL]: tx.TX_QUANTITY,
		[TR_KEY]: `orderCart${TABLE_VALUE_OBJECT_DELIMITER}quantity`,
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,
	},
	{
		[TR_LABEL]: tx.TX_PAYMENT_METHOD,
		[TR_KEY]: `paymentMethod${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 600,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'payment_method__name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_TRANSLATE]: true,

		[TR_DEFAULT_STYLE]: {
			fontWeight: '300',
			fontStyle: 'italic',
		},
		[TR_DEFAULT_TRANSLATE]: true,
		[TR_DEFAULT_VALUE]: tx.TX_NONE,
	},
	{
		[TR_LABEL]: tx.TX_SHIPPING_METHOD,
		[TR_KEY]: `shippingMethod${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 700,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'shipping_method__name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_4,

		[TR_TRANSLATE]: false,

		[TR_DEFAULT_STYLE]: {
			fontWeight: '300',
			fontStyle: 'italic',
		},
		[TR_DEFAULT_TRANSLATE]: true,
		[TR_DEFAULT_VALUE]: tx.TX_NONE,
	},
	{
		[TR_LABEL]: tx.TX_SHIPPING_ADDRESS,
		[TR_KEY]: `shippingAddress`,
		[TR_SEQUENCE]: 800,
		[TR_TYPE]: TR_VAL_TYPE_ADDRESS,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,
	},
	{
		[TR_LABEL]: tx.TX_STATUS,
		[TR_KEY]: `status${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 900,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'status',
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_TRANSLATE]: true,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 1000,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_ORDERS_ORDER_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_PAGES = [
	{
		[TR_LABEL]: tx.TX_PAGE,
		[TR_KEY]: 'name',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 250,
		[TR_QUICK_SEARCH]: true,
		[TR_TRANSLATE]: true,
	},
	{
		[TR_LABEL]: tx.TX_EDIT,
		[TR_KEY]: 'editAction',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_SETTINGS_PAGES_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'key',
		],

		[TR_ACTION_LABEL]: ICON_EDIT,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_PAYMENT_METHODS = [
	{
		[TR_LABEL]: tx.TX_CHECKOUT_PAYMENT_METHOD,
		[TR_KEY]: 'name',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 250,
		[TR_QUICK_SEARCH]: true,
		[TR_TRANSLATE]: false,
		[TR_TRANSLATE_FUNCTION_KEY]: 'getDisplayName',
	},
	{
		[TR_LABEL]: tx.TX_STATUS,
		[TR_KEY]: 'isEnabled',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'isEnabled',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_BINARY_TRUE]: tx.TX_ENABLED,
		[TR_BINARY_FALSE]: tx.TX_DISABLED,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'public_uuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_PRODUCT = [
	{
		[TR_LABEL]: tx.TX_ACTION,
		[TR_KEY]: 'id',
		[TR_SEQUENCE]: 50,
		[TR_TYPE]: TR_VAL_TYPE_CHECKBOX,
		[TR_WIDTH]: 50,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_NO_MOBILE]: true,
	},
	{
		[TR_LABEL]: tx.TX_SKU,
		[TR_KEY]: 'sku',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 170,
		[TR_ALIGN]: TR_VAL_ALIGN_LEFT,
		[TR_SORT_KEY]: 'sku',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,
	},
	{
		[TR_LABEL]: tx.TX_PRODUCT,
		[TR_KEY]: 'name',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_COMPOUND_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'name',
		[TR_SORT_NAME]: tx.TX_NAME,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MULTIVALUED_KEYS]: [
			`name`, 
			`magic${TABLE_VALUE_OBJECT_DELIMITER}setName`, 
		],
		[TR_MIN_WIDTH]: 250,
		[TR_CUSTOM_ATTR]: [
			{
				[TR_CUSTOM_ATTR_KEY]: 'lang', 
				[TR_CUSTOM_ATTR_VALUE_KEY]: 'languageCode',
			},
		],
	},
	{
		[TR_LABEL]: tx.TX_PRODUCT_LINE,
		[TR_KEY]: `productLine${TABLE_VALUE_OBJECT_DELIMITER}name`,
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: 140,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'product_line__name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,
	},
	{
		[TR_LABEL]: tx.TX_PRICE_RANGE,
		[TR_KEY]: 'inventory',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC_CALCULATED,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_1,

		[TR_CALC_TYPE]: TR_VAL_CALC_TYPE_PRICE_RANGE,
	},
	{
		[TR_LABEL]: tx.TX_TOTAL_QUANTITY,
		[TR_KEY]: 'inventory',
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC_CALCULATED,
		[TR_WIDTH]: 200,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_CALC_TYPE]: TR_VAL_CALC_TYPE_TOTAL_INVENTORY,
		[TR_QUICK_SEARCH]: true,
	},

	{
		[TR_LABEL]: tx.TX_STATUS,
		[TR_KEY]: 'is_enabled',
		[TR_SEQUENCE]: 800,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 120,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'is_enabled',
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,

		[TR_BINARY_TRUE]: tx.TX_ENABLED,
		[TR_BINARY_FALSE]: tx.TX_DISABLED,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 900,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_INVENTORY_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'productLine||permalink',
			'permalink',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_PRODUCT_LINE = [
	{
		[TR_LABEL]: tx.TX_PRODUCT_LINE,
		[TR_KEY]: 'name',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 250,
		[TR_QUICK_SEARCH]: true,
	},
	{
		[TR_LABEL]: tx.TX_STATUS,
		[TR_KEY]: 'isEnabled',
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'is_enabled',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_BINARY_TRUE]: tx.TX_ENABLED,
		[TR_BINARY_FALSE]: tx.TX_DISABLED,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'editAction',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_INVENTORY_PRODUCT_LINES_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'permalink',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];

export const TABLE_SCHEMA_SHIPPING_METHODS = [
	{
		[TR_LABEL]: tx.TX_SHIPPING_METHOD,
		[TR_KEY]: 'name',
		[TR_SEQUENCE]: 100,
		[TR_TYPE]: TR_VAL_TYPE_GENERIC,
		[TR_WIDTH]: TR_VAL_WIDTH_FLEX,
		[TR_ALIGN]: TR_VAL_ALIGN_FLEX,
		[TR_SORT_KEY]: 'name',
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_MIN_WIDTH]: 250,
		[TR_QUICK_SEARCH]: true,
	},
	{
		[TR_LABEL]: tx.TX_COST,
		[TR_KEY]: 'cost',
		[TR_SEQUENCE]: 200,
		[TR_TYPE]: TR_VAL_TYPE_CURRENCY,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'cost',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_QUICK_SEARCH]: false,
	},
	{
		[TR_LABEL]: tx.TX_MINIMUM,
		[TR_KEY]: 'minPurchase',
		[TR_SEQUENCE]: 300,
		[TR_TYPE]: TR_VAL_TYPE_CURRENCY,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'minPurchase',
		[TR_PRIORITY]: TR_VAL_PRIORITY_3,

		[TR_QUICK_SEARCH]: false,
	},
	{
		[TR_LABEL]: tx.TX_STATUS,
		[TR_KEY]: 'isEnabled',
		[TR_SEQUENCE]: 400,
		[TR_TYPE]: TR_VAL_TYPE_BINARY,
		[TR_WIDTH]: 150,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: 'isEnabled',
		[TR_PRIORITY]: TR_VAL_PRIORITY_2,

		[TR_BINARY_TRUE]: tx.TX_ENABLED,
		[TR_BINARY_FALSE]: tx.TX_DISABLED,
	},
	{
		[TR_LABEL]: tx.TX_VIEW,
		[TR_KEY]: 'viewAction',
		[TR_SEQUENCE]: 500,
		[TR_TYPE]: TR_VAL_TYPE_LINK,
		[TR_WIDTH]: 100,
		[TR_ALIGN]: TR_VAL_ALIGN_RIGHT,
		[TR_SORT_KEY]: null,
		[TR_PRIORITY]: TR_VAL_PRIORITY_0,

		[TR_ACTION_TYPE]: TR_VAL_ACTION_TYPE_LINK,

		[TR_ACTION_LINK]: URL_ADMIN_SETTINGS_SHIPPING_METHODS_VIEW,
		[TR_ACTION_LINK_PARAMS]: [
			'publicUuid',
		],

		[TR_ACTION_LABEL]: ICON_VIEW,
		[TR_ACTION_LABEL_TYPE]: TR_VAL_ACTION_LABEL_TYPE_ICON,

		[TR_NO_MOBILE]: true,
		[TR_MOBILE_ACTION]: true,
	},
];










