import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ANALYTICS_DURATION_UNIT_MONTH } from '../../../../constants/analytics';
import {
  API_KEY_DURATION, 
  API_KEY_IS_BUYLIST, 
} from '../../../../constants/api';
import { ICON_CHEVRON_DOWN } from '../../../../constants/icons';
import {
  TX_ANALYTICS_ORDERS_TOTAL_TITLE,
  TX_ORDERS,
  TX_null,
} from '../../../../constants/strings';

import { formatServerError } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import usePrevious from '../../../../hooks/usePrevious';

import { ordersAnalyticsOrdersTotal } from '../../../../actions/order';

import HiddenDropdown from '../../../Input/HiddenDropdown';
import { Icon } from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockAnalyticsOrderTotal.module.scss';

export const BlockAnalyticsOrderTotal = (props) => {
  
  // Dispatch
  const dispatch = useDispatch();

  // Props
  const {
    block,
  } = props;

  // State
  const [data, setData] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [duration, setDuration] = useState(block && block.config && block.config.init && block.config.init.duration ? block.config.init.duration : `1${ANALYTICS_DURATION_UNIT_MONTH}`);

  // Previous data
  const prevDuration = usePrevious(duration);

  // Refs
  const controllerRef = useRef(null);

  // Internationalization
  const { i18n, t } = useTranslation();

  // Methods
  const fetchData = useCallback(async () => {

    setDataError(null);
    setDataLoading(true);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const getData = {
      [API_KEY_DURATION]: duration,
      [API_KEY_IS_BUYLIST]: false,
    };

    // Fetch data
    const resp = await dispatch(ordersAnalyticsOrdersTotal(getData, controller.signal))
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        setDataError(formatServerError(errResp));
        setDataLoading(false);
      });

    if(!resp) {
      return null;
    }

    setData(resp);
    setDataLoading(false);

  }, [ dispatch, duration ]);

  const handleDurationChange = (evt) => {
    setDuration(evt.target.value);
  }

  const getDurationLabel = () => {
    if(!data) { return TX_null; }

    const options = data.getDurationOptions() || [];
    for(const opt of options) {
      if(opt.value === duration) {
        return opt.display;
      }
    }

    return TX_null;
  }

  // Effects
  useEffect(() => {
    if(data === null) {
      fetchData();
    }
  }, [ data, fetchData ]);


  useEffect(() => {
    if(data !== null && prevDuration && duration !== prevDuration) {
      fetchData();
    }
  }, [ data, duration, fetchData, prevDuration ]);

  // Render
  return (
    <div className={styles.BlockAnalyticsOrderTotal}>
      <div className={styles.headerWrapper}>
        <div className={styles.titleWrapper}>{t(TX_ANALYTICS_ORDERS_TOTAL_TITLE)}</div>
      </div>
      <div className={styles.bodyWrapper}>
        {dataLoading || data === null ?
          <div className={styles.loadingWrapper}>
            <div className={styles.iconWrapper}>
              <LoadingIcon />
            </div>
          </div> :
          <>
            {dataError ?
              <div className={styles.errorWrapper}>{t(dataError)}</div> :
              <div className={styles.dataWrapper}>
                <div className={styles.totalValue}>{data.total.toLocaleString(getStoreLanguage(i18n))}</div>
                <div className={styles.totalMetric}>{t(TX_ORDERS)}</div>
                <HiddenDropdown 
                  options={data.getDurationOptions()}
                  value={duration}
                  onChange={handleDurationChange}>
                  <div className={styles.durationWrapper}>
                    <div className={styles.durationValue}>{t(getDurationLabel())}</div>
                    <div className={styles.durationIcon}>
                      <div className={styles.iconWrapper}>
                        <Icon value={ICON_CHEVRON_DOWN} />
                      </div>
                    </div>
                  </div>
                </HiddenDropdown>
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};

export default BlockAnalyticsOrderTotal;




