
import * as x from '../constants/actions';


const initialState = {
  filters: {},
  filtersCustomOptions: {},
  nameSearchValue: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_PRODUCT_SET_NAME_VALUE:
      return productSetNameSearchValue(state, action);
    case x.ACTION_PRODUCT_SET_FILTERS:
      return productSetFilters(state, action);
    case x.ACTION_PRODUCT_CLEAR_FILTERS:
      return productClearFilters(state, action);
    case x.ACTION_PRODUCT_SET_FILTER_CUSTOM_OPTIONS:
      return productSetFilterCustomOptions(state, action);
    default:
      return state;
  }
}

function productSetNameSearchValue(state, action) {
  return Object.assign({}, state, {
  	nameSearchValue: action.payload,
  });
}

function productSetFilters(state, action) {

  const newFilters = {};
  if(action.payload.key && action.payload.value) {
    newFilters[action.payload.key] = action.payload.value;
  }

  return Object.assign({}, state, {
    filters: Object.assign({}, state.filters, newFilters),
  });
}

function productClearFilters(state, action) {
  return Object.assign({}, state, {
    filters: {},
  });
}

function productSetFilterCustomOptions(state, action) {

  const newFilterOptions = {};
  if(action.payload.key && action.payload.value) {
    newFilterOptions[action.payload.key] = action.payload.value;
  }

  return Object.assign({}, state, {
    filtersCustomOptions: Object.assign({}, state.filtersCustomOptions, newFilterOptions),
  });
}


