import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isFormValid, getNameError, getEmailError } from '../../../utils/form-validation';
import { formatServerError } from '../../../utils/formatting';

import { ERROR_EMAIL_EXISTS } from '../../../constants/errors';
import * as tx from '../../../constants/strings';

import Confirm from '../../Popups/Confirm';
import LoadingIcon from '../../Icons/LoadingIcon';
import SavePrompt from '../../Popups/SavePrompt';

import * as commonActionCreators from '../../../actions/common';
import * as customerActionCreators from '../../../actions/customer';
import * as userActionCreators from '../../../actions/user';
let allActionCreators = Object.assign({}, commonActionCreators, customerActionCreators, userActionCreators);

export class CustomerDetailsEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,

      inputFirstName: this.props.customer ? this.props.customer.firstName : '',
      inputLastName: this.props.customer ? this.props.customer.lastName : '',
      inputEmail: this.props.customer ? this.props.customer.email : '',
      
      errorFirstName: '',
      errorLastName: '',
      errorEmail: '',

      requestPending: false,
      requestError: null,

      resetConfirmOpen: false,
      savePromptOpen: false, 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  changeFirstName(evt) {
    this.setState({
      inputFirstName: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorFirstName) {
        this.validateFirstName();
      }
    });
  }

  changeLastName(evt) {
    this.setState({
      inputLastName: evt.target.value,
    }, () => {
      this.makeDirty();
      if(this.state.errorLastName) {
        this.validateLastName();
      }
    });
  }

  changeEmail(evt) {
    this.setState({ 
      inputEmail: evt.target.value, 
    }, () => {
      this.makeDirty();
      if(this.state.emailError) {
        this.validateEmail();
      }
    });
  }

  validateFirstName() {
    this.setState({ errorFirstName: getNameError(this.state.inputFirstName) });
  }

  validateLastName() {
    this.setState({ errorLastName: getNameError(this.state.inputLastName) });
  }

  validateEmail() {
    this.setState({ errorEmail: getEmailError(this.state.inputEmail) }, () => {
      if(!this.state.emailError && this.state.inputEmail !== this.props.customer.email_address) {
        
        this.props.userEmailExists(this.state.inputEmail)
        .then((resp) => {
          if(resp.exists) {
            this.setState({ errorEmail: ERROR_EMAIL_EXISTS });
          }
        })
        .catch((errResp) => {
          // Do nothing, email available
        });
      }
    });
  }

  saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const editData = {
        first_name: this.state.inputFirstName,
        last_name: this.state.inputLastName,
        email_address: this.state.inputEmail,
      };

      this.setState({
        requestPending: true,
        requestError: null,
      });

      this.props.customerAdminEdit(editData, this.props.customer.publicUuid)
      .then((resp) => {
        this.setState({
          requestPending: false,
          requestError: null,
        }, () => {
          if(this.props.remoteSave) {
            this.props.finishRemote('details');
          } else {
            this.props.makeClean('details');
          }
          this.props.toggleEdit();
        });
      })
      .catch((errResp) => {
        
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });

        if(this.props.remoteSave) {
          this.props.finishRemote('details', true);
        }
      });
    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('details', true);
    }
  }

  validateAll() {
    let errorObj = {
      errorFirstName: getNameError(this.state.inputFirstName),
      errorLastName: getNameError(this.state.inputLastName),
      errorEmail: this.state.errorEmail === '' ? getEmailError(this.state.inputEmail) : this.state.errorEmail,
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  toggleConfirm(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ resetConfirmOpen: !this.state.resetConfirmOpen });
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  sendResetRequest() {
    this.props.customerAdminPasswordReset(this.props.customer.publicUuid)
    .then((resp) => {
      this.props.commonAlert({
        alertTitle: tx.TX_MESSAGE_SENT, 
        alertCopy: tx.TX_CUSTOMER_PASSWORD_RESET_SENT_COPY, 
      });
    })
    .catch((errResp) => {
      console.error(errResp);
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'CustomerDetailsEdit'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'customerDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_CUSTOMER_CUSTOMER_DETAILS)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>


            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_FIRST_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='given-name'
                  className={this.state.errorFirstName ? 'InputError' : ''}
                  value={this.state.inputFirstName}
                  onChange={this.changeFirstName.bind(this)}
                  onBlur={this.validateFirstName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_FIRST_NAME)}
                  maxLength={100} />
                {this.state.errorFirstName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorFirstName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_LAST_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  autoComplete='family-name'
                  className={this.state.errorLastName ? 'InputError' : ''}
                  value={this.state.inputLastName}
                  onChange={this.changeLastName.bind(this)}
                  onBlur={this.validateLastName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_LAST_NAME)}
                  maxLength={100} />
                {this.state.errorLastName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorLastName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_EMAIL_ADDRESS)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='email'
                  autoComplete='email'
                  className={this.state.errorEmail ? 'InputError' : ''}
                  value={this.state.inputEmail}
                  onChange={this.changeEmail.bind(this)}
                  onBlur={this.validateEmail.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EMAIL)}
                  maxLength={100} />
                {this.state.errorEmail ?
                  <div className={'adminError FieldError'}>{t(this.state.errorEmail)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_PASSWORD)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputButtonWrapper'>
                  <button className='adminInputButton' onClick={this.toggleConfirm.bind(this)}>{t(tx.TX_CUSTOMER_SEND_RESET_REQUEST)}</button>
                </div>
              </div>
            </div>
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Confirm
        title={tx.TX_CUSTOMER_SEND_RESET_REQUEST_QUESTION}
        copy={tx.TX_CUSTOMER_SEND_RESET_COPY}
        open={this.state.resetConfirmOpen}
        confirmValue={tx.TX_SEND}
        closeMethod={this.toggleConfirm.bind(this)}
        onConfirm={this.sendResetRequest.bind(this)} />
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CustomerDetailsEdit));