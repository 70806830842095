import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { getOrderedInventory } from '../../../../utils/product';

import ProductDetailRowInventoryAdd from './ProductDetailRowInventoryAdd';
import ProductDetailRowInventoryRow from './ProductDetailRowInventoryRow';

const allActionCreators = Object.assign({});

export class ProductDetailRowInventory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
      openAddSignal: null,
    }
  }

  getFilteredInventory() {

    const orderedInventory = getOrderedInventory(this.props.product);

    if(!this.props.filters || Object.keys(this.props.filters).length === 0) {
      return orderedInventory;
    }

    const filteredInv = []
    for(const inv of orderedInventory) {

      let invMatch = true;
      for(const filterKey in this.props.filters) {

        switch(filterKey) {
          case 'language':
            if(inv.language && inv.language.code) {
              const filterValues = this.props.filters[filterKey].split(',');
              if(!filterValues.includes(inv.language.code)) {
                invMatch = false;
              }
            }
            break;
          case 'condition':
            if(inv.condition && inv.condition.key) {
              const filterValues = this.props.filters[filterKey].split(',');
              if(!filterValues.includes(inv.condition.key)) {
                invMatch = false;
              }
            }
            break;
          case 'finish':
            if(inv.finish && inv.finish.key) {
              const filterValues = this.props.filters[filterKey].split(',');
              if(!filterValues.includes(inv.finish.key)) {
                invMatch = false;
              }
            }
            break;
          default:
            break;
        }
      }

      if(invMatch) {
        filteredInv.push(inv);
      }
    }
    return filteredInv;
  }

  isInventoryFiltered(filteredInv) {
    return this.props.product.inventory.length !== filteredInv.length;
  }

  toggleShow() {
    this.setState({ showAll: !this.state.showAll });
  }

  signalOpen() {
    this.setState({ openAddSignal: Date.now() });
  }

  render() {

    const {t} = this.props;

    const filteredInvList = this.getFilteredInventory();

    return <div className={'ProductDetailRowInventory'}>
      <div className='inventoryBlockLiner'>
        <div className={'inventoryHeader invRow'}>
          <div className='headerLiner'>
            <div className='nameElement'>
              <div className='nameLiner'>{t(tx.TX_INVENTORY)}</div>
              <div className='nameAction' onClick={this.signalOpen.bind(this)}>
                <div className={'nameActionValue FlexCenter'}>+</div>
              </div>
            </div>
            <div className='controlElement'>
              <div className='controlLiner'>
                <div className={'quantityElement controlElement'}>
                  <div className={'headerLabel FlexCenter'}>{t(tx.TX_QUANTITY)}</div>
                </div>
                <div className={'manualElement controlElement'}>
                  <div className={'headerLabel FlexCenter'}>{t(this.props.product.productLine.automatedPricing ? tx.TX_MANUAL : tx.TX_null)}</div>
                </div>
                <div className={'inputElement doubleElement controlElement'}>
                  <div className='headerSpan'>{t(tx.TX_PRICE)}</div>
                  <div className='headerRows'>
                    <div className='headerCell'>{t(tx.TX_SELL)}</div>
                    <div className='headerCell'>{t(tx.TX_BUY)}</div>
                  </div>
                </div>
                <div className={'inputElement doubleElement controlElement second'}>
                  <div className='headerSpan'>{t(tx.TX_RESERVE_N)}</div>
                  <div className='headerRows'>
                    <div className='headerCell'>{t(tx.TX_EBAY)}</div>
                    <div className='headerCell'>{t(tx.TX_AMAZON)}</div>
                  </div>
                </div>
                <div className={'inputElement doubleElement controlElement third'}>
                  <div className='headerSpan'>{t(tx.TX_TARGET_QUANTITY)}</div>
                  <div className='headerRows'>
                    <div className='headerCell'>{t(tx.TX_MIN)}</div>
                    <div className='headerCell'>{t(tx.TX_MAX)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='actionElement'></div>
          </div>
        </div>
        <div className='inventoryBody'>
          {filteredInvList.map((inv, i) => {
            return <div className='rowWrapper' key={inv.id || i}>
              <ProductDetailRowInventoryRow
                product={this.props.product}
                inventory={inv}
                hotSwap={this.props.hotSwap}
                rowCount={filteredInvList.length}
                rowIndex={i}
                idx={this.props.idx}
                pageSize={this.props.pageSize}
                tabStart={this.props.tabStart}
                refreshData={this.props.refreshData} />
            </div>;
          })}
          {this.isInventoryFiltered(filteredInvList) ?
            <div className='filterNotice'>
              <div className='filterTitle'>{t(this.state.showAll ? tx.TX_INV_SHOWING_ALL : tx.TX_INV_SHOWING_FILTERED)}</div>
              <div className='filterAction' onClick={this.toggleShow.bind(this)}>{t(this.state.showAll ? tx.TX_INV_SHOWING_ALL_ACTION : tx.TX_INV_SHOWING_FILTERED_ACTION)}</div>
            </div> :
            null
          }
          <div className='addInventoryWrapper'>
            <div className='addInventoryBody'>
              <ProductDetailRowInventoryAdd
                product={this.props.product}
                hotSwap={this.props.hotSwap}
                openSignal={this.state.openAddSignal} />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailRowInventory));