import React from 'react';

import SectionComponent from '../SectionComponent';

import styles from '../style/LayoutTypeVertical.module.scss';

export const LayoutTypeVertical = (props) => {
  
  // Props
  const {
    adminTheme,
    layout,
  } = props;

  // Render
  return (
    <div className={styles.LayoutTypeVertical}>
      {layout.sections.map((section, i) => {
        return <div key={i} className={styles.layoutLoadedWrapper}>
          <SectionComponent
            adminTheme={adminTheme}
            section={section} />
        </div>;
      })}
    </div>
  );
};

export default LayoutTypeVertical;




