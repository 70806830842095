import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_CHECK } from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  isFormValid, 
  getCouponCodeError,
} from '../../../utils/form-validation';
import { formatServerError } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import Icon from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';

import '../style/_cartcoupon.scss';

import * as cartActionCreators from '../../../actions/cart';
let allActionCreators = Object.assign({}, cartActionCreators);

export class CartApplyCoupon extends Component {

  constructor(props) {
    super(props);

    this.initialState = {
      
      inputCode: '', 
      errorCode: '', 

      editing: false,

      requestPending: false,
      requestError: null,
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    // Get cart, if one exists
    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });
  }

  getCart() {
    return this.props.cart.currentCart;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing }, () => {
      if(this.state.editing === false) {
        this.setState(this.initialState);
      }
    });
  }

  changeCode(evt) {
    if(this.state.requestPending) { return null; }
    this.setState({
      inputCode: evt.target.value.toUpperCase(),
    }, () => {
      if(this.state.errorCode) {
        this.validateCode();
      }
    });
  }

  validateCode() {
    this.setState({ errorCode: getCouponCodeError(this.state.inputCode) });
  }

  errorPresent() {
    return this.state.errorCode || this.state.requestError;
  }

  async applyCoupon(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const applyResp = await this.props.cartApplyCoupon(this.state.inputCode)
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!applyResp) {
        return null;
      }

      await this.props.cartFetchCart()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });

      this.setState(this.initialState);
    }
  }

  async removeCoupon() {
    
    this.setState({
      requestPending: true,
      requestError: null,
    });

    const cart = this.getCart();
    if(!cart || !cart.coupon) {
      return null;
    }

    const removeResp = await this.props.cartRemoveCoupon(cart.coupon.code)
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          requestPending: false,
          requestError: formatServerError(errResp),
        });
      });

    if(!removeResp) {
      return null;
    }

    await this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    this.setState(this.initialState);
  }

  validateAll() {
    const errorObj = {
      errorCode: getCouponCodeError(this.state.inputCode), 
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  render() {

    const {t} = this.props;
    const currentCart = this.props.cart.currentCart;

    if(!currentCart || currentCart.quantity === 0) {
      return null;
    }

    return <div className={this.props.className ? `CartApplyCoupon ${this.props.className}` : 'CartApplyCoupon'}>
      <div className='cartCouponLiner'>
        {this.state.editing ?
          <div className='cartCouponEditing'>
            <div className='editingInputWrapper'>
              <form className='applyCouponForm' onSubmit={this.applyCoupon.bind(this)}>
                <div className='inputWrapper'>
                  <input
                    type='text'
                    maxLength={16}
                    placeholder={t(tx.TX_PLACEHOLDER_COUPON_CODE)}
                    className={`couponInput ${this.errorPresent() ? 'InputError' : ''}`}
                    value={this.state.inputCode}
                    onChange={this.changeCode.bind(this)} />
                </div>
                <div className='submitWrapper' onClick={this.applyCoupon.bind(this)}>
                  <div className='submitIconWrapper'>
                    {this.state.requestPending ?
                      <LoadingIcon /> :
                      <Icon value={ICON_CHECK} />
                    }
                  </div>
                </div>
                <div className='cancelWrapper' onClick={this.toggleEdit.bind(this)}>
                  <div className={'cancelX Flexcenter'}>&times;</div>
                </div>
              </form>
            </div>
            <div className={`couponErrorWrapper ${this.errorPresent() ? 'present' : ''}`}>
              <div className='couponError'>{this.errorPresent() ? t(this.errorPresent()) : ''}</div>
            </div>
          </div> :
          <div className='cartCouponClosed'>
            {currentCart.coupon ?
              <div className={`appliedCoupon ${this.state.requestPending ? 'removing' : ''}`}>
                <div className='appliedCode'>{currentCart.coupon.code}</div>
                <div className='appliedRemove' onClick={this.removeCoupon.bind(this)}>{t(tx.TX_REMOVE)}</div>
              </div> :
              <div className='noCoupon'>
                <div className='applyNewCoupon' onClick={this.toggleEdit.bind(this)}>{t(tx.TX_CART_APPLY_COUPON)}</div>
              </div>
            }
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    checkout: state.checkout, 
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CartApplyCoupon));