import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_GUEST } from '../../../../../constants/icons';
import * as tx from '../../../../../constants/strings';
import { 
  URL_CHECKOUT, 
  URL_FORGOT_PASSWORD, 
  URL_REGISTER, 
} from '../../../../../constants/urls';

import {
  GuestUser,
} from '../../../../../models/users';

import { setReAuth } from '../../../../../utils/auth';
import { 
  getEmailError, 
  getNameError, 
  getPasswordInputError, 
  isFormValid, 
} from '../../../../../utils/form-validation';
import { formatServerError } from '../../../../../utils/formatting';

import Icon from '../../../../Icons/Icon';

import * as authActionCreators from '../../../../../actions/auth';
import * as userActionCreators from '../../../../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, userActionCreators);

export class CheckoutStepAuthInput extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',

      errorEmail: '',
      errorPassword: '',

      loginReqError: null,
      loginReqPending: false,

      showGuestForm: false,

      inputFirstName: '',
      inputLastName: '',
      inputGuestEmail: '',

      errorFirstName: '',
      errorLastName: '',
      errorGuestEmail: '',

      guestReqError: null,
      guestReqPending: false,
    };
  }

  validateAll() {
    const errorObj = {
      errorEmail: getEmailError(this.state.email),
      errorPassword: getPasswordInputError(this.state.password),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeEmail(evt) {
    this.setState({ email: evt.target.value }, () => {
      if(this.state.errorEmail) {
        this.validateEmail(this.state.email);
      }
    });
  }

  validateEmail() {
    this.setState({ errorEmail: getEmailError(this.state.email) });
  }

  changePassword(evt) {
    this.setState({ password: evt.target.value }, () => {
      if(this.state.errorPassword) {
        this.validatePassword(this.state.password);
      }
    });
  }

  validatePassword() {
    this.setState({ errorPassword: getPasswordInputError(this.state.password) });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const login_data = {
        email_address: this.state.email,
        password: this.state.password,
      };

      this.setState({ 
        loginReqPending: true, 
        loginReqError: null,
      });

      this.props.usersLogin(login_data)
      .then((resp) => {
        
        this.setState({ 
          loginReqPending: false,
          loginReqError: null,
        });

        // Record login in store, primarily to trigger re-renders
        this.props.authSet(resp);

        // Init reAuth timer
        setReAuth();

        this.props.setStepData(this.props.config, {
          isGuest: false, 
          guestUser: null,
        });
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          loginReqPending: false,
          loginReqError: formatServerError(errResp),
        });
      });
    }
  }

  changeFirstName(evt) {
    this.setState({ inputFirstName: evt.target.value }, () => {
      if(this.state.errorFirstName) {
        this.validateFirstName();
      }
    });
  }

  validateFirstName() {
    this.setState({ errorFirstName: getNameError(this.state.inputFirstName) });
  }

  changeLastName(evt) {
    this.setState({ inputLastName: evt.target.value }, () => {
      if(this.state.errorLastName) {
        this.validateLastName();
      }
    });
  }

  validateLastName() {
    this.setState({ errorLastName: getNameError(this.state.inputLastName) });
  }

  changeGuestEmail(evt) {
    this.setState({ inputGuestEmail: evt.target.value }, () => {
      if(this.state.errorGuestEmail) {
        this.validateGuestEmail();
      }
    });
  }

  validateGuestEmail() {
    this.setState({ errorGuestEmail: getEmailError(this.state.inputGuestEmail) });
  }

  validateAllGuest() {
    const errorObj = {
      errorFirstName: getNameError(this.state.inputFirstName),
      errorLastName: getNameError(this.state.inputLastName),
      errorGuestEmail: getEmailError(this.state.inputGuestEmail),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  handleGuestSubmit(evt) {
    evt.preventDefault();
    if(this.validateAllGuest()) {

      const guest_data = {
        first_name: this.state.inputFirstName,
        last_name: this.state.inputLastName,
        email_address: this.state.inputGuestEmail,
      };

      this.setState({ 
        guestReqPending: true, 
        guestReqError: null,
      });

      this.props.usersCreateGuest(guest_data)
      .then((resp) => {
        
        this.setState({ 
          guestReqPending: false,
          guestReqError: null,
        });

        this.props.setStepData(this.props.config, {
          isGuest: true, 
          guestUser: new GuestUser(resp),
        });
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          guestReqPending: false,
          guestReqError: formatServerError(errResp),
        });
      });
    }
  }

  toggleGuestForm() {
    this.setState({ showGuestForm: !this.state.showGuestForm });
  }

  render() {

    const {t} = this.props;

    return <div className={'CheckoutStepAuthInput CheckoutStepComponent'}>
      <div className='csaAuthBlock'>
        <div className='csaAuthLogin'>
          <form className={'checkoutStepInputForm singleColumn'} onSubmit={this.handleSubmit.bind(this)}>
            <div className='checkoutFormSubheader'>{t(tx.TX_LOGIN)}</div>
            <div className={this.state.loginReqError ? 'checkoutFormServerError present' : 'checkoutFormServerError'}>
              <div className='FlexCenter'>{t(this.state.loginReqError)}</div>
            </div>
            <div className='checkoutFormInput'>
              <div className='checkoutFormLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
              <input
                type='email'
                autoComplete='email'
                className={this.state.errorEmail ? 'InputError' : ''}
                value={this.state.email}
                onChange={this.changeEmail.bind(this)}
                onBlur={this.validateEmail.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_EMAIL)} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorEmail ? t(this.state.errorEmail) : ''}} />
            </div>
            <div className='checkoutFormInput'>
              <div className='checkoutFormLabel'>{t(tx.TX_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='current-password'
                className={this.state.errorPassword ? 'InputError' : ''}
                value={this.state.password}
                onChange={this.changePassword.bind(this)}
                onBlur={this.validatePassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)} />
              {this.state.errorPassword ?
                <div className={'checkoutFormError FieldError'}>{t(this.state.errorPassword)}</div> :
                null
              }
              <div className='forgotPasswordWrapper'>
                <Link to={{
                  pathname: URL_FORGOT_PASSWORD,
                  state: {
                    emailVal: this.state.email
                  },
                }}>{t(tx.TX_FORGOT_PASSWORD_QUESTION)}</Link>
              </div>
            </div>
            <div className={'checkoutFormInput checkoutFormAction'}>
              <button 
                className='checkoutFormButton'
                disabled={this.state.loginReqPending}>
                {t(tx.TX_LOGIN_SHORT)}
              </button>
            </div>
          </form>
        </div>
        <div className='csaAuthRegister'>
          <div className='csaAuthRegisterLiner'>
            <Link to={{
              pathname: URL_REGISTER,
              state: { from: URL_CHECKOUT }
            }}>
              <div className='csTextLink'>{t(tx.TX_CREATE_ACCOUNT)}</div>
            </Link>
          </div>
        </div>
      </div>
      {this.state.showGuestForm ?
        <div className='csaGuestInputBlock'>
          <div className={'csaGuestInputLiner'}>
            <form className={'checkoutStepInputForm singleColumn'} onSubmit={this.handleGuestSubmit.bind(this)}>
              <div className='checkoutFormSubheader'>{t(tx.TX_CHECKOUT_CHECKOUT_AS_GUEST)}</div>
              <div className={this.state.guestReqError ? 'checkoutFormServerError present' : 'checkoutFormServerError'}>
                <div className='FlexCenter'>{t(this.state.guestReqError)}</div>
              </div>
              <div className='checkoutFormInput'>
                <div className='checkoutFormLabel required'>{t(tx.TX_FIRST_NAME)}</div>
                <input
                  type='text'
                  autoComplete='given-name'
                  className={this.state.errorFirstName ? 'InputError' : ''}
                  value={this.state.inputFirstName}
                  onChange={this.changeFirstName.bind(this)}
                  onBlur={this.validateFirstName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_FIRST_NAME)} />
                <div 
                  className={'checkoutFormError FieldError'}
                  dangerouslySetInnerHTML={{__html: this.state.errorFirstName ? t(this.state.errorFirstName) : ''}} />
              </div>
              <div className='checkoutFormInput'>
                <div className='checkoutFormLabel required'>{t(tx.TX_LAST_NAME)}</div>
                <input
                  type='text'
                  autoComplete='family-name'
                  className={this.state.errorLastName ? 'InputError' : ''}
                  value={this.state.inputLastName}
                  onChange={this.changeLastName.bind(this)}
                  onBlur={this.validateLastName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_LAST_NAME)} />
                <div 
                  className={'checkoutFormError FieldError'}
                  dangerouslySetInnerHTML={{__html: this.state.errorLastName ? t(this.state.errorLastName) : ''}} />
              </div>
              <div className='checkoutFormInput'>
                <div className='checkoutFormLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
                <input
                  type='email'
                  autoComplete='email'
                  className={this.state.errorGuestEmail ? 'InputError' : ''}
                  value={this.state.inputGuestEmail}
                  onChange={this.changeGuestEmail.bind(this)}
                  onBlur={this.validateGuestEmail.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_EMAIL)} />
                <div 
                  className={'checkoutFormError FieldError'}
                  dangerouslySetInnerHTML={{__html: this.state.errorGuestEmail ? t(this.state.errorGuestEmail) : ''}} />
              </div>
              <div className={'checkoutFormInput checkoutFormAction'}>
                <button 
                  className='checkoutFormButton'
                  disabled={this.state.guestReqPending}>
                  {t(tx.TX_NEXT)}
                </button>
              </div>
            </form>
          </div>
        </div> :
        <div className='csaGuestBlock'>
          <div className={'csaGuestLiner'}>
            <div className='csaGuestIconWrapper'>
              <Icon 
                value={ICON_GUEST}  
                iconClass={'guestIcon'} 
                iconLabel={null} 
                ellipsisLabel={false} />
            </div>
            <div className='csaGuestActionWrapper'>
              <div className='csTextLink' onClick={this.toggleGuestForm.bind(this)}>{t(tx.TX_CHECKOUT_CHECKOUT_AS_GUEST)}</div>
            </div>
          </div>
        </div>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepAuthInput));