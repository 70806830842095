import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../store';

import * as _ from 'underscore';

import { CSS_SLIDE_DURATION } from '../../../constants/css';
import { MKT_NOTICE_KEY_HEADER } from '../../../constants/marketing';
import * as tx from '../../../constants/strings';
import { 
  URL_BUYLIST_CHECKOUT, 
  URL_CHECKOUT, 
} from '../../../constants/urls';

import { viewCartEvent } from '../../../utils/analytics';
import { getCurrencySymbol } from '../../../utils/currency';
import { formatPrice } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import Confirm from '../../Popups/Confirm';
import LoadingIcon from '../../Icons/LoadingIcon';
import MiniCart from '../MiniCart';

import '../style/_minicartslider.scss';

import * as cartActionCreators from '../../../actions/cart';
let allActionCreators = Object.assign({}, cartActionCreators);

export class MiniCartSlider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.cartBodyRef = React.createRef();
    this.scrollRef = React.createRef();

    this.openScrollTimeout = null;
    this.closeScrollTimeout = null;

    this.checkClick = this.checkClick.bind(this);
    this.updateHeightThrottled = _.throttle(this.updateHeight.bind(this), 100);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
    window.addEventListener('resize', this.updateHeightThrottled, false);
  }

  componentDidUpdate(prevProps, prevState) {
    // When closed reset scrollable element to top
    if(prevProps.cart.minicartSliderOpen === true && this.props.cart.minicartSliderOpen === false && this.scrollRef && this.scrollRef.current) {
      this.closeScrollTimeout = setTimeout(() => {
        this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth'});
      }, CSS_SLIDE_DURATION);
    }

    // When opening, check scroll height; then check again once fully opened in case there is resizing and sliding
    if(prevProps.cart.minicartSliderOpen === false && this.props.cart.minicartSliderOpen === true) {
      this.updateHeight();
      this.openScrollTimeout = setTimeout(() => {
        this.updateHeight();

        // Fire view_cart analytics event when slider opens
        if(!this.props.cart.buylistMode) {
          viewCartEvent(this.getCart(), {});
        }

      }, CSS_SLIDE_DURATION);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
    window.removeEventListener('resize', this.updateHeightThrottled, false);
    
    clearTimeout(this.openScrollTimeout);
    clearTimeout(this.closeScrollTimeout);
  }

  updateHeight() {
    if(this.cartBodyRef && this.cartBodyRef.current) {

      const top = this.cartBodyRef.current.getBoundingClientRect().top;
      const height = this.cartBodyRef.current.getBoundingClientRect().height;

      if(window.innerHeight !== top + height) {
        this.cartBodyRef.current.style.height = `${window.innerHeight - top}px`;
      }
    }
  }

  checkClick(evt) {
    if(this.props.cart.minicartSliderOpen === true) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.cartBodyRef.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.props.cartToggleMinicartSlider();
    }  
  }

  moveToCheckout() {
    if(this.props.cart.buylistMode) {
      history.push(URL_BUYLIST_CHECKOUT);
    } else {
      history.push(URL_CHECKOUT);
    }
    this.props.cartToggleMinicartSlider();
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  setLoading(isLoading) {
    this.setState({ loading: isLoading });
  }

  shouldShowToggle() {
    if(!this.props.cart.buylistMode && this.props.cart.currentBuylistCart.isEmpty() === true) {
      return false;
    }
    return true;
  }

  toggleCartMode(evt) {
    if(evt) { evt.stopPropagation(); }
    this.props.setBuylistMode(!this.props.cart.buylistMode);
  }

  getCart() {
    return this.props.cart.buylistMode ? this.props.cart.currentBuylistCart : this.props.cart.currentCart;
  }

  toggleClearAll() {
    this.setState({ clearConfirmOpen: !this.state.clearConfirmOpen });
  }

  async clearCartAction() {
    const clearMethod = this.props.cart.buylistMode ? this.props.cartBuylistClear : this.props.cartClear;

    await clearMethod(this.getCart().publicUuid)
      .catch((errResp) => {
        console.error(errResp);
      });

    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    })
  }

  headerNoticeOpen() {
    if(this.props.store.noticesDismissed.includes(MKT_NOTICE_KEY_HEADER)) { return null; }
    const allNotices = this.props.store.notices;
    for(const nt of allNotices) {
      if(nt.position === MKT_NOTICE_KEY_HEADER) {
        return true;
      }
    }
    return false;
  }

  render() {

    const {t} = this.props;
    const itemCount = this.getCart() ? this.getCart().quantity : 0;

    return <div className={`MiniCartSlider ${this.props.cart.minicartSliderOpen ? 'open' : ''} ${this.headerNoticeOpen() ? 'notified' : ''}`} ref={this.cartBodyRef}>
      <div className='miniCartSliderTitleWrapper'>
        <div className='miniCartSliderTitle'>{t(this.props.cart.buylistMode ? tx.TX_BUYLIST_TITLE : tx.TX_CART_SHOPPING_CART)}</div>
        {this.shouldShowToggle() ?
          <div className='miniCartSliderToggle' onClick={this.toggleCartMode.bind(this)}>{t(this.props.cart.buylistMode ? tx.TX_CART_VIEW_SHOPPING_CART : tx.TX_CART_VIEW_BUYLIST_CART)}</div> :
          null
        }
        {itemCount > 0 ?
          <div className='miniCartClearAll'>
            <div className={'miniCartClearAction'} onClick={this.toggleClearAll.bind(this)}>{t(tx.TX_CLEAR_ALL)}</div>
          </div> :
          null
        }
      </div>
      <div className='miniCartSliderCartWrapper' ref={this.scrollRef}>
        <MiniCart
          isBuylist={this.props.cart.buylistMode}
          setParentLoading={this.setLoading.bind(this)} />
      </div>
      <div className='miniCartActionWrapper'>
        <div className='miniCartInfoWrapper'>
          <div className='mcInfoLabel'>{t(tx.TX_SUBTOTAL)}</div>
          <div className='mcInfoCount'>({itemCount === 1 ? t(tx.TX_CART_ITEM_COUNT_ONE) : t(tx.TX_CART_ITEM_COUNT, { count: itemCount })})</div>
          <div className='mcInfoPriceWrapper'>
            <div className='mcInfoPrice'>
              <div className='mcCurrencySymbol'>{getCurrencySymbol()}</div>
              <div className='mcPriceValue'>{formatPrice(this.getCart().subTotal, { language: this.getLanguage(), omitSymbol: true })}</div>
            </div>
          </div>
        </div>
        <div className='miniCartButtonWrapper'>
          <button 
            type='button' 
            className='miniCartCheckoutButton'
            disabled={itemCount === 0}
            onClick={this.moveToCheckout.bind(this)}>
            {t(this.props.cart.buylistMode ? tx.TX_CART_CHECKOUT_BUTTON : tx.TX_CART_CHECKOUT_BUTTON)}
          </button>
        </div>
      </div>
      <div className='miniCartLoadingScreen' style={this.state.loading ? { display: 'block' } : {}}>
        <div className='miniCartLoadingScreenIcon'>
          <LoadingIcon />
        </div>
      </div>
      <Confirm
        title={tx.TX_CART_CLEAR_CONFIRM_TITLE}
        copy={tx.TX_CART_CLEAR_CONFIRM_COPY}
        open={this.state.clearConfirmOpen}
        confirmValue={tx.TX_CLEAR}
        closeMethod={this.toggleClearAll.bind(this)}
        onConfirm={this.clearCartAction.bind(this)}
        storeTheme={true} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    store: state.store,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(MiniCartSlider));