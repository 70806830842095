import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import ProductLinePricingMatrixRuleEdit from './ProductLinePricingMatrixRuleEdit';

import '../../style/_productlinepricing.scss';

const allActionCreators = Object.assign({});

export class ProductLinePricingMatrixEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      addRowOpen: false,
    };
  }

  openAddRow() {
    this.setState({ addRowOpen: true });
  }

  closeAddRow() {
    this.setState({ addRowOpen: false });
  }

  getOrderedRules() {
    if(!this.props.pricingMatrix) { return []; }

    const orderedRules = [];
    for(const ln of this.props.productLineObj.languages) {
      for(const cd of this.props.productLineObj.getAllConditions()) {
        for(const ru of this.props.pricingMatrix.rules) {
          if(ru.condition && ru.language && ru.condition.key === cd.key && ru.language.code === ln.code) {
            orderedRules.push(ru);
            break;
          }
        }
      }
    }

    // Null language
    for(const cd of this.props.productLineObj.getAllConditions()) {
      for(const ru of this.props.pricingMatrix.rules) {
        if(ru.condition && ru.language === null && ru.condition.key === cd.key) {
          orderedRules.push(ru);
          break;
        }
      }
    }

    // Null both
    for(const ru of this.props.pricingMatrix.rules) {
      if(ru.condition === null && ru.language === null) {
        orderedRules.push(ru);
        break;
      }
    }

    return orderedRules;
  }

  getMatrixTitle(t = null) {
    if(!t || !this.props.pricingMatrix) { return ''; }

    if(this.props.pricingMatrix.isDefault) {
      return t(tx.TX_DEFAULT);
    } else if(this.props.pricingMatrix.finish) {
      return `${t(tx.TX_FILTER_FINISH)}: ${t(this.props.pricingMatrix.finish.name)}`;
    }
    return '';
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductLinePricingMatrixEdit'}>
      <div className='editRulesLiner'>
        {this.props.pricingMatrix && this.props.pricingMatrix.rules.length > 0 ?
          <>
            <div className='priceRulesTitleWrapper'>
              <div className='priceRulesTitle FlexCenter'>{this.getMatrixTitle(t)}</div>
            </div>
            <div className='priceRulesHeaderWrapper'>
              <div className='priceRulesHeader'>
                <div className='headerLabel languageLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenterLeft'>{t(tx.TX_LANGUAGE)}</div>
                  </div>
                </div>
                <div className='headerLabel conditionLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenter'>{t(tx.TX_CONDITION)}</div>
                  </div>
                </div>
                <div className='headerLabel priceLabel sellPriceLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenter'>{t(tx.TX_SELL_PRICE)}</div>
                  </div>
                </div>
                <div className='headerLabel priceLabel buyPriceLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenter'>{t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}</div>
                  </div>
                </div>
                <div className='headerLabel actionsLabel'>
                  <div className='headerValueWrapper'>
                    <div className='headerValue FlexCenterLeft'></div>
                  </div>
                </div>
              </div>
            </div>
            {this.getOrderedRules().map((rule, i) => {
              return <div key={i} className='priceRuleWrapper'>
                <ProductLinePricingMatrixRuleEdit
                  rule={rule}
                  priceMatrix={this.props.pricingMatrix}
                  productLineObj={this.props.productLineObj} 
                  makeClean={this.props.makeClean}/>
              </div>;
            })}
          </> :
          <>
            {this.state.addRowOpen === false ?
              <div className='priceRulesNullWrapper'>
                <div className='priceRulesNull'>
                  <div className='priceRulesNullValue'>{t(tx.TX_NONE)}</div>
                </div>
              </div> :
              null
            }
          </>
        }
        {this.state.addRowOpen ?
          <div className='priceRuleEmptyRowWrapper'>
            <div className='priceRuleWrapper'>
              <ProductLinePricingMatrixRuleEdit
                rule={{}}
                parentCloseMethod={this.closeAddRow.bind(this)}
                priceMatrix={this.props.pricingMatrix}
                productLineObj={this.props.productLineObj}
                makeClean={this.props.makeClean} />
            </div>
          </div> :
          <div className='priceRulesAddWrapper'>
            <div className='priceRulesAdd'>
              <div className='priceRulesToggle' onClick={this.openAddRow.bind(this)}>{t(tx.TX_RULE)}</div>
            </div>
          </div>
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLinePricingMatrixEdit));