
import * as x from '../constants/actions';


const initialState = {
  calendarEvents: {},
  calendarSchedules: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_EVENTS_SET_MONTH:
      return eventSetMonthEvents(state, action);
    case x.ACTION_EVENTS_CLEAR:
    	return eventClearEvents(state, action);
    default:
      return state;
  }
}

function eventSetMonthEvents(state, action) {
  
	let existingEvents = Object.assign({}, state.calendarEvents);
	let existingSchedules = Object.assign({}, state.calendarSchedules);

	if(!existingEvents[action.payload.year]) {
		existingEvents[action.payload.year] = {
			[action.payload.month]: action.payload.events,
		};
	} else {
		existingEvents[action.payload.year] = Object.assign({}, existingEvents[action.payload.year], {
			[action.payload.month]: action.payload.events,
		});
	}

	if(!existingSchedules[action.payload.year]) {
		existingSchedules[action.payload.year] = {
			[action.payload.month]: action.payload.schedules,
		};
	} else {
		existingSchedules[action.payload.year] = Object.assign({}, existingSchedules[action.payload.year], {
			[action.payload.month]: action.payload.schedules,
		});
	}

  return Object.assign({}, state, {
  	calendarEvents: existingEvents,
  	calendarSchedules: existingSchedules,
  });
}

function eventClearEvents(state, action) {
  return Object.assign({}, state, {
  	calendarEvents: {},
  	calendarSchedules: {},
  });
}