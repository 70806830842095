import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  checkoutGetNoPaymentsRequired,
  checkoutGetSelectedPaymentMethod,
} from '../../../../../utils/checkout';

import NoPaymentStoreCredit from '../../payment-methods/no-payment/NoPaymentStoreCredit';

let allActionCreators = Object.assign({});

export class CheckoutStepPaymentDetailsInput extends Component {

  getInputComponent() {
    
    if(checkoutGetNoPaymentsRequired(this.props.checkout.stepData)) {
      return <NoPaymentStoreCredit
                config={this.props.config}
                setStepData={this.props.setStepData} />;
    }

    const paymentMethod = checkoutGetSelectedPaymentMethod(this.props.checkout.stepData);
    const InputComponent = paymentMethod.inputComponent;
    
    if(!InputComponent) { return null; }

    return <InputComponent
              config={this.props.config}
              setStepData={this.props.setStepData}
              paymentMethod={paymentMethod} />;
  }

  render() {
    return <div className={'CheckoutStepPaymentDetailsInput'}>{this.getInputComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepPaymentDetailsInput));