import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import { 
  CSS_SLIDE_DURATION 
} from '../../../constants/css';
import { 
  TX_CANCEL, 
  TX_SAVE, 
  TX_null, 
} from '../../../constants/strings';

import '../../Popups/style/_saveprompt.scss';

let allActionCreators = Object.assign({});

export class AdminTableBulkActionModal extends Component {

  // REQUIRED PROPS
  // open: bool
  // closeMethod: function

  // OPTIONAL PROPS
  // copy: ARRAY (overwrites default copy; different than SavePrompt)
  // title: string (overwrites default title)
  // onCancel: function
  // onConfirm: function


  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      hasOpacity: false,
      shouldDisplay: false,
      listenerEnabled: false,
      preventClose: false,
      submitSignal: 0,
      disableSubmit: false,
    };

    // Create refs
    this.modalBody = React.createRef();
    this.checkClick = this.checkClick.bind(this);

    // Timeout management
    this.openTimeout = null;
    this.closeTimeout = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    document.removeEventListener('click', this.checkClick, false);

    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.open !== prevProps.open) {
      if(this.props.open === true) {
        this.openPrompt();
      } else {
        this.closePrompt();
      }
    }
  }

  checkClick(evt) {
    // Removed; uncomment to close when clicked outside of modal
    // if(this.props.open && this.state.listenerEnabled) {
    //   let targetElement = evt.target;
    //   do {
    //       if(targetElement === this.modalBody.current) {
    //           return;
    //       }
    //       targetElement = targetElement.parentNode;
    //   } while (targetElement);
    //   this.props.closeMethod();
    // }  
  }

  openPrompt() {
    this.setState({
      enabled: true,
      shouldDisplay: true,
      listenerEnabled: false,
    }, () => {

      // Delay to trigger CSS animation
      setTimeout(() => {
        this.setState({
          hasOpacity: true,
        });
      }, 25);

      // Delay listening for clicks
      this.openTimeout = setTimeout(() => {
        this.setState({
          listenerEnabled: true,
        });
      }, CSS_SLIDE_DURATION);

      // Fix body
      document.body.style.overflow = 'hidden';
    });
  }

  closePrompt() {
    document.body.style.overflow = 'visible';
    this.setState({
      enabled: false,
      hasOpacity: false,
      listenerEnabled: false,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({
          shouldDisplay: false,
        });
        if(this.props.clearModalData) {
          this.props.clearModalData();
        }
      }, CSS_SLIDE_DURATION);
    });
  }

  getScreenClass() {
    return this.state.hasOpacity ? 'spScreen withOpacity' : 'spScreen';
  }

  getModalStyle() {

    const baseConfig = {
      opacity: this.state.hasOpacity ? '1' : '0',
      top: '250px',
    };

    let verticalConfig = {};

    if(this.props.open && this.modalBody && this.modalBody.current && this.modalBody.current.clientHeight > 0) {
      
      const modalHeight = this.modalBody.current.clientHeight;
      verticalConfig = {
        top: (window.innerHeight - modalHeight)/2 + 'px',
      }
    }

    return Object.assign({}, baseConfig, verticalConfig);
  }

  getTitle() {
    return this.props.bulkActionObj && this.props.bulkActionObj.title ? this.props.bulkActionObj.title : TX_null;
  }

  hasSubmitAction() {
    return this.props.bulkActionObj && this.props.bulkActionObj.submitModalLabel ? true : false;
  }

  getSubmitLabel() {
    return this.props.bulkActionObj && this.props.bulkActionObj.submitModalLabel ? this.props.bulkActionObj.submitModalLabel : TX_SAVE;
  }

  getCloseLabel() {
    return this.props.bulkActionObj && this.props.bulkActionObj.closeModalLabel ? this.props.bulkActionObj.closeModalLabel : TX_CANCEL;
  }

  cancelAction(evt) {
    if(evt) { evt.preventDefault(); }

    if(this.state.preventClose) {
      return null;
    }

    if(this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.closeMethod();
  }

  submitAction(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({ submitSignal: Date.now() });
  }

  getActionComponent() {
    if(!this.props.bulkActionObj || !this.props.bulkActionObj.component) { return null; }

    const ActionComponent = this.props.bulkActionObj.component;
    return <ActionComponent 
              bulkActionObj={this.props.bulkActionObj}
              closeMethod={this.tryToClose.bind(this)}
              selectedRows={this.props.selectedRows}
              filters={this.props.filters}
              staticFilters={this.props.staticFilters}
              sortDir={this.props.sortDir}
              sortKey={this.props.sortKey}
              resultsCount={this.props.resultsCount}
              blockClose={this.blockClose.bind(this)}
              refreshData={this.props.refreshData}
              submitSignal={this.state.submitSignal}
              disableSubmit={this.setDisableSubmit.bind(this)} />;
  }

  blockClose(val) {
    this.setState({ preventClose: val });
  }

  setDisableSubmit(val) {
    this.setState({ disableSubmit: val });
  }

  tryToClose() {
    if(this.state.preventClose) {
      return null;
    }
    this.props.closeMethod();
  }

  render() {

    const {t} = this.props;

    return <div 
            className='AdminTableBulkActionModal'
            style={{
              display: this.state.shouldDisplay ? 'block' : 'none'
            }}
            >
      <div className={this.getScreenClass()}></div>
      <div className='spModalWrapper'>
        <div className='spModal' style={this.getModalStyle()} ref={this.modalBody}>
          <div className='spModalInnerWrapper'>
            <div className='spClose' onClick={this.tryToClose.bind(this)}>&times;</div>
            <div className='spModalTitle'>{t(this.getTitle())}</div>
            <div className='spModalHr'></div>
            <div className='bulkBodyWrapper'>
              {this.getActionComponent()}
              <div className='spModalActions'>
                <button 
                  type='button' 
                  className={'spAction spActionCancel'} 
                  onClick={this.cancelAction.bind(this)}>
                  {t(this.getCloseLabel())}
                </button>
                {this.hasSubmitAction() ?
                  <button 
                    type='button' 
                    className={'spAction spActionSave'} 
                    onClick={this.submitAction.bind(this)}
                    disabled={this.state.preventClose || this.state.disableSubmit}>
                    {t(this.getSubmitLabel())}
                  </button> :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    header: state.header,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTableBulkActionModal));