import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
  ANALYTICS_AGGREGATION_TYPE_PRODUCT,
  ANALYTICS_AGGREGATION_TYPE_PRODUCT_LINE,
  ANALYTICS_CONFIG_AGGREGATION_TYPE,
  ANALYTICS_CONFIG_LIMIT,
  ANALYTICS_CONFIG_METRIC, 
  ANALYTICS_DURATION_UNIT_WEEK, 
  ANALYTICS_METRIC_COUNT,
  ANALYTICS_METRIC_TOTAL_SALES,
} from '../../../../constants/analytics';
import { 
  API_KEY_DURATION,
  API_KEY_IS_BUYLIST, 
  API_KEY_LIMIT,
  API_KEY_METRIC,
} from '../../../../constants/api';
import { ICON_CHEVRON_DOWN } from '../../../../constants/icons';
import { 
  TX_ANALYTICS_TOP_PRODUCT,
  TX_ANALYTICS_TOP_PRODUCT_LINES,
  TX_NO_RESULTS,
  TX_PRODUCT,
  TX_PRODUCT_LINE,
  TX_null,
} from '../../../../constants/strings';

import { getCurrencySymbol } from '../../../../utils/currency';
import { 
  formatPrice,
  formatServerError, 
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import usePrevious from '../../../../hooks/usePrevious';

import { ordersAnalyticsTopList } from '../../../../actions/order';

import HiddenDropdown from '../../../Input/HiddenDropdown';
import { Icon } from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';

import styles from '../../style/BlockAnalyticsTopList.module.scss';

export const BlockAnalyticsTopList = (props) => {
  
  // Dispatch
  const dispatch = useDispatch();

  // Props
  const {
    block, 
  } = props;

  // State
  const [data, setData] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [duration, setDuration] = useState(block && block.config && block.config.init && block.config.init.duration ? block.config.init.duration : `1${ANALYTICS_DURATION_UNIT_WEEK}`);

  // Refs
  const controllerRef = useRef(null);

  // Internationalization
  const { i18n, t } = useTranslation();

  // Previous data
  const prevDuration = usePrevious(duration);

  // Methods
  const fetchData = useCallback(async (requestConfig) => {

    setDataError(null);
    setDataLoading(true);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const getData = {
      [API_KEY_DURATION]: duration,
      [API_KEY_IS_BUYLIST]: false,
      [API_KEY_LIMIT]: block.getConfigAttr(ANALYTICS_CONFIG_LIMIT),
      [API_KEY_METRIC]: block.getConfigAttr(ANALYTICS_CONFIG_METRIC),
    };

    const aggregationType = block.getConfigAttr(ANALYTICS_CONFIG_AGGREGATION_TYPE);

    // Fetch data
    const resp = await dispatch(ordersAnalyticsTopList(aggregationType, getData, requestConfig, controller.signal))
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        setDataError(formatServerError(errResp));
        setDataLoading(false);
      });

    if(!resp) {
      return null;
    }

    setData(resp);
    setDataLoading(false);

  }, [ dispatch, duration, block ]);

  const getMetricSymbol = () => {

    const metric = block.getConfigAttr(ANALYTICS_CONFIG_METRIC);
    
    switch(metric) {
      case ANALYTICS_METRIC_COUNT:
        return '#';
      case ANALYTICS_METRIC_TOTAL_SALES:
        return getCurrencySymbol();
      default:
        return '';
    }
  };

  const getTitle = () => {

    if(!block) { return t(TX_null); }

    const aggregation = block.getConfigAttr(ANALYTICS_CONFIG_AGGREGATION_TYPE);
    const metricValue = getMetricSymbol() ? `(${getMetricSymbol()})` : '';

    switch(aggregation) {
      case ANALYTICS_AGGREGATION_TYPE_PRODUCT:
        return t(TX_ANALYTICS_TOP_PRODUCT, { metric: metricValue });
      case ANALYTICS_AGGREGATION_TYPE_PRODUCT_LINE:
        return t(TX_ANALYTICS_TOP_PRODUCT_LINES, { metric: metricValue });
      default:
        return t(TX_null);
    }
  };

  const getAggregationLabel = () => {

    if(!block) { return TX_null; }

    const aggregation = block.getConfigAttr(ANALYTICS_CONFIG_AGGREGATION_TYPE);

    switch(aggregation) {
      case ANALYTICS_AGGREGATION_TYPE_PRODUCT:
        return TX_PRODUCT;
      case ANALYTICS_AGGREGATION_TYPE_PRODUCT_LINE:
        return TX_PRODUCT_LINE;
      default:
        return TX_null;
    }
  };

  const handleDurationChange = (evt) => {
    setDuration(evt.target.value);
  }

  const getDurationLabel = () => {
    if(!data) { return TX_null; }

    const options = data.getDurationOptions() || [];
    for(const opt of options) {
      if(opt.value === duration) {
        return opt.display;
      }
    }

    return TX_null;
  }

  const getDatumValue = (datum) => {

    const metric = block.getConfigAttr(ANALYTICS_CONFIG_METRIC);
    
    switch(metric) {
      case ANALYTICS_METRIC_COUNT:
        return datum[metric].toLocaleString(getStoreLanguage(i18n));
      case ANALYTICS_METRIC_TOTAL_SALES:
        return formatPrice(datum[metric], { addTags: false, language: getStoreLanguage(i18n) });
      default:
        return '';
    }
  }

  // Effects
  useEffect(() => {
    if(data === null) {
      fetchData({});
    }
  }, [ data, fetchData ]);

  useEffect(() => {
    if(data !== null && prevDuration && duration !== prevDuration) {
      fetchData({});
    }
  }, [ data, duration, fetchData, prevDuration ]);

  // Render
  return (
    <div className={`${styles.BlockAnalyticsTopList}`}>
      <div className={styles.headerWrapper}>
        <div className={`${styles.titleWrapper} FlexCenter`}>{getTitle()}</div>
      </div>
      <div className={styles.bodyWrapper}>
        {dataLoading || data === null ?
          <div className={styles.loadingWrapper}>
            <div className={styles.iconWrapper}>
              <LoadingIcon />
            </div>
          </div> :
          <>
            {dataError ?
              <div className={styles.errorWrapper}>{t(dataError)}</div> :
              <div className={styles.dataWrapper}>
                <div className={`${styles.dataRow} ${styles.dataHeader} ${styles.shortValue}`}>
                  <div className={styles.dataRowLiner}>
                    <div className={`${styles.dataLabel}`}>{t(getAggregationLabel())}</div>
                    <div className={styles.dataValue}>{getMetricSymbol()}</div>
                  </div>
                </div>
                {data.displayData(block.getConfigAttr(ANALYTICS_CONFIG_AGGREGATION_TYPE)).length === 0 ?
                  <div className={styles.noResultsWrapper}>
                    <div className={styles.noResults}>{t(TX_NO_RESULTS)}</div>
                  </div> :
                  <>
                    {data.displayData(block.getConfigAttr(ANALYTICS_CONFIG_AGGREGATION_TYPE)).map((datum, i) => {
                      return <div key={i} className={`${styles.dataRow} ${block.getConfigAttr(ANALYTICS_CONFIG_METRIC) === ANALYTICS_METRIC_COUNT ? styles.shortValue : ''}`}>
                        <div className={styles.dataRowLiner}>
                          <div className={`${styles.dataLabel} EllipsisElement`}>{(datum.name)}</div>
                          <div className={styles.dataValue}>{getDatumValue(datum)}</div>
                        </div>
                      </div>
                    })}
                  </>
                }
                <HiddenDropdown 
                  options={data.getDurationOptions()}
                  value={duration}
                  onChange={handleDurationChange}>
                  <div className={styles.durationWrapper}>
                    <div className={styles.durationValue}>{t(getDurationLabel())}</div>
                    <div className={styles.durationIcon}>
                      <div className={styles.iconWrapper}>
                        <Icon value={ICON_CHEVRON_DOWN} />
                      </div>
                    </div>
                  </div>
                </HiddenDropdown>
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};

export default BlockAnalyticsTopList;




