import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../../../store';

import { CHECKOUT_STEP_SHIPPING_ADDRESS } from '../../../../../constants/checkout';
import { ERROR_CATCH_ALL } from '../../../../../constants/errors';
import * as tx from '../../../../../constants/strings';
import { 
  URL_CHECKOUT_TRANSBANK_COMPLETE,
} from '../../../../../constants/urls';

import { getSessionId } from '../../../../../utils/auth';
import { 
  checkoutGetIsStoreCreditApplied, 
  checkoutGetSelectedPaymentMethod,
  checkoutGetSelectedShippingMethod,
  getCartGrandTotal, 
  getCheckoutStepData, 
} from '../../../../../utils/checkout';
import { getCurrencyMinorCount } from '../../../../../utils/currency';
import { 
  formatServerError,
  stringFormat, 
} from '../../../../../utils/formatting';

import CartSummary from '../../../../Cart/CartSummary';
import LoadingIcon from '../../../../Icons/LoadingIcon';

import * as checkoutActionCreators from '../../../../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class TransbankPaymentInit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      submitReqError: null,
      submitReqPending: false,
    };

    this.paymentMethod = checkoutGetSelectedPaymentMethod(this.props.checkout.stepData);
    this.shippingMethod = checkoutGetSelectedShippingMethod(this.props.checkout.stepData);
    this.shippingAddress = this.getShippingAddress();

    this.checkNav = this.checkNav.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.checkNav);
    this.unblock = history.block((blobj, action) => {
      if(this.state.submitReqPending === false) {
        return true;
      }
      return false;
    });
  }

  componentWillUnmount() {
    this.unblock();
    document.body.style.overflow = 'visible';
    window.removeEventListener('beforeunload', this.checkNav);
  }

  checkNav(evt) {
    if(this.state.submitReqPending) {
      evt.preventDefault();
    }
    return '';
  }

  loadingStart(cb) {
    document.body.style.overflow = 'hidden';
    this.setState({
      submitReqError: null,
      submitReqPending: true,
    }, () => {
      if(cb) { cb(); }
    });
  }

  loadingEnd(err, cb) {
    document.body.style.overflow = 'visible';
    this.setState({
      submitReqError: err || null,
      submitReqPending: false,
    }, () => {
      if(cb) { cb(); }
    });
  }

  handleSubmit(evt) {
    if(evt) { evt.preventDefault(); }
    this.completeSave();
  }

  getShippingAddress() {
    const stepData = getCheckoutStepData(CHECKOUT_STEP_SHIPPING_ADDRESS, this.props.checkout.stepData);
    if(stepData && stepData.data && stepData.data.shippingAddress) {
      return stepData.data.shippingAddress;
    }
    return null;
  }

  getBuyOrderNumber() {
    const sessionId = getSessionId();
    const buyOrder = `tbk|${sessionId.substring(sessionId.length - 6)}|${Date.now()}`;

    return buyOrder;
  }

  async completeSave() {

    this.loadingStart(async () => {

      const paymentData = {
        payment_method_uuid: this.paymentMethod.publicUuid,
        total_cost: Math.round(getCartGrandTotal(this.props.cart.currentCart, this.props.checkout.stepData) * getCurrencyMinorCount()),

        buy_order: this.getBuyOrderNumber(),
        return_url: `${window.location.origin}${stringFormat(URL_CHECKOUT_TRANSBANK_COMPLETE, { publicUuid: '' })}`,

        shipping_address_uuid: this.shippingAddress.publicUuid,
        shipping_method_uuid: this.shippingMethod.publicUuid,

        use_store_credit: checkoutGetIsStoreCreditApplied(this.props.checkout.stepData),
      };
      
      const paymentResp = await this.props.checkoutInitiatePaymentTransbank(paymentData)
        .catch((errResp) => {
          console.error(errResp);
          this.loadingEnd(formatServerError(errResp));
        });

      if(!paymentResp || !paymentResp.publicUuid) { return null; }

      if(paymentResp.additionalData && paymentResp.additionalData.url && paymentResp.additionalData.token) {
        // Success; redirect user
        window.location.href = `${paymentResp.additionalData.url}?token_ws=${paymentResp.additionalData.token}`;
      } else {
        // Display error
        this.loadingEnd(ERROR_CATCH_ALL);
      }
    });
  }


  render() {

    const {t} = this.props;

    return <div className={'TransbankPaymentInit CheckoutStepComponent'}>
      <div className='pmiLiner'>
        <div className={this.state.submitReqError ? 'transbankServerError present' : 'transbankServerError'}>
          {this.state.submitReqError ? t(this.state.submitReqError) : ''}
        </div>
        <form 
          className={'checkoutStepInputForm'} 
          onSubmit={this.handleSubmit.bind(this)}>
          <div className='checkoutSummaryWrapper'>
            <div className='summaryTitle'>{t(tx.TX_CART_SUMMARY_TITLE)}</div>
            <CartSummary
              cartOverride={null}
              cartLoading={this.state.cartLoading} />
          </div>
          <div className={'checkoutFormInput checkoutFormAction placeOrderAction'}>
            <button 
              type='submit'
              className='checkoutFormButton'
              disabled={this.state.submitReqPending}>
              {t(tx.TX_CHECKOUT_PLACE_ORDER)}
            </button>
          </div>
        </form>
      </div>
      <div className={`checkoutSubmitScreen ${this.state.submitReqPending ? 'active' : ''}`}>
        <div className='submitScreenLiner'>
          <div className='submitIconWrapper'>
            <LoadingIcon />
          </div>
          <div className='submitIconLabel'>{t(tx.TX_CHECKOUT_PLACING_ORDER)}</div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    checkout: state.checkout,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(TransbankPaymentInit));