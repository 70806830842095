import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  PL_PERMALINK_LORCANA, 
  PL_PERMALINK_MAGIC, 
  PL_PERMALINK_POKEMON, 
  PL_PERMALINK_STARWARS, 
} from '../../../constants/product';
import * as tx from '../../../constants/strings';

import AddProductPricingGeneric from './AddProductPricingGeneric';
import AddProductPricingSynced from './AddProductPricingSynced';
import { LoadingIcon } from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class AddProductStep2 extends Component {

  getPricingForm() {

    if(!this.props.productObj || !this.props.productObj.productLine) {
      return null;
    }

    if(this.props.productObj.productLine.isManaged === false || this.props.genericOverride) {
      return <AddProductPricingGeneric
               productLine={this.props.productObj.productLine} 
               makeDirty={this.props.makeDirty.bind(this)}
               movePrev={this.props.movePrev}
               moveNext={this.props.moveNext}
               inventoryObj={this.props.inventoryObj}
               setInventory={this.props.setInventory}
               validationSignal={this.props.validationSignal} />;
    }

    const SYNCED_PRODUCT_LINES = [
      PL_PERMALINK_LORCANA,
      PL_PERMALINK_MAGIC,
      PL_PERMALINK_POKEMON,
      PL_PERMALINK_STARWARS,
    ];

    if(SYNCED_PRODUCT_LINES.includes(this.props.productObj.productLine.permalink)) {
      return <AddProductPricingSynced
               productLine={this.props.productObj.productLine} 
               makeDirty={this.props.makeDirty.bind(this)}
               saveProduct={this.props.saveProduct}
               movePrev={this.props.movePrev}
               moveNext={this.props.moveNext}
               productObj={this.props.productObj}
               inventoryObj={this.props.inventoryObj}
               setInventory={this.props.setInventory}
               validationSignal={this.props.validationSignal} />;
    }

    return null;
  }

  render() {

    const {t} = this.props;

    return <div className={'AddProductStep2'}>
      <div className='adminForm'>
        <div className='adminFormTitle'>
          <div className='afTitleWrapper'>{t(tx.TX_INV_ADD_PRODUCT_STEP_2_TITLE)}</div>
        </div>
        <div className={this.props.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.props.requestError)}</div>
        <div className='ap2Body'>
          {this.getPricingForm()}
        </div>
        <div className='adminFormPending' style={{display: this.props.requestPending ? 'block' : 'none'}}>
          <div className='adminFormPendingScreen'></div>
          <div className='adminFormPendingWrapper'>
            <LoadingIcon />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductStep2));