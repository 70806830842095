
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';

import LoadingIcon from '../Icons/LoadingIcon';
import SearchableDropdownNull from './searchable-dropdown/SearchableDropdownNull';

import './style/_dropdown.scss';

let allActionCreators = Object.assign({});

// Props to be included, all other ignored
const dropdownFilterProps = [
	'id',
	'placeholder', 
	'name',
	'required', 
];

const includes = (obj, includeKeys) =>
	Object.keys(obj).reduce((result, key) => {
		if(includeKeys.includes(key)) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export class SearchableDropdown extends Component {

	constructor(props) {
    super(props);

    this.OPEN_DELAY = 200;

    this.state = {

    	inputSearchable: '', 

    	dropdownActive: false,
      dropdownClosable: false,

      navIndex: -1,
    };

    this.closeTimeout = null;

    this.dropdownWrapperRef = React.createRef();

    this.checkClick = this.checkClick.bind(this);
    this.checkKeypress = this.checkKeypress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
    window.addEventListener('keydown', this.checkKeypress, false);
  }

  componentDidUpdate(prevProps, prevState) {
  	if(prevProps.options === null && this.props.options !== null) {
  		this.openDropdown();
  	} else if(prevProps.options !== null && this.props.options === null) {
  		this.closeDropdown();
  	}

  	// Reset input if resetSignal received
  	if(this.props.resetSignal && prevProps.resetSignal !== this.props.resetSignal) {
  		this.setState({ inputSearchable: '' });
  	}
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
    window.removeEventListener('keydown', this.checkKeypress, false);
  }

  checkClick(evt) {
    if(this.state.dropdownActive && this.state.dropdownClosable) {
      let targetElement = evt.target;
      do {
        if(this.dropdownWrapperRef && targetElement === this.dropdownWrapperRef.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeDropdown();
    }
  }

  checkKeypress(evt) {
    if(evt) {
      switch(evt.key) {
        case 'ArrowUp':
        	evt.preventDefault();
          this.setState({ navIndex: Math.max(this.state.navIndex - 1, 0) });
          break;
        case 'ArrowDown':
        	evt.preventDefault();
          this.setState({ navIndex: Math.min(this.state.navIndex + 1, this.props.options ? this.props.options.length - 1 : 0) });
          break;
        case 'Enter':
        	evt.preventDefault();
        	const filteredOption = this.getFilteredOptions()[this.state.navIndex];
        	if(filteredOption) {
        		this.handleSelect(filteredOption.value);
        	}
          break;
        case 'Escape':
        	evt.preventDefault();
          this.closeDropdown();
          break;
        default:
          break;
      }
    }
  }

  openDropdown() {
  	this.setState({
      dropdownActive: true,
      dropdownClosable: false,
    }, () => {
      this.closeTimeout = setTimeout(() => {
        this.setState({ dropdownClosable: true });
      }, this.OPEN_DELAY);
    });
  }

  closeDropdown() {
    this.setState({ 
      dropdownActive: false,
      dropdownClosable: false,
      navIndex: -1,
    });
  }

	getRootClass() {
		const propClass = this.props.className ? `${this.props.className}` : '';
		const themeClass = this.props.adminTheme ? 'titanTheme' : '';
		const errorClass = this.props.error ? 'error' : '';

		return `SearchableDropdown ${propClass} ${themeClass} ${errorClass}`;
	}

	handleFocus(evt) {
		if(this.props.options !== null && !this.state.dropdownActive) {
			this.openDropdown();
		}
		if(this.props.onFocus) {
			this.props.onFocus(evt);
		}
	}

	handleBlur(evt) {
		if(this.props.onBlur) {
			this.props.onBlur(evt);
		}
	}

	handleChange(evt) {
		this.setState({ 
			inputSearchable: evt.target.value 
		}, () => {
			if(this.props.onChange) {
				this.props.onChange(evt);
			}
			if(this.props.options !== null && !this.state.dropdownActive) {
				this.openDropdown();
			}
		});
	}

	handleSelect(val) {
		if(this.props.selectOption) {
			this.props.selectOption(val);
		}
	}

	mouseEnterAction(idx) {
  	if(idx !== this.state.navIndex) {
  		this.setState({ navIndex: idx });
  	}
  }
	
	mouseLeaveAction(idx) {
		if(idx === this.state.navIndex) {
			this.setState({ navIndex: -1 });
		}
	}

	getFilteredOptions() {
		if(!this.props.options) { return []; }

		const filteredOptions = [];
		for(const op of this.props.options) {
			if(op.display.toLowerCase().includes(this.state.inputSearchable.toLowerCase())) {
				filteredOptions.push(op);
			}
		}
		return filteredOptions;
	}

	render() {
		
		const {t} = this.props;
		const filteredProps = includes(this.props, dropdownFilterProps);
		
		return <div className={this.getRootClass()} ref={this.dropdownWrapperRef}>
			<div className={`searchableDropdownLiner`}>
				{this.props.loading ?
					<div className='contentLoading'>
						<div className='loadingLiner'>
							<div className='iconSection'>
								<div className='iconWrapper'>
									<LoadingIcon />
								</div>
							</div>
							<div className='copySection'>
								<div className='copyWrapper'>{t(tx.TX_LOADING)}</div>
							</div>
						</div>
					</div> :
					<div className='contentLoaded'>
						<input 
							{...filteredProps} 
							className={`searchableDropdownInput ${this.props.error ? ' error' : ''}`}
							value={this.state.inputSearchable}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							onChange={this.handleChange.bind(this)} />

						<div className={`optionsWrapper ${this.state.dropdownActive ? 'open' : ''}`}>
				    	{this.props.options !== null ?
					    	<div className='optionsLiner'>
					    		{this.getFilteredOptions().length ?
					    			<div className='optionsList'>
					    				{this.getFilteredOptions().map((op, i) => {
					    					return <div 
					    										key={i} 
					    										className={`optionElementWrapper ${this.state.navIndex === i ? 'hovered' : ''}`}
					    										onMouseEnter={() => this.mouseEnterAction(i)}
		                    					onMouseLeave={() => this.mouseLeaveAction(i)}
		                    					onClick={() => this.handleSelect(op.value)}>
		                    					<div className='optionElement'>
		                    						{op.display}
		                    					</div>
		                    				</div>
					    				})}
					    			</div> :
					    			<div className='optionsNull'>
					    				{this.props.nullComponent ?
					    					<div className='nullWrapper'>{this.props.nullComponent}</div> :
					    					<div className='nullWrapper'>
					    						<SearchableDropdownNull 
					    							addAction={this.props.addAction}
					    							addLabel={this.props.addLabel}
					    							adminTheme={this.props.adminTheme}
					    							lastQuery={this.state.inputSearchable} />
					    					</div>
					    				}
					    				
					    			</div>
					    		}
					    	</div> :
					    	null
					    }
				    </div>
					</div>
				}
			</div>
		</div>;
	}
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(SearchableDropdown));