import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_SELL } from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { 
  formatPrice, 
  serverDateShort,
  timeShort, 
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import { Icon } from '../../Icons/Icon';
import LoadingIcon from '../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});

export class OrderPaymentsView extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getPayments() {
    if(!this.props.order) { return []; }

    if(this.props.order.paymentRecord) {
      return [ this.props.order.paymentRecord ];
    }
    return [];
  }

  canMakePayment() {
    if(!this.props.order) { return false; }

    if(this.props.order.totalPayable) {
      return true;
    }
    if(this.props.order.canMakeBuylistPayment) {
      return true;
    }
    return false;
  }

  isBuylist() {
    return this.props.order && this.props.order.isBuylist;
  }

  openModal() {
    if(this.isBuylist()) {
      this.props.openCreateBuylistPaymentModal();
    } else {
      this.props.openCreatePaymentModal();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderPaymentsView adminSection'}>
    	<div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_ORDER_PAYMENTS)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='opvLiner'>
              {this.getPayments().length === 0 ?
                <div className='noPaymentsWrapper'>
                  <div className='paymentIconWrapper'>
                    <Icon 
                      value={ICON_SELL}  
                      iconClass={'paymentIcon'} />
                  </div>
                  <div className='noPaymentsCopy'>{t(tx.TX_ORDER_NO_PAYMENTS)}</div>
                  {this.canMakePayment() ?
                    <button 
                      type='button'
                      className={'noPaymentsAction adminButton'}
                      onClick={this.openModal.bind(this)}>
                      {t(this.isBuylist() ? tx.TX_MAKE_PAYMENT : tx.TX_ORDER_RECORD_PAYMENT)}
                    </button> :
                    null
                  }
                </div> :
                <div className='pmListWrapper'>
                  <div className='pmList'>
                    <div className={'slHeader'}>
                      <div className={'slHeaderCell dateCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_DATE)}</div>
                      </div>
                      <div className={'slHeaderCell pmCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_PAYMENT_METHOD)}</div>
                      </div>
                      <div className={'slHeaderCell amountCell'}>
                        <div className={'slHeaderValue slItemValue'}>{t(tx.TX_AMOUNT)}</div>
                      </div>
                      <div className={'slHeaderCell actionCell'}>
                        <div className={'slHeaderValue slItemValue'}></div>
                      </div>

                    </div>
                    {this.getPayments().map((payment, i) => {
                      return <div 
                              key={i}
                              className={`slItem`}>
                        <div className={'slItemCell dateCell'}>
                          <div className='slNonAbsValue'>
                            <div className='dateDate'>{serverDateShort(payment.date, this.getLanguage())}</div>
                            <div className='dateTime'>{timeShort(payment.date, this.getLanguage())}</div>
                          </div>
                        </div>
                        <div className={'slItemCell pmCell'}>
                          <div className='slNonAbsValue'>
                            <div className='pmBodyWrapper'>
                              <div className='pmName'>{payment.paymentMethod ? payment.paymentMethod.getDisplayName(t) : t(tx.TX_null)}</div>
                              <div className='pmBody'>
                                <div className='pmBodyLiner'>
                                <div 
                                  className='pmDescription'
                                  dangerouslySetInnerHTML={{ __html: payment.format(t, { addTags: true, admin: true }) }} />
                                </div>
                              </div>
                              <div className='pmKeyValue'></div>
                            </div>
                          </div>
                        </div>
                        <div className={'slItemCell amountCell'}>
                          <div className='slNonAbsValue'>
                            {this.props.order ?
                              <>
                                <div 
                                  className='amountWrapper'
                                  dangerouslySetInnerHTML={{ __html: formatPrice(payment.totalPaid + payment.totalStoreCredit, { addTags: true }) }} />
                                {payment.totalStoreCredit ?
                                  <div className='creditWrapper'>
                                    <div className='creditLabel'>{t(tx.TX_ORDER_STORE_CREDIT)}</div>
                                    <div 
                                      className='creditValue'
                                      dangerouslySetInnerHTML={{ __html: formatPrice(payment.totalStoreCredit, { addTags: true }) }} />
                                  </div> :
                                  null
                                }
                              </> :
                              null
                            }
                          </div>
                        </div>
                        <div className={'slItemCell actionCell'}>
                          <div className='slNonAbsValue'>
                            
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                </div>
              }
            </div>
            {this.canMakePayment() ?
              <div className='adminViewButtonWrapper'>
                <button 
                  type='button'
                  className={'productDetailsButton adminButton'} 
                  onClick={() => this.openModal()}>
                  {t(this.isBuylist() ? tx.TX_MAKE_PAYMENT : tx.TX_ORDER_RECORD_PAYMENT)}
                </button>
              </div> :
              null
            }
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.orderPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderPaymentsView));