
import * as x from '../constants/actions';
import { getAuthObj, saveAuth } from '../utils/auth';
import { getPermissionsObj, savePermissions } from '../utils/permissions';

const initialState = {
  loginTime: null,
  authObj: getAuthObj(),
  refreshPending: false,
  permissions: getPermissionsObj(),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case x.ACTION_AUTH_SET_AUTH:
      return authSetLogin(state, action);
    case x.ACTION_AUTH_RESET_PENDING:
      return authRefreshPending(state, action);
    case x.ACTION_AUTH_SET_PERMISSIONS:
      return authSetPermissions(state, action);
    default:
      return state;
  }
}

function authSetLogin(state, action) {
  saveAuth(action.payload);
  return Object.assign({}, state, {
    loginTime: Date.now(),
    authObj: action.payload,
  });
}

function authRefreshPending(state, action) {
  return Object.assign({}, state, {
    refreshPending: action.payload,
  });
}

function authSetPermissions(state, action) {
  savePermissions(action.payload);
  return Object.assign({}, state, {
    permissions: action.payload,
  });
}
