import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../store';

import * as e from '../../constants/errors';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_INVENTORY, 
  URL_ADMIN_INVENTORY_PRODUCT_LINES, 
  URL_NS_SHOP, 
} from '../../constants/urls';

import { ProductLine } from '../../models/products';

import { 
  isFormValid, 
  getNameError, 
  getPermalinkError, 
  getSyncTypeError, 
} from '../../utils/form-validation';
import { 
  formatServerError, 
  stringToPermalink, 
} from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';
import Toggle from '../Input/Toggle';

import './style/_addproductline.scss';

import * as productLineActionCreators from '../../actions/product-line';
let allActionCreators = Object.assign({}, productLineActionCreators);

export class AddProductLine extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,
      savePromptOpen: false,

      syncDropdownOpen: false,

      inputSyncType: '',
      inputName: '',
      inputPermalink: '',
      inputHasBuylist: false,
      inputHasEvents: false,

      errorSyncType: '',
      errorName: '',
      errorPermalink: '',

      overridePermalink: true,

      lastBlock: '',
      saveRedirect: false,

      requestPending: false,
      requestError: null,
    };

    this.dropdownRef = React.createRef();
    this.checkClick = this.checkClick.bind(this);
  }

  componentDidMount() {
    
    // Fix naviation
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.isDirty === false) {
        return true;
      }

      this.togglePrompt();
      return false;
    });

    // Get managed lines
    if(this.props.productLine && this.props.productLine.productLinesManaged === null) {      
      this.props.productLinesFetchManaged()
        .catch((errResp) => {
          if(errResp) {
            console.error(errResp);
          }
        });
    }

    // Get all product lines
    if(this.props.productLine && this.props.productLine.productLines === null) {      
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }

    // Listeners
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    this.unblock();
    document.removeEventListener('click', this.checkClick, false);
  }

  checkClick(evt) {
    if(this.state.syncDropdownOpen === true) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.dropdownRef.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.toggleDropdown();
    }
  }

  isSyncTypeAvailable(testSyncType) {
    if(this.props.productLine.productLines && testSyncType) {
      for(const pl of this.props.productLine.productLines) {
        if(pl.isManaged && pl.managedLineId === testSyncType.id) {
          return false;
        }
      }
    }
    return true;
  }

  getSyncTypes() {

    if(this.isSyncTypePending()) {
      return [];
    }

    let syncTypes = [];

    if(this.props.productLine && this.props.productLine.productLinesManaged) {
      for(const managedSync of this.props.productLine.productLinesManaged) {

        syncTypes.push({
          id: managedSync.id,
          name: managedSync.name,
          description: managedSync.description,
          permalink: managedSync.permalink,
          isAvailable: this.isSyncTypeAvailable(managedSync),
          isManaged: true,
        });
      }
    }

    syncTypes.push({
      id: null,
      name: tx.TX_INV_PL_MANUAL,
      description: tx.TX_INV_PL_MANUAL_SYNC_DESC,
      permalink: '',
      isAvailable: true,
      isManaged: false,
    });

    return syncTypes;
  }

  togglePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  toggleDropdown() {
    this.setState({ syncDropdownOpen: !this.state.syncDropdownOpen });
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveAction();
    });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const prodLine = new ProductLine({
        name: this.getSelectedName(),
        permalink: this.getSelectedPermalink(),
        isManaged: this.isFixedSyncOption(this.state.inputSyncType),
        managed_line_id: this.state.inputSyncType.id,
        in_menu: false,
        display_order: null,
        has_buylist: this.state.inputHasBuylist,
        has_events: this.state.inputHasEvents,
        is_enabled: false,
      });

      this.setState({
        requestPending: true,
        requestError: null,
      });

      const addResp = await this.props.productLineAdd(prodLine.getApiData())
        .catch((errResp) => {
          console.error(errResp);
          this.setState({ 
            requestPending: false,
            requestError: formatServerError(errResp),
          });
        });

      if(!addResp) {
        return null;
      }

      this.props.productLinesSetAll(null);
      this.setState({ isDirty: false }, () => {
        if(!this.state.saveRedirect) {
          history.push(URL_ADMIN_INVENTORY_PRODUCT_LINES);
        } else {
          history.push(this.state.lastBlock);
        }
      });
    }
  }

  validateAll() {
    let errorObj = {
      errorSyncType: getSyncTypeError(this.state.inputSyncType),
      errorName: getNameError(this.getSelectedName()),
      errorPermalink: getPermalinkError(this.getSelectedPermalink(), this.isFixedSyncOption(this.state.inputSyncType) ? [] : this.getReservedPermalinks() ),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeSyncType(syncObj) {

    if(!this.isAvailableSyncOption(syncObj)) {
      return;
    }

    if(syncObj && !this.isSyncSelected(syncObj)) {
      this.setState({
        inputSyncType: syncObj,
        isDirty: true,
      }, () => {
        if(this.state.errorSyncType) {
          this.validateSyncType();
        }
        if(this.getSelectedName()) {
          this.validateName();
        }
      });
    }
    this.toggleDropdown();
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
      isDirty: true,
    }, () => {
      if(this.state.errorName) {
        this.validateName();
      }
      if(this.state.overridePermalink) {
        this.setState({
          inputPermalink: stringToPermalink(evt.target.value, false, 32),
        }, () => {
          if(this.state.errorPermalink) {
            this.validatePermalink();
          }
        });
      }
    })
  }

  changePermalink(evt) {
    this.setState({
      inputPermalink: stringToPermalink(evt.target.value, true, 32),
      overridePermalink: false,
      isDirty: true,
    }, () => {
      if(this.state.errorPermalink) {
        this.validatePermalink();
      }
    });
  }

  changeInBuyMenu(evt) {
    this.setState({
      inputInBuyMenu: !this.state.inputInBuyMenu, 
    });
  }

  changeHasBuylist(evt) {
    this.setState({
      inputHasBuylist: !this.state.inputHasBuylist, 
    });
  }

  changeHasEvents(evt) {
    this.setState({
      inputHasEvents: !this.state.inputHasEvents, 
    });
  }

  validateSyncType() {
    this.setState({ errorSyncType: getSyncTypeError(this.state.inputSyncType) });
  }

  validateName() {
    this.setState({ errorName: getNameError(this.getSelectedName()) });
  }

  validatePermalink() {
    this.setState({ errorPermalink: getPermalinkError(this.state.inputPermalink, this.getReservedPermalinks()) }, () => {
      if(this.state.errorPermalink === '') {
        this.props.productLineFetch(this.state.inputPermalink)
        .then((resp) => {
          // Product line already exists
          this.setState({ errorPermalink: e.ERROR_PERMALINK_TAKEN });
        })
        .catch((errResp) => {
          // No matching permalink found; do nothing
        });
      }
    });
  }

  getSyncLabel() {
    if(this.state.inputSyncType && this.state.inputSyncType.name) {
      return this.state.inputSyncType.name;
    }
    return tx.TX_PLACEHOLDER_PL_SYNC;
  }

  isSyncSelected(testSync) {
    if(!testSync || !testSync.name || !this.state.inputSyncType) {
      return false;
    }
    return testSync.name === this.state.inputSyncType.name;
  }

  isSyncTypePending() {
    return this.props.productLine.productLinesPending || this.props.productLine.productLinesManagedPending;
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  isFixedSyncOption(syncOption) {
    return syncOption && syncOption.isManaged === true;
  }

  isAvailableSyncOption(syncOption) {
    return syncOption && syncOption.isAvailable === true;
  }

  getSelectedName() {
    return this.isFixedSyncOption(this.state.inputSyncType) ? this.props.t(this.state.inputSyncType.name) : this.state.inputName;
  }

  getSelectedPermalink() {
    return this.isFixedSyncOption(this.state.inputSyncType) ? this.state.inputSyncType.permalink : this.state.inputPermalink;
  }

  getSyncSelectClass() {
    let classResp = 'syncSelect';
    if(this.isSyncTypePending()) {
      classResp += ' disabled';
    } else if(this.state.errorSyncType) {
      classResp += ' InputError';
    }
    return classResp;
  }

  getReservedPermalinks() {
    let reservedPermalinks = [];
    if(this.props.productLine && this.props.productLine.productLinesManaged) {
      for(const managedSync of this.props.productLine.productLinesManaged) {
        reservedPermalinks.push(managedSync.permalink);
      }
    }
    return reservedPermalinks;
  }

  render() {

    const {t} = this.props;

    return <div className={'AddProductLine AdminPage'}>

      <AdminTitle
        title={tx.TX_ADD}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: tx.TX_CATALOG,
          },
          {
            url: URL_ADMIN_INVENTORY_PRODUCT_LINES,
            title: tx.TX_PRODUCT_LINES,
          },
        ]} />

      <div className='adminBody'>
        <div className='adminForm'>
          <form 
            className='addProductLineForm'
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_INV_NEW_PRODUCT_LINE)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_SYNC_TYPE)}</div>
              <div className='adminInputWrapper'>
                <div 
                  className={this.getSyncSelectClass()} 
                  ref={this.dropdownRef}>
                  <div className='ssHead' onClick={this.toggleDropdown.bind(this)}>
                    <div 
                      className={this.state.inputSyncType && this.state.inputSyncType.name ? 'ssHeadLabel' : 'ssHeadLabel placeholder'}>
                      {t(this.getSyncLabel())}
                    </div>
                    <div className='ssHeadArrow'>
                      <div className='ddArrow'></div>
                    </div>
                  </div>
                  <div className={this.state.syncDropdownOpen ? 'ssBody open' : 'ssBody'}>
                    <div className='ssOptionsWrapper'>
                      {this.getSyncTypes().map((option, i) => {
                        return <div className={this.isAvailableSyncOption(option) ? 'ssOption' : 'ssOption disabled'} key={i} onClick={() => this.changeSyncType(option)}>
                          <div className='ssOptionCheck'>
                            {this.isSyncSelected(option) ?
                              <div className='checkWrapper'>&#x2714;</div> :
                              null
                            }
                          </div>
                          <div className='ssOptionLabel'>{t(option.name)}</div>
                          {this.isAvailableSyncOption(option) ?
                            <div className='ssOptionDescription'>{t(option.description)}</div> :
                            <div className='ssOptionDescription'>{t(tx.TX_INV_PL_ALREADY_IN_USE)}</div>
                          }
                        </div>;
                      })}
                    </div>
                  </div>
                </div>
                {this.state.errorSyncType ?
                  <div className={'adminError FieldError'}>{t(this.state.errorSyncType)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.getSelectedName()}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_PL_NAME)}
                  disabled={this.isFixedSyncOption(this.state.inputSyncType)}
                  maxLength={100} />
                {this.state.errorName ?
                  <div className={'adminError FieldError'}>{t(this.state.errorName)}</div> :
                  null
                }
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_PERMALINK)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorPermalink && !this.isFixedSyncOption(this.state.inputSyncType) ? 'InputError' : ''}
                  value={this.getSelectedPermalink()}
                  onChange={this.changePermalink.bind(this)}
                  onBlur={this.validatePermalink.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_PL_PERMALINK)}
                  disabled={this.isFixedSyncOption(this.state.inputSyncType)}
                  maxLength={32} />
                {this.state.errorPermalink && !this.isFixedSyncOption(this.state.inputSyncType) ?
                  <div className={'adminError FieldError'}>{t(this.state.errorPermalink)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  <span className='plUrlLabel'>{t(tx.TX_INV_PL_URL)}:</span>
                  <span className='plUrlValue'>{`${window.location.origin}/${URL_NS_SHOP}/${this.getSelectedPermalink()}`}</span>
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_HAS_BUYLIST)}</div>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputHasBuylist}
                    onToggle={this.changeHasBuylist.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PL_HAS_EVENTS)}</div>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputHasEvents}
                    onToggle={this.changeHasEvents.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
            </div>

            <div className='adminActionRow'>
              <Link 
                className={'adminAction adminActionCancel'} 
                to={URL_ADMIN_INVENTORY_PRODUCT_LINES}>
                {t(tx.TX_CANCEL)}
              </Link>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.togglePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductLine));