import { useRef, useEffect } from 'react';
import _ from 'underscore';

const useResizeObserver = (callback, config = { throttle: 200 }) => {
  
  const ref = useRef();

  useEffect(() => {

    if (!ref.current) return;

    const handleResize = _.throttle(
      (entries) => {
        for (let entry of entries) {
          callback(entry.contentRect.width);
        }
      },
      config.throttle,
      { leading: true, trailing: true }
    );

    const observer = new ResizeObserver(handleResize);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [callback, config.throttle]);

  return ref;
};

export default useResizeObserver;
