
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { TX_null } from '../../constants/strings';

const includeKeys = [
	'height',
	'style',
	'width',
]

const includes = (obj, includeKeys) =>
	Object.keys(obj).reduce((result, key) => {
		if(includeKeys.includes(key)) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export class StaticImage extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			imgLoaded: false,
		};

		this.imgRef = React.createRef();
	}

	imgLoaded() {
		this.setState({ imgLoaded: true }, () => {
			if(this.props.onImgLoad) {
				this.props.onImgLoad();
			}
		});
	}

	getStyleObj() {
		const styleObj = {};
		if(this.props.maxWidth) {
			styleObj['maxWidth'] = `${this.props.maxWidth}px`;
		}
		if(this.props.maxHeight) {
			styleObj['maxHeight'] = `${this.props.maxHeight}px`;
		}
		return styleObj;
	}

	render() {

		const {t} = this.props;
		const filteredProps = includes(this.props, includeKeys);

		return (
			<img
				ref={this.imgRef}
				className='StaticImage'
				{...filteredProps}
				onLoad={this.imgLoaded.bind(this)}
				src={this.props.imgObj.src}
				alt={this.props.imgObj.noTranslate ? this.props.imgObj.alt || '' : t(this.props.imgObj.alt ? this.props.imgObj.alt : TX_null)}
				style={this.getStyleObj()}
			/>
		);
	}
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, {})(withTranslation()(StaticImage));