import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { 
  getCurrencyIncrement, 
  getCurrencySymbol, 
} from '../../../../utils/currency';
import { 
  getGenericNumberError,
  isFormValid, 
} from '../../../../utils/form-validation';
import { 
  formatPrice,
  normalizePrice,
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import Toggle from '../../../Input/Toggle';

const allActionCreators = Object.assign({});

export class IntegrationConfigStampsBalanceRefill extends Component {

  constructor(props) {
    super(props);

    const initValue = this.getConfigValue();

    this.state = {
      
      editing: false,

      inputEnabled: initValue.is_enabled || false,
      inputIncrement: normalizePrice(initValue.increment) || '',
      inputFloor: normalizePrice(initValue.floor) || '',

      errorIncrement: '',
      errorFloor: '',
    };
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getConfigValue() {
    return this.props.config && this.props.config.value ? JSON.parse(this.props.config.value) : {};
  }

  openEdit() {
    this.setState({ editing: true });
  }

  changeEnabled() {
    this.setState({ inputEnabled: !this.state.inputEnabled });
  }

  changeIncrement(evt) {
    this.setState({
      inputIncrement: evt.target.value,
    }, () => {
      if(this.state.errorIncrement) {
        this.validateIncrement(false);
      }
    });
  }

  validateIncrement(shouldNormalize = true) {
    this.setState({ 
      inputIncrement: shouldNormalize ? normalizePrice(this.state.inputIncrement) : this.state.inputIncrement, 
      errorIncrement: getGenericNumberError(this.state.inputIncrement, { min: 10, max: 10000 }), 
    });
  }

  changeFloor(evt) {
    this.setState({
      inputFloor: evt.target.value,
    }, () => {
      if(this.state.errorFloor) {
        this.validateFloor(false);
      }
    });
  }

  validateFloor(shouldNormalize = true) {
    this.setState({ 
      inputFloor: shouldNormalize ? normalizePrice(this.state.inputFloor) : this.state.inputFloor, 
      errorFloor: getGenericNumberError(this.state.inputFloor, { min: getCurrencyIncrement() }), 
    });
  }

  validateAll() {
    const errorObj = {
      errorIncrement: getGenericNumberError(this.state.inputIncrement, { min: 10, max: 10000, allowEmpty: !this.state.inputEnabled }),
      errorFloor: getGenericNumberError(this.state.inputFloor, { min: getCurrencyIncrement(), allowEmpty: !this.state.inputEnabled }),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const configValue = {
        is_enabled: this.state.inputEnabled,
      };

      if(this.state.inputEnabled) {
        configValue['increment'] = parseFloat(this.state.inputIncrement);
        configValue['floor'] = parseFloat(this.state.inputFloor);
      }

      // Call config
      this.props.setConfigAttribute(this.props.config, configValue);

      this.setState({ editing: false });
    }
  }

  cancelAction() {
    const currentValue = this.getConfigValue();

    this.setState({
      editing: false,

      inputEnabled: currentValue.is_enabled || false,
      inputIncrement: normalizePrice(currentValue.increment) || '',
      inputFloor: normalizePrice(currentValue.floor) || '',

      errorIncrement: '',
      errorFloor: '',
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'IntegrationConfigStampsBalanceRefill'}>
      <div className='refillLiner'>
        
        {this.state.editing ?
          <div className='editingWrapper'>
            <form className='editForm' onSubmit={this.saveAction.bind(this)}>
              <div className='editFormLiner'>
                <div className='enabledWrapper'>
                  <Toggle
                    checked={this.state.inputEnabled}
                    disabled={false}
                    onToggle={this.changeEnabled.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
                <div className={`settingsWrapper ${this.state.inputEnabled ? 'open' : ''}`}>
                  <div className='settingsLiner'>
                    <div className='refillInputWrapper'>
                      <div className='refillInputLabel'>{t(tx.TX_AMOUNT)}</div>
                      <div className='refillInputElementWrapper'>
                        <input
                          type='number'
                          className={`refillInput ${this.state.errorIncrement ? 'error' : ''}`}
                          min={10}
                          max={10000}
                          step={getCurrencyIncrement()}
                          placeholder={t(tx.TX_PLACEHOLDER_REFILL_INCREMENT)}
                          value={this.state.inputIncrement}
                          onChange={this.changeIncrement.bind(this)}
                          onBlur={this.validateIncrement.bind(this)} />
                        <div className='currencyIndication'>
                          <div className='FlexCenter'>{getCurrencySymbol()}</div>
                        </div>
                      </div>
                      {this.state.errorIncrement ?
                        <div className='refillError'>{t(this.state.errorIncrement)}</div> :
                        null
                      }
                    </div>
                    <div className='refillInputWrapper'>
                      <div className='refillInputLabel'>{t(tx.TX_SETTINGS_INTEGRATIONS_REFILL_FLOOR_LABEL)}</div>
                      <div className='refillInputElementWrapper'>
                        <input
                          type='number'
                          className={`refillInput ${this.state.errorFloor ? 'error' : ''}`}
                          min={getCurrencyIncrement()}
                          step={getCurrencyIncrement()}
                          placeholder={t(tx.TX_PLACEHOLDER_REFILL_FLOOR)}
                          value={this.state.inputFloor}
                          onChange={this.changeFloor.bind(this)}
                          onBlur={this.validateFloor.bind(this)} />
                        <div className='currencyIndication'>
                          <div className='FlexCenter'>{getCurrencySymbol()}</div>
                        </div>
                      </div>
                      {this.state.errorFloor ?
                        <div className='refillError'>{t(this.state.errorFloor)}</div> :
                        null
                      }
                    </div>
                  </div>
                </div>
                <div className='actionsWrapper'>
                  <button type='button' className={'refillButton cancelButton'} onClick={this.cancelAction.bind(this)}>{t(tx.TX_CANCEL)}</button>
                  <button type='submit' className={'refillButton saveButton'}>{t(tx.TX_SAVE)}</button>
                </div>
              </div>
            </form>
          </div> :
          <div className='viewingWrapper'>
            <div className='viewHeaderWrapper'>
              <div className='statusWrapper'>
                <div className='statusValue'>{t(this.state.inputEnabled ? tx.TX_ENABLED : tx.TX_DISABLED)}</div>
              </div>
              {!this.state.editing ?
                <div className='toggleWrapper'>
                  <div className='toggleValue' onClick={this.openEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
                </div> :
                null
              }
            </div>
          </div>
        }
        
        {!this.state.editing && this.state.inputEnabled ?
          <>
            <div className='refillCopy' dangerouslySetInnerHTML={{ 
              __html: t(tx.TX_SETTINGS_INTEGRATIONS_REFILL_COPY_ENABLED, { 
                increment: `<span class='em'>${formatPrice(this.state.inputIncrement, { language: this.getLanguage() } )}</span>`, 
                floor: `<span class='em'>${formatPrice(this.state.inputFloor, { language: this.getLanguage() } )}</span>`
              }) 
            }} />
            <div className='refillCopy'>{t(tx.TX_SETTINGS_INTEGRATIONS_REFILL_COPY_ENABLED_2)}</div>
          </> :
          <div className='refillCopy'>{t(tx.TX_SETTINGS_INTEGRATIONS_REFILL_COPY_DISABLED)}</div>
        }

      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(IntegrationConfigStampsBalanceRefill));