import 'whatwg-fetch';

import * as x from '../constants/actions';
import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

import { 
	StoreEvent,
	StoreEventSchedule, 
} from '../models/events';

import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq, getUrlParams } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/


export function eventSetMonthEvents(payload) {
  return {
    type: x.ACTION_EVENTS_SET_MONTH,
    payload
  };
}

export function eventClearEvents(payload) {
  return {
    type: x.ACTION_EVENTS_CLEAR,
    payload
  };
}



/******************************
*******  Asynchronous  ********
******************************/


export function eventCreate(createData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_EVENT_ADMIN_CREATE;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(createData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function eventUpdateEvent(editData, uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_EVENT_ADMIN_UPDATE, { uuid: uuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(editData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}

export function eventDeleteEvent(uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_EVENT_ADMIN_DELETE, { uuid: uuid });
    return fetch(url, {
      method: 'delete',
      headers: authReq(a.API_HEADERS),
      signal, 
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}

export function eventAdminFetchMonth(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_EVENT_ADMIN_FETCH_MONTH + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	
	    	const eventArray = [];
	    	for(const datum of resp.data.events) {
	    		eventArray.push(new StoreEvent(datum));
	    	}

	    	const scheduleArray = [];
	    	for(const schedule of resp.data.schedules) {
	    		scheduleArray.push(new StoreEventSchedule(schedule));
	    	}

	    	return Promise.resolve({
	    		events: eventArray,
	    		schedules: scheduleArray,
	    	});
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function eventAdminFetchSingle(uuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_EVENT_ADMIN_FETCH, { uuid: uuid });
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new StoreEvent(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function eventScheduleAdminFetchSingle(uuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_EVENT_SCHEDULE_ADMIN_FETCH, { uuid: uuid });
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new StoreEventSchedule(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function eventFetchUpcoming(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_EVENT_UPCOMING + getUrlParams(getParams);;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	
	    	const eventArray = [];
	    	for(const datum of resp.data.events) {
	    		eventArray.push(new StoreEvent(datum));
	    	}

	    	const scheduleArray = [];
	    	for(const schedule of resp.data.schedules) {
	    		scheduleArray.push(new StoreEventSchedule(schedule));
	    	}

	    	return Promise.resolve({
	    		events: eventArray,
	    		schedules: scheduleArray,
	    	});

	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function eventDeleteEventSchedule(uuid, signal = null) {
  return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_EVENT_SCHEDULE_ADMIN_DELETE, { uuid: uuid });
    return fetch(url, {
      method: 'delete',
      headers: authReq(a.API_HEADERS),
      signal, 
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}


export function eventUpdateEventSchedule(uuid, updateData, signal = null) {
	return async (dispatch, getState) => {

    const refreshMethod = await authTokenRefresh(dispatch, getState);
    await refreshMethod();

    const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_EVENT_SCHEDULE_ADMIN_UPDATE, { uuid: uuid });
    return fetch(url, {
      method: 'put',
      headers: authReq(a.API_HEADERS),
      body: JSON.stringify(updateData),
      signal,
    })
    .then(parseJSON)
    .then((resp) => {
      if(isOk(resp.status)) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp.data);
      }
    });
  }
}

export function eventScheduleCreateException(uuid, exceptionData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_EVENT_SCHEDULE_CREATE_EXCEPTION, { uuid: uuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(exceptionData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}









