

import * as tx from './strings';



// VALUE CONSTANTS

export const GD_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE = 60;
export const GD_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS = [ 30, 60, 90, 120 ];

export const GD_BREAK_SMALL = 400;

export const GD_BREAK_SMALL_CLASS = ' gsS'; // Leading space is intentional

export const GD_THEME_SEARCH = ' productSearch'; // Leading space is intentional
export const GD_THEME_STORE = ' theme'; // Leading space is intentional

export const GD_FILTER_KEY_PRODUCT_GENERAL = 'product-general-gallery';


// CONFIG CONSTANTS

// Required parameters

export const GAL_DISPLAY_TYPE = 'GAL_DISPLAY_TYPE';

export const GAL_HEADER_CONFIGS = 'GAL_HEADER_CONFIGS';
export const GAL_ELEMENT_CONFIGS = 'GAL_ELEMENT_CONFIGS';
export const GAL_PAGINATION_CONFIGS = 'GAL_PAGINATION_CONFIGS';

export const GAL_HEADER_SORT_KEY = 'GAL_HEADER_SORT_KEY';
export const GAL_HEADER_SORT_DIR = 'GAL_HEADER_SORT_DIR';
export const GAL_HEADER_SORT_OPTIONS = 'GAL_HEADER_SORT_OPTIONS';
export const GAL_HEADER_SORT_OPTION_KEY = 'GAL_HEADER_SORT_OPTION_KEY';
export const GAL_HEADER_SORT_OPTION_DISPLAY = 'GAL_HEADER_SORT_OPTION_DISPLAY';

export const GAL_ELEMENT_CONFIG_ACTION_LABEL = 'GAL_ELEMENT_CONFIG_ACTION_LABEL';
export const GAL_ELEMENT_CONFIG_ACTION_TYPE = 'GAL_ELEMENT_CONFIG_ACTION_TYPE';
export const GAL_ELEMENT_CONFIG_MIN_WIDTH = 'GAL_ELEMENT_CONFIG_MIN_WIDTH';
export const GAL_ELEMENT_CONFIG_MAX_WIDTH = 'GAL_ELEMENT_CONFIG_MAX_WIDTH';
export const GAL_ELEMENT_CONFIG_MIN_ACROSS = 'GAL_ELEMENT_CONFIG_MIN_ACROSS';

export const GAL_PAGINATION_CONFIG_TYPE = 'GAL_PAGINATION_CONFIG_TYPE';
export const GAL_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE = 'GAL_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE';
export const GAL_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS = 'GAL_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS';


// Optional parameters

export const GAL_COMPONENT_THEME = 'GAL_COMPONENT_THEME';
export const GAL_LOAD_OFFSET = 'GAL_LOAD_OFFSET';
export const GAL_STORE_THEME = 'GAL_STORE_THEME';
export const GAL_FILTER_KEY = 'GAL_FILTER_KEY';

export const GAL_HEADER_SORT_PLACEHOLDER = 'GAL_HEADER_SORT_PLACEHOLDER';


// Defined values

export const GV_DISPLAY_TYPE_THUMBNAIL = 'GV_DISPLAY_TYPE_THUMBNAIL';

export const GV_ELEMENT_ACTION_TYPE_LINK = 'GV_ELEMENT_ACTION_TYPE_LINK';
export const GV_ELEMENT_ACTION_TYPE_SELECT_ONE = 'GV_ELEMENT_ACTION_TYPE_SELECT_ONE';

export const GV_HEADER_SORT_DIR_ASC = 'GV_HEADER_SORT_DIR_ASC';
export const GV_HEADER_SORT_DIR_DESC = 'GV_HEADER_SORT_DIR_DESC';

export const GV_PRODUCT_TYPE_ALL = 'GV_PRODUCT_TYPE_ALL';

export const GV_PAGINATION_CONFIG_TYPE_INFINITE_SCROLL = 'GV_PAGINATION_CONFIG_TYPE_INFINITE_SCROLL';




export const GALLERY_CONFIG_ADD_PRODUCT = {

	[GAL_DISPLAY_TYPE]: GV_DISPLAY_TYPE_THUMBNAIL,
	[GAL_LOAD_OFFSET]: 25,

	[GAL_HEADER_CONFIGS]: {
		[GAL_HEADER_SORT_KEY]: 'released_at',
		[GAL_HEADER_SORT_DIR]: GV_HEADER_SORT_DIR_DESC,
		[GAL_HEADER_SORT_OPTIONS]: [
			{
				[GAL_HEADER_SORT_OPTION_KEY]: 'name',
				[GAL_HEADER_SORT_OPTION_DISPLAY]: tx.TX_NAME,
			},
			{
				[GAL_HEADER_SORT_OPTION_KEY]: 'released_at',
				[GAL_HEADER_SORT_OPTION_DISPLAY]: tx.TX_RELEASE_DATE,
			},
		],
	},
	[GAL_ELEMENT_CONFIGS]: {
		[GAL_ELEMENT_CONFIG_ACTION_TYPE]: GV_ELEMENT_ACTION_TYPE_SELECT_ONE,
		[GAL_ELEMENT_CONFIG_ACTION_LABEL]: tx.TX_SELECT,
		[GAL_ELEMENT_CONFIG_MIN_WIDTH]: 190,
		[GAL_ELEMENT_CONFIG_MAX_WIDTH]: 300,
		[GAL_ELEMENT_CONFIG_MIN_ACROSS]: 2,
	},
	[GAL_PAGINATION_CONFIGS]: {
		[GAL_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE]: GD_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE,
		[GAL_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS]: GD_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS,
	}
};


export const GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL = {

	[GAL_COMPONENT_THEME]: GD_THEME_SEARCH,
	[GAL_DISPLAY_TYPE]: GV_DISPLAY_TYPE_THUMBNAIL,
	[GAL_LOAD_OFFSET]: 25,
	[GAL_STORE_THEME]: GD_THEME_STORE,
	[GAL_FILTER_KEY]: GD_FILTER_KEY_PRODUCT_GENERAL,

	[GAL_HEADER_CONFIGS]: {
		[GAL_HEADER_SORT_KEY]: '',
		[GAL_HEADER_SORT_DIR]: GV_HEADER_SORT_DIR_DESC,
		[GAL_HEADER_SORT_PLACEHOLDER]: tx.TX_PLACEHOLDER_BEST_MATCH,
		[GAL_HEADER_SORT_OPTIONS]: [
			{
				[GAL_HEADER_SORT_OPTION_KEY]: 'name',
				[GAL_HEADER_SORT_OPTION_DISPLAY]: tx.TX_NAME,
			},
			{
				[GAL_HEADER_SORT_OPTION_KEY]: 'price',
				[GAL_HEADER_SORT_OPTION_DISPLAY]: tx.TX_PRICE,
			},
		],
	},
	[GAL_ELEMENT_CONFIGS]: {
		[GAL_ELEMENT_CONFIG_ACTION_TYPE]: GV_ELEMENT_ACTION_TYPE_LINK,
		[GAL_ELEMENT_CONFIG_ACTION_LABEL]: '',
		[GAL_ELEMENT_CONFIG_MIN_WIDTH]: 220,
		[GAL_ELEMENT_CONFIG_MAX_WIDTH]: 320,
		[GAL_ELEMENT_CONFIG_MIN_ACROSS]: 2,
	},
	[GAL_PAGINATION_CONFIGS]: {
		[GAL_PAGINATION_CONFIG_TYPE]: GV_PAGINATION_CONFIG_TYPE_INFINITE_SCROLL,
		[GAL_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE]: 30,
		// [GAL_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE]: GD_PAGINATION_CONFIG_DEFAULT_PAGE_SIZE,
		[GAL_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS]: GD_PAGINATION_CONFIG_PAGE_SIZE_OPTIONS,
	}
};
















