
import * as x from '../constants/actions';
import * as n from '../constants/endpoints';
import * as a from '../constants/api';
import { URL_HOME } from '../constants/urls';

import { getResetObj, isLoggedIn, logout, needAuthReset } from '../utils/auth';
import { sleep } from '../utils/general';
import { parseJSON, isOk, refreshReq } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/

export function authSet(payload) {
	return {
		type: x.ACTION_AUTH_SET_AUTH,
		payload
	}
}

export function authRefreshPending(payload) {
	return {
    type: x.ACTION_AUTH_RESET_PENDING,
    payload
  };
}

export function authSetPermissions(payload) {
	return {
		type: x.ACTION_AUTH_SET_PERMISSIONS,
		payload
	}
}



/******************************
*******  Asynchronous  ********
******************************/



// 
// Login / Account methods in user action creators
// 

export async function authTokenRefresh(dispatch, getState) {
	return async () => {

  	// Not logged in; not relevant
  	if(!isLoggedIn()) {
			return Promise.resolve(null);
		}

		// If you don't need a refresh, don't get one
  	if(needAuthReset() === false) {
  		return Promise.resolve(null);
  	}

		// If there's a request already pending, no need to call API
		// Must wait for request to complete until returning to make sure tokens updated
		if(getState().auth.refreshPending) {

			const POLL_INTERVAL = 25;
			const POLL_TIMEOUT = 10000;

			let i = 0;
			while(getState().auth.refreshPending) {
				await sleep(POLL_INTERVAL);
				if(i > POLL_TIMEOUT/POLL_INTERVAL) {
					break;
				}
				i++;
			}
			return Promise.resolve(null);
			
		} else {

			dispatch(authRefreshPending(true));

	  	const authData = getResetObj();
			const url = a.API_TITAN_API + n.ENDPOINT_USER_TOKEN_REFRESH;
			return fetch(url, {
			  method: 'post',
			  headers: refreshReq(a.API_HEADERS),
			  body: JSON.stringify(authData),
			})
			.then(parseJSON)
			.then((resp) => {
				dispatch(authRefreshPending(false))
		    if(isOk(resp.status)) {
		    	dispatch(authSet(resp.data));
		    	return Promise.resolve(resp.data);
		    } else {
		    	logout();
		    	window.location.href = URL_HOME;
		    	return Promise.reject(resp.data);
		    }
		  });
		  
		}
	}
}