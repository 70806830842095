import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { 
  formatServerError, 
} from '../../../utils/formatting';

import LoadingIcon from '../../Icons/LoadingIcon';
import PaymentMethodConfigField from './PaymentMethodConfigField';

import * as checkoutActionCreators from '../../../actions/checkout';
let allActionCreators = Object.assign({}, checkoutActionCreators);

export class PaymentMethodConfigView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      requestPending: false,
      requestError: null,
    };
  }

  setConfigAttribute(config, value) {

    let configData = config.getApiData(value);

    for(const conf of this.props.paymentMethod.getCalcConfigs()) {

      const calcData = conf.getApiData(conf.value);
      for(const key of Object.keys(calcData)) {
        if(configData[key]) {
          configData[key] = Object.assign({}, configData[key], { [conf.key]: conf.value });
        } else {
          configData[key] = { [conf.key]: conf.value };
        }
      }
    }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    this.props.checkoutUpdatePaymentMethod(configData, this.props.paymentMethod.publicUuid)
    .then((resp) => {
      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {
        this.props.makeClean('config');
      });
    })
    .catch((errResp) => {
      
      console.error(errResp);
      this.setState({ 
        requestPending: false,
        requestError: formatServerError(errResp),
      });
    });
  }

  render() {

    const {t} = this.props;

    const calculatedConfigs = this.props.paymentMethod.getCalcConfigs();
    const variableConfigs = this.props.paymentMethod.getVariableConfigs();

    return <div className={'PaymentMethodConfigView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_CONFIGURATION)}</div>
      </div>
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className={'detailBlock adminViewEditable'}>
              {(calculatedConfigs && calculatedConfigs.length) || (variableConfigs && variableConfigs.length) ?
                <div className='detailBlockWrapper'>
                  <div className={this.state.requestError ? 'configError present' : 'configError'}>{t(this.state.requestError)}</div>
                  {calculatedConfigs && calculatedConfigs.length > 0 ?
                    <div className='fullWidthConfigs'>
                      {calculatedConfigs.map((conf, i) => {
                        return <div className='adminViewItem' key={i}>
                          <div className='adminViewLabel'>{t(conf.label)}</div>
                          <div className={'adminViewValue urlValue'}>{conf.value}</div>
                        </div>;
                      })}
                    </div> :
                    null
                  }
                  {calculatedConfigs && calculatedConfigs.length && variableConfigs && variableConfigs.length ?
                    <div className='configHr' /> :
                    null
                  }
                  {variableConfigs && variableConfigs.length ?
                    <div className='halfWidthConfigs'>
                      {variableConfigs.map((conf, j) => {
                        return <PaymentMethodConfigField 
                                  key={j}
                                  config={conf}
                                  paymentMethod={this.props.paymentMethod}
                                  paymentMethodPending={this.props.paymentMethodPending}
                                  setConfigAttribute={this.setConfigAttribute.bind(this)} />;
                      })}
                    </div> :
                    null
                  }
                </div> :
                <div className='detailBlockWrapper'>
                  <div className='noConfigMethod'>{t(tx.TX_SETTINGS_PAYMENT_METHODS_NO_CONFIG)}</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.paymentMethodPending || this.state.requestPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(PaymentMethodConfigView));