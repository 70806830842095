import 'whatwg-fetch';

import * as x from '../constants/actions';
import * as n from '../constants/endpoints';
import * as a from '../constants/api';

import { authTokenRefresh } from './auth';

import { ProductLine } from '../models/products';

import { isLoggedIn } from '../utils/auth';
import { stringFormat } from '../utils/formatting';
import { parseJSON, isOk, authReq } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/


export function productLinesSetAll(payload) {
	return {
    type: x.ACTION_PRODUCT_LINE_SET_ALL,
    payload
  };
}

export function productLinesFetchPending(payload) {
	return {
    type: x.ACTION_PRODUCT_LINE_ALL_SET_PENDING,
    payload
  };
}

export function productLinesSetManaged(payload) {
	return {
    type: x.ACTION_PRODUCT_LINE_SET_MANAGED,
    payload
  };
}

export function productLinesFetchManagedPending(payload) {
	return {
    type: x.ACTION_PRODUCT_LINE_MANAGED_SET_PENDING,
    payload
  };
}

export function productLineSetMenus(payload) {
  return {
    type: x.ACTION_PRODUCT_LINE_SET_MENUS,
    payload
  }; 
}

export function productLineMenusPending(payload) {
	return {
    type: x.ACTION_PRODUCT_LINE_MENUS_SET_PENDING,
    payload
  }; 
}

export function productLineSetEnabled(payload) {
  return {
    type: x.ACTION_PRODUCT_LINE_SET_ENABLED,
    payload
  }; 
}

export function productLineEnabledPending(payload) {
	return {
    type: x.ACTION_PRODUCT_LINE_ENABLED_SET_PENDING,
    payload
  }; 
}

export function productLineSetAdHocData(payload) {
	return {
		type: x.ACTION_PRODUCT_LINE_SET_DATA,
		payload
	};
}



/******************************
*******  Asynchronous  ********
******************************/


export function productLinesFetchAll() {
	return async (dispatch, getState) => {

		if(!isLoggedIn() || getState().productLine.productLinesPending) {
			return Promise.reject(null);
		}
		dispatch(productLinesFetchPending(true));

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PL_GET_ALL;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(productLinesFetchPending(false));
	    if(isOk(resp.status)) {
	    	const respArray = [];
	    	for(const datum of resp.data) {
	    		respArray.push(new ProductLine(datum));
	    	}
	    	return Promise.resolve(respArray);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLinesFetchManaged() {
	return async (dispatch, getState) => {

		if(!isLoggedIn() || getState().productLine.productLinesManagedPending) {
			return Promise.reject(null);
		}
		dispatch(productLinesFetchManagedPending(true));

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PL_GET_MANAGED;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(productLinesFetchManagedPending(false));
	    if(isOk(resp.status)) {
	    	const respArray = [];
	    	for(const datum of resp.data) {
	    		respArray.push(new ProductLine(datum));
	    	}
	    	dispatch(productLinesSetManaged(respArray));
	    	return null;
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineFetch(permalink) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_GET_SINGLE, { permalink: permalink });
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new ProductLine(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineAdd(addData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PL_ADD;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineEdit(editData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_EDIT, { id: editData.pk });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(editData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineDelete(plId) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_DELETE, { id: plId });
		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productLineUpdateDefaults(updateData, plId) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_UPDATE_DEFAULTS, { id: plId });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(updateData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineFetchMenus() {

	return async (dispatch, getState) => {

		if(getState().productLine.menusPending) {
			return Promise.reject(null);
		}
		dispatch(productLineMenusPending(true));

		const url = a.API_TITAN_API + n.ENDPOINT_PL_GET_MENUS;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(productLineMenusPending(false));
	    if(isOk(resp.status)) {
	    	const plArray = [];
	    	for(const pl of resp.data) {
	    		plArray.push(new ProductLine(pl));
	    	}
	    	dispatch(productLineSetMenus(plArray));
	    	return null;
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineFetchEnabled() {

	return async (dispatch, getState) => {

		if(getState().productLine.enabledPending) {
			return Promise.reject(null);
		}
		dispatch(productLineEnabledPending(true));

		const url = a.API_TITAN_API + n.ENDPOINT_PL_GET_ENABLED;
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
			dispatch(productLineEnabledPending(false));
	    if(isOk(resp.status)) {
	    	const plArray = [];
	    	for(const pl of resp.data) {
	    		plArray.push(new ProductLine(pl));
	    	}
	    	dispatch(productLineSetEnabled(plArray));
	    	return null;
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineEnablePricing(enableData, plPermalink, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_ENABLE_PRICING, { permalink: plPermalink });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(enableData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineAddPricingMatrix(addData, plPermalink, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_PRICING_MATRIX_ADD, { permalink: plPermalink });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineAddPricingMatrixRule(addData, plPermalink, matrixUuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_PRICING_MATRIX_RULE_ADD, { permalink: plPermalink, publicUuid: matrixUuid });
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineUpdatePricingMatrixRule(updateData, plPermalink, ruleUuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_PRICING_MATRIX_RULE_UPDATE, { permalink: plPermalink, publicUuid: ruleUuid });
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(updateData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}

export function productLineDeletePricingMatrixRule(plPermalink, ruleUuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PL_PRICING_MATRIX_RULE_DELETE, { permalink: plPermalink, publicUuid: ruleUuid });
		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}













