import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Dropzone from 'react-dropzone';

import { ERR_5500 } from '../../../../constants/errors';
import * as tx from '../../../../constants/strings';

import { getErrorProductImage } from '../../../../utils/files';
import { formatServerError } from '../../../../utils/formatting';

import LoadingIcon from '../../../Icons/LoadingIcon';
import { ProductMediaSortableGrid } from '../ProductMediaSortableGrid';
import SavePrompt from '../../../Popups/SavePrompt';

import * as commonActionCreators from '../../../../actions/common';
import * as productActionCreators from '../../../../actions/product';
let allActionCreators = Object.assign({}, commonActionCreators, productActionCreators);

export class ProductMediaEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,

      unsavedMediaArray: this.formatMediaArray(this.props.productObj),
      mediaId: this.formatMediaArray(this.props.productObj).length, 

      errorMedia: '',

      dragCount: 0,

      requestPending: false,
      requestError: null,

      savePromptOpen: false, 
    };
    this.fileDropRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    let mediaDataArray = [];

    for(let i = 0; i < this.state.unsavedMediaArray.length; i++) {
      mediaDataArray.push({
        order: i,
        key: this.state.unsavedMediaArray[i].obj.key,
        caption: '',
      });
    }

    const mediaData = { media: mediaDataArray };
    const mediaResp = await this.props.productMediaUpdate(this.props.productObj.permalink, this.props.productObj.productLine.permalink, mediaData)
          .catch((errResp) => {
            // Fail response
            this.setState({
              requestPending: false,
              requestError: formatServerError(errResp),
            });

            if(this.props.remoteSave) {
              this.props.finishRemote('media', true);
            }
          });

    if(!mediaResp) {
      return null;
    }

    this.setState({
      requestPending: false,
      requestError: null,
    }, () => {
      if(this.props.remoteSave) {
        this.props.finishRemote('media');
      } else {
        this.props.makeClean('media');
      }
      this.props.toggleEdit();
    });
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  setMedia(newArray) {
    this.setState({ unsavedMediaArray: newArray }, () => {
      this.makeDirty();
    });
  }

  formatMediaArray(productObj) {
    if(!this.props.productObj || !this.props.productObj.media) {
      return [];
    }
    let respArray = [];
    for(const mediaObj of productObj.media) {
      let nextId = respArray.length + 1;
      respArray.push({
        id: nextId,
        obj: mediaObj,
      })
    }
    return respArray;
  }

  dragEnterInput(evt) {
    for(const tp of evt.dataTransfer.types) {
      if(tp === 'Files') {
        this.setState({ dragCount: this.state.dragCount + 1 });
        break;
      }
    }
  }

  dragLeaveInput(evt) {
    this.setState({ dragCount: Math.max(this.state.dragCount - 1, 0) });
  }

  async dragDropInput(files) {

    let newFiles = [];
    let uploadArray = [];
    let nextId = this.state.mediaId;

    for(const fl of files) {
      let fileError = getErrorProductImage(fl);
      if(fileError !== '') {
        this.setState({
          errorMedia: fileError,
          dragCount: 0,
        });
        return null;
      } else {
        uploadArray.push(this.props.productUploadMedia(this.props.productObj.permalink, this.props.productObj.productLine.permalink, fl));
      }
    }

    this.setState({
      requestPending: true,
      requestError: null,
    });

    const uploadRespArray = await Promise.all(uploadArray)
      .catch((errResp) => {
        this.setState({
          requestPending: false,
          requestError: errResp && errResp.error ? errResp.error : ERR_5500,
        });
      });

    if(!uploadRespArray) {
      return false;
    }

    for(const uResp of uploadRespArray) {
      newFiles.push({
        id: nextId + 1,
        obj: {
          order: nextId,
          key: uResp.key,
          caption: '',
        },
      });
      nextId++;
    }

    let currentMedia = this.state.unsavedMediaArray;
    this.setState({
      unsavedMediaArray: currentMedia.concat(newFiles),
      errorMedia: '',
      mediaId: nextId,
      dragCount: 0,
      requestPending: false,
      requestError: null,
    }, () => {
      this.makeDirty();
    });
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductMediaEdit adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INV_ADD_PRODUCT_STEP_3)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'productMediaForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

            <div className='imagesPreviewWrapper'>
              <ProductMediaSortableGrid
                objArray={this.state.unsavedMediaArray}
                setMedia={this.setMedia.bind(this)} />
            </div>
            <div className='fileDropzoneWrapper'>
              <Dropzone onDrop={acceptedFiles => this.dragDropInput(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div 
                        ref={this.fileDropRef}
                        className={this.state.dragCount ? 'dropWrapper hoverFile' : 'dropWrapper'}
                        onDragEnter={this.dragEnterInput.bind(this)}
                        onDragLeave={this.dragLeaveInput.bind(this)}>
                        <div className='dropPrompt'>{t(tx.TX_INV_ADD_PRODUCT_DRAG_HERE)}</div>
                        {this.state.errorMedia ?
                          <div className='dropError'>{t(this.state.errorMedia)}</div> :
                          null
                        }
                        <button 
                          className='dropButton'
                          type='button'>
                          {t(tx.TX_INV_ADD_PRODUCT_UPLOAD_IMAGE)}
                        </button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductMediaEdit));