import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../../constants/strings';
import { 
  URL_CHECKOUT, 
  URL_FORGOT_PASSWORD, 
  URL_REGISTER, 
} from '../../../../../constants/urls';

import { setReAuth } from '../../../../../utils/auth';
import { 
  getEmailError, 
  getPasswordInputError, 
  isFormValid, 
} from '../../../../../utils/form-validation';
import { formatServerError } from '../../../../../utils/formatting';

import * as authActionCreators from '../../../../../actions/auth';
import * as cartActionCreators from '../../../../../actions/cart';
import * as userActionCreators from '../../../../../actions/user';
let allActionCreators = Object.assign({}, authActionCreators, cartActionCreators, userActionCreators);

export class CheckoutStepBuylistAuthInput extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',

      errorEmail: '',
      errorPassword: '',

      loginReqError: null,
      loginReqPending: false,

      inputFirstName: '',
      inputLastName: '',
      inputGuestEmail: '',

      errorFirstName: '',
      errorLastName: '',
      errorGuestEmail: '',

      guestReqError: null,
      guestReqPending: false,
    };
  }

  validateAll() {
    const errorObj = {
      errorEmail: getEmailError(this.state.email),
      errorPassword: getPasswordInputError(this.state.password),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changeEmail(evt) {
    this.setState({ email: evt.target.value }, () => {
      if(this.state.errorEmail) {
        this.validateEmail(this.state.email);
      }
    });
  }

  validateEmail() {
    this.setState({ errorEmail: getEmailError(this.state.email) });
  }

  changePassword(evt) {
    this.setState({ password: evt.target.value }, () => {
      if(this.state.errorPassword) {
        this.validatePassword(this.state.password);
      }
    });
  }

  validatePassword() {
    this.setState({ errorPassword: getPasswordInputError(this.state.password) });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const login_data = {
        email_address: this.state.email,
        password: this.state.password,
      };

      this.setState({ 
        loginReqPending: true, 
        loginReqError: null,
      });

      this.props.usersLogin(login_data)
      .then((resp) => {
        
        this.setState({ 
          loginReqPending: false,
          loginReqError: null,
        });

        if(this.props.isBuylist) {
          if(!this.props.cart.buylistMode) {
            this.props.setBuylistMode(true);
          }
        } else {
          if(!this.props.cart.buylistMode) {
            this.props.setBuylistMode(false);
          }
        }

        // Record login in store, primarily to trigger re-renders
        this.props.authSet(resp);

        // Init reAuth timer
        setReAuth();

        this.props.setStepData(this.props.config, {
          isGuest: false, 
          guestUser: null,
        });
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          loginReqPending: false,
          loginReqError: formatServerError(errResp),
        });
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'CheckoutStepBuylistAuthInput CheckoutStepComponent'}>
      <div className='csaAuthBlock'>
        <div className='csaAuthLogin'>
          <form className={'checkoutStepInputForm'} onSubmit={this.handleSubmit.bind(this)}>
            <div className='checkoutFormSubheader'>{t(tx.TX_LOGIN)}</div>
            <div className={this.state.loginReqError ? 'checkoutFormServerError present' : 'checkoutFormServerError'}>
              <div className='FlexCenter'>{t(this.state.loginReqError)}</div>
            </div>
            <div className={'checkoutFormInput'}>
              <div className='checkoutFormLabel'>{t(tx.TX_EMAIL_ADDRESS)}</div>
              <input
                type='email'
                autoComplete='email'
                className={this.state.errorEmail ? 'InputError' : ''}
                value={this.state.email}
                onChange={this.changeEmail.bind(this)}
                onBlur={this.validateEmail.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_EMAIL)} />
              <div 
                className={'checkoutFormError FieldError'}
                dangerouslySetInnerHTML={{__html: this.state.errorEmail ? t(this.state.errorEmail) : ''}} />
            </div>
            <div className={'checkoutFormInput'} style={{ display: 'none' }}>
              <div className='checkoutTextField'>{t(tx.TX_CHECKOUT_BUYLIST_EMAIL_NOTICE)}</div>
            </div>
            <div className={'checkoutFormInput'}>
              <div className='checkoutFormLabel'>{t(tx.TX_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='current-password'
                className={this.state.errorPassword ? 'InputError' : ''}
                value={this.state.password}
                onChange={this.changePassword.bind(this)}
                onBlur={this.validatePassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)} />
              {this.state.errorPassword ?
                <div className={'checkoutFormError FieldError'}>{t(this.state.errorPassword)}</div> :
                null
              }
              <div className='forgotPasswordWrapper'>
                <Link to={{
                  pathname: URL_FORGOT_PASSWORD,
                  state: {
                    emailVal: this.state.email
                  },
                }}>{t(tx.TX_FORGOT_PASSWORD_QUESTION)}</Link>
              </div>
            </div>
            <div className={'checkoutFormInput fullWidth'}>
              <button 
                className='checkoutFormButton'
                disabled={this.state.loginReqPending}>
                {t(tx.TX_LOGIN_SHORT)}
              </button>
            </div>
          </form>
        </div>
        <div className='csaAuthRegister'>
          <div className='csaAuthRegisterLiner'>
            <Link to={{
              pathname: URL_REGISTER,
              state: { from: URL_CHECKOUT }
            }}>
              <div className='csTextLink'>{t(tx.TX_CREATE_ACCOUNT)}</div>
            </Link>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckoutStepBuylistAuthInput));