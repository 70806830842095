import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';
import { URL_HOME, URL_LOGIN } from '../../constants/urls';

import { isFormValid, getPasswordSetError, getPasswordMatchError } from '../../utils/form-validation';
import { formatServerError } from '../../utils/formatting';

import './style/_auth.scss';

import * as userActionCreators from '../../actions/user';
let allActionCreators = Object.assign({}, userActionCreators);

export class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordError: '',
      passwordAgain: '',
      passwordAgainError: '',

      resetReqError: null,
      resetReqPending: false,
    };

    this.forgotToken = this.props.match.params.forgotToken;
    if(!this.forgotToken) {
      this.props.history.push(URL_HOME);
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if(this.validateAll()) {

      const reset_data = {
        new_password: this.state.password,
        token: this.forgotToken,
      };

      this.setState({ 
        resetReqPending: true, 
        resetReqError: null,
      });

      this.props.usersResetPassword(reset_data)
      .then((resp) => {

        this.setState({
          resetReqPending: false,
        });
        this.props.history.push(URL_LOGIN);
      })
      .catch((errResp) => {
        console.error(errResp);
        this.setState({ 
          resetReqPending: false,
          resetReqError: formatServerError(errResp),
        });
      });
    }
  }

  validateAll() {
    let errorObj = {
      passwordError: getPasswordSetError(this.state.password),
      passwordAgainError: getPasswordMatchError(this.state.passwordAgain, this.state.password),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  changePassword(evt) {
    this.setState({ password: evt.target.value }, () => {
      if(this.state.passwordError) {
        this.validatePassword();
      }
    });
  }

  validatePassword() {
    this.setState({ passwordError: getPasswordSetError(this.state.password) }, () => {
      if(this.state.passwordAgain !== '' && this.state.passwordAgainError !== '') {
        this.validatePasswordAgain();
      }
    });
  }

  changePasswordAgain(evt) {
    this.setState({ passwordAgain: evt.target.value }, () => {
      if(this.state.passwordAgainError) {
        this.validatePasswordAgain();
      }
    });
  }

  validatePasswordAgain() {
    this.setState({ passwordAgainError: getPasswordMatchError(this.state.passwordAgain, this.state.password) });
  }

  render() {

    const {t} = this.props;

    return <div className={'ResetPassword authPage'}>
      <div className='authPageTitle'>{t(tx.TX_RESET_PASSWORD)}</div>
      <div className='authPageBody'>
        <div className='loginWrapper'>

          <form className='loginForm' onSubmit={this.handleSubmit.bind(this)}>
            <div className={this.state.resetReqError ? 'loginFormError present' : 'loginFormError'}>{t(this.state.resetReqError)}</div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='new-password'
                className={this.state.passwordError ? 'InputError' : ''}
                value={this.state.password}
                onChange={this.changePassword.bind(this)}
                onBlur={this.validatePassword.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)}
                maxLength={100} />
              {this.state.passwordError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordError)}</div> :
                null
              }
            </div>
            <div className={'loginFieldWrapper inputFieldWrapper'}>
              <div className='loginFieldLabel'>{t(tx.TX_RETYPE_PASSWORD)}</div>
              <input
                type='password'
                autoComplete='new-password'
                className={this.state.passwordAgainError ? 'InputError' : ''}
                value={this.state.passwordAgain}
                onChange={this.changePasswordAgain.bind(this)}
                onBlur={this.validatePasswordAgain.bind(this)}
                placeholder={t(tx.TX_PLACEHOLDER_PASSWORD)}
                maxLength={100} />
              {this.state.passwordAgainError ?
                <div className={'loginError FieldError'}>{t(this.state.passwordAgainError)}</div> :
                null
              }
            </div>
            <div className='loginFieldWrapper'>
              <button 
                className='loginSubmitButton'
                disabled={this.state.resetReqPending}>
                {t(tx.TX_UPDATE)}
              </button>
            </div>

          </form>

        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ResetPassword));