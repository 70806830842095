

import {
	APP_ENV,
	APP_ENV_LOCAL,
} from '../constants/app';

import {
	encryptObj,
	decryptObj,	
} from '../utils/crypto';

// Usage notes

// key: should always be system constant (constants/storage)
// value: must always be an object

// Storage in non-local environments should be obfuscated


export function put(key, value) {
	if(APP_ENV === APP_ENV_LOCAL) {
		try {
			return window.localStorage.setItem(key, JSON.stringify(value));
		} catch(err) {
			try {
				return window.localStorage.setItem(key, encryptObj(value));
			} catch(err2) {
				return null;
			}
		}
	} else {
		try {
			return window.localStorage.setItem(key, encryptObj(value));
		} catch(err) {
			try {
				return window.localStorage.setItem(key, JSON.stringify(value));
			} catch(err2) {
				return null;
			}
		}
	}
}

export function get(key) {
	if(APP_ENV === APP_ENV_LOCAL) {
		try {
			return JSON.parse(window.localStorage.getItem(key));
		} catch(err) {
			try {
				return decryptObj(window.localStorage.getItem(key));
			} catch(err2) {
				return null;
			}
		}
	} else {
		try {
			return decryptObj(window.localStorage.getItem(key));
		} catch(err) {
			try {
				return JSON.parse(window.localStorage.getItem(key));
			} catch(err2) {
				return null;
			}
		}
	}
}

export function remove(key) {
	return window.localStorage.removeItem(key);
}

export function clear() {
  window.localStorage.clear();
}