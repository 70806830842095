import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { getStoreLanguage } from '../../../../utils/language';
import { 
  getOrderedInventory, 
  getPricesCount, 
} from '../../../../utils/product';

import LoadingIcon from '../../../Icons/LoadingIcon';

let allActionCreators = Object.assign({});


export class ProductLinePricingView extends Component {

  getProductInventory() {
    return this.props.productObj && this.props.productObj.inventory ? getOrderedInventory(this.props.productObj) : [];
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getPriceLabel() {
    if(this.props.productObj && this.props.productObj.inventory && getPricesCount(this.props.productObj.inventory) > 1) {
      return tx.TX_INV_VIEW_PRODUCT_PRICE_RANGE;
    } else {
      return tx.TX_INV_VIEW_PRODUCT_PRICE;
    }
  }

  editInventory(inventoryObj) {
    this.props.toggleEdit(inventoryObj);
  }

  isEnabled() {
    return this.props.productLineObj && this.props.productLineObj.automatedPricing;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductLinePricingView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INV_VIEW_PRODUCT_LINE_PRICING)}</div>
        <div 
          className='asToggleWrapper' 
          onClick={this.props.toggleExpanded.bind(this)}
          dangerouslySetInnerHTML={{__html: this.props.expanded ? '—' : '+'}} />
      </div>
      <div className={this.props.expanded ? 'adminSectionBody expandableBody expanded' : 'adminSectionBody expandableBody'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='productLineViewWrapper'>
              {!this.isEnabled() ?
                <div className='pricingDisabledWrapper'>{t(tx.TX_CURRENTLY_DISABLED)}</div> :
                <div className='pricingEnabledWrapper'>
                  <div className='pricingDisabledWrapper'>{t(tx.TX_ENABLED)}</div>
                </div>
              }
            </div>
            <div className='adminViewButtonWrapper'>
              <button 
                type='button'
                className={'productDetailsButton adminButton'} 
                onClick={() => this.props.toggleEdit(null)}>
                {t(tx.TX_EDIT)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.productPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLinePricingView));