import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { history } from '../../store';

import { ERR_5500 } from '../../constants/errors';
import { MEDIA_DATA_TYPE_FILE } from '../../constants/media';
import * as tx from '../../constants/strings';
import { URL_ADMIN_INVENTORY } from '../../constants/urls';

import { Product } from '../../models/products';

import { formatServerError } from '../../utils/formatting';

import AddProductSidebar from './blocks/AddProductSidebar';
import AddProductStep1 from './blocks/AddProductStep1';
import AddProductStep2 from './blocks/AddProductStep2';
import AddProductStep3 from './blocks/AddProductStep3';
import AdminFormBreadcrumbs from '../Admin/AdminFormBreadcrumbs';
import AdminTitle from '../Admin/AdminTitle';
import Confirm from '../Popups/Confirm';
import SavePrompt from '../Popups/SavePrompt';

import './style/_addproduct.scss';

import * as productActionCreators from '../../actions/product';
import * as productLineActionCreators from '../../actions/product-line';
let allActionCreators = Object.assign({}, productLineActionCreators, productActionCreators);

export class AddProduct extends Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollPos: 0,

      isDirty: false,
      savePromptOpen: false,
      deletePromptOpen: false,
      lastBlock: '',
      saveRedirect: false,

      inventoryObj: null,
      mediaArray: [], 
      productObj: null,

      currentStep: 0,
      genericOverride: false,

      breadcrumbResetSignal: null, 
      validationSignals: [
        null,
        null,
        null,
      ],

      requestPending: false,
      requestError: null,
      requestFail: false,

      formMinHeight: 'initial',

      existingProduct: null,
    };

    this.formSteps = [
      {
        name: tx.TX_INV_ADD_PRODUCT_STEP_1,
      },
      {
        name: tx.TX_INV_ADD_PRODUCT_STEP_2,
      },
      {
        name: tx.TX_INV_ADD_PRODUCT_STEP_3,
      },
    ];

    this.sidebarElement = React.createRef();
    this.titleElement = React.createRef();
    this.bcElement = React.createRef();
    this.invElement = React.createRef();
    
    this.checkPosThrottled = _.throttle(this.getFormMinHeight.bind(this), 50);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkPosThrottled, false);
    window.addEventListener('resize', this.checkPosThrottled, false);

    // Save form guarding
    this.unblock = history.block((blobj, and) => {

      this.setState({ lastBlock: blobj });
      if(this.state.productObj === null || this.state.isDirty === false) {
        return true;
      }

      this.toggleSavePrompt();
      return false;
    });

    // Product line data
    if(this.props.productLine && this.props.productLine.productLines === null) {
      
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkPosThrottled, false);
    window.removeEventListener('resize', this.checkPosThrottled, false);
    this.unblock();
  }

  getSidebarStyle() {

    const SIDEBAR_BREAK = 768;
    const SIDEBAR_OFFSET = 80;

    const MARGIN_BREAK = 900;
    const MARGIN_VALUE = window.innerWidth > MARGIN_BREAK ? 25 : 10;

    const BREAK_1500 = 1500;
    const BREAK_1500_OFFSET = 1175;

    const styleObj = {};

    if(window.innerWidth > SIDEBAR_BREAK) {
      
      if(this.titleElement && this.titleElement.current && this.titleElement.current.clientHeight && this.invElement && this.invElement.current) {

        const bcHeight = 0;

        if(window.scrollY > this.titleElement.current.clientHeight + 25 + (25 - MARGIN_VALUE) + bcHeight) {

          if(!this.sidebarElement || !this.sidebarElement.current) { return styleObj; }

          const topValue = SIDEBAR_OFFSET + MARGIN_VALUE;
          const fixedOnBottom = topValue + this.sidebarElement.current.getBoundingClientRect().height > this.invElement.current.getBoundingClientRect().bottom;

          if(fixedOnBottom) {
            styleObj['top'] = 'unset';
            styleObj['bottom'] = '0';
          } else {
            styleObj['position'] = 'fixed';
            styleObj['top'] = `${topValue}px`;
          }

          if(window.innerWidth > BREAK_1500) {

            if(fixedOnBottom) {
              styleObj['right'] = `${window.innerWidth - BREAK_1500_OFFSET}px`;  
            } else {
              styleObj['left'] = `${BREAK_1500_OFFSET}px`;
            }
            
          } else {
            styleObj['right'] = fixedOnBottom ? '0' : '25px';
          }
        }
      }
    }

    return styleObj;
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  getSelectNoticeStyle() {
    try {
      if(this.props.productLine.productLinesPending === false && this.props.productLine.productLines.length > 0 && this.state.inputProductLine === '') {
        return {
          display: 'block',
        }
      }
    } catch(err) {
      return {};  
    }
    return {};
  }

  getEmptyNoticeStyle() {
    try {
      if(this.props.productLine.productLinesPending === false && this.props.productLine.productLines.length === 0) {
        return {
          display: 'block',
        }
      }
    } catch(err) {
      return {};  
    }
    return {};
  }

  makeDirty() {
    if(this.state.isDirty === false) {
      this.setState({ isDirty: true });
    }
  }

  toggleSavePrompt() {
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  validateStep(stepIndex) {
    try {

      const idx = parseInt(stepIndex);
      this.changeCurrentStep(idx, () => {
        
        let signalsCopy = [...this.state.validationSignals];
        signalsCopy[idx] = Date.now();

        this.setState({
          validationSignals: signalsCopy,
        });
      });
    } catch(err) {
      console.error(err);
    }
  }

  async saveInventoryObj(invData) {
    let response = await this.props.productInventoryAdd(invData)
      .catch((errResp) => {
        this.changeCurrentStep(1);
        this.setState({
          requestError: formatServerError(errResp),
          requestFail: true,
        });
      });
    return response;
  }

  saveAndRedirect(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ saveRedirect: true }, () => {
      this.saveProduct();
    });
  }

  async saveProduct(evt) {

    if(evt) { evt.preventDefault(); }

    // Local validation
    if(!this.state.productObj.isValid()) {
      this.validateStep(0);
      return null;
    } else if(!this.state.inventoryObj.isValid()) {
      this.validateStep(1);
      return null;
    } else {
      for(const md of this.state.mediaArray) {
        if(!md.isValid()) {
          this.validateStep(2);
          return null;
        }
      }
    }

    this.setState({
      requestPending: true,
      requestError: null,
      requestFail: false,
    });

    const prodResp = await this.props.productAdd(this.state.productObj.getApiData())
      .catch((errResp) => {
        this.changeCurrentStep(0);
        this.setState({
          requestPending: false,
          requestError: formatServerError(errResp),
          requestFail: true,
        });

        if(errResp && errResp.error && errResp.error.code === 1446 && errResp.error.existing) {
          this.setExistingProduct(errResp.error.existing);
        }

        return null;
      });

    if(!prodResp || !prodResp.id) {
      return null;
    }

    // Step 2 - Add inventory and media simultaniously
    let subsequentReqArray = [];

    subsequentReqArray.push(this.saveInventoryObj(this.state.inventoryObj.getCreateApiData(this.state.productObj)));

    // Step 2a - Upload all files to S3
    let uploadArray = [];
    for(const mediaItem of this.state.mediaArray) {
      uploadArray.push(this.props.productUploadMedia(this.state.productObj.permalink, this.state.productObj.productLine.permalink, mediaItem.file));
    }
    const uploadRespArray = await Promise.all(uploadArray)
      .catch((errResp) => {
        this.setState({
          requestPending: false,
          requestError: errResp && errResp.error ? errResp.error : ERR_5500,
          requestFail: true,
        });
      });

    if(!uploadRespArray) {
      return false;
    }

    for(let i = 0; i < uploadRespArray.length; i++) {
      subsequentReqArray.push(this.props.productMediaAdd({
        order: i,
        key: uploadRespArray[i].key,
        url: uploadRespArray[i].key,
        caption: '',
        product_permalink: this.state.productObj.permalink,
        pl_permalink: this.state.productObj.productLine.permalink
      }));
    }

    // Submit all requests
    await Promise.all(subsequentReqArray);

    if(this.state.requestFail) {
      // Doesn't display failure; since product is already created, should leave the create view 
      // In the future, we can send them to the view product view with an error (ie, product was created, but with errors)
      this.setState({
        isDirty: false,
        requestPending: false,
        requestError: null,
        requestFail: false,
      }, () => {
        if(!this.state.saveRedirect) {
          history.push(URL_ADMIN_INVENTORY);
        } else {
          history.push(this.state.lastBlock);
        }
      });
    } else {
      // Success;
      this.setState({
        isDirty: false,
        requestPending: false,
        requestError: null,
        requestFail: false,
      }, () => {
        if(!this.state.saveRedirect) {
          history.push(URL_ADMIN_INVENTORY);
        } else {
          history.push(this.state.lastBlock);
        }
      });
    }    
  }

  getProductLines() {
    return this.props.productLine.productLines ? this.props.productLine.productLines : [];
  }

  changeCurrentStep(st, cb) {
    
    if(st < 0) { st = 0; }
    if(st > this.formSteps.length - 1) { st = this.formSteps.length - 1; }
    
    this.setState({ 
      currentStep: st,
      requestError: null,
    }, () => {

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      if(cb) { cb(); }
    });
  }

  toggleGenericOverride() {
    this.setState({

      genericOverride: !this.state.genericOverride,
      currentStep: 0, 

      isDirty: false,
      inventoryObj: null,
      mediaArray: [], 
      productObj: null,
    })
  }

  clearExistingProduct() {
    this.setState({ existingProduct: null });
  }

  setExistingProduct(resp) {
    if(!resp) { return null; }

    if(resp) {
      this.setState({ existingProduct: new Product(resp) });
    }
  }

  navigateExisting() {
    if(!this.state.existingProduct) { return null; }

    this.unblock();
    history.push(this.state.existingProduct.getAdminUrl());
  }

  getCurrentStep() {
    switch(this.state.currentStep) {
      case 0:
        return <AddProductStep1
                  productObj={this.state.productObj}
                  setProduct={this.setProduct.bind(this)}
                  setInventory={this.setInventory.bind(this)}
                  makeDirty={this.makeDirty.bind(this)}
                  moveNext={() => this.changeCurrentStep(this.state.currentStep + 1)}
                  movePrev={null}
                  genericOverride={this.state.genericOverride}
                  toggleGenericOverride={this.toggleGenericOverride.bind(this)}
                  validationSignal={this.state.validationSignals[0]}
                  requestPending={this.state.requestPending}
                  requestError={this.state.requestError} />;
      case 1:
        return <AddProductStep2
                  productObj={this.state.productObj}
                  inventoryObj={this.state.inventoryObj}
                  setInventory={this.setInventory.bind(this)}
                  makeDirty={this.makeDirty.bind(this)}
                  saveProduct={this.saveProduct.bind(this)}
                  moveNext={() => this.changeCurrentStep(this.state.currentStep + 1)}
                  movePrev={() => this.changeCurrentStep(this.state.currentStep - 1)}
                  genericOverride={this.state.genericOverride}
                  toggleGenericOverride={this.toggleGenericOverride.bind(this)}
                  validationSignal={this.state.validationSignals[1]}
                  requestPending={this.state.requestPending}
                  requestError={this.state.requestError} />;
      case 2:
        return <AddProductStep3
                  productObj={this.state.productObj}
                  inventoryObj={this.state.inventoryObj}
                  mediaArray={this.state.mediaArray}
                  setMedia={this.setMedia.bind(this)}
                  makeDirty={this.makeDirty.bind(this)}
                  saveProduct={this.saveProduct.bind(this)}
                  movePrev={() => this.changeCurrentStep(this.state.currentStep - 1)}
                  genericOverride={this.state.genericOverride}
                  toggleGenericOverride={this.toggleGenericOverride.bind(this)}
                  validationSignal={this.state.validationSignals[2]}
                  requestPending={this.state.requestPending}
                  requestError={this.state.requestError} />;
      default:
        return <></>;
    }
  }

  setProduct(product) {
    if(product === null) {
      this.setState({ 
        breadcrumbResetSignal: Date.now(),
        inventoryObj: null,
        mediaArray: [], 
        productObj: product, 
      });
    } else {
      this.setState({ productObj: new Product(product) });
    }
  }

  setInventory(inventory) {
    this.setState({ inventoryObj: inventory });
  }

  setMedia(media) {
    this.setState({ mediaArray: media });
  }

  // Selectively exclude media depending on product line
  getFormSteps() {
    if(this.state.productObj && this.state.productObj.productLine && this.state.productObj.productLine.isManaged && !this.state.genericOverride) {
      return this.formSteps.slice(0, -1);
    }
    return this.formSteps;
  }

  getFormMinHeight() {

    const bcBodySpacing = 25;

    if(this.sidebarElement && this.sidebarElement.current && this.bcElement && this.bcElement.current) {
      let formMinHeightCalc = (this.sidebarElement.current.clientHeight - this.bcElement.current.clientHeight - bcBodySpacing) + 'px';
      this.setState({ formMinHeight: formMinHeightCalc });
    } else {
      this.setState({ formMinHeight: 'initial' });
    }
  }

  render() {
    return <div className={'AddProduct AdminPage'}>
      <div className='aiTitleWrapper' ref={this.titleElement}>
        <AdminTitle
          title={tx.TX_INV_ADD_PRODUCT}
          breadcrumbs={[
            {
              url: URL_ADMIN_INVENTORY,
              title: tx.TX_CATALOG,
            },
          ]} />
      </div>
      <div className='adminBody'>
        <div className='aiBreadcrumbWrapper' ref={this.bcElement}>
          <AdminFormBreadcrumbs
            steps={this.getFormSteps()}
            resetSignal={this.state.breadcrumbResetSignal}
            currentStep={this.state.currentStep}
            changeStep={this.changeCurrentStep.bind(this)} />
        </div>
        <div className='addInventoryWrapper' ref={this.invElement}>
          <div 
            className='aiFormWrapper' 
            style={{ 
              minHeight: this.state.formMinHeight, 
            }}>
            {this.getCurrentStep()}
          </div>
        </div>
        <div 
          className='aiSidebarWrapper' 
          ref={this.sidebarElement}
          style={this.getSidebarStyle()}>
          <AddProductSidebar
            productObj={this.state.productObj} 
            inventoryObj={this.state.inventoryObj}
            mediaArray={this.state.mediaArray}
            mediaType={MEDIA_DATA_TYPE_FILE}
            saveProduct={this.saveProduct.bind(this)}
            imageLoaded={this.getFormMinHeight.bind(this)} />
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAndRedirect.bind(this)}
        onCancel={this.leaveWithoutSave.bind(this)} />
      <Confirm
        title={tx.TX_INV_ADD_PRODUCT_ALREADY_EXISTS_TITLE}
        copy={tx.TX_INV_ADD_PRODUCT_ALREADY_EXISTS_COPY}
        open={this.state.existingProduct !== null}
        confirmValue={tx.TX_EDIT}
        closeMethod={this.clearExistingProduct.bind(this)}
        onConfirm={this.navigateExisting.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProduct));