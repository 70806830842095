import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const allActionCreators = Object.assign({});

export class StoreHoursChile extends Component {

  render() {
    return <div className={'StoreHours StoreHoursChile'}>
      <div className={`storeHoursWrapper`}>
        <div className='hoursLine'>
          <div className='hoursDay'>Lunes a Viernes</div>
          <div className='hoursValue'>12:00 a 22:00</div>
        </div>
        <div className='hoursLine'>
          <div className='hoursDay'>Sabado, Domingo y Festivos</div>
          <div className='hoursValue'>12:00 a 20:00</div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(StoreHoursChile));