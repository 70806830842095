import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import {
  CAL_DISPLAY_TYPE_MOBILE,
  CAL_DISPLAY_TYPE_MONTHLY,
  CAL_DISPLAY_TYPE_WEEKLY,
} from '../../../constants/calendar';
import { 
  CSS_SLIDE_DURATION, 
  CSS_SLIDE_FAST_DURATION, 
} from '../../../constants/css';
import { ICON_CHEVRON_DOWN_DOUBLE } from '../../../constants/icons';
import * as tx from '../../../constants/strings';

import { getDaysEvents } from '../../../utils/events';
import { isDateToday } from '../../../utils/general';
import { getStoreLanguage } from '../../../utils/language';

import EventCalendarEventEntry from './EventCalendarEventEntry';
import Icon from '../../Icons/Icon';

let allActionCreators = Object.assign({});

export class EventCalendarDay extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openingExpanded: false,
      closingExpanded: false,
      overflown: false,
    }

    this.openTimeout = null;
    this.closeTimeout = null;

    this.bodyWrapper = React.createRef();
    this.bodyElement = React.createRef();
    this.dayBodyWrapper = React.createRef();

    this.checkSizeThrottled = _.throttle(this.checkSize.bind(this), 100);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.checkSizeThrottled, false);
    this.checkSize();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.expanded === true && this.props.expanded === false) {
      // Closing expansion
      this.setState({ closingExpanded: true }, () => {
        this.closeTimeout = setTimeout(() => {
          this.setState({ closingExpanded: false });
        }, CSS_SLIDE_FAST_DURATION);
      });
    } else if(prevProps.expanded === false && this.props.expanded === true) {
      // Opening expansion
      this.setState({ openingExpanded: true }, () => {
        this.openTimeout = setTimeout(() => {
          this.setState({ openingExpanded: false });
        }, CSS_SLIDE_DURATION);
      });
    }
    this.checkSize();
  }

  componentWillUnmount() {
    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
    window.removeEventListener('resize', this.checkSizeThrottled, false);
  }

  checkSize() {

    if(!this.bodyWrapper || !this.bodyWrapper.current) {
      return null;
    }

    if(!this.bodyElement || !this.bodyElement.current) {
      return null;
    }

    const wrapperHeight = this.bodyWrapper.current.getBoundingClientRect().height;
    const elementHeight = this.bodyElement.current.getBoundingClientRect().height;

    if(elementHeight > wrapperHeight && !this.state.overflown) {
      this.setState({ overflown: true });
    } else if(elementHeight <= wrapperHeight && this.state.overflown) {
      this.setState({ overflown: false });
    }
  }

  isMobileView() {
    return this.props.displayMode === CAL_DISPLAY_TYPE_MOBILE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getDateClass() {
    
    const offMonthClass = this.props.displayMode === CAL_DISPLAY_TYPE_MONTHLY && this.props.displayMonth.inThisMonth(this.props.date) === false ? ' offMonth' : '';

    return `ecDayDate${offMonthClass}`;
  }

  shouldShowMonth() {
    return this.props.displayMode === CAL_DISPLAY_TYPE_WEEKLY && this.props.date.getDate() === 1 ? true : false;
  }

  getRootClass() {
    const baseClass = 'EventCalendarDay';

    const adminClass = this.props.admin ? ' adminTheme' : '';
    const expandedClass = this.props.expanded ? ' expanded' : '';
    const mobileClass = this.isMobileView() ? ' mobileView' : '';

    return `${baseClass}${adminClass}${expandedClass}${mobileClass}`;
  }

  expandDay() {
    if(this.props.setExpanded) {
      this.props.setExpanded(this.props.date);
    }
  }

  unexpandDay() {
    if(this.props.setExpanded) {
      this.props.setExpanded(null);
    }
  }

  getDayBodyWrapperStyles() {
    if(!this.props.expanded || this.state.openingExpanded) {
      return {};
    }

    if(!this.bodyWrapper || !this.bodyWrapper.current) {
      return {};
    }

    if(!this.dayBodyWrapper || !this.dayBodyWrapper.current) {
      return {};
    }

    const wrapperHeight = this.bodyWrapper.current.getBoundingClientRect().height;
    const topDiff = this.dayBodyWrapper.current.getBoundingClientRect().top - this.bodyWrapper.current.getBoundingClientRect().top;

    return { 
      height: `${wrapperHeight - topDiff}px`, 
    };
  }

  render() {

    const {t} = this.props;

    return <div className={this.getRootClass()}>
      <div className={`ecdLiner ${this.props.expanded ? 'expanded' : ''}`} ref={this.bodyWrapper}>
        <div className='ecdLinerHidden' ref={this.bodyElement}>
          {!this.isMobileView() ?
            <div className='ecDayLabel'>
              {this.props.firstWeek ?
                <div className='ecDayName'>{this.props.date.toLocaleString(this.getLanguage(), { weekday: 'long' })}</div> :
                null
              }
              <div className={this.getDateClass()}>
                {this.shouldShowMonth() ?
                  <>
                    {this.props.date.toLocaleString(this.getLanguage(), {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </> :
                  <>
                    {this.props.date.toLocaleString(this.getLanguage(), {
                      day: 'numeric',
                    })}
                  </>
                }
              </div>
              {this.props.expanded ?
                <div className='ecDayUnexpand' onClick={this.unexpandDay.bind(this)}>
                  <div className={'ecDayX FlexCenter'}>&times;</div>
                </div> :
                null
              }
            </div> :
            <div className={isDateToday(this.props.date) ? 'ecDayLabel ecTodayLabel' : 'ecDayLabel'}>
              <div className='ecMobileDayHr'></div>
              <div className='ecMobileDayLabel'>
                {this.props.date.toLocaleString(this.getLanguage(), { weekday: 'long', month: 'long', day: 'numeric' })}
              </div>
            </div>
          }
          <div className='ecDayBodyWrapper' style={this.getDayBodyWrapperStyles()} ref={this.dayBodyWrapper}>
            <div className='ecDayBody'>
              {!this.props.loading ?
                <>
                  {this.isMobileView() && getDaysEvents(this.props.date, this.props.event.calendarEvents, this.props.event.calendarSchedules, this.props.filters).length === 0 ?
                    <div className='ecDayBodyNotice'>{t(tx.TX_EVENTS_NO_EVENTS_SCHEDULED)}</div> :
                    null
                  }
                  {getDaysEvents(this.props.date, this.props.event.calendarEvents, this.props.event.calendarSchedules, this.props.filters).map((evt, i) => {
                    return <EventCalendarEventEntry
                              key={`${i}-${evt.schedule ? evt.schedule.publicUuid : evt.publicUuid}`}
                              admin={this.props.admin}
                              date={this.props.date}
                              displayMode={this.props.displayMode}
                              eventObj={evt}
                              openModal={this.props.openModal.bind(this)}
                              weekIndex={this.props.weekIndex} />;
                  })}
                </> :
                null
              }
            </div>
          </div>
        </div>
        <div className='ecdLinerVisible'>
          {this.props.displayMode === CAL_DISPLAY_TYPE_MONTHLY && (this.state.overflown || this.state.closingExpanded) && this.props.expanded === false ?
            <div className='overflowIndicatorWrapper' onClick={this.expandDay.bind(this)}>
              <div className='oiTransition'></div>
              <div className='oiMain'></div>
              <div className='ioIndicatorWrapper'>
                <div className='ioIndicatorIconWrapper'>
                  <Icon 
                    value={ICON_CHEVRON_DOWN_DOUBLE}  
                    iconClass={'ioIndicatorIcon'} />
                </div>
              </div>
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    event: state.event,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(EventCalendarDay));