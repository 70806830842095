import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import * as tx from '../../../../constants/strings';

import { simulateTab } from '../../../../utils/general';

const allActionCreators = Object.assign({});

export class FilterInputGeneric extends Component {

  constructor(props) {
    super(props);

    this.inputTimeoutDuration = 10*1000; // 10 seconds

    this.state = {
      inputValue: this.getCurrentValue(),
    }

    // Counters
    this.valueTimeout = null;

    this.submitValue = this.submitValue.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(!_.isEqual(prevProps.filters, this.props.filters)) {
      
      if(prevProps.filters[prevProps.filterElement.key] !== this.props.filters[this.props.filterElement.key]) {

        const wasPresent = Object.keys(prevProps.filters).includes(prevProps.filterElement.key);
        const isPresent = Object.keys(this.props.filters).includes(this.props.filterElement.key);

        if(wasPresent && !isPresent) {
          this.setState({ inputValue: '' });
        }
      }
    }
  }

  componentWillUnmount() {
    if(this.valueTimeout) {
      clearTimeout(this.valueTimeout);
    }
  }

  changeValue(evt) {
    this.setState({
      inputValue: evt.target.value,
    }, () => {
      if(this.valueTimeout) {
        clearTimeout(this.valueTimeout);
      }
      if(this.validateValue()) {
        this.valueTimeout = setTimeout(this.submitValue, this.inputTimeoutDuration);
      }
    });
  }

  validateValue() {
    try {
      if(this.state.inputValue === '') {
        return true;
      }
      
      // Nothing to do since it's generic
      // If that changes, add code here

    } catch(err) {
      return false;
    }
    return true;
  }

  keyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.submitValue();
      simulateTab();
    }
  }

  submitValue() {
    if(this.validateValue()) {

      try {

        const filterValue = this.getCurrentValue();

        // Intentionally use the crappy one to avoid any string/number confusion
        // eslint-disable-next-line
        if(this.state.inputValue != filterValue) {

          if(this.state.inputValue === '' && Object.keys(this.props.filters).includes(this.props.filterElement.key)) {
            this.props.clearFilter(this.props.filterElement.key);
            this.setState({ inputValue: '' });
          } else if(this.state.inputValue !== '') {
            this.props.setFilters({ [this.props.filterElement.key]: this.state.inputValue });
          }

          if(this.valueTimeout) {
            clearTimeout(this.valueTimeout);
          }
        }
      } catch(err) {
        console.error(err);
      }
    }
  }

  getCurrentValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.key) === false) {
        return '';
      } else {
        return this.props.filters[this.props.filterElement.key];
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterInputGeneric AdminTableFilter'}>
      <div className='filterElementTitle'>
        <div className='filterElementTitleValue'>{t(this.props.filterElement.name)}</div>
        <div className='filterElementClearLink' onClick={this.props.clearFilter.bind(this)}>{t(tx.TX_CLEAR)}</div>
      </div>
      <div className='filterElementBody'>
        <div className='singleInput'>
          <div className={'singleInputWrapper'}>
            <input
              type={this.props.inputType || 'text'}
              name={t(this.props.filterElement.name)}
              value={this.state.inputValue}
              placeholder={this.props.filterElement && this.props.filterElement.inputPlaceholder ? t(this.props.filterElement.inputPlaceholder) : t(this.props.filterElement.name)}
              onChange={this.changeValue.bind(this)}
              onBlur={this.submitValue.bind(this)}
              onKeyPress={this.keyPress.bind(this)} />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterInputGeneric));