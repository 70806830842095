import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Checkbox from '../../../Input/Checkbox';

let allActionCreators = Object.assign({});

export class FilterElementCheckboxes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
    };
  }

  getCompositeId(opt) {
    const paramKey = this.props.config.key;
    const valueStr = opt.toString();
    return `${paramKey}|${valueStr}`;
  }

  toggleCheckbox(evt) {

    const updatedFilters = [...this.props.filterValues];
    const idx = updatedFilters.indexOf(evt.target.value);

    if(idx > -1) {
      updatedFilters.splice(idx, 1);
    } else {
      updatedFilters.push(evt.target.value);
    }

    const filterKey = this.props.config.key;
    this.props.setFilter({
      [filterKey]: updatedFilters,
    });
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterElement FilterElementCheckboxes'}>
      <div className='feFilterTitle'>
        <div className={'feFilterTitleValue EllipsisElement'}>{t(this.props.config.label)}</div>
        <div className='feFilterTitleToggle' onClick={this.toggleExpanded.bind(this)}>
          <div className='feFilterTitleToggleElement FlexCenter'>
            {this.state.expanded ? 
              <>&mdash;</> :
              <>+</>
            }
          </div>
        </div>
      </div>
      <div className={`feFilterBody ${this.state.expanded ? 'open' : ''}`}>
        {this.props.options ?
          <div className='feFilterBodyLiner'>
            <div className='feFilterBodyLinerScroll'>
              {this.props.options.map((opt, i) => {
                return <div className='feCheckboxWrapper' key={i}>
                  <div className='feCheckboxInputWrapper'>
                    <Checkbox 
                      name={this.props.config.key}
                      id={this.getCompositeId(opt.value)}
                      value={opt.value}
                      checked={this.props.filterValues.includes(opt.value.toString())}
                      onChange={this.toggleCheckbox.bind(this)} />
                  </div>
                  <label 
                    htmlFor={this.getCompositeId(opt.value)}
                    className='feCheckboxValueWrapper'>
                    {t(opt.display)}
                  </label>
                </div>;
              })}
            </div>
          </div> :
          null
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterElementCheckboxes));