

import * as tx from './strings';

export const FN_KEY_NONFOIL = 'nonfoil';
export const FN_KEY_FOIL = 'foil';
export const FN_KEY_ETCHED_FOIL = 'etched';
export const FN_KEY_HOLOFOIL = 'holofoil';
export const FN_KEY_REVERSE_HOLOFOIL = 'reverse-holofoil';
export const FN_KEY_COLD_FOIL = 'cold-foil';
// export const FN_KEY_TEXTURED_FOIL = 'textured';

export const FINISH_NONFOIL = {
	key: FN_KEY_NONFOIL,
	name: tx.TX_FINISH_NONFOIL,
	description: '',
	exportName: 'Regular',
}

export const FINISH_FOIL = {
	key: FN_KEY_FOIL,
	name: tx.TX_FINISH_FOIL,
	description: '',
	exportName: 'Foil',
}

export const FINISH_ETCHED_FOIL = {
	key: FN_KEY_ETCHED_FOIL,
	name: tx.TX_FINISH_ETCHED_FOIL,
	description: '',
	exportName: 'Etched Foil',
}

// Not used as it's own finish for Magic, it's a tag currently
// Keeping it here in case we need a textured foil in the future

// export const FINISH_TEXTURED_FOIL = {
// 	key: FN_KEY_TEXTURED_FOIL,
// 	name: tx.TX_FINISH_TEXTURED_FOIL,
// 	description: '',
// 	exportName: 'Textured foil',
// }

export const FINISH_HOLOFOIL = {
	key: FN_KEY_HOLOFOIL,
	name: tx.TX_FINISH_HOLOFOIL,
	description: '',
	exportName: 'Holofoil',
}

export const FINISH_REVERSE_HOLOFOIL = {
	key: FN_KEY_REVERSE_HOLOFOIL,
	name: tx.TX_FINISH_REVERSE_HOLOFOIL,
	description: '',
	exportName: 'Reverse Holofoil',
}

export const FINISH_COLD_FOIL = {
	key: FN_KEY_COLD_FOIL,
	name: tx.TX_FINISH_COLD_FOIL,
	description: '',
	exportName: 'Cold Foil',
}

export const FINISHES_ALL = [ 
	FINISH_NONFOIL, 
	FINISH_FOIL, 
	FINISH_ETCHED_FOIL, 
	FINISH_HOLOFOIL, 
	FINISH_REVERSE_HOLOFOIL, 
];

export const FINISHES_MAGIC = [ 
	FINISH_NONFOIL, 
	FINISH_FOIL, 
	FINISH_ETCHED_FOIL, 
];

export const FINISHES_POKEMON = [ 
	FINISH_NONFOIL, 
	FINISH_HOLOFOIL, 
	FINISH_REVERSE_HOLOFOIL, 
];

export const FINISHES_LORCANA = [
	FINISH_NONFOIL,
	FINISH_HOLOFOIL, 
	FINISH_COLD_FOIL, 
];

export const FINISHES_STARWARS = [
	FINISH_NONFOIL,
	FINISH_FOIL, 
];



