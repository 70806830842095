import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  getGalleryElementMaxWidth, 
  getGalleryElementMinAcross, 
  getGalleryElementMinWidth, 
  getGalleryLoadOffset, 
} from '../../utils/gallery';

import GeneralThumbnail from './blocks/elements/GeneralThumbnail';

let allActionCreators = Object.assign({});

export class ThumbnailGallery extends Component {

  getElement(elemObj, idx = null) {

    if(!this.props.config) {
      return null;
    }

    const productLoadOffset = getGalleryLoadOffset(this.props.config);

    let loadDelay = null;
    if(productLoadOffset && idx !== null) {
      loadDelay = idx * productLoadOffset;
    }

    const ThumbComponent = elemObj.componentThumbnailGallery;
    if(ThumbComponent) {
      return <ThumbComponent
                config={this.props.config}
                isBuylist={this.props.isBuylist}
                modelObj={elemObj}
                loadDelay={loadDelay}
                selectAction={this.props.selectAction}  />;
    }

    return <GeneralThumbnail 
              config={this.props.config}
              isBuylist={this.props.isBuylist}
              productObj={elemObj}
              loadDelay={loadDelay} />;
  }

  getArrangementClass() {

    if(!this.props.galleryWidth) { return ''; }

    const TG_HORIZONTAL_MARGIN = 10;
    const TG_MAX_ACROSS_FITTED = 6;

    const minElements = getGalleryElementMinAcross(this.props.config);
    const minWidth = getGalleryElementMinWidth(this.props.config);
    
    let fitCount = Math.floor(this.props.galleryWidth / (minWidth + TG_HORIZONTAL_MARGIN));
    if(fitCount < minElements) {
      fitCount = minElements;
    }

    if(fitCount > TG_MAX_ACROSS_FITTED) {
      return ` tileMax`;
    } else {
      return ` tile${fitCount}`;
    }
  }

  getElementStyle() {
    const maxWidth = getGalleryElementMaxWidth(this.props.config);

    const elemStyle = {
      maxWidth: `${maxWidth}px`,
    };

    if(!this.props.galleryWidth) {
      const minWidth = getGalleryElementMinWidth(this.props.config);
      elemStyle.width = `${minWidth}px`;
      elemStyle.transition = `none`;
    }

    return elemStyle;
  }

  render() {
    return <div className={'ThumbnailGallery'}>
      <div className={'thumbnailGalleryWrapper' + this.getArrangementClass()}>
        {this.props.data ?
          <div>
            {this.props.data.map((elem, i) => {
              return <div 
                        key={elem.id ? elem.id : i}
                        className='thumbnailElementWrapper'
                        style={this.getElementStyle()}>{this.getElement(elem, i)}</div>;
            })}
          </div> :
          null
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ThumbnailGallery));