import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ICON_CHECK,
  ICON_CLOSE,
  ICON_TRASH,
} from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';

import { ProductTag } from '../../../../models/products';

import { isFormValid } from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';

import useFormValidationTagName from '../../../../hooks/form-validation/useFormValidationTagName';
import usePrevious from '../../../../hooks/usePrevious';

import { 
  productTagCreate, 
  productTagUpdate, 
  productTagDelete, 
} from '../../../../actions/product';

import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';

export const ProductTagEntry = (props) => {
  
  const dispatch = useDispatch();

  // Props
  const {
    closeMethod,
    completeSave,
    deleteOverride,
    product,
    productPending,
    productTag,
    saveOverride, 
    setRequestError,
  } = props;


  // State
  const [editing, setEditing] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [requestPending, setRequestPending] = useState(false);


  // Previous data
  const prevProductPending = usePrevious(productPending);


  // Form
  const { 
    inputTagName, 
    errorTagName, 
    changeTagName, 
    validateTagName, 
    setTagName,
  } = useFormValidationTagName(productTag ? productTag.name : '');


  // Internationalization
  const { t } = useTranslation();


  // Effects
  useEffect(() => {
    if(productTag === null) {
      setEditing(true);
    }
  }, [productTag]);

  useEffect(() => {
    if (prevProductPending === true && productPending === false) {
      setRequestPending(false);
    }
  }, [productPending, prevProductPending]);

  // Methods

  const openEditing = () => {
    if(!editing && !deleteConfirm) {
      setEditing(true);
    }
  };

  const closeEditing = () => {
    setEditing(false);
    if(closeMethod) {
      closeMethod();
    }
    if(productTag) {
      setTagName(productTag.name);
    }
  };

  const handleDelete = async (evt) => {

    setRequestPending(true);

    const productLinePermalink = product.productLine.permalink;
    const productPermalink = product.permalink;

    if(deleteOverride) {

      // Delete shared tag

      const resp = await deleteOverride(inputTagName)
        .catch((errResp) => {
          console.error(errResp);
          setRequestError(formatServerError(errResp));
          setRequestPending(false);
        });

      if(!resp) {
        return null;
      }

    } else {

      // Delete tag

      const resp = await dispatch(productTagDelete(productLinePermalink, productPermalink, productTag.getApiData()))
        .catch((errResp) => {
          console.error(errResp);
          setRequestError(formatServerError(errResp));
          setRequestPending(false);
        });

      if(!resp) {
        return null;
      }
    }

    setRequestError(null);

    if(completeSave) {
      await completeSave();
    }
    unpromptDelete();
  };

  const promptDelete = (evt) => {
    if(evt) { 
      evt.preventDefault(); 
      evt.stopPropagation();
    }
    setDeleteConfirm(true);
  };

  const unpromptDelete = () => {
    setDeleteConfirm(false);
  };

  const validateAll = () => {
    const errors = [
      errorTagName,
    ];
    return isFormValid(errors);
  };

  const handleSubmit = async (evt) => {

    if(evt) { evt.preventDefault(); }

    // Validate
    if(validateAll()) {

      setRequestPending(true);

      if(saveOverride) {

        // Save shared tag
        const resp = await saveOverride(inputTagName, productTag ? productTag.name : '')
          .catch((errResp) => {
            console.error(errResp);
            setRequestError(formatServerError(errResp));
            setRequestPending(false);
          });

        if(!resp) {
          return null;
        }

      } else {

        const saveMethod = productTag === null ? productTagCreate : productTagUpdate;

        const productLinePermalink = product.productLine.permalink;
        const productPermalink = product.permalink;

        const tagModel = productTag || new ProductTag({});
        tagModel.name = inputTagName;

        // Save tag
        const resp = await dispatch(saveMethod(productLinePermalink, productPermalink, tagModel.getApiData()))
          .catch((errResp) => {
            console.error(errResp);
            setRequestError(formatServerError(errResp));
            setRequestPending(false);
          });

        if(!resp) {
          return null;
        }
      }

      setRequestError(null);

      if(completeSave) {
        await completeSave();
      }
      closeEditing();
    }
  };

  return (
    <div className={`ProductTagEntry ${editing ? 'active' : ''} ${deleteConfirm || requestPending ? 'overlaid' : ''}`}>
      <div className={`productTagLiner`} onClick={openEditing}>
        <div className='primaryWrapper'>
          {editing ?
            <div className='primaryEditing'>
              <form className='editTagForm' onSubmit={handleSubmit}>
                <input 
                  type='text'
                  className={`inputTagName ${errorTagName ? 'InputError' : ''}`}
                  value={inputTagName}
                  placeholder={t(tx.TX_PLACEHOLDER_TAG_NAME)}
                  onChange={changeTagName}
                  onBlur={validateTagName}
                  disabled={requestPending} />
                <button 
                  className='submitTagName'
                  type='submit'>
                  <div className='submitIconWrapper'>
                    {requestPending ?
                      <LoadingIcon /> :
                      <Icon value={ICON_CHECK} />
                    }
                  </div>
                </button>
              </form>
            </div> :
            <div className='primaryDisplay'>{productTag ? productTag.name : ''}</div>
          }
        </div>
        <div className='controlWrapper'>
          {editing ?
            <div className='controlEditing' onClick={closeEditing}>
              <Icon value={ICON_CLOSE} />
            </div> :
            <div className='controlDisplay' onClick={promptDelete}>
              <Icon value={ICON_TRASH} />
            </div>
          }
        </div>
        {deleteConfirm || requestPending ?
          <div className='rowOverlay'>
            {requestPending ?
              <div className='pendingOverlay'>
                <div className='pendingCopy'>{t(tx.TX_SAVING)}</div>
              </div> :
              <div className='deleteOverlay'>
                <div className='deleteCopy'>{t(tx.TX_INV_TAGS_DELETE_TAG_PROMPT)}</div>
                <div className='deleteConfirm' onClick={handleDelete}>{t(tx.TX_DELETE)}</div>
                <div className='deleteCancel' onClick={unpromptDelete}>{t(tx.TX_CANCEL)}</div>
              </div>
            }
          </div> :
          null
        }
      </div>
    </div>
  );
};

export default ProductTagEntry;

